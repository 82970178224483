<div class="fluid-container p-3">

  <h6 *ngIf="abilityTypeNational"  class="heading p-text-bold">
    National or International prizes and awards
  </h6>
  <h6 *ngIf="!abilityTypeNational" class="heading p-text-bold">
    Receipt of a major, internationally recognized award, such as the Noble prize
  </h6>
  <form [formGroup]="prizeAwardsForm">
    <div *ngIf="hideAddAwardSection">
      <label>Add Award Details</label>
      <button [disabled]="disabledControls"  pButton class="btn btn-primary"
      (click)="toggleAddSection()" class="float-right">
      Add more</button>
    </div>
    <div class="p-fluid p-formgrid  mb-4" *ngIf="!hideAddAwardSection">
  <div class="row">
     <div class="col-8 px-0">
      <label for="awardName" class="label-cls required">Award Name</label>
      <input [class.disabled]="disabledControls"
          [disabled]="disabledControls"  type="text" pInputText name="awardName" formControlName="awardName"
          required /> 
      <span class="invalid-message" *ngIf="prizeAwardsForm.get('awardName').touched && prizeAwardsForm.get('awardName').errors?.required">
            {{ getGlobalErrorMessages("REQUIRED") }}
      </span> 
    </div>
    <div class="col-4">
      <label for="awardedYear" class="label-cls required">Awarded Year</label>
      <input [class.disabled]="disabledControls" pInputText maxlength="4" min="0" oninput="validity.valid||(value='');"
      type="number" name="awardedYear" formControlName="awardedYear"
        [disabled]="disabledControls" required /> 
      <span class="invalid-message" *ngIf="prizeAwardsForm.get('awardedYear').touched && prizeAwardsForm.get('awardedYear').errors?.required">
            {{ getGlobalErrorMessages("REQUIRED") }}
      </span> 
      <span class="invalid-message" *ngIf="prizeAwardsForm.get('awardedYear').touched && prizeAwardsForm.get('awardedYear').errors?.max">
        Award year should be lesser than current year.
      </span> 
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-8 px-0">
      <label for="awardingInstitution" class="label-cls required">Awarding Institute or Reference to award from distinguished scholar.</label>
      <input [class.disabled]="disabledControls" pInputText type="text" name="awardingInstitution" formControlName="awardingInstitution"
        [disabled]="disabledControls" required /> 
      <span class="invalid-message" *ngIf="prizeAwardsForm.get('awardingInstitution').touched && prizeAwardsForm.get('awardingInstitution').errors?.required">
            {{ getGlobalErrorMessages("REQUIRED") }}
      </span> 
    </div>
  </div>

   <div class="row mt-4">
     <div class="col-2 px-0 mr-3 saveAwardBtnHover">
        <button type="submit" [disabled]="disabledControls || !prizeAwardsForm.valid"
        (click)="addAwardDetail();" class="btn btn-primary btn-add">
        <i aria-hidden="true" class="fa fa-plus-circle"></i>{{ addEditButtonText }} </button>
      </div>
      <div class="col-3">
        <button   type="button" label="Cancel"  class="btn btn-primary pl-3 pr-3" [disabled]="disabledControls" (click)="toggleAddSection()">
          Cancel
        </button>
      </div> 
    </div>
  </div>

  <div class="row w-100" >
    <!-- <label class="text-primary">Added Details</label> -->
    <h6 class="heading p-text-bold mb-3">Added Details</h6>
    
  </div>
    <p-table class="table-striped-simple" [scrollable]="true" scrollHeight="150px" [value]="addedDocuments" *ngIf="addedDocuments.length > 0">
      <ng-template pTemplate="header">
        <tr class="">
            <th class="col-3">Award Name</th>
            <th class="col-3">Awarded Year</th>
            <th class="col-4">Awarding Institution</th>
            <th class="col-2 px-0 text-center ">Action</th>
        </tr>
    </ng-template>
            <ng-template pTemplate="body" let-doc>
              <tr>
                <td class="col-3" >{{doc.awardName}}</td>
                <td class="col-3" >{{doc.awardedYear}}</td>
                <td class="col-4" >{{doc.awardingInstitution}}</td>
                <td class="col-2 px-0 text-center">
                  <button pButton type="button" [disabled]="disabledControls" title="View Document" 
                      (click)="editDocument(doc)"
                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      <mat-icon class="mr-1 icn" matTooltip="Edit" >edit</mat-icon>
                  </button>
                  <button pButton type="button" [disabled]="disabledControls" title="Delete Document" 
                      (click)="deleteDocument(doc.id)"
                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                  </button>
              
            </td>
            </tr>
            </ng-template>
    </p-table> 

  <div *ngIf="addedDocuments.length == 0" class="border w-100 p-2">
  <p class="text-secondary m-0"> Currently No details added!</p>
  </div>



  <p-divider type="dashed" class="mt-4"></p-divider>

  <!-- <label>Upload Award Document</label> -->
  <h6 class="heading p-text-bold mb-3">Upload Award Document</h6>

    <i140-file-upload style="width: 100%;"
    [fileUploadConfigurations]="fileUploadConfigurations">
  </i140-file-upload>

  <p-divider type="dashed" class="mt-4"></p-divider>
  <!-- <label>Summary</label> -->
  <h6 class="heading p-text-bold">Summary</h6>

  <div class="col-12 px-0">
    <!-- <textarea [(froalaModel)]="additionalInfo"
      name="text" [froalaEditor]="options" class="summaryTxt"></textarea> -->
      <kendo-editor [(value)]="additionalInfo" (valueChange)="nationalprizeseditorValueChange($event)" #nationalprizeskendoeditor style="height: 400px;" formControlName="text" name="text">
        <kendo-toolbar>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
          <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
          <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
          <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
          <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
          <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
          <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
            <kendo-toolbar-button
            title="Upload’s Image at cursor point"
            text="Upload Image"
            (click)="opennationalprizesuploaddialog()"
            ></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>                                  
        </kendo-toolbar>
    </kendo-editor>
    <app-upload-image-dialog #nationalprizesupload [editor]="nationalprizeskendoeditor"></app-upload-image-dialog> 
  </div>
  <br>

  <div class="row">
    <div class="col-md-3">
      <button pButton label="Save" *ngIf="!disabledControls" (click)="saveAdditionalInfo1()" class="d-inline-block float-left saveBtn"></button>
    </div>
    <div class="col-md-6 completeStep">
      <button type="button" *ngIf="stepStatus !== 'COMPLETE' && !abilityTypeNational"
      [disabled]="stepStatus !== 'INPROGRESS'"
      class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg stepCompleted" (click)="onMarkComplete()">
      <span class="btn-text">{{markStep}}</span>
      <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
    </button>
    <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
    </div>

  </div>

  </form>
</div>