import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck, map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  public profilePicUpdated = new BehaviorSubject<boolean>(false);//im-3899
  // public profilePicUpdate = new BehaviorSubject<boolean>(false);//IM-3763
  public profilePicUpdate = new BehaviorSubject<object>({hasImage: false, image:''});//IM-3763
  public isUserTypeAdmin = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) { }

  getUsers(companyId) {
    return this.httpClient.get(`/company/${companyId}/user/roles`).pipe(
      pluck('data'),
      map((users: Array<any>) => {
        return users;
      })
    );
  }

  getUsersList(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/user/roles/list`, payload).pipe(
      pluck('data'),
      map((users: Array<any>) => {
        return users;
      })
    );
  }

  getCompanyUsersCount(companyId) {
    return this.httpClient.get(`/company/${companyId}/noofuser`).pipe(
      pluck('data'),
      map((users: Array<any>) => {
        return users;
      })
    );
  }

  updateUserCount(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/update/${payload}`, {}).pipe(
      map((response: ImagilityBaseResponse) => response) 
    );
  }

  upgradeUserCount(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/update/${payload}`, {}).pipe(
      map((response: ImagilityBaseResponse) => response) 
    );
  }

  notifyEmailForNewUser(companyId) {    
    return this.httpClient.get(`/company/getsalenotification/${companyId}`);
  }

  saveUser(companyId, payload) {
    return this.httpClient.post('/company/' + companyId + '/employee', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getRoles() {
    return this.httpClient.get('/roles').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  saveRole(payload) {
    return this.httpClient.post('/roles', payload).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getModuleList(){
    return this.httpClient.get(`/vd/formBuilder/module/list`)
      .pipe(map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return [];
        }
      }));
  }

  deleteRoles(payload) {
    return this.httpClient.post('/roles/remove', payload).pipe(
      //  pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  deleteAssignedRole(companyId, userId, roleId) {
    return this.httpClient.delete('/company/' + companyId + '/user/' + userId + '/role/' + roleId).pipe(
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getCompanyNames() {
    return this.httpClient.get('/company').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  // getPermissions() {
  //   return this.httpClient.get('/permissions').pipe(
  //     pluck('data'),
  //     map((data: Array<any>) => {
  //       return data;
  //     })
  //   );
  // }

  getPermissions() {
    return this.httpClient.get('/allPermissions').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }


  getRolesWithPermissions(roleid) {

    return this.httpClient.get('/roles/' + roleid + '/permissions').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  assignRoleToUser(payload) {
    return this.httpClient.post('/company/' + payload[0].companyId + '/user/' + payload[0].employeeId + '/role', payload)
      .pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
  }

  saveLoginCredentials(companyId, employeeId, payload) {
    return this.httpClient.post('/company/' + companyId + '/employee/' + employeeId + '/login', payload).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getRoleByCompanyId(companyId) {
    return this.httpClient.get('/company/' + companyId + '/role').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );

  }

  getRolesAndPermissionsByCompanyId(companyId, payload) {
    return this.httpClient.post('/company/' + companyId + '/role/permission', payload).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getAssignedPermissions(roleID) {
    return this.httpClient.get('/roles/' + roleID + '/permissions')
      .pipe(pluck('data'), map((data: Array<any>) => {
        return data;
      })
      );
  }
  getAssignedPermissionsForApp(roleID) {
    return this.httpClient.get('/role/' + roleID + '/permissions')
      .pipe(pluck('data'), map((data: Array<any>) => {
        return data;
      })
      );
  }

  getRoleToUser(companyId, empId, payload) {
    return this.httpClient.get('/company/' + companyId + '/user/' + empId + '/role', payload).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getCompanyNameByCompanyId(companyId) {
    return this.httpClient.get('/company/' + companyId).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  // signature png image begin

  getSignatureImage(companyId, employeeId) {
    return this.httpClient.get(`/company/${companyId}/employee/${employeeId}/signPic`).pipe(
      map((data: Array<any>) => {
        return data;
      }));
  }

  postSignatureImage(companyId, employeeId, payload) {
    return this.httpClient.post(`/company/${companyId}/employee/${employeeId}/signPic`, payload).pipe(
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  imageProfileUpdated(noUpdateFlag) {
    this.profilePicUpdated.next(noUpdateFlag); // im-3899
  }

  hasProfileImage(obj:any) {
    this.profilePicUpdate.next(obj); // IM-3763
  }

  getUserInfo() {
    return this.httpClient.get('/user').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getUserInfoByBeneficiaryId(beneficiaryId) {
    return this.httpClient.get(`/user?beneficiaryId=${beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getPolicyContent(userType, policyType) {
    return this.httpClient.get(`/user/${userType}/policyType/${policyType}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  acceptPrivacy(userId, policyId) {
    return this.httpClient.post(`/user/${userId}/policy/${policyId}`, []).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  rejectPrivacy(userId) {
    return this.httpClient.post(`/user/${userId}/loginAttempt/0`, []).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  
  getAppListByCompanyId(companyId) {
    return this.httpClient.get('/vd/company/' + companyId + '/apps').pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  saveAppRolesAndPermission(payload) {
    return this.httpClient.post('/vd/company/userAppRole/save', payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getAppUserRoles(companyId,userId) {
    return this.httpClient.get(`/vd/company/${companyId}/user/${userId}/userAppRoles`).pipe(
      pluck('data'),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  

}// class end


