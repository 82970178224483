import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class VisaDesignerService {

  constructor(private http:HttpClient) { }

    getPlaceHolderControls(payload: object) {
      return this.http.post<any>(`/vd/controls`, payload).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }

    getTimelinePlaceHolderControls(payload: object) {
      return this.http.post<any>(`/vd/controls`, payload).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }

    saveTaskDetailsTemplates(payload: object) {
      return this.http.post<any>(`/vd/taskType`, payload).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }

    getTaskDetailsTemplates(visaId: any) {
      return this.http.get<any>(`/vd/taskType/`+visaId).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }

    deleteTaskDetailsTemplates(visaId: any) {
      return this.http.delete<any>(`/vd/taskType/`+visaId).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }
    createVisa(payload) {
      return this.http.post(`/vd/taskType`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }

    getSupportedForms() {
      return this.http.get<any>(environment.baseUrl +`/supporting/visaForm/list`).pipe(map((data: ImagilityBaseResponse) => {
        return data;
      }));
    }

}
