import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { Router } from '@angular/router';
import { TaskUtilityService } from '../../../../utility/task-utility.service';
import * as RolesRouteMap from '../../../../../data/constants/roles';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { take, takeUntil } from 'rxjs/operators';
import { HelpVideoOverlayComponent } from 'src/app/modules/shared/help-video-overlay/help-video-overlay.component';
import { Subject, combineLatest } from 'rxjs';
import { CompletedPetitionStatusArr, InprogressPetitionStatusArr, NewPetitionStatusArr } from 'projects/app-models/src/constants/petition-status';
import moment from 'moment';
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';
import * as viewByOptions from 'src/app/data/constants/tasklist-duration-options.json';
import { RecentlyVisitedFilterModel } from 'src/app/data/models/dashboard-widgets.model';
import { AuthenticationService } from 'authentication';
import { DialogService } from 'primeng/dynamicdialog';
import { PetitionProcessTypeSelectionComponent } from 'src/app/modules/superTask/petition-process-type-selection/petition-process-type-selection.component';
import { CreateTaskWithWizardComponent } from 'src/app/modules/superTask/components/create-task-with-wizard/create-task-with-wizard.component';
@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class TaskListComponent implements OnInit, OnDestroy, AfterViewInit {


  taskList = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  newTaskCount = 0;
  inProgressTaskCount = 0;
  completedTaskCount = 0;
  currentYearIndex: number;
  currentMonthIndex: number;
  selectedIndex = 0;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  statusObj: string[] = [];
  globalIndexOffset: number;
  monthIndex = 0;
  noDataMsg = false;
  taskListObj: Array<any>;
  toggleStatus = 'NEW';
  userType: string;
  isOutsideImagility = false;
  taskType: ReferenceLibrary[];
  subTaskType: any[];
  visaTypes: ReferenceLibrary[];
  petitionTypes: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  cols: any[];
  nestedCols: any[];
  immigrantTypeCols: any[];
  nonImmigrantTypeCols: any[];
  immigrantTypeNestedCols: any[];
  nonImmigrantTypeNestedCols: any[];
  awaitingNonImmigrantTypeCols: any[];
  awaitingImmigrantTypeCols: any[];
  completeNonImmigrantTypeCols: any[];
  completeImmigrantTypeCols: any[];
  observableSubscription$ = new Subject();
  filter = {
    superTaskType: 'NONIMMPET',
    subTaskType: [],
    visaType: [],
    petitionType: [],
    immClassificationType: [],
    immCategoryType: [],
    duration: 'halfYearly'
  };
  startDate: any;
  endDate: any;
  roleName: boolean = false;

  payload = {
    companyId: parseInt(sessionStorage.getItem('companyId')),
    endDate: null,
    pageNumber: 1,
    pageSize: 10,
    searchText: '',
    startDate: null,
    visaTypes: this.filter.visaType,
    petitionTypes: this.filter.petitionType,
    subTaskTypes: this.filter.subTaskType,
    superTaskType: this.filter.superTaskType,
    immClassificationTypes: this.filter.immClassificationType,
    immCategoryTypes: this.filter.immCategoryType,
    taskStatus: [],
    filterTasksFromDate: null,
    filterTasksToDate: null,
    employeeId: null
  };

  @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;
  immigrantSubTask: ReferenceLibrary[];
  nonImmigrantSubTask: ReferenceLibrary[];
  viewOptions: RecentlyVisitedFilterModel[];
  viewOptionNew: string = 'halfYearly';
  viewOptionPending: string = 'halfYearly';
  viewOptionCompleted: string = 'halfYearly';
  activeTasksTab: string = 'mine';
  activeEmployeeId: any = parseInt(sessionStorage.getItem('loginEmployeeId'));
  constructor(
    private dashboardWidgetsService: DashboardWidgetsService,
    public dialog: MatDialog,
    private toastService: ToastrService,
    private referenceLibraryService: ReferenceLibraryService,
    private router: Router,
    private utilityService: TaskUtilityService,
    protected store: Store<any>,
    private dialogService: DialogService,
    public authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.viewOptions = (viewByOptions as any).default;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.roleName = sessionStorage.getItem('roleName') === 'External Attorney' ? true : false;
    // this.getTasks('NEW');
    this.utilityService.setTaskId(null);

    this.immigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'status', header: 'Status' },
    ];
    this.nonImmigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'petitionType', header: 'Petition Type' },
      { field: 'status', header: 'Status' },
    ];
    this.immigrantTypeNestedCols = [
      { field: 'subTaskType', header: 'Petition Sub-Type' },
      { field: 'preparerName', header: 'Preparer' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'enddate', header: 'End Date' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'status', header: 'Status' }
    ];
    this.nonImmigrantTypeNestedCols = [
      { field: 'subTaskType', header: 'Petition Sub-Type' },
      { field: 'preparerName', header: 'Preparer' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'enddate', header: 'End Date' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'status', header: 'Status' }
    ];

    this.awaitingImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'serviceCenter', header: 'Service Center' }
    ];
    this.awaitingNonImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'petitionType', header: 'Petition Type' },
      { field: 'serviceCenter', header: 'Service Center' },
    ];
    this.completeImmigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'filedDate', header: 'Filed Date' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'serviceCenter', header: 'Service Center' },
      { field: 'status', header: 'USCIS Decision' },
    ];
    this.completeNonImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'filedDate', header: 'Filed Date' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'petitionType', header: 'Petition Type' },
      { field: 'serviceCenter', header: 'Service Center' },
      { field: 'status', header: 'USCIS Decision' },
    ];

    this.activeEmployeeId = !isNaN(this.activeEmployeeId) ? this.activeEmployeeId : (this.authenticationService?.currentUserValue ? this.authenticationService?.currentUserValue['employeeId'] : null);
    this.payload.employeeId = this.activeEmployeeId;
    this.getTaskType();
    // this.getIMMClassification();
    // this.getIMMCategory();
  }

  ngAfterViewInit() {
    this.helpVideoComponent.setValues('right', 'How to create Tasks', 'How to create Tasks');
  }

  // addNewTask() {
  //   // this.router.navigate(['petitioner-landing/dashboard/task',  { id: 5 }]);
  //   // this.router.navigate(['petitioner-landing/dashboard/task/task-details', 0]);
  //   sessionStorage.removeItem('taskId');
  //   sessionStorage.removeItem('requestDataDocument');
  //   //this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/task-list/new`]);
  //   // const slug = this.userType === 'Attorney' ? 'new-attorney': 'new';
  //   this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/new`]);
  // }

  addNewTask() {
    const dialogConfig = {
      width: '60%',
      closeOnEscape: false,
      closable: false,
      showHeader: false,
      contentStyle: { "z-index": "1001" }
    };
    const seletionDialogRef = this.dialogService.open(PetitionProcessTypeSelectionComponent, {
      ...dialogConfig,
    });
    seletionDialogRef.onClose
                .pipe(takeUntil(this.observableSubscription$)).subscribe((response: number) => {
                  if (response) {
                      if(response == 1) {
                        sessionStorage.removeItem('taskId');
                        sessionStorage.removeItem('requestDataDocument');
                        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/new`]);
                      } else {
                        this.openQuestionnaireForWizard();
                      }
                    }
                    else {
                    }
                  })
                }

openQuestionnaireForWizard() {
  const dialogConfig = {
    width: '60%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001" }
  };
  const wizardRef = this.dialogService.open(CreateTaskWithWizardComponent, {
    ...dialogConfig,
  });
}

  // Filter

  getTaskType() {
    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        this.getSubTaskType(this.filter.superTaskType);
      });
  }

  handleFilterChange() {
    this.payload.subTaskTypes = this.filter.subTaskType;
    this.payload.superTaskType = this.filter.superTaskType;
    this.payload.filterTasksFromDate = this.startDate === undefined ? null : moment(this.startDate).format('YYYY-MM-DD');
    this.payload.filterTasksToDate = this.endDate === undefined ? null : moment(this.endDate).format('YYYY-MM-DD');
    if (this.payload.superTaskType === 'NONIMMPET') {
      this.payload.visaTypes = this.filter.visaType;
      this.payload.petitionTypes = this.filter.petitionType;
      this.payload.immClassificationTypes = null;
      this.payload.immCategoryTypes = null;
    } else {
      this.payload.visaTypes = null;
      this.payload.petitionTypes = null;
      this.payload.immClassificationTypes = this.filter.immClassificationType;
      this.payload.immCategoryTypes = this.filter.immCategoryType;
    }
    this.getTasks();
  }

  // filterCancel() {
  //   this.filter.superTaskType = this.payload.superTaskType;
  //   this.filter.subTaskType = this.payload.subTaskTypes;
  //   this.filter.visaType = this.payload.visaTypes;
  //   this.filter.petitionType = this.payload.petitionTypes;
  //   this.filter.immClassificationType = this.payload.immClassificationTypes;
  //   this.filter.immCategoryType = this.payload.immCategoryTypes;
  // }

  getSubTaskType(superTaskType: string) {
    this.filter.superTaskType = superTaskType;
    if (superTaskType === 'IMMPET' ? this.immigrantSubTask : this.nonImmigrantSubTask) {
      if (superTaskType === 'IMMPET') {
        this.subTaskType = [...this.immigrantSubTask];
      } else {
        this.subTaskType = [...this.nonImmigrantSubTask];
      }
      this.filter.subTaskType = this.subTaskType.map(x => x.code);
      this.onSubTaskSelected();
    } else {
      this.dashboardWidgetsService.getSubTasksType(superTaskType)
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          if (response) {
            this.subTaskType = [];
            new Set(response.map(x => x.id)).forEach(id => {
              this.subTaskType.push(response.find(x => x.id === id));
            });
            if (superTaskType === 'IMMPET') {
              this.subTaskType.push({
                code: "PETITION",
                createdBy: 1,
                description: "Petition",
                group: "TASKTYP",
                id: 494,
                modifiedBy: null,
                name: "Petition",
                refGroupdesc: "Task Type",
              })
            }
            this.filter.subTaskType = this.subTaskType.map(x => x.code);
            if (superTaskType === 'IMMPET') {
              this.immigrantSubTask = [...this.subTaskType];
            } else if (superTaskType === 'NONIMMPET') {
              this.nonImmigrantSubTask = [...this.subTaskType];
            }
          } else {
            this.subTaskType = [];
            this.filter.subTaskType = [];
          }
          this.onSubTaskSelected();
        });
    }
  }

  onSubTaskSelected() {
    if (this.filter.superTaskType === 'IMMPET') {
      switch (this.toggleStatus) {
        case 'NEW':
          this.cols = this.immigrantTypeCols;
          break;
        case 'FILED':
          this.cols = this.awaitingImmigrantTypeCols;
          break;
        case 'COMPLETE':
          this.cols = this.completeImmigrantTypeCols;
          break;
        default:
          break;
      }
      //this.cols = this.immigrantTypeCols;
      this.nestedCols = this.immigrantTypeNestedCols;
      this.getIMMCategory();
    } else if (this.filter.superTaskType === 'NONIMMPET') {
      switch (this.toggleStatus) {
        case 'NEW':
          this.cols = this.nonImmigrantTypeCols;
          break;
        case 'FILED':
          this.cols = this.awaitingNonImmigrantTypeCols;
          break;
        case 'COMPLETE':
          this.cols = this.completeNonImmigrantTypeCols;
          break;
        default:
          break;
      }
      //this.cols = this.nonImmigrantTypeCols;
      this.nestedCols = this.nonImmigrantTypeNestedCols;
      this.getVisaType();
    }
  }

  getVisaType() {
    if (!this.visaTypes) {
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.visaTypes = response;
          this.filter.visaType = this.visaTypes.map(x => x.code);
          this.getPetitionType();
        });
    } else {
      this.filter.visaType = this.visaTypes.map(x => x.code);
      this.getPetitionType();
    }
  }

  getPetitionType() {
    if (!this.petitionTypes) {
      this.referenceLibraryService.getReferenceData('PETTYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.petitionTypes = response;
          this.filter.petitionType = this.petitionTypes.map(x => x.code);
          this.handleFilterChange();
        });
    } else {
      this.filter.petitionType = this.petitionTypes.map(x => x.code);
      this.handleFilterChange();
    }
  }

  getIMMClassification() {
    if (this.immCategoryTypes) {
      if (!this.immClassificationTypes) {
        this.immClassificationTypes = [];
        const req = [];
        this.immCategoryTypes.forEach(immCategory => {
          req.push(this.referenceLibraryService.getReferenceData(immCategory.code));
        });
        combineLatest(req)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            response.forEach((res, k) => {
              this.immClassificationTypes = [...this.immClassificationTypes, ...res];
            });
            // this.immClassificationTypes = [...response[0], ...response[1]];
            this.filter.immClassificationType = this.immClassificationTypes.map(x => x.code);
            this.handleFilterChange();
          });
      } else {
        this.filter.immClassificationType = this.immClassificationTypes.map(x => x.code);
        this.handleFilterChange();
      }
    }
  }

  getIMMCategory() {
    if (!this.immCategoryTypes) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.immCategoryTypes = response;
          this.filter.immCategoryType = this.immCategoryTypes.map(x => x.code);
          this.getIMMClassification();
        });
    } else {
      this.filter.immCategoryType = this.immCategoryTypes.map(x => x.code);
      this.getIMMClassification();
    }
  }

  getTasks() {
    // IM-3329 IM-3368
    if (this.toggleStatus === 'NEW') {
      this.statusObj = NewPetitionStatusArr;
    } else if (this.toggleStatus === 'COMPLETE') {
      this.statusObj = CompletedPetitionStatusArr;
    } else {
      this.statusObj = InprogressPetitionStatusArr;
    }
    this.payload.filterTasksFromDate = this.filter.duration ? this.getDate('from') : null;
    this.payload.filterTasksToDate = this.filter.duration ? this.getDate('to') : null;
    if (this.userType === 'Attorney') {
      const payload = {
        taskStatus: this.statusObj
      };

      this.payload.taskStatus = this.statusObj;
      this.dashboardWidgetsService.getTaskListForAttorney(parseInt(sessionStorage.getItem('companyId')), this.payload)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.taskList = (data && data['superTasks']) ? data['superTasks'].map((item) => { if (item?.filedDate) { item.filedDate = moment(item.filedDate).format('YYYY-MM-DD') } return item; }) : [];
          this.newTaskCount = data && data.newSuperTaskCount;
          this.inProgressTaskCount = data && data.pendingSuperTaskCount;
          this.completedTaskCount = data && data.completedSuperTaskCount;
          sessionStorage.setItem('beneficiaryId', (this.taskList.length ? data['superTasks'][0].beneficiaryId : null));
          this.setDataSource();
        });
    } else {
      this.payload.taskStatus = this.statusObj;
      this.dashboardWidgetsService.getSuperTaskList(this.payload)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.taskListObj = data['data']['superTasks'];
          if (data['status'] === 200) {
            this.taskList = (data['data']['superTasks']) ? data['data']['superTasks'].map((item) => { if (item?.filedDate) { item.filedDate = moment(item.filedDate).format('YYYY-MM-DD') } return item; }) : [];
            this.newTaskCount = data['data'].newSuperTaskCount;
            this.inProgressTaskCount = data['data'].pendingSuperTaskCount;
            this.completedTaskCount = data['data'].completedSuperTaskCount;
            sessionStorage.setItem('beneficiaryId', (this.taskList.length ? data['data']['superTasks'][0].beneficiaryId : null));
            this.setDataSource();
          } else {
            this.taskList = [];
            this.newTaskCount = 0;
            this.inProgressTaskCount = 0;
            this.completedTaskCount = 0;
            // this.dataSource = new MatTableDataSource(this.taskList);
            this.noDataMsg = true;
          }
        });
    }
  }

  onRowExpand(event) {
    if (!event.data.subTaskList) {
      this.dashboardWidgetsService.getSubTaskList(event.data.id)
        .pipe(take(1))
        .subscribe((data: ImagilityBaseResponse) => {
          if (data.status === 200) {
            event.data.subTaskList = data.data;
          }
        });
    }
  }

  setDataSource() {
    if (this.taskList && this.taskList.length > 0) {
      this.taskList = this.taskList.slice(0, 10);
      // this.taskList.map((item) => {
      //   item.petitionType = item.taskTypeDetails ? item.taskTypeDetails.visaType + ' ' + item.taskTypeDetails.petitionName : null;
      // });
    } else {
      this.taskList = [];
    }
    // this.dataSource = new MatTableDataSource(this.taskList);
  }

  handleTask(task) {
    if(task?.taskSource === 'WIZARD'){
      sessionStorage.setItem('wizardTID', task?.subTaskId);
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
    }else{
      sessionStorage.setItem('tempBeneficiaryEmployeeId', task.beneficiaryId);
      sessionStorage.setItem('beneficiaryId', task.beneficiaryId);
      sessionStorage.removeItem('selectedSocCode'); 
      sessionStorage.removeItem('majorgroupValue');
      this.store.dispatch(new Task.SetTaskRouteId(task.id));
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details`]);
    }
  }

  resetFilters() {
    //this.filter = Object.assign({},this.initialFilterState);
    this.getSubTaskType('NONIMMPET');
  }

  toogleTaskTab(toggleText: string = '', event: any = null, duration: string = 'halfYearly') {
    if (event && event.target && event.target.classList && (event.target.className.indexOf('p-dropdown-label') > -1 || event.target.className.indexOf('p-inputtext') > -1 || event.target.className.indexOf('p-dropdown-trigger') > -1 || event.target.className.indexOf('p-dropdown-trigger-icon') > -1 || event.target.className.indexOf('pi-chevron-down') > -1)) {
      //Opening the dropdown
    } else {
      if (this.toggleStatus !== toggleText) {
        //reset the filters for new tab
        this.toggleStatus = toggleText;
        this.filter.duration = this.getDuration(toggleText);
        this.resetFilters();
      } else {
        this.toggleStatus = toggleText;
        this.filter.duration = duration;
        this.getTasks();
      }

    }
  }

  getDuration(statusText: string) {
    switch (statusText) {
      case 'NEW':
        return this.viewOptionNew;
      case 'FILED':
        return this.viewOptionPending;
      case 'COMPLETE':
        return this.viewOptionCompleted;
    }
  }

  getDate(flag: string) {
    if (flag === 'to') {
      return moment().format('YYYY-MM-DD');
    } else {
      switch (this.filter.duration) {
        case 'today':
          return moment().format('YYYY-MM-DD');
        case 'weekly':
          return moment().subtract(1, 'w').format('YYYY-MM-DD');
        case 'monthly':
          return moment().subtract(1, 'M').format('YYYY-MM-DD');
        case 'quarterly':
          return moment().subtract(3, 'M').format('YYYY-MM-DD');
        case 'halfYearly':
          return moment().subtract(6, 'M').format('YYYY-MM-DD');
        case 'yearly':
          return moment().subtract(1, 'y').format('YYYY-MM-DD');
      }
    }
  }

  handleDelete(input) {
    const title = 'Confirm';
    const message = 'Do you wish to delete the Task?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.dashboardWidgetsService.deleteTaskId(input.id)
          .pipe(take(1))
          .subscribe(data => {
            if (data) {
              this.toastService.success('Task deleted successfully', 'Success');
              this.getTasks();
            }
          });
      }
    });
  }

  showMore() {
    this.router.navigate([`${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}-landing/${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}/task-list`]);
  }

  dialogueInitializer(title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  onActiveTaskTab(activeTab: string) {
    this.activeTasksTab = activeTab;
    switch (activeTab) {
      case 'mine':
        this.payload.employeeId = this.activeEmployeeId;
        break;
      case 'all':
        this.payload.employeeId = null;
    }
    this.getTasks();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
  }


}// class end
