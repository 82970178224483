import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiServicesService } from '../../../../modules/beneficiary/services/api-services.service';
import { PetitionerService } from '../../../../services/petitioner.service';
import { TaskUtilityService } from '../../../utility/task-utility.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
// const PETITION_TYPE_KEYS = Object.keys(PETITION_TYPES);
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { take, takeUntil } from 'rxjs/operators';
import { ReferenceLibrary } from 'app-models';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { CreateTaskService } from 'src/app/modules/superTask/components/create-task/create-task.service';
import { Company } from 'src/app/data/models/company.model';
import { CustomQuestionnaireService } from 'src/app/modules/custom-questionaire/services/custom-questionnaire.service';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'dashboard-all-time-lines',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit, OnDestroy {

  public petitiontype: string;
  public allTasks = [];
  public taskCapsuleDataSource = [];
  public primaryCompanyId: number;
  public selectedTimeLineType: string;
  public selectedTaskId: number;
  /*app time line inputs vars' begin */
  stepsData = [];
  petitionId = '';
  taskId = '';
  taskTypeDetails = '';
  visaTypes = [];
  immiStatus = [];
  petitionTypes = [];
  beneficiaryId;
  experienceData;
  immigrationData = [];
  subTaskList = [];
  selectedSubTaskType;
  timelineList = [];
  isOutsideImagility;
  userName;
  noticeNumber;
  taskType: ReferenceLibrary[];
  superTaskId;
  receiptETADateRange;
  decisionETADateRange;
  isBeneficiaryVisible = true;
  PetitionDrowDownList = [];
  filter = {
    superTaskType: 'NONIMMPET',
    subTaskType: 'PETITION',
    visaType: '',
    petitionType: '',
    immClassificationType: '',
    immCategoryType: '',
    companyId: 0
  };
  noDataMsg = false;
  taskListObj: Array<any>;
  toggleStatus = 'NEW';
  userType: string;
  subTaskType: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  cols: any[];
  nestedCols: any[];
  immigrantTypeCols: any[];
  nonImmigrantTypeCols: any[];
  immigrantTypeNestedCols: any[];
  nonImmigrantTypeNestedCols: any[];
  payload = {
    companyId: this.filter.companyId,
    endDate: null,
    pageNumber: 1,
    pageSize: 25,
    searchText: '',
    startDate: null,
    visaTypes: this.filter.visaType,
    petitionTypes: this.filter.petitionType,
    subTaskTypes: this.filter.subTaskType,
    superTaskType: this.filter.superTaskType,
    immClassificationTypes: this.filter.immClassificationType,
    immCategoryTypes: this.filter.immCategoryType,
    taskStatus: [],
  };
  companyId = 1;
  completedPetitions = true;
  companyList: Company[] = [];

  /*app time line inputs vars' end */
  selectedTaskId$: BehaviorSubject<number> = new BehaviorSubject<number>(0); // IM-3620
  selectedTaskIdObsvble: Observable<number> = this.selectedTaskId$.asObservable();
  beneficiaryGuidedTourContent: {
    title: string;
    headerOne: string;
    image: string;
    videoLink: string;
    nextButtonText: string;
    watchLabel: string;
  }[];
  destroy$ = new Subject();

  constructor(
    private apiService: ApiServicesService,
    private petitionerService: PetitionerService,
    private utilityService: TaskUtilityService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private authenticationService: AuthenticationService,
    private referenceLibraryService: ReferenceLibraryService,
    private customQuestionnaireService: CustomQuestionnaireService,
    private createTaskService: CreateTaskService
  ) {
    // this.primaryCompanyId = parseInt(sessionStorage.getItem('beneficiaryId'));
    this.primaryCompanyId = parseInt(sessionStorage.getItem('loggedInBeneficiaryId'));
    this.selectedTimeLineType = 'PET'; // default timel line loaded
    this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ?
      parseInt(sessionStorage.getItem('listBeneficiaryId')) :
      this.authenticationService.currentUserValue['beneficiaryId'];
  }

  ngOnInit() {
    // this.fetchTaskListForBeneficiary();
    this.loadBeneficiaryWorkExperience();
    this.loadBeneficiaryImmigration();
    // this.sortTaskListForBeneficiar();
    // this.selectedTaskIdObsvble.subscribe((id) => {
    //   if (id != 0)
    //     this.getTaskInfo(id);
    // });

    this.beneficiaryGuidedTourContent = [
      {
        title: 'Building your Profile',
        headerOne: 'A Beneficiary detailed profile can help in the efficient processing of a visa petition. Through Edit Profile on dashboard or through <strong>View Profile</strong> dropdown option next to your profile image.Fill in personal,education, work experience and immigration details here and <strong>Save.</strong>',
        image: '',
        videoLink: 'Building Beneficiary Profile',
        nextButtonText: 'Click Next to continue with the tour',
        watchLabel: ''
      },
      {
        title: 'Working with Timelines',
        headerOne: 'Beneficiaries can view their Petition timeline,Immigration timeline nd project timeline on the dashboard itself. Click on the Petition timeline steps to complete your actions. Track the progress of the petition here!',
        image: '',
        videoLink: 'Using Timelines and Notifications for Beneficiary',
        nextButtonText: 'Our tour ends here!',
        watchLabel: ''
      }];
    this.getPetitionDrowDownList();
    this.getTaskType();
    this.getVisaType();
    this.getPetitionType();
    this.getIMMClassification();
    this.getIMMCategory();
    this.getCompanyList();
  }

  getPetitionDrowDownList() {
    this.apiService.getPetitionDropdownList(this.beneficiaryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((responseSubTask: any[]) => {
        if (responseSubTask?.length > 0) {
          this.PetitionDrowDownList = responseSubTask;
          this.taskId = responseSubTask[0].taskId;
          // this.taskType = responseSubTask[0].taskTypeCode;
          this.loadtaskTimeLine(this.taskId);
          // this.petitionerService.getTaskTimeline(this.taskId).subscribe((responseSubTask: any) => {
          //   this.stepsData = responseSubTask;
          // });
        }
      });
  }

  getTaskType() {
    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        this.getSubTaskType(this.filter.superTaskType);
        this.cols = this.nonImmigrantTypeCols;
        this.nestedCols = this.nonImmigrantTypeNestedCols;
        // this.getTasks('NEW');
      });
  }

  getCompanyList() {
    this.apiService.getCompanyListAssociatedToBene(this.beneficiaryId).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response?.length > 0) {
        this.companyList = response;
        this.filter.companyId = response[0].code;
      }
    });
  }

  getSubTaskType(superTaskType: string) {
    if (superTaskType === 'NONIMMPET') {
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.visaTypes = response;
          if (this.filter.visaType === '') {
            this.filter.visaType = this.visaTypes[0].code;
          }
          if (this.filter.visaType) {
            this.getPetitionList();
          }
        });
    } else if (superTaskType === 'IMMPET' &&
      (!this.immClassificationTypes || (this.immClassificationTypes && this.immClassificationTypes.length === 0))) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          response = response.filter(ele => ele.code === 'GCEB');
          this.immClassificationTypes = response;
          if (this.filter.immCategoryType) {
            this.getSubTasksTypeForImmigrant();
          }
        });
    }
    this.filter.superTaskType = superTaskType;
    this.cols = superTaskType === 'NONIMMPET' ? this.nonImmigrantTypeCols : this.immigrantTypeCols;
    this.nestedCols = superTaskType === 'NONIMMPET' ? this.nonImmigrantTypeNestedCols : this.immigrantTypeNestedCols;
    this.dashboardWidgetsService.getSubTasksType(superTaskType).subscribe((response: ReferenceLibrary[]) => {
      if (response) {
        this.subTaskType = [];
        new Set(response.map(x => x.id)).forEach(id => {
          this.subTaskType.push(response.find(x => x.id === id));
        });
        // this.filter.subTaskType = this.subTaskType.map(x => x.code);
      } else {
        this.subTaskType = [];
        // this.filter.subTaskType = [];
      }
    });
  }

  getSubTasksTypeForImmigrant() {
    this.subTaskType = [];
    this.dashboardWidgetsService.getSubTasksType(this.filter.superTaskType).subscribe((response: ReferenceLibrary[]) => {
      if (response.length > 0) {
        const obj = response.find(ele => ele.code === 'I140');
        this.subTaskType.push(obj);
      }
    });
  }


  getPetitionList() {
    this.petitionTypes = [];
    switch (this.filter.visaType) {
      case 'H1-B':
        this.createTaskService.getPetitionList(this.filter.visaType)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypes = response.map(x => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group
              };
            });
            if (this.filter.petitionType) {
              this.getSubtaskForNonImmigrant();
            }
          });
        break;
      default:
        this.createTaskService.getPetitionList(this.filter.visaType)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypes = response.map(x => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group
              };
            });
            if (this.filter.petitionType) {
              this.getSubtaskForNonImmigrant();
            }
          });
        break;
    }
  }

  getVisaType() {
    this.referenceLibraryService.getReferenceData('VISATYP').subscribe((response: ReferenceLibrary[]) => {
      this.visaTypes = response;
      // response.forEach(x => {
      //   this.filter.visaTypes.push(x.code);
      // });
    });
  }

  getPetitionType() {
    this.referenceLibraryService.getReferenceData('PETTYP').subscribe((response: ReferenceLibrary[]) => {
      this.petitionTypes = response;
      // response.forEach(x => {
      //   this.filter.petitionType.push(x.code);
      // });
    });
  }

  getIMMClassification() {
    this.referenceLibraryService.getReferenceData('GCEB').subscribe((response: ReferenceLibrary[]) => {
      this.immClassificationTypes = response;
      // response.forEach(x => {
      //   this.filter.immClassificationType.push(x.code);
      // });
    });
  }

  getIMMCategory() {
    this.referenceLibraryService.getReferenceData('GCIMMCATG').subscribe((response: ReferenceLibrary[]) => {
      this.immCategoryTypes = response;
      // response.forEach(x => {
      //   this.filter.immCategoryType.push(x.code);
      // });
    });
  }

  getSubtaskForNonImmigrant() {
    this.subTaskType = [];
    this.dashboardWidgetsService.getSubTasksType(this.filter.superTaskType).subscribe((response: ReferenceLibrary[]) => {
      if (response.length > 0) {
        this.subTaskType = response.filter(ele => ele.code !== 'RFE');
        // if (this.template.taskTypeRefId) {
        //   this.getPlaceHolder();
        // }
      }
    });
  }

  loadTimeLineType(type) {
    this.selectedTimeLineType = type;
  }

  // fetchTaskListForBeneficiary() {
  //   const payload = {
  //     companyId: parseInt(sessionStorage.getItem('companyId')),
  //     endDate: null,
  //     pageNumber: 0,
  //     pageSize: null,
  //     searchText: '',
  //     startDate: null,
  //     visaTypes: [],
  //     petitionTypes: [],
  //     subTaskTypes: [],
  //     superTaskType: "",
  //     taskStatus: [],
  //   };
  //   this.dashboardWidgetsService.getTaskListForBeneficiary(sessionStorage.getItem('beneficiaryId'), payload)
  //     .pipe(take(1))
  //     .subscribe(data => {
  //       if (data) {
  //         this.allTasks = data['superTasks'];
  //         // this.setDisplayData(this.taskList);
  //       }
  //     },
  //       () => { },
  //       () => { this.sortTaskListForBeneficiar() }
  //     );

  //   // this.apiService.getBeneficiarysTasks(this.primaryCompanyId).subscribe((data)=>{
  //   //   this.allTasks = data['data']||[];
  //   // },
  //   // ()=> {},
  //   // ()=>{  this.sortTaskListForBeneficiar() });
  // }

  // sortTaskListForBeneficiar() {
  //   this.taskCapsuleDataSource = [];
  //   this.allTasks.forEach((item, index) => {
  //     this.taskCapsuleDataSource.push({
  //       taskid: item.id,
  //       name: item.visaType,
  //       companyName: item.companyName
  //       // code:PETITION_TYPES[key].code
  //     });
  //   });
  //   // for(let key of PETITION_TYPE_KEYS){
  //   //      this.allTasks.forEach((item,index) => {
  //   //       if (item['taskTypeDetails']['petitionType'] == PETITION_TYPES[key].code) {
  //   //            this.taskCapsuleDataSource.push({
  //   //            taskid: this.allTasks[index]['id'],
  //   //            name:  PETITION_TYPES[key].name,
  //   //            code:PETITION_TYPES[key].code
  //   //          });
  //   //       }
  //   //     });
  //   // }
  //   // call default case .
  //   if (this.taskCapsuleDataSource && this.taskCapsuleDataSource.length > 0) {
  //     this.selectedTaskId = parseInt(this.taskCapsuleDataSource[0].taskid);
  //     // this.selectedTaskId$.next(this.selectedTaskId);//page init with initial task id; IM-3620
  //     this.loadtaskTimeLine(this.selectedTaskId);
  //   }
  // }

  loadtaskTimeLine(taskid) {
    this.selectedTaskId = parseInt(taskid);
    sessionStorage.setItem('taskId', taskid);
    this.utilityService.setTaskId(taskid);
    this.getTaskInfo(parseInt(taskid)); // refactored during IM-3620
  }

  loadBeneficiaryWorkExperience() {
    this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'experience').subscribe(data => {
      this.experienceData = data;
      this.experienceData.forEach(item => {
        const clients = [];
        if (item.clients && item.clients.length > 0) {
          item.clients.forEach(val => {
            clients.push(val.clientName);
          });
        }
        item['clientsList'] = clients.join(',');
        item['startDate'] = (item['startDate']) ? moment(item['startDate']).format('DD-MMM-YYYY') : '';
        item['endDate'] = (item['endDate']) ? moment(item['endDate']).format('DD-MMM-YYYY') : 'Present';
      });
    });
  }

  loadBeneficiaryImmigration() {
    this.apiService.getVisaDetails(this.beneficiaryId).subscribe(res => {
      this.immigrationData = (res['data']) ? res['data'] : [];
    });
  }

  getTaskInfo(id) {
    this.completedPetitions = true;
    const petition = this.PetitionDrowDownList.find(x => x.taskId === id);
    const API = petition.taskTypeCode && petition.taskTypeCode === 'I130' ?
      this.petitionerService.getImmigrationTaskDetails(id) :
      this.petitionerService.getTaskDetails(id);
    API
      .pipe(take(1))
      .subscribe((response) => {
        if (response != null) {
          this.superTaskId = response.superTaskId;
          // this.subTaskList = response.subTaskList;
          // this.subTaskList = response.subTaskList ?
          //   response.subTaskList.filter(x => !['PWD', 'RECEFF', 'PERM', 'I140'].includes(x.taskTypeCode) && (x.taskTypeCode === 'PETITION' && response.superTask.visaType.code === 'H1-B')) : [];
          this.selectedSubTaskType = petition;
          this.completedPetitions = true;
          // this.taskId = this.selectedSubTaskType['id'];
          // if (this.selectedSubTaskType['taskTypeCode'] == 'PETITION') {
          //   this.taskType = 'PETITION';
          // }
          // else if (this.selectedSubTaskType.taskTypeCode == 'RFE') {
          //   this.taskType = 'RFE';
          // }
          // this.petitionerService.getTask(id).subscribe((responseSubTask: any) => {
            let companyId = response.createdByUserType=== "Attorney"?response.attorneyId:response.companyId;
            this.customQuestionnaireService.getCustomStepsDetails(companyId, response.taskTypeId).subscribe((cqData: any) => {
              let customSteps=[];
              cqData.customStepList.forEach(element => {
                if(element.isBeneficiaryEditable || element.isBeneficiaryVisible) customSteps.push(element)
              });
              let customStepList = customSteps.reverse();
              for (var i = 0; i < customStepList.length; i++) {
                let index = response.taskStepDetails.findIndex(x => x.stepName === customStepList[i].previousStepName);
                customStepList[i]['isCustomStep'] = true;
                response.taskStepDetails.splice(index + 1, 0, customStepList[i]);
              }
              this.stepsData = response.taskStepDetails;

            });

          this.petitionId = petition.taskTypeCode && petition.taskTypeCode === 'I130' ? response.caseId : response.petitionId;
          this.filter.companyId = this.companyId = response.companyId;
          this.filter.petitionType = response.taskTypeDetails.petitionType;
          this.superTaskId = response.superTaskId;
          sessionStorage.setItem('petitionId', this.petitionId);
          this.receiptETADateRange = response.receiptETADateRange;
          this.decisionETADateRange = response.decisionETADateRange;
          // this.stepsData = response.taskStepDetails;
          this.noticeNumber = response.noticeNumber;
          this.taskTypeDetails = response.taskTypeDetails;
          this.userName = `${response.beneficiaryDetails.firstName} ${response.beneficiaryDetails.lastName}`;
          this.isOutsideImagility = response.isOutsideImagility ? true : false;
          this.isBeneficiaryVisible = response.isBeneficiaryVisible;
          // });

          // this.petitionerService.getTaskTimeline(this.taskId).subscribe((responseSubTask: any) => {
          //   this.petitionId = responseSubTask.petitionId;
          //   sessionStorage.setItem('petitionId', this.petitionId);
          //   this.receiptETADateRange = responseSubTask.receiptETADateRange;
          //   this.decisionETADateRange = responseSubTask.decisionETADateRange;
          //   this.stepsData = responseSubTask.taskStepDetails;
          //   this.noticeNumber = responseSubTask.noticeNumber;
          //   this.taskTypeDetails = responseSubTask.taskTypeDetails;
          //   this.userName = `${responseSubTask.beneficiaryDetails.firstName} ${responseSubTask.beneficiaryDetails.lastName}`;
          //   this.isOutsideImagility = responseSubTask.isOutsideImagility ? true : false;
          // });
        }
      });
  }

  toggleTimeline(selectedSubTaskType) {
    this.completedPetitions = true;
    this.selectedSubTaskType = selectedSubTaskType;
    // if (selectedSubTaskType['taskTypeCode'] == 'PETITION') {
    //   this.taskType = 'PETITION';
    // }
    // else if (selectedSubTaskType.taskTypeCode == 'RFE') {
    //   this.taskType = 'RFE';
    // }
    const petition = this.PetitionDrowDownList.find(x => x.taskId === this.selectedSubTaskType.taskId);
    const API = petition.taskTypeCode && petition.taskTypeCode === 'I130' ?
      this.petitionerService.getImmigrationTaskDetails(this.selectedSubTaskType.taskId) :
      this.petitionerService.getTaskDetails(this.selectedSubTaskType.taskId);
    API
      .pipe(take(1))
      .subscribe((response: any) => {
        this.petitionId = petition.taskTypeCode && petition.taskTypeCode === 'I130' ? response.caseId : response.petitionId;
        this.companyId = response.companyId;
        this.superTaskId = response.superTaskId;
        sessionStorage.setItem('petitionId', this.petitionId);
        this.receiptETADateRange = response.receiptETADateRange;
        this.decisionETADateRange = response.decisionETADateRange;

        let companyId = response.createdByUserType=== "Attorney"?response.attorneyId:response.companyId;
            this.customQuestionnaireService.getCustomStepsDetails(companyId, response.taskTypeId).subscribe((cqData: any) => {
              let customSteps=[];
              cqData.customStepList.forEach(element => {
                if(element.isBeneficiaryEditable || element.isBeneficiaryVisible) customSteps.push(element)
              });
              let customStepList = customSteps.reverse();
              for (var i = 0; i < customStepList.length; i++) {
                let index = response.taskStepDetails.findIndex(x => x.stepName === customStepList[i].previousStepName);
                customStepList[i]['isCustomStep'] = true;
                response.taskStepDetails.splice(index + 1, 0, customStepList[i]);
              }
              this.stepsData = response.taskStepDetails;

            });

        this.noticeNumber = response.noticeNumber;
        this.taskTypeDetails = response.taskTypeDetails;
        this.userName = `${response.beneficiaryDetails.firstName} ${response.beneficiaryDetails.lastName}`;
        this.isOutsideImagility = response.isOutsideImagility ? true : false;
        this.isBeneficiaryVisible = response.isBeneficiaryVisible;
      });
  }

  // handleFilterChange() {
  //   // this.stepsData = [];
  //   const payload = {
  //     categoryCode: this.filter.immCategoryType,
  //     classificationCode: this.filter.immClassificationType,
  //     companyId: this.filter.companyId,
  //     petitionTypeCode: this.filter.petitionType,
  //     superTaskTypeCode: this.filter.superTaskType,
  //     taskTypeCode: this.filter.subTaskType,
  //     visaTypeCode: this.filter.visaType
  //   };
  //   this.petitionerService.postTaskTimelineCompletedPetitions(this.beneficiaryId, payload).subscribe((responseSubTask: any) => {
  //     if (responseSubTask) {
  //       this.completedPetitions = true;
  //       this.petitionId = responseSubTask.petitionId;
  //       sessionStorage.setItem('petitionId', this.petitionId);
  //       this.receiptETADateRange = responseSubTask.receiptETADateRange;
  //       this.decisionETADateRange = responseSubTask.decisionETADateRange;
  //       this.stepsData = responseSubTask.taskStepDetails;
  //       this.noticeNumber = responseSubTask.noticeNumber;
  //       this.taskTypeDetails = responseSubTask.taskTypeDetails;
  //       this.userName = `${responseSubTask.beneficiaryDetails.firstName} ${responseSubTask.beneficiaryDetails.lastName}`;
  //       this.isOutsideImagility = responseSubTask.isOutsideImagility ? true : false;
  //     } else {
  //       this.completedPetitions = false;
  //     }

  //   });
  //   // this.payload.subTaskTypes = this.filter.subTaskType;
  //   // this.payload.superTaskType = this.filter.superTaskType;
  //   // if (this.payload.superTaskType === 'NONIMMPET') {
  //   //   this.payload.visaTypes = this.filter.visaType;
  //   //   this.payload.petitionTypes = this.filter.petitionType;
  //   //   this.payload.immClassificationTypes = null;
  //   //   this.payload.immCategoryTypes = null;
  //   // } else {
  //   //   this.payload.visaTypes = null;
  //   //   this.payload.petitionTypes = null;
  //   //   this.payload.immClassificationTypes = this.filter.immClassificationType;
  //   //   this.payload.immCategoryTypes = this.filter.immCategoryType;
  //   // }
  //   // this.getTasks('NEW');
  // }

  filterCancel() {
    this.filter.superTaskType = this.payload.superTaskType;
    this.filter.subTaskType = this.payload.subTaskTypes;
    this.filter.visaType = this.payload.visaTypes;
    this.filter.petitionType = this.payload.petitionTypes;
    this.filter.immClassificationType = this.payload.immClassificationTypes;
    this.filter.immCategoryType = this.payload.immCategoryTypes;
  }

  getOptionValue(item) {
    return item.visaTypeName + ' ' + item.petitionTypeName + ' - ' + item.companyName;
  }

  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
