import { Component, Inject,OnInit,ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
  
})
export class InfoModalComponent implements OnInit {
  infoForWhichPage: string;
  constructor(public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA)  public dialogData ) { }

  ngOnInit() {
    this.infoForWhichPage =  this.dialogData.update.pageName;
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  handleClose(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
