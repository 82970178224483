import { Action } from '@ngrx/store';

export enum ActionTypes {
  DIRTY = '[formState] DIRTY',
  CLEAN = '[formState] CLEAN'
}

export class MarkDirtyFormAction implements Action {
  public type = ActionTypes.DIRTY;
  constructor(public payload: { dirty: boolean, message?: string }) { }
}

export class MarkCleanFormAction implements Action {
  public type = ActionTypes.CLEAN;
  constructor(public payload: { dirty: boolean, message?: string }) { } // form id
}

export type Actions = MarkCleanFormAction | MarkDirtyFormAction;
