import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PrimaryData } from 'app-models';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'print-petition',
  templateUrl: './print-petition.component.html',
  styleUrls: ['./print-petition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintPetitionComponent implements OnInit {

   primaryData: PrimaryData;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit() {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  handleDialogClose() {
    this.dynamicDialogRef.close();
  }
}

