import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { I140FileUploadConfiguration, PrimaryData } from 'app-models';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { sync_petitioner_document_code } from '../../../utility/constants/sync-code';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
import { ToastrService } from 'ngx-toastr';
import { SyncDetailsService } from '../../../utility/services/sync-details.service';
import { PetitionerDetailsService } from '../../petitioner-details/petitioner-details.service';
import { VisaExtendedState } from 'src/app/modules/visa-base/utility/interfaces/visa-extended-state';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs/internal/Subject';
import { combineLatest } from 'rxjs';
import { getStepDetails, updateStepStatus, loadSubTaskDetails, getSubTaskDetails, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { HighsalaryRemunerationService } from './highsalary-remuneration.service';
import * as DirtyForm from '../../../../../app-state/actions/dirty-form.actions';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
@Component({
  selector: 'app-highsalary-remuneration',
  templateUrl: './highsalary-remuneration.component.html',
  styleUrls: ['./highsalary-remuneration.component.scss']
})
export class HighsalaryRemunerationComponent implements OnInit {
  @ViewChild("highsalaryimageupload") public highsalaryimageupload: UploadImageDialogComponent;
@Output() @ViewChild("highsalarykendoeditor") public highsalarykendoeditor: EditorComponent;

  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;
  fileUploadConfigurations: I140FileUploadConfiguration;
  // public dynamicDialogConfig: DynamicDialogConfig;
  //public options = FroalaEditorOptions.EditorOptions;
  formAuthorPublication: FormGroup;
  authorAndPublicationList = [];
  isEdit: boolean = false;
  btnText: string = 'Add';
  stepDetails: TaskStep;
  task: SubTaskDetails;
  id
  userType: string;
  disabledControls: boolean;
  extrAblDocEvdCatg = [];
  template: {
    body: string,
  }
  stepStatus;
  showMarkComplete = false;
  observableSubscription$ = new Subject();
  markStep: string;
  formDirtyFromDifferentControls = false;
  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //       // this.isFormValidCheck();
  //     }
  //   }
  // };
  inputData: PrimaryData;

  constructor(private store: Store<VisaExtendedState>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private highSalaryRemunerationService: HighsalaryRemunerationService,
    public dialogService: DialogService,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadService: I140FileUploadService,
    private syncDetailsService: SyncDetailsService,
    private fb: FormBuilder,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.inputData = this.dynamicDialogConfig.data;
    this.disabledControls = false;
    this.template = {
      body: ''
    }
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    //froalaService.insertPageBreak();
    this.loadAdditionalInfo();
    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        //console.log(this.task);
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;
        // if(this.task.extrAblDocEvdList[this.task.extrAblDocEvdList.length - 1]['code'] == "EXHBTDOC"){
        //   this.showMarkComplete = true;
        // }
        let list = this.task.extrAblDocEvdList.sort((a, b) => (a['id'] > b['id']) ? 1 : -1)
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;
        if (list[list.length - 1]['code'] == "HIGHSALARY") {
          this.showMarkComplete = true;
        }
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        this.stepDetails = response[1];
        this.fileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/HIREMNRTNDOC/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/HIREMNRTNDOC/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'HIREMNRTNDOC'
        });
        this.toggleControlsStatus();
      });
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit '
    } else {
      this.markStep = 'MARK STEP COMPLETE';
    }
  }
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/HIREMNRTNDOC/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/HIREMNRTNDOC/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'HIREMNRTNDOC'
    });
  }

  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }
  highsalaryimageuploaddialog() {
    this.highsalaryimageupload.open();
  }
  Save() {
    const paylod = {
      "additionalText": this.template.body,
      "infoName": null,
      "infoType": "HIGHSALARY",
      "isDel": 0
    }

    this.highSalaryRemunerationService.saveAdditionalInfo(this.dynamicDialogConfig.data, paylod).subscribe(res => {
      if (res && res['status'] === 200) {
        this.toastr.success(res['message'], 'Success');
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.updateStepStatus('INPROGRESS');
        }
      }
    })
  }

  loadAdditionalInfo() {
    this.highSalaryRemunerationService.getAdditionalInfo(this.dynamicDialogConfig.data, 'HIGHSALARY').subscribe(res => {
      this.template.body = res.additionalText
    })
  }

  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }

    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  public highsalaryeditorValueChange(value: string): void {
    this.template.body = value;
    this.setFormDirty();
  }
}
