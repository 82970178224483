
<mat-card class="mat-card h-100">
  <mat-card-header class="border-bottom">
    <mat-card-title class="title mb-0">I-129 processing Time</mat-card-title>
  </mat-card-header>
  <mat-card-content class="notification-mat-card-content">

    <mat-form-field>
      <p class="label-cls">Select Service Center</p>
      <mat-select [(ngModel)]="selectedserviceCenter"
      (selectionChange)="loadI29ForServiceCenterName($event.value)">
        <mat-option *ngFor="let name of serviceCenterNamelist" [value]="name">
          {{name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="table-header row  mt-2">
      <div class="col-3 col-md-3 notify-header  my-auto pr-0">
        Est.Time Range
      </div>
      <div class="col-6 col-md-6  notify-header  my-auto">
        Form type
      </div>
      <div class="col-3 col-md-3 text-left notify-header  my-auto">
        Receipt Date
      </div>
    </div>
    <div class="container-scroll">
    <div class="container p-0">
      <ng-container *ngIf= "I29ProcessingTimeDisplayRecords.length>0 else nodata">

        <div class=" row record-heightmt-2 pt-1 pb-1 col-12 pl-0 pr-0"
        *ngFor="let item of  this.I29ProcessingTimeDisplayRecords">

          <div class="col-3 col-md-3 pl-0">
              <div class="date-range mt-1">
                <div class="pt-1">
                  {{ splitDateRange(item.estimatedTimeRange,0)+"to" }}
                </div>
                <div class="pt-1">
                  {{ splitDateRange(item.estimatedTimeRange,1) }}
                </div>
              </div>
          </div>

          <div class="col-6 col-md-6">
            <p>{{item.text}}</p>
          </div>

          <div class="col-3 col-md-3 text-right">
            <div class="box">
              <div class="box-part1">{{splitDate(item.receiptDateForEnquiry,0)}}</div>
              <div class="box-part2">{{splitDate(item.receiptDateForEnquiry,1)}}</div>
            </div>
          </div>
      </div>
    </ng-container>
    <ng-template #nodata>
      <div class="nodata"> No Records to Show.</div>

    </ng-template>
  </div><!---container end-->
  </div><!----container-scroll-->
  </mat-card-content>

</mat-card>
