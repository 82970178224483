import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-custom-steps',
  templateUrl: './custom-steps.component.html',
  styleUrls: ['./custom-steps.component.scss']
})
export class CustomStepsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  customTriggeredStatus: boolean = false;

  stepName:String;
  stepTaskId:number;
  stepStepId:number;
  isViewMode:boolean;

  ngOnInit(): void {
    console.log('this.data.stepName',this.data.stepName)
    console.log('this.data.taskId',this.data.taskId)
    console.log('this.data.stepId',this.data.stepId)
    console.log('this.data.stepId',this.data.isViewMode)

    this.stepName = this.data.stepName
    this.stepTaskId = this.data.taskId
    this.stepStepId =  this.data.stepId
    this.isViewMode = this.data.isViewMode
  }
  
}
