<div class="container-fluid meeting">
    <div class="nav home icn" (click)="backtoDashboard()">
        Home >{{breadcrumbTitle}}
    </div>
    <div (click)="backtoCalendar()" class="back icn">
        <i class="fa fa-angle-left " style="margin-left: 0px !important;"></i><span>Back to {{backToTitle}}</span>
    </div>
    <div class="tab-container">
        <div  *ngIf="this.showTabContainer" class="tab-view mb-2">
            <p-tabView [activeIndex]="index" (onChange)="handleChange($event)">
                <p-tabPanel header="Meeting">
                    <!-- <ng-template pTemplate="content"> -->
                    <app-meeting [obj]="meetingObj">
                    </app-meeting>
                    <!-- </ng-template> -->
                </p-tabPanel>
                <p-tabPanel header="Task">
                    <!-- <ng-template pTemplate="content"> -->
                    <app-task [obj]="taskObj">
                    </app-task>
                    <!-- </ng-template> -->
                </p-tabPanel>
                <p-tabPanel header="Event" aria-hidden="!(history.state.calendarItem == 'event')">
                    <!-- <ng-template pTemplate="content"> -->
                        <app-event [obj]="eventObj">
                        </app-event>
                    <!-- </ng-template> -->
                    
                </p-tabPanel>
                <p-tabPanel header="Court date" *ngIf="userType !== 'Petitioner'">
                    <app-courtdate [obj]="courtObj"></app-courtdate>
                </p-tabPanel>
                <p-tabPanel header="General Reminder">
                    <!-- <ng-template pTemplate="content"> -->
                        <app-reminder [obj]="reminderObj"></app-reminder>
                    <!-- </ng-template> -->
                    
                </p-tabPanel>
            </p-tabView>
        </div>
        <div  *ngIf=this.showMeetingToSchedule class="tab-view mb-2">
        <app-meeting [obj]="meetingObj">
        </app-meeting>
        </div>
    </div>
</div>