import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { updateStepStatus, updateStepStatusToInitialState } from 'visa-store';
import * as errorUtils from 'error-message-utility';
import { PrimaryData, TaskStep } from 'app-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CacheCountryListService } from 'cache-country-list';
import { take } from 'rxjs/operators';
import { H1BEERPayload, ProvideEerService } from '../provide-eer/provide-eer.service';


@Component({
  selector: 'app-supervisor-details',
  templateUrl: './supervisor-details.component.html',
  styleUrls: ['./supervisor-details.component.scss']
})
export class SupervisorDetailsComponent implements OnInit, OnChanges, OnDestroy {

  supervisorForm: FormGroup;

  disabledControls: boolean;
  @Input() stepDetails: TaskStep;
  @Input() primaryData: PrimaryData;
  @Input() payload: H1BEERPayload;
  @Input() countryList: any[];
  @Input() workLocationTypeCode: string;
  typeOfButton: string;
  @Input()
  simplifyH1b;
  wizardTID:string=';'

  isSimplifyH1b: boolean;

  observableSubscription$ = new Subject();

  constructor(
    private supervisorDetailsBuilder: FormBuilder,
    private store: Store<any>,
    private stepStatusUpdatePayloadConstruction: StepStatusUpdatePayloadService,
    private provideEerService: ProvideEerService
  ) {
    this.disabledControls = false;
    this.countryList = [];
  }

  ngOnInit(): void {
    // this.store.pipe(select(getStepStatusUpdate))
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((response: ImagilityBaseResponse) => {
    //     if (response && response.status === 200) {
    //       this.toastr.success(response.message);
    //     }
    //   });
    this.isSimplifyH1b = this.simplifyH1b;
    this.supervisorForm.valueChanges
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(() => {
        if (this.supervisorForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
    // this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((data: TaskStep) => {
    //     this.stepDetails = data;
    //     this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
    //     this.toggleControlsStatus();
    //   });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.wizardTID =sessionStorage.getItem('wizardTID');
    if(this.wizardTID){
      this.toggleControlsStatus();
    }
    else if (changes.stepDetails) {
      this.typeOfButton =
        this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ?
          'MARK_COMPLETE' : 'MARK_SUBMIT';
      this.toggleControlsStatus();
    }
    if (changes.payload) {
      this.setSupervisorDetails();
    }
  }

  setSupervisorDetails() {
    this.supervisorForm.reset({
      petitionerFirstName: this.payload.petitionerFirstName,
      petitionerLastName: this.payload.petitionerLastName,
      petitionerMiddleName: this.payload.petitionerMiddleName,
      petitionerSupervisorRole: this.payload.petitionerSupervisorRole,
      petitionerPhoneCode: this.payload.petitionerPhoneCode,
      petitionerPhoneNo: this.payload.petitionerPhoneNo,
      petitionerEmail: this.payload.petitionerEmail,
      clientSupervisorFirstName: this.payload.clientSupervisorFirstName,
      clientSupervisorLastName: this.payload.clientSupervisorLastName,
      clientSupervisorMiddleName: this.payload.clientSupervisorMiddleName,
      clientSupervisorRole: this.payload.clientSupervisorRole,
      clientSupervisorPhoneCode: this.payload.clientSupervisorPhoneCode,
      clientSupervisorPhoneNo: this.payload.clientSupervisorPhoneNo,
      clientSuperviorEmail: this.payload.clientSuperviorEmail
    });
  }

  postSupervisor() {
    this.payload = {
      ...this.payload,
      ...this.supervisorForm.value
    };
    this.provideEerService.postSupervisorDetails(this.primaryData.visatype,
      this.primaryData.petitionType, this.primaryData.petitionId,
      this.primaryData.superTaskId, this.primaryData.subTaskId, this.payload)
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
          if(!this.wizardTID){
            if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
              this.updateStepStatus('INPROGRESS');
            }
          }
          this.provideEerService.getSupervisorDetailsSubject.next();
        }
      });
  }


  // handleCancel() {
  //   this.provideEerService.getSupervisorDetailsSubject.next();
  // }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    if(!this.wizardTID){
      this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    }
    if (!this.supervisorForm) {
      if (!this.workLocationTypeCode || (this.workLocationTypeCode && this.workLocationTypeCode === 'INHSE')) {
        this.supervisorForm = this.supervisorDetailsBuilder.group({
          petitionerFirstName: [null, Validators.required],
          petitionerLastName: [null, Validators.required],
          petitionerMiddleName: [null],
          petitionerSupervisorRole: [null, Validators.required],
          petitionerPhoneCode: [null, Validators.required],
          petitionerPhoneNo: [null, [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/),
          Validators.minLength(10), Validators.maxLength(10)]],
          petitionerEmail: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')]],
          clientSupervisorFirstName: [null],
          clientSupervisorLastName: [null],
          clientSupervisorMiddleName: [null],
          clientSupervisorRole: [null],
          clientSupervisorPhoneCode: [null],
          clientSupervisorPhoneNo: [null, [Validators.pattern(/^-?(0|[0-9]\d*)?$/),
          Validators.minLength(10), Validators.maxLength(10)]],
          clientSuperviorEmail: [null, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')]
        });
      } else {
        this.supervisorForm = this.supervisorDetailsBuilder.group({
          petitionerFirstName: [null, Validators.required],
          petitionerLastName: [null, Validators.required],
          petitionerMiddleName: [null],
          petitionerSupervisorRole: [null, Validators.required],
          petitionerPhoneCode: [null, Validators.required],
          petitionerPhoneNo: [null, [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/),
          Validators.minLength(10), Validators.maxLength(10)]],
          petitionerEmail: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')]],
          clientSupervisorFirstName: [null, Validators.required],
          clientSupervisorLastName: [null, Validators.required],
          clientSupervisorMiddleName: [null],
          clientSupervisorRole: [null, Validators.required],
          clientSupervisorPhoneCode: [null, Validators.required],
          clientSupervisorPhoneNo: [null, [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/),
          Validators.minLength(10), Validators.maxLength(10)]],
          clientSuperviorEmail: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')]]
        });
      }
    }
    if (this.disabledControls) {
      this.supervisorForm.disable();
    } else {
      this.supervisorForm.enable();
    }
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadConstruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }


  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
