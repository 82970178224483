import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";

@Component({
  selector: "app-clients-tab",
  templateUrl: "./clients-tab.component.html",
  styleUrls: ["./clients-tab.component.scss"],
})
export class ClientsTabComponent implements OnInit {
  public petitionerId: any;
  companyId: number = 0;
  pageIndex = 1; // default
  pageSize = 10;
  public getAllTabsData: any = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private billingInvoiceService: BillingInvoiceService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((data) => {
      this.petitionerId = data;
      // console.log(data, "data>>>@@@@@@");
    });
  }
  ngOnInit(): void {
    this.companyId = parseInt(sessionStorage.getItem("companyId"));
    this.getClientPetitionerDetails();
  }

  routeToGenerateInvoice() {
    this.router.navigate([
      "attorney-landing/attorney-invoice/client-generate-invoice/" +
        this.petitionerId.id,
    ]);
  }
  getClientPetitionerDetails() {
    let payload = {
      companyId: this.companyId,
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      status: "all",
    };
    this.billingInvoiceService
      .getClientInvoiceList(this.petitionerId.id, "list", payload)
      .subscribe((data: any) => {
        this.getAllTabsData = data.data.clientInvoiceListResponseDto;
        // console.log(this.getAllTabsData, "data>>>>>");
      });
  }
}
