import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';


@Injectable({ providedIn: 'root' })
export class CustomStepsService {
    private customStepsSubject = new Subject<any>();
    customStepsObservable = this.customStepsSubject.asObservable();
    constructor(
        private httpClient: HttpClient
    ) { }

    getStepDetails(visaType, petitionId, stepId) {
        return this.httpClient.get(`/vd/${visaType}/PETITION/${petitionId}/JSONInfo?taskStepId=${stepId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }
    postStepDetails(visaType, petitionId, stepId, payload) {
        return this.httpClient.post(`/vd/${visaType}/PETITION/${petitionId}/JSONInfo?taskStepId=${stepId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }
    setStepsObservable(data){
        this.customStepsSubject.next(data);
    }

    syncStep(visaType, petitionId, stepId){
        return this.httpClient.post(`/vd/${visaType}/PETITION/${petitionId}/JSONInfo/${stepId}/sync`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response;
                }
            })
        );
    }
}