import { Action } from '@ngrx/store';

export enum I9ActionTypes {
  SETBENEINFO = '[I9SECTION1] SETBENEINFO',
  SETFORMINFO = '[I9SECTION1] SETFORMINFO',
  SETPREPAREINFO =  '[I9SECTION1] SETPREPAREINFO',
  SETWORKAUTHPREPARERINFO =  '[I9SECTION1] SETWORKAUTHPREPARERINFO',
  SETSECTIONONE = '[I9SECTION1] SETSECTIONONE',
  GETSECTIONONE = '[I9SECTION1] GETSECTIONONE'
}

export class setI9SectionOneInfoAction implements Action {
  public type = I9ActionTypes.SETSECTIONONE;
  constructor(public payload) { }
}

export class getI9SectionOneInfoAction implements Action {
  public type = I9ActionTypes.GETSECTIONONE;
  constructor(public payload: { i9FormId: number, companyId?: number }) { }
}

export class setBeneInfoAction implements Action {
    public type = I9ActionTypes.SETBENEINFO;
    constructor(public payload) { }
}

export class setFormInfoAction implements Action {
    public type = I9ActionTypes.SETFORMINFO;
    constructor(public payload) { }
}

export class setPreparerInfoAction implements Action {
    public type = I9ActionTypes.SETPREPAREINFO;
    constructor(public payload) { }
}

export class setWorkAuthPreparerInfoAction implements Action {
  public type = I9ActionTypes.SETWORKAUTHPREPARERINFO;
  constructor(public payload) { } // form id
}



export type I9Actions = setI9SectionOneInfoAction | getI9SectionOneInfoAction | setBeneInfoAction | setFormInfoAction | setPreparerInfoAction | setWorkAuthPreparerInfoAction;
