import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ImagilityBaseResponse } from 'app-models';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AddEducationDetailsService {
  public countryDataSource = new BehaviorSubject<any>({ listCountries: [] });
  public deletedfile$ = new BehaviorSubject<any>({});
  public uploaded$ = new BehaviorSubject<any>({});
  public dependentProfileSubject = new BehaviorSubject(<any>{});
  public onLifeStorySubTabs = new Subject();
  public isExpired = false;
  public isEducationDetailsChanged = new Subject<any>();

  selfBeneficiaryDetailChange = new Subject();
  newSubDependentCreated = new Subject<{ beneficiaryFamilyInfo: any, selectedSubTabId: number }>();


  constructor(private http: HttpClient) { }


  getStates(countryCode: string) {
    return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  // getEducationDetails(petitionId: number, superTaskId: number, taskId: number, familyId?: number) {
  getEducationDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number) {
    // const url = `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=true`;
    const url = `pika`;
    return this.http.get(url).pipe(
      map((data) => data)
    );
  }

  saveEducatinDegreeDetails(petitionId: number, payload: any, familyId?: number) {
    const url = `/petition/saveEducation/${petitionId}`;
    return this.http.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  deleteEducatinDegreeDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, educationId: number) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  checkBeneEditPermission() {
    const permission = JSON.parse(sessionStorage.getItem('currentUser'));
    return permission ? permission['permissions'].length > 0 && permission['permissions'].find(x => x === 'EDIT_BENEFICIARY') ? true : false : false;
    // return permission['permissions'].length>0 && permission['permissions'].find(x=> x === 'DELETE_BENEFICIARY')? true : false;
  }

  checkValidity() {
    return this.isExpired;
  }

  educationDetailsChanged(data) {
    this.isEducationDetailsChanged.next(data);
  }

  changeDependentProfileID(dependentId: number, beneficiaryType: string) {
    this.dependentProfileSubject.next({ dependentId: dependentId, beneficiaryType: beneficiaryType });
  }

  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  

}
