<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-8 step-title p-0">
      <h5 class="mb-0">{{ title }}</h5>
      <p class="mb-0" style="font-size:14px">{{ subTitle }}</p>
    </div>
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <lib-step-status-update-component-library
        [visaType]="primaryData.caseType != 'I130' ? primaryData.visatype : primaryData.caseType" 
        [stepDetails]="stepDetails" type="EDIT" class="float-right" [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-step-status-update-component-library>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':primaryData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="primaryData.caseType" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->

      <lib-assigning-task-library [visaType]="primaryData.caseType" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)" [isViewMode]="primaryData['isViewMode']" [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-assigning-task-library>

    </div>
  </div>
  <p-divider></p-divider>
  <div class="main-body footer-adjust" #mainBody>
    <lib-last-update-library *ngIf="stepDetails && primaryData && sectionCode" [inputDetails]="stepDetails"
      [primaryData]="primaryData" [sectionCode]="sectionCode">
    </lib-last-update-library>
    <div class="row mt-2">
      <div class="card card-min no-border">
        <div class="im-card-tab-menu">
          <div class="im-list-group leftSidebar ml-0">
            <a class="im-list-group-item" *ngFor="let item of tabList"
              (click)="handleLeftPanelSelection(item.code, item.isLastTab)"
              [ngClass]="{'activeItem': selectedItem === item.code , 'inActiveItem':  selectedItem !== item.code }">
              <span>{{item.name}}</span></a>
          </div>
        </div>
      </div>
      <div class="col-md pl-0 pr-0 ">
        <div [ngSwitch]="selectedItem">
          <div *ngSwitchCase="'PERSONAL_DETAILS'">
            <app-sponser-personal-detail [primaryData]="primaryData" [userId]="userId" [type]="type">
            </app-sponser-personal-detail>
          </div>
          <div *ngSwitchCase="'ADDR_HISTORY'">
            <app-address-history (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="userId">
            </app-address-history>
          </div>
          <div *ngSwitchCase="'MARITAL_HISTORY'">
            <app-marital-history (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="userId">
            </app-marital-history>
          </div>
          <div *ngSwitchCase="'PARENT_INFO'">
            <app-parent-information [primaryData]="primaryData"></app-parent-information>
          </div>
          <div *ngSwitchCase="'EMP_INFO'">
            <app-employment-history [primaryData]="primaryData" [userId]="userId"></app-employment-history>
          </div>
          <div *ngSwitchCase="'ADD_INFO'">
            <app-additional-information [primaryData]="primaryData"></app-additional-information>
          </div>
          <div *ngSwitchCase="'FAMILY_INFO'">
            <app-family-information (scrollTop)="onScrollTop()" [primaryData]="primaryData" [userId]="userId">
            </app-family-information>
          </div>
          <div *ngSwitchCase="'DOC_INFO'">
            <app-required-documents [primaryData]="primaryData" [userId]="userId"></app-required-documents>
          </div>
          <div *ngSwitchCase="'IMM_DOC_INFO'">
            <vd-family-immigrant-documents [primaryData]="primaryData" [userId]="userId" [isPrimaryApplicant]="false"
              [type]="type"></vd-family-immigrant-documents>
          </div>
          <div *ngSwitchCase="'IMM_INFO'">
            <app-immigration-history [primaryData]="primaryData" [userId]="userId" [type]="type">
            </app-immigration-history>
          </div>
          <div *ngSwitchCase="'OTHER_INFO'">
            <app-other-information [primaryData]="primaryData"></app-other-information>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-divider></p-divider>
  <!-- pending UI verification -->
 <div class="col-12 px-0 pt-3 mt-n3">
  <lib-custom-questionnaire [stepId]="primaryData.stepId" [taskId]="primaryData.taskId"
  [viewQuestions]="false" [showSaveBtn]="true" [loadedIn]="stepDetails.stepName"
  [isSection]="false"></lib-custom-questionnaire>
 </div>
 <p-divider></p-divider>

  <div class="row pt-3" *ngIf="isLastTab">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <gc-status-update [visaType]="primaryData.caseType" [stepDetails]="stepDetails" [type]="typeOfButton">
      </gc-status-update>
    </div>
  </div>
  <div class="row pt-2" *ngIf="!isLastTab">
    <span style="color: #6c6666; font-size: 14px;">
      <i class="pi pi-info-circle pr-2" style="color: #19A0DA;"></i>This step can be marked complete when you
      reach the last tab, i.e., {{lastTabObj?.name}}
    </span>
  </div>
</div>
