<div class="container-fluid family-information-container">
  <form [formGroup]="familyInformationForm">
    <div>
      <div class="row d-flex align-items-center">
        <div class="col-md-12 step-title">
          <h5 class="mb-0">Information About Family</h5>
        </div>
      </div>
      <p-divider></p-divider>
      <div>
        <div class="row mt-2">
          <div class="col-4 form-group processing-dropdown">
            <p class="label-cls required">Select Relationship</p>
            <p-dropdown appendTo="body" [options]="familyTypeArr" [filter]="false" [disabled]="disabledControls"
              formControlName="relationshipCode" optionLabel="name" optionValue="code" optionDisabled="inActive"
              (onChange)="handleFamilyTypeChange($event.value)" placeholder="select"> </p-dropdown>
            <span class="invalid-message" *ngIf="
                      (familyInformationForm.get('relationshipCode').touched) &&
                      familyInformationForm.get('relationshipCode').errors?.required
                    ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls required">Title</p>
            <p-dropdown appendTo="body" [options]="titleListArr" formControlName="title" optionLabel="name" optionValue="code" placeholder="select" class="w-100"> </p-dropdown>
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('title').touched) &&
                    familyInformationForm.get('title').errors?.required
                  ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="col-4">
            <p class="label-cls required">First Name</p>
            <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (familyInformationForm.get('firstName').touched) &&
                      familyInformationForm.get('firstName').errors?.required
                  }" formControlName="firstName" placeholder="Enter your First Name" />
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('firstName').touched) &&
                    familyInformationForm.get('firstName').errors?.required
                  ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('firstName').touched) &&
                    familyInformationForm.get('firstName').errors?.pattern
                  ">
              {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
            </span>
          </div>
          <div class="col-4">
            <p class="label-cls">Middle Name</p>
            <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (familyInformationForm.get('middleName').touched) &&
                      familyInformationForm.get('middleName').errors?.pattern
                  }" formControlName="middleName" placeholder="Enter your Middle Name" />
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('middleName').touched) &&
                    familyInformationForm.get('middleName').errors?.pattern
                  ">
              {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
            </span>
          </div>
          <div class="col-4 mt-2">
            <p class="label-cls required">Last Name</p>
            <input type="text" class="im-input" [ngClass]="{
                    'im-input-error':
                      (familyInformationForm.get('lastName').touched) &&
                      familyInformationForm.get('lastName').errors?.required
                  }" formControlName="lastName" placeholder="Enter your Last Name" />
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('lastName').touched) &&
                    familyInformationForm.get('lastName').errors?.required
                  ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
            <span class="invalid-message" *ngIf="
                    (familyInformationForm.get('lastName').touched) &&
                    familyInformationForm.get('lastName').errors?.pattern
                  ">
              {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
            </span>
          </div>
        </div>
        <div *ngIf="familyInformationForm.get('relationshipCode').valid" class="row mt-3">
          <div class="col-12 form-group mb-0">
            <div class="p-field-checkbox mb-0">
              <p-checkbox id="otherBirthName" formControlName="hasOtherName" [disabled]="disabledControls"
                binary="true">
              </p-checkbox>
              <label class="label-cls">Does your {{selectedRelationShipType?.name}} have any other birth name?</label>
            </div>
          </div>
        </div>
        <div class="my-3" *ngIf="familyInformationForm.get('hasOtherName').value" formArrayName="otherNameDTO">
          <div *ngFor="let item of familyInformationForm.get('otherNameDTO')['controls']; let i= index">
            <div [formGroupName]="i" class="row">
              <div class="col-4">
                <p class="label-cls required">Title</p>
                <select class="im-input col-md" formControlName="title">
                  <option *ngFor="let title of titleListArr" [value]="title.name">
                    {{ title.name }}
                  </option>
                </select>
                <span class="invalid-message" *ngIf="
                         (item.get('title').touched) &&
                         item.errors?.otherNameDTOTitleFieldIsRequired
                       ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
              </div>
              <div class="col-4">
                <p class="label-cls required">First Name</p>
                <input type="text" class="im-input" [ngClass]="{
                         'im-input-error':
                           (item.get('firstName').touched) &&
                           (item.errors?.otherNameDTOFirstNameFieldIsRequired || item.errors?.otherNameDTOFirstNameFieldIsPattern)
                       }" formControlName="firstName" placeholder="Enter your First Name" />
                <span class="invalid-message" *ngIf="
                         (item.get('firstName').touched) &&
                         item.errors?.otherNameDTOFirstNameFieldIsRequired
                       ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
                <span class="invalid-message" *ngIf="
                         (item.get('firstName').touched) &&
                         item.errors?.otherNameDTOFirstNameFieldIsPattern
                       ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>
              <div class="col-4">
                <p class="label-cls">Middle Name</p>
                <input type="text" class="im-input" [ngClass]="{
                         'im-input-error':
                           (item.get('middleName').touched) &&
                           item.errors?.otherNameDTOMiddleNameFieldIsPattern
                       }" formControlName="middleName" placeholder="Enter your Middle Name" />
                <span class="invalid-message" *ngIf="
                         (item.get('middleName').touched) &&
                         item.errors?.otherNameDTOMiddleNameFieldIsPattern
                       ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>
              <div class="col-4 mt-2">
                <p class="label-cls required">Last Name</p>
                <input type="text" class="im-input" [ngClass]="{
                         'im-input-error':
                           (item.get('lastName').touched) &&
                           (item.get('lastName').errors?.otherNameDTOLastNameFieldIsRequired || item.errors?.otherNameDTOLastNameFieldIsPattern)
                       }" formControlName="lastName" placeholder="Enter your Last Name" />
                <span class="invalid-message" *ngIf="
                                (item.get('lastName').touched) &&
                                item.errors?.otherNameDTOLastNameFieldIsRequired
                              ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
                <span class="invalid-message" *ngIf="
                                (item.get('lastName').touched) &&
                                item.errors?.otherNameDTOLastNameFieldIsPattern
                              ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 form-group input-disabled" *ngIf='!(primaryData.immigrationClassification && this.primaryData.immigrationClassification === "ONLINEIMMVISAREG")'>
            <p class="label-cls">Gender</p>
            <select class="im-input col-md" formControlName="gender">
              <option *ngFor="let gender of genderListArr" [value]="gender.code">
                {{ gender.name }}
              </option>
            </select>
            <span class="invalid-message" *ngIf="
                      (familyInformationForm.get('gender').touched) &&
                      familyInformationForm.get('gender').errors?.required
                    ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls">Date of Birth </label>
            <p-calendar class="im-input-calendar mw-100" styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
              [showIcon]="true" [disabled]="disabledControls" formControlName="dob"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050" [showButtonBar]="true">
            </p-calendar>
            <div class="invalid-message"
              *ngIf="familyInformationForm.get('dob').touched && familyInformationForm.get('dob').errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </div>
          </div>
        </div>
        <div class="row col-md-12">
          <label class="text-bold">Place of Birth</label>
        </div>
        <div class="row w-100">
          <div class="col-md-4 form-group processing-dropdown">
            <label class="label-cls"> Country of Birth</label>
            <p-dropdown appendTo="body" [options]="countryList" [filter]="false" [disabled]="disabledControls"
              formControlName="birthCountryCode" optionLabel="countryName" optionValue="countryCode"
              (onChange)="handleCountryChange($event.value)" placeholder="select">
            </p-dropdown>
          </div>
          <div class="col-md-4 form-group processing-dropdown">
            <label class="label-cls"> State of Birth</label>
            <p-dropdown appendTo="body" [options]="listBirthStates"
              *ngIf="listBirthStates && listBirthStates.length > 0" formControlName="birthStateCode" name="country"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
            </p-dropdown>
            <input class="im-input mw-100" [readonly]="disabledControls"
              *ngIf="!listBirthStates || (listBirthStates && listBirthStates.length === 0)" id="clientName"
              formControlName="birthState" type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-4">
            <label class="label-cls">City of Birth</label>
            <input type="text" class="im-input" pInputText [ngClass]="{
                          'im-input-error':
                            (familyInformationForm.get('birthCity').touched) &&
                            familyInformationForm.get('birthCity').errors?.pattern
                        }" formControlName="birthCity" />
            <span class="invalid-message" *ngIf="
                          (familyInformationForm.get('birthCity').touched) &&
                          familyInformationForm.get('birthCity').errors?.pattern
                        ">
              {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
            </span>
          </div>
          <div class="col-6 mt-2">
            <label class="label-cls"> AddressLine 1 </label>
            <input class="im-input mw-100" id="inAddressLine1" [class.disabled]="disabledControls" formControlName="addressLine1"
            type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-6 mt-2">
            <label class="label-cls"> AddressLine 2 </label>
            <input class="im-input mw-100" id="inAddressLine2" [class.disabled]="disabledControls" formControlName="addressLine2"
            type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-6">
            <label class="label-cls">Current Country of Residence </label>
            <p-dropdown appendTo="body" [options]="countryList" [filter]="false" [disabled]="disabledControls"
              formControlName="countryOfResidence" (onChange)="handleCountryChangeLiving($event.value)" optionLabel="countryName" optionValue="countryCode" placeholder="select">
            </p-dropdown>
          </div>
          <div class="col-6">
            <label class="label-cls">Current State of Residence </label>
            <p-dropdown appendTo="body" [options]="listLivingStates"
              *ngIf="listLivingStates && listLivingStates.length > 0" formControlName="livingStateCode" name="country"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
            </p-dropdown>
            <input class="im-input mw-100" [readonly]="disabledControls"
              *ngIf="!listLivingStates || (listLivingStates && listLivingStates.length === 0)" id="listLivingStates"
              formControlName="livingStateName" type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-6 mt-2" *ngIf='!(primaryData.immigrationClassification && this.primaryData.immigrationClassification === "ONLINEIMMVISAREG")'>
            <label class="label-cls"> County of Residence </label>
            <input class="im-input mw-100" id="inCareOfName" [class.disabled]="disabledControls" formControlName="county"
            type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-6 mt-2">
            <label class="label-cls">Current city of Residence </label>
            <input type="text" class="im-input" pInputText formControlName="cityOfResidence" />
          </div>
          <div class="col-4 mt-2">
            <label class="label-cls"> Zip / Postal Code</label>
            <input class="im-input mw-100" id="zip" [class.disabled]="disabledControls" formControlName="zipCode"
            type="text" pInputText placeholder="Enter" />
          </div>
          <div class="col-4 mt-2">
            <label class="label-cls">A-Number (if any)</label>
            <input type="text" class="im-input" pInputText formControlName="alienRegNo" />
          </div>
          <div class="col-12 mt-2" *ngIf="familyInformationForm.get('relationshipCode').value === 'CHILD'">
            <label class="label-cls">What is your child's relationship to you? (for example, biological child, stepchild, legally adopted child)</label>
            <input type="text" class="im-input" pInputText formControlName="childSpecificRelationship" />
          </div>
        </div>
      </div>
      <div class="col-md-12 p-0 ">
        <div class="row mb-4 mt-3">
          <div class="col-12">
            <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-outline-primary mr-3"
              [ngClass]="{'disabled': disabledControls || !familyInformationForm.valid }"
              [disabled]="disabledControls || !familyInformationForm.valid" (click)="saveAddress()">ADD</button>
            <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-light"
              (click)="cancelForm()">CANCEL</button>
          </div>
        </div>
        <div class="row w-100 mt-4 mb-5">
          <div class="container-fluid">
            <h5 class="sub-title text-primary">Added Details</h5>
            <p-table tableStyleClass="i130-address-table" [value]="familyList" *ngIf="familyList.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th class="col-4 px-0">Relationship</th>
                  <th class="col-3">Name </th>
                  <th class="col-3">Date Of Birth </th>
                  <th class="col-2 px-0">Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-doc let-ri="rowIndex">
                <tr>
                  <td class="col-4 px-0">{{doc.relationshipCode}}</td>
                  <td class="col-3">{{doc.firstName}}</td>
                  <td class="col-3">{{doc.dob | date: 'MM/dd/yyyy'}}</td>
                  <td class="col-2 px-0">
                    <div class="row action-btn">
                      <div class="p-0">
                        <button pButton type="button" [disabled]="disabledControls" title="Edit" icon="pi pi-pencil"
                          (click)="editDocument(doc)"
                          class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                        </button>
                      </div>
                      <div class="p-0">
                        <button pButton type="button" [disabled]="disabledControls" title="Delete" icon="pi pi-trash"
                          (click)="deleteDocument(doc.id)"
                          class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div class="no-data-box" *ngIf="familyList.length == 0">
              <p>Currently No Details Added</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
