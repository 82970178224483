import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { ImagilityBaseResponse } from 'src/app/data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class UpdateEmploymentService {

  constructor(private http: HttpClient) { }

  getOnboardingEmployment(benificiaryId, taskId, companyId) {
    return this.http.get(`/getWorkExperienceByTaskId?beneficiaryId=${benificiaryId}&companyId=${companyId}&taskId=${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      }));
  }

  getOnboardingEmploymentFromBenList(benificiaryId, taskId, companyId) {
    return this.http.get(`/getWorkExperienceByTaskId?beneficiaryId=${benificiaryId}&companyId=${companyId}&taskId=${taskId}`);
  }

  updateEmployment(payload: any) {
    return this.http.post('/updateWorkExperience', payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      }));
  }

}
