import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { RfeService } from "src/app/services/rfe.service";
import { ToastrService } from "ngx-toastr";
import { PetitionerService } from 'src/app/services/petitioner.service';
import { stepStatus } from '../../../../data/constants/step-status';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';

@Component({
  selector: "app-rfe-provide-additional-details",
  templateUrl: "./rfe-provide-additional-details.component.html",
  styleUrls: ["./rfe-provide-additional-details.component.scss"],
})
export class RfeProvideAdditionalDetailsComponent implements OnInit {
  @Input() rfeTimelineModalInput: [];
  @Output() onMovetoSource: EventEmitter<any> = new EventEmitter();
  taskId: number;
  taskNumber:any;
  displaySupportingDocuments:boolean =  false;
  displayRfeResponseBuilder:boolean = false;

  rfeNoticeId: any;
  notificationDocuments: any[] = [];
  notificationResponses: any[] = [];
  notificationDocumentsForResponse:any[] = [];
  notificationComments: any = {};
  rfeSubSteps:any[] = [];
  updateDocumentStepStatus:any;
  updateResponseStepStatus:any;
  SubTaskOverView;
  disableFields;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();
  responseNotificationids: any[]=[];

  constructor(private store: Store<fromTask.State>,private rfeService: RfeService, private toastr: ToastrService, private petitionerService:PetitionerService) {}

  ngOnInit(): void {
    
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      let stepNumber;
      if(this.rfeTimelineModalInput["userType"] === "Petitioner")
        stepNumber = 3;
      else
        stepNumber = 4;
      if(this.SubTaskOverView.taskStepDetails[stepNumber].stepStatusDetails.stepStatusCode == 'COMPLETE' || this.SubTaskOverView.taskStepDetails[stepNumber].stepStatusDetails.stepStatusCode == 'SUBMIT')
      this.disableFields = true;
    else
      this.disableFields = false;
      this.getRFEDetails();
      this.getRfeSubSteps();
    });
    this.taskNumber = this.SubTaskOverView['id']; 
  }

  getRfeSubSteps(){
    this.rfeService
   .getRfeAttorneySteps(this.SubTaskOverView['id'],this.rfeTimelineModalInput["parentStepId"])
   .subscribe((data) => {
     this.rfeSubSteps = data;
     this.rfeSubSteps.forEach( (element) => { 
       if(element["stepName"] === "Upload Documents"){
         this.updateDocumentStepStatus = element;
        }else if((element["stepName"] === "Add Response")){
          this.updateResponseStepStatus = element;
        }

     });

   })
 }

  getRFEDetails() {
    this.rfeService
      .getRFEDetails(this.SubTaskOverView['id'])
      .subscribe((data) => {
        //  console.log("getdata", data);

        this.rfeNoticeId = data["id"];
        this.getRFENotification(this.rfeNoticeId);
        //  this.getRfeSelectedCatagoryList(this.rfeNoticeId);
      });
  }
  getRFENotification(rfeNoticeId) {
    let type;
    let id;
    if (this.rfeTimelineModalInput["userType"] === "Petitioner") {
      type = "employee";
      id = sessionStorage.getItem("employeeId");
    } else if (this.rfeTimelineModalInput["userType"] === "Beneficiary") {
      type = "beneficiary";
      id = sessionStorage.getItem("beneficiaryId");
    }
    this.rfeService
    .getRfeNotification(rfeNoticeId,type,id)
    .subscribe((data) => {
      if(data){
       // console.log("getdata for documents", data);
        this.notificationComments = data['notificationComments'];
        this.notificationDocuments = data['titleSheetDocDtoList'];
      }
    
    });
    

    this.rfeService
    .getRfeNotificationForResponse(rfeNoticeId,type,id)
    .subscribe((data) => {
   
    if(data){
     // console.log("getdata for response", data);
      this.notificationResponses = data['categoryList'];
      this.responseNotificationids = data['notificationIds']
    }
    
    //this.notificationDocumentsForResponse = data['categoryList'];
    });

    let temp = [];

  }

  ngOnChanges(e: SimpleChanges) {
    if (e.isOutsideImagility && e.isOutsideImagility.currentValue) {
      this.getRFEDetails();
    }
  }

  addResponse(){
    this.displayRfeResponseBuilder = true;

  }
  uploadDocuments(){
    this.displaySupportingDocuments = true;

  }

  changeSubStepStatusEvent(event){
    //console.log("$$$$$ event",event);
    if(event.name === "Upload Documents"){
       this.changeStatus(event.status,this.updateDocumentStepStatus)
     }else if((event.name === "Add Response")){
     this.changeStatus(event.status,this.updateResponseStepStatus)
     }
   
  }

  changeStatusToSubmit(){
    this.notify.emit(stepStatus.Submitted);
  }

  changeStatus(status?,stepObj?){
    const payload = {
      statusCode: status === 'Inprogress' ? stepStatus.Inprogress: stepStatus.Complete,
      stepNote: ''
    };

     
    // if(stepObj && stepObj.stepStatusDetails.stepStatusCode === 'NEW'){
    //   payload.statusCode = status === 'Inprogress' ? stepStatus.Inprogress: stepStatus.Complete;
    // }
    if(status === 'Inprogress' && stepObj.stepStatusDetails.stepStatusCode === stepStatus.New){
      this.petitionerService.stepStatusUpdateRFE(payload, this.rfeTimelineModalInput["taskNumber"], this.rfeTimelineModalInput["parentStepId"]).subscribe();
     
     this.petitionerService.stepStatusUpdateRFE(payload, this.rfeTimelineModalInput["taskNumber"], stepObj.id).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
   
      }
    });

    }else if(status === 'Complete'){
      this.petitionerService.stepStatusUpdateRFE(payload, this.rfeTimelineModalInput["taskNumber"], stepObj.id).subscribe((response: any) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
     
        }
      });

    }
  
  }
}
