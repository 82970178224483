import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PrimaryData } from 'app-models';

@Component({
  selector: 'app-get-receipts',
  templateUrl: './get-receipts.component.html',
  styleUrls: ['./get-receipts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetReceiptsComponent implements OnInit {

  primaryData: PrimaryData;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
  ) { }

  ngOnInit() {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  handleDialogClose() {
    this.dynamicDialogRef.close();
  }

}
