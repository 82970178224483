import { Component, OnInit, Input } from '@angular/core';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import moment from 'moment';
import { WorkDetails } from 'src/app/data/models/work-experience.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-timeline',
  templateUrl: './project-timeline.component.html',
  styleUrls: ['./project-timeline.component.scss']
})
export class ProjectTimelineComponent implements OnInit {
  @Input() experienceData: WorkDetails[];
  @Input() showEditBtn;
  constructor(private router: Router) { 
    
  }

  ngOnInit() {
  }

  routeToProfile(){
    this.router.navigate(['beneficiary-landing/beneficiaries/profile/work-experience',{listview:true}]);
  }

}
