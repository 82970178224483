import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-wizard-confirmation-dialog',
  templateUrl: './wizard-confirmation-dialog.component.html',
  styleUrls: ['./wizard-confirmation-dialog.component.scss']
})
export class WizardConfirmationDialogComponent implements OnInit {

  constructor(private wizardDialogRef: DynamicDialogRef,) { }

  ngOnInit(): void {
  }

  closePopup(data) {
    this.wizardDialogRef.close(data);
  }

}
