import Highcharts from "highcharts";

export const chartOptions = {
  chart: {
    type: 'column'
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Number of Response'
    },
    stackLabels: {
      enabled: false,
      style: {
        fontWeight: 'bold',
        color: ( // theme
          Highcharts.defaultOptions.title.style &&
          Highcharts.defaultOptions.title.style.color
        ) || 'gray'
      }
    }
  },
  credits: {
    enabled: false
},
  legend: {
    align: 'left',
    verticalAlign: 'top',
    x: 20,
    y: 0,
    squareSymbol:false,
    symbolWidth: 15,
    symbolHeight:5,
    symbolRadius:5,
    itemStyle: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontFamily: 'Source Sans Pro, Regular',
      color: '#4D4F5C',
  },
    },
  tooltip: {
  //  headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '<b>{series.name}: {point.y}</b>'
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [{
    type: '',
    name: '',
    maxPointWidth:30,
    data: [],
    color:"",
  }]
}
