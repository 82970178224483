import { Action } from '@ngrx/store';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { parentTabList } from 'src/app/data/models/policy.model';
import { TaskSummaryOverview } from 'app-models';

export const SET_TASKSUMMARYOVERVIEW = '[task] Set Task Summery Overview';
export const SET_ACTIVESUBTASKINDEX = '[task] Set Active Sub Task Index';
export const SET_RFESUBTASKDETAILS = '[task] Set RFE Sub Task Details';
export const SET_PETITIONSUBTASKDETAILS = '[task] Set Petition Sub Task Details';
export const SET_RFEINDEX = '[task] Set RFE Index';
export const SET_PETITIONPOLICY = '[task] Set Petition Policy';
export const SET_I485PETITION = '[task] Set I-485 Petition Task';
export const SET_DS260PETITION = '[task] Set DS-260 Petition Task';
export const SET_DS160PETITION = '[task] Set DS-160 Petition Task';
export const SET_SUB_PETITION = '[task] Set Sub Petition Task';
export const SET_TASKROUTEID = '[task] Set Super Task ID for Routing';
export const SET_SUBTASKSTATUS = '[task] Set Sub Task Status';

export class SetRFEIndex implements Action {
  readonly type = SET_RFEINDEX;

  constructor(public payload: number) { }
}

export class SetTaskSummaryOverview implements Action {
  readonly type = SET_TASKSUMMARYOVERVIEW;

  constructor(public payload: TaskSummaryOverview) { }
}

export class SetActiveSubTaskIndex implements Action {
  readonly type = SET_ACTIVESUBTASKINDEX;

  constructor(public payload: number) { }
}

export class SetRfeSubTaskDetails implements Action {
  readonly type = SET_RFESUBTASKDETAILS;

  constructor(public payload: SubTaskDetails) { }
}

export class SetPetitionSubTaskDetails implements Action {
  readonly type = SET_PETITIONSUBTASKDETAILS;

  constructor(public payload: SubTaskDetails) { }
}

export class SetPetitionPolicy implements Action {
  readonly type = SET_PETITIONPOLICY;

  constructor(public payload: parentTabList) { }
}

export class SetI485Petition implements Action {
  readonly type = SET_I485PETITION;

  constructor(public payload: any) { }
}
export class SetTaskRouteId implements Action {
  readonly type = SET_TASKROUTEID;

  constructor(public payload: number) { }
}

export class SetDS260Petition implements Action {
  readonly type = SET_DS260PETITION;

  constructor(public payload: any) { }
}

export class SetDS160Petition implements Action {
  readonly type = SET_DS160PETITION;

  constructor(public payload: any) { }
}

export class SetSubPetition implements Action {
  readonly type = SET_SUB_PETITION;

  constructor(public payload: { subTaskDetails: any, type: String }) { }
}

export class SetSubTaskStatus implements Action {
  readonly type = SET_SUBTASKSTATUS;

  constructor(public payload: {code: any, value: any}) { }
}

export type TaskActions = SetTaskSummaryOverview | SetPetitionPolicy |
  SetActiveSubTaskIndex | SetRfeSubTaskDetails | SetPetitionSubTaskDetails |
  SetRFEIndex | SetI485Petition | SetDS260Petition | SetDS160Petition |
  SetSubPetition | SetTaskRouteId | SetSubTaskStatus;
