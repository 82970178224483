<div class="justify-content-center ad">
  <img class="" src="../../../../assets/ad1.png" alt="Card image" style="width:100%">
</div>

<div class="container">
  <div class="row error-block">
      <div class="col-md-6 pt-5">
          <h1 class="error-text">Page not found</h1>
          <h3 class="error-code">We can't find the page you're looking for.</h3>
          <h4 class="error-code text-black-50">error code 404</h4>
          <div class="mt-5">
              <a href="javascript:void(0);" (click)= "redirectToTheUrl()" class="p-2 im-btn im-btn-primary im-btn-lg">Back to Home</a>
          </div>
      </div>
      <div class="col-md-6">
          <div class="img-fluid">
              <img class="" src="../../../../assets/error-image.png" alt="Card image" style="width:100%">
          </div>
      </div>
  </div>
</div>

<div class="justify-content-center">
  <img class="" src="../../../../assets/ad1.png" alt="Card image" style="width:100%">
</div>