import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncDetailsService {

  constructor(private http: HttpClient) { }

  syncProfileData(visatype, caseType, caseId, synctype, superTaskId, subTaskId) {
    // return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/sync`, {}).pipe(
      return this.http.post(`/${visatype}/${caseType}/${caseId}/${synctype}/overall/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  syncAllList(visatype, caseType, caseId, synctype, superTaskId, subTaskId) {
    // return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/education/sync`, {}).pipe(
      return this.http.post(`/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  syncItem(visatype, caseType, caseId, synctype, superTaskId, subTaskId, educationId) {
    // return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/${educationId}/sync`, {}).pipe(
      return this.http.post(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  deleteAllList(visatype, caseType, caseId, synctype, superTaskId, subTaskId, educationId) {
    // return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/delete`).pipe(
      return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  deletecCourseItem(visatype, caseType, caseId, synctype, superTaskId, subTaskId, educationId, courseId) {
    // return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/course/${courseId}/delete`).pipe(
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}/course/${courseId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  deletecItem(visatype, caseType, caseId, synctype, superTaskId, subTaskId, educationId, courseId) {
    // return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/document/${documentId}/delete`).pipe(
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}/course/${courseId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  deleteDocuments(visatype, caseType, caseId, documentId) {
    // return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/document/${documentId}/delete`).pipe(
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  getSkipStepDetails(parentId: any, taskId: any) {
    return this.http.get(`/getChildStepDetails/${parentId}/${taskId}`).pipe( map((response: ImagilityBaseResponse) => response.data)
    );
  }

  saveSkipStepDetails(stepId, isDisabled) {
      return this.http.post(`/saveSkipStepDetails/${stepId}?disabled=${isDisabled}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }
}
