import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorRoutingModule } from 'src/app/modules/error/error-routing.module';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
import { TimeOutComponent } from './components/time-out/time-out.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';

@NgModule({
  declarations: [PageNotFoundComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    SomethingWentWrongComponent,
    TimeOutComponent,
    SessionExpiredComponent],
  imports: [
    CommonModule,
    ErrorRoutingModule
  ],
  exports:[ PageNotFoundComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    SomethingWentWrongComponent,
    TimeOutComponent,
    SessionExpiredComponent  
  ]
})
export class ErrorModule { }
