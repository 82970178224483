import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import {TreeNode} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Roles from 'src/app/data/constants/roles';

@Component({
  selector: 'app-rfe-organize-documents',
  templateUrl: './rfe-organize-documents.component.html',
  styleUrls: ['./rfe-organize-documents.component.scss']
})
export class RfeOrganizeDocumentsComponent implements OnInit {
  @Input() rfeTimelineModalInput;
  @Input() supportingDocumentsTree;
  supportingDocuments;
  @ViewChild('inplace', {static: false}) inplaceEditor;
  @ViewChild('inplaceCustom', {static: false}) inplaceEditorCustom;
  newNode = [];
  titleSheetButtonEnabled: boolean = true;
  SubTaskOverView;
  newTitleSheetSequenceNo;

  constructor(private confirmationService: ConfirmationService,
    private store: Store<fromTask.State>,
    private rfeService:RfeService,private toastr: ToastrService) { }

  ngOnInit() {
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
    });
    this.supportingDocumentsTreeValue();
  }

  onDrop(event){
    if(event.dropNode.class == 'custom'){
      event.dragNode.data["class"]="customChild";
    }
    else{
      event.dragNode.data["class"]="";
    }
    if(event.originalEvent.currentTarget.className === 'p-treenode-droppoint ng-star-inserted p-treenode-droppoint-active'){
      if(event.dragNode.type == "child" && event.dropNode.type == "parent")
        event.reject();
      else  
        if(event.dragNode.type == "parent" && event.dropNode.type == "child")
          event.reject();
        else
          event.accept();
    }
    else{  
      if(event.originalEvent.currentTarget.className === "p-treenode-content p-treenode-dragover"){
        if(event.dragNode.type == event.dropNode.type){
          event.reject();
        }
        else 
        if(event.dragNode.type == "parent" && event.dropNode.type == "child")
          event.reject();
        else  
          event.accept();
      }
      else 
          event.accept();
  }
}
  
  renameTitleSheet(data,event){
    this.rfeService.renameTitleSheet(this.SubTaskOverView['id'], data.titleSheetId,data.titleSheetName).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
        this.inplaceEditor.deactivate(event);
      }
  });
  }

  renameCustomTitleSheet(event){
    this.toastr.success("Title Sheet Renamed", 'Success');
    //this.inplaceEditor.onDeactivate.emit(event);
    this.inplaceEditorCustom.deactivate(event);
  }

  addNewTitleSheet(){
    let tempData = this.supportingDocumentsTree;
    var element = {},data = {};
    let children = [];
    data["categoryName"] = "Add Title";
    data["categoryId"] = null;
    data["categorySequenceNo"] = this.newTitleSheetSequenceNo;
    data["titleSheetSequnceNo"] = this.newTitleSheetSequenceNo;
    element["data"] = data;
    element["class"] = "custom";
    element["type"] = "parent";
    element["children"] = children;
    tempData.push(element);
    this.supportingDocumentsTree = <TreeNode[]>tempData;
    this.titleSheetButtonEnabled = false;
  }

  deleteTitleSheet(node){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key:'organize',
      accept: () => {
        let removeIndex = this.supportingDocumentsTree.map(function(item) { return item.categoryId; }).indexOf(null);
        this.supportingDocumentsTree.splice(removeIndex, 1);
        this.titleSheetButtonEnabled = true;
      },
      reject: () => {
      }
  });
    
  }
  
  saveOrganizedData(){
    let organizedList = []; 
    this.supportingDocumentsTree.forEach((element) => {
            let obj = {};
            let children = {};

            let tempChild = [];
            if(element.class != "custom")
              obj["titleSheetName"] = element["data"]["titleSheetName"];
            else
              obj["titleSheetName"] = element["data"]["categoryName"];
            obj["categorySequenceNo"] = element["data"]["titleSheetSequnceNo"];
            obj["titleSheetSequnceNo"] = element["data"]["titleSheetSequnceNo"];
            obj["titleSheetId"] = element["data"]["titleSheetId"];
            
            if(element["children"] != undefined){   
              
            element["children"].forEach(doc => {
              let child = {};
              let subChildList = []; 
                child["titleSheetDetailId"] = doc["data"]["titleSheetDetailId"];
                child["titleSheetDetailSequenceNo"] = doc["data"]["titleSheetDetailSequenceNo"];
                
                doc["data"]["documents"].forEach(doc1 => {
                  let subChild = {};
                  subChild["documentId"] = doc1["documentId"];
                  subChild["documentSequenceNo"] = doc1["documentSequenceNo"];
                  subChildList.push(subChild);
              });
              child["documents"] = subChildList;
              tempChild.push(child);
              //children.append(child);
            });
            obj["documentTypes"]=tempChild;
          }
          organizedList.push(obj);
          });
          
    console.log(organizedList);
      this.rfeService.saveOrganizedDocuments(this.SubTaskOverView['id'], organizedList).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
        this.titleSheetButtonEnabled = true;
        this.supportingDocumentsTreeValue();

      }
  });
  }

  viewDocument(item) {
    const docPath = item.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }

  confirmDelete(item){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key:'organize',
      accept: () => {
        this.deleteDocument(item);
      },
      reject: () => {
      }
  });
  }

  deleteDocument(item){
    this.rfeService.deleteRFEDocument(this.SubTaskOverView['id'],item).subscribe((response:any) => {
      if(response){
        this.toastr.success(response.message, 'Success');
        this.supportingDocumentsTreeValue();
      }
    });
}

supportingDocumentsTreeValue(){
  let tempData = [];
    this.rfeService
      .getOrganizeDocuments(this.SubTaskOverView['id'])
      .subscribe((data) => {
        this.newTitleSheetSequenceNo = data[data.length - 1]["titleSheetSequnceNo"] + 1;
        data.forEach((element) => {
          let obj = [];
          let children = [];
          obj["data"] = element;
          obj["type"] = "parent";
          element.documentTypes.forEach(doc => {
            if(doc.present == true){
              let child = [];
              child["data"] = doc;
              child["type"] = "child";
              children.push(child);
            }
          });
          obj["children"] = children;
          if(children.length > 0)
            tempData.push(obj);
        });
        this.supportingDocumentsTree = <TreeNode[]>tempData; 
        this.supportingDocumentsTree.forEach( node => {
          this.expandRecursive(node, true);
      } );
      });
}

private expandRecursive(node:TreeNode, isExpand:boolean){
  node.expanded = isExpand;
  if (node.children){
      node.children.forEach( childNode => {
          this.expandRecursive(childNode, isExpand);
      } );
  }
}

}
