import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../data/models/user.model';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { EncryptService } from '../modules/core/http/http-encrypt.service';
import { CustomErrorToastComponent } from 'custom-error-toast';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  public domainName:any;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private encryptService: EncryptService) {

      this.domainName = window.location.host;
      const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
      let footerNameString = regexExp.test(this.domainName);

      if(footerNameString == false && this.domainName == 'imagility.co' || footerNameString == true || footerNameString == false && this.domainName == 'localhost:4200'){
        this.domainName = 'imagility.co'
      }
     }

  register(user: User) {
    this.http = this.http.disableJwt();
    return this.http.post(`/users/register`, user);
  }

  registerCreatedBeneficiaryByAttorney(linkRequestCode: string, payload: any) {
    this.http = this.http.disableJwt();
    return this.http.post(`/users/register/beneficiaryThroughAttorneyLink/${linkRequestCode}`, payload);
  }

  benRegister(payload, linkCode) {
    this.http = this.http.disableJwt();
    return this.http.post(`/beneficiary/linkBeneficiaryToAttorney/${linkCode}`, payload);
  }

  verifyemail(data, id) {
    this.http = this.http.disableJwt();
    let domainName = this.domainName;
    return this.http.get(`/mail/confirmRegistration?id=${id}&userotp=${data}&domainName=${domainName}`);
  }

  verifyBenEmail(data, id, linkCode) {
    this.http = this.http.disableJwt();
    return this.http.get(`/mail/confirmRegistration?id=${id}&linkCode=${linkCode}&userotp=${data}`);
  }

  resendOtpCode(id) {
    this.http = this.http.disableJwt();
    let domainName = this.domainName;
    return this.http.get(`/mail/resendOTP?id=${id}&domainName=${domainName}`);
  }

  changepassword(changepwd) {
    this.http = this.http.enableJwt();
    return this.http.post(`/resetpassword`, changepwd);
  }


  verifyUser(userName) {
    this.http = this.http.disableJwt();
    let domainName = this.domainName;
    return this.http.get(`/validateUser?userName=${userName}&domainName=${domainName}`);
  }

  resetpassword(resetpwd) {
    this.http = this.http.disableJwt();
    return this.http.post(`/forgotPassword`, resetpwd);
  }

  resetuserid(payload) {
    this.http = this.http.disableJwt();
    return this.http.post(`/forgotUserId`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } else if (response.status === 204) {
          CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
        }
      })
    );
  }

  getBeneficiaryDetailsByLinkId(linkId) {    
    this.http = this.http.disableJwt();
    //var lnkId = this.encryptService.toEncrypt(linkId);
    return this.http.get(`/beneficiary/link/${linkId}?linkTokenId_unique`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  logoCalling(url) {
    var logo = this.encryptService.toEncrypt(url);

    return this.http.get<any>(`/company/logo/`+logo).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getUserName(nameList: any[]): string{
    let username = '';
    
    //Get Random name
    username = nameList[Math.floor(Math.random() * nameList.length)].toLowerCase();

    // If length is grated than 10
    if(username.length > 10){
      username = username.substring(0,9);
    }

    //Append 2 digits
    username += this.getRndInteger(11,99);

    if(username.length < 5){
      username = this.getUserName(nameList);
    }

    //First letter capital
    const [firstLetter, ...rest] = username.split("");
    username = firstLetter.toUpperCase() + rest.join("");

    return username;
  }

}
