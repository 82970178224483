import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AwaitLotteryService {

    constructor(private httpClient: HttpClient) { }

    getStatusAndResponse(stepTypeCode: string) {
        return this.httpClient.get(`/step/${stepTypeCode}/statusAndResponse`).pipe(
            map((response: ImagilityBaseResponse) => {
                return response['data'];
            })
        )
    }

    postH1BLottery(visaType, taskId, stepId, payload) {
        return this.httpClient.post(`/${visaType}/task/${taskId}/step/${stepId}/update`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getTask(visaType, taskId) {
        return this.httpClient.get(`/${visaType}/task/${taskId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getBeneConfirmationNo(visaType, caseType, caseId){ 
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/receiptInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

}