import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as utils from 'src/app/modules/utility/global-utils';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AttorneyGuardService {

  jwt;
  constructor(public router: Router, private authService: AuthenticationService) { }
  canActivate(route: Route, segments: UrlSegment[]): Observable<boolean> {
    this.jwt = localStorage.getItem('UserToken');
    if (this.jwt && this.jwt !== 'null') {
      return this.authService.validateUser(this.jwt).pipe(
        map((user) => {
          if (user && user.status === 200) {
            this.authService.mapUserData(user);
            const userType = sessionStorage.getItem('userTypeRole');
            if (userType !== 'Attorney') {
              this.router.navigate(['/access-denied']);
              return false;
            }
            return true;
          } else {
            this.authService.logout();
            this.router.navigate(['/login']);
            return false;
          }
        })
      );
    }
    else {
      this.authService.logout();
      this.router.navigate(['/login']);
      return of(false);
    }
    return of(true);
  }
}
