<!-- <p>calendar-widget works!</p> -->

<div class="fixed-height card border-0 shadow-sm">
    <div class="card-header d-flex justify-content-between align-items-center ml-4 mr-4 pl-0 pr-0"
       >
        Calendar
        <i class="pi pi-plus  pull-right plus-icon icn"
            routerLink="scheduling"></i>
    </div>
    <div class="card-body">
        <div class="card-content">
            <div>
                <!-- <h6>Today</h6> -->
                <div *ngIf="events.length != 0">
                    <h6 class="today">Today</h6>
                    <div *ngFor="let item of todaysEvents">
                        <!-- <div class="calendar-item">
                            <div class="calendar-item">
                                <div id="startTime"> {{item.startTime }}</div>
                                <div id="title"> {{item.title}}</div>
                            </div>
                            <div id="type" > {{item.calenderItemTypeName}}</div>
                        </div> -->
                        <!-- <div >
                                <div id="startTime" class="d-inline p-2"> {{item.startTime }}</div>
                                <div id="title" class="d-inline p-2"> {{item.title}}</div>
                            <div id="type" class="d-inline p-2"> {{item.calenderItemTypeName}}</div>
                        </div> -->
                        <div class="row mb-1">

                            <!-- <div class=" data time" [ngClass]='item.type'> {{item.startTime }} </div> -->
                            <div class="col-md-12 col-lg-3 data time">
                                <div [ngClass]='item.type'>{{item.startTime }} </div>
                            </div>
                            <div class="col-md-12 col-lg-5 data title"> {{item.title}}</div>
                            <!-- <div id="type" class="col-4 data" [ngClass]='item.calenderItemTypeName'> {{item.calenderItemTypeName}}</div> -->
                            <div class="col-md-12 col-lg-4 data">
                                <div id="type" class='data text-center icn' [ngClass]='item.calenderItemTypeName'
                                    (click)="viewItem(item)"> {{item.calenderItemTypeName}}</div>
                            </div>

                        </div>
                    </div>
                    <div class="row mb-1" *ngIf='todaysEvents.length == 0'>
                        No Events
                    </div>
                    <h6 class="tomorrow">Tomorrow</h6>
                    <div *ngFor="let item of tomorrowsEvents">
                        <!-- <div class="calendar-item">
                            <div class="calendar-item">
                                <div id="startTime"> {{item.startTime }}</div>
                                <div id="title"> {{item.title}}</div>
                            </div>
                            <div id="type" > {{item.calenderItemTypeName}}</div>
                        </div> -->
                        <!-- <div >
                                <div id="startTime" class="d-inline p-2"> {{item.startTime }}</div>
                                <div id="title" class="d-inline p-2"> {{item.title}}</div>
                            <div id="type" class="d-inline p-2"> {{item.calenderItemTypeName}}</div>
                        </div> -->
                        <div class="row mb-1">
                            <div class="col-md-12 col-lg-3 data time" style="display:flex">
                                <div [ngClass]='item.type'>{{item.startTime }} </div>
                            </div>
                            <div class="col-md-12 col-lg-5 data title"> {{item.title}}</div>
                            <!-- <div id="type" class="col-4 data" [ngClass]='item.calenderItemTypeName'> {{item.calenderItemTypeName}}</div> -->
                            <div class="col-md-12 col-lg-4 data">
                                <div id="type" class='data text-center icn' [ngClass]='item.calenderItemTypeName'
                                    (click)="viewItem(item)"> {{item.calenderItemTypeName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 text-center" *ngIf='tomorrowsEvents.length == 0'>No Events</div>
                </div>
               <!--  <ng-template #noEvents>No Events</ng-template>  -->
            </div>

        </div>
    </div>
</div>