import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class I140FileUploadService {


  constructor(private httpClient: HttpClient) { }

  uploadDocument(url: string, payload) {
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  getUploadedDocument(url: string) {
    return this.httpClient.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  getOtherDocUploadFile(superTaskId, subTaskId , i140CaseId, fileCategory) {
    return this.httpClient.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/profileOtherDoc/category/${fileCategory}`).pipe(
        map((response: ImagilityBaseResponse) => {
            if (response && (response.status === 200 || response.status === 204)) {
                return response;
            }
        })
    );
}

  deleteUploadDocument(url: string) {
    return this.httpClient.delete(url).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  downloadDocuments(path: string, fileName: string) {
    return this.httpClient.get(path, { responseType: "arraybuffer" }).subscribe(pdf => {
      var blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    })
  }

}

