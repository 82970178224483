import { Component, OnInit, Input, Output, EventEmitter,ViewChild, } from '@angular/core';
// import {NgForm} from '@angular/forms';
// import {EMAIL} from "../../common-component/common-validation/validation";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: '[app-email-address]',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.scss']
})
export class EmailAddressComponent implements OnInit {

  constructor() { }
  // emailPattern = EMAIL.pattern;
  @Input() Email: FormGroup | undefined;
  @Input() customColummn:string | undefined;

  ngOnInit(): void {
    
  }

  // ngAfterViewInit(){
  //   this.email.setValue(this.data);
  // }
 

}
