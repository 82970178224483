export class VisaDetails {
    constructor(obj: Partial<VisaDetails>) {
        Object.assign(this, obj);
    }
    id: number;
    immigrationStatusCode: string;
    currentlyStayInUS: boolean;
    hasTravelToUs: boolean;
    receiptDate: string;
    noticeNo: string;
    companyId: number;
    companyName: string;
    isEadNo: boolean;
    eadNo: string;
    isSevisNo: boolean;
    sevisNo: string;
    validityEndDate: string;
    validityStartDate: string;
    outcomeIdCode: string;
    outcomeReason: string;
    outcomeDate: string;
    petitionTypeCode: string;
    visaTypeCode: string;
    visaStatus?: string;
    travelInfo?: TravelHistory[];
    statusId: number;
    visaStamping: boolean;
    visaIssueDate: string;
    visaExpirationDate: string;
}

export class VisaMeta {
    constructor(obj: Partial<VisaMeta>) {
        Object.assign(this, obj);
    }
    alienRegNo: string;
    hasAlienRegnNo: boolean;
    hasNonImmVisa: boolean;
}

export class TravelHistory {
    constructor(obj: Partial<TravelHistory>) {
        Object.assign(this, obj);
    }
    arrivalDate?: string;
    arrivalCountryCode?: any;
    // arrivalCountryCode?: {
    //     countryCode?: String;
    // };
    arrivalStateProvinceCode?:string;
    arrivalStateProvinceName?:string;
    arrivalCity?: string;
    exitDate?: string;
    effectiveEndDate?: string;
    effectiveStartDate?: string;
    i94ExpiryDate: string;
    i94Number: number;
    id: number;
    isCurrentlyInUS: boolean;
    meansOfTravel: string;
    portOfEntry: string;
    durationOfStatus: string;
}
