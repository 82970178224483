import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { DashboardWidgetsService } from './../services/dashboard-widgets.service';
import { AuthenticationService } from './../services/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private router: Router
  ) {
    this.authenticationService.logout()
      .pipe(take(1))
      .subscribe(data => {
        this.authenticationService.clearUserData();
        this.dashboardWidgetsService.setBellNotifyCount(0);
        this.router.navigate(['/login']);
      });
  }

}
