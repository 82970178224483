<table class="details-table" mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="attorneyDetails">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell class="no-border" *matCellDef="let row">
      <div class="request-container">
        <div class="row">
          <div class="col-10 boldHead">
            <p>My Lawfirm Details</p>
            <label class="companyname">{{ row.companyName }}</label>
            <p>Address</p>
            <label>{{ row.companyAddress }}</label>
            <p>Phone No</p>
            <label>{{ row.phone }}</label>
            <p>Email ID</p>
            <label>{{ row.email }}</label>
          </div>
          <div class="d-flex justify-content-center align-items-center img-div">
            <img src="/assets/images/attorneys-icon.png" />
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator class="align-paginator" [pageSizeOptions]="[1]"></mat-paginator>
