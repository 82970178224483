import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnoozeService {
  private subject = new Subject<any>();
  snoozes: any;



  constructor(private http: HttpClient) { }

    triggerSnoozes(message: string) {
      this.subject.next({ text: message });
  }

  saveSnooze(payload) {
    return this.http.put(`/schedulingcalendering/snooze/createorupdate`, payload);
  }

  getSnoozes(id) {
    return this.http.get(`/schedulingcalendering/snooze/fetchAllSnoozeByCreatedId/${id}`);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
}

}
