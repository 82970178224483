import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PetitionerDetailsService {
  encriptedData: string;
  constructor(private http: HttpClient) { }

  // api/v2/{type}/company/{companyId}/petitionerDetails? superTaskId = 123 & taskId = 34
  getPetitionerDetails(visatype, companyId, superTaskId, subTaskId) {
    return this.http.get(`/${visatype}/company/${companyId}/petitionerDetails?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
     map((response: ImagilityBaseResponse) => {
         if (response.status === 200) {
           return response;
         }
         else {
           return [];
         }
       })
     );
   }

  // /api/v2/L1A/PETITION/351/petitioner/COMPGENDOC?superTaskId=0&taskId=0
   getPetitionerUploadDetails(visatype, caseType, caseId, synccode, superTaskId, subTaskId) {
    var encryptedText = this.toEncrypt('petitioner');
    return this.http.get(`/${visatype}/${caseType}/${caseId}/${encryptedText}/${synccode}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else
        {
          return null;
        }
      })
    );
   }

  // api/v2/L1A/PETITION/351/immigration/BENIMMDOC?superTaskId=0&taskId=0
   syncProfileData(visatype, caseType, caseId, groupId, synccode, superTaskId, subTaskId) {
     return this.http.post(`/${visatype}/${caseType}/${caseId}/${groupId}/category/${synccode}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
       map((response: ImagilityBaseResponse) => {
         if (response.status === 200) {
           return response;
         }else
         {
           return null;
         }
       })
     );
   }

  //  v2/L1/PETITION/351/additionalInfo
  postAdditionalInfo(visatype, caseType, caseId, payload) {
      return this.http.post(`/${visatype}/${caseType}/${caseId}/additionalInfo`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }else
          {
            return null;
          }
        })
      );
  }

  // api/v2/L1/PETITION/351/additionalInfo/PTLTRLIST?superTaskId=0&taskId=0
  getAdditionalInfo(visatype, caseType, caseId, superTaskId, subTaskId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/additionalInfo/PTLTRLIST?superTaskId=${superTaskId}&taskId=${subTaskId}
    `).pipe(
     map((response: ImagilityBaseResponse) => {
         if (response.status === 200) {
           return response;
         }
         else {
           return [];
         }
       })
     );
   }

  //  api/v1/O1A/PETITION/356/7/getPetitionOrganizationDetails
  getPetitionOrganizationDetails(visatype, caseType, caseId, id) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/getPetitionOrganizationDetails`).pipe(
     map((response: ImagilityBaseResponse) => {
         if (response.status === 200) {
           return response;
         }
         else {
           return [];
         }
       })
     );
   }

   //  api/v1/O1A/PETITION/356/7/getPetitionOrganizationDetails
  postPetitionOrganizationDetails(visatype, caseType, caseId, payload) {
    return this.http.post(`/${visatype}/${caseType}/${caseId}/savePetitionOrganizationDetails`, payload).pipe(
     map((response: ImagilityBaseResponse) => {
         if (response.status === 200) {
           return response;
         }
         else {
           return [];
         }
       })
     );
   }

   toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }
}