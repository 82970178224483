import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { stepStatus } from 'src/app/data/constants/step-status';
import { Store } from '@ngrx/store';
import { GCI140State } from '../../store';
import { updatei140StepStatus } from '../../store/actions/step-status-update.actions';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { TaskNotesService } from 'task-notes-lib';
import { GCI140Service } from 'src/app/services/gci140.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { GC_I140_Layout } from "src/app/modules/l1-visa/utility/constants/layout-code";
import { MarkCleanFormAction, selectFormDirty } from 'dirty-check-store';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { map, take } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gc-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent implements OnInit {

  @Input()
  stepDetails: TaskStep;

  @Input()
  type: string;

  @Output()
  onStatusToggle: EventEmitter<boolean> = new EventEmitter();


  observableSubscription$ = new Subject();
  @Output()
  onDiscardUnsavedChanges: EventEmitter<boolean> = new EventEmitter();

  guardSubscription: any;
  tootlTipTextMarkComplete: string;
  tootlTipTextMarkSubmit: string;
  tootlTipTextEdit: string;
  userType : string;
  stepLayoutCodes: any[] = [GC_I140_Layout.JOB_DESCRIPTION,GC_I140_Layout.Past_JOB_DESCRIPTION,'JOB_DESC'];

  constructor(
    private store: Store<any>,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayload,
    private taskNotesService: TaskNotesService,
    private i140service: GCI140Service,
    private toastrService: ToastrService,
    private dialog: MatDialog, 
    private ngxService: NgxUiLoaderService
  ) {
    this.tootlTipTextMarkComplete = '';
    this.tootlTipTextEdit = '';
    this.tootlTipTextMarkSubmit = '';
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem("userTypeRole");
    this.tootlTipTextMarkComplete = `To update the '${this.stepDetails.stepName}' step status to COMPLETE in Timeline, Please click on "Mark Complete" Button`;
    this.tootlTipTextEdit = `To update the '${this.stepDetails.stepName}' step status to IN-PROGRESS in Timeline, Please click on "Edit" Button`;
    this.tootlTipTextMarkSubmit = `To update the '${this.stepDetails.stepName}' step status to SUBMIT in Timeline, Please click on "Mark Submit" Button`;
    //this.getNotes();
  }

  get stepStatus() {
    return stepStatus;
  }

  updateStepStatus(status) {
    if(this.stepDetails['stepActionList'] && this.stepLayoutCodes.includes(this.stepDetails['stepActionList'][0]['layoutCode'])){
      this.store.select(selectFormDirty).pipe(
        take(1)).subscribe(formState => {
          if (!formState.dirty) {
            this.continueToStepUpdate(status);
          } else {
            this.ngxService.stopAll();
            return this.dialog.open(ConfirmationModalComponent, {
              width: '400px',
              data: {
                update: {
                  title: 'Unsaved Changes!',
                  message: 'If you leave before saving, all changes made on the page will be lost. Do you wish to continue?'
                },
                buttons: ['No', 'Yes'],
                disableClose: true
              }
            }).beforeClosed().pipe(take(1)).subscribe((confirmed: boolean) =>{
                if(confirmed){
                  this.continueToStepUpdate(status);
                  this.onDiscardUnsavedChanges.emit(true);
                }
            });
          }
        });
    }else{
      this.continueToStepUpdate(status);
    }
  }

  continueToStepUpdate(status){
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    this.store.dispatch(updatei140StepStatus({ payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode: this.stepDetails.stepActionList[0].layoutCode }));
  }
  

  updateNoteAvailability(e){    
    this.stepDetails.notesAvailable = e?.notesAvailable || false;  
    this.stepDetails.notes.noteText = e.noteText || '';
  } 
  

}
