<div class="container-fluid" *ngIf="primaryData && additionalInfoForm">
  <form [formGroup]="additionalInfoForm">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 step-title">
        <h5 class="mb-0">Additional Information</h5>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row">
      <div class="col-12">
        <label class="label-cls d-block required">Was the beneficiary EVER in immigiration
          proceedings</label>
        <div class="row mt-2">
          <div class="col-4">
            <div class="p-field-checkbox">
              <p-radioButton class="float-left p-checkbox" name="wasBenInImmProceedingName"
                formControlName="wasBenInImmProceeding" [value]="true" (onClick)="onImmigrationProceedingsChange()"
                label="Yes">
              </p-radioButton>
            </div>
          </div>
          <div class="col-4">
            <div class="p-field-checkbox">
              <p-radioButton class="float-left p-checkbox" name="wasBenInImmProceedingName"
                formControlName="wasBenInImmProceeding" [value]="false" (onClick)="onImmigrationProceedingsChange()"
                label="No">
              </p-radioButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-3"
      [ngStyle]="{display: additionalInfoForm.get('wasBenInImmProceeding').value ? 'block' : 'none'}"
      formArrayName="gcI130ProceedingDtlDTO">
      <div [formGroupName]="0" class="row">
        <div class="col-12">
          <label class="label-cls required">Select
            the type of proceedings and provide the location and date of the
            proceedings</label><br />
          <div class="row">
            <div *ngFor="let item of proceedingsTypesArr" class="col-4 mt-2">
              <div class="p-field-checkbox">
                <p-radioButton name="proceedingTypIdName" [value]="item.id" formControlName="proceedingTypId"
                  [label]="item.name">
                </p-radioButton>
              </div>
            </div>
          </div>
          <div class="invalid-message"
            *ngIf="proceedingsControls.get('proceedingTypId').touched && proceedingsControls.errors?.proceedingTypIdIsRequired">
            {{getGlobalErrorMessages('REQUIRED')}}
          </div>
        </div>
        <div class="col-12">
          <div class="row mt-2">
            <div class="col-4">
              <label class="label-cls required">City or Town</label>
              <input type="text" class="im-input" pInputText [ngClass]="{
                          'im-input-error':
                            (proceedingsControls.get('city').touched) &&
                            proceedingsControls.get('city').errors?.pattern
                        }" pattern="^[a-zA-Z ]*$" formControlName="city" />
              <span class="invalid-message" *ngIf="
                          (proceedingsControls.get('city').touched) &&
                          proceedingsControls.get('city').errors?.pattern
                        ">
                {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
              </span>
              <div class="invalid-message"
                *ngIf="proceedingsControls.get('city').touched && proceedingsControls.errors?.proceedingCityIsRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
            <div class="col-4 form-group processing-dropdown">
              <label class="label-cls required"> State </label>
              <p-dropdown appendTo="body" [options]="statesList" *ngIf="statesList.length > 0" id="stateProvinceCode"
                formControlName="stateProvinceCode" name="proceedingStates" optionLabel="stateProvinceName"
                optionValue="stateProvinceCode" placeholder="select">
              </p-dropdown>
              <div class="invalid-message"
                *ngIf="proceedingsControls.get('stateProvinceCode').touched && proceedingsControls.errors?.proceedingStateProvinceCodeFieldIsRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
            <div class="col-4 form-group input-disabled">
              <label class="label-cls required">Date </label>
              <p-calendar class="im-input-calendar mw-100" styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [showIcon]="true" [disabled]="disabledControls" formControlName="proceedingDate"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050" [showButtonBar]="true">
              </p-calendar>
              <div class="invalid-message"
                *ngIf="proceedingsControls.get('proceedingDate').touched && proceedingsControls.errors?.proceedingDateIsRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h5 class="step-tab-form-sub-header">Provide the Address where you are going to stay in US</h5>
      </div>
      <div class="col-12">
        <app-address (childAddressForm)="addAddressFormControlToParentForm($event)"
          [selectedCountryAndStateCode]="selectedCountryCode" [readonlyCountry]="true" [formDisabled]="addressFormDisabled"></app-address>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <p-radioButton class="i130-radio-button" formControlName="aosInUs" [value]="true"
          label="The beneficiary is in the United States and will apply for adjustment of status to that of a lawful permanent resident at the U.S. Citizenship and Immigration Services (USCIS) office in:"
          (onClick)="onAOSInUSChange()">
        </p-radioButton>
      </div>
      <div class="col-6">
        <p-radioButton class="i130-radio-button" formControlName="aosInUs" [value]="false"
          label="The beneficiary will not apply for adjustment of status in the United States, but he or she will apply for an immigrant visa abroad at the U.S. Embassy or U.S. Consulate in:"
          (onClick)="onAOSInUSChange()">
        </p-radioButton>
      </div>
    </div>
    <div class="row mb-3" *ngIf="additionalInfoForm.get('aosInUs').value === true">
      <div class="col-4">
        <label class="label-cls required">City or Town</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                      'im-input-error':
                        (additionalInfoForm.get('aosUsCity').touched) &&
                        additionalInfoForm.get('aosUsCity').errors?.pattern
                    }" pattern="^[a-zA-Z ]*$" formControlName="aosUsCity" />
        <span class="invalid-message" *ngIf="
                      (additionalInfoForm.get('aosUsCity').touched) &&
                      additionalInfoForm.get('aosUsCity').errors?.pattern
                    ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="additionalInfoForm.get('aosUsCity').touched && additionalInfoForm.errors?.aosUsCityIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls required"> State </label>
        <p-dropdown appendTo="body" [options]="statesList" *ngIf="statesList.length > 0" id="stateProvinceCode"
          formControlName="aosUsStateProvinceCode" name="aosInUsStatesList" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="additionalInfoForm.get('aosUsStateProvinceCode').touched && additionalInfoForm.errors?.aosUsStateProvinceCodeIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
        <!-- <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listBirthStates.length === 0"
          id="clientName" formControlName="aosUsStateProvinceName" type="text" pInputText placeholder="Enter" /> -->
      </div>
    </div>
    <div class="row mb-3" *ngIf="additionalInfoForm.get('aosInUs').value === false">
      <div class="col-4">
        <label class="label-cls required">City or Town</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                      'im-input-error':
                        (additionalInfoForm.get('aosNonUsCity').touched) &&
                        additionalInfoForm.get('aosNonUsCity').errors?.pattern
                    }" pattern="^[a-zA-Z ]*$" formControlName="aosNonUsCity" />
        <span class="invalid-message" *ngIf="
                      (additionalInfoForm.get('aosNonUsCity').touched) &&
                      additionalInfoForm.get('aosNonUsCity').errors?.pattern
                    ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="additionalInfoForm.get('aosNonUsCity').touched && additionalInfoForm.errors?.aosNonUsCityIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls required"> Province </label>
        <p-dropdown appendTo="body" [options]="aosNotUsStateList"
          *ngIf="aosNotUsStateList &&aosNotUsStateList.length > 0" id="stateProvinceCode"
          formControlName="aosNonUsStateProvinceCode" name="country" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <input class="im-input mw-100" [readonly]="disabledControls"
          *ngIf="!aosNotUsStateList || (aosNotUsStateList && aosNotUsStateList.length === 0)" id="clientName"
          formControlName="aosNonUsStateProvinceName" type="text" pInputText placeholder="Enter" />
        <div class="invalid-message"
          *ngIf="(aosNotUsStateList &&aosNotUsStateList.length > 0) && additionalInfoForm.get('aosNonUsStateProvinceCode').touched && additionalInfoForm.errors?.aosNonUsStateProvinceCodeIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
        <div class="invalid-message"
          *ngIf="(!aosNotUsStateList || (aosNotUsStateList && aosNotUsStateList.length === 0)) && additionalInfoForm.get('aosNonUsStateProvinceName').touched && additionalInfoForm.errors?.aosNonUsStateProvinceNameIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls required"> Country </label>
        <p-dropdown appendTo="body" [options]="countryList" [filter]="false" id="countryCode"
          [disabled]="disabledControls" formControlName="aosNonUsCountryCode" optionLabel="countryName"
          optionValue="countryCode" (onChange)="handleCountryChange($event.value, null, null)" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="additionalInfoForm.get('aosNonUsCountryCode').touched && additionalInfoForm.errors?.aosNonUsCountryCodeIsRequired">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-12 mt-3">
        <label> <b>NOTE:</b> Choosing a U.S. Embassy or U.S. Consulate outside the country of the beneficiary's
          last residence does not guarantee that it will accept the beneficiary's case for processing. In these
          situations, the designated U.S. Embassy or U.S. Consulate has discretion over whether or not to accept
          the beneficiary's case.</label>
      </div>
    </div>
    <p-divider class="d-block mb-4"></p-divider>
    <div class="row pt-3">
      <div class="col-md-3">
        <button pButton type="button" label="Save" title="Save" class="save-btn" icon="pi pi-save"
          [ngClass]="additionalInfoForm.invalid || disabledControls || addressFormDisabled  ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="additionalInfoForm.invalid || disabledControls || addressFormDisabled" (click)="saveAdditionalInfo()"></button>
      </div>
      <div class="col-md-3">
        <button pButton type="button" label="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
            d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
        </button>
      </div>
    </div>
  </form>
</div>
