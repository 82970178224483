import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as RolesRouteMap from 'src/app/data/constants/roles';
@Component({
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent implements OnInit {
  redirectUrl: string;

  constructor(private router: Router) {
    this.redirectUrl = "";
  }

  ngOnInit() {
    if (('isFBUser' in sessionStorage) && sessionStorage.getItem('isFBUser') === 'true') {
      this.redirectUrl = '/modules-landing/fb/module';
    } else if (sessionStorage.getItem('userTypeRole')) {
      this.redirectUrl = `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard`;
    } else {
      this.redirectUrl = '../login/login/signin';
    }
  }


  redirectToTheUrl() {
    this.router.navigate([this.redirectUrl]);
  }

}
