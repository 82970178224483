import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { tap, map, switchMap } from 'rxjs/operators';
import { I9Service } from "src/app/services/i9-service";
import * as I9Actions from "../actions/i9-section-one.actions";

@Injectable({
    providedIn : 'root'
})

export class i9SectionOneEffects{
    constructor(private actions$: Actions, private i9Service: I9Service){}

     getSectionOneInfo$ = createEffect(() => this.actions$.pipe(
        ofType<I9Actions.getI9SectionOneInfoAction>(I9Actions.I9ActionTypes.GETSECTIONONE),
        switchMap(action => {
            return this.i9Service.getI9Section1Info(action.payload.companyId, action.payload.i9FormId).pipe(
                map(response=>{
                    return {type: I9Actions.I9ActionTypes.SETSECTIONONE, payload: response}  
                })
            )          
            
        })
    ))
    
}