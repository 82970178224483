import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingComponent } from "./landing.component";
import { CarouselModule } from "ngx-owl-carousel-o";

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, CarouselModule],
  exports: [LandingComponent],
})
export class LandingModule {}
