//IM-2472 - Download button opens file instead of download
import { Component, OnInit,  Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {
  @Input() fileName;
  @Input() Path;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  download() {
    this.http.get(this.Path,{responseType: "arraybuffer"}).subscribe(pdf => {
      var blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob,this.fileName);
    })
    
    
  }

}
