import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserRoleService } from 'src/app/services/user-role.service';
import { JoyrideService } from 'ngx-joyride';
import { VideoTourService } from 'src/app/services/video-tour.service';

@Component({
  selector: 'app-attorney-menu',
  templateUrl: './attorney-menu.component.html',
  styleUrls: ['./attorney-menu.component.scss']
})
export class AttorneyMenuComponent implements OnInit {
  isAdmin = false;
  isAdminSelected = false;

  attorneyGuidedTourContent = [
    {
      'title': 'Create a New Task',
      'headerOne': "To create a petition, you need to create a task. This can be done through the <strong>Dashboard</strong> or <strong>Tasks Menu.</strong> Use the <strong>Add New Task</strong> button to create a new task,fill in details and <strong>Save.</strong>",
      'image': '/assets/images/video-tour/Petitioner_Create_New_Task.svg',
      'videoLink': 'How to create Tasks',
      'nextButtonText': 'Learn how to <b>Create Client Connections</b>',
      'watchLabel': ''
    },
    {
      'title': 'Create Client Connections',
      'headerOne': "Lawfirms can add clients to the platform by clicking on <strong>+Add Connection</strong>,filling in details and click <strong>Save</strong>",
      'image': '/assets/images/video-tour/Attorney_Create_Client_Connections.svg',
      'videoLink': '',
      'nextButtonText': 'Know more about managing beneficiaries',
      'watchLabel': ''
    },
    {
      'title': 'Manage Beneficiaries',
      'headerOne': "Lawfirms can manage beneficiaries who are onboarded to the platform here. Through this menu <strong>editing</strong> beneficiary details like <strong>Personal details</strong> or <strong>Education/Work Experience/Immigration</strong> details is possible.",
      'image': '/assets/images/video-tour/Attorney_Manage_Beneficairy.svg',
      'videoLink': 'Next steps after Tasks creation',
      'nextButtonText': 'To continue with the tour',
      'watchLabel': ''
    },
    {
      'title': 'Back to the Dashboard',
      'headerOne': "This contains a snapshot of all activites related to your account: <strong>Petitioner Information, Platform Statistics, Notifications, Tasks </strong>etc.",
      'image': '',
      'videoLink': 'Part 1: How to build a Petition',
      'nextButtonText': 'Our tour ends here!',
      'watchLabel': 'to start building a petition!'
    }
  ]
  roleName: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private videoTourService: VideoTourService,
    private joyride: JoyrideService,
    private authenticationService: AuthenticationService,
    private userRoleService: UserRoleService) {
    this.roleName = sessionStorage.getItem('roleName') === 'External Attorney' ? true : false;
    this.videoTourService.videoTourStarted$.subscribe(value => {
      if (value === 'Attorney') {
        this.startVideoTour();
      }
    });
  }

  ngOnInit(): void {
    this.userRoleService.isUserTypeAdmin.next(false);
    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        data.permissions.find(el => {
          if (el === 'ISADMIN') {
            this.isAdmin = true;
          }
        });
      }
    });
  }
  adminAttoney(isValueSelected: boolean) {
    if (this.isAdmin) {
      this.isAdminSelected = isValueSelected;
      this.userRoleService.isUserTypeAdmin.next(this.isAdminSelected);
    }
  }

  startVideoTour() {
    this.joyride.startTour(
      { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep'] }
    )
  }

  ngOnDestroy() {
    this.videoTourService.videoTourStarted$.next(false);
  }
}
