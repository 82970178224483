import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DependentProfileDataService {

  dependentProfileSubject = new BehaviorSubject(<any>{});
  onLifeStorySubTabs = new Subject();
  selfBeneficiaryDetailChange = new Subject();
  newSubDependentCreated = new Subject<{ beneficiaryFamilyInfo: any, selectedSubTabId: number }>();

  constructor() { }

  changeDependentProfileID(dependentId: number, beneficiaryType: string) {
    this.dependentProfileSubject.next({ dependentId: dependentId, beneficiaryType: beneficiaryType });
  }

  // subDependentCreated(id:number){
  //   this.newSubDependentCreated.next(id);
  // }




}