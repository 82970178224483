import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EditCompleteLicenseDetailsService {
  
  public uploaded$ = new BehaviorSubject<any>({});
  public deletedfile$ = new BehaviorSubject<any>({});
  public showBeneficiary = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

  getBeneDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=true`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  saveTrainingDetails(payload: any) {
    let petitionId = JSON.parse(sessionStorage.getItem('tempPetitionId'));
    return this.http.post(`/petition/saveTrainingOrLicense/${petitionId}/license`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  } 

  getTrainingsDetails(beneficiaryId: number, type: string, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/professional/${type}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  deleteLicenceDocuments(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, educationId: number) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } 
      })
    );
  }

  // IM-3066 - remove deleted file.
  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }


  uploaded() {
    this.uploaded$.next(true);
  }

  genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  // getLicensesInformation(superTaskId, subTaskId) {
  //   return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license`);
  // }

  getLicensesInformationPerm(caseId, isUsedForBenEdit: boolean) {
    return this.http.get(`/gc/perm/${caseId}/educationDetails/education/license?isUsedForBenEdit=${isUsedForBenEdit}`);
  }
}
