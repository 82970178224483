import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";



@Component({
  selector: 'app-generate-receipt',
  templateUrl: './generate-receipt.component.html',
  styleUrls: ['./generate-receipt.component.scss']
})
export class GenerateReceiptComponent implements OnInit {

  viewInvoiceData: any;
  attorneysignsrc = "";
  businessLogosrc = "";
  invoiceId: any;

  constructor(
    private billinginvoiceservice: BillingInvoiceService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.invoiceId = history.state.invoiceId;
    // console.log('id', this.invoiceId)
    this.billinginvoiceservice.getPreviewInvoiceDetails(this.invoiceId).subscribe((res: any) => {
      // console.log("preview", res.message);
      //this.toaster.success(res.message);
      this.viewInvoiceData = res;
      this.attorneysignsrc = `data:image/jpeg;base64,${this.viewInvoiceData.signImage}`;
      this.businessLogosrc = `data:image/jpeg;base64,${this.viewInvoiceData.billBycompanyLogo}`;
    });
  }

}
