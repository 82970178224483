import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomQuestionsService {
  selectedFamilyId: any;
  public selectedFamilyIdSubject = new Subject<any>();

  constructor(private httpClient: HttpClient) {
  }

  saveQuestionList(beneficiaryId, companyId, familyId, payload) {
    if(localStorage.getItem('cutomSelectedCompanyId')){
      companyId = localStorage.getItem('cutomSelectedCompanyId');
    }
    let dynamicUrl;
    if(familyId){
      dynamicUrl = `/saveCustomSectionsForBeneficiary/${beneficiaryId}/${companyId}?familyId=${familyId}`;
    } else {
      dynamicUrl = `/saveCustomSectionsForBeneficiary/${beneficiaryId}/${companyId}`;
    }
    return this.httpClient.post(dynamicUrl, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveQuestionStepList(taskId, stepId, companyId, payload) {
    let dynamicUrl;
      dynamicUrl = `/saveCustomStepForTask/${taskId}/${stepId}/${companyId}`;
    return this.httpClient.post(dynamicUrl, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  
  getQuestionList(beneficiaryId, companyId, familyId) {
    if(localStorage.getItem('cutomSelectedCompanyId')){
      companyId = localStorage.getItem('cutomSelectedCompanyId');
    }
    let dynamicUrl;
    if(familyId){
      dynamicUrl = `/getCustomSectionsForBeneficiary/${beneficiaryId}/${companyId}?familyId=${familyId}`;
    } else {
      dynamicUrl = `/getCustomSectionsForBeneficiary/${beneficiaryId}/${companyId}`;
    }
    return this.httpClient.get(dynamicUrl).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  } 

  getQuestionListSteps(companyId,taskTypeId) {
    let dynamicUrl;
      dynamicUrl = `/getCustomQuestionnaryDetails/company/${companyId}?taskTypeId=${taskTypeId}`; 
    return this.httpClient.get(dynamicUrl).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  } 
 
}
