<div class="fluid-container">
    <div class="row col-md-12 pa-title">
        <div class="col-md-10 mb-3">
            <h5>Petition Analysis</h5>
        </div>
        <div class="col-md-2 mb-3">
            <p-button type="button" class="p-button-secondary p-button-lg pull-right" (click)="refreshPA()">
                <i class="pi pi-refresh" style="margin-right: 5px;"></i> Refresh
            </p-button>
        </div>
    </div>
    <div class="row col-md-12 pa-status">
        <div class="col-md-4">
            <div class="pa-brd">
                <h6>Petition Status </h6>
                <div class="col-md-12">
                    <img-primeng-donut [inputData]="summeryGraphDetails"></img-primeng-donut>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="pa-brd">
                <div class="row col-md-12 pr-0 pl-0">
                    <div class="col-md-4">
                        <h6>Each Category level analysis </h6>
                    </div>
                    <div class="row col-md-8 pa-color">
                        <div class="ml-auto">
                            <div class="mr-3 float-left">
                                <div class="float-left mr-1 legend blue"></div>
                                <div class="float-left"><span>Looks Perfect</span></div>
                            </div>
                            <div class="mr-3 float-left">
                                <div class="float-left mr-1 legend yellow"></div>
                                <div class="float-left"><span>Needs to be fixed</span></div>
                            </div>
                            <div class="mr-3 float-left">
                                <div class="float-left mr-1 legend notInitiatedColor"></div>
                                <div class="float-left"><span>Not Initiated</span></div>
                            </div>
                            <div class="float-left">
                                <div class="float-left mr-1 legend notApplicableColor"></div>
                                <div class="float-left"><span>Not Applicable</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-md-12 pt-4">
                    <div class="col-md-6 pb-3" [ngClass]="{'pl-5': i%2 !== 0,'pr-5': i%2 === 0 }"
                        *ngFor="let category of categoryList; let i = index;">
                        <p>{{category.name}} <span class="pull-right" (click)="handlePaCategorized(category)">Check
                                details</span></p>
                        <div class="grey">
                            <div class="green" [ngStyle]="{ 'width': category.successPercentage + '%'}"></div>
                            <div class="red"
                                [ngStyle]="{ 'left': category.successPercentage + '%', 'width': category.failurePercentage + '%'}">
                            </div>
                        </div>
                        <span style="color:#C5C5C5;">{{category.success}} / {{category.total}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 col-md-12 pa-status">
            <div class="pa-brd ">
                <div class="col-md-12 row" style="height: 50px;">
                    <div class="col-md-10 row align-items-center">
                        <h6>Overall Observations</h6>
                    </div>
                    <div class="col-md-2">
                        <p-multiSelect [displaySelectedLabel]="false" optionValue="code" [filter]="false" [showToggleAll]="false"
                            [options]="observationCategories" [(ngModel)]="selObservationFilterCtg" (onChange)="handleFilter()"
                            defaultLabel="Filter" optionLabel="name" class="filter-check">
                            <ng-template pTemplate="header">
                                <p-checkbox  [(ngModel)]="selectAllObservations" label="All Observations" [binary]="true" class="filter-all-select" value="afsdf" (onChange)="handleSelectAll()"></p-checkbox>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>
                <div class="col-md-12 pl-0">
                    <div class="col px-1 task-table">
                        <p-table styleClass="p-datatable-gridlines"
                            class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                            [paginator]="true" [rows]="25" [showCurrentPageReport]="true" rowExpandMode="single"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[25,50, 100]" [value]="observations" dataKey="indexId">
                            <ng-template pTemplate="header">
                                <tr class="text-uppercase">
                                    <th style="width: 3rem"></th>
                                    <th>Petition Element</th>
                                    <th  colspan="2">Findings</th>
                                    <th  colspan="2">Recommendations</th>
                                    <th>Status</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-obervation let-expanded="expanded">
                                <tr>
                                    <td style="padding-top: 6px!important;">
                                        <button type="button" pButton pRipple [pRowToggler]="obervation"
                                            class="p-button-text p-button-rounded p-button-plain"
                                            [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
                                    </td>
                                    <td>{{obervation.elementName}}</td>
                                    <td  colspan="2" class="text-truncate">{{obervation.finding}}</td>
                                    <td  colspan="2" class="text-truncate">{{obervation.recommendation}}</td>
                                    <td class="text-truncate">
                                        <p *ngIf="obervation.status === 0">
                                            Needs to be fixed
                                        </p>
                                        <p *ngIf="obervation.status === 1">
                                            Looks Perfect
                                        </p>
                                        <p *ngIf="obervation.status === 2">
                                            Not Applicable
                                        </p>
                                        <p *ngIf="obervation.status === 3">
                                            Not Initiated
                                        </p>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-obervation>
                                <tr class="pt-5 pt-5">
                                    <td colspan="7">
                                        <div class="p-p-3 rowExpansionData">
                                            <h6>Findings</h6>
                                            <p> {{obervation.finding}} </p>
                                            <h6>Recommendation</h6>
                                            <p>{{obervation.recommendation}} </p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>