import { Component, OnInit ,ViewChild} from "@angular/core";
import { DashboardWidgetsService } from "src/app/services/dashboard-widgets.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: "contact-details",
  templateUrl: "./contact-details.component.html",
  styleUrls: ["./contact-details.component.scss"],
})
export class ContactDetailsComponent implements OnInit {
  beneficiaryId: number = 0;
  displayedColumns: string[] = ['petionerDetails'];
  dataSource: MatTableDataSource<any>;
  petionerDetails:any = [];
 
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dashboardWidgetsService: DashboardWidgetsService,
    private authenticationService: AuthenticationService
  ) {
    this.beneficiaryId = sessionStorage.getItem("listBeneficiaryId")
      ? parseInt(sessionStorage.getItem("listBeneficiaryId"))
      : this.authenticationService.currentUserValue["beneficiaryId"];
  }

  ngOnInit() {
    this.dashboardWidgetsService
      .getContactDetailsPetitioner(this.beneficiaryId)
      .subscribe((Response) => {
        Response['data'].map((item: any) => {
          let address = item.address.filter(x => (x.type.code === "REGS"));
          item.companyAddress = address[0].addressLine1+' '+address[0].addressLine2+' '+address[0].city+' '+address[0].countryCode.countryName+' '+address[0].postCode
          item.email = item.emailContacts.filter(x => (x.type.code === "PRIM"))[0].email;
          if (item.phoneContacts === null) {
            item.phone = item.phoneContacts;
          } else {
            item.phone = item.phoneContacts.filter(x => (x.type.code === "OFFC"))[0].phoneNo;
          }
        });
        this.petionerDetails = Response['data'];
        this.dataSource = new MatTableDataSource(this.petionerDetails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
}
