import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImagilityBaseResponse } from 'app-models';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImmigrationFileUploadService {

  public uploaded$ = new BehaviorSubject<any>({});
  constructor(private http: HttpClient) { }
  public deletedfile$ = new BehaviorSubject<any>({});

  genericUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, entityId: any, fileCategory: string,
    otherName: string, payload, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    let petitionId = JSON.parse(sessionStorage.getItem('tempPetitionId'));
    return this.http.post(`/document/${useCase}/${useCaseId}/category/${categoryName}/entity/${entityId}/fileCategory/${fileCategory}${appendUrl}${profileFamilyString}?petitionId=${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericPassPortUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, entityId: any, fileCategory: string,
    otherName: string, payload, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/document/${useCase}/${useCaseId}/category/${categoryName}/entity/${entityId}/fileCategory/${fileCategory}${appendUrl}${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericDeletePassportUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, fileCategory: string, fileId: number,
    entityId: any, useEntityId: boolean, isEntityBasedDelete: boolean) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const fileIdTobePassed = (isEntityBasedDelete) ? '' : fileId;
    return this.http.delete(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}/fileCategory/${fileCategory}/${fileIdTobePassed}`).pipe(
      map((response) => {
        this.uploaded();
        return response;
      })
    );
  }
  genericGetPassportUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericGetPetitionUploadDocument(petitionId: number, caseType: string, categoryCode: string, fileCategoryCode: string, entityId: number, type: string, otherName: string, payload, familyId?: number) {  
    let profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    if (otherName) {
      profileFamilyString = profileFamilyString ? `${profileFamilyString}&otherName=${otherName}` : `?otherName=${otherName}`;
    }
    let url = `/document/category/fileCategory/${petitionId}/${categoryCode}/${fileCategoryCode}/${entityId}/${type}${profileFamilyString}`;
    if(caseType === 'I485' || type === 'PETITION'){      
      url = `/document/category/fileCategory/${petitionId}/${categoryCode}/${fileCategoryCode}/${entityId}${profileFamilyString}`; 
    }
    return this.http.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }


  generiBankUploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/upload/bankDetails/${empId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericW4UploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/w4formsupload/${empId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }


  genericForm16UploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/form16upload/${empId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericPetitionGetUploadDocument(petitionId: number, categoryCode: string) {
    return this.http.get(`/petition/getDocuments/${petitionId}/${categoryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericPetitionGetUploadDocumentI130(gcVisaType: number, gcPermI140I130Id: number, categoryCode: string) {
    return this.http.get(`/gcCase/getDocuments/${gcVisaType}/${gcPermI140I130Id}/${categoryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericBankGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/fbUploadedDocs/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericW4GetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/fbW4UploadedDocs/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }
  genericDeleteUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, fileCategory: string, fileId: number,
    entityId: any, useEntityId: boolean, isEntityBasedDelete: boolean) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const fileIdTobePassed = (isEntityBasedDelete) ? '' : fileId;
    return this.http.delete(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}/fileCategory/${fileCategory}/${fileIdTobePassed}`).pipe(
      map((response) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericPetitionDeleteUploadDocument(
    visaType: string, caseType: string, caseId: number, documentId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/document/${documentId}`).pipe(
      map((response) => {
        this.uploaded();
        return response;
      })
    );
  }


  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  deleteBankFileFromDocumentList(empId: number) {
    return this.http.delete(`/formBuilder/employee/bankDetails/delete/${empId}`).pipe(
      map((data) => {
        this.deletefile(empId);
        return data;
      })
    );
  }

  deleteW4FileFromDocumentList(empId: number, categoryName: string, fileCategory: string) {
    return this.http.delete(`/formBuilder/employee/w4Details/delete/${empId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
      map((data) => {
        this.deletefile(empId);
        return data;
      })
    );
  }

  getEntitySpecificUploadDocument(useCase: string, useCaseId: number, categoryName: string, entity) {
    // console.log(entity);
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}/entity/${entity}`).pipe(
      map((response) => response)
    );
  }

  uploaded() {
    this.uploaded$.next(true);
  }

  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  /**
   * New API
   */
  immigrationDocumentUpload(gcPermOrI140Id: number, categoryCode: string, fileCategoryCode: string, entityId: number, type: string, payload: any) {
    return this.http.post(`/document/category/fileCategory/${gcPermOrI140Id}/${categoryCode}/${fileCategoryCode}/${entityId}/${type}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  immigrationGetDocumentUpload(gcVisaType: string, gcPermI140I130Id: string, categoryCode: string) {
    return this.http.get(`/gcCase/getDocuments/${gcVisaType}/${gcPermI140I130Id}/${categoryCode}`);
  }
}
