<h5 mat-dialog-title>
  <div class="col-md-12 p-0 row delete-header">
    <div class="col-md-10 p-0">
      {{title}}
    </div>
    <div class="col-md-2 p-0 justify-content-end d-flex">
      <i (click)="handleNo();" class="close-icon pi pi-times"></i>
    </div>
  </div>
</h5>
<div class="message" mat-dialog-content>
  <p>{{message}}</p>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-raised-button class="btnCustom mr-3" color="primary" (click)="onConfirm()">{{buttons[1]}}</button>
  <button mat-button class="btnCustom" (click)="onDismiss()">{{buttons[0]}}</button>
</div> 
 