
export enum NotifyCodeAction {
  
    IOSDTL_STP = 'IOSDTL_STP', //IOS Details
    RVW_PTON_SEND_BACK_STP ='RVW_PTON_SEND_BACK_STP', //'Review Petition Send Back
    RVW_PTON_COM_STP ='RVW_PTON_COM_STP', //'Review Petition Complete
    PROJECT_DTLS_STP= 'PROJECT_DTLS_STP',//'Project Details Step
    SUPPORT_LTR_STP= 'SUPPORT_LTR_STP', //'Support Letter Step
    LINKREQ = 'LINKREQ', //'Link request
    OCCPDTL_STP= 'OCCPDTL_STP',//'Occupation Details
    WRKLOC_STP= 'WRKLOC_STP',//'Work Location
    LSTDUTY_STP= 'LSTDUTY_STP',//'List Of Duty
    EDUWE_STP= 'EDUWE_STP',//'Education and Work experience
    BEN_POS_DETAIL_STP= 'BEN_POS_DETAIL_STP', //'Beneficiary Position Details
    DOCREJECT= 'DOCREJECT', //'Wrong document uploaded
    DOCMISS= 'DOCMISS', //'Document Missing
    TASKREQ = 'TASKREQ', //'Step Status Notification
    RFEDOCMISSRFERESPONSEREQ = 'RFE_DOC_MISSRFE_RESPONSE_REQ', //RFE missing docs
    RFEDOCMISS='RFE_DOC_MISS',
    RFERESPONSEREQ='RFE_RESPONSE_REQ',
    ETA_NOTICE='ETA_NOTICE // ETA Notice',
    LCA_ACKNOWLEDGEMENT_REQ='LCA_ACKNOWLEDGEMENT_REQ', //Req for lca acknowledge beneficiary

}


export enum NotifyCodeNoAction {

    BUILD_PETITION_STP = 'BUILD_PETITION_STP', //Build Petition
    GETRCPT_ATTOR_STP  = 'GETRCPT_ATTOR_STP',  //Get Receipt Attorney
    GETRCPT_PET_STP    = 'GETRCPT_PET_STP',  // Get Receipt Petitioner
    SUBTPTION_ATTOR_STP = 'SUBTPTION_ATTOR_STP', // Submit Petition Attorney
    SUBTPTION_PET_STP =  'SUBTPTION_PET_STP',  // Submit Petition Petitioner
    RVWDOCS_STP =  'RVWDOCS_STP', // Review Docs
    WAIT_DCSON_COMP_PT_STP = 'WAIT_DCSON_COMP_PT_STP',  // Wait for Decision Petitioner
    WAIT_DCSON_COMP_ATT_STP = 'WAIT_DCSON_COMP_ATT_STP', // Wait for Decision Attorney
    WAIT_DCSON_RFE_PT_STP = 'WAIT_DCSON_RFE_PT_STP', // Wait for Decision RFE Petitioner
    WAIT_DCSON_RFE_AT_STP = 'WAIT_DCSON_RFE_AT_STP', // Wait for Decision RFE Attorney
    AWAIT_LOTTERY_STP = 'AWAIT_LOTTERY_STP', // Await Lottery Selection
    REGISTER_WTH_USCIS_STP = 'REGISTER_WTH_USCIS_STP', // Register with USCIS
    LNKWITHDRW = 'LNKWITHDRW', // Link withdraw
    BENUSRREQ = 'BENUSRREQ', // Beneficiary Username notification
    TASKREVREQ = 'TASKREVREQ', // Step Review Notification
    TASK_AUDIT  = 'TASK_AUDIT'// tasl audit  custom Notification
   
}

