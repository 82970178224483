<div *ngIf="userType == 'Attorney'">
<div class="row">
    <div class="add-task-btn">
      <a [ngClass]="{'disabledFields': disableFields}" class="add-btn task-btn" href="javascript:void(0);" role="button" (click)="addCatagory()">Add New Category</a>
     </div>
 
    <p-dialog [contentStyle]="{'overflow': 'visible'}" [(visible)]="displayAddCatagoryPanel" [modal]="true" [responsive]="true" [style]="{width: '700px', minWidth: '200px'}" [minY]="70"
 [maximizable]="false" [baseZIndex]="50000">
          <ng-template pTemplate="header">
            <div >
            <div class="row">
              <div class="col-12 px-0">
            <h5 class="text-primary">Add New Category</h5>
            </div>
            </div>
         
          </div>
          </ng-template>
          <hr class="mt-0"/>
          <div class="row form-group">
            <div class="col-12 pl-0">
              <label>Add New Category Name</label>
              <input name="newCatagoryName" type="text" pInputText [(ngModel)]="newCatagoryName" required/>
            </div>
            </div>
  
          <p-footer>
            <button type="button" pButton icon="pi pi-check" (click)="saveNewCatagory();" [disabled]="newCatagoryName === ''"  label="Save"></button>
            
          </p-footer>
</p-dialog>   



    <p-pickList [disabled]="disableFields" [source]="defaultCatagoryList" [target]="selectedCatagoryList" dragdrop="true" sourceHeader="Categories List" targetHeader="Selected Categories ({{selectedCatagoryList?.length}})"
    [style]="{'width': '100%'}" [showSourceControls]="false"
    [showTargetControls]="false" [sourceStyle]="{'width':'400px'}" [targetStyle]="{'width':'400px'}" (onMoveToTarget)="selectedCatagory($event)" (onMoveAllToTarget)="selectedCatagory($event)">
    <ng-template  pTemplate="emptymessagetarget">
      <div class="row">
        <div class="col-12"><small>No Categories are selected yet.</small></div>
        <div class="col-12"><small>Add the categories to the bucket to proceed</small></div>
      </div>
    </ng-template>    
    <ng-template let-catagory pTemplate="item"> 
            <div class="row" [ngClass]="{'customCategory': catagory?.enableDelete}" >                
                <div class="col-10">{{catagory?.categoryName}}</div>
                <div class="col-2"> 
                    <span *ngIf="catagory?.enableDelete" (click)="confirmDeleteRfeClause(catagory)">
                    <mat-icon class="mr-1 icn" matTooltip="Delete Document" class="material-icons">delete</mat-icon>
                  </span></div>
            </div>
        </ng-template>
    </p-pickList>
</div>
<div class="mt-4 text-center">
  <button *ngIf="!disableFields" type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" 
  (click)="changeStatusToComplete();" label="Mark Complete" class="ml-2 button_whitebg p-button-outlined"></button>
  <div *ngIf="disableFields" class="d-inline-block">
    <img src="../../../../../assets/images/stepCompleteLabel.png" />
  </div> 
  <button class="btn-success button-width-icon-left float-right" 
    icon="fa fa-floppy-o" type="button" pButton [disabled]="disableFields" 
    (click)="saveData();" label="Save"></button> 
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
</div>

<div *ngIf="userType != 'Attorney'" class="p-3 border rounded bg-light">
  <div><strong>Last Updated By : </strong> {{ SubTaskOverView['preparerAttorneyName']}}</div>
  <hr/>
  <div><strong>Status : </strong> {{ SubTaskOverView['taskStepDetails'][1]['stepStatusDetails']['stepStatusName'] }}</div>
  <div><strong>Comment : </strong> {{ SubTaskOverView['taskStepDetails'][1]['stepNote'] }}</div>
</div>
