<button (click)="closeOverlay()" type="button" class="video-button" [ngClass]="{ 'left-position': position === 'left'}"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin" mat-icon-button aria-label="Help Video">
</button>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
  *ngIf="isOpen">
  <div class="overlayContainer border p-3 bg-white rounded shadow">
    <div class="">
      <h5 class="mt-2" *ngIf="tabStructure"><strong>{{ dialogHeader }}</strong></h5>
      <button (click)="isOpen = false" mat-icon-button aria-label="Close" class="icon-button position-absolute"
        style="top:10px;right:10px">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="text-center" *ngIf="!tabStructure">
      <div>
        <!-- <video autoplay controls style="height:300px;">
          <source [src]="videoLink" type="video/mp4">
          Your browser does not support HTML5 video.
        </video> -->

        <!-- <iframe [src]="vid" width="500" height="300" frameborder="0" allow="" encrypted-media [title]="header"></iframe> -->

        <iframe [src]="videoLink" width="500" height="300" frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture" allowfullscreen [title]="header"></iframe>

        <!-- <video autoplay controls style="height:300px;">
          <source src="https://player.vimeo.com/video/518583749?app_id=58479" type="video/mp4">
          Your browser does not support HTML5 video.
        </video> -->


      </div>
    </div>

    <div *ngIf="tabStructure">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab [label]="header">
          <iframe [src]="videoLink" width="500" height="250" frameborder="0" allow="autoplay" allowfullscreen></iframe>
        </mat-tab>
        <mat-tab [label]="header1">
          <iframe [src]="videoLink1" width="500" height="250" frameborder="0" allow="autoplay" allowfullscreen></iframe>
        </mat-tab>
      </mat-tab-group>
    </div>

    <hr class="mb-0 ml-1" />
    <div class="footer">
      <div class="text-left">
        <h6 class="d-inline-block mb-4"><strong>Assistance at any time:</strong></h6>
        <a (click)="routeToResourcePage()"
          class="back-button button-width-icon-left btn-pos-top-right btn button_whitebgImp float-right">
          <i class="fa fa-question-circle"></i>
          Video Help
        </a>
      </div>
      <hr class="mt-0 ml-1" />
      <button class="link-button btn" (click)="isOpen = false">Dismiss</button>
    </div>
  </div>

</ng-template>