import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { ApiSponserPersonalDetailService } from './api-sponser-personal-detail.service';
import { DEPENDENT_VISAS } from 'app-models';
import { Router } from '@angular/router';
import * as RolesRouteMap from 'app-models';
import { AppServicesLibraryService } from 'app-services-library';


@Component({
  selector: 'app-immigration-history',
  templateUrl: './immigration-history.component.html',
  styleUrls: ['./immigration-history.component.scss']
})
export class ImmigrationHistoryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() primaryData: PrimaryData;
  @Input() type: string;
  observableSubscription$ = new Subject();
  @Input() userId: number;
  beneficiaryPersonalDetails: any;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  requiredFields: any;
  missingValueFalg: boolean;
  isDependentVisa: any;
  userType: string;
  questionnaire: boolean;
  isEditImmigration: boolean = false;
  selectedState = 'simple';

  constructor(
    public store: Store<any>,
    public apiService: ApiSponserPersonalDetailService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    public router: Router,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.questionnaire = false;
    this.requiredFields = {};
    this.userType = sessionStorage.getItem('userTypeRole');
    this.questionnaire = window.location.pathname === "/beneficiary-profile/profile" ? true : false;
  }

  ngOnInit() {
    this.isDependentVisa = DEPENDENT_VISAS.includes(this.primaryData.visatype);
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
          this.getBeneProfileInformation();
        }
      });
  }

  ngOnChanges() {
    if (this.primaryData && this.userId) {
      this.primaryData = {...this.primaryData, familyId: this.primaryData.familyId ?? (this.type === 'family' && this.userId ? this.userId: null)}
      this.getBeneProfileInformation();
    }
  }

  getBeneProfileInformation() {
    if (this.type && this.type === 'family') {
      this.apiService.getBeneficiaryFamilyProfileInformation(
        this.primaryData.visatype,
        this.userId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          this.beneficiaryPersonalDetails = response.data;
        });

    } else {
      this.apiService.getBeneficiaryProfileInformation(
        this.primaryData.visatype,
        this.type && this.type === 'family' ? this.type : 'beneficiary',
        this.userId ? this.userId : this.primaryData.beneficiaryId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          this.beneficiaryPersonalDetails = response.data;
        });

    }

  }

  handleVerify() {
    this.missingValueFalg = false;
    this.requiredFields = {
      alienRegNo: false,
      benForeignAddress: { // IM-7655 fix as per userstory this fields are not mandataory
        addressLine1: false,
        city: false,
        countryName: false,
        postCode: false,
        state: false,
      },
      benCurrentAddress: {
        addressLine1: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
        city: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
        countryName: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
        postCode: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
        state: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      },
      birthCity: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      birthCountry: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      birthState: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      citizenShip: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      countryCode: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      dob: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      emailId: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      firstName: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      title: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      gender: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      hasBeenTravelledToUs: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      passport: false,
      immigrationDetails: {
        placeOfLastArrival: false,
        modeOfTravel: false,
        city: false, // there is no field in UI
        state: false, // there is no field in UI
        arrivalDate: false,
        admittedAs: false,
        classOfAdmission: false,
        i94ExpiryDate: false,
        i94Number: false
      },
      lastName: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      mobileNo: this.beneficiaryPersonalDetails.hasBeenTravelledToUs,
      signImage: false
    };
    if (!this.iterateRequiredFileds(this.requiredFields, null)) {
      if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        this.updateStepStatus('INPROGRESS');
      }
      this.toastr.success('Fields validation is successful', 'Success');
    } else {
      this.toastr.info('Check the missing fields');
    }
  }

  iterateRequiredFileds(obj, parentKey) {
    if (!this.missingValueFalg) {
      for (const key in obj) {
        if (parentKey) {
          // for object inside Obj
          if (obj[key] && (this.beneficiaryPersonalDetails[parentKey] === null ||
            this.beneficiaryPersonalDetails[parentKey][key] === null ||
            this.beneficiaryPersonalDetails[parentKey][key] === undefined ||
            this.beneficiaryPersonalDetails[parentKey][key] === '')) {
            this.missingValueFalg = true;
            break;
          }
        } else if (key === 'immigrationDetails' || key === 'benForeignAddress' || key === 'benCurrentAddress') {
          this.iterateRequiredFileds(this.requiredFields[key], key);
        } else if (this.beneficiaryPersonalDetails[key] && (typeof this.beneficiaryPersonalDetails[key] === 'object')) {
          this.iterateRequiredFileds(this.requiredFields[key], key);
        } else {
          // for dirct child within Obj
          if (obj[key] && (this.beneficiaryPersonalDetails[key] === null ||
            this.beneficiaryPersonalDetails[key] === undefined ||
            this.beneficiaryPersonalDetails[key] === '')) {
            this.missingValueFalg = true;
            break;
          }
        }
      }
    }
    return this.missingValueFalg;
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT' ? true : false;
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }
  updateImmigration() {
    this.isEditImmigration = !this.isEditImmigration;
    // let a = window.location.href
    // if (a.toString().includes('/beneficiary-profile')) {
    //   window.location.href = window.location.origin + '/beneficiary-profile/profile?prop=BENE&section=4';
    // } else {
    //   if ((this.primaryData.visatype === "K3" && this.primaryData.layoutCode === "BENEFICIARY_DETAIL") ||
    //     (this.primaryData.visatype === "ASYLUM" && this.primaryData.layoutCode === "SPOUSE_DETAIL") ||
    //     (this.primaryData.visatype === "K1" && this.primaryData.layoutCode === "BENEFICIARY_DETAIL") || (this.primaryData.jsonInput && this.primaryData.jsonInput.primaryApplicant && this.primaryData.jsonInput.primaryApplicant.relationshipCode)  || (this.primaryData.jsonInput && this.primaryData.jsonInput.applicantsList  && this.primaryData.layoutCode !== "BENEFICIARY_DETAIL")) {
    //     sessionStorage.setItem('navigationFamilySection', 'true');
    //     if(this.primaryData.jsonInput && this.primaryData.jsonInput.applicantsList && !this.primaryData.jsonInput.primaryApplicant){
    //       const applicant = this.primaryData.jsonInput.applicantsList.filter((x)=> x.relationshipCode === this.stepDetails['stepApplTo']);
    //       if(applicant.length){
    //         sessionStorage.setItem('relationshipCode', applicant[0].relationshipCode);
    //         sessionStorage.setItem('relativeId', applicant[0].id);
    //       }else if(this.stepDetails['stepApplTo'] === 'SECAPPLCNT' && this.primaryData.jsonInput.applicantsList.length === 1){
          //   sessionStorage.setItem('relationshipCode', this.primaryData.jsonInput.applicantsList[0].relationshipCode);
          //   sessionStorage.setItem('relativeId', this.primaryData.jsonInput.applicantsList[0].id);
          // }
    //     }else if(this.primaryData.jsonInput && this.primaryData.jsonInput.primaryApplicant && this.primaryData.jsonInput.primaryApplicant.relationshipCode){
    //       sessionStorage.setItem('relationshipCode', this.primaryData.jsonInput.primaryApplicant.relationshipCode);
    //       sessionStorage.setItem('relativeId', this.primaryData.jsonInput.primaryApplicant.id);
    //     }
    //   }
    //   sessionStorage.setItem('bene-profile-viewMode', 'false');
    //   let listBeneficiaryId = (sessionStorage.getItem('beneficiaryId'));
    //   sessionStorage.setItem('listBeneficiaryId', listBeneficiaryId);
    //   let url;
    //   if (this.userType === RolesRouteMap.ATTORNEY) {
    //     url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiary/profile/immigration`, { listview: true }];
    //   } else {
    //     url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/beneficiaries/profile/immigration`, { listview: true }];
    //   }
    //   this.router.navigate(url, { state: { redirectUrl: this.router.url, redirectPage: 'Back to Petition Builder' } });
    //   sessionStorage.setItem('bene-update-experience', 'true');
    //   sessionStorage.removeItem('bene-update-profile');
    // }
  }

  onChange($event) {
    this.selectedState = $event.value;
  }
}
