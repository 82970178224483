<div class="row">
  <div class="col-md-12 text-right">
    <button (click)="closeEdit()" button pButton class="p-button-outlined marginbottom">
      <i class="close-icon pi pi-times"></i> Close Edit
    </button>
  </div>
</div>
<div class="col-12 text-right mb-3" >
  <mat-button-toggle-group [value]="selectedProfileType" (change)="onChangeProfile($event)">
      <mat-button-toggle value="simple">Simple Profile</mat-button-toggle>
      <mat-button-toggle value="complete">Complete Profile</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<ng-container *ngIf="selectedProfileType === 'simple'">
  <lib-simple-education-detail-library [primaryData]="inputData" [isWizard]="false"></lib-simple-education-detail-library>
</ng-container>
<ng-container *ngIf="selectedProfileType === 'complete'">
  <p-tabView>
    <p-tabPanel header="Education">
        <edit-complete-add-education-details [editEduData]="inputData"></edit-complete-add-education-details>
    </p-tabPanel>
    <p-tabPanel header="Trainings">
      <ng-template pTemplate="content">
        <edit-complete-training-details [editTrainingData]="inputData"></edit-complete-training-details> 
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Licenses or Certifications">
        <ng-template pTemplate="content">
            <edit-complete-license-details [editLicenceData]="inputData"></edit-complete-license-details>
        </ng-template>
    </p-tabPanel>
  </p-tabView>
</ng-container>
