import { NgModule } from '@angular/core';
import { PetitionerLandingRoutingModule } from './petitioner-routing.module';
import { PetitionerLandingComponent } from './petitioner-landing/petitioner-landing.component';
import { PetitionerMenuComponent } from './petitioner-menu/petitioner-menu.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { PetitionAnalysisModule } from '../petition-analysis/petition-analysis.module';
import { JoyrideModule } from 'ngx-joyride';
import { SharedModule } from '../shared/shared.module';
import { PetitionAnalysisContainerModule } from '../petition-analysis-container/petition-analysis.module';
import { TaskAllocationModule } from '../task-allocation/task-allocation.module';
import { CaseStatusModule } from '../case-status/case-status.module';
import {CalendarModule} from 'primeng/calendar';
import { VisaBulletinDetailsComponent } from './visa-bulletin-details/visa-bulletin-details.component';


@NgModule({
  imports: [
    CommonModule,
    PetitionerLandingRoutingModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatIconModule,
    PetitionAnalysisModule,
    NgxPermissionsModule.forChild(),
    JoyrideModule.forChild(),
    SharedModule,
    TaskAllocationModule,
    CaseStatusModule,
    CalendarModule
  ],
  declarations: [
    PetitionerLandingComponent,
    PetitionerMenuComponent,
    VisaBulletinDetailsComponent
  ]
})

export class LandingPetitionerModule { }
