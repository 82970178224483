import { Component, OnInit } from '@angular/core';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { take } from 'rxjs/operators';
import { ImagilityWebStorage } from 'src/app/services/webStorage.service';

@Component({
  selector: 'platform-statistics',
  templateUrl: './platform-statistics.component.html',
  styleUrls: ['./platform-statistics.component.scss']
})
export class PlatformStatisticsComponent implements OnInit {
  isAttorneyStatsEnabled: boolean = false;
  isPetitionerStatsEnabled: boolean = false;
  isBeneficiaryStatsEnabled: boolean = false;
  // isPlatformAdminStatsEnabled: boolean;
  isPetitionerAdminStatsEnabled: boolean = false;
  isAttorneyAdminStatsEnabled: boolean = false;
  isPlatformAdminStatsEnabled: boolean = false;
  platformStatList;
  numberOfAttorneys = 0;
  numberOfPetitioners = 0;
  numberOfBeneficiaries;
  numberOfProjects;
  numberOfClients;
  numberOfRoles;
  numberOfUsers; numberOfCompanies;
  userType;
  permissions = [];
  constructor(
    private dashboardWidgetsService: DashboardWidgetsService,
    private authenticationService: AuthenticationService,
    private webStorage: ImagilityWebStorage) { }

  ngOnInit() {
    this.authenticationService.currentUser
    .pipe(take(1))
    .subscribe((data) => {
      if (data) {
        this.userType = data['userType'];
        this.permissions = data['permissions'];

        // data['permissions'].find(el => {
        //   if(el === 'ATTORNEY_STATS') {
        //     this.isAttorneyStatsEnabled = true;
        //   }
        // });
        // data['permissions'].find(el => {
        //   if(el === 'ATTORNEY_ADMIN_STATS') {
        //     this.isAttorneyAdminStatsEnabled = true;
        //   }
        // });
        // data['permissions'].find(el => {
        //   if(el === 'PETITIONER_STATS') {
        //     this.isPetitionerStatsEnabled = true;
        //   }
        // });
        // data['permissions'].find(el => {
        //   if(el === 'PETITIONER_ADMIN_STATS') {
        //     this.isPetitionerAdminStatsEnabled = true;
        //   }
        // });
        // data['permissions'].find(el => {
        //   if(el === 'BENEFICIARY_STATS') {
        //     this.isBeneficiaryStatsEnabled = true;
        //   }
        // });
        // data['permissions'].find(el => {
        //   if(el === 'PLATFORM_ADMIN_STATS') {
        //     this.isPlatformAdminStatsEnabled = true;
        //   }
        // });
        this.dashboardWidgetsService.getPlatformStatistics()
          .pipe(take(1))
          .subscribe(Response => {
            this.platformStatList = Response;
            if (this.platformStatList.status === 200) {
              this.numberOfAttorneys = this.platformStatList.data['number_of_attorneys'];
              this.numberOfPetitioners = this.platformStatList.data['number_of_petitioners'];
              this.numberOfBeneficiaries = this.platformStatList.data['number_of_beneficiaries'];
              // this.numberOfProjects = this.platformStatList.data['number_of_projects'];
              this.numberOfClients = this.platformStatList.data['number_of_clients'];
              this.numberOfRoles = this.platformStatList.data['number_of_roles'];
              this.numberOfUsers = this.platformStatList.data['number_of_users'];
              this.numberOfCompanies = this.platformStatList.data['number_of_companies'];
            }
          });
      }
    });


  }

}
