

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BeneficiaryGuardService } from './beneficiary-guard.service';
import { BeneficiaryLandingComponent } from './beneficiary-landing/beneficiary-landing.component';
import { TermsConditionsComponent } from '../shared/footer/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '../shared/footer/privacy-policy/privacy-policy.component';
import { BeneficiaryInformationComponent } from '../beneficiary/beneficiary-information/beneficiary-information.component';
import { BillingPaymentComponent } from '../billing-payment/billing-payment.component';

const testRoutes: Routes = [
    {
        path: 'beneficiary-landing',
        component: BeneficiaryLandingComponent,
        children: [
            {
                path: 'beneficiaries',
                loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
                data: { preload: true }
            },
            {
                path: 'questionnier',
                component: BeneficiaryInformationComponent
            },
            {
                path: 'petitions',
                loadChildren: () => import('../manage-petitions/manage-petitions.module').then((mod) => mod.ManagePetitionsModule),
                data: { preload: true }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard-beneficiary/dashboard-beneficiary.module').then((mod) => mod.BeneficiaryDashboardModule),
                data: { preload: true }
            },
            {
                path: 'notifications',
                loadChildren: () => import('../../modules/notifications/notifications.module').then((mod) => mod.NotificationsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('../../modules/settings/settings.module').then(m => m.SettingsModule),
                data: { preload: true }
            },
            {
                path: 'help',
                loadChildren: () => import('../../modules/help/help.module').then(m => m.HelpModule),
                data: { preload: true }
            },

            // {
            //     path: 'billing',
            //     loadChildren: () => import('../billing-payment/billing-payment.module').then(m => m.BillingPaymentModule),
            //     data: { preload: true }
            // },
            // {
            //     path: 'companies',
            //     loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
            //   },
            {
                path: 'compliance',
                loadChildren: () => import('../compliance/compliance.module').then((mod) => mod.ComplianceModule),
              },
              {
                path: 'billing',
                loadChildren: () => import('../billing-payment/billing-payment.module').then((mod) => mod.BillingPaymentModule)
              },
              {
                path: 'forms',
                loadChildren: () => import('../i9-beneficiary/i9-beneficiary.module').then((mod) => mod.I9BeneficiaryModule),
              },

            {
                path: 'resources',
                loadChildren: () => import('../../modules/resources/resources.module').then(m => m.ResourcesModule),
                
            },
            {
                path: 'myforms',
                loadChildren: () => import('../../modules/manage-myforms/manage-myforms.module').then(m => m.ManageMyformsModule),
                
            },
            {
                path: 'faq',
                loadChildren: () => import('../../modules/faq/faq.module').then((mod) => mod.FaqModule),
              },
            {
                path: 'terms-and-conditions',
                component: TermsConditionsComponent,
            },
            {
                path: 'privacy',
                component: PrivacyPolicyComponent,
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
        ],
        canActivate: [BeneficiaryGuardService],
        runGuardsAndResolvers: 'always'
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'beneficiary-landing'
    }
];

@NgModule({
    imports: [RouterModule.forChild(testRoutes)],
    exports: [RouterModule],
    providers: []
})
export class BeneficiaryLandingRoutingModule { }