import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmploymentEssentialCriticalCapacityService {

  constructor(private http:HttpClient) { }
  saveAdditionalInfo(subtask,payload){
    return this.http.post(`/${subtask.visatype}/${subtask.caseType}/${subtask.caseId}/additionalInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getAdditionalInfo(subtask,infoType:string){
    return this.http.get(`/${subtask.visatype}/${subtask.caseType}/${subtask.caseId}/additionalInfo/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }else {
          return [];
        }
      })
    );
  }
}
