import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';

@Injectable({
    providedIn: 'root'
})

export class PetitionAnalysisService {

    selectedPACategory: any;
    constructor(private http: HttpClient) {
    }


    // Petition Analysis
    getSummery(petitionId) {
        return this.http.get(`/analysis/petition/${petitionId}/summary`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response
                }
            })
        );
    }

    getCategorySummary(petitionId) {
        return this.http.get(`/analysis/petition/${petitionId}/categorySummary`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response
                }
            })
        );
    }

    getCategorySummeryOnCategoryCode(petitionId, categoryCode) {
        return this.http.get(`/analysis/petition/${petitionId}/categorySummary?category_code=${categoryCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 || response.status === 204) {
                    return response
                }
            })
        );
    }

    getCategoryObervation(petitionId) {
        return this.http.get(`/analysis/petition/${petitionId}/observation`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getCategoryObervationCode(petitionId, categoryCode) {
        return this.http.get(`/analysis/petition/${petitionId}/observation?category_code=${categoryCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getPACategoryList() {
        return this.http.get(`/analysis/petition/category`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getPACategory(petitionId) {
        return this.http.get(`/analysis/petition/${petitionId}/category`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    generateAnalysis(petitionId) {
        return this.http.post(`/analysis/petition/${petitionId}/All`, {}).pipe(
            map((response: ImagilityBaseResponse) => response)
        )
    }


    getPetitionAnalysisStatus(petitionId) {
        return this.http.get(`/analysis/petition/${petitionId}/status`).pipe(
            map((response: ImagilityBaseResponse) => response)
        )
    }
}