import { Component, ComponentFactoryResolver, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TaskStep, ImagilityBaseResponse, PrimaryData } from 'app-models';
import { PlaceHolderDirective } from 'place-holder-library';
import { Subject } from 'rxjs/internal/Subject';
import { updateStepStatus, loadSubTaskDetails, getStepDetails, getStepStatusUpdate, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { first } from 'rxjs/internal/operators/first';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ToastrService } from 'ngx-toastr';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { combineLatest } from 'rxjs';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { OccupationDetailsComponent } from '../occupation-details/occupation-details.component';
import { OccupationDeterminationComponent } from '../occupation-determination/occupation-determination.component';
import { HelpVideoOverlayLibraryComponent } from 'help-video-overlay-library';

@Component({
  selector: 'app-speciality-occupation',
  templateUrl: './speciality-occupation.component.html',
  styleUrls: ['./speciality-occupation.component.scss']
})
export class SpecialityOccupationComponent implements OnInit {

  isLastTab: boolean;
  selectedItem: number;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  @ViewChild(PlaceHolderDirective, { static: true }) placeHolder: PlaceHolderDirective;
  @ViewChild(HelpVideoOverlayLibraryComponent, { static: false })  helpVideoComponent: HelpVideoOverlayLibraryComponent;

  observableSubscription$ = new Subject();

  @Input()
  primaryData;

  @Output()
  onDialogClose = new EventEmitter<any>();

  public inputData: PrimaryData;
  showAdditionalDetails: boolean;

  constructor(
    public toastr: ToastrService,
    public store: Store<any>,
    public guard: DirtyFormGuard,
    public componentFactoryResolver: ComponentFactoryResolver,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
  ) {
    this.showAdditionalDetails = false;
    this.selectedItem = 0;
    this.isLastTab = false;
    this.inputData = this.dynamicDialogConfig.data;
  }

  ngOnInit(): void {
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    this.store.pipe(select(getStepDetails, { id: this.inputData.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      if (this.selectedItem === 0) {
        this.handleOccupationDetermination();
      }
    });
  }

  ngAfterViewInit() {
    this.helpVideoComponent.setValues('right','Importance of Specialty Occupation','Importance of Speciality Occupation');
  }

  loadComponent(component) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.placeHolder.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(componentFactory);
    componentRef.instance.primaryData = this.inputData;
  }

  handleOccupationDetermination() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.isLastTab = false;
        this.selectedItem = 0;
        this.loadComponent(OccupationDeterminationComponent);
      }
      else {
        return false;
      }
    });

  }

  handleOccupationDetails(isAdditional?) {
    if(isAdditional){
      this.showAdditionalDetails = true;
    }else{
      this.showAdditionalDetails = false;
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.isLastTab = true;
        this.selectedItem = 1;
        this.loadComponent(OccupationDetailsComponent);
      }
      else {
        return false;
      }
    });
  }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}

