<div class="fluid-container p-3">
    <!-- <h5>
      Professional Publications
    </h5> -->
    <h6 class="heading p-text-bold">Professional Publications</h6>
    <form [formGroup]="publicationsForm">      
        <div *ngIf="hideAddAwardSection">
            <label>Add Award Details</label>
            <button [disabled]="disabledControls"  pButton
            (click)="toggleAddSection()" class="float-right">
            Add more</button>
          </div>
    <div class="p-fluid p-formgrid  mb-4" *ngIf="!hideAddAwardSection">
    <div class="row">
       <div class="col px-0">
        <label for="title" class="required">Title</label>
        <input pInputText type="text" name="title" formControlName="title"
          [disabled]="disabledControls" required /> 
        <span class="invalid-message" *ngIf="publicationsForm.get('title').touched && publicationsForm.get('title').errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
        </span> 
      </div>      
    </div>
    <div class="row mt-2">
     <div class="col-4 px-0">
            <label for="publishedDate" class="required">Published Date</label>
            <p-calendar appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                 name="publishDate" formControlName="publishedDate" [monthNavigator]="true" [yearNavigator]="true"
                [maxDate]="currentDate" yearRange="1950:2050">
            </p-calendar>
            <span class="invalid-message" *ngIf="publicationsForm.get('publishedDate').touched && publicationsForm.get('publishedDate').errors?.required">
                  {{ getGlobalErrorMessages("REQUIRED") }}
            </span> 
      </div>
      <div class="col-8">
        <label for="author" class="required">Author</label>
        <input pInputText type="text" name="author" formControlName="author"
          [disabled]="disabledControls" required /> 
        <span class="invalid-message" *ngIf="publicationsForm.get('author').touched && publicationsForm.get('author').errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
        </span> 
      </div>
    </div>
  
    <div class="row mt-4">
       <div class="col-2 px-0 addBtnHover">
          <button type="submit" [disabled]="disabledControls || !publicationsForm.valid"
          (click)="addPublication();" class="btn btn-primary btn-add">
          <i aria-hidden="true" class="fa fa-plus-circle"></i>{{addEditButtonText}}</button>
        </div>
        <!-- <div class="col-2">
          <button pButton  type="button" label="Cancel" pRipple  class="p-button-secondary" [disabled]="disabledControls" (click)="toggleAddSection()">
          </button>
        </div>  -->
    </div>
    </div>
    <div class="row w-100" *ngIf="addedPublication.length > 0">
      <!-- <label class="text-primary">Added Details</label> -->
      <h6 class="heading p-text-bold mb-3">Added Details</h6>
    </div>
      <p-table [value]="addedPublication" class="table-striped-simple">
        <ng-template pTemplate="header">
          <tr>
              <th class="col-3">Title</th>
              <th class="col-3">Author</th>
              <th class="col-4">Published Date</th>
              <th class="col-2 px-0 text-center">Action</th>
          </tr>
      </ng-template>
              <ng-template pTemplate="body" let-doc>
                <tr>
                  <td class="col-3" >{{(doc.title)? doc.title : '--'}}</td>
                  <td class="col-3" >{{(doc.author)? doc.author: '--'}}</td>
                  <td class="col-4" >{{doc?.publishedDate | date:'MM/dd/yyyy'}}</td>
                  <td class="col-2 px-0 text-center">
                    <button pButton type="button" title="Edit Publication" 
                        (click)="editPublication(doc)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                        <mat-icon class="mr-1 icn" matTooltip="Edit" >edit</mat-icon>
                    </button>
                    <button pButton type="button" title="Delete Publication"
                        (click)="deletePublication(doc.publicationId)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                        <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                    </button>
                
              </td>
              </tr>
              </ng-template>
      </p-table> 
      <div *ngIf="addedPublication.length == 0" class="border w-100 p-2">
        <p class="text-secondary m-0"> Currently No details added!</p>
      </div>
  
    <p-divider type="dashed" class="mt-4"></p-divider>
  
    <!-- <label>Upload Publication Document</label> -->
    <h6 class="heading p-text-bold mb-3">Upload Publication Document</h6>
    <i140-file-upload style="width: 100%;"
      [fileUploadConfigurations]="fileUploadConfigurations">
    </i140-file-upload>
  
  
    <p-divider type="dashed" class="mt-4"></p-divider>
    <!-- <label>Summary</label> -->
    <h6 class="heading p-text-bold">Summary</h6>
    <div class="col-12 px-0">
      <!-- <textarea [(froalaModel)]="additionalInfo"
        name="text" [froalaEditor]="options"></textarea> -->
        <kendo-editor [(value)]="additionalInfo" (valueChange)="professionalpublicationseditorValueChange($event)" #proffessionalkendoeditor style="height: 400px;" formControlName="text" name="text">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
              <kendo-toolbar-button
              title="Upload’s Image at cursor point"
              text="Upload Image"
              (click)="openproffessionalimageuploaddialog()"
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>                                  
          </kendo-toolbar>
      </kendo-editor>
      <app-upload-image-dialog #proffessionalimageupload [editor]="proffessionalkendoeditor"></app-upload-image-dialog>     
    </div>  
    <br>

    <div class="row">
      <div class="col-md-3">
        <button pButton type="button" [disabled]="disabledControls" (click)="saveAdditionalInfo()" class="d-inline-block float-left saveBtn">Save</button>

      </div>
      <div class="col-md-6 completeStep">
        <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showMarkComplete" [disabled]="stepStatus !== 'INPROGRESS'"
        class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg stepCompleted" (click)="onMarkComplete()">
        <span class="btn-text">{{markStep}}</span>
        <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
      </button>
      <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
      </div>
  
    </div>

</form>
</div>