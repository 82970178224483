export const BENE_SELF_WRITE = 'Beneficiary_Self_Write';
export const BENE_SELF_READ = 'Beneficiary_Self_Read';
export const BENE_SPOUSE_WRITE = 'Beneficiary_Spouse_Write';
export const BENE_SPOUSE_READ = 'Beneficiary_Spouse_Write';
// export const BENE_SELF_WRITE = 'Beneficiary_Self_Write';
// export const BENE_SELF_READ = 'Beneficiary_Self_Read';
// export const BENE_SELF_WRITE = 'Beneficiary_Self_Write';
// export const BENE_SELF_READ = 'Beneficiary_Self_Read';
export const USER_PERMISSIONS = {
    ASSIGN_PERMISSION: 'ASSIGN_PERMISSION',
    ASSIGN_ROLE: 'ASSIGN_ROLE',
    DEL_PETITION: 'DEL_PETITION',
    DELETE_COMPANY: 'DELETE_COMPANY',
    DELETE_PERMISSION: 'DELETE_PERMISSION',
    DELETE_PETITION: 'DELETE_PETITION',
    EDIT_BENEFICIARY: 'EDIT_BENEFICIARY',
    EDIT_COMPANY: 'EDIT_COMPANY',
    EDIT_EMPLOYEES: 'EDIT_EMPLOYEES',
    EDIT_LINK: 'EDIT_LINK',
    EDIT_PERMISSION: 'EDIT_PERMISSION',
    EDIT_PETITION: 'EDIT_PETITION',
    EDIT_ROLE: 'EDIT_ROLE',
    UNASSIGN_ROLE: 'UNASSIGN_ROLE',
    VIEW_ASSOC_COMPANY: 'VIEW_ASSOC_COMPANY',
    VIEW_BENEFICIARY: 'VIEW_BENEFICIARY',
    VIEW_COMPANY: 'VIEW_COMPANY',
    VIEW_EMPLOYEES: 'VIEW_EMPLOYEES',
    VIEW_PERMISSION: 'VIEW_PERMISSION',
    VIEW_PETITION: 'VIEW_PETITION',
    VIEW_ROLE: 'VIEW_ROLE',
    PETITIONER_STATS: 'PETITIONER_STATS',
    PETITION_REVIEWER: 'PETITION_REVIEWER',
    VIEW_QUESTIONNAIRE: 'VIEW_QUESTIONNAIRE',
    CREATE_EDIT_QUESTIONNAIRE: 'CREATE_EDIT_QUESTIONNAIRE',
}



