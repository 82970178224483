<div class="fluid-container">
  <form [formGroup]="projectDetailsForm" (ngSubmit)="postProjectDetails()" class="pb-3 pt-2">
    <div class="main-container">
      <div class="row"  *ngIf="editorName">
        <div class="col-md-12">
          <h6><strong>{{ editorName }}</strong> <span *ngIf="editorSubName">{{ editorSubName }}</span></h6>
        </div>
      </div>
      <div class="row mb-3" *ngIf="showProjectName && (!payload || (payload && !payload.projectId)) ">
        <div class="col-md-6 form-group input-disabled">
          <label class="label-cls required">Project Name</label>
          <input pInputText type="text" name="projectName" formControlName="editorTitle"
            class="im-input  mw-100 disable" [readonly]="disabledProjectName" />
          <small
            *ngIf="!projectDetailsForm.get('editorTitle').valid && projectDetailsForm.get('editorTitle').touched && projectDetailsForm.get('editorTitle').errors"
            class="p-error">
            <span *ngIf="projectDetailsForm.get('editorTitle').errors['required']">Required</span>
          </small>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12">
          <label class="label-cls" *ngIf="editorLabel">{{ editorLabel }}</label>
          <div class="beneficiary-experience-froala-editor" *ngIf="!disabledControls">
            <!-- <textarea [froalaEditor]="options" formControlName="editorValue"></textarea> -->
            <kendo-editor #projectdetailskendoeditor style="height: 500px;" formControlName="editorValue">
              <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                  <kendo-toolbar-button
                  title="Upload’s Image at cursor point"
                  text="Upload Image"
                  (click)="projectdetailsimageuploaddialog()"
                  ></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
              </kendo-toolbar>
          </kendo-editor>
          <app-upload-image-dialog #projectdetailsimageupload [editor]="projectdetailskendoeditor"></app-upload-image-dialog>
            <small
              *ngIf="!projectDetailsForm.get('editorValue').valid && projectDetailsForm.get('editorValue').touched && projectDetailsForm.get('editorValue').errors"
              class="p-error">
              <span *ngIf="projectDetailsForm.get('editorValue').errors['required']">Required</span>
            </small>
          </div>
          <div *ngIf="disabledControls">
            <div [innerHTML]="projectDetailsForm.get('editorValue').value">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p-divider></p-divider>
      </div>
    </div>
    <div class="row mb-3" *ngIf="tabIndex === 1 || (payload && payload.projectId)">
      <div class="col-md-12">
        <mat-radio-group formControlName="selectedRadioValue">
          <div class="row">
            <mat-radio-button name="groupname" [value]="0"><label class="label-cls">Update data only for this
                petition</label> </mat-radio-button>
          </div>
          <div class="row">
            <mat-radio-button name="groupname" [value]="1"><label class="label-cls">Add/Update data in Master
                data</label> </mat-radio-button>
          </div>
        </mat-radio-group>
        <!-- <div class="col-md-12 mb-1">
        <p-radioButton name="groupname" label="Update data only for this petition" [value]="0"
          formControlName="selectedRadioValue"></p-radioButton>
      </div>
      <div class="col-md-12">
        <p-radioButton name="groupname" label="Add/Update data in the master data" [value]="1"
          formControlName="selectedRadioValue"></p-radioButton>
      </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="isSimplifyH1b ? false : true">
        <button type="submit" label="Save" class="btn btn-primary"
          [ngClass]="projectDetailsForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="projectDetailsForm.invalid  || disabledControls">Save</button>
      </div>
      <div class="col-md-12 alignRight" *ngIf="isSimplifyH1b ? true : false">
        <button type="submit" label="Save & Continue" class="btn btn-primary pull-right"
          [ngClass]="projectDetailsForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="projectDetailsForm.invalid  || disabledControls">Save & Continue</button>
      </div>
    </div>
  </form>
</div>
