import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResearchContributionService {

  constructor(private http:HttpClient) { }

  deleteResearch(petitionId,researchId){
    return this.http.delete(`/extraordinaryAbility/petition/${petitionId}/research/${researchId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  getList(petitionId){
    return this.http.get(`/extraordinaryAbility/petition/${petitionId}/research/info `).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  saveReserach(petitionId,payload){
    // return this.http.post(`/${type}/${caseType}/${caseId}/additionalInfo`,payload).pipe(
      return this.http.post(`/extraordinaryAbility/petition/${petitionId}/research/createOrUpdate`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }

  getSummaryInfo(type,caseType,caseId,infoType){
    return this.http.get(`/${type}/${caseType}/${caseId}/additionalInfo/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  saveSummary(type,caseType,caseId,payload){
    return this.http.post(`/${type}/${caseType}/${caseId}/additionalInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }


  
}
