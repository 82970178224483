import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { environment } from '../../../../../environments/environment';
import { RfeService } from "src/app/services/rfe.service";
import { ToastrService } from "ngx-toastr";
import {ConfirmationService} from 'primeng/api';
import { acceptedFileTypes } from "app-models";
import { CustomErrorToastComponent } from "custom-error-toast";
@Component({
  selector: 'app-rfe-supporting-documents',
  templateUrl: './rfe-supporting-documents.component.html',
  styleUrls: ['./rfe-supporting-documents.component.scss']
})
export class RfeSupportingDocumentsComponent implements OnInit {

  @Input() rfeTimelineModalInput:any;
  @Input() notificationComments: any[] = [];
 // @Input() notificationDocuments: any[] = [];
  @Input() rfeNoticeId: any;
  @Output() changeSubStepStatus = new EventEmitter<any>();
  supportingDocuments: any[] = [];
  displaySupportingDocuments: boolean = true;
  selectedDocType: any;
  customDocType;
  uploadedFile: any;
  selectedCategory: any;
  documentType: any[] = [];
  categories: any[] = [];
  showFileuploadSection: boolean = false;
  showForOtherDocs: boolean = false;
  //missingNotificationDocuments: any[] = [];
  missingNotificationDocuments = new Map();
  missingDocs:any [];
  missingDocCategoryId:any;
  notificationDocuments: any[] = [];
  rfeSubSteps:any[] = [];
  updateDocumentStepStatus:any;
  notificationIds:any[] = [];
  notificationObj:any = {};
  userType:string;
  acceptedTypes = acceptedFileTypes;
  constructor(private rfeService: RfeService, private toastr: ToastrService,private confirmationService:ConfirmationService) { }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.getCatagories();
    this.getSupportingDocuments();
    this.getRFENotification(this.rfeNoticeId);
    if (this.notificationDocuments) {
      this.modifyData();
    }
   // this.getRfeSubSteps();

  }

//   getRfeSubSteps(){
//     this.rfeService
//    .getRfeAttorneySteps(this.rfeTimelineModalInput["taskNumber"],this.rfeTimelineModalInput["parentStepId"])
//    .subscribe((data) => {
//      this.rfeSubSteps = data;
//      this.rfeSubSteps.forEach( (element) => { 
//        if(element["stepName"] === "Upload Documents"){
//          this.updateDocumentStepStatus = element;

//        }

//      });
    
//      console.log("this.rfeSubSteps",this.rfeSubSteps);
//    })
//  }

  ngOnChanges(e: SimpleChanges) {

    //this.modifyData();
   // console.log("$$$$ notificationDocuments", this.notificationDocuments)

  }

  modifyData() {
    this.missingDocs = [];
   this.missingNotificationDocuments.clear();
   if (this.notificationDocuments.length > 0) {
      for (let item of this.notificationDocuments) {
        if (this.missingNotificationDocuments && this.missingNotificationDocuments.size > 0 && this.missingNotificationDocuments.get(item.categoryName) != null) {
          let temp = this.missingNotificationDocuments.get(item.categoryName);
          //let singleDoc = temp.missingDocArray;
          let singleDoc:any = {};
          singleDoc.notifyTo = item.notifyTo;
          singleDoc.notifiedDate = item.notifiedDate;
          singleDoc.notificationId = item.notificationId;
          singleDoc.comments = item.comments;
          singleDoc.titleSheetDetailDocName = item.titleSheetDetailDocName;
          singleDoc.titleSheetDetailId = item.titleSheetDetailId;
          singleDoc.docList = item.docList;
          temp.missingDocArray.push(singleDoc);
          this.missingNotificationDocuments.set(item.categoryName, temp);
        } else {
          let temp: any = {};
          let singleDoc: any = {};
          temp.missingDocArray = [];
          temp.categoryDtlId = item.categoryDtlId;
          temp.categoryName = item.categoryName;
          singleDoc.notifyTo = item.notifyTo;
          singleDoc.notifiedDate = item.notifiedDate;
          singleDoc.notificationId = item.notificationId;
          singleDoc.comments = item.comments;
          singleDoc.docList = item.docList;
          singleDoc.titleSheetDetailDocName = item.titleSheetDetailDocName;
          singleDoc.titleSheetDetailId = item.titleSheetDetailId;
          temp.missingDocArray.push(singleDoc);
          this.missingNotificationDocuments.set(item.categoryName, temp);
        }
      }
      //console.log("$$$$$$ 123", this.missingNotificationDocuments);
      this.missingDocs = Array.from(this.missingNotificationDocuments.values() );
     // console.log("$$$$$$ 143", this.missingDocs, this.notificationDocuments);
    }
}

getSupportingDocuments() {


  this.rfeService
    .getSupportingDocuments(this.rfeTimelineModalInput['taskNumber'])
    .subscribe((data) => {
      // data.forEach( (element) => {
      //   element["showLabelForCheckbox"] = false;
      //   element.documentTypes.forEach(doc => {
      //     if(doc.present == false)
      //       element["showLabelForCheckbox"] = true;
      //     doc["selectedDocumentType"] = true;  
      //     doc["notifyTo"] = "";
      //     doc["showIndividual"] = true;
      //     this.selectedCategoryType.push(element);
      //   });
      // });
      this.supportingDocuments = data;
      //  this.supportingDocumentsForNotification = data;
    });

}
getCatagories() {
  this.rfeService
    .getRfeSelectedCatagoryList(this.rfeNoticeId)
    .subscribe((data) => {
      this.categories = data;
      if (this.categories.length > 0) {
        this.selectedCategory = this.categories[0];

        this.updateDocType(this.selectedCategory);

      }
    });

}

getRFENotification(rfeNoticeId) {
  let type;
  let id;
  if (sessionStorage.getItem('userTypeRole') === "Petitioner") {
    type = "employee";
    id = sessionStorage.getItem("employeeId");
  } else if (sessionStorage.getItem('userTypeRole') === "Beneficiary") {
    type = "beneficiary";
    id = sessionStorage.getItem("beneficiaryId");
  }
  this.rfeService
  .getRfeNotification(rfeNoticeId,type,id)
  .subscribe((data) => {
 // console.log("getdata for documents", data);
 if(data){
  this.notificationComments = data['notificationComments'];
  this.notificationDocuments = data['titleSheetDocDtoList'];
  this.notificationIds = data['notificationIds']
  this.modifyData();
 }
 
  });
}

updateDocType(selectedCategory, categoryDetailId ?) {
  let selectedCatagoryDetailId = categoryDetailId ? categoryDetailId : selectedCategory["categoryDetailId"];
  this.rfeService
    .getDocumentType(selectedCatagoryDetailId)
    .subscribe((data) => {
      this.documentType = data;
      if (categoryDetailId) {
        this.selectedDocType = this.documentType.find(e => (e.rfeTitleSheetDetailId === selectedCategory.titleSheetDetailId));
      }
    });
}


viewDocument(item) {
  const docPath = item.substring(23);
  window.open(`${environment.docs}${docPath}`, "_blank");
}

deleteDocument(item) {
  this.rfeService.deleteRFEDocument(this.rfeTimelineModalInput['taskNumber'], item).subscribe((response: any) => {
    if (response) {
      this.toastr.success(response.message, 'Success');
      this.getSupportingDocuments();
      this.getRFENotification(this.rfeNoticeId);
    }
  });
}
saveData() {

}
changeStatus(status) {
let stepInfo:any = {};
stepInfo.name = "Upload Documents";
stepInfo.status = status;
if(status === 'Complete'){
  this.updateNotificationIds(stepInfo);
}else{
  this.changeSubStepStatus.emit(stepInfo)
}
//

}

updateNotificationIds(stepInfo){
  let payload;
  this.notificationObj.action = true;
  this.notificationObj.beneficiaryId = sessionStorage.getItem('userTypeRole') === "Beneficiary" ? sessionStorage.getItem("beneficiaryId") : 0;
  this.notificationObj.delete = false;
  this.notificationObj.employeeId = sessionStorage.getItem('userTypeRole') === "Petitioner" ? sessionStorage.getItem("employeeId") : 0;
  this.notificationObj.favorite = false;
  this.notificationObj.read = true;
  if(this.notificationIds && this.notificationIds.length > 0){
  payload = this.notificationIds.map(item => ({...this.notificationObj, notificationId : item}));
  }
  //console.log("id's",payload,this.notificationIds);
  if(this.notificationIds && this.notificationIds.length > 0){
 this.rfeService.updateNotificationIds(payload).subscribe((response: any) => {
      if (response.status == 200) {
      //  this.toastr.success(response.message);
        this.changeSubStepStatus.emit(stepInfo);
       // this.getSupportingDocuments();
      }
    });
  }else{
    this.changeSubStepStatus.emit(stepInfo);
  }
  
}

setDocTypeEmpty() {
  this.selectedDocType = undefined;

}

onUpload(event, fileUpload) {
  //this.uploadDocument();
  if (this.selectedDocType == undefined && this.customDocType == undefined) {
    CustomErrorToastComponent.showErrorToast(this.toastr, "Please select a document type or enter a custom one");
  }
  else {
    let documentTypeName, entityTypeId, masterTitleSheetId, rfeTitleSheetDetailId;
    for (let file of event.files) {
      this.uploadedFile = file;
      this.uploadedFile["fileName"] = file.name;
    }
    fileUpload.clear();
    let payload = new FormData();
    if (this.customDocType != undefined) {
      documentTypeName = this.customDocType;
      entityTypeId = masterTitleSheetId = rfeTitleSheetDetailId = '';
    }
    else {

      documentTypeName = this.selectedDocType["docName"] ? this.selectedDocType["docName"] : '';
      entityTypeId = this.selectedDocType["entityType"] ? this.selectedDocType["entityType"] : '';
      masterTitleSheetId = this.selectedDocType["masterTitleSheetDetailId"] ? this.selectedDocType["masterTitleSheetDetailId"] : '';
      rfeTitleSheetDetailId = this.selectedDocType["rfeTitleSheetDetailId"] ? this.selectedDocType["rfeTitleSheetDetailId"] : '';

    }
    payload.append("file", this.uploadedFile, this.uploadedFile.name);
    let catagoryId=this.selectedCategory ? this.selectedCategory["categoryDetailId"] : this.missingDocCategoryId;
    this.rfeService.saveUploadedDocument(this.rfeTimelineModalInput['taskNumber'], catagoryId, documentTypeName, entityTypeId, masterTitleSheetId, rfeTitleSheetDetailId, payload).subscribe((response: any) => {
      if (response) {
        this.showFileuploadSection = false;
        this.toastr.success(response.message, 'Success');
        this.getSupportingDocuments();
        this.getRFENotification(this.rfeNoticeId);
        if(response.status == 200){
          this.changeStatus('Inprogress');
        }
       
      }
    });
  }
}

setCustomEmpty() {
  this.customDocType = undefined;

}

uploadMissingDocument(doc, catagoryID) {
  this.showFileuploadSection = true;
  this.showForOtherDocs = false;
  this.selectedDocType = {};
  this.selectedDocType.docName=doc.titleSheetDetailDocName;
  this.selectedDocType.rfeTitleSheetDetailId=doc.titleSheetDetailId;
  this.missingDocCategoryId = catagoryID;
  this.selectedCategory=undefined;

  //this.updateDocType(doc, catagoryID)

}
uploadOtherDocs() {
  if (this.categories.length > 0) {
    this.selectedCategory = this.categories[0];
    this.updateDocType(this.selectedCategory);
  }
  this.selectedDocType = undefined;
  this.showFileuploadSection = true;
  this.showForOtherDocs = true;

}

confirmDelete(item) {
  this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.deleteDocument(item);
         
      },
      reject: () => {
        
      }
  });
}

}
