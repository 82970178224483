import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mark-task-complete',
  templateUrl: './mark-task-complete.component.html',
  styleUrls: ['./mark-task-complete.component.scss']
})
export class MarkTaskCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
