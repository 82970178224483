import { Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { abort } from 'process';


@Component({
  selector: 'app-signature-upload',
  templateUrl: './signature-upload.component.html',
  styleUrls: ['./signature-upload.component.scss']
})
export class SignatureUploadComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showCropperSection: boolean = true;
  showFileUploadSection: boolean = true;
  zoomVal: any = 1;
  unacceptedFormat :boolean = false;
  currentIconItem: boolean = false;
  currentItemSize: any;

  constructor(
    public dialogRef: MatDialogRef<SignatureUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {
        if(data=="iconUpload"){
            this.currentIconItem = true;
        }
    }

    ngOnInit() {

    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  uploadProfileImage(){
    this.showCropperSection = false;
  }
  
  fileChangeEvent(event: any): void {
    
    this.imageChangedEvent = event;
    const type = 'image/png';
    if(this.currentIconItem){
        this.currentItemSize = (this.imageChangedEvent.target.files[0].size / 1024 / 1024).toFixed(2);
    }

    if(!this.imageChangedEvent.target.files[0].type.match(type) || (this.currentItemSize > 5)){
        this.unacceptedFormat = true;
        this.showFileUploadSection =true;
        return
    }

    this.showFileUploadSection = false;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
     // this.croppedImage = base64ToFile(event.base64);
  }
  imageLoaded() {
      // show cropper
      //console.log("image ready");
  }
  cropperReady() {
      // cropper ready
      //console.log("cropper ready");
  }
  loadImageFailed() {
      // show message
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

previousVal = this.zoomVal;
zoom(event){
    if(this.previousVal > event ){
        this.zoomOut();
    }
    else {
        this.zoomIn();
    }
    this.previousVal = event; //IM-4239
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}


zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}



}
