import { NgModule } from '@angular/core';
import { BeneficiaryLandingRoutingModule } from './beneficiary-landing.routing.module';
import { BeneficiaryMenuComponent } from './beneficiary-menu/beneficiary-menu.component';
import { BeneficiaryLandingComponent } from './beneficiary-landing/beneficiary-landing.component';


import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatMenuModule} from '@angular/material/menu';
import { MatToolbarModule} from '@angular/material/toolbar';
import {  MatIconModule } from '@angular/material/icon';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatExpansionModule} from '@angular/material/expansion';

import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        BeneficiaryLandingRoutingModule,
        MatToolbarModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        OverlayModule,
        MatExpansionModule,
        CommonModule,
        NgxPermissionsModule.forChild(),
        SharedModule
    ],
    declarations: [
        BeneficiaryMenuComponent,
        BeneficiaryLandingComponent
    ]
})

export class LandingBeneficiaryModule { }