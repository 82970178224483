import { Action, createReducer, on } from '@ngrx/store';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import * as frmSubTaskDetailsActions from '../actions/sub-task-details.actions';


export const subTaskDetailsFeatureKey = 'subTaskDetails';

export interface SubTaskDetailsState extends SubTaskDetails { }

export const initialState: SubTaskDetails = {};

export const reducer = createReducer(
  initialState,
  on(frmSubTaskDetailsActions.loadSubTaskDetailsSuccess, (state, { details }) => {
    return { ...state, ...details };
  })
);

