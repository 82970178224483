import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { ParentInformationService } from './parent-information.service';
import { AppServicesLibraryService } from 'app-services-library';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-parent-information',
  templateUrl: './parent-information.component.html',
  styleUrls: ['./parent-information.component.scss']
})
export class ParentInformationComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  primaryData;

  parentDetails = [];
  disabledControls = false;
  observableSubscription$ = new Subject();
  motherDetails: any;
  fatherDetails: any;
  stepDetails: TaskStep;
  verified = false;

  constructor(
    private apiService: ParentInformationService,
    private toastrService: ToastrService,
    public store: Store<any>,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
  }

  ngOnInit(): void {
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
        }
      });
  }

  ngOnChanges() {
    if (this.primaryData) {
      this.getParentInformation();
    }
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
  }

  getParentInformation() {
    this.apiService.getParentInformation('PARENT', this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.parentDetails = response.data;
        this.motherDetails = this.parentDetails.find(item => item.relationshipCode === 'MOTHER');
        this.fatherDetails = this.parentDetails.find(item => item.relationshipCode === 'FATHER');
      });
  }

  handleVerify() {
    if (!this.fatherDetails || !this.fatherDetails.title || !this.fatherDetails.firstName ||
      !this.fatherDetails.lastName || !this.fatherDetails.gender ||
      !this.fatherDetails.dob || !this.fatherDetails.birthCountry ||
      !this.fatherDetails.birthCity ||
      !this.motherDetails || !this.motherDetails.title || !this.motherDetails.firstName ||
      !this.motherDetails.lastName || !this.motherDetails.gender ||
      !this.motherDetails.dob || !this.motherDetails.birthCountry ||
      !this.motherDetails.birthCity) {
      CustomErrorToastComponent.showErrorToast(this.toastrService, 'Mandatory fields not filled.');
    } else {
      this.toastrService.success('Verified', 'Success');
      if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        this.updateStepStatus('INPROGRESS');
      }
    }
    this.verified = true;
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType,
      stepId: this.primaryData.stepId
    }));
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}
