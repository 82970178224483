import { NgModule } from "@angular/core";
import { AttorneyLandingRoutingModule } from "./attorney-routing.module";
import { AttorneyLandingComponent } from "./attorney-landing/attorney-landing.component";
import { AttorneyMenuComponent } from "./attorney-menu/attorney-menu.component";

import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";

import { NgxPermissionsModule } from "ngx-permissions";
import { CommonModule } from "@angular/common";
import { JoyrideModule } from "ngx-joyride";
import { SharedModule } from "../shared/shared.module";
import { AttorneyInvoiceModule } from "../attorney-invoice/attorney-invoice.module";
import { TaskAllocationModule } from "../task-allocation/task-allocation.module";
import { CaseStatusModule } from "../case-status/case-status.module";
import {CalendarModule} from 'primeng/calendar';
import { VisaBulletinDetailsComponent } from './visa-bulletin-details/visa-bulletin-details.component';


@NgModule({
  imports: [
    CommonModule,
    AttorneyLandingRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatIconModule,
    NgxPermissionsModule.forChild(),
    JoyrideModule.forChild(),
    SharedModule,
    AttorneyInvoiceModule,
    TaskAllocationModule,
    CaseStatusModule,
    CalendarModule
  ],
  declarations: [AttorneyLandingComponent, AttorneyMenuComponent, VisaBulletinDetailsComponent],
})
export class LandingAttorneyModule {}