<div class="container-fluid">
    <div class="content-container pb-1">
   
    <div class="content-container pb-0 invoice-stepper">
      <!-- <div class="row"> -->
      <!-- <div class="col-6 m-auto col-offset-3"> -->
      <mat-horizontal-stepper  [linear]="isLinear" #stepper labelPosition="bottom" [selectedIndex]="currentStep">
        <mat-step>
          <ng-template matStepLabel>Receipt Details</ng-template>
          <h3 class="ml-3">Invoice receipt</h3>
          <div class="row mt-2 ml-2">
              <div class="col-12" *ngIf="isReceiptgenerated">
                <button type="button" class="btn btn-primary"(click)="generateReceipt()">Generate</button>
                <button type="button" class="btn btn-primary ml-5"(click)="receiptUpload()">Upload</button>
              </div>
              <!-- <div class="col-2">
                <p-fileUpload #invoiceUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                customUpload="true" (uploadHandler)="UploadReceipt($event, invoiceUploader)"
                accept="application/pdf" invalidFileTypeMessageSummary=""
                invalidFileTypeMessageDetail="Unaccepted format. Please upload a 'pdf' format file"
                showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
            </p-fileUpload>
            </div> -->
              </div>
            <div class="row" *ngIf="isReceiptgenerated">
              <div class="col-2"></div>
              <div class="col-4  mt-1 nopadding"><p>The receipt you created outside imagility</p></div>
            </div>
          
          <div *ngIf="receiptView" class="receipt-container mt-3">
            <app-generate-receipt></app-generate-receipt>
          </div>
          
            <div *ngIf="uploadView"class="row mt-3">
              <div class="col-lg-12 nopadding">
                  <p-fileUpload #receiptUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                      customUpload="true" (uploadHandler)="UploadReceipt($event, receiptUploader)"
                      [accept]="acceptedTypes" invalidFileTypeMessageSummary=""
                      invalidFileTypeMessageDetail="Unaccepted format. PDF, JPG, JPEG, and PNG file types are allowed"
                      showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                  </p-fileUpload>
              </div>
              
          </div>
          <div class="row mt-3" *ngIf="uploadedFile">
              <div>
                  <label for="upload"> Uploaded Receipt</label>
              </div>
              <table>
                  <tr>
                      <th>File Name</th>
                      <th>Format</th>
                  </tr>
                  <tr>
                      <td>{{uploadedFile.fileName}}</td>
                      <td>{{uploadedFile.type}}</td>
                  </tr>
              </table>
          </div>
          
         
          <div class="pull-right mt-3">
            <!-- <button mat-button matStepperNext type="button">Next</button> -->
            <button class="im-btn btn-light im-btn-md mr-2" type="button" (click)="backtoInvoiceDashboard()">Cancel</button>
            <!-- <button mat-button type="button" class="im-btn im-btn-primary im-btn-md mr-2"
              disabled=" noInvoiceData">Save</button> -->
            <!-- <button mat-button matStepperNext type="button" class="im-btn im-btn-primary im-btn-md"
              [disabled]="noInvoiceData" (click)="sendDataTosecondPage()">Next</button> -->
            <button mat-button matStepperNext type="button" class="im-btn im-btn-primary im-btn-md"(click)="saveReceipt()">Next</button>
            <!-- <button class="im-btn im-btn-primary im-btn-md mr-2" type="button"
              [disabled]="!invoiceObj.valid">Save</button> -->
            <!-- <button class="im-btn im-btn-primary im-btn-md" matStepperNext type="button" [disabled]="noInvoiceData"
              (click)="sendDataTosecondPage()">Next</button> -->
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Send Receipt</ng-template>
          <div>
            <!-- <app-send-invoice></app-send-invoice> -->
            <app-send-receipt [invoiceId]= "invoiceId" ></app-send-receipt>
          </div>
          <div class="pull-right mt-3">
            <!-- <button mat-button matStepperNext type="button">Next</button> -->
            <button class="im-btn btn-light im-btn-md mr-2" type="button"(click)="backtoInvoiceDashboard()">Cancel</button>
            <!-- <button class="im-btn im-btn-primary im-btn-md  mr-2"  type="button">Save</button> -->
            <!-- <button class="im-btn im-btn-primary im-btn-md"  type="button">Send</button> -->
          </div>
        </mat-step>
      </mat-horizontal-stepper>
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
  </div>
