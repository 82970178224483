import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaseRequestService {
  masterData: any;

  constructor(private httpClient: HttpClient) {
  }

  // getSuperTaskList(payload) {
  //   return this.httpClient.post(`/superTask/list`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       if (response.status === 200) {
  //         return response;
  //       }
  //     })
  //   );
  // }

  getCaseRequest(payload) {
    return this.httpClient.get(`/caseRequest/${payload.attorneyCompanyId}/${payload.taskTypeId}?beneficiaryId=${payload.beneficiaryId}&superTaskId=${payload.superTaskId}&employeeId=${payload.lawFirmUserId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  sendCaseRequestNotification(payload) {
    return this.httpClient.post(`/caseRequest/saveDetailsByPetitioner/`, payload).pipe(
      map((response) => response)
    );
  }

  rejectCaseRequest(payload){
    let url;
    if(payload.status == 'CASEREQAREJ'){
      url = `/updateCaseRequestStatus?caseRequestId=${payload.caseRequestId}&rejectReason=${payload.rejectReason}&status=${payload.status}`;
    } else {
      url = `/updateCaseRequestStatus?caseRequestId=${payload.caseRequestId}&status=${payload.status}`;
    }
    return this.httpClient.post(url,payload).pipe(
      map((response) => response)
    );
  }

}
