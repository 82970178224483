<form [formGroup]="clientForm">
  <div class="d-flex justify-content-between align-items-center">
    <h5 mat-dialog-title class="mb-0">Add New Client/Company</h5>
    <button mat-icon-button mat-dialog-close aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>

    <div class="form-group">
      <label for="organizationName" class="label-cls required">Company/Organization Name</label>
      <input type="text" class="form-control" id="organizationName" formControlName="companyName">
      <!-- <select class="form-control" id="organizationName" formControlName="organizationName">
        <option>Select Company</option>
        <option value="company.petitionerId" *ngFor="let company of companies">{{company.petitionerName}}</option>>
      </select> -->
    </div>
    <hr class="dashed">
    <div class="d-flex justify-content-start align-items-center mb-3">
      <h5 class="mb-0">Primary Contact</h5>
      <div class="form-check d-flex justify-content-between align-items-center ml-2">
        <input type="checkbox" class="form-check-input w-auto h-auto" id="sendInvite" formControlName="isSendInvite">
        <label class="form-check-label" for="sendInvite">Send Invite</label>
        <small class="form-text text-muted">Primary Contact will be invited to register with Imagility</small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="firstName" class="label-cls required">First Name</label>
        <input type="text" class="form-control" id="firstName" formControlName="firstName">
      </div>
      <div class="form-group col-md-4">
        <label for="lastName" class="label-cls required">Last Name</label>
        <input type="text" class="form-control" id="lastName" formControlName="lastName">
      </div>
      <div class="form-group col-md-4">
        <label for="middleName" class="label-cls">Middle Name</label>
        <input type="text" class="form-control" id="middleName" formControlName="middleName">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="email" class="label-cls required">Email</label>
        <input type="email" class="form-control" id="email" formControlName="email" (keyup)="validate()">
        <small class="text-danger"
          *ngIf="clientForm.get('email').hasError('required') && emailRequired && email.touched && email.value !== '' && email.value">
          Email is required.
        </small>
        <small class="text-danger"
          *ngIf="(clientForm.get('email').hasError('email') || email.status === 'INVALID') && email.touched && email.value !== '' && email.value">
          Please enter a valid email address.
        </small>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="saveClient()"
      [disabled]="!clientForm.valid">Save</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>