import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';

@Injectable({
  providedIn: 'root'
})
export class ImmigrationWizardService {
  selectedUploadTypes = new BehaviorSubject(null);
  // selectedClient = new BehaviorSubject(null);
  wizardTaskDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  wizardTaskHeaderDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  wizardSettingsDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  wizardReqDataDocsDetails$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
  }

  getWizardTaskDetails$(): Observable<any> {
    return this.wizardTaskDetails$.asObservable();
  }

  setWizardTaskDetails$(value: any) {
    this.wizardTaskDetails$.next(value);
  }

  getWizardSettingsDetails$(): Observable<any> {
    return this.wizardSettingsDetails$.asObservable();
  }

  setWizardSettingsDetails$(value: any) {
    this.wizardSettingsDetails$.next(value);
  }

  getWizardTaskHeaderDetails$(): Observable<any> {
    return this.wizardTaskHeaderDetails$.asObservable();
  }

  setWizardTaskHeaderDetails$(value: any) {
    this.wizardTaskHeaderDetails$.next(value);
  }

  getWizardReqDataDocsDetails$(): Observable<any> {
    return this.wizardReqDataDocsDetails$.asObservable();
  }

  setWizardReqDataDocsDetails$(value: any) {
    this.wizardReqDataDocsDetails$.next(value);
  }

  getTaskDetails(id) {
    return this.http.get(`/H1-B/task/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          return response.data;
        }
      })
    );
  }

  getWizardSettings(id) {
    return this.http.get(`/wizard/getWizardTaskSetting/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          return response.data;
        }
      })
    );
  }

  saveWizardSettings(superTaskId: any, payload) {
    return this.http.post(`/wizard/save/wizardTaskSetting/${superTaskId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  convertUploadDocument(
    type: string, beneficiaryId: number, payload) {
    return this.http.post(`/wizard/upload/${type}/beneficiary/${beneficiaryId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }


  getReviewersUser(companyId, permissionName) {
    return this.http.get(`/company/${companyId}/employee?permissionName=${permissionName}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getUsersOfCompany(companyId) {
    return this.http.get('/company/' + companyId + '/employee').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getDataLog(taskTypeId, companyId, beneficiaryId){
    return this.http.post(`/getDataLog/${taskTypeId}/${companyId}/${beneficiaryId}?isWizardTask=true`,'').pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  resendDataLogs(id: number) {
    return this.http.post(`/resendDataLog/${id}`,'').pipe(
      map((response: any) => {        
        if (response.status === 200) {          
          return response;
        }
      })
    );
  }

  postH1BLottery(visaType, taskId, stepId, payload) {
    return this.http.post(`/${visaType}/task/${taskId}/step/${stepId}/update`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
            if (response && response.status === 200) {
                return response;
            }
        })
    );
  }

}
