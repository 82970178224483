<!-- <mat-card class="mt-1 dashboard-mini-card mat-card">
    <mat-card-header class="border-bottom">
      <mat-card-title class="title">Platform Statistics</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container class="pb-2 pt-2">
           <div class="display-flex" *ngIf="userType === 'Beneficiary'">
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">PETITIONERS</h6>
                       <div class="flex-container">
                           <p class="attorney-count"><span class="material-icons">perm_identity</span></p>
                           <h2 class="attorney-count">{{numberOfPetitioners}}</h2>
                       </div>
                   </mat-card>
                </div>
                <div class="col-6 col-md-6">
                     <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">ATTORNEYS</h6>
                       <div class="flex-container">
                           <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                           <h2 class="beneficiary-count">{{numberOfAttorneys}}</h2>
                       </div>
                   </mat-card>
                </div>
            </div>

            <div class="display-flex" *ngIf="userType === 'Attorney'">
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">PETITIONERS</h6>
                       <div class="flex-container">
                           <p class="attorney-count"><span class="material-icons">perm_identity</span></p>
                           <h2 class="attorney-count">{{numberOfPetitioners}}</h2>
                       </div>
                   </mat-card>
                </div>
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">CLIENTS</h6>
                        <div class="flex-container">
                            <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                            <h2 class="beneficiary-count">{{numberOfClients}}</h2>
                        </div>
                    </mat-card>
                </div>
            </div>

            <div class="display-flex" *ngIf="userType === 'Petitioner'">
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                       <h6 class="type-title">ATTORNEYS</h6>
                      <div class="flex-container">
                          <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                          <h2 class="beneficiary-count">{{numberOfAttorneys}}</h2>
                      </div>
                  </mat-card>
               </div>
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">BENEFICIARIES</h6>
                       <div class="flex-container">
                           <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                           <h2 class="beneficiary-count">{{numberOfBeneficiaries}}</h2>
                       </div>
                   </mat-card>
                </div>
            </div>

            <div class="display-flex" *ngIf="userType === 'Platform'">                
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">PETITIONERS</h6>
                       <div class="flex-container">
                           <p class="attorney-count"><span class="material-icons">perm_identity</span></p>
                           <h2 class="attorney-count">{{numberOfPetitioners}}</h2>
                       </div>
                   </mat-card>
                </div>
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                       <h6 class="type-title">ATTORNEYS</h6>
                      <div class="flex-container">
                          <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                          <h2 class="beneficiary-count">{{numberOfAttorneys}}</h2>
                      </div>
                  </mat-card>
               </div>                
            </div>
            <div class="display-flex" *ngIf="permissions?.indexOf('ISADMIN')>0 && userType !== 'Attorney'">
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">USERS</h6>
                    <div class="flex-container">
                        <p class=" attorney-count"><span class=" material-icons">gavel</span></p>
                        <h2 class=" attorney-count">{{numberOfUsers}}</h2>
                    </div>
                    </mat-card>
                </div>
                <div class="col-6 col-md-6">
                    <mat-card class="text-center dashboard-mini-tile-card">
                        <h6 class="type-title">ROLES</h6>
                        <div class="flex-container">
                            <p class=" attorney-count"><span class=" material-icons">gavel</span></p>
                            <h2 class=" attorney-count">{{numberOfRoles}}</h2>
                        </div>
                    </mat-card>
                </div>
            </div> -->
<!-- ------//----- -->
<!-- <div class=" display-flex col-6 col-md-6" *ngIf="isAttorneyStatsEnabled || isPetitionerStatsEnabled">
                <mat-card class="text-center dashboard-mini-tile-card">
                     <h6 class="type-title">BENEFICIARIES</h6>
                    <div class="flex-container">
                        <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                        <h2 class="beneficiary-count">{{numberOfBeneficiaries}}</h2>
                    </div>
                </mat-card>
            </div>
        
            <div class="display-flex col-6 col-md-6" *ngIf="isAttorneyAdminStatsEnabled">
                <mat-card class="text-center dashboard-mini-tile-card">
                    <h6 class="type-title">CLIENTS</h6>
                    <div class="flex-container">
                        <p class="beneficiary-count"><span class="material-icons">perm_identity</span></p>
                        <h2 class="beneficiary-count">{{numberOfClients}}</h2>
                    </div>
                </mat-card>
            </div>
            <div class="display-flex col-6 col-md-6" *ngIf="isPetitionerAdminStatsEnabled || isAttorneyAdminStatsEnabled">
                <mat-card class="text-center dashboard-mini-tile-card">
                    <h6 class="type-title">USERS</h6>
                 <div class="flex-container">
                    <p class=" attorney-count"><span class=" material-icons">gavel</span></p>
                    <h2 class=" attorney-count">{{numberOfUsers}}</h2>
                 </div>
                </mat-card>
            </div>
            <div class="display-flex col-6 col-md-6" *ngIf="isAttorneyStatsEnabled || isBeneficiaryStatsEnabled">  
                <mat-card class="text-center dashboard-mini-tile-card">
                    <h6 class="type-title">PETITIONERS</h6>
                    <div class="flex-container">
                        <p class="beneficiary-count"><span class="material-icons">people_outline</span></p>
                        <h6 class="beneficiary-count">{{numberOfPetitioners}}</h6>
                    </div>
                </mat-card>
            </div>
            <div class="display-flex col-6 col-md-6" *ngIf="isAttorneyAdminStatsEnabled || isPetitionerAdminStatsEnabled">
                <mat-card class="text-center dashboard-mini-tile-card">
                    <h6 class="type-title">ROLES</h6>
                    <div class="flex-container">
                        <p class=" attorney-count"><span class=" material-icons">gavel</span></p>
                        <h2 class=" attorney-count">{{numberOfRoles}}</h2>
                    </div>
                </mat-card>
            </div>
        
            <div class="display-flex col-6 col-md-6" *ngIf="isPetitionerAdminStatsEnabled || isPetitionerStatsEnabled || isBeneficiaryStatsEnabled"> 
                <mat-card class="text-center dashboard-mini-tile-card">
                    <h6 class="type-title">ATTORNEYS</h6>
                    <div class="flex-container">
                        <p class=" attorney-count"><span class=" material-icons">gavel</span></p>
                        <h2 class=" attorney-count">{{numberOfAttorneys}}</h2>
                    </div>
                </mat-card>
            </div> -->
<!-- </ng-container>

    </mat-card-content>
</mat-card>    -->

<div class="card border-0 rounded">
  <div class="card-header">Platform Statistics</div>
  <div class="card-body align-content-around">

    <div class="card-content" *ngIf="userType === 'Beneficiary'">
      <div class="d-petitioner">
        <ng-container  *ngIf="numberOfPetitioners > 0; else noPetitioner">
          <h6>Petitioners</h6>
          <div class=" justify-content-center align-items-center float-left">
            <img src="/assets/images/beneficiary-icon.png">
          </div>
          <div>{{numberOfPetitioners ? numberOfPetitioners : 0}}</div>
        </ng-container> <!--IM-3396--->
        <ng-template #noPetitioner>
          <div class="no_linked">No Petitioners are connected now </div>
        </ng-template>
      </div>  
     
      <div class="d-attorneys">
        <ng-container  *ngIf="numberOfAttorneys > 0; else noAttorney">
          <h6>Lawfirms</h6>

          <div class=" justify-content-center align-items-center float-left">
            <img src="/assets/images/attorneys-icon.png">
          </div>
        <div>{{numberOfAttorneys ? numberOfAttorneys : 0}}</div>

        </ng-container><!--IM-3396--->
        <ng-template #noAttorney>
          <div class="no_linked">No Lawfirms are connected now </div>
        </ng-template>
      </div>
    </div>

    <div class="card-content" *ngIf="userType === 'Attorney'">
      <div class="d-petitioner">
        <h6>Petitioners</h6> 
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/beneficiary-icon.png">
        </div>
        <div>{{numberOfPetitioners ? numberOfPetitioners : 0}}</div>
      </div>

      <div class="d-clients">
        <h6>Clients</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/attorneys-icon.png">
        </div>
        <div>{{numberOfClients ? numberOfClients : 0}}</div>
      </div>
    </div>

    <div class="card-content" *ngIf="userType === 'Petitioner'">
      <div class="d-attorneys">
        <h6>Lawfirms</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/attorneys-icon.png">
        </div>
        <div>{{numberOfAttorneys ? numberOfAttorneys : 0}}</div>     
      </div>

      <div class="d-beneficiary">
        <h6>Beneficiaries</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/beneficiary-icon.png">
        </div>
        <div>{{numberOfBeneficiaries ? numberOfBeneficiaries : 0}}</div>
      </div>
    </div>

    <div class="card-content" *ngIf="userType === 'Platform'">
      <h6>Petitioners</h6>
      <div class="d-petitioner">
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/beneficiary-icon.png">
        </div>
        <div>{{numberOfPetitioners ? numberOfPetitioners : 0}}</div>
       
      </div>
      <div class="d-attorneys">
        <h6>Lawfirms</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/attorneys-icon.png">
        </div>
        <div>{{numberOfAttorneys ? numberOfAttorneys : 0}}</div>
       
      </div>
    </div>

    <div class="card-content" *ngIf="permissions?.indexOf('ISADMIN')>0">
      <div class="d-users">
        <h6>Users</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/attorneys-icon.png">
        </div>
        <div>{{numberOfUsers ? numberOfUsers : 0}}</div>
        
      </div>

      <div class="d-roles">
        <h6>Roles</h6>
        <div class=" justify-content-center align-items-center float-left">
          <img src="/assets/images/attorneys-icon.png">
        </div>
        <div>{{numberOfRoles ? numberOfRoles : 0}}</div>
        
      </div>
    </div>

  </div>
</div>
