import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MembershipAssociationService {

  constructor(private http:HttpClient) { }

  
  getList(petitionId){
    return this.http.get(`/extraordinaryAbility/membership/petition/${petitionId}/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  
  deleteMembership(petitionId,docId){
    return this.http.delete(`/extraordinaryAbility/membership/${docId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }


  saveMemberShip(petitionId,payload){
    // return this.http.post(`/${type}/${caseType}/${caseId}/additionalInfo`,payload).pipe(
      return this.http.post(`/extraordinaryAbility/membership/petition/${petitionId}`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }

  getSummaryInfo(type,caseType,caseId,infoType){
    return this.http.get(`/${type}/${caseType}/${caseId}/additionalInfo/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  saveSummary(type,caseType,caseId,payload){
    return this.http.post(`/${type}/${caseType}/${caseId}/additionalInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }
}
