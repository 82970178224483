import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private httpClient: HttpClient) { }

 getSubTopics(topicId: string) {
    return this.httpClient.get(`/faq/topic/${topicId}/subtopic`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getTopics() {
    return this.httpClient.get(`/faq/topics`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getFaqById(faqId: string) {
    return this.httpClient.get(`/faq/${faqId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  createFaq(faq){
    return this.httpClient.post(`/faq/save`, faq).pipe(
      map((response) => response)
    );

  }

  deleteFaq(faqId) {
    return this.httpClient.delete(`/faq/${faqId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getFaqListByCompanyId(companyId: string,payload:any) {
    return this.httpClient.post(`/company/${companyId}/faqInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response;
        }
      })
    );
  }

  getFaqListByMostlyViewed(beneficiaryId: string) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/mostlyViewedFAQ`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getFaqListBySearch(beneficiaryId: string,searchText:string) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/faqInfoSearch?searchText=${searchText}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  incrementViewCount(questionId,answerId){
    return this.httpClient.post(`/faq/${questionId}/answer/${answerId}`,{}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );

  }

}

