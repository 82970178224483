import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewCaseComponent } from './add-new-case/add-new-case.component';
import { CaseStatusContainerComponent } from "./case-status-container/case-status-container.component";
import { ManageCaseStatusComponent } from './manage-case-status/manage-case-status.component';
const routes: Routes = [
  {
  path: "",
  component: CaseStatusContainerComponent,
  children: [
    {
      path: "",
      pathMatch: "full",
      redirectTo: "manage-case-status",
    },
    {
      path: "manage-case-status",
      component: ManageCaseStatusComponent,
    },
    {
      path: "add-new-case",
      component: AddNewCaseComponent,
    },
    {
      path: "edit/case/:id",
      component: AddNewCaseComponent,
    },
  ],
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseStatusRoutingModule { }
