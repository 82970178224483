import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import * as errorUtils from 'error-message-utility';
import { take, takeUntil } from 'rxjs/operators';
import { ApiAddressHistoryService } from '../address-history/api-address-history.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges, OnDestroy {
  addressForm: FormGroup;
  disabledControls = false;
  requiredFields: any;
  countryList: any[];
  listStates: any[] = [];
  @Output() childAddressForm = new EventEmitter<FormGroup>();
  @Input() selectedCountryAndStateCode: {
    countryCode: any;
    stateProvinceCode: any;
    stateProvinceName: any;
  };
  @Input() addressReadOnly = false;
  observableSubscription$ = new Subject();
  @Input() readonlyCountry = false;
  @Input() formDisabled = false;

  constructor(
    private cacheCountryService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    public apiService: ApiAddressHistoryService) {
    this.addressForm = new FormGroup({
      addressLine1: new FormControl(null, Validators.required),
      addressLine2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      stateProvinceCode: new FormControl(null),
      stateProvinceName: new FormControl(null),
      countryCode: new FormControl(null, Validators.required),
      postCode: new FormControl(null, Validators.required)
    }, [
      this.stateProvinceCodeFieldRequired.bind(this),
      this.stateProvinceNameFieldRequired.bind(this)
    ]);
  }

  stateProvinceCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.listStates && this.listStates.length > 0 &&
      !group.value.stateProvinceCode) {
      return { stateProvinceCodeFieldIsRequired: true };
    }
    return null;
  }

  stateProvinceNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if ((!this.listStates || (this.listStates && this.listStates.length === 0)) &&
      (!group.value.stateProvinceName || (group.value.stateProvinceName && group.value.stateProvinceName.trim() === ''))) {
      return { stateProvinceNameFieldIsRequired: true };
    }
    return null;
  }

  ngOnInit(): void {
    this.cacheCountryService.getCountry()
      .pipe(take(1))
      .subscribe(data => {
        if (data.length > 0) {
          this.countryList = [{countryCode:null,countryName:'Select',phoneCode:''},...data];;
        }
        this.childAddressForm.emit(this.addressForm);
      });
    this.apiService.emptyStateListOnReset
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(() => {
        this.listStates = [];
      });
  }

  ngOnChanges() {
    if (this.selectedCountryAndStateCode) {
      this.addressForm.patchValue({
        countryCode: this.selectedCountryAndStateCode.countryCode
      });
      this.handleCountryChange(
        this.selectedCountryAndStateCode.countryCode,
        this.selectedCountryAndStateCode.stateProvinceCode,
        this.selectedCountryAndStateCode.stateProvinceName);
    }
    this.disabledControls = this.addressReadOnly ? this.addressReadOnly : false;
    if (this.formDisabled) {
      this.addressForm.disable();
    } else {
      this.addressForm.enable();
    }
  }

  handleCountryChange(countryCode: string, stateProvinceCode: string = null, stateProvinceName: string = null) {
    this.addressForm.patchValue({
      stateProvinceCode,
      stateProvinceName
    });
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.listStates = states;
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}
