import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  fbActions,
  SET_FBFORMTYPE,
  SET_FORMSBUILDERID,
  SET_FORMSBUILDERMODULEID,
  SET_ISBENEFICIARYPROFILE,
  SET_ISFORMSBUILDERLOGIN,
  SET_ISFORMSBUILDERPREVIEW
} from '../actions/fb.actions';

export interface fbState {
  fbFormType: number;
  isFBModuleLogin?: boolean;
  isFBModulePreview?: boolean;
  fbModuleId?: number;
  formBuilderId?: number;
  isBeneFiciaryProfile?: boolean;
}

const InitialFbState: fbState = {
  fbFormType: null,
  isFBModuleLogin: false,
  isFBModulePreview: false,
  fbModuleId: null,
  formBuilderId: null,
  isBeneFiciaryProfile: false
};

export function fbReducer(state = InitialFbState, action: fbActions) {
  switch (action.type) {
    case SET_FBFORMTYPE:
      return {
        ...state,
        fbFormType: action.payload
      };
    case SET_ISFORMSBUILDERLOGIN:
      return {
        ...state,
        isFBModuleLogin: action.payload
      };
    case SET_ISFORMSBUILDERPREVIEW:
      return {
        ...state,
        isFBModulePreview: action.payload
      };
    case SET_FORMSBUILDERMODULEID:
      return {
        ...state,
        fbModuleID: action.payload
      };
    case SET_FORMSBUILDERID:
      return {
        ...state,
        formBuilderId: action.payload
      };
    case SET_ISBENEFICIARYPROFILE:
      return {
        ...state,
        isBeneFiciaryProfile: action.payload
      };
    default:
      return {
        ...state
      };
  }
}

export const getFBState = createFeatureSelector<fbState>('fb');
export const getFBFormType = createSelector(getFBState, (state: fbState) =>
  state.fbFormType ? state.fbFormType : null);
export const getIsFBModuleLogin = createSelector(getFBState, (state: fbState) =>
  state.isFBModuleLogin);
export const getIsFBModulePreview = createSelector(getFBState, (state: fbState) =>
  !!state.isFBModulePreview);
export const getFBModuleId = createSelector(getFBState, (state: fbState) =>
  state.fbModuleId ? state.fbModuleId : null);
export const getFormBuilderId = createSelector(getFBState, (state: fbState) =>
  state.formBuilderId ? state.formBuilderId : null);
export const getIsBeneFiciaryProfile = createSelector(getFBState, (state: fbState) =>
  state.isBeneFiciaryProfile);
