<div style="position: relative" class="w-100 fixed-height info-graph-widget shadow-sm" >
  <mat-card>
    <mat-card-header class="border-bottom">
      <mat-card-title>{{userRoleInfoText}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="pb-2 pt-2">
      <div class="info-container col-md-12 container">
        <div [hidden]="false">
          <div class="filter_box_container d-flex justify-content-evenly align-items-start">
            <div class="service_center_section">
              <h6>Primary Service Center :</h6>
              <p-dropdown class="filter-dropdown" appendTo="body" [options]="getServcieCenters" optionValue="id"
              (onChange)="filterByServiceCentre($event.value)"  optionLabel="name">
               </p-dropdown>
            </div>
            <div class="filter-box">
              <div class="service_center_section select-area">
                <h6>Petition :</h6>
                <p-dropdown class="filter-dropdown" appendTo="body" [options]="months" optionValue="value"
            (onChange)="filterByMonth($event.value)"  optionLabel="text">
             </p-dropdown>
              </div>
            </div>
          </div>
          <div class="form-group" [ngClass]="{no_data : chartData.length == 0}">

            <div id="container" #charts ></div>
            <div class="mt-5 pt-5 text-center grey" *ngIf="chartData.length == 0">
              No data available now.
            </div>
            <!---IM-3556-->
          </div>

        </div>
      </div>
      <!---nfo-container col-md-12 container my-2-->
    </mat-card-content>
  </mat-card>
</div>