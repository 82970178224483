import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PetitionerDetailsForeignCompanyService {

  encriptedData: string;
  constructor(private http: HttpClient) { }

    // api/v2/{type}/company/{companyId}/petitionerDetails? superTaskId = 123 & taskId = 34
    getPetitionerDetailsForeignCompany(visatype, companyId, superTaskId, subTaskId) {
      return this.http.get(`/${visatype}/company/${companyId}/petitionerDetails?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
       map((response: ImagilityBaseResponse) => {
           if (response.status === 200) {
             return response;
           }
           else {
             return [];
           }
         })
       );
     }


    // /api/v1/L1A/PETITION/351/company/277/COMPGENDOC
     getPetitionerUploadDetails(visatype, caseType, caseId, companyId, synccode) {
      var encryptedText = this.toEncrypt('company');
      return this.http.get(`/${visatype}/${caseType}/${caseId}/${encryptedText}/${companyId}/${synccode}`, {}).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }else
          {
            return null;
          }
        })
      );
     }

     // api/v1/L1A/PETITION/351/company/277/category/COMPGENDOC/sync

    //  /api/v2/L1A/PETITION/351/petitioner/category/COMPGENDOC/sync?superTaskId=257&taskId=741
     syncProfileData(visatype, caseType, caseId, groupId, synccode, superTaskId, subTaskId) {
      var encryptedText = this.toEncrypt('company');
       return this.http.post(`/${visatype}/${caseType}/${caseId}/${encryptedText}/${groupId}/category/${synccode}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
         map((response: ImagilityBaseResponse) => {
           if (response.status === 200) {
             return response;
           }else
           {
             return null;
           }
         })
       );
     }

     toEncrypt(_data) {
      var pharase = 'c63a423527b24f65'
      var data = _data;
      var key  = CryptoJS.enc.Latin1.parse(pharase);
      var iv   = CryptoJS.enc.Latin1.parse(pharase);  
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key,
        {iv:iv,mode:CryptoJS.mode.CBC,
          padding:CryptoJS.pad.ZeroPadding
      }).toString();
  
      var e64 = CryptoJS.enc.Base64.parse(encrypted);
      var eHex = e64.toString(CryptoJS.enc.Hex);
  
     return this.encriptedData = eHex
    }
}