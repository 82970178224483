import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-immigration-timeline',
  templateUrl: './immigration-timeline.component.html',
  styleUrls: ['./immigration-timeline.component.scss']
})
export class ImmigrationTimelineComponent implements OnInit {
  @Input() immigrationData;
  @Input() showEditBtn;
  constructor(private router: Router) { }

  ngOnInit() {
    
  }
  routeToProfile(){
  this.router.navigate(['beneficiary-landing/beneficiaries/profile/immigration',{listview:true}]);
  }

}
