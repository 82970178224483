import { Component, OnInit } from "@angular/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
declare const $;
@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  pathSrc: string = "beneficiary_banner.svg";
  images = [
    "../assets/img_avatar.png",
    "../assets/img_avatar.png",
    "../assets/img_avatar.png",
    "../assets/img_avatar.png",
    "../assets/img_avatar.png",
    "../assets/img_avatar.png",
  ];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 100,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    // nav: true,
  };
  // CarouselOptions = { items: 3, dots: true, nav: true, center: true };

  constructor() {}

  ngOnInit() {
    $(".carousel.carousel-multi-item.v-2 .carousel-item").each(function () {
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next.children(":first-child").clone().appendTo($(this));

      for (var i = 0; i < 4; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }
        next.children(":first-child").clone().appendTo($(this));
      }
    });
  }

  changeBackgroundImage(path: string) {
    this.pathSrc = path;
  }
}
