import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class ApiSponserPersonalDetailService {
  constructor(
    private http: HttpClient
  ) { }

  getSponsorProfileInformation(visatype: string, beneficiaryId: number) {
    return this.http.get(`/${visatype}/beneficiary/${beneficiaryId}/personalDetails`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  getBeneficiaryProfileInformation(visatype: string, familyId: number) {
    return this.http.get(`/${visatype}/family/${familyId}/personalDetails`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }


}
