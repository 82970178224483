<div *ngIf="taskpageLoaded"class="mt-3">
    <div class="row mt-2">
        <div class="col-2 nopadding ">
            <h5 style="font-weight:bolder;">Task Details</h5>
        </div>
        <!-- <div *ngIf="this.taskEditDetails" class="col-8">
            <button class="btn btn-primary" (click)="cancel()"> Cancel Task</button>
        </div> -->
        <!-- <div class="col-1">
            <label for="">Public</label>
            <p-radioButton value="Public"formControlName ="isPublic" ></p-radioButton>
        </div>
        <div class="col-1">
            <label for="">Private</label>
            <p-radioButton value="Private" formControlName ="isPublic" ></p-radioButton>
        </div> -->
    </div>
    
        <form [formGroup]=" taskForm">
            <div class="row ml-3">
                <div class="col-4">
                    <label for="first-name" class="label-cls required">Assign To</label>
                </div>
               <div class="col-lg-8">
                <div class="pull-right">
                    <p-radioButton class='mr-1' name="groupname" value="1" label="public"
                        formControlName='isPublic'>
                    </p-radioButton>
                    <p-radioButton name="groupname" value="0" label='private' formControlName='isPublic'>
                    </p-radioButton>
                </div>
            </div>


            </div>
            <div  class="assigneedropdown ml-3">
                <p-dropdown [options]="assigneMembersList" formControlName="attendiesList" 
                optionLabel="name" 
                    placeholder="Select a Team member" [(ngModel)]="taskattendes"></p-dropdown>
                    <small
                    *ngIf="taskForm.get('attendiesList').touched && taskForm.get('attendiesList').errors"
                    class="p-error">
                    <span *ngIf="taskForm.get('attendiesList').errors['required']">Required</span>
                  </small>
            </div>
            <div class="row divider mt-3">

            </div>

            <div class="row mt-2">
                <div class="col-3">
                    <div>
                    <label for="Date" class="label-cls required">Date</label>
                </div>
                    <div>
                    <p-calendar  formControlName="startDate" name="date" styleClass="p-form-datepicker"
                        dateFormat="mm/dd/yy"  placeholder="MM/DD/YYYY" [showIcon]="true"  [monthNavigator]="true"
                        [yearNavigator]="true" yearRange="2000:2099" [minDate]="minimumDate" >
                      </p-calendar>
                      </div>
                      <small
                      *ngIf="taskForm.get('startDate').touched && taskForm.get('startDate').errors"
                      class="p-error">
                      <span *ngIf="taskForm.get('startDate').errors['required']">Required</span>
                    </small>

                </div>
                <div class="col-2">
                    <label for="first-name" class="label-cls required">Time</label>
                   
                    <input type="time" formControlName="startTime" >
                    <small
                    *ngIf="taskForm.get('startTime').touched && taskForm.get('startTime').errors"
                    class="p-error">
                    <span *ngIf="taskForm.get('startTime').errors['required']">Required</span>
                  </small>

                </div>
                <div class="col-6">

                </div>
                </div>
                <div class="row divider mt-3">

                </div>
                
                <div class="row ml-2 mt-2">
                    <h6 style="font-weight:bolder;">Details</h6>
                </div>
                <div class="row mt-2 ml-2">
                <label  class="label-cls required"for="">Description/Agenda</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="description" ></textarea>
                    <small
                    *ngIf="taskForm.get('description').touched && taskForm.get('description').errors"
                    class="p-error">
                    <span *ngIf="taskForm.get('description').errors['required']">Required</span>
                  </small>


                </div>
                <div *ngIf="!this.taskEditDetails"class="row mt-4 mb-4">
                    <button type="button"  class="btn btn-primary" [disabled]="!this.taskForm.valid"(click)="save()">Save</button>
                    <button type="button" class="btn btn-light ml-2"  (click)="redirectoCalendar()">Cancel</button>
                    
                </div>
                <div *ngIf="this.taskEditDetails" class="row mt-5 mb-5">
                    <button type="button" class="btn btn-primary" (click)="update()">Update</button>
                    <button class="btn btn-primary ml-2" (click)="cancel()"> Cancel Task</button>
                </div>

        </form>
    
</div>