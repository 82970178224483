import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TaskSummaryOverview } from 'app-models';
import { take } from 'rxjs/operators';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { VisaExtendedState } from '../utility/interfaces/visa-extended-state';
import * as Task from './../../../superTask/task-state/actions/task.actions';


@Injectable({
  providedIn: 'root'
})
export class refDialogComponentService {

  constructor(
    public petitionerService: PetitionerService,
    private store: Store<VisaExtendedState>) { }

  onStepStatusUpdate(superTaskId: number) {
    this.petitionerService.getSuperTask(superTaskId)
      .pipe(take(1))
      .subscribe((taskSummaryOverview: TaskSummaryOverview) => {
        this.store.dispatch(new Task.SetTaskSummaryOverview(taskSummaryOverview));
      });
  }

}
