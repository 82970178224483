import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  TaskActions,
  SET_TASKSUMMARYOVERVIEW,
  SET_RFESUBTASKDETAILS,
  SET_PETITIONSUBTASKDETAILS,
  SET_ACTIVESUBTASKINDEX,
  SET_RFEINDEX,
  SET_PETITIONPOLICY,
  SET_I485PETITION,
  SET_DS260PETITION,
  SET_DS160PETITION,
  SET_SUB_PETITION,
  SET_TASKROUTEID,
  SET_SUBTASKSTATUS
} from '../actions/task.actions';

import * as fromRoot from '../../../../app.reducer';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { parentTabList } from 'src/app/data/models/policy.model';
import { TaskSummaryOverview } from 'app-models';

export interface TaskState {
  taskSummaryOverview: TaskSummaryOverview;
  activeSubTaskTabIndex: number;
  RfeSubTaskDetails: SubTaskDetails;
  PetitionSubTaskDetails: SubTaskDetails;
  rfeIndex: number;
  PetitionPolicy: parentTabList;
  i485?: any;
  ds260?: any;
  ds160?: any;
  RE?: any;
  PERM?: any;
  I140?: any;
  taskRouteId: number;
  subTaskStatus?: any;
}

export interface State extends fromRoot.State {
  task: TaskState;
}

const InitialTask: TaskState = {
  taskSummaryOverview: null,
  activeSubTaskTabIndex: 0,
  RfeSubTaskDetails: null,
  PetitionSubTaskDetails: null,
  rfeIndex: null,
  PetitionPolicy: null,
  i485: null,
  ds260: null,
  ds160: null,
  RE: null,
  PERM: null,
  I140: null,
  taskRouteId: 0,
  subTaskStatus: {code: null, value: null}
};

export function taskReducer(state = InitialTask, action: TaskActions) {
  switch (action.type) {
    case SET_TASKSUMMARYOVERVIEW:
      return {
        ...state,
        taskSummaryOverview: {
          ...JSON.parse(JSON.stringify(state)),
          ...JSON.parse(JSON.stringify(action.payload))
        }
      };
    case SET_RFESUBTASKDETAILS:
      return {
        ...state,
        RfeSubTaskDetails: action.payload,
      };
    case SET_PETITIONSUBTASKDETAILS:
      return {
        ...state,
        PetitionSubTaskDetails: action.payload,
      };
    case SET_ACTIVESUBTASKINDEX:
      return {
        ...state,
        activeSubTaskTabIndex: action.payload === 0 ? action.payload :
          (state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
            (state.taskSummaryOverview.subTaskList.findIndex(subTask => subTask.id === action.payload) + 1) : 0)
      };
    case SET_RFEINDEX:
      return {
        ...state,
        rfeIndex: action.payload,
      };
    case SET_PETITIONPOLICY:
      return {
        ...state,
        PetitionPolicy: action.payload
      };
    case SET_I485PETITION:
      return {
        ...state,
        i485: action.payload ? Object.assign({}, action.payload) : null,
      };
    case SET_DS260PETITION:
      return {
        ...state,
        ds260: action.payload ? Object.assign({}, action.payload) : null,
      };
    case SET_DS160PETITION:
      return {
        ...state,
        ds160: action.payload ? Object.assign({}, action.payload) : null,
      };
    case SET_SUB_PETITION:
      switch (action.payload.type) {
        case 'RE':
          return {
            ...state,
            RE: action.payload && action.payload.subTaskDetails ? Object.assign({}, action.payload.subTaskDetails) : null,
          };
          break;
        case 'PERM':
          return {
            ...state,
            PERM: action.payload && action.payload.subTaskDetails ? Object.assign({}, action.payload.subTaskDetails) : null,
          };
          break;
        case 'I140':
          return {
            ...state,
            I140: action.payload && action.payload.subTaskDetails ? Object.assign({}, action.payload.subTaskDetails) : null,
          };
          break;
      }
    case SET_TASKROUTEID:
      return {
        ...state,
        taskRouteId: action.payload
      };
    case SET_SUBTASKSTATUS:
      return {
        ...state,
        subTaskStatus: action.payload
      };
    default:
      return state;
  }
}

export const getTaskState = createFeatureSelector<TaskState>('task');
export const getSuperTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview ? state.taskSummaryOverview.superTask : null);
export const getSuperTaskId = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.superTask ? state.taskSummaryOverview.superTask.superTaskId : null
);
export const getSubTaskList = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview ? state.taskSummaryOverview.subTaskList : null);
export const getStatusPercentageMap = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview ? state.taskSummaryOverview.statusPercentageMap : null);
export const getIsSuperTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview ? !!state.taskSummaryOverview : false);
export const getSubTaskTypeList = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    state.taskSummaryOverview.subTaskList.map(x => x.taskTypeCode) : []);
export const getPetitionSubTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    state.taskSummaryOverview.subTaskList.find(x => x.taskTypeCode === 'PETITION') : null);
export const getRFESubTask = createSelector(getTaskState, (state: TaskState) => {
  const index = state.rfeIndex ? state.rfeIndex :
    state.taskSummaryOverview &&
      state.taskSummaryOverview.subTaskList && state.taskSummaryOverview.subTaskList.length > 0 ?
      state.taskSummaryOverview.subTaskList.findIndex(x => x.taskTypeCode === 'RFE') : null;
  return index !== null && state.taskSummaryOverview &&
    state.taskSummaryOverview.subTaskList && state.taskSummaryOverview.subTaskList[index]
    ? state.taskSummaryOverview.subTaskList[index]
    : null;
}
);
export const getPetitionTabIndex = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    (state.taskSummaryOverview.subTaskList.findIndex(x => x.taskTypeCode === 'PETITION') + 1) : 0);
export const getRFETabIndex = createSelector(getTaskState, (state: TaskState) =>
  state.rfeIndex ? state.rfeIndex :
    state.taskSummaryOverview.subTaskList.findIndex(x => x.taskTypeCode === 'RFE') + 1);
export const getPWDSubTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    state.taskSummaryOverview.subTaskList.find(x => x.taskTypeCode === 'PWD') : null);
export const getRESubTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    state.taskSummaryOverview.subTaskList.find(x => x.taskTypeCode === 'RECEFF') : null);
export const getPermSubTask = createSelector(getTaskState, (state: TaskState) =>
  state.taskSummaryOverview && state.taskSummaryOverview.subTaskList ?
    state.taskSummaryOverview.subTaskList.find(x => x.taskTypeCode === 'PERM') : null);
export const getActiveTabIndex = createSelector(getTaskState, (state: TaskState) =>
  state.activeSubTaskTabIndex ? (state.activeSubTaskTabIndex) : 0);
export const getRFESubTaskDetails = createSelector(getTaskState, (state: TaskState) =>
  state.RfeSubTaskDetails
    ? state.RfeSubTaskDetails
    : null
);
export const getPetitionSubTaskDetails = createSelector(getTaskState, (state: TaskState) =>
  state.PetitionSubTaskDetails
    ? state.PetitionSubTaskDetails
    : null
);
export const getPetitionPolicyDetails = createSelector(getTaskState, (state: TaskState) =>
  state.PetitionPolicy
    ? state.PetitionPolicy
    : null
);
export const getI485Details = createSelector(getTaskState, (state: TaskState) =>
  state.i485
    ? state.i485
    : null
);
export const getDS260Details = createSelector(getTaskState, (state: TaskState) =>
  state.ds260
    ? state.ds260
    : null
);
export const getDS160Details = createSelector(getTaskState, (state: TaskState) =>
  state.ds160
    ? state.ds160
    : null
);
export const getREDetails = createSelector(getTaskState, (state: TaskState) =>
  state.RE
    ? state.RE
    : null
);
export const getPERMDetails = createSelector(getTaskState, (state: TaskState) =>
  state.PERM
    ? state.PERM
    : null
);
export const getI140Details = createSelector(getTaskState, (state: TaskState) =>
  state.I140
    ? state.I140
    : null
);
export const getTaskRouteId = createSelector(getTaskState, (state: TaskState) =>
  state.taskRouteId);

export const getSubTaskStatus = createSelector(getTaskState, (state: TaskState) =>
  state.subTaskStatus);
