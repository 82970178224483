import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PrimaryData } from 'app-models';

@Component({
  selector: 'app-h1b-job-description',
  templateUrl: './h1b-job-description.component.html',
  styleUrls: ['./h1b-job-description.component.scss']
})
export class H1bJobDescriptionComponent implements OnInit {
  primaryData: PrimaryData;
  isForeignCompany: boolean;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.isForeignCompany = false;
  }

  ngOnInit() {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  handleDialogClose() {
    this.dynamicDialogRef.close();
  }

}