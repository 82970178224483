<div class="fluid-container p-3">
    <!-- <h5 class=""></h5> -->
    <h6 class="heading p-text-bold">High salary or remuneration for services in relation to others</h6>
    <div class="row pl-0 pr-0 profile-title pt-3">
        <label>Explain what is the normal salary in position and why the scholar is being paid significantly more than usual</label>
        <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
            <!-- <textarea
                [(froalaModel)]="template.body"
                name="text" [froalaEditor]="options">
            </textarea> -->
            <kendo-editor [(value)]="template.body" (valueChange)="highsalaryeditorValueChange($event)" #highsalarykendoeditor style="height: 400px;" formControlName="text" name="text">
                <kendo-toolbar>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                    <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                        <kendo-toolbar-button
                        title="Upload’s Image at cursor point"
                        text="Upload Image"
                        (click)="highsalaryimageuploaddialog()"
                        ></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>                                  
                </kendo-toolbar>
          </kendo-editor>
          <app-upload-image-dialog #highsalaryimageupload [editor]="highsalarykendoeditor"></app-upload-image-dialog> 
        </div>
      </div>
    <div class="row mt-5">
        <div class="col-md-12 p-0 ">
          <i140-file-upload 
               [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
         </i140-file-upload>
        </div>
    </div>

      <div class="row">
        <div class="col-md-3">
          <button pButton type="button" [disabled]="disabledControls" (click)="Save()" class="d-inline-block float-left saveBtn">Save</button>
        </div>
        <div class="col-md-6 completeStep">
          <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showMarkComplete" [disabled]="stepStatus !== 'INPROGRESS'"
          class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg stepCompleted" (click)="onMarkComplete()">
          <span class="btn-text">{{markStep}}</span>
          <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
        </button>
        <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
        </div>
    
      </div>

</div>

