import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromSubTaskDetails from './reducers/sub-task-details.reducer';
import * as fromStepStatusUpdate from './reducers/step-status-update.reducer';

export const storeFeatureKey = 'GCI140Feature';

export interface GCI140State {
  [fromSubTaskDetails.subTaskDetailsFeatureKey]: fromSubTaskDetails.SubTaskDetailsState;
  [fromStepStatusUpdate.stepStatusUpdateFeatureKey]: fromStepStatusUpdate.StepStatusUpdateState;

}

export const reducers: ActionReducerMap<GCI140State> = {
  [fromSubTaskDetails.subTaskDetailsFeatureKey]: fromSubTaskDetails.reducer,
  [fromStepStatusUpdate.stepStatusUpdateFeatureKey]: fromStepStatusUpdate.reducer,
};


export const metaReducers: MetaReducer<GCI140State>[] = !environment.production ? [] : [];
