import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { Chart } from 'highcharts';
import moment from 'moment';
import { chartOptions } from 'src/app/data/configurations/information-graph.config';
import * as Roles from '../../../../data/constants/roles';
import { UserRoleService } from 'src/app/services/user-role.service';
import { take,takeUntil } from 'rxjs/operators';
import { combineLatest,Subject } from 'rxjs';

@Component({
  selector: 'information-graph',
  templateUrl: './information-graph.component.html',
  styleUrls: ['./information-graph.component.scss'],
})
export class InformationGraphComponent implements OnInit,OnDestroy {
  
  destroy$ = new Subject<void>();
  selectedMonth: string;
  serviceCenterName = '';
  months = [
    { text: 'Past 3 Months', value: 3 },
    { text: 'Past 6 Months', value: 6 }
  ];
  monthNames = moment.months();
  currentDate;
  chart: Chart;
  selectedMonthNo: number;
  chartData: any[] = [];
  @ViewChild('charts', { static: true }) public chartEl: ElementRef;
  userRoleInfoText = '';
  serviceCenterPremium:any[] = [];
  getServcieCenters:any;
  serviceCenterRegular:any[] = [];
  serviceCenterID:number;
  removedDuplicateCentres:any[] = [];
  constructor(
    private dashboardWidgetService: DashboardWidgetsService,
    private changeDetector: ChangeDetectorRef,
    private userRoleService: UserRoleService
  ) {
    this.chartData = [];// IM-3556
  }
  ngOnInit() {
    const currentRole = sessionStorage.getItem('userTypeRole');
    if ( currentRole === Roles.ATTORNEY) {
      this.userRoleInfoText = "Lawfirm Information";
    } else if (sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
      this.userRoleInfoText = "Petitioner Information";
      this.dashboardWidgetService.getServiceCenterName(parseInt(sessionStorage.getItem('companyId'))).subscribe((data) => {
        if (data['data'].length > 0) {
          this.serviceCenterName = data['data'][0].serviceCenterName;
        } else {
          this.serviceCenterName = 'Data not Available!';
        }
      });
    } else { this.userRoleInfoText = "Information"; }
    if (currentRole === Roles.ATTORNEY || currentRole === Roles.PETITIONER) {
      this.userRoleService.isUserTypeAdmin.subscribe((isAdmin) => {
        const userType = isAdmin ? 'Admin Information' : 'Information';
        this.userRoleInfoText = `${currentRole === Roles.ATTORNEY ? 'Lawfirm' : currentRole} ${userType}`;
      });
    }
    
    combineLatest([
      this.dashboardWidgetService.getServiceCenterPremimum(),
      this.dashboardWidgetService.getServiceCenterRegular()
    ])
      .pipe(take(1))
      .subscribe((response: any[]) => {
        this.removedDuplicateCentres = [ ...response[0].data, ...response[1].data];
        this.getServcieCenters = this.removedDuplicateCentres.filter((v,i,a)=>a.findIndex(v2=>(v2.name===v.name))===i);
      });
      this.serviceCenterID = 1;
    this.filterByMonth(3);
    this.filterByServiceCentre(1);
    
  }

  filterByMonth(e) {
    this.selectedMonthNo = e;
    this.serviceCenterID = this.serviceCenterID;
    this.getChart(this.selectedMonthNo);
    this.getSelectedMonth();
  }
  filterByServiceCentre(id){
  this.serviceCenterID = id;
  this.getChart(this.selectedMonthNo);
  this.getSelectedMonth();
}
  getChart(monthVal) {
    const payload = {
      "fromDate": moment().subtract(monthVal, 'months').format('YYYY-MM-DD'),
      "toDate": moment().format('YYYY-MM-DD'),
      "serviceCenterId":this.serviceCenterID,
    };
   
    this.dashboardWidgetService.getPetitionInfo(payload).pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.chartData = this.chartData = data['data'] || [];
      this.selectedChartData();
    });
  }
  getSelectedMonth() {
    chartOptions.xAxis.categories = [];
    this.currentDate = new Date();
    for (let i = 0; i < this.selectedMonthNo; i++) {
      var newMonth = (this.currentDate.getMonth()) - i;
      var newYear = this.currentDate.getFullYear();
      let monthInWords = this.monthNames[newMonth];
      if (newMonth < 0) {
        newYear = this.currentDate.getFullYear() - 1;
        monthInWords = this.monthNames[12 + newMonth];
      }
      var newDate = new Date(this.currentDate.getDate(), newMonth, newYear);
      chartOptions.xAxis.categories.push(monthInWords);
    }
  }

  selectedChartData() {
    chartOptions.series = [];
    if (this.chartData && this.chartData.length > 0) {
      let newones = this.chartData.filter(x => (x.status === "NEW"));
      chartOptions.series.push({
        type: '',
        name: 'New',
        maxPointWidth: 30,
        data: [],
        color:"#1AA0DA"
      });
      if (newones) {
        newones.forEach(element => {
          chartOptions.series[0].data.push(element.count);
        });
      }
      let inprogress = this.chartData.filter(x => x.status === "INPROGRESS");
      chartOptions.series.push({
        type: '',
        name: 'In progress',
        maxPointWidth: 30,
        data: [],
        color:"#4cc9f6"
      });
      if (inprogress) {
        inprogress.forEach(element => {
          chartOptions.series[1].data.push(element.count);
        });
      }

      let filed = this.chartData.filter(x => x.status === "FILED");
      chartOptions.series.push({
        type: '',
        name: 'Filed',
        maxPointWidth: 30,
        data: [],
        color:"#2dc7ff"
      });
      if (filed) {
        filed.forEach(element => {
          chartOptions.series[2].data.push(element.count);
        });
      }

      let approved = this.chartData.filter(x => x.status === "APPROVED");
      chartOptions.series.push({
        type: '',
        name: 'Approved',
        maxPointWidth: 30,
        data: [],
        color:"#64d6ff"
      });
      if (approved) {
        approved.forEach(element => {
          chartOptions.series[3].data.push(element.count);
        });
      }

      let deniels = this.chartData.filter(x => x.status === "DENIED");
      chartOptions.series.push({
        type: '',
        name: 'Denials',
        maxPointWidth: 30,
        data: [],
        color:"#78d7fa"
      });
      if (deniels) {
        deniels.forEach(element => {
          chartOptions.series[4].data.push(element.count);
        });
      }

      let withdrawns = this.chartData.filter(x => x.status === "WITHDRAWN");
      chartOptions.series.push({
        type: '',
        name: 'Withdrawn',
        maxPointWidth: 30,
        data: [],
        color:"#a4e7ff"
      });
      if (withdrawns) {
        withdrawns.forEach(element => {
          chartOptions.series[5].data.push(element.count);
        });
      }

      let rfes = this.chartData.filter(x => x.status === "RFE");
      chartOptions.series.push({
        type: '',
        name: 'RFEs',
        maxPointWidth: 30,
        data: [],
        color:"#009ad1"
      });
      if (rfes) {
        rfes.forEach(element => {
          chartOptions.series[5].data.push(element.count);
        });
      }

      let noids = this.chartData.filter(x => x.status === "NOID");
      chartOptions.series.push({
        type: '',
        name: 'NOIDs',
        maxPointWidth: 30,
        data: [],
        color:"#1d9dcb"
      });
      if (noids) {
        noids.forEach(element => {
          chartOptions.series[5].data.push(element.count);
        });
      }
    }
    this.dashboardWidgetService.createChart(this.chartEl.nativeElement, chartOptions);
   
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

