import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, SimpleChanges, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ApiServicesService } from "../../services/api-services.service";
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as Roles from '../../../../data/constants/roles';
import { AuthenticationService } from '../../../../services/authentication.service';
import { UploadDocumentService } from '../../../../services/upload-document.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Tranings } from '../../../../data/models/trainings.model';
import { environment } from '../../../../../environments/environment';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { UtilityServiceService } from '../../services/utility-service.service';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { FileDownloadComponent } from 'src/app/modules/shared/file-download/file-download.component';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DependentProfileDataService } from '../../services/dependent-profile-data.service';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { ActivatedRoute } from '@angular/router';
import { acceptedFileTypes } from 'app-models';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-beneficiary-training',
  templateUrl: './beneficiary-training.component.html',
  styleUrls: ['./beneficiary-training.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BeneficiaryTrainingComponent implements OnInit, AfterViewInit {
  @Input()   showLifeStoryTimeLineSection: boolean;
  @Input() mode :string;
  @Input() isViewMode :boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;
  modeVal: boolean = false;
  showEditButton: boolean = true;
  iscaseRequest: boolean = false;
  currentItemDisplay: any = [];

  expandPanel: boolean = false;
  updateBenProfile: Boolean = true;
  selectedIndex: number = 0;
  beneficiaryId: number = 0;
  topic;
  //trainingForm: NgForm;
  @ViewChild('trainingForm', { static: true }) trainingForm: NgForm;


  listAddedSubjects: string[] = [];

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;

  subDisplayedColumns: string[] = [];
  listSubjects: MatTableDataSource<any>;

  training = new Tranings({});

  envPath: any;

  visible = true;
  selectable = true;
  removable = true;
  hasID: boolean = false;
  isLinear: boolean = true;
  today: Date = new Date();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  configurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENTRGDOC',
    entityId: 0,
    fileCategory: 'TCERT',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENTRGDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    familyId: null
  }

  expandedElement: null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  columns = [
    { columnDef: 'toggle', header: '', columnType: 'icon', customClass: '' },
    { columnDef: 'name', header: 'Name', columnType: 'text', customClass: '' },
    { columnDef: 'institution', header: 'Institution', columnType: 'text', customClass: '' },
    { columnDef: 'startDate', header: 'Start Date', columnType: 'text', customClass: '' },
    { columnDef: 'endDate', header: 'End Date', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  subjectsColumns = [
    { columnDef: 'subject', header: 'Added Topics', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];
  isListView = false;
  isTimeLineView = true;
  trainingsData = [];
  viewMode: boolean = false;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[] = [];
  dependentIdSubscription: Subscription
  dependentId: any;
  beneficiaryType: any;
  familyId: any;
  observableSubscription$ = new Subject();
  isSaveAsDraftDisabled:boolean = true;
  isExternalEmployeeId:string;
  isLocked:boolean=false;
  onlyFormBuilder;
  isBenQuestShowDivs: boolean = false;

  constructor(
    private apiService: ApiServicesService,
    private toastr: ToastrService,
    private uploadDocumentService: UploadDocumentService,
    private authenticationService: AuthenticationService,
    private utilityService: UtilityServiceService,
    public dialog: MatDialog,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private benServ: BeneficiaryQuestionnaireService,
    private route: ActivatedRoute,
  ) {
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if(this.isExternalEmployeeId){
      this.isLocked=true;
    }
   }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    this.dependentIdSubscription = this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
      this.familyId = selectedBeneObj.dependentId;
      this.beneficiaryType = selectedBeneObj.beneficiaryType;
      this.showLifeStoryTimeLineSection = (this.beneficiaryType === 'family' && this.familyId) ? true : (this.beneficiaryType === 'self') ? true : false;

      if(this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')){
        if(this.beneficiaryType === 'self'){
          this.iscaseRequest = true;
        } else {
          this.iscaseRequest = false;
        }
      }
      this.getTrainings();
    });
    this.showEditButton = !this.benServ.checkValidity();
    this.dependentProfileDataService.onLifeStorySubTabs.next();

    this.envPath = environment.docs;

    //IM-3066 - remove deleted file.
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getTrainings();
      }
    });
    this.uploadDocumentService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getTrainings();
      }
    });
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.subDisplayedColumns = this.subjectsColumns.map(c => c.columnDef);

  }

  // ngAfterViewInit() {
    // console.log('Values on ngAfterViewInit():');
    //console.log("this.trainingForm",this.trainingForm) ||| undefined cant access references  inside a ngif
    //this.trainingForm.resetForm();

  // }

  formCheck(trainingForm) {
    this.trainingForm = trainingForm;
    this.formChangesSubscriptions[0] = trainingForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      if (trainingForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: "If you leave before saving, all changes made on the 'Training' form will be lost. Do you wish to continue?"
        }));
      }
      else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }
    });
    this.formChangesSubscriptions[1] = this.trainingForm.valueChanges?.pipe(takeUntil(this.observableSubscription$)).subscribe(formValues => {
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });
  }

  onNewTopicEnter(event) {
    this.store.dispatch(new MarkDirtyFormAction({
      dirty: true,
      message: "If you leave before saving, all changes made on the 'Topic Covered' section will be lost. Do you wish to continue?"
    }));
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    this.training = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    }
    this.listSubjects = new MatTableDataSource();
    this.configurations.listUploadedFiles = [];
    this.configurations.useCaseId = 0;
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId;
    this.configurations = Object.assign({}, this.configurations);
    this.hasID = false;
    this.isLinear = true;
    //this.trainingForm.resetForm();
    //this.trainingForm.form.reset();
  }

  getTrainings() {
    this.apiService.getTrainingsDetails(this.beneficiaryId, 'TRAINING', this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      data['profEducation'].map((item) => {
        if (item.subjects) {
          item.subjects = item.subjects.split(',');
        }
      });
      data['profEducation'].map((item) => {
        item.startDate = item.startDate ? moment(item.startDate).format('DD-MMM-YYYY') : '';
        item.endDate = item.endDate ? moment(item.endDate).format('DD-MMM-YYYY') : '';
      });
      this.trainingsData = data['profEducation'];
      this.dataSource = new MatTableDataSource(data['profEducation']);
    });
  }

  handleEdit(input) {
    if (this.expandPanel !== true) {
      this.expandPanel = true;
      this.training = {
        id: input.id,
        name: input.name,
        subjects: input.subjects,
        institution: input.institution,
        startDate: input.startDate ? moment(input.startDate).toISOString() : '',
        endDate: input.endDate ? moment(input.endDate).toISOString() : ''
      };
      const obj = [];
      if (input.subjects) {
        input.subjects.forEach((item) => {
          obj.push({ 'subject': item });
        });
      }
      this.hasID = true;
      this.isLinear = false;
      this.listSubjects = new MatTableDataSource(obj);
      this.training.subjects = input.subjects && input.subjects.length > 0 ? input.subjects.join() : "";
      this.configurations.entityId = input.id;
      this.configurations.useCaseId = this.beneficiaryId;
      this.configurations.familyId = this.familyId;
      this.configurations.listUploadedFiles = input.documents ? this.configurations.listUploadedFiles.concat(input.documents) : [];
      this.configurations = Object.assign({}, this.configurations);
    }
  }

  handleDelete(input, trainingForm) {
    const title = 'Confirm';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteTrainingsDetails(this.beneficiaryId, 'TRAINING', input.id, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            if (this.training.id === input.id) {
              this.resetForm(trainingForm);
            }
            this.toastr.success(response.message, 'Success');
            this.getTrainings();
          }
        });
      }
    });
  }

  resetForm(trainingForm: NgForm) {
    trainingForm.resetForm();
    trainingForm.form.reset();
    this.training = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    }
    this.listSubjects = new MatTableDataSource();
    this.configurations.listUploadedFiles = [];
    this.configurations.useCaseId = 0;
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId;
    this.configurations = Object.assign({}, this.configurations);
    this.hasID = false;
    this.isLinear = true;
  }
  closeForm(f) {
    // console.log(f);
    this.resetForm(f);
    this.toggleExpansion();
  }

  saveTrianing(trainingForm, isDraft:boolean = false) {
    const payload = {
      id: this.training.id || 0,
      name: this.training.name,
      subjects: this.training.subjects,
      institution: this.training.institution,
      startDate: this.training.startDate ? moment(this.training.startDate).format('YYYY-MM-DD') : null,
      endDate: this.training.endDate ? moment(this.training.endDate).format('YYYY-MM-DD') : null,
      isSaveAsDraft: isDraft
    };
    this.apiService.saveTrainingDetails(this.beneficiaryId, 'TRAINING', payload, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe(response => {
      if (response.status === 200) {
        this.training.id = response.data['profEducation'][0].id;
        this.toastr.success(response.message, 'Success');
        response.data['profEducation'].forEach(element => {
          this.configurations.entityId = element.id;
          this.configurations.familyId = this.familyId;
          this.configurations = Object.assign({}, this.configurations);
        });
        this.configurations.useCaseId = this.beneficiaryId;
        this.getTrainings();
        this.hasID = true;
        this.isLinear = false;
        trainingForm.reset(trainingForm.value)
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
  }

  redirectToDocs() {
    this.selectedIndex = 1;
  }

  handleTabChange(tab) {
    this.selectedIndex = tab.index;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add subject
    if ((value || '').trim()) {
      this.listAddedSubjects.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/,/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.listAddedSubjects.push(value.trim()); //Push if valid
        }
      })
  }

  remove(fruit: string): void {
    const index = this.listAddedSubjects.indexOf(fruit);
    if (index >= 0) {
      this.listAddedSubjects.splice(index, 1);
    }
  }

  addSubjects() {
    if (!this.training.id) {
      this.toastr.info('Please add the training details first, then proceed to topics');
      return;
    }
    const payload = {
      id: this.training.id || 0,
      name: this.training.name,
      subjects: this.training.subjects,
      institution: this.training.institution,
      startDate: this.training.startDate ? moment(this.training.startDate).format('YYYY-MM-DD'): null,
      endDate: this.training.endDate ? moment(this.training.endDate).format('YYYY-MM-DD') : null,
    };
    const subs: string[] = [];
    if (this.listSubjects) {
      this.listSubjects.data.forEach((item) => {
        subs.push(item.subject);
      });
    }
    payload.subjects = subs.concat(this.listAddedSubjects).join();
    this.apiService.saveTrainingDetails(this.beneficiaryId, 'TRAINING', payload, this.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe(response => {
      if (response.status === 200) {
        this.listAddedSubjects = [];
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
        response.data['profEducation'].forEach(element => {
          this.configurations.entityId = element.id;
          this.configurations.familyId = this.familyId;
          this.configurations = Object.assign({}, this.configurations);
        });
        this.configurations.useCaseId = this.beneficiaryId;
        const obj = [];
        if (response.data['profEducation'][0]['subjects']) {
          response.data['profEducation'][0]['subjects'].split(',').map((item) => {
            obj.push({ 'subject': item });
          })
        }
        this.listSubjects = new MatTableDataSource(obj);
        this.getTrainings();
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
  }

  deleteSubject(index) {
    this.listSubjects.data.splice(index, 1);
    this.addSubjects();
  }

  fileUploadSuccess() {
    // this.getTrainings();
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mode.currentValue) {
      if(changes.mode.currentValue === 'list') {
        this.modeVal = true;
      }
    }
    if((changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue)){
      // this.formValidation();

      if(changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue){
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }

      if(changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue){
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }

    } else {
      this.iscaseRequest = false;
    }
  }

  ngAfterViewInit(): void {
    if(this.modeVal) {
      this.showLifeStoryTimeLineSection = true;
      this.toggleListView(true);
    }
  }

  ngOnDestroy() {
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(element => { element.unsubscribe(); });
    }
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  saveAsDraft(event){
    this.saveTrianing(this.trainingForm, event);
  }
}
