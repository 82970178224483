import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/modules/error/components/page-not-found/page-not-found.component';
import { SessionExpiredComponent } from 'src/app/modules/error/components/session-expired/session-expired.component';
import { InternalServerErrorComponent } from 'src/app/modules/error/components/internal-server-error/internal-server-error.component';
import { SomethingWentWrongComponent } from 'src/app/modules/error/components/something-went-wrong/something-went-wrong.component';
import { AccessDeniedComponent } from 'src/app/modules/error/components/access-denied/access-denied.component';
import { TimeOutComponent } from 'src/app/modules/error/components/time-out/time-out.component';

 const routes: Routes = [
    {
      path: 'page-not-found',
      component:PageNotFoundComponent 
    },
    {
      path: 'access-denied',
      component:SessionExpiredComponent 
    },
   
    {
      path: 'something-went-wrong',
      component:SomethingWentWrongComponent 
    }
    /*,
    {
      path: 'access-denied',
      component: AccessDeniedComponent
    },
    {
      path: 'time-out-error',
      component:TimeOutComponent
    },
    {
      path: 'internal-error',
      component:InternalServerErrorComponent
    }
     */
  ];
 @NgModule({
   imports: [RouterModule.forChild(routes)],
 exports: [RouterModule]
 })
 export class ErrorRoutingModule { }
