import { Action } from '@ngrx/store';
import { Company , petionerCompany } from 'src/app/data/models/company.model';

export enum CompanyActionTypes  {
    ListCompany = '[Company]List',
    ListCompanySuccess = '[Company]ListSuccess',
    ListAssocCompany = '[Company]List Assoc',
    ListAssocCompanySuccess = '[Company]ListSuccess Assoc',
    SaveCompany = '[Company]Save',
    GetCompanyDetails = '[Company]GetDetails',
    DeleteCompany = '[Company] Delete',
    SaveCompanySuccess = '[Company]SaveSuccess',
    DeleteCompanySuccess = '[Company]DeleteSuccess',
    GetCompany = '[Company]GetCompanyId',
    SetCompany = '[Company]SetCompanyId'
}

export class ListCompany implements Action{
    readonly type = CompanyActionTypes.ListCompany;
    constructor(public payload: petionerCompany){}
}

export class ListCompanySuccess implements Action{
    readonly type = CompanyActionTypes.ListCompanySuccess;
    constructor(public payload: Company){}
}

export class ListAssocCompany implements Action{
    readonly type = CompanyActionTypes.ListAssocCompany;
    constructor(public payload: number){}
}

export class ListAssocCompanySuccess implements Action{
    readonly type = CompanyActionTypes.ListAssocCompanySuccess;
    constructor(public payload: Company){}
}

export class SaveCompany implements Action{
    readonly type = CompanyActionTypes.SaveCompany;
    constructor(public payload: any){}
}

export class GetCompanyDetails implements Action{
    readonly type = CompanyActionTypes.GetCompanyDetails;
    constructor(public payload: String){}
}

export class DeleteCompany implements Action{
    readonly type = CompanyActionTypes.DeleteCompany;
    constructor(public payload: String){}
}

export class SaveCompanySuccess implements Action{
    readonly type = CompanyActionTypes.SaveCompanySuccess;
    constructor(public payload: String){}
}

export class DeleteCompanySuccess implements Action{
    readonly type = CompanyActionTypes.DeleteCompanySuccess;
    constructor(public payload: String){}
}

export class GetCompany implements Action{
    readonly type = CompanyActionTypes.GetCompany;
}

export class SetCompany implements Action{
    readonly type = CompanyActionTypes.SetCompany;
    constructor(public payload: String){}
}

export type CompanyActions = ListCompany | ListCompanySuccess | ListAssocCompany | ListAssocCompanySuccess | SaveCompany | GetCompanyDetails | DeleteCompany| SaveCompanySuccess| DeleteCompanySuccess | SetCompany | GetCompany;
