import { Component, OnInit, ChangeDetectorRef, ViewChildren, OnDestroy, Input, Output, SimpleChanges, AfterViewInit, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CacheCountryListService } from 'cache-country-list';
import { EducationDetails, ReferenceLibrary, ImagilityBaseResponse } from 'app-models';
import { sync_profile_code } from 'permission-library';
import { MatAccordion } from '@angular/material/expansion';
import * as errorUtils from "error-message-utility";
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AddEducationDetailsService } from './add-education-details.service';
import { ReferenceLibraryService } from 'reference-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { environment } from 'environment-configurations';
import { acceptedFileTypes } from 'app-models';
import { EditCompleteEducationDetailService } from '../edit-complete-education-detail.service';
import { CustomErrorToastComponent } from 'custom-error-toast';

export const DeleteDialogConfigurations = {
  width: '50%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};

@Component({
  selector: 'edit-complete-add-education-details',
  templateUrl: './add-education-details.component.html',
  styleUrls: ['./add-education-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AddEducationDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() editEduData: any;
  @Input() mode: string;
  @Input() showFileUploadSection: boolean;
  @Input() isViewMode: boolean;
  @Input() caseRequestShowDivs: any;
  @Input() type: string;

  modeVal: boolean = false;
  
  showEditButton: boolean = false;
  addDegreeCourceForm: FormGroup;
  addRelatedCourceForm: FormGroup;
  submitted = false;
  updateBenProfile = true;
  listCountry: [];
  listStates: Array<any>;
  listCourses: any[] = [];
  beneficiaryId: number;
  reqPayload: EducationDetails;
  educationDegreeDetails: any;
  getList: boolean;
  educationDetailsList: any = [];
  // auto chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  courses: any = [];
  degreeTypeList: ReferenceLibrary[];
  documentTypes: any = [];
  setYearValue: any = '2010';
  showEndYearValidationError = false;
  showStartYearValidationError = false;
  showAwardedYearValidationError = false;
  formSubmittd = false;
  viewMode = false;
  endYearValue: any;
  editEducationId: number;
  degreeFileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENEDUDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENEDUDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    familyId: null,
  };
  expandPanel = true;
  isListView = false;
  isTimeLineView = true;
  hasID = false;
  isLinear = true;
  iscountryUSA = false;
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[];
  dependentIdSubscription: Subscription;
  familyId: any;
  beneficiaryType: any;
  observableSubscription$ = new Subject();
  isSaveAsDraftDisabled: boolean = true;
  selectedUser: any;
  customQuestions: any;
  isExternalEmployeeId;
  isLocked = false;
  currentItemDisplay: any = [];
  iscaseRequest: boolean = false;
  newEntryEducationId: number;
  eduId: number;
  onlyFormBuilder;

  ///////////////// --------------------------------------------------------- LISTING CODE START ---------------------------------------------------//

  @Input() educationList: any = [];
  educationDetails: any = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  expandedElement: any | null;
  envPath: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() enableEdit: EventEmitter<number> = new EventEmitter();
  @Output() removeElement: EventEmitter<number> = new EventEmitter();

  columns = [
    { columnDef: 'toggle', header: '', columnType: 'icon', customClass: '' },
    { columnDef: 'degreeType', header: 'Degree', columnType: 'text', customClass: '' },
    { columnDef: 'fieldOfStudy', header: 'Field Of Study', columnType: 'text', customClass: '' },
    { columnDef: 'startYear', header: 'Start Year', columnType: 'text', customClass: '' },
    { columnDef: 'endYear', header: 'End Year', columnType: 'text', customClass: '' },
    { columnDef: 'grade', header: 'Grade', columnType: 'text', customClass: '' },
    { columnDef: 'university', header: 'University', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }];


  isFBUser: boolean;
  ///////////////// --------------------------------------------------------- LISTING CODE END ---------------------------------------------------//

  showDegreeAwardedDateRequired: boolean = true;
  inputData: any;
  listUploadedFiles: any;

  constructor(
    private cacheCountryService: CacheCountryListService,
    private apiService: AddEducationDetailsService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLibraryService: ReferenceLibraryService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    protected store: Store<any>,
    public dynamicDialogConfig: DynamicDialogConfig,
    private dialogService: DialogService,
    private srv: EditCompleteEducationDetailService
    // private benServ: BeneficiaryQuestionnaireService
  ) {
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('DEGR').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.degreeTypeList = response;
    });

    this.cacheCountryService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.length > 0) {
        this.listCountry = data;
      }
    });

    // this.referenceLibraryService.getReferenceData('BENEDUDOC').pipe(takeUntil(this.observableSubscription$))..subscribe((response: ReferenceLibrary[]) => {
    //   this.documentTypes = response;
    // });

    this.addDegreeCourceForm = this.fb.group({
      id: [],
      degree: [''],
      degreeType: ['', Validators.required],
      fieldOfStudy: ['', Validators.required],
      grade: [''],
      universityName: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      postCode: [''],
      countryCode: ['', Validators.required],
      stateProvinceCode: [null],
      stateProvinceName: [''],
      city: ['', Validators.required],
      startYear: ['', Validators.required],
      endYear: ['', Validators.required],
      degreeAwardedDate: [''],
      gcI140CaseId: ['']
    });

    this.addRelatedCourceForm = this.fb.group({

    });

    this.listCountry = [];
    this.listStates = [];
    this.formChangesSubscriptions = [];

    this.inputData = {
      visaType: 'gc',
      caseType: 'PETITION',
      caseId: JSON.parse(sessionStorage.getItem('tempPetitionId')),
      superTaskId: this.dynamicDialogConfig.data?.superTaskId ?? null,
      subTaskId: this.dynamicDialogConfig.data?.subTaskId ?? null
    };

  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    this.beneficiaryId = sessionStorage.getItem('beneficiaryId') ? parseInt(sessionStorage.getItem('beneficiaryId')) : 0;
    this.modeVal = true;
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.expandPanel = true;
    this.hasID = false;
    this.isLinear = true;
    this.afterLoadingNgInit();
    this.formStatusCheck();
    this.getEducationList();
  }

  downloadDoc(doc) {
    this.apiService.downloadDocuments(environment.docs + doc.fileLocation.substring(23), doc.fileLocation.substring(23));
  }

  formStatusCheck() {
    this.formChangesSubscriptions[0] = this.addDegreeCourceForm.valueChanges.pipe(
      map(() => this.addDegreeCourceForm.dirty && this.addDegreeCourceForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: 'If you leave before saving, all changes made on the \'Education\' form will be lost. Do you wish to continue?'
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }

    });

    this.formChangesSubscriptions[1] = this.addRelatedCourceForm.valueChanges.pipe(
      map(() => this.addRelatedCourceForm.dirty && this.addRelatedCourceForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: 'If you leave before saving, all changes made on the \'Related Course\' form will be lost. Do you wish to continue?'
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }

    });

  }


  afterLoadingNgInit() {
    if (this.route.snapshot.paramMap.get('listview')) {
      this.toggleListView(true);
    }

    if (this.beneficiaryId) {
      this.degreeFileUploadConfigurations.useCaseId = this.beneficiaryId;
      this.degreeFileUploadConfigurations.familyId = this.familyId;
      this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      this.getUpolodedFiles();
    }
    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
      }

    });
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getEducationList();
      }
    });
    this.addDegreeCourceForm.controls['endYear'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(change => {
      this.degreeAwardedDateValidator();
    });
    this.addDegreeCourceForm.controls['startYear'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(change => {
      this.degreeAwardedDateValidator();
    });
    // IM-3493 - System accepting Degree awarded date lesser than education end year
    this.addDegreeCourceForm.controls['degreeAwardedDate'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(val => {
      this.showAwardedYearValidationError = false;
      if (this.addDegreeCourceForm.get('endYear').value && moment(this.addDegreeCourceForm.get('endYear').value).format('YYYY') > moment(val).format('YYYY')) {
        this.showAwardedYearValidationError = true;
      }
    });

    if (localStorage.getItem('currentUserSelection')) {
      this.selectedUser = JSON.parse(localStorage.getItem('currentUserSelection'));
    }

    if (localStorage.getItem('customQuestions')) {
      this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
    }

  }


  handleCountryChange() {
    const countryCode = this.addDegreeCourceForm.get('countryCode').value;
    this.apiService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      this.changeDetectorRef.markForCheck();
      this.addDegreeCourceForm.get('stateProvinceName').clearValidators();
      this.addDegreeCourceForm.get('stateProvinceCode').clearValidators();
      if (states.length > 0) {
        this.addDegreeCourceForm.get('stateProvinceCode').setValidators([Validators.required]);
      } else {
        this.addDegreeCourceForm.get('stateProvinceName').setValidators([Validators.required]);
      }
      this.addDegreeCourceForm.get('stateProvinceName').updateValueAndValidity();
      this.addDegreeCourceForm.get('stateProvinceCode').updateValueAndValidity();

      if (this.iscaseRequest) {
        this.addDegreeCourceForm.get('stateProvinceName').clearValidators();
        this.addDegreeCourceForm.get('stateProvinceCode').clearValidators();
        this.addDegreeCourceForm.get('stateProvinceName').updateValueAndValidity();
        this.addDegreeCourceForm.get('stateProvinceCode').updateValueAndValidity();
      }
    });

    if (countryCode === 'USA') {
      this.iscountryUSA = true;
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.required, Validators.pattern(/^[a-z0-9]+$/)]);
    } else {
      this.iscountryUSA = false;
      this.addDegreeCourceForm.get('postCode').clearValidators();
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.pattern(/^[a-z0-9]+$/)]);
    }
    this.addDegreeCourceForm.get('postCode').updateValueAndValidity();

    if (this.iscaseRequest) {
      this.addDegreeCourceForm.get('postCode').clearValidators();
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.pattern(/^[a-z0-9]+$/)]);
      this.addDegreeCourceForm.get('postCode').updateValueAndValidity();
    }
    this.degreeAwardedDateValidator();
  }

  addCourses() {
    this.listCourses = this.listCourses.concat(this.courses);
    this.saveDegreeDetails();
  }

  deleteEducation(index) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        if (index !== -1) { this.listCourses.splice(index, 1); }
        this.saveDegreeDetails();
      }
    });
  }

  // getDegreeDetails() {
  //   debugger;
  //   this.apiService.getEducationDetails(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, sync_profile_code.SYNC_TASK_TYPE_EDUCATION, this.inputData.superTaskId, this.inputData.subTaskId)
  //   .pipe(take(1))
  //   .subscribe(data => {
  //       debugger;
  //       if (data['status'] === 200) {
  //         this.educationDetailsList = data['data'];
  //         if (this.educationDetailsList) {
  //           this.educationDetails = this.educationDetailsList;
  //           this.educationDetails = this.educationDetails.map(e1 => {
  //             let _e1 = { ...e1 };
  //             if (_e1.documents) {
  //               _e1.documents = _e1.documents.map(e2 => {
  //                 let _e2 = { ...e2 };
  //                 if (_e2.fileLocation) {
  //                   _e2.fileLocationName = _e2.fileLocation.substring(23);
  //                   this.courses = [];
  //                   this.addDegreeCourceForm.controls['id'].setValue(_e1.id);
  //                   return _e2;
  //                 }
  //               });
  //             }
  //             else {
  //               this.degreeFileUploadConfigurations.listUploadedFiles = [];
  //             }
  //             return _e1;
  //           });
  //           this.dataSource = new MatTableDataSource(this.educationDetails);
  //         }
  //       }
  //       if (data['status'] === 204) {
  //         this.educationDetailsList = [];
  //         this.educationDetails = [];
  //         this.dataSource = new MatTableDataSource([]);
  //       }
  //     });
  // }

  saveDegreeDetails(isDraft: boolean = false) {
    this.educationDegreeDetails = this.addDegreeCourceForm.value;
    this.createPayload(this.addDegreeCourceForm.value, isDraft);
    this.addDegreeCourceForm.reset(this.addDegreeCourceForm.value);
  }
  createPayload(degreeInfo, isDraft: boolean = false) {
    let _stateProvinceName = degreeInfo.stateProvinceName ? degreeInfo.stateProvinceName : '';

    if(this.listStates.length > 0 && !!degreeInfo.stateProvinceCode) {
      _stateProvinceName = this.listStates.find((e: any) => e.stateProvinceCode == degreeInfo.stateProvinceCode).stateProvinceName;
    }

    const reqObj = {
      isSaveAsDraft: isDraft,
      id: this.eduId || 0,
      country: degreeInfo && degreeInfo.countryCode ? { countryCode: degreeInfo.countryCode } : null,
      university: degreeInfo.universityName ? degreeInfo.universityName : '',
      degree: degreeInfo.degree ? degreeInfo.degree : '',
      grade: degreeInfo.grade ? degreeInfo.grade : '',
      equivalance: degreeInfo.equivalance ? degreeInfo.equivalance : '',
      fieldOfStudy: degreeInfo.fieldOfStudy ? degreeInfo.fieldOfStudy : '',
      startYear: degreeInfo.startYear ? moment(degreeInfo.startYear).year().toString() : '',
      endYear: degreeInfo.endYear ? moment(degreeInfo.endYear).year().toString() : '',
      degreeAwardedDate: degreeInfo.degreeAwardedDate ? moment(degreeInfo.degreeAwardedDate).format('YYYY-MM-DD') : '',
      addressLine1: degreeInfo.addressLine1 ? degreeInfo.addressLine1 : '',
      addressLine2: degreeInfo.addressLine2 ? degreeInfo.addressLine2 : '',
      stateProvinceCode: degreeInfo.stateProvinceCode ? degreeInfo.stateProvinceCode : null,
      stateProvinceName: _stateProvinceName,
      postCode: degreeInfo.postCode ? degreeInfo.postCode : '',
      city: degreeInfo.city ? degreeInfo.city : '',
      degreeType: degreeInfo && degreeInfo.degreeType ? { id: degreeInfo.degreeType } : null,
      courses: this.listCourses
    };

    const reqPayload = { education: [reqObj] };
    let petitionId = JSON.parse(sessionStorage.getItem('tempPetitionId'));
    let url;
    if(this.inputData.caseType == 'I140') {
      url = this.srv.savegcI140DegreeDetails(degreeInfo.gcI140CaseId ?? this.inputData.caseId, reqPayload)
    } else if(this.inputData.caseType == 'Perm') {
      url = this.srv.savePermDegreeDetails(degreeInfo.gcI140CaseId ?? this.inputData.caseId, reqPayload);
    }
    url.pipe(takeUntil(this.observableSubscription$)).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.degreeFileUploadConfigurations.entityId = response.data.id;
        this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
        this.listCourses = response.data.courses;
        this.courses = [];
        this.eduId = response.data.id;
        // this.addDegreeCourceForm.controls['id'].patchValue(response.data.id);\
        this.hasID = true;
        this.isLinear = false;
        this.degreeFileUploadConfigurations.useCaseId = this.beneficiaryId;
        // this.resetForm();
        this.getEducationList();
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
  }

  editDegreeHandler(eduDegree) {
    this.expandPanel = true;
    this.eduId = eduDegree.id;
    this.addDegreeCourceForm.setValue({
      id: eduDegree.id ? eduDegree.id : 0,
      degree: eduDegree.degree ? eduDegree.degree : '',
      degreeType: eduDegree && eduDegree.degreeType ? eduDegree.degreeType.id : null,
      fieldOfStudy: eduDegree.fieldOfStudy,
      grade: eduDegree.grade ? eduDegree.grade : '',
      universityName: eduDegree.university,
      addressLine1: eduDegree.addressLine1,
      addressLine2: eduDegree.addressLine2,
      postCode: eduDegree.postCode ? eduDegree.postCode : '',
      countryCode: eduDegree.country ? eduDegree.country.countryCode : null,
      stateProvinceCode: eduDegree.stateProvinceCode ? eduDegree.stateProvinceCode : null,
      stateProvinceName: eduDegree.stateProvinceName,
      city: eduDegree.city,
      startYear: eduDegree.startYear ? moment([eduDegree.startYear, 0, 1]) : '',
      endYear: eduDegree.endYear ? moment([eduDegree.endYear, 0, 1]) : '',
      degreeAwardedDate: eduDegree.degreeAwardedDate ? moment(eduDegree.degreeAwardedDate).toISOString() : '',
      gcI140CaseId: eduDegree.gcI140CaseId
    });
    this.handleCountryChange();
    this.listCourses = eduDegree.courses ? eduDegree.courses : [];
    if (eduDegree.id) {
      this.degreeFileUploadConfigurations.listUploadedFiles = eduDegree.documents;
      this.degreeFileUploadConfigurations.useCaseId = this.beneficiaryId;
      this.degreeFileUploadConfigurations.entityId = eduDegree.id;
      this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      this.hasID = true;
      this.isLinear = false;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.courses.push(
        {
          courseName: value.trim(),
          courseId: ''
        }
      );
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); // Prevents the default action
    event.clipboardData
      .getData('Text') // Gets the text pasted
      .split(/,/) // Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.courses.push({
            courseName: value.trim(),
            courseId: ''
          }); // Push if valid
        }
      });
  }

  remove(fruit: any): void {
    const index = this.courses.indexOf(fruit);

    if (index >= 0) {
      this.courses.splice(index, 1);
    }
  }

  // dynamically adding validator for degreeAwardedDate

  degreeAwardedDateValidator() {
    const countryCode = this.addDegreeCourceForm.get('countryCode').value;
    const degreeAwardedDateControl = this.addDegreeCourceForm.get('degreeAwardedDate');
    // const endYearControl = this.addDegreeCourceForm.get('endYear');
    let endYear;
    let startYear;
    if (this.addDegreeCourceForm.get('startYear').value) {
      startYear = (this.addDegreeCourceForm.get('startYear').value).year();
    }
    if (this.addDegreeCourceForm.get('endYear').value) {
      this.endYearValue = moment(this.addDegreeCourceForm.get('endYear').value).format('YYYY-MM-DD');
      endYear = (this.addDegreeCourceForm.get('endYear').value).year();
    }


    const currentYear = moment().year();
    if (endYear && countryCode) {
      if (countryCode !== 'USA' && (endYear < currentYear)) {
        this.showDegreeAwardedDateRequired = true;
        degreeAwardedDateControl.setValidators(Validators.required);

      } else {
        this.showDegreeAwardedDateRequired = false;
        degreeAwardedDateControl.clearValidators();
      }

    }

    if (startYear && endYear) {
      // IM-3850 -Education -> End year same as start year to be allowed
      if (endYear >= startYear) {
        this.showEndYearValidationError = false;
      } else {
        this.showEndYearValidationError = true;
      }

    }

    if (startYear) {
      if (startYear > (new Date()).getFullYear()) {
        this.showStartYearValidationError = true;
      } else {
        this.showStartYearValidationError = false;
      }

    }
    degreeAwardedDateControl.updateValueAndValidity();
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    if (this.expandPanel) {
      this.resetForm();
      this.hasID = false;
      this.isLinear = true;
    }
  }

  addNewDegree() {
    this.resetForm();
    this.hasID = false;
    this.isLinear = true;
  }

  resetForm() {
    // this.addDegreeCourceForm.clearValidators();
    // this.addDegreeCourceForm.updateValueAndValidity();
    this.addDegreeCourceForm.reset();
    // validators required for both new and edit form
    this.formSubmittd = true;
    this.listCourses = [];
    this.degreeFileUploadConfigurations.listUploadedFiles = [];
    this.degreeFileUploadConfigurations.useCaseId = 0;
    this.degreeFileUploadConfigurations.entityId = 0;
    this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
    this.showEndYearValidationError = false;
    this.showStartYearValidationError = false;
  }

  closeForm() {
    this.toggleExpansion();
    this.addDegreeCourceForm.reset();
  }

  addEducation() {
    this.addDegreeCourceForm.setValue({
      id: 0,
      degree: '',
      degreeType: null,
      fieldOfStudy: '',
      grade: '',
      universityName: '',
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      countryCode: null,
      stateProvinceCode: null,
      stateProvinceName: '',
      city: '',
      startYear: '',
      endYear: '',
      degreeAwardedDate: '',
      gcI140CaseId: this.inputData.caseId
    });
    this.handleCountryChange();
    this.toggleExpansion();
  }


  fileUploadSuccess() {
    this.getEducationList();
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }

  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onRefreshPage(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editEduData.currentValue) {
      const inputData = {
        ...changes.editEduData.currentValue,
        superTaskId: changes.editEduData.currentValue.superTaskId ?? this.inputData?.superTaskId ?? null,
        subTaskId: changes.editEduData.currentValue.subTaskId ?? this.inputData?.subTaskId ?? null
      };
      this.inputData = {
        ...inputData
      };
    //   this.getEducationList();
    }
  }
  // formValidation() {
  //   debugger;
  //   this.addDegreeCourceForm = this.fb.group({
  //     id: [],
  //     degree: [''],
  //     degreeType: [''],
  //     fieldOfStudy: [''],
  //     grade: [''],
  //     universityName: [''],
  //     addressLine1: [''],
  //     addressLine2: [''],
  //     postCode: [''],
  //     countryCode: [''],
  //     stateProvinceCode: [null],
  //     stateProvinceName: [''],
  //     city: [''],
  //     startYear: [''],
  //     endYear: [''],
  //     degreeAwardedDate: [''],
  //     gcI140CaseId: ['']
  //   });
  // }

  ngAfterViewInit(): void {
    if (this.modeVal) {
      this.showFileUploadSection = true;
      this.toggleListView(true);
      this.toggleExpansion();
    }
  }

  ngOnDestroy() {
    this.addDegreeCourceForm.get('startYear').clearValidators();
    this.addDegreeCourceForm.get('endYear').clearValidators();
    this.addDegreeCourceForm.get('startYear').updateValueAndValidity();
    this.addDegreeCourceForm.get('endYear').updateValueAndValidity();

    if (this.formChangesSubscriptions.length > 0) {
      this.formChangesSubscriptions.forEach(element => {
        element.unsubscribe();
      });
    }
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  saveAsDraft(event) {
    this.saveDegreeDetails(event);
  }

  // ----------------------------------------------- LIST VIEW CODE START -----------------------------------------------//

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  handleEdit(educationElement) {
    this.enableEdit.emit(educationElement);
  }

  handleDelete(input, addDegreeCourceForm) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        let petitionId = JSON.parse(sessionStorage.getItem('tempPetitionId'));
        this.srv.deletegcI140DegreeDetails(this.inputData.superTaskId, this.inputData.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
          if (this.addDegreeCourceForm.get('id').value === input.id) {
            this.resetForm();
          }
          this.toastr.success("Data deleted successfully!", 'Success');
          this.getEducationList();
        });
      }
    });
  }

  getUpolodedFiles() {
    this.apiService.genericGetUploadDocument(this.degreeFileUploadConfigurations.useCase, this.degreeFileUploadConfigurations.useCaseId, this.degreeFileUploadConfigurations.categoryName, this.degreeFileUploadConfigurations.entityId, this.degreeFileUploadConfigurations.useEntityId, this.degreeFileUploadConfigurations.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response['status'] === 200) {
        this.listUploadedFiles = response['data'];
        // this.degreeFileUploadConfigurations.listUploadedFiles = response['data'];
        this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      }
      else {
        this.listUploadedFiles = [];
        this.degreeFileUploadConfigurations.listUploadedFiles = [];
      }
    });
  }

  getEducationList() {
    let url;
    if(this.editEduData.caseType == 'I140') {
      url = this.srv.getBeneEducationInformation(
        this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.superTaskId : this.editEduData.superTaskId,
        this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.subTaskId : this.editEduData.subTaskId,
        true
      );
    } else if(this.editEduData.caseType == 'Perm') {
      url = this.srv.getBeneEducationInformationPerm(
        this.editEduData.caseId, true);
    }

    url.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      this.educationList = data;

      if (data['status'] === 200) {
        this.educationDetailsList = data['data'];
        if (this.educationDetailsList) {
          this.educationDetails = this.educationDetailsList;
          this.educationDetails = this.educationDetails.map(e1 => {
            let _e1 = { ...e1 };
            if (_e1.documents) {
              _e1.documents = _e1.documents.filter(e => !!e.fileLocation).map(e2 => {
                let _e2 = { ...e2 };
                if (_e2.fileLocation) {
                  _e2.fileLocationName = _e2.fileLocation.substring(23);
                  this.courses = [];
                  this.addDegreeCourceForm.controls['id'].setValue(_e1.id);
                  return _e2;
                }
              });
            }
            else {
              this.degreeFileUploadConfigurations.listUploadedFiles = [];
            }
            return _e1;
          });
          this.dataSource = new MatTableDataSource(this.educationDetails);
        }
      }
      if (data['status'] === 204) {
        this.educationDetailsList = [];
        this.educationDetails = [];
        this.dataSource = new MatTableDataSource([]);
      }
    });
  }
}
