import {
  Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, AfterContentInit,
  OnDestroy, AfterViewInit, Input, SimpleChanges, OnChanges
} from "@angular/core";
import { NgForm, Validators } from '@angular/forms';
import { ApiServicesService } from "../../services/api-services.service";
import { CacheCountryService } from "../../../../services/cacheCountries.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { ReferenceLibrary } from "../../../../data/models/referenceLibrary.model";
import { UploadDocumentService } from "../../../../services/upload-document.service";
import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ImagilityBaseResponse } from "../../../../data/models/response.model";
import { WorkDetails, Client, JobDuties, Tool } from "../../../../data/models/work-experience.model";
import { environment } from '../../../../../environments/environment';
import { UtilityServiceService } from '../../services/utility-service.service';
import * as Roles from '../../../../data/constants/roles';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute } from '@angular/router';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { DirtyFormGuard } from '../../../../dirty-form.guard'
import { DependentProfileDataService } from "../../services/dependent-profile-data.service";
import { BeneficiaryQuestionnaireService } from "src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service";
import { CustomQuestionsComponent } from '../../../shared/custom-questions/custom-questions.component';

import { UnsubscribeSubcriptionService } from 'src/app/services/unsubscribe-subcription.service';
import { acceptedFileTypes } from 'app-models';
import { ApiServicesService as benQuestionairreApiService } from "src/app/modules/beneficiary-questionnaire/services/api-services.service";
@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class WorkExperienceComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() mode: string;
  @Input() showLifeStoryTimeLineSection: boolean;
  @Input() isViewMode:boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;
  iscaseRequest: boolean = false;
  currentItemDisplay: any = [];

  modeVal: boolean = false;
  beneficiaryWorkDetails = new WorkDetails({});
  client: Client = {
    clientName: '',
    id: null,
    startDate: null,
    endDate: null
  };
  [x: string]: any;
  // subscriptions: Subscription;
  duty: JobDuties = {
    duty: '',
    dutyId: null,
    sequenceNo: null,
    subDuties: [
      {
        subDutyDescription: '',
        subDutyId: null,
        sequenceNo: null
      }
    ]
  };

  tool: Tool = {
    id: null,
    skillName: ''
  };

  listToolsTechnology: Tool[] = [];

  envPath: any;


  jobEditable = false;

  hasUnsavedData: boolean = false;
  beneficiaryId: number;
  phoneTypeList: ReferenceLibrary[];
  currencyTypeList: ReferenceLibrary[];
  listEmploymentType: ReferenceLibrary[];
  listCountry: any[];
  listStates: any[];
  //auto chips
  validation_pattern;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hasID: boolean = false;
  isLinear: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  checked: boolean = false;
  expandedElement: any | null;
  maxDate: Date;


  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  viewMode: boolean = false;
  isBenQuestShowDivs: boolean = false;

  destroy$ = new Subject<void>();

  @ViewChild('item', { static: true }) item: ElementRef;
  @ViewChild('benExpForm', { read: NgForm }) benExpForm: NgForm;

  showEditButton: boolean = true;
  beneficiaryDetails;
  updateBenProfile: Boolean = true;
  expandPanel = false;
  isListView = false;
  isTimeLineView = true;
  visibleSubSection = "WORKEXP";
  experienceData: any[];

  payload: any = null;
  toolsAndTechChip;
  columns = [
    { columnDef: 'toggle', header: '', columnType: 'toggleicon', customClass: '' },
    { columnDef: 'designation', header: 'Title', columnType: 'text', customClass: '' },
    { columnDef: 'companyName', header: 'Company Name', columnType: 'text', customClass: '' },
    { columnDef: 'employmentTypeValue', header: 'Employment Type', columnType: 'text', customClass: '' },
    { columnDef: 'startDate', header: 'From ', columnType: 'text', customClass: '' },
    { columnDef: 'endDate', header: 'To', columnType: 'text', customClass: '' },
    { columnDef: 'salary', header: 'Salary', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  experienceFileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENWEDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENWEDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    isDeleteEnabled: this.viewMode ? false : true,
    isUploadEnabled: this.viewMode ? false : true,
    familyId: null
  };
  dirtyFormList = {
    form1Dirty: false,
    form2Dirty: false,
    form3Dirty: false,
    form4Dirty: false
  };
  dirtyFormName = {
    form1Name: '',
    form2Name: '',
    form3Name: '',
    form4Name: '',
  };
  dependentIdSubscription: Subscription;
  observableSubscription$ = new Subject();
  dependentId: any;
  beneficiaryType: any;
  familyId: any;
  subscriptions: Subscription[] = [];
  isSaveAsDraftDisabled: boolean = true;
  selectedUser: any;
  customQuestions: any;
  // customTriggeredStatus: boolean = false;
  @ViewChild(CustomQuestionsComponent) customQuestionsComponent;

  isExternalEmployeeId:string;
  isLocked:boolean=false;
  onlyFormBuilder;
  listCountryOfficePhone: any[] = [];

  isFBUser: boolean;
  isWizard: boolean = false;
  beneData: any = null;
  wizardData: any = {};
  constructor(private apiService: ApiServicesService,
    private cacheCountryService: CacheCountryService,
    private authenticationService: AuthenticationService,
    private uploadDocumentService: UploadDocumentService,
    private referenceLibraryService: ReferenceLibraryService,
    private utilityService: UtilityServiceService,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private dirtyFormGuard: DirtyFormGuard,
    private benServ: BeneficiaryQuestionnaireService,
    private unsubscribeSubscriptionService: UnsubscribeSubcriptionService,
    private benQuestionairreApiService: benQuestionairreApiService) {
    this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    this.listCountry = [];
    this.listStates = [];
    this.phoneTypeList = [];
    this.maxDate = new Date();
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if(this.isExternalEmployeeId){
      this.isLocked=true;
    }

    this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
    //check for Wizard flag
    this.beneData = !!(localStorage.getItem('BeneData')) ? JSON.parse(localStorage.getItem('BeneData')) : null;
    this.isWizard = this.beneData?.version === 'WWF';
    if(this.isWizard){
      this.wizardData = {
        visaType: this.beneData?.visaType,
        caseType: this.beneData?.taskTypeRef,
        caseId: this.beneData?.petitionId,
        superTaskId: this.beneData.superTaskId,
        subTaskId: this.beneData?.taskId,
        taskId: this.beneData?.taskId,
      };
    }
    
  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });

    if (this.route.snapshot.paramMap.get('listview')) {
      this.toggleListView(true);
    }
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('PHON').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.phoneTypeList = response;
    });
    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries.map((c)=>{
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountry = this.listCountryOfficePhone = [{countryCode:null,countryName:'Select',labelWithCode:'Select',phoneCode:''},...this.listCountry];
      }
    });
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('CURR').pipe(takeUntil(this.observableSubscription$)).subscribe((data: []) => {
      this.currencyTypeList = [{code: "",name: "Select", id: 0,desc: '',group: ''},...data];
    });
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('EMPTYP').pipe(takeUntil(this.observableSubscription$)).subscribe((empTypeResponse: []) => {
      this.listEmploymentType = empTypeResponse;
    });
    this.dependentIdSubscription = this.dependentProfileDataService.dependentProfileSubject.pipe(takeUntil(this.observableSubscription$)).subscribe((selectedBeneObj:any) => {
      this.familyId = selectedBeneObj.dependentId;
      this.beneficiaryType = selectedBeneObj.beneficiaryType;
      this.showLifeStoryTimeLineSection = (this.beneficiaryType === 'family' && this.familyId) ? true : (this.beneficiaryType === 'self') ? true : false;

     if(this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')){
      if(this.beneficiaryType === 'self'){
        this.iscaseRequest = true;
      } else {
        this.iscaseRequest = false;
      }
     }
      this.getBeneficiaryWorkDetails();
    });
    //  this.getBeneficiaryWorkDetails();
    this.dependentProfileDataService.onLifeStorySubTabs.next();
    this.envPath = environment.docs;

    //IM-3066 - remove deleted file.
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getBeneficiaryWorkDetails();
      }
    });
    this.uploadDocumentService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getBeneficiaryWorkDetails();
      }
    });
    // console.log(this.clientForm_,"nnnn")
    this.initializeFormMonitoring();
    this.showEditButton = !this.benServ.checkValidity();
    if(localStorage.getItem('currentUserSelection')){
      this.selectedUser = JSON.parse(localStorage.getItem('currentUserSelection'));
    }
    if(localStorage.getItem('customQuestions')){
      this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    if (this.modeVal) {
      this.showLifeStoryTimeLineSection = true;
      this.toggleListView(true);
      this.toggleExpansion();
    }
    // console.log(this.clientForm_,"fffff")
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  initializeFormMonitoring() {
    let message;
    let formList;
    this.utilityService.statusChangeSubScriptionSubjectStep.next(1);
    this.utilityService.formIsDirtySubject.subscribe(response => {
      switch (response.formNumber) {
        case 1:
          this.dirtyFormList.form1Dirty = response.dirty;
          this.dirtyFormName.form1Name = response.formName;
          break;
        case 2:
          this.dirtyFormList.form2Dirty = response.dirty;
          this.dirtyFormName.form2Name = response.formName;

          break;
        case 3:
          this.dirtyFormList.form3Dirty = response.dirty;
          this.dirtyFormName.form3Name = response.formName;
          break;
        case 4:
          this.dirtyFormList.form4Dirty = response.dirty;
          this.dirtyFormName.form4Name = response.formName;
      }
      formList = this.dirtyFormName.form1Name ? this.dirtyFormName.form1Name + ',' : '' +
        this.dirtyFormName.form2Name ? this.dirtyFormName.form2Name + ',' : '' +
          this.dirtyFormName.form3Name ? this.dirtyFormName.form3Name + ',' : '' +
            this.dirtyFormName.form4Name ? this.dirtyFormName.form4Name + ',' : '';
      formList = formList.slice(0, -1);
      message = `If you leave before saving, all changes made on the ${formList} form will be lost. Do you wish to continue?`
      if (!this.dirtyFormList.form1Dirty &&
        !this.dirtyFormList.form2Dirty &&
        !this.dirtyFormList.form3Dirty &&
        !this.dirtyFormList.form4Dirty
      ) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      } else {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: message
        }));
      }
    });
  }

  formCheck(benExpForm, clientForm, dutiesForm, toolsAndTech) {

    if (this.utilityService.formChangesSubscription) {
      this.utilityService.formChangesSubscription.unsubscribe();
    }
    this.benExpForm = benExpForm;
    benExpForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.utilityService.statusChangeSubScriptionSubjectStep.next(1);
      if (benExpForm.dirty) {
        this.utilityService.formIsDirtySubject.next({ dirty: true, formName: 'Employment', formNumber: 1 });
      }
      else {
        this.utilityService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 1 });
      }
    });

    this.subscriptions[0] = this.benExpForm.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((formValues)=>{
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });

    clientForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.utilityService.statusChangeSubScriptionSubjectStep.next(2);
      if (clientForm.dirty) {
        this.utilityService.formIsDirtySubject.next({ dirty: true, formName: 'Client', formNumber: 2 });
      }
      else {
        this.utilityService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 2 });
      }
    });

    dutiesForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.utilityService.statusChangeSubScriptionSubjectStep.next(3);
      if (dutiesForm.dirty) {
        this.utilityService.formIsDirtySubject.next({ dirty: true, formName: 'Duties', formNumber: 3 });
      }
      else {
        this.utilityService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 3 });
      }
    });

    toolsAndTech.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.utilityService.statusChangeSubScriptionSubjectStep.next(4);
      if (toolsAndTech.dirty) {
        this.utilityService.formIsDirtySubject.next({ dirty: true, formName: 'Tools and Technology', formNumber: 4 });
      }
      else {
        this.utilityService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 4 });
      }
    });

  }



  // IM-4368
  // getBeneficiaryDetails() {
  //     this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'experience', this.familyId).pipe(takeUntil(this.observableSubscription$),
  //       map((beneficiary: any[]) => {
  //         beneficiary = beneficiary.map((experience) => {
  //           const clients = [];
  //           if (experience.clients && experience.clients.length > 0) {
  //             experience.clients.forEach(val => {
  //               clients.push(val.clientName);
  //             });
  //           }
  //           return {
  //             ...experience,
  //             employmentTypeValue: experience.employmentType ? experience.employmentType['name'] : '',
  //             'clientsList': clients.join(','),
  //             'startDate': experience['startDate'] ? moment(experience['startDate']).format('DD-MMM-YYYY') : '',
  //             'endDate': experience['endDate'] ? moment(experience['endDate']).format('DD-MMM-YYYY') : 'Present'
  //           };
  //         });
  //         return beneficiary;
  //       }), shareReplay()
  //     );
  // }

  // GET api call & Main Table
  getBeneficiaryWorkDetails() {
        this.dataSource = null;
    this.experienceData = [];
    //Check if wizard then get petition level data
    let getExpApi = this.isWizard ? this.benQuestionairreApiService.getBeneExperienceInformation(this.wizardData?.visaType,this.wizardData?.caseType,this.wizardData?.caseId,'experience',this.wizardData?.superTaskId,this.wizardData?.taskId,false) :this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'experience', this.familyId);
    // IM-4368
    getExpApi.pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: WorkDetails[]) => {
      beneficiary = beneficiary?.map((experience: any) => {
        const clients = [];
        if (experience.clients && experience.clients.length > 0) {
          experience.clients.forEach(val => {
            clients.push(val.clientName);
          });
        }

        experience['documents'] = experience?.documents?.map(e => {
          let _e = {...e};

          _e.fileLocationName = this.isFBUser ? (_e.fileLocation ? _e.fileLocation.split('/').slice(-1)[0]: null) : (_e.fileLocation ? _e.fileLocation.substring(23) : null);

          return _e;
        });

        return {
          ...experience,
          employmentTypeValue: experience?.employmentType ? experience.employmentType['name'] : '',
          'clientsList': clients.join(','),
          'startDate': experience['startDate'] ? moment(experience['startDate']).format('DD-MMM-YYYY') : '',
          'endDate': experience['endDate'] ? moment(experience['endDate']).format('DD-MMM-YYYY') : 'Present'
        };
      });

      this.hasUnsavedData = false;

      this.dataSource = new MatTableDataSource(beneficiary);



      this.experienceData = beneficiary || [];

      this.benServ.experienceChanged(this.experienceData)
      // this.experienceData.forEach(item => {
      //   const clients = [];
      //   if (item.clients && item.clients.length > 0) {
      //     item.clients.forEach(val => {
      //       clients.push(val.clientName);
      //     });
      //   }
      //   item['clientsList'] = clients.join(',');
      //   item['startDate'] = (item['startDate']) ? moment(item['startDate']).format('DD-MMM-YYYY') : '';
      //   item['endDate'] = (item['endDate']) ? moment(item['endDate']).format('DD-MMM-YYYY') : 'Present';
      // });
    });


  }

  // IM-4368
  updateView(id: number) {
    // this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'experience').subscribe((beneficiary: WorkDetails[]) => {
      //Check if wizard then get petition level data
    let getExpApi = this.isWizard ? this.benQuestionairreApiService.getBeneExperienceInformation(this.wizardData?.visaType,this.wizardData?.caseType,this.wizardData?.caseId,'experience',this.wizardData?.superTaskId,this.wizardData?.taskId,false) :this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'experience', this.familyId);
    // IM-4368
    getExpApi.pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: WorkDetails[]) => {
      beneficiary = beneficiary?.map((experience) => {
        const clients = [];
        if (experience.clients && experience.clients.length > 0) {
          experience.clients.forEach(val => {
            clients.push(val.clientName);
          });
        }
        return {
          ...experience,
          employmentTypeValue: experience?.employmentType ? experience.employmentType['name'] : '',
          'clientsList': clients.join(','),
          'startDate': experience['startDate'] ? moment(experience['startDate']).format('DD-MMM-YYYY') : '',
          'endDate': experience['endDate'] ? moment(experience['endDate']).format('DD-MMM-YYYY') : 'Present'
        };
      });

      this.hasUnsavedData = false;
      this.dataSource = new MatTableDataSource(beneficiary);
      this.handleEdit(beneficiary.find(x => x.id === id));
    });
  }

  resetFormToInitial(form: NgForm) {
    if(form)
      form.reset();
  }

  resetForm() {
    this.experienceFileUploadConfigurations.listUploadedFiles = [];
    this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
    this.beneficiaryWorkDetails = new WorkDetails({});
    this.hasID = false;
    this.isLinear = true;
  }

  closeForm(ngFrm) {
    this.resetForm();
    this.toggleExpansion();
    ngFrm.reset(ngFrm.value);
  }

  getUpolodedFiles() {
    this.uploadDocumentService.genericGetUploadDocument(this.experienceFileUploadConfigurations.useCase, this.experienceFileUploadConfigurations.useCaseId, this.experienceFileUploadConfigurations.categoryName, this.experienceFileUploadConfigurations.entityId, this.experienceFileUploadConfigurations.useEntityId, this.experienceFileUploadConfigurations.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response['status'] === 200) {
        this.experienceFileUploadConfigurations.listUploadedFiles = response['data'];
        this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
      }
      else {
        this.experienceFileUploadConfigurations.listUploadedFiles = [];
        this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
      }
    });

  }

  // Save employment
  saveEmployment(benExpForm: NgForm, isDraft:boolean = false) {
    this.payload = this.constructPayload(this.beneficiaryWorkDetails, isDraft);
    const reqTobeCalled = this.isWizard ? this.benQuestionairreApiService.savePetitionWorkExperience(this.wizardData?.caseId,this.payload) : this.apiService.saveBeneficiaryDetails(this.beneficiaryId, 'experience', this.payload, this.familyId);
    reqTobeCalled.pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        if (this.beneficiaryType === 'self') {
          if(this.customQuestionsComponent){
            this.customQuestionsComponent.onClickSave(null);
          }
        } else {
          if(this.customQuestionsComponent){
            this.customQuestionsComponent.onClickSave(this.familyId);
          }
        }
        benExpForm.reset(benExpForm.value)
        this.toastr.success(response.message, 'Success');
        if(this.isWizard){
          this.payload.id = response.data;
        }else{
          this.payload.id = response.data['workExpDetailsReq'][0]['id'];
        }
        this.updateView(this.payload.id);
        this.getBeneficiaryWorkDetails();
        this.hasID = true;
        this.isLinear = false;
      }
    });
   //  this.customTriggeredStatus == false ? this.customTriggeredStatus = true: this.customTriggeredStatus = false;
  }

  constructPayload(input, isDraft:boolean = false) {
    const mainObject = {
      id: input.id || null,
      addressLine1: input.addressLine1 || null,
      addressLine2: input.addressLine2 || null,
      city: input.city || null,
      companyName: input.companyName || null,
      employmentType: input.employmentType ? input.employmentType : null,
      country: input.countryCode || null,
      countryCode: input.countryCode || null,
      currency: input.currency || null,
      designation: input.designation || null,
      isCurrentRole: input.isCurrentRole ? 1 : 0,
      mobileCountryCode: null,
      mobileNo: null,
      officeCountryCode: input.officeCountryCode ? input.officeCountryCode : null,
      officeNo: input.officeNo || null,
      salary: parseInt(input.salary) || null,
      startDate: input.startDate ? moment(input.startDate).format('YYYY-MM-DD'): null,
      endDate: input.isCurrentRole ? null : (input.endDate ? moment(input.endDate).format('YYYY-MM-DD') : null),
      stateProvinceCode: input.stateProvinceCode || null,
      stateProvinceName: input.stateProvinceCode && this.listStates && this.listStates.length > 0 ?
        this.listStates.find((x: any) => x.stateProvinceCode === input.stateProvinceCode).stateProvinceName :
        (input.stateProvinceName ? input.stateProvinceName : null),
      workExpDetailId: input.id || null, //input.workExpDetailId,
      zipCode: input.zipCode || null,
      duty: input.duty || null,
      subDutyDescription: input.subDutyDescription || null,
      skillName: input.skillName || null,
      jobDuties: input.jobDuties || [],
      tools: input.tools || [],
      clients: input.clients || []
    }
    if(this.isWizard){
      this.payload = mainObject;
    }else{
      this.payload = {
        'workExpDetailsReq': [mainObject],
        isSaveAsDraft: isDraft
      };
    }
    return this.payload;
  }

  callSaveAPI(payload) {
    const saveReq = this.isWizard ? this.benQuestionairreApiService.savePetitionWorkExperience(this.wizardData?.caseId,this.payload) : this.apiService.saveBeneficiaryDetails(this.beneficiaryId, 'experience', payload, this.familyId);
    saveReq.pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
        if(this.isWizard){
          payload.id = response?.data;
        }else{
          payload.id = response.data && response.data['workExpDetailsReq'][0] && response.data['workExpDetailsReq'][0]['id'];
        }
        this.updateView(payload.id);
      }
    });
  }

  //clientForm: NgForm;

  resetClient(clientForm) {
    clientForm.resetForm();
  }

  addClientDetails(clientForm: NgForm) {
   // if (this.client.clientName && this.client.startDate) {
      this.beneficiaryWorkDetails.clients.push({
        id: null,
        clientName: this.client.clientName,
        startDate: this.client.startDate ? moment(this.client.startDate).format('YYYY-MM-DD') : '',
        endDate: (this.client.endDate) ? moment(this.client.endDate).format('YYYY-MM-DD') : ''
      });
      this.payload = this.constructPayload(this.beneficiaryWorkDetails);
      this.callSaveAPI(this.payload);
      this.client.clientName = '';
      this.client.startDate = '';
      this.client.endDate = '';
      clientForm.resetForm(); // clear the form after add the client details
    //}

  }

  removeClientItem(id) {
    let index = this.beneficiaryWorkDetails.clients.findIndex(itm => itm.id === id);
    this.beneficiaryWorkDetails.clients.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  //dutiesForm: NgForm;
  resetJobDuties(dutiesForm) {
    dutiesForm.resetForm();
    this.duty = {
      duty: '',
      dutyId: null,
      sequenceNo: null,
      subDuties: [
        {
          subDutyDescription: '',
          subDutyId: null,
          sequenceNo: null
        }
      ]
    };
  }

  removeToolsChip(item) {
    const index = this.listToolsTechnology.indexOf(item);
    if (index >= 0) {
      this.listToolsTechnology.splice(index, 1);
    }
  }

  addJobDuties(duty) {
    if (!this.jobEditable) {
      this.beneficiaryWorkDetails.jobDuties.push({
        dutyId: duty && duty.dutyId ? duty.dutyId : null,
        sequenceNo: 0,
        duty: duty.duty,
        subDuties: duty.subDuties,
      });
    }
    this.jobEditable = false;
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  addSubDuties() {
    this.duty.subDuties.push({
      sequenceNo: null,
      subDutyId: null,
      subDutyDescription: ''
    });
  }
  deleteSubDuties(index) {
    this.duty.subDuties.splice(index, 1);
  }

  removeJobDuty(id) {
    let index = this.beneficiaryWorkDetails.jobDuties.findIndex(itm => itm.dutyId === id);
    this.beneficiaryWorkDetails.jobDuties.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  editDuty(duty) {
    this.jobEditable = true;
    this.duty = duty;
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.listToolsTechnology.push({
        skillName: value.trim(),
        id: null
      });
    }
    if (input) {
      input.value = '';

    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/,/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.listToolsTechnology.push({
            skillName: value.trim(),
            id: null
          }); //Push if valid
        }
      });
  }

  addToolsTechnologies(toolsAndTech) {
    this.beneficiaryWorkDetails.tools = this.listToolsTechnology.concat(this.beneficiaryWorkDetails.tools);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
    this.listToolsTechnology = [];
    toolsAndTech.reset(toolsAndTech.value);
    this.toolsAndTechChip = '';

  }

  removeToolsTechnology(id: number) {
    let index = this.beneficiaryWorkDetails.tools.findIndex(itm => itm.id === id);
    this.beneficiaryWorkDetails.tools.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  handleEdit(input) {    
    this.beneficiaryWorkDetails = {
      id: input.id,
      addressLine1: input.addressLine1,
      addressLine2: input.addressLine2,
      city: input.city,
      companyName: input.companyName,
      scheduledWeeklyHours:input.scheduledWeeklyHours,
      employmentType: input.employmentType ? input.employmentType.code : null,
      country: input.countryCode,
      countryCode: input.countryCode,
      currency: input.currency,
      designation: input.designation,
      isCurrentRole: input.isCurrentRole == 1 ? true : false,
      mobileCountryCode: null,
      mobileNo: null,
      officeCountryCode: input.officeCountryCode && input.officeCountryCode.countryCode ? input.officeCountryCode.countryCode : '',
      officeNo: input.officeNo,
      salary: input.salary,
      startDate: moment(input.startDate).toISOString(),
      endDate: input.isCurrentRole == 1 ? null : moment(input.endDate).toISOString(),
      stateProvinceCode: input.stateProvinceCode,
      stateProvinceName: input.stateProvinceCode,
      workExpDetailId: input.id,
      zipCode: input.zipCode,
      duty: input.duty,
      subDutyDescription: input.subDutyDescription,
      skillName: input.skillName,
      jobDuties: input.jobDuties,
      tools: input.tools,
      clients: input.clients,
      documentList: input.documents
    };
    this.hasID = true;
    this.isLinear = false;
    this.experienceFileUploadConfigurations.entityId = input.id;
    this.experienceFileUploadConfigurations.useCaseId = this.beneficiaryId;
    this.experienceFileUploadConfigurations.familyId = this.familyId ? this.familyId : null;
    // this.listToolsTechnology = input.tools;
    this.experienceFileUploadConfigurations.listUploadedFiles = input.documents ? input.documents : [];
    this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
    this.changeDetector.markForCheck();

    this.expandPanel = true;
    // this.handleCountryChange(input.countryCode);
    this.apiService.getStates(input.countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      this.beneficiaryWorkDetails.stateProvinceName = input.stateProvinceCode ?
      this.listStates.find((x: any) => x.stateProvinceCode === input.stateProvinceCode).stateProvinceName : (input.stateProvinceName ? input.stateProvinceName : null);

      this.beneficiaryWorkDetails = {
        id: input.id,
        addressLine1: input.addressLine1,
        addressLine2: input.addressLine2,
        city: input.city,
        companyName: input.companyName,
        scheduledWeeklyHours:input.scheduledWeeklyHours,
        employmentType: input.employmentType ? input.employmentType.code : null,
        country: input.countryCode,
        countryCode: input.countryCode,
        currency: input.currency,
        designation: input.designation,
        isCurrentRole: input.isCurrentRole == 1 ? true : false,
        mobileCountryCode: null,
        mobileNo: null,
        officeCountryCode: input.officeCountryCode && input.officeCountryCode.countryCode ? input.officeCountryCode.countryCode : '',
        officeNo: input.officeNo,
        salary: input.salary,
        startDate: moment(input.startDate).toISOString(),
        endDate: input.isCurrentRole == 1 ? null : moment(input.endDate).toISOString(),
        stateProvinceCode: input.stateProvinceCode,
        stateProvinceName: input.stateProvinceCode ?
          this.listStates.find((x: any) => x.stateProvinceCode === input.stateProvinceCode).stateProvinceName :
          (input.stateProvinceName ? input.stateProvinceName : null),
        workExpDetailId: input.id,
        zipCode: input.zipCode,
        duty: input.duty,
        subDutyDescription: input.subDutyDescription,
        skillName: input.skillName,
        jobDuties: input.jobDuties,
        tools: input.tools,
        clients: input.clients,
        documentList: input.documents
      };
      this.hasID = true;
      this.isLinear = false;
      this.experienceFileUploadConfigurations.entityId = input.id;
      if(this.isWizard){
         'petition';
      }
      this.experienceFileUploadConfigurations.useCase = this.isWizard ? 'petition' : 'beneficiary';
      this.experienceFileUploadConfigurations.useCaseId = this.isWizard ? this.wizardData?.caseId : this.beneficiaryId;
      this.experienceFileUploadConfigurations.familyId = this.familyId ? this.familyId : null;
      // this.listToolsTechnology = input.tools;
      this.experienceFileUploadConfigurations.listUploadedFiles = input.documents ? input.documents : [];
      this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
      this.changeDetector.markForCheck();
    });
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    this.resetForm();
    this.hasID = false;
    this.isLinear = true;
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }

  }

  handleCountryChange(countryCode: string) {
    if(null === countryCode){
      this.listStates = [];
      this.beneficiaryWorkDetails['stateProvinceCode'] = null;
      this.beneficiaryWorkDetails['stateProvinceName'] = null;
      return;
    }
    this.apiService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      if(this.listStates.length){
        this.beneficiaryWorkDetails['stateProvinceCode'] = null;
      }else{
        this.beneficiaryWorkDetails['stateProvinceName'] = null;
      }
      this.changeDetector.markForCheck();
    });
  }

  handleDeleteExperience(input) {
    const title = 'Confirm';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
      if (result) {
        const deleteReq = this.isWizard ? this.benQuestionairreApiService.deletBeneWorkExperience(this.wizardData?.visaType,this.wizardData?.caseType,this.wizardData?.caseId,'experience',this.wizardData?.superTaskId,this.wizardData?.subTaskId,input.id) : this.apiService.deleteBeneExperience(this.beneficiaryId, input.id, this.familyId);
        deleteReq.pipe(takeUntil(this.observableSubscription$)).subscribe((data: ImagilityBaseResponse) => {
          if (data.status === 200) {
            if (this.beneficiaryWorkDetails.id === input.id) {
              this.resetForm();
            }
            this.toastr.success(data.message, 'Success');
            this.getBeneficiaryWorkDetails();
          }
        });
      }
    });
  }

  fileUploadSuccess() {
    this.getBeneficiaryWorkDetails(); // Relaod main table
  }

  handleEmploymentTypeChange(val, benExpForm: NgForm) {
    // console.log("$$$$$$$",val,benExpForm);
    benExpForm.form.get('currency').clearValidators();
    benExpForm.form.get('salary').clearValidators();
    // if (val !== 'SELF') {
    //   benExpForm.form.get('currency').setValidators([Validators.required]);
    //   benExpForm.form.get('salary').setValidators([Validators.required]);
    // } else {
    //   benExpForm.form.get('currency').clearValidators();
    //   benExpForm.form.get('salary').clearValidators();
    // }
    benExpForm.form.get('currency').updateValueAndValidity();
    benExpForm.form.get('salary').updateValueAndValidity();

  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  onRefreshPage(event){
    if(event){
      this.ngOnInit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode.currentValue) {
      if (changes.mode.currentValue === 'list') {
        this.modeVal = true;
      }
    }
    if((changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue)){
      if(changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue){
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      if(changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue){
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
    } else {
      this.iscaseRequest = false;
    }
    // console.log('this.currentItemDisplay', this.currentItemDisplay);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.unsubscribeSubscriptionService.onUnSubscribe(this.subscriptions);
    if (this.utilityService.formChangesSubscription) {
      this.utilityService.formChangesSubscription.unsubscribe();
    }
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  saveAsDraft(event){
    if(event){
      this.saveEmployment(this.benExpForm, event);
    }
  }
}
