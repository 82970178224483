<div class="row pl--1rem8  d-flex align-items-center" *ngIf="stepTaskTimelineList.length > 0">
    <div class="col-md-8 step-header pt-4 pl-0 pb-2">
        <h4>Task Timeline Details</h4>
    </div>
    <div class=" col-4 pb-2">
        <form [formGroup]=" dueDateForm">
        <span class="mt-2 ml-2">
        <label Class="filter-label">Task due date</label> 
        </span>
        
        <p-calendar #sd formControlName="taskduetDate"    styleClass="p-form-datepicker"
        dateFormat="mm/dd/yy"  [minDate]="minimumDate" placeholder="MM/DD/YYYY" (onSelect)="taskDueDateSelected(sd)" [showIcon]="true">
    </p-calendar>
    </form>
    </div>
    
    
</div>
<div class="mat-elevation-z8">
    <p-table class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
        styleClass="p-datatable-gridlines" [value]="stepTaskTimelineList" dataKey="id"
        *ngIf="stepTaskTimelineList.length > 0">
        <ng-template pTemplate="header" let-columns>
            <tr class="text-uppercase">
                <th>Step No </th>
                <th>Step Name </th>
                <th>Step Actionable For</th>
                <th *ngIf="!isAssignTo">Assign To</th>
                <th>Completion Time </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-expanded="expanded" let-rowIndex="rowIndex" let-columns="columns">
            <tr>
                <td> Step {{rowIndex+1}} </td>
                <td> {{row.stepName}} </td>
                <td>{{row.isBeneficiaryAction === 0 ? 'Petitioner/Lawfirm' : 'Beneficiary'}}</td>
                <td *ngIf="!isAssignTo && assignToList">

                    <div *ngIf="row.isPetitionerAction === 1 || row.isAttorneyAction === 1" class="processing-dropdown">
                        <p-dropdown *ngIf="!taskObj" [options]="assignToList" [(ngModel)]="row.assignToEmployee"
                            optionLabel="fullName" optionValue="id" placeholder="Select">
                        </p-dropdown>
                        <p-dropdown *ngIf="taskObj" [options]="assignToList" [(ngModel)]="row.assignToEmployee"
                            optionLabel="fullName" optionValue="id" placeholder="Select">
                        </p-dropdown>
                    </div>
                    <div *ngIf="row.isBeneficiaryAction === 1" class="processing-dropdown">
                        <p-dropdown [options]="beneficiaryList" [(ngModel)]="row.assignToBeneficiary"
                        optionLabel="beneficiaryEmployeeFullName" (onChange)="onChangeBene($event, row)" optionValue="beneficiaryEmployeeId" placeholder="Select">
                    </p-dropdown>
                    <!-- <p-dropdown *ngIf="!taskObj" [options]="beneficiaryList" [(ngModel)]="row.assignToBeneficiary"
                            optionLabel="beneficiaryEmployeeFullName" optionValue="beneficiaryEmployeeId" placeholder="Select" [disabled]="disabled">
                        </p-dropdown>
                        <span *ngIf="taskObj && row?.assignToBeneficiaryName">{{row.assignToBeneficiaryName}}</span>
                        <span *ngIf="taskObj && !row?.assignToBeneficiaryName">{{taskObj?.beneficiaryDetails?.firstName}} {{taskObj?.beneficiaryDetails?.lastName}}</span> -->
                    </div>
                </td>
                <td>
                    <input type="number"
                        [ngClass]="{'text-danger':row.duration > stateEnum.taskTimeLineValidationNumber && row.durationUnit === hours || row.duration <= stateEnum.shouldNotLessThanZero || row.duration === stateEnum. shouldGraterThanZero}"
                        (change)="numValidation(row)" pInputText class="timesInput" [(ngModel)]="row.duration" />
                    <div *ngIf="row.duration > stateEnum.taskTimeLineValidationNumber && row.durationUnit === hours"
                        class="text-hour">
                        You can’t assign more then {{stateEnum.taskTimeLineValidationNumber}} hr.
                    </div>
                    <div *ngIf="row.duration <= stateEnum.shouldNotLessThanZero || row.duration === stateEnum.shouldGraterThanZero"
                        class="text-hour">
                        Please enter time greater than {{stateEnum.shouldGraterThanZero}}.
                    </div>
                    <div class="day-hours">
                        <p-dropdown [options]="completionTimesList" optionLabel="name" optionValue="code"
                            (onChange)="onChange($event)" [(ngModel)]="row.durationUnit"></p-dropdown>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-table class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
        styleClass="p-datatable-gridlines" [value]="customStepTaskTimelineList" dataKey="id1"
        *ngIf="customStepTaskTimelineList.length > 0">
        <ng-template pTemplate="header" let-columns1>
            <tr class="text-uppercase" style="display: none !important;">
                <th>Step No </th>
                <th>Step Name </th>
                <th>Step Actionable For</th>
                <th *ngIf="!isAssignTo">Assign To</th>
                <th>Completion Time </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row1 let-expanded1="expanded" let-rowIndex1="rowIndex" let-columns1="columns1">
            <tr>
                <td> Step {{stepTaskTimelineList.length + rowIndex1+1}} </td>
                <td> {{row1.stepName}} </td>
                <td>{{row1.isBeneficiaryAction === 0 ? 'Petitioner/Lawfirm' : 'Beneficiary'}}</td>
                <td *ngIf="!isAssignTo && assignToList">

                    <div *ngIf="row1.isPetitionerAction === 1 || row1.isAttorneyAction === 1" class="processing-dropdown">
                        <p-dropdown *ngIf="!taskObj" [options]="assignToList" [(ngModel)]="row1.assignToEmployee"
                            optionLabel="fullName" optionValue="id" placeholder="Select">
                        </p-dropdown>
                        <p-dropdown *ngIf="taskObj" [options]="assignToList" [(ngModel)]="row1.assignToEmployee"
                            optionLabel="fullName" optionValue="id" placeholder="Select">
                        </p-dropdown>
                    </div>
                    <div *ngIf="row1.isBeneficiaryAction === 1" class="processing-dropdown">
                        <p-dropdown [options]="beneficiaryList" [(ngModel)]="row1.assignToBeneficiary"
                        optionLabel="beneficiaryEmployeeFullName" (onChange)="onChangeBene($event, row1)" optionValue="beneficiaryEmployeeId" placeholder="Select">
                    </p-dropdown>
                    <!-- <p-dropdown *ngIf="!taskObj" [options]="beneficiaryList" [(ngModel)]="row.assignToBeneficiary"
                            optionLabel="beneficiaryEmployeeFullName" optionValue="beneficiaryEmployeeId" placeholder="Select" [disabled]="disabled">
                        </p-dropdown>
                        <span *ngIf="taskObj && row?.assignToBeneficiaryName">{{row.assignToBeneficiaryName}}</span>
                        <span *ngIf="taskObj && !row?.assignToBeneficiaryName">{{taskObj?.beneficiaryDetails?.firstName}} {{taskObj?.beneficiaryDetails?.lastName}}</span> -->
                    </div>
                </td>
                <td>
                    <input type="number"
                        [ngClass]="{'text-danger':row1.duration > stateEnum.taskTimeLineValidationNumber && row1.durationUnit === hours || row1.duration <= stateEnum.shouldNotLessThanZero || row1.duration === stateEnum. shouldGraterThanZero}"
                        (change)="numValidation(row1)" pInputText class="timesInput" [(ngModel)]="row1.duration" />
                    <div *ngIf="row1.duration > stateEnum.taskTimeLineValidationNumber && row1.durationUnit === hours"
                        class="text-hour">
                        You can’t assign more then {{stateEnum.taskTimeLineValidationNumber}} hr.
                    </div>
                    <div *ngIf="row1.duration <= stateEnum.shouldNotLessThanZero || row1.duration === stateEnum.shouldGraterThanZero"
                        class="text-hour">
                        Please enter time greater than {{stateEnum.shouldGraterThanZero}}.
                    </div>
                    <div class="day-hours">
                        <p-dropdown [options]="completionTimesList" optionLabel="name" optionValue="code"
                            (onChange)="onChange($event)" [(ngModel)]="row1.durationUnit"></p-dropdown>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="stepTaskTimelineList.length == 0" class="mt-4 text-center">No Data</div>
</div>