<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12 p-0">
            <p-tabView [(activeIndex)]="index">
                <p-tabPanel header="Visa Bulletin" [headerStyle]='{"width": "150px"}' id="visaBulletingDetailsSub">
                    <p-tabView [(activeIndex)]="subIndex">
                        <p-tabPanel [headerStyle]='{"width": "250px"}'>
                            <ng-template pTemplate="header">
                                <div style="white-space: normal;">
                                    Current Visa Bulletin<br>
                                    <h5 class="m-0"><b>February 2024</b></h5>
                                </div>
                            </ng-template>
                            <ng-container>
                                <div class="row" id="visaBulletinginnerTab">
                                    <div class="col-12">
                                        <p-tabView [(activeIndex)]="innerTabIndex">
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;">
                                                        Family Sponsored
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of dataSourceFamily">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="sponsored">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Family-Sponsored</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.sponsored}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All Chargeability Areas Except Those Listed</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b>CHINA-mainland born</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="india">
                                                                <th mat-header-cell *matHeaderCellDef> <b>INDIA</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.india}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="mexico">
                                                                <th mat-header-cell *matHeaderCellDef> <b>MEXICO</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.mexico}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="philippines">
                                                                <th mat-header-cell *matHeaderCellDef> <b>PHILIPPINES </b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.philippines}} </td>
                                                              </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                          </table>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;"> 
                                                        Employment Based
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of dataSourceEmployment">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="sponsored">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Employment-based</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.sponsored}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All Chargeability Areas Except Those Listed</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b>CHINA-mainland born</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="india">
                                                                <th mat-header-cell *matHeaderCellDef> <b>INDIA</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.india}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="mexico">
                                                                <th mat-header-cell *matHeaderCellDef> <b>MEXICO</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.mexico}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="philippines">
                                                                <th mat-header-cell *matHeaderCellDef> <b>PHILIPPINES </b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.philippines}} </td>
                                                              </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                          </table>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;"> 
                                                        Diversity Immigrant
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of dataSourceDiversity">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="region">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Region</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.region}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All DV Chargeability Areas Except Those Listed Separately</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b></b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                            </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsDiversity"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsDiversity;"></tr>
                                                        </table>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>D.  SCHEDULED EXPIRATION OF THE EMPLOYMENT FOURTH PREFERENCE RELIGIOUS WORKERS (SR) CATEGORY</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p>Pursuant to H.R. 6363, signed on November 16, 2023, the non-minister special immigrant program expires on February 2, 2024.  No SR visas may be issued overseas, or final action taken on adjustment of status cases, after midnight February 1, 2024.  Visas issued prior to that date will be valid only until February 1, 2024, and all individuals seeking admission in the non-minister special immigrant category must be admitted (repeat admitted) into the United States no later than midnight February 1, 2024.</p>
                                                            <p class="mb-0">The SR category is available and subject to the same final action dates as the other Employment Fourth Preference categories per applicable foreign state of chargeability for February.  In the event there is no legislative action extending the category beyond February 2, 2024, the category will immediately become “Unavailable” as of February 2, 2024.  In the event there is legislative action extending the category beyond February 2, the published dates will continue to be in effect for the remainder of February.</p>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>E.  U.S. GOVERNMENT EMPLOYEE SPECIAL IMMIGRANT VISAS (SIVs)</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p class="mb-0">The National Defense Authorization Act (NDAA) for Fiscal Year 2024, signed into law on December 22, 2023, may affect certain current and former employees of the U.S. Government abroad applying for SIVs or adjustment of status, as described in section 101(a)(27)(D) of the INA.  This does not affect certain Iraqis and Afghans applying for SQ and SI SIVs.  Applicants should contact the consular section at which they filed their Form DS‑1884 for further information on the impact of that law on their case.</p>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>F.  FOR THE LATEST INFORMATION ON VISA PROCESSING AT U.S. EMBASSIES AND CONSULATES, PLEASE VISIT THE BUREAU OF CONSULAR AFFAIRS WEBSITE AT TRAVEL.STATE.GOV</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p>Department of State Publication 9514</p>
                                                            <p class="mb-0">CA/VO: January 10, 2024</p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                        </p-tabView>
                                    </div>
                                </div>
                            </ng-container>
                        </p-tabPanel>
                        <p-tabPanel [headerStyle]='{"width": "250px"}'>
                            <ng-template pTemplate="header">
                                <div style="white-space: normal;">
                                    Upcoming Visa Bulletin<br>
                                    <h5 class="m-0"><b>March 2024</b></h5>
                                </div>
                            </ng-template>
                            <ng-container>
                                <div class="row" id="visaBulletinginnerTab">
                                    <div class="col-12">
                                        <p-tabView [(activeIndex)]="innerTabIndex">
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;">
                                                        Family Sponsored
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of upComingDataSourceFamily">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="sponsored">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Family-Sponsored</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.sponsored}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All Chargeability Areas Except Those Listed</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b>CHINA-mainland born</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="india">
                                                                <th mat-header-cell *matHeaderCellDef> <b>INDIA</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.india}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="mexico">
                                                                <th mat-header-cell *matHeaderCellDef> <b>MEXICO</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.mexico}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="philippines">
                                                                <th mat-header-cell *matHeaderCellDef> <b>PHILIPPINES </b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.philippines}} </td>
                                                              </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                          </table>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;"> 
                                                        Employment Based
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of upComingDDataSourceEmployment">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="sponsored">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Employment-based</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.sponsored}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All Chargeability Areas Except Those Listed</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b>CHINA-mainland born</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="india">
                                                                <th mat-header-cell *matHeaderCellDef> <b>INDIA</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.india}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="mexico">
                                                                <th mat-header-cell *matHeaderCellDef> <b>MEXICO</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.mexico}} </td>
                                                              </ng-container>
                                                              <ng-container matColumnDef="philippines">
                                                                <th mat-header-cell *matHeaderCellDef> <b>PHILIPPINES </b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.philippines}} </td>
                                                              </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                          </table>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                            <p-tabPanel [headerStyle]='{"width": "150px"}'>
                                                <ng-template pTemplate="header">
                                                    <div style="white-space: normal;"> 
                                                        Diversity Immigrant
                                                    </div>
                                                </ng-template>
                                                <ng-container>
                                                    <div  class="bxShadow mb-4" *ngFor="let data of upcomingDataSourceDiversity">
                                                        <div class="row">
                                                            <div class="col-12 p-0">
                                                                <h6><b>{{data.heading}}</b></h6>
                                                            </div>
                                                        </div>
                                                        <table class="table table-bordered table-hover individual-list" mat-table [dataSource]="data.item">
                                                            <ng-container matColumnDef="region">
                                                              <th mat-header-cell *matHeaderCellDef> <b>Region</b> </th>
                                                              <td mat-cell *matCellDef="let element"> {{element.region}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="allChargeability">
                                                                <th mat-header-cell *matHeaderCellDef> <b>All DV Chargeability Areas Except Those Listed Separately</b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.allChargeability}} </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="china">
                                                                <th mat-header-cell *matHeaderCellDef> <b></b> </th>
                                                                <td mat-cell *matCellDef="let element"> {{element.china}} </td>
                                                            </ng-container>
                                                            
                                                            <tr mat-header-row *matHeaderRowDef="upcomingDisplayedColumnsDiversity"></tr>
                                                            <tr mat-row *matRowDef="let row; columns: upcomingDisplayedColumnsDiversity;"></tr>
                                                        </table>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>D.  SCHEDULED EXPIRATION OF THE EMPLOYMENT FOURTH PREFERENCE RELIGIOUS WORKERS (SR) CATEGORY</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p>Pursuant to H.R. 6363, signed on January 19, 2024, the non-minister special immigrant program expires on March 8, 2024.  No SR visas may be issued overseas, or final action taken on adjustment of status cases, after midnight March 7, 2024.  Visas issued prior to that date will be valid only until March 7, 2024, and all individuals seeking admission in the non-minister special immigrant category must be admitted (repeat admitted) into the United States no later than midnight March 7, 2024.</p>
                                                            <p class="mb-0">The SR category is available and subject to the same final action dates as the other Employment Fourth Preference categories per applicable foreign state of chargeability for March.  In the event there is no legislative action extending the category beyond March 8, 2024, the category will immediately become “Unavailable” as of March 8, 2024.  In the event there is legislative action extending the category beyond March 8, the published dates will continue to be in effect for the remainder of March.</p>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>E.  U.S. GOVERNMENT EMPLOYEE SPECIAL IMMIGRANT VISAS (SIVs)</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p class="mb-0">The National Defense Authorization Act (NDAA) for Fiscal Year 2024, signed into law on December 22, 2023, may affect certain current and former employees of the U.S. Government abroad applying for SIVs or adjustment of status, as described in section 101(a)(27)(D) of the INA.  This does not affect certain Iraqis and Afghans applying for SQ and SI SIVs.  Applicants should contact the consular section at which they filed their Form DS‑1884 for further information on the impact of that law on their case.</p>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 p-0">
                                                            <h6><b>F.  FOR THE LATEST INFORMATION ON VISA PROCESSING AT U.S. EMBASSIES AND CONSULATES, PLEASE VISIT THE BUREAU OF CONSULAR AFFAIRS WEBSITE AT TRAVEL.STATE.GOV</b></h6>
                                                        </div>
                                                        <div class="col-12 subInfoContent">
                                                            <p>Department of State Publication 9514</p>
                                                            <p class="mb-0">CA/VO: February 6, 2024</p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </p-tabPanel> 
                                        </p-tabView>
                                    </div>
                                </div>
                            </ng-container>
                        </p-tabPanel> 
                    </p-tabView>
                </p-tabPanel>
                <p-tabPanel header="Individuals Tracker" [headerStyle]='{"width": "150px"}'>
                    <ng-container>
                        <div class="row">
                           <div class="col-12">
                                <form [formGroup]="form" (ngSubmit)="checkStatus(1)">
                                    <div class="col-12 viewDetails">
                                        <p><b>View Details</b></p>
                                    </div>
                                    <div class="col-12 priorityDate">
                                        <div class="priorityDateCustom">
                                            <label class="d-block required m-0">Start Date</label>
                                            <p-calendar formControlName="startDate" showButtonBar="true"
                                                [monthNavigator]="true" [yearNavigator]="true" name="establishedDate" class="w-100" yearRange="1910:2050" dateFormat="mm-dd-yy" [showIcon]="true" [(ngModel)]="startDate" [maxDate]="maxEndDate" (onSelect)="validateDates()">
                                            </p-calendar>
                            
                                            <span class="invalid-message"
                                                *ngIf="form.get('startDate').touched && form.get('startDate').errors?.required">
                                                {{ getGlobalErrorMessages("REQUIRED") }}
                                            </span>
                                        </div>
                                        <div class="priorityDateCustom ml-2 mr-2">
                                            <label class="d-block required m-0">End Date</label>
                                            <p-calendar formControlName="endDate" showButtonBar="true"
                                                [monthNavigator]="true" [yearNavigator]="true" name="establishedDate" class="w-100" yearRange="1910:2050" dateFormat="mm-dd-yy" [showIcon]="true" [(ngModel)]="endDate" [maxDate]="maxEndDate" (onSelect)="validateDates()">
                                            </p-calendar> 
                            
                                            <span class="invalid-message"
                                                *ngIf="form.get('endDate').touched && form.get('endDate').errors?.required">
                                                {{ getGlobalErrorMessages("REQUIRED") }}
                                            </span>
                                            <span class="invalid-message" *ngIf="endDateError">End date cannot be greater than start date!</span>
                                        </div>
                                        <div class="checkStatus">
                                            <button pButton type="submit" class="mr-15" [disabled]="form.invalid || endDateError">
                                                Check Status
                                            </button>
                                        </div>
                                    </div>
                                </form>
                           </div>
                           <div *ngIf="isPriorityDateTracker" class="col-12">
                            <div class="row individualHeader pl-0 pr-0">
                                <div class="col-6 p-0"><h6 class="m-0"><b>Individuals Priority Date Tracker</b></h6></div>
                                <div class="col-6 actionItems pl-0 pr-0" *ngIf="this.totalRecordCount > 0">
                                    <button type="button" class="btn btn-primary mr-3 pr-3 pl-3" (click)="onClickMessage()">
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                        <span>Bulk Messaging</span>
                                    </button>
                                    <p-dropdown [options]="downloadOptions" class="filetype-dropdown ml-2 mt-1" [(ngModel)]="selectedDownlod" optionLabel="name"
                                    placeholder="Export To" (onChange)="selectedDownlod && onExportClicked(selectedDownlod)">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="download-item" *ngIf="selectedDownlod">
                                        <img [src]="selectedDownlod.path" alt="" class="mr-2" />
                                        <span>{{selectedDownlod?.name}}</span>
                                        </div>
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                        <div class="download-item">
                                        <img [src]="item.path" alt="" class="mr-2" />
                                        <span>{{item.name}}</span>
                                        </div>
                                    </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                           </div>
                           <div *ngIf="isPriorityDateTracker" class="col-12">
                            <div  class="bxShadow mb-4">
                                
                                <table *ngIf="this.totalRecordCount > 0" mat-table [dataSource]="dataSource1" class="table table-bordered table-hover individual-list">

                                    <!-- Checkbox Column -->
                                    <ng-container matColumnDef="select">
                                      <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                      [checked]="selection.hasValue() && isAllSelected()"
                                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                      [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                      </th>
                                      <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                                      (change)="$event ? selection.toggle(row) : null"
                                                      [checked]="selection.isSelected(row)"
                                                      [aria-label]="checkboxLabel(row)">
                                        </mat-checkbox>
                                      </td>
                                    </ng-container>
                                  
                                    <!-- Position Column -->
                                    <!-- <ng-container matColumnDef="position">
                                      <th mat-header-cell *matHeaderCellDef> No. </th>
                                      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                                    </ng-container> -->
                                  
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                      <th mat-header-cell *matHeaderCellDef> <b>Individuals Name</b> </th>
                                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>
                                  
                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="visaType">
                                      <th mat-header-cell *matHeaderCellDef> <b>Visa Type</b> </th>
                                      <td mat-cell *matCellDef="let element"> {{element.visaType}} </td>
                                    </ng-container>
                                  
                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="priorityDate">
                                        <th mat-header-cell *matHeaderCellDef> <div class="priorityInfo">
                                            <b>I-797 Priority Date</b> <mat-icon matTooltip="An individual's priority dates are determined by the visa approval receipt date. If the receipt date is not entered in the task update decision field, Priority date will remain empty. Kindly include the receipt date if it has not been added yet.">info</mat-icon>
                                        </div> </th> 
                                        <td mat-cell *matCellDef="let element"> {{element?.priorityDate ? element?.priorityDate : '-'}} </td>
                                      </ng-container>

                                    <!-- Symbol Column -->
                                    <!-- <ng-container matColumnDef="finalAction">
                                      <th mat-header-cell *matHeaderCellDef> <b>Final Action Date</b> </th>
                                      <td mat-cell *matCellDef="let element"> {{element.finalAction}} </td>
                                    </ng-container> -->
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"
                                        (click)="selection.toggle(row)">
                                    </tr>
                                </table>

                                <div *ngIf="totalRecordCount > 0">
                                    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" [pageSize]="pageSize"
                                        [length]="totalRecordCount" (page)="paginationEvent($event)">
                                    </mat-paginator>
                                </div>

                                <div class="text-center mt-5" *ngIf="this.totalRecordCount <= 0">
                                    <b>No Records!</b>
                                </div>
                            </div>
                           </div>
                        </div>
                    </ng-container>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>

<p-dialog header="Send Bulk Message" [(visible)]="showMessageDialog" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" styleClass="sendBulkMessage">
        <form [formGroup]="notifyBeneficiaryForm">
            <div class="container p-0 notify-beneficiary">
                <div class="form-row">
                    <div class="col-md-12"  [ngClass]="{'notAllowedCls': isMultiSelect == true}">
                        <hr>
                        <textarea [ngClass]="{'disabedCls': isMultiSelect == true}" [disabled]="true" class="w-100" formControlName="sendMessage" pInputTextarea [rows]="6"  name="question" placeholder="Enter Your Message..."></textarea>
                        <span class="invalid-message"
                            *ngIf="(notifyBeneficiaryForm.get('sendMessage').touched && notifyBeneficiaryForm.get('sendMessage').errors?.required)">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                        </span> 
                        <hr>
                    </div> 
                </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <p-button (click)="showMessageDialog = false" label="CANCEL"></p-button>
            <p-button (click)="notifyBeneficiaryFormSub()" label="SEND" [disabled]="notifyBeneficiaryForm.invalid"></p-button>
        </ng-template>
</p-dialog>

<p-confirmDialog key="confirm-drop-database" header="Confirm" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmDropDatabaseDialogVisible"></p-confirmDialog>
