<div style="margin:10px; ">
    <!-- <div class="pull-right" *ngIf="!isClientGenerateInvoice"> -->
    <div class="pull-right" *ngIf="invoiceType">
        <button type="button" (click)="showPreview()" class="preview">
            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i> Preview </button>
    </div>
    <form [formGroup]="mform">
        <div>
            Select the mode you wish to generate the invoice with
        </div>
        <div class="row mt-2">
            <p-radioButton class='mr-5' label="Generate Invoice" value="yes" formControlName="name" id="invType"
                (click)="changeType()"></p-radioButton>
            <p-radioButton label="Upload your invoice copy" value="no" formControlName="name" (click)="changeType()">
            </p-radioButton>
        </div>
    </form>

    <div style="margin-top: 180px;" *ngIf="isClientGenerateInvoice">
        <form [formGroup]="invoiceForm" *ngIf="invoiceType">
            <div class="row">
            </div>
            <div style=" border: 1px solid black; height:1400px;" class="p-2 mt-4">
                <div class="row mt-3 mb-3" style="visibility:hidden">
                    <div class="col-3 invoice-form-container" style="padding-bottom: 10px">
                        <h6 class="top-row">Firm Details</h6>
                        <label class="item-label">Add business Logo</label>
                        <div class="d-flex">
                            <p-fileUpload [disabled]="disableFields" #createUploader name="demo[]" mode="basic"
                                chooseLabel="Browse" [auto]="true" customUpload="true" accept="image/*"
                                invalidFileTypeMessageSummary="" (uploadHandler)="Upload($event, createUploader)"
                                invalidFileTypeMessageDetail="Unaccepted format. Please upload a 'png' format file"
                                showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                            </p-fileUpload>
                            <span class="ml-3 pt-2"> OR</span>
                        </div>

                        <div class="col-10">
                            <div style="width:250px;height:auto !important">
                                <img *ngIf="companyLogoImage" [src]="companyLogoImage" />
                            </div>
                        </div>
                        <div>
                            <label class="label-cls required test mt-2 item-label" for="first-name">Business/Firm name
                            </label>
                            <input id="first-name" type="text" formControlName="busFirmName">
                            <small
                                *ngIf="invoiceForm.get('busFirmName').touched && invoiceForm.get('busFirmName').errors"
                                class="p-error">
                                <span *ngIf="invoiceForm.get('busFirmName').errors['required']">Required</span>
                            </small>
                        </div>

                    </div>
                    <div class="col-3 ml-2 invoice-form-container pb-3">
                        <h6 class="mt-2 top-row">Title</h6>
                        <label class="label-cls required test mt-2 item-label" for="first-name">Invoice Title </label>
                        <input id="first-name" type="text" formControlName="invoiceTitle">
                        <small *ngIf="invoiceForm.get('invoiceTitle').touched && invoiceForm.get('invoiceTitle').errors"
                            class="p-error">
                            <span *ngIf="invoiceForm.get('invoiceTitle').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-5 ml-2 invoice-form-container pb-3">
                        <h6 class="mt-2 top-row">Date and invoice number</h6>
                        <div class="row">
                            <div class="col-6 col-lg-6 nopadding">
                                <label class="label-cls required item-label"> Invoice Date</label>
                                <p-calendar #invDate formControlName="invoiceDate" [showIcon]="true"
                                    [monthNavigator]="true" yearRange="2000:2099" [yearNavigator]="true"
                                    placeholder="MM/DD/YYYY" [minDate]="minimumDate"></p-calendar>
                                <small
                                    *ngIf="invoiceForm.get('invoiceDate').touched && invoiceForm.get('invoiceDate').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('invoiceDate').errors['required']">Required</span>
                                </small>
                            </div>
                            <div class="col-6 col-lg-5 ">
                                <label for='startDate' class="label-cls required item-label"> Invoice Number</label>
                                <input type="text" formControlName="invoiceNumber" />
                                <small
                                    *ngIf="invoiceForm.get('invoiceNumber').touched && invoiceForm.get('invoiceNumber').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('invoiceNumber').errors['required']">Required</span>
                                </small>
                            </div>
                        </div>
                        <div class="row  mt-3">
                            <div class="col-6 col-lg-6 nopadding">
                                <label class="label-cls required item-label"> Due Date</label>
                                <p-calendar formControlName="invoiceDueDate" [showIcon]="true" [monthNavigator]="true"
                                    [yearNavigator]="true" placeholder="MM/DD/YYYY" yearRange="2000:2099"
                                    [minDate]="invDate.value || minimumDate">
                                </p-calendar>
                                <small
                                    *ngIf="invoiceForm.get('invoiceDueDate').touched && invoiceForm.get('invoiceDueDate').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('invoiceDueDate').errors['required']">Required</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 " style="position:relative; top:-300px">
                    <div class="col-md-3 col-lg-3">
                        <div class="p-3" *ngIf="companyLogoImage">
                            <img [src]="companyLogoImage " alt="profile picture">
                        </div>
                    </div>
                    <div class="col-5 col-lg-5 mt-2 text">
                        <span>Invoice</span>
                    </div>

                    <div class="col-lg-4 mt-2 ">
                        <div class="pull-right">
                            <div class="mt-2">
                                <div class=" nopadding d-flex">
                                    <div class="col-lg-4">
                                        <label class="label-cls required item-label"> Invoice Date</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <p-calendar #invDate formControlName="invoiceDate" [showIcon]="true"
                                            [monthNavigator]="true" yearRange="2000:2099" [yearNavigator]="true"
                                            placeholder="MM/DD/YYYY" [minDate]="minimumDate"></p-calendar>
                                        <small
                                            *ngIf="invoiceForm.get('invoiceDate').touched && invoiceForm.get('invoiceDate').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('invoiceDate').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>

                            </div>
                            <div class="mt-2">
                                <div class="nopadding d-flex">
                                    <div class="col-lg-4">
                                        <label class="label-cls required item-label"> Due Date</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <p-calendar formControlName="invoiceDueDate" [showIcon]="true"
                                            [monthNavigator]="true" [yearNavigator]="true" placeholder="MM/DD/YYYY"
                                            yearRange="2000:2099" [minDate]="invDate.value || minimumDate">
                                        </p-calendar>
                                        <small
                                            *ngIf="invoiceForm.get('invoiceDueDate').touched && invoiceForm.get('invoiceDueDate').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('invoiceDueDate').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 d-flex">
                                <div class="col-lg-4">
                                    <strong>Invoice No</strong>:
                                </div>
                                <div class="col-lg-4">
                                    <span>{{this.invoiceForm.get('invoiceNumber').value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 " style=" background-color: #EDF4FB; position:relative; top:-300px">
                    <div class=" col-6 mt-2">
                        <strong>Bill By</strong>
                    </div>
                    <div class="ml-2col-6 mt-2 ml-2">
                        <strong>Bill To</strong>
                    </div>
                </div>
                <div class="divider" style="position:relative; top:-300px">
                </div>
                <div class="row" style=" background-color: #EDF4FB;position:relative; top:-300px">
                    <div class="col-6">
                        <strong>{{invoiceForm.get('busFirmName').value}}</strong>
                        <address *ngIf="attorneyFirms[0]">
                            {{ this.attorneyFirms[0].address[0].addressLine1}}<br />
                            {{ this.attorneyFirms[0].address[0].city}}
                            {{ this.attorneyFirms[0].address[0].stateProvinceCode}}
                            {{ this.attorneyFirms[0].address[0].postCode}}<br />
                            Tel : {{ this.attorneyFirms[0].phoneContacts[0].phoneNo}}<br />
                            Email :{{this.attorneyFirms[0].emailContacts[0].email}}<br />
                        </address>
                    </div>
                    <div class="col-6">
                        <strong> {{previewClient.companyName}}</strong>
                        <address *ngIf="previewClient">
                            {{previewClient.address}}<br>
                            {{previewClient.city}}
                            {{previewClient.state}}
                            zipcode :{{previewClient.zipcode}}<br>
                            Tel: {{previewClient.phoneNumber}} <br>
                            Email: {{previewClient.email}} <br>
                        </address>
                    </div>
                </div>
                <div style='visibility: hidden;' class="row  invoice-form-container pb-3">
                    <div class="row">
                        <h4 class="mt-2 ml-2">Contact Details</h4>
                    </div>
                    <div style="width: 100% !important" class="d-flex">
                        <div class="row mt-2 ml-3" style="width: 49% !important">
                            <div class="col-12 col-lg-12 mr-2 pb-2 invoice-form-container">
                                <div class="row p-2">
                                    <h6>Billed By</h6>
                                </div>
                                <div class="row">
                                    <div class="col-12 ">
                                        <p class="label-cls required mb-2">Business/Firm name</p>
                                        <input type="text" formControlName="billedByCompany"
                                            [readonly]="this.isAttorneyFirmEditable">
                                    </div>
                                </div>
                                <div class="row  ">
                                    <div class="col-6 ">
                                        <label for="" class="item-label">Your email</label>
                                        <input type="text" formControlName="billedByEmail"
                                            [readonly]="this.isAttorneyFirmEditable">
                                        <small
                                            *ngIf="invoiceForm.get('billedByEmail').touched && invoiceForm.get('billedByEmail').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('billedByEmail').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <label for="" class="item-label">Phone Number</label>
                                        <div class="d-flex"> <input type="text" formControlName="billedByPhoneCode"
                                                class="col-md-3" [readonly]="this.isAttorneyFirmEditable">
                                            <input type="text" formControlName="billedByPhoneNumber" class=" "
                                                [readonly]="this.isAttorneyFirmEditable">
                                        </div>
                                        <small
                                            *ngIf="invoiceForm.get('billedByPhoneNumber').touched && invoiceForm.get('billedByPhoneNumber').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('billedByPhoneNumber').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-12">
                                        <label for="" class="item-label">Address</label>
                                        <input type="text" formControlName="billedByAddress"
                                            [readonly]="this.isAttorneyFirmEditable">
                                        <small
                                            *ngIf="invoiceForm.get('billedByAddress').touched && invoiceForm.get('billedByAddress').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('billedByAddress').errors['required']">Required</span>
                                        </small>
                                    </div>

                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Country</p>
                                        <input type="text" formControlName="billedByCountry"
                                            [readonly]="this.isAttorneyFirmEditable">
                                        <small
                                            *ngIf="invoiceForm.get('billedByCountry').touched && invoiceForm.get('billedByCountry').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('billedByCountry').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">State</p>
                                        <input type="text" formControlName="billedByState"
                                            [readonly]="this.isAttorneyFirmEditable">
                                        <small
                                            *ngIf="invoiceForm.get('billedByState').touched && invoiceForm.get('billedByState').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('billedByState').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2 ">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">City</p>
                                        <input type="text" formControlName="billedByCity"
                                            [readonly]="this.isAttorneyFirmEditable">
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Zip/Postal code</p>
                                        <input type="text" formControlName="billedByPostCode"
                                            [readonly]="this.isAttorneyFirmEditable">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-2 ml-3 mr-3" style="width: 49% !important">
                            <div class="col-12 col-lg-12 invoice-form-container">
                                <div class="row p-2">
                                    <h6>Billed To</h6>
                                </div>
                                <div class="row">
                                    <div class="col-12 ">
                                        <p class="label-cls required mb-2">Clients Business/Company name</p>
                                        <p-dropdown class="w-100" placeholder='select client company'
                                            formControlName='selectedClient' [options]="clients"
                                            optionLabel="companyName" (onChange)="populateData($event)">
                                        </p-dropdown>
                                        <small
                                            *ngIf="invoiceForm.get('selectedClient').touched && invoiceForm.get('selectedClient').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('selectedClient').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!others">

                                    <div class="col-6">
                                        <label for="" class="item-label">Your email</label>
                                        <input type="text" formControlName="email" [attr.required]="!others || null"
                                            [readonly]="this.isEditable">
                                        <small
                                            *ngIf="invoiceForm.get('email').touched && invoiceForm.get('email').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get('email').errors['required']">Required</span>
                                        </small>

                                    </div>
                                    <div class="col-6">
                                        <label for="" class="item-label">Phone Number</label>
                                        <div class="d-flex">
                                            <input type="text" formControlName="countryPhoneCode" class="col-3 col-md-3"
                                                [attr.required]="!others || null" [readonly]="this.isEditable">

                                            <input type="text" formControlName="phoneNumber" class=""
                                                [attr.required]="!others || null" [readonly]="this.isEditable">
                                        </div>
                                        <small
                                            *ngIf="invoiceForm.get('phoneNumber').touched && invoiceForm.get('phoneNumber').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('phoneNumber').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!others">
                                    <div class="col-12">
                                        <label for="" class="item-label">Address</label>
                                        <input type="text" formControlName="address" [readonly]="this.isEditable">
                                    </div>
                                    <small
                                        *ngIf="invoiceForm.get('address').touched && invoiceForm.get('address').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('address').errors['required']">Required</span>
                                    </small>

                                </div>
                                <div class="row mt-3" *ngIf="!others">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Country</p>
                                        <input type="text" formControlName="country" [readonly]="this.isEditable">
                                        <small
                                            *ngIf="invoiceForm.get('country').touched && invoiceForm.get('country').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get('country').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">State</p>
                                        <input type="text" formControlName="state" [readonly]="this.isEditable">
                                        <small
                                            *ngIf="invoiceForm.get('state').touched && invoiceForm.get('state').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get('state').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2" *ngIf="!others">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">City</p>
                                        <input type="text" formControlName="city" [readonly]="this.isEditable">
                                        <small *ngIf="invoiceForm.get('city').touched && invoiceForm.get('city').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get('city').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Zip/Postal code</p>
                                        <input type="text" formControlName="zipcode" [readonly]="this.isEditable">
                                        <small
                                            *ngIf="invoiceForm.get('zipcode').touched && invoiceForm.get('zipcode').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get('zipcode').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>

                                <div class="col-12 " *ngIf="others">
                                    <p class="label-cls required mb-2 mt-2">New Clients Business/Company name
                                        <p-checkbox class="pull-right" name="isSameAddress"
                                            label="save client for future use" formControlName="saveClient" #saveClient
                                            (click)="displayValue($event,saveClient)
                                        " [readonly]="this.isOtherFieldsEditable">
                                        </p-checkbox>
                                    </p>
                                    <input type="text" formControlName="newClient" [attr.required]="others || null">
                                    <small
                                        *ngIf="invoiceForm.get('newClient').touched && invoiceForm.get('newClient').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('newClient').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="row" *ngIf="others">
                                    <div class="col-6">
                                        <label for="" class="item-label">Your email</label>
                                        <input type="text" formControlName="otherEmail"
                                            [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                                        <small
                                            *ngIf="invoiceForm.get('otherEmail').touched && invoiceForm.get('otherEmail').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherEmail').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <label for="" class="item-label">Phone Number</label>
                                        <div class="d-flex">
                                            <div>
                                                <p-dropdown [options]="countries" formControlName="countryPhCode"
                                                    placeholder='Select Country Code' optionLabel="countryPhoneCode"
                                                    optionValue="countryCode" (onChange)="showSelectedCountry($event)">
                                                    [attr.required]="others || null"
                                                    [readonly]="this.isOtherFieldsEditable"
                                                </p-dropdown>
                                            </div>
                                            <input type="text" formControlName="otherPhoneNumber"
                                                [readonly]="this.isOtherFieldsEditable"
                                                [attr.required]="others || null">
                                        </div>
                                        <small
                                            *ngIf="invoiceForm.get('otherPhoneNumber').touched && invoiceForm.get('otherPhoneNumber').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherPhoneNumber').errors['required']">Required</span>

                                        </small>
                                        <small
                                            *ngIf="invoiceForm.get('otherPhoneNumber').touched && invoiceForm.get('otherPhoneNumber').errors"
                                            class="p-error">
                                            <span *ngIf="invoiceForm.get['otherPhoneNumber'].errors?.pattern">Please
                                                enter only numbers</span>

                                        </small>

                                    </div>
                                </div>
                                <div class="row" *ngIf="others">
                                    <div class="col-12">
                                        <label for="" class="item-label">Address</label>
                                        <input type="text" formControlName="otherAddress"
                                            [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                                        <small
                                            *ngIf="invoiceForm.get('otherAddress').touched && invoiceForm.get('otherAddress').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherAddress').errors['required']">Required</span>
                                        </small>
                                    </div>

                                </div>
                                <div class="row mt-3" *ngIf="others">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Country</p>
                                        <p-dropdown class="w-100" [options]="countries" formControlName="otherCountry"
                                            placeholder='Select Country' optionLabel="countryName"
                                            (onChange)="showSelectedCountry($event)"> [attr.required]="others || null"
                                            [readonly]="this.isOtherFieldsEditable"
                                        </p-dropdown>
                                        <small
                                            *ngIf="invoiceForm.get('otherCountry').touched && invoiceForm.get('otherCountry').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherCountry').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2 item-label">State</p>
                                        <p-dropdown class="w-100" [options]="otherStates" formControlName="otherState"
                                            placeholder='Select State' optionLabel="stateProvinceName" [attr.required]="others || null"
                                            [readonly]="this.isOtherFieldsEditable">
                                        </p-dropdown>
                                        <small
                                            *ngIf="invoiceForm.get('otherState').touched && invoiceForm.get('otherState').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherState').errors['required']">Required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2" *ngIf="others">
                                    <div class="col-6">
                                        <p class="label-cls required mb-2 item-label">City</p>
                                        <input type="text" formControlName="otherCity" [attr.required]="others || null"
                                            [readonly]="this.isOtherFieldsEditable" placeholder='Select City' />
                                        <small
                                            *ngIf="invoiceForm.get('otherCity').touched && invoiceForm.get('otherCity').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherCity').errors['required']">Required</span>
                                        </small>
                                    </div>
                                    <div class="col-6">
                                        <p class="label-cls required mb-2">Zip/Postal code</p>
                                        <input type="text" formControlName="otherZipCode"
                                            [attr.required]="others || null" [readonly]="this.isOtherFieldsEditable"
                                            placeholder='Select Zipcode' />
                                        <small
                                            *ngIf="invoiceForm.get('otherZipCode').touched && invoiceForm.get('otherZipCode').errors"
                                            class="p-error">
                                            <span
                                                *ngIf="invoiceForm.get('otherZipCode').errors['required']">Required</span>
                                            <span *ngIf="invoiceForm.get['otherZipCode'].errors?.pattern">Please
                                                enter only numbers</span>

                                        </small>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row   mt-5 mb-5 d-block" style="position:relative; top:-800px">
                    <div class="row mt-3 ml-2 d-block">
                        <div class="billing-class">
                            <div class="row billing-item2 p-2 " style="width:100% !important">
                                <div class="col-1 col-lg-1 bill-item">
                                    Number
                                </div>
                                <div class="col-2 col-lg-2 bill-item">
                                    Billing Item
                                </div>
                                <div class="col-3 col-lg-2 bill-item">
                                    Chargeable amount
                                </div>
                                <div class="col-2 col-lg-2 bill-item">
                                    Discount
                                </div>
                                <div class="col-2  col-lg-2 bill-item">
                                    Tax
                                </div>
                                <div class="col-2 col-lg-2 bill-item">
                                    Total
                                </div>
                                <div class="col-1 col-lg-2 bill-item">

                                </div>
                            </div>
                            <div formArrayName="billingItemDTOs" class='pt-4 d-block' style="position:relative">
                                <div *ngFor="let item of invoiceForm.get('billingItemDTOs')?.controls; let i=index;">
                                    <div>
                                        <div [formGroupName]="i" class="mb-3">
                                            <div class="row nopadding">
                                                <div class="col-1 py-1 text-center">
                                                    <p>{{i+1}}</p>
                                                </div>
                                                <div class="col-2">
                                                    <input type="text" formControlName="desc" />
                                                    <small
                                                        *ngIf="item.controls['desc'].touched && item.controls['desc'].errors"
                                                        class="p-error">
                                                        <span
                                                            *ngIf="item.controls['desc'].errors['required']">Required</span>
                                                        <span *ngIf="item.controls['desc'].errors?.pattern">Please
                                                            enter alphanumerics only</span>
                                                    </small>
                                                </div>
                                                <div class="col-2">
                                                    <span class="p-input-icon-left">
                                                        <i class='icon-style'>
                                                            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                                class="img-style" />
                                                        </i>
                                                        <input type="text" pInputText formControlName="chargableAmount"
                                                            placeholder="Enter Amount">
                                                    </span>
                                                    <small
                                                        *ngIf="item.controls['chargableAmount'].touched && item.controls['chargableAmount'].errors"
                                                        class="p-error">
                                                        <span
                                                            *ngIf="item.controls['chargableAmount'].errors['required']">Required</span>
                                                        <span
                                                            *ngIf="item.controls['chargableAmount'].errors?.pattern">Please
                                                            enter only numbers</span>
                                                    </small>
                                                </div>
                                                <div class="col-2 d-flex">
                                                    <span class="p-input-icon-left">
                                                        <i class='icon-style'>
                                                            <img src="../../../../assets/images/invoice/discount-icn.svg"
                                                                class="img-style" />
                                                        </i>
                                                        <input type="text" pInputText formControlName="discount"
                                                            placeholder="Discount">
                                                    </span>
                                                    <small
                                                        *ngIf="item.controls['discount'].touched && item.controls['discount'].errors"
                                                        class="p-error">
                                                        <span
                                                            *ngIf="item.controls['discount'].errors['required']">Required</span>
                                                        <span *ngIf="item.controls['discount'].errors?.pattern">Please
                                                            enter only numbers</span>
                                                    </small>
                                                </div>
                                                <div class="col-2">
                                                    <span class="p-input-icon-left">
                                                        <i class='icon-style'>
                                                            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                                class="img-style" />
                                                        </i>
                                                        <input type="text" pInputText formControlName="tax"
                                                            placeholder="Enter Tax">
                                                    </span>
                                                    <small
                                                        *ngIf="item.controls['tax'].touched && item.controls['tax'].errors"
                                                        class="p-error">
                                                        <span
                                                            *ngIf="item.controls['tax'].errors['required']">Required</span>
                                                        <span *ngIf="item.controls['tax'].errors?.pattern">Please
                                                            enter only numbers</span>
                                                    </small>
                                                </div>
                                                <div class="col-2">
                                                    <span class="p-input-icon-left">
                                                        <i class='icon-style'>
                                                            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                                class="img-style" />
                                                        </i>
                                                        <input type="text" pInputText formControlName="totalAmount"
                                                            (focus)="showTotal(item, i)" placeholder="Total Amount">
                                                    </span>
                                                    <small
                                                        *ngIf="item.controls['totalAmount'].touched && item.controls['totalAmount'].errors"
                                                        class="p-error">
                                                        <span
                                                            *ngIf="item.controls['totalAmount'].errors['required']">Required</span>
                                                        <span
                                                            *ngIf="item.controls['totalAmount'].errors?.pattern">Please
                                                            enter only numbers</span>
                                                    </small>
                                                </div>
                                                <div *ngIf="i!=0" class="col-1">
                                                    <div class="">
                                                        <button mat-stroked-button class="remove-billing-item"
                                                            (click)="removeBillingItem(i)">
                                                            <mat-icon class="remove_icon">
                                                                remove_circle_outline
                                                            </mat-icon>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-block mt-3">
                                    <div class="col-4 pull-right d-block mt-3">
                                        <div class="d-flex">
                                            <div class="col-6  pr-3 mr-2">
                                                <p class="total">Total</p>
                                            </div>
                                            <div class="total-amount col-6 text-center">
                                                <p> {{grandTotal | currency}}</p>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="col-6  pr-3 mr-2">
                                                <p class="total">
                                                    <p-checkbox class="show-words" name="isSameAddress" binary="true"
                                                        #checkbox2 label="Show total in words"
                                                        formControlName="grandTotal">
                                                    </p-checkbox>
                                                </p>
                                            </div>
                                            <div *ngIf="checkbox2.checked">
                                                <strong class='text-capitalize'> {{ grandTotal | numberToWords }} dollars only</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div>
                                        <button type="button" [disabled]="billingItemDTOs.length == 10"
                                            (click)="addBillingItemDTOs()" class="new-row"> +
                                            Add
                                            New Row</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row  mt-20 " style="clear:both; position:relative; top:-800px">

                    <div class="col-6 terms-conditions">
                        <div class="row">
                            <h5 class=" ml-2 terms">Terms and conditions</h5>
                            <p-divider></p-divider>
                        </div>

                        <div>
                            <textarea formControlName="termsAndConditions" class="w-100"></textarea>
                            <small
                                *ngIf="invoiceForm.get('termsAndConditions').touched && invoiceForm.get('termsAndConditions').errors"
                                class="p-error">
                                <span *ngIf="invoiceForm.get('termsAndConditions').errors['required']">Required</span>
                            </small>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row mt-3">
                            <div class="col-3 nopadding recurrence">
                                <p class="item-label"> Invoice Preparer</p>
                                <p-dropdown class='w-100' [options]="employees" formControlName="signatoryName"
                                    optionLabel="firstName" placeholder='Please Choose Invoice Preparer'
                                    (onChange)="showSignature($event)">
                                </p-dropdown>
                                <small
                                    *ngIf="invoiceForm.get('signatoryName').touched && invoiceForm.get('signatoryName').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('signatoryName').errors['required']">Required</span>
                                </small>

                            </div>
                            <div class="col-3 ml-3" *ngIf="otherSignatory">
                                <!-- <label>Signatory Name</label> -->
                                <label>Invoice Preparer Name</label>
                                <input formControlName="signatoryName1" (blur)="getSignatoryName()" />
                            </div>

                            <div class="col-3 ml-3">
                                <div class=" p-0">
                                    <label class="item-label">Upload signature</label>
                                    <p-fileUpload #signatureUploader [disabled]="disableFields" name="demo[]"
                                        mode="basic" chooseLabel="upload" [auto]="true" customUpload="true"
                                        accept="image/*" invalidFileTypeMessageSummary=""
                                        (uploadHandler)="UploadSignature($event, signatureUploader)"
                                        invalidFileTypeMessageDetail="Unaccepted format. Please upload a 'png' format file"
                                        showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                                    </p-fileUpload>
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="col-10">
                                    <div style="width:250px;height:auto !important">
                                        <img *ngIf="signatureImage" [src]="signatureImage" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row mt-3 ">
                <div class="col-5 col-lg-5 "></div>
                <div class="col-6 col-lg-6 ">
                    <!-- <button type="button" class="btn btn-primary item-label btn-txt pull-right"
                        [disabled]="(!invoiceForm.valid || !companyLogoImage || !signatureImage)  || !(createdInvoiceId == null) "
                        (click)="saveClientInvoice()">Save
                    </button> -->
                    <!-- <button type="button" class="btn btn-primary item-label btn-txt pull-right"
                        [disabled]="(!invoiceForm.valid ||  !signatureImage)  || !(createdInvoiceId == null) "
                        (click)="saveClientInvoice()">Save
                    </button> -->
                    <button type="button" class="btn btn-primary item-label btn-txt pull-right"
                        [disabled]="(!invoiceForm.valid)  || !(createdInvoiceId == null) "
                        (click)="saveClientInvoice()">Save
                    </button>
                    <button type="button" class="btn btn-light mr-2 pull-right" (click)="resetFields()"
                        *ngIf='!editInvoiceItem'>Cancel</button>
                    <button type="button" class="btn btn-light mr-2 pull-right" *ngIf='editInvoiceItem'
                        (click)="backToMain()">Cancel</button>

                </div>

            </div>
        </form>
    </div>
    <div *ngIf="invoiceType && !isClientGenerateInvoice">
        <form [formGroup]="invoiceForm">
            <div class="row">
            </div>
            <div class="row mt-3">
                <h3>Invoice Header</h3>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-3 invoice-form-container" style="padding-bottom: 10px">
                    <h6 class="top-row">Firm Details</h6>
                    <label class="item-label">Add business Logo</label>
                    <div class="d-flex">
                        <p-fileUpload [disabled]="disableFields" #createUploader name="demo[]" mode="basic"
                            chooseLabel="Browse" [auto]="true" customUpload="true" accept="image/*"
                            invalidFileTypeMessageSummary="" (uploadHandler)="Upload($event, createUploader)"
                            invalidFileTypeMessageDetail="Unaccepted format. Please upload a 'png' format file"
                            showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                        </p-fileUpload>
                        <span class="ml-3 pt-2"> OR</span>
                    </div>

                    <div class="col-12 py-2 px-0">
                        <div style="width:auto;height:auto !important">
                            <img *ngIf="companyLogoImage" [src]="companyLogoImage" />
                        </div>
                    </div>
                    <div>
                        <label class="label-cls required test mt-2 item-label" for="first-name">Business/Firm name
                        </label>
                        <input id="first-name" type="text" formControlName="busFirmName">
                        <small *ngIf="invoiceForm.get('busFirmName').touched && invoiceForm.get('busFirmName').errors"
                            class="p-error">
                            <span *ngIf="invoiceForm.get('busFirmName').errors['required']">Required</span>
                        </small>
                    </div>

                </div>
                <div class="col-3 ml-2 invoice-form-container pb-3">
                    <h6 class="mt-2 top-row">Title</h6>
                    <label class="label-cls required test mt-2 item-label" for="first-name">Invoice Title </label>
                    <input id="first-name" type="text" formControlName="invoiceTitle">
                    <small *ngIf="invoiceForm.get('invoiceTitle').touched && invoiceForm.get('invoiceTitle').errors"
                        class="p-error">
                        <span *ngIf="invoiceForm.get('invoiceTitle').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-5 ml-2 invoice-form-container pb-3">
                    <h6 class="mt-2 top-row">Date and invoice number</h6>
                    <div class="row">
                        <div class="col-6 col-lg-6 nopadding">
                            <label class="label-cls required item-label"> Invoice Date</label>
                            <p-calendar #invDate formControlName="invoiceDate" [showIcon]="true" [monthNavigator]="true"
                                yearRange="2000:2099" [yearNavigator]="true" placeholder="MM/DD/YYYY"
                                [minDate]="minimumDate"></p-calendar>
                            <small
                                *ngIf="invoiceForm.get('invoiceDate').touched && invoiceForm.get('invoiceDate').errors"
                                class="p-error">
                                <span *ngIf="invoiceForm.get('invoiceDate').errors['required']">Required</span>
                            </small>
                        </div>
                        <div class="col-6 col-lg-5 ">
                            <label for='startDate' class="label-cls required item-label"> Invoice Number</label>
                            <input type="text" formControlName="invoiceNumber" />
                            <small
                                *ngIf="invoiceForm.get('invoiceNumber').touched && invoiceForm.get('invoiceNumber').errors"
                                class="p-error">
                                <span *ngIf="invoiceForm.get('invoiceNumber').errors['required']">Required</span>
                            </small>
                        </div>
                    </div>
                    <div class="row  mt-3">
                        <div class="col-6 col-lg-6 nopadding">
                            <label class="label-cls required item-label"> Due Date</label>
                            <p-calendar formControlName="invoiceDueDate" [showIcon]="true" [monthNavigator]="true"
                                [yearNavigator]="true" placeholder="MM/DD/YYYY" yearRange="2000:2099"
                                [minDate]="invDate.value || minimumDate">
                            </p-calendar>
                            <small
                                *ngIf="invoiceForm.get('invoiceDueDate').touched && invoiceForm.get('invoiceDueDate').errors"
                                class="p-error">
                                <span *ngIf="invoiceForm.get('invoiceDueDate').errors['required']">Required</span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row  invoice-form-container pb-3">
                <div class="row">
                    <h4 class="mt-2 ml-2">Contact Details</h4>
                </div>
                <div style="width: 100% !important" class="d-flex">
                    <div class="row mt-2 ml-3" style="width: 49% !important">
                        <div class="col-12 col-lg-12 mr-2 pb-2 invoice-form-container">
                            <div class="row p-2">
                                <h6>Billed By</h6>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                    <p class="label-cls required mb-2">Business/Firm name</p>
                                    <input type="text" formControlName="billedByCompany"
                                        [readonly]="this.isAttorneyFirmEditable">
                                </div>
                            </div>
                            <div class="row  ">
                                <div class="col-6 ">
                                    <label for="" class="item-label label-cls required">Your email</label>
                                    <input type="text" formControlName="billedByEmail"
                                        [readonly]="this.isAttorneyFirmEditable">
                                    <small
                                        *ngIf="invoiceForm.get('billedByEmail').touched && invoiceForm.get('billedByEmail').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('billedByEmail').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <label for="" class="item-label label-cls required">Phone Number</label>
                                    <div class="d-flex"> <input type="text" formControlName="billedByPhoneCode"
                                            class="col-md-3" [readonly]="this.isAttorneyFirmEditable">
                                        <input type="text" formControlName="billedByPhoneNumber" class=" "
                                            [readonly]="this.isAttorneyFirmEditable">
                                    </div>
                                    <small
                                        *ngIf="invoiceForm.get('billedByPhoneNumber').touched && invoiceForm.get('billedByPhoneNumber').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('billedByPhoneNumber').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-12">
                                    <label for="" class="item-label label-cls required">Address</label>
                                    <input type="text" formControlName="billedByAddress"
                                        [readonly]="this.isAttorneyFirmEditable">
                                    <small
                                        *ngIf="invoiceForm.get('billedByAddress').touched && invoiceForm.get('billedByAddress').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('billedByAddress').errors['required']">Required</span>
                                    </small>
                                </div>

                            </div>
                            <div class="row mt-3 ">
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Country</p>
                                    <input type="text" formControlName="billedByCountry"
                                        [readonly]="this.isAttorneyFirmEditable">
                                    <small
                                        *ngIf="invoiceForm.get('billedByCountry').touched && invoiceForm.get('billedByCountry').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('billedByCountry').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2">State</p>
                                    <input type="text" formControlName="billedByState"
                                        [readonly]="this.isAttorneyFirmEditable">
                                    <small
                                        *ngIf="invoiceForm.get('billedByState').touched && invoiceForm.get('billedByState').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('billedByState').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2 ">
                                <div class="col-6">
                                    <p class="label-cls required mb-2">City</p>
                                    <input type="text" formControlName="billedByCity"
                                        [readonly]="this.isAttorneyFirmEditable">
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Zip/Postal code</p>
                                    <input type="text" formControlName="billedByPostCode"
                                        [readonly]="this.isAttorneyFirmEditable">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2 ml-3 mr-3" style="width: 49% !important">
                        <div class="col-12 col-lg-12 invoice-form-container">
                            <div class="row p-2">
                                <h6>Billed To</h6>
                            </div>

                            <form [formGroup]="billToform">
                                <div class="col-md-12 mt-2 mb-3">
                                    <p-radioButton class='mr-5' label="Company" value="Company" formControlName="billType" (onClick)="updateValidations($event)"></p-radioButton>
                                    <p-radioButton label="Individual" value="Individual" formControlName="billType" (onClick)="updateValidations($event)"></p-radioButton>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-12 ">
                                    <p class="label-cls required mb-2">Individual/Company name</p>
                                    <p-dropdown *ngIf="billToform.get('billType').value == 'Company'" class="w-100" placeholder='select client company'
                                        formControlName='selectedClient' [options]="clients" optionLabel="companyName"
                                        (onChange)="populateData($event)" [attr.required]="!others || null" [filter]="true" filterBy="companyName">
                                    </p-dropdown>

                                    <p-dropdown *ngIf="billToform.get('billType').value == 'Individual'" class="w-100" placeholder='select individual name'
                                        formControlName='selectedClient' [options]="individualsList" optionLabel="individualName"
                                        (onChange)="populateData($event)" [attr.required]="!others || null" [filter]="true" filterBy="individualName">
                                    </p-dropdown>

                                    <small
                                        *ngIf="invoiceForm.get('selectedClient').touched && invoiceForm.get('selectedClient').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('selectedClient').errors['required']">Required</span>
                                    </small>
                                </div>

                            </div>
                            <div class="row" *ngIf="!others">

                                <div class="col-6">
                                    <label for="" class="item-label label-cls required">Your email</label>
                                    <input type="text" formControlName="email" [attr.required]="!others || null"
                                        [readonly]="this.isEditable">
                                    <small *ngIf="invoiceForm.get('email').touched && invoiceForm.get('email').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('email').errors['required']">Required</span>
                                    </small>

                                </div>
                                <div class="col-6">
                                    <label for="" class="item-label label-cls required">Phone Number</label>
                                    <div class="d-flex">
                                        <input type="text" formControlName="countryPhoneCode" class="col-3 col-md-3"
                                            [attr.required]="!others || null" [readonly]="this.isEditable">

                                        <input type="text" formControlName="phoneNumber" class=""
                                            [attr.required]="!others || null" [readonly]="this.isEditable">
                                    </div>
                                    <small
                                        *ngIf="invoiceForm.get('phoneNumber').touched && invoiceForm.get('phoneNumber').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('phoneNumber').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row" *ngIf="!others">
                                <div class="col-12">
                                    <label for="" class="item-label label-cls required">Address</label>
                                    <input type="text" formControlName="address" [readonly]="this.isEditable">
                                </div>
                                <small *ngIf="invoiceForm.get('address').touched && invoiceForm.get('address').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('address').errors['required']">Required</span>
                                </small>

                            </div>
                            <div class="row mt-3" *ngIf="!others">
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Country</p>
                                    <input type="text" formControlName="country" [readonly]="this.isEditable">

                                    <small
                                        *ngIf="invoiceForm.get('country').touched && invoiceForm.get('country').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('country').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2">State</p>
                                    <input type="text" formControlName="state" [readonly]="this.isEditable">

                                    <small *ngIf="invoiceForm.get('state').touched && invoiceForm.get('state').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('state').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2" *ngIf="!others">
                                <div class="col-6">
                                    <p class="label-cls required mb-2">City</p>

                                    <input type="text" formControlName="city" [readonly]="this.isEditable">

                                    <small *ngIf="invoiceForm.get('city').touched && invoiceForm.get('city').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('city').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Zip/Postal code</p>


                                    <input type="text" formControlName="zipcode" [readonly]="this.isEditable">

                                    <small
                                        *ngIf="invoiceForm.get('zipcode').touched && invoiceForm.get('zipcode').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('zipcode').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>

                            <div class="col-12 " *ngIf="others">
                                <p class="label-cls required mb-2 mt-2">New Clients Business/Company name <p-checkbox
                                        class="pull-right" name="isSameAddress" label="save client for future use"
                                        formControlName="saveClient" #saveClient (click)="displayValue($event,saveClient)
                                        " [readonly]="this.isOtherFieldsEditable">
                                    </p-checkbox>
                                </p>
                                <input type="text" formControlName="newClient" [attr.required]="others || null">
                                <small
                                    *ngIf="invoiceForm.get('newClient').touched && invoiceForm.get('newClient').errors"
                                    class="p-error">
                                    <span *ngIf="invoiceForm.get('newClient').errors['required']">Required</span>
                                </small>
                            </div>
                            <div class="row" *ngIf="others">
                                <div class="col-6">
                                    <label for="" class="item-label label-cls required">Your email</label>
                                    <input type="text" formControlName="otherEmail"
                                        [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                                    <small
                                        *ngIf="invoiceForm.get('otherEmail').touched && invoiceForm.get('otherEmail').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherEmail').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <label for="" class="item-label label-cls required">Phone Number</label>
                                    <div class="d-flex">
                                        <div>
                                            <p-dropdown [options]="countries" formControlName="countryPhCode"
                                                placeholder='Select Country Code' optionLabel="countryPhoneCode"
                                                optionValue="countryCode" (onChange)="showSelectedCountry($event)">
                                                [attr.required]="others || null"
                                                [readonly]="this.isOtherFieldsEditable"
                                            </p-dropdown>
                                        </div>
                                        <input type="text" formControlName="otherPhoneNumber"
                                            [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                                    </div>
                                    <small
                                        *ngIf="invoiceForm.get('otherPhoneNumber').touched && invoiceForm.get('otherPhoneNumber').errors"
                                        class="p-error">
                                        <span
                                            *ngIf="invoiceForm.get('otherPhoneNumber').errors['required']">Required</span>

                                    </small>
                                    <small
                                        *ngIf="invoiceForm.get('otherPhoneNumber').touched && invoiceForm.get('otherPhoneNumber').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get['otherPhoneNumber'].errors?.pattern">Please
                                            enter only numbers</span>

                                    </small>

                                </div>
                            </div>
                            <div class="row" *ngIf="others">
                                <div class="col-12">
                                    <label for="" class="item-label label-cls required">Address</label>
                                    <input type="text" formControlName="otherAddress"
                                        [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                                    <small
                                        *ngIf="invoiceForm.get('otherAddress').touched && invoiceForm.get('otherAddress').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherAddress').errors['required']">Required</span>
                                    </small>
                                </div>

                            </div>
                            <div class="row mt-3" *ngIf="others">
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Country</p>
                                    <p-dropdown class="w-100" [options]="countries" formControlName="otherCountry"
                                        placeholder='Select Country' optionLabel="countryName"
                                        (onChange)="showSelectedCountry($event)"> [attr.required]="others || null"
                                        [readonly]="this.isOtherFieldsEditable"
                                    </p-dropdown>
                                    <small
                                        *ngIf="invoiceForm.get('otherCountry').touched && invoiceForm.get('otherCountry').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherCountry').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2 item-label">State</p>
                                    <p-dropdown class="w-100" [options]="otherStates" formControlName="otherState"
                                        placeholder='Select State' optionLabel="stateProvinceName" [attr.required]="others || null"
                                        [readonly]="this.isOtherFieldsEditable">
                                    </p-dropdown>
                                    <small
                                        *ngIf="invoiceForm.get('otherState').touched && invoiceForm.get('otherState').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherState').errors['required']">Required</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2" *ngIf="others">
                                <div class="col-6">
                                    <p class="label-cls required mb-2 item-label">City</p>
                                    <input type="text" formControlName="otherCity" [attr.required]="others || null"
                                        [readonly]="this.isOtherFieldsEditable" placeholder='Select City' />
                                    <small
                                        *ngIf="invoiceForm.get('otherCity').touched && invoiceForm.get('otherCity').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherCity').errors['required']">Required</span>
                                    </small>
                                </div>
                                <div class="col-6">
                                    <p class="label-cls required mb-2">Zip/Postal code</p>
                                    <input type="text" formControlName="otherZipCode" [attr.required]="others || null"
                                        [readonly]="this.isOtherFieldsEditable" placeholder='Select Zipcode' />
                                    <small
                                        *ngIf="invoiceForm.get('otherZipCode').touched && invoiceForm.get('otherZipCode').errors"
                                        class="p-error">
                                        <span *ngIf="invoiceForm.get('otherZipCode').errors['required']">Required</span>
                                        <span *ngIf="invoiceForm.get['otherZipCode'].errors?.pattern">Please
                                            enter only numbers</span>

                                    </small>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>


            <div class="row  invoice-form-container mt-5 mb-5 d-block">
                <h4 class="ml-2">Invoice Details</h4>

                <div class="row mt-3 ml-2 d-block">
                    <div>
                        <div class="row billing-item p-2 " style="width:100% !important">
                            <div class="col-1 col-lg-1 bill-item">
                                Number
                            </div>
                            <div class="col-2 col-lg-2 bill-item">
                                Billing Item
                            </div>
                            <div class="col-3 col-lg-2 bill-item">
                                Chargeable amount
                            </div>
                            <div class="col-2 col-lg-2 bill-item">
                                Discount
                            </div>
                            <div class="col-2  col-lg-2 bill-item">
                                Tax
                            </div>
                            <div class="col-2 col-lg-2 bill-item">
                                Total
                            </div>
                            <div class="col-1 col-lg-2 bill-item">

                            </div>
                        </div>
                        <div formArrayName="billingItemDTOs" class='pt-4 d-block' style="position:relative">
                            <div *ngFor="let item of invoiceForm.get('billingItemDTOs')?.controls; let i=index;">
                                <div>
                                    <div [formGroupName]="i" class="mb-3">
                                        <div class="row nopadding">
                                            <div class="col-1 py-1 text-center">
                                                <p>{{i+1}}</p>
                                            </div>
                                            <div class="col-2">
                                                <input type="text" formControlName="desc" />
                                                <small
                                                    *ngIf="item.controls['desc'].touched && item.controls['desc'].errors"
                                                    class="p-error">
                                                    <span
                                                        *ngIf="item.controls['desc'].errors['required']">Required</span>
                                                    <span *ngIf="item.controls['desc'].errors?.pattern">Please
                                                        enter alphanumerics only</span>
                                                </small>
                                            </div>
                                            <div class="col-2">
                                                <span class="p-input-icon-left">
                                                    <i class='icon-style'>
                                                        <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                            class="img-style" />
                                                    </i>
                                                    <input type="text" pInputText formControlName="chargableAmount"
                                                        placeholder="Enter Amount">
                                                </span>
                                                <small
                                                    *ngIf="item.controls['chargableAmount'].touched && item.controls['chargableAmount'].errors"
                                                    class="p-error">
                                                    <span
                                                        *ngIf="item.controls['chargableAmount'].errors['required']">Required</span>
                                                    <span
                                                        *ngIf="item.controls['chargableAmount'].errors?.pattern">Please
                                                        enter only numbers</span>
                                                </small>
                                            </div>
                                            <div class="col-2 d-flex">
                                                <span class="p-input-icon-left">
                                                    <i class='icon-style'>
                                                        <img src="../../../../assets/images/invoice/discount-icn.svg"
                                                            class="img-style" />
                                                    </i>
                                                    <input type="text" pInputText formControlName="discount"
                                                        placeholder="Discount">
                                                </span>
                                                <small
                                                    *ngIf="item.controls['discount'].touched && item.controls['discount'].errors"
                                                    class="p-error">
                                                    <span
                                                        *ngIf="item.controls['discount'].errors['required']">Required</span>
                                                    <span *ngIf="item.controls['discount'].errors?.pattern">Please
                                                        enter only numbers</span>
                                                </small>
                                            </div>
                                            <div class="col-2">
                                                <span class="p-input-icon-left">
                                                    <i class='icon-style'>
                                                        <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                            class="img-style" />
                                                    </i>
                                                    <input type="text" pInputText formControlName="tax"
                                                        placeholder="Enter Tax">
                                                </span>
                                                <small
                                                    *ngIf="item.controls['tax'].touched && item.controls['tax'].errors"
                                                    class="p-error">
                                                    <span
                                                        *ngIf="item.controls['tax'].errors['required']">Required</span>
                                                    <span *ngIf="item.controls['tax'].errors?.pattern">Please
                                                        enter only numbers</span>
                                                </small>
                                            </div>
                                            <div class="col-2">
                                                <span class="p-input-icon-left">
                                                    <i class='icon-style'>
                                                        <img src="../../../../assets/images/invoice/dolar-sign.svg"
                                                            class="img-style" />
                                                    </i>
                                                    <input type="text" pInputText formControlName="totalAmount"
                                                        (focus)="showTotal(item, i)" placeholder="Total Amount">
                                                </span>
                                                <small
                                                    *ngIf="item.controls['totalAmount'].touched && item.controls['totalAmount'].errors"
                                                    class="p-error">
                                                    <span
                                                        *ngIf="item.controls['totalAmount'].errors['required']">Required</span>
                                                    <span *ngIf="item.controls['totalAmount'].errors?.pattern">Please
                                                        enter only numbers</span>
                                                </small>
                                            </div>
                                            <div *ngIf="i!=0" class="col-1">
                                                <div class="">
                                                    <button mat-stroked-button class="remove-billing-item"
                                                        (click)="removeBillingItem(i)">
                                                        <mat-icon class="remove_icon">
                                                            remove_circle_outline
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-block mt-3">
                                <div class="col-4 pull-right d-block mt-3">



                                    <div class="d-flex">
                                        <div class="col-6  pr-3 mr-2">
                                            <p class="total">Total</p>
                                        </div>
                                        <div class="total-amount col-6 text-center">
                                            <p> {{grandTotal | currency}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="col-6  pr-3 mr-2">
                                            <p class="total">
                                                <p-checkbox class="show-words" name="isSameAddress" binary="true"
                                                    #checkbox1 label="Show total in words" formControlName="grandTotal">
                                                </p-checkbox>
                                            </p>
                                        </div>
                                        <div *ngIf="checkbox1.checked">
                                            <strong class='text-capitalize'> {{ grandTotal | numberToWords }} dollars only</strong>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="checkbox1.checked">
                                        <strong> {{ grandTotal | numberToWords }}</strong>
                                    </div> -->
                                </div>

                            </div>


                            <div class="row">
                                <div>
                                    <button type="button" [disabled]="billingItemDTOs.length == 10 "
                                        (click)="addBillingItemDTOs()" class="new-row"> +
                                        Add
                                        New Row</button>
                                </div>

                            </div>




                        </div>
                    </div>
                </div>
            </div>
            <div class="row invoice-form-container mt-20" style="clear:both
            ">
                <h5 class="ml-2 terms">Terms and conditions</h5>
                <div class="row">
                    <p-divider></p-divider>
                </div>

                <div class="col-12">
                    <textarea formControlName="termsAndConditions" class="w-100"></textarea>
                    <small
                        *ngIf="invoiceForm.get('termsAndConditions').touched && invoiceForm.get('termsAndConditions').errors"
                        class="p-error">
                        <span *ngIf="invoiceForm.get('termsAndConditions').errors['required']">Required</span>
                    </small>
                </div>


            </div>
            <div class="row mt-3">
                <div class="col-3 nopadding recurrence">
                    <p class="item-label"> Invoice Preparer</p>
                    <p-dropdown class='w-100' [options]="employees" formControlName="signatoryName"
                        optionLabel="firstName" placeholder='Please Choose Invoice Preparer'
                        (onChange)="showSignature($event)">
                    </p-dropdown>
                    <small *ngIf="invoiceForm.get('signatoryName').touched && invoiceForm.get('signatoryName').errors"
                        class="p-error">
                        <span *ngIf="invoiceForm.get('signatoryName').errors['required']">Required</span>
                    </small>

                </div>
                <div class="col-2 ml-3" *ngIf="otherSignatory">
                    <!-- <label>Signatory Name</label> -->
                    <label>Invoice Preparer Name</label>
                    <input formControlName="signatoryName1" (blur)="getSignatoryName()" />
                </div>

                <div class="col-2 ml-3">
                    <div class=" p-0">
                        <label class="item-label">Upload signature</label>
                        <p-fileUpload #signatureUploader [disabled]="disableFields" name="demo[]" mode="basic"
                            chooseLabel="upload" [auto]="true" customUpload="true" accept="image/*"
                            invalidFileTypeMessageSummary=""
                            (uploadHandler)="UploadSignature($event, signatureUploader)"
                            invalidFileTypeMessageDetail="Unaccepted format. Please upload a 'png' format file"
                            showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                        </p-fileUpload>
                    </div>
                </div>

                <div class="col-2">
                    <div class="col-10">
                        <div style="width:250px;height:auto !important">
                            <img *ngIf="signatureImage" [src]="signatureImage" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-3  pull-right">
                <div class="col-12 pull-right" style="position:relative; top: -6px;right:75px">
                    <button type="button" class="btn btn-light mr-2" (click)="resetFields()"
                        *ngIf='!editInvoiceItem'>Cancel</button>
                    <button type="button" class="btn btn-light mr-2" *ngIf='editInvoiceItem'
                        (click)="backToMain()">Cancel</button>
                    <!-- <button type="button" class="btn btn-primary item-label btn-txt "
                        [disabled]="(!invoiceForm.valid || !companyLogoImage || !signatureImage)  || !(createdInvoiceId == null) "
                        (click)="saveInvoice()">Save
                    </button> -->
                    <!-- <button type="button" class="btn btn-primary item-label btn-txt "
                        [disabled]="(!invoiceForm.valid  || !signatureImage)  || !(createdInvoiceId == null) "
                        (click)="saveInvoice()">Save
                    </button> -->
                    <button type="button" class="btn btn-primary item-label btn-txt "
                    [disabled]="(!invoiceForm.valid)  || !(createdInvoiceId == null) "
                    (click)="saveInvoice()">Save
                </button>
                </div>
            </div>
        </form>
    </div>
    <form [formGroup]="uploadInvoiceForm" *ngIf="!isClientGenerateInvoice">
        <div *ngIf="!invoiceType" class="container-fluid">
            <div class="row mt-5 mb-5">
                <div class="col-4  nopadding">
                    <div>
                        <label class="label-cls required item-label"> Invoice Date</label>
                    </div>
                    <div>
                        <p-calendar #invDate1 formControlName="invoiceDate" placeholder="MM/DD/YYYY"
                            yearRange="2000:2099" [monthNavigator]="true" [yearNavigator]="true" [showIcon]="true"
                            [minDate]="minimumDate">
                        </p-calendar>
                        <small
                            *ngIf="uploadInvoiceForm.get('invoiceDate').touched && uploadInvoiceForm.get('invoiceDate').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('invoiceDate').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label class="label-cls required item-label"> Due Date</label>
                    </div>
                    <div>
                        <p-calendar formControlName="invoiceDueDate" [showIcon]="true" [monthNavigator]="true"
                            yearRange="2000:2099" [yearNavigator]="true" placeholder="MM/DD/YYYY"
                            [minDate]="invDate1.value || minimumDate">
                        </p-calendar>
                        <div>
                            <small
                                *ngIf="uploadInvoiceForm.get('invoiceDueDate').touched && uploadInvoiceForm.get('invoiceDueDate').errors"
                                class="p-error">
                                <span *ngIf="uploadInvoiceForm.get('invoiceDueDate').errors['required']">Required</span>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-4  nopadding">
                    <label for='startDate' class="label-cls required item-label"> Invoice Number</label>
                    <input type="text" formControlName="invoiceNumber" />
                    <small
                        *ngIf="uploadInvoiceForm.get('invoiceNumber').touched && uploadInvoiceForm.get('invoiceNumber').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('invoiceNumber').errors['required']">Required</span>
                    </small>
                </div>
            </div>
            <div class="row mt-5">
                <h6>Select the company you want to bill to</h6>
            </div>
            <div class="row mt-2">
                <div class="col-6 nopadding">
                    <p-dropdown class="w-100" placeholder='select client company' formControlName='selectedUploadClient'
                        [options]="clients" optionLabel="companyName" (onChange)="populateBilledToCompanyData($event)">
                    </p-dropdown>
                </div>
                <div class="col-6" *ngIf="otherUpload" style="position:relative;top:-36px !important">
                    <p class="label-cls required mb-2 mt-2">New Clients Business/Company name <p-checkbox
                            class="pull-right" name="isSameAddress" label="save client for future use"
                            formControlName="saveClientUpload" #saveClientUpload (click)="displayValue2($event,saveClientUpload)
                        " [readonly]="this.isOtherFieldsEditable">
                        </p-checkbox>
                    </p>
                    <input type="text" formControlName="newClient" [attr.required]="others || null">
                    <small
                        *ngIf="uploadInvoiceForm.get('newClient').touched && uploadInvoiceForm.get('newClient').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('newClient').errors['required']">Required</span>
                    </small>
                </div>
            </div>
            <div class="row mt-2" *ngIf="!otherUpload">
                <div class="col-6 nopadding recurrence">
                    <p class='label-cls required'>Clients Email</p>
                    <input type="text" formControlName="email" [disabled]="disableClientFields">
                </div>
                <div class="col-3">
                    <p class='label-cls required'>Phone Number</p>

                    <div class="d-flex">
                        <input type="text" formControlName="countryPhoneCode" class="col-3 col-md-3"
                            [attr.required]="!otherUpload || null" [readonly]="this.isUpEditable">

                        <input type="text" formControlName="phoneNumber" class="" [attr.required]="!others || null"
                            [readonly]="this.isUpEditable">
                    </div>
                </div>
                <div class="col-3">

                </div>
            </div>
            <div class="row" *ngIf="!otherUpload">
                <div class="col-6 nopadding ">
                    <label for="" class="item-label label-cls required">Address</label>
                    <input type="text" formControlName="address" [disabled]="disableClientFields"
                        [readonly]="this.isUpEditable">
                </div>
                <div class="col-6">

                </div>

            </div>
            <div class="row mt-3 " *ngIf="!otherUpload">
                <div class="col-3 nopadding">
                    <p class="label-cls required mb-2">Country</p>
                    <input type="text" formControlName="country" [readonly]="this.isUpEditable">
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">State</p>
                    <input type="text" formControlName="state" [readonly]="this.isUpEditable">
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">City</p>
                    <input type="text" formControlName="city" [readonly]="isUpEditable">
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">Zip/postal code</p>
                    <input type="text" formControlName="zipcode" [readonly]="isUpEditable">
                </div>
            </div>
            <div class="row" *ngIf="otherUpload">
                <div class="col-6 nopadding ">
                    <label for="" class="item-label label-cls required">Address</label>
                    <input type="text" formControlName="otherAddress" [readonly]="this.isOtherFieldsEditable"
                        [attr.required]="others || null">
                    <small
                        *ngIf="uploadInvoiceForm.get('otherAddress').touched && uploadInvoiceForm.get('otherAddress').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherAddress').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-6">
                </div>
            </div>

            <div class="row mt-2" *ngIf="otherUpload">
                <div class="col-6 nopadding recurrence">
                    <p class="item-label label-cls required"> Clients Email</p>
                    <input type="text" formControlName="otherEmail" [readonly]="this.isOtherFieldsEditable"
                        [attr.required]="others || null">
                    <small
                        *ngIf="uploadInvoiceForm.get('otherEmail').touched && uploadInvoiceForm.get('otherEmail').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherEmail').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-3">
                    <p class='label-cls required'>Phone Number</p>
                    <div class="d-flex">
                        <div>
                            <p-dropdown [options]="countries" formControlName="countryPhCode"
                                placeholder='Select Country Code' optionLabel="countryPhoneCode"
                                optionValue="countryCode" (onChange)="showSelectedCountry($event)">
                                [attr.required]="others || null"
                                [readonly]="this.isOtherFieldsEditable"
                            </p-dropdown>
                        </div>
                        <input type="text" formControlName="otherPhoneNumber" [readonly]="this.isOtherFieldsEditable"
                            [attr.required]="others || null">
                    </div>
                    <small
                        *ngIf="uploadInvoiceForm.get('otherPhoneNumber').touched && uploadInvoiceForm.get('otherPhoneNumber').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherPhoneNumber').errors['required']">Required</span>

                    </small>
                </div>
                <div class="col-3">
                </div>
            </div>

            <div class="row mt-3 " *ngIf="otherUpload">
                <div class="col-3 nopadding">
                    <p class="label-cls required mb-2">Country</p>
                    <p-dropdown class="w-100" [options]="countries" formControlName="otherCountry"
                        placeholder='Select Country' optionLabel="countryName" (onChange)="showSelectedCountry($event)">
                        [attr.required]="others || null"
                        [readonly]="this.isOtherFieldsEditable"
                    </p-dropdown>
                    <small
                        *ngIf="uploadInvoiceForm.get('otherCountry').touched && uploadInvoiceForm.get('otherCountry').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherCountry').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">State</p>
                    <p-dropdown class="w-100" [options]="otherStates" formControlName="otherState"
                        placeholder='Select State' optionLabel="stateProvinceName"
                        [attr.required]="others || null" [readonly]="this.isOtherFieldsEditable">
                    </p-dropdown>
                    <small
                        *ngIf="uploadInvoiceForm.get('otherState').touched && uploadInvoiceForm.get('otherState').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherState').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">City</p>
                    <input type="text" formControlName="otherCity" [attr.required]="others || null"
                        [readonly]="this.isOtherFieldsEditable" placeholder='Select City' />
                    <small
                        *ngIf="uploadInvoiceForm.get('otherCity').touched && uploadInvoiceForm.get('otherCity').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherCity').errors['required']">Required</span>
                    </small>
                </div>
                <div class="col-3">
                    <p class="label-cls required mb-2">Zip/postal code</p>
                    <input type="text" formControlName="otherZipCode" [attr.required]="others || null"
                        [readonly]="this.isOtherFieldsEditable" placeholder='Select Zipcode' />
                    <small
                        *ngIf="uploadInvoiceForm.get('otherZipCode').touched && invoiceForm.get('otherZipCode').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('otherZipCode').errors['required']">Required</span>
                        <span *ngIf="uploadInvoiceForm.get['otherZipCode'].errors?.pattern">Please
                            enter only numbers</span>
                    </small>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-12 nopadding">
                    <p>If you created the invoice and want to send it to the client upload a copy and send</p>
                    <p-fileUpload #invoiceUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                        customUpload="true" (uploadHandler)="UploadInvoice($event, invoiceUploader)"
                        [accept]="acceptedTypes" invalidFileTypeMessageSummary=""
                        invalidFileTypeMessageDetail="Unaccepted format. PDF, JPG, JPEG, and PNG file types are allowed"
                        showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                    </p-fileUpload>
                </div>
            </div>
            <div class="mt-3" *ngIf="uploadedFile">
                <div>
                    <label for="upload" class="item-label"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Format</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td>{{uploadedFile.fileName}}</td>
                        <td>{{uploadedFile.type}}</td>
                        <td>
                            <div class="row">
                                <div class="ml-2"> <i class="fa fa-trash icn" title="Delete"
                                        (click)="deleteuploadedDoc(uploadedFile)"></i> </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="mt-3" *ngIf="showUploadedfile">
                <div>
                    <label for="upload" class="item-label"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td>{{showUploadedfile}}</td>
                        <td>
                            <div class="row">
                                <div class="ml-2"> <i class="fa fa-trash icn" title="Delete"
                                        (click)="deleteuploadDoc(showUploadedfile)"></i> </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <!-- <div>
             Valid::   {{uploadInvoiceForm.valid}}
             FileUploaded ::   {{uploadedFile}}
             Value::    {{uploadInvoiceForm.value}}
             Value::    {{uploadInvoiceForm.controls}}
             invoiceDate::    {{uploadInvoiceForm.get('invoiceDate').value}}
            {{ this.invoiceForm.get(controls).value }}

             {{uploadInvoiceForm.get('controls').value}}
             <div>
                <button (click)="showFormValue()">
                    showFormValue();
                </button>
             </div>
                
            </div> -->
            <div class="row mt-3  pull-right">
                <div class="col-12 pull-right" style="position:relative; top: -6px;right:75px">
                    <button type="button" class="btn btn-light mr-3" (click)="backToMain()">Cancel</button>
                    <button type="button" class="btn btn-primary mr-3" *ngIf='!editInvoiceItem'
                        [disabled]="!uploadInvoiceForm.valid || !uploadedFile" (click)="saveUploadInvoice()">
                        Save</button>
                    <!-- <button *ngIf='editInvoiceItem && showUploadedfile' type="button" class="btn btn-primary mr-3"
                        [disabled]="!uploadInvoiceForm.valid " (click)="saveUploadInvoice()">Save</button>
                    <button *ngIf='editInvoiceItem && uploadedFile' type="button" class="btn btn-primary mr-3"
                        [disabled]="!uploadInvoiceForm.valid " (click)="saveUploadInvoice()">Save</button> -->

                        <button *ngIf='editInvoiceItem' type="button" class="btn btn-primary mr-3"
                        [disabled]="!uploadInvoiceForm.valid  || (!uploadedFile && !showUploadedfile)" (click)="saveUploadInvoice()">Save</button>
                    
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="uploadInvoiceForm" *ngIf="isClientGenerateInvoice">
        <div *ngIf="!invoiceType" class="container-fluid">
            <div class="row mt-5 mb-5">
                <div class="col-4  nopadding">
                    <div>
                        <label class="label-cls required item-label"> New Invoice Date</label>
                    </div>
                    <div>
                        <p-calendar #invDate1 formControlName="invoiceDate" placeholder="MM/DD/YYYY"
                            yearRange="2000:2099" [monthNavigator]="true" [yearNavigator]="true" [showIcon]="true"
                            [minDate]="minimumDate">
                        </p-calendar>
                        <small
                            *ngIf="uploadInvoiceForm.get('invoiceDate').touched && uploadInvoiceForm.get('invoiceDate').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('invoiceDate').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label class="label-cls required item-label"> Due Date</label>
                    </div>
                    <div>
                        <p-calendar formControlName="invoiceDueDate" [showIcon]="true" [monthNavigator]="true"
                            yearRange="2000:2099" [yearNavigator]="true" placeholder="MM/DD/YYYY"
                            [minDate]="invDate1.value || minimumDate">
                        </p-calendar>
                        <div>
                            <small
                                *ngIf="uploadInvoiceForm.get('invoiceDueDate').touched && uploadInvoiceForm.get('invoiceDueDate').errors"
                                class="p-error">
                                <span *ngIf="uploadInvoiceForm.get('invoiceDueDate').errors['required']">Required</span>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-4  nopadding">
                    <label for='startDate' class="label-cls required item-label"> Invoice Number</label>
                    <input type="text" formControlName="invoiceNumber" />
                    <small
                        *ngIf="uploadInvoiceForm.get('invoiceNumber').touched && uploadInvoiceForm.get('invoiceNumber').errors"
                        class="p-error">
                        <span *ngIf="uploadInvoiceForm.get('invoiceNumber').errors['required']">Required</span>
                    </small>
                </div>
            </div>
            <div style="visibility:hidden">
                <div class="row mt-5">
                    <h6>Select the company you want to bill to</h6>
                </div>
                <div class="row mt-2">
                    <div class="col-6 nopadding">
                        <p-dropdown class="w-100" placeholder='select client company'
                            formControlName='selectedUploadClient' [options]="clients" optionLabel="companyName"
                            (onChange)="populateBilledToCompanyData($event)">
                        </p-dropdown>
                    </div>
                    <div class="col-6" *ngIf="otherUpload" style="position:relative;top:-36px !important">
                        <p class="label-cls required mb-2 mt-2">New Clients Business/Company name <p-checkbox
                                class="pull-right" name="isSameAddress" label="save client for future use"
                                formControlName="saveClientUpload" #saveClientUpload (click)="displayValue2($event,saveClientUpload)
                        " [readonly]="this.isOtherFieldsEditable">
                            </p-checkbox>
                        </p>
                        <input type="text" formControlName="newClient" [attr.required]="others || null">
                        <small
                            *ngIf="uploadInvoiceForm.get('newClient').touched && uploadInvoiceForm.get('newClient').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('newClient').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="!otherUpload">
                    <div class="col-6 nopadding recurrence">
                        <p>Clients Email</p>
                        <input type="text" formControlName="email" [disabled]="disableClientFields">
                    </div>
                    <div class="col-3">
                        <p>Phone Number</p>

                        <div class="d-flex">
                            <input type="text" formControlName="countryPhoneCode" class="col-3 col-md-3"
                                [attr.required]="!otherUpload || null" [readonly]="this.isUpEditable">

                            <input type="text" formControlName="phoneNumber" class="" [attr.required]="!others || null"
                                [readonly]="this.isUpEditable">
                        </div>
                    </div>
                    <div class="col-3">

                    </div>
                </div>
                <div class="row" *ngIf="!otherUpload">
                    <div class="col-6 nopadding ">
                        <label for="" class="item-label">Address</label>
                        <input type="text" formControlName="address" [disabled]="disableClientFields"
                            [readonly]="this.isUpEditable">
                    </div>
                    <div class="col-6">

                    </div>

                </div>
                <div class="row mt-3 " *ngIf="!otherUpload">
                    <div class="col-3 nopadding">
                        <p class="label-cls required mb-2">Country</p>
                        <input type="text" formControlName="country" [readonly]="this.isUpEditable">
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">State</p>
                        <input type="text" formControlName="state" [readonly]="this.isUpEditable">
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">City</p>
                        <input type="text" formControlName="city" [readonly]="isUpEditable">
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">Zip/postal code</p>
                        <input type="text" formControlName="zipcode" [readonly]="isUpEditable">
                    </div>
                </div>
                <div class="row" *ngIf="otherUpload">
                    <div class="col-6 nopadding ">
                        <label for="" class="item-label">Address</label>
                        <input type="text" formControlName="otherAddress" [readonly]="this.isOtherFieldsEditable"
                            [attr.required]="others || null">
                        <small
                            *ngIf="uploadInvoiceForm.get('otherAddress').touched && uploadInvoiceForm.get('otherAddress').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherAddress').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-6">
                    </div>
                </div>

                <div class="row mt-2" *ngIf="otherUpload">
                    <div class="col-6 nopadding recurrence">
                        <p>Clients Email</p>
                        <input type="text" formControlName="otherEmail" [readonly]="this.isOtherFieldsEditable"
                            [attr.required]="others || null">
                        <small
                            *ngIf="uploadInvoiceForm.get('otherEmail').touched && uploadInvoiceForm.get('otherEmail').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherEmail').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-3">
                        <p>Phone Number</p>
                        <div class="d-flex">
                            <div>
                                <p-dropdown [options]="countries" formControlName="countryPhCode"
                                    placeholder='Select Country Code' optionLabel="countryPhoneCode"
                                    optionValue="countryCode" (onChange)="showSelectedCountry($event)">
                                    [attr.required]="others || null"
                                    [readonly]="this.isOtherFieldsEditable"
                                </p-dropdown>
                            </div>
                            <input type="text" formControlName="otherPhoneNumber"
                                [readonly]="this.isOtherFieldsEditable" [attr.required]="others || null">
                        </div>
                        <small
                            *ngIf="uploadInvoiceForm.get('otherPhoneNumber').touched && uploadInvoiceForm.get('otherPhoneNumber').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherPhoneNumber').errors['required']">Required</span>

                        </small>
                    </div>
                    <div class="col-3">
                    </div>
                </div>

                <div class="row mt-3 " *ngIf="otherUpload">
                    <div class="col-3 nopadding">
                        <p class="label-cls required mb-2">Country</p>
                        <p-dropdown class="w-100" [options]="countries" formControlName="otherCountry"
                            placeholder='Select Country' optionLabel="countryName"
                            (onChange)="showSelectedCountry($event)">
                            [attr.required]="others || null"
                            [readonly]="this.isOtherFieldsEditable"
                        </p-dropdown>
                        <small
                            *ngIf="uploadInvoiceForm.get('otherCountry').touched && uploadInvoiceForm.get('otherCountry').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherCountry').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">State</p>
                        <p-dropdown class="w-100" [options]="otherStates" formControlName="otherState"
                            placeholder='Select State' optionLabel="stateProvinceName"
                            [attr.required]="others || null" [readonly]="this.isOtherFieldsEditable">
                        </p-dropdown>
                        <small
                            *ngIf="uploadInvoiceForm.get('otherState').touched && uploadInvoiceForm.get('otherState').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherState').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">City</p>
                        <input type="text" formControlName="otherCity" [attr.required]="others || null"
                            [readonly]="this.isOtherFieldsEditable" placeholder='Select City' />
                        <small
                            *ngIf="uploadInvoiceForm.get('otherCity').touched && uploadInvoiceForm.get('otherCity').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherCity').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-3">
                        <p class="label-cls required mb-2">Zip/postal code</p>
                        <input type="text" formControlName="otherZipCode" [attr.required]="others || null"
                            [readonly]="this.isOtherFieldsEditable" placeholder='Select Zipcode' />
                        <small
                            *ngIf="uploadInvoiceForm.get('otherZipCode').touched && invoiceForm.get('otherZipCode').errors"
                            class="p-error">
                            <span *ngIf="uploadInvoiceForm.get('otherZipCode').errors['required']">Required</span>
                            <span *ngIf="uploadInvoiceForm.get['otherZipCode'].errors?.pattern">Please
                                enter only numbers</span>
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mt-3" style="position:relative;top:-350px !important;">
                <div class="col-lg-12 nopadding">
                    <p>If you created the invoice and want to send it to the client upload a copy and send</p>
                    <p-fileUpload #invoiceUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                        customUpload="true" (uploadHandler)="UploadInvoice($event, invoiceUploader)"
                        accept="acceptedTypes" invalidFileTypeMessageSummary=""
                        invalidFileTypeMessageDetail="Unaccepted format.  PDF, JPG, JPEG, and PNG file types are allowed"
                        showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                    </p-fileUpload>
                </div>
            </div>
            <div class="mt-3" *ngIf="uploadedFile" style="position:relative;top:-350px !important;">
                <div>
                    <label for="upload" class="item-label"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Format</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td>{{uploadedFile.fileName}}</td>
                        <td>{{uploadedFile.type}}</td>
                        <td>
                            <div class="row">
                                <div class="ml-2"> <i class="fa fa-trash icn" title="Delete"
                                        (click)="deleteuploadedDoc(uploadedFile)"></i> </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="mt-3" *ngIf="showUploadedfile" style="position:relative;top:-350px;">
                <div>
                    <label for="upload" class="item-label"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <td>{{showUploadedfile}}</td>
                        <td>
                            <div class="row">
                                <div class="ml-2"> <i class="fa fa-trash icn" title="Delete"
                                        (click)="deleteuploadDoc(showUploadedfile)"></i> </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row mt-3 mb-5 " style="position:relative;top:-350px;">
                <div class="col-6 col-lg-4  ">
                    <button type="button" class="btn btn-light ml-2" (click)="backToMain()">Cancel</button>
                    <button type="button" class="btn btn-primary pull-right"
                        [disabled]="!uploadInvoiceForm.valid || !uploadedFile"
                        (click)="saveUploadInvoice()">Save</button>
                </div>
            </div>
        </div>
    </form>
    <p-dialog header="Invoice Preview" [(visible)]="previewDialog" [modal]="true" [style]="{width: '80vw'}"
        [draggable]="false" [resizable]="false" *ngIf="!isClientGenerateInvoice">

        <div class="row mt-3 preview-header heading-text">
            <div class="col-12 ml-3 p-2">
                <span>Invoice Preview</span>
            </div>
        </div>
        <div class="row mt-3 ">
            <div class="col-md-4">
                <div class="p-3" *ngIf="companyLogoImage">
                    <img [src]="companyLogoImage " alt="profile picture">
                </div>
            </div>
            <div class="col-5 mt-2 text">
                <span>Invoice</span>
            </div>

            <div class="col-3 mt-2 pull-right" *ngIf="invoiceType">
                <div class="mt-2">
                    <strong>Date</strong> : <span
                        *ngIf="this.invoiceForm.get('invoiceDate').value">{{this.invoiceForm.get('invoiceDate').value |
                        date :
                        'shortDate'}}</span>
                </div>
                <div class="mt-2">
                    <strong>Due Date</strong> : <span>{{this.invoiceForm.get('invoiceDueDate').value | date :
                        'shortDate'}}</span>
                </div>
                <div class="mt-2">
                    <strong>Invoice No</strong>: <span>{{this.invoiceForm.get('invoiceNumber').value}}</span>
                </div>
            </div>

            <div class="col-3 mt-2 pull-right" *ngIf="!invoiceType">
                <div class="mt-2">
                    <strong>Date</strong> : <span
                        *ngIf="this.uploadInvoiceForm.get('invoiceDate').value">{{this.uploadInvoiceForm.get('invoiceDate').value
                        |
                        date :
                        'shortDate'}}</span>
                </div>
                <div class="mt-2">
                    <strong>Due Date</strong> : <span>{{this.uploadInvoiceForm.get('invoiceDueDate').value | date :
                        'shortDate'}}</span>
                </div>
                <div class="mt-2">
                    <strong>Invoice No</strong>: <span>{{this.uploadInvoiceForm.get('invoiceNumber').value}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-3 " style=" background-color: #EDF4FB;">
            <div class=" col-6 mt-2">
                <strong>Bill By</strong>
            </div>
            <div class="ml-2col-6 mt-2 ml-2">
                <strong>Bill To</strong>
            </div>
        </div>
        <div class="divider">
        </div>
        <div class="row" style=" background-color: #EDF4FB;">
            <div class="col-6">
                <strong>{{invoiceForm.get('busFirmName').value}}</strong>
                <address *ngIf="attorneyFirms[0]">
                    {{ this.attorneyFirms[0].address[0].addressLine1}}<br />
                    {{ this.attorneyFirms[0].address[1].city}}
                    {{ this.attorneyFirms[0].address[1].stateProvinceCode}}
                    {{ this.attorneyFirms[0].address[1].postCode}}<br />
                    Tel : {{ this.attorneyFirms[0].phoneContacts[0].phoneNo}}<br />
                    Email :{{this.attorneyFirms[0].emailContacts[0].email}}<br />
                </address>
            </div>
            <div class="col-6">
                <div *ngIf="invoiceType">
                    <strong *ngIf="!others">{{invoiceForm.get("selectedClient").value.companyName}}</strong>
                    <strong *ngIf="others">{{this.invoiceForm.get("newClient").value}}</strong>
                </div>
                <div *ngIf="!invoiceType">
                    <strong
                        *ngIf="!otherUpload">{{uploadInvoiceForm.get("selectedUploadClient").value.companyName}}</strong>
                    <strong *ngIf="otherUpload">{{this.uploadInvoiceForm.get("newClient").value}}</strong>
                </div>
                <address *ngIf="previewClient">
                    {{previewClient.address}}<br>
                    {{previewClient.city}}
                    {{previewClient.state}}
                    zipcode :{{previewClient.zipcode}}<br>
                    Tel: {{previewClient.phoneNumber}} <br>
                    Email: {{previewClient.email}} <br>
                </address>
            </div>
        </div>


        <div *ngIf="invoiceType" class="row mt-3 ml-2 d-block">
            <div>
                <div class="row billing-item p-2 " style="width:100% !important">
                    <div class="col-1 col-lg-1 bill-item">
                        Number
                    </div>
                    <div class="col-2 col-lg-2 bill-item">
                        Billing Item
                    </div>
                    <div class="col-3 col-lg-3 bill-item">
                        Chargeable amount
                    </div>
                    <div class="col-2 col-lg-2 bill-item">
                        Discount
                    </div>
                    <div class="col-2  col-lg-2 bill-item">
                        Tax
                    </div>
                    <div class="col-2 col-lg-2 bill-item">
                        Total
                    </div>
                    <div *ngIf="previewBillingItems">
                        <div class='row p-2' *ngFor="let item of previewBillingItems; let j= index;">
                            <div class="col-1 py-1 text-center">
                                <p>{{j}}</p>
                            </div>
                            <div class="col-2">
                                <input type="text" [value]='item.desc' readonly />
                            </div>

                            <div class="col-3 d-flex">
                                <span class="p-input-icon-left">
                                    <i class='icon-style'>
                                        <img src="../../../../assets/images/invoice/discount-icn.svg"
                                            class="img-style" />
                                    </i>
                                    <input type="text" pInputText [value]='item.chargableAmount' readonly>
                                </span>
                            </div>

                            <div class="col-2 d-flex">
                                <span class="p-input-icon-left">
                                    <i class='icon-style'>
                                        <img src="../../../../assets/images/invoice/discount-icn.svg"
                                            class="img-style" />
                                    </i>
                                    <input type="text" pInputText [value]='item.discount' readonly>
                                </span>
                            </div>

                            <div class="col-2">
                                <span class="p-input-icon-left">
                                    <i class='icon-style'>
                                        <img src="../../../../assets/images/invoice/dolar-sign.svg" class="img-style" />
                                    </i>
                                    <input type="text" pInputText [value]='item.tax' readonly>
                                </span>
                            </div>

                            <div class="col-2">
                                <span class="p-input-icon-left">
                                    <i class='icon-style'>
                                        <img src="../../../../assets/images/invoice/dolar-sign.svg" class="img-style" />
                                    </i>
                                    <input type="text" pInputText [value]='item.totalAmount' readonly>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block mt-3">
                <div class="col-4 pull-right d-block mt-3">
                    <div class="d-flex">
                        <div class="col-6  pr-3 mr-2">
                            <p class="total">Total</p>
                        </div>
                        <div class="total-amount col-6 text-center">
                            <p>{{grandTotal | currency }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="invoiceType" class="row mt-5 mb-5 d-flex " style="clear:both">
            <div class="col-6 preview-header">
                <h3>Terms and conditions</h3>
                <div class="divider"></div>
                <p>{{invoiceForm.get('termsAndConditions')?.value}}</p>
            </div>
            <div class="col-3 ">
                <div class="p-3 d-block pull-right">
                    <div><img [src]="signatureImage" alt="profile picture"></div>
                    <div>
                        <strong>{{signatoryFirstName}}(immigration Attorney)</strong>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!invoiceType" class="row mt-5 mb-5 d-flex " style="clear:both">
            <div class="col-6 ">
                <div class="p-3 d-block ">
                    <div><img [src]="ownInvoiceImage" alt="profile picture"></div>
                </div>
            </div>

        </div>
    </p-dialog>