import { Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
//import { FroalaEditorOptions } from '../../../../data/constants/froala-editor-options';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
import { stepStatus } from '../../../../data/constants/step-status';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-rfe-prepare-response-step3',
  templateUrl: './rfe-prepare-response-step3.component.html',
  styleUrls: ['./rfe-prepare-response-step3.component.scss']
})
export class RfePrepareResponseStep3Component implements OnInit {
@ViewChild("rferesponseheaderupload") public rferesponseheaderupload: UploadImageDialogComponent;
@ViewChild("rferesponsecontentupload") public rferesponsecontentupload: UploadImageDialogComponent;
@ViewChild("rferesponsefooterupload") public rferesponsefooterupload: UploadImageDialogComponent;

@Output() @ViewChild("rfeResponseHeaderkendoeditor") public rfeResponseHeaderkendoeditor: EditorComponent;
@Output() @ViewChild("rfeResponseContentkendoeditor") public rfeResponseContentkendoeditor: EditorComponent;
@Output() @ViewChild("rfeResponseFooterkendoeditor") public rfeResponseFooterkendoeditor: EditorComponent;
  // public options = FroalaEditorOptions.EditorOptions;
  @Input() rfeTimelineModalInput;
  @Input() rfeNoticeId;
  @Input() currentStepId;
  rfeTemplateList: any[];
  standardRfeTemplateList: any[];
  legalRfeTemplateList: any[];
  rfeResponseCompanyId;
  rfeResponseHeader: string;
  rfeResponseContent: string;
  rfeResponseFooter: string;
  rfePlaceholderList: any[] = [];
  petitionData: any[] = [];
  displayResponse: boolean = false;
  rfeDisplayResponse = [];
  disableComplete: boolean = true;

  constructor(private petitionerService: PetitionerService,
    private _clipboardService: ClipboardService,
    private rfeService: RfeService, private toastr: ToastrService) {
    // this._clipboardService.configure({ cleanUpAfterCopy: true });
  }

  ngOnInit() {
    //froalaService.insertPageBreak();
    this.getRfeResponse();
    this.getRfeResponseTemplate();
    this.getRfePlaceholder();
    this.getPetitonData();
    // this._clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
    // // console.log("$$$",res)
    //   if (res.isSuccess) {
    //     this.toastr.info('response copied');
    //     //this.toastr.info(`${item.responseTitle} response copied ${res.successMessage}`);
    //   }
    // });
  }

  public openrferesponseheaderimageuploaddialog() {    
    this.rferesponseheaderupload.open();
  }
  public openrferesponsecontentimageuploaddialog() {    
    this.rferesponsecontentupload.open();
  }
  public openrferesponsefooterimageuploaddialog() {    
    this.rferesponsefooterupload.open();
  }
  getRfeResponse() {
    this.rfeService
      .getRfeResponseBlock(parseInt(sessionStorage.getItem('companyId')))
      .subscribe((data) => {
        this.rfeTemplateList = data;
        this.standardRfeTemplateList = data.filter(
          item => item["responseTypeCode"] === "RFEDEFRESP");
        this.legalRfeTemplateList = data.filter(
          item => item["responseTypeCode"] === "RFELAWRESP");
      });
  }

  getRfeResponseTemplate() {
    this.rfeService
      .getRfeResponseTemplate(this.rfeNoticeId, 0)
      .subscribe((data) => {
        if (data != undefined) {
          this.rfeResponseCompanyId = data["companyId"];
          this.rfeResponseHeader = data["contentMap"]["header"];
          this.rfeResponseContent = data["contentMap"]["body"];
          this.rfeResponseFooter = data["contentMap"]["footer"];
        }
      });
  }

  getRfePlaceholder() {
    this.rfeService
      .getRfePlaceholder(this.rfeNoticeId)
      .subscribe((data) => {
        if (data.length != 0) {
          this.rfePlaceholderList = data;

        }
      });
  }

  getPetitonData() {
    this.rfeService.getPetitonData(this.rfeNoticeId).subscribe((data) => {
      //  console.log("getdata", data);
      this.petitionData = data.petitionData ? data.petitionData : [];
    });

  }

  copyContent(item, val?) {
    if (item["responseTitle"] === "USCIS Office Address") {
      this.toastr.info(`${item.responseTitle} response copied`);
      this.copyContentToListener(val['address'])
      //  this._clipboardService.copyFromContent(val['address'])
    } else if (item.responseText) {
      this.toastr.info(`${item.responseTitle} response copied`);
      this.copyContentToListener(item.responseText)
      // this._clipboardService.copyFromContent(item.responseText);
    }
    else {
      this.copyContentToListener('')
      this.toastr.info(`No data available to copy`);
    }
    this.displayResponse = false;
  }

  view(item) {
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = item["responseTypeName"];
    this.rfeDisplayResponse["responseText"] = item["responseText"];
    this.rfeDisplayResponse["responseTitle"] = item["responseTitle"];
  }

  viewPlaceholder(item) {
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = "";

    this.rfeDisplayResponse["responseTitle"] = item["placeHolderDescription"];
    if (this.rfeDisplayResponse["responseTitle"] === "USCIS Office Address") {
      this.rfeDisplayResponse["responseText"] = item["serviceCenters"];
    } else {
      this.rfeDisplayResponse["responseText"] = item["placeHolderValue"];
    }
  }

  viewPetiton(item) {
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = item["description"];
    this.rfeDisplayResponse["responseText"] = item["value"];
    this.rfeDisplayResponse["responseTitle"] = "Petition Details";
  }

  closeDisplayResponse() {
    this.displayResponse = false;
  }

  copyContentToListener(copiedContent) {
    //   let copyEvent = (e: ClipboardEvent) => {
    //   e.preventDefault();
    //   e.clipboardData.clearData("text/html");
    //   e.clipboardData.setData('text/html', copiedContent);
    //   document.removeEventListener('copy', copyEvent);
    // }
    // this._clipboardService.copyFromContent(copiedContent);
    // document.addEventListener('copy', copyEvent);
    // document.execCommand('copy');
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/html', copiedContent);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }



  copyPlaceholderContent(item) {
    if (item.placeHolderValue) {
      this.toastr.info(`${item.placeHolderDescription} response copied`);
      this.copyContentToListener(item.placeHolderValue)
    } else {
      this.copyContentToListener('')
      this.toastr.info(`No data available to copy`);
    }
    this.displayResponse = false;

  }

  copyContentPetition(item) {
    if (item.value) {
      this.toastr.info(`${item.description} response copied`);
      this.copyContentToListener(item.value)

    } else {
      this.copyContentToListener('')
      this.toastr.info(`No data available to copy`);
    }
    this.displayResponse = false;

  }

  copyDefaultContent(type) {

    this.rfeService
      .getRfeResponseTemplate(this.rfeNoticeId, 1)
      .subscribe((data) => {
        if (data.length != 0) {
          switch (type) {
            case "header":
              this.rfeResponseHeader = data["contentMap"]["header"];
              break;
            case "body":
              this.rfeResponseContent = data["contentMap"]["body"];
              break;
            case "footer":
              this.rfeResponseFooter = data["contentMap"]["footer"];
              break;
          }
        }
      });

  }

  saveRfeResponseTemplate() {
    let contentMap = {
      "header": this.rfeResponseHeader,
      "footer": this.rfeResponseFooter,
      "body": this.rfeResponseContent
    };

    let payload = {
      "companyId": this.rfeResponseCompanyId,
      "contentMap": contentMap,
      "isDefault": 0,
      "rfeId": this.rfeTimelineModalInput["taskNumber"],
      "rfeNoticeId": this.rfeNoticeId
    };

    this.rfeService.saveRfeResponseTemplate(this.rfeNoticeId, payload).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.disableComplete = false;
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
    //this.rfePrepareResponseStep1Component.getStatus();
    // this.rfePrepareResponseStep2Component.getStatus();
  }

  changeStatus() {
    const payload = {
      statusCode: stepStatus.Complete,
      stepNote: ''
    };
    const payload1 = {
      statusCode: stepStatus.Inprogress,
      stepNote: ''
    };
    this.petitionerService.stepStatusUpdateRFE(payload1, this.rfeTimelineModalInput["taskNumber"], this.rfeTimelineModalInput["parentStepId"]).subscribe();
    this.petitionerService.stepStatusUpdateRFE(payload, this.rfeTimelineModalInput["taskNumber"], this.currentStepId).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
      }
    });

  }

  copied(e) {
    // console.log("$$$ Copied",e);
    this.closeDisplayResponse()
  }

  public rferesponseheadereditorValueChange(value: string): void {
    this.rfeResponseHeader = value;
  }

  public rferesponsecontentValueChange(value: string): void {
    this.rfeResponseContent = value;
  }

  public rferesponsefooterValueChange(value: string): void {
    this.rfeResponseFooter = value;
  }
}
