import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UtilityServiceService {
  formChangesSubscription: Subscription;
  statusChangeSubScriptionSubjectStep = new BehaviorSubject<number>(1);
  formIsDirtySubject = new BehaviorSubject<{ dirty: boolean, formName: string; formNumber: number }>({ dirty: false, formName: '', formNumber: 1 });

  constructor() { }

  checkBeneEditPermission() {
    const permission = JSON.parse(sessionStorage.getItem('currentUser'));
    return permission ? permission['permissions'].length > 0 && permission['permissions'].find(x => x === 'EDIT_BENEFICIARY') ? true : false : false;
    // return permission['permissions'].length>0 && permission['permissions'].find(x=> x === 'DELETE_BENEFICIARY')? true : false;
  }
}
