<div mat-dialog-title class="text-left position-relative">
  <h5 class="mt-2">{{title}}</h5>
  <button *ngIf="showClose" mat-dialog-close mat-icon-button aria-label="Close" class="icon-button position-absolute" style="top:0;right:0">
      <mat-icon>close</mat-icon>
    </button>
    <hr />  
</div>

<mat-dialog-content class="mat-typography text-left">

<div [innerHTML]="message | keepHtml"></div>
</mat-dialog-content>

<mat-dialog-actions align="right" class="border-top mt-1">
  <div *ngIf="showButtons" class="float-right w-100 d-block">
  <button mat-button class="btn text-secondary" (click)="rejectPolicy()">Decline</button>
  <button mat-button class="btn btn-primary" (click)="acceptPolicy()">
    <i class="fa fa-chevron-right im-drop-icon"></i>
    Accept & Continue</button>
  </div>
</mat-dialog-actions>
