<form #noteDialogForm="ngForm">
    <div class="col-md-12 p-0 row delete-header">
        <div class="col-md-10 p-0">
            <span>{{title}}</span>
        </div>
        <div class="col-md-2 p-0 justify-content-end d-flex">
            <i (click)="handleClose();" class="close-icon pi pi-times"></i>
        </div>
    </div>
    <div class="row col-md-12 pl-0 pb-3 pt-3">
        <div class="col-md-12 row pl-0">Add Note</div>
        <div class="col-md-12 row pl-0">
            <input type="text" class="im-input mw-100" [(ngModel)]="reason" />
        </div>
    </div>
    <div class="row col-md-12 pl-0 justify-content-start d-flex pr-0">
        <div class="col-md-3 pl-0">
            <button pButton type="button" label="Submit" (click)="handleSubmit()"></button>
        </div>
    </div>
</form>