<div *ngIf="userType == 'Attorney'">
<div class="form-group">
<div>
  <div class="row">
    <div class="col-12 px-0">
      <h6><b>RFE Details</b></h6>
    </div>
    <div class="row form-group">
      <div class="col-4 pl-0">
        <label>Receipt No. *</label>
        <input [disabled]="disableFields" name="receiptNo" class="form-control" type="text" pInputText [(ngModel)]="receiptNo" required/>
      </div>
    
      <div class="col-4 pl-0">
        <label>RFE Notice Date *</label>
        <p-calendar [disabled]="disableFields" name="noticeDate" [(ngModel)]="noticeDate" dateFormat="dd/mm/yy" required [showIcon]="true" ></p-calendar>
      </div>
      <div class="col-4 px-0">
        <label>RFE Response Due Date *</label>
        <p-calendar [disabled]="disableFields" name="receiptDate" [(ngModel)]="receiptDate" [minDate]="noticeDate" dateFormat="dd/mm/yy" required [showIcon]="true"></p-calendar>
      </div>
    </div>
  </div>
</div>
<hr class="mt-4"/> 
  <div class="row">
    <div class="col-12 px-0">
      <h6><b>Upload RFE Notice</b></h6>
    </div>
  </div>
    <div class="row">
      <div class="col-12 px-0">
        <!-- <app-file-upload style="width: 100%;" [configurations]="configurations"></app-file-upload> -->
        <p-fileUpload [disabled]="disableFields" #createUploader name="demo[]" mode="basic" chooseLabel="Browse" [auto]="true" customUpload="true" 
        (uploadHandler)="onUpload($event, createUploader)"  [accept]="acceptedTypes" 
        invalidFileTypeMessageSummary=""
        invalidFileTypeMessageDetail="Unaccepted format. PDF, JPG, JPEG, and PNG file types are allowed"
        showUploadButton="false"
        showCancelButton="false"
        maxFileSize="10485760">
      </p-fileUpload>
    </div>
  </div>
      <div class="row mt-4">
      <h6> Uploaded Documents</h6>
    </div>
      <div class="p-datatable"> 
        <div class="p-datatable-thead">
          <div class="row">
            <div class="col-4">
              Document Type  
            </div>
            <div class="col-6">
              Document Name
            </div>
            <div class="col-2">
              Action
            </div>
          
          </div>
        </div>
          <div class="p-datatable-tbody">
            <div class="row" *ngIf="uploadedFile">
              <div class="col-4">
                <div *ngIf="uploadedFile.fileCategory">
                {{uploadedFile?.fileCategory?.name}}  
              </div>
              <div *ngIf="!uploadedFile.fileCategory">
                RFE Receipt Notice  
              </div>
              </div>

              <div class="col-6">
                <label class="ellipsis">{{uploadedFile?.fileName}}</label>
                <span *ngIf="uploadedFile?.fileType">.{{uploadedFile?.fileType}}</span>
              </div>
              <div class="col-2">
                <div class="d-flex flex-row">
                  <div
                    *ngIf="uploadedFile.fileLocation"
                    class
                  >
                    <app-file-download
                      [fileName]="uploadedFile.fileLocation.substring(23)"
                      [Path]="envPath + uploadedFile.fileLocation.substring(23)"
                    ></app-file-download>
                  </div>
                  <span *ngIf="uploadedFile.fileLocation" (click)="openDoc(uploadedFile.fileLocation)" class="ml-2">
                    <mat-icon class="mr-1 icn"  matTooltipClass="tooltip-bg" matTooltip="View Document" class="material-icons">visibility</mat-icon>
                    <!-- <i class="fa fa-eye fa-lg icn" aria-hidden="true"></i>  -->
                  </span>
                  <div class="ml-2">
                    <span [hidden]="disableFields" (click)="confirmDelete(uploadedFile)">
                      <mat-icon
                        class="mr-1 icn"
                        matTooltip="Delete Document"
                        class="material-icons"
                        >delete</mat-icon
                      >
                      <!-- <i class="fa fa-trash fa-lg icn" aria-hidden="true"></i> -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          
        </div>
        <div class="mt-4 text-center">
          <button *ngIf="!disableFields" [disabled]="enableMarkComplete" type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" 
          (click)="changeStatusToComplete();" label="Mark Complete" class="ml-2 button_whitebg p-button-outlined"></button>
          <div *ngIf="disableFields" class="d-inline-block">
            <img src="../../../../../assets/images/stepCompleteLabel.png" />
          </div>
          
          <button class="btn-success button-width-icon-left float-right" 

            icon="fa fa-floppy-o" type="submit" pButton [disabled]="disableFields || !receiptNo || !noticeDate || !receiptDate" 
            (click)="saveData();" label="Save"></button> 
        </div>
</div>  
<p-confirmDialog header="Delete"></p-confirmDialog>
</div>

<div *ngIf="userType != 'Attorney'" class="p-3 border rounded bg-light">
  <div><strong>Last Updated By : </strong> {{ SubTaskOverView['preparerAttorneyName']}}</div>
  <hr/>
  <div><strong>Status : </strong> {{ SubTaskOverView['taskStepDetails'][0]['stepStatusDetails']['stepStatusName'] }}</div>
  <div><strong>Comment : </strong> {{ SubTaskOverView['taskStepDetails'][0]['stepNote'] }}</div>
</div>
        
     
    

