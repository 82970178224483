import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType} from '@ngrx/effects'
import { CompanyActionTypes } from '../actions/company.actions';
import { tap, map, switchMap } from 'rxjs/operators';
import * as CompanyActions  from '../actions/company.actions';
import { CompanyService } from 'src/app/services/company.service';

@Injectable({
    providedIn : 'root'
})

export class CompanyEffects{
    constructor(private actions$: Actions, private companyService: CompanyService){}

     listCompany$ = createEffect(() => this.actions$.pipe(
        ofType<CompanyActions.ListCompany>(CompanyActions.CompanyActionTypes.ListCompany),
        switchMap(action => {
           //call api
            return this.companyService.getParentCompanyList(action.payload).pipe(
                map(response=>{
                    return {type: CompanyActions.CompanyActionTypes.ListCompanySuccess, payload: response}
                })
            )
        })
    ))
     listAssocCompany$ = createEffect(() => this.actions$.pipe(
        ofType<CompanyActions.ListAssocCompany>(CompanyActions.CompanyActionTypes.ListAssocCompany),
        switchMap(action => {
           //call api
            return this.companyService.getAssocCompanies(action.payload).pipe(
                map(response=>{
                    return {type: CompanyActions.CompanyActionTypes.ListAssocCompanySuccess, payload: response}
                })
            )
        })
    ))
     saveCompany$ = createEffect(() => this.actions$.pipe(
        ofType<CompanyActions.SaveCompany>(CompanyActions.CompanyActionTypes.SaveCompany),
        switchMap(action => {
           //call api
            return this.companyService.saveCompany(action.payload).pipe(
                map(response =>{
                    // console.log('here');
                    // console.log(response);
                    return {type: CompanyActions.CompanyActionTypes.SetCompany, payload: response}
                })
            )
        })
    ))

}
