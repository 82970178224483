
import { I9Model } from 'src/app/data/models/app-state';
import { I9Actions, I9ActionTypes } from '../actions/i9-section-one.actions';



export function I9SectionOneReducer(state:I9Model = {}, action: I9Actions){
  switch(action.type){
       case I9ActionTypes.GETSECTIONONE:{
           return state;
       }
       case I9ActionTypes.SETSECTIONONE:{
         return {...state,
            companyId: action.payload.companyId,
            beneficiaryId: action.payload.beneficiaryId,
            i9FormDtlId: action.payload.i9FormDtlId,
            beneficiaryDetails: action.payload.beneficiaryDetails,
            i9WorkAuthPreparerInfo: action.payload.i9WorkAuthPreparerInfo,
            isAntiDiscrAccpt: action.payload.isAntiDiscrAccpt,
            statusCode: action.payload.statusCode
        };
       }
       case I9ActionTypes.SETBENEINFO:{
         return {...state, beneficiaryDetails: action.payload};
       }
       case I9ActionTypes.SETFORMINFO:{
         return {...state, formInfo: action.payload};
       }
       case I9ActionTypes.SETPREPAREINFO:{
        return {...state, i9WorkAuthPreparerInfo:{...state.i9WorkAuthPreparerInfo, preparerInfo: action.payload}};
       }
       case I9ActionTypes.SETWORKAUTHPREPARERINFO:{
        //  console.log(action.payload);
        return {...state, i9WorkAuthPreparerInfo:action.payload};
       }
       default: {
           return state;
       }

   }
}
