<!-- <div class="container-fluid mt-2"> -->
<div  *ngIf="meetingpageLoaded"class="mt-4">
    <form [formGroup]=" meetingForm">
        <div class="mt-3">
            <div class="row">
                <div class="col-4 nopadding mt-3">
                    <h5 style="font-weight:bolder;">Meeting Details</h5>
                </div>
                <div class="col-lg-8">
                    <div class="pull-right">
                        <p-radioButton class='mr-1' name="groupname" value="1" label="public"
                        [(ngModel)]="isPublic"formControlName='isPublic'>
                        </p-radioButton>
                        <p-radioButton name="groupname" value="0" label='private' [(ngModel)]="isPublic"formControlName='isPublic'>
                        </p-radioButton>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
            <div class="col-4 nopadding">
                <h5 style="font-weight: bolder">Set Court Date Reminder</h5>
            </div>
            <div class="col-lg-8">
                <div class="pull-right">
                    <p-radioButton class='mr-1' name="groupname" value="1" label="public"
                        formControlName='isPublic'>
                    </p-radioButton>
                    <p-radioButton name="groupname" value="0" label='private' formControlName='isPublic'>
                    </p-radioButton>
                </div>
            </div>
        </div> -->

            <div>
                <div class="row mt-2">
                    <label class="label-cls required test" for="first-name">Title/Subject </label>
                    <input id="first-name" type="text" formControlName="subject">
                    <small *ngIf="meetingForm.get('subject').touched && meetingForm.get('subject').errors"
                        class="p-error">
                        <span *ngIf="meetingForm.get('subject').errors['required']">Required</span>
                    </small>
                </div>
                <div class=" mt-3">
                    <label class="label-cls required">Attendees </label>
                    <span><i class="fa fa-plus ml-2" (click)="requiredImagilityEmail()"></i></span>
                    <span>(click on plus icon to add Imagility users)</span>
                    <div class="p-fluid">
                    <p-chips styleClass="p-chips" formControlName="reqattendiesList" [(ngModel)]="requiredEmails" separator=","></p-chips>
                    <small
                        *ngIf="meetingForm.get('reqattendiesList').touched && meetingForm.get('reqattendiesList').errors"
                        class="p-error">
                        <span *ngIf="meetingForm.get('reqattendiesList').errors['required']">Required</span>
                    </small>
                    </div>

                </div>
                <p-dialog [style]="{height: '500px'}" header="Add Imagility Attendes" [(visible)]="required"
                    [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                    <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filteredCountries"
                        (completeMethod)="filterAttendes($event)" field="name" [multiple]="true"
                        formControlName="reqImagilityattendiesList" [(ngModel)]="requiredImgEmails">
                    </p-autoComplete>
                    <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                    <div class="mt-2">
                        <button class="btn btn-primary" (click)="addEmail()">Add</button>
                        <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                    </div>
                    <div [style]="{height: '200px'}">

                    </div>

                </p-dialog>
                <div class=" mt-3" style="width:100%">
                    <label>Optional Attendes</label>
                    <span><i class="fa fa-plus ml-2" (click)="addImagilityEmail()"></i></span>
                    <span>(click on plus icon to add Imagility users)</span>
                    <div class="p-fluid">
                    <p-chips [style]="{'minWidth':'100%'}" [(ngModel)]="optEmail" formControlName="optattendiesList" separator=","></p-chips>
                    </div>

                </div>
                <p-dialog header="Add Imagility Users" [(visible)]="optional" [modal]="true"
                    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                    <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filteredCountries"
                        (completeMethod)="filterAttendes($event)" field="name" [multiple]="true"
                        formControlName="optimagilityattendiesList" [(ngModel)]="optImgEmail">
                    </p-autoComplete>
                    <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                    <div class="mt-2">
                        <button class="btn btn-primary" (click)="addoptEmail()">Add</button>
                        <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                    </div>
                    <div [style]="{height: '200px'}">

                    </div>
                </p-dialog>
                <!-- <div>
                    <input type="text" (keyup)="attendeSearch($event)">
                </div>
                <section>
                    {{filteredEmails.length}}
                    <p *ngIf="filteredEmails.length<1">sorry no email found</p>
                    <div  *ngFor="let emails of filteredEmails">
                     <p>{{emails.name}}</p>
                    </div>
                </section> -->
                <div class="row mt-2">
                    <label class="label-cls required" for="first-name">Location</label>
                    <input id="first-name" type="text" formControlName="meetingLocation">
                    <small
                        *ngIf="meetingForm.get('meetingLocation').touched && meetingForm.get('meetingLocation').errors"
                        class="p-error">
                        <span *ngIf="meetingForm.get('meetingLocation').errors['required']">Required</span>
                    </small>
                </div>
                <div class="row divider mt-3">
                </div>
                <div class="row mt-3">
                    <p class="label-cls"> Time and duration</p>
                </div>
                <div class="row mt-3">
                    <h6 style="font-weight:bolder;">Time Zone: EST ( Eastern time zone - USA )</h6>
                </div>
                <div class="row">
                    <div class="col-2 nopadding">
                        <div>
                            <label class="label-cls required"> Start Date</label>
                            <!-- </div> -->
                            <!-- <div> -->
                            <p-calendar #sd formControlName="startDate" name="date" styleClass="p-form-datepicker"
                                dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true" [monthNavigator]="true"
                                [yearNavigator]="true" [minDate]="minimumDate" yearRange="2000:2099"
                                (onSelect)="checkEndDateTouched(sd)">
                            </p-calendar>
                        </div>
                        <small *ngIf="meetingForm.get('startDate').touched && meetingForm.get('startDate').errors"
                            class="p-error">
                            <span *ngIf="meetingForm.get('startDate').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-2 ml-3">
                        <label class="label-cls required">Start Time</label>
                        <input type="time" #ed formControlName="startTime" (click)="checkendTime()">
                        <small *ngIf="meetingForm.get('startTime').touched && meetingForm.get('startTime').errors"
                            class="p-error">
                            <span *ngIf="meetingForm.get('startTime').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-1 mt-2 text-center">
                        <label for=""></label>
                        <div>
                            <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
                        </div>
                    </div>
                    <div *ngIf="hideDate" class="col-2 ">
                        <label class="label-cls required"> End Date</label>
                        <!-- <input type="date" formControlName="endDate"> -->
                        <p-calendar #ed [disabled]='!sd.value' formControlName="endDate" name="date"
                            styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                            [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                            [minDate]="sd.value || minimumDate" yearRange="2000:2099" (onSelect)="setEndDate(ed.value)">
                        </p-calendar>
                        <small *ngIf="meetingForm.get('endDate').touched && meetingForm.get('endDate').errors"
                            class="p-error">
                            <span *ngIf="meetingForm.get('endDate').errors['required']">Required</span>
                        </small>

                    </div>
                    <div class="col-2 ">
                        <label class="label-cls required">End Time</label>
                        <input type="time" formControlName="endTime">
                        <small *ngIf="meetingForm.get('endTime').touched && meetingForm.get('endTime').errors"
                            class="p-error">
                            <span *ngIf="meetingForm.get('endTime').errors['required']">Required</span>
                            <span *ngIf="timevalidationerror">End Time should be greater than Start Time</span>
                        </small>
                    </div>



                </div>
                <div class="row mt-3">
                    <div class="col-12 nopadding recurrence">
                        <p class="label-cls required mb-2">Recurrence</p>
                        <p-dropdown [options]="recurrencedropdown" (onChange)="recurrenceTypechanged($event)"
                            placeholder="Select" optionLabel="name" optionValue="code" formControlName="recuurenceType">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row mt-2">

                    <div class="mt-4" *ngIf="scheduleType ==='WEEKLY'">
                        <div class=" mt-2">
                            <h6 style="font-weight:bolder;">Set Recurrence</h6>
                        </div>
                        <!-- value={{day.day}} formControlName="selectedWeeks" -->
                        <div>
                            <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                value={{day.day}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                                (onChange)="onChangeWeekDays(day,$event)">
                            </p-checkbox>
                        </div>
                        <br>

                    </div>
                    <br>
                    <div class="mt-4" *ngIf="scheduleType ==='BIWEEKLY'">
                        <div class=" mt-2">
                            <h6 style="font-weight:bolder;">Set Recurrence</h6>
                        </div>
                        <!-- value={{day.day}} formControlName="selectedWeeks" -->
                        <div>
                            <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                value={{day.day}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                                (onChange)="onChangeWeekDays(day,$event)">
                            </p-checkbox>
                        </div>
                        <br>

                    </div>
                    <!--Bi-week-->
                    <!-- <div class="mt-3 days" *ngIf="scheduleType==='BIWEEK'">
                        <div class="mt-2">
                            <mat-radio-group [(ngModel)]="weekNo" formControlName="weekNo">
                                <mat-radio-button name="Bi-Weekly" value="1"> First Week </mat-radio-button>
                                <mat-radio-button name="Bi-Weekly" value="2"> Second Week </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="mt-2">        
                            <div>
                                <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                    value={{day.value}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                                    (onChange)="onChangeWeekDays(day,$event)">
                                </p-checkbox>
                            </div>

                            <div class="p-2 mt-2 mb-3 timeOnly d-flex" *ngIf="scheduleType==='BIWEEK'">
                                <span class="mr-2 mt-2">Time</span>
                                <p-calendar  placeholder="00:00" hourFormat="24"
                                    formControlName="scheduleTime" timeOnly="true"></p-calendar>
                            </div>
                        </div>
                    </div> -->
                    <div class=" mt-3 mb-3 " *ngIf="scheduleType==='MONTHLY'">
                        <div class=" mt-2">
                            <h6 style="font-weight:bolder;" class="cls required">Set Recurrence</h6>
                        </div>
                        <div class="d-flex">
                            <span class="mt-2">Day</span>
                            <input type="text" formControlName="dayOfMonth" class="form-control w-25 ml-2 mr-2"
                                placeholder="Enter">
                            <span class="mt-2">of every month</span> <br>
                        </div><br>

                    </div>
                    <div class=" mt-3 mb-2 " *ngIf="scheduleType==='YEARLY'">
                        <div class=" mt-2">
                            <h6 style="font-weight:bolder;">Set Recurrence</h6>
                        </div>
                        <div class="d-flex">
                            <span class="mt-2 mr-3">On</span>
                            <!-- <input type="text" class="form-control w-25 ml-2 mr-2" formControlName="monthOfYear"  > -->
                            <p-calendar placeholder="mm/dd" dateFormat="mm/dd" hourFormat="24"
                                formControlName="monthOfYear" showIcon="true"></p-calendar>
                            <span class="mt-2 ml-2">of every year</span>
                        </div>
                        <br>


                    </div>
                    <div class=" mt-3 mb-2 " *ngIf="scheduleType==='CUSTOM'">
                        <div class="row">
                            <div class="col-2">
                                <label for="custom">Repeats Every</label>
                            </div>
                            <div class="col-2">
                                <input type="text" formControlName="customInterval">
                            </div>
                            <div class="col-8">
                                <p-dropdown [options]="customRecurrence"
                                    (onChange)="customrecurrenceTypechanged($event)" placeholder="Select"
                                    optionLabel="name" optionValue="code" formControlName="customrecuurenceType">
                                </p-dropdown>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12" *ngIf="subrecurrenceType ==='WEEKLY'">
                                <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                    value={{day.day}} formControlName="customselectedWeeks"
                                    (onChange)="onChangeWeekDays(day,$event)">
                                </p-checkbox>

                            </div>
                        </div>
                        <!-- <div class="row mt-2" *ngIf="subrecurrenceType ==='MONTHLY'">

                            <span class="mt-2">On Day</span>
                            <input type="text" formControlName="customdayOfMonth" class="form-control w-25 ml-2 mr-2"
                                placeholder="Enter">

                            <span>of every Month</span>

                        </div> -->
                        <div class="row mt-2" *ngIf="subrecurrenceType ==='MONTHLY'">
                            <div class="col-2">
                                <span class="mt-2">On Day</span>
                            </div>
                            <div class="col-4">
                                <p-dropdown class="ml-2" [options]="weeKnumber" placeholder="Select" optionLabel="name"
                                    optionValue="code" formControlName="customWeekNo">
                                </p-dropdown>
                            </div>
                            <div class="col-4">
                                <p-dropdown class="ml-2" [options]="daysList" placeholder="Select" optionLabel="day"
                                    optionValue="day" formControlName="customday">
                                </p-dropdown>
                            </div>

                        </div>

                        <div class=" mt-3 mb-2 " *ngIf="subrecurrenceType==='YEARLY'">
                            <div class="d-flex">
                                <span class="mt-2 mr-3">On</span>
                                <p-calendar placeholder="mm/dd" dateFormat="mm/dd" hourFormat="24"
                                    formControlName="custommonthOfYear" showIcon="true"></p-calendar>
                                <span class="mt-2 ml-2">of every year</span>
                            </div>
                            <br>
                        </div>
                        <!-- <div class="row">
                              <p>Occurs Every  2 Days</p>
                          </div> -->

                    </div>

                </div>

                <div class="row mt-4 mb-3 divider"></div>

                <div class="row mt-5">
                    <h6 style="font-weight:bolder;">Details</h6>
                </div>
                <div class="row mt-2">
                    <div class="col-12 nopadding">
                        <label for="">Description/Agenda</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description"
                            rows="3"></textarea>
                        <!-- <small
                    *ngIf="meetingForm.get('description').touched && meetingForm.get('description').errors"
                    class="p-error">
                    <span *ngIf="meetingForm.get('description').errors['required']">Required</span>
                  </small> -->
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-3 nopadding recurrence">
                        <label>Client Name</label>
                        <p-dropdown [options]="petitionerCompanyList" formControlName="meetingCompanyId"
                            optionLabel="companyName" optionValue="id" placeholder="Select a Company"></p-dropdown>
                        <small
                            *ngIf="meetingForm.get('meetingCompanyId').touched && meetingForm.get('meetingCompanyId').errors"
                            class="p-error">
                            <span *ngIf="meetingForm.get('meetingCompanyId').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-5 ml-3">
                        <label>Petitioner Information(if Any)</label>
                        <input type="text" formControlName="meetingCompanyName">
                    </div>
                    <div class="col-2">
                    </div>

                </div>

                <!-- <div class="row mt-3">
                    <div class="col-4">
                        <label>Event Type</label>
                        <p-dropdown class="ml-2" [options]="eventOptions" placeholder="Select" optionLabel="type"
                            optionValue="value" formControlName="eventType">
                        </p-dropdown>
                    </div>
                </div> -->

                <div *ngIf="!this.meetingEditObj" class="row mt-5 mb-5">
                    <button type="button"  [disabled]="!this.meetingForm.valid"  class="btn btn-primary"
                        (click)="save()">Save</button>
                    <button type="button" class="btn btn-light ml-2" (click)="reset()">Cancel</button>


                </div>
                <div *ngIf="this.meetingEditObj" class="row mt-5 mb-5">
                    <button type="button" [disabled]="!this.meetingForm.valid" class="btn btn-primary"
                        (click)="update()">Update</button>
                    <button class="btn btn-primary ml-2" (click)="cancel()"> Cancel Meeeting</button>
                </div>

            </div>
        </div>
    </form>
</div>



<p-dialog header="Update Meeting" [(visible)]="updatepopUp" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-field-radiobutton">
        <p-radioButton name="city" value=0 [(ngModel)]="meetingUpdate" label="Update this event"></p-radioButton>
         <span> (Only Title , time and attendes can be updated)</span>
    </div>
    <div class="p-field-radiobutton">
        <p-radioButton name="city" value=1 [(ngModel)]="meetingUpdate" label="Update All series in this event">
        </p-radioButton> <span> (All series and all fields can be updated)</span>
    </div>

    <div class="row d-flex justify-content-end">

        <button type="button" [disabled]="meetingUpdate! == null" class="btn btn-primary"
            (click)="updatesave()">Ok</button>
        <button class="btn btn-primary ml-2" (click)="updatecancel()"> Cancel</button>

    </div>
</p-dialog>