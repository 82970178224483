import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  }

  handleYes() {
    this.dynamicDialogRef.close(true);
  }

  handleNo() {
    this.dynamicDialogRef.close(false);
  }

}
