<div class="fluid-container">
  <div class="main-container">
    <form #clientProjectDocumentForm="ngForm">
      <div class="row mb-3">
        <div class="col-md-10">
          <h6>{{ fileUploaderName }}</h6>
        </div>
        <div class="col-md-2 pull-right" *ngIf="projectId">
          <button pButton type="button" label="Sync" title="Sync" class="p-button-secondary"
            (click)="syncDocuments()"></button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-0 ">
          <file-upload-library style="width: 100%;" [fileUploadConfigurations]="fileUploadUSConfigurations"
            (fileListCount)="onfileListCount($event)">
          </file-upload-library>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="projectId && filesCount && filesCount > 0">
      <div class="col-12">
        <p-checkbox [(ngModel)]="updateMasterData" label="Add/Update data in the master data" [binary]="true"
          inputId="binary" class="pull-left p-checkbox client-project-documents-checkbox-holder">
        </p-checkbox>
        <button type="button" [disabled]="!updateMasterData" (click)="onUpdateMaster()"
          [ngClass]="{'disabled': !updateMasterData}" class="p-btn btn btn-primary mr-3">UPDATE</button>
      </div>
    </div>
  </div>
</div>
