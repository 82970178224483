<div class="fluid-container col-md-12">

    <!-- <div *ngFor="let item of listCategory"> -->

    <!-- </div> -->
    <div >
        <img class="img-responsive" src="assets/analysisLoaders.gif">
        <div class="container" *ngIf="category">
            <h5 class="review-index">{{categoryIndex}}/{{listCategory?.length}} </h5> 
            <h5 class="review-pa"> {{category.description}}</h5>
            <p-accordion>
                <p-accordionTab header="More Information" class="pa-accordian">
                    <p-tree [value]="category.children">
                        <ng-template let-node>
                            {{node.label}}
                        </ng-template>
                    </p-tree>
                </p-accordionTab>
            </p-accordion>
           <div class="row">
            <div class="col-md-6">
                <div *ngIf="categoryIndex <  listCategory?.length" class="col-md-12 text-right">
                    <button pButton pRipple type="button" label="NEXT" (click)="nextPACategoryLoad(categoryIndex)"
                    icon="pi pi-chevron-circle-right" class="p-button-outlined pa-next-btn"></button>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngIf="categoryIndex <  listCategory?.length" class="col-md-12 text-left">
                    <button pButton pRipple type="button" label="SKIP" (click)="finishPACategoryLoad()"
                    icon="pi pi-times-circle" class="p-button-outlined pa-next-btn"></button>
                </div>
            </div>
           </div>
            <div *ngIf="categoryIndex >=  listCategory?.length" class="col-md-12 text-center">
                <button pButton pRipple type="button" label="FINISH" 
                (click)="finishPACategoryLoad()" class="p-button-success pa-next-btn"></button>
            </div>
        </div>
    </div>
</div>