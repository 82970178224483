<div class="clearfix">
  <div class="row body-color" *ngIf="isLoggedIn">
    <div class="col-md-12 padding-x-5">
      <footer class="footer mt-4 d-flex align-items-center justify-content-between">
        <div class="d-inline-block">
          <ul class="nav" *ngIf="!hideContent && !isFBUser">

            <li class="nav-item">
              <a class="nav-link" [href]='termsPath'><img class="icon" src="../../../../assets/images/terms.svg">Terms &
                Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [href]='privacyPath'><img class="icon"
                  src="../../../../assets/images/privacy.svg">Privacy Policy</a>
            </li>
            <li class="nav-item" *ngIf="userType != 'Beneficiary'">
              <a class="nav-link" [href]='contactPath'><img class="icon"
                  src="../../../../assets/images/contact.svg">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="d-inline-block float-right">
          <label class="text-secondary mt-2 mr-3"
            *ngIf="footerNameString == true || domainName == 'imagility.co' || domainName == 'localhost:4200'">Imagility
            LLC &copy; {{this.currentYear}}</label>
          <label class="text-secondary mt-2 mr-3"
            *ngIf="footerNameString == false &&  domainName !== 'localhost:4200'">{{domainName}} &copy;
            {{this.currentYear}}</label>
        </div>
      </footer>
    </div>
  </div>
</div>