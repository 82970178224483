import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, SimpleChanges, Input, OnChanges } from '@angular/core';
import { NgForm, Validators, FormGroup } from '@angular/forms';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { PrimaryData, ReferenceLibrary, ImagilityBaseResponse, Tranings, acceptedFileTypes } from 'app-models';
import * as Roles from 'app-models';
import { EditCompleteTrainingDetailsService } from './edit-training-details.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import * as errorUtils from 'error-message-utility';
import { environment } from 'environment-configurations';
import { ReferenceLibraryService } from 'reference-library';
import { CacheCountryListService } from 'cache-country-list';
import { DeleteDialogLibraryComponent } from "delete-dialog-library";
import { Store } from '@ngrx/store';
import { Subject, Subscription } from "rxjs";
import { DialogService } from 'primeng/dynamicdialog';
import { EditCompleteEducationDetailService } from '../edit-complete-education-detail.service';
import { CustomErrorToastComponent } from 'custom-error-toast';

export const DeleteDialogConfigurations = {
  width: '50%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};

@Component({
  selector: 'edit-complete-training-details',
  templateUrl: './edit-training-details.component.html',
  styleUrls: ['./edit-training-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EditTrainingDetailsComponent implements OnInit, OnChanges {

  get editTrainingData() {
    return this.inputData;
  }

  @Input()
  set editTrainingData(value: any) {
    this.inputData = value;
  }

  showEditButton: boolean = true;
  currentItemDisplay: any = [];
  expandPanel: boolean = false;
  updateBenProfile: Boolean = true;
  selectedIndex: number = 0;
  topic;
  //trainingForm: NgForm;
  @ViewChild('trainingForm', { static: true }) trainingForm: NgForm;
  listAddedSubjects: string[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  subDisplayedColumns: string[] = [];
  listSubjects: MatTableDataSource<any>;
  training = new Tranings({});
  envPath: any;
  visible = true;
  selectable = true;
  removable = true;
  hasID: boolean = false;
  isLinear: boolean = true;
  today: Date = new Date();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  configurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENTRGDOC',
    entityId: 0,
    fileCategory: 'TCERT',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENTRGDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    familyId: null
  }

  expandedElement: null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  columns = [
    { columnDef: 'toggle', header: '', columnType: 'icon', customClass: '' },
    { columnDef: 'name', header: 'Name', columnType: 'text', customClass: '' },
    { columnDef: 'institution', header: 'Institution', columnType: 'text', customClass: '' },
    { columnDef: 'startDate', header: 'Start Date', columnType: 'text', customClass: '' },
    { columnDef: 'endDate', header: 'End Date', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  subjectsColumns = [
    { columnDef: 'subject', header: 'Added Topics', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];
  isListView = false;
  isTimeLineView = true;
  trainingsData = [];
  viewMode: boolean = false;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[] = [];
  dependentIdSubscription: Subscription
  dependentId: any;
  beneficiaryType: any;
  familyId: any;
  observableSubscription$ = new Subject();
  isSaveAsDraftDisabled: boolean = true;
  inputData: any;

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog,
    protected store: Store<any>,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private editTrainingDetailsService: EditCompleteTrainingDetailsService,
    private srv: EditCompleteEducationDetailService
  ) {

  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.editTrainingDetailsService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.editTrainingDetailsService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });
    this.getTrainings();
    this.showEditButton = !this.editTrainingDetailsService.checkValidity();
    this.editTrainingDetailsService.onLifeStorySubTabs.next();
    this.envPath = environment.docs;

    //IM-3066 - remove deleted file.
    this.editTrainingDetailsService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getTrainings();
      }
    });
    this.editTrainingDetailsService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getTrainings();
      }
    });

  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.subDisplayedColumns = this.subjectsColumns.map(c => c.columnDef);

  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.editTrainingData.currentValue) {
    //   this.inputData = changes.editTrainingData.currentValue;
    //   this.getTrainings();
    // }
  }


  formCheck(trainingForm) {
    this.trainingForm = trainingForm;
    this.formChangesSubscriptions[0] = trainingForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      if (trainingForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: "If you leave before saving, all changes made on the 'Training' form will be lost. Do you wish to continue?"
        }));
      }
      else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }
    });
    this.formChangesSubscriptions[1] = this.trainingForm.valueChanges?.pipe(takeUntil(this.observableSubscription$)).subscribe(formValues => {
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });
  }

  onNewTopicEnter(event) {
    this.store.dispatch(new MarkDirtyFormAction({
      dirty: true,
      message: "If you leave before saving, all changes made on the 'Topic Covered' section will be lost. Do you wish to continue?"
    }));
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    this.training = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    }
    this.listSubjects = new MatTableDataSource();
    this.configurations.listUploadedFiles = [];
    this.configurations.useCaseId = 0;
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId;
    this.configurations = Object.assign({}, this.configurations);
    this.hasID = false;
    this.isLinear = true;
    //this.trainingForm.resetForm();
    //this.trainingForm.form.reset();
  }

  getTrainings() {
    let url;
    if(this.inputData.caseType == 'I140') {
      url = this.srv.getTrainingInformation(this.inputData.superTaskId, this.inputData.subTaskId, true)
    } else if(this.inputData.caseType == 'Perm') {
      url = this.editTrainingDetailsService.getTrainingInformationPerm(this.inputData.caseId, true);
    }

    url.pipe(takeUntil(this.observableSubscription$)).subscribe((response: any) => {
      if (response?.data) {
        this.trainingsData = response.data.map((item) => {
          let _item = {...item};
          _item.subjects = item.subjects.split(',');
          _item.documents = item.documents.filter(e => !!e.fileLocation);
          _item.startDate = item.startDate ? moment(item.startDate, 'YYYY-MMM-DD').format('DD-MMM-YYYY') : '';
          _item.endDate = item.endDate ? moment(item.endDate, 'YYYY-MMM-DD').format('DD-MMM-YYYY') : '';

          return _item;
        });
        this.dataSource = new MatTableDataSource(this.trainingsData);
      }
    });
  }

  handleEdit(input) {
    if (this.expandPanel !== true) {
      this.expandPanel = true;
      this.training = {
        id: input.id,
        name: input.name,
        subjects: input.subjects,
        institution: input.institution,
        startDate: input.startDate ? moment(input.startDate).toISOString() : '',
        endDate: input.endDate ? moment(input.endDate).toISOString() : ''
      };
      const obj = [];
      if (input.subjects) {
        input.subjects.forEach((item) => {
          obj.push({ 'subject': item });
        });
      }
      this.hasID = true;
      this.isLinear = false;
      this.listSubjects = new MatTableDataSource(obj);
      this.training.subjects = input.subjects && input.subjects.length > 0 ? input.subjects.join() : "";
      this.configurations.entityId = input.id;
      this.configurations.familyId = this.familyId;
      this.configurations.listUploadedFiles = input.documents ? this.configurations.listUploadedFiles.concat(input.documents) : [];
      this.configurations = Object.assign({}, this.configurations);
    }
  }

  handleDelete(input, trainingForm) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.srv.deletei140TrainingDetails(this.inputData.superTaskId, this.inputData.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          if (this.training.id === input.id) {
            this.resetForm(trainingForm);
          }
          this.toastr.success(response.message, 'Success');
          this.getTrainings();
        })
      }
    });
  }

  downloadDoc(doc) {
    this.editTrainingDetailsService.downloadDocuments(environment.docs + doc.fileLocation.substring(23), doc.fileLocation.substring(23));
  }

  resetForm(trainingForm: NgForm) {
    trainingForm.resetForm();
    trainingForm.form.reset();
    this.training = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    }
    this.listSubjects = new MatTableDataSource();
    this.configurations.listUploadedFiles = [];
    this.configurations.useCaseId = 0;
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId;
    this.configurations = Object.assign({}, this.configurations);
    this.hasID = false;
    this.isLinear = true;
  }
  closeForm(f) {
    // console.log(f);
    this.resetForm(f);
    this.toggleExpansion();
  }

  saveTrianing(trainingForm, isDraft: boolean = false) {
    const payload = {
      id: this.training.id || 0,
      name: this.training.name,
      subjects: this.training.subjects,
      institution: this.training.institution,
      startDate: this.training.startDate ? moment(this.training.startDate).format('YYYY-MM-DD') : null,
      endDate: this.training.endDate ? moment(this.training.endDate).format('YYYY-MM-DD') : null,
      isSaveAsDraft: isDraft
    };

    let url;
    if(this.inputData.caseType == 'I140') {
      url = this.srv.savegcI140TrainingDetails(this.inputData.caseId, payload, 'TRAINING');
    } else if(this.inputData.caseType == 'Perm') {
      url = this.srv.savePermTrainingDetails(this.inputData.caseId, payload, 'TRAINING');
    }

    url.pipe(takeUntil(this.observableSubscription$)).subscribe((response: any) => {
      if (response.status === 200) {
        this.training.id = response.data[0].id;
        this.toastr.success(response.message, 'Success');
        response.data.forEach(element => {
          this.configurations.entityId = element.id;
          this.configurations = Object.assign({}, this.configurations);
        });
        this.getTrainings();
        this.hasID = true;
        this.isLinear = false;
        trainingForm.reset(trainingForm.value)
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
  }

  redirectToDocs() {
    this.selectedIndex = 1;
  }

  handleTabChange(tab) {
    this.selectedIndex = tab.index;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add subject
    if ((value || '').trim()) {
      this.listAddedSubjects.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/,/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.listAddedSubjects.push(value.trim()); //Push if valid
        }
      })
  }

  remove(fruit: string): void {
    const index = this.listAddedSubjects.indexOf(fruit);
    if (index >= 0) {
      this.listAddedSubjects.splice(index, 1);
    }
  }

  addSubjects() {
    if (!this.training.id) {
      this.toastr.info('Please add the training details first, then proceed to topics');
      return;
    }
    const payload = {
      id: this.training.id || 0,
      name: this.training.name,
      subjects: this.training.subjects,
      institution: this.training.institution,
      startDate: this.training.startDate ? moment(this.training.startDate).format('YYYY-MM-DD') : null,
      endDate: this.training.endDate ? moment(this.training.endDate).format('YYYY-MM-DD') : null,
    };
    const subs: string[] = [];
    if (this.listSubjects) {
      this.listSubjects.data.forEach((item) => {
        subs.push(item.subject);
      });
    }
    payload.subjects = subs.concat(this.listAddedSubjects).join();
    this.srv.savegcI140TrainingDetails(this.inputData.caseId, payload, 'training').pipe(takeUntil(this.observableSubscription$)).subscribe((response: any) => {
      if (response.status === 200) {
        this.listAddedSubjects = [];
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
        response.data.forEach(element => {
          this.configurations.entityId = element.id;
          this.configurations.familyId = this.familyId;
          this.configurations = Object.assign({}, this.configurations);
        });
        const obj = [];
        if (response.data[0]['subjects']) {
          response.data[0]['subjects'].split(',').map((item) => {
            obj.push({ 'subject': item });
          })
        }
        this.listSubjects = new MatTableDataSource(obj);
        this.getTrainings();
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
  }

  deleteSubject(index) {
    this.listSubjects.data.splice(index, 1);
    this.addSubjects();
  }

  fileUploadSuccess() {
    // this.getTrainings();
  }


  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }


  saveAsDraft(event) {
    this.saveTrianing(this.trainingForm, event);
  }

  ngOnDestroy() {
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(element => { element.unsubscribe(); });
    }
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

}
