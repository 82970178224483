import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class TaskUtilityService {

  taskId: number;
  companyId: number;
  taskDetailsSubject: BehaviorSubject<any>;
  taskDetailsObserver: Observable<any>;

  constructor() {
    this.taskId = 0;
    this.companyId = 0;
    this.taskDetailsSubject = new BehaviorSubject<any>(null);
    this.taskDetailsObserver = this.taskDetailsSubject.asObservable();
  }

  initialiseTaskDetailsObs() {
    this.taskDetailsSubject = new BehaviorSubject<any>(null);
    this.taskDetailsObserver = this.taskDetailsSubject.asObservable();
  }

  setTaskDetails(taskDetails) {
    this.taskDetailsSubject.next(taskDetails);
  }

  setTaskId(id: number) {
    this.taskId = id;
  }

  getTaskId() {
    return this.taskId;
  }

  setCompanyId(id: number) {
    this.companyId = id;
  }

  getCompanyId() {
    return this.companyId;
  }
}
