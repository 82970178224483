<div class="fluid-container">
    <div class="row pt-4 d-flex align-items-center">
        <div class="col-md-8 step-title p-0">
            <div class="w-100 d-flex">
                <h5 class="mb-0">Provide Beneficiary Documents</h5>
                <a  (click)="updateImmigration()" class="ml-3 pt-1">Update Documents</a>
            </div>
            <div class="w-100 pt-1">
                <p class="mb-0">Sync & Upload Supporting Beneficiary Documents</p>
            </div>
        </div>

        <div class="col-md-4 editBtn headerSubPortion px-0"> 
            <ul>
                <li class="col-auto px-0 firstPortion"> 
                    <gc-status-update [stepDetails]="stepDetails" type="EDIT"></gc-status-update>
                </li>
                <li class="col-auto pr-0 pl-2 secondPortion">
                    <lib-maximize-dialog-library [configData]="{'layoutCode':stepDetails?.stepActionList[0].layoutCode}"></lib-maximize-dialog-library>
                    <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 p-0">
            <lib-assigning-task-library [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
            </lib-assigning-task-library>
    <!-- <lib-assigning-task-library [stepDetails]="stepDetails"
    (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
    </lib-assigning-task-library> -->
        </div>
    </div>
    <p-divider></p-divider>
    <div class="main-body footer-adjust">
        <div class="row col-md-12 pt-2">
            <div class="p-0 col-md-8">
                <h5 class="sub-title">Beneficiary  Documents</h5>
            </div>
            <div class="p-0 col-md-4">
                <button pButton type="button" label="SYNC FROM PROFILE" class="p-button-outlined  pull-right"
                    [disabled]="disabledControls" (click)="syncBeneficiaryProfileData()"></button>
            </div>
        </div>
        <div class="row pt-3">
            <div class="courseList col-md-12 p-0">
                <p-card class="courses-card">
                    <div class="row document-header">
                        <div class="col-md-5">
                            <h6>Document Type</h6>
                        </div>
                        <div class="col-md-5">
                            <h6>Document Name</h6>
                        </div>
                        <div class="col-md-2">
                            <h6>Action</h6>
                        </div>
                    </div>
                    <div class="row document  courses-title d-flex align-items-center"
                        *ngFor="let item of immigrationDocuments; let i = index">
                        <div class="col-md-5">
                            <span>
                                {{ item?.fileCategory.code === 'OTHER' && item.isPresent ? item?.fileCtaegoryOtherName : item?.fileCategory.name }}
                            </span>
                        </div>
                        <div class="col-md-5">
                            <span *ngIf="item.isPresent">
                                {{ item.fileName}}
                            </span>
                            <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                                <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                            </span>
                        </div>
                        <div class="col-md-2 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
                            <div class="mr-2 padding0">
                                <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                    [disabled]="!item.fileLocation" (click)="openDoc(item)"
                                    class="p-button-text p-button-rounded"></button>
                            </div>
                            <div class="mr-2 padding0">
                                <button *ngIf="userType !== 'Beneficiary'" pButton pRipple type="button"
                                    style=" color: #75777A;" icon="pi pi-directions-alt icons-arrow"
                                    class="p-button-rounded p-button-text" pTooltip="Send Back"
                                    [disabled]="disabledControls" (click)="handleSendBack(item)"></button>
                            </div>
                            <div class="mr-2 padding0">
                                <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                    (click)="deleteImmigrationDocuments(item)" [disabled]="disabledControls"
                                    class="p-button-text p-button-rounded"></button>
                            </div>
                        </div>
                        <div class="col-md-2" *ngIf="!item?.isPresent">
                            <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Notify" type="button"
                                icon="pi pi-bell" [disabled]="disabledControls" style=" color: #75777A;"
                                (click)="handleNotification(item)" class="p-button-text p-button-rounded"></button>

                            <!-- <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Not Applicable"
                                type="button" icon="pi pi-ban" [disabled]="disabledControls"
                                class="p-button-text p-button-rounded"></button> -->
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="row col-md-12 ">
                <p style="color: #626262; font-size: 13px;">To upload any beneficiary document please upload in Profile
                    section. If you delete any document , It will removed from this screen only.</p>
            </div>
        </div>
        <div class="col-md-12">
            <p-divider></p-divider>
        </div>
        <div class="row col-md-12 pt-4">
            <div class="p-0 col-md-12">
                <h5 class="sub-title">Other Documents</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 p-0  my-3">
                <i140-file-upload style="width: 100%;"
                    [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations" [isProvideBenificary]="true">
                </i140-file-upload>
            </div>
        </div>
        <div class="row">
            <lib-custom-questionnaire [stepId]="stepId" [taskId]="stepDetails.taskId" [viewQuestions]="false"
            [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false">
            </lib-custom-questionnaire>
              </div>
    </div>
    <p-divider></p-divider>
    <div class="row pt-3">
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton"></gc-status-update>
        </div>
    </div>
</div>