<div class="container-fluid padding-top-bottom-15 new-dashboard">
  <div class="row margin-bottom-15">
    <div class="row d-block">
      <div class="nav icn home" (click)="backtoDashboard()">Home/Calendar</div>
      <div class="calendar">
        <h4 *ngIf="!otherUserName" class="text-capitalize">
          {{ currentUserName }}'s Calendar
        </h4>
        <h4 *ngIf="otherUserName" class="text-capitalize">
          {{ otherUserName }}'s Calendar
        </h4>
      </div>
    </div>
  </div>
</div>
<div class="card mb-2">
  <div class="d-flex flex-row" style="padding: 5px 10px;margin-left: 13px;">
    <a class="border mr-2 d-flex align-items-center rounded px-4 py-2 justify-content-between"
      [routerLink]="[navUrl+'scheduling']">Calendar <i class="pi pi-calendar px-2" style=" font-size: 22px; "></i></a>
  
    <a [routerLink]="[navUrl+'email-client']" class="border mr-2 d-flex align-items-center rounded px-4 py-2 justify-content-between">Mail
      <i class="pi pi-envelope px-2" style=" font-size: 22px; "></i></a>
  </div>
</div>
<div class="d-flex">
  <div class="col-12 border px-0">
    <div class="row m-0" id="createNew">
      <p-dropdown class="text-capitalize" #calItem class="pull-right" placeholder="CREATE NEW"
        [options]="calendarItemTypes" [(ngModel)]="selectedCalendarItem" optionLabel="name"
        (onChange)="navigateToTabView(calItem)"></p-dropdown>
    </div>
    <div class="row margin-top-bottom-15 bg-white">
      <div class="col-2 px-0" style="height: 100% !important">
        <div style="padding-left: 2px" class="calendar-menu-options">
          <p-accordion #accord [multiple]="true" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <div>
              <p-accordionTab [selected]="true">
                <ng-template pTemplate="header">My Calendar</ng-template>
                <ng-template pTemplate="content" class="border-top-0 border-left-0 border-right-0 border-bottom-1">
                  <div style="padding: 5px 0 10px 5px">
                    <div class="mb-2 pl-1" *ngFor="let item of calendarItemCheckboxes; let i = index">
                      <p-checkbox name="groupname" [disabled]="disableFilters" [ngClass]="item.name" [id]="'itm' + i"
                        [value]="item.id" [label]="item.name" [(ngModel)]="myCals"
                        (onChange)="onCheckboxChange($event, item)">
                      </p-checkbox>
                    </div>
                  </div>
                </ng-template>
              </p-accordionTab>
            </div>

            <!-- <p-accordionTab header="My Other Calendars" [selected]="true">
                      Content 2
                    </p-accordionTab> -->
            <p-accordionTab [selected]="true">
              <ng-template pTemplate="header">Peoples Calendar</ng-template>
              <ng-template pTemplate="content" class="border-top-0 border-left-0 border-right-0 border-bottom-1">
                <form [formGroup]="mform">
                  <input formControlName="name" style="border: none !important" type="text" pInputText
                    placeholder="Start typing name" [(ngModel)]="searchPeople" />
                </form>
                <div class="mb-2 mt-1 pl-1" *ngFor="let item of otherPeoples">
                  <p-checkbox name="groupname" class="other" single [value]="item.userId"
                    [label]="item.firstName + ' ' + item.lastName" (onChange)="getCalendar($event, item)">
                  </p-checkbox>
                </div>
                <div class="mb-2 pl-1" *ngIf="empDetailStatus == 204">
                  <small>
                    {{ empDetailMessage }}
                  </small>
                </div>
              </ng-template>
            </p-accordionTab>
            <p-accordionTab [selected]="true">
              <ng-template pTemplate="header">Add Calendars</ng-template>
              <ng-template pTemplate="content" class="border-top-0 border-left-0 border-right-0 border-bottom-1">
                <div class="d-flex flex-column justify-content-start align-items-start">
                  <button class="btn btn-link pl-1 mb-2" type="button" (click)="addNewGoogleAccount()"
                    *ngIf="!userInfo?.info">
                    <img src="../../../../assets/btn_google_signin_dark_pressed_web@2x.png" style=" min-width: 182px; width: 182px;">
                  </button>
                  <button class="btn btn-link pl-1 mb-2" type="button" *ngIf="!loginDisplay" (click)="loginOutlook()">
                    <img src="../../../../assets/ms-symbollockup_signin_dark.png"  style=" min-width: 198px; width: 198px;">
                  </button>
                </div>
                <div class="authenticatedProfileData" *ngIf="userInfo">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center justify-content-between">
                      <img src="../../../../assets/google-calendar.svg" class="img-style" style="width: 30px;" />
                      <small class="mb-0 ml-1 d-flex flex-column">
                        {{ userInfo?.info?.name }}
                        <small>{{ userInfo?.info?.email }}</small>
                      </small>
                    </div>
                    <div class="d-flex align-items-center justify-content-around">
                      <i class="pi pi-refresh pointer mx-1" title="Refresh" (click)="ngOnInit()"></i>
                      <i class="pi pi-cog pointer" title="Settings" (click)="googlemenu.toggle($event)"></i>
                    </div>
                  </div>
                  <p-menu #googlemenu appendTo="body" [model]="googleSettingsMenu" [popup]="true"></p-menu>
                </div>
                <div class="authenticatedProfileData" *ngIf="profile">
                  <div class="d-flex align-items-center justify-content-between" *ngIf="loginDisplay">
                    <div class="d-flex align-items-center justify-content-between">
                      <img src="../../../../assets/microsoft-outlook.svg" class="img-style" style="width: 30px;" />
                      <small class="mb-0 ml-1 d-flex flex-column">
                        {{ profile?.displayName }}
                        <small>{{ profile?.mail }}</small>
                      </small>
                    </div>
                    <div class="d-flex align-items-center justify-content-around">
                      <!-- <i class="pi pi-plus pointer mx-1" (click)="addOutlookEvent()"></i> -->
                      <i class="pi pi-refresh pointer mx-1" title="Refresh" (click)="ngOnInit()"></i>
                      <i class="pi pi-cog pointer" title="Settings" (click)="menu.toggle($event)"></i>
                    </div>
                  </div>
                  <p-menu #menu appendTo="body" [model]="outlookSettingsMenu" [popup]="true"></p-menu>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <div class="col-10 py-2">
        <!-- <p (click)="afterCheck()">check</p> -->
        <full-calendar style="height: 100% !important;" #userCalendar
          [options]="calendarOptions"></full-calendar>
      </div>
    </div>

    <!-- <div id="d-arrow">
              <i class="fa fa-angle-down fa-2x"></i>
            </div>
            <div id="cal">
              <mat-calendar id="cal1" [(selected)]="selected" (selectedChange)="dateChanged($event)"
                (monthSelected)="monthSelected($event)" (yearSelected)="yearSelected($event)">
              </mat-calendar>
            </div> -->
  </div>
  <p-dialog header="Do you want to Mark task complete" [(visible)]="markTaskcompletePopup" [modal]="true"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div class="row justify-content-center d-flex">
      <button type="button" class="btn btn-primary" (click)="taskcomplete('yes')">
        Yes
      </button>
      <button type="button" class="btn btn-primary ml-4" (click)="taskcomplete('no')">
        No
      </button>
    </div>
  </p-dialog>
  <p-dialog header="{{ this.taskdetails }}" [(visible)]="TaskcompletePopup" [modal]="true" [style]="{ width: '50vw' }"
    [draggable]="false" [resizable]="false">
    <div class="row divider mt-3"></div>
    <div class="row mt-3">
      <div class="col-1 mt-2">
        <i class="pi pi-clock" style="font-size: 2rem"></i>
      </div>
      <div class="col-3 task">
        <div>{{ this.taskDate }}</div>
        <div>{{ this.taskTime }}</div>
      </div>
      <div class="col-8"></div>
    </div>
    <div class="row divider mt-3"></div>
    <div class="row mt-3 justify-content-end d-flex">
      <button type="button" class="btn btn-primary" (click)="marktaskcomplete()">
        Mark Complete
      </button>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="outlookMeetingModal" (onHide)="closeOutlookMeetingModal()" [modal]="true" [style]="{ width: '70vw' }"
    class="eventDetails outlookMeetingModal" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <div *ngIf="isNewOutlookTitle">Add New Outlook Event</div>
      <div *ngIf="!isNewOutlookTitle">{{ clickedItemType?.subject }} </div>
    </ng-template>
    <ng-template pTemplate="content" class="px-2">
      <div *ngIf="!editOutlookModal">
        <div class="card my-2">
          <div class="d-flex align-items-center justify-content-end">
            <div class="p-buttonset col" *ngIf="clickedItemType?.organizer?.emailAddress?.address !== profile?.mail">
              <button class="p-button-text p-button-success" type="button" (click)="going('accept')" pButton pRipple
                label="Yes" icon="pi pi-check"></button>
              <button class="p-button-text p-button-help" type="button" (click)="going('tentativelyAccept')" pButton
                pRipple label="Maybe" icon="pi pi-question"></button>
              <button class="p-button-text p-button-danger" type="button" (click)="going('decline')" pButton pRipple
                label="No" icon="pi pi-times"></button>
            </div>
            <div>
              <button class="p-button-text p-button-primary" pButton pRipple label="Forward"
                (click)="forwardModalShow = !forwardModalShow"></button>
              <button class="p-button-text p-button-primary" pButton pRipple label="Edit"
                (click)="getMeetingDetails()"></button>
            </div>
          </div>
        </div>
        <div class="card my-2 p-2" *ngIf="goingSubmitShow">
          <p>{{statusUpdateType==='accept'?"Yes, I'll attend":statusUpdateType==='decline'?"No I won't attend":"I might
            attend"}}</p>
          <div class="border rounded p-2">
            <textarea placeholder="Add a message (optional)" class="p-2 border-0 w-100 h-100"
              [(ngModel)]="goingcomment"></textarea>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button class="p-button-text p-button-success" pButton pRipple label="Send"
              (click)="goingSubmit(statusUpdateType,eventDetailsRaw.id)" icon="pi pi-send"></button>
            <button class="p-button-text p-button-help" pButton pRipple label="Cancel"
              (click)="goingSubmitShow = !goingSubmitShow" icon="pi pi-trash"></button>
          </div>
        </div>
        <div class="card my-2 p-2" *ngIf="forwardModalShow">
          <p>Forwarding event</p>
          <div class="border rounded p-2">
            <div class="d-flex align-items-center justify-content-end border-bottom">
              <label class="mb-0">To</label>
              <input type="text" [(ngModel)]="recipients" class="border-0" (keyup)="validateEmail(recipients)">
            </div>
            <textarea placeholder="Add a message (optional)" class="p-2 border-0 w-100 h-100"
              [(ngModel)]="comment"></textarea>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <div class="spinner-border text-primary" role="status" *ngIf="forwardMeetinginProgress"></div>
            <span *ngIf="!recipients || invalidEmails.length>0" class="text-warning"> {{invalidEmails.length>0?"Invalid
              email found":"To field should not be empty"}}</span>
            <button class="p-button-text p-button-success" pButton pRipple label="Send"
              [disabled]="!recipients || invalidEmails.length>0" *ngIf="!forwardMeetinginProgress"
              (click)="forwardMeeting(eventDetailsRaw.id)" icon="pi pi-send"></button>
            <button class="p-button-text p-button-help" pButton pRipple label="Cancel" (click)="cancelforwardMeeting()"
              icon="pi pi-trash"></button>
          </div>
        </div>
        <div class="card d-flex flex-column">
          <div class="details-row d-flex align-items-center border-bottom py-2">
            <i class="pi pi-user m-2 px-2"></i>
            <div>From: {{clickedItemType?.organizer?.emailAddress?.name}}
              &lt;{{clickedItemType?.organizer?.emailAddress?.address}}&gt;</div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2">
            <i class="pi pi-clock m-2 px-2"></i>
            <div *ngIf="eventDetailsRaw._def.extendedProps.type !=='singleInstance'">{{eventDetailsRaw?.start | date:'EEE,
              MMMM d, y'}} {{clickedItemType.originalstart | date:'h:mm a'}} - {{clickedItemType.originalend | date:'h:mm a'}}</div>
            <div *ngIf="eventDetailsRaw._def.extendedProps.type === 'singleInstance'">From {{eventDetailsRaw?.start |
              date:'EEE, MMMM d, y, h:mm:ss a'}} to
              {{eventDetailsRaw?.end?eventDetailsRaw?.end:eventDetailsRaw._def.extendedProps.originalend | date:'EEE, MMMM d,
              y, h:mm:ss a'}}</div>
  
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.location">
            <i class="pi pi-map-marker m-2 px-2"></i>
            <div><a [href]="clickedItemType?.location?.displayName" target="_blank">
                {{clickedItemType?.location?.displayName}}</a></div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2">
            <i class="pi pi-thumbs-up m-2 px-2"></i>
            <div>{{'You are the '+clickedItemType?.responseStatus?.response+' for this meeting'}}</div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.isReminderOn">
            <i class="pi pi-bell m-2 px-2"></i>
            <div> {{reminderBeforeText}}</div>
          </div>
          <div class="details-row d-flex align-items-start r border-bottom py-2" *ngIf="clickedItemType?.isReminderOn">
            <i class="pi pi-envelope m-2 px-2"></i>
            <div [innerHtml]="clickedItemType?.body.content">
            </div>
          </div>
  
        </div>
      </div>
      <div *ngIf="editOutlookModal">
        <app-edit-event [meetingId]="meetingId" [meetingBy]="'microsoft'" (cancelEvent)="onCancelEvent($event)"></app-edit-event>
      </div>
    </ng-template>
  </p-dialog>
 

  <p-dialog [(visible)]="googleNewMeetingModal" (onHide)="closeGoogleMeetingModal()" [modal]="true" [style]="{ width: '70vw' }"
    class="eventDetails googleMeetingModal" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <div *ngIf="isNewGoogleTitle">Add New Google Event</div>
      <div *ngIf="!isNewGoogleTitle">{{ clickedItemType?.subject }} </div>
    </ng-template>
    <ng-template pTemplate="content" class="px-2">
      <div *ngIf="!editGoogleModal">
        <div class="card my-2">
          <div class="d-flex align-items-center justify-content-end">
            <div class="p-buttonset col" *ngIf="clickedItemType?.organizer?.emailAddress?.address !== profile?.mail">
              <button class="p-button-text p-button-success" type="button" (click)="going('accept')" pButton pRipple
                label="Yes" icon="pi pi-check"></button>
              <button class="p-button-text p-button-help" type="button" (click)="going('tentativelyAccept')" pButton
                pRipple label="Maybe" icon="pi pi-question"></button>
              <button class="p-button-text p-button-danger" type="button" (click)="going('decline')" pButton pRipple
                label="No" icon="pi pi-times"></button>
            </div>
            <div>
              <button class="p-button-text p-button-primary" pButton pRipple label="Forward"
                (click)="forwardModalShow = !forwardModalShow"></button>
              <button class="p-button-text p-button-primary" pButton pRipple label="Edit"
                (click)="getMeetingDetails()"></button>
            </div>
          </div>
        </div>
        <div class="card my-2 p-2" *ngIf="goingSubmitShow">
          <p>{{statusUpdateType==='accept'?"Yes, I'll attend":statusUpdateType==='decline'?"No I won't attend":"I might
            attend"}}</p>
          <div class="border rounded p-2">
            <textarea placeholder="Add a message (optional)" class="p-2 border-0 w-100 h-100"
              [(ngModel)]="goingcomment"></textarea>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button class="p-button-text p-button-success" pButton pRipple label="Send"
              (click)="goingSubmit(statusUpdateType,eventDetailsRaw.id)" icon="pi pi-send"></button>
            <button class="p-button-text p-button-help" pButton pRipple label="Cancel"
              (click)="goingSubmitShow = !goingSubmitShow" icon="pi pi-trash"></button>
          </div>
        </div>
        <div class="card my-2 p-2" *ngIf="forwardModalShow">
          <p>Forwarding event</p>
          <div class="border rounded p-2">
            <div class="d-flex align-items-center justify-content-end border-bottom">
              <label class="mb-0">To</label>
              <input type="text" [(ngModel)]="recipients" class="border-0" (keyup)="validateEmail(recipients)">
            </div>
            <textarea placeholder="Add a message (optional)" class="p-2 border-0 w-100 h-100"
              [(ngModel)]="comment"></textarea>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <div class="spinner-border text-primary" role="status" *ngIf="forwardMeetinginProgress"></div>
            <span *ngIf="!recipients || invalidEmails.length>0" class="text-warning"> {{invalidEmails.length>0?"Invalid
              email found":"To field should not be empty"}}</span>
            <button class="p-button-text p-button-success" pButton pRipple label="Send"
              [disabled]="!recipients || invalidEmails.length>0" *ngIf="!forwardMeetinginProgress"
              (click)="forwardMeeting(eventDetailsRaw.id)" icon="pi pi-send"></button>
            <button class="p-button-text p-button-help" pButton pRipple label="Cancel" (click)="cancelforwardMeeting()"
              icon="pi pi-trash"></button>
          </div>
        </div>
        <div class="card d-flex flex-column">
          <div class="details-row d-flex align-items-center border-bottom py-2">
            <i class="pi pi-user m-2 px-2"></i>
            <div>From: {{clickedItemType?.organizer?.emailAddress?.name}}
              &lt;{{clickedItemType?.organizer?.emailAddress?.address}}&gt;</div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2">
            <i class="pi pi-clock m-2 px-2"></i>
            <div *ngIf="eventDetailsRaw._def.extendedProps.type !=='singleInstance'">{{eventDetailsRaw?.start | date:'EEE,
              MMMM d, y, h:mm:ss a'}} - {{eventDetailsRaw?.end
              | date:'shortTime'}}</div>
            <div *ngIf="eventDetailsRaw._def.extendedProps.type === 'singleInstance'">From {{eventDetailsRaw?.start |
              date:'EEE, MMMM d, y, h:mm:ss a'}} to
              {{eventDetailsRaw?.end?eventDetailsRaw?.end:eventDetailsRaw._def.extendedProps.originalend | date:'EEE, MMMM d,
              y, h:mm:ss a'}}</div>
  
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.location">
            <i class="pi pi-map-marker m-2 px-2"></i>
            <div><a [href]="clickedItemType?.location?.displayName" target="_blank">
                {{clickedItemType?.location?.displayName}}</a></div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2">
            <i class="pi pi-thumbs-up m-2 px-2"></i>
            <div>{{'You are the '+clickedItemType?.responseStatus?.response+' for this meeting'}}</div>
          </div>
  
          <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.isReminderOn">
            <i class="pi pi-bell m-2 px-2"></i>
            <div> {{reminderBeforeText}}</div>
          </div>
          <div class="details-row d-flex align-items-start r border-bottom py-2" *ngIf="clickedItemType?.isReminderOn">
            <i class="pi pi-envelope m-2 px-2"></i>
            <div [innerHtml]="clickedItemType?.body.content">
            </div>
          </div>
  
        </div>
      </div>
      <div *ngIf="editGoogleModal">
        <app-edit-event [meetingId]="meetingId" [meetingBy]="'google'" (cancelEvent)="onCancelEvent($event)"></app-edit-event>
      </div>
    </ng-template>
  </p-dialog>
 
  <p-dialog [(visible)]="googleMeetingModal" [modal]="true" [style]="{ width: '50vw' }" class="eventDetails"
    [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      {{ clickedItemType?.summary }}
    </ng-template>
    <div class="card my-2">
      <div class="d-flex align-items-center justify-content-end">
        <div class="p-buttonset col">
          <!-- <button class="p-button-text p-button-success" type="button" (click)="going('accept')" pButton pRipple
            label="Yes" icon="pi pi-check"></button>
          <button class="p-button-text p-button-help" type="button" (click)="going('tentativelyAccept')" pButton
            pRipple label="Maybe" icon="pi pi-question"></button>
          <button class="p-button-text p-button-danger" type="button" (click)="going('decline')" pButton pRipple
            label="No" icon="pi pi-times"></button> -->
        </div>
        <div>
          <button class="p-button-text p-button-primary" pButton pRipple label="Forward"
            (click)="forwardGmeetingShow = !forwardGmeetingShow"></button>
          <button class="p-button-text p-button-danger" pButton pRipple label="Delete"
            (click)="deleteEventById(eventDetailsRaw)"></button>
        </div>
      </div>
    </div>
    <ng-template pTemplate="content" class="p-2">
      <div class="card d-flex flex-column">

        <div class="card my-2 p-2" *ngIf="forwardGmeetingShow">
          <p>Forwarding event</p>
          <div class="border rounded p-2">
            <div class="d-flex align-items-center justify-content-end border-bottom">
              <label class="mb-0">To</label>
              <input type="text" [(ngModel)]="recipientsG" class="border-0" (keyup)="validateEmail(recipientsG)">
            </div>
            <textarea placeholder="Add a message (optional)" class="p-2 border-0 w-100 h-100"
              [(ngModel)]="commentG"></textarea>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <div class="spinner-border text-primary" role="status" *ngIf="forwardGMeetinginProgress"></div>
            <span *ngIf="!recipientsG || invalidEmails.length>0" class="text-warning"> {{invalidEmails.length>0?"Invalid email found":"To field should not be empty"}}</span>
            <button class="p-button-text p-button-success" pButton pRipple label="Send" [disabled]="!recipientsG || invalidEmails.length>0" *ngIf="!forwardGMeetinginProgress"
              (click)="forwardGoogleMeeting(eventDetailsRaw)" icon="pi pi-send"></button>
            <button class="p-button-text p-button-help" pButton pRipple label="Cancel"
              (click)="cancelforwardGoogleMeeting()" icon="pi pi-trash"></button>
          </div>
        </div>

        <div class="details-row d-flex align-items-center border-bottom py-2">
          <i class="pi pi-user m-2 px-2"></i>
          <div>From: {{clickedItemType?.creator?.email}}</div>
        </div>


        <div class="details-row d-flex align-items-center r border-bottom py-2">
          <i class="pi pi-clock m-2 px-2"></i>
          <div *ngIf="!clickedItemType.daysInWeek && !eventDetailsRaw.allDay">{{eventDetailsRaw?.start | date:'EEEE, d, MMMM ⋅ h:mm a'}} - {{eventDetailsRaw?.end?eventDetailsRaw.end:clickedItemType.originalend | date:'h:mm a'}}</div>
          <div *ngIf="!clickedItemType.daysInWeek && eventDetailsRaw.allDay">{{eventDetailsRaw?.start | date:'EEEE, d MMMM'}}</div>
          <div class="d-flex flex-column" *ngIf="clickedItemType.daysInWeek && !eventDetailsRaw.allDay">
            <div>{{eventDetailsRaw?.start | date:'EEEE, d, MMMM ⋅ h:mm a'}} - {{eventDetailsRaw?.end?eventDetailsRaw.end:clickedItemType.originalend | date:'h:mm a'}}</div>
            <div>Weekly on {{clickedItemType.daysInWeek}}</div>
          </div>
          <div class="d-flex flex-column" *ngIf="clickedItemType.daysInWeek && eventDetailsRaw.allDay">
            <div>{{eventDetailsRaw?.start | date:'EEEE, d, MMMM'}}</div>
            <div>Weekly on {{clickedItemType.daysInWeek}}</div>
          </div>
        </div>

        <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.hangoutLink">
          <i class="pi pi-map-marker m-2 px-2"></i>
          <div><a [href]="clickedItemType?.hangoutLink" target="_blank"> {{clickedItemType?.hangoutLink}}</a></div>
        </div>

        <div class="details-row d-flex align-items-center r border-bottom py-2">
          <i class="pi pi-map-marker m-2 px-2"></i>
          <div class="d-flex flex-column">

            <div>{{clickedItemType?.organizer?.displayName}}</div>
            <small>Created By: {{clickedItemType.creator.email}}</small>
          </div>
        </div>
        <div class="details-row d-flex align-items-center r border-bottom py-2" *ngIf="clickedItemType?.description">
          <div style="margin-bottom: 2rem;white-space:pre-wrap;" [innerHtml]="getTrimmedData(clickedItemType?.description)"></div>
        </div>


      </div>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="addOutlookMeetingModal" [modal]="true" [style]="{ width: '50vw' }" class="eventDetails addOutlookMeetingModal"
    [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      {{ clickedItemType?.summary }}
    </ng-template>
    <ng-template pTemplate="content" class="p-2">
      <div class="card d-flex flex-column">

      </div>
    </ng-template>
  </p-dialog>
  <!-- <p-dialog [(visible)]="addGoogleMeetingModal" [modal]="true" [style]="{ width: '50vw' }" class="eventDetails"
    [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      {{ clickedItemType?.summary }}
    </ng-template>
    <ng-template pTemplate="content" class="p-2">
      <div class="card d-flex flex-column">
        
      </div>
    </ng-template>
  </p-dialog> -->

  <p-dialog [(visible)]="outlookCalendarsPickModal" [closeOnEscape]="false" [modal]="true" [style]="{ width: '50vw' }"
    class="eventDetails" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">Choose Outlook Calendars to sync</ng-template>
    <ng-template pTemplate="content" class="px-2 bg-white">
      <div class="mt-3d-flex flex-column py-4">
        <div class="mb-2 mt-1 pl-1" *ngFor="let cal of outlookCalendars">
          <p-checkbox name="groupname" class="other" multiple [value]="cal.id" [(ngModel)]="selectedOutlookCals"
            [label]="cal.name">
          </p-checkbox>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="d-flex align-items-center">
        <button type="button" (click)="selectedOutlookCalendars(selectedOutlookCals)"
          [disabled]="!selectedOutlookCals?.length" class="btn btn-sm btn-primary">Submit</button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="googleCalendarsPickModal" [closeOnEscape]="false" [modal]="true" [style]="{ width: '50vw' }"
    class="eventDetails" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">Choose Google Calendars to sync</ng-template>
    <ng-template pTemplate="content" class="px-2">
      <div class="mt-3d-flex flex-column py-4">
        <div class="mb-2 mt-1 pl-1" *ngFor="let cal of calendarList.items">
          <p-checkbox name="groupname" class="other" multiple [value]="cal.id" [(ngModel)]="selectedGoogleCals"
            [label]="cal.summary">
          </p-checkbox>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="d-flex align-items-center">
        <button type="button" (click)="selectedGoogleCalendars(selectedGoogleCals)"
          [disabled]="!selectedGoogleCals?.length" class="btn btn-sm btn-primary">Submit</button>
      </div>
    </ng-template>
  </p-dialog>
  <p-dialog [(visible)]="isGcallogoutInProgress" [showHeader]="false" [closeOnEscape]="false" [closable]="false"
    [modal]="true" [style]="{ width: '30vw' }" class="eventDetails" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="content">
      <div class="p-4 d-flex flex-column justify-content-center align-items-center">
        <h4>Removing calendar events & signing out google account.</h4>
        <div class="spinner-border text-danger my-3 mx-auto" style="width: 3rem;height: 3rem;" role="status"></div>
      </div>
    </ng-template>
  </p-dialog>
</div>