<div *ngIf="rfeTimelineModalInput['userType'] != 'Attorney'">
 <div *ngIf="notificationDocuments && notificationDocuments.length > 0">
      <div>
        <h5 class="text-primary mb-4">Upload Documents</h5>
      </div>
      <div class="row mt-0 pt-0">
         <h6 class="mb-0">Requested Documents</h6>
      </div>
      <div class="row">
         <ul class="list-horizontal" *ngFor="let item of notificationDocuments;let i=index">
             <li>{{item?.titleSheetDetailDocName}}</li>
         </ul>
      </div>

      <div class="row">
         <div class="col-9 pl-0">
         <div>
            <h6 class="mb-0">Attorney Comment</h6>
         </div>
          <div  *ngFor="let item of notificationComments" >
             <div class="row">{{item?.notes}}</div>
         </div>
      </div>
      <div class="col mt-4">
         <button [disabled]="disableFields" type="button" pButton (click)="uploadDocuments();" label="Upload Documents" class="btn-success"></button>
      </div>
      </div>
      <hr />
   </div>

   <div *ngIf="notificationResponses && notificationResponses.length > 0">
      
        <div>
           <h5 class="text-primary mb-4">Add Response</h5>
         </div>
         <div class="row">
            <h6 class="mb-0">Requested Response</h6>
         </div>

         <div class="row">
        
         <div class="col-9 pl-0" *ngFor="let item of notificationResponses;let i=index" >
             <div class="row">{{item?.categoryName}}</div>
             <div class="row mt-3"><h6>Attorney Comment</h6></div>
             <div class="row" *ngFor="let note of item.notes;let j=index" >
                <div class="row">{{note}}</div>
            </div>
            <hr />
         </div>
         <div class="col mt-4">
            <button [disabled]="disableFields" type="button" pButton (click)="addResponse();" label="Add Response" class="btn-success"></button>
         </div>
         </div>

   </div>

   <p-dialog [(visible)]="displaySupportingDocuments" [modal]="true" [responsive]="true" [draggable]="false"
   [style]="{width: '1000px', minWidth: '200px',minHeight:'550px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
   <ng-template pTemplate="header">
      <div>
         <div class="row">
             <div class="col-12 px-0">
                 <h5 class="text-primary mb-0">Supporting Documents</h5>
                 <label class="my-1">Request or Add required documents</label>
             </div>
         </div>
     </div>
   </ng-template>
   <hr class="mt-0" />
   <app-rfe-supporting-documents [rfeTimelineModalInput]="rfeTimelineModalInput" [rfeNoticeId]="rfeNoticeId" [notificationComments]="notificationComments" (changeSubStepStatus)="changeSubStepStatusEvent($event)" *ngIf="displaySupportingDocuments"></app-rfe-supporting-documents>
  </p-dialog>

   
    <p-dialog [(visible)]="displayRfeResponseBuilder" [modal]="true" [responsive]="true" [draggable]="false"
    [style]="{width: '1000px',maxWidth: '1000px', minWidth: '600px',minHeight:'600px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
<ng-template pTemplate="header">
   <div>
      <div class="row">
          <div class="col-12 px-0">
              <h5 class="text-primary mb-0">Requested Response</h5>
              <label class="my-1">Request or Add required Response</label>
          </div>
      </div>
  </div>
</ng-template>
<hr class="mt-0" />
 <app-rfe-response-builder [rfeTimelineModalInput]="rfeTimelineModalInput" [rfeNoticeId]="rfeNoticeId" [notificationResponses]="notificationResponses" [responseNotificationids]="responseNotificationids" (changeSubStepStatus)="changeSubStepStatusEvent($event)" *ngIf="displayRfeResponseBuilder"></app-rfe-response-builder>
    </p-dialog>

    <div class="mt-4 text-center">
      <button *ngIf="!disableFields" type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" 
      (click)="changeStatusToSubmit();" label="Mark Submit" class="ml-2 button_whitebg p-button-outlined"></button>
      <div *ngIf="disableFields" class="d-inline-block">
        <img src="../../../../../assets/images/stepCompleteLabel.png" />
      </div> 
    </div>
</div>




