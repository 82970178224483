<div class="p-4">
  <h5 class="pb-4"><b>PRIVACY POLICY</b></h5>
  <div class="border rounded">
    <div class="p-3 px-4" style="background:#EDF4FB">
      <h6>Imagility Privacy Policy</h6>
    </div>
    <div class="p-3 bg-white px-5">
      <div [innerHTML]="content | keepHtml"></div>
    </div>
  </div>
 </div> 
