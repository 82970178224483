import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck, map } from "rxjs/operators";
import { ImagilityBaseResponse } from "../data/models/response.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BillingInvoiceService {
 private subjectvalue =new BehaviorSubject<any>('');
  constructor(private httpClient: HttpClient) {}
 getData(){
  return this.subjectvalue.asObservable();
 }
 updataData(storedata){
  this.subjectvalue.next(storedata)
 }
 setTransactionData(paymentData){
  this.subjectvalue.next(paymentData)
 }
 getTransaction(){
return this.subjectvalue.asObservable();
 }
  getListStepsConfigured(companyId, payload) {
    return this.httpClient
      .post(`/attorney/invoice/${companyId}/client/list`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && response.status === 200) {
            return response;
          }
        })
      );
  }

  getInvoiceItem(id) {
    // /attorney/invoice/{invoiceId}
    return this.httpClient.get(`/attorney/invoice/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getAllTabDataList(clientId, type, payload) {
    return this.httpClient
      .post(`/attorney/invoice/${clientId}/client/${type}`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  getClientInvoiceList(clientId, type, payload) {
    return this.httpClient
      .post(`/attorney/invoice/client/${clientId}/${type}`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  getAllTabsFilterData(companyId, payload) {
    return this.httpClient
      .post(`/attorney/invoice/search/${companyId}`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  deleteListStepsConfigured(type, companyId, taskTypeId) {
    return this.httpClient
      .delete(
        `/${type}/company/${companyId}/taskType/${taskTypeId}/deleteSteps`
      )
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  // get the client companies of the logged in attorneys company.
  getAttorneyClientCompanies(companyId) {
    // /api/v1/attorney/invoice/{companyId}/billToClients
    return this.httpClient
      .get(`/attorney/invoice/${companyId}/billToClients`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  // get the associate companies of the logged in attorneys company
  getAssocCompanies(companyId) {
    return this.httpClient.get(`/company/${companyId}/associates`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getCompaniesById(companyId: number): Observable<any> {
    return this.httpClient.get("/company/" + companyId).pipe(
      pluck("data"),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getEmployeeDetails(companyId, employeeId) {
    return this.httpClient
      .get(`/company/${companyId}/employee/${employeeId}`)
      .pipe(
        pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }
  getPreviewInvoiceDetails(invoiceId) {
    return this.httpClient.get(`/attorney/invoice/${invoiceId}`).pipe(
      pluck("data"),
      map((data: Array<any>) => {
        return data;
      })
    );
  }
  getBankList() {
    return this.httpClient.get(`/bankList?page=3&page_size=4`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getPaymentHistoty(invoiceId?: any){
    // return this.httpClient.get(`/getPaymentHistory`).pipe(
    //   map((response: ImagilityBaseResponse) => {
    //     if (response.status) {
    //       return response[0];
    //     }
    //   })
    // );
    return this.httpClient.get(`/getPaymentHistory/${invoiceId? invoiceId: ''}`);
  }
  billingpayPost(payload){
  
    return this.httpClient.post(`/savePaymentBillingInformation/${payload.addressId}`,payload).pipe(
      map((response) => response)
    );

  }
  cancelInvoice(invoiceId) {
    let payload = {};
    return this.httpClient
      .post(`/attorney/invoice/cancel/${invoiceId}`, payload)
      .pipe(
        // pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }

  deleteInvoice(invoiceId) {
    let payload = {};
    return this.httpClient
      .delete(`/attorney/invoiceDelete/${invoiceId}`, payload)
      .pipe(
        pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }

  getEmployeesOfTheCompany(companyId) {
    return this.httpClient.get(`/company/${companyId}/employee`).pipe(
      pluck("data"),
      map((data: Array<any>) => {
        return data;
      })
    );
  }

  getTermsAndConditions(companyId: number): Observable<any> {
    return this.httpClient
      .get("/attorney/getTermsAndCondition/" + companyId)
      .pipe(map((response: ImagilityBaseResponse) => response.data));
  }

  updatePaymentInvoice(payload, invoiceId) {
    return this.httpClient
      .post(`/attorney/invoice/payment/${invoiceId}`, payload)
      .pipe(
        // pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }
  clientupdatePaymentInvoice(payload, invoiceId, id) {
    return this.httpClient
      .post(
        `/attorney/invoice/payment/${invoiceId}?notificationId=${id}`,
        payload
      )
      .pipe(
        // pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }
  isclientupdatePaymentInvoice(payload, invoiceId, id) {
    return this.httpClient
      .post(
        `/attorney/invoice/payment/${invoiceId}?notificationId=${id}&isClientUpdated=true`,
        payload
      )
      .pipe(
        map((data: Array<any>) => {
          return data;
        })
      );
  }
  // This method is used to create an invoice . url is /attorney/invoice/generate
  createInvoice(payload) {
    return this.httpClient.post(`/attorney/invoice/generate`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  uploadFile(url, payload) {
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  // /attorney/send/invoice/{invoiceId}
  sendInvoice(invoiceId, payload) {
    return this.httpClient
      .post(`/attorney/send/invoice/${invoiceId}`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  generateInvoice(payload) {
    return this.httpClient.post(`/attorney/invoice/generate`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  uploadOwnInvoive(payload, ivoiceId) {
    return this.httpClient
      .post(`/attorney/upload/ownInvoice/${ivoiceId}`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }

  uploadReceipt(payload, ivoiceId) {
    return this.httpClient
      .post(`/attorney/upload/ownInvoice/${ivoiceId}?isReceipt=true`, payload)
      .pipe(
        // pluck("data"),
        map((data: any) => {
          return data;
        })
      );
  }

  generatePdf(invoiceId) {
    // /api/1v / attorney / invoice / generatePdf / 4;
    return this.httpClient
      .post(`/attorney/invoice/generatePdf/${invoiceId}`, {})
      .pipe(
        pluck("data"),
        map((data: any) => {
          return data;
        })
      );
  }
  generatereceiptPdf(invoiceId) {
    // /api/1v / attorney / invoice / generatePdf / 4;
    return this.httpClient
      .post(`/attorney/invoice/generatePdf/${invoiceId}?isReceipt=true`, {})
      .pipe(
        // pluck("data"),
        map((data: any) => {
          return data;
        })
      );
  }

  downloadInvoice(ivoiceId) {
    return this.httpClient
      .get(`/attorney/invoice/download/${ivoiceId}`)
      .pipe(map((response: ImagilityBaseResponse) => response.data));
  }

  sendReceipt(invoiceId, payload) {
    return this.httpClient
      .post(`/attorney/send/invoice/${invoiceId}?isReceipt=true`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }
  uploadproofofPayment(payload, ivoiceId) {
    return this.httpClient
      .post(
        `/attorney/upload/ownInvoice/${ivoiceId}?isProofOfPayment=true`,
        payload
      )
      .pipe(
        // pluck("data"),
        map((data: any) => {
          return data;
        })
      );
  }
  showproofofPayment(invoiceId) {
    return this.httpClient.get(`/attorney/invoice/download/${invoiceId}`).pipe(
      pluck("data"),
      map((data: any) => {
        return data;
      })
    );
  }
  getProofofPayment(ivoiceId) {
    return this.httpClient
      .get(`/attorney/invoice/download/${ivoiceId}?isProofOfPayment=true`)
      .pipe(map((response: ImagilityBaseResponse) => response.data));
  }

  deleteuploadOwnInvoice(invoiceId) {
    let payload = {};
    return this.httpClient
      .delete(`/attorney/DeleteUploadOwnInvoice/${invoiceId}`, payload)
      .pipe(
        //pluck("data"),
        map((data: Array<any>) => {
          return data;
        })
      );
  }

  getState(){
    return this.httpClient
    .get(`/countries/states?countryCode=USA`)
    .pipe(map((response: ImagilityBaseResponse) => response.data))
  }
  savePaymentHistory(payload){
    return this.httpClient.post(`/savePaymentHistory/${payload.id}`,payload).pipe(
      map((response) => response)
    );

  }
  creditAutoCapture(payload){
  
    return this.httpClient.post(`/charge_credit_card_auto_capture`,payload).pipe(
      map((response) => response)
    );

  }
  storeCardCall(payload){
  
    return this.httpClient.post(`/store_card`,payload).pipe(
      map((response) => response)
    );

  }
  paymentChargeCall(id,payload){
  
    return this.httpClient.post(`/charges/${id}`,payload);

  }
  getpayToken(payload){
  
    return this.httpClient.post(`/oauth/token`,payload).pipe(
      map((response) => response)
    );

  }

  getLoginToken(payload){
    return this.httpClient.post(`/oauth/callback/authorize`,payload).pipe(
      map((response) => response)
    );
  }

  getGatewayRes(id) {
    // /attorney/invoice/{invoiceId}
    return this.httpClient.get(`/gateway-credentials/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getMerchant(id) {
    // /attorney/invoice/{invoiceId}
    return this.httpClient.get(`/gateway-credentials/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getPaymentHistory(userType: string = null, userId: number = 0){
    let slug = userType && userId ? `/${userType}/${userId}` : '';
    return this.httpClient.get(`/getPaymentHistory${slug}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getPaymentTransactionHistory(){
    return this.httpClient.get(`/getPaymentTransactionHistory/1/beneficiary`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  singUpMerchant(secretKey,payload){
    // https://secure.affinipay.com/api/v1/merchant_applications/sign_up
    
    return this.httpClient.post(`/merchant_applications/sign_up/${secretKey}`,payload).pipe(
      map((response) => response)
    );
  }
  paymentReceipt(invoiceId:any){
    return this.httpClient.get(`/receipt/download/${invoiceId}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
getmerchantStatus(id){
  return this.httpClient.get(`/getRegistrationStatus/${id}`).pipe(
    map((response: ImagilityBaseResponse) => {
      return response;
    })
  );
}

getMerchantId(id) {
  return this.httpClient.get(`/getAccountId/${id}`).pipe(
    map((response: ImagilityBaseResponse) => {
      return response;
    })
  );
}
setMerchantSts(paymentData){
  this.subjectvalue.next(paymentData)
 }
 getMerchantSts(){
return this.subjectvalue.asObservable();
 }

 getAttorneyPaymentHistory(attorneyId){
  return this.httpClient.get(`/getPaymentHistory/${attorneyId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      return response;
    })
  );
 }

  // get the Individuals of the logged in attorneys company.
  getAttorneyIndividuals(companyId) {
    return this.httpClient
      .get(`/attorney/invoice/${companyId}/billToClients?individual=true`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
  }
  }

