import { AfterViewInit, Component, NgZone, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { I } from "@angular/cdk/keycodes";
@Component({
  selector: "app-tabview",
  templateUrl: "./tabview.component.html",
  styleUrls: ["./tabview.component.scss"],
})
export class TabviewComponent implements OnInit, AfterViewInit {
  showTabContainer: any;
  showMeetingToSchedule: any;

  breadcrumbTitle:string ="";
  backToTitle: string = "";
  userType: string;
  navUrl: string;
  public index: number;
  activityType;
  tabObj;
  courtObj;
  meetingObj;
  eventObj;
  taskObj;
  reminderObj;
  dashboardUrl;
  constructor(private router: Router, private ngZone: NgZone,public activatedRoute: ActivatedRoute,) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if(Object.keys(params).length > 0){
        if((params.schedulemeetingid != "undefined") && (params.schedulemeetingid != undefined)){ 
          this.showTabContainer= false;
          this.showMeetingToSchedule= true;          
        }
      }else{
        this.showTabContainer= true;
        this.showMeetingToSchedule= false;
      }
    });
    if(this.showTabContainer == true){
      this.breadcrumbTitle ="Calendar";
      this.backToTitle = "dashboard";
      console.log("history.state.itemType", history.state);
      this.activityType = history.state.calendarItem.toLowerCase();
      this.tabObj = history.state.elemData
      console.log("his.activityType", this.activityType);
      console.log("tabObj elemData ::", history.state.elemData);
      this.userType = sessionStorage.getItem("userTypeRole");
      this.getActiveTab();    
      if (this.userType == "Petitioner") {
        this.navUrl =
          "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
        this.dashboardUrl = "/petitioner-landing/dashboard/petitioner-dashboard";
      } else if (this.userType == "Attorney") {
        this.navUrl =
          "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
        this.dashboardUrl = "/attorney-landing/dashboard/attorney-dashboard";
      }
    }else if(this.showMeetingToSchedule == true){
      this.breadcrumbTitle ="Leads > Manage leads";
      this.backToTitle = "manage leads";
      this.navUrl = "/attorney-landing/leads/marketing";
      this.dashboardUrl = "/attorney-landing/leads/marketing";
    }
  }

  ngAfterViewInit() {
    //this.getActiveTab();
  }

  getActiveTab() {
    console.log('this.userType', this.userType)
    console.log('this.activityType', this.activityType)
    if (this.activityType) {
      switch (this.activityType) {
        case "meeting":
          
          this.ngZone.run(() => {
            this.index = 0;
            this.meetingObj = this.tabObj;
          });
          console.log("this.index", this.index);
          break;
        case "task":
          this.ngZone.run(() => {
            this.index = 1;
            this.taskObj = this.tabObj;
          });

          break;
        case "event":
          this.ngZone.run(() => {
            this.index = 2;
            this.eventObj  = this.tabObj;
          });

          break;
        case "courtdate":
          this.ngZone.run(() => {
            this.index = 3;
            this.courtObj  = this.tabObj;
          });
          break;
        case "reminder":
          this.ngZone.run(() => {
            if (this.userType == "Petitioner") {
              this.index = 3;
            } else if (this.userType == "Attorney") {
              this.index = 4;
            }
            this.reminderObj = this.tabObj;
          });
          break;
        case "default":
          console.log("this.index", this.index);
          break;
      }
      console.log("this.index", this.index);
    }
    else {
      console.log("No activity type");
    }
  }
  handleChange(e) {
    var index = e.index;
  }
  backtoCalendar() {
    this.router.navigate([this.navUrl]);
  }

  backtoDashboard() {
    this.router.navigate([this.dashboardUrl]);
  }
}