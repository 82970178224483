import { constants } from 'perf_hooks';

export enum visa_config_code {
  VISA_TYPE = 'L1A',
  USER_TYPE = 'PETITION',
}

export enum sync_profile_code {
  SYNC_TASK_TYPE_EDUCATION = 'EDUCATION', //education
  SYNC_TASK_TYPE_EXPERIENCE = 'EXPERIENCE', //experience
  SYNC_TASK_TYPE_LICENSE = 'LICENSE', //license
  SYNC_TASK_TYPE_TRAINING = 'TRAINING', //training
  DELETE_TASK_TYPE_COURSE = 'COURSE', //course
  DELETE_TASK_TYPE_JOBDUTY = 'JOBDUTY', //jobduty
  DELETE_TASK_TYPE_SKILL = 'SKILL', //skill
}

export enum sync_beneficiary_document_code {
  SYNC_TASK_TYPE_BENE_IMMIGRATION = 'IMMIGRATION',
  SYNC_TASK_TYPE_BENE_IMMIGRATION_Code = 'COMPPETDOCUS',
  SYNC_TASK_TYPE_BENE_IMMIGRATION_Other_Doc_Code = 'COMPPETDOCUS',
}

export enum sync_petitioner_document_code {
  SYNC_TASK_TYPE_BENE_IMMIGRATION = 'IMMIGRATION',
  SYNC_TASK_TYPE_Code_US = 'COMPPETDOCUS',
  SYNC_TASK_TYPE_Code = 'COMPPETDOCFORGN',
  SYNC_TASK_TYPE_Code_MEMBERSHIP = 'MEMBRDOC',
  SYNC_TASK_TYPE_BENE_IMMIGRATION_Other_Doc_Code = 'COMPPETDOCFORGN',
  SYNC_TASK_TYPE_Code_RESEARCH = 'RESEARCHDOC'
}
