import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeSubcriptionService {

  constructor() { }

  onUnSubscribe(subcriptions: Subscription[]) {
    subcriptions.forEach(subcription => {
      if (subcription) {
        subcription.unsubscribe();
      }
    });
  }

}
