import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
// import { ImagilityBaseResponse } from '../../../../data/models/receipt.models';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  @Output() updateNoteCountEvent = new EventEmitter<any>();

  updateNoteCount(msg: any) {
  this.updateNoteCountEvent.emit(msg);
}

  constructor(private httpClient: HttpClient) { }
  // API call to create or update the lead
  createupdatelead(url, payload) {
    return this.httpClient.put(url, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  // API call to delete the selected lead
  deletelead(url) {
    return this.httpClient.delete(url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // API call to update Lead Priority and update Lead Status
  updateLeadValue(url, payload) {
    return this.httpClient.patch(url, payload, httpOptions).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // API call to get getCompaniesList for auto complete company name.
  getCompaniesList(autoSearch) {
    return this.httpClient.get(`/marketing/lead/employeeMasterDataList/${autoSearch}`, httpOptions).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getLeadById(id) {
    return this.httpClient.get(`/marketing/lead/getLeadById/${id}`, httpOptions).pipe(
      map((response) => {
        return response;
      })
    );
  }
  // getAllEvents(payload, calItemType?) {
  //   // "calItemType": 1067,
  //   // if (calItemType) {
  //   //   payload.calItemTypes = calItemType
  //   // }
  //   console.log('service payload', payload)
  //   let url = '/meeting/search/calendar'
  //   return this.httpClient.post(url, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  // }


  // // getAllEvents(payload, calItemType?) {
  // saveCalendarItem(payload) {
  //   console.log('saveCalendarItem', payload)
  //   let url = '/scheduleCalender/create'
  //   return this.httpClient.post(url, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }  elser= re
  //     })
  // );
  // }


  // updateCalendarItem(payload){
  //   console.log('updateCalendarItem', payload)
  //   let url = '/scheduleCalender/update'
  //   return this.httpClient.post(url, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  // }



  // saveMeeting(payload) {
  //   console.log('meeting',payload)
  //   return this.httpClient.post(`/scheduleCalender/create`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  // }

  // getAttendesList(userid, searchquery) {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${userid}`
  //   })
  //   return this.httpClient.get(`/company/272/employeeInfo/email/${searchquery}`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;
  //     })
  //   );
  // }

  // getClientNameList(userid) {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${userid}`
  //   })
  //   return this.httpClient.get(`/company/272/hierarchy`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;

  //     })
  //   );
  // }

  // getEmployeeDetails(companyid, searchText) {
  //   let url = `/company/${companyid}/employeeInfo/name/${searchText}`
  //   return this.httpClient.get(url).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  // }

  // getAttendesList1(userid, searchquery, companyId) {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${userid}`
  //   })
  //   return this.httpClient.get(`/company/${companyId}/employeeInfo/email/${searchquery}`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;
  //     })
  //   );
  // }

  // getClientNameList1(usertoken, companyid) {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${usertoken}`
  //   })
  //   return this.httpClient.get(`/company/${companyid}/hierarchy`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;

  //     })
  //   );
  // }

  // getEmployeeDetails1(companyId, searchText) {
  //   let url = `/company/${companyId}/employeeInfo/name/${searchText}`
  //   return this.httpClient.get(url).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  // }

  // updateMeeting(payload) {
  //   console.log('meeting')
  //   return this.httpClient.post(`/scheduleCalender/update`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );

  // }


  // getTeamembersList(token, comId) {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`
  //   })
  //   return this.httpClient.get(`/company/${comId}/employeeInfo/all/na`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );

  // }

  // getCalendarItemDetails(calItemId, series: number){
  //   let url = `/meeting/calendar/${calItemId}/${series}`;
  //    // let url = `/company/${companyId}/employeeInfo/name/${searchText}`
  //    return this.httpClient.get(url).pipe(
  //      map((response: ImagilityBaseResponse) => {
  //        // if (response.status === 200) {
  //        return response;
  //        // }
  //      })
  //    );
  //  }

  //  cancelCalendarItem(payload){
  //   return this.httpClient.put('/calendar/cancel', payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  //  }

  //  eventUpload(payload, calendarSchedulId){
  //   return this.httpClient.post(`/schedulCalender/event/${calendarSchedulId}/fileRefGroupCode/CALITEMDOC/fileRefCode/CALITEMDOC/document`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  //  }

  //  getEventDoc(calendarSchedulId){
  //   // const headers = new Headers({
  //   //   'Content-Type': 'application/json',
  //   //   'Authorization': `Bearer ${userid}`
  //   // })
  //   return this.httpClient.get(`/schedulCalender/event/${calendarSchedulId}/fileRefGroupCode/CALITEMDOC/document`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  //  }

  // getTaskComplete(payload){

  //    return this.httpClient.put(`/calendar/task/complete`,payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  //  }

  //  deleteEventDocument(docid){
  //   return this.httpClient.delete(`/schedulCalender/event/${docid}/deleteDocument`).pipe(
  //     map((response) => {
  //       // if (response.status === 200) {
  //       return response;
  //       // }
  //     })
  //   );
  //  }
}









