import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RfeService } from 'src/app/services/rfe.service';
import { environment } from '../../../../../environments/environment';
import { stepStatus } from '../../../../data/constants/step-status';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import {ConfirmationService} from 'primeng/api';
import { acceptedFileTypes } from 'app-models';
import { CustomErrorToastComponent } from 'custom-error-toast';
@Component({
  selector: 'app-rfe-prepare-response-step1',
  templateUrl: './rfe-prepare-response-step1.component.html',
  styleUrls: ['./rfe-prepare-response-step1.component.scss']
})
export class RfePrepareResponseStep1Component implements OnInit {
  @Input() rfeTimelineModalInput;
  @Input() rfeNoticeId;
  @Input() currentStepId;
  customDocTypeForNotification;
  selectedDocType;
  selectedDocTypeForNotification;
  customDocType;
  uploadedFile: any;
  selectedCategory;
  selectedCategoryType: any[] = [];
  supportingDocuments:any[];
  supportingDocumentsForNotification:any[];
  categories:any[] = [];
  selectedCategoryForNotification;
  documentType:any[] = [];
  individualNotification:boolean = false;
  displayDocumentPanel:boolean = false;
  beneficiaryNotes;
  petitionerNotes;
  notificationStatusUpdate:string = "";
  additionalDocuments:boolean = false;
  SubTaskOverView;
  acceptedTypes = acceptedFileTypes;
  constructor(private petitionerService: PetitionerService,
    private store: Store<fromTask.State>,
    private confirmationService:ConfirmationService,
    private rfeService:RfeService,private toastr: ToastrService) { }

  ngOnInit() {
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
    });
    this.getStatus();
    this.getSupportingDocuments();
  }

  setDocTypeEmpty(){
    this.selectedDocType = undefined;
    this.selectedDocTypeForNotification = undefined;
  }

  onUpload(event,fileUpload) {
    //this.uploadDocument();
    if(this.selectedDocType == undefined && this.customDocType == undefined){
      CustomErrorToastComponent.showErrorToast(this.toastr, 'Please select a document type or enter a custom one');
    }
    else{
    let documentTypeName,entityTypeId,masterTitleSheetId,rfeTitleSheetDetailId;
    for(let file of event.files) {
      this.uploadedFile = file;
      this.uploadedFile["fileName"] = file.name;
    }
    fileUpload.clear();
    let payload = new FormData();
    if(this.customDocType != undefined){
      documentTypeName = this.customDocType;
      entityTypeId = masterTitleSheetId = rfeTitleSheetDetailId = '';
    }
    else {
      documentTypeName = this.selectedDocType["docName"] ? this.selectedDocType["docName"] : '';
      entityTypeId = this.selectedDocType["entityType"] ? this.selectedDocType["entityType"] : '';
      masterTitleSheetId = this.selectedDocType["masterTitleSheetDetailId"] ? this.selectedDocType["masterTitleSheetDetailId"] : '';
      rfeTitleSheetDetailId = this.selectedDocType["rfeTitleSheetDetailId"] ? this.selectedDocType["rfeTitleSheetDetailId"] : '';

    }
     payload.append("file", this.uploadedFile , this.uploadedFile.name);
      this.rfeService.saveUploadedDocument(this.SubTaskOverView['id'],this.selectedCategory["categoryDetailId"],documentTypeName,entityTypeId,masterTitleSheetId,rfeTitleSheetDetailId,payload).subscribe((response: any) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
          this.getSupportingDocuments();
        }
      });
  }
}

getSupportingDocuments(){
  this.rfeService
    .getSupportingDocuments(this.SubTaskOverView['id'])
    .subscribe((data) => {
      data.forEach( (element) => {
        element["showLabelForCheckbox"] = false;
        element.documentTypes.forEach(doc => {
          if(doc.present == false)
            element["showLabelForCheckbox"] = true;
          doc["selectedDocumentType"] = true;  
          doc["notifyTo"] = "";
          doc["showIndividual"] = true;
          this.selectedCategoryType.push(element);
        });
      });
      this.supportingDocuments = data; 
      this.supportingDocumentsForNotification = data;
    });

    this.rfeService
    .getRfeSelectedCatagoryList(this.rfeNoticeId)
    .subscribe((data) => {
    this.categories = data; 
    if(this.categories.length > 0){
      this.selectedCategory = this.categories[0];
      this.selectedCategoryForNotification = this.categories[0];
      this.updateDocType(this.selectedCategory);
      this.updateDocType(this.selectedCategoryForNotification);
    }
    });
}

updateDocType(selectedCategory){
  this.rfeService
  .getDocumentType(selectedCategory["categoryDetailId"])
  .subscribe((data) => {
    this.documentType = data; 
    });
 }

 setDisplayDocumentPanel(){
  this.individualNotification = false;
  this.displayDocumentPanel = true;
  this.supportingDocumentsForNotification = this.supportingDocuments;
}

viewDocument(item) {
  const docPath = item.substring(23);
  window.open(`${environment.docs}${docPath}`, "_blank");
}

confirmDelete(item) {
  this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key:'step1Response',
      accept: () => {
        this.deleteDocument(item);
      },
      reject: () => {
        
      }
  });
}

deleteDocument(item){
    this.rfeService.deleteRFEDocument(this.SubTaskOverView['id'],item).subscribe((response:any) => {
      if(response){
        this.toastr.success(response.message, 'Success');
        this.getSupportingDocuments();
      }
    });
}

setCustomEmpty(){
  this.customDocType = undefined;
  this.customDocTypeForNotification = undefined;
}

notifyDocument(item,document){
  this.displayDocumentPanel = true;
  this.individualNotification = true;
  let selectedCategoryTypeTemp : any[] = [];
  this.supportingDocuments.forEach( (element) => {
    if(element.categoryName == item.categoryName){
    element["showLabelForCheckbox"] = true;
    element.documentTypes.forEach(doc => {

      if(document.docName == doc.docName){
        doc["selectedDocumentType"] = true;  
        doc["notifyTo"] = "";
        doc["showIndividual"] = true; 
      }
      else {
        doc["showIndividual"] = false;
      }
    });
    selectedCategoryTypeTemp.push(element);
  }
  });
  this.supportingDocumentsForNotification = selectedCategoryTypeTemp;
}

addNewCategory(){
  if(this.selectedDocTypeForNotification == undefined && this.customDocTypeForNotification == undefined){
    CustomErrorToastComponent.showErrorToast(this.toastr, 'Please select a document type or enter a custom one');
  }
  else{
    let docName,entityType,masterTitleSheetDetailId;
    if(this.customDocTypeForNotification != undefined){
      docName = this.customDocTypeForNotification;
      entityType = masterTitleSheetDetailId = '';
    }
    else {
      docName = this.selectedDocTypeForNotification["docName"];
      entityType = this.selectedDocTypeForNotification["entityType"];
      masterTitleSheetDetailId = this.selectedDocTypeForNotification["masterTitleSheetDetailId"];
    }
    let payload = {
      docName:  docName,
      entityType: entityType,
      masterTitleSheetDetailId:masterTitleSheetDetailId
    };
    this.rfeService.addDocumentType(this.SubTaskOverView['id'],this.selectedCategoryForNotification["categoryDetailId"],payload).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
        this.getSupportingDocuments();
      }
    });
}

}

sendNotification(){
  let documents = this.supportingDocuments;
   if(this.individualNotification)
     documents = this.supportingDocumentsForNotification
   let titleSheetDocDtoList = [];
   documents.forEach( (element) => {
       element.documentTypes.forEach(doc => {
         if(doc.selectedDocumentType && !doc.present){
           titleSheetDocDtoList.push({
             "categoryDtlId": element.categorySequenceNo,
           "categoryName": element.categoryName,
           "notifyTo": doc.notifyTo,
           "titleSheetDetailDocName": doc.docName,
           "titleSheetDetailId": doc.titleSheetDetailId
           });
         }
       });
     });
  
     if(titleSheetDocDtoList.length == 0){
      CustomErrorToastComponent.showErrorToast(this.toastr, 'Please select atleast One required document.');
     }
     else{
   let payload = {
     "petitionerNotes": this.petitionerNotes,
     "beneficiaryNotes":  this.beneficiaryNotes,
     "channelType": "",
     "notificationType": "",
     "notifyAllBeneficiaries": false,
     "notifyAllEmployees": false,
     "priority": "",
     "titleSheetDocDtoList": titleSheetDocDtoList
   };
 
   this.rfeService.sendNotification(this.rfeNoticeId,payload).subscribe((response: any) => {
     if (response.status == 200) {
       this.toastr.success(response.message, 'Success');
       this.getStatus();
       this.displayDocumentPanel = false;
     }
   });
  }
 }

 getStatus(){
  this.rfeService
  .getRFENotificationMessage('RFE_DOC_MISS',this.rfeNoticeId)
  .subscribe((data) => {
    this.notificationStatusUpdate = data['message'];
  });
 }

 changeStatus(){
  const payload = {
    statusCode: stepStatus.Complete,
    stepNote: ''
  };
  const payload1 = {
    statusCode: stepStatus.Inprogress,
    stepNote: ''
  };
  this.petitionerService.stepStatusUpdateRFE(payload1, this.SubTaskOverView['id'], this.rfeTimelineModalInput["parentStepId"]).subscribe();
  this.petitionerService.stepStatusUpdateRFE(payload, this.SubTaskOverView['id'], this.currentStepId).subscribe((response: any) => {
    if (response) {
      this.toastr.success(response.message, 'Success');
 //     this.displaySection = "";
    }
  });

}

documentSelected(event:any){

}


categorySelected(event:any,item){
  item.documentTypes.forEach(doc => {
    doc["selectedDocumentType"] = event.checked;  
  });
}

}
