<div>
    <div class="row">
        <div class="col-12">
            <h3>Send Invoice</h3>
        </div>
    </div>
    <form [formGroup]="sendInvoiceForm">
        <div class="row">

            <div class="col-12">
                <div class="row">
                    <div class="col-8 w-100 pl-0">
                        <p class="label-cls mb-2 mt-3">Template Name</p>
                        <p-dropdown inputId="" [options]="templateList" placeholder="Template List" optionLabel="templateName" optionValue="id"
                        name="templateName" (onChange)="onSelectEmailTemplate($event)" formControlName="templateName">
                        </p-dropdown>
                    </div>
                    <div class="col-4 create-link">
                        <div class="mt-5"><a class="link" (click)="navigate()">Create New Template</a></div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label class="label-cls required test mt-2" for="receipients">Enter multiple recipients by entering
                    comma seperated Email Ids </label><br>
                <!-- <input id='receipients' type="email" formControlName="toMails">-->
                <p-chips class="w-100" formControlName="toMails" separator="," [allowDuplicate]="false"  (onAdd)="validateEmailAddress($event,'to')" required ></p-chips> 
                
                <small *ngIf="(sendInvoiceForm.get('toMails').touched || submitted) && sendInvoiceForm.get('toMails').errors" class="p-error">
                    <span class="text-danger"*ngIf="sendInvoiceForm.get('toMails').errors?.required">
                        Email is required
                    </span>
                      <span class="text-danger" *ngIf="(sendInvoiceForm.get('toMails').dirty || sendInvoiceForm.get('toMails').touched) && sendInvoiceForm.get('toMails').value.length && isEmailToError">
                        {{getGlobalErrorMessages('INVALID_EMAIL')}}
                      </span>
                </small>       
            </div>
            <div class="col-12">
                <label class="label-cls required test mt-2" for="subject">Subject </label>
                <input id='subject' type="email" formControlName="subject" class="ml-0">
            </div>
            <span class="text-danger w-100 ml-3"
                        *ngIf="(sendInvoiceForm.get('subject').touched || submitted) && sendInvoiceForm.get('subject').errors?.required">
                        Subject is required
                    </span>
            <!-- <div class="col-12">
                <label class="label-cls required test mt-2" for="bodyContent">Body Content </label>
                <textarea class="col-12 h-300" id='bodyContent' type="email" formControlName="body"></textarea>
            </div> -->
            <div class="col-12">
                <label class="label-cls required test mt-2" for="bodyContent">Body Content </label>
                <div class="customInputContainer">
                  <kendo-editor #commonkendoeditor style="height: 500px;" name="editor" formControlName="editor">
                    <!-- <kendo-editor [(value)]="previewLetter" (valueChange)="previewLettereditorValueChange($event)" #letterpreviewkendoeditor style="height: 400px;" name="coverLetterTextContent">     -->
                    <kendo-toolbar>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                      <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                      <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                      <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                      <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                        <kendo-toolbar-button title="Upload’s Image at cursor point" text="Upload Image"
                          (click)="commonkendoeditorimageuploaddialog()"></kendo-toolbar-button>
                      </kendo-toolbar-buttongroup>
                    </kendo-toolbar>
                  </kendo-editor>
                  <app-upload-image-dialog #commonkendoeditorupload [editor]="commonkendoeditor"></app-upload-image-dialog>
                </div>
              </div>
        </div>
        <div class="row pull-right mt-3">
            <div class="warning">
                <i class=" pi pi-exclamation-triangle" style="color:orange ; font-size: 1.5rem;"></i>
                Once you click on the send option <br /> you will not be able to update the invoice
                <!-- <p class="warning">Once you click on the send option you will not be able to update the invoice</p> -->
            </div>
        </div>
        <div class="row pull-right mt-3" style="clear:both">
            <div>
                <!-- <button (click)="sendInvoice()">
                    Send
                </button> -->
                <button class="im-btn btn-light im-btn-md mr-2" type="button" (click)="cancelInvoice()">Cancel</button>
                <!-- <button (click)="cancelInvoice()" class="im-btn im-btn-primary im-btn-md " type="button"> Cancel
                </button> -->
                <button (click)="sendInvoice()" [disabled]='isClicked' class="im-btn im-btn-primary im-btn-md "
                    type="button"> Send
                </button>
            </div>
        </div>
    </form>
</div>