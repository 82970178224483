import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { JobDutiesService } from './job-duties.service';
import { ImagilityBaseResponse, PrimaryData } from 'app-models';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DeleteDialogLibraryComponent } from 'projects/delete-dialog-library/src/public-api';
import { MarkDirtyFormAction } from 'dirty-check-store';
import { Store } from '@ngrx/store';

export const deleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};

@Component({
  selector: 'job-duties',
  templateUrl: './job-duties.component.html',
  styleUrls: ['./job-duties.component.scss'],
  providers: [DialogService]
})
export class JobDutiesComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  parentForm: FormGroup;

  @Output()
  deleteDuty: EventEmitter<number> = new EventEmitter();

  @Output()
  deleteAllDutie: EventEmitter<any> = new EventEmitter();

  @Output()
  deleteSubDuty: EventEmitter<{ dutyIndex: number, subDutyIndex: number }> = new EventEmitter();

  @Output()
  addUpdateDuty: EventEmitter<{ type: string, data: any[] }> = new EventEmitter();

  dialogRef: DynamicDialogRef;
  jobDuties: FormGroup;
  @Input()
  disabledControls: boolean;

  categoryList = [];
  jobRoleOptions: any;

  @Output()
  onsaveJobRoleOrPopulateDuties: EventEmitter<{ selectedJobPosition: string, customPositionTitle: string }> = new EventEmitter();

  @Input()
  inputData: PrimaryData;
  observableSubscription$ = new Subject();

  @Output() deleteDutiesOnPopulateDuties = new EventEmitter();

  constructor(
    private dialogService: DialogService,
    private jobDutiesService: JobDutiesService,
    public jobDescriptionBuilder: FormBuilder,
    public store: Store<any>,
  ) {
    this.disabledControls = false;
    this.jobRoleOptions = [];
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.parentForm) {
      // work around for primeng radio button
      this.parentForm.get('selectedRaiobtn').valueChanges.subscribe(e => {
        this.parentForm.get('selectedRaiobtn').setValue(e, { emitEvent: false });
      });
      this.loadCategory_ddl();
    }
  }

  loadCategory_ddl() {
    this.jobDutiesService.getJobCategoryList_ddl()
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(res => {
        this.categoryList = res;
        if(this.inputData && this.parentForm && this.inputData.jsonInput && this.inputData.jsonInput.jobCategory){
          const selectedCategory = this.categoryList.filter((cat)=> cat.jobCategory === this.inputData.jsonInput.jobCategory);
          if(selectedCategory.length){
            this.parentForm.get('selectedCategory').patchValue(selectedCategory[0]);
            this.loadJobRoles_ddl({value:{id:selectedCategory[0].id}});
          }
        }
      });
  }


  onChange_Category(event) {
    this.loadJobRoles_ddl(event);
  }

  loadJobRoles_ddl(event) {
    this.jobDutiesService.getJobRolesList_ddl(event.value.id, this.inputData)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(response => {
        this.jobRoleOptions = response ? response.filter(role => role.jobTitle !== 'Other') : [];
        let selectedJobRole:any = [];
        if(this.inputData && this.parentForm && this.inputData.jsonInput && this.inputData.jsonInput.jobRole){
          selectedJobRole = this.jobRoleOptions.filter((role)=> role.jobTitle === this.inputData.jsonInput.jobRole);
        }
        if(selectedJobRole.length){
          this.parentForm.get('jobRoleJD').patchValue(selectedJobRole[0].jobTitle);
        }
      });
  }

  setPositionTitle({ value }) {
    sessionStorage.setItem('selectedJobRole', JSON.stringify(this.jobRoleOptions.find(item => item.jobTitle === value)));
    if (value !== 'Other') {
      this.parentForm.controls['otherJobPosition'].setValue(null);
    }
  }

  get dutyList() {
    return (this.parentForm.controls['duties'] as FormArray).controls;
  }

  getSubDutiesList(dutyIndex: number) {
    return ((this.parentForm.controls['duties'] as FormArray).controls[dutyIndex].get('subJobDuty') as FormArray).controls;
  }

  addDuty(duty = null, type: string = 'ADD', dutyIndex: number = null) {
    this.dialogRef = this.dialogService.open(AddDutyComponent, {
      header: 'Add Duty and Sub-duties',
      data: {
        duties: {
          name: duty && duty.name ? duty.name : '',
          subJobDuty: duty && duty.subJobDuty ? duty.subJobDuty : [{
            name: '',
          }]
        },
        type,
        dutyIndex
      },
      width: '70%',
      contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
      baseZIndex: 2000
    });
    this.dialogRef.onClose.subscribe((response: { type: string, data: any[], dutyIndex: number }) => {
      this.addUpdateDuty.emit(response);
    });
  }

  deleteAllDuties() {
    if (['E3'].includes(this.inputData.visatype)) {
      this.parentForm.controls['jobDutyForceDelete'].setValue(true);
    }
    this.deleteAllDutie.emit();
  }

  handleEdit(duty, dutyIndex) {
    this.addDuty(duty.value, 'UPDATE', dutyIndex);
  }

  dutiesDelete(i: number) {
    this.deleteDuty.emit(i);
  }

  subDutiesDelete(dutyIndex: number, subJobDutyIndex: number) {
    this.deleteSubDuty.emit({ dutyIndex, subDutyIndex: subJobDutyIndex });
  }

  saveJobRoleOrPopulateDuties() {
    this.handleOnsaveJobRoleOrPopulateDuties({ selectedJobPosition: this.parentForm.get('jobRoleJD').value, customPositionTitle: this.parentForm.get('otherJobPosition').value });
  }

  handleOnsaveJobRoleOrPopulateDuties(input: { selectedJobPosition: string, customPositionTitle: string }) {
    const overlppingReconfirmDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, {
      ...deleteDialogConfigurations,
      data: {
        deleteMessage: 'Do you wish to populate duties & sub duties? This will override any existing duties'
      }
    });
    overlppingReconfirmDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          if (this.parentForm.value && this.parentForm.value.id) {
            if (['E3'].includes(this.inputData.visatype)) {
              this.jobDutiesService.getIOSMapping(this.inputData.visatype, this.inputData.caseType,
                this.inputData.caseId, this.parentForm.value.id, 0)
                .pipe(take(1))
                .subscribe((IOSMappingResponse) => {
                  if (IOSMappingResponse && IOSMappingResponse.data && IOSMappingResponse.data['isAnyDependency']) {
                    const deleteReconfirmDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, {
                      ...deleteDialogConfigurations,
                      data: {
                        deleteMessage: 'Job duties cannot be populated as Itinerary of Services mapping exists with the job duties. Do you want continue with the deletion of Itinerary of Services mapping?'
                      }
                    });
                    deleteReconfirmDialogRef.onClose
                      .pipe(takeUntil(this.observableSubscription$))
                      .subscribe((responseOnClose: boolean) => {
                        if (responseOnClose) {
                          this.parentForm.controls['jobDutyForceDelete'].setValue(true);
                          this.populateJobDuties(input);
                        }
                      });
                  } else {
                    this.populateJobDuties(input);
                  }
                });
            } else {
              this.populateJobDuties(input);
            }
          } else {
            // if there is no position id exists. no need to check ios mapping exists
            this.populateJobDuties(input);
          }
        }
      });
  }


  populateJobDuties(input) {
    this.deleteDutiesOnPopulateDuties.emit();
    this.jobDutiesService.getJobRolesDuties(this.inputData.companyId,
      this.jobRoleOptions.find(item => item.jobTitle === input.selectedJobPosition).id)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any) => {
        if (response && response.jobDuty && response.jobDuty.length > 0) {
          response.jobDuty.forEach(element => {
            element['id'] = null;
            if (element.subduty && element.subduty.length > 0) {
              element.subduty.forEach(subJobDuty => {
                subJobDuty['id'] = null;
              });
            }
            (this.parentForm.controls['duties'] as FormArray).push(this.buildDutyList(element));
          });
        }
      });
  }

  buildDutyList(item) {
    const subJobDuty = [];
    if (item.subduty && item.subduty.length > 0) {
      item.subduty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    } else if (item.subJobDuty && item.subJobDuty.length > 0) {
      item.subJobDuty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    }
    return this.jobDescriptionBuilder.group({
      id: item.id,
      name: item.duty || item.name,
      subJobDuty: this.jobDescriptionBuilder.array(subJobDuty)
    });
  }

  buildSubDutyList(item) {
    return this.jobDescriptionBuilder.group({
      id: item.id,
      name: item.duty || item.name
    });
  }
  // selectedRaiobtn;
  // handleChange(value){
  //   this.selectedRaiobtn=value;
  //   sessionStorage.setItem('saveDuty', this.selectedRaiobtn);
  // }

  ngOnDestroy(): void {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}


@Component({
  selector: 'add-duty-dialog',
  templateUrl: 'new-duty.dialog.html'
})
export class AddDutyComponent implements OnInit {

  dutiesForm: FormGroup;

  constructor(
    private dutiesFormBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef) {
    this.resetForm();
  }

  ngOnInit() {
    // (this.dutiesForm.get('subJobDuty') as FormArray).clear();
    (this.dutiesForm.get('subJobDuty') as FormArray).push(this.getSubDuty());
    if (this.config.data.duties.subJobDuty && this.config.data.duties.subJobDuty.length > 1) {
      for (let index = 1; index <= this.config.data.duties.subJobDuty.length - 1; index++) {
        (this.dutiesForm.get('subJobDuty') as FormArray).push(this.getSubDuty());

      }
    }
    this.dutiesForm.patchValue(this.config.data.duties);
  }

  createSubDuty(response): FormGroup {
    return this.dutiesFormBuilder.group({
      name: response && response.name ? response.name : null
    });
  }

  get subDutiesList() {
    return (this.dutiesForm.get('subJobDuty') as FormArray).controls;
  }

  addSubDuty() {
    (this.dutiesForm.get('subJobDuty') as FormArray).push(this.getSubDuty());
  }

  getSubDuty() {
    return this.dutiesFormBuilder.group({
      name: [null]
    });
  }

  resetForm() {
    this.dutiesForm = this.dutiesFormBuilder.group({
      name: '',
      subJobDuty: this.dutiesFormBuilder.array([])
    });
  }

  handleClose(type) {
    const data = {
      ...this.dutiesForm.value,
      subJobDuty: this.dutiesForm.value.subJobDuty ? this.dutiesForm.value.subJobDuty
        .filter(x => x.name && x.name.trim() !== '') : []
    };

    this.ref.close({ type, data, dutyIndex: this.config.data['dutyIndex'] });
  }

  appendDutyList() {
    this.handleClose(this.config.data['type']);
  }

}
