<div class="fluid-container">
  <form [formGroup]="supervisorForm" (ngSubmit)="postSupervisor()" class="pb-3 pt-2">
    <div class="main-container">
      <div class="row">
        <div class="col-md-12">
          <h6><b>At the Petitioner</b></h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> First Name </label>
          <input type="text" class="im-input mw-100" id="petitionerFirstName" formControlName="petitionerFirstName"
            placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerFirstName'].touched && supervisorForm.controls['petitionerFirstName'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerFirstName'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Last Name </label>
          <input type="text" class="im-input mw-100" id="petitionerLastName" formControlName="petitionerLastName"
            placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerLastName'].touched && supervisorForm.controls['petitionerLastName'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerLastName'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls"> Middle Name </label>
          <input type="text" class="im-input mw-100" id="petitionerMiddleName" formControlName="petitionerMiddleName"
            placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerMiddleName'].touched && supervisorForm.controls['petitionerMiddleName'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerMiddleName'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Designation </label>
          <input type="text" class="im-input mw-100" id="petitionerSupervisorRole"
            formControlName="petitionerSupervisorRole" placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerSupervisorRole'].touched && supervisorForm.controls['petitionerSupervisorRole'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerSupervisorRole'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-4 form-group processing-dropdown">
          <label class="label-cls required"> Country Code </label>
          <p-dropdown appendTo="body" styleClass="h1b-err-dropdown" [options]="countryList" [filter]="false"
            formControlName="petitionerPhoneCode" optionLabel="displayPhoneCode" optionValue="countryCode"
            placeholder="Select">
          </p-dropdown>
          <div
            *ngIf="supervisorForm.controls['petitionerPhoneCode'].touched && supervisorForm.controls['petitionerPhoneCode'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerPhoneCode'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Phone No </label>
          <input type="text" class="im-input mw-100" id="petitionerPhoneNo" formControlName="petitionerPhoneNo"
            maxlength="10" minlength="10" placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerPhoneNo'].touched && supervisorForm.controls['petitionerPhoneNo'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerPhoneNo'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message"
              *ngIf="supervisorForm.controls['petitionerPhoneNo'].errors?.pattern || supervisorForm.controls['petitionerPhoneNo'].errors?.minlength || supervisorForm.controls['petitionerPhoneNo'].errors?.maxlength">
              {{getGlobalErrorMessages('INVALID_NUMBER')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Email </label>
          <input type="text" class="im-input mw-100" id="petitionerEmail" formControlName="petitionerEmail"
            placeholder="Enter" pInputText />
          <div
            *ngIf="supervisorForm.controls['petitionerEmail'].touched && supervisorForm.controls['petitionerEmail'].invalid">
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerEmail'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message" *ngIf="supervisorForm.controls['petitionerEmail'].errors?.pattern">
              {{getGlobalErrorMessages('INVALID_EMAIL')}}
            </span>
          </div>
        </div>
      </div>
      <ng-template [ngIf]="workLocationTypeCode && workLocationTypeCode !== 'INHSE'">
        <div class="row">
          <div class="col-md-12">
            <h6><b>Client Supervisor</b></h6>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> First Name </label>
            <input type="text" class="im-input mw-100" id="clientSupervisorFirstName"
              formControlName="clientSupervisorFirstName" placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSupervisorFirstName'].touched && supervisorForm.controls['clientSupervisorFirstName'].invalid">
              <span class="invalid-message"
                *ngIf="supervisorForm.controls['clientSupervisorFirstName'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Last Name </label>
            <input type="text" class="im-input mw-100" id="clientSupervisorLastName"
              formControlName="clientSupervisorLastName" placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSupervisorLastName'].touched && supervisorForm.controls['clientSupervisorLastName'].invalid">
              <span class="invalid-message"
                *ngIf="supervisorForm.controls['clientSupervisorLastName'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls"> Middle Name </label>
            <input type="text" class="im-input mw-100" id="clientSupervisorMiddleName"
              formControlName="clientSupervisorMiddleName" placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSupervisorMiddleName'].touched && supervisorForm.controls['clientSupervisorMiddleName'].invalid">
              <span class="invalid-message"
                *ngIf="supervisorForm.controls['clientSupervisorMiddleName'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Designation </label>
            <input type="text" class="im-input mw-100" id="clientSupervisorRole" formControlName="clientSupervisorRole"
              placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSupervisorRole'].touched && supervisorForm.controls['clientSupervisorRole'].invalid">
              <span class="invalid-message" *ngIf="supervisorForm.controls['clientSupervisorRole'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-4 form-group processing-dropdown">
            <label class="label-cls required"> Country Code </label>
            <p-dropdown [options]="countryList" styleClass="h1b-err-dropdown" [filter]="false"
              formControlName="clientSupervisorPhoneCode" optionLabel="displayPhoneCode" optionValue="countryCode"
              placeholder="Select">
            </p-dropdown>
            <div
              *ngIf="supervisorForm.controls['clientSupervisorPhoneCode'].touched && supervisorForm.controls['clientSupervisorPhoneCode'].invalid">
              <span class="invalid-message"
                *ngIf="supervisorForm.controls['clientSupervisorPhoneCode'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Phone No </label>
            <input type="text" class="im-input mw-100" id="clientSupervisorPhoneNo"
              formControlName="clientSupervisorPhoneNo" maxlength="10" minlength="10" placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSupervisorPhoneNo'].touched && supervisorForm.controls['clientSupervisorPhoneNo'].invalid">
              <span class="invalid-message" *ngIf="supervisorForm.controls['clientSupervisorPhoneNo'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message"
                *ngIf="supervisorForm.controls['clientSupervisorPhoneNo'].errors?.pattern || supervisorForm.controls['clientSupervisorPhoneNo'].errors?.minlength || supervisorForm.controls['clientSupervisorPhoneNo'].errors?.maxlength">
                {{getGlobalErrorMessages('INVALID_NUMBER')}}
              </span>
            </div>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Email </label>
            <input type="text" class="im-input mw-100" id="clientSuperviorEmail" formControlName="clientSuperviorEmail"
              placeholder="Enter" pInputText />
            <div
              *ngIf="supervisorForm.controls['clientSuperviorEmail'].touched && supervisorForm.controls['clientSuperviorEmail'].invalid">
              <span class="invalid-message" *ngIf="supervisorForm.controls['clientSuperviorEmail'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message" *ngIf="supervisorForm.controls['clientSuperviorEmail'].errors?.pattern">
                {{getGlobalErrorMessages('INVALID_EMAIL')}}
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-12">
        <p-divider></p-divider>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-12" *ngIf="isSimplifyH1b ? false : true">
        <button pButton type="submit" label="Save" title="Save" class="save-btn"
          [ngClass]="supervisorForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="supervisorForm.invalid  || disabledControls"></button>
      </div>
      <div class="col-md-12 alignRight" *ngIf="isSimplifyH1b ? true : false">
        <button pButton type="submit" label="Save & Continue" title="Save" class="save-btn pull-right"
          [ngClass]="supervisorForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="supervisorForm.invalid  || disabledControls"></button>
      </div>
    </div>
  </form>
</div>
