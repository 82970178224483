import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service'
import moment from 'moment';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserPrivacyModalComponent } from 'src/app/modules/shared/modal/user-privacy-modal/user-privacy-modal.component';
import { UserRoleService } from 'src/app/services/user-role.service';
import { TourModalComponent } from 'src/app/modules/shared/modal/tour-modal/tour-modal.component';
@Component({
  selector: 'dashboard-beneficiary',
  templateUrl: './dashboard-beneficiary.component.html',
  styleUrls: ['./dashboard-beneficiary.component.scss']
})
export class DashboardBeneficiaryComponent implements OnInit {
  public userName: string;
  public userTimeNow: string;
  dialogContent;
  policyId;
  constructor(private router: Router,
    public dialog: MatDialog,
    private userRoleService: UserRoleService,
    private authenticationService: AuthenticationService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private route: ActivatedRoute) {
    this.userName = "";
    this.userTimeNow = moment(new Date()).format("dddd, MMMM Do YYYY");
  }

  ngOnInit() {
    this.getuserName();
    this.getNotifications();
    let userType;
    if (localStorage.getItem('policyAcceptanceRequired') == "true") {
      switch (sessionStorage.getItem('userTypeRole')) {
        case 'Petitioner': userType = 'PETNR'; break;
        case 'Beneficiary': userType = 'BEN'; break;
        case 'Attorney': userType = 'ATRNY'; break;
        default: userType = 'BEN';
      }
      this.userRoleService.getPolicyContent(userType, 'PRVCYPLCY').subscribe((data) => {
        if (data) {
          this.dialogContent = data.content;
          this.policyId = data.policyId;
          this.displayPrivacyPolicy();
        }
      });
    }

  }

  displayIntroDialog() {
    this.dialog.open(BeneficiaryWelcomeDialog,
      {
        height: '740px',
        width: '800px',
      }).afterClosed().subscribe(result => {
        localStorage.setItem('isFirstLogin', "false");
      });
  }

  displayPrivacyPolicy() {
    let type = 'privacy';
    const dialogRef = this.dialog.open(UserPrivacyModalComponent, {
      width: '800px',
      height: 'auto',
      data: {
        update: {
          type: type,
          policyId: this.policyId,
          dialogContent: this.dialogContent
        }
      }
    }).afterClosed().subscribe(result => {
      if (result == 'acceptPolicy') {
        if (localStorage.getItem('isFirstLogin') == "true")
          this.displayIntroDialog();
      }
    });
  }

  addNewTask() {
    //this.router.navigate(['petitioner-landing/dashboard/task',  { id: 5 }]);
    this.router.navigate(['petitioner-landing/dashboard/task/task-details', 0]);
  }
  getuserName() {
    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        this.userName = data.firstname;
        //this.userName=  data.firstname+data.lastName;
      }
    });
  }

  getNotifications() {
    let payload = {
      isActionable: null,
      isNotifyGroup: null,
      isRead: null,
      isStarred: null,
      pageNumber: 0,
      recordsPerPage: 0
    }
    this.dashboardWidgetsService.getNotifications(payload).subscribe((response) => {
      this.dashboardWidgetsService.setBellNotifyCount(response?.unReadCount || 0);
    });
  }

}

@Component({
  selector: 'beneficiary-welcome-dialog',
  templateUrl: 'beneficiary-welcome-dialog.html',
})
export class BeneficiaryWelcomeDialog {
  constructor(public dialogRef: MatDialogRef<BeneficiaryWelcomeDialog>,
    public dialog: MatDialog) {

  }

  displayStartTourDialog() {
    this.dialogRef.close();
    this.dialog.open(TourModalComponent,
      {
        width: '650px',
        data: {
          update: {
            userType: sessionStorage.getItem('userTypeRole')
          }
        }
      }).afterClosed().subscribe(result => {

      });
  }
}
