export const NewPetitionStatusArr: string[] = ['PETITIONINPROGRESS', 'PETITIONNEW',
  'RFEISSUED', 'RFEINPROGRESS', 'NOIRISSUED', 'NOIDISSUED', 'I140INITIATED',
  'I140INPROGRESS', 'PERMFILED', 'PERMINITIATED', 'PERMINPROGRESS', 'PWDINITIATED',
  'PWDINPROGRESS', 'RECINITIATED', 'RECINPROGRESS', 'NEW', 'I130INITIATED',
  'I130INPROGRESS', 'I130RFEISS'];
export const InprogressPetitionStatusArr: string[] = ['FILED', 'PETITIONFILED',
  'I140FILED', 'PWDREQUESTED', 'I140APPROVED', 'I140RFEISS', 'PERMAUDITNOTCISS',
  'PERMCERTIFIED', 'RECCOMPLETED', 'CERTIFIED', 'AUDITNOTCISS', 'CERTEXPIRED',
  'DENIED', 'INPROGRESS', 'RFERESPONDED', 'I130FILED', 'PWDPENDING'];
export const CompletedPetitionStatusArr: string[] = ['PETITIONDENIED', 'PETITIONAPPROVED',
  'PETITIONWITHDRAWN', 'I140DENIED', 'I140WITHDRAWN', 'PERMCERTEXPIRED', 'PERMDENIED',
  'PWDNOTISSUED', 'PWDISSUED', 'I130DENIED', 'I130WITHDRAWN', 'I130APPROVED'];
