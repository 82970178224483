<div class="fluid-container">
    <form (ngSubmit)="postOccupationDetermination()" #occupationDeterminationForm="ngForm" class="pb-3 pt-2">
        <div class="main-container">
            <div class="row mb-3">
                <div class="col-md-6 form-group input-disabled">
                    <label class="label-cls required">LCA Code</label>
                    <input pInputText type="text" name="lcaCode" [(ngModel)]="lcaCode" [disabled]="disabledControls"
                        readonly class="im-input  mw-100 disable" required />
                </div>
                <div class="col-md-6 form-group input-disabled">
                    <label class="label-cls required">Case Number</label>
                    <input pInputText type="text" name="caseNumber" [(ngModel)]="caseNumber"
                        [disabled]="disabledControls" readonly class="im-input disable  mw-100" required />
                </div>
            </div>
            <div class="row col-md-12 mb-3 mt-3">
                <label class="label-cls listLca">View the 'List of Lca Duties' the beneficiary will perform</label>
                <p-listbox [options]="topOohLcaDutyList" optionLabel="dutyDescription" class="duties-lis-box"
                    class="duties-lis-box" optionValue="id">
                    <ng-template let-item let-i="index" pTemplate="i">
                        <div class="country-item">
                            <div class="row">
                                <div class="col-md-1 p-0">
                                    <p class="index">{{i+1}}</p>
                                </div>
                                <div class="col-md-11 p-0">
                                    <label class="label-cls">{{item.dutyDescription}}</label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-listbox>
            </div>

            <div class="row mt-3 mb-3 pt-3">
                <div class="col-md-12">
                    <label class="label-cls mb-2"><b>
                            Select the 'LCA' list of duties that a '{{positionTitle}}' will perform to match the
                            '{{positionTitle}}' list of duties
                        </b>
                    </label>
                    <div class="row col-md-12 p-0">
                        <div class="col-md-12 mb-3 p-0 row">
                            <div class="col-md-5 p-0">
                                <h6 class="ooh-text"> List of Duties - {{positionTitle}}</h6>
                            </div>
                            <div class="col-md-7">
                                <h6 class="ooh-text required"> LCA / OOH List of Duties</h6>
                            </div>
                        </div>
                        <div class="col-md-12 p-0 positionDutyList mb-4 row"
                            *ngFor="let item of positionDutyList; let i=index">
                            <div class="col-md-5 pl-0 duties-list-left">
                                <div class="row p-0">
                                    <div class="col-md-2 p-0 ">
                                        <p class="index">{{i+1}}</p>
                                    </div>
                                    <div class="col-md-10 p-0 label-cls">{{item.name}}</div>
                                </div>
                            </div>
                            <div class="col-md-7 pr-0">
                                <div class="form-group processing-dropdown">
                                    <p-multiSelect  placeholder="Select the matching LCA duty" appendTo="body" class="multiselect-custom"
                                        [options]="topOohLcaDutyList" [disabled]="disabledControls"
                                        [(ngModel)]="item.selectedOohDuties" optionLabel="dutyDescription"
                                        [filter]="false" name="selectedOohDuties-{{i}}" virtualScroll="true"
                                        [showHeader]="false" optionValue="id" (onChange)="onChange($event, item)"
                                        required>
                                        <ng-template let-oohDuty pTemplate="item">
                                            <div class="oohDuty-item">
                                                <div class="list text-truncate">{{oohDuty.dutyDescription}}</div>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="row">
                <div class="col-md-12">
                    <button pButton type="submit" label="Save" class="save-btn"
                        [ngClass]="occupationDeterminationForm.invalid  || positionDutyList.length <= 0 || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
                        [disabled]="occupationDeterminationForm.invalid || positionDutyList.length <= 0 || disabledControls"></button>
                </div>
            </div>
        </div>
    </form>
</div>