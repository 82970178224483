import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PrimaryData, TaskStep } from 'app-models';
import { I140FileUploadConfiguration } from 'app-models';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';

@Component({
  selector: 'h1b-petitioner-details',
  templateUrl: './h1b-petitioner-details.component.html',
  styleUrls: ['./h1b-petitioner-details.component.scss']
})
export class H1bPetitionerDetailsComponent implements OnInit {
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;

  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  petitionerDetails;
  petitionerDocumentList = [];

  primaryData: PrimaryData;
  isForeignCompany: boolean;

  constructor(
    private store: Store<VisaExtendedState>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
  ) {
    this.isForeignCompany = false;
  }

  ngOnInit() {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  handleDialogClose() {
    this.dynamicDialogRef.close();
  }
}
