<div class="fluid-container p-3">
  <!-- <h5>
    Participation As Judge
  </h5> -->
  <h6 class="heading p-text-bold">Participation As Judge</h6>
  <div class="row mt-2">
    <label>Details & Explanations</label>

    <div class="col-12 px-0" *ngIf="!disabledControls">
      <!-- <textarea [(froalaModel)]="participationInfo.detailsAndExplanationInfo"
        name="text" [froalaEditor]="options"></textarea> -->
        <kendo-editor [(value)]="participationInfo.detailsAndExplanationInfo" (valueChange)="participationInfodetailsAndExplanationInfoeditorValueChange($event)" #participationkendoeditor style="height: 400px;" formControlName="text" name="text">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
              <kendo-toolbar-button
              title="Upload’s Image at cursor point"
              text="Upload Image"
              (click)="openparticipationimageuploaddialog()"
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>                                  
          </kendo-toolbar>
      </kendo-editor> 
      <app-upload-image-dialog #participationimageupload [editor]="participationkendoeditor"></app-upload-image-dialog>
    </div>
  </div>

  <p-divider type="dashed" class="mt-4"></p-divider>

  <!-- <label>Upload Document</label> -->
  <h6 class="heading p-text-bold mb-3">Upload Document</h6>

  <i140-file-upload style="width: 100%;"
      [fileUploadConfigurations]="fileUploadConfigurations">
    </i140-file-upload>


  <p-divider type="dashed" class="mt-4"></p-divider>  

  <div class="row">
    <div class="col-md-3">
      <button pButton type="button" [disabled]="disabledControls" (click)="saveParticipationInfo()" class="d-inline-block float-left saveBtn">Save</button>
    </div>
    <div class="col-md-6 completeStep">
      <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showMarkComplete" [disabled]="stepStatus !== 'INPROGRESS'"
      class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg stepCompleted" (click)="onMarkComplete()">
      <span class="btn-text">{{markStep}}</span>
      <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
    </button>
    <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
    </div>

  </div>
 
</div>