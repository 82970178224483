import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-alert-modal',
  templateUrl: './action-alert-modal.component.html',
  styleUrls: ['./action-alert-modal.component.scss']
})
export class ActionAlertModalComponent implements OnInit {
  buttons = [];
  dateDifference;

  constructor(
    public dialogRef: MatDialogRef<ActionAlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {

    const lcaPostedDate = new Date(this.data.lcaData.postedDate);
    const currentDate = new Date();
    this.dateDifference = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(lcaPostedDate.getFullYear(), lcaPostedDate.getMonth(), lcaPostedDate.getDate()) ) /(1000 * 60 * 60 * 24))
    
    this.buttons = (this.data.buttons) ? this.data.buttons : ['No', 'Yes'];
  }
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
