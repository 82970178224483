<div *ngIf="userType == 'Attorney'">
<label>Generated on : {{pdfTimeStamp | date:'dd-MMM-yyyy'}} </label>
<pdf-viewer [src]="pdfSrc" useBrowserLocale="true"></pdf-viewer>
<hr />
<p-accordion class="border d-block">
  <p-accordionTab [selected]="false">
    <ng-template pTemplate="header">All Documents List</ng-template>
    <ng-template pTemplate="content">
    <div class="p-datatable">
        <div class="p-datatable-thead">
      <tr class="row">
        <th class="col-4">Category Name</th>
        <th class="col-4">Document Type</th>
        <th class="col-3">Document Name</th>
        <th class="col-1"></th>
      </tr>
    </div>
    </div>
      <div  *ngFor="let item of supportingDocuments;let i=index">
      <p-table [value]="item.documentTypes" [scrollable]="true" scrollHeight="150px">
          <ng-template pTemplate="body" let-doc>
              <tr *ngIf="doc.documents.length <= 1 && doc.present">
                  <td colspan="4" style="padding: 0!important;">
                      <div class="col-4 d-inline-block content">{{item.titleSheetName}} </div>
                      <div class="col-4 d-inline-block content">{{doc.docName}} </div>
                      <div class="col-3 d-inline-block content">
                              {{doc.documents[0].fileName}}.{{doc.documents[0].fileType}}
                      </div>
                      <div class="col-1 d-inline-block content">
                          <span style="margin-left:10px;">
                              <button pButton type="button"
                                  (click)="viewDocument(doc.documents[0].fileLocation)"
                                  icon="fa fa-eye"
                                  class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                  <mat-icon class="mr-1 icn" matTooltip="View Document"
                                      class="material-icons">visibility</mat-icon>
                              </button>
                          </span>
                      </div>
                  </td>
              </tr>
              <tr *ngIf="doc.documents.length > 1 && doc.present">
                  <td colspan="4" style="border:0;padding: 0!important;">
                      <p-table [value]="doc.documents">
                          <ng-template pTemplate="body" let-singleDoc>
                  <tr><td style="padding: 0!important;">
                    <div class="col-4 d-inline-block content">{{item.titleSheetName}}</div>
                        <div class="col-4 d-inline-block content">{{doc.docName}}</div>
                            <div class="col-3 d-inline-block content">
                                {{singleDoc.fileName}}.{{singleDoc.fileType}}
                        </div>
                  <div class="col-1 d-inline-block content">
                      <span *ngIf="doc.present" style="margin-left:10px;">
                          <button pButton type="button"
                              (click)="viewDocument(singleDoc.fileLocation)"
                              class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                              <mat-icon class="mr-1 icn" matTooltip="View Document"
                                  class="material-icons">visibility</mat-icon>
                          </button>
                      </span>
                    </div>
                </td>
              </tr>
          </ng-template>
      </p-table>
      </td>

              </tr>
        </ng-template>
  </p-table>
</div>
  </ng-template>
  </p-accordionTab>  
</p-accordion>
<div *ngIf="rfeTimelineModalInput['userType'] == 'Attorney' && rfeTaskDetails && loggedInUserId == rfeTaskDetails.preparerAttorneyId">
<h6 class="text-secondary mt-4">Enter the Reviewer details so you can proceed to generate the Draft PDF & Print</h6>
<div class="mt-3">
    <div class="row">
        <div class="col-4 pl-0">
            <small class="text-secondary">Reviewers Firm Name</small>
        </div>
        <div class="col-4 pl-0">
            <small class="text-secondary">Reviewers Name</small>
        </div>
    </div>
    <div class="row">
        <div class="col-4 pl-0">
            <p-dropdown [disabled]="disableFields"  class="w-100" [options]="attorneys" [(ngModel)]="selectedAttorneyFirm" required name="selectedAttorneyFirm" optionLabel="attorneyName"  (onChange)="attorneySelected()" placeholder="Select lawfirm">
            </p-dropdown>
    </div>
    <div class="col-4 pl-0">
    <p-dropdown [disabled]="disableFields"  [options]="reviewUsersList" class="w-100" [(ngModel)]="reviewer" name="reviewer"  optionLabel="firstName" placeholder="Select Reviewer">           
        <ng-template pTemplate="selectedItem">
          <div  *ngIf="reviewer">
            <div>{{reviewer?.firstName +' '+ reviewer?.lastName}}</div>
              <!-- <div>{{selectedCountry.name}}</div> -->
          </div>
      </ng-template>
        <ng-template let-employee pTemplate="item">
          <div>
               <div>{{employee?.firstName +' '+employee?.lastName}}</div>
          </div>
      </ng-template>
      </p-dropdown>
    </div>
    <div class="col-4">
        <button [disabled]="disableFields"  class="button_whitebg p-button-outlined" type="button" pButton (click)="updateReviewerInfo();" label="Update Reviewer Info"></button>
     </div> 
    </div>
</div>
</div>

<div *ngIf="rfeTimelineModalInput['userType'] != 'Attorney' && rfeTaskDetails && loggedInUserId == rfeTaskDetails.preparerAttorneyId" class="mt-3">
    <div class="row">
        <div class="col-4 pl-0">
            <small class="text-secondary">Reviewers Name</small>
        </div>
        <div class="col-4 pl-0">
            <small class="text-secondary">Designation</small>
        </div>
    </div>
    
    <div class="row">
        <div class="col-4 pl-0">
            <p-dropdown [disabled]="disableFields"  class="w-100" [options]="reviewUsersList" class="w-100" [(ngModel)]="reviewer" name="reviewer"  optionLabel="firstName" placeholder="Select Reviewer">           
                <ng-template pTemplate="selectedItem">
                  <div  *ngIf="reviewer">
                    <div>{{reviewer?.firstName +' '+ reviewer?.lastName}}</div>
                      <!-- <div>{{selectedCountry.name}}</div> -->
                  </div>
              </ng-template>
                <ng-template let-employee pTemplate="item">
                  <div>
                       <div>{{employee?.firstName +' '+employee?.lastName}}</div>
                  </div>
              </ng-template>
              </p-dropdown>
      </div>
      <div class="col-4 pl-0">
    <input *ngIf="reviewer" [(ngModel)]="reviewer.designation" disabled />
</div>
<div class="col-4">
    <button [disabled]="disableFields"  class="button_whitebg p-button-outlined" type="button" pButton (click)="updateReviewerInfo();" label="Update Reviewer Info"></button>
 </div> 
</div>
</div>

<button [disabled]="disableFields" *ngIf="rfeTaskDetails && loggedInUserId == rfeTaskDetails.preparerAttorneyId" 
class="button_whitebg p-button-outlined mt-2" type="button" pButton (click)="notifyReviewer();" label="Notify Reviewer"></button>
<hr/>
<div *ngIf="rfeNotice && loggedInUserId == reviewerId">
<button [disabled]="disableFields" class="btn-success mt-2" type="button" pButton label="Review Complete" (click)="notifypreparer();"></button>
<button [disabled]="disableFields" (click)="displayGetMoreData = !displayGetMoreData" class="button_whitebg p-button-outlined mt-2 ml-3" type="button" pButton label="Send back for Rework"></button>
<div class="border mt-2 shadow rounded" [style]="{width: '900px'}" [hidden]="!displayGetMoreData">
    
        <div class="row p-3">
            <div class="col-10 px-0">
            <textarea style="min-height: 135px; overflow: hidden;" [rows]="5" [cols]="1000" autoResize="autoResize" class="w-100" placeholder="Enter your comment" pInputTextarea [(ngModel)]="commentFromReviewer"></textarea>
            </div>
            <div class="col-2">
            <button class="btn-success mt-2" type="button" pButton label="Send" (click)="sendCommentFromReviewer();"></button>
        </div>
        </div>
    
</div>
</div>

<div *ngIf="rfeTaskDetails && loggedInUserId == rfeTaskDetails.preparerAttorneyId && reviewerResponse">
    <small class="text-secondary">Reviewers Feedback</small>
<h6 class="text-secondary mt-2">{{reviewerResponse}}</h6>
</div>

<div *ngIf="rfeTaskDetails && loggedInUserId == rfeTaskDetails.preparerAttorneyId">
    <hr/>
<button [disabled]="disableFields"  type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" (click)="changeStatusToComplete();" label="Mark Complete" class="d-block mx-auto button_whitebg p-button-outlined"></button>
</div>

</div>


<div *ngIf="userType != 'Attorney'" class="p-3 border rounded bg-light">
    <div><strong>Last Updated By : </strong> {{ SubTaskOverView['preparerAttorneyName']}}</div>
    <hr/>
    <div><strong>Status : </strong> {{ SubTaskOverView['taskStepDetails'][5]['stepStatusDetails']['stepStatusName'] }}</div>
    <div><strong>Comment : </strong> {{ SubTaskOverView['taskStepDetails'][5]['stepNote'] }}</div>
  </div>