<div mat-dialog-title class="text-center position-relative">
    <img _ngcontent-hue-c1="" alt="Imagility Logo" src="../../../../assets/weblogo.png">
    <h5 class="mt-2"><strong>Welcome!</strong> Lets see how we can help you.</h5>
    <button mat-dialog-close mat-icon-button aria-label="Close" class="icon-button position-absolute" style="top:0;right:0">
        <mat-icon>close</mat-icon>
      </button>
</div>
<mat-dialog-content class="mat-typography text-center">
  
  <p>We understand the new immigration landscape, losses incurred and increased workload, petitioners have to bear.
    We also know the power of collaboration. Through imagility, we have leveraged technology to build a robust platform for
    Beneficiaries, Petitioners and Lawfirms to collaborate in order to overcome today's immigration challenges.
  </p>

    <h4 class="text-primary text-center"> See how it works!</h4>
    <div class="embed-responsive embed-responsive-1by1 videoPlayer mb-2">
      <iframe src="https://player.vimeo.com/video/518583749?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1280" height="720" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Imagility for Petitioners"></iframe>      </div>
      <h4 class="d-inline-block mr-2">Let's now take you on a short tour of our top features...</h4>
      <button type="button" class="btn btn-primary" (click)="displayStartTourDialog()">
        <span class="float-left">Start Tour</span>
        <i class="fa fa-angle-double-right"></i>
      </button>
      <hr/>
<div class="text-left">
  <div class="d-inline-block">
    <h4 class="d-inline-block mb-0"><strong>Assistance at any time:</strong></h4>
    <div class="text-secondary">Click on the floating help button on the top right of the page</div>
    </div>
    <button mat-dialog-close routerLink="/petitioner-landing/resources" class="rounded-pill back-button button-width-icon-left btn-pos-top-right btn button_whitebgImp float-right">
    <i class="fa fa-question-circle"></i>
    Video Help
</button>
</div>
<hr/>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close class="link-button btn">Dismiss</button>
</mat-dialog-actions>
