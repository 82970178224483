import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from "moment";
import { SchedulingService } from 'src/app/services/scheduling.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})


export class TaskComponent implements OnInit {
  @Input('obj') taskEditObj;
  obs: Subscription;
  taskForm: FormGroup
  userToken: string;
  assigneMembersList: any;
  navUrl;
  userType: any;
  filteredMembers: any;
  calendarItemId: any;
  series: any;
  taskEditDetails: any;
  taskattendes: any;
  companyId: any;
  minimumDate: any;
  assigne: any;
  taskpageLoaded: boolean = true;
  loggedInUserId: any;
  isPublic: any;
  

  constructor(private router: Router,
    private schedulingservice: SchedulingService, private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.taskForm = this.fb.group({
      attendiesList: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      description: ['', Validators.required],
      calItemTypeCode: "TASK",
      calendarItemId: null,
      isPublic: []
    })
    let currentUser: any = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log('currentUser', currentUser);
    this.loggedInUserId = currentUser.userId;

    this.companyId = sessionStorage.getItem('companyId')
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    };
    this.schedulingservice.getTeamembersList(this.userToken, this.companyId).subscribe(res => {
      // this.assigneMembersList = res['data'].map((val)=>{
      //   return {
      //     name: val.firstName + " " + val.lastName,
      //     userId: val.userId,
      //   emailId: val.email
      //   }
      // });

      console.log('Task List loggedInUserId', this.loggedInUserId)
      console.log('Task List assigneMembersList', this.assigneMembersList)
      this.assigneMembersList = res['data'].filter((val) => {
        return val.userId != this.loggedInUserId
      }).map((val) => {
        return {
          name: val.firstName + " " + val.lastName,
          userId: val.userId,
          emailId: val.email
        }
      });
    })

    console.log('Task List assigneMembersList', this.assigneMembersList)
    // this.taskForm = this.fb.group({
    //   attendiesList:['',Validators.required],
    //   startDate:['',Validators.required],
    //   startTime:['',Validators.required],
    //   description:['',Validators.required],
    //   calItemTypeCode: "TASK",
    //   calendarItemId: null,
    //   isPublic:[],
    // })

    this.minimumDate = new Date();
    this.taskpageLoaded = true;

  }

  ngOnInit(): void {
    this.calendarItemId = this.taskEditObj.id;
    this.series = this.taskEditObj.isSeries;
    this.schedulingservice.getCalendarItemDetails(this.calendarItemId, this.series).subscribe(res => {
      console.log('item', res);
      this.taskEditDetails = res['data'];
      if (this.taskEditDetails) {
        this.assigne = this.taskEditDetails.required[0].firstName + " " + this.taskEditDetails.required[0].lastName;
        this.taskattendes = {
          "name": this.assigne,
          "userId": parseInt(this.taskEditDetails.required[0].userId),
          "emailId": this.taskEditDetails.required[0].emailId
        }
        setTimeout(() => {
          this.taskForm = this.fb.group({
            attendiesList: [
              this.taskattendes, Validators.required
            ],
            startDate: [moment(this.taskEditDetails.startDate).format('MM/DD/YYYY'), Validators.required],
            startTime: [this.taskEditDetails.startTime, Validators.required],
            description: this.taskEditDetails.description,
            calItemTypeCode: "TASK",
            calendarItemId: this.taskEditDetails.id,
            isPublic: this.taskEditDetails.isPublic
          })
        }, 500);
      }
      else {
        this.taskForm = this.fb.group({
          attendiesList: ['', Validators.required],
          startDate: ['', Validators.required],
          startTime: ['', Validators.required],
          description: ['', Validators.required],
          calItemTypeCode: "TASK",
          calendarItemId: null,
          isPublic: []
        })
      }
    },
      error => {
        console.log(error)
      }
    )


    this.userToken = sessionStorage.getItem('UserToken');

    // if(this.taskEditDetails){
    //   this.taskForm = this.fb.group({
    //     attendiesList:['',Validators.required],
    //     startDate:moment(this.taskEditDetails.date).format('YYYY-MM-DD'),
    //     startTime:moment(this.taskEditDetails.startTime),
    //     description:this.taskEditDetails.description,
    //     calItemTypeCode: "TASK",
    //     calendarItemId: this.taskEditDetails.id

    //   })

    // } else {
    //   this.taskForm = this.fb.group({
    //     attendiesList:['',Validators.required],
    //     startDate:['',Validators.required],
    //     startTime:['',Validators.required],
    //     description:['',Validators.required],
    //     calItemTypeCode: "TASK",
    //     calendarItemId: null

    //   })

    // }
  }

  ngAfterViewInit() {
    let control = this.taskForm.get('isPublic');
    this.obs = control.valueChanges.subscribe(e => { control.setValue(e, { emitEvent: false }); });
  }

  save() {
    console.log('task')

    let taskPayload = this.taskForm.value;
    this.taskForm.value.startDate = moment(this.taskForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.taskForm.value.isPublic = parseInt(this.isPublic);

      console.log('task', taskPayload)

    //taskPayload.attendiesList = [JSON.stringify(this.taskForm.get('attendiesList').value)]
    taskPayload.attendiesList = [(this.taskForm.get('attendiesList').value)]
    this.schedulingservice.saveMeeting(taskPayload).subscribe(res => {
      console.log(res);
      this.toastr.success(res.message, 'Success');
      this.router.navigate([this.navUrl]);

    })

  }

  update() {
    let taskPayload = this.taskForm.value;
    console.log('update taskPayload ::', taskPayload)
    taskPayload.attendiesList = [(this.taskForm.get('attendiesList').value)];
    this.taskForm.value.startDate = moment(this.taskForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.taskForm.value.isPublic = parseInt(this.isPublic);
    this.taskForm.value.calItemTypeCode = 'TASK';
    this.taskForm.value.isSeries = 1;
    this.schedulingservice.updateMeeting(taskPayload).subscribe(response => {
      console.log('meeting response', response);
      this.toastr.success(response.message, 'Success');
      this.router.navigate([this.navUrl]);
    },
      error => {
        console.log('meeting error', error)
      }
    );
  }

  cancel() {
    let cancelpayload = {
      "calendarId": this.taskEditDetails.parentId,
      "calendarItemId": this.taskEditDetails.id,
      "calendarItemType": this.taskEditDetails.calenderItemType,
      "isCancelAllItems": true
    }
    this.schedulingservice.cancelCalendarItem(cancelpayload).subscribe(res => {
      if (res) {
        this.router.navigate([this.navUrl]);
        this.toastr.success(res.message, 'Success');
      }
    })
  }

  redirectoCalendar() {
    this.router.navigate([this.navUrl]);
  }

}
