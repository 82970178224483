<form [formGroup]="researchForm">
    <div class="row col-md-12 mb-3">
      <!-- <h5 class="sub-title">Research Contributions</h5> -->
      <h6 class="heading p-text-bold">Research Contributions</h6>
    </div>
    <div class="row">
      <div class="row col-md-12 pl-0">
        <div class="col-12 pl-0">
          <div class="row w-100">
            <div class="col-md-8 form-group input-disabled">
              <label class="label-cls"> Meeting Place </label>
              <input class="im-input mw-100" [readonly]="disabledControls"[class.disabled]="disabledControls" id="meetingPlace" formControlName="meetingPlace" type="text" pInputText
                placeholder="Enter" />
              <div *ngIf="researchForm.get('meetingPlace').touched && researchForm.get('meetingPlace').invalid">
                  <span class="invalid-message" *ngIf="researchForm.get('meetingPlace').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
            </div>
            <div class="col-md-4 form-group input-disabled">
              <label class="label-cls"> Meeting Date</label>

              <p-calendar appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                name="meetingDate" formControlName="meetingDate" [monthNavigator]="true" [yearNavigator]="true"
                [maxDate]="currentDate" yearRange="1950:2050">
              </p-calendar>

              <div *ngIf="!researchForm.get('meetingDate').valid && researchForm.get('meetingDate').touched && researchForm.get('meetingDate').errors">
              <span class="invalid-message" *ngIf="researchForm.get('meetingDate').errors['required']">
                Date Filed is required.
              </span>
            </div>
            </div>

          </div>
          <div class="row w-100">
            <div class="col-3 px-0 addBtnHover">
              <button type="submit" [disabled]="disabledControls || !researchForm.valid" (click)="saveResearch();"
                class="btn btn-primary btn-add ml-3">
                <i class="fa fa-plus"></i>{{ addEditButtonText }} </button>
            </div>
            <div class="col-2 px-0">
              <!-- <button pButton type="button"  [disabled]="disabledControls" label="Cancel" pRipple class="p-button-secondary ml-2"
                [disabled]="disabledControls" (click)="cancelForm()">
              </button> -->
            </div>
          </div>
          <div class="row w-100 mt-4 mb-4">
            <div class="col-12 p-0">
              <div class="container-fluid">
                <!-- <label class="text-primary"><b>Added Details</b></label> -->
                <h6 class="heading p-text-bold mb-3">Added Details</h6>

                <p-table [value]="researchList" class="table-striped-simple"  *ngIf="researchList.length > 0" [scrollable]="true" scrollHeight="150px">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="col-3">Meeting Place</th>
                      <th class="col-3">Meeting Date</th>
                      <th class="col-2 text-center">Action</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-doc>
                    <tr>
                      <td class="col-3">{{doc.meetingPlace}}</td>
                      <td class="col-3">{{doc.meetingDate}}</td>
                      <td class="col-2 text-center">
                        <button pButton type="button"  [disabled]="disabledControls" title="View Document" 
                          (click)="editDocument(doc)"
                          class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                          <mat-icon class="mr-1 icn" matTooltip="Edit" >edit</mat-icon>
                        </button>
                        <button pButton type="button"  [disabled]="disabledControls" title="Delete Document" 
                        
                          (click)="deleteDocument(doc.resContributionId)"
                          class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                          <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                        </button>

                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div *ngIf="researchList.length == 0" class="border w-100 p-2">
                  <p class="text-secondary m-0"> Currently No details added!</p>
                  </div>
              </div>
            </div>
          </div>
          <div class="row w-100">

            <div class="col-md-12 p-0 ">
              <div class="col-md-12">
                <p-divider type="dashed" class="mt-4"></p-divider>

                <!-- <label><b>Upload Document</b></label> -->
                <h6 class="heading p-text-bold mb-3">Upload Document</h6>
              </div>

                <div class="col-md-12 p-0 align-action">
                <i140-file-upload style="width: 100%;"
                  [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
                </i140-file-upload>
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-md-12 p-0 beneficiary-experience-froala-editor">
              <div class="container-fluid">
                <p-divider type="dashed" class="mt-4"></p-divider>

                <!-- <textarea [(froalaModel)]="additionalInfo" name="text" [class.disabled]="disabledControls" [froalaEditor]="options">
            </textarea> -->

            <kendo-editor [(value)]="additionalInfo" (valueChange)="researchcontributioneditorValueChange($event)" #researchcontributionkendoeditor style="height: 400px;" formControlName="text" name="text">
              <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                  <kendo-toolbar-button
                  title="Upload’s Image at cursor point"
                  text="Upload Image"
                  (click)="openresearchcontributionimageuploaddialog()"
                  ></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>                                  
              </kendo-toolbar>
          </kendo-editor> 
          <app-upload-image-dialog #researchcontributionimageupload [editor]="researchcontributionkendoeditor"></app-upload-image-dialog> 
                <div class="col-md-12 p-0 ">
                  <button label="Save" class="d-block mt-4 btn btn-primary" [disabled]="disabledControls" (click)="saveSummaryInfo()">Save</button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

<div class="row">
  <div class="col-md-3">
  </div>
  <div class="col-md-6 completeStep">
    <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showCompleteButton" [disabled]="stepStatus !== 'INPROGRESS'"
    class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg stepCompleted" (click)="onMarkComplete()">
    <span class="btn-text">{{markStep}}</span>
    <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
  </button>
  <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
  </div>

</div>

</form>