<p-tree [value]="supportingDocumentsTree" 
    [draggableNodes]="true" 
    [droppableNodes]="true" 
    draggableScope="self" 
    [validateDrop]="true"
    (onNodeDrop)="onDrop($event)"
    droppableScope="self">
    <ng-template let-node pTemplate="parent">
        <div class="row mt-3 mb-2" >
            <div class="col-10 pl-0">
                <p-inplace *ngIf="node.class != 'custom'" #inplace closable="closable" [style]="{'min-height':'33px'}" class="titleSheet">
                    <ng-template pTemplate="display">
                        <div class="d-inline"> <strong>{{node.data.titleSheetName}}</strong></div>
                        <label class="text-primary ml-2">(Edit Title)</label>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input type="text" [(ngModel)]="node.data.titleSheetName" pInputText>
                        <button pButton type="button" label="Save" class="ml-2 button-success" (click)="renameTitleSheet(node.data,$event)"></button>
                        </ng-template>
                </p-inplace> 
                <p-inplace *ngIf="node.class == 'custom'" #inplaceCustom closable="closable" [style]="{'min-height':'33px'}" class="titleSheet">
                    <ng-template pTemplate="display">
                        <div class="d-inline"> <strong>{{node.data.categoryName}}</strong></div>
                        <label class="text-primary ml-2">(Edit Title)</label>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input type="text" [(ngModel)]="node.data.categoryName" pInputText>
                        <button pButton type="button" label="Save" class="ml-2 button-success" (click)="renameCustomTitleSheet($event)"></button>
                    </ng-template>
                </p-inplace>         
            </div>
        <div *ngIf="node.class != 'custom'" class="col-2 text-right">
            <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
            <i class="fa fa-ellipsis-v text-secondary ml-0 mr-4" aria-hidden="true"></i>
        </div>


        <div *ngIf="node.class == 'custom'" class="pl-0 col-2">
          <button [disabled]="node.children.length > 0" pButton type="button" label="Delete Title sheet" (click)="deleteTitleSheet(node)" class="linkButton"></button>
        </div>
    </div>
    <div class="p-datatable p-datatable-header border mt-3 ml-n4" style="width:100.9%">
    <div class="p-datatable-thead">
        <tr style="min-width: 100%;display: inline-table;">
            <th style="width:40%">Document Type</th>
            <th style="width:40%">Document Name</th>
            <th><span class="ml-4">Action</span></th>
        </tr>
    </div>
    <div *ngIf="node.class == 'custom' && node.children.length == 0" class="p-datatable-tbody">
      <tr>
        <td><small class="text-secondary">Drag & Drop related documents to this title sheet.</small> </td>
      </tr>
    </div>
</div>
    </ng-template>
    <ng-template let-childNode pTemplate="child">
    <div class="p-datatable">
                <div class="p-datatable-tbody" *ngFor="let singleDoc of childNode.data.documents;let i=index">
                  <div *ngIf="i > 0" class="border"></div>
                    <tr>
                        <td style="width:41%" >{{childNode.data.docName}} </td>
                        <td style="width:41%"><span>
                                {{singleDoc.fileName}}.{{singleDoc.fileType}}</span>
                        </td>
                        <td class="text-right pr-0">
                            <span class="align-top">
                                <button pButton type="button"
                                    (click)="viewDocument(singleDoc.fileLocation)"
                                    class="p-0 p-button-icon-only bg-white border-0 text-secondary d-inline">
                                    <mat-icon class="mr-1 icn" matTooltip="View Document"
                                        class="material-icons">visibility</mat-icon>
                                </button>
                                <button pButton type="button"
                                    [ngClass]="{'invisible':childNode.data.class == 'customChild'}"
                                    (click)="confirmDelete(singleDoc.documentId)"
                                    class="p-0 p-button-icon-only bg-white border-0 text-secondary d-inline delete-button">
                                    <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                        class="material-icons">delete</mat-icon>
                                </button>
                            </span>
                            <div class="d-inline pl-5">
                                <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                                <i class="fa fa-ellipsis-v text-secondary ml-0 mr-2" aria-hidden="true"></i>
                            </div>
                        </td>

                    </tr>
                    </div>
                </div>
    </ng-template>
    </p-tree>
    <button type="button" [disabled]="!titleSheetButtonEnabled" iconPos="left" pButton icon="fa fa-plus" (click)="addNewTitleSheet();" label="Add New Title Sheet" class="linkButton"></button>
    <hr/>
    <button type="button" class="btn-success" pButton (click)="saveOrganizedData();" label="Save"></button>

    <p-confirmDialog header="Delete" key="organize"></p-confirmDialog>