import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';

@Injectable({
  providedIn: 'root'
})
export class UpdateDecisionService {

  constructor(private httpClient: HttpClient) { }

  getUPdateDecisionDetails(visaType: string, caseType: string, caseId) {
    return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/decision`)
      .pipe(map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      }));
  }

  getStatsReponses(caseType: string) {
    return this.httpClient.get(`/gc/perm/${caseType === 'I130' ? 'I130STAT' : 'I140STAT'}/fetchLookupValues`)
      .pipe(map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      }));
  }

  postUPdateDecisionDetails(visaType: string, caseType, caseId, payload: {}) {
    return this.httpClient.post(`/${visaType}/${caseType}/${caseId}/decision`, payload)
      .pipe(map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200)) {
          return response;
        }
      }));
  }

}
