import { Component, ViewContainerRef, Input, ViewChild, ComponentFactoryResolver, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ComponentLoaderService } from '../../../services/component-loader.service';
import { ChangeDetectorRef } from '@angular/core';
import { I140FileUploadConfiguration, PrimaryData, TaskStep } from 'app-models';
import { DialogService } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-component-loader',
  templateUrl: './component-loader-component.html',
  styleUrls: ['./component-loader-component.sass'],
  providers: [DialogService]
})
export class ComponentLoaderComponent implements AfterViewInit {
  @Input() rendercomponent: any;
  @Input() sublabel: any;
  @Input() validation: any;
  @Input() readonly: any;
  @Input() minLength: any;
  @Input() maxLength: any;
  @Input() placeHolders: any;
  @Input() formControlName: any;
  @Input() value: any;
  @Input() groupElementProperty: any;
  @Input() visaType: any;
  @Input() typeOfButton: string;
  @Input() group: FormGroup;
  @Input() inputParams: any;
  @Input() groupName: any;
  @Input() fileUploadConfigurations: I140FileUploadConfiguration;
  @Input() inputData: PrimaryData = null;
  @Output()
  isFileListModified: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() fieldConfiguration: any;
  @Input() templateTaskTypeId: any;
  @Input() i485SuperTask: any;
  @Input() ds160SuperTask:any;
  @Input() subTaskDetails:any;
  @Input() formBuilderInfo:any;
  @Input() htmlRenderObj:any;


  @ViewChild('componentHost', { read: ViewContainerRef }) componentHost: ViewContainerRef;
  private AllComponents = ComponentLoaderService.getComponent;
  fileUploadConfiguration: I140FileUploadConfiguration;
  primaryData: PrimaryData;
  stepDetails: TaskStep;
  interval;
  VdPhoneComponent: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.getComponents();
  }

  loadComponent() {
    if (this.componentHost) {
      this.componentHost.clear();
    }
    if (typeof this.componentHost !== 'undefined') {
      const componentFactory = this.componentFactoryResolver?.resolveComponentFactory(this.AllComponents[this.rendercomponent]);
      const componentRef = this.componentHost.createComponent(componentFactory);
      (componentRef.instance as any).minLength = this.minLength;
      (componentRef.instance as any).maxLength = this.maxLength;
      (componentRef.instance as any).placeHolders = this.placeHolders;
      (componentRef.instance as any).groupElementProperty = this.groupElementProperty;
      (componentRef.instance as any).formControlName = this.formControlName;
      (componentRef.instance as any).value = this.value;
      (componentRef.instance as any).group = this.group;
      (componentRef.instance as any).inputParams = this.inputParams;
      (componentRef.instance as any).groupName = this.groupName;
      (componentRef.instance as any).fileUploadConfigurations = this.fileUploadConfigurations;
      (componentRef.instance as any).fieldConfiguration = this.fieldConfiguration;
      (componentRef.instance as any).inputData = this.inputData;
      (componentRef.instance as any).templateTaskTypeId = this.templateTaskTypeId;
      (componentRef.instance as any).subTaskDetails = this.subTaskDetails;

      if (this.rendercomponent == 'FileUploadLibraryComponent') {
        (componentRef.instance as any).primaryData = this.primaryData;
        (componentRef.instance as any).fileUploadConfigurations = this.fileUploadConfiguration;
      }

      if (this.rendercomponent == 'StepStatusUpdateComponentLibraryComponent') {
        (componentRef.instance as any).stepDetails = this.stepDetails;
        (componentRef.instance as any).type = this.typeOfButton;
        (componentRef.instance as any).visaType = this.visaType;
      }

      if (this.rendercomponent &&
        ['VdConfigPetitionSubTaskComponent', 'VdH4TaskDetailsComponent'].includes(this.rendercomponent)) {
        (componentRef.instance as any).i485SuperTask = this.i485SuperTask;
        (componentRef.instance as any).ds160SuperTask = this.ds160SuperTask;
      }

      if (this.rendercomponent == 'LibPreviewInvoice' || this.rendercomponent == 'LibJobPostingDetailsComponent') {
        (componentRef.instance as any).htmlRenderObj = this.htmlRenderObj;
      }


      (componentRef.instance as any).formBuilderInfo = this.formBuilderInfo;  //added for forms builder

      componentRef?.changeDetectorRef?.detectChanges();

      // if (this.rendercomponent && ['VdConfigPetitionSubTaskComponent'].includes(this.rendercomponent)) {
      //   (componentRef.instance as any).customOnChanges();
      // }


      // } catch (error) {
      //   console.log('componnet loadedr error', this.rendercomponent, error);
      // }

      // (componentRef.instance as any).primaryData = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  getComponents() {
    setTimeout(() => {
      this.loadComponent();
    }, 100);
  }
  // ngOnDestroy() {
  // clearInterval(this.interval);
  // }



}
