import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulingRoutingModule } from './scheduling-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ImacalendarComponent } from './imacalendar/imacalendar.component';
import { TabviewComponent } from './tabview/tabview.component';
import { MeetingComponent } from './meeting/meeting.component';
import { EventComponent } from './event/event.component';
import { TaskComponent } from './task/task.component';
import { CourtdateComponent } from './courtdate/courtdate.component';
import { ReminderComponent } from './reminder/reminder.component';
import { MarktaskcompleteComponent } from './marktaskcomplete/marktaskcomplete.component';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/menu';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import { EditorModule } from "@progress/kendo-angular-editor";

import { ChipsModule } from 'primeng/chips';
import { RadioButtonModule } from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';


// import { MaterialModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';

import { DialogModule } from 'primeng/dialog';
import { MatRadioModule } from '@angular/material/radio';
// import {MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatCardModule } from '@angular/material/card';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FilterPipe } from './pipes/filter.pipe';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarWidgetComponent } from './calendar-widget/calendar-widget.component';
import { MarkTaskCompleteComponent } from './mark-task-complete/mark-task-complete.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from '../shared/shared.module';
import { EditEventComponent } from './edit-event/edit-event.component';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'dc8268c7-af8c-4dc3-a545-12e221ddf6cb',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: window.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read','Calendars.ReadWrite']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

export function storageFactory() : OAuthStorage {
  return localStorage
}


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ImacalendarComponent,
    TabviewComponent,
    MeetingComponent,
    EventComponent,
    EditEventComponent,
    TaskComponent,
    CourtdateComponent,
    ReminderComponent,
    MarktaskcompleteComponent,
    FilterPipe,
    CalendarWidgetComponent,
    MarkTaskCompleteComponent
  ],
  imports: [
    CommonModule,
    SchedulingRoutingModule,
    OAuthModule.forRoot(),
    SharedModule,
    DropdownModule,
    TabViewModule,
    MenuModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    FullCalendarModule,
    DialogModule,
    MatRadioModule,
    CalendarModule,
    ChipsModule,
    RadioButtonModule,
    MatDatepickerModule,
    MatCardModule,
    CalendarModule,
    FileUploadModule,
    HttpClientModule,
    AutoCompleteModule,
    TableModule,
    ButtonModule,
    EditorModule,
    DynamicDialogModule
  ],
  providers: [
    { 
      provide: OAuthStorage, 
      useFactory: storageFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [ImacalendarComponent, MsalRedirectComponent],
  exports: [CalendarWidgetComponent]
})
export class SchedulingModule { }
