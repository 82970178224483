import { Component, OnInit, Input } from '@angular/core';
import { Router,NavigationEnd,Event } from '@angular/router';
import { VIDEOS } from '../../../../data/constants/video-doc.data';
import { MatDialog } from '@angular/material/dialog';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { JoyrideService,JoyrideStepsContainerService,JoyrideStepService } from 'ngx-joyride';
@Component({
  selector: 'app-guided-tour-steps',
  templateUrl: './guided-tour-steps.component.html',
  styleUrls: ['./guided-tour-steps.component.scss']
})
export class GuidedTourStepsComponent implements OnInit {
  @Input() content;
  userType;
  resourceLink;
  videos: any[] = [];
  videoLink;
  current;
  total;

  constructor(private router: Router,
    public dialog: MatDialog,
    private joyrideStepsContainerService: JoyrideStepsContainerService,
    private joyrideStepService:JoyrideStepService,
    private joyrideService: JoyrideService) { }

  ngOnInit() {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.setValues();
  }

  setValues(){
    
    if (this.userType == 'Petitioner') {
      this.resourceLink = '/petitioner-landing/resources';
      this.videos = VIDEOS.PETITIONER_VIDEOS;
    } else if (this.userType == 'Beneficiary') {
      this.resourceLink = '/beneficiary-landing/resources';
      this.videos = VIDEOS.BENEFICIARY_VIDEOS;
    } else if (this.userType == 'Attorney') {
      this.resourceLink = '/attorney-landing/resources';
      this.videos = VIDEOS.ATTORNEY_VIDEOS;
    }
    this.current = this.joyrideStepsContainerService['currentStepIndex']+1;
    this.total = this.joyrideStepsContainerService['steps'].length;
  }

  routeToResourcePage() {
    this.close();
    this.router.navigate([this.resourceLink]);
  }

  openVideo(){
      this.dialog.open(VideoModalComponent,
        {
          width: '650px',
          data: { update: { 
            videoLink: this.content.videoLink}
          }
        });
    
  }

  close(){
    this.joyrideService.closeTour();
  }

  nextOne(){
    if(this.userType === 'Beneficiary'){
      console.log("step name",this.joyrideStepsContainerService['getStepName'])

      this.router.navigate(['beneficiary-landing/dashboard']);
    
     
    }
    this.joyrideStepService.next();
   
  }

  prevOne(){
    this.joyrideStepService.prev();
  }

}
