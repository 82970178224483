<div class="fluid-container">
    <!-- <h5 class="ml-3"></h5> -->
    <h6 class="heading p-text-bold ml-3">Authorship and publication of books and articles</h6>
    <form [formGroup]="formAuthorPublication" (ngSubmit)="onSubmit()">
    <div class="row mt-3">
        <div class="col-md-6">
            <label class="required">Publication Name</label>
            <input type="text" class="im-input" placeholder="Enter" formControlName="title">
            <span  class="invalid-message" *ngIf="formAuthorPublication.get('title').touched && formAuthorPublication.get('title').errors?.required">Publication Name required</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
        <button type="submit" [disabled]="disabledControls || !formAuthorPublication.valid"  class="btn btn-primary btn-add mt-3" >
            <i aria-hidden="true" class="fa fa-plus-circle"></i>{{btnText}}</button>

        <!-- <button pButton type="button" *ngIf="isEdit" class="p-button-outlined ml-3 mt-3 mb-3 p-3" (click)="Cancel()">Cancel</button> -->
        </div>
    </div>
  </form>
  <p-divider type="dashed" class="mt-4"></p-divider>
    <div class="row">
        <div class="col-md-12">
            <!-- <label class="addedDetails">Added Details</label> -->
            <h6 class="heading p-text-bold mb-3">Added Details</h6>
            <textarea class="im-input" rows="3" *ngIf="authorAndPublicationList.length < 0" placeholder="Currently no details added!"></textarea>

            <p-table [value]="authorAndPublicationList" *ngIf="authorAndPublicationList.length > 0" class="table-striped-simple">
                <ng-template pTemplate="header">
                  <tr>
                      <th class="col-1">Publication Name</th>
                      <th class="col-2 text-center">Action</th>
                  </tr>
              </ng-template>
                      <ng-template pTemplate="body" let-item>
                        <tr>
                          <td class="col-1" >{{item.title}}</td>
                          <td class="col-2 text-center">
                            <button pButton type="button" title="View Document" (click)="EditAuthorAndPublication(item)"
                                
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Edit" >edit</mat-icon>
                            </button>
                            <button pButton type="button" title="Delete Document" (click)="deleteAuthorAndPublication(item.id)"
                                
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                            </button>
                        
                      </td>
                      </tr>
                      </ng-template>
              </p-table> 

        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12 p-0 ">
          <i140-file-upload  
               [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
         </i140-file-upload>
        </div>
    </div>

    <p-divider type="dashed" class="mt-4"></p-divider>

    <div class="row pl-0 pr-0 profile-title pt-3 ml-3">
 
        <h6 class="heading p-text-bold mb-3">Summary</h6>
        <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
            <!-- <textarea class="w-90"
                [(froalaModel)]="template.body"
                name="text" [froalaEditor]="options">
            </textarea> -->
            <kendo-editor [(value)]="template.body" (valueChange)="authorandpublicationeditorValueChange($event)" #authorandpublicationkendoeditor style="height: 400px;" formControlName="text" name="text">
                <kendo-toolbar>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                    <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                    <kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                        <kendo-toolbar-button
                        title="Upload’s Image at cursor point"
                        text="Upload Image"
                        (click)="authorandpublicationimageuploaddialog()"
                        ></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>                                  
                </kendo-toolbar>
            </kendo-editor> 
            <app-upload-image-dialog #authorandpublicationimageupload [editor]="authorandpublicationkendoeditor"></app-upload-image-dialog>
        </div>
      </div>
      <br>

    <div class="text-center mt-4 ml-3">
        <button pButton type="button" *ngIf="!disabledControls" class="d-inline-block float-left" [disabled]="disabledControls" (click)="Save()">Save</button>
      
<div class="row">
    <div class="col-md-3">
        <button pButton type="button" *ngIf="!disabledControls" class="d-inline-block float-left saveBtn" [disabled]="disabledControls" (click)="Save()">Save</button>
    </div>
    <div class="col-md-6 completeStep">
        <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showMarkComplete" [disabled]="stepStatus !== 'INPROGRESS'"
          class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg" (click)="onMarkComplete()">
          <span class="btn-text">{{markStep}}</span>
          <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
        </button>
        <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
      </div>
</div>
