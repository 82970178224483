
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaOverallComponent } from './pa-overall/pa-overall.component';
import { PaCategorizedComponent } from './pa-categorized/pa-categorized.component';

const routes: Routes = [
    {
        path: 'over-all',
        pathMatch: 'full',
        component: PaOverallComponent
    },
    {
        path: 'pa-categorized/:category',
        pathMatch: 'full',
        component: PaCategorizedComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'over-all'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PetitionAnalysisRoutingModule { }

