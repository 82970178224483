import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReferenceLibrary } from '../../../../data/models/referenceLibrary.model';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { I140FileUploadService } from '../../../../services/i140-file-upload.service';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { environment } from '../../../../../environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { DeleteDialogConfigurations } from '../../utility/configurations/dialog.configuration';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { I140FileUploadConfiguration } from '../../../../data/models/i140UploadConfiguration';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'i140-file-upload',
  templateUrl: './i140-file-upload.component.html',
  styleUrls: ['./i140-file-upload.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class I140FileUploadComponent implements OnInit, OnChanges {

  otherName: string;
  uploadedFiles: [];
  listDocumentTypes: ReferenceLibrary[];
  envPath: any;
  configurations: I140FileUploadConfiguration;

  @Input()
  fileUploadConfigurations: I140FileUploadConfiguration;
  @Input() isProvideBenificary = false;
  observableSubscription$ = new Subject();

  constructor(
    private toastrService: ToastrService,
    private uploadService: I140FileUploadService,
    private referenceLibraryService: ReferenceLibraryService,
    public dialogService: DialogService,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.uploadedFiles = [];
    this.listDocumentTypes = [];
    this.configurations = {
      disableControls: false,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      postApiUrl: '',
      getApiUrl: '',
      deleteApiUrl: '',
      documentTypeDropdownCode: ''
    };
    this.otherName = '';
  }

  ngOnInit(): void {
  }

  onUploadHandle(event, fubauto) {
    if (this.configurations.documentTypeDropdownCode === 'OTHER' && this.otherName.trim() === '') {
      CustomErrorToastComponent.showErrorToast(this.toastrService, 'Document Type Name is required');
      fubauto.clear();
      return false;
    }
    const file: any = event.files[0];
    file.fileName = event.files[0].name;
    const payload = new FormData();
    payload.append('file', file, file.name);
    let apiUrl = this.configurations.documentTypeDropdownCode === 'OTHER' ? `${this.configurations.postApiUrl}` + `${this.configurations.documentTypeDropdownCode}` + '?otherName=' + `${this.otherName}` : `${this.configurations.postApiUrl}` + `${this.configurations.documentTypeDropdownCode}`;
    this.uploadService.uploadDocument(apiUrl, payload).subscribe((response: ImagilityBaseResponse) => {
      this.toastrService.success(response.message, 'Success');
      this.otherName = '';
      this.getDocuments();
      if (this.configurations.isDocumentTypeRequired) {
        this.configurations.documentTypeDropdownCode = '';
      }
      fubauto.clear();
    });
  }

  getDocuments() {
    this.uploadService.getUploadedDocument(this.configurations.getApiUrl).subscribe((response: ImagilityBaseResponse) => {
      this.uploadedFiles = Object.assign([], response.data);
    });
  }

  getDocumentTypeList() {
    if (this.isProvideBenificary) {
      this.uploadService.getOtherDocUploadFile(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId,
        this.dynamicDialogConfig.data.i140CaseId, 'BENOTHDOC').subscribe((response: ImagilityBaseResponse) => {
          this.listDocumentTypes = response.data;
        });
    } else {
      this.referenceLibraryService.getReferenceData(this.configurations.documentTypeDropdownCode).subscribe((response: ReferenceLibrary[]) => {
        this.listDocumentTypes = response;
      });
    }
  }

  deleteDocumentTypeLis(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.uploadService.deleteUploadDocument(this.configurations.deleteApiUrl + `${input.id}`).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastrService.success(response.message, 'Success');
          this.getDocuments();
        })
      }
    });
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.fileUploadConfigurations && e.fileUploadConfigurations.currentValue) {
      this.configurations = e.fileUploadConfigurations.currentValue;
      if (this.configurations.isDocumentTypeRequired) {
        this.getDocumentTypeList();
      }
      if (this.configurations.documentTypeDropdownCode) {
        this.getDocuments();
      }
    }
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }

}
