<!-- 
     <form #emailForm="ngForm">    
    <div class="{{'float-left col-md-'+ customColummn}}">
    <div class="emailAddress">
        <input 
            type="email"
            pattern={{emailPattern}}
            [(ngModel)]="data.email" 
            placeholder="email address"   
            #email="ngModel"
            name="email"
            class="form-control elementCount"
            required
        />       
        <div class="error"><span *ngIf="email.invalid && (email.dirty || email.touched)" >Please enter valid email</span></div>   
   </div>
   </div>
   </form> -->

   <form [formGroup]="Email">
   <div class="{{'float-left overflowHidden col-md-'+ customColummn}}">
    <div class="emailAddress">
        <input 
            type="email"
            placeholder="email address"   
            class="form-control elementCount"
            formControlName="email"
            required
        />       
        <!-- <div class="error"><span *ngIf="email.invalid && (email.dirty || email.touched)" >Please enter valid email</span></div>    -->
   </div>
   </div>
   </form>

