import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AttorneyInvoiceContainerComponent } from "./attorney-invoice-container/attorney-invoice-container.component";
import { BillingInvoiceComponent } from "./billing-invoice/billing-invoice.component";
import { ClientGenerateInvoiceComponent } from "./client-generate-invoice/client-generate-invoice.component";
import { ClientsTabComponent } from "./clients-tab/clients-tab.component";
import { GenerateInvoiceComponent } from "./generate-invoice/generate-invoice.component";
import { InvoiceReceiptComponent } from "./invoice-receipt/invoice-receipt.component";
import { PreviewComponent } from "./preview/preview.component";
import { SendReceiptComponent } from "./send-receipt/send-receipt.component";

const routes: Routes = [
  {
    path: "",
    component: AttorneyInvoiceContainerComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "billing-invoices",
      },
      {
        path: "billing-invoices",
        component: BillingInvoiceComponent,
      },
    ],
  },
  /* {
    path: 'users',
    pathMatch: 'full', 
    component: UserListComponent
  }, */
  {
    path: "client-details/:id",
    pathMatch: 'full',
    component: ClientsTabComponent,
  },
  /* {
    path: 'client-details/:id',
    pathMatch: 'full',
    component: ClientsTabComponent
  }, */
  {
    path: "client-generate-invoice/:id",
    component: ClientGenerateInvoiceComponent,
  },
  {
    path: "generate-invoice",
    component: GenerateInvoiceComponent,
  },
  {
    path: "preview",
    component: PreviewComponent,
  },
  {
    path: "invoice-receipt",
    component:InvoiceReceiptComponent ,
  },
  {
    path: "send-receipt",
    component:SendReceiptComponent ,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvoiceTemplateRoutingModule {}
