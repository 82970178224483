import { Action, createReducer, on } from '@ngrx/store';
import * as frmStepStatusUpdateActions from '../actions/step-status-update.actions';


export const stepStatusUpdateFeatureKey = 'stepStatusUpdate';

export interface StepStatusUpdateState { }

export const initialState: StepStatusUpdateState = {};

export const reducer = createReducer(
  initialState,
  on(frmStepStatusUpdateActions.updateStepStatusSuccess, (state, { response }) => {
    return { ...state, ...response };
  }),
  on(frmStepStatusUpdateActions.updateStepStatusToInitialState, (state) => {
    return initialState;
  })
);

