import { Component, OnInit, Inject } from '@angular/core';
import { VIDEOS } from '../../../../data/constants/video-doc.data';
import * as Roles from '../../../../data/constants/roles';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  videos: any[] = [];
  videoLinkName;
  videoLink;

  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dialogData) {
      this.getData();
   }

  ngOnInit() {
  }

  getData(){
    if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY) {
      this.videos = VIDEOS.ATTORNEY_VIDEOS;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
      this.videos = VIDEOS.PETITIONER_VIDEOS;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.BENEFICIARY) {
      this.videos = VIDEOS.BENEFICIARY_VIDEOS;
    }
    this.videoLinkName = this.dialogData.update.videoLink;
    let link: any;
  //  link = this.videos.filter(x => x.sectionName == this.videoLinkName);
   // this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(link[0].url);
   link = this.findingVideoUrl(this.videoLinkName);
   if (link) {
     this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(link.url);
   }
  }

  findingVideoUrl(sectionName) {
    let linkObj: any;
    linkObj = this.videos.find(
      (element) => element.sectionName === sectionName
    );
    if (!linkObj)
      this.videos.map((element) => {
        if (element.links && element.links.length > 0) {
          linkObj = element.links.find((x) => x.sectionName === sectionName);
          return;
        }
      });
    console.log("link", linkObj);
    return linkObj;
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
