import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, TaskStep, PrimaryData, I140FileUploadConfiguration, LotteryDetails } from 'app-models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { combineLatest } from 'rxjs';
import { NgForm } from '@angular/forms';
import { USCISH1BLotteryService } from './uscis-h1-b-lottery.service';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import * as errorUtils from "error-message-utility";
import { map } from 'rxjs/internal/operators/map';
import { first } from 'rxjs/internal/operators/first';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-uscis-h1-b-lottery',
  templateUrl: './uscis-h1-b-lottery.component.html',
  styleUrls: ['./uscis-h1-b-lottery.component.scss']
})
export class USCISH1BLotteryComponent implements OnInit {

  @ViewChild('h1bLotteryForm', { static: true }) h1bLotteryForm: NgForm;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  fileUploadConfigurations: I140FileUploadConfiguration;
  statusTypeList: any;
  observableSubscription$ = new Subject();
  guardSubscription: any;
  inputData: PrimaryData;
  isBeneficiaryNotify: boolean;
  responseCode: string;
  receiptNumber: string;
  responseNote: string;
  userType: string;

  constructor(
    private store: Store<any>,
    private apiService: USCISH1BLotteryService,
    private toastr: ToastrService,
    private guard: DirtyFormGuard,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
    this.isBeneficiaryNotify = false;
    this.responseCode = "";
    this.receiptNumber = "";
    this.responseNote = "";
    this.userType = sessionStorage.getItem("userTypeRole");
    console.log("USCIS H1B Lottery Component ::", this.userType);
  }

  ngOnInit(): void {
    this.getStatusype();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      console.log('USCISH1BLotteryComponent visa-store after ::', this.stepDetails)
      this.getBeneConfNo();
      this.getH1BLottery();
      this.isBeneficiaryNotify = this.stepDetails.isBeneficiaryNotify === 1 ? true : false;
      this.responseCode = this.stepDetails.stepResponseDetails && this.stepDetails.stepResponseDetails.stepResponseCode === this.stepDetails.stepResponseDetails.stepResponseCode ? this.stepDetails.stepResponseDetails.stepResponseCode : null;
      this.responseNote = this.stepDetails.stepResponseNote ? this.stepDetails.stepResponseNote : null;
      this.toggleControlsStatus();
    });
    this.h1bLotteryForm.form.valueChanges.pipe(
      map(() => this.h1bLotteryForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  getStatusype() {
    this.apiService.getStatusAndResponse('USCISREG').subscribe((response: ImagilityBaseResponse) => {
      this.statusTypeList = response['response'];
      console.log('this.statusTypeList :: ',this.statusTypeList);
    });
  }

  getH1BLottery() {
    this.fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: false,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/USCISLOTT/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/USCISLOTT/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'USCISLOTT '
    });
  }

  getBeneConfNo() {
    this.apiService.getBeneConfirmationNo(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.receiptNumber = response.data.beneficiaryConfirmationNumber;
      }
    });
  }

  postLotteryDetails() {
    const lotteryPayload = {
      baseActionCode: "",
      benchMarkActionCode: "",
      endDate: "",
      isAttorneyNotify: 0,
      isBeneficiaryNotify: this.isBeneficiaryNotify ? 1 : 0,
      isPetitionerNotify: 0,
      receiptCategory: "",
      receiptDate: "",
      receiptNumber: null,
      beneficiaryConfirmationNumber: this.receiptNumber,
      receiptType: "",
      responseCode: this.responseCode,
      responseNote: this.responseNote,
      reviewCode: "",
      reviewNote: "",
      startDate: "",
      statusCode: "",
      stepNote: "",
    }
    this.apiService.postH1BLottery(this.inputData.visatype, this.inputData.subTaskId, this.inputData.stepId, lotteryPayload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success("Data Saved Successfully", 'Success');
        this.h1bLotteryForm.resetForm(this.h1bLotteryForm.value);
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.store.dispatch(loadSubTaskDetails({
            taskId: this.inputData.subTaskId,
            visaTypeCode: this.inputData.getTaskType
          }));
          this.updateStepStatus('INPROGRESS');
        }
        else {
          this.store.dispatch(loadSubTaskDetails({
            taskId: this.inputData.subTaskId,
            visaTypeCode: this.inputData.getTaskType
          }));
        }
      }
    })
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status, this.responseNote, this.isBeneficiaryNotify),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ? this.inputData.caseType : this.dynamicDialogConfig.data.visatype
    }));
  }

  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    console.log('toggleControlsStatus ::', this.disabledControls)
    this.fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: false,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/USCISLOTT /`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/USCISLOTT /fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'USCISLOTT '
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  handleCancel() {
    this.store.dispatch(loadSubTaskDetails({
      taskId: this.inputData.subTaskId,
      visaTypeCode: this.inputData.getTaskType
    }));
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
