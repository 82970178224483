import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-petition-process-type-selection',
  templateUrl: './petition-process-type-selection.component.html',
  styleUrls: ['./petition-process-type-selection.component.scss']
})
export class PetitionProcessTypeSelectionComponent {
  processes = [
    {
      title: 'Standard manual process',
      description: 'Create petition by entering details manually',
      isChecked: false,
      icon: 'fa-book',
      id: 1
    },
    {
      title: 'Build using automated wizard',
      description: 'Complete your petition effortlessly with our automated process',
      isChecked: true,
      icon:'fa-magic',
      id: 2
    }
  ];
  selectedId = 2;

  constructor(
    private dynamicDialogRef: DynamicDialogRef
  ){}

  handleYes() {
    this.dynamicDialogRef.close(this.selectedId);
  }

  selectProcess(index) {
    this.processes.forEach((item, i)=> {
      if(i == index) {
        item.isChecked = true;
        this.selectedId = item.id;
      } else {
        item.isChecked = false
      }
    })
  }

  handleNo() {
    this.dynamicDialogRef.close(false);
  }

}

