<div>
    <div class="row">
      <div><h4 class="faqTitle"><b>Frequently Asked Questions</b></h4></div>
      
    </div>
    <hr />
    <div class="scrollableContent">
     
      <mat-accordion class="faq-expansion mt-3" *ngIf="faqList.length > 0">
    <!-- #enddocregion multi -->
      <mat-expansion-panel [togglePosition]="'before'" *ngFor="let faq of faqList; let i=index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{faq?.question}}
          </mat-panel-title>
        
        </mat-expansion-panel-header>
        <p>{{faq?.answer}}</p>
    
      
    
      </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="d-flex justify-content-center">
      
      <span class="expandMoreStyle" (click)="goToBeneficiaryFaqDetailsPage()">Show More</span>
      
    </div>
    </div>