import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImacalendarComponent } from './imacalendar/imacalendar.component';
import * as pages from './index';

const routes: Routes = [{
  path: '',
  component: pages.ImacalendarComponent
},
{ path: 'calendar',
component: pages.ImacalendarComponent
},
{ path: 'meeting',
component: pages.MeetingComponent
},
{ path: 'task',
component: pages.TaskComponent
},
{ path: 'courtdate',
component: pages.CourtdateComponent
},
{ path: 'event',
component: pages.EventComponent
},
{ path: 'reminder',
component: pages.ReminderComponent
},
{ path: 'tabview',
component: pages.TabviewComponent
},

{ path: 'calwidget',
component: pages.CalendarWidgetComponent
},



];

// const routes: Routes = [{
//   path: '',
//   component: pages.ImacalendarComponent,
//   children: [
//     { path: 'calendar',
//     component: pages.ImacalendarComponent
//     },
//     { path: 'meeting',
//     component: pages.MeetingComponent
//     },
//     { path: 'task',
//     component: pages.TaskComponent
//     },
//     { path: 'courtdate',
//     component: pages.CourtdateComponent
//     },
//     { path: 'event',
//     component: pages.EventComponent
//     },
//     { path: 'reminder',
//     component: pages.ReminderComponent
//     },
    
//   ]
// },

// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulingRoutingModule { }
