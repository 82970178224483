import { Component, OnInit, ViewChild } from '@angular/core';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { PetitionAnalysisService } from 'src/app/services/petition-analysis.service';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';


export interface Category {
  name: string,
  code: string,
  id: number,
  description: string,
  check?: Category[]
}

export interface NodeCategory extends TreeNode {
  name: string,
  code: string,
  id: number,
  description: string,
  check?: Category[],
}

@Component({
  selector: 'app-pa-load',
  templateUrl: './pa-load.component.html',
  styleUrls: ['./pa-load.component.scss']
})
export class PaLoadComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  listCategory: Category[] = [];
  category: NodeCategory;
  categoryIndex: number = 0;
  petitionId: number;

  constructor(
    private PAService: PetitionAnalysisService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.petitionId = parseInt(sessionStorage.getItem('petitionId'));
    this.PAService.getPACategory(this.petitionId).subscribe((response: any[]) => {
      this.listCategory = response.length > 0 ? response : [];
      this.nextPACategoryLoad(this.categoryIndex);
    });
  }

  setListCategory(itemList) {
    const listItem: TreeNode[] = [];
    for (let index = 0; index < itemList.length; index++) {
      const item: TreeNode = {
        label: itemList[index].description,
        key: itemList[index].id.toString()
      };
      if (itemList[index].check) {
        item.children = this.setListCategory(itemList[index].check);
      }
      listItem.push(item);
    }
    return listItem;
  }

  nextPACategoryLoad(categoryIndex) {
    this.categoryIndex++;
    this.category = {
      ...this.listCategory[categoryIndex],
      children: this.listCategory[categoryIndex].check && this.listCategory[categoryIndex].check.length > 0 ? this.setListCategory(this.listCategory[categoryIndex].check) : []
    };
  }

  finishPACategoryLoad() {
    this.ref.close();
  }

}
