<span *ngIf="appRoleData" class="multiple-role fa fa-th fnt-size-23" (click)="handleSwitchRole()"></span>
<div class="switch-app-section" *ngIf="showSwitchAppSection && appRoleData">
  <p class="fnt-style">Choose an app to switch</p>
  <div class="clearfix application-container">
    <div class="app-box-container" *ngFor="let app of appRoleData">
      <div class="app-box" [pTooltip]="app.companyApp?.appName" tooltipPosition="left"
        (click)="presentApp !== app.companyApp.appName && handleAppChange(app)"
        [ngClass]="{'disabled':presentApp === app.companyApp.appName}">
        <img src='{{app.companyApp.iconImagePath}}' [alt]="app.companyApp?.appName" class="logo-Image-switch-app">
        <h6>{{app.companyApp?.appName}}</h6>
      </div>
      <!-- <div
        [ngClass]="app.isUserDefaultApp ? 'default-app-color radio-border-box' : 'non-default-color radio-border-box'">
        <div class="rd-default">
          <input type="radio" class="role-radio-style" name="radiogroup" [checked]="app.isUserDefaultApp"
            [value]="app.isUserDefaultApp" (change)="handleDefault(app)">
          <label class="ml-2 roleRadBtnLabel">{{app.isUserDefaultApp ? 'Default App':'Make Default'}}
          </label>
        </div>
      </div> -->
    </div>
  </div>
</div>
