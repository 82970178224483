<div class="container-fluid">
    <div class="row col-md-12 pl-0">
        <div class="col-md-10">
            <div class="mat-title titleColor font-weight-bold">Manage Beneficiaries</div>
            <div class="mat-subheading-1 titleColor">Manage existing beneficiary or create new beneficiary</div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row  pb-2 pt-2">
        <div class="filter">
            <p-overlayPanel #filterPanel [style]="{width: '300px', padding: '0px 10px'}" [dismissable]="false">
                <ng-template pTemplate>
                    <div class="row col-md-12 pl-0 pr-0 pb-2">
                        <div class="col-md-12 p-0 pb-2 processing-dropdown">
                            <label class="view-label">View By </label>
                            <p-dropdown class="filter-dropdown" [style]="{width: '250px'}" [options]="companyType"
                                [(ngModel)]="filter.filterByCompany"
                                (onChange)="getCompanyType(filter.filterByCompany);">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row col-md-12 pl-0 pr-0 pb-2"
                        *ngIf="((filter.filterByCompany !== 'Petitioner') && (filter.filterByCompany !== 'Individual'))">
                        <div class="col-md-12 p-0 pb-2 processing-dropdown">
                            <label class="filter-label">Select Company</label> <br>
                            <p-dropdown [options]="listOfAttorneyCompanies" [(ngModel)]="filter.selectAttorneyCompanyId"
                                optionLabel="companyName" placeholder="Select a Company" optionValue="companyId"
                                (onChange)="onChangeAttorneyCompany();">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row col-md-12 pl-0 pr-0 pb-2"
                        *ngIf="((filter.filterByCompany !== 'Attorney') && (filter.filterByCompany !== 'Individual') )">
                        <div class="col-md-12 p-0 pb-2 processing-dropdown">
                            <label class="filter-label">Select Company</label> <br>
                            <p-dropdown [options]="listOfPetitionerCompanies" (onChange)="onChangePeitionerCompany();"
                                [(ngModel)]="filter.selectPetitionerCompanyId" optionLabel="companyName"
                                placeholder="Select a Company" optionValue="companyId"></p-dropdown>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="row col-md-12 pt-3">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button pButton type="button" label="Cancel" (click)="resetFilters();filterPanel.hide();"
                                class="p-button-outlined pull-right"></button>
                        </div>
                        <div class="col-md-4 p-0">
                            <button pButton type="button" label="Apply"
                                (click)="handleFilterChange();filterPanel.hide()"
                                class="p-button-primary p-button apply-btn" [disabled]="applyFilterDisabled"></button>
                        </div>
                    </div>
                </ng-template>
            </p-overlayPanel>

            <button type="text" class="taskFilter-btn" pButton label="Filter" (click)="filterPanel.toggle($event)"
                icon="pi pi-filter" iconPos="right"> </button>
        </div>
        <div class="col-md-7"></div>
        <div class="col-md-4 pull-right">
            <div class="input-group">
                <input class="form-control py-2 rounded-pill mr-1 pr-5" type="search" #searchBeneficiaryListTable
                    (search)="resetSearch()" placeholder="Search Beneficiary">
                <span class="input-group-append">
                    <button class="btn rounded-pill border-0 ml-n4r" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="bgWhite noMargin w100">

        <div *ngIf="this.totalRecordCount > 0" class="mat-elevation-z8 bene-border">
            <table class="table im-card-no-border-table im-card-table-noborder-spacing" mat-table
                [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> <b>Beneficiary Name </b></th>
                    <td mat-cell *matCellDef="let element"> {{element.beneficiaryEmployeeFName}}
                        {{element.beneficiaryEmployeeMName||''}} {{element.beneficiaryEmployeeLName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef> <b>Company</b> </th>
                    <td mat-cell *matCellDef="let element"> {{element.companyName ? element.companyName : '--'}} </td>
                </ng-container>
                <ng-container matColumnDef="beneficiaryEmail">
                    <th mat-header-cell *matHeaderCellDef> <b>Email Id</b> </th>
                    <td mat-cell *matCellDef="let element"> {{element.beneficiaryEmail ? element.beneficiaryEmail :
                        '--'}} </td>
                </ng-container>
                <ng-container matColumnDef="modeOfCreationName">
                    <th mat-header-cell *matHeaderCellDef> <b>Mode of Creation</b> </th>
                    <td mat-cell *matCellDef="let element"> {{element.modeOfCreationName}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef><b> Action </b></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="row">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="viewBeneficiary(element)">
                                    View Profile</button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" [pageSize]="pageSize"
                [length]="this.totalRecordCount" (page)="paginationEvent($event)">
            </mat-paginator>
        </div>
    </div>
    <div class="text-center mt-5" *ngIf="this.totalRecordCount <= 0">
        No Records
    </div>
</div>

<p-confirmDialog></p-confirmDialog>