<div>
    <div class="row">
        <div class="col-12">
            <h3>Send Receipt</h3>
        </div>
    </div>
    <form [formGroup]="sendReceiptForm">
        <div class="row">
            <div class="col-12">
                <label class="label-cls required test mt-2" for="receipients">Enter multiple recipients by entering
                    comma seperated Email Ids </label>
                <input id='receipients' type="email" formControlName="toMails"[(ngModel)] ="toMails" [readonly]="true">

                <!-- <small
                    *ngIf="sendInvoiceForm.get('toMails').touched && sendInvoiceForm.get('recipientsEmailIds').errors"
                    class="p-error">
                    <span *ngIf="sendInvoiceForm.get('toMails').errors['required']">Required</span>
                    <span *ngIf="sendInvoiceForm.get('toMails').errors['email']">Email address is
                        invalid</span>
                </small> -->
            </div>
            <div class="col-12">
                <label class="label-cls required test mt-2" for="subject">Subject </label>
                <input id='subject' type="email" formControlName="subject">
            </div>
            <div class="col-12">
                <label class="label-cls required test mt-2" for="bodyContent">Body Content </label>
                <!-- <div> -->
                <textarea  class="col-12 h-300" id='bodyContent' type="email" formControlName="body" [(ngModel)] ="body"></textarea>
                <!-- </div> -->
            </div>
        </div>
        <div class="row pull-right mt-3">
            <div>
                <!-- <button (click)="sendInvoice()">
                    Send
                </button> -->
                <button (click)="sendReceipt()" class="im-btn im-btn-primary im-btn-md " type="button"> Send
                    Receipt</button>
            </div>
        </div>
    </form>
</div>
