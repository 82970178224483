import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { CoreRoutingModule } from './core-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GlobalErrorHandlerService } from './global-error-handler/global-error.interceptor';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatTooltipModule

  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  exports: [RouterModule]
})
export class CoreModule { }
