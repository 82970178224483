import { Component, OnInit, Output, EventEmitter, ViewChild, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm, FormControl } from '@angular/forms';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ApiServicesService } from '../../services/api-services.service';
import { UtilityServiceService } from '../../services/utility-service.service';
import { AuthenticationService } from '../../../../services/authentication.service';
// import { CountryApiService } from '../../childs/country_details/country.service';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { ReferenceLibrary } from '../../../../data/models/referenceLibrary.model';
import moment from 'moment';
import { BeneficiaryDetails } from 'src/app/data/models/beneficiaryDetails.model';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { MatRadioChange } from '@angular/material/radio';
import { BeneAddress } from 'src/app/data/models/beneficiaryDetails.model';
import * as Roles from '../../../../data/constants/roles';
import { CustomValidationService } from 'src/app/services/custom-validation.service';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { UserRoleService } from '../../../../services/user-role.service';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { HelpVideoOverlayComponent } from '../../../../modules/shared/help-video-overlay/help-video-overlay.component';
import { acceptedFileTypes } from 'app-models';
// import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import { HAIRCOLORS, EYECOLORS } from '../../../../data/constants/profile-hair-eye-options';
import { DependentProfileDataService } from "../../services/dependent-profile-data.service";
import { SignatureUploadComponent } from 'src/app/modules/shared/signature-upload/signature-upload.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadDocumentService } from "../../../../services/upload-document.service";
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { CustomQuestionsComponent } from '../../../shared/custom-questions/custom-questions.component';
import * as copyAddressLists from 'src/app/data/constants/copy-address-types';
import { CustomErrorToastComponent } from 'custom-error-toast';
// @Pipe({name: 'creditCardMask'})
@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, OnChanges, AfterViewInit {
  // @Output() updateEditButtonStatus = new EventEmitter<string>();
  @Input('updateEditButtonStatus') updateEditButtonStatus: boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;
  @Input()
  isViewMode: boolean = false;
  @ViewChild(CustomQuestionsComponent) customQuestionsComponent;
  updateBenProfile: Boolean = true;
  beneficiaryId: number;
  beneSelfDetailsForm: FormGroup;
  editDetails: boolean = false;
  listCountry: any[];
  listStates: [];
  ssn_hide: boolean = false;
  listTitle: ReferenceLibrary[];
  listGender: ReferenceLibrary[];
  birthDetails: any;
  showAliasName: boolean = false;
  showforeignAddress: boolean = false;
  permanentLocality: boolean = false;
  currentLocality: boolean = false;
  addressTypeList: ReferenceLibrary[];
  maritalTypeList: ReferenceLibrary[];
  phoneTypeList: ReferenceLibrary[];
  emailTypeList: ReferenceLibrary[];
  subscription: Subscription;
  foriegnAddressType: string;
  submitted: boolean = false;
  listStatesCurrent = [];
  listStatesPermanent = [];
  listStatesMailing = [];
  listStatesForeign = [];
  listStatesBirth = [];
  listStatesMarriage = [];
  @Input()
  beneficiaryData: BeneficiaryDetails;
  currentDate: any;
  showExpiryValidation: boolean = false;
  //IM-3402 fix
  selectedPermanentCheckbox: boolean = false;
  selectedCurrentCheckbox: boolean = false;
  listForeignCountry = [];
  showForienAddressMandatory: boolean = false;
  maritalStatusArr: ReferenceLibrary[];
  raceArr: ReferenceLibrary[];
  familyRelTypeArr: ReferenceLibrary[];
  observableSubscription$ = new Subject();
  isBirthStateRequired: boolean = false;
  //IM
  datesStoredArrayForDisplay = [
    { dob: '' },
    { issueDate: '' },
    { expiryDate: '' }
  ];

  @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;

  //update for expansion panel
  personaldetails = 0;
  viewMode: boolean = false;
  @Output()
  profileEditStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() personalDetails = new EventEmitter<any>();

  @Output() beneficiaryIdFromFB1 = new EventEmitter<any>();

  @Output()
  hasFamily: EventEmitter<boolean> = new EventEmitter<any>();
  @Input() detailsAbout: any;
  @Input() editMode: boolean;
  @Input() customQuestionsDisplay: any;
  customQuestions: any;
  editModeVal: boolean = false;
  destroy$ = new Subject<void>();
  formChangesSubscription: any;
  activeIndex;
  citizenshipAcquiredArr: ReferenceLibrary[];
  ethinicityArr: ReferenceLibrary[];

  fileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENGREENCARD',
    entityId: 0,
    fileCategory: 'BENGREENCARD',
    listUploadedFiles: [],
    useCase: 'beneficiary',
    useCaseId: 0,
    documentTypeRequired: true,
    docummentTypeCode: 'BENGREENCARD',
    useEntityId: false,
    isDeleteEnabled: sessionStorage.getItem('bene-profile-viewMode') == 'true' ? false : true,
    isUploadEnabled: sessionStorage.getItem('bene-profile-viewMode') == 'true' ? false : true,
    familyId: null,
    family: false
  };
  documentFileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'FBBENDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'FBBENDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    isDeleteEnabled: this.viewMode ? false : true,
    isUploadEnabled: this.viewMode ? false : true,
    familyId: null,
    family: false
  };

  showDepedentInfo: boolean = false;
  beneficiaryFamilyInfo: any[];
  showMarriageSection: boolean = true;
  hairColors: any[];
  eyeColors: any[];
  greenCardValidPattern: string;
  certificateNumberValidPattern: string;
  textOnlyValidPattern: string;
  showCitizenShipAquiredInfo: boolean = false
  beneficiaryType: string;
  dependentDetailsLoaded = false;
  lifeStoryTabsLoaded = false;
  showAddIcon = false;
  referenceLibararyReady = false;
  ngOnChangesCalled = false;

  //Signature variable declarations
  signatureImage: string;
  public showCropperSection: boolean;
  sessionpicimage: void;
  signatureImageForm: FormData;

  selectedSubTabId: any;
  loading = false;
  beneficiaryProfileUpdate: any;
  beneUpdateProfile: boolean = false;
  showEditButton: boolean = true;
  //selectedTabDetail: any;
  isPersonalDetailsFormReset: boolean = false;
  // customTriggeredStatus: boolean = false;
  currentFamilyId: any;
  onlyFormBuilder;
  isExternalEmployeeId: string;
  isDisabled = false;
  companyId;
  beneficiaryIdFromFB;
  employeeId;
  fbDetails = {
    moduleId: null,
    pageFormId: null,
    employeeCreationType: null,
    employeeDataToBeFilled: null,
    employeeType: null,
    fbEmpId: null
  };
  listCountryMarriage: any[] = [];
  listCountryForeignAdd: any[] = [];
  currentItemDisplay: any = [];
  iscaseRequest: boolean = false;
  isBenQuestShowDivs: boolean = false;

  @Output()
  hasPassportChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  fbEmpId: any;
  listCopyMailAddress: any[];
  listCopyFornAddress: any[];
  listCopyPermAddress: any[];
  constructor(
    private authenticationService: AuthenticationService,
    private referenceLibraryService: ReferenceLibraryService,
    private beneSelfFormBuilder: FormBuilder,
    private cacheCountryService: CacheCountryService,
    private apiService: ApiServicesService,
    // private countryService: CountryApiService,
    private toastr: ToastrService,
    private utilityService: UtilityServiceService,
    private customValidationService: CustomValidationService,
    private userRoleService: UserRoleService,
    public dialog: MatDialog,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private uploadDocumentService: UploadDocumentService,
    private benServ: BeneficiaryQuestionnaireService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if (this.isExternalEmployeeId) {
      this.isDisabled = true;
    }

    this.loading = true;
    this.addressTypeList = [];
    this.maritalTypeList = [];
    this.phoneTypeList = [];
    this.emailTypeList = [];
    this.currentDate = moment().format('YYYY-MM-DD');
    this.employeeId = parseInt(this.route.snapshot.queryParams.fbEmpId) ? parseInt(this.route.snapshot.queryParams.fbEmpId) : sessionStorage.getItem('employeeId');
    if (this.route.snapshot.queryParams.hasOwnProperty('fbEmpId')) {
      this.fbEmpId = this.route.snapshot.queryParams.fbEmpId;
    }

    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if (this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries.map((c) => {
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountry = [{ countryCode: null, countryName: 'Select', labelWithCode: 'Select', phoneCode: '' }, ...this.listCountry];
        this.listCountryMarriage = this.listCountryForeignAdd = this.listForeignCountry = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
    this.hairColors = HAIRCOLORS;
    this.eyeColors = EYECOLORS;

    this.greenCardValidPattern = "^[a-zA-Z{3}0-9]{13}$";
    this.greenCardValidPattern = "^[a-zA-Z]{3}[0-9]{10}$";
    this.certificateNumberValidPattern = "^[a-zA-Z0-9]{8}$";
    this.textOnlyValidPattern = '^[a-zA-Z ]*$';
    this.listCopyMailAddress = copyAddressLists.MAILING_LIST;
    this.listCopyFornAddress = copyAddressLists.FOREIGN_LIST;
    this.listCopyPermAddress = copyAddressLists.PERMANENT_LIST;
  }

  ngOnInit() {
    this.companyId = parseInt(sessionStorage.getItem('companyId'));

    if (this.onlyFormBuilder === 'FB' && this.beneficiaryId === 0) {
      this.editDetails = true;
    } else {
      this.editDetails = false;
    }
    if (JSON.parse(localStorage.getItem('customQuestions'))) {
      this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
    }
    this.referenceLibraryService.requestDataFromMultipleSources().subscribe(response => {
      this.addressTypeList = <ReferenceLibrary[]>response[0].data;
      this.maritalTypeList = <ReferenceLibrary[]>response[1].data;
      this.phoneTypeList = <ReferenceLibrary[]>response[2].data;
      this.emailTypeList = <ReferenceLibrary[]>response[3].data;
      this.listGender = <ReferenceLibrary[]>response[4].data;
      this.listTitle = <ReferenceLibrary[]>response[5].data;
      this.maritalStatusArr = <ReferenceLibrary[]>response[6].data;
      this.raceArr = <ReferenceLibrary[]>response[7].data;
      this.familyRelTypeArr = <ReferenceLibrary[]>response[8].data;
      this.citizenshipAcquiredArr = <ReferenceLibrary[]>response[9].data;
      this.ethinicityArr = <ReferenceLibrary[]>response[10].data;
      this.referenceLibararyReady = true;
      this.setvalidatations();
      this.afterOnChangesAndReferenceLibraryLoaded();
      this.loading = false;
      this.updatePassportFormControl(false);
      this.hidePasspportDetails();
    });
    this.beneficiaryProfileUpdate = sessionStorage.getItem('bene-update-profile');
    if (this.beneficiaryProfileUpdate === 'true') {
      this.beneUpdateProfile = true;
    }
    this.showEditButton = !this.benServ.checkValidity();
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
        this.fileUploadConfigurations = { ...this.fileUploadConfigurations, isDeleteEnabled: !this.viewMode, isUploadEnabled: !this.viewMode };
        this.documentFileUploadConfigurations = { ...this.documentFileUploadConfigurations, isDeleteEnabled: !this.viewMode, isUploadEnabled: !this.viewMode };
      }
    });

    this.dependentProfileDataService.onLifeStorySubTabs.subscribe(() => {
      this.lifeStoryTabsLoaded = true;
      this.triggerChangeDependentProfileID();
    });
    if (this.onlyFormBuilder === 'FB') {
      this.getFBEmployeeDetails();
    }

  }

  getFBEmployeeDetails() {
    if ((this.fbDetails && this.fbDetails.fbEmpId === null) || !this.fbEmpId) {
      this.apiService.getFBEmployeeDetails(this.fbEmpId).subscribe((data: any) => {
        this.fbDetails = {
          moduleId: data.moduleId,
          pageFormId: data.pageFormId,
          employeeCreationType: data.employeeCreationType,
          employeeDataToBeFilled: data.employeeDataToBeFilled,
          employeeType: data.employeeType,
          fbEmpId: this.fbEmpId
        };
        this.getformBuilderDetails();
      });
    } else {
      this.getformBuilderDetails();
    }
  }

  // initData() {

  //   // return new Promise((resolve, reject) => {
  //   //   const request1 = this.referenceLibraryService.getReferenceData('ADDR');
  //   //   const request2 = this.referenceLibraryService.getReferenceData('MARSTAT');
  //   //   const request3 = this.referenceLibraryService.getReferenceData('PHON');
  //   //   const request4 = this.referenceLibraryService.getReferenceData('EMLTYP');
  //   //   const request5 = this.referenceLibraryService.getReferenceData('GENDER');
  //   //   const request6 = this.referenceLibraryService.getReferenceData('TITL');
  //   //   const request7 = this.referenceLibraryService.getGroupReferenceData('MARSTAT');
  //   //   const request8 = this.referenceLibraryService.getGroupReferenceData('RACE');
  //   //   const request9 = this.referenceLibraryService.getGroupReferenceData('FAMRELTYP');
  //   //   const request10 = this.referenceLibraryService.getGroupReferenceData('CTZNACQRTYP');
  //   //   const request11 = this.referenceLibraryService.getGroupReferenceData('ETHNICITY');

  //   //   forkJoin([request1, request2, request3, request4, request5, request6, request7, request8, request9, request10, request11]).subscribe(
  //   //     ([response1, response2, response3, response4, response5, response6, response7, response8, response9, response10, response11]) => {
  //   //       this.addressTypeList = <ReferenceLibrary[]>response1;
  //   //       this.maritalTypeList = <ReferenceLibrary[]>response2;
  //   //       this.phoneTypeList = <ReferenceLibrary[]>response3;
  //   //       this.emailTypeList = <ReferenceLibrary[]>response4;
  //   //       this.listGender = <ReferenceLibrary[]>response5;
  //   //       this.listTitle = <ReferenceLibrary[]>response6;
  //   //       this.maritalStatusArr = <ReferenceLibrary[]>response7;
  //   //       this.raceArr = <ReferenceLibrary[]>response8;
  //   //       this.familyRelTypeArr = <ReferenceLibrary[]>response9;
  //   //       this.citizenshipAcquiredArr = <ReferenceLibrary[]>response10;
  //   //       this.ethinicityArr = <ReferenceLibrary[]>response11;

  //   //       this.referenceLibararyReady = true;
  //   //       this.afterOnChangesAndReferenceLibraryLoaded();
  //   //       this.setvalidatations();
  //   //       this.loading = false;
  //   //       resolve(true);
  //   //     })
  //   // })
  // }

  setvalidatations() {
    if (!this.iscaseRequest) {
      this.beneSelfDetailsForm = this.beneSelfFormBuilder.group({
        id: [],
        userName: [''],
        visaSponsor: [''],
        fName: ['', Validators.required],
        mName: [''],
        lName: ['', Validators.required],
        title: [''],
        aliasName: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            fName: [''],
            title: [''],
            id: ['']
          })
        ]),
        gender: this.beneSelfFormBuilder.group({
          id: [''],
          name: [''],
        }),
        dob: [''],
        ssn: [''],
        emailContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['PRIM'],
              id: [this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null]
            })
          })
        ]),
        phoneContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            phoneNo: ['', [Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['MOBL'],
              id: [this.phoneTypeList.find(x => x.code === 'MOBL') ? this.phoneTypeList.find(x => x.code === 'MOBL').id : null]
            }),
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: [''],
              phoneCode: ['']
            })
          })
        ]),
        address: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null],
              code: ['CURR']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null],
              code: ['MAIL']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null],
              code: ['FORN']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null],
              code: ['PERM']
            })
          })
        ]),
        identificationMark: [''],
        height: ['', [Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
        hairColor: [''],
        skinColor: [''],/*removed from html as per IM4604*/
        eyeColor: [''],
        countryCode: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        birthStateProvinceCode: [null],
        birthStateProvinceName: [''],
        birthCity: [''],
        ctznshipCountryCode: this.beneSelfFormBuilder.group({
          countryName: null,
          countryCode: ['']
        }),
        hasPassport: [false],
        passport: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            id: [''],
            passportNo: [''],
            issueDate: [''],
            expiryDate: [''],
            issueCountryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            })
          })
        ]),
        currentVisaStatus: null,
        profileImage: null,
        smallProfileImage: null,
        profilePic: null,
        smallProfilePic: null,
        firstAndLastName: null,
        // maritalStatus:null,
        maritalStatus: this.beneSelfFormBuilder.group({
          id: [null],
          name: [''],
        }),
        countryOfMarriage: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        marriageStateProvinceCode: [null],
        marriageStateProvinceName: [''],
        cityOfMarriage: [''],
        // relationShipType:this.beneSelfFormBuilder.group({
        //   id: [''],
        //   name: [''],
        // }),
        isLpr: [null],
        lprThruMarriage: null,
        greenCardNo: ['', [Validators.pattern(this.greenCardValidPattern)]],
        // ethnicity:[],
        ethnicity: this.beneSelfFormBuilder.group({
          ethnicityId: [],
          name: ['']
        }),

        race: this.beneSelfFormBuilder.array([
          //  this.beneSelfFormBuilder.group({
          //    id: [''],
          //   raceId: this.beneSelfFormBuilder.group({
          //     id: [''],
          //     name: ['']
          //   })
          // })
        ]),
        weight: ['', [Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
        ctznAcqrTypeId: this.beneSelfFormBuilder.group({
          ctznAcqrId: [''],
          name: ['']
        }),
        hasCtznCrtfct: [false],
        ctznCrtfctNo: [''],
        ctznCrtfctIssPlace: [''],
        ctznCrtfctIssDate: [null],
        marriageDate: [null]
      });
    } else {
      this.beneSelfDetailsForm = this.beneSelfFormBuilder.group({
        id: [],
        userName: [],
        visaSponsor: [''],
        fName: [''],
        mName: [''],
        lName: [''],
        title: [''],
        aliasName: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            fName: [''],
            title: [''],
            id: ['']
          })
        ]),
        gender: this.beneSelfFormBuilder.group({
          id: [''],
          name: [''],
        }),
        dob: [''],
        ssn: [''],
        emailContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            email: [''],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['PRIM'],
              id: [this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null]
            })
          })
        ]),
        phoneContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            phoneNo: [''],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['MOBL'],
              id: [this.phoneTypeList.find(x => x.code === 'MOBL') ? this.phoneTypeList.find(x => x.code === 'MOBL').id : null]
            }),
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: [''],
              phoneCode: ['']
            })
          })
        ]),
        address: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null],
              code: ['CURR']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null],
              code: ['MAIL']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null],
              code: ['FORN']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', Validators.pattern(/^[a-zA-Z0-9 ]+$/)],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null],
              code: ['PERM']
            })
          })
        ]),
        identificationMark: [''],
        height: [''],
        hairColor: [''],
        skinColor: [''],/*removed from html as per IM4604*/
        eyeColor: [''],
        countryCode: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        birthStateProvinceCode: [null],
        birthStateProvinceName: [''],
        birthCity: [''],
        ctznshipCountryCode: this.beneSelfFormBuilder.group({
          countryName: null,
          countryCode: [null]
        }),
        hasPassport: [false],
        passport: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            id: [''],
            passportNo: [''],
            issueDate: [''],
            expiryDate: [''],
            issueCountryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            })
          })
        ]),
        currentVisaStatus: null,
        profileImage: null,
        smallProfileImage: null,
        profilePic: null,
        smallProfilePic: null,
        firstAndLastName: null,
        // maritalStatus:null,
        maritalStatus: this.beneSelfFormBuilder.group({
          id: [''],
          name: [''],
        }),
        countryOfMarriage: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        marriageStateProvinceCode: [null],
        marriageStateProvinceName: [''],
        cityOfMarriage: [''],
        // relationShipType:this.beneSelfFormBuilder.group({
        //   id: [''],
        //   name: [''],
        // }),
        isLpr: [''],
        lprThruMarriage: null,
        greenCardNo: ['', Validators.pattern(this.greenCardValidPattern)],
        // ethnicity:[],
        ethnicity: this.beneSelfFormBuilder.group({
          ethnicityId: [],
          name: ['']
        }),

        race: this.beneSelfFormBuilder.array([
          //  this.beneSelfFormBuilder.group({
          //    id: [''],
          //   raceId: this.beneSelfFormBuilder.group({
          //     id: [''],
          //     name: ['']
          //   })
          // })
        ]),
        weight: [''],
        ctznAcqrTypeId: this.beneSelfFormBuilder.group({
          ctznAcqrId: [''],
          name: ['']
        }),
        hasCtznCrtfct: [false],
        ctznCrtfctNo: [''],
        ctznCrtfctIssPlace: ['', Validators.pattern(this.textOnlyValidPattern)],
        ctznCrtfctIssDate: [null],
        marriageDate: [null]
      });
    }
    if (this.onlyFormBuilder === 'FB') {
      this.beneSelfDetailsForm.get('isLpr').clearValidators();
      this.beneSelfDetailsForm.get('isLpr').updateValueAndValidity();
    }
    this.formStatusCheck();
  }

  hidePasspportDetails() {
    if (this.beneSelfDetailsForm.get('hasPassport').value === true && this.onlyFormBuilder === 'FB') {
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].clearValidators();

      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].updateValueAndValidity();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].updateValueAndValidity();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].updateValueAndValidity();
      // this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].updateValueAndValidity();
    }

  }

  updatePassportFormControl(event: any) {
    if (!event.checked && this.beneficiaryType === 'self') {
      if (!this.iscaseRequest) {
        // this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].setValidators(Validators.required);

        // this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].setValidators(Validators.required);
        // this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].setValidators(Validators.required);
        // this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].setValidators(Validators.required);
      }
    } else {
      if ((this.beneficiaryData.passport && !this.beneficiaryData.passport.length) || this.beneficiaryData.passport === null) {
        this.beneSelfDetailsForm.patchValue({
          passport: [{
            id: 0,
            issueCountryCode: {
              countryCode: null
            },
            expiryDate: '',
            issueDate: '',
            passportNo: ''
          }]
        });
      }
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].clearValidators();
    }
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].updateValueAndValidity();
    this.hasPassportChange.emit(!event.checked);
  }

  addRaceArrayFormControl() {
    const raceOptionArray = this.beneSelfDetailsForm.get('race') as FormArray;
    //   for(let i = raceOptionArray.length-1; i >= 0; i--) {
    //     raceOptionArray.removeAt(i)
    //  }
    raceOptionArray.clear();
    // this.raceArr.forEach(() => {
    //   raceOptionArray.push(createNewRaceControl());
    // });
    if (this.raceArr && this.raceArr.length > 0) {
      this.raceArr.forEach((x, index) => {
        const raceChecked = this.beneficiaryData.race && this.beneficiaryData.race.length > 0 && this.beneficiaryData.race.some(y => y.raceId.id === x.id);
        // raceOptionArray.push(this.createNewRaceControl(x)); // new FormControl(false)
        raceOptionArray.push(new FormControl(raceChecked));
      });
    }


  }


  ngOnChanges(changes: SimpleChanges) {

    if (JSON.parse(localStorage.getItem('customQuestions'))) {
      this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
    }

    this.beneUpdateProfile = this.updateEditButtonStatus;
    this.ngOnChangesCalled = true;
    this.afterOnChangesAndReferenceLibraryLoaded();
    if (changes?.editMode?.currentValue) {
      this.editModeVal = true;
    }
    if (changes.customQuestionsDisplay && changes.customQuestionsDisplay.currentValue) {
      this.customQuestions = changes.customQuestionsDisplay.currentValue;
    }
    if ((sessionStorage.getItem('isCaseRequest') || (changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue)) || (localStorage.getItem('selectedBeneData') || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue))) {

      if (changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) {
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      if (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue) {
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      this.beneSelfDetailsForm = this.beneSelfFormBuilder.group({
        id: [],
        userName: [''],
        visaSponsor: [''],
        fName: [''],
        mName: [''],
        lName: [''],
        title: [''],
        aliasName: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            fName: [''],
            title: [''],
            id: ['']
          })
        ]),
        gender: this.beneSelfFormBuilder.group({
          id: [''],
          name: [''],
        }),
        dob: [''],
        ssn: [''],
        emailContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            email: [''],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['PRIM'],
              id: [this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null]
            })
          })
        ]),
        phoneContacts: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            phoneNo: [''],
            id: [''],
            type: this.beneSelfFormBuilder.group({
              code: ['MOBL'],
              id: [this.phoneTypeList.find(x => x.code === 'MOBL') ? this.phoneTypeList.find(x => x.code === 'MOBL').id : null]
            }),
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: [''],
              phoneCode: ['']
            })
          })
        ]),
        address: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null],
              code: ['CURR']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null],
              code: ['MAIL']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: [''],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [''],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null],
              code: ['FORN']
            })
          }),
          this.beneSelfFormBuilder.group({
            addressLine1: [''],
            addressLine2: [''],
            postCode: ['', Validators.pattern(/^[a-zA-Z0-9 ]+$/)],
            doorNo: [''],
            id: [''],
            streetNo: [''],
            countryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            }),
            stateProvinceCode: [null],
            stateProvinceName: [''],
            city: [''],
            locality: [''],
            type: this.beneSelfFormBuilder.group({
              id: [this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null],
              code: ['PERM']
            })
          })
        ]),
        identificationMark: [''],
        height: [''],
        hairColor: [''],
        skinColor: [''],/*removed from html as per IM4604*/
        eyeColor: [''],
        countryCode: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        birthStateProvinceCode: [null],
        birthStateProvinceName: [''],
        birthCity: [''],
        ctznshipCountryCode: this.beneSelfFormBuilder.group({
          countryName: null,
          countryCode: [null]
        }),
        hasPassport: [false],
        passport: this.beneSelfFormBuilder.array([
          this.beneSelfFormBuilder.group({
            id: [''],
            passportNo: [''],
            issueDate: [''],
            expiryDate: [''],
            issueCountryCode: this.beneSelfFormBuilder.group({
              countryCode: ['']
            })
          })
        ]),
        currentVisaStatus: null,
        profileImage: null,
        smallProfileImage: null,
        profilePic: null,
        smallProfilePic: null,
        firstAndLastName: null,
        // maritalStatus:null,
        maritalStatus: this.beneSelfFormBuilder.group({
          id: [''],
          name: [''],
        }),
        countryOfMarriage: this.beneSelfFormBuilder.group({
          countryCode: ['']
        }),
        marriageStateProvinceCode: [null],
        marriageStateProvinceName: [''],
        cityOfMarriage: [''],
        // relationShipType:this.beneSelfFormBuilder.group({
        //   id: [''],
        //   name: [''],
        // }),
        isLpr: [''],
        lprThruMarriage: null,
        greenCardNo: ['', Validators.pattern(this.greenCardValidPattern)],
        // ethnicity:[],
        ethnicity: this.beneSelfFormBuilder.group({
          ethnicityId: [],
          name: ['']
        }),

        race: this.beneSelfFormBuilder.array([
          //  this.beneSelfFormBuilder.group({
          //    id: [''],
          //   raceId: this.beneSelfFormBuilder.group({
          //     id: [''],
          //     name: ['']
          //   })
          // })
        ]),
        weight: [''],
        ctznAcqrTypeId: this.beneSelfFormBuilder.group({
          ctznAcqrId: [''],
          name: ['']
        }),
        hasCtznCrtfct: [false],
        ctznCrtfctNo: [''],
        ctznCrtfctIssPlace: ['', Validators.pattern(this.textOnlyValidPattern)],
        ctznCrtfctIssDate: [null],
        marriageDate: [null]
      });
    } else {
      this.iscaseRequest = false;
    }
  }

  afterOnChangesAndReferenceLibraryLoaded() {
    if (this.referenceLibararyReady && this.ngOnChangesCalled) {
      this.beneficiaryType = this.detailsAbout.showDetailFor === 'self' ? 'self' : 'family';
      if (this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')) {
        if (this.beneficiaryType === 'self') {
          this.iscaseRequest = true;
        } else {
          this.iscaseRequest = false;
        }
      }
      if (!this.beneSelfDetailsForm || this.beneficiaryType === 'self') {
        this.setvalidatations();
      } else if (this.beneficiaryType === 'family') {
        //Remove validation for Family and Other dependents
        this.removeValidations(this.beneSelfDetailsForm);
        this.applyForeignAddressValidation(null, null, 2);
      }
      this.showMarriageSection = this.detailsAbout && this.detailsAbout.dependentType && this.detailsAbout.dependentType.code ? !['SPOUSE'].includes(this.detailsAbout.dependentType.code) : true;
      if (this.detailsAbout.showDetailFor === 'self') {
        if (this.onlyFormBuilder === 'FB') {
          this.getFBEmployeeDetails();
        } else {
          this.getBeneficiaryDetails();
        }

      } else {
        this.getBeneficiaryFamilyDetails();
      }
    }
  }

  createNewRaceControl(item): FormGroup {
    return new FormGroup({
      id: new FormControl(0),
      raceId: new FormGroup({
        id: new FormControl(item.id ? item.id : null),
        name: new FormControl(item.name ? item.name : null),
        checked: new FormControl(false)
      }),
    })
  }

  ngAfterViewInit() {
    // this.helpVideoComponent.setValues('right', 'Building Beneficiary Profile', 'Building Beneficiary Profile');
    this.cacheCountryService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.length > 0) {
        this.listCountry = data?.map((c) => {
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountry = [{ countryCode: null, countryName: 'Select', labelWithCode: 'Select', phoneCode: '' }, ...this.listCountry];
        this.listCountryMarriage = this.listCountryForeignAdd = this.listForeignCountry = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
  }

  onRefreshPage(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  formStatusCheck() {
    this.formChangesSubscription = this.beneSelfDetailsForm.valueChanges.pipe(
      map(() => this.beneSelfDetailsForm.dirty && this.beneSelfDetailsForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: "If you leave before saving, all changes made on the 'Profile Details' form will be lost. Do you wish to continue?"
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ""
        }));
      }

    });
  }


  handleEdit() {
    if (this.isPersonalDetailsFormReset) {
      this.setBeneficiaryFormData();
    }
    this.editDetails = !this.editDetails;
    this.beneSelfDetailsForm.enable();
    this.updatePassportFormControl({ checked: this.beneSelfDetailsForm.get('hasPassport').value });
    this.editDetails ? this.subscribeValueChanges() : this.unSubscribeValueChanges();
    if (this.editDetails) {
      this.profileEditStatus.emit(true);
    } else {
      this.profileEditStatus.emit(false);
    }
  }


  subscribeValueChanges() {
    this.subscription = this.beneSelfDetailsForm.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((values) => {
      this.showforeignAddress = values.address['0']['countryCode']['countryCode'] === 'USA' && values.address['1']['countryCode']['countryCode'] === 'USA';
      this.currentLocality = values.address['0']['countryCode']['countryCode'] === 'IND';
      this.permanentLocality = values.address['1']['countryCode']['countryCode'] === 'IND';
    });
  }

  maskify(cc) {
    return cc.slice(0, -4).replace(/./g, '#') + cc.slice(-4);
  }

  patch(group, stateIndex, addType) {
    if (group.countryCode.countryCode) {
      this.handleCountryChange(group.countryCode.countryCode, stateIndex);
    }
    if (addType === 'Foreign') {
      const foreignAddress = this.getAddress('FORN');
      const foreignAddressType = foreignAddress.value.type;
      const foreignAddressID = foreignAddress.value.id;
      foreignAddress.setValue(group);
      foreignAddress.patchValue(
        {
          id: foreignAddressID,
          type: foreignAddressType
        }
      );

    } else if (addType === 'Mailing') {
      const mailAddress = this.getAddress('MAIL');
      const mailAddressType = mailAddress.value.type;
      const mailAddressID = mailAddress.value.id;
      mailAddress.setValue(group);
      mailAddress.patchValue(
        {
          id: mailAddressID,
          type: mailAddressType
        }
      );
    } else if (addType === 'Permanent') {
      const permAddress = this.getAddress('PERM');
      const permAddressType = permAddress.value.type;
      const permAddressID = permAddress.value.id;
      permAddress.setValue(group);
      permAddress.patchValue(
        {
          id: permAddressID,
          type: permAddressType
        }
      );
    }
  }

  unSubscribeValueChanges() {
    this.subscription.unsubscribe();
  }

  clearProfileData(myForm) {
    this.selectedCurrentCheckbox = false;
    this.selectedPermanentCheckbox = false;
    this.isPersonalDetailsFormReset = true;
    this.beneSelfDetailsForm.disable();
    myForm.resetForm();
    this.editDetails = !this.editDetails;
    this.profileEditStatus.emit(this.editDetails);
    this.setvalidatations();
  }

  checkForeignAddress() {
    let currAddress = this.getAddress('CURR');
    let permAddress = this.getAddress('PERM');
    let ca = currAddress['value']['countryCode']['countryCode'];
    let pa = permAddress['value']['countryCode']['countryCode'];
    if (ca != 'USA') {
      this.patch(currAddress['value'], 'Current', 'Foreign');
    } else if (ca === 'USA' && pa != 'USA') {
      this.patch(permAddress['value'], 'Permanent', 'Foreign');
    }
  }

  getBeneficiaryDetails() {
    if (this.onlyFormBuilder !== 'FB' && this.beneficiaryId !== null) {
      this.emptyBeneficiaryData();
      this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'self').pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: BeneficiaryDetails) => {
        this.beneficiaryData = { ...beneficiary, dob: beneficiary.dob && moment(beneficiary.dob, 'YYYY-MM-DD').toDate(), marriageDate: beneficiary.marriageDate && moment(beneficiary.marriageDate, 'YYYY-MM-DD').toDate(), ctznCrtfctIssDate: beneficiary.ctznCrtfctIssDate && moment(beneficiary.ctznCrtfctIssDate, 'YYYY-MM-DD').toDate(), passport: beneficiary.passport?.length ? [{ ...beneficiary.passport[0], expiryDate: beneficiary.passport[0].expiryDate && moment(beneficiary.passport[0].expiryDate, 'YYYY-MM-DD').toDate(), issueDate: beneficiary.passport[0].issueDate && moment(beneficiary.passport[0].issueDate, 'YYYY-MM-DD').toDate() }] : beneficiary.passport };
        this.personalDetails.next(this.beneficiaryData);

        this.setBeneficiaryFormData();
      });
    }
  }


  getformBuilderDetails() {
    this.emptyBeneficiaryData();
    this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    this.beneficiaryIdFromFB1.emit(this.beneficiaryIdFromFB);
    this.apiService.getEmployeeFBDetails(this.fbDetails.moduleId, this.fbDetails.pageFormId, this.companyId, this.beneficiaryId).pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: BeneficiaryDetails) => {
      this.beneficiaryData = beneficiary;
      this.personalDetails.next(this.beneficiaryData);
      // if(this.beneficiaryIdFromFB !== null && this.beneficiaryIdFromFB !== 0){
      this.setBeneficiaryFormData();
      // }

    });
  }

  setBeneficiaryFormData() {
    let orderedArr: BeneAddress[] = [];

    this.showAddIcon = this.beneficiaryData.id == 0 ? true : false;
    if (this.onlyFormBuilder === 'FB') {
      this.fileUploadConfigurations.entityId = this.documentFileUploadConfigurations.entityId = this.beneficiaryId ? this.beneficiaryId : this.beneficiaryIdFromFB;
      this.fileUploadConfigurations.useCaseId = this.documentFileUploadConfigurations.useCaseId = this.beneficiaryId ? this.beneficiaryId : this.beneficiaryIdFromFB;
    } else {
      this.fileUploadConfigurations.entityId = this.beneficiaryId;
      this.fileUploadConfigurations.useCaseId = this.beneficiaryId;
    }

    if (this.onlyFormBuilder === 'FB') {
      this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
      this.documentFileUploadConfigurations.familyId = (this.beneficiaryType === 'family') ? this.beneficiaryData.id : null;
      this.currentFamilyId = this.documentFileUploadConfigurations.familyId;
      this.documentFileUploadConfigurations.family = (this.beneficiaryType === 'family') ? true : false;
      this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
    }

    this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
    this.fileUploadConfigurations.familyId = (this.beneficiaryType === 'family') ? this.beneficiaryData.id : null;
    this.currentFamilyId = this.fileUploadConfigurations.familyId;
    this.fileUploadConfigurations.family = (this.beneficiaryType === 'family') ? true : false;
    this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

    //this.editClicked =  (sessionStorage.getItem('dependentId') == '0') ? false : true
    if (this.beneficiaryType === 'family' && this.beneficiaryData.id === 0) {
      if (this.onlyFormBuilder === 'FB') {
        this.documentFileUploadConfigurations.listUploadedFiles = [];
        this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
      }
      this.fileUploadConfigurations.listUploadedFiles = [];
      this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
    } else {
      if (this.onlyFormBuilder === 'FB') {
        this.uploadDocumentService.genericGetUploadDocument(this.documentFileUploadConfigurations.useCase, this.documentFileUploadConfigurations.useCaseId, this.documentFileUploadConfigurations.categoryName, this.documentFileUploadConfigurations.entityId, this.documentFileUploadConfigurations.useEntityId, this.documentFileUploadConfigurations.familyId).subscribe((response) => {

          if (response['status'] === 200) {
            this.documentFileUploadConfigurations.listUploadedFiles = response['data'];
            this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
          }
          else {
            this.documentFileUploadConfigurations.listUploadedFiles = [];
            this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
          }
        });
      }
      this.uploadDocumentService.genericGetUploadDocument(this.fileUploadConfigurations.useCase, this.fileUploadConfigurations.useCaseId, this.fileUploadConfigurations.categoryName, this.fileUploadConfigurations.entityId, this.fileUploadConfigurations.useEntityId, this.fileUploadConfigurations.familyId).subscribe((response) => {

        if (response['status'] === 200) {
          this.fileUploadConfigurations.listUploadedFiles = response['data'];
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
        }
        else {
          this.fileUploadConfigurations.listUploadedFiles = [];
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
        }
      });
    }

    this.showAddIcon = this.beneficiaryData.id == 0 ? true : false;
    this.signatureImage = (this.beneficiaryData['signPic'] !== null && this.beneficiaryData['signPic'] !== undefined) ? `data:image/jpeg;base64,${this.beneficiaryData['signPic']}` : '';
    if (this.beneficiaryData.address && this.beneficiaryData.address.length > 0) {
      if (this.beneficiaryData.address.find(x => x.type.code === 'CURR')) {
        orderedArr[0] = this.beneficiaryData.address.find(x => x.type.code === 'CURR');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'MAIL')) {
        orderedArr[1] = this.beneficiaryData.address.find(x => x.type.code === 'MAIL');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'FORN')) {
        orderedArr[2] = this.beneficiaryData.address.find(x => x.type.code === 'FORN');
      } else {
        orderedArr[2] = this.emptyAddress('Foreign');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'PERM')) {
        orderedArr[3] = this.beneficiaryData.address.find(x => x.type.code === 'PERM');
      } else {
        orderedArr[3] = this.emptyAddress('Permanent');
      }
    } else {
      orderedArr.push(this.emptyAddress('Current'), this.emptyAddress('Mailing'), this.emptyAddress('Permanent'), this.emptyAddress('Foreign'));
    }
    ///IM-3601
    this.datesStoredArrayForDisplay['dob'] = this.beneficiaryData.dob ? moment(this.beneficiaryData.dob).toISOString() : '';
    this.datesStoredArrayForDisplay['issueDate'] = this.beneficiaryData.passport[0] ? moment(this.beneficiaryData.passport[0].issueDate).toISOString() : '';
    this.datesStoredArrayForDisplay['expiryDate'] = this.beneficiaryData.passport[0] ? moment(this.beneficiaryData.passport[0].expiryDate).toISOString() : '';

    this.beneficiaryData.address = orderedArr;
    if (this.beneficiaryData && this.beneficiaryData.aliasName && this.beneficiaryData.aliasName.length > 0) {
      this.showAliasName = (this.beneficiaryData.aliasName[0].fName) ? true : false;
    }

    if (this.beneficiaryData.address && this.beneficiaryData.address.length > 0) {
      if (this.beneficiaryData.address[0] && this.beneficiaryData.address[0].countryCode && this.beneficiaryData.address[0].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[0].countryCode.countryCode, 'Current');
      } else if (this.beneficiaryData.address[0] && !this.beneficiaryData.address[0].countryCode) {
        this.beneficiaryData.address[0]['countryCode'] = {
          countryCode: '',
          countryName: ''
        };
      }
      if (this.beneficiaryData.address[1] && this.beneficiaryData.address[1].countryCode && this.beneficiaryData.address[1].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[1].countryCode.countryCode, 'Mailing');
      } else if (this.beneficiaryData.address[1] && !this.beneficiaryData.address[1].countryCode) {
        this.beneficiaryData.address[1]['countryCode'] = {
          countryCode: '',
          countryName: ''
        };
      }
      if (this.beneficiaryData.address[2] && this.beneficiaryData.address[2].countryCode && this.beneficiaryData.address[2].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[2].countryCode.countryCode, 'Foreign');
      }
      if (this.beneficiaryData.address[2] && !this.beneficiaryData.address[2].countryCode) {
        //IM-3932-For Existing beneficiary-after clicking on edit link-country code, mobile no, email id, passport fields are showing blank
        this.beneficiaryData.address[2]['countryCode'] = {
          countryCode: '',
          countryName: ''
        };
      }
      if (this.beneficiaryData.address[3] && this.beneficiaryData.address[3].countryCode && this.beneficiaryData.address[3].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[3].countryCode.countryCode, 'Permanent');
      } else if (this.beneficiaryData.address[3] && !this.beneficiaryData.address[3].countryCode) {
        this.beneficiaryData.address[3]['countryCode'] = {
          countryCode: '',
          countryName: ''
        };
      }
    }

    if (this.beneficiaryData.countryCode && this.beneficiaryData.countryCode.countryCode) {
      this.handleCountryChange(this.beneficiaryData.countryCode.countryCode, 'Birth');
    }
    if (this.beneficiaryData.countryOfMarriage && this.beneficiaryData.countryOfMarriage.countryCode) {
      this.handleCountryChange(this.beneficiaryData.countryOfMarriage.countryCode, 'Marriage');
    }
    if (this.beneficiaryData.ctznshipCountryCode && this.beneficiaryData.ctznshipCountryCode.countryCode) {
      this.showCitizenShipAquiredInfo = (this.beneficiaryData.ctznshipCountryCode.countryCode === 'USA') ? true : false;
    } else {
      this.showCitizenShipAquiredInfo = false;
    }
    if (!this.beneficiaryData.gender) {
      this.beneficiaryData.gender = {
        id: null,
        name: null
      };
    }
    if (!this.beneficiaryData.countryCode) {
      this.beneficiaryData.countryCode = {
        countryCode: null,
        countryName: null
      };
    }

    if (!(this.beneficiaryData.ctznshipCountryCode && this.beneficiaryData.ctznshipCountryCode.countryCode)) {
      this.beneficiaryData.ctznshipCountryCode = {
        countryCode: null,
        countryName: null
      };
    }
    if (!(this.beneficiaryData.countryOfMarriage && this.beneficiaryData.countryOfMarriage.countryCode)) {
      this.beneficiaryData.countryOfMarriage = {
        countryCode: null,
        countryName: null
      };
    }
    if (!(this.beneficiaryData.maritalStatus)) {
      this.beneficiaryData.maritalStatus = {
        id: null,
        name: ''
      };
    }

    if (!this.beneficiaryData.dob) {
      this.beneSelfDetailsForm.get('dob').setValue(null);
    }
    if (!this.beneficiaryData.marriageDate) {
      this.beneSelfDetailsForm.get('marriageDate').setValue(null);
    }
    if (!this.beneficiaryData.ctznCrtfctIssDate) {
      this.beneSelfDetailsForm.get('ctznCrtfctIssDate').setValue(null);
    }
    if (!(this.beneficiaryData.aliasName && this.beneficiaryData.aliasName.length > 0 && this.beneficiaryData.aliasName[0].fName)) {
      const aliasNameArray = this.beneSelfDetailsForm.get('aliasName') as FormArray;
      aliasNameArray.clear();

    }
    //IM-3763-Profile picture is not shown when Petitioner edits beneficiary.
    if (this.beneficiaryData['profileImage']) {
      //sessionStorage.setItem('profilePic', this.beneficiaryData['profileImage']);
      this.userRoleService.hasProfileImage({ hasImage: true, image: this.beneficiaryData['profileImage'] });
    } else {
      this.userRoleService.hasProfileImage(false);
    }

    if (!this.isPersonalDetailsFormReset) {
      this.editDetails = false;
      this.profileEditStatus.emit(false);
    }

    this.dependentDetailsLoaded = true;
    this.triggerChangeDependentProfileID();
    this.addRaceArrayFormControl();
    if (this.beneficiaryData && this.beneficiaryData['phoneContacts']) {
      this.beneficiaryData['phoneContacts'].forEach((el) => {
        if (!el.countryCode) {
          el.countryCode = {
            phoneCode: 0,
            countryCode: '',
            countryName: '',
          };
        }
      });
    }
    const beneFormPatchValue = {
      ... this.beneficiaryData,
      race: [],
      hasPassport: !this.beneficiaryData['hasPassport']
    }


    try {
      // this.beneSelfDetailsForm.patchValue(this.beneficiaryData);
      this.beneSelfDetailsForm.patchValue({
        ...beneFormPatchValue,
        ctznCrtfctIssPlace: this.beneficiaryData.ctznCrtfctIssPlace,
        ctznCrtfctNo: this.beneficiaryData.ctznCrtfctNo,
        ctznAcqrTypeId: {
          ctznAcqrId: this.beneficiaryData.ctznAcqrTypeId && this.beneficiaryData.ctznAcqrTypeId.id ? this.beneficiaryData.ctznAcqrTypeId.id : null
        },
        ethnicity: {
          ethnicityId: this.beneficiaryData.ethnicity && this.beneficiaryData.ethnicity.id ? this.beneficiaryData.ethnicity.id : null
        }


      });

      // this.dependentProfileDataService.changeDependentProfileID(this.beneSelfDetailsForm.get('id').value,this.beneficiaryType);

    } catch (error) {

    }
    this.beneSelfDetailsForm.patchValue({
      weight: this.beneficiaryData.weight ? this.beneficiaryData.weight : null
    });
    // this.addRaceArrayFormControl();

    this.handleIsLprChanges();
    //this.setValidatorForCitizenShipAquired();
    this.handleCitizenshipSwitchChange();

    /// this.beneSelfDetailsForm.patchValue(this.beneficiaryData);
    this.beneSelfDetailsForm.disable();
    if (this.editModeVal) {
      this.editDetails = false;
      this.handleEdit();
    }

    if (this.beneUpdateProfile && !this.viewMode && !this.isPersonalDetailsFormReset) {
      this.handleEdit();
    }

  }

  triggerChangeDependentProfileID() {
    if (this.dependentDetailsLoaded && this.lifeStoryTabsLoaded) {
      this.dependentProfileDataService.changeDependentProfileID(this.beneficiaryType !== 'self' ? this.beneficiaryData.id : null, this.beneficiaryType);
    }
  }


  getBeneficiaryFamilyDetails(isOnSave?: boolean) {
    this.apiService.getBeneficiaryDetails(this.beneficiaryId, 'family').pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiaryFamilyInfo: any) => {
      this.beneficiaryFamilyInfo = beneficiaryFamilyInfo;
      let a;
      a = this.beneficiaryFamilyInfo.find(e => e.relationShipType.code === this.detailsAbout.dependentType.code)
      if (a) {
        this.hasFamily.emit(a.id);
      }
      if (isOnSave && this.selectedSubTabId) {
        this.dependentProfileDataService.newSubDependentCreated.next({ beneficiaryFamilyInfo, selectedSubTabId: this.selectedSubTabId });
      } else {
        this.handleTabChange();
      }
    });

  }

  getStateName(stateProvinceCodes, stateIndex) {
    switch (stateIndex) {
      case 'Current':
        const selectedCurrStateObj = this.listStatesCurrent.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedCurrStateObj) ? selectedCurrStateObj.stateProvinceName : '--';
      case 'Permanent':
        const selectedPermStateObj = this.listStatesPermanent.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedPermStateObj) ? selectedPermStateObj.stateProvinceName : '--';
      case 'Permanent':
        const selectedMailStateObj = this.listStatesMailing.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedPermStateObj) ? selectedPermStateObj.stateProvinceName : '--';
      case 'Foreign':
        const selectedFornStateObj = this.listStatesForeign.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedFornStateObj) ? selectedFornStateObj.stateProvinceName : '--';
      case 'Birth':
        const selectedBirthStateObj = this.listStatesBirth.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedBirthStateObj) ? selectedBirthStateObj.stateProvinceName : '--';
      case 'Marriage':
        const selectedMarriageStateObj = this.listStatesMarriage.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedMarriageStateObj) ? selectedMarriageStateObj.stateProvinceName : '--';
      case 'Mailing':
        const selectedMailingStateObj = this.listStatesMailing.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedMailingStateObj) ? selectedMailingStateObj.stateProvinceName : '--';
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.beneSelfDetailsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    // console.log(invalid);
    return invalid;
  }

  savePersonalData(isDraft: boolean = false) {
    if (!isDraft) {
      this.submitted = true;
      if (this.beneSelfDetailsForm.invalid) {
        this.findInvalidControls();
        return;
      }
    }
    // IM-3402 - Commented code
    //this.checkForeignAddress();

    //patchvalue add to fix IM-3485
    this.beneSelfDetailsForm.patchValue({
      dob: this.beneSelfDetailsForm.get('dob').value ? moment(this.beneSelfDetailsForm.get('dob').value).format('YYYY-MM-DD') : null,
      marriageDate: this.beneSelfDetailsForm.get('marriageDate').value ? moment(this.beneSelfDetailsForm.get('marriageDate').value).format('YYYY-MM-DD') : null,
      ctznCrtfctIssDate: this.beneSelfDetailsForm.get('ctznCrtfctIssDate').value ? moment(this.beneSelfDetailsForm.get('ctznCrtfctIssDate').value).format('YYYY-MM-DD') : null
    });
    const passportExpiryDate = this.beneSelfDetailsForm.get("passport")['controls'][0].get('expiryDate').value;
    const passportIssueDate = this.beneSelfDetailsForm.get("passport")['controls'][0].get('issueDate').value;
    this.beneSelfDetailsForm.get("passport")['controls'][0].patchValue({
      expiryDate: passportExpiryDate ? moment(passportExpiryDate).format('YYYY-MM-DD') : '',
      issueDate: passportIssueDate ? moment(passportIssueDate).format('YYYY-MM-DD') : '',
    });
    // if (this.checkProperties(this.beneSelfDetailsForm.value.address[2]) && this.checkProperties(this.beneSelfDetailsForm.value.address[2].countryCode.countryCode)) {
    //   //IM-3932-For Existing beneficiary-after clicking on edit link-country code, mobile no, email id, passport fields are showing blank
    //   this.beneSelfDetailsForm.value.address.splice(2);
    // }
    //IM-7524 fix
    const foreignAddressIndex = this.beneSelfDetailsForm.value.address.findIndex(x => x.type.code === 'FORN');
    const foreignAddressValue = (this.beneSelfDetailsForm.get('address') as FormArray)
      .controls[foreignAddressIndex]?.value;
    if (foreignAddressValue) {
      if (!this.showForienAddressMandatory && (!foreignAddressValue.addressLine1 || (foreignAddressValue.addressLine1 && foreignAddressValue.addressLine1.trim() === '')) &&
        (!foreignAddressValue.addressLine2 || (foreignAddressValue.addressLine2 && foreignAddressValue.addressLine2.trim() === '')) &&
        (!foreignAddressValue.locality || (foreignAddressValue.locality && foreignAddressValue.locality.trim() === '')) &&
        (!foreignAddressValue.countryCode || (foreignAddressValue.countryCode && (!foreignAddressValue.countryCode.countryCode || (foreignAddressValue.countryCode.countryCode && foreignAddressValue.countryCode.countryCode.trim() === '')))) &&
        ((this.listStatesForeign.length <= 0 &&
          (!foreignAddressValue.stateProvinceName ||
            (foreignAddressValue.stateProvinceName && foreignAddressValue.stateProvinceName.trim() === ''))) ||
          (this.listStatesForeign.length > 0 && (!foreignAddressValue.stateProvinceCode ||
            (foreignAddressValue.stateProvinceCode && foreignAddressValue.stateProvinceCode.trim() === '')))) &&
        (!foreignAddressValue.city || (foreignAddressValue.city && foreignAddressValue.city.trim() === '')) &&
        (!foreignAddressValue.postCode || (foreignAddressValue.postCode && foreignAddressValue.postCode.trim() === ''))) {
        this.beneSelfDetailsForm.value.address.splice(foreignAddressIndex);
      }
    }
    let url;



    const race = [];
    // if(this.beneficiaryData.race && this.beneficiaryData.race.length > 0){
    this.beneSelfDetailsForm.value.race.forEach((raceBoolean, i) => {
      if (raceBoolean) {
        const raceObj = this.raceArr[i];
        // //  const racePresent = this.beneficiaryData.race ? this.beneficiaryData.race.find(x => x.raceId.id === raceObj.id) : null;
        race.push({
          raceId: {
            id: raceObj.id,
            name: raceObj.name
          },
          id: 0
        });
        //race.push(raceObj);
      }
    });
    //}
    const payload = {
      ... this.beneSelfDetailsForm.value,
      hasCtznCrtfct: this.beneSelfDetailsForm.value.hasCtznCrtfct ? 1 : 0,
      lprThruMarriage: this.beneSelfDetailsForm.value.lprThruMarriage ? 1 : 0,
      maritalStatus: {
        ...this.beneSelfDetailsForm.value.maritalStatus,
        id: this.showMarriageSection ? this.beneSelfDetailsForm.value.maritalStatus.id : 9
      },
      ctznAcqrTypeId: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'] && this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'] !== 0 ? {
        id: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'],
        name: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['name']
      } : null,
      ethnicity: {
        id: this.beneSelfDetailsForm.value['ethnicity']['ethnicityId'],
        name: this.beneSelfDetailsForm.value['ethnicity']['name']
      },
      gender: {
        id: this.beneSelfDetailsForm.value['gender']['id'],
        name: this.beneSelfDetailsForm.value['gender']['name']
      },
      marriageStateProvinceCode: this.beneSelfDetailsForm.value['marriageStateProvinceCode'] ? this.beneSelfDetailsForm.value['marriageStateProvinceCode'] : null,
      birthCountryCode: this.beneSelfDetailsForm.value['countryCode']['countryCode'] ? this.beneSelfDetailsForm.value['countryCode'] : null,
      race,
      hasPassport: !this.beneSelfDetailsForm.value['hasPassport'],
      title: this.beneSelfDetailsForm.value['title'],
      ctznshipCountryCode: !this.beneSelfDetailsForm.value['ctznshipCountryCode']['countryCode'] ? { countryCode: null, countryName: null } : this.beneSelfDetailsForm.value['ctznshipCountryCode']
    };

    if (!(payload && payload.countryOfMarriage && payload.countryOfMarriage.countryCode)) {
      payload.countryOfMarriage = null;
    }



    let familyPayload: any = {
      family: []
    }
    if (this.detailsAbout.showDetailFor === 'self') {
      payload.id = this.beneficiaryId;
      if (payload.hasOwnProperty('relationShipType')) {
        delete payload.relationShipType;
      }
      payload['isSaveAsDraft'] = isDraft;
      if (this.onlyFormBuilder === 'FB') {
        let fbPayload = {
          companyId: this.companyId,
          employeeCreationType: this.fbDetails.employeeCreationType,
          employeeDataToBeFilled: this.fbDetails.employeeDataToBeFilled,
          employeeDataToBeFilledTypeJSON: JSON.stringify(payload),
          employeeType: this.fbDetails.employeeType,
          moduleId: this.fbDetails.moduleId,
          pageFormId: this.fbDetails.pageFormId,
          id: this.employeeId
        }
        url = this.apiService.saveEmployeeFB(fbPayload);
      } else {
        url = this.apiService.saveBeneficiaryDetails(this.beneficiaryId, 'self', payload);
      }

      // this.beneSelfDetailsForm.removeControl('relationShipType');

    } else {
      payload.id = payload.id ? payload.id : 0;
      familyPayload.family.push(payload)
      url = this.apiService.saveBeneficiaryFamilyDetails(this.beneficiaryId, 'family', familyPayload)

    }
    // this.customTriggeredStatus == false ? this.customTriggeredStatus = true: this.customTriggeredStatus = false;
    url.pipe(takeUntil(this.observableSubscription$)).subscribe((data: ImagilityBaseResponse) => {
      this.selectedSubTabId = null;
      if (data.status === 200) {
        if (this.onlyFormBuilder === 'FB') {
          this.beneficiaryId = data.data.beneficiaryId;
          sessionStorage.setItem('FbEmployeeId', this.beneficiaryId.toString());
        }
        if (this.detailsAbout.showDetailFor === 'self') {
          if (this.customQuestionsComponent) {
            this.customQuestionsComponent?.onClickSave(null); //IM-23009 - uncommenting this as custom questions not getting saved which are added under personal details.
          }
        } else {
          if (this.customQuestionsComponent) {
            this.customQuestionsComponent.onClickSave(data.data[0].id);
          }
        }

        // this.currentFamilyId = data.data[0].id;
        // this.customQuestionsService.selectedFamilyId = data.data[0].id;
        // this.customTrigger();
        this.beneSelfDetailsForm.reset(this.beneSelfDetailsForm.value);
        //form.resetForm();
        this.toastr.success(data.message, 'Success');
        if (this.detailsAbout.showDetailFor === 'dependent') {
          if (this.detailsAbout.dependentType.subTabsList) {
            this.selectedSubTabId = data.data[0].id ? data.data[0].id : null;
            // this.dependentProfileDataService.newSubDependentCreated.next(this.selectedSubTabId)
          }

          this.getBeneficiaryFamilyDetails(true);
          this.showAddIcon = false;
        } else {
          this.dependentProfileDataService.selfBeneficiaryDetailChange.next();
          if (this.onlyFormBuilder === 'FB') {
            this.getformBuilderDetails();
          } else {
            this.getBeneficiaryDetails();
          }

        }
        if (this.onlyFormBuilder === 'FB') {
          this.documentFileUploadConfigurations.family = false;
          this.documentFileUploadConfigurations = Object.assign({}, this.documentFileUploadConfigurations);
        }

        this.fileUploadConfigurations.family = false;
        this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

        this.selectedCurrentCheckbox = false;
        this.selectedPermanentCheckbox = false;
        this.beneSelfDetailsForm.disable();
        if (this.beneUpdateProfile) {
          this.isPersonalDetailsFormReset = true;
        }
        this.handleEdit();
        this.benServ.triggerSelfDetailsSaved();
      }
    });

  }


  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key].toString().trim() != "" && key != 'countryCode' && key != 'type')
        return false;
    }
    return true;
  }

  toggleAlias(e) {
    if (e.checked) {
      this.showAliasName = true;
      this.addAliasName();
    } else {
      this.showAliasName = false;
      this.removeAllAliasNames();
    }
  }

  removeAllAliasNames() {
    const aliasNameArray = this.beneSelfDetailsForm.get('aliasName') as FormArray;
    aliasNameArray.clear(); // Clear all alias names
  }

  addAliasName() {
    const aliasNameArray = this.beneSelfDetailsForm.get('aliasName') as FormArray;
    aliasNameArray.push(this.createAliasNameGroup());
  }

  createAliasNameGroup(): FormGroup {
    return this.beneSelfFormBuilder.group({
      fName: [''],
      title: [''],
      id: ['']
    });
  }

  // IM-4338 fix
  resetAddress(addressType: string) {
    let addressTypeCode = '';
    switch (addressType.toLowerCase()) {
      case 'permanent':
        this.listStatesPermanent = [];
        addressTypeCode = 'PERM';
        break;
      case 'foreign':
        this.listStatesForeign = [];
        addressTypeCode = 'FORN';
        break;
      case 'mailing':
        this.listStatesMailing = [];
        addressTypeCode = 'MAIL';
        break;
    }


    const resetAddress = this.getAddress(addressTypeCode);
    if (resetAddress) {
      const resetAddressType = resetAddress.value.type;
      const resetAddressID = resetAddress.value.id;
      resetAddress.reset();
      resetAddress.patchValue(
        {
          id: resetAddressID,
          type: resetAddressType
        }
      );
    }

  }

  emptyAddress(addressType: string) {
    const addressObj = {
      id: null,
      addressLine1: '',
      addressLine2: null,
      countryCode: {
        countryCode: null,
        countryName: null,
      },
      stateProvinceCode: null,
      stateProvinceName: null,
      state: null,
      city: '',
      locality: null,
      postCode: null,
      type: { id: null, code: null, name: null }
    };
    switch (addressType) {
      case 'Current':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null,
          code: 'CURR',
          name: 'Current'
        };
        break;
      case 'Permanent':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null,
          code: 'PERM',
          name: 'Permanent'
        };
        break;
      case 'Foreign':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null,
          code: 'FORN',
          name: 'Foreign'
        };
        break;
      case 'Mailing':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null,
          code: 'MAIL',
          name: 'Mailing'
        };
        break;
    }
    return addressObj;
  }

  copyAddress(copyFrom, copyTo) {
    if (copyFrom && copyTo) {
      const addressValue = this.getAddress(copyFrom);
      this.patch(addressValue.value, copyTo, copyTo);
    } else {
      this.resetAddress(copyTo);
    }
  }

  handleTabChange(index?) {
    // this.clearProfileData(this.beneSelfDetailsForm);


    const selectedDependentTab: any = this.detailsAbout.dependentType;

    this.beneSelfDetailsForm.addControl('relationShipType', this.beneSelfFormBuilder.group({
      id: [selectedDependentTab.id],
      name: [selectedDependentTab.name]
    }));

    // this.showMarriageSection = ['SPOUSE', 'MOTHER'].includes(this.detailsAbout.dependentType.code) ? false : true;
    if (this.beneficiaryFamilyInfo.length > 0) {
      if (selectedDependentTab.subTabsList) {
        const data = this.beneficiaryFamilyInfo.find(item => item.id == this.detailsAbout.selectedSubTabId);
        this.beneficiaryData = data ? data : this.emptyBeneficiaryData(selectedDependentTab);
      } else {
        const data = this.beneficiaryFamilyInfo.find(item => item.relationShipType && item.relationShipType.id === selectedDependentTab.id)
        this.beneficiaryData = data ? data : this.emptyBeneficiaryData(selectedDependentTab);
      }

    } else {
      this.beneficiaryData = this.emptyBeneficiaryData(selectedDependentTab);
    }

    this.setBeneficiaryFormData();
  }

  handleCountryChange(countryCode: string, stateIndex) {

    // IM-3402 - Added to update state blank for US and India
    if (stateIndex === 'Current') {
      this.listStatesCurrent = [];
    } else if (stateIndex === 'Permanent') {
      this.listStatesPermanent = [];
    } else if (stateIndex === 'Mailing') {
      this.listStatesMailing = [];
    } else if (stateIndex === 'Foreign') {
      this.listStatesForeign = [];
    } else if (stateIndex === 'Birth') {
      this.listStatesBirth = [];
    } else if (stateIndex === 'Marriage') {
      this.listStatesMarriage = [];
    }

    if (null === countryCode) return;

    this.apiService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      // console.log(states?.length);
      switch (stateIndex) {
        case 'Current':
          this.listStatesCurrent = states;
          // this.applyStateValidation(states, 0);
          // this.applyForeignAddressValidation(countryCode, states, 2); //IM-3402 fix
          break;
        case 'Permanent':
          this.listStatesPermanent = states;
          //this.applyStateValidation(states, 1);
          break;
        case 'Foreign':
          this.listStatesForeign = states;
          //this.applyForeignStateValidation(states, 2); //IM-3402 fix
          break;
        case 'Birth':
          this.listStatesBirth = states;
          //this.applyBirthStateValidation(states, countryCode);
          break;
        case 'Marriage':
          this.listStatesMarriage = states;
          //  this.applyBirthStateValidation(states);
          break;
        case 'Mailing':
          this.listStatesMailing = states;
          break;

      }
    });
  }

  handleStateChange(statecode, stateIndex) {
    this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').clearValidators();
    if (statecode === "null") {
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').setValidators(null);
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').value = null;
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceName').value = '';
      this.beneSelfDetailsForm.updateValueAndValidity();
    }
  }
  resetStateOnCountryChange(index: number) {
    this.beneSelfDetailsForm.get('address')['controls'][index].get('stateProvinceCode').value = null;
    this.beneSelfDetailsForm.get('address')['controls'][index].get('stateProvinceName').value = '';
  }

  resetBirthStateOnCountryChange() {
    this.beneSelfDetailsForm.get('birthStateProvinceCode').clearValidators();
    this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
    this.beneSelfDetailsForm.get('birthStateProvinceCode').setValue(null);
    this.beneSelfDetailsForm.get('birthStateProvinceName').setValue('');
    this.beneSelfDetailsForm.updateValueAndValidity();
  }

  applyStateValidation(states, type) {
    if (states.length > 0 && this.beneficiaryType === 'self') {
      // IM-3402 - For state validation
      this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').setValidators([Validators.required]);
    } else {
      //IM-3948-beneficiary-profile - removed validation for stateprovicename
      this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
      //IM-3948 - validators were getting cleared but the errors was not getting reset.
      this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
    }
    this.beneSelfDetailsForm.updateValueAndValidity();
  }
  applyForeignStateValidation(states, type) {
    this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
    if (states.length > 0) {
      // this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').setValidators([Validators.required]);
    }
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
    // this.beneSelfDetailsForm.updateValueAndValidity();

  }

  applyBirthStateValidation(states, countryCode?) {
    if (states.length > 0) {
      this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
      this.beneSelfDetailsForm.get('birthStateProvinceCode').setValidators([Validators.required]);
    }
    else {
      this.beneSelfDetailsForm.get('birthStateProvinceCode').clearValidators();
      this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
      if (countryCode === 'CAN') {
        this.beneSelfDetailsForm.get('birthStateProvinceName').setValidators([Validators.required]);
        this.isBirthStateRequired = true;
      } else {
        this.isBirthStateRequired = false;
      }

      //IM-3768 this.beneSelfDetailsForm.get('birthStateProvinceName').setValidators([Validators.required]);
    }
    this.beneSelfDetailsForm.updateValueAndValidity();
  }

  //IM-3402 fix
  applyForeignAddressValidation(countryCode, states, type) {
    if (countryCode === 'USA' && this.beneficiaryType === 'self') {
      this.showForienAddressMandatory = true;
      this.beneSelfDetailsForm.get('address')['controls'][type].get('addressLine1').setValidators([Validators.required]);
      this.beneSelfDetailsForm.get('address')['controls'][type].get('city').setValidators([Validators.required]);
    } else {
      this.showForienAddressMandatory = false;
      this.clearForeignAddrValidators(type);
    }
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
  }

  customFormUpdateAndValidity(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].updateValueAndValidity();
    });
  }

  clearForeignAddrValidators(type) {
    this.beneSelfDetailsForm.get('address')['controls'][type].get('addressLine1').clearValidators();
    this.beneSelfDetailsForm.get('address')['controls'][type].get('city').clearValidators();
    this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);

  }
  //IM-3403 fix
  updateCountry(countryCode, field) {
    const ctznshipCountryCodeVal = this.beneSelfDetailsForm.get("ctznshipCountryCode.countryCode").value;
    if (ctznshipCountryCodeVal === 'USA') {
      this.showCitizenShipAquiredInfo = true;
      // if (this.beneficiaryType === 'self') {
      //   this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').setValidators(Validators.required);
      //   this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').updateValueAndValidity();
      // }
    } else {
      this.showCitizenShipAquiredInfo = false;
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').clearValidators();
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').reset(null);
      this.beneSelfDetailsForm.get('hasCtznCrtfct').reset(false);
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').updateValueAndValidity();
      this.setValidatorForCitizenShipAquired()
    }
    // this.setValidatorForCitizenShipAquired();
    if (field === 'citizenship') {
      this.beneSelfDetailsForm.get("ctznshipCountryCode").patchValue({
        countryCode: countryCode,
      });
      this.updateCountry(countryCode, 'issueCountryCode');
    } else {
      if (this.onlyFormBuilder !== 'FB') {
        this.beneSelfDetailsForm.get("passport")['controls'][0].get('issueCountryCode').patchValue({
          countryCode: countryCode,
        });
      }
    }
  }

  resetCitizenShipAquiredValues() {

    const ctznCrtfctNoCtrl = this.beneSelfDetailsForm.get('ctznCrtfctNo') as FormControl;
    const ctznCrtfctIssPlaceCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssPlace') as FormControl;
    const ctznCrtfctIssDateCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssDate') as FormControl;
    const validationCitizenshipArr = [ctznCrtfctNoCtrl, ctznCrtfctIssPlaceCtrl, ctznCrtfctIssDateCtrl];
    for (let i = 0; i < validationCitizenshipArr.length; i++) {
      validationCitizenshipArr[i].patchValue(null);
      validationCitizenshipArr[i].clearValidators();
      validationCitizenshipArr[i].reset();
      validationCitizenshipArr[i].markAsPristine();
      validationCitizenshipArr[i].updateValueAndValidity();
    }

  }

  setValidatorForCitizenShipAquired() {
    //  const hasCtznCrtfctCtrl = this.beneSelfDetailsForm.get('hasCtznCrtfct') as FormControl;
    //  const ctznAcqrTypeIdCtrl = this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId') as FormControl;
    // const ctznCrtfctNoCtrl = this.beneSelfDetailsForm.get('ctznCrtfctNo') as FormControl;
    // const ctznCrtfctIssPlaceCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssPlace') as FormControl;
    // const ctznCrtfctIssDateCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssDate') as FormControl;
    // const validationCitizenshipArr = [ctznCrtfctNoCtrl, ctznCrtfctIssPlaceCtrl, ctznCrtfctIssDateCtrl];
    // // hasCtznCrtfctCtrl.setValidators
    // for (let i = 0; i < validationCitizenshipArr.length; i++) {
    //   if (this.showCitizenShipAquiredInfo) {
    //     validationCitizenshipArr[i].setValidators(Validators.required);
    //     ctznCrtfctNoCtrl.setValidators([Validators.required, Validators.pattern(this.certificateNumberValidPattern)]);
    //     ctznCrtfctIssPlaceCtrl.setValidators([Validators.required, Validators.pattern(this.textOnlyValidPattern)]);

    //   } else {
    //     validationCitizenshipArr[i].patchValue(null);
    //     validationCitizenshipArr[i].reset();
    //     validationCitizenshipArr[i].clearValidators();
    //   }
    //   validationCitizenshipArr[i].updateValueAndValidity();
    // }


  }

  foreignAddChange($event: MatRadioChange) {
    if (this.showforeignAddress && $event.value === 'PA') {
      let Address = this.getAddress('PERM');
      this.patch(Address['value'], 'Permanent', 'Foreign');
    } else if (this.showforeignAddress && $event.value === 'CA') {
      let Address = this.getAddress('CURR');
      this.patch(Address['value'], 'Current', 'Foreign');
    }
  }

  getAddress(code): FormGroup {
    let Address: any;
    for (const control of (this.beneSelfDetailsForm.get('address') as FormArray).controls) {
      if (control.value.type.code === code) {
        Address = control;
        return Address;
      }
    }
  }

  permanentAddChange($event: MatRadioChange) {
    if ($event.value === 'CA') {
      this.patch(
        this.beneSelfDetailsForm.get('address').get('0').value, 'Current', 'Permanent');
    }
  }
  getAddressGroup(addrType) {
    let temp = this.beneSelfDetailsForm.get('address');
    if (temp instanceof FormArray) {
      return temp.controls.findIndex(x => x.value['type']['code'] === addrType);
    }
  }
  validateExpiry(issueDate, expiryDate) {
    this.showExpiryValidation = this.customValidationService.MaxDateValidate(issueDate, expiryDate);
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  handleCitizenshipSwitchChange() {

    if (!this.beneSelfDetailsForm.get('hasCtznCrtfct').value) {
      this.resetCitizenShipAquiredValues();
    } else if (this.beneficiaryType === 'self') {
      this.setValidatorForCitizenShipAquired();
    }

  }
  handleLawfulSwitchChange() {

  }
  handleIsLprChanges() {
    // const isLprVlaue = this.beneSelfDetailsForm.get('isLpr').value;
    // const greenCardControl = this.beneSelfDetailsForm.get('greenCardNo');
    // const lprThruMarriageCtrl = this.beneSelfDetailsForm.get('lprThruMarriage');
    // if (isLprVlaue && this.beneficiaryType === 'self') {
    //   greenCardControl.setValidators([Validators.required, Validators.pattern(this.greenCardValidPattern)]);
    // } else {
    //   greenCardControl.clearValidators();
    //   greenCardControl.patchValue(null);
    //   lprThruMarriageCtrl.patchValue(null);
    //   // greenCardControl.setValidators([Validators.pattern(this.greenCardValidPattern)]);
    // }
    // greenCardControl.updateValueAndValidity();
  }

  handleRaceSelection(isChecked, id, index) {

    this.beneSelfDetailsForm.get('race')['controls'][index].get('raceId').patchValue({
      checked: isChecked ? true : false
    });


  }

  toggleEditView(val) {
    this.showAddIcon = false;
    this.handleEdit();
    // this.editDetails = true;

  }

  emptyBeneficiaryData(selectedDepedent?): BeneficiaryDetails {

    // this.setvalidatations();
    //  this.beneSelfDetailsForm.reset();
    this.submitted = false;
    return {
      id: 0,
      userName: '',
      visaSponsor: '',
      title: '',
      fName: '',
      mName: '',
      lName: ' ',
      profilePic: ' ',
      aliasName: [{
        id: 0,
        title: '',
        fName: '',
        mName: '',
        lName: '',
      }],
      currentVisaStatus: '',
      visaStatus: '',
      birthState: null,
      birthCity: '',
      birthStateProvinceCode: '',
      birthStateProvinceName: '',
      ctznshipCountryCode: {
        countryCode: '',
        countryName: '',
      },
      gender: {
        id: 0,
        name: '',
      },
      countryCode: {
        countryCode: '',
        countryName: '',
      },
      dob: null,
      identificationMark: '',
      height: null,
      skinColor: '',
      eyeColor: '',
      hairColor: '',
      phoneContacts: [
        {
          id: 0,
          phoneNo: null,
          countryCode: {
            phoneCode: 0,
            countryCode: '',
            countryName: '',
          },
          type: {
            code: '',
            name: '',
          }
        }
      ],
      emailContacts: [{
        email: '',
        id: 0,
        type: {
          code: 'PRIM',
          id: this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null
        }
      }],
      hasPassport: true,
      passport: [{
        id: 0,
        passportNo: '',
        issueDate: null,
        expiryDate: null,
        issueCountryCode: {
          countryCode: '',
          countryName: '',
        }
      }],
      addressDifferFlag: null,
      address: [
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: '',
            countryName: '',
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'FORN',
            name: 'FOREIGN',
            id: 232,
          },
        },
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: '',
            countryName: '',
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'PERM',
            name: 'Permanent',
            id: 2,
          },
        },
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: '',
            countryName: '',
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'CURR',
            name: 'Current',
            id: 1,
          },
        }
      ],
      ssn: '',
      relationShipType: {
        id: selectedDepedent && selectedDepedent.id ? selectedDepedent.id : 0,
        name: selectedDepedent && selectedDepedent.name ? selectedDepedent.name : ''
      },
      maritalStatus: {
        id: selectedDepedent && ['SPOUSE', 'MOTHER'].includes(selectedDepedent.code) ? 9 : null,
        name: '',
      },
      countryOfMarriage: {
        countryCode: '',
        countryName: '',
      },
      marriageStateProvinceCode: null,
      marriageStateProvinceName: '',
      cityOfMarriage: '',
      isLpr: null,
      lprThruMarriage: 0,
      greenCardNo: '',
      ethnicity: {
        id: 0,
        ethnicityId: 0,
        name: '',
      },

      race: null,
      weight: 0,
      ctznAcqrTypeId: {
        ctznAcqrId: null,
        id: null,
        name: '',
      },
      hasCtznCrtfct: null,
      ctznCrtfctNo: '',
      ctznCrtfctIssPlace: '',
      ctznCrtfctIssDate: null,
      marriageDate: null,
      profileImage: null,
    }

  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  uploadSignatureImage() {
    this.showCropperSection = false;

    const dialogRef = this.dialog.open(SignatureUploadComponent, {
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.sessionpicimage = sessionStorage.setItem("SesionpicValue", this.signatureImage)
      const file = this.dataURItoBlob(result);
      //console.dir("blob", file);

      let reader = new FileReader();
      let self = this;

      reader.readAsDataURL(file);
      reader.onload = function () {

        self.signatureImage = reader.result as string;
      };
      reader.onerror = function (error) {

      };

      this.signatureImageForm = new FormData();
      this.signatureImageForm.append("file", file, "mysignature.png");


      if (this.onlyFormBuilder !== 'FB') {

        if (this.beneficiaryData.id === this.beneficiaryId) {
          this.apiService.postSignatureImageBen(this.beneficiaryId, this.signatureImageForm).subscribe((response) => {

            if (response['status'] === 200) {
              this.toastr.success('Image Uploaded successfully', 'Success');
            }
            else {
              CustomErrorToastComponent.showErrorToast(this.toastr, 'Image is not Uploaded successfully');
            }
          });
        } else {
          if (this.beneficiaryData.id !== 0) {
            this.apiService.postFamilySignatureImageBen(this.beneficiaryId, this.beneficiaryData.id, this.signatureImageForm).subscribe((response) => {

              if (response['status'] === 200) {
                this.toastr.success('Image Uploaded successfully', 'Success');
              }
              else {
                CustomErrorToastComponent.showErrorToast(this.toastr, 'Image is not Uploaded successfully');
              }
            });
          }
        }
      } else {
        if (this.beneficiaryData.id === this.beneficiaryIdFromFB) {
          this.apiService.postSignatureImageBen(this.beneficiaryIdFromFB, this.signatureImageForm).subscribe((response) => {

            if (response['status'] === 200) {
              this.toastr.success('Image Uploaded successfully', 'Success');
            }
            else {
              CustomErrorToastComponent.showErrorToast(this.toastr, 'Image is not Uploaded successfully');
            }
          });
        }
      }
    });
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  getSignatureImage() {
    let signImage = "";
    if (this.onlyFormBuilder === 'FB') {
      if (this.beneficiaryId === this.beneficiaryData.id) {
        this.apiService.getSignatureImageBen(this.beneficiaryId).subscribe((response) => {
          if (response['status'] === 200) {
            this.signatureImage = (response['data']['signImage'] !== null && response['data']['signImage'] !== undefined) ? `data:image/jpeg;base64,${response['data']['signImage']}` : '';
            //alert(this.sessionpicimage);
          }
        });
      } else {
        if (this.beneficiaryData.id > 0) {
          this.apiService.getFamilySignatureImageBen(this.beneficiaryId, this.beneficiaryData.id).subscribe((response) => {
            if (response['status'] === 200) {
              this.signatureImage = (response['data']['signImage'] !== null && response['data']['signImage'] !== undefined) ? `data:image/jpeg;base64,${response['data']['signImage']}` : '';
              //alert(this.sessionpicimage);
            }
          });
        }
      }
    } else {
      if (this.beneficiaryIdFromFB === this.beneficiaryData.id) {
        this.apiService.getSignatureImageBen(this.beneficiaryIdFromFB).subscribe((response) => {
          if (response['status'] === 200) {
            this.signatureImage = (response['data']['signImage'] !== null && response['data']['signImage'] !== undefined) ? `data:image/jpeg;base64,${response['data']['signImage']}` : '';
            //alert(this.sessionpicimage);
          }
        });
      }
    }

  }

  backToOriginPage() {
    sessionStorage.removeItem('redirectUrl');
    //this.router.navigate([`${this.redirectUrl}`]);
  }

  backToTask() {
    const taskPageUrl = sessionStorage.getItem('redirectUrl');
    sessionStorage.removeItem('redirectUrl');
    this.router.navigate([`${taskPageUrl}`]);
  }
  removeValidations(formElement: FormGroup | FormArray) {
    Object.keys(formElement.controls).forEach(field => {
      const control = formElement.get(field);
      if (control instanceof FormControl) {
        control.clearValidators();
        control.updateValueAndValidity();
      } else if (control instanceof FormArray) {
        this.removeValidations(control)
      } else if (control instanceof FormGroup) {
        this.removeValidations(control)
      }
    });
  }

  saveAsDraft(event) {
    this.savePersonalData(event);
  }

}

//IM-3402 fix done by jeeva 08/10/2020::06P.M
//IM-3358 user story,IM-3403 -fix done by jeeva 12/10/2020
