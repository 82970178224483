import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { GCI140State } from '../../store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { getStepDetails } from '../../store/selectors/sub-task-details.selectors';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { DeleteDialogConfigurations, NoteDialogConfigurations } from '../../utility/configurations/dialog.configuration';
import { updatei140StepStatus, updateStepStatusToInitialState } from '../../store/actions/step-status-update.actions';
import { getStepStatusUpdate } from '../../store/selectors/step-status-update.selectors';
import { loadSubTaskDetails } from '../../store/actions/sub-task-details.actions';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { Tranings } from 'src/app/data/models/trainings.model';
import { License } from 'src/app/data/models/license.model';
import { GCEducationDetails } from '../../../../data/models/gcEducation.model';
import { environment } from '../../../../../environments/environment';
import * as RolesRouteMap from 'app-models';
import { Router } from '@angular/router';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-education-details',
  templateUrl: './education-details.component.html',
  styleUrls: ['./education-details.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class EducationDetailsComponent implements OnInit {
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  educationCols: any[];
  educationSubCols: any[];
  trainingCols: any[];
  linceseCols: any[];
  fileDocuments: any[];
  stepDetails: TaskStep;
  educationList: GCEducationDetails[];
  licensesList: License[];
  trainingList: Tranings[];
  userType: string;
  observableSubscription$ = new Subject();
  questionnaire :boolean;
  stepId: any;
  selectedState: string = 'simple';
  isEditMode: boolean = false;
  primaryData: any;

  constructor(
    private pbService: PetitionBuilderService,
    private toastr: ToastrService,
    private store: Store<any>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayload,
    public router: Router,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.typeOfButton = null;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.disabledControls = false;
    this.questionnaire = false;
    this.educationCols = [
      { field: 'degree', header: 'Education' },
      { field: 'fieldOfStudy', header: 'Field of Study' },
      { field: 'university', header: 'University Name' },
      { field: 'grade', header: 'Grade' },
      { field: 'startYear', header: 'Start Year' },
      { field: 'endYear', header: 'Year Completed' },
      { field: 'action', header: 'Action' },
    ];
    this.educationSubCols = [
      { field: 'addressLine1', header: 'Address 1' },
      { field: 'addressLine2', header: 'Address 2' },
      { field: 'city', header: 'City' },
      { field: 'countryCode', header: 'Country' },
      { field: 'stateProvinceName', header: 'State' },
      { field: 'postCode', header: 'ZIP / Postal Code' },
    ];
    this.trainingCols = [
      { field: 'name', header: 'Name' },
      { field: 'subjects', header: 'Topic of Study' },
      { field: 'institution', header: 'Institution' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'endDate', header: 'End Date' },
      { field: 'action', header: 'Action' },
    ];
    this.linceseCols = [
      { field: 'name', header: 'Name' },
      { field: 'subjects', header: 'Topic of Study' },
      { field: 'institution', header: 'Institution' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'endDate', header: 'End Date' },
      { field: 'action', header: 'Action' },
    ];
    this.questionnaire = window.location.pathname === "/beneficiary-profile/profile" ?  true : false;
  }

  ngOnInit(): void {
    this.primaryData = this.dynamicDialogConfig.data;
    this.stepId = this.dynamicDialogConfig.data.stepId;
    // Selector for step status update
    this.store.pipe(select(getStepStatusUpdate)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(loadSubTaskDetails({ taskId: this.stepDetails.taskId }));
      }
    });
    // Get Specific step details by passing step code
  
    this.store.pipe(select(getStepDetails, { id: this.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      try {
        if (data) {
          this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
          this.stepDetails = data;
          this.toggleControlsStatus();
          this.getEducationList(false);
          this.getLicensesList(false);
          this.getTrainingList(false);
        }
      } catch (error) {
        console.log("", error);
      }

    });
    // there is no save, so on landing of the page we are updating status to "INPROGRESS" if status is "NEW"
    // if (this.stepDetails && this.stepDetails.stepStatusDetails && this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
    //   this.updateStepStatus('INPROGRESS');
    // }
  }

  getEducationList(isUsedForBenEdit: boolean) {
    this.pbService.getBeneEducationInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, isUsedForBenEdit).pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      this.educationList = data;
    });
  }

  syncProfileData() {
    this.pbService.syncProfileData(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.educationList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
        this.getLicensesList(false);
        this.getTrainingList(false);
      }
    });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  syncAllEducation() {
    this.pbService.syncAllBeneEducation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.educationList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
      }
    });
  }

  syncSingleEduaction(input) {
    this.pbService.syncBeneEducationId(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.educationList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
      }
    });
  }

  deleteEducationList(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteEducationInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getEducationList(false);
        })
      }
    });
  }

  deleteCoursesList(subeducationlist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteCourseDetails(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, subeducationlist.id, item.courseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getEducationList(false);
        })
      }
    });
  }

  deleteDocumentsList(subeducationlist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteEducationDocuments(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, subeducationlist.id, item.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getEducationList(false);
        })
      }
    });
  }

  getTrainingList(isUsedForBenEdit: boolean) {
    this.pbService.getTrainingInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, isUsedForBenEdit).pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      this.trainingList = data;
    });
  }

  handleTrainingSyncAll() {
    this.pbService.syncAllTraining(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.licensesList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getTrainingList(false);
      }
    });
  }

  handleTrainingSync(input) {
    this.pbService.syncTraining(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.licensesList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getTrainingList(false);
      }
    });
  }

  deleteTrainingList(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteTrainingInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getTrainingList(false);
        })
      }
    });
  }

  deleteTrainingDocumentsList(subeducationlist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteTrainingDocuments(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, subeducationlist.id, item.id).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getTrainingList(false);
        })
      }
    });
  }

  getLicensesList(isUsedForBenEdit: boolean) {
    this.pbService.getLicensesInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, isUsedForBenEdit).pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      this.licensesList = data;
    });
  }

  handleLicensesSyncAll() {
    this.pbService.syncAllLicenses(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.licensesList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getLicensesList(false);
      }
    });
  }

  handleLicensesSync(input) {
    this.pbService.syncLicenses(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        this.licensesList = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getLicensesList(false);
      }
    });
  }

  deleteLicensesList(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteLicensesInformation(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getLicensesList(false);
        })
      }
    });
  }

  deleteLicensesDocumentsList(subeducationlist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteLicensesDocuments(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, subeducationlist.id, item.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getLicensesList(false);
        })
      }
    });
  }

  handleSendBack(item) {
    NoteDialogConfigurations.data = {
      title: `Rejected Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCREJECT",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCMISS",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  //To Verify Missing Fields
  handleVerify() { }

  onRowExpand(event) {
    console.log(event.data);
  }
  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  updateStepStatus(status) {
    this.store.dispatch(updatei140StepStatus({ payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode: this.stepDetails.stepActionList[0].layoutCode }));
  }

  handleClose() {
    this.dynamicDialogRef.close();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
  updateEducation() {
    let a = window.location.href
    if (a.toString().includes('/beneficiary-profile')) {
      window.location.href = window.location.origin + '/beneficiary-profile/profile?prop=BENE&section=2';
    } else {
      let listBeneficiaryId = (sessionStorage.getItem('beneficiaryId'));
      sessionStorage.setItem('listBeneficiaryId', listBeneficiaryId);
      sessionStorage.setItem('bene-profile-viewMode', 'false');
      let url;
      if (this.userType === RolesRouteMap.ATTORNEY) {
        url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiary/profile/education`, { listview: true }];
      } else {
        url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/beneficiaries/profile/education`, { listview: true }];
      }
      this.router.navigate(url, { state: { redirectUrl: this.router.url, redirectPage: 'Back to Petition Builder' } });
      sessionStorage.setItem('bene-update-experience', 'true');
      sessionStorage.removeItem('bene-update-profile');
    }
  }


  onChange($event) {
    this.selectedState = $event.value;
    if (this.selectedState === 'complete') {
      sessionStorage.setItem('selectedState','complete');
    }else if(this.selectedState === 'simple'){
      this.isEditMode = false;
      sessionStorage.setItem('selectedState','simple');
    }
  }

  onEditEducation() {
    this.isEditMode = true;   
  }

  onEditClose() {
    this.isEditMode = false;
    this.getEducationList(false);
    this.getTrainingList(false);
    this.getLicensesList(false);

  }

  handleDialogClose() {
    // TODO
  }

  syncToProfile() {
    this.pbService.syncToProfile(this.primaryData.caseType, this.primaryData.caseId).subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
        this.getLicensesList(false);
        this.getTrainingList(false);
      }
    });
  }

  mergeEducation() {
    this.pbService.mergeEducation(this.primaryData.superTaskId, this.primaryData.subTaskId).subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
        this.getLicensesList(false);
        this.getTrainingList(false);
      }
    });
  }

  resetEducation() {
    this.pbService.resetEducation(this.primaryData.superTaskId, this.primaryData.subTaskId).subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getEducationList(false);
        this.getLicensesList(false);
        this.getTrainingList(false);
      }
    });
  }

  mergeTraining() {
    this.pbService.mergeTraining(this.primaryData.superTaskId, this.primaryData.subTaskId, this.primaryData.caseType).subscribe((response: any) => {
      if(response.status === 200) {
        this.getTrainingList(false);
      }
    });
  }

  resetTraining() {
    this.pbService.resetTraining(this.primaryData.superTaskId, this.primaryData.subTaskId, this.primaryData.caseType).subscribe((response: any) => {
      if(response.status === 200) {
        this.getTrainingList(false);
      }
    });
  }

  mergeLicense() {
    this.pbService.mergeLicense(this.primaryData.superTaskId, this.primaryData.subTaskId, this.primaryData.caseType).subscribe((response: any) => {
      if(response.status === 200) {
        this.getLicensesList(false);
      }
    });
  }

  resetLicense() {
    this.pbService.resetLicense(this.primaryData.superTaskId, this.primaryData.subTaskId, this.primaryData.caseType).subscribe((response: any) => {
      if(response.status === 200) {
        this.getLicensesList(false);
      }
    });
  }
}
