<div *ngIf="userType == 'Attorney'">
<button class="btn-success" type="button" pButton (click)="generateResponse();" label="Generate Final Response"></button>
<label *ngIf="pdfTimeStamp" class="text-secondary ml-2">{{pdfTimeStamp | date:'dd-MMM-yyyy'}}</label>
<hr />
<pdf-viewer [src]="pdfSrc" useBrowserLocale="true"></pdf-viewer>
<hr *ngIf="pdfSrc"/>  
<div>
    <button *ngIf="pdfSrc" class="btn-success" type="button" pButton (click)="printResponse();" label="Print Final Response"></button>
    <div *ngIf="!pdfSrc" style="width:208px;display:inline-block"></div>
    <button *ngIf="pdfSrc && !disableFields" type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" style="left:20%" (click)="changeStatusToComplete();" 
        label="Mark Complete" class="button_whitebg p-button-outlined"></button>
    <div style="margin-left:20%" *ngIf="disableFields" class="d-inline-block">
            <img src="../../../../../assets/images/stepCompleteLabel.png" />
          </div>     
</div>     
</div>   

<div *ngIf="userType != 'Attorney'" class="p-3 border rounded bg-light">
    <div><strong>Last Updated By : </strong> {{ SubTaskOverView['preparerAttorneyName']}}</div>
    <hr/>
    <div><strong>Status : </strong> {{ SubTaskOverView['taskStepDetails'][6]['stepStatusDetails']['stepStatusName'] }}</div>
    <div><strong>Comment : </strong> {{ SubTaskOverView['taskStepDetails'][6]['stepNote'] }}</div>
  </div>