import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FAMILYDATA, EMPLOYMENTDATA } from '../../../../data/constants/visa-bulletin';

@Component({
  selector: 'app-visa-bulletin',
  templateUrl: './visa-bulletin.component.html',
  styleUrls: ['./visa-bulletin.component.scss']
})
export class VisaBulletinComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  familyCurrentList: any = [];
  familyFillingList: any = [];
  empCurrentList: any = [];
  empFillingList: any = [];
  dateTypesFam;
  dateTypesEmp;
  activeTasksTab = 'familyBased';
  activedatesFam = 'FCURRENT';
  activedatesEmp = 'ECURRENT';
  dateFilter;

  userType: string;

  cols: any[];

  familyBasedCurrentDateCols: any[];
  familyBasedFillingDateCols: any[];
  empBasedCurrentDateCols: any[];
  empBasedFillingDateCols: any[];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.dateTypeFam();
    this.dateTypeEmp();

    this.familyBasedCurrentDateCols = [
      { field: 'sponsored', header: 'Family-Sponsored ' },
      { field: 'allChargeability', header: 'All Chargeability Areas Except Those Listed' },
      { field: 'china', header: 'CHINA-mainland born' },
      { field: 'india', header: 'INDIA' },
      { field: 'mexico', header: 'MEXICO' },
      { field: 'philippines', header: 'PHILIPPINES' },
    ];
    this.familyBasedFillingDateCols = [
      { field: 'sponsored', header: 'Family-Sponsored ' },
      { field: 'allChargeability', header: 'All Chargeability Areas Except Those Listed' },
      { field: 'china', header: 'CHINA-mainland born' },
      { field: 'india', header: 'INDIA' },
      { field: 'mexico', header: 'MEXICO' },
      { field: 'philippines', header: 'PHILIPPINES' },
    ];
    this.empBasedCurrentDateCols = [
      { field: 'sponsored', header: 'Employment-based ' },
      { field: 'allChargeability', header: 'All Chargeability Areas Except Those Listed' },
      { field: 'china', header: 'CHINA-mainland born' },
      { field: 'india', header: 'INDIA' },
      { field: 'mexico', header: 'MEXICO' },
      { field: 'philippines', header: 'PHILIPPINES' },
    ];
    this.empBasedFillingDateCols = [
      { field: 'sponsored', header: 'Family-Sponsored ' },
      { field: 'allChargeability', header: 'All Chargeability Areas Except Those Listed' },
      { field: 'china', header: 'CHINA-mainland born' },
      { field: 'india', header: 'INDIA' },
      { field: 'mexico', header: 'MEXICO' },
      { field: 'philippines', header: 'PHILIPPINES' },
    ];

    this.getFamilyCurrentDate();
    this.getFamilyFillingDate();
    this.getEmployeeCurrentDate();
    this.getEmployeeFillingDate();
  }

  getFamilyCurrentDate() {
    
    this.familyCurrentList = FAMILYDATA[0].item.slice(0, 10);
 
  }
  getFamilyFillingDate() {
    this.familyFillingList = FAMILYDATA[1].item.slice(0, 10);

  }
  getEmployeeCurrentDate() {
    this.empCurrentList = EMPLOYMENTDATA[0].item.slice(0, 10);

  }
  getEmployeeFillingDate() {
    this.empFillingList = EMPLOYMENTDATA[1].item.slice(0, 10);
  }


  // Filter

  dateTypeFam() {
    this.dateTypesFam = [{
      name: 'Current Priority  Date',
      id: 101,
      code: 'FCURRENT',
    },
    {
      name: 'Date for filling for Visa Application',
      id: 102,
      code: 'FFILLING',
    }]
  }

  dateTypeEmp() {
    this.dateTypesEmp = [{
      name: 'Current Priority  Date',
      id: 101,
      code: 'ECURRENT',
    },
    {
      name: 'Date for filling for Visa Application',
      id: 102,
      code: 'EFILLING',
    }]
  }

  getDateTypeFamily(dateFilter: string) {
    this.activedatesFam = dateFilter;
    switch (dateFilter) {
      case 'FCURRENT':
        this.getFamilyCurrentDate();
        break;
      case 'FFILLING':
        this.getFamilyFillingDate();
    }

  }


  getDateTypeEmployee(dateFilter: string) {
    this.activedatesEmp = dateFilter;
    switch (dateFilter) {
      case 'ECURRENT':
        this.getEmployeeCurrentDate();
        break;
      case 'EFILLING':
        this.getEmployeeFillingDate();
    }

  }


  showMore() {
    if(sessionStorage.getItem('userTypeRole')=='Petitioner'){
      this.router.navigate(['/petitioner-landing/visaBulletin'], { queryParams: { data: 1 } });
    } else if(sessionStorage.getItem('userTypeRole')=='Attorney'){
      this.router.navigate(['/attorney-landing/visaBulletin'], { queryParams: { data: 1 } });
    }
  }


  onActiveTaskTab(activeTab: string) {
    this.activeTasksTab = activeTab;
    switch (activeTab) {
      case 'familyBased':
        this.getFamilyCurrentDate();
        break;
      case 'employeeBased':
        this.getEmployeeCurrentDate();
    }
  }


}// class end

