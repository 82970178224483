import { T } from "@angular/cdk/keycodes";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-generate-invoice",
  templateUrl: "./generate-invoice.component.html",
  styleUrls: ["./generate-invoice.component.scss"],
})
export class GenerateInvoiceComponent implements OnInit {
  @Input("isClientGenerateInvoice") isClientGenerateInvoice: boolean;
  @Input("petitionerId") petitionerId: any;
  noInvoiceData: boolean = true;
  invoiceObj: any;
  dashboardUrl: string;
  invoiceId: any = null;
  invoiceType: any;
  currentStep: number = 0;
  form: any;
  editInvoiceItem;
  editInvoiceId;
  invType: any;
  sendDraftItemId: any;
  constructor(private router: Router) {}

  ngOnInit(): void {
    // console.log("history.state.itemType", history.state);
    // console.log("history.state  invoiceItem ::", history.state.invoiceItem);
    // console.log(
    //   "history.state  sendDraftItemId ::",
    //   history.state.sendDraftItemId
    // );
    this.editInvoiceItem = history.state.invoiceItem;
    if (this.editInvoiceItem) {
      this.editInvoiceId = this.editInvoiceItem.invoiceId;
      // this.sendDraftItemId = this.editInvoiceId;
    }
    // console.log(this.petitionerId, "!!!!!!");
    // if (history.state.receiptStep === 1) {
    //   setTimeout(() => {
    //     this.currentStep = 1;
    //   }, 1500);
    // }
    if (history.state.receiptStep === 1) {
      setTimeout(() => {
        this.sendDraftItemId = history.state.sendDraftItemId;
        this.currentStep = 1;
      }, 1500);
    }
    this.invoiceId = history.state.invoiceId;
  }

  enableNext(event) {
    this.invoiceId = event.invoiceId;
    this.invoiceType = event.invoiceType;

    if (this.invoiceId === undefined) {
      this.invoiceId = history.state.invoiceId;
    }
    this.form = event.form.value;
    // console.log("Enable Next", event);
    // console.log("Enable Next Form", this.form);
  }

  positionNext(event) {
    this.invType = event.invoiceType;
    // console.log("positionNext", event);
    // console.log("positionNext", event.invoiceType);
  }

  sendDataTosecondPage() {
    // console.log("sendDataTosecondPage", this.invoiceId);
  }

  invoicepriview() {
    this.router.navigateByUrl("/attorney-landing/attorney-invoice/preview", {
      state: {
        invoiceId: this.invoiceId,
      },
    });
  }

  // attorney-landing/dashboard/attorney-dashboard

  backtoDashboard() {
    this.dashboardUrl = "attorney-landing/dashboard/attorney-dashboard";
    // this.dashboardUrl = "/attorney-landing/attorney-invoice/";
    this.router.navigate([this.dashboardUrl]);
  }

  backtoMangaeBilling() {
    this.dashboardUrl = "/attorney-landing/attorney-invoice/billing-invoices";
    this.router.navigate([this.dashboardUrl]);
  }
  saveInvoice(event) {}
  cancelInvoice() {
    this.router.navigate(["/attorney-landing/attorney-invoice"]);
  }
}
