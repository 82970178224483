import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DashboardWidgetsService } from '../../../../services/dashboard-widgets.service';

@Component({
  selector: 'app-individuals-date-tracker',
  templateUrl: './individuals-date-tracker.component.html',
  styleUrls: ['./individuals-date-tracker.component.scss']
})
export class IndividualsDateTrackerComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  familyCurrentList = [];
  cols: any[];
  familyBasedCurrentDateCols: any[];
  isShowMore: boolean = false;
  isBeneficiary: boolean = false;

  constructor( private router: Router,private dashboardWidgetsService: DashboardWidgetsService,) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('userTypeRole')=='Petitioner' || sessionStorage.getItem('userTypeRole')=='Attorney'){
      this.isShowMore = true;
    }
    if(sessionStorage.getItem('userTypeRole')=='Beneficiary'){
      this.isBeneficiary = true;
    }
    this.familyBasedCurrentDateCols = [
      { field: 'name', header: 'Individuals Name' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'priorityDate', header: 'I-797 Priority date' },
    ];

    this.getFamilyCurrentDate();
  }

  getFamilyCurrentDate() {
    this.dashboardWidgetsService.getIndividualTracker().subscribe((res: any) => {
      if(res){
        this.familyCurrentList = res['data'];
      }
    });
  }
  
  showMore() {
    if(sessionStorage.getItem('userTypeRole')=='Petitioner'){
      this.router.navigate(['/petitioner-landing/visaBulletin'], { queryParams: { data: 2 } });
    } else if(sessionStorage.getItem('userTypeRole')=='Attorney'){
      this.router.navigate(['/attorney-landing/visaBulletin'], { queryParams: { data: 2 } });
    }
    // this.router.navigate([`${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}-landing/${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}/task-list`]);
  }

}
