import { CompanyActions, CompanyActionTypes } from '../actions/company.actions';



export function CompanyReducer(state = {}, action: CompanyActions){
   switch(action.type){
       case CompanyActionTypes.ListCompanySuccess:{
           return action.payload;
       }
       case CompanyActionTypes.ListAssocCompanySuccess:{
        return action.payload;
       }  
       case CompanyActionTypes.SaveCompanySuccess:{

       }
       case CompanyActionTypes.SetCompany:{
        return {...state, companyId: action.payload};
       }
       default: {
           return state;
       }

   }
}