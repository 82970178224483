<div class="infoClass">
    <div class="row headerColor pt-2"> 
           <div class="col-md-11">Select the following </div>
           <div class="col-md-1">
               <mat-icon class="icn" (click)="handleClose()">highlight_off</mat-icon>
            </div>
       </div>
       <div class="row p-2">

<p>Start of stay on this Visa - Select if there is 'Change of status'</p>

<p>Arrival Date -  Enter the day you have arrived in the U.S.</p>

<p>End of stay on this Visa - if you have switched the visa status select and enter</p>

<p>Departure Date - Enter the day you left the U.S</p>
</div>
   
</div>

