import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateConfigureTimeComponent } from './create-configure-time/create-configure-time.component';
import { StepTaskAllocationComponent } from './step-task-allocation/step-task-allocation.component';
import { StepTimesConfigurationsComponent } from './step-times-configurations/step-times-configurations.component';
import { TaskAllocationContainerComponent } from './task-allocation-container/task-allocation-container.component';

const routes: Routes = [
    {
        path: '',     
        component: TaskAllocationContainerComponent,   
        children: [
          { 
            path: '', 
            pathMatch: 'full',
            redirectTo: 'task-allocations' 
          },
          {
            path: 'task-allocations',
            component: StepTimesConfigurationsComponent
          },
          {
            path: 'setps-task-allocation',
            component: StepTaskAllocationComponent
          },
          {
            path: 'configure-time',    //configure-time
            component: CreateConfigureTimeComponent
          }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskAllocationRoutingModule { }
