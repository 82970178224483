import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PetitionAnalysisService } from 'src/app/services/petition-analysis.service';
import { PAObservation, PAChart, PAObservationCategories, PACategoryDetails } from 'src/app/data/models/petitionAnalysis.model';
import { Category } from '../../../data/models/category.model';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { PAUtility } from '../utility.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-pa-categorized',
  templateUrl: './pa-categorized.component.html',
  styleUrls: ['./pa-categorized.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})

export class PaCategorizedComponent implements OnInit {

  selectedPACategory: any;
  categoryName: string;
  categoryCode: string;
  petitionId: number;
  observations: PAObservation[] = [];
  selObservationFilterCtg: number[] = [];
  selectAllObservations: boolean = true;
  observationCategories: PAObservationCategories[] = [
    { name: 'Needs to be fixed', code: 0 },
    { name: 'Looks Perfect', code: 1 },
    { name: 'Not Applicable', code: 2 },
    { name: 'Not Initiated', code: 3 }
  ];
  categoryList: Category[];
  categoryDetails: PAChart;
  categoryGraphDetails: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };

  constructor(private pAService: PetitionAnalysisService,
    private utilityPAService: PAUtility,
    private router: Router,
    private route: ActivatedRoute) {

    // this.categoryList = [
    //   { code: 'PD', name: 'Petition Deatils', id: 0, desc: 'hi', group: 'hi' },
    //   { code: 'FEE', name: 'Fees', id: 1, desc: 'hi', group: 'hi' },
    //   { code: 'JD', name: 'Job Description', id: 2, desc: 'hi', group: 'hi' },
    //   { code: 'LCA', name: 'LCA', id: 3, desc: 'hi', group: 'hi' },
    // ];
  }

  ngOnInit() {
    this.petitionId = parseInt(sessionStorage.getItem('petitionId'));
    this.getCategoryList();
    this.handleSelectAll();
  }

  getCategoryObervation() {
    this.pAService.getCategoryObervationCode(this.petitionId, this.categoryCode)
      .subscribe((response: PAObservation[]) => {
        this.utilityPAService.setObservationList(response.map((x, i) => {
          return {
            ...x,
            indexId: i
          };
        }));
        this.observations = this.utilityPAService.getObservationList();
      });
  }


  getCategoryList() {
    this.pAService.getPACategory(this.petitionId).subscribe((response: Category[]) => {
      this.categoryList = response;
      this.route.params.forEach((param) => {
        if (param['category']) {
          this.categoryCode = param['category'];
          this.loadData();
        }
      });
    })
  }

  loadData() {
    if (this.categoryList.find(x => x.code === this.categoryCode)) {
      this.categoryName = this.categoryList.find(x => x.code === this.categoryCode).name;
      this.getCategoryObervation();
      this.getCategoryChart();
    }
    else {
      this.router.navigate(['../../over-all'], { relativeTo: this.route });
    }
  }


  onCategorySelect() {
    this.router.navigate(['../../pa-categorized', this.categoryCode], { relativeTo: this.route });
  }

  backToOverallAnalysis() {
    this.router.navigate(['../../over-all'], { relativeTo: this.route });
  }

  getCategoryChart() {
    this.pAService.getCategorySummeryOnCategoryCode(this.petitionId, this.categoryCode).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.categoryDetails = response.data as PAChart;
      }
      else {
        this.categoryDetails = null;
      }
      this.categoryGraphDetails = {
        labels: [`${Math.round((100 * this.categoryDetails.success) / this.categoryDetails.total)}% Looks Perfect`,
        `${Math.round((100 * this.categoryDetails.failure) / this.categoryDetails.total)}% Needs to be fixed`,
        `${Math.round((100 * this.categoryDetails.notinitiated) / this.categoryDetails.total)}% Not Initiated`,
        `${Math.round((100 * this.categoryDetails.na) / this.categoryDetails.total)}% Not Applicable`],
        datasets: [
          {
            data: [
              Math.round((100 * this.categoryDetails.success) / this.categoryDetails.total),
              Math.round((100 * this.categoryDetails.failure) / this.categoryDetails.total),
              Math.round((100 * this.categoryDetails.notinitiated) / this.categoryDetails.total),
              Math.round((100 * this.categoryDetails.na) / this.categoryDetails.total)
            ],
            backgroundColor: [
              "#03A0DA",
              "#FF8800",
              "#A3A6B4",
              "#E1E1E1"
            ],
            hoverBackgroundColor: [
              "#03A0DA",
              "#FF8800",
              "#A3A6B4",
              "#E1E1E1"
            ]
          }]
      };
    });
  }

  handleFilter() {
    if (this.selObservationFilterCtg && this.selObservationFilterCtg.length > 0) {
      this.observations = this.utilityPAService.getObservationList().filter((x: PAObservation) => {
        if (this.selObservationFilterCtg.includes(x.status)) {
          return true;
        }
      });
    }
    else {
      this.observations = [];
    }
    this.togglleAllFilter();
  }

  togglleAllFilter() {
    this.selectAllObservations = this.selObservationFilterCtg.length === this.observationCategories.length ? true : false;
  }

  handleSelectAll() {
    this.selObservationFilterCtg = this.selectAllObservations ? this.observationCategories.map(x => x.code) : [];
    this.handleFilter();
  }

}

