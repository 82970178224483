  <p-dialog [(visible)]="displayDocumentPanel" [modal]="true" [responsive]="true"
      [style]="{width: '1000px', minWidth: '200px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
      <ng-template pTemplate="header">
          <div>
              <div class="row">
                  <div class="col-12 px-0">
                      <h5 class="text-primary mb-0">Request for the Documents</h5>
                  </div>
              </div>
          </div>
      </ng-template>
      <hr class="mt-0" />
      <div class="row">
          <div class="col-12 px-0">
              <h5 class="mb-0"><span [hidden]="individualNotification">Required</span> Document Type</h5>
              <div [hidden]="!individualNotification" class="text-secondary"><small>Select the Category for Document
                      types</small></div>
          </div>
      </div>
      <div class="row form-group">
          <div class="row pl-0 outerRow w-100" *ngFor="let item of supportingDocumentsForNotification;let i=index">
              <div class="col-12 px-0">
                  <div class="col-12  mt-2 px-0" *ngIf="item.showLabelForCheckbox">
                      <p-checkbox [disabled]="individualNotification" name="group2" value="item" [value]="item"
                          [inputId]="item.categoryId" [(ngModel)]="selectedCategoryType"
                          (onChange)="categorySelected($event,item)"></p-checkbox>
                      <label [for]="item.categoryId"
                          class="d-inline-block mb-0 ml-2 font-weight-bold">{{item.categoryName}}</label>
                  </div>
              </div>

              <div class="col-12 innerRow px-0" *ngFor="let documentType of item.documentTypes;let j=index">
                  <div class="row pt-3 mx-4" style="margin-bottom:-2px"
                      *ngIf="!documentType.present && documentType?.showIndividual">
                      <div class="col-5 pl-0">
                          <div class="text-secondary pb-1"><small>Document Type </small></div>
                          <p-checkbox [disabled]="individualNotification" (onChange)="documentSelected($event)"
                              name="group3" value="documentType" [value]="documentType"
                              [inputId]="documentType.titleSheetDetailId" binary="true"
                              [(ngModel)]="documentType.selectedDocumentType"></p-checkbox>
                          <label [for]="documentType.titleSheetDetailId"
                              class="d-inline-block mb-0 ml-2">{{documentType.docName}}</label>
                      </div>
                      <div class="col-6  border-left" [hidden]="!documentType.selectedDocumentType">
                          <div class="text-secondary pb-1"><small>Request for</small></div>
                          <div class="row">
                              <div class="col-6 pl-0">
                                  <p-radioButton name="notifyTo" value="petitioner" class="mr-2" label="Petitioner"
                                      [(ngModel)]="documentType.notifyTo"></p-radioButton>
                              </div>
                              <div class="col-6 pl-0">
                                  <p-radioButton name="notifyTo" value="beneficiary" class="mr-2" label="Beneficiary"
                                      [(ngModel)]="documentType.notifyTo"></p-radioButton>
                              </div>
                              <!-- <div class="col-4 pl-0"><p-radioButton name="notifyTo"  value="" class="mr-2" label="None" [(ngModel)]="documentType.notifyTo" ></p-radioButton></div> -->
                          </div>
                      </div>
                      <div class="col-12 px-0">
                          <hr class="mb-0" />

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div [hidden]="individualNotification">
          <div class="mt-2">
              <p-checkbox name="group2" binary="true" [(ngModel)]="additionalDocuments" inputId="additional">
              </p-checkbox>
              <label class="d-inline-block mb-0 ml-2 font-weight-bold" for="additional">Add Other Required
                  Documents</label>
          </div>
          <div [hidden]="!additionalDocuments">
              <div class="row mt-2">
                  <div class="col-3">
                      <label class="small">Category</label>
                      <p-dropdown class="w-100" [options]="categories" [(ngModel)]="selectedCategoryForNotification"
                          (onChange)="updateDocType(selectedCategory)" optionLabel="categoryName"></p-dropdown>
                  </div>
                  <div class="col-3 pl-2">
                      <label class="small">Document Type</label>
                      <p-dropdown class="w-100" [options]="documentType" placeholder="Select / Enter"
                          [(ngModel)]="selectedDocTypeForNotification" optionLabel="docName"
                          (onChange)="setCustomEmpty()"></p-dropdown>
                  </div>
                  <div class="col-4 pl-2">
                      <label class="small">Can't find your document type, Enter here</label>
                      <input type="text" pInputText [(ngModel)]="customDocTypeForNotification"
                          (keyup)="setDocTypeEmpty()" />
                  </div>
                  <div class="col-2 pl-2">
                      <label class="small d-block invisible">1</label>
                      <button type="button" pButton label="ADD" class="p-button-outlined mt-1"
                          (click)="addNewCategory()"></button>
                  </div>
              </div>
          </div>
          <hr class="mb-0" />
      </div>

              <div class="row mt-2">
          <div class="col-6 border-right pb-2">
              <h6 class="mt-1">Add Notes / Comments for Beneficiary</h6>
              <input type="text" pInputText [(ngModel)]="beneficiaryNotes" style="width:100%;height:80px" />
          </div>
          <div class="col-6">
              <h6 class="mt-1">Add Notes / Comments for Petitioner</h6>
              <input type="text" pInputText [(ngModel)]="petitionerNotes" style="width:100%;height:80px" />
          </div>
      </div>
      <p-footer>
          <button type="button" pButton label="Request Now" (click)="sendNotification()"></button>
      </p-footer>
  </p-dialog>

  <div class="row">
      <div class="col-3">
          <h6>Selected RFE Categories</h6>
          <div class="border px-2 pt-2 pb-3">
              <div *ngFor="let item of supportingDocuments;let i=index" class="pt-1">
                  {{item.categoryName}}
              </div>
          </div>

      </div>
      <div class="col-9">
          <h6>Documents List</h6>
          <div class="border px-2 pt-2 pb-3">
              <p-accordion [multiple]="true" *ngFor="let item of supportingDocuments;let i=index">
                  <p-accordionTab [selected]="true">
                      <ng-template pTemplate="header">{{item.categoryName}}</ng-template>
                      <ng-template pTemplate="content">
                          <p-table [value]="item.documentTypes" [scrollable]="true" scrollHeight="150px">
                              <ng-template pTemplate="header">
                                  <tr>
                                      <th class="col-5">Document Type</th>
                                      <th class="col-5">Document Name</th>
                                      <th class="col-2">Action</th>
                                  </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-doc>
                                  <tr *ngIf="doc.documents.length <= 1">
                                      <td colspan="3" style="padding: 0!important;">
                                          <div class="col-5 d-inline-block content">{{doc.docName}} </div>
                                          <div class="col-5 d-inline-block content"><span *ngIf="doc.present"
                                                  >
                                                  {{doc.documents[0].fileName}}.{{doc.documents[0].fileType}}</span>
                                              <span *ngIf="!doc.present" class="text-danger"
                                                  >
                                                  <mat-icon class="mr-1 icn"
                                                      class="delete-client-entry material-icons">warning</mat-icon>
                                                  <span class="align-top ml-1">Missing Document</span>
                                              </span>
                                          </div>
                                          <div class="col-2 d-inline-block content" style="padding-left: 0px !important;">
                                              <span *ngIf="doc.present ">
                                                  <button pButton type="button"
                                                      (click)="viewDocument(doc.documents[0].fileLocation)"
                                                      icon="fa fa-eye"
                                                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                                      <mat-icon class="mr-1 icn" matTooltip="View Document"
                                                          class="material-icons">visibility</mat-icon>
                                                  </button>
                                                  <button pButton type="button"
                                                      (click)="confirmDelete(doc.documents[0].documentId)"
                                                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                                      <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                                          class="material-icons">delete</mat-icon>
                                                  </button>
                                              </span>
                                              <span *ngIf="!doc.present" >
                                                  <button pButton type="button" (click)="notifyDocument(item,doc)"
                                                      class="bg-white p-button-icon-only p-button-rounded border-0 text-secondary d-inline">
                                                      <mat-icon class="mr-1 icn" matTooltip="Notify"
                                                          class="material-icons">notifications</mat-icon>
                                                  </button>
                                              </span>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr *ngIf="doc.documents.length > 1">
                                      <td colspan="3" style="border:0;padding: 0!important;">
                                          <p-table [value]="doc.documents">
                                              <ng-template pTemplate="body" let-singleDoc>
                                  <tr>
                                      <td class="col-5" >{{doc.docName}}</td>
                                      <td class="col-5"><span *ngIf="doc.present">
                                              {{singleDoc.fileName}}.{{singleDoc.fileType}}</span>
                                          <span *ngIf="!doc.present" class="text-danger">
                                              <mat-icon class="mr-1 icn" class="delete-client-entry material-icons">
                                                  warning</mat-icon><span class="align-top ml-1">Missing
                                                  Document</span>
                                          </span>
                                      </td>
                                      <td class="col-2">
                                          <span *ngIf="doc.present">
                                              <button pButton type="button"
                                                  (click)="viewDocument(singleDoc.fileLocation)"
                                                  class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                                  <mat-icon class="mr-1 icn" matTooltip="View Document"
                                                      class="material-icons">visibility</mat-icon>
                                              </button>
                                              <button pButton type="button"
                                                  (click)="deleteDocument(singleDoc.documentId)"
                                                  class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                                  <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                                      class="material-icons">delete</mat-icon>
                                              </button>
                                          </span>
                                          <span *ngIf="!doc.present">
                                              <button pButton type="button" (click)="notifyDocument(item,singleDoc)"
                                                  class="bg-white notificationButton p-button-rounded border-0 text-secondary d-inline">
                                                  <mat-icon class="mr-1 icn" matTooltip="Notify"
                                                      class="material-icons">notifications</mat-icon>
                                              </button>
                                          </span>
                                      </td>
                                  </tr>
                              </ng-template>
                          </p-table>
                          </td>

                          </tr>
                      </ng-template>
                      </p-table>
                      </ng-template>
                  </p-accordionTab>
              </p-accordion>
          </div>
          <div class="mt-4">
              <button pButton type="button" label="Upload Documents" class="p-button-outlined align-top"
                  (click)="op.toggle($event)"></button>
              <p-overlayPanel #op class="customPanel">
                  <ng-template pTemplate>
                      <div class="row py-4">
                          <div class="col-4">
                              <div class="mt-4">
                                  <label>Category</label>
                                  <p-dropdown class="w-100" [options]="categories" [(ngModel)]="selectedCategory"
                                      (onChange)="updateDocType(selectedCategory)" optionLabel="categoryName">
                                  </p-dropdown>
                              </div>
                          </div>
                          <div class="col-4 pl-2">
                              <div class="mt-4">
                                  <label>Document Type</label>
                                  <p-dropdown class="w-100" [options]="documentType" placeholder="Select / Enter"
                                      [(ngModel)]="selectedDocType" optionLabel="docName"
                                      (onChange)="setCustomEmpty()"></p-dropdown>
                              </div>
                          </div>
                          <div class="col-4 pl-2">
                              <label>Can't find your document type, Enter here</label>
                              <input type="text" pInputText [(ngModel)]="customDocType" (keyup)="setDocTypeEmpty()" />
                          </div>
                      </div>
                      <div class="ml-3 pb-4">
                          <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Browse" [auto]="true"
                              customUpload="true" (uploadHandler)="onUpload($event, createUploader)"
                              [accept]="acceptedTypes" showUploadButton="false" showCancelButton="false"
                              maxFileSize="10485760">
                          </p-fileUpload>
                      </div>

                  </ng-template>
              </p-overlayPanel>
              <div class="d-inline-block">
                  <button pButton type="button" label="Request for Documents" (click)="setDisplayDocumentPanel()"
                      class="p-button-outlined ml-3"></button>
                  <small class="mt-2 ml-3 text-secondary "
                      [ngClass]="{'text-success d-block' : notificationStatusUpdate === 'Response received from Petitioner/Beneficiary' }"
                      [ngClass]="{'text-secondary d-block' : notificationStatusUpdate === 'Request sent-Awaiting for response' }"
                      [ngClass]="{'d-none' : notificationStatusUpdate === 'Not Available'}">

                      {{ notificationStatusUpdate }}</small>
              </div>
          </div>
      </div>
      <div class="mt-2 w-100">
          <!-- <button type="button" pButton (click)="saveData();" label="Save"></button> -->
          <button type="button" pButton (click)="changeStatus();" label="Complete"
              class="ui-button-secondary float-right"></button>
      </div>
  </div>

  <p-confirmDialog header="Delete" key="step1Response"></p-confirmDialog>