import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { debounceTime, take } from "rxjs/operators";
import { CompanyService } from "src/app/services/company.service";
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";
import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { CacheCountryService } from "src/app/services/cacheCountries.service";
import { Address } from "src/app/data/models/address.model";
import { CountryApiService } from "src/app/modules/petitioner/services/country.service";
import { combineLatest, Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { FileUploadLibraryService } from "file-upload-library";
import { environment } from "environment-configurations";
import { Router } from "@angular/router";
import { acceptedFileTypes } from "app-models";
import { CustomErrorToastComponent } from "custom-error-toast";

interface country {
  createdBy: number;
  modifiedBy: number;
  countryName: string;
  countryCode: string;
  shortCountryCode: string;
  phoneCode: string;
  sequenceNumber: number;
}

@Component({
  selector: "app-invoice-deatils",
  templateUrl: "./invoice-deatils.component.html",
  styleUrls: ["./invoice-deatils.component.scss"],
})
export class InvoiceDeatilsComponent implements OnInit {
  @Input("isClientGenerateInvoice") isClientGenerateInvoice: boolean;
  @Input("petitionerId") petitionerId: any;
  @Output() valueChange = new EventEmitter();
  @Output() invoiceTypChanged = new EventEmitter();
  @Output() invoiceCreated = new EventEmitter();
  @Input("editItem") editInvoiceItem;
  @Input("uploadedInvoice") uploadedInvoice;
  @Output() removeElement: EventEmitter<number> = new EventEmitter();
  mform: FormGroup = new FormGroup({
    name: new FormControl("yes"),
  });
  uploadInvoiceForm: FormGroup;
  invoicemode: string;
  invoiceForm: FormGroup;
  disableFields = false;
  companyId: any;
  uploadedFile: any;
  invoiceFile: any;
  public payload: FormData;
  public invoicePayload: FormData;
  uploadedFiles: any = [];
  generateInvoiceview: boolean = false;
  uploadInvoiceview: boolean = false;
  clients = [];
  clientsWithId = [];
  clientsWithoutId = []; // Individual Client
  others: boolean = false;
  listCountry: country[] = [];
  listState: { stateProvinceName: ""; stateProvinceCode: "" }[] = [];
  listCity: { city: "" }[] = [];
  listPostalCode: { postCode: "" }[] = [];
  attorneyFirms = [];
  obs: Subscription;

  listBilledPostCode: { postCode: "" }[] = [];
  listBilledCity: { cityName: "" }[] = [];
  listBillStates: {
    stateProvinceCode: "FL";
    stateProvinceName: "";
  }[] = [];
  listBillCountries: {
    createdBy: null;
    modifiedBy: null;
    countryName: string;
    countryCode: string;
    shortCountryCode: string;
    phoneCode: string;
  }[] = [];
  employeeId: string;
  emp: any = [];
  invoiceType: boolean = true;
  billeId: number;
  billToId: number;

  billedToCompanyId: any;
  arr: FormArray;
  employees: { firstName: string; lastName: string; id: string }[] = [];

  businessLogo;
  createdInvoiceId;
  signatureFile: any;
  sigPayload: FormData;
  selectedClient: any;
  isClientSaved = 0;
  isUploadClientSaved = 0;
  countries = [];
  otherStates: { stateProvinceName: ""; stateProvinceCode: "" }[] = [];
  minimumDate = new Date();
  companyLogoImage: any;
  signatureImage: any;
  disableClientFields: boolean = false;
  isClicked: boolean = false;
  isEditable: boolean = false;
  isAttorneyFirmEditable: boolean = false;
  isOtherFieldsEditable: boolean = false;
  previewDialog: boolean = false;
  previewClient;
  signatoryFirstName: any;
  otherSignatory: boolean;
  signatoryName1;
  billingItemDTOs: FormArray;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  previewBillingItems: any = [];
  grandTotal = 0;
  previousTotal: number;
  isIndividualClient: boolean = false;
  isIndividualClientUpload: boolean = false;
  isUpEditable: boolean = false;
  otherUpload: boolean = false;
  ownInvoiceImage: any;
  generatedInvoice;
  showUploadedfile: any;
  invoiceId: number;
  itemData: any;
  clientNotSaved: boolean = false;
  gpdfId: any;
  acceptedTypes = acceptedFileTypes;

  billToform: FormGroup = new FormGroup({
    billType: new FormControl("Company"),
  });
  individualsList = [];
  individualId = null;
  individualName = '';
  constructor(
    public fb: FormBuilder,
    private companyService: CompanyService,
    private invoiceService: BillingInvoiceService,
    public dialog: MatDialog,
    private cacheCountryService: CacheCountryService,
    private apiService: CountryApiService,
    private toaster: ToastrService,
    public sanitizer: DomSanitizer,
    public uploadService: FileUploadLibraryService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.companyId = sessionStorage.getItem("companyId");

    this.employeeId = sessionStorage.getItem("employeeId");
    this.uploadInvoiceForm = this.fb.group({
      // phoneNumber: [null, Validators.required],
      // email: [null, Validators.required],
      // address: [null, Validators.required],
      // country: [null, Validators.required],
      // state: [null, Validators.required],
      // city: [null, Validators.required],

      phoneNumber: [],
      email: [{ value: "", disabled: this.disableClientFields }],
      address: [],
      country: [],
      state: [],
      city: [],
      selectedUploadClient: ["", Validators.required],
      zipcode: [],
      invoiceDate: ["", Validators.required],
      invoiceDueDate: ["", Validators.required],
      invoiceNumber: ["", Validators.required],
      newClient: [],
      saveClientUpload: [],
      otherAddress: [],
      otherPhoneNumber: ["", [Validators.pattern("^[0-9]*$")]],
      otherEmail: [],
      otherCountry: [],
      otherZipCode: ["", [Validators.pattern("^[0-9]*$")]],
      otherCity: [],
      otherState: [],
      countryPhoneCode: [],
      countryPhCode: [],
      billedByEmail: [],
      billedByPhoneCode: [],
      billedByPhoneNumber: [],
      billedByAddress: [],
      billedByCountry: [],
      billedByState: [],
      billedByCity: [],
      billedByPostCode: [],
      signatoryName: [],
      signatoryName1: [],
    });
    this.invoiceForm = this.fb.group({
      busFirmName: [null, Validators.required],
      billedByCompany: [],
      invoiceDate: ["", Validators.required],
      invoiceDueDate: ["", Validators.required],
      invoiceId: [],
      invoiceNumber: ["", Validators.required],
      invoiceTitle: ["Invoice", Validators.required],
      billingItemDTOs: this.fb.array([]),
      termsAndConditions: [],
      newClient: [],
      saveClient: [],
      otherAddress: [],
      otherPhoneNumber: ["", [Validators.pattern("^[0-9]*$")]],
      otherEmail: [],
      otherCountry: [],
      otherZipCode: ["", [Validators.pattern("^[0-9]*$")]],
      otherCity: [],
      otherState: [],
      countryPhoneCode: [],
      countryPhCode: [],
      phoneNumber: [],
      email: [{ value: "", disabled: this.disableClientFields }],
      address: [],
      country: [],
      state: [],
      city: [],
      selectedClient: ["", Validators.required],
      zipcode: [],
      billedByEmail: [],
      billedByPhoneCode: [],
      billedByPhoneNumber: [],
      billedByAddress: [],
      billedByCountry: [],
      billedByState: [],
      billedByCity: [],
      billedByPostCode: [],
      // signatoryName: ["", Validators.required],
      signatoryName: [""],
      signatoryName1: [],
    });
  }

  ngOnInit(): void {
    this.getAttorneyAssociatedCompanies();
    this.invoiceTypeChanged();
    if (this.editInvoiceItem) {
      let editInvoiceId = this.editInvoiceItem.invoiceId;
      this.generatedInvoice = this.editInvoiceItem.isInvcGenerated;

      // if generated invoice (not uploaded), get generated invoice data
      if (this.generatedInvoice) {
        this.getInvoiceItem(editInvoiceId);
      } else {
        this.mform.patchValue({ name: "no" });
        this.invoiceType = false;
        this.invoiceTypeChanged();
        // if uploaded invoice,  get uploaded invoice data
        this.getUploadedInvoiceItem(editInvoiceId);
      }
    } else {
      this.getAttorneyClients();
      this.getAttorneyIndividuals();
      this.getEmployees();
      this.getTermsAndConditions();
      this.getCountries();
      this.obs = this.invoiceForm.valueChanges.subscribe((data) => {
        if (!this.invoiceForm.valid) {
          this.valueChanged();
        }
      });
    }
    this.addBillingItemDTOs();
    this.getAttorneyAssociatedCompanies();
    this.getEmployees();
    this.invoiceTypeChanged();
  }

  getClientUploadData() {
    let shouldSkip = false;
    this.clientsWithId.forEach((item) => {
      if (shouldSkip) {
        return;
      }
      if (item.companyId == this.petitionerId.id) {
        this.uploadInvoiceForm.patchValue({
          selectedUploadClient: item,
        });
        this.billToId = this.petitionerId.id;
        this.populateEditData(item);
        shouldSkip = true;
        return;
      }
    });
  }

  // get invoice item by id
  getInvoiceItem(id) {
    combineLatest([
      this.invoiceService.getInvoiceItem(id),
      this.invoiceService.getAttorneyClientCompanies(this.companyId),
      this.invoiceService.getEmployeesOfTheCompany(this.companyId),
    ])
      .pipe(take(1))
      .subscribe(
        (resp: any[]) => {
          this.clients = resp[1].data;
          this.clients.push({ companyName: "others" });
          this.clients.forEach((item) => {
            if (item.companyId) {
              this.clientsWithId.push(item);
            } else {
              this.clientsWithoutId.push(item);
            }
          });
          this.employees = resp[2];
          this.employees.unshift({ firstName: "others", lastName: "", id: "" });
          this.populateObject(resp[0].data);
        },
        (err) => {
          console.log("getInvoiceItem" + "Error while getInvoiceItem" + err);
        }
      );
  }

  // get uploaded invoice item by id
  getUploadedInvoiceItem(id) {
    this.invoiceId = id;
    this.invoiceService
      .downloadInvoice(this.invoiceId)
      .subscribe((res: any) => {
        this.showUploadedfile = res;
      });
    combineLatest([
      this.invoiceService.getInvoiceItem(id),
      this.invoiceService.getAttorneyClientCompanies(this.companyId),
    ])
      .pipe(take(1))
      .subscribe(
        (resp: any[]) => {
          this.clients = resp[1].data;
          this.clients.push({ companyName: "others" });
          this.clients.forEach((item) => {
            if (item.companyId) {
              this.clientsWithId.push(item);
            } else {
              this.clientsWithoutId.push(item);
            }
          });
          this.populateUploadForm(resp[0].data);
        },
        (err) => {
          console.log("getInvoiceItem" + "Error while getInvoiceItem" + err);
        }
      );
  }

  // while editing an invoice, populate the invoice form
  populateObject(data) {
    this.itemData = data;
    this.grandTotal = data.grandTotal;
    this.invoiceForm.patchValue({
      invoiceNumber: data.invoiceNumber,
      invoiceDate: data.invoiceDate,
      invoiceDueDate: data.invoiceDueDate,
      termsAndConditions: data.termsAndConditions,
    });

    this.billingItemDTOs.removeAt(0);
    this.billingItemDTOs = <FormArray>this.invoiceForm.get("billingItemDTOs");

    data.billingItemDTOs.forEach((item) => {
      this.billingItemDTOs.push(
        this.fb.group({
          chargableAmount: item.chargeableAmt,
          desc: item.billingItem,
          discount: item.discountAmt,
          tax: item.taxAmt,
          totalAmount: item.totalAmt,
        })
      );
    });

    // if (data.billedTocompany.companyId) {
    this.clientsWithId.forEach((item) => {
      let shouldSkip = false;
      if (shouldSkip) {
        return;
      }
      if (item.companyId == data.billedTocompany.companyId) {
        this.invoiceForm.patchValue({
          selectedClient: item,
        });
        this.billedToCompanyId = data.billedTocompany.companyId;
        this.populateEditData(item);
        shouldSkip = true;
        return;
      }
    });

    if (data.billedTocompany.companyId == null)
      this.clientsWithoutId.forEach((item) => {
        let shouldSkip = false;
        if (shouldSkip) {
          return;
        }
        if (item.companyName == data.billedTocompany.companyName) {
          this.invoiceForm.patchValue({
            selectedClient: item,
          });
          // this.billedToCompanyId = data.billedTocompany.companyId;
          this.populateEditData(item);
          shouldSkip = true;
          return;
        }
      });
    //       console.log('Invoice Form Patch Value :: Not SaveD Client ', this.invoiceForm.get('invoiceNumber').value)

    // console.log('Invoice Form Patch Value :: Not SaveD Client ::', JSON.stringify(this.invoiceForm.get('selectedClient').value )+ 'nodata' )

    // if(!this.invoiceForm.get('selectedClient').value) {
    //     this.clientNotSaved = true;
    //     this.populateEditData(data.billedTocompany);
    // }

    let shouldSkipEmployee = false;
    // if (data.signatoryId) {
    this.employees.forEach((emp) => {
      if (shouldSkipEmployee) {
        return;
      }

      if (emp.firstName + " " + emp.lastName == data.signatoryName) {
        this.invoiceForm.patchValue({
          signatoryName: emp,
        });
        shouldSkipEmployee = true;
        return;
      }
    });

    // console.log('Signatory Name :: --'+ this.invoiceForm.get('signatoryName').value.firstName + '**')
    if (!data.signatory && !this.invoiceForm.get('signatoryName').value.firstName) {
      if(data.signatoryName){
      this.otherSignatory = true;
      this.invoiceForm.patchValue({
        signatoryName: this.employees[0],
        signatoryName1: data.signatoryName
      });
    }
    }



    this.signatureImage = data.signImageSmall
      ? "data:image/jpeg;base64," + data.signImageSmall
      : null;

    this.companyLogoImage = data.billBycompanyLogo
      ? "data:image/jpeg;base64," + data.billBycompanyLogo
      : null;
  }

  // while editing the uploaded invoice , populate the upload invoice form
  populateUploadForm(data) {
    this.itemData = data;
    this.uploadInvoiceForm.patchValue({
      invoiceNumber: data.invoiceNumber,
      invoiceDate: data.invoiceDate,
      invoiceDueDate: data.invoiceDueDate,
      // termsAndConditions: data.termsAndConditions,
    });
    let shouldSkip = false;

    this.clientsWithId.forEach((item) => {
      if (shouldSkip) {
        return;
      }
      if (item.companyId == data.billedTocompany.companyId) {
        this.uploadInvoiceForm.patchValue({
          selectedUploadClient: item,
        });
        this.billToId = data.billedTocompany.companyId;
        this.populateEditData(item);
        shouldSkip = true;
        return;
      }

    });

    if (data.billedTocompany.companyId == null)
      this.clientsWithoutId.forEach((item) => {
        let shouldSkip = false;
        if (shouldSkip) {
          return;
        }
        if (item.companyName == data.billedTocompany.companyName) {
          this.uploadInvoiceForm.patchValue({
            selectedUploadClient: item,
          });
          this.populateEditData(item);
          shouldSkip = true;
          return;
        }
      });
  }

  // get the invoice number for creating a new invoice
  getTermsAndConditions() {
    let id;
    if (this.petitionerId) {
      id = this.petitionerId.id;
    } else {
      id = this.companyId;
    }
    this.invoiceService.getTermsAndConditions(this.companyId).subscribe(
      (resp) => {
        this.invoiceForm.patchValue({
          invoiceNumber: resp.invoiceNo,
          // termsAndConditions: resp.termsAndConditions,
        });
        this.uploadInvoiceForm.patchValue({
          invoiceNumber: resp.invoiceNo,
        });
      },
      (err) => {
        console.log("Error while fetching employees of the company", err);
      }
    );
  }

  ngAfterViewInit() {
    this.obs = this.invoiceForm.valueChanges.subscribe((data) => {
      if (!this.invoiceForm.valid) {
        this.valueChanged();
      }
    });
  }

  // /api/v1/company/{companyId}/employee

  /*
Method to save the invoice for different clients.
Client are 1)Existing Clients of the logged in (attorney or attorney company.)
2) Individual Clients (Other Clients)
  */
  saveInvoice() {
    this.isClicked = true;
    let reqObject: any = {};

    if (
      this.editInvoiceItem &&
      this.itemData.billedTocompany.companyId == null
    ) {
      this.selectedClient = this.invoiceForm.get("selectedClient").value;
      
      reqObject = {
        billedBycompanyId: this.companyId,
        individualId: null,
        individualName: '',
        addressDto: {
          addressLine1: this.selectedClient.address.addressLine1,
          city: this.selectedClient.address.city,
          county: this.selectedClient.address.countryCode,
          postCode: this.selectedClient.address.postCode,
          stateProvinceCode: this.selectedClient.address.stateName,
        },
        billingItemDTOs: this.invoiceForm.get("billingItemDTOs").value,
        billedToClientName: this.selectedClient.companyName,
        email: this.selectedClient.email,
        phoneCode: this.selectedClient.countryPhoneAbbr,
        phoneNumber: this.selectedClient.phone,
        busFirmName: this.attorneyFirms[0].companyName,
        invoiceDate: moment(this.invoiceForm.get("invoiceDate").value),
        invoiceDueDate: moment(this.invoiceForm.get("invoiceDueDate")?.value),
        invoiceId: this.editInvoiceItem ? this.editInvoiceItem.invoiceId : null,
        invoiceNumber: this.itemData.invoiceNumber,
        invoiceTitle: this.invoiceForm.get("invoiceTitle").value,
        // signatoryId: !this.otherSignatory
        //   ? this.invoiceForm.get("signatoryName").value.id
        //   : "",
        // signatoryName: !this.otherSignatory
        //   ? this.invoiceForm.get("signatoryName").value.firstName +
        //   " " +
        //   this.invoiceForm.get("signatoryName").value.lastName
        //   : this.invoiceForm.get("signatoryName1").value,

          signatoryId: !this.otherSignatory
          ? this.invoiceForm.get("signatoryName").value ? this.invoiceForm.get("signatoryName").value.id
          : "" : "",
        signatoryName: !this.otherSignatory ?  this.invoiceForm.get("signatoryName").value
          ? this.invoiceForm.get("signatoryName").value.firstName +
          " " +
          this.invoiceForm.get("signatoryName").value.lastName :""
          : this.invoiceForm.get("signatoryName1").value ? this.invoiceForm.get("signatoryName1").value : "",
        termsAndConditions: this.invoiceForm.get("termsAndConditions").value,
        isClientSaved: 0,
      };

    } else {
      if (!this.others) {
        if (this.isIndividualClient) {
          reqObject = {
            billedBycompanyId: this.companyId,
            individualId: null,
            individualName: '',
            addressDto: {
              addressLine1: this.selectedClient.address.addressLine1,
              city: this.selectedClient.address.city,
              county: this.selectedClient.address.countryCode,
              postCode: this.selectedClient.address.postCode,
              stateProvinceCode: this.selectedClient.address.stateName,
            },
            billingItemDTOs: this.invoiceForm.get("billingItemDTOs").value,
            billedToClientName: this.selectedClient.companyName,
            email: this.selectedClient.email,
            phoneCode: this.selectedClient.countryPhoneAbbr,
            phoneNumber: this.selectedClient.phone,
            busFirmName: this.attorneyFirms[0].companyName,
            invoiceDate: moment(
              this.invoiceForm.get("invoiceDate").value
            ).format("YYYY-MM-DD"),
            invoiceDueDate: moment(
              this.invoiceForm.get("invoiceDueDate").value
            ).format("YYYY-MM-DD"),
            invoiceId: this.editInvoiceItem
              ? this.editInvoiceItem.invoiceId
              : null,
            invoiceNumber: this.invoiceForm.get("invoiceNumber").value,
            invoiceTitle: this.invoiceForm.get("invoiceTitle").value,
            // signatoryId: !this.otherSignatory
            //   ? this.invoiceForm.get("signatoryName").value.id
            //   : "",
            // signatoryName: !this.otherSignatory
            //   ? this.invoiceForm.get("signatoryName")?.value?.firstName +
            //   " " +
            //   this.invoiceForm.get("signatoryName")?.value?.lastName
            //   : this.invoiceForm.get("signatoryName1")?.value,


              signatoryId: !this.otherSignatory
              ? this.invoiceForm.get("signatoryName").value ? this.invoiceForm.get("signatoryName").value.id
              : "" : "",
            signatoryName: !this.otherSignatory ?  this.invoiceForm.get("signatoryName").value
              ? this.invoiceForm.get("signatoryName").value.firstName +
              " " +
              this.invoiceForm.get("signatoryName").value.lastName :""
              : this.invoiceForm.get("signatoryName1").value ? this.invoiceForm.get("signatoryName1").value : "",
            termsAndConditions:
              this.invoiceForm.get("termsAndConditions").value,
            isClientSaved: 0,
          };
        } else {
          reqObject = {
            billedBycompanyId: this.companyId,
            individualId: null,
            individualName: '',
            billedTocompanyId: this.billedToCompanyId,
            billingItemDTOs: this.invoiceForm.get("billingItemDTOs").value,
            busFirmName: this.attorneyFirms[0].companyName,
            invoiceDate: moment(
              this.invoiceForm.get("invoiceDate")?.value
            ).format("YYYY-MM-DD"),
            invoiceDueDate: moment(
              this.invoiceForm.get("invoiceDueDate")?.value
            ).format("YYYY-MM-DD"),
            invoiceId: this.editInvoiceItem
              ? this.editInvoiceItem.invoiceId
              : null,
            invoiceNumber: this.invoiceForm.get("invoiceNumber").value,
            invoiceTitle: this.invoiceForm.get("invoiceTitle").value,
            // signatoryId: !this.otherSignatory
            //   ? this.invoiceForm.get("signatoryName").value.id
            //   : "",
            // signatoryName: !this.otherSignatory
            //   ? this.invoiceForm.get("signatoryName").value.firstName +
            //   " " +
            //   this.invoiceForm.get("signatoryName").value.lastName
            //   : this.invoiceForm.get("signatoryName1").value,
            signatoryId: !this.otherSignatory
            ? this.invoiceForm.get("signatoryName").value ? this.invoiceForm.get("signatoryName").value.id
            : "" : "",
          signatoryName: !this.otherSignatory ?  this.invoiceForm.get("signatoryName").value
            ? this.invoiceForm.get("signatoryName").value.firstName +
            " " +
            this.invoiceForm.get("signatoryName").value.lastName :""
            : this.invoiceForm.get("signatoryName1").value ? this.invoiceForm.get("signatoryName1").value : "",
            termsAndConditions:
              this.invoiceForm.get("termsAndConditions").value,
            isClientSaved: 0,
          };
        }
      } else {
        reqObject = {
          billedBycompanyId: this.companyId,
          individualId: null,
          individualName: '',
          addressDto: {
            addressLine1: this.invoiceForm.get("otherAddress").value,
            city: this.invoiceForm.get("otherCity").value,
            county: this.invoiceForm.get("otherCountry").value.countryCode,
            postCode: this.invoiceForm.get("otherZipCode").value,
            stateProvinceCode:
              this.invoiceForm.get("otherState").value.stateProvinceCode,
          },
          billingItemDTOs: this.invoiceForm.get("billingItemDTOs").value,
          billedToClientName: this.invoiceForm.get("newClient").value,
          email: this.invoiceForm.get("otherEmail").value,
          phoneCode: this.invoiceForm.get("countryPhCode").value,
          phoneNumber: this.invoiceForm.get("otherPhoneNumber").value,
          busFirmName: this.attorneyFirms[0].companyName,
          invoiceDate: moment(this.invoiceForm.get("invoiceDate").value).format(
            "YYYY-MM-DD"
          ),
          invoiceDueDate: moment(
            this.invoiceForm.get("invoiceDueDate").value
          ).format("YYYY-MM-DD"),
          invoiceId: this.editInvoiceItem
            ? this.editInvoiceItem.invoiceId
            : null,
          invoiceNumber: this.invoiceForm.get("invoiceNumber").value,
          invoiceTitle: this.invoiceForm.get("invoiceTitle").value,
          // signatoryId: !this.otherSignatory
          //   ? this.invoiceForm.get("signatoryName").value.id
          //   : "",
          // signatoryName: !this.otherSignatory
          //   ? this.invoiceForm.get("signatoryName").value.firstName +
          //   " " +
          //   this.invoiceForm.get("signatoryName").value.lastName
          //   : this.invoiceForm.get("signatoryName1").value,



            signatoryId: !this.otherSignatory
            ? this.invoiceForm.get("signatoryName").value ? this.invoiceForm.get("signatoryName").value.id
            : "" : "",
          signatoryName: !this.otherSignatory ?  this.invoiceForm.get("signatoryName").value
            ? this.invoiceForm.get("signatoryName").value.firstName +
            " " +
            this.invoiceForm.get("signatoryName").value.lastName :""
            : this.invoiceForm.get("signatoryName1").value ? this.invoiceForm.get("signatoryName1").value : "",
          termsAndConditions: this.invoiceForm.get("termsAndConditions").value,
          isClientSaved: this.isClientSaved,
        };

      }
    }

    if (this.billToform.get('billType').value == 'Individual') {
      reqObject.individualId = this.individualId;
      reqObject.individualName = this.individualName;
    }

    this.invoiceService
      .createInvoice(reqObject)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.createdInvoiceId = resp.data.invoiceId;
            let logoUrl =
              "/attorney/invoice/upload/companyLogoImage/" +
              this.createdInvoiceId;

            let signatureUrl =
              "/attorney/invoice/uploadSignImage/" + this.createdInvoiceId;

            if (this.payload) {
              if (this.sigPayload) {
                combineLatest([
                  this.invoiceService.uploadFile(logoUrl, this.payload),
                  this.invoiceService.uploadFile(signatureUrl, this.sigPayload),
                ])
                  .pipe(take(1))
                  .subscribe(
                    (resp: any[]) => {
                      this.invoiceService
                        .generatePdf(this.createdInvoiceId)
                        .subscribe(
                          (response) => {
                            //console.log("generate pdf ::", response);
                          },
                          (err) => {
                            console.log("generate pdf err ::", err);
                          }
                        );

                      if (this.editInvoiceItem) {
                      } else {
                        this.valueChanged();
                      }
                    },
                    (err) => {
                      console.log(
                        "LogoUpload" + "Error while uploading the logo" + err
                      );
                    }
                  );
              } else {
                this.invoiceService
                  .uploadFile(logoUrl, this.payload)
                  .pipe(take(1))
                  .subscribe(
                    (resp: any) => {
                      this.invoiceService
                        .generatePdf(this.createdInvoiceId)
                        .subscribe(
                          (response) => {
                            this.valueChanged();
                          },
                          (err) => {
                            console.log("generate pdf err ::", err);
                          }
                        );
                    },
                    (err) => {
                      console.log(
                        "LogoUpload" + "Error while uploading the logo" + err
                      );
                    }
                  );
              }
            } else if (this.sigPayload) {
              this.invoiceService
                .uploadFile(signatureUrl, this.sigPayload)
                .pipe(take(1))
                .subscribe((resp) => {
                  this.invoiceService
                    .generatePdf(this.createdInvoiceId)
                    .subscribe(
                      (response) => {
                        this.valueChanged();
                      },
                      (err) => {
                        console.log("generate pdf err ::", err);
                      }
                    );
                });
            } else {
              this.invoiceService.generatePdf(this.createdInvoiceId).subscribe(
                (response) => {
                  this.valueChanged();
                },
                (err) => {
                  console.log("generate pdf err ::", err);
                }
              );
            }
          }
          this.savePayHistory()
          this.toaster.success(resp.message, 'Success');
        },
        (err) => {
          CustomErrorToastComponent.showErrorToast(this.toaster, err.message);
          console.log("Error while saving the invoice");
        }
      );
  }

  savePayHistory(){
      let formPayload = {
        amount:this.grandTotal,
        attorneyId: this.companyId,
        beneficiaryId: null,
        billByAddressId:this.companyId,
        billToAddressId:this.billedToCompanyId,
        duedate:moment(this.invoiceForm.get("invoiceDueDate").value).format(
          "DD MMM YYYY"
        ),
        id:1,
        invoiceFrom: this.selectedClient.companyName, 
        invoiceid: this.createdInvoiceId,
        paidon: null,
        paymentDate:null,
        paymentStatus: 1611,
        transactionId: null,
        currency:"USD",
        amountpaid:null,
        transactionTime:null,
        transactionStatus:"1902",
        clientCompanyId: this.billedToCompanyId
      }
  this.invoiceService.savePaymentHistory(formPayload).subscribe((response)=>{
  })
  }

  // emit value change method so that the next button in the generate invoice component gets enabled and
  // the user can go to next page and from there can send the invoice.
  valueChanged() {
    let invoiceId;
    if (this.createdInvoiceId && this.createdInvoiceId != null) {
      invoiceId = this.createdInvoiceId;
    }

    if (this.editInvoiceItem) {
      this.valueChange.emit({
        invoiceId: this.editInvoiceItem.invoiceId,
        form: this.invoiceForm,
      });
    } else {
      if (this.invoiceType)
        this.valueChange.emit({
          invoiceId: this.createdInvoiceId,
          form: this.invoiceForm,
        });
      else {
        this.valueChange.emit({
          invoiceId: this.createdInvoiceId,
          form: this.uploadInvoiceForm,
        });
      }
    }
  }

  // which type of invoice you want to create 1)using imagility generate invoice 2) Upload invoice
  changeType() {
    if (this.mform.get("name").value == "yes") {
      this.invoiceType = true;
      this.invoiceTypeChanged();
    } else {
      this.invoiceType = false;
      this.invoiceTypeChanged();
    }
    if (this.isClientGenerateInvoice && this.mform.get("name").value == "no") {
      this.getClientUploadData();
    }
  }

  invoiceTypeChanged() {
    this.invoiceTypChanged.emit({
      invoiceType: this.invoiceType,
    });
  }

  download(file) {
    this.uploadService.downloadDocuments(
      environment.docs + file.fileName.substring(23),
      file.fileName.substring(23)
    );
  }

  // get clients of the attorney
  getAttorneyClients() {
    let payload = {
      fromAttorneyCreatePetitioner: true,
      pageNumber: 0,
      recordsPerPage: 25,
      searchCriteria: "",
      sortBy: "asc",
      sortOrder: "",
    };
    this.invoiceService
      .getAttorneyClientCompanies(this.companyId)
      .pipe(take(1))
      .subscribe((resp) => {
        this.clients = resp.data;
        this.clients.push({ companyName: "others" });
        this.clients.forEach((item) => {
          if (item.companyId) {
            this.clientsWithId.push(item);
          } else {
            this.clientsWithoutId.push(item);
          }
        });

        if (this.isClientGenerateInvoice) {
          if (this.mform.get("name").value == "yes") {
            this.getClientData();
          } else {
            this.getClientUploadData();
          }
        }
      });
  }

  // get client data to populate the form while editing
  getClientData() {
    let shouldSkip = false;

    // if (data.billedTocompany.companyId) {
    this.clientsWithId.forEach((item) => {
      if (shouldSkip) {
        return;
      }
      if (item.companyId == this.petitionerId.id) {
        this.invoiceForm.patchValue({
          selectedClient: item,
        });
        this.billedToCompanyId = this.petitionerId.id;
        this.populateEditData(item);
        shouldSkip = true;
        return;
      }
    });
  }

  // get associate companies of the logged in attorney .
  //(right now no assocaite companies so just fetching the company details of the logged in user)

  getAttorneyAssociatedCompanies() {
    let firms = [];
    let id;
    if (this.petitionerId) {
      id = this.petitionerId.id;
    } else {
      id = this.companyId;
    }

    this.invoiceService.getCompaniesById(this.companyId).subscribe(
      (resp) => {
        this.attorneyFirms = [resp];
        this.companyLogoImage = "data:image/jpeg;base64," + resp.companyPic;
        this.populateAttorneyFirmData(resp);
        
      },
      (err) => {
        console.log("Error while fetching getCompaniesById", err);
      }
    );
  }

  // UploadSignature
  Upload(event, fileUpload) {
    fileUpload.clear();
    this.uploadedFile = event.files[0];
    this.companyLogoImage = this.sanitizer.bypassSecurityTrustUrl(
      this.uploadedFile.objectURL.changingThisBreaksApplicationSecurity
    );
    this.uploadedFile["fileName"] = event.files[0].name;
    this.payload = new FormData();
    this.payload.append("file", this.uploadedFile, this.uploadedFile.name);
  }

  // Upload Company Logo
  UploadInvoice(event, fileUpload) {
    if (this.editInvoiceItem) {
      this.deleteuploadDoc(this.showUploadedfile);
    }
    fileUpload.clear();
    this.uploadedFile = event.files[0];

    this.uploadedFile["fileName"] = event.files[0].name;

    this.payload = new FormData();
    this.payload.append("file", this.uploadedFile, this.uploadedFile.name);
  }

  // upload your own invoice
  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
      this.payload.append(
        "file",
        this.uploadedFiles,
        this.uploadedFile.fileName
      );
    }
  }

  confirmDelete(uploadedFile) {
    this.deleteDoc(uploadedFile);
  }

  deleteuploadedDoc(item) {
    this.uploadedFile = null;
  }

  deleteDoc(item) {
    const title = "Confirm";
    const message = "Do you wish to Delete the File?";
    if (item) {
      this.dialogueInitializer(event, title, message)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
          }
        });
    }
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "400px",
      data: {
        update: { title, message },
        buttons: ["Cancel", "Delete"],
      },
    });
    return dialogRef;
  }

  // Method used to populate data while editing invoice
  populateEditData(item) {
    this.invoiceForm.patchValue({
      selectedClient: item,
    });
    


    /*
        "billedTocompany": {
            "companyId": null,
            "companyName": "othernotsaved2",
            "email": "othernotsaved2@mailinator.com",
            "phoneNumber": "9876598765",
            "address": {
                "id": 11756,
                "addressLine1": "othernotsaved2 address",
                "city": "sunnyvale",
                "postCode": "98765",
                "countryCode": "USA",
                "stateName": "AL",
                "countryName": null,
                "stateFullName": null
            }
        },

    */
    // if(this.clientNotSaved) {
    //   this.invoiceForm.patchValue({
    //     email: item.email,
    //     phoneNumber: item.phoneNumber,
    //     address: item.address.addressLine1,
    //     country: item.address.countryName,
    //     state: item.address.stateFullName,
    //     city: item.address.city,
    //     zipcode: item.address.postCode,
    //     countryPhoneCode: item.phoneCode,
    //     termsAndConditions: item.termsAndConditions,
    //   });
    // }
    // else {
    if (
      this.generatedInvoice ||
      (this.isClientGenerateInvoice && this.mform.get("name").value == "yes")
    ) {
      this.invoiceForm.patchValue({
        email: item.email,
        phoneNumber: item.phone,
        address: item.address.addressLine1,
        country: item.address.countryName,
        state: item.address.stateFullName,
        city: item.address.city,
        zipcode: item.address.postCode,
        countryPhoneCode: item.phoneCode,
        termsAndConditions: item.termsAndConditions,
      });

      this.previewClient = {
        email: item.email,
        phoneNumber: item.phone,
        address: item.address.addressLine1,
        state: item.address.stateFullName,
        city: item.address.city,
        zipcode: item.address.postCode,
        companyName: item.companyName,
      };
    } else {
      this.uploadInvoiceForm.patchValue({
        email: item.email,
        phoneNumber: item.phone,
        address: item.address.addressLine1,
        country: item.address.countryName,
        state: item.address.stateFullName,
        city: item.address.city,
        zipcode: item.address.postCode,
        countryPhoneCode: item.phoneCode,
        // termsAndConditions: item.termsAndConditions,
      });
    }
    // }
  }

  //While creating the invoice , once a company is selected
  // the other details like email , phone are populated automatically.
  populateData(event) {
    console.log(event);
    this.isEditable = true;

    {
      
      this.isIndividualClient = event.value.individualClient;
      this.individualId = event.value.individualId ? event.value.individualId : null;
      this.individualName = event.value.individualName ? event.value.individualName : '';
      this.disableClientFields = true;
      this.selectedClient = this.invoiceForm.get("selectedClient").value;
      if (
        this.invoiceForm.get("selectedClient").value.companyName == "others"
      ) {
        this.others = true;
        // this.isOtherFieldsEditable = true;

        if (this.others) {
          this.invoiceForm
            .get("newClient")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherEmail")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherCountry")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherPhoneNumber")
            .setValidators([
              Validators.required,
              Validators.pattern("^[0-9]*$"),
            ]);
          this.invoiceForm
            .get("otherAddress")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherState")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherCity")
            .setValidators([Validators.required]);
          this.invoiceForm
            .get("otherZipCode")
            .setValidators([
              Validators.required,
              Validators.pattern("^[0-9]*$"),
            ]);
        }
      } else {
        this.others = false;
        this.invoiceForm
            .get("newClient")
            .clearValidators();
        this.invoiceForm
          .get("selectedClient")
          .setValidators([Validators.required]);
        this.invoiceForm.get("email").setValidators([Validators.required]);
        this.invoiceForm
          .get("phoneNumber")
          .setValidators([Validators.required]);
        this.invoiceForm.get("address").setValidators([Validators.required]);
        this.invoiceForm.get("country").setValidators([Validators.required]);
        this.invoiceForm.get("state").setValidators([Validators.required]);
        this.invoiceForm.get("city").setValidators([Validators.required]);
        this.invoiceForm.get("zipcode").setValidators([Validators.required]);
      }
      if (!this.others) {
        let selClient = event.value;
        this.billedToCompanyId = event.value.companyId;
        this.invoiceForm.patchValue({
          email: selClient.email,
          phoneNumber: selClient.phone,
          address: selClient.address.addressLine1,
          country: selClient.address.countryName,
          state: selClient.address.stateFullName,
          city: selClient.address.city,
          zipcode: selClient.address.postCode,
          countryPhoneCode: selClient.phoneCode,
          termsAndConditions: selClient.termsAndConditions,
        });
        this.previewClient = {
          email: selClient.email,
          countryPhoneCode: selClient.phoneCode,
          phoneNumber: selClient.phone,
          address: selClient.address.addressLine1,
          state: selClient.address.stateFullName,
          city: selClient.address.city,
          zipcode: selClient.address.postCode,
        };
      }
    }
  }

  getCountries() {
    this.cacheCountryService.getCountry().subscribe((data) => {
      this.countries = data;
      if (data.length > 0) {
        this.countries = data.map((x) => {
          return {
            ...x,
            countryPhoneCode: `${x.countryCode} - ${x.phoneCode}`,
          };
        });
      }
    });
  }

  // Automatically populate the logged in Attorneys firm Data
  populateAttorneyFirmData(event) {
    this.isAttorneyFirmEditable = true;
    let selClient = event.value;
    let countries = [];
    this.businessLogo = this.attorneyFirms[0].smallCompanyLogo;
    this.invoiceForm.patchValue({
      busFirmName: this.attorneyFirms[0].companyName,
    });
    this.attorneyFirms[0].address.forEach((element) => {
      if (element.type.code == "REGS") {
        this.invoiceForm.patchValue({
          billedByAddress: element.addressLine1,
          billedByCountry: element.countryCode.countryName,
          billedByState: element.stateProvinceName || element.stateName,
          billedByCity: element.city,
          billedByPostCode: element.postCode,
          billedByCompany: this.attorneyFirms[0].companyName,
        });
      }
    });

    this.attorneyFirms[0].emailContacts.forEach((contact) => {
      if (contact.type.code == "PRIM") {
        this.invoiceForm.patchValue({
          billedByEmail: contact.email,
        });
      }
    });

    this.attorneyFirms[0].phoneContacts.forEach((element) => {
      if (element.type.code.trim() == "OFFC") {
        this.invoiceForm.patchValue({
          billedByPhoneNumber: element.phoneNo,
          billedByPhoneCode: element.countryCode.phoneCode,
        });
      }
    });
    
  }

  createBillingItem(): FormGroup {
    return this.fb.group({
      chargableAmount: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      desc: [
        "",
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s\-\_ ]+$/)],
      ],
      discount: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      tax: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      totalAmount: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }

  addBillingItemDTOs(): void {
    this.previewBillingItems = this.invoiceForm.get("billingItemDTOs").value;
    this.billingItemDTOs = this.invoiceForm.get("billingItemDTOs") as FormArray;
    this.billingItemDTOs.push(this.createBillingItem());
  }

  getEmployees() {
    let id;
    if (this.petitionerId) {
      id = this.petitionerId.id;
    } else {
      id = this.companyId;
    }
    this.invoiceService.getEmployeesOfTheCompany(this.companyId).subscribe(
      (resp) => {
        this.employees = resp;
        this.employees.unshift({ firstName: "others", lastName: "", id: "" });
      },
      (err) => {
        console.log("Error while fetching employees of the company", err);
      }
    );
  }

  UploadSignature(event, fileUpload) {
    fileUpload.clear();
    this.signatureFile = event.files[0];
    this.signatureImage = this.sanitizer.bypassSecurityTrustUrl(
      this.signatureFile.objectURL.changingThisBreaksApplicationSecurity
    );

    this.signatureFile["fileName"] = event.files[0].name;
    this.sigPayload = new FormData();
    this.sigPayload.append("file", this.signatureFile, this.signatureFile.name);
  }

  removeBillingItem(index) {
    this.billingItemDTOs.removeAt(index);
    let grandTotal = 0;
    this.billingItemDTOs.value.forEach((x) => {
      grandTotal += +x.totalAmount;
    });
    this.grandTotal = grandTotal;
  }

  // saveUploadInvoice() {
  //   let reqObject = {};
  //   console.log("Other Upload", this.otherUpload);
  //   if (
  //     this.editInvoiceItem &&
  //     this.itemData.billedTocompany.companyId == null
  //   ) {
  //     let selectedClient = this.uploadInvoiceForm.get(
  //       "selectedUploadClient"
  //     ).value;
  //     reqObject = {
  //       billedBycompanyId: this.companyId,
  //       addressDto: {
  //         addressLine1: selectedClient.address.addressLine1,
  //         city: selectedClient.address.city,
  //         county: selectedClient.address.countryCode,
  //         postCode: selectedClient.address.postCode,
  //         stateProvinceCode: selectedClient.address.stateName,
  //       },
  //       billedToClientName: selectedClient.companyName,
  //       email: selectedClient.email,
  //       phoneCode: selectedClient.countryPhoneAbbr,
  //       phoneNumber: selectedClient.phone,
  //       busFirmName: this.attorneyFirms[0].companyName,
  //       invoiceDate: moment(
  //         this.uploadInvoiceForm.get("invoiceDate").value
  //       ).format("YYYY-MM-DD"),
  //       invoiceDueDate: moment(
  //         this.uploadInvoiceForm.get("invoiceDueDate").value
  //       ).format("YYYY-MM-DD"),
  //       invoiceId: this.editInvoiceItem ? this.editInvoiceItem.invoiceId : null,
  //       invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
  //       invoiceTitle: "Invoice",
  //       isClientSaved: 0,
  //     };

  //     console.log("saveInvoice ::", reqObject);
  //   } else {
  //     if (this.otherUpload) {
  //       reqObject = {
  //         billedBycompanyId: this.companyId,
  //         addressDto: {
  //           addressLine1: this.uploadInvoiceForm.get("otherAddress").value,
  //           city: this.uploadInvoiceForm.get("otherCity").value,
  //           county:
  //             this.uploadInvoiceForm.get("otherCountry").value.countryCode,
  //           postCode: this.uploadInvoiceForm.get("otherZipCode").value,
  //           stateProvinceCode:
  //             this.uploadInvoiceForm.get("otherState").value.stateProvinceCode,
  //         },
  //         billedToClientName: this.uploadInvoiceForm.get("newClient").value,
  //         email: this.uploadInvoiceForm.get("otherEmail").value,
  //         phoneCode: this.uploadInvoiceForm.get("countryPhCode").value,
  //         phoneNumber: this.uploadInvoiceForm.get("otherPhoneNumber").value,
  //         busFirmName: this.attorneyFirms[0].companyName,
  //         invoiceDate: moment(
  //           this.uploadInvoiceForm.get("invoiceDate").value
  //         ).format("YYYY-MM-DD"),
  //         invoiceDueDate: moment(
  //           this.uploadInvoiceForm.get("invoiceDueDate").value
  //         ).format("YYYY-MM-DD"),
  //         invoiceId: null,
  //         invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
  //         invoiceTitle: "Invoice",
  //         isClientSaved: this.isUploadClientSaved,
  //       };
  //     } else {
  //       if (this.isIndividualClientUpload) {
  //         let selectedClient = this.uploadInvoiceForm.get(
  //           "selectedUploadClient"
  //         ).value;
  //         console.log(
  //           "this.isIndividualClientUpload",
  //           this.isIndividualClientUpload,
  //           selectedClient
  //         );
  //         reqObject = {
  //           billedBycompanyId: this.companyId,
  //           addressDto: {
  //             addressLine1: selectedClient.address.addressLine1,
  //             city: selectedClient.address.city,
  //             county: selectedClient.address.countryCode,
  //             postCode: selectedClient.address.postCode,
  //             stateProvinceCode: selectedClient.address.stateName,
  //           },
  //           billedToClientName: selectedClient.companyName,
  //           email: selectedClient.email,
  //           phoneCode: selectedClient.countryPhoneAbbr,
  //           phoneNumber: selectedClient.phone,
  //           busFirmName: this.attorneyFirms[0].companyName,
  //           invoiceDate: moment(
  //             this.uploadInvoiceForm.get("invoiceDate").value
  //           ).format("YYYY-MM-DD"),
  //           invoiceDueDate: moment(
  //             this.uploadInvoiceForm.get("invoiceDueDate").value
  //           ).format("YYYY-MM-DD"),
  //           invoiceId: this.editInvoiceItem
  //             ? this.editInvoiceItem.invoiceId
  //             : null,
  //           invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
  //           invoiceTitle: "Invoice",
  //           isClientSaved: 0,
  //         };
  //       } else {
  //         reqObject = {
  //           addressDto: {
  //             addressLine1: "",
  //             city: "",
  //             county: "",
  //             postCode: "",
  //             stateProvinceCode: "",
  //           },
  //           invoiceDate: moment(
  //             this.uploadInvoiceForm.get("invoiceDate")?.value
  //           ).format("YYYY-MM-DD"),
  //           invoiceDueDate: moment(
  //             this.uploadInvoiceForm.get("invoiceDueDate")?.value
  //           ).format("YYYY-MM-DD"),
  //           billedBycompanyId: this.companyId,
  //           billedToClientName: "",
  //           billedTocompanyId: this.billToId,
  //           billingItemDTOs: null,
  //           busFirmName: "",
  //           email: "",
  //           grandTotal: null,
  //           invoiceId: this.editInvoiceItem
  //             ? this.editInvoiceItem.invoiceId
  //             : null,
  //           invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
  //           invoiceTitle: "Invoice",
  //           isClientSaved: 0,
  //           phoneCode: "",
  //           phoneNumber: "",
  //           signatoryId: "",
  //           signatoryName: "",
  //           termsAndConditions: "",
  //           sigNatoryName1: "",
  //         };
  //       }
  //     }
  //   }
  //   console.log("SAVE UPLOAD INVOICE :: ", reqObject);
  //   this.invoiceService
  //     .generateInvoice(reqObject)
  //     .pipe(take(1))
  //     .subscribe(
  //       (res: any) => {
  //         console.log("own invoice copy", res);
  //         // console.log("invoice", this.uploadInvoiceForm.value);
  //         if (res) {
  //           let id = this.editInvoiceItem
  //             ? this.editInvoiceItem.invoiceId
  //             : res.data.invoiceId;
  //           console.log("id", id);
  //           // this.toaster.success(res.message);
  //           this.invoiceService
  //             .uploadOwnInvoive(this.payload, id)
  //             .pipe(take(1))
  //             .subscribe((resp) => {
  //               if (resp.status == 200) {
  //                 this.createdInvoiceId = id;
  //                 this.valueChanged();
  //                 console.log("invoicecopy uploaded", resp.status);
  //                 // this.toaster.success(resp.message);
  //                 // console.log("invoicecopy uploaded", res);
  //               } else {
  //                 this.toaster.warning(resp.message);
  //               }
  //             });
  //         }
  //       },
  //       (err) => {
  //         console.log("Error while generating invoice", err);
  //       }
  //     );
  // }

  saveUploadInvoice() {
    
    let reqObject = {};
    if (
      this.editInvoiceItem &&
      this.itemData.billedTocompany.companyId == null
    ) {
      let selectedClient = this.uploadInvoiceForm.get(
        "selectedUploadClient"
      ).value;
      reqObject = {
        billedBycompanyId: this.companyId,
        individualId: null,
        individualName: '',
        addressDto: {
          addressLine1: selectedClient.address.addressLine1,
          city: selectedClient.address.city,
          county: selectedClient.address.countryCode,
          postCode: selectedClient.address.postCode,
          stateProvinceCode: selectedClient.address.stateName,
        },
        billedToClientName: selectedClient.companyName,
        email: selectedClient.email,
        phoneCode: selectedClient.countryPhoneAbbr,
        phoneNumber: selectedClient.phone,
        busFirmName: this.attorneyFirms[0].companyName,
        invoiceDate: moment(
          this.uploadInvoiceForm.get("invoiceDate").value
        ).format("YYYY-MM-DD"),
        invoiceDueDate: moment(
          this.uploadInvoiceForm.get("invoiceDueDate").value
        ).format("YYYY-MM-DD"),
        invoiceId: this.editInvoiceItem ? this.editInvoiceItem.invoiceId : null,
        invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
        invoiceTitle: "Invoice",
        isClientSaved: 0,
      };

    } else {
      if (this.otherUpload) {
        reqObject = {
          billedBycompanyId: this.companyId,
          individualId: null,
          individualName: '',
          addressDto: {
            addressLine1: this.uploadInvoiceForm.get("otherAddress").value,
            city: this.uploadInvoiceForm.get("otherCity").value,
            county:
              this.uploadInvoiceForm.get("otherCountry").value.countryCode,
            postCode: this.uploadInvoiceForm.get("otherZipCode").value,
            stateProvinceCode:
              this.uploadInvoiceForm.get("otherState").value.stateProvinceCode,
          },
          billedToClientName: this.uploadInvoiceForm.get("newClient").value,
          email: this.uploadInvoiceForm.get("otherEmail").value,
          phoneCode: this.uploadInvoiceForm.get("countryPhCode").value,
          phoneNumber: this.uploadInvoiceForm.get("otherPhoneNumber").value,
          busFirmName: this.attorneyFirms[0].companyName,
          invoiceDate: moment(
            this.uploadInvoiceForm.get("invoiceDate").value
          ).format("YYYY-MM-DD"),
          invoiceDueDate: moment(
            this.uploadInvoiceForm.get("invoiceDueDate").value
          ).format("YYYY-MM-DD"),
          invoiceId: null,
          invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
          invoiceTitle: "Invoice",
          isClientSaved: this.isUploadClientSaved,
        };
      } else {
        if (this.isIndividualClientUpload) {
          let selectedClient = this.uploadInvoiceForm.get(
            "selectedUploadClient"
          ).value;
          reqObject = {
            billedBycompanyId: this.companyId,
            individualId: null,
            individualName: '',
            addressDto: {
              addressLine1: selectedClient.address.addressLine1,
              city: selectedClient.address.city,
              county: selectedClient.address.countryCode,
              postCode: selectedClient.address.postCode,
              stateProvinceCode: selectedClient.address.stateName,
            },
            billedToClientName: selectedClient.companyName,
            email: selectedClient.email,
            phoneCode: selectedClient.countryPhoneAbbr,
            phoneNumber: selectedClient.phone,
            busFirmName: this.attorneyFirms[0].companyName,
            invoiceDate: moment(
              this.uploadInvoiceForm.get("invoiceDate").value
            ).format("YYYY-MM-DD"),
            invoiceDueDate: moment(
              this.uploadInvoiceForm.get("invoiceDueDate").value
            ).format("YYYY-MM-DD"),
            invoiceId: this.editInvoiceItem
              ? this.editInvoiceItem.invoiceId
              : null,
            invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
            invoiceTitle: "Invoice",
            isClientSaved: 0,
          };
        } else {
          reqObject = {
            addressDto: {
              addressLine1: "",
              city: "",
              county: "",
              postCode: "",
              stateProvinceCode: "",
            },
            invoiceDate: moment(
              this.uploadInvoiceForm.get("invoiceDate")?.value
            ).format("YYYY-MM-DD"),
            invoiceDueDate: moment(
              this.uploadInvoiceForm.get("invoiceDueDate")?.value
            ).format("YYYY-MM-DD"),
            billedBycompanyId: this.companyId,
            individualId: null,
            individualName: '',
            billedToClientName: "",
            billedTocompanyId: this.billToId,
            billingItemDTOs: null,
            busFirmName: "",
            email: "",
            grandTotal: null,
            invoiceId: this.editInvoiceItem
              ? this.editInvoiceItem.invoiceId
              : null,
            invoiceNumber: this.uploadInvoiceForm.get("invoiceNumber").value,
            invoiceTitle: "Invoice",
            isClientSaved: 0,
            phoneCode: "",
            phoneNumber: "",
            signatoryId: "",
            signatoryName: "",
            termsAndConditions: "",
            sigNatoryName1: "",
          };
        }
      }
    }
    this.invoiceService
      .generateInvoice(reqObject)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (res) {
            let id = this.editInvoiceItem
              ? this.editInvoiceItem.invoiceId
              : res.data.invoiceId;
            this.toaster.success(res.message, 'Success');
            this.invoiceService
              .uploadOwnInvoive(this.payload, id)
              .pipe(take(1))
              .subscribe((resp) => {
                if (resp.status == 200) {
                  this.createdInvoiceId = id;
                  // this.valueChanged();
                  this.toaster.success(resp.message, 'Success');
                  // console.log("invoicecopy uploaded", res);
                  this.valueChanged();
                } else {
                  this.toaster.warning(resp.message, 'Warning');
                }
              });
          }
        },
        (err) => {
          console.log("Error while generating invoice", err);
        }
      );
      
  }

  populateBilledToCompanyDataBackup(event) {
    this.isUpEditable = true;
    this.isIndividualClientUpload = event.value.individualClient;

    if (
      this.uploadInvoiceForm.get("selectedUploadClient").value.companyName ==
      "others"
    ) {
      this.otherUpload = true;
    } else {
      this.otherUpload = false;
    }
    this.billeId = this.companyId;
    this.billToId = event.value.companyId;
    let selClient = event.value;
    this.uploadInvoiceForm.patchValue({
      email: selClient.email,
      phoneNumber: selClient.phone,
      address: selClient.address.addressLine1,
      country: selClient.address.countryName,
      state: selClient.address.stateFullName,
      city: selClient.address.city,
      zipcode: selClient.address.postCode,
      countryPhoneCode: selClient.phoneCode,
    });

    this.previewClient = {
      email: selClient.email,
      countryPhoneCode: selClient.phoneCode,
      phoneNumber: selClient.phone,
      address: selClient.address.addressLine1,
      state: selClient.address.stateFullName,
      city: selClient.address.city,
      zipcode: selClient.address.postCode,
    };
  }

  populateBilledToCompanyData(event) {
    this.isUpEditable = true;
    this.isIndividualClientUpload = event.value.individualClient;

    if (
      this.uploadInvoiceForm.get("selectedUploadClient").value.companyName ==
      "others"
    ) {
      this.otherUpload = true;
    } else {
      this.otherUpload = false;
    }
    // console.log("populateBilledToCompanyData ", event);
    // console.log("populateBilledToCompanyData Called", event);
    // this.billeId = this.companyId;
    // this.billToId = event.value.companyId;
    let selClient = event.value;
    // new code added.


    if (
      this.uploadInvoiceForm.get("selectedUploadClient").value.companyName ==
      "others"
    ) {
      this.otherUpload = true;

      if (this.otherUpload) {
        this.uploadInvoiceForm
          .get("newClient")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherEmail")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherCountry")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherPhoneNumber")
          .setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
        this.uploadInvoiceForm
          .get("otherAddress")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherState")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherCity")
          .setValidators([Validators.required]);
        this.uploadInvoiceForm
          .get("otherZipCode")
          .setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      }
    } else {
      this.otherUpload = false;
      this.uploadInvoiceForm
        .get("selectedUploadClient")
        .setValidators([Validators.required]);
      this.uploadInvoiceForm.get("email").setValidators([Validators.required]);
      this.uploadInvoiceForm
        .get("phoneNumber")
        .setValidators([Validators.required]);
      this.uploadInvoiceForm
        .get("address")
        .setValidators([Validators.required]);
      this.uploadInvoiceForm
        .get("country")
        .setValidators([Validators.required]);
      this.uploadInvoiceForm.get("state").setValidators([Validators.required]);
      this.uploadInvoiceForm.get("city").setValidators([Validators.required]);
      this.uploadInvoiceForm
        .get("zipcode")
        .setValidators([Validators.required]);
    }

    this.billeId = this.companyId;
    this.billToId = event.value.companyId;
    this.uploadInvoiceForm.patchValue({
      email: selClient.email,
      phoneNumber: selClient.phone,
      address: selClient.address.addressLine1,
      country: selClient.address.countryName,
      state: selClient.address.stateFullName,
      city: selClient.address.city,
      zipcode: selClient.address.postCode,
      countryPhoneCode: selClient.phoneCode,
    });
    this.previewClient = {
      email: selClient.email,
      countryPhoneCode: selClient.phoneCode,
      phoneNumber: selClient.phone,
      address: selClient.address.addressLine1,
      state: selClient.address.stateFullName,
      city: selClient.address.city,
      zipcode: selClient.address.postCode,
    };
  }

  displayValue(event, saveClient) {
    if (saveClient.checked) {
      this.isClientSaved = 1;
    } else {
      this.isClientSaved = 0;
    }
    this.invoiceForm.patchValue({
      saveClient: this.isClientSaved,
    });
  }

  displayValue2(event, saveClient) {
    if (saveClient.checked) {
      this.isUploadClientSaved = 1;
    } else {
      this.isUploadClientSaved = 0;
    }
    this.uploadInvoiceForm.patchValue({
      saveClient: this.isUploadClientSaved,
    });
  }

  showSelectedCountry(event) {
    this.apiService
      .getStates(event.value.countryCode)
      .subscribe((states: []) => {
        this.otherStates = states;
      });
  }

  showCity(event) {
    
  }

  resetFields() {
    if (this.createdInvoiceId) {
      this.backToMain();
    } else {
      this.invoiceForm.patchValue({
        termsAndConditions: "",
        newClient: [""],
        saveClient: [""],
        otherAddress: [""],
        otherPhoneNumber: [""],
        otherEmail: [""],
        otherCountry: [""],
        otherZipCode: [""],
        otherCity: [""],
        otherState: [""],
        phoneNumber: [],
        email: [""],
        address: [""],
        country: [""],
        state: [""],
        city: [""],
        selectedClient: [""],
        zipcode: [""],
        signatoryName: [""],
        sigNatoryName1: [""],
        countryPhCode: [""],
      });
      this.companyLogoImage = "";
      this.signatureImage = "";
      const control = <FormArray>this.invoiceForm.controls["billingItemDTOs"];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i);
      }

      this.billingItemDTOs.push(this.createBillingItem());
      this.grandTotal = 0;
    }
  }

  showPreview() {
    if (this.invoiceType) {
      if (this.others)
        this.previewClient = {
          email: this.invoiceForm.get("otherEmail").value,
          // countryPhoneCode:
          //   selClient.petitionerRegisteredAddress.countryCode.phoneCode,
          phoneNumber: this.invoiceForm.get("otherPhoneNumber").value,
          address: this.invoiceForm.get("otherAddress").value,
          state: this.invoiceForm.get("otherState").value.stateProvinceName,
          city: this.invoiceForm.get("otherCity").value,
          zipcode: this.invoiceForm.get("otherZipCode").value,
        };
      this.previewBillingItems = this.invoiceForm.get("billingItemDTOs").value;
    } else {
      if (this.otherUpload) {
        this.previewClient = {
          email: this.uploadInvoiceForm.get("otherEmail").value,
          // countryPhoneCode: selClient.phoneCode,
          phoneNumber: this.uploadInvoiceForm.get("otherPhoneNumber").value,
          address: this.uploadInvoiceForm.get("otherAddress").value,
          state:
            this.uploadInvoiceForm.get("otherState").value.stateProvinceName,
          city: this.uploadInvoiceForm.get("otherCity").value,
          zipcode: this.uploadInvoiceForm.get("otherZipCode").value,
        };
      }
    }

    this.previewDialog = true;
  }

  showSignature(event?) {
    if (this.invoiceForm.get("signatoryName").value.firstName == "others") {
      this.otherSignatory = true;
      this.invoiceForm
        .get("signatoryName1")
        .setValidators([Validators.required]);
      this.invoiceForm.get("signatoryName").clearValidators();
    } else {
      this.otherSignatory = false;
      // this.invoiceForm
      //   .get("signatoryName")
      //   .setValidators([Validators.required]);
    }

    this.signatoryFirstName =
      this.invoiceForm.get("signatoryName").value.firstName +
      " " +
      this.invoiceForm.get("signatoryName").value.lastName;
  }

  getSignatoryName() {
    this.signatoryFirstName = this.invoiceForm.get("signatoryName1").value;
  }

  showTotal(item, index) {
    let total = 0;
    const currentChargableAmount = item.controls.chargableAmount.value == '' ? Number(item.controls.chargableAmount.value) : parseFloat(item.controls.chargableAmount.value);
    const currentTaxAmount = item.controls.tax.value == '' ? Number(item.controls.tax.value) : parseFloat(item.controls.tax.value);
    const currentDiscountAmount = item.controls.discount.value == '' ? Number(item.controls.discount.value) : parseFloat(item.controls.discount.value);
    total =
    currentChargableAmount +
    currentTaxAmount - 
    currentDiscountAmount;
    this.billingItemDTOs.at(index).get("totalAmount").setValue(total);
    let grandTotal = 0;
    this.billingItemDTOs.value.forEach((x) => {
      grandTotal += +x.totalAmount;
    });
    this.grandTotal = grandTotal;
  }

  backToMain() {
    this.router.navigate(["/attorney-landing/attorney-invoice"]);
  }

  openDoc(fileLocation) {
    const docPath = fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
  deleteuploadDoc(item) {
    this.invoiceService
      .deleteuploadOwnInvoice(this.invoiceId)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.showUploadedfile = null;
        }
      });
  }

  saveClientInvoice() {
    let reqObject = {
      billedBycompanyId: this.companyId,
      billedTocompanyId: this.billedToCompanyId,
      individualId: null,
      individualName: '',
      billingItemDTOs: this.invoiceForm.get("billingItemDTOs").value,
      busFirmName: this.attorneyFirms[0].companyName,
      invoiceDate: moment(this.invoiceForm.get("invoiceDate")?.value).format(
        "YYYY-MM-DD"
      ),
      invoiceDueDate: moment(
        this.invoiceForm.get("invoiceDueDate")?.value
      ).format("YYYY-MM-DD"),
      // invoiceId: null,
      invoiceId: this.editInvoiceItem ? this.editInvoiceItem.invoiceId : null,
      invoiceNumber: this.invoiceForm.get("invoiceNumber").value,
      invoiceTitle: this.invoiceForm.get("invoiceTitle").value,
      // signatoryId: !this.otherSignatory
      //   ? this.invoiceForm.get("signatoryName").value.id
      //   : "",
      // signatoryName: !this.otherSignatory
      //   ? this.invoiceForm.get("signatoryName").value.firstName +
      //   " " +
      //   this.invoiceForm.get("signatoryName").value.lastName
      //   : this.invoiceForm.get("signatoryName1").value,


        signatoryId: !this.otherSignatory
        ? this.invoiceForm.get("signatoryName").value ? this.invoiceForm.get("signatoryName").value.id
        : "" : "",
      signatoryName: !this.otherSignatory ?  this.invoiceForm.get("signatoryName").value
        ? this.invoiceForm.get("signatoryName").value.firstName +
        " " +
        this.invoiceForm.get("signatoryName").value.lastName :""
        : this.invoiceForm.get("signatoryName1").value ? this.invoiceForm.get("signatoryName1").value : "",
      termsAndConditions: this.invoiceForm.get("termsAndConditions").value,
      isClientSaved: 0,
    };

    if (this.billToform.get('billType').value == 'Individual') {
      reqObject.individualId = this.individualId;
      reqObject.individualName = this.individualName;
    }

    this.invoiceService
      .createInvoice(reqObject)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.createdInvoiceId = resp.data.invoiceId;
            let signatureUrl =
              "/attorney/invoice/uploadSignImage/" + this.createdInvoiceId;
            if (this.sigPayload) {
              this.invoiceService
                .uploadFile(signatureUrl, this.sigPayload)
                .pipe(take(1))
                .subscribe((resp) => {
                  this.invoiceService
                    .generatePdf(this.createdInvoiceId)
                    .subscribe(
                      (response) => {
                        this.valueChanged();
                      },
                      (err) => {
                        console.log("generate pdf err ::", err);
                      }
                    );
                });
            } else {
              this.invoiceService
              .generatePdf(this.createdInvoiceId)
              .subscribe(
                (response) => {
                  this.valueChanged();
                },
                (err) => {
                  console.log("generate pdf err ::", err);
                }
              );

            }
          }

          this.toaster.success(resp.message, 'Success');
        },
        (err) => {
          CustomErrorToastComponent.showErrorToast(this.toaster, err.message);
          console.log("Error while saving the invoice");
        }
      );
  }
  // get individuals of the attorney
  getAttorneyIndividuals() {
    let payload = {
      fromAttorneyCreatePetitioner: true,
      pageNumber: 0,
      recordsPerPage: 25,
      searchCriteria: "",
      sortBy: "asc",
      sortOrder: "",
    };
    this.invoiceService
      .getAttorneyIndividuals(this.companyId)
      .pipe(take(1))
      .subscribe((resp) => {
        this.individualsList = resp.data;
      });
  }

  updateValidations(event:any){
    this.invoiceForm.patchValue({
          selectedClient: null,
          email: null,
          phoneNumber: null,
          address: null,
          country: null,
          state: null,
          city: null,
          zipcode: null,
          countryPhoneCode: null,
          termsAndConditions: null,
          newClient: null,
          otherEmail: null,
          otherCountry: null,
          otherPhoneNumber: null,
          otherAddress: null,
          otherState: null,
          otherCity: null,
          otherZipCode: null
    });
    this.invoiceForm.get("newClient").clearValidators();
    this.invoiceForm.get("newClient").updateValueAndValidity();
    this.invoiceForm.get("otherEmail").clearValidators();
    this.invoiceForm.get("otherEmail").updateValueAndValidity();
    this.invoiceForm.get("otherCountry").clearValidators();
    this.invoiceForm.get("otherCountry").updateValueAndValidity();
    this.invoiceForm.get("otherPhoneNumber").clearValidators();
    this.invoiceForm.get("otherPhoneNumber").updateValueAndValidity();
    this.invoiceForm.get("otherAddress").clearValidators();
    this.invoiceForm.get("otherAddress").updateValueAndValidity();
    this.invoiceForm.get("otherState").clearValidators();
    this.invoiceForm.get("otherState").updateValueAndValidity();
    this.invoiceForm.get("otherCity").clearValidators();
    this.invoiceForm.get("otherCity").updateValueAndValidity();
    this.invoiceForm.get("otherZipCode").clearValidators();
    this.invoiceForm.get("otherZipCode").updateValueAndValidity();
  }

}
