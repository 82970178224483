import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class CacheCountryService {

  public countryDataSource = new BehaviorSubject<any>({ listCountries: [] });
  private countryCodeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public countryCode$: Observable<string> = this.countryCodeSubject.asObservable();
  
  constructor(private http: HttpClient) {
  }


  setCountryCode(countryCode: string): void {
    this.countryCodeSubject.next(countryCode);
  }

  getCountryCode(): Observable<string> {
    return this.countryCode$;
  }

  getCountry() {
    return this.http.get('/countries').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          this.countryDataSource.next({ listCountries: response.data });
          return response.data;
        }
      })
    );
  }

  getCountryWithoutUSA() {
    return this.http.get('/countriesNonUsa').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          this.countryDataSource.next({ listCountries: response.data });
          return response.data;
        }
      })
    );
  }

  getCachedCountryList() {
    return this.countryDataSource.asObservable();
  }
}
