import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'delete-dialog-library',
  templateUrl: 'delete-dialog-library.component.html',
  styleUrls: ['delete-dialog-library.component.scss'],
  providers: [DialogService]
})
export class DeleteDialogLibraryComponent implements OnInit {
  deleteConfirmationMessage: string;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    public dialogService: DialogService,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
    if (this.dynamicDialogConfig.data && this.dynamicDialogConfig.data.deleteMessage) {
      this.deleteConfirmationMessage = this.dynamicDialogConfig.data.deleteMessage;
    } else {
      this.deleteConfirmationMessage = 'Do you want to delete this record?';
    }
  }

  ngOnInit(): void {
  }

  handleYes() {
    this.dynamicDialogRef.close(true);
  }

  handleNo() {
    this.dynamicDialogRef.close(false);
  }

}

