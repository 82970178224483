import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengLibraryModule } from 'primeng-library';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CaseStatusRoutingModule } from './case-status-routing.module';
import { CaseStatusContainerComponent } from './case-status-container/case-status-container.component';
import { ManageCaseStatusComponent } from './manage-case-status/manage-case-status.component';
import { CaseStatusService } from './services/case-status.service';
import { AddNewCaseComponent } from './add-new-case/add-new-case.component';
import {DropdownModule} from 'primeng/dropdown';
@NgModule({
  declarations: [CaseStatusContainerComponent, ManageCaseStatusComponent, AddNewCaseComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CaseStatusRoutingModule,
    PrimengLibraryModule,
    DropdownModule
  ],
  providers: [
    CaseStatusService
  ]
})
export class CaseStatusModule { }
