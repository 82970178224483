import { NG_VALIDATORS, Validator, AbstractControl, ControlContainer } from '@angular/forms';
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VisaDetails, TravelHistory } from '../../../../data/models/immigration.model';
import * as moment from 'moment';

@Directive({
    selector: '[exitDateValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ExitDateValidator,
            multi: true
        }
    ]
})

export class ExitDateValidator implements Validator, OnChanges {
    @Input()
    arrivalDate: Date;

    @Input()
    visa: VisaDetails;

    todayDate: Date = new Date();

    private _onChange: () => void;



    validate(control: AbstractControl) {
        //control.parent.get('arrivalDate'));
       // console.log("Arrival" + this.arrivalDate);
       // console.log("today" + this.todayDate);
       // console.log("visa" + this.visa);

        let output = null;
        if (control.value) {
            switch (this.visa.outcomeIdCode) {
                case 'VSADENY':
                    if (new Date(control.value) >= new Date(this.arrivalDate) && new Date(control.value) >= new Date(this.visa.receiptDate) && new Date(control.value) <= this.todayDate) {
                        output = null;
                    }
                    else {
                        output = { 'exitDateInavlid': true };
                    }
                    break;
                case 'VSAAPP':
                    if (new Date(control.value) >= new Date(this.arrivalDate) && new Date(control.value) <= this.todayDate && new Date(control.value) <= new Date(this.visa.validityEndDate) && new Date(control.value) >= new Date(this.visa.validityStartDate) && new Date(control.value) >= new Date(this.visa.receiptDate)) {
                        output = null;
                    }
                    else {
                        output = { 'exitDateInavlid': true };
                    }
                    break;
                case 'VSAWTDN':
                    if (new Date(control.value) >= new Date(this.arrivalDate) && new Date(control.value) <= this.todayDate) {
                        output = null;
                    }
                    else {
                        output = { 'exitDateInavlid': true };
                    }
                    break;
                case 'VSPROCESS':
                    if (new Date(control.value) >= new Date(this.arrivalDate) && new Date(control.value) <= this.todayDate) {
                        output = null;
                    }
                    else {
                        output = { 'exitDateInavlid': true };
                    }
                    break;
                default:
                    output = null;
            }
            return output;
        }
        return output;
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    ngOnChanges(e: SimpleChanges) {
        if (e.arrivalDate && e.arrivalDate.currentValue) {
            this._onChange();
        }
        if (e.expiryDate && e.expiryDate.currentValue) {
            this._onChange();
        }
    }
}



@Directive({
    selector: '[arrivalDateValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ArrivalDateValidator,
            multi: true
        }
    ]
})

export class ArrivalDateValidator implements Validator, OnChanges {

    @Input()
    visaInput: VisaDetails;

    todayDate: Date = new Date();

    private _onChange: () => void;



    validate(control: AbstractControl) {
        //IM-8611
        let arrivalDate = moment(control.value).toISOString();
        let out = null;
        if (arrivalDate) {
            if (this.visaInput.outcomeIdCode === 'VSAAPP') {
                if (this.visaInput.immigrationStatusCode === 'H1' || this.visaInput.immigrationStatusCode === 'L1') {
                    // if (new Date(arrivalDate) >= new Date(this.visaInput.receiptDate) && new Date(arrivalDate) >= new Date(this.visaInput.outcomeDate) && new Date(arrivalDate) >= new Date(this.visaInput.validityStartDate) && new Date(arrivalDate) <= new Date(this.visaInput.validityEndDate) && new Date(arrivalDate) <= new Date()) {
                      if (new Date(arrivalDate) >= new Date(this.visaInput.receiptDate) && new Date(arrivalDate) >= new Date(this.visaInput.validityStartDate) && new Date(arrivalDate) <= new Date(this.visaInput.validityEndDate) && new Date(arrivalDate) <= new Date()) {
                        out = null;
                    } else {
                        out = { 'arrivalDateInavlid': true };
                    }

                } else {                    
                    if (new Date(arrivalDate) >= new Date(this.visaInput.validityStartDate) && new Date(arrivalDate) <= new Date(this.visaInput.validityEndDate)) {
                        out = null;
                    } else {
                        out = { 'arrivalDateInavlid': true };
                    }

                }

            }
            else {
                if (new Date(arrivalDate) < new Date(this.visaInput.receiptDate) || new Date(arrivalDate) > new Date(this.visaInput.validityStartDate)) {
                    out = { 'arrivalDateInavlid': true };
                }
            }
        }
        return out;
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    ngOnChanges(e: SimpleChanges) {
        if (e.travelHistory && e.travelHistory.currentValue) {
            this._onChange();
        }
        if (e.visa && e.visa.currentValue) {
            this._onChange();
        }
    }
}
