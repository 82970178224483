import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { getStepDetails } from '../../store/selectors/sub-task-details.selectors';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { NgForm } from '@angular/forms';
//import { FroalaEditorOptions } from '../../../../data/constants/froala-editor-options';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { ReferenceLibrary } from '../../../../data/models/referenceLibrary.model';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeleteDialogConfigurations, NoteDialogConfigurations } from '../../utility/configurations/dialog.configuration';
import { updatei140StepStatus, updateStepStatusToInitialState } from '../../store/actions/step-status-update.actions';
import { getStepStatusUpdate } from '../../store/selectors/step-status-update.selectors';
import { loadSubTaskDetails } from '../../store/actions/sub-task-details.actions';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { map } from 'rxjs/internal/operators/map';
import { DirtyFormGuard } from 'src/app/dirty-form.guard';
import { first } from 'rxjs/internal/operators/first';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { GCWorkDetails } from '../../../../data/models/gcWorkExp.model';
import { environment } from '../../../../../environments/environment';
import * as RolesRouteMap from 'app-models';
import { Router } from '@angular/router';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
import { PrimaryData } from 'app-models';


@Component({
  selector: 'app-work-experience-details',
  templateUrl: './work-experience-details.component.html',
  styleUrls: ['./work-experience-details.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class WorkExperienceDetailsComponent implements OnInit {

  @ViewChild('workExperienceForm', { static: true }) workExperienceForm: NgForm;

  @ViewChild("workexpimageupload") public workexpimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("workexpkendoeditor") public workexpkendoeditor: EditorComponent;

  // public options = FroalaEditorOptions.EditorOptions;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  experienceCols: any[];
  experienceSubCols: any[];
  primaryData: PrimaryData;
  stepDetails: TaskStep;
  countryList: [];
  businessTypeList: ReferenceLibrary[];
  showOthername: boolean;
  subTaskDetails: SubTaskDetails;
  experienceDetails: GCWorkDetails;

  observableSubscription$ = new Subject();
  guardSubscription: any;
  userType: string;
  questionnaire :boolean;
  stepId: any;
  isEditWorkExperience: boolean = false;

  constructor(
    private toastr: ToastrService,
    private pbService: PetitionBuilderService,
    private store: Store<any>,
    public dynamicDialogRef: DynamicDialogRef,
    private guard: DirtyFormGuard,
    public dynamicDialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    private cacheCountyService: CacheCountryService,
    private referenceLibraryService: ReferenceLibraryService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayload,
    public router: Router,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.questionnaire = false;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.disabledControls = false;
    this.showOthername = false;
    this.countryList = [];
    this.experienceDetails = {
      gcAdditionalInfoResponseDTO: {
        additionalText: '',
        createdByUser: null,
        createdDate: null,
        gcI140CaseId: null,
        id: null,
        infoTypeId: null,
        infoTypeName: null,
        isDel: null,
        modifiedByUser: null,
        modifiedDate: null
      },
      gcI140CaseId: null,
      gcWorkExperienceDetailsResponseList: []
    };
    this.questionnaire = window.location.pathname === "/beneficiary-profile/profile" ?  true : false;
    this.primaryData = this.dynamicDialogConfig.data;
  }

  ngOnInit(): void {
    //froalaService.insertPageBreak();
    // Selector for step status update
    this.stepId = this.dynamicDialogConfig.data.stepId;
    console.log("app-work-experience-details this.dynamicDialogConfig.data ", this.dynamicDialogConfig.data)
    this.store.pipe(select(getStepStatusUpdate))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message, 'Success');
          this.store.dispatch(loadSubTaskDetails({ taskId: this.stepDetails.taskId }));
        }
      });
    this.getBusniessType();
    this.getCountryList();

    this.experienceCols = [
      { field: 'designation', header: 'Job Title' },
      { field: 'employmentType', header: 'Job Type' },
      { field: 'startDate', header: 'Employed from' },
      { field: 'endDate', header: 'Employed till' },
      { field: 'salary', header: 'Salary' },
      { field: 'action', header: 'Action' },
    ];
    this.experienceSubCols = [
      { field: 'addressLine1', header: 'Address 1' },
      { field: 'addressLine2', header: 'Address 2' },
      { field: 'countryName', header: 'Country' },
      { field: 'stateProvinceName', header: 'State' },
      { field: 'city', header: 'City' },
      { field: 'zipCode', header: 'ZIP / Postal Code' },
    ];
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.getExperienceList();
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
        this.stepDetails = data;
        this.toggleControlsStatus();
      });

    this.workExperienceForm.form.valueChanges.pipe(
      map(() => this.workExperienceForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });

  }

  public workexpimageuploaddialog() {
    this.workexpimageupload.open();
  }


  getBusniessType() {
    this.referenceLibraryService.getReferenceData('BUSTYP').pipe(takeUntil(this.observableSubscription$)).subscribe((data: ReferenceLibrary[]) => {
      this.businessTypeList = data;
    });
  }

  //To fetch  list of countries
  getCountryList() {
    this.cacheCountyService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
      }
    });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  onBusinessTypeChange(selectedValues) {
    let codes = [];
    if (selectedValues && selectedValues.length > 0) {
      selectedValues.forEach(el => {
        codes.push(this.businessTypeList.filter(x => x.id === el)[0].code);
      });
      if (codes.length > 0 && codes.indexOf('OTHER') >= 0) {
        this.showOthername = true;
      } else {
        this.showOthername = false;
      }
    }
  }

  getExperienceList() {
    this.pbService.getBeneExperienceInformation(
      this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, false)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data) => {        
        this.experienceDetails = data;
      });
  }

  handleAllExperienceSync() {
    this.pbService.syncBeneWorkExperienceAll(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response && response.data) {
        this.experienceDetails = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getExperienceList();
      }
    });
  }

  handleExperienceSync(input) {
    this.pbService.syncBeneWorkExperience(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response && response.data) {
        this.experienceDetails = response.data ? response.data : [];
        this.toastr.success(response.message, 'Success');
        this.getExperienceList();
      }
    });
  }

  deleteExperienceList(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deletBeneWorkExperience(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getExperienceList();
        })
      }
    });
  }

  dutiesDelete(item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deletBeneWorkExperienceDuties(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, item.dutyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getExperienceList();
        })
      }
    });
  }

  subDutiesDelete(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deletBeneWorkExperienceDuties(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.subDutyId).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getExperienceList();
        })
      }
    });
  }

  handleToolsDelete(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deletBeneWorkExperienceTools(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getExperienceList();
        })
      }
    });
  }

  deleteDocuments(subexperiencelist, item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteBeneWorkExperienceDocuments(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, subexperiencelist.id, item.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getExperienceList();
        })
      }
    });
  }

  handleSendBack(item) {
    NoteDialogConfigurations.data = {
      title: `Rejected Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCREJECT",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCMISS",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  //To Verify Missing Fields
  handleVerify() { }

  postBeneWorkExperience() {
    const payload = {
      gcAdditionalInfoRequestDTO: {
        additionalText: this.experienceDetails.gcAdditionalInfoResponseDTO && this.experienceDetails.gcAdditionalInfoResponseDTO.additionalText ? this.experienceDetails.gcAdditionalInfoResponseDTO.additionalText : '',
        // infoType: '',
        // isDel: 0,
      },
      gcAdditionalWorkInfoDtoList: [],
      gcI140CaseId: this.dynamicDialogConfig.data.i140CaseId,
    };
    this.experienceDetails.gcWorkExperienceDetailsResponseList.forEach((item) => {
      payload.gcAdditionalWorkInfoDtoList.push({
        gcI140CaseId: this.dynamicDialogConfig.data.i140CaseId,
        gcWorkDetailId: item.id,
      });
    });
    this.pbService.postBeneExperienceInformation(payload, this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      this.toastr.success(response.message, 'Success');
      this.getExperienceList();
      if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        this.updateStepStatus('INPROGRESS');
      }
      this.workExperienceForm.resetForm();
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updatei140StepStatus({ payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode: this.stepDetails.stepActionList[0].layoutCode }));
  }

  onRowExpand(event) {
    console.log(event.data);
  }
  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  handleCancel() {
    this.getExperienceList();
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

  updateExperience() {
    this.isEditWorkExperience = !this.isEditWorkExperience;
    if(!this.isEditWorkExperience){
      this.getExperienceList();
    }    
  }

  public workexperiencedetailseditorValueChange(value: string): void {
    this.experienceDetails.gcAdditionalInfoResponseDTO.additionalText = value;
  }

  sync() {
    this.pbService.syncI140(this.primaryData.caseId).subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getExperienceList();
      }
    });
  }
  merge() {
    this.pbService.mergeI140(
      this.primaryData.superTaskId,
      this.primaryData.subTaskId)
    .subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getExperienceList();
      }
    });
  }
  reset() {
    this.pbService.resetI140(
      this.primaryData.superTaskId,
      this.primaryData.subTaskId
    )
    .subscribe((response: any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.getExperienceList();
      }
    });
  }
}
