<div class="bg-white">
  <!-- Nav bar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <a class="my-0 mr-md-auto font-weight-normal p-2" >
      <img src="../../../../assets/weblogo.png" alt="Imagility Logo" />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item active">
                <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li> -->
      </ul>

      <a
        routerLink="/login"
        id="button-login"
        class="im-btn im-btn-primary im-btn-lg text-white mx-3"
        >LOG IN</a
      >

      <form class="form-inline m-2 my-lg-0">
        <div class="im-search-group bg-white" style="width: 350px">
          <input
            type="search"
            placeholder="What're you searching for?"
            aria-describedby="button-search"
            class="im-input im-search"
          />
          <div class="im-input-group-append border-none">
            <button
              id="button-search"
              type="button"
              class="im-btn-search im-text-dark"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </nav>

  <div class="greeting-container">
    <!-- bg-dark  -->
    <div class="card text-dark border-0">
      <img
        class="card-img"
        src="../../../../assets/{{ pathSrc }}"
        alt="Card image"
      />
      <div class="card-img-overlay">
        <!-- <h3 class="card-text">Imagine a Life with</h3>
                <h2 class="card-title">Clarity and Managing Outcomes</h2>
                <h4 class="card-text">Imagility a one stop solution for</h4> -->

        <div
          class="button-with-image"
          [class.active]="pathSrc === 'beneficiary_banner.svg'"
          (click)="changeBackgroundImage('beneficiary_banner.svg')"
        >
          <img
            src="../../../../assets/images/landing-petitioner.png"
            alt=""
            srcset=""
          />
          <span>BENEFICIARIES</span>
        </div>
        <div
          class="button-with-image-2"
          [class.active]="pathSrc === 'Landing-Page-Petitioners.svg'"
          (click)="changeBackgroundImage('Landing-Page-Petitioners.svg')"
        >
          <img
            src="../../../../assets/images/landing-petitioner.png"
            alt=""
            srcset=""
          />
          <span>PETITIONERS</span>
        </div>
        <div
          class="button-with-image-4"
          [class.active]="pathSrc === 'Landing-Page-Attorney.svg'"
          (click)="changeBackgroundImage('Landing-Page-Attorney.svg')"
        >
          <img
            src="../../../../assets/images/landing-attorney.png"
            alt=""
            srcset=""
          />
          <span>LAWFIRMS</span>
        </div>
      </div>
      <div class="my-3">
        <a
          routerLink="/login"
          id="button-signin"
          class="im-btn im-btn-primary im-btn-lg mx-3 text-white"
          >SIGN ME IN</a
        >
        <a
          routerLink="/"
          routerLinkActive="router-link-active"
          id="button-learn-more"
          class="im-btn im-btn-primary im-btn-lg mx-3 bg-white text-info"
          >LEARN MORE</a
        >
      </div>
    </div>
  </div>

  <div class="card-text text-center p-5">IMAGILITY CAN HELP</div>

  <div class="role-tabs">
    <ul class="nav im-nav-tab-pills mb-3 row" id="pills-tab" role="tablist">
      <li class="im-tab-item col-4">
        <a
          class="im-tab-link d-flex active"
          id="pills-beneficiary-tab"
          data-toggle="pill"
          href="#pills-beneficiary"
          role="tab"
          aria-controls="pills-beneficiary"
          aria-selected="true"
        >
          <div class="tab-icon">
            <img
              src="../../../../assets/images/menu-beneficiary.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="tab-text">BENEFICIARIES</div>
        </a>
      </li>
      <li class="im-tab-item col-4">
        <a
          class="im-tab-link d-flex"
          id="pills-petitioners-tab"
          data-toggle="pill"
          href="#pills-petitioners"
          role="tab"
          aria-controls="pills-petitioners"
          aria-selected="false"
        >
          <div class="tab-icon">
            <img
              src="../../../../assets/images/menu-petitions.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="tab-text">PETITIONERS</div>
        </a>
      </li>
      <li class="im-tab-item col-4">
        <a
          class="im-tab-link d-flex"
          id="pills-attorneys-tab"
          data-toggle="pill"
          href="#pills-attorneys"
          role="tab"
          aria-controls="pills-attorneys"
          aria-selected="false"
        >
          <div class="tab-icon">
            <img
              src="../../../../assets/images/menu-attorneys.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="tab-text">LAWFIRMS</div>
        </a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-beneficiary"
        role="tabpanel"
        aria-labelledby="pills-beneficiary-tab"
      >
        <div class="row">
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/h-1b.png"
                  width="45%"
                  style="opacity: 1"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Effective H-1b Application</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-document.png"
                  width="36%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Document storage & management</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-staying-ahead.png"
                  width="29%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Staying Ahead</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-map.png"
                  width="37%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Managing the Journey</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-petitioners"
        role="tabpanel"
        aria-labelledby="pills-petitioners-tab"
      >
        <div class="row">
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/h-1b.png"
                  width="45%"
                  style="opacity: 1"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Effective H-1b Application</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-document.png"
                  width="36%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Document storage & management</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-staying-ahead.png"
                  width="29%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Staying Ahead</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-map.png"
                  width="37%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Managing the Journey</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-attorneys"
        role="tabpanel"
        aria-labelledby="pills-attorneys-tab"
      >
        <div class="row">
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/h-1b.png"
                  width="45%"
                  style="opacity: 1"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Effective H-1b Application</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-document.png"
                  width="36%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Document storage & management</h2>
              </div>
              <br /><br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3 border-right-dashed">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-staying-ahead.png"
                  width="29%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Staying Ahead</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="tab-col-data">
              <div class="h-135">
                <img
                  src="../../../../assets/images/tab-map.png"
                  width="37%"
                  alt=""
                />
              </div>
              <div class="h-50">
                <h2>Managing the Journey</h2>
              </div>
              <br /><br />
              <br />
              <div class="h-150">
                <ul class="tab-content-li">
                  <li>Specialty Occupation</li>
                  <li>Beneficiary Qualifications</li>
                  <li>Employer-Employee Relationship</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="our-tools">
    <div class="heading">
      <h1>OUR TOOLS</h1>
      <h3>
        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor !
      </h3>
    </div>

    <div class="row mb-5">
      <div class="col-9 pr-0">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../../../assets/tools.png"
                alt="Card image cap"
              />
              <div class="card-body row px-2 py-1">
                <div class="col-10">
                  <p class="card-text-tab">
                    Praesent commodo cursus magna, vel consectetur et.
                  </p>
                  <span class="card-text-tab"
                    >Maecenas faucibus mollis interdum. Sed posuere consectetur
                    est at lobortis. Nullam quis risus eget urna mollis ornare
                    vel eu leo.</span
                  >
                </div>
                <div class="col-2">
                  <a
                    href="#"
                    class="im-btn im-btn-primary im-btn-lg learn-more-btn-tab"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../../../assets/tools.png"
                alt="Card image cap"
              />
              <div class="card-body row px-2 py-1">
                <div class="col-10">
                  <p class="card-text-tab">
                    Praesent commodo cursus magna, vel consectetur et.
                  </p>
                  <span class="card-text-tab"
                    >Maecenas faucibus mollis interdum. Sed posuere consectetur
                    est at lobortis. Nullam quis risus eget urna mollis ornare
                    vel eu leo.</span
                  >
                </div>
                <div class="col-2">
                  <a
                    href="#"
                    class="im-btn im-btn-primary im-btn-lg learn-more-btn-tab"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../../../assets/tools.png"
                alt="Card image cap"
              />
              <div class="card-body row px-2 py-1">
                <div class="col-10">
                  <p class="card-text-tab">
                    Praesent commodo cursus magna, vel consectetur et.
                  </p>
                  <span class="card-text-tab"
                    >Maecenas faucibus mollis interdum. Sed posuere consectetur
                    est at lobortis. Nullam quis risus eget urna mollis ornare
                    vel eu leo.</span
                  >
                </div>
                <div class="col-2">
                  <a
                    href="#"
                    class="im-btn im-btn-primary im-btn-lg learn-more-btn-tab"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../../../assets/tools.png"
                alt="Card image cap"
              />
              <div class="card-body row px-2 py-1">
                <div class="col-10">
                  <p class="card-text-tab">
                    Praesent commodo cursus magna, vel consectetur et.
                  </p>
                  <span class="card-text-tab"
                    >Maecenas faucibus mollis interdum. Sed posuere consectetur
                    est at lobortis. Nullam quis risus eget urna mollis ornare
                    vel eu leo.</span
                  >
                </div>
                <div class="col-2">
                  <a
                    href="#"
                    class="im-btn im-btn-primary im-btn-lg learn-more-btn-tab"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-report"
            role="tabpanel"
            aria-labelledby="v-pills-report-tab"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../../../assets/tools.png"
                alt="Card image cap"
              />
              <div class="card-body row px-2 py-1">
                <div class="col-10">
                  <p class="card-text-tab">
                    Praesent commodo cursus magna, vel consectetur et.
                  </p>
                  <span class="card-text-tab"
                    >Maecenas faucibus mollis interdum. Sed posuere consectetur
                    est at lobortis. Nullam quis risus eget urna mollis ornare
                    vel eu leo.</span
                  >
                </div>
                <div class="col-2">
                  <a
                    href="#"
                    class="im-btn im-btn-primary im-btn-lg learn-more-btn-tab"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 pl-0">
        <div
          class="nav flex-column nav-pills border-right"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link active"
            id="v-pills-home-tab"
            data-toggle="pill"
            href="#v-pills-home"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            <p>Petition Builder</p>
            <span>Vestibulum id ligula porta felis euismod semper.</span>
          </a>
          <a
            class="nav-link"
            id="v-pills-profile-tab"
            data-toggle="pill"
            href="#v-pills-profile"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <p>Comprehensive Dashboard</p>
            <span>Vestibulum id ligula porta felis euismod semper.</span>
          </a>
          <a
            class="nav-link"
            id="v-pills-messages-tab"
            data-toggle="pill"
            href="#v-pills-messages"
            role="tab"
            aria-controls="v-pills-messages"
            aria-selected="false"
          >
            <p>Notifications and Alerts</p>
            <span>Vestibulum id ligula porta felis euismod semper.</span>
          </a>
          <a
            class="nav-link"
            id="v-pills-settings-tab"
            data-toggle="pill"
            href="#v-pills-settings"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            <p>Case Management</p>
            <span>Vestibulum id ligula porta felis euismod semper.</span>
          </a>
          <a
            class="nav-link"
            id="v-pills-report-tab"
            data-toggle="pill"
            href="#v-pills-report"
            role="tab"
            aria-controls="v-pills-report"
            aria-selected="false"
          >
            <p>Detailed Reports</p>
            <span>Vestibulum id ligula porta felis euismod semper.</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="testimonials">
    <div class="heading">
      <h1>What people are saying about Imagility</h1>
      <h3>Don’t just take it from us, let our customers do the talking!</h3>
    </div>

    <div class="testimonial-slider my-4">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let img of images; let i=index">
          <ng-template carouselSlide [id]="i">
            <div class="item">
              <div class="mx-3 align-content-center">
                <div
                  class="bg-light position-relative radius-3 px-3 my-4 text-center py-5"
                >
                  <div class="px-3 text-center pb-3">
                    <p class="font-weight-light my-3">
                      Lorem ipsum dolor sit consectetur adipisicing elit. Alias
                      amet deleniti et fugit iusto nesciunt.
                    </p>
                  </div>
                  <div>
                    <div
                      class="font-weight-bold circle rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white mt-4"
                      style="width: 60px; height: 60px"
                    >
                      <img
                        class="rounded-circle"
                        src="{{ img }}"
                        alt=""
                        height="55px"
                        width="55px"
                      />
                    </div>
                    <div class="text-center">
                      <h5
                        class="font-weight-bold mt-4 mb-0 testimonials-name"
                        style="color: #9b5de5"
                      >
                        Ramin JD
                      </h5>
                      <small class="font-weight-bold testimonials-role"
                        >Manager</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

      <!-- <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let img of images; let i=index">
          <ng-template carouselSlide [id]="i">
          <div class="mx-3 align-content-center">
            <div
              class="bg-light position-relative radius-3 px-3 my-4 text-center py-5"
            >
              <div class="px-3 text-center pb-3">
                <p class="font-weight-light my-3">
                  Lorem ipsum dolor sit consectetur adipisicing elit. Alias amet
                  deleniti et fugit iusto nesciunt.
                </p>
              </div>
              <div>
                <div
                  class="font-weight-bold circle rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white mt-4"
                  style="width: 60px; height: 60px"
                >
                  <img
                    class="rounded-circle"
                    src="{{ img }}"
                    alt=""
                    height="55px"
                    width="55px"
                  />
                </div>
                <div class="text-center">
                  <h5
                    class="font-weight-bold mt-4 mb-0 testimonials-name"
                    style="color: #9b5de5"
                  >
                    Ramin JD
                  </h5>
                  <small class="font-weight-bold testimonials-role"
                    >Manager</small
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        </ng-container>
      </owl-carousel-o> -->
    </div>
  </div>

  <div class="resources">
    <div class="heading">
      <h1>Imagility Sources</h1>
      <h3>
        Imagility integrates data from reliable sources to provide authentic
        guidance
      </h3>
    </div>

    <div class="row mt-5">
      <div class="col-4">
        <div class="card p-3">
          <img
            src=""
            class="card-img-top"
            alt="http://via.placeholder.com/250"
          />
          <!-- <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div> -->
        </div>
        <div class="content">
          <h3>FLC Data Center</h3>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-3">
          <img
            src=""
            class="card-img-top"
            alt="http://via.placeholder.com/250"
          />
          <!-- <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div> -->
        </div>
        <div class="content">
          <h3>O*NET On Line</h3>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-3">
          <img
            src=""
            class="card-img-top"
            alt="http://via.placeholder.com/250"
          />
          <!-- <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div> -->
        </div>
        <div class="content">
          <h3>USCIS</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="landing-footer">
    <div class="row">
      <div class="col-3 p-5 border-right-dashed">
        <h5 class="heading">
          <img
            src="../../../../assets/weblogo.png"
            width="100px"
            alt="Imagility Logo"
          />
        </h5>
        <p class="footer-content">
          Seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.
          consectetur pisicin elit, sed do eiusmod tempor.
        </p>
        <div class="footer-icons">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 112.196 112.196"
              xmlns:v="https://vecta.io/nano"
            >
              <circle cx="56.098" cy="56.098" r="56.098" fill="#3b5998" />
              <path
                d="M70.2 58.294h-10v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34c0-5.964 2.833-15.303 15.3-15.303l11.234.047v12.5h-8.15c-1.337 0-3.217.668-3.217 3.513v7.585h11.334L70.2 58.294z"
                fill="#fff"
              />
            </svg>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 291.319 291.319"
              xmlns:v="https://vecta.io/nano"
            >
              <path
                d="M145.66 0c80.45 0 145.66 65.22 145.66 145.66s-65.2 145.66-145.66 145.66S0 226.1 0 145.66 65.2 0 145.66 0z"
                fill="#0e76a8"
              />
              <path
                d="M82.08 200.136h27.275v-90.9H82.08v90.9zm106.26-94.06c-13.237 0-25.08 4.834-33.483 15.504v-12.654H127.48v91.2h27.375v-49.324c0-10.424 9.55-20.593 21.512-20.593s14.912 10.17 14.912 20.338v49.57h27.275v-51.6c-.001-35.842-16.97-42.45-30.216-42.45zm-92.75-5.936c7.538 0 13.656-6.118 13.656-13.656S103.127 72.83 95.59 72.83s-13.656 6.118-13.656 13.656S88.05 100.14 95.59 100.14z"
                fill="#fff"
              />
            </svg>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 112.197 112.197"
              xmlns:v="https://vecta.io/nano"
            >
              <circle cx="56.099" cy="56.098" r="56.098" fill="#55acee" />
              <path
                d="M90.46 40.316c-2.404 1.066-5 1.787-7.702 2.11 2.77-1.66 4.894-4.284 5.897-7.417-2.59 1.537-5.462 2.652-8.515 3.253-2.446-2.605-5.93-4.233-9.8-4.233-7.404 0-13.41 6.005-13.41 13.41 0 1.05.12 2.074.35 3.056-11.144-.56-21.025-5.897-27.64-14.012a13.35 13.35 0 0 0-1.816 6.742c0 4.65 2.37 8.757 5.965 11.16-2.197-.07-4.266-.672-6.073-1.68l-.001.17a13.42 13.42 0 0 0 10.757 13.147 13.36 13.36 0 0 1-3.532.471c-.866 0-1.705-.083-2.523-.24 1.706 5.326 6.657 9.203 12.526 9.312-4.6 3.597-10.37 5.74-16.655 5.74a27 27 0 0 1-3.197-.188 37.93 37.93 0 0 0 20.553 6.025C70.32 87.143 83.808 66.71 83.808 49a38.35 38.35 0 0 0-.039-1.734 27.19 27.19 0 0 0 6.692-6.94h0z"
                fill="#f1f2f2"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="col-3 border-right-dashed">
        <h5 class="heading">Contact Us</h5>
        <p class="footer-content">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore
        </p>
        <div class="footer-icons">
          <span></span>
        </div>
      </div>
      <div class="col-3 border-right-dashed">
        <h5 class="heading">Our Tools</h5>
        <p class="footer-content">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore
        </p>
        <div class="footer-icons">
          <span></span>
        </div>
      </div>
      <div class="col-3">
        <h5 class="heading">Want to join us?</h5>
        <p class="footer-content">
          <a
            routerLink="/login/signup"
            class="im-btn im-btn-primary text-white im-btn-lg"
            >SIGN UP</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
