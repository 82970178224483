import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { updateStepStatus, updateStepStatusToInitialState } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { PrimaryData, TaskStep, FileUploadConfiguration } from 'app-models';
import * as errorUtils from 'error-message-utility';
import { ProvideEerService } from '../provide-eer/provide-eer.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-client-project-documents',
  templateUrl: './client-project-documents.component.html',
  styleUrls: ['./client-project-documents.component.scss']
})
export class ClientProjectDocumentsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('clientProjectDocumentForm', { static: true }) clientProjectDocumentForm: NgForm;

  @Input() stepDetails: TaskStep;
  @Input() primaryData: PrimaryData;
  @Input() fileUploaderName: string;
  @Input() documentTypeDropdownCode: string;
  @Input() projectId: number;

  fileUploadUSConfigurations: FileUploadConfiguration;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  observableSubscription$ = new Subject();
  updateMasterData = false;
  filesCount: number;
  wizardTID:string='';

  constructor(
    private store: Store<any>,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private provideEerService: ProvideEerService,
    private toastr: ToastrService,
  ) {
    this.disabledControls = false;
  }

  ngOnInit(): void {
    // combineLatest([
    //   this.store.pipe(select(getStepStatusUpdate)),
    //   this.store.pipe(select(getVisaTypeCode)),
    // ])
    //   .pipe(takeUntil(this.observableSubscription$)).subscribe((response: any[]) => {
    //     if (response[0] && response[0].status === 200 && response[1]) {
    //       this.toastr.success(response[0].message);
    //       this.store.dispatch(loadSubTaskDetails({
    //         taskId: this.stepDetails.taskId,
    //         visaTypeCode: response[1]
    //       }));
    //     }
    //   });
    // this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((data: TaskStep) => {
    //   });
    this.clientProjectDocumentForm.form.valueChanges.pipe(
      map(() => this.clientProjectDocumentForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documentTypeDropdownCode && changes.fileUploaderName) {
      this.updateMasterData = false;
    }
    this.wizardTID =sessionStorage.getItem('wizardTID');
    if(!this.wizardTID){
      this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
    }
    this.toggleControlsStatus();
  }

  onUpdateMaster() {
    this.updateMasterData = false;
    this.provideEerService.onUpdateMaster(`/company/${this.primaryData.companyId}/update/masterData/document/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/category/${this.documentTypeDropdownCode}/entity/${this.projectId}`)
      .pipe(take(1))
      .subscribe();
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    if(!this.wizardTID){
      this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    }
    this.fileUploadUSConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: `/${this.primaryData.visatype}/petitionType/${this.primaryData.petitionType}/group/${this.documentTypeDropdownCode}/documentTypes`,
      getApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${this.documentTypeDropdownCode}/`,
      postApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${this.documentTypeDropdownCode}/fileCategory/`,
      deleteApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/`,
      documentTypeDropdownCode: this.documentTypeDropdownCode
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  syncDocuments() {
    this.provideEerService.getMasterDataDocument(this.primaryData.caseId, this.projectId, this.documentTypeDropdownCode)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(response => {
        if (response && response.data) {
          this.fileUploadUSConfigurations = response.data ? response.data : null;
          this.toastr.success(response.message, 'Success');
          this.toggleControlsStatus();
        }
      });
  }

  onfileListCount(fileCount: number) {
    this.filesCount = fileCount;
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
