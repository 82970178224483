<div mat-dialog-title class="d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Snooze</h4>
  <i class="fa fa-close pointer" (click)="closeSnooze()"></i>
</div>

<mat-dialog-content class="mat-typography snooze-details">
  <div class="loading-wrapper" *ngIf="loading">
    <span> Loading...</span>
  </div>
  <div *ngIf="!loading">
    <div class="snoozes-wrap">
      <h3><b>Message:</b> {{snooze.reminderDesc}}</h3>
      <hr>
      <div class="d-flex align-items-center justify-content-start">
        <label>Remind me after</label>
        <p-dropdown [options]="snoozeDdValues" class="p-ml-2" [(ngModel)]="remindAfter" placeholder="Select Time Interval" optionLabel="name" [showClear]="true"></p-dropdown>
        <button class="btn btn-sm btn-primary ml-2" style="height: 36px;" (click)="updateSnooze()">Snooze</button>
      </div>
      <div class="row mt-3 bg-light p-3">
        <div class="col-sm-12">
          <b>Lead Details:</b>
        </div>
        <div class="col-sm-6">
          <table class="table table-sm bg-white">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{{leadDetails.firstName}} {{leadDetails.middleName}} {{leadDetails.lastName}}</td>
              </tr>
              <tr>
                <th>Company</th>
                <td>{{leadDetails.companyName}}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{leadDetails.email}}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{leadDetails.phoneContacts}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6">
          <table class="table bg-white table-sm">
            <tbody>
              <tr>
                <th>Lead Source</th>
                <td>{{leadDetails.sourceName}}</td>
              </tr>
              <tr>
                <th>Lead Status</th>
                <td>{{leadDetails.statusName}}</td>
              </tr>
              <tr>
                <th>Lead Type</th>
                <td>{{leadDetails.leadTypeName}}</td>
              </tr>
              <tr>
                <th>Lead Level</th>
                <td>{{leadDetails.leadLevelName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-12">
          <table class="table bg-white table-sm">
            <tbody>
              <tr>
                <th style="width:155px">Lead Message</th>
                <td class="text-left w-auto">{{leadDetails.message}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <hr>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="d-flex align-items-center justify-content-end">
  <button class="mt-2 btn btn-primary" (click)="closeSnooze()">Close</button>
</mat-dialog-actions>
