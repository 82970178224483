import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { select, Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { first, map, take, takeUntil } from 'rxjs/operators';

import { PrimaryData, TaskStep, ReferenceLibrary, StateObj } from 'app-models';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { combineLatest, Subject } from 'rxjs';
import { getStepDetails, updateStepStatus, updateStepStatusToInitialState } from 'visa-store';
import { EnterProcessingInformationService } from './enter-processing-information.service';
import { ReferenceLibraryService } from 'reference-library';
import { Validators } from '@angular/forms';
import { CacheCountryListService } from 'cache-country-list';
import * as moment from 'moment';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';

@Component({
  selector: 'app-enter-processing-information',
  templateUrl: './enter-processing-information.component.html',
  styleUrls: ['./enter-processing-information.component.scss']
})
export class EnterProcessingInformationComponent implements OnInit, OnDestroy {
  primaryData: PrimaryData;
  stepDetails: TaskStep;
  enterProcessingInformationForm: FormGroup;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  observableSubscription$ = new Subject();
  childTypeList: ReferenceLibrary[];
  initialRefLibrary: ReferenceLibrary = {
    code: null,
    desc: null,
    group: null,
    id: null,
    name: 'Select'
  };
  titleList: ReferenceLibrary[];
  resultList: ReferenceLibrary[];
  stateList: StateObj[];
  initialStateObj: StateObj = {
    createdDate: null,
    createdId: null,
    modifiedDate: null,
    modifiedId: null,
    sequenceNumber: null,
    stateProvinceCode: null,
    stateProvinceName: 'Select'
  };
  toYear: string;
  fromYear: string;
  currentDate = new Date();
  sectionCode: string;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private guard: DirtyFormGuard,
    private store: Store<VisaExtendedState>,
    private enterProcessingInformationService: EnterProcessingInformationService,
    public referenceLibraryService: ReferenceLibraryService,
    public cacheCountryService: CacheCountryListService,
    public stepStatusUpdatePayloadService: StepStatusUpdatePayloadService
  ) {
    this.toYear = moment().format('YYYY');
    this.fromYear = moment().subtract(99, 'years').format('YYYY');
    this.primaryData = this.dynamicDialogConfig.data;
  }

  ngOnInit() {
    this.enterProcessingInformationForm = new FormGroup({
      gcPrevPetitionFiledDetailId: new FormControl(null),
      childTypeCode: new FormControl(null),
      isAdopted: new FormControl(this.primaryData.immigrationClassification === 'F4' ? false : null),
      lawFulPermanentRsdOrCitznAdop: new FormControl(false),
      hasPetFileBen: new FormControl(null, Validators.required),
      hasPetFileBenOrAlien: new FormControl(null, Validators.required),
      title: new FormControl(null),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      middleName: new FormControl(null),
      city: new FormControl(null),
      stateProvinceCode: new FormControl(null),
      resultCode: new FormControl(null),
      filingDate: new FormControl(null)
    }, [
      this.childTypeCodeFieldRequired.bind(this),
      this.titleFieldRequired,
      this.resultFieldRequired,
      this.stateProvinceCodeFieldRequired,
      this.firstNameFieldRequired,
      this.lastNameFieldRequired,
      this.cityFieldRequired,
      this.filingDateFieldRequired
    ]);
    // work around for primeng radio button
    this.enterProcessingInformationForm.get('childTypeCode').valueChanges.subscribe(e => {
      this.enterProcessingInformationForm.get('childTypeCode').setValue(e, { emitEvent: false });
    });
    // work around for primeng radio button
    this.enterProcessingInformationForm.get('hasPetFileBen').valueChanges.subscribe(e => {
      this.enterProcessingInformationForm.get('hasPetFileBen').setValue(e, { emitEvent: false });
    });
    // work around for primeng radio button
    this.enterProcessingInformationForm.get('hasPetFileBenOrAlien').valueChanges.subscribe(e => {
      this.enterProcessingInformationForm.get('hasPetFileBenOrAlien').setValue(e, { emitEvent: false });
    });
    this.enterProcessingInformationForm.valueChanges.pipe(
      map(() => this.enterProcessingInformationForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$))
      .subscribe(dirty => {
        if (dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.sectionCode = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'PROC_INFO' : 'BEN_PROC_INFO';
        this.stepDetails = data;
        this.disabledControls =
          this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW' || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
          this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
        if (this.disabledControls) {
          this.enterProcessingInformationForm.disable();
        } else {
          this.enterProcessingInformationForm.enable();
        }
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      });

    combineLatest([
      this.referenceLibraryService.getReferenceData('CHILDTYP'),
      this.referenceLibraryService.getReferenceData('TITL'),
      this.referenceLibraryService.getReferenceData('VSAOUT'),
      this.referenceLibraryService.getStatesFromStore('USA')
    ])
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data[0]) {
          this.childTypeList = data[0];
        }
        if (data[1]) {
          (data[1] as ReferenceLibrary[]).unshift(this.initialRefLibrary);
          this.titleList = data[1];
        }
        if (data[2]) {
          (data[2] as ReferenceLibrary[]).unshift(this.initialRefLibrary);
          this.resultList = data[2];
        }
        if (data[3]) {
          (data[3] as StateObj[]).unshift(this.initialStateObj);
          this.stateList = data[3];
          this.getEnterProcessingInformationDetails();
        } else {
          this.referenceLibraryService.getStatesFromApi('USA')
            .pipe(take(1))
            .subscribe((states: any[]) => {
              (states as StateObj[]).unshift(this.initialStateObj);
              this.stateList = states;
              this.getEnterProcessingInformationDetails();
            });
        }
      });
  }

  onPrevPetitionFiled() {
    this.enterProcessingInformationForm.patchValue({
      title: null,
      firstName: null,
      lastName: null,
      middleName: null,
      city: null,
      stateProvinceCode: null,
      filingDate: null,
      resultCode: null
    });
  }

  childTypeCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.primaryData.immigrationClassification &&
      ['IR2', 'IR5', 'F1', 'F2A', 'F2B', 'F3'].includes(this.primaryData.immigrationClassification) &&
      !group.value.childTypeCode) {
      return { childTypeCodeFieldIsRequired: true };
    }
    return null;
  }

  titleFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      !group.value.title) {
      return { titleFieldIsRequired: true };
    }
    return null;
  }

  resultFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      !group.value.resultCode) {
      return { resultFieldIsRequired: true };
    }
    return null;
  }

  stateProvinceCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      !group.value.stateProvinceCode) {
      return { stateProvinceCodeFieldIsRequired: true };
    }
    return null;
  }

  firstNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      (!group.value.firstName || (group.value.firstName && group.value.firstName === ''))) {
      return { firstNameFieldIsRequired: true };
    }
    return null;
  }

  lastNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      (!group.value.lastName || (group.value.lastName && group.value.lastName === ''))) {
      return { lastNameFieldIsRequired: true };
    }
    return null;
  }

  cityFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      (!group.value.city || (group.value.city && group.value.city === ''))) {
      return { cityFieldIsRequired: true };
    }
    return null;
  }

  filingDateFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasPetFileBenOrAlien !== null &&
      group.value.hasPetFileBenOrAlien !== undefined &&
      group.value.hasPetFileBenOrAlien === 1 &&
      !group.value.filingDate) {
      return { filingDateFieldIsRequired: true };
    }
    return null;
  }

  getEnterProcessingInformationDetails() {
    this.enterProcessingInformationService.onGetEnterProcessingInformationDetails(this.primaryData)
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          this.enterProcessingInformationForm.patchValue({
            gcPrevPetitionFiledDetailId: data.gcPrevPetitionFiledDetailId,
            childTypeCode: data.childTypeReferenceLibraryDTO ? data.childTypeReferenceLibraryDTO.code : null,
            isAdopted: data.isAdopted !== null && data.isAdopted !== undefined ? data.isAdopted === 1 : false,
            lawFulPermanentRsdOrCitznAdop: data.lawFulPermanentRsdOrCitznAdop !== null && data.lawFulPermanentRsdOrCitznAdop !== undefined ?
              data.lawFulPermanentRsdOrCitznAdop === 1 : null,
            hasPetFileBen: data.hasPetFileBen !== null && data.hasPetFileBen !== undefined ? data.hasPetFileBen : null,
            hasPetFileBenOrAlien: data.hasPetFileBenOrAlien !== null && data.hasPetFileBenOrAlien !== undefined ?
              data.hasPetFileBenOrAlien : null,
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            city: data.city,
            stateProvinceCode: data.stateProvinceCode,
            resultCode: data.resultReferenceLibraryDTO ? data.resultReferenceLibraryDTO.code : null,
            filingDate: data.filingDate ? new Date(data.filingDate) : null,
          });
        } else {
          this.enterProcessingInformationForm.patchValue({
            gcPrevPetitionFiledDetailId: null,
            childTypeCode: null,
            isAdopted: this.primaryData.immigrationClassification === 'F4' ? false : null,
            lawFulPermanentRsdOrCitznAdop: false,
            hasPetFileBen: null,
            hasPetFileBenOrAlien: null,
            title: null,
            firstName: null,
            lastName: null,
            middleName: null,
            city: null,
            stateProvinceCode: null,
            resultCode: null,
            filingDate: null
          });
        }
        this.enterProcessingInformationForm.reset(this.enterProcessingInformationForm.value);
      });
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  onSubmit() {
    const payload = {
      isAdopted: this.enterProcessingInformationForm.get('isAdopted').value !== null ?
        (this.enterProcessingInformationForm.get('isAdopted').value ? 1 : 0) : null,
      childTypeCode: this.enterProcessingInformationForm.get('childTypeCode').value,
      lawFulPermanentRsdOrCitznAdop: this.enterProcessingInformationForm.get('lawFulPermanentRsdOrCitznAdop').value !== null ?
        (this.enterProcessingInformationForm.get('lawFulPermanentRsdOrCitznAdop').value ? 1 : 0) : 0,
      hasPetFileBen: this.enterProcessingInformationForm.get('hasPetFileBen').value,
      hasPetFileBenOrAlien: this.enterProcessingInformationForm.get('hasPetFileBenOrAlien').value,
      title: this.enterProcessingInformationForm.get('title').value,
      firstName: this.enterProcessingInformationForm.get('firstName').value,
      lastName: this.enterProcessingInformationForm.get('lastName').value,
      middleName: this.enterProcessingInformationForm.get('middleName').value,
      city: this.enterProcessingInformationForm.get('city').value,
      stateProvinceCode: this.enterProcessingInformationForm.get('stateProvinceCode').value,
      resultCode: this.enterProcessingInformationForm.get('resultCode').value,
      filingDate:
        this.enterProcessingInformationForm.get('filingDate').value ?
          moment(this.enterProcessingInformationForm.get('filingDate').value).format('YYYY-MM-DD') : null,
      gcPrevPetitionFiledDetailId: this.enterProcessingInformationForm.get('gcPrevPetitionFiledDetailId').value
    };

    this.enterProcessingInformationService.onSaveEnterProcessingInformationDetails(this.primaryData, payload)
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
          this.getEnterProcessingInformationDetails();
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(
      updateStepStatus({
        payload: this.stepStatusUpdatePayloadService.payloadConstruction(this.stepDetails, status),
        subTaskId: this.stepDetails.taskId,
        visaType: this.primaryData.caseType,
        stepId: this.primaryData.stepId
      }));
  }

  handleCancel() {
    this.getEnterProcessingInformationDetails();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
