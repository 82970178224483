import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import moment from "moment";
import { Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  PayloadRequest,
  TableValidationNumber,
} from "src/app/data/models/referenceLibrary.model";
import { LookupService } from "src/app/services/lookup-data.service";
import { TaskAllocationService } from "src/app/services/task-allocation.service";
import { CustomQuestionnaireService } from "../../custom-questionaire/services/custom-questionnaire.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-step-task-allocation",
  templateUrl: "./step-task-allocation.component.html",
  styleUrls: ["./step-task-allocation.component.scss"],
})
export class StepTaskAllocationComponent implements OnInit {
  @Input("isAssignTo") isAssignTo: boolean; //stepTimeLineDetails newTaskForm
  @Input("stepTimeLineDetails") stepTimeLineDetails: any;
  @Input("parentFormGroup") parentFormGroup: FormGroup;
  @Input("taskObj") taskObj: any = null;
  @Input("fromTemplate") fromTemplate: boolean = false;
  @Output() stepTaskTimelineListDetails = new EventEmitter<any>();
  @Input("subTaskType") subTaskType = null;
  @Input("taskTypeId") taskTypeId = null;

  @Input("index") index;

  userType = "";
  hours: string = "HOUR";
  taskduetDate: string;
  public stepLineDetailsData: any;
  public visaType: string = "";
  public payload: PayloadRequest;
  stepTaskTimelineList = [];
  customStepTaskTimelineList = [];
  assignToList = [];
  beneficiaryParamPayload = {
    pageNumber: 1,
    recordsPerPage: 100,
    searchCriteria: "beneficiary",
    searchText: "",
    sortBy: "companyName",
    sortOrder: "asc",
    status: ["ACTIVE"], // IM-3352 (IM-3335) user story
  };
  beneficiaryList: any[] = [];
  benefiacityTask;
  minimumDate;
  disabled: boolean = true;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() taskDateSelected = new EventEmitter<string>();
  taskDate: string;

  public immgrntClassification: string;
  public companyId = parseInt(sessionStorage.getItem("companyId"));

  readonly stateEnum: typeof TableValidationNumber = TableValidationNumber;
  completionTimesList = [
    { code: "DAY", name: "DAY" },
    { code: "HOUR", name: "HOUR" },
  ];
  selectedPetetionPreprarer: any;
  companySelectedInParentForm;

  observableSubscription$ = new Subject();
  dueDateForm: FormGroup;
  constructor(
    private lookupService: LookupService,
    private _taskAllocationService: TaskAllocationService,
    private fb: FormBuilder,
    private customQuestionsService: CustomQuestionnaireService,
    private route: ActivatedRoute
  ) {
    this.dueDateForm = this.fb.group({
      taskduetDate: [''],
    })
    this.index = this.route.snapshot.paramMap.get('ti');

    // console.log('constructor ::', this.parentFormGroup);
    // console.log('constructor :: value', this.parentFormGroup.value);
  }

  ngOnInit(): void { // Optional param added to know if clicked on next or save. if Next Custom StepList should not be sent in request.
    this.minimumDate = new Date();
    this.userType = sessionStorage.getItem("userTypeRole");
    // this.customQuestionsService.getCustomStepsDetails(this.companyId, this.taskTypeId).subscribe((masterRes: any) => {
      // this.customStepTaskTimelineList = masterRes.customStepList;
    this.getTaskTimeLineDetails(this.index);
    console.log('duedateObj', this.taskObj.taskDueDate);
    if (this.taskObj.taskDueDate) {
      this.dueDateForm = this.fb.group({
        taskduetDate: [moment(this.taskObj.taskDueDate).format('MM/DD/YYYY')],
      })
    }
  // })
    //this.getBeneficiaryList(this.parentFormGroup.value.companyId);
    //this.getBeneficiaryList();
  }

  taskDueDateSelected(sd) {
    this.messageEvent.emit(sd.value);
    this.taskDate = sd.value;
    this.taskDateSelected.emit(sd.value);
  }



  // getBeneficiaryList() {
  //   if(this.parentFormGroup){
  //     let selectedCompany = (this.parentFormGroup.value?.immCategoryCode === 'GCFLY') ? this.parentFormGroup.value.attorneyId : this.parentFormGroup.value.companyId;
  //     this.apiService
  //       .getBeneficiariesByCompanyId(selectedCompany, this.beneficiaryParamPayload)
  //       .subscribe((data: Array<any>) => {
  //         if (data) {
  //           this.beneficiaryList = data;
  //           this.beneficiaryList.forEach((e) => {
  //             e.beneficiaryEmployeeFullName =
  //               e?.beneficiaryEmployeeFName + " " + e?.beneficiaryEmployeeLName;
  //           });
  //         }
  //       });
  //   }

  // }

  getValidBeneEmployeeList(selectedCompany, selectedBene) {
    this.lookupService
      .getValidBeneEmployeeCompany(selectedCompany)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data) => {
        if (data) {
          this.beneficiaryList = data.filter(ele => ele.userType !== 'Beneficiary' || (ele.userType === 'Beneficiary' && ele.beneficiaryEmployeeId === selectedBene));
          this.beneficiaryList.forEach((e) => {
            e.beneficiaryEmployeeFullName =
              e?.beneficiaryEmployeeFName + " " + e?.beneficiaryEmployeeLName;
          });
        }
      });
  }

  getTaskTimeLineDetails(index?) {
    if (this.isAssignTo) {
      if (this.stepTimeLineDetails.value.visaType) {
        this.visaType = this.stepTimeLineDetails.value.visaType;
        this.immgrntClassification = null; // Hardcoded value for the time being
      } else {
        this.visaType = this.stepTimeLineDetails.value.immCategoryCode;
        this.immgrntClassification =
          this.stepTimeLineDetails.value.immClassificationCode; //"EB1EXEC";  // Hardcoded value for the time being
      }
      this.payload = {
        immigrationCategory: this.stepTimeLineDetails.value.immCategoryCode,
        immigrationClassification: this.immgrntClassification, // Hardcoded value for the time being
        //this.stepTimeLineDetails.value.immClassificationCode,
        immigrationType: "VISA",
        parentStepId: 0,
        petitionType: this.stepTimeLineDetails.value.petitionType,
        taskType: this.stepTimeLineDetails.value.subTaskType,
        superTaskType: this.stepTimeLineDetails.value.superTaskTypeCode,
        visaType: this.stepTimeLineDetails.value.visaType,
        companyId: this.stepTimeLineDetails.value.companyId,
        taskTypeId: this.stepTimeLineDetails.value.taskTypeId
      };
      this._taskAllocationService
        .postTaskTimelineDetails(this.visaType, this.payload)
        .subscribe((res: any) => {
          this.stepLineDetailsData = res;
          this.stepTaskTimelineList = res?.data?.stepList.filter(d => !d.isCustomStep) || [];
          // this.customStepTaskTimelineList = index ? (res?.data?.customStepList || []) : [];
          this.stepTaskTimelineList.forEach((e) => {
            if (e.durationUnit === null) {
              e.durationUnit = "DAY";
            }
          });
          if (this.customStepTaskTimelineList.length > 0) {
            this.customStepTaskTimelineList.forEach((e) => {
              if (e.durationUnit === null) {
                e.durationUnit = "DAY";
              }
            });
          }
          this.stepTaskTimelineListDetails.next(this.stepLineDetailsData);
        });
    } else {
      if (this.parentFormGroup && !this.fromTemplate) {
        let selectedCompany = (this.parentFormGroup.value?.immCategoryCode === 'GCFLY') ? this.parentFormGroup.value.attorneyId : this.parentFormGroup.value.companyId;

        if (this.userType == 'Attorney') {
          if (selectedCompany == 0) {
            selectedCompany = this.parentFormGroup.value.attorneyId
          }
        }
        let selectedBeneficiary = (this.parentFormGroup.value?.beneficiaryId) ? this.parentFormGroup.value.beneficiaryId : (this.parentFormGroup.value.applicantId) ? this.parentFormGroup.value.applicantId : (this.parentFormGroup.value.sponsorId) ? this.parentFormGroup.value.sponsorId : null;
        this.buildAssignToList((selectedCompany === this.parentFormGroup.value.attorneyId ? null : this.parentFormGroup.value.companyId), this.parentFormGroup.value.attorneyId);
        this.getValidBeneEmployeeList(selectedCompany, selectedBeneficiary);
        if (this.parentFormGroup.value.visaType) {
          this.visaType = this.parentFormGroup.value.visaType;
        } else {
          this.visaType = this.parentFormGroup.value.immCategoryCode;
        }
        this.payload = {
          immigrationCategory: this.parentFormGroup.value.immCategoryCode,
          immigrationClassification:
            this.parentFormGroup.value.immClassificationCode,
          immigrationType: "VISA",
          parentStepId: 0,
          petitionType: this.parentFormGroup.value.petitionType,
          taskType: this.parentFormGroup.value.taskTypeCode,
          superTaskType: this.parentFormGroup.value.superTaskTypeCode,
          visaType: this.parentFormGroup.value.visaType,
          companyId: selectedCompany//this.companyId,
        };
        this._taskAllocationService
          .postTaskTimelineDetails(this.visaType, this.payload)
          .subscribe((res: any) => {
            this.stepLineDetailsData = res;
            this.stepTaskTimelineList = res?.data?.stepList.filter(d => !d.isCustomStep) || [];

    this.customQuestionsService.getCustomStepsDetails(this.companyId, res?.data.taskTypeId).subscribe((masterRes: any) => {
      this.customStepTaskTimelineList = masterRes.customStepList;
    //  let custSteps = masterRes.customStepList;
    // for (var i = 0; i < custSteps.length; i++) {
    //   let allindex = this.stepTaskTimelineList.filter(x => x.stepName === custSteps[i].previousStepName);
    //   let index = this.stepTaskTimelineList.findIndex(x => x.stepName === custSteps[i].previousStepName);
    //   custSteps[i]['isCustomStep'] = true;
    //   if(custSteps[i].completionStatus === "PENDING"){
    //     custSteps[i].stepStatusCode = "INPROGRESS";
    //     custSteps[i].stepStatusName = "In progress";
    //   }
    //   custSteps[i].stepPosition === 'before'?'':index = allindex.length>1?index+2:index+1
    //   this.stepTaskTimelineList.splice(index, 0, custSteps[i]);
    // }

            // this.customStepTaskTimelineList = res?.data?.customStepList || [];

            // this.customStepTaskTimelineList = index ? (res?.data?.customStepList || []) : [];
            // this.stepTaskTimelineList
            //   .filter((e) => e.isBeneficiaryAction === 1)
            //   .map(
            //     (e) =>
            //       (e.assignToBeneficiary =
            //         this.parentFormGroup.value.beneficiaryId)
            //   );
            this.stepTaskTimelineList.forEach((e) => {
              if (e.durationUnit === null) {
                e.durationUnit = "DAY";
              }
              if (e.duration === null) {
                e.duration = "1";
              }
              if (e.isBeneficiaryAction === 1) {
                e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
              }
            });
            if (this.customStepTaskTimelineList.length > 0) {
              this.customStepTaskTimelineList.forEach((e) => {
                if (e.durationUnit === null) {
                  e.durationUnit = "DAY";
                }
                if (e.duration === null) {
                  e.duration = "1";
                }
                if (e.isBeneficiaryAction === 1) {
                  e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
                }
              });
            }
            this.stepTaskTimelineListDetails.next(this.stepTaskTimelineList);
          });
          });
      } else if (this.parentFormGroup && this.fromTemplate) {
        let formObj: any = {};
        let formData = [];
        Object.keys(this.parentFormGroup.controls).forEach((key, i) => {
          if (key !== 'jsonInput') {
            formData.push(this.parentFormGroup.controls[key].value);
            Object.assign(formObj, this.parentFormGroup.controls[key].value);
          }
        });

        let selectedCompany = (formObj?.immCategoryCode === 'GCFLY') ? formObj.attorneyId : formObj.companyId;
        if (this.userType == 'Attorney') {
          if (selectedCompany == 0) {
            selectedCompany = formObj.attorneyId;
          }
        }
        let selectedBeneficiary = (formObj?.beneficiaryId) ? formObj.beneficiaryId : formObj.applicantId ? formObj.applicantId : formObj.sponsorId ? formObj.sponsorId : null;
        this.buildAssignToList((selectedCompany === formObj.attorneyId ? null : formObj.companyId), formObj.attorneyId);
        this.getValidBeneEmployeeList(selectedCompany, selectedBeneficiary);
        if (formObj.visaType) {
          this.visaType = formObj.visaType;
        } else {
          this.visaType = formObj.immCategoryCode;
        }
        this.payload = {
          immigrationCategory: formObj.immCategoryCode,
          immigrationClassification:
            formObj.immClassificationCode,
          immigrationType: "VISA",
          parentStepId: 0,
          petitionType: formObj.petitionType,
          taskType: (formObj.taskTypeCode) ? formObj.taskTypeCode : this.subTaskType,
          superTaskType: formObj.taskType,
          visaType: formObj.visaType,
          companyId: selectedCompany,//this.companyId,
          taskTypeId: this.taskTypeId
        };
        console.log('form Obj :: ', this.payload)
        this._taskAllocationService
          .postTaskTimelineDetails(this.visaType, this.payload)
          .subscribe((res: any) => {
            this.stepLineDetailsData = res;
            this.stepTaskTimelineList = res?.data?.stepList.filter(d => !d.isCustomStep) || [];
            // this.customStepTaskTimelineList = index ? (res?.data?.customStepList || []) : [];
            // this.stepTaskTimelineList
            //   .filter((e) => e.isBeneficiaryAction === 1)
            //   .map(
            //     (e) =>
            //       (e.assignToBeneficiary =
            //         formObj.beneficiaryId)
            //   );
            this.stepTaskTimelineList.forEach((e) => {
              if (e.durationUnit === null) {
                e.durationUnit = "DAY";
              }
              if (e.duration === null) {
                e.duration = "1";
              }
              if (e.isBeneficiaryAction === 1) {
                e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
              }
            });

            if (this.customStepTaskTimelineList.length > 0) {
              this.customStepTaskTimelineList.forEach((e) => {
                if (e.durationUnit === null) {
                  e.durationUnit = "DAY";
                }
                if (e.duration === null) {
                  e.duration = "1";
                }
                if (e.isBeneficiaryAction === 1) {
                  e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
                }
              });
            }
            this.stepTaskTimelineListDetails.next(this.stepTaskTimelineList);
          });

      } else if (this.taskObj) {
        let selectedCompany = (this.taskObj?.companyId) ? this.taskObj.companyId : this.taskObj.attorneyId;
        let selectedBeneficiary = (this.taskObj?.beneficiaryId) ? this.taskObj.beneficiaryId : this.taskObj.applicantId ? this.taskObj.applicantId : this.taskObj.sponsorId ? this.taskObj.sponsorId : null;
        this.buildAssignToList(this.taskObj?.companyId, this.taskObj.attorneyId);
        this.getValidBeneEmployeeList(selectedCompany, selectedBeneficiary);
        this.stepTaskTimelineList = this.taskObj?.taskStepDetails.filter(d => !d.isCustomStep) || [];
        this.customStepTaskTimelineList = this.taskObj?.taskCustomStepDetails || [];
        this.customStepTaskTimelineList = index ? (this.taskObj?.taskCustomStepDetails || []) : [];
        // if(this.taskObj?.taskCustomStepDetails.length){
        //   let customStepList = this.taskObj?.taskCustomStepDetails;
        //   for (var i = 0; i < customStepList.length; i++) {
        //     let allindex = this.stepTaskTimelineList.filter(x => x.stepName === customStepList[i].previousStepName);
        //     let index = this.stepTaskTimelineList.findIndex(x => x.stepName === customStepList[i].previousStepName);
        //     customStepList[i]['isCustomStep'] = true;
        //     if(customStepList[i].completionStatus === "PENDING"){
        //       customStepList[i].stepStatusCode = "INPROGRESS";
        //       customStepList[i].stepStatusName = "In progress";
        //     }
        //     customStepList[i].stepPosition === 'before'?'':index = allindex.length>1?index+2:index+1
        //     this.stepTaskTimelineList.splice(index, 0, customStepList[i]);
        //   }
        // }
        this.stepTaskTimelineList.forEach((e) => {
          if (e.durationUnit === null) {
            e.durationUnit = "DAY";
          }
          if (e.duration === null) {
            e.duration = "1";
          }
          if (e.isBeneficiaryAction === 1) {
            e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
          }
        });
        this.customStepTaskTimelineList.forEach((e) => {
          if (e.durationUnit === null) {
            e.durationUnit = "DAY";
          }
          if (e.duration === null) {
            e.duration = "1";
          }
          if (e.isBeneficiaryAction === 1) {
            e.assignToBeneficiary = (e.assignToEmployee) ? e.assignToEmployee : e.assignToBeneficiary;
          }
        });
      }
    }
  }

  buildAssignToList(companyId: any, attorneyId: any) {
    if (companyId && attorneyId) {
      forkJoin([
        this.lookupService.getValidUsersOfCompany(companyId),
        this.lookupService.getValidUsersOfCompany(attorneyId)
      ]).pipe(takeUntil(this.observableSubscription$)).subscribe(([data1, data2]) => {
        if (data1 && data2) {
          this.assignToList = data1.concat(data2);
        } else if (data1) {
          this.assignToList = data1;
        } else if (data2) {
          this.assignToList = data2;
        }
        this.assignToList.forEach((e) => {
          e.fullName = e?.firstName + " " + e?.lastName;
        });
      });
    } else {
      const selectedCompanyId = companyId ? companyId : attorneyId;
      this.lookupService
        .getValidUsersOfCompany(selectedCompanyId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((data) => {
          this.assignToList = data;
          this.assignToList.forEach((e) => {
            e.fullName = e?.firstName + " " + e?.lastName;
          });
        });
    }
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  numValidation(param) {
    this.stepLineDetailsData["data"]["stepList"].forEach((element) => {
      if (param.id === element.id) {
        element.duration = param.duration;
      }
    });
    this.stepLineDetailsData["data"]["customStepList"].forEach((element) => {
      if (param.id === element.id) {
        element.duration = param.duration;
      }
    });
    this.stepTaskTimelineListDetails.next(this.stepLineDetailsData);
  }
  onChange(param) {
    this.stepLineDetailsData["data"]["stepList"].forEach((element) => {
      if (param.id === element.id) {
        element.durationUnit = param.durationUnit;
      }
    });
    this.stepLineDetailsData["data"]["customStepList"].forEach((element) => {
      if (param.id === element.id) {
        element.durationUnit = param.durationUnit;
      }
    });
    this.stepTaskTimelineListDetails.next(this.stepLineDetailsData);
  }
  onChangeBene(e, row) {
    let userTypeSelected = this.beneficiaryList.find(item => item.beneficiaryEmployeeId === e.value).userType;
    if (userTypeSelected === 'Beneficiary') {
      row.assignToBeneficiary = e.value;
      row.assignToEmployee = null;
      row.assigneeType = 'Beneficiary';
      row.assignToEmployeeName = null;
    } else {
      row.assignToEmployee = e.value;
      row.assignToBeneficiary = null;
      row.assigneeType = userTypeSelected === 'Attorney' ? 'Attorney' : 'Petitioner';
      row.assignToBeneficiaryName = null;
    }
    row.assignToBeneficiary = e.value;
  }
}
