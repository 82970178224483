import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-edit-complete-education-detail-library',
  templateUrl: './edit-complete-education-detail-library.component.html',
  styleUrls: [
    './edit-complete-education-detail-library.component.scss'
  ]
})
export class EditCompleteEducationDetailLibraryComponent implements OnInit {

  public inputData: any;
  selectedProfileType: string = 'simple';

  get editEducationData() {
    return this.inputData;
  }

  @Input() set editEducationData(value: any) {
    this.inputData = value;
  }

  @Output() onEditClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  closeEdit() {
    this.onEditClose.emit();
  }

  onChangeProfile(event) {
    this.selectedProfileType = this.selectedProfileType === 'simple' ? 'complete': 'simple';
  }

}
