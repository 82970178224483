<div class="row m-4">
    <div class="col-12 px-0">
        <router-outlet></router-outlet>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: #ffffff">Please Wait. </p>
        </ngx-spinner>
        <!-- <app-help-video-overlay #helpVideoComponent></app-help-video-overlay>     -->
        <!-- <button  type="button" class="video-button" mat-icon-button aria-label="Help Video">
        </button> -->
    </div>
</div>