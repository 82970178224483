import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ClientDetailsDialog } from '../client-list/client-list.component';
import { AttorneyService } from 'src/app/services/attorney.service';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

const conditionalEmailValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  const isSendInvite = control.get('isSendInvite')?.value;
  if (isSendInvite) {
    const emailControl = control.get('email');
    return Validators.required(emailControl) || Validators.email(emailControl);
  } else {
    return Validators.email(control.get('email'));
  }
};
@Component({
  selector: 'app-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss'],
  providers: [DynamicDialogConfig]
})


export class AddClientModalComponent {
  inputData;
  clientForm: FormGroup;
  sendInviteChecked: boolean = false;
  companies: any[] = [];
  destroy$ = new Subject<void>();
  emailRequired: boolean;
  isValidFormSubmitted: boolean;



  constructor(
    private fb: FormBuilder,
    private apiService: AttorneyService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<AddClientModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.companies = this.data.companies;
    const emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
    this.clientForm = this.fb.group({
      companyName: ['', Validators.required],
      isSendInvite: [false],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      email: [null, [Validators.required, Validators.pattern(emailRegex)]]
    }, { validator: conditionalEmailValidator });

    // this.clientForm.get('isSendInvite')?.valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.emailRequired = true;
    //     this.clientForm.get('email')?.setValidators([Validators.required, Validators.pattern(emailRegex)]);
    //   } else {
    //     this.emailRequired = false;
    //     this.clientForm.get('email')?.clearValidators();
    //     this.clientForm.get('email')?.setValidators([Validators.pattern(emailRegex)]);
    //   }
    //   this.clientForm.get('email')?.updateValueAndValidity();
    // });
  }

  get email() {
    return this.clientForm.get('email');
  }

  validate() {
    this.clientForm.get('email')?.updateValueAndValidity();
  }

  saveClient() {
    this.isValidFormSubmitted = false;
    if (this.clientForm.invalid) {
      return;
    } else {
      this.isValidFormSubmitted = true;
      // Perform save operation or other actions
      // console.log('Client data:', this.clientForm.value);
      const attorneyId = Number(sessionStorage.getItem('companyId'));
      let payload = {
        id: null,
        isPetitionerAdmin: true,
        attorneyId: attorneyId,
        ...this.clientForm.value
      };
      // if (!this.clientForm.value.isSendInvite && this.email.status !== 'VALID') {
      //   payload.email
      // }

      this.apiService.postCompany(payload).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response) {
          // this.toast.success(response.message);
          this.apiService.clientListSubject.next({ saved: true, data: response.data });
          // this.dynamicDialogRef.close();
          this.dialogRef.close(response.message);
        }
      });

    }
  }
}
