import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import * as errorUtils from 'error-message-utility';
import { ReferenceLibraryService } from 'reference-library';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { OtherInformationService } from './other-information.service';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { getStepDetails } from 'visa-store';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};

@Component({
  selector: 'app-other-information',
  templateUrl: './other-information.component.html',
  styleUrls: ['./other-information.component.scss']
})
export class OtherInformationComponent implements OnInit {
  @Input() primaryData: PrimaryData;
  otherInfoForm: FormGroup;
  statesList: any = [];
  countryList: any = [];
  disabledControls = false;
  relationshipList: any = [];
  relationPetitionList: any = [];
  observableSubscription$ = new Subject();
  listEmbassyStates: any = [];
  otherRealtivesPetitionForm: FormGroup;
  petitionResultStatus:any = [];
  otherInfo: any;
  stepDetails: TaskStep;
  constructor(private referenceLibraryService: ReferenceLibraryService,
    private cacheCountryService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    public dialogService: DialogService,
    public toastr: ToastrService,
    private otherInformationService: OtherInformationService,
    public store: Store<any>,) { }

  ngOnInit(): void {
    this.otherInfoForm = new FormGroup({
      id: new FormControl(""),
      officeCity: new FormControl(null),
      officeState: new FormControl(null),
      usaEmbassyCountry: new FormControl(null),
      usaEmbassyCityOrTown: new FormControl(null),
      usaEmbassyProvince: new FormControl(null),
      haveYoufiledForBeneficiary: new FormControl(null),
      legalNameFirstName: new FormControl(null),
      legalNameMiddleName: new FormControl(null),
      legalNameLastName: new FormControl(null),
      petitionFiledCityOrtown: new FormControl(null),
      petitionFiledState: new FormControl(null),
      petitionFiledDate: new FormControl(null),
      result: new FormControl(null),
    });

    this.otherRealtivesPetitionForm = new FormGroup({
      id: new FormControl(null),
      firstName: new FormControl(null,Validators.required),
      middleName: new FormControl(null),
      lastName: new FormControl(null,Validators.required),
      relationship: new FormControl(null,Validators.required)
    });

    this.petitionResultStatus = ['Approved','Widthdrawn','Denied'];

    combineLatest([
      this.cacheCountryService.getCountry(),
      this.referenceLibraryService.getReferenceData('FAMRELTYP'),
      this.apiServiceState.getStates('USA')
    ])
      .pipe(take(1))
      .subscribe(data => {
        if (data[0].length > 0) {
          this.countryList = data[0];
        }
        this.relationshipList = data[1];
        this.statesList = data[2];
      });
    this.getOtherInfo();
    this.getAllRelativesInfo();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((data: TaskStep) => {
      if (data && data.id) {
        this.stepDetails = data;
        this.toggleControlsStatus();
      }
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  handleCountryChange(countryCode: string, stateCode?: string, stateName?: string) {
    if(countryCode){
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.listEmbassyStates = states;
    });
  }

  }
  getOtherInfo() {
    this.otherInformationService.getOtherInformation(this.primaryData.caseType,
      this.primaryData.caseId)
      .pipe(take(1))
      .subscribe((response: ImagilityBaseResponse) => {
        console.log("get other info", response);
        if(response && response.status === 200){
          this.otherInfo = response.data; 
          if (this.otherInfo.usaEmbassyCountry && this.otherInfo.usaEmbassyCountry.trim() !== '') {
            this.handleCountryChange(
              this.otherInfo.usaEmbassyCountry,
              this.otherInfo.usaEmbassyProvince,
              this.otherInfo.aosNonUsStateProvinceName);
          }
        this.otherInfoForm.reset({
          ... this.otherInfo,
          petitionFiledDate: new Date(this.otherInfo.petitionFiledDate),
        })
        }
       
      });
  }

  getAllRelativesInfo() {
    this.otherInformationService.getAllRelativesInfo(this.primaryData.visatype,
      this.primaryData.caseType, this.primaryData.caseId)
      .pipe(take(1))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.relationPetitionList = response.data ? response.data : [];
          console.log("get relatives list", response);
        }

      });
  }
  saveOtherInfo() {
    const payload = {
      ... this.otherInfoForm.value,
      petitionFiledDate: this.otherInfoForm.value.petitionFiledDate ?
        moment(this.otherInfoForm.value.petitionFiledDate).format('YYYY-MM-DD') : null,
    };
    console.log("save other info", payload);
    this.otherInformationService.postOtherInformation(this.primaryData.visatype,
      this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
          // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          //   this.updateStepStatus('INPROGRESS');
          // }
          this.getOtherInfo();
        }
      });
   // console.log("additional info",this.otherInfoForm.value);
  }
  handleCancel() {

  }
  saveRelativeInfo() {
    console.log("relative value", this.otherRealtivesPetitionForm.value);
    const payload = {
      ...this.otherRealtivesPetitionForm.value,
    }
    this.otherInformationService.postRelativeInformation(this.primaryData.visatype,
      this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
          this.otherRealtivesPetitionForm.reset();
          this.getAllRelativesInfo();
          // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          //   this.updateStepStatus('INPROGRESS');
          // }
          // this.getOtherInfo();
        }
      });
  }
  cancelForm() {

  }
  editDocument(doc) {
  this.otherRealtivesPetitionForm.reset({
    ...doc
  })
  }
  deleteDocument(id) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.otherInformationService.deleteRelativeItem(this.primaryData.visatype,this.primaryData.caseType,this.primaryData.caseId, id)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((deleteResponse: ImagilityBaseResponse) => {
              this.toastr.success(deleteResponse.message, 'Success');
              this.getAllRelativesInfo();
              // this.getFamilyInformation();
            });
        }
      });
  }
  toggleControlsStatus() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT') {
      this.disabledControls = true;
      this.otherInfoForm.disable();
      this.otherRealtivesPetitionForm.disable();
     // this.addressFormDisabled = true;
    } else {
      this.disabledControls = false;
      this.otherInfoForm.enable();
      this.otherRealtivesPetitionForm.enable();
     // this.addressFormDisabled = false;
    }
  }
  onDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }



}
