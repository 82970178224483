import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AttorneyInvoiceContainerComponent } from "./attorney-invoice-container/attorney-invoice-container.component";
import { InvoiceTemplateRoutingModule } from "./attorney-invoice-routing.module";
import { GenerateInvoiceComponent } from "./generate-invoice/generate-invoice.component";
import { BillingInvoiceComponent } from "./billing-invoice/billing-invoice.component";
import { MatStepperModule } from "@angular/material/stepper";
import { InvoiceDeatilsComponent } from "./invoice-deatils/invoice-deatils.component";
import { SendInvoiceComponent } from "./send-invoice/send-invoice.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "primeng/fileupload";
import { FullCalendarModule } from "@fullcalendar/angular";
import { CalendarModule } from "primeng/calendar";

import { DropdownModule } from "primeng/dropdown";
import { InputNumberModule } from "primeng/inputnumber";
import { TableModule } from "primeng/table";
import { SharedModule } from "../shared/shared.module";
import { PaginatorModule } from "primeng/paginator";
import { DividerModule } from "primeng/divider";
import { ToastModule } from "primeng/toast";
import { SliderModule } from "primeng/slider";
import { MultiSelectModule } from "primeng/multiselect";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { ProgressBarModule } from "primeng/progressbar";
import { InputTextModule } from "primeng/inputtext";
import { RatingModule } from "primeng/rating";
import { ViewInvoiceComponent } from "./view-invoice/view-invoice.component";
import { PreviewComponent } from "./preview/preview.component";
import { MatDividerModule } from "@angular/material/divider";
import { PanelModule } from "primeng/panel";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { MatPaginatorModule } from "@angular/material/paginator";
import { InvoiceReceiptComponent } from "./invoice-receipt/invoice-receipt.component";
import { GenerateReceiptComponent } from "./generate-receipt/generate-receipt.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  
import { MatNativeDateModule } from '@angular/material/core'
import { SendReceiptComponent } from './send-receipt/send-receipt.component';
import { ClientsTabComponent } from './clients-tab/clients-tab.component';
import { ClientGenerateInvoiceComponent } from './client-generate-invoice/client-generate-invoice.component';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { EditorModule } from "@progress/kendo-angular-editor";
import { EditorLibraryModule } from "editor-library";
// import { AttorneyModule } from "../attorney/attorney.module";
//import { SignoffPrintComponent } from "../petition-builder/components/signoff-print/signoff-print.component";

@NgModule({
  declarations: [
    AttorneyInvoiceContainerComponent,
    GenerateInvoiceComponent,
    BillingInvoiceComponent,
    InvoiceDeatilsComponent,
    SendInvoiceComponent,
    ViewInvoiceComponent,
    PreviewComponent,
    InvoiceReceiptComponent,
    GenerateReceiptComponent,
    SendReceiptComponent,
    ClientsTabComponent,
    ClientGenerateInvoiceComponent,
    NumberToWordsPipe,
    //SignoffPrintComponent
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    // BrowserAnimationsModule,
    MatNativeDateModule,
    InvoiceTemplateRoutingModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDividerModule,
    PanelModule,
    OverlayPanelModule,
    MatPaginatorModule,
    MatStepperModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FullCalendarModule,
    DropdownModule,
    InputNumberModule,
    DividerModule,
    SharedModule,
    PaginatorModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    RatingModule,
    EditorModule,
    EditorLibraryModule
    // AttorneyModule,
  ],
  exports: [ClientsTabComponent, NumberToWordsPipe]
})
export class AttorneyInvoiceModule {}
