import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visa-bulletin-details',
  templateUrl: './visa-bulletin-details.component.html',
  styleUrls: ['./visa-bulletin-details.component.scss']
})
export class VisaBulletinDetailsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClickBack(){
    this.router.navigate(["/attorney-landing/dashboard/attorney-dashboard"]); 
  } 

}
