import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobDutiesService {

  constructor(private http: HttpClient) { }

  //http://localhost:5651/api/v2/L1/L1/227/jobduty
  postJobDutyItem(payload, visatype, caseType, caseId) {
    return this.http.post(`/${visatype}/${caseType}/${caseId}/jobduty`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // getJobDuty(payload, visatype, caseType, caseId) {
  //   return this.http.get(`/${visatype}/${caseType}/${caseId}/jobduty`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response;
  //     })
  //   );
  // }

  deleteJobDutyItem(visatype, jobDutyId) {
    return this.http.delete(`/${visatype}/position/jobduty/${jobDutyId}?forceDelete=true`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // http://localhost:5651/api/v2/L1/company/288/L1/227/position/158/jobDuty?forceDelete=true
  deleteAllJobDutyItems(visatype, caseType, caseId, positionId) {
    return this.http.delete(`/${visatype}/company/0/${caseType}/${caseId}/position/${positionId}/jobDuty?forceDelete=true`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getJobCategoryList_ddl() {
    return this.http.get(`/company/masterData/jobcategory`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getJobRolesList_ddl(categoryId, selectedCompany) {
    return this.http.get(`/company/${selectedCompany.companyId}/category/${categoryId}/jobroles`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getIOSMapping(visatype, caseType, caseId, positionId, dutyId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/position/${positionId}/duty/${dutyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }
  getJobRolesDuties(companyId, jobRoleId) {
    // return this.http.get(`/${visatype}/company/${companyId}/position/${jobTitle}`)
    //   .pipe(
    //     map((response: ImagilityBaseResponse) => {
    //       if (response.status === 200) {
    //         return response;
    //       }
    //     })
    //   );
    return this.http.get(`/company/${companyId}/masterData/jobRole/${jobRoleId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }
}
