import { ClickOutSideDirective } from './clickOutSide/clickOutSide.directive';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavbarComponent } from './navbar/navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalComponent } from './modal/app-modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { TimelineComponent } from './timeline/timeline.component';
import { AppFileUpload } from './file-upload/file-upload.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ngfModule } from 'angular-file';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { UserPrivacyModalComponent } from './modal/user-privacy-modal/user-privacy-modal.component';
import { TourModalComponent } from './modal/tour-modal/tour-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfilePictureUploadComponent } from './profile-picture-upload/profile-picture-upload.component';
import { CountryDetailsComponent } from './country_details/countryDetails.component';
import { CountryApiService } from './country_details/country.service';
import { EducationTimelineComponent } from './education-timeline/education-timeline.component';
import { SignatureUploadComponent } from './signature-upload/signature-upload.component';
import { ProjectTimelineComponent } from './project-timeline/project-timeline.component';
import { ImmigrationTimelineComponent } from './immigration-timeline/immigration-timeline.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ImgDateFormatPipe } from 'src/app/modules/utility/dateFormat.pipe';
import { ImgDateFormatPipeWithTime } from '../utility/dateFormatWithTime.pipe';
import { TrainingTimelineComponent } from './training-timeline/training-timeline.component';
import { LicenseTimelineComponent } from './license-timeline/license-timeline.component';
import { InfoModalComponent } from './modal/info-modal/info-modal.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { SecondsToTimePipe } from './pipes/secondsToTime.pipe';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { DatePipe } from '@angular/common';
import { RfeDialogComponent } from '../rfe-builder/components/rfe-dialog/rfe-dialog.component';
import { RfeUploadNoticeComponent } from '../rfe-builder/components/rfe-upload-notice/rfe-upload-notice.component';
import { RfePrepareResponseComponent } from '../rfe-builder/components/rfe-prepare-response/rfe-prepare-response.component';
import { RfeSelectClausesComponent } from '../rfe-builder/components/rfe-select-clauses/rfe-select-clauses.component';
import { RfePrintResponseComponent } from '../rfe-builder/components/rfe-print-response/rfe-print-response.component';
import { RfeProvideAdditionalDetailsComponent } from '../rfe-builder/components/rfe-provide-additional-details/rfe-provide-additional-details.component';
import { RfeSupportingDocumentsComponent } from '../rfe-builder/components/rfe-supporting-documents/rfe-supporting-documents.component';
import { RfeReviewResponseComponent } from '../rfe-builder/components/rfe-review-response/rfe-review-response.component';
import { RfeResponseBuilderComponent } from '../rfe-builder/components/rfe-response-builder/rfe-response-builder.component';
import { RfeOrganizeDocumentsComponent } from '../rfe-builder/components/rfe-organize-documents/rfe-organize-documents.component';
import { RfePrepareResponseStep1Component } from '../rfe-builder/components/rfe-prepare-response-step1/rfe-prepare-response-step1.component';
import { RfePrepareResponseStep2Component } from '../rfe-builder/components/rfe-prepare-response-step2/rfe-prepare-response-step2.component';
import { RfePrepareResponseStep3Component } from '../rfe-builder/components/rfe-prepare-response-step3/rfe-prepare-response-step3.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ImgPrimengDonutComponent } from './primeng-donut/primeng-donut.component';
import { ChartModule } from 'primeng/chart';
import { TabViewModule } from 'primeng/tabview';
//import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
//import 'froala-editor/js/plugins.pkgd.min.js';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TreeDragDropService } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { InplaceModule } from 'primeng/inplace';
import { OnboardEmploymentComponent } from './onboard-employment/onboard-employment.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { UserIdValidationComponent } from '../login/userId-validation/userId-validation.component'
import { OverlayModule } from '@angular/cdk/overlay';
import { HelpVideoOverlayComponent } from './help-video-overlay/help-video-overlay.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TermsConditionsComponent } from './footer/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
import { JoyrideModule } from 'ngx-joyride';
import { GuidedTourStepsComponent } from './modal/guided-tour-steps/guided-tour-steps.component';
import { ContactUsComponent } from './footer/contact-us/contact-us.component';
import { ClipboardModule } from 'ngx-clipboard';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RouterModule } from '@angular/router';
import { BeneficiaryDetailsReadonlyComponent } from './beneficiary-details-readonly/beneficiary-details-readonly.component';
import { I9FormDetailsComponent } from './i9-form-details/i9-form-details.component';
import { I9PreparerInfoComponent } from './i9-preparer-info/i9-preparer-info.component';
import { MatRadioModule } from '@angular/material/radio';
import { InputTextModule } from 'primeng/inputtext';
import { I9BeneficiaryDetailsComponent } from './i9-beneficiary-details/i9-beneficiary-details.component';
import { nl2brPipe } from './pipes/nl2br.pipe';
import { TaskNotesLibModule } from 'task-notes-lib';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RequestDataDocumentsComponent } from './request-data-documents/request-data-documents.component';
import { CustomKendoModule } from '../custom-kendo/custom-kendo.module';
import { EditorModule } from '@progress/kendo-angular-editor';
import { TreeSelectComponent } from './TreeSelect/TreeSelect.component';
import { PrimengLibraryModule } from 'primeng-library';
import { CustomQuestionsComponent } from './custom-questions/custom-questions.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InlineComponent } from './inline/inline.component';
import { FbMenuComponent } from './fb-menu/fb-menu.component';
import { VdBuilderFilter } from './vd-builder-filter/vd-builder-filter.pipe';
import { DataLogComponent } from './data-log/data-log.component';
import { AddIndividualSponsorComponent } from './add-individual-sponsor/add-individual-sponsor.component';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DateSuffixPipe } from '../utility/date-suffix.pipe';
import { VisaBulletinComponent } from './visa-bulletin/visa-bulletin.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddDefaultCompanyUsersComponent } from './add-default-company-users/add-default-company-users.component';
import { SwitchAppComponent } from './switch-app/switch-app.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { FormsWeSupportComponent } from './forms-we-support/forms-we-support.component';

@NgModule({
  declarations: [
    ChangepasswordComponent,
    ImgDateFormatPipe,
    ImgDateFormatPipeWithTime,
    NavbarComponent,
    ModalComponent,
    TimelineComponent,
    EscapeHtmlPipe,
    SafeUrlPipe,
    AppFileUpload,
    ConfirmationModalComponent,
    UserPrivacyModalComponent,
    TourModalComponent,
    ProfilePictureUploadComponent,
    CountryDetailsComponent,
    EducationTimelineComponent,
    SignatureUploadComponent,
    ProjectTimelineComponent,
    ImmigrationTimelineComponent,
    TrainingTimelineComponent,
    LicenseTimelineComponent,
    InfoModalComponent,
    FileDownloadComponent,
    SecondsToTimePipe,
    RfeDialogComponent,
    RfeUploadNoticeComponent,
    RfePrepareResponseComponent,
    RfePrintResponseComponent,
    RfeReviewResponseComponent,
    RfeResponseBuilderComponent,
    RfeOrganizeDocumentsComponent,
    RfePrepareResponseStep1Component,
    RfePrepareResponseStep2Component,
    RfePrepareResponseStep3Component,
    RfeSelectClausesComponent,
    RfeProvideAdditionalDetailsComponent,
    RfeSupportingDocumentsComponent,
    ClickOutSideDirective,
    ImgPrimengDonutComponent,
    OnboardEmploymentComponent,
    UserIdValidationComponent,
    HelpVideoOverlayComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    GuidedTourStepsComponent,
    ContactUsComponent,
    BeneficiaryDetailsReadonlyComponent,
    I9FormDetailsComponent,
    I9PreparerInfoComponent,
    I9BeneficiaryDetailsComponent,
    nl2brPipe,
    RequestDataDocumentsComponent,
    TreeSelectComponent,
    CustomQuestionsComponent,
    InlineComponent,
    FbMenuComponent,
    VdBuilderFilter,    
    DataLogComponent,
    AddIndividualSponsorComponent,
    DateSuffixPipe,
    VisaBulletinComponent,
    AddDefaultCompanyUsersComponent,
    SwitchAppComponent,
    AddNotesComponent,
    FormsWeSupportComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    ChartModule,
    MatListModule,
    MatSidenavModule,
    MatButtonModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatBadgeModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatStepperModule,
    ngfModule,
    MatInputModule,
    MatSelectModule,
    ImageCropperModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatRadioModule,
    InputTextModule,
    ReactiveFormsModule,
    MatSelectModule,
    TimelineModule,
    CardModule,
    ButtonModule,
    DialogModule,
    FileUploadModule,
    CustomKendoModule,
    EditorModule,
    // HttpClientModule,
    CalendarModule,
    RadioButtonModule,
    PickListModule,
    TableModule,
    AccordionModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    DropdownModule,
    CheckboxModule,
    MatSelectModule,
    TabViewModule,
    PdfViewerModule,
    MatTableModule,
    //FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    TreeModule,
    InplaceModule,
    InputTextareaModule,
    OverlayModule,
    MatTabsModule,
    JoyrideModule.forChild(),
    ClipboardModule,
    InputSwitchModule,
    RouterModule,
    PrimengLibraryModule,
    TaskNotesLibModule,
    NgbTooltipModule,
    MatCheckboxModule,
    PaginatorModule,
    ConfirmPopupModule,
    MatDatepickerModule
  ],
  entryComponents: [
    UserPrivacyModalComponent,
    TourModalComponent,
    ChangepasswordComponent,
    ConfirmationModalComponent,
    ProfilePictureUploadComponent,
    SignatureUploadComponent,
    InfoModalComponent,
    OnboardEmploymentComponent,
    
    
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    DatePipe,
    ConfirmationService,
    { provide: 'windowObject', useValue: window },
    CountryApiService,
    TreeDragDropService,
    DynamicDialogRef, 
    DynamicDialogConfig
  ],
  exports: [
    TimelineComponent,
    ImgDateFormatPipe,
    ImgDateFormatPipeWithTime,
    SafeUrlPipe,
    NavbarComponent,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableModule,
    MatBadgeModule,
    TimelineComponent,
    EscapeHtmlPipe,
    AppFileUpload,
    CountryDetailsComponent,
    EducationTimelineComponent,
    ProjectTimelineComponent,
    ImmigrationTimelineComponent,
    ChangepasswordComponent,
    TrainingTimelineComponent,
    LicenseTimelineComponent,
    FileDownloadComponent,
    ModalComponent,
    SecondsToTimePipe,
    ClickOutSideDirective,
    ImgPrimengDonutComponent,
    OnboardEmploymentComponent,
    UserIdValidationComponent,
    HelpVideoOverlayComponent,
    GuidedTourStepsComponent,
    DialogModule,
    FileUploadModule,
    DropdownModule,
    RadioButtonModule,
    ConfirmDialogModule,
    BeneficiaryDetailsReadonlyComponent,
    I9FormDetailsComponent,
    I9PreparerInfoComponent,
    CheckboxModule,
    TabViewModule,
    TableModule,
    InputSwitchModule,
    I9BeneficiaryDetailsComponent,
    nl2brPipe,
    RequestDataDocumentsComponent,
    ConfirmPopupModule,
    TreeSelectComponent,
    CustomQuestionsComponent,
    AccordionModule,
    InlineComponent,
    FbMenuComponent,
    PrimengLibraryModule,
    VdBuilderFilter,
    MatStepperModule,
    DataLogComponent,
    AddIndividualSponsorComponent,
    DateSuffixPipe,
    VisaBulletinComponent,
    AddDefaultCompanyUsersComponent,
    SwitchAppComponent,
    AddNotesComponent
  ],
  bootstrap: [TimelineComponent],
})
export class SharedModule { }
