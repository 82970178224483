<div id="toast-container" class="toast-middle-center toast-container">
    <div class="ngx-toastr toast-error" toast-component="" style="opacity: 1;">
      <!-- Close Button -->
      <button aria-label="Close" class="toast-close-button" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
  
      <!-- Toast Title -->
      <div class="toast-title" aria-label="Error">
        {{ title }}
      </div>
  
      <!-- Toast Message -->
      <div [innerHTML]="message"></div>
  
      <!-- Footer with OK Button -->
      <div class="toast-footer">
        <button (click)="close()" class="btn btn-danger btn-xs pull-right">OK</button>
      </div>
    </div>
  </div>
   