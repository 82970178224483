import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SwitchAppService {

  constructor(private http:HttpClient) { }

  getUserAppRoleDetails(companyId,userId) {
    return this.http.get(`/vd/company/${companyId}/user/${userId}/userAppRole/details`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }
}
