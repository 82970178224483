import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, ReferenceLibrary, EYECOLORS, HAIRCOLORS, BeneAddress, BeneficiaryDetails, MAILING_LIST, FOREIGN_LIST, PERMANENT_LIST, acceptedFileTypes } from 'app-models';
// import { AuthenticationService } from 'authentication';
import { ReferenceLibraryService } from 'reference-library';
import { EditCompletePersonalDetailLibraryService } from './edit-complete-personal-detail-library.service'; 
import { CacheCountryListService } from 'cache-country-list';
import * as errorUtils from 'error-message-utility';
import { MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import moment from 'moment';
import * as Roles from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { SignatureUploadComponent } from './signature-upload/signature-upload.component';
import { ApiServicesService } from './helper-services/api-services.service'; 
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'lib-edit-complete-personal-detail-library',
  templateUrl: './edit-complete-personal-detail-library.component.html',
  styleUrls: ['./edit-complete-personal-detail-library.component.scss']
})
export class EditCompletePersonalDetailLibraryComponent implements OnInit {

  primaryData: PrimaryData;
  updateBenProfile: Boolean = true;
  beneficiaryId: number;
  beneSelfDetailsForm: FormGroup;
  editDetails: boolean = false;
  listCountry: any[];
  listStates: [];
  ssn_hide: boolean = false;
  listTitle: ReferenceLibrary[];
  listGender: ReferenceLibrary[];
  birthDetails: any;
  showAliasName: boolean = false;
  showforeignAddress: boolean = false;
  permanentLocality: boolean = false;
  currentLocality: boolean = false;
  addressTypeList: ReferenceLibrary[];
  maritalTypeList: ReferenceLibrary[];
  phoneTypeList: ReferenceLibrary[];
  emailTypeList: ReferenceLibrary[];
  subscription: Subscription;
  foriegnAddressType: string;
  submitted: boolean = false;
  listStatesCurrent = [];
  listStatesMailing = [];
  listStatesPermanent = [];
  listStatesForeign = [];
  listStatesBirth = [];
  listStatesMarriage = [];
  petitionData: any
  @Input() personalInputData: any;
  @Input()
  beneficiaryData: any;
  currentDate: any;
  showExpiryValidation: boolean = false;
  selectedPermanentCheckbox: boolean = false;
  selectedCurrentCheckbox: boolean = false;
  listForeignCountry = [];
  showForienAddressMandatory: boolean = false;
  maritalStatusArr: ReferenceLibrary[];
  raceArr: ReferenceLibrary[];
  familyRelTypeArr: ReferenceLibrary[];
  observableSubscription$ = new Subject();
  isBirthStateRequired: boolean = false;
  showUploadPassport: boolean = false;

  datesStoredArrayForDisplay = [
    { dob: '' },
    { issueDate: '' },
    { expiryDate: '' }
  ];

  //update for expansion panel
  personaldetails = 0;
  viewMode: boolean = false;

  @Output() closePersonalEdit = new EventEmitter<any>();
  detailsAbout: any = {};
  customQuestions: any;
  editModeVal: boolean = true;
  destroy$ = new Subject<void>();
  formChangesSubscription: any;
  activeIndex;
  citizenshipAcquiredArr: ReferenceLibrary[];
  ethinicityArr: ReferenceLibrary[];

  fileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENGREENCARD',
    entityId: 0,
    fileCategory: 'BENGREENCARD',
    listUploadedFiles: [],
    useCase: 'beneficiary',
    useCaseId: 0,
    documentTypeRequired: true,
    docummentTypeCode: 'BENGREENCARD',
    useEntityId: false,
    isDeleteEnabled: true,
    isUploadEnabled: true,
    familyId: null,
    family: false
  };

  fileUploadPassConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENPASSDOC',
    entityId: 0,
    fileCategory: 'PASSCOPY',
    listUploadedFiles: [],
    useCase: 'beneficiary',
    useCaseId: 0,
    documentTypeRequired: true,
    docummentTypeCode: 'BENPASSDOC',
    useEntityId: false,
    isDeleteEnabled: true,
    isUploadEnabled: true,
    familyId: null,
    family: false
  };

  @Input() beneImmigrant: string;

  showDepedentInfo: boolean = false;
  beneficiaryFamilyInfo: any[];
  showMarriageSection: boolean = true;
  hairColors: any[];
  eyeColors: any[];
  greenCardValidPattern: string;
  certificateNumberValidPattern: string;
  textOnlyValidPattern: string;
  showCitizenShipAquiredInfo: boolean = false
  beneficiaryType: string;
  signatureImage: string;
  public showCropperSection: boolean;
  sessionpicimage: void;
  signatureImageForm: FormData;
  loading = false;
  beneficiaryProfileUpdate: any;
  showEditButton: boolean = true;
  isPersonalDetailsFormReset: boolean = false;
  currentFamilyId: any;
  isDisabled = false;
  companyId;
  beneficiaryIdFromFB;
  fbDetails = {
    moduleId: null,
    pageFormId: null,
    employeeCreationType: null,
    employeeDataToBeFilled: null,
    employeeType: null
  };
  listCountryMarriage: any[] = [];
  listCountryForeignAdd: any[] = [];
  currentItemDisplay: any = [];
  iscaseRequest: boolean = false;

  // @Output()
  // hasPassportChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  listCopyMailAddress: any[];
  listCopyFornAddress: any[];
  listCopyPermAddress: any[];
  constructor(
    private referenceLibraryService: ReferenceLibraryService,
    private beneSelfFormBuilder: FormBuilder,
    private cacheCountryService: CacheCountryListService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    protected store: Store<any>,
    private personalDetailsApiService: ApiServicesService,
    private editPersonalDetailService: EditCompletePersonalDetailLibraryService,
  ) {
    this.loading = true;
    this.addressTypeList = [];
    this.maritalTypeList = [];
    this.phoneTypeList = [];
    this.emailTypeList = [];
    this.currentDate = moment().format('YYYY-MM-DD');
    this.beneficiaryId = parseInt(sessionStorage.getItem('beneficiaryId'));

    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries.map((c) => {
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountry = [{countryCode:'',countryName:'Select',labelWithCode:'Select',phoneCode:''},...this.listCountry];
        this.listCountryMarriage = this.listCountryForeignAdd = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
    this.hairColors = HAIRCOLORS;
    this.eyeColors = EYECOLORS;

    this.greenCardValidPattern = "^[a-zA-Z{3}0-9]{13}$";
    this.greenCardValidPattern = "^[a-zA-Z]{3}[0-9]{10}$";
    this.certificateNumberValidPattern = "^[a-zA-Z0-9]{8}$";
    this.textOnlyValidPattern = '^[a-zA-Z ]*$';
    this.listCopyMailAddress = MAILING_LIST;
    this.listCopyFornAddress = FOREIGN_LIST;
    this.listCopyPermAddress = PERMANENT_LIST;
    var retrievedObject = sessionStorage.getItem('primaryData');
    this.primaryData=JSON.parse(retrievedObject);
    this.primaryData
  }

  ngOnInit() {    
    this.editModeVal = true;
    this.editDetails = false;
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.referenceLibraryService.requestDataFromMultipleSources().subscribe(response => {
      this.addressTypeList = <ReferenceLibrary[]>response[0].data;
      this.maritalTypeList = <ReferenceLibrary[]>response[1].data;
      this.phoneTypeList = <ReferenceLibrary[]>response[2].data;
      this.emailTypeList = <ReferenceLibrary[]>response[3].data;
      this.listGender = <ReferenceLibrary[]>response[4].data;
      this.listTitle = <ReferenceLibrary[]>response[5].data;
      this.maritalStatusArr = <ReferenceLibrary[]>response[6].data;
      this.raceArr = <ReferenceLibrary[]>response[7].data;
      this.familyRelTypeArr = <ReferenceLibrary[]>response[8].data;
      this.citizenshipAcquiredArr = <ReferenceLibrary[]>response[9].data;
      this.ethinicityArr = <ReferenceLibrary[]>response[10].data;
      this.afterOnChangesAndReferenceLibraryLoaded();
      this.setvalidatations();
      this.loading = false;
     // this.updatePassportFormControl(true);
    });
    this.showEditButton = !this.editPersonalDetailService.checkValidity();
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;

    if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
      this.updateBenProfile = this.editPersonalDetailService.checkBeneEditPermission();
      this.viewMode = !this.updateBenProfile;
    }
  }

  setvalidatations() {
    this.beneSelfDetailsForm = this.beneSelfFormBuilder.group({
      id: [],
      title: [''],
      fName: ['', Validators.required],
      mName: [''],
      lName: ['', Validators.required],
      aliasName: this.beneSelfFormBuilder.array([
        this.beneSelfFormBuilder.group({
          fName: [''],
          title: [''],
          id: ['']
        })
      ]),
      gender: this.beneSelfFormBuilder.group({
        id: [''],
        name: null,
      }),
      dob: [''],
      ssn: [''],
      emailContacts: this.beneSelfFormBuilder.array([
        this.beneSelfFormBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          id: 0,
          type: this.beneSelfFormBuilder.group({
            code: ['PRIM'],
            id: [this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null]
          })
        })
      ]),
      phoneContacts: this.beneSelfFormBuilder.array([
        this.beneSelfFormBuilder.group({
          phoneNo: [''],
          id: 0,
          type: this.beneSelfFormBuilder.group({
            code: ['MOBL'],
            id: [this.phoneTypeList.find(x => x.code === 'MOBL') ? this.phoneTypeList.find(x => x.code === 'MOBL').id : null]
          }),
          countryCode: this.beneSelfFormBuilder.group({
            countryCode: [''],
            phoneCode: 0
          })
        })
      ]),
      address: this.beneSelfFormBuilder.array([
        this.beneSelfFormBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          postCode: [''],
          doorNo: [''],
          id: [''],
          streetNo: [''],
          countryCode: this.beneSelfFormBuilder.group({
            countryCode: ['']
          }),
          stateProvinceCode: [null],
          stateProvinceName: [''],
          city: [''],
          locality: [''],
          type: this.beneSelfFormBuilder.group({
            id: [this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null],
            code: ['CURR']
          })
        }),
        this.beneSelfFormBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          postCode: [''],
          doorNo: [''],
          id: [''],
          streetNo: [''],
          countryCode: this.beneSelfFormBuilder.group({
            countryCode: ['']
          }),
          stateProvinceCode: [null],
          stateProvinceName: [''],
          city: [''],
          locality: [''],
          type: this.beneSelfFormBuilder.group({
            id: [this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null],
            code: ['MAIL']
          })
        }),
        this.beneSelfFormBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          postCode: [''],
          doorNo: [''],
          id: [''],
          streetNo: [''],
          countryCode: this.beneSelfFormBuilder.group({
            countryCode: ['']
          }),
          stateProvinceCode: [null],
          stateProvinceName: [''],
          city: [''],
          locality: [''],
          type: this.beneSelfFormBuilder.group({
            id: [this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null],
            code: ['FORN']
          })
        }),
        this.beneSelfFormBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          postCode: [''],
          doorNo: [''],
          id: [''],
          streetNo: [''],
          countryCode: this.beneSelfFormBuilder.group({
            countryCode: ['']
          }),
          stateProvinceCode: [null],
          stateProvinceName: [''],
          city: [''],
          locality: [''],
          type: this.beneSelfFormBuilder.group({
            id: [this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null],
            code: ['PERM']
          })
        })
      ]),
      identificationMark: [''],
      height: [''],
      hairColor: [''],
      skinColor: null,/*removed from html as per IM4604*/
      eyeColor: [''],
      countryCode: this.beneSelfFormBuilder.group({
        countryCode:null
      }),
      birthStateProvinceCode: [null],
      birthStateProvinceName: [''],
      birthCity: [''],
      ctznshipCountryCode: this.beneSelfFormBuilder.group({
        countryName: null,
        countryCode: null
      }),
      hasPassport: [true],
      passport: this.beneSelfFormBuilder.array([
        this.beneSelfFormBuilder.group({
          id: 0,
          passportNo: [''],
          issueDate: [''],
          expiryDate: [''],
          issueCountryCode: this.beneSelfFormBuilder.group({
            countryCode: null
          })
        })
      ]),
      currentVisaStatus: null,
      profileImage: null,
      smallProfileImage: null,
      profilePic: null,
      smallProfilePic: null,
      firstAndLastName: null,
      maritalStatus: this.beneSelfFormBuilder.group({
        id: [null],
        name: [''],
      }),
      countryOfMarriage: this.beneSelfFormBuilder.group({
        countryCode: ['']
      }),
      marriageStateProvinceCode: [null],
      marriageStateProvinceName: null,
      cityOfMarriage: null,
      isLpr: [null],
      lprThruMarriage: null,
      greenCardNo: null,
      ethnicity: this.beneSelfFormBuilder.group({
        ethnicityId: [],
        name: ['']
      }),

      race: this.beneSelfFormBuilder.array([
      ]),
      weight: [''],
      ctznAcqrTypeId: this.beneSelfFormBuilder.group({
        ctznAcqrId: [''],
        name: ['']
      }),
      hasCtznCrtfct: [false],
      ctznCrtfctNo: [''],
      ctznCrtfctIssPlace: [''],
      ctznCrtfctIssDate: [null],
      marriageDate: [null]
    });
    this.formStatusCheck();
  }

  updatePassportFormControl(event: any) {
    this.showUploadPassport = true;
      if ((this.beneficiaryData.passport && !this.beneficiaryData.passport.length) || this.beneficiaryData.passport === null) {
        this.beneSelfDetailsForm.patchValue({
          passport: [{
            id: 0,
            issueCountryCode: {
              countryCode: null
            },
            expiryDate: '',
            issueDate: '',
            passportNo: ''
          }]
        });
      }
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].clearValidators();
      this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].clearValidators();
    
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueCountryCode']['controls']['countryCode'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['expiryDate'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['issueDate'].updateValueAndValidity();
    this.beneSelfDetailsForm.get('passport')['controls'][0]['controls']['passportNo'].updateValueAndValidity();
    // this.hasPassportChange.emit(!event.checked);
  }

  addRaceArrayFormControl() {
    const raceOptionArray = this.beneSelfDetailsForm.get('race') as FormArray;
    raceOptionArray.clear();
    if (this.raceArr && this.raceArr.length > 0) {
      this.raceArr.forEach((x, index) => {
        const raceChecked = this.beneficiaryData.race && this.beneficiaryData.race.length > 0 && this.beneficiaryData.race.some(y => y.raceId.id === x.id);
        raceOptionArray.push(new FormControl(raceChecked));
      });
    }
    //this.beneSelfDetailsForm.get('race').updateValueAndValidity();
  }

  get raceArray(): FormArray{
    return this.beneSelfDetailsForm.get('race') as FormArray;
  }



  ngOnChanges(changes: SimpleChanges) {    
    if (changes.beneImmigrant.currentValue) {
      this.petitionData = changes.beneImmigrant.currentValue;
    }
  }

  afterOnChangesAndReferenceLibraryLoaded() {
    this.detailsAbout.showDetailFor = this.beneImmigrant;
    this.beneficiaryType = this.detailsAbout.showDetailFor;
    if (!this.beneSelfDetailsForm || this.beneficiaryType === 'self') {
      this.setvalidatations();
    } else if (this.beneficiaryType === 'family') {
      //Remove validation for Family and Other dependents
      this.removeValidations(this.beneSelfDetailsForm);
      this.applyForeignAddressValidation(null, null, 2);
    }
    this.showMarriageSection = this.detailsAbout && this.detailsAbout.dependentType && this.detailsAbout.dependentType.code ? !['SPOUSE'].includes(this.detailsAbout.dependentType.code) : true;
   if(this.detailsAbout.showDetailFor === 'family') {
      this.getimmigBeneProfileInfo();
    } 
    else {
      this.getBeneficiaryFamilyDetails();
    }
  }

  getimmigBeneProfileInfo() {
    this.personalDetailsApiService.getBeneficiaryDetails(this.primaryData.sponsorId || this.primaryData.beneficiaryId, 'family', this.primaryData.familyId || this.primaryData.jsonInput.applicantsList[0].id).pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: any) => {
     let response =beneficiary.find(e=>e.id = this.primaryData.familyId || this.primaryData.jsonInput.applicantsList[0].id);
     this.beneficiaryData = { ...response, dob: response?.dob && moment(response?.dob, 'YYYY-MM-DD').toDate(), marriageDate: response?.marriageDate && moment(response?.marriageDate, 'YYYY-MM-DD').toDate(), ctznCrtfctIssDate: response?.ctznCrtfctIssDate && moment(response?.ctznCrtfctIssDate, 'YYYY-MM-DD').toDate(), passport: response?.passport?.length ? [{ ...response?.passport[0], expiryDate: response?.passport[0].expiryDate && moment(response?.passport[0].expiryDate, 'YYYY-MM-DD').toDate(), issueDate: response?.passport[0].issueDate && moment(response?.passport[0].issueDate, 'YYYY-MM-DD').toDate() }] : response?.passport };
     this.beneficiaryData.benCurrentAddress=response.address.find( e => e.type.code=='CURR');
     this.beneficiaryData.benForeignAddress=response.address.find( e => e.type.code=='FORN');
     this.beneficiaryData.benPermanentAddress=response.address.find( e => e.type.code=='PERM');
     this.beneficiaryData.benMailingAddress=response.address.find( e => e.type.code=='MAIL');
      if(this.beneficiaryData?.benCurrentAddress !== undefined)
      {
        this.beneficiaryData.benCurrentAddress.countryName=this.beneficiaryData?.benCurrentAddress?.countryCode?.countryName;

        this.beneficiaryData.benCurrentAddress.state=this.beneficiaryData?.benCurrentAddress?.countryCode?.countryCode === 'USA' ?
        this.beneficiaryData?.benCurrentAddress?.stateProvinceCode : this.beneficiaryData?.benCurrentAddress?.countryCode?.countryCode === 'IND' ? this.beneficiaryData?.benCurrentAddress?.stateProvinceCode : this.beneficiaryData?.benCurrentAddress?.stateProvinceName;
      }
      if(this.beneficiaryData?.benForeignAddress !== undefined)
      {
          this.beneficiaryData.benForeignAddress.countryName=this.beneficiaryData?.benForeignAddress?.countryCode?.countryName;

          this.beneficiaryData.benForeignAddress.state=this.beneficiaryData?.benForeignAddress?.countryCode?.countryCode === 'USA' ?
          this.beneficiaryData?.benForeignAddress?.stateProvinceCode : this.beneficiaryData?.benForeignAddress?.countryCode?.countryCode === 'IND' ? this.beneficiaryData?.benForeignAddress?.stateProvinceCode : this.beneficiaryData?.benForeignAddress?.stateProvinceName;
      }
      if(this.beneficiaryData?.benPermanentAddress !== undefined)
      {
          this.beneficiaryData.benPermanentAddress.countryName=this.beneficiaryData?.benPermanentAddress?.countryCode?.countryName;

          this.beneficiaryData.benPermanentAddress.state=this.beneficiaryData?.benPermanentAddress?.countryCode?.countryCode === 'USA' ?
          this.beneficiaryData?.benPermanentAddress?.stateProvinceCode : this.beneficiaryData?.benPermanentAddress?.countryCode?.countryCode === 'IND' ? this.beneficiaryData?.benPermanentAddress?.stateProvinceCode : this.beneficiaryData?.benPermanentAddress?.stateProvinceName;
      }
    
      if(this.beneficiaryData?.benMailingAddress !== undefined)
        {
          this.beneficiaryData.benMailingAddress.countryName=this.beneficiaryData?.benMailingAddress?.countryCode?.countryName;

          this.beneficiaryData.benMailingAddress.state=this.beneficiaryData?.benMailingAddress?.countryCode?.countryCode === 'USA' ?
          this.beneficiaryData?.benMailingAddress?.stateProvinceCode : this.beneficiaryData?.benMailingAddress?.countryCode?.countryCode === 'IND' ? this.beneficiaryData?.benMailingAddress?.stateProvinceCode : this.beneficiaryData?.benMailingAddress?.stateProvinceName;
        }

     this.beneficiaryData.emailId=response.emailContacts.find(e=>e.type.code=='PRIM').email;
     this.beneficiaryData.mobileNo=response?.phoneContacts[0]?.phoneNo;
     this.beneficiaryData.birthCountry=response?.birthCountryCode?.countryCode;
     this.beneficiaryData.birthState=response?.birthStateProvinceName
     this.beneficiaryData.citizenShip=response?.ctznshipCountryCode?.countryName;
     this.setBeneficiaryFormData();
  
     });
   
  }


  createNewRaceControl(item): FormGroup {
    return new FormGroup({
      id: new FormControl(0),
      raceId: new FormGroup({
        id: new FormControl(item.id ? item.id : null),
        name: new FormControl(item.name ? item.name : null),
        checked: new FormControl(false)
      }),
    })
  }

  ngAfterViewInit() {
    this.cacheCountryService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.length > 0) {
        this.listCountry = data.map((c) => {
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountry = [{ countryCode: '', countryName: 'Select', labelWithCode: 'Select', phoneCode: '' }, ...this.listCountry];
        this.listCountryMarriage = this.listCountryForeignAdd = this.listForeignCountry = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
  }

  onRefreshPage(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  formStatusCheck() {
    this.formChangesSubscription = this.beneSelfDetailsForm.valueChanges.pipe(
      map(() => this.beneSelfDetailsForm.dirty && this.beneSelfDetailsForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: "If you leave before saving, all changes made on the 'Profile Details' form will be lost. Do you wish to continue?"
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ""
        }));
      }

    });
  }


  handleEdit() {
    if (this.isPersonalDetailsFormReset) {
      this.setBeneficiaryFormData();
    }
    this.editDetails = !this.editDetails;
    this.beneSelfDetailsForm.enable();
    this.updatePassportFormControl({ checked: this.beneSelfDetailsForm.get('hasPassport').value });
    this.editDetails ? this.subscribeValueChanges() : this.unSubscribeValueChanges();
    // if (this.editDetails) {
    //   this.profileEditStatus.emit(true);
    // } else {
    //   this.profileEditStatus.emit(false);
    // }
  }

  subscribeValueChanges() {
    this.subscription = this.beneSelfDetailsForm.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((values) => {
      this.showforeignAddress = values.address['0']['countryCode']['countryCode'] === 'USA' && values.address['1']['countryCode']['countryCode'] === 'USA';
      this.currentLocality = values.address['0']['countryCode']['countryCode'] === 'IND';
      this.permanentLocality = values.address['1']['countryCode']['countryCode'] === 'IND';
    });
  }

  maskify(cc) {
    return cc.slice(0, -4).replace(/./g, '#') + cc.slice(-4);
  }

  patch(group, stateIndex, addType) {
    if (group.countryCode.countryCode) {
      this.handleCountryChange(group.countryCode.countryCode, stateIndex);
    }
    if (addType === 'Foreign') {
      const foreignAddress = this.getAddress('FORN');
      const foreignAddressType = foreignAddress.value.type;
      const foreignAddressID = foreignAddress.value.id;
      foreignAddress.setValue(group);
      foreignAddress.patchValue(
        {
          id: foreignAddressID,
          type: foreignAddressType
        }
      );

    } else if (addType === 'Mailing') {
      const mailAddress = this.getAddress('MAIL');
      const mailAddressType = mailAddress.value.type;
      const mailAddressID = mailAddress.value.id;
      mailAddress.setValue(group);
      mailAddress.patchValue(
        {
          id: mailAddressID,
          type: mailAddressType
        }
      );
    } else if (addType === 'Permanent') {
      const permAddress = this.getAddress('PERM');
      const permAddressType = permAddress.value.type;
      const permAddressID = permAddress.value.id;
      permAddress.setValue(group);
      permAddress.patchValue(
        {
          id: permAddressID,
          type: permAddressType
        }
      );
    }
  }

  unSubscribeValueChanges() {
    this.subscription.unsubscribe();
  }

  clearProfileData(myForm) {
    this.selectedCurrentCheckbox = false;
    this.selectedPermanentCheckbox = false;
    this.isPersonalDetailsFormReset = true;
    this.beneSelfDetailsForm.disable();
    myForm.resetForm();
    this.editDetails = !this.editDetails;
    // this.profileEditStatus.emit(this.editDetails);
    this.setvalidatations();
  }

  checkForeignAddress() {
    let currAddress = this.getAddress('CURR');
    let permAddress = this.getAddress('PERM');
    let ca = currAddress['value']['countryCode']['countryCode'];
    let pa = permAddress['value']['countryCode']['countryCode'];
    if (ca != 'USA') {
      this.patch(currAddress['value'], 'Current', 'Foreign');
    } else if (ca === 'USA' && pa != 'USA') {
      this.patch(permAddress['value'], 'Permanent', 'Foreign');
    }
  }

 


  setBeneficiaryFormData() {
    let orderedArr: BeneAddress[] = [];
    this.fileUploadConfigurations.entityId = this.beneficiaryId;
    this.fileUploadConfigurations.useCaseId = this.beneficiaryId;
    this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
    this.fileUploadConfigurations.familyId = (this.beneficiaryType === 'family') ? this.beneficiaryData.id : null;
    this.currentFamilyId = this.fileUploadConfigurations.familyId;
    this.fileUploadConfigurations.family = (this.beneficiaryType === 'family') ? true : false;
    this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

    this.fileUploadPassConfigurations.entityId = this.beneficiaryId;
    this.fileUploadPassConfigurations.useCaseId = this.beneficiaryId;
    this.fileUploadPassConfigurations = Object.assign({}, this.fileUploadPassConfigurations);
    this.fileUploadPassConfigurations.familyId = (this.beneficiaryType === 'family') ? this.beneficiaryData.id : null;
    this.currentFamilyId = this.fileUploadPassConfigurations.familyId;
    this.fileUploadPassConfigurations.family = (this.beneficiaryType === 'family') ? true : false;
    this.fileUploadPassConfigurations = Object.assign({}, this.fileUploadPassConfigurations);

    if (this.beneficiaryType === 'family' && this.beneficiaryData.id === 0) {

      this.fileUploadConfigurations.listUploadedFiles = [];
      this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);

      this.fileUploadPassConfigurations.listUploadedFiles = [];
      this.fileUploadPassConfigurations = Object.assign({}, this.fileUploadPassConfigurations);
    } else {
      this.editPersonalDetailService.genericGetUploadDocument(this.fileUploadConfigurations.useCase, this.fileUploadConfigurations.useCaseId, this.fileUploadConfigurations.categoryName, this.fileUploadConfigurations.entityId, this.fileUploadConfigurations.useEntityId, this.fileUploadConfigurations.familyId).subscribe((response) => {
        if (response['status'] === 200) {
          this.fileUploadConfigurations.listUploadedFiles = response['data'];
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
        }
        else {
          this.fileUploadConfigurations.listUploadedFiles = [];
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
        }
      });

      this.editPersonalDetailService.genericGetUploadDocument(this.fileUploadPassConfigurations.useCase, this.fileUploadPassConfigurations.useCaseId, this.fileUploadPassConfigurations.categoryName, this.fileUploadPassConfigurations.entityId, this.fileUploadPassConfigurations.useEntityId, this.fileUploadPassConfigurations.familyId).subscribe((response) => {
        if (response['status'] === 200) {
          this.fileUploadPassConfigurations.listUploadedFiles = response['data'];
          this.fileUploadPassConfigurations = Object.assign({}, this.fileUploadPassConfigurations);
        }
        else {
          this.fileUploadPassConfigurations.listUploadedFiles = [];
          this.fileUploadPassConfigurations = Object.assign({}, this.fileUploadPassConfigurations);
        }
      });
    }
  
    if (this.beneficiaryData && this.beneficiaryData.title) {
      this.beneSelfDetailsForm.get('title').patchValue(this.beneficiaryData.title);
    } else {
      this.beneSelfDetailsForm.get('title').patchValue(null); 
    }
    this.beneSelfDetailsForm.get('fName').patchValue(this.beneficiaryData.fName);
    if (this.beneficiaryData && this.beneficiaryData.mName) {
      this.beneSelfDetailsForm.get('mName').patchValue(this.beneficiaryData.mName);
    } else {
      this.beneSelfDetailsForm.get('mName').patchValue(null); 
    }
    this.beneSelfDetailsForm.get('lName').patchValue(this.beneficiaryData.lName);
    this.beneSelfDetailsForm.patchValue({
      ethnicity: {
        ethnicityId: this.beneficiaryData.ethnicity && this.beneficiaryData.ethnicity.id ? this.beneficiaryData.ethnicity.id : null
      }
    });
    this.signatureImage = this.beneficiaryData.signImage !== null  ? "data:image/jpeg;base64,"+this.beneficiaryData.signImage : '';

    if (this.beneficiaryData && this.beneficiaryData.gender) {
      this.beneSelfDetailsForm.patchValue({
        gender: {
          id:  this.listGender.find(gender => gender.name ===this.beneficiaryData.gender.name).id
        }
      });
    } else {
      this.beneSelfDetailsForm.patchValue({
        gender: {
          id:null
        }
      });
    }
    if (!this.beneficiaryData.dob) {
      this.beneSelfDetailsForm.get('dob').setValue(null);
    }
    else {
      this.beneSelfDetailsForm.get('dob').patchValue(this.beneficiaryData.dob);
    }
    if (!this.beneficiaryData.marriageDate) {
      this.beneSelfDetailsForm.get('marriageDate').setValue(null);
    }
    const phoneContactsArray = this.beneSelfDetailsForm.get('phoneContacts') as FormArray;
    const phoneFormGroup = phoneContactsArray.at(0) as FormGroup;
    if (this.beneficiaryData && this.beneficiaryData?.phoneContacts[0]?.countryCode?.countryName) {
     
      phoneFormGroup.patchValue({
        countryCode: {
          countryCode:  this.listCountry.find(cuntry => cuntry.countryName ===this.beneficiaryData?.phoneContacts[0]?.countryCode.countryName).countryCode
        }
      });
    } else {
      phoneFormGroup.patchValue({
        countryCode: {
          countryCode:''
        }
      });
    }

    const addressArray = this.beneSelfDetailsForm.get('address') as FormArray;
    addressArray.controls.forEach((addressFormGroup: FormGroup, index: number) => {
      if(index==0 && this.beneficiaryData?.benCurrentAddress!=null && this.beneficiaryData?.benCurrentAddress!='undefined') {
        addressFormGroup.patchValue({
          addressLine1:  this.beneficiaryData?.benCurrentAddress?.addressLine1 ? this.beneficiaryData?.benCurrentAddress?.addressLine1 : '',
          addressLine2:  this.beneficiaryData?.benCurrentAddress?.addressLine2 ? this.beneficiaryData?.benCurrentAddress?.addressLine2 : '',
          locality:  this.beneficiaryData?.benCurrentAddress?.locality ? this.beneficiaryData?.benCurrentAddress?.locality : '',
          countryCode: {
            countryCode:this.beneficiaryData?.benCurrentAddress?.countryCode?.countryCode ? this.beneficiaryData?.benCurrentAddress?.countryCode?.countryCode : '',
          },
          stateProvinceCode:this.beneficiaryData?.benCurrentAddress?.stateProvinceCode=='USA'? this.listStatesForeign.find(x => x.stateProvinceCode === this.beneficiaryData?.benCurrentAddress?.stateProvinceCode) : '',
          stateProvinceName:this.beneficiaryData?.benCurrentAddress?.stateProvinceName!=null ? this.beneficiaryData?.benCurrentAddress?.stateProvinceName : '',
          city:  this.beneficiaryData?.benCurrentAddress?.city ? this.beneficiaryData?.benCurrentAddress?.city : '',
          postCode:  this.beneficiaryData?.benCurrentAddress?.postCode ? this.beneficiaryData?.benCurrentAddress?.postCode : null,
          id: this.beneficiaryData?.benCurrentAddress?.id ? this.beneficiaryData?.benCurrentAddress?.id : 0
        });
      }
      else if(index==1 && this.beneficiaryData?.benForeignAddress!=null && this.beneficiaryData?.benForeignAddress!='undefined') {
        addressFormGroup.patchValue({
          addressLine1:  this.beneficiaryData?.benForeignAddress?.addressLine1 ? this.beneficiaryData?.benForeignAddress?.addressLine1 : '',
          addressLine2:  this.beneficiaryData?.benForeignAddress?.addressLine2 ? this.beneficiaryData?.benForeignAddress?.addressLine2 : '',
          locality:  this.beneficiaryData?.benForeignAddress?.locality ? this.beneficiaryData?.benForeignAddress?.locality : '',
          countryCode: {
            countryCode:this.beneficiaryData?.benForeignAddress?.countryCode?.countryCode ? this.beneficiaryData?.benForeignAddress?.countryCode?.countryCode : '',
          },
          stateProvinceCode:this.beneficiaryData?.benForeignAddress?.stateProvinceCode=='USA'? this.listStatesForeign.find(x => x.stateProvinceCode === this.beneficiaryData?.benForeignAddress?.stateProvinceCode) : '',
          stateProvinceName:this.beneficiaryData?.benForeignAddress?.stateProvinceName!=null ? this.beneficiaryData?.benForeignAddress?.stateProvinceName : '',
          city:  this.beneficiaryData?.benForeignAddress?.city ? this.beneficiaryData?.benForeignAddress?.city : '',
          postCode:  this.beneficiaryData?.benForeignAddress?.postCode ? this.beneficiaryData?.benForeignAddress?.postCode : null,
          id: this.beneficiaryData?.benForeignAddress?.id ? this.beneficiaryData?.benForeignAddress?.id : 0
        });
      }
      else if(index==2 && this.beneficiaryData?.benMailingAddress!=null && this.beneficiaryData?.benMailingAddress!='undefined') {
        addressFormGroup.patchValue({
          addressLine1:  this.beneficiaryData?.benMailingAddress?.addressLine1 ? this.beneficiaryData?.benMailingAddress?.addressLine1 : '',
          addressLine2:  this.beneficiaryData?.benMailingAddress?.addressLine2 ? this.beneficiaryData?.benMailingAddress?.addressLine2 : '',
          locality:  this.beneficiaryData?.benMailingAddress?.locality ? this.beneficiaryData?.benMailingAddress?.locality : '',
          countryCode: {
            countryCode:this.beneficiaryData?.benMailingAddress?.countryCode?.countryCode ? this.beneficiaryData?.benMailingAddress?.countryCode?.countryCode : '',
          },
          stateProvinceCode:this.beneficiaryData?.benMailingAddress?.stateProvinceCode=='USA'? this.listStatesForeign.find(x => x.stateProvinceCode === this.beneficiaryData?.benMailingAddress?.stateProvinceCode) : '',
          stateProvinceName:this.beneficiaryData?.benMailingAddress?.stateProvinceName!=null ? this.beneficiaryData?.benMailingAddress?.stateProvinceName : '',
          city:  this.beneficiaryData?.benMailingAddress?.city ? this.beneficiaryData?.benMailingAddress?.city : '',
          postCode:  this.beneficiaryData?.benMailingAddress?.postCode ? this.beneficiaryData?.benMailingAddress?.postCode : null,
          id: this.beneficiaryData?.benMailingAddress?.id ? this.beneficiaryData?.benMailingAddress?.id : 0
        });
      }
      else if(index==3 && this.beneficiaryData?.benPermanentAddress!=null && this.beneficiaryData?.benPermanentAddress!='undefined') {
        addressFormGroup.patchValue({
          addressLine1:  this.beneficiaryData?.benPermanentAddress?.addressLine1 ? this.beneficiaryData?.benPermanentAddress?.addressLine1 : '',
          addressLine2:  this.beneficiaryData?.benPermanentAddress?.addressLine2 ? this.beneficiaryData?.benPermanentAddress?.addressLine2 : '',
          locality:  this.beneficiaryData?.benPermanentAddress?.locality ? this.beneficiaryData?.benPermanentAddress?.locality : '',
          countryCode: {
            countryCode:this.beneficiaryData?.benPermanentAddress?.countryCode?.countryCode ? this.beneficiaryData?.benPermanentAddress?.countryCode?.countryCode : '',
          },
          stateProvinceCode:this.beneficiaryData?.benPermanentAddress?.stateProvinceCode=='USA'? this.listStatesForeign.find(x => x.stateProvinceCode === this.beneficiaryData?.benPermanentAddress?.stateProvinceCode) : '',
          stateProvinceName:this.beneficiaryData?.benPermanentAddress?.stateProvinceName!=null ? this.beneficiaryData?.benPermanentAddress?.stateProvinceName : '',
          city:  this.beneficiaryData?.benPermanentAddress?.city ? this.beneficiaryData?.benPermanentAddress?.city : '',
          postCode:  this.beneficiaryData?.benPermanentAddress?.postCode ? this.beneficiaryData?.benPermanentAddress?.postCode : null,
          id: this.beneficiaryData?.benPermanentAddress?.id ? this.beneficiaryData?.benPermanentAddress?.id : 0
        });
      }
      // Use these values as needed
  });

    if (this.beneficiaryData && this.beneficiaryData?.citizenShip) {
      this.beneSelfDetailsForm.patchValue({
        ctznshipCountryCode: {
          countryCode:  this.listCountry.find(citiznshp => citiznshp.countryName ===this.beneficiaryData.citizenShip).countryCode
        }
      });
    } else {
      this.beneSelfDetailsForm.patchValue({
        ctznshipCountryCode: {
          countryCode:  null
        }
      });
    }
    if (this.beneficiaryData && this.beneficiaryData?.hairColor) {
      this.beneSelfDetailsForm.get('hairColor').patchValue(this.beneficiaryData.hairColor);
    } else {
      this.beneSelfDetailsForm.get('hairColor').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.eyeColor) {
      this.beneSelfDetailsForm.get('eyeColor').patchValue(this.beneficiaryData.eyeColor);
    } else {
      this.beneSelfDetailsForm.get('eyeColor').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.height) {
      this.beneSelfDetailsForm.get('height').patchValue(this.beneficiaryData.height);
    } else {
      this.beneSelfDetailsForm.get('height').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.weight) {
      this.beneSelfDetailsForm.get('weight').patchValue(this.beneficiaryData.weight);
    } else {
      this.beneSelfDetailsForm.get('weight').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.identificationMark) {
      this.beneSelfDetailsForm.get('identificationMark').patchValue(this.beneficiaryData.identificationMark);
    } else {
      this.beneSelfDetailsForm.get('identificationMark').patchValue(null); 
    }
    
    if (this.beneficiaryData && this.beneficiaryData?.mobileNo) {
      phoneFormGroup.get('phoneNo').patchValue(this.beneficiaryData.mobileNo);
    } else {
      phoneFormGroup.get('phoneNo').patchValue('');
    }
    if (this.beneficiaryData && this.beneficiaryData?.emailId) {
      const emailContactsArray = this.beneSelfDetailsForm.get('emailContacts') as FormArray;
      const emailFormGroup = emailContactsArray.at(0) as FormGroup;
      emailFormGroup.get('email').patchValue(this.beneficiaryData.emailId);
    } else {
      const emailContactsArray = this.beneSelfDetailsForm.get('emailContacts') as FormArray;
      const emailFormGroup = emailContactsArray.at(0) as FormGroup;
      emailFormGroup.get('email').patchValue('');
    }
    if (this.beneficiaryData && this.beneficiaryData?.ssn) {
      this.beneSelfDetailsForm.get('ssn').patchValue(this.beneficiaryData.ssn);
    } else {
      this.beneSelfDetailsForm.get('ssn').patchValue(null); 
    }    
    if (this.beneficiaryData && this.beneficiaryData?.birthCountryCode?.countryCode) {
      this.beneSelfDetailsForm.get('countryCode').get('countryCode').patchValue(this.beneficiaryData.birthCountryCode.countryCode);
    } else {
      this.beneSelfDetailsForm.get('countryCode').get('countryCode').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.birthState) {
      this.beneSelfDetailsForm.get('birthStateProvinceName').patchValue(this.beneficiaryData?.birthState);
    } else {
      this.beneSelfDetailsForm.get('birthStateProvinceName').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.birthCity) {
      this.beneSelfDetailsForm.get('birthCity').patchValue(this.beneficiaryData?.birthCity);
    } else {
      this.beneSelfDetailsForm.get('birthCity').patchValue(null); 
    }
    
    if (this.beneficiaryData && this.beneficiaryData?.ctznCrtfctNo) {
      this.beneSelfDetailsForm.get('ctznCrtfctNo').patchValue(this.beneficiaryData.ctznCrtfctNo);
    } else {
      this.beneSelfDetailsForm.get('ctznCrtfctNo').patchValue(null); 
    }
    if (this.beneficiaryData && this.beneficiaryData?.ctznCrtfctIssPlace) {
      this.beneSelfDetailsForm.get('ctznCrtfctIssPlace').patchValue(this.beneficiaryData.ctznCrtfctIssPlace);
    } else {
      this.beneSelfDetailsForm.get('ctznCrtfctIssPlace').patchValue(null); 
    }
    
    if (this.beneficiaryData?.ctznshipCountryCode && this.beneficiaryData?.ctznshipCountryCode?.countryCode) {
      this.showCitizenShipAquiredInfo = (this.beneficiaryData.ctznshipCountryCode.countryCode === 'USA') ? true : false;
    } else {
      this.showCitizenShipAquiredInfo = false;
    }
   

    this.signatureImage = (this.beneficiaryData['signPic'] !== null && this.beneficiaryData['signPic'] !== undefined) ? `data:image/jpeg;base64,${this.beneficiaryData['signPic']}` : '';
    if (this.beneficiaryData.address && this.beneficiaryData.address.length > 0) {
      if (this.beneficiaryData.address.find(x => x.type.code === 'CURR')) {
        orderedArr[0] = this.beneficiaryData.address.find(x => x.type.code === 'CURR');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'MAIL')) {
        orderedArr[1] = this.beneficiaryData.address.find(x => x.type.code === 'MAIL');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'FORN')) {
        orderedArr[2] = this.beneficiaryData.address.find(x => x.type.code === 'FORN');
      } else {
        orderedArr[2] = this.emptyAddress('Foreign');
      }
      if (this.beneficiaryData.address.find(x => x.type.code === 'PERM')) {
        orderedArr[3] = this.beneficiaryData.address.find(x => x.type.code === 'PERM');
      } else {
        orderedArr[3] = this.emptyAddress('Permanent');
      }
    } else {
     // orderedArr.push(this.emptyAddress('Current'), this.emptyAddress('Mailing'), this.emptyAddress('Foreign'), this.emptyAddress('Permanent'));
    }
    ///IM-3601
   this.datesStoredArrayForDisplay['dob'] = this.beneficiaryData.dob ? moment(this.beneficiaryData.dob).toISOString() : '';
    this.datesStoredArrayForDisplay['issueDate'] = this.beneficiaryData.passport!=null && this.beneficiaryData.passport[0] ? moment(this.beneficiaryData.passport[0].issueDate).toISOString() : '';
    this.datesStoredArrayForDisplay['expiryDate'] =this.beneficiaryData.passport!=null  &&  this.beneficiaryData.passport[0] ? moment(this.beneficiaryData.passport[0].expiryDate).toISOString() : '';

    this.beneficiaryData.address = orderedArr;
    // if (this.beneficiaryData && this.beneficiaryData.aliasName && this.beneficiaryData.aliasName.length > 0) {
    //   this.showAliasName = (this.beneficiaryData.aliasName[0].fName) ? true : false;
    // }

    if (this.beneficiaryData.address && this.beneficiaryData.address.length > 0) {
      if (this.beneficiaryData.address[0] && this.beneficiaryData.address[0].countryCode && this.beneficiaryData.address[0].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[0].countryCode.countryCode, 'Current');
      } else if (this.beneficiaryData.address[0] && !this.beneficiaryData.address[0].countryCode) {
        this.beneficiaryData.address[0]['countryCode'] = {
          countryCode: null,
          countryName: null
        };
      }
      if (this.beneficiaryData.address[1] && this.beneficiaryData.address[1].countryCode && this.beneficiaryData.address[1].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[1].countryCode.countryCode, 'Mailing');
      } else if (this.beneficiaryData.address[1] && !this.beneficiaryData.address[1].countryCode) {
        this.beneficiaryData.address[1]['countryCode'] = {
          countryCode: null,
          countryName: null
        };
      }
      if (this.beneficiaryData.address[2] && this.beneficiaryData.address[2].countryCode && this.beneficiaryData.address[2].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[2].countryCode.countryCode, 'Foreign');
      }
      if (this.beneficiaryData.address[2] && !this.beneficiaryData.address[2].countryCode) {
        //IM-3932-For Existing beneficiary-after clicking on edit link-country code, mobile no, email id, passport fields are showing blank
        this.beneficiaryData.address[2]['countryCode'] = {
          countryCode: null,
          countryName: null
        };
      }
      if (this.beneficiaryData.address[3] && this.beneficiaryData.address[3].countryCode && this.beneficiaryData.address[3].countryCode.countryCode) {
        this.handleCountryChange(this.beneficiaryData.address[3].countryCode.countryCode, 'Permanent');
      } else if (this.beneficiaryData.address[3] && !this.beneficiaryData.address[3].countryCode) {
        this.beneficiaryData.address[3]['countryCode'] = {
          countryCode: null,
          countryName: null
        };
      }
    }

    if (this.beneficiaryData.countryCode && this.beneficiaryData.countryCode.countryCode) {
      this.handleCountryChange(this.beneficiaryData.countryCode.countryCode, 'Birth');
    }
    if (this.beneficiaryData.countryOfMarriage && this.beneficiaryData.countryOfMarriage.countryCode) {
      this.handleCountryChange(this.beneficiaryData.countryOfMarriage.countryCode, 'Marriage');
    }
   
    if (!this.beneficiaryData?.gender) {
      this.beneficiaryData.gender = {
        id: null,
        name: null
      };
    }
    if (!this.beneficiaryData?.countryCode) {
      this.beneficiaryData.countryCode = {
        countryCode: null,
        countryName: null
      };
    }

    if (!(this.beneficiaryData?.ctznshipCountryCode && this.beneficiaryData?.ctznshipCountryCode.countryCode)) {
      this.beneficiaryData.ctznshipCountryCode = {
        countryCode: null,
        countryName: null
      };
    }

    if (!(this.beneficiaryData.countryOfMarriage && this.beneficiaryData.countryOfMarriage.countryCode)) {
      this.beneficiaryData.countryOfMarriage = {
        countryCode: null,
        countryName: null
      };
    }
    if (!(this.beneficiaryData.maritalStatus)) {
      this.beneficiaryData.maritalStatus = {
        id: null,
        name: ''
      };
    }

    if (!this.beneficiaryData?.ctznCrtfctIssDate) {
      this.beneSelfDetailsForm.get('ctznCrtfctIssDate').setValue(null);
    }


    if (!this.isPersonalDetailsFormReset) {
      this.editDetails = false;
      // this.profileEditStatus.emit(false);
    }
    this.addRaceArrayFormControl();
 
    if (this.beneficiaryData && this.beneficiaryData['phoneContacts']) {
      this.beneficiaryData['phoneContacts'].forEach((el) => {
        if (!el.countryCode) {
          el.countryCode = {
            phoneCode: 0,
            countryCode: null,
            countryName: null,
          };
        }
      });
    }
    const beneFormPatchValue = {
      ... this.beneficiaryData,
      race: [],
      hasPassport: !this.beneficiaryData['hasPassport'],
      title: this.beneficiaryData.title ? this.listTitle.find((x)=> x.code === this.beneficiaryData.title || x.name === this.beneficiaryData.title)?.code : null
    }

    try {
      this.beneSelfDetailsForm.patchValue({
        ...beneFormPatchValue,
        ctznCrtfctIssPlace: this.beneficiaryData.ctznCrtfctIssPlace,
        ctznCrtfctNo: this.beneficiaryData.ctznCrtfctNo,
        ctznAcqrTypeId: {
          ctznAcqrId: this.beneficiaryData.ctznAcqrTypeId && this.beneficiaryData.ctznAcqrTypeId.id ? this.beneficiaryData.ctznAcqrTypeId.id : null
        },
        ethnicity: {
          ethnicityId: this.beneficiaryData.ethnicity && this.beneficiaryData.ethnicity.id ? this.beneficiaryData.ethnicity.id : null
        },
        isLpr: this.beneficiaryData?.isLpr
      });
    } catch (error) {
      console.log("benedate error", error, this.beneSelfDetailsForm);
    }
    this.beneSelfDetailsForm.patchValue({
      title: this.beneficiaryData.title ? this.beneficiaryData.title : null,
      weight: this.beneficiaryData.weight ? this.beneficiaryData.weight : null
    });

    this.handleIsLprChanges();
    this.handleCitizenshipSwitchChange();

    this.beneSelfDetailsForm.disable();
    if (this.editModeVal) {
      this.editDetails = false;
      this.handleEdit();
    }
  }

  getBeneficiaryFamilyDetails() {
    this.editPersonalDetailService.getBeneficiaryDetails(this.beneficiaryId, 'family').pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiaryFamilyInfo: any) => {
      this.beneficiaryFamilyInfo = beneficiaryFamilyInfo;
      let a;
      a = this.beneficiaryFamilyInfo.find(e => e.relationShipType.code === this.detailsAbout.dependentType.code)
      // if (a) {
      //   this.hasFamily.emit(a.id);
      // }
     
    });

  }

  getStateName(stateProvinceCodes, stateIndex) {
    switch (stateIndex) {
      case 'Current':
        const selectedCurrStateObj = this.listStatesCurrent.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedCurrStateObj) ? selectedCurrStateObj.stateProvinceName : '--';
      case 'Permanent':
        const selectedPermStateObj = this.listStatesPermanent.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedPermStateObj) ? selectedPermStateObj.stateProvinceName : '--';
      case 'Permanent':
        const selectedMailStateObj = this.listStatesMailing.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedPermStateObj) ? selectedPermStateObj.stateProvinceName : '--';
      case 'Foreign':
        const selectedFornStateObj = this.listStatesForeign.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedFornStateObj) ? selectedFornStateObj.stateProvinceName : '--';
      case 'Birth':
        const selectedBirthStateObj = this.listStatesBirth.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedBirthStateObj) ? selectedBirthStateObj.stateProvinceName : '--';
      case 'Marriage':
        const selectedMarriageStateObj = this.listStatesMarriage.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedMarriageStateObj) ? selectedMarriageStateObj.stateProvinceName : '--';
      case 'Mailing':
        const selectedMailingStateObj = this.listStatesMailing.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedMailingStateObj) ? selectedMailingStateObj.stateProvinceName : '--';
    }
  }

  savePersonalData(isDraft: boolean = false) {
    if (!isDraft) {
      this.submitted = true;
      if (this.beneSelfDetailsForm.invalid) {
        return;
      }
    }

    this.beneSelfDetailsForm.patchValue({
      dob: this.beneSelfDetailsForm.get('dob').value ? moment(this.beneSelfDetailsForm.get('dob').value).format('YYYY-MM-DD') : null,
      marriageDate: this.beneSelfDetailsForm.get('marriageDate').value ? moment(this.beneSelfDetailsForm.get('marriageDate').value).format('YYYY-MM-DD') : null,
      ctznCrtfctIssDate: this.beneSelfDetailsForm.get('ctznCrtfctIssDate').value ? moment(this.beneSelfDetailsForm.get('ctznCrtfctIssDate').value).format('YYYY-MM-DD') : null
    });
    const passportExpiryDate = this.beneSelfDetailsForm.get("passport")['controls'][0].get('expiryDate').value;
    const passportIssueDate = this.beneSelfDetailsForm.get("passport")['controls'][0].get('issueDate').value;
    this.beneSelfDetailsForm.get("passport")['controls'][0].patchValue({
      expiryDate: passportExpiryDate ? moment(passportExpiryDate).format('YYYY-MM-DD') : '',
      issueDate: passportIssueDate ? moment(passportIssueDate).format('YYYY-MM-DD') : '',
    });

    const foreignAddressIndex = this.beneSelfDetailsForm.value.address.findIndex(x => x.type.code === 'FORN');
    const foreignAddressValue = (this.beneSelfDetailsForm.get('address') as FormArray)
      .controls[foreignAddressIndex]?.value;
    if (foreignAddressValue && foreignAddressValue!=-1) {
      if (!this.showForienAddressMandatory && (!foreignAddressValue.addressLine1 || (foreignAddressValue.addressLine1 && foreignAddressValue.addressLine1.trim() === '')) &&
        (!foreignAddressValue.addressLine2 || (foreignAddressValue.addressLine2 && foreignAddressValue.addressLine2.trim() === '')) &&
        (!foreignAddressValue.locality || (foreignAddressValue.locality && foreignAddressValue.locality.trim() === '')) &&
        (!foreignAddressValue.countryCode || (foreignAddressValue.countryCode && (!foreignAddressValue.countryCode.countryCode || (foreignAddressValue.countryCode.countryCode && foreignAddressValue.countryCode.countryCode.trim() === '')))) &&
        ((this.listStatesForeign.length <= 0 &&
          (!foreignAddressValue.stateProvinceName ||
            (foreignAddressValue.stateProvinceName && foreignAddressValue.stateProvinceName.trim() === ''))) ||
          (this.listStatesForeign.length > 0 && (!foreignAddressValue.stateProvinceCode ||
            (foreignAddressValue.stateProvinceCode && foreignAddressValue.stateProvinceCode.trim() === '')))) &&
        (!foreignAddressValue.city || (foreignAddressValue.city && foreignAddressValue.city.trim() === '')) &&
        (!foreignAddressValue.postCode || (foreignAddressValue.postCode && foreignAddressValue.postCode.trim() === ''))) {
        this.beneSelfDetailsForm.value.address.splice(foreignAddressIndex);
      }
    }
    let url;

    const race = [];
    this.beneSelfDetailsForm.value.race.forEach((raceBoolean, i) => {
      if (raceBoolean) {
        const raceObj = this.raceArr[i];
        race.push({
          raceId: {
            id: raceObj.id,
            name: raceObj.name
          },
          id: 0
        });
      }
    });

    const payload = {
      ... this.beneSelfDetailsForm.value,
      aliasName:[],
      userName: "",
      countryCode: {
                    "countryCode": ""
                },
       relationShipType: {
       id: this.beneficiaryData.relationShipType.id,
       name: this.beneficiaryData.relationShipType.name
        },
     
      hasCtznCrtfct: this.beneSelfDetailsForm.value.hasCtznCrtfct ? 1 : 0,
      lprThruMarriage: this.beneSelfDetailsForm.value.lprThruMarriage ? 1 : 0,
      maritalStatus: {
        ...this.beneSelfDetailsForm.value.maritalStatus,
        id: this.showMarriageSection ? this.beneSelfDetailsForm.value.maritalStatus.id : 9
      },
      ctznAcqrTypeId: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'] && this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'] !== 0 ? {
        id: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['ctznAcqrId'],
        name: this.beneSelfDetailsForm.value['ctznAcqrTypeId']['name']
      } : null,
      ethnicity: {
        id: this.beneSelfDetailsForm.value['ethnicity']['ethnicityId'],
        name: this.beneSelfDetailsForm.value['ethnicity']['name']
      },
      gender: {
        id: this.beneSelfDetailsForm.value['gender']['id'],
        name: this.beneSelfDetailsForm.value['gender']['name']
      },
      marriageStateProvinceCode: this.beneSelfDetailsForm.value['marriageStateProvinceCode'] ? this.beneSelfDetailsForm.value['marriageStateProvinceCode'] : null,
      birthCountryCode: this.beneSelfDetailsForm.value['countryCode']['countryCode'] ? this.beneSelfDetailsForm.value['countryCode'] : null,
      race,
      hasPassport: !this.beneSelfDetailsForm.value['hasPassport'],
      title: this.beneSelfDetailsForm.value['title'],
      ctznshipCountryCode: !this.beneSelfDetailsForm.value['ctznshipCountryCode']['countryCode'] ? { countryCode: null, countryName: null } : this.beneSelfDetailsForm.value['ctznshipCountryCode']
    };
    payload.countryCode.countryCode =this.beneficiaryData?.countryCode?.countryName ? this.listCountry.find(cuntry => cuntry?.countryName ===this.beneficiaryData?.countryCode?.countryName).countryCode : '';
    
    if (!(payload && payload.countryOfMarriage && payload.countryOfMarriage.countryCode)) {
      payload.countryOfMarriage = null;
    }

    let familyPayload: any = {
      family: []
    }

      payload.id = this.beneficiaryData.id ? this.beneficiaryData.id : 0;
      familyPayload.family.push(payload)
      url = this.editPersonalDetailService.saveBeneficiaryFamilyDetails(this.beneficiaryId, 'family', familyPayload)

    

    url.pipe(takeUntil(this.observableSubscription$)).subscribe((data: ImagilityBaseResponse) => {
    
      if (data.status === 200) {
        this.beneSelfDetailsForm.reset(this.beneSelfDetailsForm.value);
        this.toastr.success(data.message, 'Success');
        if (this.detailsAbout.showDetailFor === 'dependent') {
          
          this.getBeneficiaryFamilyDetails();
         
        } else {
          this.editPersonalDetailService.selfBeneficiaryDetailChange.next();
        
        }

        this.selectedCurrentCheckbox = false;
        this.selectedPermanentCheckbox = false;
        this.beneSelfDetailsForm.disable();

        this.handleEdit();
        this.editPersonalDetailService.triggerSelfDetailsSaved();
        this.backToTask();
      }
    });
  }

  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key].toString().trim() != "" && key != 'countryCode' && key != 'type')
        return false;
    }
    return true;
  }

  toggleAlias(e) {
    if (e.checked) {
      this.showAliasName = true;
    } else {
      this.showAliasName = false;
    }
  }

  resetAddress(addressType: string) {
    let addressTypeCode = '';
    switch (addressType.toLowerCase()) {
      case 'permanent':
        this.listStatesPermanent = [];
        addressTypeCode = 'PERM';
        break;
      case 'foreign':
        this.listStatesForeign = [];
        addressTypeCode = 'FORN';
        break;
      case 'mailing':
        this.listStatesMailing = [];
        addressTypeCode = 'MAIL';
        break;
    }


    const resetAddress = this.getAddress(addressTypeCode);
    if (resetAddress) {
      const resetAddressType = resetAddress.value.type;
      const resetAddressID = resetAddress.value.id;
      resetAddress.reset();
      resetAddress.patchValue(
        {
          id: resetAddressID,
          type: resetAddressType
        }
      );
    }

  }

  emptyAddress(addressType: string) {
    const addressObj = {
      id: null,
      addressLine1: null,
      addressLine2: null,
      countryCode: {
        countryCode: null,
        countryName: null,
      },
      stateProvinceCode: null,
      stateProvinceName: null,
      state: null,
      city: null,
      locality: null,
      postCode: null,
      type: { id: null, code: null, name: null }
    };
    switch (addressType) {
      case 'Current':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'CURR') ? this.addressTypeList.find(x => x.code === 'CURR').id : null,
          code: 'CURR',
          name: 'Current'
        };
        break;
      case 'Permanent':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'PERM') ? this.addressTypeList.find(x => x.code === 'PERM').id : null,
          code: 'PERM',
          name: 'Permanent'
        };
        break;
      case 'Foreign':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'FORN') ? this.addressTypeList.find(x => x.code === 'FORN').id : null,
          code: 'FORN',
          name: 'Foreign'
        };
        break;
      case 'Mailing':
        addressObj.type = {
          id: this.addressTypeList.find(x => x.code === 'MAIL') ? this.addressTypeList.find(x => x.code === 'MAIL').id : null,
          code: 'MAIL',
          name: 'Mailing'
        };
        break;
    }
    return addressObj;
  }

  copyAddress(copyFrom, copyTo) {
    if (copyFrom && copyTo) {
      const addressValue = this.getAddress(copyFrom);
      this.patch(addressValue.value, copyTo, copyTo);
    } else {
      this.resetAddress(copyTo);
    }
  }

  handleTabChange(index?) {
    const selectedDependentTab: any = this.detailsAbout.dependentType;

    this.beneSelfDetailsForm.addControl('relationShipType', this.beneSelfFormBuilder.group({
      id: [selectedDependentTab.id],
      name: [selectedDependentTab.name]
    }));
    if (this.beneficiaryFamilyInfo.length > 0) {
      if (selectedDependentTab.subTabsList) {
        const data = this.beneficiaryFamilyInfo.find(item => item.id == this.detailsAbout.selectedSubTabId);
        this.beneficiaryData = data ? data : this.emptyBeneficiaryData(selectedDependentTab);
      } else {
        const data = this.beneficiaryFamilyInfo.find(item => item.relationShipType && item.relationShipType.id === selectedDependentTab.id)
        this.beneficiaryData = data ? data : this.emptyBeneficiaryData(selectedDependentTab);
      }
    } else {
      this.beneficiaryData = this.emptyBeneficiaryData(selectedDependentTab);
    }
    this.setBeneficiaryFormData();
  }

  handleCountryChange(countryCode: string, stateIndex) {
    switch (stateIndex) {
      case 'Current':
        this.listStatesCurrent = [];
        break;
      case 'Permanent':
        this.listStatesPermanent = [];
        break;
      case 'Foreign':
        this.listStatesForeign = [];
        break;
      case 'Birth':
        this.listStatesBirth = [];
        break;
      case 'Marriage':
        this.listStatesMarriage = [];
        break;
      case 'Mailing':
        this.listStatesMailing = [];
        break;
    }

    this.editPersonalDetailService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      switch (stateIndex) {
        case 'Current':
          this.listStatesCurrent = states;
          // this.applyStateValidation(states, 0);
          // this.applyForeignAddressValidation(countryCode, states, 2); //IM-3402 fix
          break;
        case 'Permanent':
          this.listStatesPermanent = states;
          //this.applyStateValidation(states, 1);
          break;
        case 'Foreign':
          this.listStatesForeign = states;
          //this.applyForeignStateValidation(states, 2); //IM-3402 fix
          break;
        case 'Birth':
          this.listStatesBirth = states;
          //this.applyBirthStateValidation(states, countryCode);
          break;
        case 'Marriage':
          this.listStatesMarriage = states;
          //  this.applyBirthStateValidation(states);
          break;
        case 'Mailing':
          this.listStatesMailing = states;
          break;
      }
    });
  }

  handleStateChange(statecode, stateIndex) {
    this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').clearValidators();
    if (statecode === "null") {
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').setValidators(null);
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').value = null;
      this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceName').value = '';
      this.beneSelfDetailsForm.updateValueAndValidity();
    }
  }
  resetStateOnCountryChange(index: number) {
    this.beneSelfDetailsForm.get('address')['controls'][index].get('stateProvinceCode').value = null;
    this.beneSelfDetailsForm.get('address')['controls'][index].get('stateProvinceName').value = '';
  }

  resetBirthStateOnCountryChange() {
    this.beneSelfDetailsForm.get('birthStateProvinceCode').clearValidators();
    this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
    this.beneSelfDetailsForm.get('birthStateProvinceCode').setValue(null);
    this.beneSelfDetailsForm.get('birthStateProvinceName').setValue('');
    this.beneSelfDetailsForm.updateValueAndValidity();
  }

  applyStateValidation(states, type) {

      this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
      this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
    
    this.beneSelfDetailsForm.updateValueAndValidity();
  }
  applyForeignStateValidation(states, type) {
    this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
  }

  applyBirthStateValidation(states, countryCode?) {
    if (states.length > 0) {
      this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
      this.beneSelfDetailsForm.get('birthStateProvinceCode').setValidators([Validators.required]);
    }
    else {
      this.beneSelfDetailsForm.get('birthStateProvinceCode').clearValidators();
      this.beneSelfDetailsForm.get('birthStateProvinceName').clearValidators();
      if (countryCode === 'CAN') {
        this.beneSelfDetailsForm.get('birthStateProvinceName').setValidators([Validators.required]);
        this.isBirthStateRequired = true;
      } else {
        this.isBirthStateRequired = false;
      }

      //IM-3768 this.beneSelfDetailsForm.get('birthStateProvinceName').setValidators([Validators.required]);
    }
    this.beneSelfDetailsForm.updateValueAndValidity();
  }

  //IM-3402 fix
  applyForeignAddressValidation(countryCode, states, type) {
    if (countryCode === 'USA' && this.beneficiaryType === 'self') {
      this.showForienAddressMandatory = true;
      this.beneSelfDetailsForm.get('address')['controls'][type].get('addressLine1').setValidators([Validators.required]);
      this.beneSelfDetailsForm.get('address')['controls'][type].get('city').setValidators([Validators.required]);
    } else {
      this.showForienAddressMandatory = false;
      this.clearForeignAddrValidators(type);
    }
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);
  }

  customFormUpdateAndValidity(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].updateValueAndValidity();
    });
  }

  clearForeignAddrValidators(type) {
    this.beneSelfDetailsForm.get('address')['controls'][type].get('addressLine1').clearValidators();
    this.beneSelfDetailsForm.get('address')['controls'][type].get('city').clearValidators();
    this.beneSelfDetailsForm.get('address')['controls'][type].get('stateProvinceCode').clearValidators();
    this.customFormUpdateAndValidity(this.beneSelfDetailsForm.get('address')['controls'][type]);

  }
  //IM-3403 fix
  updateCountry(countryCode, field) {
    const ctznshipCountryCodeVal = this.beneSelfDetailsForm.get("ctznshipCountryCode.countryCode").value;
    if (ctznshipCountryCodeVal === 'USA') {
      this.showCitizenShipAquiredInfo = true;
      if (this.beneficiaryType === 'self') {
        this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').setValidators(Validators.required);
        this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').updateValueAndValidity();
      }
    } else {
      this.showCitizenShipAquiredInfo = false;
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').clearValidators();
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').reset(null);
      this.beneSelfDetailsForm.get('hasCtznCrtfct').reset(false);
      this.beneSelfDetailsForm.get('ctznAcqrTypeId.ctznAcqrId').updateValueAndValidity();
      this.setValidatorForCitizenShipAquired()
    }
    if (field === 'citizenship') {
      this.beneSelfDetailsForm.get("ctznshipCountryCode").patchValue({
        countryCode: countryCode,
      });
      this.updateCountry(countryCode, 'issueCountryCode');
    } else {

      this.beneSelfDetailsForm.get("passport")['controls'][0].get('issueCountryCode').patchValue({
        countryCode: countryCode,
      });

    }
  }

  resetCitizenShipAquiredValues() {

    const ctznCrtfctNoCtrl = this.beneSelfDetailsForm.get('ctznCrtfctNo') as FormControl;
    const ctznCrtfctIssPlaceCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssPlace') as FormControl;
    const ctznCrtfctIssDateCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssDate') as FormControl;
    const validationCitizenshipArr = [ctznCrtfctNoCtrl, ctznCrtfctIssPlaceCtrl, ctznCrtfctIssDateCtrl];
    for (let i = 0; i < validationCitizenshipArr.length; i++) {
      validationCitizenshipArr[i].patchValue(null);
      validationCitizenshipArr[i].clearValidators();
      validationCitizenshipArr[i].reset();
      validationCitizenshipArr[i].markAsPristine();
      validationCitizenshipArr[i].updateValueAndValidity();
    }

  }

  setValidatorForCitizenShipAquired() {
    const ctznCrtfctNoCtrl = this.beneSelfDetailsForm.get('ctznCrtfctNo') as FormControl;
    const ctznCrtfctIssPlaceCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssPlace') as FormControl;
    const ctznCrtfctIssDateCtrl = this.beneSelfDetailsForm.get('ctznCrtfctIssDate') as FormControl;
    const validationCitizenshipArr = [ctznCrtfctNoCtrl, ctznCrtfctIssPlaceCtrl, ctznCrtfctIssDateCtrl];
    for (let i = 0; i < validationCitizenshipArr.length; i++) {
      if (this.showCitizenShipAquiredInfo) {
        validationCitizenshipArr[i].setValidators(Validators.required);
        ctznCrtfctNoCtrl.setValidators([Validators.required, Validators.pattern(this.certificateNumberValidPattern)]);
        ctznCrtfctIssPlaceCtrl.setValidators([Validators.required, Validators.pattern(this.textOnlyValidPattern)]);

      } else {
        validationCitizenshipArr[i].patchValue(null);
        validationCitizenshipArr[i].reset();
        validationCitizenshipArr[i].clearValidators();
      }
      validationCitizenshipArr[i].updateValueAndValidity();
    }


  }

  foreignAddChange($event: MatRadioChange) {
    if (this.showforeignAddress && $event.value === 'PA') {
      let Address = this.getAddress('PERM');
      this.patch(Address['value'], 'Permanent', 'Foreign');
    } else if (this.showforeignAddress && $event.value === 'CA') {
      let Address = this.getAddress('CURR');
      this.patch(Address['value'], 'Current', 'Foreign');
    }
  }

  getAddress(code): FormGroup {
    let Address: any;
    for (const control of (this.beneSelfDetailsForm.get('address') as FormArray).controls) {
      if (control.value.type.code === code) {
        Address = control;
        return Address;
      }
    }
  }

  permanentAddChange($event: MatRadioChange) {
    if ($event.value === 'CA') {
      this.patch(
        this.beneSelfDetailsForm.get('address').get('0').value, 'Current', 'Permanent');
    }
  }
  getAddressGroup(addrType) {
    let temp = this.beneSelfDetailsForm.get('address');
    if (temp instanceof FormArray) {
      //console.log(temp.controls.findIndex(x => x.value['type']['code'] === addrType));
      return temp.controls.findIndex(x => x.value['type']['code'] === addrType);
      
    }
  }
  validateExpiry(issueDate, expiryDate) {
    this.showExpiryValidation = this.editPersonalDetailService.MaxDateValidate(issueDate, expiryDate);
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  handleCitizenshipSwitchChange() {
    if (!this.beneSelfDetailsForm.get('hasCtznCrtfct').value) {
      this.resetCitizenShipAquiredValues();
    } 

  }
  handleLawfulSwitchChange() {

  }
  handleIsLprChanges() {
    // const isLprVlaue = this.beneSelfDetailsForm.get('isLpr').value;
    // const greenCardControl = this.beneSelfDetailsForm.get('greenCardNo');
    // const lprThruMarriageCtrl = this.beneSelfDetailsForm.get('lprThruMarriage');

    //   greenCardControl.clearValidators();
    //   greenCardControl.patchValue(null);
    //   lprThruMarriageCtrl.patchValue(null);
    
    // greenCardControl.updateValueAndValidity();
  }

  handleRaceSelection(isChecked, id, index) {
    this.beneSelfDetailsForm.get('race')['controls'][index].get('raceId').patchValue({
      checked: isChecked ? true : false
    });
  }



  emptyBeneficiaryData(selectedDepedent?): BeneficiaryDetails {
    this.submitted = false;
    return {
      id: 0,
      title: '',
      fName: '',
      mName: '',
      lName: ' ',
      profilePic: ' ',
      visaSponsor: '',
      aliasName: [{
        id: 0,
        title: '',
        fName: '',
        mName: '',
        lName: '',
      }],
      aliasNames: '',
      currentVisaStatus: '',
      visaStatus: '',
      birthState: null,
      birthCity: '',
      birthStateProvinceCode: '',
      birthStateProvinceName: '',
      ctznshipCountryCode: {
        countryCode: '',
        countryName: null,
      },
      gender: {
        id: 0,
        name: null,
      },
      countryCode: {
        countryCode: null,
        countryName: null,
      },
      dob: null,
      identificationMark: '',
      height: null,
      skinColor: null,
      eyeColor: '',
      hairColor: '',
      phoneContacts: [
        {
          id: 0,
          phoneNo: null,
          countryCode: {
            phoneCode: 0,
            countryCode: null,
            countryName: null,
          },
          type: {
            code: '',
            name: '',
          }
        }
      ],
      emailContacts: [{
        email: '',
        id: 0,
        type: {
          code: 'PRIM',
          id: this.emailTypeList.find(x => x.code === 'PRIM') ? this.emailTypeList.find(x => x.code === 'PRIM').id : null
        }
      }],
      hasPassport: true,
      passport: [{
        id: 0,
        passportNo: '',
        issueDate: null,
        expiryDate: null,
        issueCountryCode: {
          countryCode: null,
          countryName: null,
        }
      }],
      addressDifferFlag: null,
      address: [
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: null,
            countryName: null,
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'CURR',
            name: 'Current',
            id: 1,
          },
        },
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: null,
            countryName: null,
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'MAIL',
            name: 'Mailing',
            id: 5,
          },
        },
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: null,
            countryName: null,
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'FORN',
            name: 'FOREIGN',
            id: 232,
          },
        },
        {
          id: 0,
          addressLine1: '',
          addressLine2: '',
          countryCode: {
            countryCode: null,
            countryName: null,
          },
          stateProvinceCode: '',
          stateProvinceName: '',
          state: null,
          city: '',
          locality: '',
          postCode: null,
          type: {
            code: 'PERM',
            name: 'Permanent',
            id: 2,
          },
        }
      ],
      ssn: '',
      relationShipType: {
        id: selectedDepedent && selectedDepedent.id ? selectedDepedent.id : 0,
        name: selectedDepedent && selectedDepedent.name ? selectedDepedent.name : ''
      },
      maritalStatus: {
        id: selectedDepedent && ['SPOUSE', 'MOTHER'].includes(selectedDepedent.code) ? 9 : null,
        name: '',
      },
      countryOfMarriage: {
        countryCode: null,
        countryName: null,
      },
      marriageStateProvinceCode: null,
      marriageStateProvinceName: null,
      cityOfMarriage: null,
      isLpr: null,
      lprThruMarriage: 0,
      greenCardNo: null,
      ethnicity: {
        id: 0,
        ethnicityId: 0,
        name: '',
      },

      race: null,
      weight: 0,
      ctznAcqrTypeId: {
        ctznAcqrId: null,
        id: null,
        name: '',
      },
      hasCtznCrtfct: null,
      ctznCrtfctNo: null,
      ctznCrtfctIssPlace: null,
      ctznCrtfctIssDate: null,
      marriageDate: null,
      profileImage: null,
    }

  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  uploadSignatureImage() {
    const dialogRef = this.dialog.open(SignatureUploadComponent, {
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.sessionpicimage = sessionStorage.setItem("SesionpicValue", this.signatureImage)
      const file = this.dataURItoBlob(result);
      let reader = new FileReader();
      let self = this;

      reader.readAsDataURL(file);
      reader.onload = function () {
        self.signatureImage = reader.result as string;
      };
      reader.onerror = function (error) {
      };

      this.signatureImageForm = new FormData();
      this.signatureImageForm.append("file", file, "mysignature.png");



      if (this.beneficiaryData.id === this.beneficiaryId) {
        this.editPersonalDetailService.postSignatureImageBen(this.beneficiaryId, this.signatureImageForm).subscribe((response) => {
          if (response['status'] === 200) {
            this.toastr.success('Image Uploaded successfully', 'Success');
          }
          else {
            CustomErrorToastComponent.showErrorToast(this.toastr, 'Image is not Uploaded successfully');
          }
        });
      } else {
        if (this.beneficiaryData.id !== 0) {
          this.editPersonalDetailService.postFamilySignatureImageBen(this.beneficiaryId, this.beneficiaryData.id, this.signatureImageForm).subscribe((response) => {
            if (response['status'] === 200) {
              this.toastr.success('Image Uploaded successfully', 'Success');
            }
            else {
              CustomErrorToastComponent.showErrorToast(this.toastr, 'Image is not Uploaded successfully');
            }
          });
        }

      }
    });
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  getSignatureImage() {
    let signImage = "";
    if (this.beneficiaryIdFromFB === this.beneficiaryData.id) {
      this.editPersonalDetailService.getSignatureImageBen(this.beneficiaryIdFromFB).subscribe((response) => {
        if (response['status'] === 200) {
          this.signatureImage = (response['data']['signImage'] !== null && response['data']['signImage'] !== undefined) ? `data:image/jpeg;base64,${response['data']['signImage']}` : '';
          //alert(this.sessionpicimage);
        }
      });
    }

  }

  backToOriginPage() {
    sessionStorage.removeItem('redirectUrl');
  }

  fileUploadSuccess() {
    // this.getTrainings();
  }

  backToTask() {
    this.closePersonalEdit.emit(true);
  }
  removeValidations(formElement: FormGroup | FormArray) {
    Object.keys(formElement.controls).forEach(field => {
      const control = formElement.get(field);
      if (control instanceof FormControl) {
        control.clearValidators();
        control.updateValueAndValidity();
      } else if (control instanceof FormArray) {
        this.removeValidations(control)
      } else if (control instanceof FormGroup) {
        this.removeValidations(control)
      }
    });
  }

  saveAsDraft(event) {
    this.savePersonalData(event);
  }

}
