import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-allocation-container',
  templateUrl: './task-allocation-container.component.html',
  styleUrls: ['./task-allocation-container.component.scss']
})
export class TaskAllocationContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
