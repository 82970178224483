import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { ImagilityWebStorage } from '../../../services/webStorage.service';
import * as utils from "src/app/modules/utility/global-utils";

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(private webStorage: ImagilityWebStorage) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
       // if (this.webStorage.getWebStorageItem('currentUser') && this.webStorage.getWebStorageItem('currentUser')['jwt']) {
            if (localStorage.getItem('UserToken') && localStorage.getItem('UserToken') !== 'null') {    
            let token = localStorage.getItem('UserToken');    
            if(!request.url.includes('google.com') && !request.url.includes('googleapis.com')){ // by passing imagility bearer token for google authentication api's
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': '*/*',
                    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
                }
            });
        }
        }
        return next.handle(request);
    }
}