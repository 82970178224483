import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../../../../environments/environment';
import { ApiServicesService } from '../../../services/api-services.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { UploadDocumentService } from 'src/app/services/upload-document.service';
import { UtilityServiceService } from '../../../services/utility-service.service';
import * as Roles from '../../../../../data/constants/roles';
import { FileDownloadComponent } from 'src/app/modules/shared/file-download/file-download.component';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-degree',
  templateUrl: './list-degree.component.html',
  styleUrls: ['./list-degree.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ListDegreeComponent implements OnInit {
  @Input() educationList: any = [];
  @Input() isLocked: boolean; 
   

  updateBenProfile: Boolean = true;
  educationDetails: any = [];
  beneficiaryId: any;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  expandedElement: any | null;
  envPath: any;
  viewMode: boolean = false;
  showEditButton: boolean = true;
  onlyFormBuilder;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() enableEdit: EventEmitter<number> = new EventEmitter();
  @Output() removeElement: EventEmitter<number> = new EventEmitter();

  columns = [
    { columnDef: 'toggle', header: '', columnType: 'icon', customClass: '' },
    { columnDef: 'degreeType', header: 'Degree', columnType: 'text', customClass: '' },
    { columnDef: 'fieldOfStudy', header: 'Field Of Study', columnType: 'text', customClass: '' },
    { columnDef: 'startYear', header: 'Start Year', columnType: 'text', customClass: '' },
    { columnDef: 'endYear', header: 'End Year', columnType: 'text', customClass: '' },
    { columnDef: 'grade', header: 'Grade', columnType: 'text', customClass: '' },
    { columnDef: 'university', header: 'University', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }];


  isFBUser: boolean;

  constructor(private apiService: ApiServicesService,
    private authenticationService: AuthenticationService,
    private utilityService: UtilityServiceService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private benServ: BeneficiaryQuestionnaireService) {
      this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
    }

  ngOnInit() {
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
          this.viewMode = !this.updateBenProfile;
      }
    });
    this.envPath = environment.docs;
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    this.showEditButton = !this.benServ.checkValidity();
  }

  ngOnChanges() {
    if (this.educationList) {
      this.educationDetails = this.educationList;
      this.educationDetails = this.educationDetails.map(e1 => {
        let _e1 = {...e1, startYear: e1.startYear === 0 ? '' : e1.startYear, endYear: e1.endYear === 0 ? '' : e1.endYear,};
        _e1.documents = _e1.documents?.map(e2 => {
          let _e2 = {...e2};
          _e2.fileLocationName = this.isFBUser ? _e2.fileLocation.split('/').slice(-1)[0] : _e2.fileLocation.substring(23);

          return _e2;
        });
        return _e1;
      });
      this.dataSource = new MatTableDataSource(this.educationDetails);
    } 
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  handleEdit(educationElement) {    
    this.enableEdit.emit(educationElement);
  }

  handleDelete(educationElement) {
    const title = 'Confirm';
    const message = 'Do you wish to Delete the File?';
    if (educationElement) {
      this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {        
        if (result) {
          this.removeElement.emit(educationElement);
        }
      })
    }
  }

  // public download(downloadUrl: string,text): void {
  //   var element = document.createElement('a');
  //   element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(text));
  //   element.setAttribute('download', downloadUrl);

  //   element.style.display = 'none';
  //   document.body.appendChild(element);

  //   element.click();

  //   document.body.removeChild(element);

  //     //window.open(downloadUrl, '_blank');
  //   }




  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

}
