import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-platform-admin-menu',
  templateUrl: './platform-admin-menu.component.html',
  styleUrls: ['./platform-admin-menu.component.scss']
})
export class PlatformAdminMenuComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  //  this.router.navigate(['dashboard'], { relativeTo: this.route });
  }

}
