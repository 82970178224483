import { Injectable, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({ 'providedIn': 'root' })

export class ImagilityWebStorage {
    reloadSubject: BehaviorSubject<any>;
    reloadTrigger: Observable<any>;
    constructor(private router: Router) {         
        this.reloadSubject = new BehaviorSubject<any>(false);
        this.reloadTrigger = this.reloadSubject.asObservable();
    }

    setWebStorageItem(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    getWebStorageItem(key: string) {
        return JSON.parse(sessionStorage.getItem(key));
    }

    removeWebStorageItem(key: string) {
        sessionStorage.removeItem(key);
    }
    /*IM3069*/
    startLocalstorageEventListening(): void {
        window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    storageEventListener(event: StorageEvent) {
        if (event.target.hasOwnProperty('localStorage') && event.key == 'session_end') {
            localStorage.setItem('UserToken', null);
            localStorage.setItem('userTypeRole', null);
            sessionStorage.clear();      
            this.router.navigate(['/login']);
        }
        if (event.target.hasOwnProperty('localStorage') && localStorage.getItem('UserToken') !== 'null' && event.key == 'UserToken') {
            if(sessionStorage.getItem('currentUser') && sessionStorage.getItem('currentUser') !== 'null'){
                if(localStorage.getItem('UserToken') !== JSON.parse(sessionStorage.getItem('currentUser'))['jwt']){
                    this.reloadSubject.next(true);
                }
            }            
        }
    }

    stopLocalstorageEventListening(): void {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
    }
    
    sessionEndTimestamping(): void {
        let timestamp = new Date().getTime();
        localStorage.setItem('session_end', timestamp.toString());
    }
   /*IM3069*/
 
}