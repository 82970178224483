<div class="row pt-2">
  <div class="col-9 px-0">
    <h5 class="joyride-step__title">{{content.title}}</h5>
  </div>
  <div class="col-3 px-0">
    <div class="navigationButtons text-right">
      <button type="button" class="btn" (click)="prevOne()" *ngIf="current != 1">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
      </button>
      <label> Step {{current}}/{{total}}</label>
      <button type="button" class="btn" (click)="nextOne()" *ngIf="current != total">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </button>
      <div *ngIf="current == total" style="width:44px" class="d-inline-block">
      </div>
    </div>
  </div>
</div>


<div [innerHTML]="content.headerOne"></div>
  <img *ngIf="content.image != ''" [src]="content.image" width="650px" class="my-3"/>
  <br *ngIf="content.image == ''">
  <div *ngIf="content.videoLink != ''">
  <label>Watch</label>
  <button type="button" class="d-inline-block link-button" (click)="openVideo()">{{content.videoLink}}
  </button>
  <label *ngIf="content.watchLabel">{{content.watchLabel}}</label>
</div>
<br *ngIf="content.videoLink == ''">
  <div class="text-right">
    <div class="d-inline-block mr-2 align-middle" [innerHTML]="content.nextButtonText"></div>
    <button type="button" class="btn btn-primary" (click)="nextOne()" *ngIf="current != total">
      Next
    </button>
    <button type="button" class="btn btn-primary" (click)="close()" *ngIf="current == total">
      Done
    </button>
  </div>
  <hr/>
  <div class="text-left">
    <div class="d-inline-block">
  <h6 class="d-inline-block mb-0"><strong>Assistance at any time:</strong></h6>
  <div class="text-secondary">Click on the floating help button on the top right of the page</div>
  </div>
  <button mat-dialog-close (click)="routeToResourcePage()" class="rounded-pill back-button button-width-icon-left btn-pos-top-right btn button_whitebgImp float-right">
      <i class="fa fa-question-circle"></i>
      Video Help
  </button>
  </div>
  <hr/>
  <div class="text-center">
  <button type="button" class="link-button btn" (click)="close()">Dismiss</button>
</div>