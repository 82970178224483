import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryQuestionnaireService {

  private data;
  public isExpired = false;
  public checkFormValid = new Subject<any>();
  public isExperienceChanged = new Subject<any>();
  public isEducationDetailsChanged = new Subject<any>();
  public checkFormValidRes = new Subject<any>();
  isFormValid = this.checkFormValid.asObservable();
  isFormValidChecked = this.checkFormValidRes.asObservable();
  selfDetailsSaved = new Subject<any>();

  triggerSelfDetailsSaved(){
    this.selfDetailsSaved.next('success');
  }
  experienceChanged(data){
    this.isExperienceChanged.next(data);
  }
  educationDetailsChanged(data){
    this.isEducationDetailsChanged.next(data);
  }

  getBenificiaryDetails() {
    return this.data;
  }

  addBenificiary(data: string) {
    this.data = data;
  }

  checkValidity() {
    return this.isExpired;
  }


onMessage(): Observable<any> {
  return this.checkFormValidRes.asObservable();
}

  addValidity(val: boolean) {
    this.isExpired = val;
  }


}
