import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-faq-dashboard',
  templateUrl: './faq-dashboard.component.html',
  styleUrls: ['./faq-dashboard.component.scss']
})
export class FaqDashboardComponent implements OnInit {
  isOpen = false;
  faqList:any[]=[];
  beneficiaryId;
  constructor(private router: Router, private route: ActivatedRoute,private faqService:FaqService) { }

  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnInit(): void {
    this.beneficiaryId = sessionStorage.getItem('beneficiaryId');
    this.getMostlyViewedFaq();
  }
  getMostlyViewedFaq(){
    this.faqService.getFaqListByMostlyViewed(this.beneficiaryId).subscribe((response)=>{
         if(response){
           console.log("beneficiary list",response)
           this.faqList = response;
         }
    })
  }
  
  goToBeneficiaryFaqDetailsPage(){
    this.isOpen = false;
    this.router.navigate(['../../faq/beneficiary-faq'], {relativeTo: this.route});

  }

}
