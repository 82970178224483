<div class="container-fluid">


    <div class="card mb-4">
        <div class="card-body">
            <h4>List Of Form</h4>
        </div>
    </div>

    <ng-container *ngIf="totalRecordCount > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Forms Column -->
            <ng-container matColumnDef="form">
                <th mat-header-cell *matHeaderCellDef> Forms </th>
                <td mat-cell *matCellDef="let element"> {{element.form}} </td>
            </ng-container>

            <!-- VISA Type Column -->
            <ng-container matColumnDef="visaType">
                <th mat-header-cell *matHeaderCellDef> VISA Type </th>
                <td mat-cell *matCellDef="let element"> {{element.visaType}} </td>
            </ng-container>

            <!-- Filing Type Column -->
            <!-- <ng-container matColumnDef="lastUpdatedTime">
            <th mat-header-cell *matHeaderCellDef> Filing Type </th>
            <td mat-cell *matCellDef="let element"> {{element.lastUpdatedTime}} </td>
        </ng-container> -->


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
    </ng-container>

    <div class="no-data" *ngIf="totalRecordCount === 0">
        <h6>No Data Found!</h6>
    </div>

</div>