<ng-template [ngIf]="!singleButtonUpload">
  <div class="row" [ngClass]="{'fileDahedBorderBox': !buttonOnlyMode}" *ngIf="isUploadEnabled">
    <div class="col-4 mt-1" *ngIf="documentTypeRequired">
      <div class="form-group">
        <label class="label-cls d-block">Document Type</label>
        <select class="im-input mt-2 mw-100 d-block" *ngIf="documentTypes?.length > 1" [(ngModel)]="fileCategory"
          name="fileCategoryType" #fileCategoryType="ngModel" (change)="setOtherNameEmpty()" required>
          <option value="">Select</option>
          <option *ngFor="let type of documentTypes" [value]="type.code">
            {{ type.name }}
          </option>
        </select>
        <input type="text" [value]="selectedDocType" class="im-input mt-2 mw-100 d-block"
          *ngIf="documentTypes?.length == 1" readonly />
      </div>
    </div>
    <div class="col-4 form-group" *ngIf="fileCategory === 'OTHER'">
      <label>Document Type Name</label>
      <input type="text" [(ngModel)]="otherName" class="im-input" />
    </div>
    <div class="col-4" [ngClass]="{'marginTop':!buttonOnlyMode}">
      <div *ngIf="isMultiple">
        <div class="form-group">
          <button [disabled]="entityId === 0 || !fileCategory || (family && !familyId)" type="button"
            class="im-btn im-btn-md im-btn-primary" multiple="1" ngfSelect [accept]="acceptFileType" [(files)]="files">
            Browse
          </button>
          <label class="label-cls single-file-upload d-block">Max file size is 10 MB | PDF file type is allowed</label>
        </div>
      </div>
      <div *ngIf="!isMultiple">
        <div class="form-group mb-0" [ngClass]="{'w-200':buttonOnlyMode}">
          <button [disabled]="entityId === 0 || !fileCategory || this.inputMultipleFlag === null || this.inputMultipleFlagFed === null || this.inputMultipleFlagState === null || (family && !familyId) || (fileCategory === 'OTHER' && otherName.trim() ==='')" type="button"
            class="btn-primary im-btn-md im-btn-primary" ngfSelect [accept]="acceptFileType" [(files)]="files"
            (filesChange)="onFileSelected($event)">
            <div class="btnModeBtnContent">
              <span>
                <mat-icon *ngIf="buttonOnlyMode" class="material-icons">upload_file</mat-icon>
              </span>
              <span>
                {{singleFileTypeName}}
              </span>
            </div>
          </button>
          <label class="label-cls single-file-upload d-block">{{acceptedTypesText}}</label>
        </div>
      </div>
    </div>
  </div>
  <ngfFormData [files]="files" *ngIf="isViewEnabled" [(FormData)]="uploadedFilesData" postName="file"></ngfFormData>
  <div class="pt-2 marginBottom" *ngIf="family && familyId == null && isFileListRequired && files && files.length > 0">
    <div>
      <h5>Files To Be Uploaded</h5>
    </div>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="files.length=0"
          [disabled]="!files.length"><i class="fa fa-trash" aria-hidden="true"></i> Remove all </button>
      </div>
    </div>
    <table class="im-card-no-border-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Size</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of files;let i=index">
          <td>
            <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.type)>=0">
              <div class="previewIcon" [ngfBackground]="item"></div>
            </div>
            <strong>{{ item.name }}</strong>
          </td>
          <td nowrap>
            {{ item.type }}
          </td>
          <td nowrap>
            {{ item.size/1024/1024 | number:'.2' }} MB
          </td>
          <td nowrap>
            <div class="d-flex flex-row">
              <div class="p-2 padding0">
                <span (click)="files.splice(i,1)" title="Remove"><i class="fa fa-trash fa-lg icn"
                    aria-hidden="true"></i></span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ngfFormData [files]="files" [(FormData)]="uploadedFilesData" postName="file"></ngfFormData>
  </div>

  <div class="pt-2 marginBottom" *ngIf="listFiles && listFiles.length > 0">
    <h5 class="heading">List of Uploaded Documents</h5>
    <table class="im-card-no-border-table file-upload-list table-striped-simple">
      <thead>
        <tr>
          <th>Document Name</th>
          <th>Document Type</th>
          <th>Size</th>
          <th *ngIf="isViewEnabled || isDownloadEnabled || isDeleteEnabled" class="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="wrap-word">
        <tr *ngFor="let item of listFiles; let i = index; trackBy: trackItem">
          <td >
            <strong><a class="cursorPointer" (click)="openDoc(item)">{{
                item.fileName
                }}</a></strong>
          </td>
          <td  nowrap>
            {{
            item.fileCategory.code === "OTHER"
            ? item.fileCategoryOtherName
            ? item.fileCategoryOtherName
            : item.fileCategory.name
            : item.fileCategory.name
            }}
          </td>
          <td  nowrap>{{ item.fileSize / 1024 / 1024 | number: ".2" }} MB</td>
          <td  nowrap *ngIf="(isViewEnabled || isDownloadEnabled || isDeleteEnabled) ">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div class="mr-2 padding0" *ngIf="isViewEnabled">
                <span *ngIf="item.fileLocation" (click)="openDoc(item)">
                  <mat-icon class="mr-1 icn" matTooltipClass="tooltip-bg" matTooltip="View Document"
                    class="material-icons">visibility</mat-icon>
                </span>
              </div>
              <div *ngIf="item.fileLocation && isDownloadEnabled" class="mr-2 padding0">
                <file-download-library [fileName]="item.fileLocationName"
                [Path]="envPath + item.fileLocation.substring(23)">
              </file-download-library>
              </div>
              <div class="mr-2 padding0" *ngIf="isDeleteEnabled && false">
                <span (click)="deleteFiles(i, item)">
                  <mat-icon class="mr-1 icn" matTooltipClass="tooltip-bg" matTooltip="Delete Document"
                    class="material-icons">delete</mat-icon>
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pt-2 text-center marginBottom" *ngIf="!listFiles || (listFiles && listFiles.length === 0)">
    <h5>No Documents Uploaded</h5>
  </div>
</ng-template>
<ng-template [ngIf]="singleButtonUpload">
  <ngfFormData [files]="files" *ngIf="isViewEnabled" [(FormData)]="uploadedFilesData" postName="file"></ngfFormData>
  <div class="d-inline-block" [ngClass]="{'disabled': entityId === 0}"
    *ngIf="!listFiles || (listFiles && listFiles.length === 0)">
    <button [disabled]="entityId === 0 || !fileCategory" type="button" matTooltipClass="tooltip-bg"
      class="btn btn-outline-primary single-file-upload" ngfSelect [accept]="acceptFileType" [(files)]="files"
      (filesChange)="onFileSelected($event)">{{ singleFileTypeName
      }}</button>
    <label class="label-cls single-file-upload d-block">Max file size is 10 MB | PDF file type is allowed</label>
  </div>
  <div *ngIf="listFiles && listFiles.length > 0" class="d-inline-block" class="single-fileuploaded-view-holder">
    <span class="file-uploaded">{{ listFiles[0].fileNameWithExtension }}</span>
    <file-download-library [fileName]="listFiles[0].fileLocation.substring(23)"
    [Path]="envPath + listFiles[0].fileLocation.substring(23)" class="ml-3 pr-2"></file-download-library>
    <mat-icon *ngIf="false" class="mr-1 icn" (click)="deleteFiles(0, listFiles[0])" matTooltip="Delete Document"
      matTooltipClass="tooltip-bg" class="material-icons">delete</mat-icon>
  </div>
</ng-template>
