import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {MatAccordion} from '@angular/material/expansion';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'beneficiary-menu',
  templateUrl: './beneficiary-menu.component.html',
  styleUrls: ['./beneficiary-menu.component.css'],
})

export class BeneficiaryMenuComponent implements OnInit {
  //faqList: { questioId: number; topicId: number; topicName: string; subtopicId: number; subtopicName: string; companyId: string; companyName: string; question: string; answer: string; newlyAddedFlag: string; createdDate: string; modifiedDate: string; }[];
  constructor(private router: Router, private route: ActivatedRoute,private faqService:FaqService) { }

  @ViewChild(MatAccordion) accordion: MatAccordion;

  isOpen = false;
  faqList:any[]=[];
  beneficiaryId;

  ngOnInit(): void {
    this.beneficiaryId = sessionStorage.getItem('beneficiaryId');
    this.getMostlyViewedFaq();
    this.router.events.subscribe(val => {
     if(val){
       this.isOpen = false;
     }
    });
  };
  loadFaq(){
    if(this.isOpen){
      this.getMostlyViewedFaq()
    }

  }
  getMostlyViewedFaq(){
    this.faqService.getFaqListByMostlyViewed(this.beneficiaryId).subscribe((response)=>{
         if(response){
          //  console.log("beneficiary list",response)
           this.faqList = response;
         }
    })
  }

  goToBeneficiaryFaqDetailsPage(){
    this.isOpen = false;
    this.router.navigate(['faq/beneficiary-faq'], {relativeTo: this.route});

  }
}
