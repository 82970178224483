import { createAction, props } from '@ngrx/store';

export const updatei140StepStatus = createAction(
  '[i140StepStatusUpdate] Update StepStatusUpdates',
  props<{ payload: any, subTaskId: number, actionCode: string }>()
);

export const updateStepStatusSuccess = createAction(
  '[i140StepStatusUpdate] Update StepStatusUpdates Success',
  props<{ response: any }>()
);

export const updateStepStatusFailure = createAction(
  '[i140StepStatusUpdate] Update StepStatusUpdates Failure',
  props<{ error: any }>()
);

export const updateStepStatusToInitialState = createAction(
  '[i140StepStatusUpdate] Update StepStatusUpdates InitialState'
);
