<div class="fluid-container">
  <form (ngSubmit)="postOccupationDetails()" #occupationDetailsForm="ngForm" class="pb-3 pt-2">
    <div class="main-container">
      <div class="row mb-3">
        <div class="col-md-6 form-group input-disabled">
          <label class="label-cls required">LCA Code</label>
          <input pInputText type="text" name="lcaCode" [(ngModel)]="lcaCode" [disabled]="disabledControls" readonly
            class="im-input  mw-100 disable" required />
        </div>
        <div class="col-md-6 form-group input-disabled">
          <label class="label-cls required">Case Number</label>
          <input pInputText type="text" name="caseNumber" [(ngModel)]="caseNumber" [disabled]="disabledControls"
            readonly class="im-input disable  mw-100" required />
        </div>
      </div>
      <div class="row col-md-12 mb-3 mt-3" id="textarea1">
        <label class="label-cls">Enter Occupation Details</label>
        <kendo-editor [(value)]="occupationDescription" (valueChange)="occupationdetailseditorValueChange($event)"
          #occupationdetailskendoeditor style="height: 400px;" name="description">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
              <kendo-toolbar-button
              title="Upload’s Image at cursor point"
              text="Upload Image"
              (click)="openoccupationdetailsimageuploaddialog()"
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>                                  
          </kendo-toolbar>
        </kendo-editor>
        <app-upload-image-dialog #occupationdetailsimageupload [editor]="occupationdetailskendoeditor"></app-upload-image-dialog>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row">
      <div class="col-md-12">
        <button pButton type="submit" label="Save" title="Save" class="save-btn"
          [ngClass]="occupationDetailsForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="occupationDetailsForm.invalid  || disabledControls"></button>
      </div>
    </div>
  </form>
</div>
