import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GCI140State, storeFeatureKey } from '..';

export const getGCI140Feature = createFeatureSelector<GCI140State>(
    storeFeatureKey
);

// return whether status update is succesful or failure
export const getStepStatusUpdate = createSelector(
    getGCI140Feature,
    (state: GCI140State) => {
        return state.stepStatusUpdate;
    }
);