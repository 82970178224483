<div class="modalBoxContainer">

  <div class="modelTitleBox">
    <div class="row">
      <div class="col-11"><h3>Upload Files</h3></div>
      <div class="col-1 rounded-circle">  <i class="fa fa-close" style="font-size:24px;font-weight: 500;" [mat-dialog-close]></i></div>
    </div>
  </div>
  
 <div class="dialogContentBox">
      <div *ngIf="showFileUploadSection" style="height: 313px;">
        <label for="file-input">
          <div class="center-icon">
          <i class="fa fa-cloud-upload" style="font-size:96px;color:blue"></i>
          </div>
        </label>
        <input id="file-input" type="file" (change)="fileChangeEvent($event)"
          class="hiddenFile" />
          <span class="hint"><sup>*</sup>png format only<span *ngIf="currentIconItem">, file size should be less than 10MB</span></span>
          <div class="error"  *ngIf="unacceptedFormat">
            Unaccepted format!. Please upload a 'png' format file
          </div>
          <div class="error"  *ngIf="unacceptedFormat && currentIconItem">
            Please upload a file size less than 10MB
          </div>
          
      </div> 
  
 
   
       

    <div *ngIf="!showFileUploadSection">
      <div *ngIf="showCropperSection" style="width:100%;height:206px">
            <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [aspectRatio]="4 / 2"
            [onlyScaleDown]="true"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [alignImage]="'left'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
          
          <input (ngModelChange)="zoom($event)" [(ngModel)]="zoomVal" type="range" class="custom-range" id="customRange1">
          
          <!-- <input [(ngModel)]="rotation1" placeholder="Rotation" type="number" (keyup)="updateRotation()" /> <button (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button><button (click)="resetImage()">Reset image</button> -->
          
          <div class="row">
            <div class="col-1"><i class="fa fa-rotate-right" style="font-size:24px;" (click)="rotateLeft()"></i></div>
            <div class="col-3"><button class="btn btn-primary" (click)="resetImage()">Reset</button></div>
            <div class="col-4"><img *ngIf="croppedImage" [src]="croppedImage" class="" /> </div>
            <div class="col-4"><button class="btn btn-primary" [mat-dialog-close]="croppedImage" *ngIf="croppedImage">Save Photo</button></div>
          </div>
      </div>
    </div>
  
 </div><!---dialogue content box-->
  
</div><!---modal box container---->
  