export interface ReferenceLibrary {
  code: string;
  name: string;
  id: number;
  desc: string;
  group: string;
}

export interface CompanyDetails {
  companyName: string;
  id: number;
}

export interface PayloadRequest {
  companyId: number;
  immigrationCategory: string;
  immigrationClassification: string;
  immigrationType: string;
  parentStepId: number;
  petitionType: string;
  superTaskType: string;
  taskType: string;
  visaType: String;
  taskTypeId?: number;
}

export enum TableValidationNumber {
  "taskTimeLineValidationNumber" = 24,
  "shouldGraterThanZero" = 0,
  "shouldNotLessThanZero" = -1
}

interface Data {
  id: number;
  companyId: number;
  duration: number;
  durationUnit: string;
  feinNo: string;
  isAttorneyAction: number;
  isAttorneyEditable: number;
  isAttorneyNotify: number;
  isAttorneyVisible: number;
  isBeneficiaryAction: number;
  isBeneficiaryEditable: number;
  isBeneficiaryNotify: number;
  isBeneficiaryVisible: number;
  isPetitionerAction: number;
  isPetitionerEditable: number;
  isPetitionerNotify: number;
  isPetitionerVisible: number;
  sequenceNo: number;
  stepDescription: string;
  stepName: string;
  stepType: string;
  taskTypeId: number;
}

export interface StepTimeLineResponse {
  data: Array<Data>;
  errors?: Array<any> | object | null;
  isCredentialsExpired?: any;
  message: string;
  status: number;
}
