<header class="invitation-section-header d-flex flex-column flex-md-row align-items-center p-1 px-md-4">
  <div class="container m-auto">
    <figure class="d-flex align-items-centter justify-content-start">
      <div class="attorney-logo" *ngIf="!invalidLink" [style.backgroundImage]="'url('+attorneyLogo+')'"></div>
      <a class="navbar-brand my-0 mr-md-auto pointer font-weight-normal pl-2 d-flex flex-column ml-4">
        <span class="pt-2">powered by</span>
        <img src="../../../assets/weblogo.png" alt="Imagility Logo">
      </a>
    </figure>
  </div>
</header>
<div class="invitation-section-wrapper">
  <div class="row container m-auto">
    <div class="col-sm-6 text-white" *ngIf="!invalidLink">
      <h3 class="text-left text-white mb-5">Welcome to our world-class immigration services! </h3>
      <div class="content-wrap lefsid">
          <p>We would like to know a little more about your requirements, to find the best fit for you. Please fill in the form attached here, so we can connect with you as soon as possible with the best possible solution.
          We'll never let you down! We assure efficiency and clarity, where you can entrust completely. The rest, our work will reflect. So go ahead and send us your details now!</p>
      </div>
    </div>
    <div class="col-sm-6" *ngIf="!invalidLink">
      <div *ngIf="formSubmit === 'success'" class="card">
        <h2 class="p-4 text-center">Thank you for showing interest in <p>{{companyData.data.company.companyName}}.</p></h2>
      </div>
      <form class="card p-3" [formGroup]="leadForm" (ngSubmit)="onSubmit()" *ngIf="formSubmit !== 'success'">
        <div class="content-wrap m-auto">
          <h3 class="text-center" *ngIf="!urlParams[1]">Welcome!</h3>
          <h3 class="text-center" *ngIf="urlParams[1]">Send us a message</h3>
          <div class="form-text pb-4">
            <p class="text-center f-24" *ngIf="!urlParams[1]"><strong>Connect</strong> by entering the following details
            </p>
            <p class="text-center" *ngIf="urlParams[1]">Send us a message and we will respond within 24 hours.</p>
          </div>
        </div>
        <hr>
        <div class="content-wrap m-auto">
          <h6 class="text-center">Let us know who you are?</h6>
          <div class="d-flex align-items-center justify-content-center">
            <div class="form-check d-flex">
              <input formControlName="leadTypeCode" class="form-check-input" type="radio" name="leadTypeCode"
                id="individual" value="individual" checked>
              <label class="form-check-label" for="individual">
                Individual
              </label>
            </div>
            <div class="form-check d-flex ml-3">
              <input formControlName="leadTypeCode" class="form-check-input" type="radio" name="leadTypeCode"
                id="company" value="company">
              <label class="form-check-label" for="company">
                Company
              </label>
            </div>
          </div>
        </div>
        <hr>
        <div class="content-wrap m-auto">
          <div class="form-group">
            <label class="mb-0" for="hearAbout">How did you hear about us?<span></span></label>
            <select class="form-control" id="hearAbout" formControlName="sourceId"
              [ngClass]="{ 'is-invalid': submitted && f.sourceId.errors }">
              <option  value="none" selected disabled hidden>Select an option</option>
              <option *ngFor="let source of leadSourcesList" [value]="source.id">{{source.name}}</option>
            </select>
            <div *ngIf="submitted && f.sourceId.errors" class="invalid-feedback">
              <div *ngIf="f.sourceId.errors.required">How did you hear about us is required</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6 pl-0 pr-1">
            <label class="mb-0" for="fName">First Name</label>
            <input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" id="fName" placeholder="Enter Your Name">
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name required</div>
              <div *ngIf="f.firstName.errors.pattern">Invalid First Name.</div>
            </div>
          </div>
          <div class="col-md-6 pr-0 pl-1">
            <label class="mb-0" for="lName">Last Name</label>
            <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" id="lName" placeholder="Enter Your Name">
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              <div *ngIf="f.lastName.errors.pattern">Invalid Last Name.</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="mb-0" for="phone">Phone</label>
          <input type="text" class="form-control" formControlName="phoneContacts"
            [ngClass]="{ 'is-invalid': submitted && f.phoneContacts.errors }" id="phone"
            placeholder="Enter Your Phone Number">
          <div *ngIf="submitted && f.phoneContacts.errors" class="invalid-feedback">
            <div *ngIf="f.phoneContacts.errors.required">Phone is required</div>
            <div *ngIf="f.phoneContacts.errors.pattern">Invalid phone number.</div>
          </div>
        </div>
        <div class="form-group">
          <label class="mb-0" for="email">Email</label>
          <input type="email" class="form-control" formControlName="emailContacts"
            [ngClass]="{ 'is-invalid': submitted && f.emailContacts.errors }" id="email"
            placeholder="Enter Your Email Id">
            <div *ngIf="submitted && f.emailContacts.errors" class="invalid-feedback">
              <div *ngIf="f.emailContacts.errors.required">Email is required</div>
              <div *ngIf="f.emailContacts.errors.pattern">Invalid email entered.</div>
            </div>
            <div *ngIf="emailavailable" class="text-danger">Email is already registered</div>
        </div>
        <div class="form-group">
          <label class="mb-0" for="companyName">Company Name</label>
          <!-- <input type="text" class="form-control" formControlName="companyName"
            [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" id="companyName"
            placeholder="Enter Your Company Name"> -->
            <p-autoComplete
                            [style]="{'width': '100%'}"
                            [suggestions]="companies"
                            (completeMethod)="search($event)"
                            formControlName="companyName"
                            field="employerName"
                            [minLength]="3"
                            [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }">
                              <ng-template let-val pTemplate="item">
                                <div class="country-item">
                                  <div>{{val.employerName}}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
            <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
              <div *ngIf="f.companyName.errors.required">Company Name is required</div>
            </div>
        </div>
        <div class="form-group">
          <label class="mb-0" for="message">Message</label>
          <textarea class="form-control" id="message" formControlName="message"
            [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="3"
            placeholder="Enter Your Message Here…"></textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
        </div>
        <div class="form-group">
          <button class="btn w-100 rounded-0" type="submit" [disabled]="submitLoader">
            CONNECT NOW
            <div [ngClass]="{'load': submitLoader}"></div>
          </button>
        </div>
    </div>
    </form>
  </div>
  <div class="col-12 text-white" *ngIf="invalidLink">
    <h1>Oops,</h1>
    <h1>Its an Invalid Url! </h1>
  </div>
</div>
</div>
