import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFormStateModel from './data/models/app-state';
import * as fromFormState from 'dirty-check-store';
import * as fromCompany from './app-state/reducers/company.reducer';
import * as fromI9SectionOne from './app-state/reducers/i9-section-one.reducer';
import * as fromBuilder from './modules/form-designer/fb-state/reducers/fb.reducer';

export interface State {
  formState: fromFormStateModel.FormStateModel;
  company: {};
  i9: fromFormStateModel.I9Model,
  fb: fromBuilder.fbState
}

export const reducers: ActionReducerMap<State> = {
  formState: fromFormState.DirtyFormReducer,
  company: fromCompany.CompanyReducer,
  i9:  fromI9SectionOne.I9SectionOneReducer,
  fb: fromBuilder.fbReducer
};

