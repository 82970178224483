<div class="licenseContainer" *ngIf="showLifeStoryTimeLineSection">
  <div class="mt-4 btn-group row">
    <div class="col-7"></div>
    <div class="col viewType" *ngIf="!modeVal">
      <button class="childBtn firstBtn" [class.activebtn]="isTimeLineView" (click)="toggleListView(false)">
        <mat-icon matTooltip="Change to timeline view" class="btn-icn">timeline</mat-icon>
        Timeline View
      </button>
      <button class="childBtn lastBtn" [class.activebtn]="isListView" (click)="toggleListView(true)">
        <mat-icon matTooltip="Change to list view" class="btn-icn">list</mat-icon>
        List View
      </button>

    </div>
  </div>
  <div class="row" *ngIf="isTimeLineView && licenseData?.length > 0">
    <app-license-timeline class="col" [licenseData]="licenseData"></app-license-timeline>
  </div>

  <div class="add-placeholder" *ngIf="isTimeLineView && licenseData?.length==0">
    <button mat-fab color="primary" class="btn" *ngIf="!viewMode" (click)="toggleListView(true)">
      <mat-icon matTooltip="Add License / Certificate">add_circle_outline</mat-icon>
    </button>
  </div>

  <div *ngIf="isListView">
    <ng-container *ngIf="!isExternalEmployeeId">
    <div class="row">
      <button class="btn btn-primary mb-2" *ngIf="updateBenProfile && !viewMode && showEditButton" type="button"
        (click)="toggleExpansion();stepper.reset();formCheck(licForm)">Add License / Certificate</button>
    </div>
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="expandPanel">
        <mat-card>
          <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
            class="pb-mat-stepper-custom-horizontal">
            <mat-step [completed]="hasID"> 
              <ng-template matStepLabel>Licenses Or Certifications</ng-template>
              <form (ngSubmit)="licForm.form.valid && saveLicenseTrianing(licForm)" #licForm="ngForm">
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="form-group col-md-12 mt-2" *ngIf="!iscaseRequest">
                    <label class="label-cls">Name</label>
                    <input class="im-input"                      
                      type="text" name="name" [(ngModel)]="license.name" #name="ngModel" placeholder="Enter" />                   
                  </div>
                  <div class="form-group col-md-12 mt-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCNAME') ? true: false) : false">
                    <label class="label-cls">Name</label>
                    <input class="im-input"                      
                      type="text" name="name" [(ngModel)]="license.name" #name="ngModel" placeholder="Enter" />                    
                  </div>
                  <div class="form-group col-md-12" *ngIf="!iscaseRequest">
                    <label class="label-cls">Issuing Organization</label>
                    <input class="im-input"                      
                      type="text" name="institution" [(ngModel)]="license.institution" #institution="ngModel"
                      placeholder="Enter" />                    
                  </div>
                  <div class="form-group col-md-12" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCORGANIZATION') ? true: false) : false">
                    <label class="label-cls">Issuing Organization</label>
                    <input class="im-input"                      
                      type="text" name="institution" [(ngModel)]="license.institution" #institution="ngModel"
                      placeholder="Enter" />                    
                  </div>
                  <div class="form-group col-md-12" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCENDDATE') ? true: false) : true">
                    <mat-checkbox class="example-margin" name="infineDtFlag" [(ngModel)]="infiniteDtFlag">This
                      credentials
                      does not expire
                    </mat-checkbox>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                      <label class="label-cls">Start Date*</label>
                      <mat-form-field appearance="outline" class="mt-0 date-field">
                        <input [(ngModel)]="license.startDate" #startDt="ngModel" name="startDt" [max]="today" matInput
                          [matDatepicker]="startDate" (click)="startDate.open()" placeholder="MM/DD/YYYY" />
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>                                            
                    </div>
                    <div class="form-group col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCSTARTDATE') ? true: false) : false">
                      <label class="label-cls">Start Date</label>
                      <mat-form-field appearance="outline" class="mt-0 date-field">
                        <input [(ngModel)]="license.startDate" #startDt="ngModel" name="startDt" [max]="today" matInput
                          [matDatepicker]="startDate" (click)="startDate.open()" placeholder="MM/DD/YYYY" />
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>                      
                    </div>
                    <div class="form-group col-md-6" *ngIf="infiniteDtFlag === false && !iscaseRequest">
                      <label class="label-cls">End Date*</label>
                      <mat-form-field appearance="outline" class="mt-0 date-field">
                        <input [(ngModel)]="license.endDate" name="endDt" matInput [min]="license.startDate"
                          [matDatepicker]="endDate" #endDt="ngModel" (click)="endDate.open()" placeholder="MM/DD/YYYY"/>
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>                      
                    </div>
                    <div class="form-group col-md-6" *ngIf="infiniteDtFlag === false && (iscaseRequest ? (currentItemDisplay.includes('EDULCENDDATE') ? true: false) : false)">
                      <label class="label-cls">End Date</label>
                      <mat-form-field appearance="outline" class="mt-0 date-field">
                        <input [(ngModel)]="license.endDate" name="endDt" matInput [min]="license.startDate"
                          [matDatepicker]="endDate" #endDt="ngModel" (click)="endDate.open()" placeholder="MM/DD/YYYY" />
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>                 
                  <hr />
                  <div class="d-flex flex-row-reverse">
                    <div class="p-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCSUBCOVER') ? true: (currentItemDisplay.includes('EDULCDOC') ? true: false)) : true">
                      <button type="button" class="btn btn-primary" matStepperNext [disabled]="!hasID">Next</button>
                    </div>
                    <div *ngIf="!modeVal" class="p-2">
                      <button type="button" class="back-button" (click)="closeForm(licForm)">Close</button>
                    </div>
                    <div class="p-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCNAME') ? true: (currentItemDisplay.includes('EDULCORGANIZATION') ? true: (currentItemDisplay.includes('EDULCENDDATE') ? true: (currentItemDisplay.includes('EDULCSTARTDATE') ? true: (currentItemDisplay.includes('EDULCSUBCOVER') ? true: false))))) : true">
                      <button class="btn btn-success" type="submit">Save</button>
                    </div>                    
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCSUBCOVER') ? true: false) : true">
              <ng-template matStepLabel>Topics or Subjects Covered</ng-template>
              <div class="row formBox" [ngClass]="{disabledView: isViewMode}">
                <div class="form-group col-md-12 mt-2">
                  <div class="row">
                    <label class="row">Add topics or subjects covered in the Licenses or Certifications</label>
                    <small>Enter all relevant Topics or Subjects Covered as a part of your Certifications using a comma
                      to
                      separate.</small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row paddingTop10">
                    <label class="col-md-12">Topics Or Subjects Covered</label>
                    <div class="col-md-10">
                      <mat-form-field appearance="outline" class="example-chip-list chips-contianer">
                        <mat-chip-list #chipList aria-label="Topic selection">
                          <mat-chip *ngFor="let subject of listAddedSubjects" [selectable]="selectable"
                            [removable]="removable" (removed)="remove(subject)">
                            {{subject}}
                            <mat-icon matTooltip="Remove this item" matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="New subject..." [matChipInputFor]="chipList" (paste)="pasteChips($event)"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [(ngModel)]="topic"
                            (input)="onNewTopicEnter($event)" name="topic" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                      </mat-form-field>
                      <mat-hint class="hint-text">Please enter comma separated values</mat-hint>
                    </div>
                    <div class="col-md-2">
                      <button type="button" class="btn btn-success" (click)="addSubjects()"
                        [disabled]="listAddedSubjects.length === 0">Add</button>
                    </div>
                  </div>
                </div>
                <div class="spacing im-card-no-border-table">
                  <table class="im-card-no-border-table" mat-table [dataSource]="listSubjects" matSort>
                    <ng-container *ngFor="let column of subjectsColumns" [matColumnDef]="column.columnDef">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.header}} </th>
                      <td mat-cell *matCellDef="let row; let i = index">
                        <span class="column.customClass" *ngIf="column.columnType=='icon'">
                          <mat-icon matTooltip="Delete" class="mr-1 icn" (click)="deleteSubject(i)">delete_forever
                          </mat-icon>
                        </span>
                        <span *ngIf="column.columnType=='text'">
                          {{row[column.columnDef]}}
                        </span>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="subDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: subDisplayedColumns;"></tr>
                  </table>
                </div>
                <div class="w-100">
                  <hr />
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCNAME') ? true: (currentItemDisplay.includes('EDULCORGANIZATION') ? true: (currentItemDisplay.includes('EDULCENDDATE') ? true: (currentItemDisplay.includes('EDULCSTARTDATE') ? true: false)))) : true">
                      <button type="button" class="back-button" matStepperPrevious>Back</button>
                    </div>
                    <div class="col-md-6 text-right">
                      <button *ngIf="!modeVal" type="button" class="back-button mr-3"
                        (click)="closeForm(licForm);stepper.reset()">Close</button>
                      <button *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCDOC') ? true: false) : true" type="button" class="btn btn-primary pull-right" matStepperNext>Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <mat-step *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCDOC') ? true: false) : true">
              <ng-template matStepLabel>Upload Documents</ng-template>
              <div class="row formBox" [ngClass]="{disabledView: isViewMode}">
                <div class="col-md-12 mt-2">
                  <app-file-upload style="width: 100%;" [configurations]="configurations"
                    (fileUploadSuccess)="fileUploadSuccess()"></app-file-upload>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDULCNAME') ? true: (currentItemDisplay.includes('EDULCORGANIZATION') ? true: (currentItemDisplay.includes('EDULCENDDATE') ? true: (currentItemDisplay.includes('EDULCSTARTDATE') ? true: (currentItemDisplay.includes('EDULCSUBCOVER') ? true: false))))) : true">
                      <button type="button" class="back-button" matStepperPrevious>Back</button>
                    </div>
                    <div class="col-md-6 text-right">
                      <button type="button" class="back-button mr-3" *ngIf="!modeVal"
                        (click)="closeForm(licForm);stepper.reset()">Close</button>
                      <button type="button" class="btn btn-primary pull-right"
                        (click)="resetForm(licForm);stepper.reset();">Add Next
                        Licenses</button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
   </ng-container>
    <!------------------------------------*****  view added license  items ***** ------------------------------------->
    <div class="spacing im-card-no-border-table">
      <div class="p-3">
        <div>{{dataSource && dataSource.data.length > 0 ? dataSource.data.length : 'No' }} Licenses Added by
          you</div>
      </div>
      <table class="im-card-no-border-table" multiTemplateDataRows mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
          <td mat-cell *matCellDef="let row">
            <span class="row" *ngIf="column.columnDef == 'toggle'" class="toggle-col">
              <a>
                <mat-icon matTooltip="View more">expand_more</mat-icon>
              </a>
            </span>
            <div *ngIf="updateBenProfile && showEditButton">
              <span class="row" *ngIf="column.columnDef == 'action'">
                <ng-container *ngIf="!isExternalEmployeeId">
                <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Edit" class="im-table-verticle-icon"
                  (click)="handleEdit(row);formCheck(licForm)">edit
                </mat-icon>
                <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Delete" (click)="handleDelete(row, licForm)">
                  delete_forever
                </mat-icon>
              </ng-container>
              </span>
            </div>
            <span *ngIf="column.columnType=='text'">
              {{row[column.columnDef]}}
            </span>
          </td>
        </ng-container>
        <div class="example-element-description">
          <ng-container matColumnDef="expandedDetail">
            <label>
              List of Topics
            </label>
            <td class="w40" mat-cell *matCellDef="let element;let i = index;" [attr.colspan]="columns.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="w100 example-element-description py-1" *ngIf="element?.subjects?.length > 0">
                  <h6>List of Topics</h6>
                  <ng-container>
                    <table class="table table-striped">
                      <tr *ngFor="let sub of element.subjects; let i = index;" class="border border-dark">
                        <td class="w40">
                          <div class="circleIndex rounded-circle">{{i + 1}}
                          </div>
                        </td>
                        <td nowrap>
                          {{sub}}
                        </td>
                      </tr>
                    </table>
                  </ng-container>
                </div>
                <div class="w100 example-element-description py-1" *ngIf="element?.documents?.length > 0">
                  <h6>List of Documents</h6>
                  <ng-container>
                    <table class="im-card-no-border-table">
                      <thead>
                        <tr>
                          <th class="w40"></th>
                          <th>Document</th>
                          <th>Document Type</th>
                          <th><i class="fa fa-download fa-lg icn" aria-hidden="true"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of element.documents;let i=index">
                          <td class="w40">
                            <div class="circleIndex rounded-circle">{{i + 1}}
                            </div>
                          </td>
                          <td>
                            <strong>{{ item.fileName }}</strong>
                          </td>
                          <td nowrap>
                            {{ item?.fileCategory?.code === 'OTHER' ? item?.fileCategoryOtherName : item?.fileCategory?.name }}
                          </td>
                          <td nowrap>
                            <app-file-download [fileName]="item.fileLocation.substring(23)"
                              [Path]="envPath+item.fileLocation.substring(23)"></app-file-download>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
              </div>
            </td>
          </ng-container>
        </div>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
</div>