import { createAction, props } from '@ngrx/store';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';

//Action to trigger get api call for sub task details
export const loadPetitionSubTaskDetails = createAction(
  '[SubTaskDetails] Load SubTaskDetails',
  props<{ taskId: number }>()
);

//Action will be triggered on success of getSubTaskDetails api call
export const loadSubTaskDetailsSuccess = createAction(
  '[SubTaskDetails] Load SubTaskDetails Success',
  props<{ details: SubTaskDetails }>()
);

//Action will be triggered on failure of getSubTaskDetails api call
export const loadSubTaskDetailsFailure = createAction(
  '[SubTaskDetails] Load SubTaskDetails Failure',
  props<{ error: any }>()
);
