import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'img-primeng-donut',
  templateUrl: './primeng-donut.component.html',
  styleUrls: ['./primeng-donut.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgPrimengDonutComponent implements OnInit, OnChanges {

  @Input()
  inputData: any;

  data: any;
  options: any;

  constructor() { }

  ngOnInit() {
    this.options = {      
      tooltips: {
        bodyFontSize : 16,
        callbacks: {
          label: function (tooltipItem, data) {
            return `${data.labels[tooltipItem.index]}`;
          }
        }
      },
      // legend: {
      //   labels : {
      //     fontSize: 16,
      //   },
      //   position: 'bottom',
      //   align: "center",
       
      // }
      legend: {
        display: true,
        position: "bottom",
        fullWidth: true,
        labels: {
          boxWidth: 8,
          fontSize: 14
        }
      },
    };
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.inputData && e.inputData.currentValue) {
      this.data = e.inputData.currentValue;
    }
  }

}
