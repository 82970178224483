import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { SchedulingService } from 'src/app/services/scheduling.service';

@Component({
  selector: 'calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.scss']
})
export class CalendarWidgetComponent implements OnInit, AfterViewInit {

  startDate;
  endDate;
  userId: any;
  userType: string;
  events: any = [];
  todaysEvents: any = [];
  tomorrowsEvents: any = [];
  navUrl;
  dashboardUrl;

  // the logged in users calendar mettings, events, tasks details
  activities: any = [];

  constructor(
    private schedulingService: SchedulingService,
    public router: Router,
  ) {


    this.userType = sessionStorage.getItem('userTypeRole');
    this.userId = sessionStorage.getItem('userId');
    if (this.userType == 'Petitioner') {
      this.navUrl =
        '/petitioner-landing/dashboard/petitioner-dashboard/scheduling/';
      this.dashboardUrl = '/petitioner-landing/dashboard/petitioner-dashboard';
    } else if (this.userType == 'Attorney') {
      this.navUrl =
        '/attorney-landing/dashboard/attorney-dashboard/scheduling/';
      this.dashboardUrl = '/attorney-landing/dashboard/attorney-dashboard';
    }
    // console
  }

  ngOnInit(): void {
    this.getAllEvents();
  }
  ngAfterViewInit() {
    // this.getAllEvents();
  }

  getAllEvents() {
    const start = moment().startOf('month').format('YYYY-MM-DD');
    const end = moment().endOf('month').format('YYYY-MM-DD');
    // console.log('Start Date ::', start);
    // console.log('End date ::', end);
    this.activities = [];
    this.events = [];
    let payload: any;
    payload = {
      userId: this.userId,
      // location: "",
      // title: "",
      // description: "",
      // keyword: "",
      // isPublic: 0,
      // participantEmailIds: [],
      // participantIds: [],
      // fromDate: this.startDate ? this.startDate : start,
      // toDate: this.endDate ? this.endDate : end,
      isCalendarView: false,
      // dateRange: {
      //   startFromDate: moment().format("YYYY-MM-DD"),
      //   startToDate: moment().format("YYYY-MM-DD"),
      //   endFromDate: moment().add(3, 'days').format("YYYY-MM-DD"),
      //   endToDate: moment().add(3, 'days').format("YYYY-MM-DD"),
      // },

      dateRange: {
        startFromDate: moment().format('YYYY-MM-DD'),
        startToDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        endFromDate: moment().format('YYYY-MM-DD'),
        endToDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      },
    };
    // console.log('New Payload :: ', payload)
    this.schedulingService.getAllEvents(payload).subscribe(
      (response) => {
        // console.log("response :: ", response);
        if(response.data!=null){
        response.data.forEach((el) => {
          // console.log("el.date ::", el.date);

          let date = el.date; // .substring(0, el.date.indexOf(' '));
          date = date.replaceAll('/', '-');

          const time = el.date.substring(
            el.date.indexOf(' ') + 2,
            el.date.length
          );
          // console.log("The time::", "asha" + time + "asha");

          const newdate = date + time;
          // console.log("The dateobj", newdate);
          date = moment(date).format('YYYY-MM-DD');
          // console.log("date ::", date);

          let startDate;
          let endDate;
          if (el.startTime && el.startTime !== null) {
            startDate = date + 'T' + el.startTime;
          }
          if (el.endTime && el.endTime !== null) {
            endDate = date + 'T' + el.endTime;
          }

          // if (this.calendarMode == "self")
          this.activities.push({
            id: el.id,
            title: el.title || el.description,
            start: startDate || date,
            end: endDate || date,
            type: el.calenderItemTypeName.toUpperCase(),
            eleData: el,
            startTime: el.startTime ? el.startTime.substring(0, 5) + (el.startTime.substring(0, 2) < 12 ? 'AM' : 'PM') : '00:00',
            calenderItemTypeName: el.calenderItemTypeName.toLowerCase(),
            sd: date
          });

        });
        this.activities.forEach((elem) => {
          elem.textColor = 'black';
          if (elem.type == 'event') {
            elem.color = '#cf2ef1';
          } else if (elem.type == 'task') {
            elem.color = '#95e697';
          } else if (elem.type == 'reminder') {
            elem.color = 'pink';
          } else if (elem.type == 'appointment' || elem.type == 'meeting') {
            elem.color = '#a7d9ec';
          } else if (elem.type == 'court date') {
            elem.color = 'rgb(255, 255, 0)';
          }
        });
        this.events = this.activities;
        this.seperateEvents();
      }else{
        this.events = [];

      }
        // console.log("This.events Scheduling Events:: ", this.events);
      },
      (error) => {
        // console.log("Error while fetching scheduling serve :: ");
      },
      () => { }
    );
  }

  seperateEvents() {
    // console.log("seperateEvents:: ", this.events);
    // console.log('moment ::', moment())
    // console.log('Todays Date', moment());
    this.todaysEvents = this.events.filter(el => {
      // var date = moment(el.date);
      // console.log('el Date', el.date);
      // console.log('el Date in moment', moment(el.date));
      // console.log('el start', el.start);
      // console.log('el start in moment', moment(el.start));
      const today = moment();
      // var today = moment().add(1, 'days')
      const startDate = moment(el.start);
      // console.log('today :: ', today);
      // console.log('startDate :: ', startDate);
      // console.log('today == CALENDARDATE', moment(today).isSame(startDate,'day'));
      return moment(today).isSame(startDate, 'day');
      // return el
      // return (moment().isSame(date))
    });

    this.tomorrowsEvents = this.events.filter(el => {
      const today = moment();
      // var today = moment().add(1, 'days')
      const startDate = moment(el.start);
      // console.log('today :: ', today);
      // console.log('startDate :: ', startDate);
      // console.log('today == CALENDARDATE', moment(today).isSame(startDate,'day'));
      return moment(today).isBefore(startDate, 'day');
      // console.log('today == CALENDARDATE', moment().isSame(date));
      // return (moment().isBefore(date))
    });

    // console.log('this.todaysEvetns', this.todaysEvetns);
    // console.log('this.this.tomorrowsEvents', this.tomorrowsEvents);
    // console.log('date:: ', moment().add(3, 'days').format("YYYY-MM-DD"))

  }

  /*handleEventClick(clickInfo: EventClickArg) {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
    console.log("handle event click", clickInfo);
    console.log("handle event click", clickInfo.event);
    console.log("handle event click def", clickInfo.event._def);
    console.log("handle event click", clickInfo.event._def.extendedProps);
    console.log(
      "handle event click type",
      clickInfo.event._def.extendedProps.type
    );
    this.clickedItemType = clickInfo.event._def.extendedProps;
    console.log(
      "handle event click clickedItemType",
      this.clickedItemType.type
    );
    this.router.navigateByUrl(this.navUrl + "tabview", {
      state: {
        calendarItem: this.clickedItemType.type,
        elemData: this.clickedItemType.eleData,
      },
    });
  } */

  viewItem(item) {
    // console.log('Item ::', item);
    this.router.navigateByUrl(this.navUrl + 'tabview', {
      state: {
        calendarItem: item.type,
        elemData: item.eleData,
      },
    });
  }

}

