import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DateSuffixPipe'
})
export class DateSuffixPipe implements PipeTransform {

  transform(value: unknown): unknown {
    let suffix = 'th';

        if (value == 1 || value == 21 || value == 31) {
            suffix = 'st';
        } else if (value == 2 || value == 22) {
            suffix = 'nd';
        } else if (value == 3 || value == 23) {
           suffix = 'rd';
        }

        return suffix;
  }

}
