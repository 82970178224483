<form [formGroup]="addressForm">
  <div class="row">
    <div class="col-md-12 form-group input-disabled">
      <label class="label-cls required"> Address 1 </label><small class="hintTxt">Ex: Street Name & Number</small>
      <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls" formControlName="addressLine1"
        type="text" pInputText placeholder="Enter" />
      <div class="invalid-message"
        *ngIf="addressForm.get('addressLine1').touched && addressForm.get('addressLine1').errors?.required">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-group input-disabled">
      <label class="label-cls"> Address 2 </label><small class="hintTxt">Ex: Apartment..,Suite,Building</small>
      <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls" formControlName="addressLine2"
        type="text" pInputText placeholder="Enter" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-group processing-dropdown">
      <label class="label-cls required"> Country </label>
      <p-dropdown appendTo="body" [readonly]="readonlyCountry" [options]="countryList" [filter]="false" id="countryCode"
        class="address-component-country-dropdown" [ngClass]="{'readonly-country': readonlyCountry}"
        [disabled]="disabledControls" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode"
        (onChange)="handleCountryChange($event.value)" placeholder="select"> </p-dropdown>
      <div class="invalid-message"
        *ngIf="addressForm.get('countryCode').touched && addressForm.get('countryCode').errors?.required">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
    <div class="col-md-6 form-group processing-dropdown">
      <label class="label-cls required"> State </label>
      <p-dropdown appendTo="body" [options]="listStates" *ngIf="listStates.length > 0" id="stateProvinceCode"
        formControlName="stateProvinceCode" name="country" optionLabel="stateProvinceName"
        optionValue="stateProvinceCode" placeholder="select">
      </p-dropdown>
      <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listStates.length === 0" id="clientName"
        formControlName="stateProvinceName" type="text" pInputText placeholder="Enter" />
      <div class="invalid-message"
        *ngIf="addressForm.get('stateProvinceCode').touched && addressForm.errors?.stateProvinceCodeFieldIsRequired">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
      <div class="invalid-message"
        *ngIf="addressForm.get('stateProvinceName').touched && addressForm.errors?.stateProvinceNameFieldIsRequired">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-group input-disabled">
      <label class="label-cls required"> City </label>
      <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls" formControlName="city"
        type="text" pInputText placeholder="Enter" />
      <div class="invalid-message" *ngIf="addressForm.get('city').touched && addressForm.get('city').errors?.required">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
    <div class="col-md-6 form-group input-disabled">
      <label class="label-cls required"> Zip / Postal code </label>
      <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls" formControlName="postCode"
        type="text" pInputText placeholder="Enter" />
        <!--Removed max length 6 since N-400 and some forms using usps zipcode format which is more that 6 characters-->
      <div class="invalid-message"
        *ngIf="addressForm.get('postCode').touched && addressForm.get('postCode').errors?.required">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
  </div>
</form>
