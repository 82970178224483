import moment from "moment";
import { TaskStep } from "src/app/data/models/task-step.model";
import { Injectable } from '@angular/core';

@Injectable()
export class StepStatusUpdatePayload {
    payloadConstruction(stepDetails: TaskStep, status: string) {
        return {
            benchMarkActionCode: '',
            endDate: '',
            isAttorneyNotify: stepDetails['isAttorneyNotify'],
            isBeneficiaryNotify: stepDetails['isBeneficiaryNotify'],
            isPetitionerNotify: stepDetails['isPetitionerNotify'],
            receiptCategory: '',
            receiptDate: '',
            receiptNumber: '',
            receiptType: '',
            responseCode: stepDetails.stepResponseDetails && stepDetails.stepResponseDetails['stepResponseCode'] ? stepDetails.stepResponseDetails['stepResponseCode'] : null,
            responseNote: stepDetails['stepResponseNote'] || '',
            reviewCode: stepDetails.stepReviewDetails && stepDetails.stepReviewDetails['stepReviewCode'] ? stepDetails.stepReviewDetails['stepReviewCode'] : null,
            reviewNote: stepDetails.stepReviewDetails && stepDetails.stepReviewDetails['stepReviewNote'] ? stepDetails.stepReviewDetails['stepReviewNote'] : null,
            startDate: '',
            statusCode: status,
            stepNote: stepDetails['stepNote'] ? stepDetails['stepNote'] : ''
        };
    }
}