import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { stepStatus } from '../../../../data/constants/step-status';
import { RfeUploadNoticeComponent } from '../rfe-upload-notice/rfe-upload-notice.component';
import { RfeSelectClausesComponent } from '../rfe-select-clauses/rfe-select-clauses.component';
import { RfePrepareResponseComponent } from '../rfe-prepare-response/rfe-prepare-response.component';
import { RfePrintResponseComponent } from '../rfe-print-response/rfe-print-response.component';
import { RfeProvideAdditionalDetailsComponent } from '../rfe-provide-additional-details/rfe-provide-additional-details.component';
import { RfeReviewResponseComponent } from '../rfe-review-response/rfe-review-response.component';
import { ToastrService } from 'ngx-toastr';
import * as Roles from 'src/app/data/constants/roles';
import { combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import { ImagilityBaseResponse, SubTaskDetails, TaskStep, TaskSummaryOverview } from 'app-models';
import { TaskNotesService } from 'task-notes-lib';
import { refDialogComponentService } from './rfe-dialog.component.service';
import { take, takeUntil } from 'rxjs/operators';
import { getSubTaskDetails, getSubTaskStepList, loadSubTaskDetails, SetVisaTypeCode, getStepStatusUpdate, getSuperTask } from 'visa-store';
import { VisaExtendedState } from '../utility/interfaces/visa-extended-state';
import { Task } from 'src/app/data/models/tasks.model';
import { DomHandler } from 'primeng/dom';


@Component({
  selector: 'app-rfe-dialog',
  templateUrl: './rfe-dialog.component.html',
  styleUrls: ['./rfe-dialog.component.scss']
})
export class RfeDialogComponent implements OnInit {

  @Input()
  stepDetails: TaskStep;

  @Input() stepName;
  @Input() rfeDialogDisplay;
  stepNameNumber;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  taskId: number;
  header: string;
  subHeader: string;
  uploadedFiles: any[] = [];
  @Input() rfeTimelineModalInput: [];
  dialogDisplay;
  receiptNo;
  noticeDate;
  receiptDate;
  hideFooter: boolean;
  hideHeader: boolean = false;
  userType;
  responseType;
  currentStatus = 'NEW';
  SubTaskOverView;
  disableEdit = false;
  dialogPosition = 'right';
  subTaskDetails: SubTaskDetails;
  taskTypeCode: string;
  observableSubscription$ = new Subject();

  @ViewChild(RfeUploadNoticeComponent) rfeUploadNoticeComponent: RfeUploadNoticeComponent;
  @ViewChild(RfeSelectClausesComponent) rfeSelectClausesComponent: RfeSelectClausesComponent;
  @ViewChild(RfePrepareResponseComponent) rfePrepareResponseComponent: RfePrepareResponseComponent;
  @ViewChild(RfePrintResponseComponent) rfePrintResponseComponent: RfePrintResponseComponent;
  @ViewChild(RfeProvideAdditionalDetailsComponent) rfeProvideAdditionalDetailsComponent: RfeProvideAdditionalDetailsComponent;
  @ViewChild(RfeReviewResponseComponent) rfeReviewResponseComponent: RfeReviewResponseComponent;

  taskDetailsSubscription$ = new Subject();
  dialogForm: FormGroup;

  maximized: boolean;
  @Output() onMaximize: EventEmitter<any> = new EventEmitter();
  stepLayoutCode: string = '';
  maximizedDialogClass: string = 'p-dialog-maximized';
  maximizedIconClass: string = 'icon-window-maximize';
  minimizedIconClass: string = 'icon-window-minimize';

  constructor(private petitionerService: PetitionerService,
    private toastr: ToastrService,
    private store: Store<fromTask.State>,
    private stores: Store<any>,
    private taskNotesService: TaskNotesService,
    private mainContainerComponentService: refDialogComponentService,
    public datepipe: DatePipe) {
    this.userType = sessionStorage.getItem('userTypeRole');
    if (this.userType === Roles.ATTORNEY)
      this.dialogPosition = "right";
    else
      this.dialogPosition = "center";
  }

  ngOnInit() {
    //this.getNotes();
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.stepName && e.stepName.currentValue) {
      this.loadData();
    }
  }

  updateData() {
    this.loadData();
  }

  loadData() {
    this.dialogDisplay = this.rfeDialogDisplay;
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      this.rfeTimelineModalInput["isPetitionOutsideImagility"] = this.SubTaskOverView.isPetitionOutsideImagility ? true : false;
      const currentStep = this.SubTaskOverView.taskStepDetails.filter((step)=> step.stepName === this.stepName);
      if( currentStep){
        this.stepLayoutCode = currentStep[0].stepActionList[0].layoutCode;
        this.maximized = this.getPersistanceByLayoutCode(this.stepLayoutCode);
        this.maximize();
      }
      if (getRFESubTask['taskStatus']['code'] != 'RESPONDED') {
        this.currentStatus = this.SubTaskOverView.taskStepDetails[this.stepNameNumber - 1].stepStatusDetails.stepStatusCode;
        // Get Specific Task Details
        this.store.select(getSubTaskDetails).pipe(takeUntil(this.observableSubscription$)).subscribe((data: SubTaskDetails) => {
          this.subTaskDetails = data;
        });
        this.taskNotesService.onNotesUpdated
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe(() => {
            this.petitionerService.getSuperTask(this.subTaskDetails.superTaskId)
              .pipe(take(1))
              .subscribe((taskSummaryOverview: TaskSummaryOverview) => {
                // this.store.dispatch(new Task.SetTaskSummaryOverview(taskSummaryOverview));
              });
        });
        

      }
      else {
        this.disableEdit = true;
      }
    });


    this.taskId = this.rfeTimelineModalInput["taskNumber"];
    this.currentStatus = this.rfeTimelineModalInput["stepStatus"];
    switch (this.rfeTimelineModalInput["stepName"]) {
      case 'Upload RFE Notice & Collect Details':
        this.header = "RFE Details";
        this.stepNameNumber = 1;
        if (this.userType === Roles.ATTORNEY)
          this.subHeader = "To begin the RFE process, Provide the RFE details";
        this.hideFooter = true;
        if (this.rfeUploadNoticeComponent != undefined)
          this.rfeUploadNoticeComponent.initialiseData();
        break;
      case 'Select RFE Clauses':
        this.header = "Select RFE Clauses";
        this.stepNameNumber = 2;
        if (this.userType === Roles.ATTORNEY)
          this.subHeader = "Select applicable categories";
        this.hideFooter = true;
        break;
      case 'Prepare RFE Response':
        this.header = "Prepare RFE Response";
        this.stepNameNumber = 3;
        this.hideFooter = true;
        if (this.userType === Roles.ATTORNEY) {
          this.subHeader = "Create RFE Response & Attach respective documents";
        }
        if (this.rfePrepareResponseComponent != undefined)
          this.rfePrepareResponseComponent.getRFEResponseValue();
        break;
      case 'Provide Additional Details (Petitioner Action)':
        this.header = "Upload Documents / Add Response";
        this.subHeader = "";
        this.stepNameNumber = 4;
        this.hideFooter = true;
        break;
      case 'Provide Additional Details (Beneficiary Action)':
        this.header = "Upload Documents / Add Response";
        this.subHeader = "";
        this.stepNameNumber = 5;
        this.hideFooter = true;
        break;
      case 'Review response':
        this.header = "Review RFE Response";
        if (this.userType === Roles.ATTORNEY)
          this.subHeader = "Review RFE Response & Generate Draft";
        this.stepNameNumber = 6;
        this.hideFooter = true;
        if (this.rfePrepareResponseComponent != undefined) {
          this.rfeReviewResponseComponent.getContent();
        }
        break;
      case 'Print and File RFE Response':
        this.header = "Print and File RFE Response";
        if (this.userType === Roles.ATTORNEY)
          this.subHeader = "Generate Final RFE Response & File";
        this.stepNameNumber = 7;
        this.hideFooter = true;
        break;
    }

      // Get Specific Task Details
      this.stores.select(getSubTaskDetails).pipe(takeUntil(this.observableSubscription$)).subscribe((data: SubTaskDetails) => {
        this.subTaskDetails = data;
      });
      this.taskNotesService.onNotesUpdated
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(() => {
          this.petitionerService.getSuperTask(this.subTaskDetails.superTaskId)
            .pipe(take(1))
            .subscribe((taskSummaryOverview: TaskSummaryOverview) => {
              // this.store.dispatch(new Task.SetTaskSummaryOverview(taskSummaryOverview));
            });
      });
    
  }

  returningDataToParent() {

    if (this.stepNameNumber == '2') {
      this.rfeSelectClausesComponent.getRFEDetails();
    }
    this.notify.emit("");
  }

  saveData() {
    // switch(this.stepNameNumber){
    //   case 1:
    //     this.rfeUploadNoticeComponent.saveData();
    //     break;
    //   // case 2:
    //   //     this.rfeSelectClausesComponent.saveData();
    //   //     break;
    // }
    //this.notify.emit(stepStatus.Inprogress);
  }

  changeStatusToComplete() {
    this.notify.emit(stepStatus.Complete);
  }

  handleStatusEdit() {
    this.notify.emit(stepStatus.Inprogress);
  }

  parentListener(data: string) {
    this.notify.emit(data);
  }

  updateNoteAvailability(e) {    
    this.stepDetails.notesAvailable = e?.notesAvailable || false;
    this.stepDetails.notes.noteText = e.noteText || '';
  }

  getPersistanceByLayoutCode(code: string){
    let persistedModalsArr = this.getPersistedModals();
    let isPersistedModal = false;
    if(persistedModalsArr.length){
      const index = persistedModalsArr.findIndex((value)=> value.layoutCode === code && value.maximized);
      isPersistedModal = index > -1 ? true : false;
    }
    return isPersistedModal;
  }

  getPersistedModals(): Array<any> {
    const persistedModals = localStorage.getItem('maximizedModals');
    if(persistedModals){
      return JSON.parse(persistedModals);
    }

    return [];
  }

  toggleMaximize(){
    this.maximized = !this.maximized;
    this.maximize();
    this.updatePersistance();
    this.onMaximize.emit({ maximized: this.maximized });
  }

  maximize() {
    let dialogEl = DomHandler.findSingle(document.body,'p-dynamicdialog .p-dialog'); // for visa steps
    dialogEl ||= DomHandler.findSingle(document.body,'.p-dialog'); //for RFE

    if(!dialogEl) return;

    if(this.maximized){
      DomHandler.addClass(dialogEl, this.maximizedDialogClass);
    }else{
      DomHandler.removeClass(dialogEl, this.maximizedDialogClass);
    }
  }

  updatePersistance(){
    let persistedModalsArr = this.getPersistedModals();
    if(persistedModalsArr.length && this.stepLayoutCode){
      const index = persistedModalsArr.findIndex((value)=> value.layoutCode === this.stepLayoutCode);
      if(index > -1){
        persistedModalsArr[index]['maximized'] = this.maximized;
      }else{
        persistedModalsArr.push({maximized: this.maximized,'layoutCode':this.stepLayoutCode});
      }
    }else if(this.stepLayoutCode){
      persistedModalsArr = [{maximized: this.maximized,'layoutCode':this.stepLayoutCode}];
    }
    localStorage.setItem('maximizedModals',JSON.stringify(persistedModalsArr));
  }
}
