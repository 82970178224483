<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center border-bottom pb-2">
    <div class="col-md-8 step-title p-0">
      <!-- <h5 class="mb-0">Capture Extraordinary Ability details</h5>
            <p class="mb-0" style="font-size:14px">Provide Extraordinary ability details</p> -->

      <div class="page-header">
        <h2>Capture Extraordinary Ability details</h2>
        <p>Provide Extraordinary ability details.</p>
      </div>
    </div>
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <gc-status-update [stepDetails]="stepDetails"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" type="EDIT">
      </gc-status-update>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" 
      (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <div class="main-body footer-adjust" *ngIf="abilityTypeNational">
    <p class="mt-2">You have selected these {{ tabViewSteps.length}} options in sub task. Please provide additional
      details for the same.</p>

    <div class="card border-top-0 border-bottom-0">
      <p-tabView>
        <!-- Example please replace with the component you will use with correct index. -->
        <p-tabPanel [header]="item.name" *ngFor="let item of tabViewSteps; let i = index" [selected]="i == 0">
          <!-- *ngIf="item.code == 'NATIONALAWRD'" -->
          <app-membership-association *ngIf="item.code === 'ASSOCMEMBER'"></app-membership-association>
          <app-highsalary-remuneration *ngIf="item.code === 'HIGHSALARY'"></app-highsalary-remuneration>
          <app-national-international-prizes *ngIf="item.code == 'NATIONALAWRD'"></app-national-international-prizes>
          <app-participation-as-judge *ngIf="item.code === 'PANELJUDGE'"></app-participation-as-judge>
          <app-professional-publications *ngIf="item.code === 'PROFPUBLCTN'"></app-professional-publications>
          <app-research-contribution *ngIf="item.code === 'RESEARCHCONTRBT'"></app-research-contribution>
          <app-author-and-publication *ngIf="item.code === 'SCHLRBOOKAUTHOR'"></app-author-and-publication>
          <app-artistic-exhibitions-showcases *ngIf="item.code === 'ARTEXHIBIT'"></app-artistic-exhibitions-showcases>
          <app-employment-essential-or-critical-capacity *ngIf="item.code === 'CRITCALEMPL'">
          </app-employment-essential-or-critical-capacity>
        </p-tabPanel>
      </p-tabView>
    </div>

  </div>
  <div class="main-body footer-adjust" *ngIf="!abilityTypeNational">
    <p class="mt-2">You have selected 'Receipt of a major, internationally recognized award, such as the Noble prize'
    </p>

    <div class="border p-2">
      <app-national-international-prizes></app-national-international-prizes>
    </div>-
  </div>

  <p-divider></p-divider>
    <!-- pending UI verification -->
   <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId"
    [viewQuestions]="false" [showSaveBtn]="true" [loadedIn]="stepDetails.stepName"
    [isSection]="false"></lib-custom-questionnaire>
   </div>
  <p-divider type="dashed"></p-divider>
  <label *ngIf="abilityTypeNational"><i class="pi pi-info-circle text-primary mr-2"></i>This step can be marked complete
    when you reach the last tab, i.e. {{ tabViewSteps[tabViewSteps.length-1]['name']}}</label>
  <div>
    <!-- <button pButton label="Save" (click)="saveData()" class="d-block"></button>
     <div class="text-center">
        <button type="button" *ngIf="stepStatus !== 'COMPLETE' && !abilityTypeNational" [disabled]="stepStatus !== 'INPROGRESS'"
          class="p-btn btn btn-mark-complete" (click)="onMarkComplete()">
          <span class="btn-text">MARK STEP COMPLETE</span>
          <span class="btn-info">!</span>
        </button>
        <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
      </div>
    </div> -->
  </div>
