<div> 
  <ng-container *ngIf="!isExternalEmployeeId">
  <div class="row">
    <button class="btn btn-primary mb-2" type="button" 
      (click)="addEducation(); stepper?.reset();">
      Add / Edit Education
    </button>
  </div>
  <mat-accordion>
    <mat-expansion-panel hideToggle [expanded]="expandPanel">
      <mat-card>
        <div class="row">
          <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
            class="pb-mat-stepper-custom-horizontal">
            <mat-step [completed]="hasID">
              <form [formGroup]="addDegreeCourceForm">
                <ng-template matStepLabel>Degree Details</ng-template>
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row paddingTop10">
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">Degree</label>
                        <p-dropdown class="w-100" [options]="degreeTypeList" formControlName="degreeType" optionLabel="name" optionValue="id" placeholder="Select Degree"></p-dropdown>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">Field of study</label>
                        <input class="im-input mw-100" formControlName="fieldOfStudy" type="text" form placeholder="Enter" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">Grade/CGPA/Percentage</label>
                        <input type="text" class="im-input mw-100"  name="grade" formControlName="grade" placeholder="Enter" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">University Name</label>
                        <input class="im-input mw-100" formControlName="universityName" type="text" form placeholder="Enter" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="label-cls">Address Line 1</label>
                        <input class="im-input mw-100" maxlength="99"  formControlName="addressLine1" type="text" form placeholder="Enter" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="label-cls">Address Line 2</label>
                        <input class="im-input mw-100" maxlength="99" formControlName="addressLine2" type="text" form
                          placeholder="Enter" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">
                          ZIP / Postalcode<span *ngIf="iscountryUSA"></span>
                        </label>
                        <input class="im-input mw-100" [ngClass]="{
                            'im-input-error':
                              addDegreeCourceForm.get('postCode').touched &&
                              addDegreeCourceForm.get('postCode').errors
                                ?.pattern
                          }" formControlName="postCode" type="text" form placeholder="Enter" maxlength="10" />
                        <span class="invalid-message" *ngIf="
                            addDegreeCourceForm.get('postCode').touched &&
                            addDegreeCourceForm.get('postCode').errors?.required
                          ">
                          {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">Country</label>
                        <p-dropdown class="w-100" [options]="listCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" placeholder="Select Country" (onChange)="handleCountryChange()"></p-dropdown>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="listStates.length > 0">
                      <div class="form-group">
                        <label class="label-cls">State</label>
                        <p-dropdown class="w-100" [options]="listStates" formControlName="stateProvinceCode" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="Select State"></p-dropdown>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="listStates.length <= 0">
                      <div class="form-group">
                        <label class="label-cls">State</label>
                        <input class="im-input mw-100" name="addState" formControlName="stateProvinceName" type="text" placeholder="State" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls">City</label>
                        <input class="im-input mw-100" formControlName="city" type="text" form placeholder="Enter" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls d-block">Start Year</label>
                        <year-date-picker-library [yearFormControl]="addDegreeCourceForm.controls.startYear"></year-date-picker-library>
                        <div class="invalid-message" *ngIf="showStartYearValidationError">
                          {{ getGlobalErrorMessages("INVALID_YEAR") }}
                        </div>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUDTLS') ? true: false) : true">
                      <div class="form-group">
                        <label class="label-cls d-block">End Year</label>
                        <year-date-picker-library [yearFormControl]="addDegreeCourceForm.controls.endYear"></year-date-picker-library>
                        <div class="invalid-message" *ngIf="showEndYearValidationError">
                          {{ getGlobalErrorMessages("INVALID_YEAR") }}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls d-block">Degree Awarded Date</label>
                        <mat-form-field appearance="outline" class="mt-0 date-field w-100">
                          <input formControlName="degreeAwardedDate" matInput [min]="endYearValue"
                            [matDatepicker]="degreeAwardedDate" placeholder="MM/DD/YYYY" (click)="degreeAwardedDate.open()" />
                          <mat-datepicker-toggle matSuffix [for]="degreeAwardedDate"></mat-datepicker-toggle>
                          <mat-datepicker #degreeAwardedDate></mat-datepicker>
                        </mat-form-field>
                        <div class="invalid-message" *ngIf="showAwardedYearValidationError">
                          Awarded Date cannot be less than End year
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <!-- <button mat-button matStepperNext>Next</button> -->
                    <div class="row paddingTop10 dasheedTopBorder">
                      <div class="form-group col-md-6">
                        <lib-save-as-draft confirmModelKey="savedraftdegree" [disabled]="isSaveAsDraftDisabled" *ngIf="beneficiaryType === 'self'" class="mr-3" (onClickSaveAsDraft)="saveAsDraft($event)" [formToBeSaved]="addDegreeCourceForm"></lib-save-as-draft>
                        <button class="btn btn-success" (click)="saveDegreeDetails()">
                          Save
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex flex-row-reverse">
                      <div class="p-2">
                        <button class="btn btn-primary" matStepperNext [disabled]="!hasID">Next</button>
                      </div>
                      <div class="p-2">
                        <button class="back-button" (click)="closeForm()">
                          Close
                        </button>
                      </div>
                      <div class="p-2 f10 mTop10">
                        (Click on save button to save data)
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="addRelatedCourceForm">
              <form [formGroup]="addRelatedCourceForm">
                <ng-template matStepLabel>Related Courses</ng-template>
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row">
                    <label>List of Courses</label>
                  </div>
                  <div class="row">
                    <div class="col-10">
                      <mat-form-field appearance="outline" class="example-chip-list chips-contianer w100">
                        <mat-chip-list #chipList aria-label="Course selection">
                          <mat-chip *ngFor="let cource of courses" [selectable]="selectable" [removable]="removable"
                            (removed)="remove(cource)">
                            {{cource.courseName}}
                            <mat-icon matTooltip="Remove this item" matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Enter Course" [matChipInputFor]="chipList" (paste)="pasteChips($event)"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                      </mat-form-field>
                      <mat-hint class="hint-text">Please enter comma separated values</mat-hint>
                    </div>
                    <div class="col-2 pt-3">
                      <button class="btn btn-success" (click)="addCourses()" [disabled]="courses && courses.length === 0">
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="row mt-4" *ngIf="listCourses && listCourses.length > 0">
                    <h5>Added Courses</h5>
                    <table class="im-card-no-border-table" style="width: 100%">
                      <tbody>
                        <tr class="col-md-12" *ngFor="let course of listCourses; let i = index">
                          <td class="w40">
                            <div class="circleIndex rounded-circle">
                              {{ i + 1 }}
                            </div>
                          </td>
                          <td class="col-md-10">{{ course.courseName }}</td>
                          <td class="col-md-2" (click)="deleteEducation(i)" style="cursor: pointer">
                            <mat-icon matTooltip="Delete" *ngIf="!viewMode">delete_forever</mat-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row mt-2 paddingTop10 dasheedTopBorder">
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <button class="back-button" matStepperPrevious *ngIf="iscaseRequest ? (currentItemDisplay.includes('DGRENAME') ? true: (currentItemDisplay.includes('EDUDTLS') ? true: (currentItemDisplay.includes('GRADE') ? true: (currentItemDisplay.includes('STUDYFIELD') ? true: (currentItemDisplay.includes('UNIVERSITYADR') ? true: (currentItemDisplay.includes('UNIVERSITYNAME') ? true: false)))))) : true">
                        Back
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button  *ngIf="!modeVal" class="back-button mr-3" (click)="closeForm(); stepper.reset()">
                        Close
                      </button>
                      <button class="btn btn-success" matStepperNext>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Related Documents</ng-template>
              <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                <div class="row">
                  <!--  3391 -->
                  <p>
                    Select the related Degree documents and then click upload to
                    save
                  </p>
                </div>
                <div class="row">
                  <div class="col-12">
                  <immigration-file-upload class="w-100" [primaryData]="inputData" [configurations]="degreeFileUploadConfigurations"
                  (fileUploadSuccess)="fileUploadSuccess()">
                  </immigration-file-upload>
                  </div>
                </div>
                <div class="row paddingTop10 dasheedTopBorder">
                  <!-- <div class="form-group">
                  <button class="btn btn-success">Save Changes</button>
              </div> -->
                </div>
                <hr />
                <div class="row">
                  <div class="col-4">
                    <button class="back-button" matStepperPrevious>Back</button>
                  </div>
                  <div *ngIf="!viewMode" class="col-8 text-right">
                    <button class="back-button mr-3" *ngIf="!modeVal" (click)="closeForm(); stepper.reset()">
                      Close
                    </button>
                    <button class="btn btn-primary" matStepperNext (click)="addNewDegree(); stepper.reset();"
                      *ngIf="!viewMode">
                      Add New Degree
                    </button>
                  </div>
                </div>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
 </ng-container>
</div>

<!--- LISTING CODE START-->
<div>
  <div class="p-3">
    <div>{{educationDetails.length > 0 ? educationDetails.length : 'No' }} Degrees Added by you</div>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="im-card-no-border-table">
      <ng-container matColumnDef="{{column.columnDef}}" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
        <td mat-cell *matCellDef="let element">
          {{column.columnDef == 'degreeType' ? element?.degreeType?.description : element[column.columnDef] }}
          <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <mat-icon matTooltip="View more" class="mr-1 icn">expand_more</mat-icon>
          </span>
          <div>
            <div class="row" style="width:100px;" *ngIf="column.columnDef == 'action'">
              <mat-icon class="mr-1 icn" matTooltip="Edit" class="im-table-verticle-icon"
                (click)="editDegreeHandler(element)">edit</mat-icon>
              <mat-icon class="mr-1 icn" matTooltip="Delete" (click)="handleDelete(element,addDegreeCourceForm)">
                delete_forever</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="w100 example-element-description py-1" *ngIf="element && element.courses && element.courses.length > 0">
              <h6>List of Courses</h6>
              <ng-container>
                <table class="table table-striped">
                  <tr *ngFor="let item of element.courses;let i=index" class="border border-dark">
                    <td class="w40">
                      <div class="circleIndex rounded-circle">{{i+1}}
                      </div>
                    </td>
                    <td nowrap>
                      {{item.courseName}}
                    </td>
                  </tr>
                </table>
              </ng-container>
            </div>
            <div class="w100 example-element-description py-1" *ngIf="element && element.documents && element.documents.length > 0">
              <h6>List of Documents</h6>
              <ng-container>
                <table class="im-card-no-border-table">
                  <thead>
                    <tr>
                      <th class="w40"></th>
                      <th>Document</th>
                      <th>Document Type</th>
                      <th><i class="fa fa-download fa-lg icn" aria-hidden="true"></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of element.documents;let i=index">
                      <td class="w40">
                        <div class="circleIndex rounded-circle">{{i+1}}
                        </div>
                      </td>
                      <td>
                        <strong>{{ item?.fileName }}</strong>
                      </td>
                      <td nowrap>
                        {{ item?.fileCategory?.code === 'OTHER' ? item?.fileCategoryOtherName : item?.fileCategory?.name }}
                      </td>
                      <td nowrap>
                         <!-- <app-file-download [fileName]="item.fileLocationName"
                          [Path]="envPath+item.fileLocation.substring(23)"></app-file-download>  -->
                          <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                    (click)="downloadDoc(item)" class="p-button-text p-button-rounded p-3"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>
<!--- LISTING CODE END-->