import { Component, Inject,OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VideoTourService } from 'src/app/services/video-tour.service';
import { PetitionerMenuComponent} from 'src/app/modules/petitioner-landing/petitioner-menu/petitioner-menu.component'

@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.scss']
})
export class TourModalComponent implements OnInit {
  @ViewChild(PetitionerMenuComponent) petitionerMenuComponent: PetitionerMenuComponent;
  userType;
  resourceLink;
  constructor(
    private router: Router,
    private videoTourService:VideoTourService,
      public dialogRef: MatDialogRef<TourModalComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData) 
  { 
    dialogRef.disableClose = true;
    this.populateData();
  }

  ngOnInit() {
  }

  populateData(){
    this.userType = this.dialogData.update.userType;
    }

    routeToResourcePage() {
      let resourceLink;
      if (this.userType == 'Petitioner') {
        resourceLink = '/petitioner-landing/resources';
      } else if (this.userType == 'Beneficiary') {
        resourceLink = '/beneficiary-landing/resources';
      } else if (this.userType == 'Attorney') {
        resourceLink = '/attorney-landing/resources';
      }
      this.router.navigate([resourceLink]);
    }

    startVideoTour(){
      this.dialogRef.close();
      this.videoTourService.videoTourStart(this.userType);

      // if (this.userType) {
        
      //     case 'Beneficiary': {
      //       this.joyrideService.startTour(
      //         { steps: ['beneficiaryFirstStep@beneficiary-landing','beneficiarySecondStep']}
      //       );
           
      //       break;
      //     }
      //   }
      // }
  
  }
}
