import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PetitionAnalysisService } from 'src/app/services/petition-analysis.service';
import { PAObservation, PAChart, PAObservationCategories } from 'src/app/data/models/petitionAnalysis.model';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { PAUtility } from '../utility.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-pa-overall',
  templateUrl: './pa-overall.component.html',
  styleUrls: ['./pa-overall.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class PaOverallComponent implements OnInit {

  observations: PAObservation[] = [];
  backUpObservations: PAObservation[] = [];
  categoryList;
  summaryDetails: PAChart;
  categoryDetails: PAChart[];
  petitionId: number;
  selObservationFilterCtg: number[] = [];
  selectAllObservations: boolean = true;
  observationCategories: PAObservationCategories[] = [
    { name: 'Needs to be fixed', code: 0 },
    { name: 'Looks Perfect', code: 1 },
    { name: 'Not Applicable', code: 2 },
    { name: 'Not Initiated', code: 3 }
  ];

  summeryGraphDetails: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };

  constructor(private pAService: PetitionAnalysisService,
    private utilityPAService: PAUtility,
    private router: Router,
    private route: ActivatedRoute,) { }


  ngOnInit() {
    this.petitionId = parseInt(sessionStorage.getItem('petitionId'));
    this.getPetitionAnalysis();
    this.handleSelectAll();
  }

  refreshPA() {
    this.pAService.generateAnalysis(this.petitionId).subscribe(() => {
      this.getPetitionAnalysis();
    });
  }

  getPetitionAnalysis() {
    this.pAService.getPetitionAnalysisStatus(this.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.getCategoryList();
        this.getSummary();
        this.getObservationSummery();
      }
    });
  }

  getObservationSummery() {
    this.pAService.getCategoryObervation(this.petitionId)
      .subscribe((response: PAObservation[]) => {
        this.utilityPAService.setObservationList(response.map((x, i) => {
          return {
            ...x,
            indexId: i
          };
        }));
        this.observations = this.utilityPAService.getObservationList();
      });
  }

  getSummary() {
    this.pAService.getSummery(this.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.summaryDetails = response.data as PAChart;
      }
      else {
        this.summaryDetails = null;
      }
      this.summeryGraphDetails = Object.assign({}, {
        labels: [`${Math.round(((100 * this.summaryDetails.success) / this.summaryDetails.total))}% Looks Perfect`,
        `${Math.round(((100 * this.summaryDetails.failure) / this.summaryDetails.total))}% Needs to be fixed`,
        `${Math.round(((100 * this.summaryDetails.notinitiated) / this.summaryDetails.total))}% Not Initiated`,
        `${Math.round(((100 * this.summaryDetails.na) / this.summaryDetails.total))}% Not Applicable`],
        datasets: [
          {
            data: [
              Math.round((100 * this.summaryDetails.success) / this.summaryDetails.total),
              Math.round((100 * this.summaryDetails.failure) / this.summaryDetails.total),
              Math.round((100 * this.summaryDetails.notinitiated) / this.summaryDetails.total),
              Math.round((100 * this.summaryDetails.na) / this.summaryDetails.total)
            ],
            backgroundColor: [
              "#03A0DA",
              "#FF8800",
              "#A3A6B4",
              "#E1E1E1"
            ],
            hoverBackgroundColor: [
              "#03A0DA",
              "#FF8800",
              "#A3A6B4",
              "#E1E1E1"
            ]
          }]
      });
    });
  }

  getCategoryList() {
    this.pAService.getCategorySummary(this.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.categoryDetails = response.data as PAChart[];
      }
      else {
        this.categoryDetails = null;
      }
      this.categoryList = this.categoryDetails.map(x => {
        x['successPercentage'] = parseInt(((100 * x.success) / x.total).toString(), 10);
        x['failurePercentage'] = parseInt(((100 * x.failure) / x.total).toString(), 10);
        return x;
      });
    });
  }

  handlePaCategorized(category) {
    this.utilityPAService.categoryCode = category.code;
    this.utilityPAService.categoryName = category.name;
    this.router.navigate(['../pa-categorized', category.code], { relativeTo: this.route });
  }

  handleFilter() {
    if (this.selObservationFilterCtg && this.selObservationFilterCtg.length > 0) {
      this.observations = this.utilityPAService.getObservationList().filter((x: PAObservation) => {
        if (this.selObservationFilterCtg.includes(x.status)) {
          return true;
        }
      });
    }
    else {
      this.observations = [];
    }
    this.togglleAllFilter();
  }

  togglleAllFilter() {
    this.selectAllObservations = this.selObservationFilterCtg.length === this.observationCategories.length ? true : false;
  }

  handleSelectAll() {
    this.selObservationFilterCtg = this.selectAllObservations ? this.observationCategories.map(x => x.code) : [];
    this.handleFilter();
  }
}
