<p-dialog class="rfeDialog" [contentStyle]="{'overflow': 'visible'}" [(visible)]="rfeDialogDisplay" [modal]="true"
  [responsive]="true" [draggable]="false" [position]="dialogPosition"
  [style]="{width: '1000px', minWidth: '600px',minHeight:'200px'}" [minY]="70"
  (onShow)="updateData()" (onHide)="returningDataToParent()" [maximizable]="false" [baseZIndex]="10000">
  <ng-template pTemplate="header" [hidden]="hideHeader">
    <div>
      <div class="row">
        <div class="col-12 px-0">
          <h5 class="text-primary">{{ header }}</h5>
        </div>
      </div>
      <div class="row" *ngIf="subHeader">
        <div class="col-12 px-0">
          <label>{{ subHeader }}</label>
        </div>
      </div>
    </div>
    <div class="d-flex row col-md-7">
      <button type="button" *ngIf="currentStatus == 'COMPLETE' || currentStatus == 'SUBMIT'" [disabled]="disableEdit"
        (click)="handleStatusEdit()" style="right:5rem"
        class="p-button-text p-button-rounded float-right im-btn im-btn-with-icon im-btn-outline-primary pull-right button_whitebg position-absolute">
        Edit <span class="ml-2"><i class="fa fa-pencil"></i></span>
      </button>
      <div [pTooltip]="stepDetails?.notes?.noteText" class="pull-right icon-background notes task-notes-btn float-right"
        [class.yellow]="stepDetails?.notesAvailable===true" #btnNote (click)="notepanel.toggle($event, btnNote)">
        <!-- <i class="material-icons icn-note">assignment</i>     -->
        <span class="icn-note"></span>
      </div>
      <p-overlayPanel #notepanel appendTo="btnNote" class="notepanel" [showCloseIcon]="true" [dismissable]="true">
        <ng-template pTemplate>
          <task-notes-lib [step]="stepDetails" (noteAdded)="updateNoteAvailability($event)"></task-notes-lib>
        </ng-template>
      </p-overlayPanel>
    </div>
    <div class="maximize-icon-outer" *ngIf="stepLayoutCode">
      <span (click)="toggleMaximize()" class="p-dialog-header-maximize-icon pr-1"><i [ngClass]="maximized ? minimizedIconClass : maximizedIconClass"></i></span>
    </div>
  </ng-template>
  <hr class="mt-0" />
  <form name="dialogForm" #dialogForm="ngForm" (ngSubmit)="saveData();">
    <div [ngSwitch]="stepNameNumber">
      <div *ngSwitchCase="'1'">
        <app-rfe-upload-notice (notify)="parentListener($event)"></app-rfe-upload-notice>
      </div>
      <div *ngSwitchCase="'2'">
        <app-rfe-select-clauses (notify)="parentListener($event)" [rfeTimelineModalInput]="rfeTimelineModalInput">
        </app-rfe-select-clauses>
      </div>
      <div *ngSwitchCase="'3'">
        <app-rfe-prepare-response (notify)="parentListener($event)" [rfeTimelineModalInput]="rfeTimelineModalInput">
        </app-rfe-prepare-response>
      </div>
      <div *ngSwitchCase="'4'">
        <app-rfe-provide-additional-details (notify)="parentListener($event)"
          [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-provide-additional-details>
      </div>
      <div *ngSwitchCase="'5'">
        <app-rfe-provide-additional-details (notify)="parentListener($event)"
          [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-provide-additional-details>
      </div>
      <div *ngSwitchCase="'6'">
        <app-rfe-review-response (notify)="parentListener($event)" [rfeTimelineModalInput]="rfeTimelineModalInput">
        </app-rfe-review-response>
      </div>
      <div *ngSwitchCase="'7'">
        <app-rfe-print-response (notify)="parentListener($event)" [rfeTimelineModalInput]="rfeTimelineModalInput">
        </app-rfe-print-response>
      </div>

    </div>
  </form>
</p-dialog>
