import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
import { I140FileUploadConfiguration } from 'app-models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { sync_petitioner_document_code } from '../../../utility/constants/sync-code';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { select } from '@ngrx/store';
//import * as  froalaService from 'src/app/modules/utility/froala-service';

import { BeneficiaryWorkExperience } from 'src/app/modules/green-card/green-card-models/beneficiary-work-experience';
import { ExperienceDetail } from 'src/app/modules/green-card/green-card-models/experience-detail';
import * as fromGCSubTask from 'src/app/modules/green-card/green-card-store/reducers/greencard-subtask.reducer';
import * as DirtyForm from 'src/app/app-state/actions/dirty-form.actions';
import { MembershipAssociationService } from './membership-association.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { Subject } from 'rxjs/internal/Subject';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, getSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { environment } from 'environment-configurations';
import * as errorUtils from "error-message-utility";
import { combineLatest } from 'rxjs';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { DialogService } from 'primeng/dynamicdialog';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { CountryApiService } from 'src/app/modules/petitioner/services/country.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};




@Component({
  selector: 'app-membership-association',
  templateUrl: './membership-association.component.html',
  styleUrls: ['./membership-association.component.scss']
})
export class MembershipAssociationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("membershipassociationimageupload") public membershipassociationimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("membershipassociationkendoeditor") public membershipassociationkendoeditor: EditorComponent;
  membershipForm: FormGroup;
  formDirtyFromDifferentControls = false;
  subcriptions: Subscription[] = [];
  markStep: string;
  isFormValid = false;
  checkStateMondatory = false;
  experienceList: ExperienceDetail[];
  permBeneficiaryExperienceDetails: BeneficiaryWorkExperience;
  // public options = FroalaEditorOptions.EditorOptions;

  /*public options = {
    ...FroalaEditorOptions.EditorOptions,
    events: {
      contentChanged: () => {
        this.setFormDirty();
      }
    }
  };*/
  disabledControls: boolean;
  stepDetails: TaskStep;
  countryList: any;
  observableSubscription$ = new Subject();
  listStates: [];
  addEditButtonText = 'Add';

  membershipList: any = [];
  additionalInfo;
  primaryData;
  listCountry: [];

  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;
  inputData: PrimaryData;

  constructor(
    private formBuilder: FormBuilder,
    private cacheCountryService: CacheCountryService,
    private apiService: CountryApiService,
    private changeDetectorRef: ChangeDetectorRef,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private store: Store<fromGCSubTask.State>,
    public dynamicDialogConfig: DynamicDialogConfig,
    private membershiAssocService: MembershipAssociationService,
    private cacheCountyService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    private uploadService: I140FileUploadService,
    public dialogService: DialogService,

    private toastr: ToastrService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.inputData = this.dynamicDialogConfig.data;
    this.listCountry = [];

    this.countryList = [];
    this.listStates = [];
    this.disabledControls = false;
    this.membershipForm = formBuilder.group({
      membershipType: ['', [Validators.required]],
      associationName: ['', [Validators.required]],
      addressOne: ['', [Validators.required]],
      addressTwo: [''],
      city: ['', [Validators.required]],
      state: [''],
      countryCode: [''],
      postal: [''],
      id: [null]
    });
  }

  template: {
    body: string,
  };
  task: SubTaskDetails;
  extrAblDocEvdCatg = [];
  showMarkComplete = false;
  tabViewSteps: any;
  stepStatus: any;
  ngOnInit(): void {

    this.cacheCountryService.getCountry().subscribe();
    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
      }
    });
    this.getListInfo();
    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;
        this.tabViewSteps = this.task.extrAblDocEvdList.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
        setTimeout(() => {
          this.getAdditionalInfo();
        }, 2000);
        this.stepDetails = response[1];

        // if (this.stepStatus === 'COMPLETE') {
        //   this.disabledControls = true;
        // } else {
        //   this.disabledControls = false;
        // }
        if (this.tabViewSteps[this.tabViewSteps.length - 1]['code'] == 'ASSOCMEMBER') {
          this.showMarkComplete = true;
        }

        this.extrAblDocEvdCatg['infoType'] = "ASSOCMEMBER";
        this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          // getDocTypeApiUrl:`/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_US}/documentTypes`,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_MEMBERSHIP}/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_MEMBERSHIP}/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'MEMBRDOC'

        });
        this.toggleControlsStatus();
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
        // this.getListInfo();
      });
    // this.subcriptions[this.subcriptions.length] = this.membershipForm.statusChanges.subscribe(() => {
    //   if (this.membershipForm.dirty) {
    //     this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
    //   } else {
    //     this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
    //   }
    // });

    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit ';
    } else {
      this.markStep = 'MARK STEP COMPLETE';
    }
  }

  ngAfterViewInit() {
    this.subcriptions[this.subcriptions.length] = this.membershipForm.statusChanges.subscribe(() => {
      if (this.membershipForm.dirty) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }
  deleteDocument(id) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.membershiAssocService.deleteMembership(
          this.dynamicDialogConfig.data.petitionId, id,
        ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.addEditButtonText = 'Add';
          this.membershipForm.reset();
          this.getListInfo();
        });
      }
    });
  }
  // handleCountryChange(countryCode: string) {
  //   this.apiServiceState.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
  //     this.listStates = states;
  //     //this.changeDetector.markForCheck();
  //   });
  // }

  handleCountryChange(countryCode: string) {
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.listStates = states;
      this.changeDetectorRef.markForCheck();
    });
  }
  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  getCountries() {
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
      }
    });
  }
  openmembershipassociationimageuploaddialog() {
    this.membershipassociationimageupload.open();
  }
  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }
    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
    // this.toggleControlsStatus();
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      // getDocTypeApiUrl:`/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_US}/documentTypes`,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_MEMBERSHIP}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_MEMBERSHIP}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'MEMBRDOC'
    });
  }


  isFormValidCheck() {
    this.isFormValid = true;
    this.experienceList.forEach(experience => {
      const experienceVar = experience.gcAdditionalWorkInfoDto;
      if (!experienceVar.businessType) {
        this.isFormValid = false;
        experienceVar.businessTypeValid = false;
      } else {
        experienceVar.businessTypeValid = true;
      }
      if (!experienceVar.hrsPerWeek || (experienceVar.hrsPerWeek && experienceVar.hrsPerWeek === 0)) {
        this.isFormValid = false;
        experienceVar.hrsPerWeekValid = false;
      } else {
        experienceVar.hrsPerWeekValid = true;
      }
      if (experienceVar.supervisorEmail && !this.validateEmail(experienceVar.supervisorEmail)) {
        this.isFormValid = false;
        experienceVar.supervisorEmailValid = false;
      } else {
        experienceVar.supervisorEmailValid = true;
      }
      // if (!experienceVar.supervisorEmail ||
      //   (experienceVar.supervisorEmail && experienceVar.supervisorEmail.trim() === '') ||
      //   (experienceVar.supervisorEmail && !this.validateEmail(experienceVar.supervisorEmail))) {
      //   this.isFormValid = false;
      //   experienceVar.supervisorEmailValid = false;
      // } else {
      //   experienceVar.supervisorEmailValid = true;
      // }
      // if (!experienceVar.supervisorName || (experienceVar.supervisorName && experienceVar.supervisorName.trim() === '')) {
      //   this.isFormValid = false;
      //   experienceVar.supervisorNameValid = false;
      // } else {
      //   experienceVar.supervisorNameValid = true;
      // }
      // if (!experienceVar.supervisorPhoneCode) {
      //   this.isFormValid = false;
      //   experienceVar.supervisorPhoneCodeValid = false;
      // } else {
      //   experienceVar.supervisorPhoneCodeValid = true;
      // }
      // if (!experienceVar.supervisorPhoneNo) {
      //   this.isFormValid = false;
      //   experienceVar.supervisorPhoneNoValid = false;
      // } else {
      //   experienceVar.supervisorPhoneNoValid = true;
      // }
    });
    const additionInfoVar = this.permBeneficiaryExperienceDetails.gcAdditionalInfoResponseDTO;
    if (!additionInfoVar.additionalText ||
      (additionInfoVar.additionalText &&
        additionInfoVar.additionalText.trim() === '')) {
      this.isFormValid = false;
      additionInfoVar.additionalTextValid = false;
    } else {
      additionInfoVar.additionalTextValid = true;
    }
  }

  validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  }
  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }


  saveMemebership() {
    let formData = this.membershipForm.value;
    let selectedState: any;
    if (this.listStates.length > 0) {
      selectedState = this.listStates.find((element: any) => element.stateProvinceCode === formData.state);
    }
    let payload = {
      memberType: formData.membershipType,
      assocName: formData.associationName,
      addressLine1: formData.addressOne,
      addressLine2: formData.addressTwo,
      postCode: formData.postal,
      city: formData.city,
      countryCode: formData.countryCode,
      stateProvinceCode: !selectedState ? null : selectedState.stateProvinceCode,
      stateProvinceName: !selectedState ? null : selectedState.stateProvinceName,
      id: formData.id
    };
    this.membershiAssocService.saveMemberShip(this.dynamicDialogConfig.data.petitionId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.addEditButtonText = 'Add';
        this.membershipForm.reset();
        this.getListInfo();
        this.toastr.success(response.message, 'Success');
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.updateStepStatus('INPROGRESS');
        }
      }
    });
  }
  editDocument(document) {
    this.membershipForm.patchValue({
      membershipType: document.memberType,
      associationName: document.assocName,
      addressOne: document.assocName,
      addressTwo: document.addressLine2,
      city: document.city,
      state: document.stateProvinceCode,
      postal: document.postCode,
      countryCode: document.countryCode,
      id: document.id
    });
    this.addEditButtonText = 'Update';
  }
  getListInfo() {
    this.membershiAssocService.getList(this.dynamicDialogConfig.data.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response['data']) {
        this.membershipList = response['data'];
        this.toastr.success(response.message, 'Success');
      } else {
        this.membershipList = [];
      }
    });
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  // cancelForm() {
  //   this.membershipForm.reset();
  // }
  getAdditionalInfo() {
    this.membershiAssocService.getSummaryInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, "ASSOCMEMBER").subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          // this.toastr.success(response.message);
          this.additionalInfo = response['data']['additionalText'];
          //froalaService.insertPageBreak();
        }
      });
  }

  saveSummaryInfo() {
    let payload = {
      "additionalText": this.additionalInfo,
      "infoType": "ASSOCMEMBER"
    };
    this.membershiAssocService.saveSummary(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.getAdditionalInfo();
          this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
          this.toastr.success(response.message, 'Success');
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
  }

  public membershipassociationeditorValueChange(value: string): void {
    this.additionalInfo = value;
  }
}
