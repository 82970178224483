import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
@Component({
  selector: 'custom-error-toast',
  templateUrl: './custom-error-toast.component.html',
  styleUrls: ['./custom-error-toast.component.scss']
})
export class CustomErrorToastComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  // Method to close the toast
  close() {
    this.toastPackage.triggerAction();
  }

  // Static method to display error toast
  static showErrorToast(toastrService: ToastrService, errorMessage: string) {
    const toastRef = toastrService.error(errorMessage, 'Error', {
      positionClass: 'toast-middle-center',
      closeButton: true,
      disableTimeOut: true,
      toastComponent: CustomErrorToastComponent,
    });

    // Add keydown listener for 'Enter' key
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        toastRef.toastRef.close();
      }
    });
  }
}
