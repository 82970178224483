<div class="p-grid">
    <div class="p-col-12">
      <div class="row border-bottom">
        <div class="col-md-10 py-3 px-0">
          <h5 class="text-primary m-0">Confirm</h5>
        </div>
        <div class="col-md-2 p-0 align-items-center justify-content-end d-flex">
          <i (click)="closePopup(false)" class="close-icon pi pi-times"></i>
        </div>
      </div>
    </div>
    <div class="p-col-12">
        <div class="row border-bottom">
            <div class="col-md-12 py-3 px-0">
              <p>Task is already created for the beneficiary, would like to continue to create new task ?</p>
              <!-- <p>Are you sure, you want to proceed?</p> -->
            </div>
        </div>
    </div>
    <div class="p-col-12 p-0">
        <div class="row">
            <div class="col-md-12 pt-1 m-0 ui-dialog-button-bar">
                <button type="button" pButton icon="pi pi-times" label="No" (click)="closePopup(false)"></button>
                <button type="button" pButton icon="pi pi-check" label="Yes" (click)="closePopup(true)"></button>
            </div>
        </div>
    </div>
  </div>

  