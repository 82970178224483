import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import {ConfirmationService} from 'primeng/api';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Roles from 'src/app/data/constants/roles';
import { stepStatus } from '../../../../data/constants/step-status';
import { acceptedFileTypes } from 'app-models';
@Component({
  selector: 'app-rfe-upload-notice',
  templateUrl: './rfe-upload-notice.component.html',
  styleUrls: ['./rfe-upload-notice.component.scss']
})
export class RfeUploadNoticeComponent implements OnInit {

receiptNo;
noticeDate;
receiptDate;
uploadedFile: any;
noticeId;
envPath:any;
SubTaskOverView;
userType;
currentStatus;
disableFields = false;
enableMarkComplete = false;
@Output () notify: EventEmitter<string> = new EventEmitter<string>();
acceptedTypes = acceptedFileTypes;
  constructor( private rfeService:RfeService,
    private confirmationService: ConfirmationService,
    private store: Store<fromTask.State>,
    private toastr: ToastrService,public datepipe: DatePipe
    ) {
      this.userType = sessionStorage.getItem('userTypeRole');
   }

  ngOnInit() {
    this.initialiseData();
  }

  initialiseData(){
    this.envPath = environment.docs;
    this.getRFEDetails();
    this.uploadedFile = "";
  }

  openDoc(fileLocation) {
    const docPath = fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }

  getRFEDetails(){

    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      if(this.SubTaskOverView.taskStepDetails[0].stepStatusDetails.stepStatusCode == 'COMPLETE')
        this.disableFields = true;
      else
      this.disableFields = false;
    });
    
    if(this.userType === Roles.ATTORNEY){
    this.rfeService
      .getRFEDetails(this.SubTaskOverView['id'])
      .subscribe((data) => {
        this.uploadedFile = data['receiptNoticeDoc'];   
        this.noticeId = data['id'];
        this.receiptNo = data['receiptNo'];
        if(data['rfeNoticeDate'] != null)
          this.noticeDate = new Date(data['rfeNoticeDate']);
        if(data['rfeResponseDueDate'] != null)
          this.receiptDate = new Date(data['rfeResponseDueDate']);
        if(!this.receiptNo && !this.noticeDate  && !this.receiptDate)
          this.enableMarkComplete = true;
        else  
          this.enableMarkComplete = false;
      });
    }
  }

  saveData(){
    let payload = new FormData();
    if(this.uploadedFile != null && this.uploadedFile != ""){
      if(this.uploadedFile.createdBy =="" || this.uploadedFile.createdBy == null)
        payload.append("file", this.uploadedFile , this.uploadedFile.fileName);
    }
      this.rfeService.saveRFEDetails(this.SubTaskOverView['id'],this.receiptNo,this.datepipe.transform(this.noticeDate, 'dd-MM-yyyy'),
      this.datepipe.transform(this.receiptDate, 'dd-MM-yyyy'),payload).subscribe((response: any) => {
        if (response) {
          this.enableMarkComplete = false;
          this.toastr.success(response.message, 'Success');
        }
      });
      if(this.noticeId != null){
        const payload = {
          statusCode: "INPROGRESS",
          stepNote: ''
        };
      this.rfeService.updateRfeNotice(this.noticeId,"UPLOAD_RFE_NOTICE", payload).subscribe((response:any) => {
        if(response){
          
        }
        
      });
    }
  
  }

confirmDelete(uploadedFile){
  this.confirmationService.confirm({
    message: 'Are you sure you want to delete?',
    accept: () => {
      this.deleteFiles(uploadedFile);
    },
    reject: () => {
    }
});
}

  deleteFiles(uploadedFile) {
    if(uploadedFile["fileLocation"] != null){
      this.rfeService.deleteRFEDocument(this.SubTaskOverView['id'],uploadedFile.id).subscribe((response:any) => {
        if(response){
          this.toastr.success(response.message, 'Success');
          this.uploadedFile = "";
        }
      });
    }
    else{
      this.uploadedFile = "";
    }
    //
}

  onUpload(event,fileUpload) {
    //this.uploadDocument();
    for(let file of event.files) {
      this.uploadedFile = file;
      this.uploadedFile["fileName"] = file.name;
    }
    fileUpload.clear();
  }

  changeStatusToComplete(){
    this.notify.emit(stepStatus.Complete);
  }
}
