<div class="dashboard-regular-card mat-card profile-card imageBox">
  <app-help-video-overlay #helpVideoComponent></app-help-video-overlay>

  <img [src]="imgsrc" alt="Your(beneficiary) profile picture">
  <div class="card-body p-2">
    <h4 class="card-title mb-1">{{userName}}</h4>
    <h6 class="card-sub-title mb-3">{{this.email}}</h6>
  </div>
  <a routerLink="../../beneficiaries/beneficiary-profile-data/bene-personal-details"
   class="edit-btn edit-profile-btn im-btn im-btn-light im-btn-lg py-2 px-3 mb-2">EDIT
    PROFILE</a>
  <div class=" top-bordered-box mt-2">
    <div class="action-box">
      <div class="profile-status">
        <div class="status-data py-2">
          <span class="status-text float-left">Status</span>
          <span *ngIf="profileCompletionPerecentage != '100%'"
            class="font-14-600 status-value float-right text-danger">Incomplete</span>
          <span *ngIf="profileCompletionPerecentage == '100%'"
            class="font-14-600 status-value float-right text-success">Complete</span>
        </div>
        <div class="status-bar">
          <div class="im-progress-value">{{profileCompletionPerecentage}}</div>
          <div class="im-progress">
            <div class="im-progress-bar bg-success" role="progressbar"
              [ngStyle]="{'width': profileCompletionPerecentage}" aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row tp-brder btm-brder message-box">
        <div>
          <ng-container *ngIf="this.alertsList.length > 0 ; else noalert">
            <span class="badge p-2 btn-orange-alert">Alerts</span>
          </ng-container>
          <ng-template #noalert>
              <span  class="badge p-2 badge-light">Alerts</span>
           </ng-template>
        </div>
        <div class="flex-col font-10 pl-4 mr-auto">
            <ng-container *ngIf="this.alertsList.length > 0 ; else noAlertData" >
              <span class=" text-left alert-text-header">
                <strong>{{this.alertsList[alertWidgetPagination.currentIndex]['title']}}</strong>
              </span>
              <span class="text-left alert-text-para">
                {{this.alertsList[alertWidgetPagination.currentIndex]['text']}}
              </span>
            </ng-container>
            <ng-template #noAlertData>
              <span>No alerts for you</span>
            </ng-template>
        </div>
        <div class="pt-1 ml-4">
          <ul class="pagination">

            <li [ngClass]="{'disabled-click':alertWidgetPagination.currentIndex == 0 }">
              <a (click)="setContentIndex('dec')">
                <span class="material-icons"> keyboard_arrow_left </span>
              </a>
            </li>

            <li [ngClass]="{'disabled-click': alertWidgetPagination.currentIndex == alertWidgetPagination.maxIndex}">
              <a (click)="setContentIndex('inc')">
                <span class="material-icons">keyboard_arrow_right</span>
              </a>
            </li>

          </ul>
          <div class="index-track" *ngIf="this.alertsList.length > 0">
            {{ this.alertWidgetPagination.currentIndex+1}}/{{ this.alertWidgetPagination.maxIndex+1}}
          </div>
        </div>
      </div>
      <div class="text-center">
        <p class="show-more"><a [routerLink]="'../../beneficiaries/alerts'">Show More</a></p>
      </div>
    </div>
  </div>
</div>