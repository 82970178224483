export const DeleteDialogConfigurations = {
    width: '30%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001"}
};

export const NoteDialogConfigurations = {
    width: '40%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001"},
    data: {}
};