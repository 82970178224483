import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class BeneficiaryMyformService {

  constructor(private httpClient: HttpClient) { }

getBeneficiary(accessCode: number){
  return this.httpClient.get(`/admin/getQuestionaryByAccessToken/${accessCode}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status) {
        return response;
      }
    })
  );
}

downloadcsv(payload, beneficiaryId){ 
  return this.httpClient.post(`/externalAttorney/company/downloadcsv/${beneficiaryId}`, payload).pipe(
    map((response) => response)
  ); 
}

  getMyformList(beneficiaryId,formType) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/myForms/formType/${formType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getLcaAckAndPostDecDetail(companyId,petitionId,formType) {
    return this.httpClient.get(`/company/${companyId}/petition/${petitionId}/formType/${formType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }
  saveLcaAcknowledgeFlag(companyId,petitionId,lcaId,ackFlag,payload){
    return this.httpClient.post(`/company/${companyId}/petition/${petitionId}/lca/${lcaId}/ackFlag/${ackFlag}`, payload).pipe(
      map((response) => response)
    );

  }
  updateNotification(taskId,petitionId,beneficiaryId,formType,payload){
    return this.httpClient.post(`/lca/notification/task/${taskId}/petition/${petitionId}/beneficiary/${beneficiaryId}/form/${formType}`, payload).pipe(
      map((response) => response)
    );

  }

  saveLcaAckPostDeclareDocuments(companyId,petitionId,categoryName,fileCategory,payload){
    return this.httpClient.post(`/company/${companyId}/petition/${petitionId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response) => response)
    );

  }
  deleteLcaAckPostDeclareDocuments(companyId,petitionId,documentId) {
    return this.httpClient.delete(`/company/${companyId}/petition/${petitionId}/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

}


