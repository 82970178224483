<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-10 step-header">
            <div class="mat-title titleColor font-weight-bold clsmarg">Clients</div>
            <div class="mat-subheading-1 titleColor clsmarg">Manage Client details</div>
        </div>
    </div>
    <p-divider class="pb-15"></p-divider>

    <div class="mat-elevation-z8">
        <div class="row tab-container">
            <div class="col-2 tab-label tab-height p-2">
                <div class="lbl-text align-tabs">
                    <span class="font-weight-bold">Billing Details</span>
                </div>
            </div>
            <div class="col-2 tab-label tab-height p-2 btnLft">
                <div class="lbl-text align-tabs">
                    <button pButton type="button" (click)="routeToGenerateInvoice()"
                        class="im-btn button_whitebg im-btn-with-icon im-btn-outline-primary">
                        <div class="d-flex">
                            <span>Generate Invoice</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="mat-elevation-z8"> -->
            <p-table class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                styleClass="p-datatable-gridlines" [value]="getAllTabsData" dataKey="id"
                >
                <ng-template pTemplate="header" let-columns>
                    <tr class="text-uppercase">
                        <th class="row-text text-capitalize">Invoice Number </th>
                        <th class="row-text text-capitalize">Generated On</th>
                        <th class="row-text text-capitalize">Due Date </th>
                        <th class="row-text text-capitalize">Payment Date </th>
                        <th class="row-text text-capitalize">Times Resent</th>
                        <th class="row-text text-capitalize">Payment Status </th>
                        <!-- <th class="row-text text-capitalize">Actions</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-expanded="expanded" let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                        <td>{{row.invoiceNumber}}  </td>
                        <td>{{row.createdDate}}  </td>
                        <td>{{row.dueDate}}  </td>
                        <td>{{row.paymentDate}}  </td>
                        <td>{{row.reSentCount}}  </td>
                        <td class="row-text data-style"><span
                            [class]="'order-badge order-' + row.paymentStatus.toLowerCase()">{{row.paymentStatus}}</span>
                    </td>
                    <!-- <td *ngIf="row.paymentStatus === 'Pending'">
                        <div class="row row-icon">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="cancelInvoice(row)">
                                    Cancel sent Invoice</button>
                                <button mat-menu-item (click)="resendInvoice(row)">
                                    Re-send Invoice</button>
                                <button mat-menu-item (click)="onViewInvoice(row)">
                                    View</button>
                                <button mat-menu-item (click)="downloadInvoice(row)">
                                    Download</button>
                                <button mat-menu-item (click)="updateStatus(row)">
                                    Update Status</button>
                            </mat-menu>
                        </div>
                    </td>
                    <td *ngIf="row.paymentStatus === 'Paid'">
                        <div class="row row-icon">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="updateStatus(row)">
                                    Update Status</button>
                            </mat-menu>
                        </div>
                    </td>
                    <td *ngIf="row.paymentStatus === 'Cancelled'">
                        <div class="row row-icon">
                            <mat-icon matTooltip="settings" class="mr-1 icn settingsBorder">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                        </div>
                    </td> -->
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="getAllTabsData.length == 0" class="mt-4 text-center">
                <span>There are no invoices for this client. Click on 'Generate invoice' to send the invoice</span>
            </div>
        <!-- </div> -->
    </div>
</div>