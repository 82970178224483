import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from 'ngx-toastr';
import { VisaDesignerService } from 'src/app/services/visa-designer.service';

const DATA =  {
  "status": 200,
  "message": "Fee details fetched successfully!",
  "data": [
      {
          "id": 1,
          "form": "I129FORM",
          "visaType": "H1-B"
      },
      {
          "id": 74,
          "form": "I539FORM",
          "visaType": "H4"
      },
      {
          "id": 76,
          "form": "I765FORM",
          "visaType": "H4"
      },
      {
          "id": 91,
          "form": "I129FFORM",
          "visaType": "K1"
      },
      {
          "id": 96,
          "form": "I589FORM",
          "visaType": "ASYLUM"
      },
      {
          "id": 97,
          "form": "I765FORMIMM",
          "visaType": "ASYLUM"
      },
      {
          "id": 98,
          "form": "I485FORM",
          "visaType": "GCFLY"
      },
      {
          "id": 112,
          "form": "I751FORM",
          "visaType": "GCFLY"
      },
      {
          "id": 113,
          "form": "I131FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 115,
          "form": "I601FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 116,
          "form": "I601AFORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 117,
          "form": "I821DFORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 118,
          "form": "I360FORM",
          "visaType": "GCFLY"
      },
      {
          "id": 126,
          "form": "I864FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 127,
          "form": "I864WFORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 128,
          "form": "I191FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 129,
          "form": "I881FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 130,
          "form": "I730FORM",
          "visaType": "ASYLUM"
      },
      {
          "id": 131,
          "form": "I821FORM",
          "visaType": "GCOTHER"
      },
      {
          "id": 132,
          "form": "I140FORM",
          "visaType": "GCEB"
      },
      {
          "id": 134,
          "form": "ETA9089FORM",
          "visaType": "GCEB"
      },
      {
          "id": 145,
          "form": "I130FORM",
          "visaType": "GCFLY"
      },
      {
          "id": 247,
          "form": "DS160FORM",
          "visaType": "H1-B"
      },
      {
          "id": 302,
          "form": "ETA9141FORM",
          "visaType": "H1-B"
      },
      {
          "id": 317,
          "form": "ETA9141FORMIMM",
          "visaType": "GCEB"
      },
      {
          "id": 334,
          "form": "I918FORM",
          "visaType": "U1"
      },
      {
          "id": 336,
          "form": "I918AFORM",
          "visaType": "UD"
      },
      {
          "id": 353,
          "form": "N400FORM",
          "visaType": "GCOTHER"
      }
  ],
  "errors": null,
  "isCredentialsExpired": false
}

@Component({
  selector: 'app-forms-we-support',
  templateUrl: './forms-we-support.component.html',
  styleUrls: ['./forms-we-support.component.scss']
})
export class FormsWeSupportComponent implements OnInit {

  displayedColumns: string[] = [   
    "form",     
    "visaType",
    //"filingType",            
    //"id"
  ];   

  dataSource: MatTableDataSource<any>;
  totalRecordCount: number = 0;

  constructor(
    private visaService: VisaDesignerService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSupportedFormsList();
  }

  getSupportedFormsList() {
    this.visaService.getSupportedForms().subscribe({
      next: (response: any) => { // Success handler      
        let result = response.data; 
        this.totalRecordCount = result.length;        
        this.dataSource = new MatTableDataSource(result);
      },
      error: (err: any) => { // Error handler       
       console.log('Not able to load the list')      
      }
    });
  }

}
