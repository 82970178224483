import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class ApiAddressHistoryService {
  emptyStateListOnReset = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  getAddressHistoryInformation(beneficiaryId: number, familyId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/addressHistory${familyId ? '?familyId=' + familyId : ''}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postAddressHistoryInformation(beneficiaryId: number, payload) {
    return this.http.post(
      `/beneficiary/${beneficiaryId}/addressHistory${payload.familyId ? '?familyId=' + payload.familyId : ''}`,
      payload
    ).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  deleteAddressHistoryItem(addressHistoryId) {
    return this.http.delete(`/addressHistory/${addressHistoryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
