<div class="fluid-container">
  <form [formGroup]="membershipForm">
    <div class="">
      <div class="row col-md-12 mb-3">
        <h6 class="heading p-text-bold">Membership in Associations</h6>
      </div>
      <div class="">
        <div class="">
          <div class="">
            <div class="row pl-0">
              <div class="col-6 form-group input-disabled">
                <label class="label-cls required"> Membership Type </label>
                <input [class.disabled]="disabledControls" class="im-input mw-100" id="clientName"
                  formControlName="membershipType" type="text" pInputText placeholder="Enter" required />
                <div class="invalid-message"
                  *ngIf="membershipForm.get('membershipType').touched && membershipForm.get('membershipType').errors?.required">
                  <!-- <span class="invalid-message"> -->
                  {{getGlobalErrorMessages('REQUIRED')}}
                  <!-- </span> -->
                </div>
              </div>
              <div class="col-6 form-group input-disabled">
                <label class="label-cls required"> Association Name </label>
                <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls"
                  formControlName="associationName" type="text" pInputText placeholder="Enter" />
                <div class="invalid-message"
                  *ngIf="membershipForm.get('associationName').touched && membershipForm.get('associationName').errors?.required">
                  <!-- <span class="invalid-message" *ngIf="membershipForm.get('associationName').errors?.required"> -->
                  {{getGlobalErrorMessages('REQUIRED')}}
                  <!-- </span> -->
                </div>
              </div>
            </div>
            <div class="col-12 pl-0">
              <div class="row w-100">
                <div class="col-md-4 form-group input-disabled">
                  <label class="label-cls required"> Address 1 </label>
                  <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls"
                    formControlName="addressOne" type="text" pInputText placeholder="Enter" />
                  <div class="invalid-message"
                    *ngIf="membershipForm.get('addressOne').touched && membershipForm.get('addressOne').errors?.required">
                    <!-- <span class="invalid-message" *ngIf="membershipForm.get('addressOne').errors?.required"> -->
                    {{getGlobalErrorMessages('REQUIRED')}}
                    <!-- </span> -->
                  </div>
                </div>
                <div class="col-md-4 form-group input-disabled">
                  <label class="label-cls"> Address 2 </label>
                  <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls"
                    formControlName="addressTwo" type="text" pInputText placeholder="Enter" />
                  <!-- <div *ngIf="membershipForm.get('addressTwo').touched && membershipForm.get('addressTwo').invalid">
                  <span class="invalid-message" *ngIf="membershipForm.get('addressTwo').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div> -->
                </div>
                <div class="col-md-4 form-group input-disabled">
                  <label class="label-cls required"> City </label>
                  <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls"
                    formControlName="city" type="text" pInputText placeholder="Enter" />
                  <div class="invalid-message"
                    *ngIf="membershipForm.get('city').touched && membershipForm.get('city').errors?.required">
                    <!-- <span class="invalid-message" *ngIf="membershipForm.get('city').errors?.required"> -->
                    {{getGlobalErrorMessages('REQUIRED')}}
                    <!-- </span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 pl-0">
              <div class="row w-100">

                <div class="col-md-4 form-group processing-dropdown">
                  <label class="label-cls"> Country </label>
                  <p-dropdown [options]="listCountry" [filter]="false" id="countryCode" [disabled]="disabledControls"
                    formControlName="countryCode" optionLabel="countryName" optionValue="countryCode"
                    (onChange)="handleCountryChange($event.value)" placeholder="select"> </p-dropdown>
                  <!-- <p-dropdown [options]="listCountry" [filter]="false" id="countryCode" formControlName="countryCode"
                      optionLabel="countryName" optionValue="countryCode" (onChange)="handleCountryChange($event.value)"
                      placeholder="select"> </p-dropdown> -->
                  <!-- <div class="invalid-message" *ngIf="membershipForm.get('countryCode').touched && membershipForm.get('countryCode').errors?.required"> -->
                  <!-- <span class="invalid-message" *ngIf="membershipForm.get('countryCode').errors?.required"> -->
                  <!-- {{getGlobalErrorMessages('REQUIRED')}} -->
                  <!-- </span> -->
                  <!-- </div> -->
                </div>
                <div class="col-md-4 form-group processing-dropdown">
                  <label class="label-cls"> State </label>
                  <p-dropdown [options]="listStates" *ngIf="listStates.length > 0" id="stateProvinceCode"
                    formControlName="state" name="country" optionLabel="stateProvinceName"
                    optionValue="stateProvinceCode" placeholder="select">
                  </p-dropdown>

                  <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listStates.length === 0"
                    id="clientName" formControlName="state" type="text" pInputText placeholder="Enter" />
                  <!-- <p-dropdown [options]="stateList" [class.disabled]="disabledControls" id="stateProvinceCode" formControlName="state"
                  [disabled]="disabledControls" name="country" optionLabel="stateProvinceName"
                  optionValue="stateProvinceCode" placeholder="select"> </p-dropdown> -->
                  <!-- <div class="invalid-message" *ngIf="membershipForm.get('state').touched && membershipForm.get('state').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                </div> -->
                </div>
                <div class="col-md-4 form-group input-disabled">
                  <label class="label-cls"> Postal code </label>
                  <input class="im-input mw-100" id="clientName" [class.disabled]="disabledControls"
                    formControlName="postal" type="text" pInputText placeholder="Enter" />
                  <!-- <div class="invalid-message" *ngIf="membershipForm.get('postal').touched && membershipForm.get('postal').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 p-0 ">
          <div class="row mt-4 ml-3">
            <div class="col-3 px-0">
              <button type="submit" [disabled]="disabledControls || !membershipForm.valid" (click)="saveMemebership();"
                class="btn btn-primary btn-add">
                <i aria-hidden="true" class="fa fa-plus-circle"></i>{{ addEditButtonText }} </button>
            </div>
            <div class="col-2 px-0">
              <!--             
            <button pButton  type="button"  [disabled]="disabledControls" label="Cancel" pRipple  class="p-button-secondary ml-2" [disabled]="disabledControls" (click)="cancelForm()">
            </button> -->
            </div>
          </div>
          <div class="row w-100 mt-4 mb-5">
            <div class="container-fluid">
              <!-- <label class="text-primary">Added Details</label> -->
              <h6 class="heading p-text-bold mb-3">Added Details</h6>
              <p-table [value]="membershipList" *ngIf="membershipList.length > 0" class="table-striped-simple">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="col-2">Membership Type</th>
                    <th class="col-2">Association Name</th>
                    <th class="col-2">Country</th>
                    <th class="col-2">State</th>
                    <th class="col-2 px-0">Address 1</th>
                    <!-- <th class="col-2">Address 2</th> -->
                    <th class="col-2 px-0 text-center">Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                  <tr>
                    <td class="col-2">{{doc.memberType}}</td>
                    <td class="col-2">{{doc.assocName}}</td>
                    <td class="col-2">{{doc.countryCode}}</td>
                    <td class="col-2">{{doc.stateProvinceName}}</td>
                    <td class="col-2 px-0">{{doc.addressLine1}}</td>
                    <!-- <td class="col-1" >{{doc.addressLine2}}</td> -->
                    <td class="col-2 px-0 text-center">

                      <div class="row">
                        <div class="col-6 p-0">
                          <button pButton type="button" [disabled]="disabledControls" title="View Document"
                            (click)="editDocument(doc)"
                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                            <mat-icon class="mr-1 icn" matTooltip="Edit">edit</mat-icon>
                          </button>
                        </div>
                        <div class="col-6 p-0">
                          <button pButton type="button" [disabled]="disabledControls" title="Delete Document"
                            (click)="deleteDocument(doc.id)"
                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                            <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                          </button>

                        </div>
                      </div>

                    </td>
                  </tr>
                </ng-template>
              </p-table>

              <div *ngIf="membershipList.length == 0" class="border w-100 p-2">
                <p class="text-secondary m-0"> Currently No details added!</p>
              </div>
            </div>
          </div>


          <div class="col-md-12 p-0 ">
            <div class="container-fluid">
              <p-divider type="dashed" class="mt-4 mb-2"></p-divider>
              <!-- 
          <label><b>Upload Membership Document</b></label> -->
              <h6 class="heading p-text-bold mb-3">Upload Membership Document</h6>
            </div>
            <div class="align-action">
              <i140-file-upload style="width: 100%;"
                [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
              </i140-file-upload>
            </div>
          </div>
          <div class="container-fluid">
            <p-divider type="dashed" class="mt-4 mb-3"></p-divider>
            <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
              <!-- <label><b>Summary</b></label> -->
              <h6 class="heading p-text-bold">Summary</h6>
              <!-- <textarea [(froalaModel)]="additionalInfo" [class.disabled]="disabledControls" name="text"
                [froalaEditor]="options">
        </textarea> -->
        <kendo-editor [(value)]="additionalInfo" (valueChange)="membershipassociationeditorValueChange($event)" #membershipassociationkendoeditor style="height: 400px;" formControlName="text" name="text">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
              <kendo-toolbar-button
              title="Upload’s Image at cursor point"
              text="Upload Image"
              (click)="openmembershipassociationimageuploaddialog()"
              ></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>                                  
          </kendo-toolbar>
      </kendo-editor> 
      <app-upload-image-dialog #membershipassociationimageupload [editor]="membershipassociationkendoeditor"></app-upload-image-dialog>
            </div>
            <!-- <div class="col-md-12 p-0 ">
        <button label="Save" class="d-block mt-4 btn-save"  [disabled]="disabledControls" (click)="saveSummaryInfo()">Save</button>
        </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <button pButton type="button" [disabled]="disabledControls" (click)="saveSummaryInfo()"
        class="d-inline-block float-left ml-3">Save</button>
      <button type="button" *ngIf="stepStatus !== 'COMPLETE' && showMarkComplete"
        [disabled]="stepStatus !== 'INPROGRESS'"
        class="im-btn im-btn-with-icon im-btn-outline-primary align-items-center button_whitebg"
        (click)="onMarkComplete()">
        <span class="btn-text">{{markStep}}</span>
        <span class="pi pi-exclamation-circle span-pi-exclamation-circle"></span>
      </button>
      <img *ngIf="stepStatus === 'COMPLETE'" src="assets/images/stepCompleteLabel.png" alt="" class="step-complete">
    </div>
  </form>
</div>