<div class="container-fluid" id="visaBulletingDetails">
    <div class="row">
        <div class="col-12">
            <h6 class="addQuestion"><b>Visa Bulletin</b></h6>
            <a class="backClick" (click)="onClickBack()">
                <mat-icon>keyboard_arrow_left</mat-icon><span>Back</span>
            </a>
        </div>
        <div class="col-12">
            <app-visa-bulletin></app-visa-bulletin>
        </div>
    </div>
</div>

