import { Injectable } from "@angular/core";
import { PAObservation } from "../../data/models/petitionAnalysis.model";

@Injectable({
    providedIn: 'root'
})
export class PAUtility {

    private selectedCategoryCode: string;
    private selectedCategoryName: string;
    private observationList: PAObservation[] = [];

    public set categoryCode(code) {
        this.selectedCategoryCode = code;
    }
    public set categoryName(name) {
        this.selectedCategoryName = name;
    }
    public get categoryCode() {
        return this.selectedCategoryCode;
    }
    public get categoryName() {
        return this.selectedCategoryName;
    }

    getObservationList() {
        return this.observationList;
    }

    setObservationList(list: PAObservation[]) {
        this.observationList = list;
    }

}


