<div class="col-md-12 p-0 row delete-header">
    <div class="col-md-10 p-0">
        <span>Confirm Deletion!</span>
    </div>
    <div class="col-md-2 p-0 justify-content-end d-flex">
        <i (click)="handleNo();" class="close-icon pi pi-times"></i>
    </div>
</div>
<div class="row col-md-12 p-0 detele-body">
    <p>{{ deleteConfirmationMessage }}</p>
</div>
<div class="row col-md-12  justify-content-end d-flex pr-0">
    <div class="col-md-2">
        <button pButton type="button" label="Yes" icon="pi pi-check" (click)="handleYes()"></button>
    </div>
    <div class="col-md-2">
        <button pButton type="button" label="No" icon="pi pi-times" (click)="handleNo()" class="mr-3"></button>
    </div>
</div>