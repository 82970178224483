<div class="container-fluid">
    <div class="col-md-12 p-0 pb-3 row d-flex- align-items-end"
        *ngIf="configurations.isDocumentTypeRequired  || configurations.isDocumentUploadRequired">
        <div class="col-md-4 p-0 pr-2 processing-dropdown" *ngIf="configurations.isDocumentTypeRequired">
            <h6>Document Type</h6>
            <p-dropdown appendTo="body" [disabled]="configurations.disableControls" [options]="listDocumentTypes"
                placeholder="Select Category" [(ngModel)]="configurations.documentTypeDropdownCode" optionValue="code"
                optionLabel="name"></p-dropdown>
        </div>
        <div class="col-md-4 p-0 pr-2" *ngIf="configurations.documentTypeDropdownCode === 'OTHER'">
            <h6>Document Type Name</h6>
            <input type="text" pInputText [(ngModel)]="otherName" />
        </div>
        <div class="col-md-4 pl-0" *ngIf="configurations.isDocumentUploadRequired">
            <h6></h6>
            <p-fileUpload [disabled]="configurations.disableControls || configurations.documentTypeDropdownCode == '' || (configurations.documentTypeDropdownCode === 'OTHER' && otherName.trim() === '')"
                #fubauto mode="basic" customUpload="true" name="demo[]"
                (uploadHandler)="onUploadHandle($event, fubauto)" accept=".pdf" [auto]="true"
                chooseLabel="Browse & Upload">
            </p-fileUpload>
        </div>
    </div>
    <div class="mb-5 pt-2" *ngIf="uploadedFiles?.length>0">
        <h6 class="labelText">List of Uploaded Documents</h6>
        <p-table class="pa-table" [value]="uploadedFiles" name="uploadedFiles">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2">Document Type</th>
                    <th colspan="2">Document Name</th>
                    <th>Actions </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
                <tr>
                    <td colspan="2" nowrap>
                        {{
                        file.fileCategory.code === "OTHER"
                        ? file.fileCtaegoryOtherName
                        ? file.fileCtaegoryOtherName
                        : file.fileCategory.name
                        : file.fileCategory.name
                        }}
                    </td>
                    <td colspan="2">
                        <strong><a style="cursor: pointer" (click)="openDoc(file)">
                                {{ file.fileName }}</a></strong>
                    </td>
                    <td nowrap>
                        <div class="d-flex flex-row documents-icons d-flex align-items-center">
                            <div class="mr-2 padding0" *ngIf="configurations.isViewEnabled">
                                <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                    [disabled]="!file.fileLocation" (click)="openDoc(file)"
                                    class="p-button-text p-button-rounded"></button>
                            </div>
                            <div *ngIf="file.fileLocation && configurations.isDownloadEnabled" class="mr-2 padding0">
                                <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                    (click)="download(file)" class="p-button-text p-button-rounded"></button>
                            </div>
                            <div class="mr-2 padding0" *ngIf="configurations.isDeleteEnabled">
                                <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                    (click)="deleteDocumentTypeLis(file)" [disabled]="configurations.disableControls"
                                    class="p-button-text p-button-rounded"></button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>