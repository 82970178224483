import { Component, OnInit } from '@angular/core';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';

@Component({
  selector: 'app-petitioner-landing',
  templateUrl: './petitioner-landing.component.html',
  styleUrls: ['./petitioner-landing.component.scss']
})
export class PetitionerLandingComponent implements OnInit {
  isExternal;
  constructor(
    private cacheCountryService: CacheCountryService

  ) { }

  ngOnInit() {
    this.cacheCountryService.getCountry().subscribe();
    this.isExternal = localStorage.getItem("isExternal");
  }

}
