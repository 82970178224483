import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationService {

  constructor(private http: HttpClient) { }

  getAdditionalInformation(type, caseId: number) {
    return this.http.get(`/${type}/PETITION/${caseId}/timeLine/additionalInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postAdditionalInformation(visaType, caseType, caseId: number, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/timeLine/additionalInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }
}
