import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParticipationAsJudgeService {

  constructor(private http:HttpClient) { }

  addParticipationInfo(petitionId,payload){
    return this.http.post(`/extraordinaryAbility/petition/${petitionId}/participation/createOrUpdate`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }

  getParticipationInfo(petitionId){
    return this.http.get(`/extraordinaryAbility/petition/${petitionId}/participation/info `).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }


}
