<div class="p-grid create-task-with-wizard">
  <div class="p-col-12">
    <div class="row border-bottom">
      <div class="col-md-10 py-3 px-0">
        <h5 class="text-primary m-0">Petition Building</h5>
      </div>
      <div class="col-md-2 p-0 align-items-center justify-content-end d-flex">
        <i (click)="closePopup()" class="close-icon pi pi-times"></i>
      </div>
    </div>
    <form [formGroup]="newTaskForm" (ngSubmit)="onSubmit()">
      <div class="p-gid">
        <div class="p-col-12 mb-2 pb-3 highlighted-box">
          <div class="p-grid">
            <div class="w-100 pt-2"></div>
            <div class="p-col-12">
              <label class="create-task-for-label"
                >Quickly Craft your petition: Share details to complete the
                petition in 10 minutes!</label
              >
            </div>
            <div class="p-col-6 pb-3">
              <div class="w-100 pb-3">
                <label for="visatype" class="create-task-label d-block"
                  >Q1. Visa Type</label
                >
                <p-dropdown
                  class="w-100"
                  appendTo="body"
                  inputId="visatype"
                  [options]="visaTypes"
                  formControlName="visaType"
                  optionLabel="name"
                  name="visatype"
                  [disabled]="true"
                  optionValue="code"
                  placeholder="Select Visa Type"
                >
                </p-dropdown>
                <span
                  class="invalid-message"
                  *ngIf="
                    newTaskForm.get('visaType').touched &&
                    newTaskForm.get('visaType').errors?.required
                  "
                >
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
              </div>
            </div>
            <div class="p-col-6 pb-3" *ngIf="newTaskForm.get('visaType').value">
              <label
                for="petitiontype"
                class="create-task-label d-block required"
                >Which H1-B petition do you want to proceed with?</label
              >
              <p-dropdown
                class="w-100"
                inputId="petitiontype"
                [options]="petitionTypes"
                formControlName="petitionType"
                name="pettype"
                [disabled]="!newTaskForm.get('visaType').value"
                optionLabel="name"
                optionValue="code"
                placeholder="Select"
                appendTo="body"
                (onChange)="onPetitionTypeChange($event)"
              >
              </p-dropdown>
              <div
                *ngIf="
                  newTaskForm.get('petitionType').touched &&
                  newTaskForm.get('petitionType').invalid
                "
              >
                <span
                  class="invalid-message"
                  *ngIf="newTaskForm.get('petitionType').errors?.required"
                >
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
              </div>
            </div>
            <div class="p-col-12 mb-2 highlighted-box" *ngIf="userType === 'Attorney'">
              <div class="p-grid">
                  <div class="w-100 pt-2"></div>
                  <div class="p-col-5 w-45">
                      <label class="create-task-label d-block">Client/Company<span class="required" *ngIf="(!newTaskForm.get('visaType').value ||
                          (newTaskForm.get('visaType').value ))"></span> <button pButton type="button" label="Add New Company" class="p-button-text p-0 pull-right" (click)="handleAddClick('addClientCompany')"  *ngIf="isCaseRequestCreateTask"></button></label>
                      <p-autoComplete class="client-company-autocomplete w-100 d-block" formControlName="company" [suggestions]="filteredCompanies" (completeMethod)="filterCompanies($event)" field="petitionerName"  dataKey="petitionerId" [dropdown]="true" [placeholder]="autoCompletePlaceholder" (onSelect)="onCompanyChange($event)" [forceSelection]="true" name="company" [showEmptyMessage]="true" [disabled]="(!(newTaskForm.get('petitionType').value ))">
                          <ng-template pTemplate="header">
                              <div class="autocomplete-header row pt-1 pb-1">
                                  <div class="col-6 pl-2">
                                      <span class="autocomplete-heading-text">Company Name</span>
                                  </div>
                                  <div class="col-6 pl-0">
                                      <span class="autocomplete-heading-text">Primary Contact</span>
                                  </div>
                              </div>
                          </ng-template>
                          <ng-template let-item pTemplate="item">
                              <div class="group-item row">
                                  <div class="col-6 pl-2"><span class="autocomplete-value-text" title="{{item.petitionerName}}" *ngIf="userType === 'Attorney'">{{item.petitionerName}}</span><span class="autocomplete-value-text" title="{{item.companyName}}">{{item.companyName}}</span></div>
                                  <div class="col-6 pl-0"><span class="autocomplete-value-text" title="{{item.contactPersonFirstName}} {{item.contactPersonMiddleName ? item.contactPersonMiddleName +' ' : ''}}{{item.contactPersonLastName}}"  *ngIf="userType === 'Attorney'">{{item.contactPersonFirstName}} {{item.contactPersonMiddleName ? item.contactPersonMiddleName +' ' : ''}}{{item.contactPersonLastName}}</span>
                                      <!-- <span class="autocomplete-value-text" title="{{item.signatoryFirstName}} {{item.signatoryLastName}}"  *ngIf="userType === 'Petitioner'">{{item.signatoryFirstName}} {{item.signatoryLastName}}</span> -->
                                      </div> 
                              </div>
                          </ng-template>
                      </p-autoComplete>
                      <span class="invalid-message"
                          *ngIf="newTaskForm.get('company').touched && newTaskForm.get('company').errors?.companyIsRequired">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                      </span>
                  </div>  
              </div>
          </div>
            <hr class="mt-0 w-100" />
            <div class="p-col-12" *ngIf="['CAP'].includes(newTaskForm.get('petitionType').value)">
              <div class="w-100 pb-3">
                <label for="lottery" class="create-task-label"
                  >Q2. Is the individual already selected in the Lottery?</label
                >
                <p-inputSwitch
                  (onChange)="onChangeLottery($event.checked)"
                  formControlName="isLottery"
                  name="lottery"
                  class="pl-2 pull-right"
                >
                </p-inputSwitch>
              </div>
              <div class="w-50 pb-3" *ngIf="isLottery">
                <label class="create-task-label"
                  >Beneficiary Confirmation Number</label
                >
                <input
                  type="text"
                  pInputText
                  formControlName="beneficiaryConfirmationNo"
                />
              </div>
            </div>
            <div *ngIf="!isLottery" class="w-100">
              <p class="text-danger">
                You cannot proceed to build a petition without the beneficiary
                confirmation number. You will get the beneficiary confirmation
                number once the beneficiary is selected in the lottery
              </p>
            </div>
            <div *ngIf="isLottery" class="w-100">
              <hr class="mt-0 w-100" *ngIf="['CAP'].includes(newTaskForm.get('petitionType').value)"/>
              <div class="p-col-12">
                <div class="w-100 pb-3">
                  <label for="visatype" class="create-task-label"
                    >Q{{['CAP'].includes(newTaskForm.get('petitionType').value) ? 3 : 2}}. Is this individual already added to Imagility?</label
                  >
                  <p-inputSwitch
                    (onChange)="onChangeOutsideImagility($event.checked)"
                    name="isOutsideImagility"
                    formControlName="isOutsideImagility"
                    class="pl-2 pull-right"
                  >
                  </p-inputSwitch>
                </div>
                <div class="w-50 pb-3">
                  <label for="visatype" class="create-task-label required"
                    >Select Individual from list</label
                  >
                  <p-autoComplete
                    class="beneficiary-autocomplete w-100 d-block"
                    formControlName="beneficiary"
                    [suggestions]="filteredBeneficiaryList"
                    (completeMethod)="filterBeneficiaries($event)"
                    field="fullName"
                    [dropdown]="true"
                    [placeholder]="autoCompletePlaceholder"
                    (onSelect)="onBenChange($event)"
                    [forceSelection]="true"
                    [showEmptyMessage]="true"
                  >
                    <ng-template pTemplate="header">
                      <div class="autocomplete-header row pt-1 pb-1">
                        <div class="col-6 pl-2">
                          <span class="autocomplete-heading-text">Name</span>
                        </div>
                        <div class="col-6 pl-0">
                          <span class="autocomplete-heading-text">Email</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="group-item row">
                        <div class="col-6 pl-2">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.fullName }}"
                            >{{ item.fullName }}</span
                          >
                        </div>
                        <div class="col-6 pl-0">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.beneficiaryEmail }}"
                            >{{ item.beneficiaryEmail }}</span
                          >
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                  <span
                    class="invalid-message"
                    *ngIf="
                      newTaskForm.get('beneficiary').touched &&
                      newTaskForm.get('beneficiary').errors?.required
                    "
                  >
                    {{ getGlobalErrorMessages("REQUIRED") }}
                  </span>
                </div>
                <div
                  class="w-100 pb-3"
                  *ngIf="!newTaskForm.get('isOutsideImagility').value"
                >
                  <div class="w-100 pb-3">
                    <label class="create-task-for-label">Add Individual</label>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-4 pb-3 pl-0">
                        <label for="fName" class="create-task-label required"
                          >First Name</label
                        >
                        <input type="text" pInputText formControlName="fName" />
                        <div
                          *ngIf="
                            newTaskForm.get('fName').touched &&
                            newTaskForm.get('fName').invalid
                          "
                        >
                          <span
                            class="invalid-message"
                            *ngIf="newTaskForm.get('fName').errors?.required"
                          >
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </span>
                        </div>
                      </div>
                      <div class="col-4 pb-3">
                        <label for="lName" class="create-task-label required"
                          >Last Name</label
                        >
                        <input type="text" pInputText formControlName="lName" />
                        <div
                          *ngIf="
                            newTaskForm.get('lName').touched &&
                            newTaskForm.get('lName').invalid
                          "
                        >
                          <span
                            class="invalid-message"
                            *ngIf="newTaskForm.get('lName').errors?.required"
                          >
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 pb-3 pl-0">
                        <label for="email" class="create-task-label required"
                          >Email</label
                        >
                        <input
                          type="email"
                          pInputText
                          formControlName="email"
                        />
                        <div
                          *ngIf="
                            newTaskForm.get('email').touched &&
                            newTaskForm.get('email').invalid
                          "
                        >
                          <span
                            class="invalid-message"
                            *ngIf="newTaskForm.get('email').errors?.required"
                          >
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </span>
                          <span
                            class="invalid-message"
                            *ngIf="newTaskForm.get('email').errors?.pattern"
                          >
                            {{ getGlobalErrorMessages("INVALID_EMAIL") }}
                          </span>
                        </div>
                        <!-- <ng-container *ngFor="let x of addBenMemberForm.get('emailContacts')['controls']; index as i">
                                                        <div [formGroupName]="i">
                                                            <input type="text" class="im-input" formControlName="email" placeholder="Enter" [pattern]="emailPattern" />
                                                        </div>
                                                        <span class="invalid-message" *ngIf="(x.get('email').touched) && x.get('email').hasError('required')">
                                                            {{ getGlobalErrorMessages("REQUIRED") }}
                                                        </span>
                                                        <span class="invalid-message" *ngIf="(x.get('email').touched) && x.get('email').hasError('pattern')">
                                                            {{ getGlobalErrorMessages("INVALID_EMAIL") }}
                                                        </span>
                                                    </ng-container> -->
                      </div>
                      <!-- <div class="col-4 pb-3">
                                                    <label for="dob" class="create-task-label required">Date of Birth</label>
                                                    <p-calendar id="dob" yearRange="1900:2100" appendTo="body" type="text" class="w-100" yearNavigator="true"
                                                        monthNavigator="true" formControlName="dob" showIcon="true" showButtonBar="true" [maxDate]="maxDate"
                                                        [disabled]="disabledControls" inputId="icon" [showIcon]="true" dateFormat="mm/dd/yy"
                                                        placeholder="MM/DD/YY" name="dob" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
                                                    </p-calendar>
                                                </div> -->
                      <div class="col-4 pb-3">
                        <button
                          type="button"
                          class="btn btn-outline-primary add-individual"
                          (click)="saveIndividual()"
                        >
                          Add Individual
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--Pending-->
                </div>
              </div>
              <hr class="mt-0 w-100" />
              <div class="p-col-12">
                <div class="w-100 pb-3">
                  <label for="visatype" class="create-task-label"
                    >Q{{['CAP'].includes(newTaskForm.get('petitionType').value) ? 4 : 3}}. What is the position title and work location?
                  </label>
                </div>
              </div>
              <div class="p-grid pl-2">
                <div class="p-col-6 pb-3">
                  <label for="visatype" class="create-task-label required"
                    >Position Title</label
                  >
                  <input
                    type="text"
                    pInputText
                    formControlName="positionTitle"
                  />
                  <div
                    *ngIf="
                      newTaskForm.get('positionTitle').touched &&
                      newTaskForm.get('positionTitle').invalid
                    "
                  >
                    <span
                      class="invalid-message"
                      *ngIf="newTaskForm.get('positionTitle').errors?.required"
                    >
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                </div>
                <div class="p-col-6 pb-3">
                  <label for="visatype" class="w-100 create-task-label required"
                    >Start Date</label
                  >
                  <p-calendar
                    id="startDate"
                    yearRange="1900:2100"
                    appendTo="body"
                    type="text"
                    class="w-100"
                    yearNavigator="true"
                    monthNavigator="true"
                    formControlName="startDate"
                    showIcon="true"
                    showButtonBar="true"
                    [disabled]="disabledControls"
                    inputId="icon"
                    [showIcon]="true"
                    dateFormat="mm/dd/yy"
                    placeholder="MM/DD/YY"
                    name="startDate"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }" 
                    (onSelect)="validateLCA()"
                  >
                  </p-calendar>
                  <div
                    *ngIf="
                      newTaskForm.controls['startDate'].touched &&
                      newTaskForm.controls['startDate'].invalid
                    "
                  >
                    <span
                      class="invalid-message"
                      *ngIf="newTaskForm.controls['startDate'].errors?.required"
                    >
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                </div>
                <div class="p-col-6 pb-3">
                  <label for="endDate" class="w-100 create-task-label required"
                    >End Date</label
                  >
                  <p-calendar
                    yearRange="1900:2100"
                    id="endDate"
                    appendTo="body"
                    type="text"
                    class="w-100" 
                    formControlName="endDate"
                    yearNavigator="true"
                    monthNavigator="true"
                    showIcon="true"
                    showButtonBar="true"
                    [disabled]="disabledControls"
                    inputId="icon"
                    [showIcon]="true"
                    dateFormat="mm/dd/yy"
                    placeholder="MM/DD/YY"
                    name="endDate"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }" 
                    (onSelect)="validateLCA()"
                  >
                  </p-calendar>
                  <div
                    *ngIf="
                      newTaskForm.controls['endDate'].touched &&
                      newTaskForm.controls['endDate'].invalid
                    "
                  >
                    <span
                      class="invalid-message"
                      *ngIf="newTaskForm.controls['endDate'].errors?.required"
                    >
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                  <!-- <div
                    *ngIf="
                      newTaskForm.controls['endDate'].errors
                        ?.positionDateValidation
                    "
                  >
                    <span class="invalid-message">
                      End date should be greater than or equal to Start Date
                    </span>
                  </div> -->
                </div>
                <div class="p-col-6 pb-3">
                  <label class="create-task-label required">State/District/Territory</label>
                  <p-dropdown
                    class="w-100"
                    inputId="stateProvince"
                    [options]="listStates"
                    formControlName="stateProvinceCode"
                    name="stateProvince"
                    optionLabel="stateProvinceName"
                    optionValue="stateProvinceCode"
                    placeholder="Select"
                    appendTo="body"
                    (onChange)="onChangeState($event.value)"
                  >
                  </p-dropdown>
                  <div
                      *ngIf="
                        newTaskForm.get('stateProvinceCode').touched &&
                        newTaskForm.get('stateProvinceCode').invalid
                      "
                    >
                      <span
                        class="invalid-message"
                        *ngIf="
                          newTaskForm.get('stateProvinceCode').errors?.required
                        "
                      >
                        {{ getGlobalErrorMessages("REQUIRED") }}
                      </span>
                    </div>
                </div>
                <div class="p-col-6 pb-3">
                  <label class="create-task-label required">County</label>
                  <p-dropdown
                    class="w-100"
                    inputId="county"
                    [options]="listCounty"
                    formControlName="county"
                    name="county"
                    placeholder="Select"
                    appendTo="body"
                  >
                  </p-dropdown>
                  <div
                      *ngIf="
                        newTaskForm.get('county').touched &&
                        newTaskForm.get('county').invalid
                      "
                    >
                      <span
                        class="invalid-message"
                        *ngIf="newTaskForm.get('county').errors?.required"
                      >
                        {{ getGlobalErrorMessages("REQUIRED") }}
                      </span>
                    </div>
                </div>
              </div>
              <hr class="mt-0 w-100" />
              <!--Q4-->
              <div class="p-col-12">
                <div class="w-100 pb-3">
                  <label for="visatype" class="create-task-label"
                    >Q{{['CAP'].includes(newTaskForm.get('petitionType').value) ? 5 : 4}}. Is LCA already filed for this position?
                  </label>
                  <p-inputSwitch
                    (onChange)="onChangeLcaFiled($event.checked)"
                    name="isLCAFiled"
                    formControlName="isLCAFiled"
                    class="pl-2 pull-right"
                  >
                  </p-inputSwitch>
                </div>
                <div
                  class="w-50 pb-3"
                  *ngIf="newTaskForm.get('isLCAFiled').value"
                >
                  <label for="lcaId" class="create-task-label"
                    >Select LCA from list</label
                  >
                  <p-dropdown
                    class="w-100"
                    inputId="lcaId"
                    [options]="lcaList"
                    formControlName="lcaId"
                    name="lcaId"
                    placeholder="Select"
                    appendTo="body"
                    optionLabel="caseNumber"
                    optionValue="caseNumber" 
                    (onChange)="validateLCA()"
                  >
                  </p-dropdown>
                </div>
                <div
                  class="w-100 pb-3"
                  *ngIf="!newTaskForm.get('isLCAFiled').value"
                >
                  <label for="visatype" class="create-task-label"
                    ><i
                      >You can add the LCA to the petition later; there's no
                      need to worry about it now.</i
                    ></label
                  >
                </div>
              </div>
              <hr class="mt-0 w-100" />
              <!--Q5-->
              <div class="p-col-12">
                <div class="w-100 pb-3">
                  <label for="visatype" class="create-task-label"
                    >Q{{['CAP'].includes(newTaskForm.get('petitionType').value) ? 6 : 5}}. How do you want to build the petition?
                  </label>
                  <div>
                    <p-radioButton
                      class="mr-4 mb-3 mt-3"
                      *ngFor="let i of filingOption"
                      name="filingType"
                      [value]="i.code"
                      [label]="i.name"
                      (click)="redirectToEFiling(i.name)"
                      formControlName="filingType"
                      [inputId]="i.inputId"
                    ></p-radioButton>
                  </div>
                </div>
              </div>
              <!-- <div class="p-col-12">
                <label class="create-task-for-label"
                  >Visa Processing Details</label
                >
              </div>
              <div class="p-grid pl-2">
                <div class="p-col-4 pb-3 d-flex justify-items-center">
                  <p-radioButton
                    name="processingType"
                    formControlName="processingType"
                    (onClick)="onChangeProcessingType(0)"
                    value="0"
                    class="mr-2"
                    label="Regular"
                  >
                  </p-radioButton>
                  <p-radioButton
                    name="processingType"
                    formControlName="processingType"
                    (onClick)="onChangeProcessingType(1)"
                    value="1"
                    class="mr-2"
                    label="Premium"
                  >
                  </p-radioButton>
                </div>
                <div class="p-col-4 pb-3">
                  <label for="serviceCenterId" class="create-task-label"
                    >Add a default Service Center</label
                  >
                  <p-dropdown
                    class="w-100"
                    inputId="serviceCenterId"
                    [options]="serviceCenterList"
                    formControlName="serviceCenterId"
                    name="serviceCenterId"
                    optionLabel="name"
                    optionValue="id"
                    (onClick)="poplulateServicesList()"
                    placeholder="Select"
                    appendTo="body"
                  >
                  </p-dropdown>
                </div>
                <div class="p-col-4"></div>
              </div> -->
              <div class="mt-0 w-100"></div>

              <!--Attorney-->
              <div class="p-col-12" *ngIf="userType !== 'Attorney'">
                <div class="w-100 pb-3">
                  <label class="create-task-for-label">Attorney Details</label>
                </div>
                <div class="w-100 pb-3">
                  <label for="visatype" class="create-task-label"
                    >Do you want to include Attorney/Lawfirm to this petition?
                  </label>
                  <p-inputSwitch
                    (onChange)="onChangeAttorney($event.checked)"
                    name="includeAttorney"
                    formControlName="includeAttorney"
                    class="pl-2 pull-right"
                  >
                  </p-inputSwitch>
                </div>
                <div
                  class="w-50 pb-3"
                  *ngIf="newTaskForm.get('includeAttorney').value"
                >
                  <label for="lcaId" class="create-task-label"
                    >Select Attorney / LawFirm</label
                  >
                  <!-- <p-autoComplete
                    class="client-company-autocomplete w-100 d-block"
                    formControlName="company"
                    [suggestions]="filteredCompanies"
                    (completeMethod)="filterCompanies($event)"
                    field="petitionerName"
                    dataKey="petitionerId"
                    [dropdown]="true"
                    [placeholder]="autoCompletePlaceholder"
                    (onSelect)="onCompanyChange($event)"
                    [forceSelection]="true"
                    name="company"
                    [showEmptyMessage]="true"
                  >
                    <ng-template pTemplate="header">
                      <div class="autocomplete-header row pt-1 pb-1">
                        <div class="col-6 pl-2">
                          <span class="autocomplete-heading-text"
                            >Company Name</span
                          >
                        </div>
                        <div class="col-6 pl-0">
                          <span class="autocomplete-heading-text"
                            >Primary Contact</span
                          >
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="group-item row">
                        <div class="col-6 pl-2">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.petitionerName }}"
                            *ngIf="userType === 'Attorney'"
                            >{{ item.petitionerName }}</span
                          ><span
                            class="autocomplete-value-text"
                            title="{{ item.companyName }}"
                            *ngIf="userType === 'Petitioner'"
                            >{{ item.companyName }}</span
                          >
                        </div>
                        <div class="col-6 pl-0">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.contactPersonFirstName }} {{
                              item.contactPersonMiddleName
                                ? item.contactPersonMiddleName + ' '
                                : ''
                            }}{{ item.contactPersonLastName }}"
                            *ngIf="userType === 'Attorney'"
                            >{{ item.contactPersonFirstName }}
                            {{
                              item.contactPersonMiddleName
                                ? item.contactPersonMiddleName + " "
                                : ""
                            }}{{ item.contactPersonLastName }}</span
                          >
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.signatoryFirstName }} {{
                              item.signatoryLastName
                            }}"
                            *ngIf="userType === 'Petitioner'"
                            >{{ item.signatoryFirstName }}
                            {{ item.signatoryLastName }}</span
                          >
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete> -->
                  <p-autoComplete
                    class="client-company-autocomplete w-100 d-block"
                    formControlName="attorney"
                    [suggestions]="filteredAttorneysList"
                    (completeMethod)="filteredAttorneys($event)"
                    field="companyName"
                    dataKey="companyId"
                    [dropdown]="true"
                    [placeholder]="autoCompletePlaceholder"
                    (onSelect)="onAttroneyChange($event)"
                    [forceSelection]="true"
                    name="attorney"
                    [showEmptyMessage]="true"
                  >
                    <ng-template pTemplate="header">
                      <div class="autocomplete-header row pt-1 pb-1">
                        <div class="col-6 pl-2">
                          <span class="autocomplete-heading-text"
                            >Company Name</span
                          >
                        </div>
                        <div class="col-6 pl-0">
                          <span class="autocomplete-heading-text"
                            >Primary Contact</span
                          >
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="group-item row">
                        <div class="col-6 pl-2">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item.companyName }}"
                            >{{ item.companyName }}</span
                          >
                        </div>
                        <div class="col-6 pl-0">
                          <span
                            class="autocomplete-value-text"
                            title="{{ item?.attorneycontactFirstName }} {{
                              item?.attorneycontactLastName
                            }}">{{ item?.attorneycontactFirstName }}
                            {{ item?.attorneycontactLastName }}</span
                          >
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col-12">
                  <div class="w-100">
                    <label class="create-task-for-label">Signatory Details</label>
                  </div>
                </div>
                <div class="p-col-6">
                  <label class="create-task-label required"
                    >Signatory Name</label
                  >

                  <p-dropdown
                    [options]="reviewUsersList"
                    formControlName="authorizedSignatoryId"
                    name="authorizedSignatoryId"
                    optionLabel="firstName"
                    optionValue="id"
                    placeholder="Select Signatory"
                    (onChange)="userSelected()"
                  >
                    <ng-template pTemplate="selectedItem">
                      <div *ngIf="selectedSignatory">
                        <div>
                          {{
                            selectedSignatory?.firstName +
                              " " +
                              selectedSignatory?.lastName
                          }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-employee pTemplate="item">
                      <div>
                        <div>
                          {{ employee?.firstName + " " + employee?.lastName }}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <span
                    class="invalid-message"
                    *ngIf="
                      newTaskForm.get('authorizedSignatoryId').touched &&
                      newTaskForm.get('authorizedSignatoryId').errors
                        ?.required
                    "
                  >
                    {{ getGlobalErrorMessages("REQUIRED") }}
                  </span>
                </div>
                <div class="p-col-6">
                  <label class="create-task-label">Designation</label>
                  <input
                    type="text"
                    readOnly
                    name="selectedDesignation"
                    class="form-control txt-comp m-0"
                    formControlName="selectedDesignation"
                  />
                </div>
              </div>
              <hr class="mt-0 w-100" />
              <div class="p-grid">
                <div class="p-col-6">
                  <label class="create-task-label required"
                    >Petitioner preparer name</label
                  >

                  <p-dropdown
                    [options]="users"
                    formControlName="immSpecialistId"
                    name="immSpecialistId"
                    optionLabel="firstName"
                    optionValue="id"
                    placeholder="Select Petitioner Preparer"
                    (onChange)="onPetitionerPreparerSelected()"
                    appendTo="body"
                  >
                    <ng-template pTemplate="selectedItem">
                      <div *ngIf="selectedPetetionPreprarer">
                        <div>
                          {{
                            selectedPetetionPreprarer?.firstName
                              ? selectedPetetionPreprarer?.firstName +
                                "
                                                " +
                                selectedPetetionPreprarer?.lastName
                              : "--"
                          }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-employee pTemplate="item">
                      <div>
                        <div>
                          {{ employee?.firstName + " " + employee?.lastName }}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <span
                    class="invalid-message"
                    *ngIf="
                      newTaskForm.get('immSpecialistId').touched &&
                      newTaskForm.get('immSpecialistId').errors?.required
                    "
                  >
                    {{ getGlobalErrorMessages("REQUIRED") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p-divider type="dashed"></p-divider>
        <div class="d-flex justify-content-end">
          <button
            pButton
            type="button"
            label="CANCEL"
            title="CANCEL"
            (click)="this.closePopup(); backToDashboard()"
            class="p-button-secondary"
          ></button>
          <button
            *ngIf="isLottery"
            pButton
            class="ml-3"
            type="submit"
            label="SAVE"
            title="SAVE"
            [disabled]="
              isimmSpecialistIdIsInvalid ||
              isauthorizedSignatoryIdIsInvalid ||
              ispetitionTypeIsInvalid ||
              isbeneficiaryConfirmationNoIsInvalid ||
              isisOutsideImagilityIsInvalid ||
              isbeneficiaryIsInvalid ||
              isbeneficiaryIdIsInvalid ||
              ispositionTitleIsInvalid ||
              isstartDateIsInvalid ||
              isendDateIsInvalid ||
              isstateProvinceCodeIsInvalid ||
              iscountyIsInvalid ||
              isserviceCenterIdIsInvalid
            "
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
<p-confirmDialog header="Confirm Reset" key="taskcreation"></p-confirmDialog>