import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ImagilityBaseResponse } from 'app-models';
import { DirtyFormGuard, MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/internal/operators/first';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';

@Component({
  selector: 'note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent implements OnInit, AfterViewInit {
  reason: string;
  title: string;
  @ViewChild(NgForm) noteDialogForm: NgForm;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private store: Store<VisaExtendedState>,
    private dynamicDialogConfig: DynamicDialogConfig,
    private guard: DirtyFormGuard,
    private toastService: ToastrService,
    private pbApiService: PetitionBuilderService,
  ) {
    this.reason = '';
    this.title = '';
  }

  ngOnInit(): void {
    this.title = this.dynamicDialogConfig.data.title;
  }

  ngAfterViewInit() {
    this.noteDialogForm.form.valueChanges.subscribe(() => {
      if (this.noteDialogForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  handleSubmit() {
    this.dynamicDialogConfig.data.payload.title = this.title;
    this.dynamicDialogConfig.data.payload.text = this.reason;
    this.pbApiService.getNotificationDocListBeneQua(this.dynamicDialogConfig.data.payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastService.success(response.message, 'Success');
        this.dynamicDialogRef.close();
      }
    });
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

}
