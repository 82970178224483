import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { EncryptService } from '../modules/core/http/http-encrypt.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  public reviewUsersListData$ = new BehaviorSubject<any>({});
  public attorneyList$ = new BehaviorSubject<any>({});
  public userList$ = new BehaviorSubject<any>({});
  data = this.reviewUsersListData$.asObservable();
  attorneyData = this.attorneyList$.asObservable();
  userData = this.userList$.asObservable();

  constructor(private httpClient: HttpClient, private encryptService: EncryptService) {

  }

  getBeneficiaries(companyId) {
    return this.httpClient.get('/company/beneficiaries/company/' + companyId).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }
  // premium
  getServiceCenters(isPremium, visaType, petitionType) {
    //var encryptedText = this.encryptService.toEncrypt(isPremium);
    return this.httpClient.get(`/serviceCenter/${isPremium}/visaType/${visaType}/petitionType/${petitionType}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getSuperTaskServiceCenters(isPremium) {
    var encryptedText = this.encryptService.toEncrypt(isPremium);
    return this.httpClient.get(`/serviceCenter/${encryptedText}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  // superPremium
  getServiceCentersImmigrant(isPremium) {    
    // var encryptedText = this.encryptService.toEncrypt(isPremium);
    return this.httpClient.get(`/I140/serviceCenter/${isPremium}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  // nonpremium
  getServiceCentersList() {
    return this.httpClient.get('/serviceCenter/').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  detectReviewUsersListChanges(data) {
    this.reviewUsersListData$.next(data);
  }

  detectAttorneyListChanges(data) {
    this.attorneyList$.next(data);
  }
  detectUserListChanges(data) {
    this.userList$.next(data);
  }

  getUsersOfCompany(companyId) {
    return this.httpClient.get('/company/' + companyId + '/employee').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getValidUsersOfCompany(companyId) {
    return this.httpClient.get('/company/' + companyId + '/validLoginEmployeeList ').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getValidBeneEmployeeCompany(companyId) {
    return this.httpClient.get('/petitioner/dashboard/beneficiaries/employees/company/associated/' + companyId + '/list ').pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getReviewersUser(companyId, permissionName, isOnlySignatory?) {    
    const params = isOnlySignatory ? `&isOnlySignatory=${isOnlySignatory}` : '';
    return this.httpClient.get(`/company/${companyId}/employee?permissionName=${permissionName}${params}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getAttorneysOfCompany(companyId) {
    const payload = {
      pageNo: 0,
      pagination: 0,
      searchCriteria: '',
      searchText: '',
      sortBy: ''
    };
    return this.httpClient.post('/petitioner/dashboard/attorney/company/' + companyId, payload).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }
  getTask(taskId,type){
    return this.httpClient.get(`/${type}/task/${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data));
  }
  


  getActiveAttorneyCompanyList(petCompanyId) {
    return this.httpClient.get(`/petitioner/${petCompanyId}/attorney/list`).pipe(
      map((response: ImagilityBaseResponse) => response.data));
  }

  getConsulateAddressByContry(countryCode) {
    return this.httpClient.get(`/vd/consulates/${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

}
