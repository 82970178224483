import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-generate-invoice',
  templateUrl: './client-generate-invoice.component.html',
  styleUrls: ['./client-generate-invoice.component.scss']
})
export class ClientGenerateInvoiceComponent implements OnInit {
  public petitionerId:any;
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(data=>{
      this.petitionerId = data;
    })
  }

  ngOnInit(): void {
  }
  clientPetitionerId(item){
    // console.log(item, '$$$$$');

  }
}
