import { FormGroup } from '@angular/forms';
export const REQUIRED = 'REQUIRED';
export const INVALID_DATE = 'INVALID_DATE';
export const INVALID_YEAR = 'INVALID_YEAR';
export const INVALID_FORMAT = 'INVALID_FORMAT';
export const MIN = 'MIN';
export const MAX = 'MAX';
export const INCOMPLETE = 'INCOMPLETE';
export const CHECKBOX = 'CHECKBOX';
export const RADIO = 'RADIO';
export const MINLENGTH = 'MINLENGTH';
export const MAXLENGTH = 'MAXLENGTH';
export const SUCCESS = 'SUCCESS';
export const INVALID_CRED = 'INVALID_CRED';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_EXTENSION = 'INVALID_EXTENSION';
export const INVALID_NAME = 'INVALID_NAME';
export const INVALID_CITY = 'INVALID_CITY';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const INVALID_NUMBER = 'INVALID_NUMBER';
export const INVALID_NUMBER_2 = 'INVALID_NUMBER_2';
export const INVALID_NAME_2 = 'INVALID_NAME_2';
export const INVALID_DBNAME_2 = 'INVALID_DBNAME_2';
export const INVALID_TITLE_5 = 'INVALID_TITLE_5';
export const INVALID_DBTITLE_5 = 'INVALID_DBTITLE_5';
export const INVALID_NAME_5 = 'INVALID_NAME_5';
export const INVALID_WAGERANGE = 'INVALID_WAGERANGE';
export const INVALID_TRACK_NUMBER = 'INVALID_TRACK_NUMBER';
export const INVALID_PREVAILING_WAGE = 'INVALID_PREVAILING_WAGE';
export const INVALID_WAGE_PREVAILING = 'INVALID_WAGE_PREVAILING';
export const ALPHABETS_ONLY = 'ALPHABETS_ONLY';
export const ZIPCODE_ONLY = 'ZIPCODE_ONLY';
export const NUMBERS_ONLY = 'NUMBERS_ONLY';
export const NUMBER_ONLY = 'NUMBER_ONLY';
export const WAGES_TO_GREATER = 'WAGES_TO_GREATER';
export const JOBTITTLE = 'JOBTITTLE';
export const SOC_CODE = 'SOC_CODE';
export const SOC_OCCUPATION = 'SOC_OCCUPATION';
export const TOTAL_WORKER = 'TOTAL_WORKER';
export const Continuation_Employment = 'Continuation_Employment';
export const New_Employment = 'New_Employment';
export const Change_Employment = 'Change_Employment';
export const Change_Employer = 'Change_Employer';
export const Amended_Petition = 'Amended_Petition';
export const Concurrent_Employment = 'Concurrent_Employment';
export const DESIGNATED_TITLE= 'DESIGNATED_TITLE';
export const INVALID_H1B = 'INVALID_H1B';
export const INVALID_E3 = 'INVALID_E3';
export const INVALID_DOMAIN = 'INVALID_DOMAIN';
export const INVALID_QUESTION = 'INVALID_QUESTION';
export const ALPHABETS_SPLCHARS = 'ALPHABETS_SPLCHARS';


// [Concurrent_Employment, 'Please enter a valid .'],
// [Amended_Petition, 'Please enter a valid soc code.'],
// [Change_Employer, 'Please enter a valid soc code.'],
// [Change_Employment, 'Please enter a valid soc code.'],
// [New_Employment, 'Please enter a valid soc code.'],
// [Continuation_Employment, 'Please enter a valid soc code.'],

export const errorMessages = new Map([[REQUIRED, 'This field is required.'], 
[INVALID_FORMAT, 'Please enter in the correct format.'], [MIN, 'Please enter a value of at least '],[MIN, 'Please enter a value of at least '],
[MAX, 'Please enter a value no greater than '], [INCOMPLETE, 'Please complete all fields.'],
[ZIPCODE_ONLY, 'Please enter a valid zipcode '], [INVALID_YEAR, 'Please enter a valid year.'],
[CHECKBOX, 'Please make at least one selection.'], [RADIO, 'Please make a selection.'], [MINLENGTH, 'Please enter at least '],
[MAXLENGTH, 'Please enter no greater than  '], [INVALID_CRED, 'Login credentials are incorrect. Please try again.'],
[INVALID_EMAIL, 'Please enter a valid Email'], [INVALID_EXTENSION, 'Please enter a valid extension'], [INVALID_NAME, 'Please enter a valid name.'],  [INVALID_CITY, 'Please enter a valid city'],[CONFIRM_PASSWORD,'Confim password entered is not matching to your password.'],
[INVALID_NUMBER, 'Please enter a valid Number'], [WAGES_TO_GREATER, 'Wage to greater than or equal to wage from'], [NUMBER_ONLY ,'Please enter numbers only'], [INVALID_NUMBER_2, 'Invalid Number'], [NUMBERS_ONLY, 'Enter 3 digit number'], 
[SOC_OCCUPATION, 'Please enter a valid soc occupation.'],[JOBTITTLE, 'Please enter a valid Job title.'],[TOTAL_WORKER, 'Please enter a valid total worker.'],[SOC_CODE, 'Please enter a valid soc code.'],
[NUMBER_ONLY ,'Please enter numbers only'], [INVALID_NUMBER_2, 'Invalid Number'], [NUMBERS_ONLY, 'Enter 3 digit number'],  [DESIGNATED_TITLE, 'Please enter a valid designated title'], 
[ALPHABETS_ONLY, 'Please enter alphabets only'], [INVALID_WAGERANGE, 'To should be greater than From'], [INVALID_PREVAILING_WAGE, 'From wage should be greater than or equal to Prevailing wage paid - rate'], [INVALID_TRACK_NUMBER, 'Please enter a valid PWD tracking number'], [INVALID_WAGE_PREVAILING, 'Wage paid to non-immigrant - per under Wage Details & Prevailing wage paid – rate details should be same'], [INVALID_NAME_2, 'Please enter a valid name with at least 2 characters'], [INVALID_NAME_5, 'Please enter a valid business name with at least 5 characters'], [INVALID_TITLE_5, 'Please enter a valid job title with at least 5 characters'], [INVALID_DBNAME_2, 'Please correct the last name field in profile page with at least 2 characters'], [INVALID_DBTITLE_5, 'Please correct the designation field in profile page with at least 5 characters'], [INVALID_H1B, `The end date for the worker's period of employment must be less than or equal to three years from the start date for H1B and H1B1 visa classes.`], [INVALID_E3, `The end date for the worker's period of employment must be less than or equal to two years from the start date for the E3 visa class.`],[INVALID_DOMAIN, 'Invalid domain'],  [INVALID_QUESTION, 'Please enter a valid question'],[ALPHABETS_SPLCHARS, 'Only alphabets and special characters are allowed'] ]);
export const InvalidCredMessage = '';

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }        
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export function groupBy(InputArray, property) {  
    return InputArray.reduce(function (accumulator, object) { 
      const key = object[property]; 
      if (!accumulator[key]) {      
        accumulator[key] = [];    
      }    
      accumulator[key].push(object);
    return accumulator;  
    }, {});
  }    


  /* Given a start date, end date and day name, return
** an array of dates between the two dates for the
** given day inclusive
** @param {Date} start - date to start from
** @param {Date} end - date to end on
** @param {string} dayName - name of day
** @returns {Array} array of Dates
*/
export function getDaysBetweenDates(start, end, dayName) {  
  var result = [];
  var days = {sun:0,mon:1,tue:2,wed:3,thu:4,fri:5,sat:6};
  var day = days[dayName.toLowerCase().substr(0,3)];
  // Copy start date
  var current = new Date(start);
  // Shift to next of required days
  current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
  // While less than end date, add dates to result array
  while (current < end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }  
  return result;  
}
//Get Days between 2 dates
export function getDaysArray(start, end) {
  const arr = [];
  for(const dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
};

export function getPreviousMonday(date, dayToAdd)
{    
  var today=new Date();
  var todaysDay=today.getDay();
  var goBack=today.getDay()%7+ dayToAdd-1;
  var lastMonday=new Date().setDate(today.getDate()-goBack);
  var desiredDate=new Date(lastMonday);
  return desiredDate;
}

export function getPreviousSunday(date, dayToAdd)
{    
  var today=new Date();
  var todaysDay=today.getDay();
  var goBack=today.getDay()%7+ dayToAdd;
  var lastSunday=new Date().setDate(today.getDate()-goBack);
  var desiredDate=new Date(lastSunday);
  return desiredDate;
}

//get the dates between 2 dates
export function getDates(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate));
      currentDate = currentDate.addDays(1);
  }
  return dateArray;
}