import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class FamilyInformationService {

  constructor(private http: HttpClient) { }
  getFamilyInformation(caseType, familyId: number, superTaskId?, taskId?) {
    return this.http.get(`/${caseType}/family/${familyId}?superTaskId=${superTaskId}&taskId=${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postFamilyInformation(caseType, familyId: number, payload, superTaskId?, taskId?) {
    return this.http.post(`/${caseType}/family/${familyId}?superTaskId=${superTaskId}&taskId=${taskId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  deleteFamilyItem(caseType, id, superTaskId?, taskId?) {
    return this.http.delete(`/${caseType}/family/${id}?superTaskId=${superTaskId}&taskId=${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getBenficiaryFamilyDetails(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/dependentDetails`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && response.status === 200) {
            return response;
          } else {
            return null;
          }
        })
      );
  }

  postBenficiaryFamilyDetails(caseType: string, beneficiaryId: number, payload: any) {
    return this.http.post(`/${caseType}/beneficiary/${beneficiaryId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }
}
