export class Tranings {
    constructor(obj: Partial<Tranings>) {
        Object.assign(this, obj);
    }
    id: number;
    name: string;
    subjects: string;
    institution :string;
    startDate: string;     
    endDate: string;		
}