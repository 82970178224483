<!-- <p>upload-image-dialog works!</p> -->
<kendo-dialog title="Upload Image" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="450">
    <div class="row example-wrapper">
        <div class="col-xs-8 col-sm-12 example-col">
            <div class="card">
                <div class="card-block">
                    <form class="k-form-inline">
                        <div class="k-form-field">
                            <label>Image</label>
                            <app-upload-image (valueChange)="setUploadImageInfo($event)"> </app-upload-image>
                        </div>
                        <div class="k-form-field">
                            <label [for]="heightInput">Height (px)</label>
                            <kendo-numerictextbox #heightInput format="n0" [(value)]="height" [min]="0"> </kendo-numerictextbox>
                        </div>
                        <div class="k-form-field">
                            <label [for]="widthInput">Width (px)</label>
                            <kendo-numerictextbox #widthInput format="n0" [(value)]="width" [min]="0"> </kendo-numerictextbox>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton class="k-button k-button-md k-rounded-md k-button-rectangle k-button-solid-base k-button-solid ng-star-inserted" (click)="close()">Cancel</button>
        <button kendoButton class="k-button k-button-md k-rounded-md k-button-rectangle k-button-solid-primary k-button-solid ng-star-inserted" ng-reflect-primary="true" [disabled]="canInsert" (click)="uploadImage()" themeColor="primary">Insert</button>
    </kendo-dialog-actions>
</kendo-dialog>
