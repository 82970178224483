<div class="fluid-container">
    <div class="row col-md-12 pa-title">
        <div class="row col-md-12 mb-3">
            <a class="link" (click)="backToOverallAnalysis()" style="color: #8F8F8F;
            font: normal normal normal 14px/18px Source Sans Pro;">
                << Back to Petition Analysis</a>
        </div>
        <div class="col-md-6 mb-3">
            <h5>{{categoryName}} Details</h5>
        </div>
        <div class="col-md-6">
            <p-dropdown class="pull-right" [(ngModel)]="categoryCode" optionLabel="name" optionValue="code"
                [options]="categoryList" (ngModelChange)="onCategorySelect()"></p-dropdown>
        </div>

    </div>
    <div class="row col-md-12 pa-status">
        <div class="pa-brd">
            <h6>Each Category level analysis </h6>
            <div class="row col-md-12 pt-4">
                <div class="col-md-6 brd-right">
                    <img-primeng-donut [inputData]="categoryGraphDetails"></img-primeng-donut>
                </div>
                <div class="col-md-6">
                    <h6 class="pb-4">Overview</h6>
                    <p>{{categoryDetails.overview}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 col-md-12 pa-status">
        <div class="pa-brd ">
            <div class="col-md-12 row" style="height: 50px;">
                <div class="col-md-10 row align-items-center">
                    <h6>Overall Observations</h6>
                </div>
                <div class="col-md-2">
                    <p-multiSelect [displaySelectedLabel]="false" optionValue="code" [filter]="false" [showToggleAll]="false"
                        [options]="observationCategories" [(ngModel)]="selObservationFilterCtg" (onChange)="handleFilter()"
                        defaultLabel="Filter" optionLabel="name" class="filter-check">
                        <ng-template pTemplate="header">
                            <p-checkbox  [(ngModel)]="selectAllObservations" label="All Observations" [binary]="true" class="filter-all-select" value="afsdf" (onChange)="handleSelectAll()"></p-checkbox>
                        </ng-template>
                    </p-multiSelect>
                </div>
            </div>
            <div class="col-md-12 pl-0">
                <!-- <h6>Overall Observations</h6> -->
                <div class="col px-1 task-table">
                    <p-table styleClass="p-datatable-gridlines"
                        class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [paginator]="true"
                        [rows]="25" [showCurrentPageReport]="true" rowExpandMode="single"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[25,50, 100]" [value]="observations" dataKey="indexId">
                        <ng-template pTemplate="header">
                            <tr class="text-uppercase">
                                <th style="width: 3rem"></th>
                                <th >Petition Element</th>
                                <th colspan="2">Findings</th>
                                <th colspan="2">Recommendations</th>
                                <th>Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-obervation let-expanded="expanded">
                            <tr>
                                <td style="padding-top: 6px!important;">
                                    <button type="button" pButton pRipple [pRowToggler]="obervation"
                                        class="p-button-text p-button-rounded p-button-plain"
                                        [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
                                </td>
                                <td>{{obervation.elementName}}</td>
                                <td colspan="2" class="text-truncate">{{obervation.finding}}</td>
                                <td colspan="2" class="text-truncate">{{obervation.recommendation}}</td>
                                <td class="text-truncate">
                                    <p *ngIf="obervation.status === 0">
                                        Needs to be fixed
                                    </p>
                                    <p *ngIf="obervation.status === 1">
                                        Looks Perfect
                                    </p>
                                    <p *ngIf="obervation.status === 2">
                                        Not Applicable
                                    </p>
                                    <p *ngIf="obervation.status === 3">
                                        Not Initiated
                                    </p>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-obervation>
                            <tr class="pt-5 pt-5">
                                <td colspan="7">
                                    <div class="p-p-3 rowExpansionData">
                                        <h6>Findings</h6>
                                        <p> {{obervation.finding}} </p>
                                        <h6>Recommendation</h6>
                                        <p>{{obervation.recommendation}} </p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>