import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {
  notificationData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData,
    private dialogRef: MatDialogRef<ViewNotificationComponent>) {
      this.notificationData = this.dialogData;
  }  

  ngOnInit() {}

  onClose(){
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

}

