<div #eventpageDiv *ngIf="eventpageLoaded" class="mt-2">
    <form [formGroup]=" eventsForm">
        <div class="mt-3">
            <div class="row ">
                <div class="col-4 nopadding mt-3 mb-2">
                    <h5 style="font-weight:bolder;">Event Details</h5>
                </div>
                <div class="col-lg-8">
                    <div class="pull-right">
                        <p-radioButton class='mr-1' name="groupname" value="1" label="public" formControlName='isPublic'
                            [(ngModel)]="isPublic">
                        </p-radioButton>
                        <p-radioButton name="groupname" value="0" label='private' [(ngModel)]="isPublic"
                            formControlName='isPublic'>
                        </p-radioButton>
                    </div>
                </div>
            </div>
            <div>

                <div class="row mt-2">
                    <label class="label-cls required" for="first-name">Title/Subject </label>
                    <input id="first-name" type="text" formControlName="subject">
                    <small *ngIf="eventsForm.get('subject').touched && eventsForm.get('subject').errors"
                        class="p-error">
                        <span *ngIf="eventsForm.get('subject').errors['required']">Required</span>
                    </small>
                </div>
                <div class=" mt-3">
                    <label class="label-cls required">Share Events With </label>
                    <span><i class="fa fa-plus ml-2" (click)="requiredImagilityEmail()"></i></span>
                    <span>(click on plus icon add Imagility emails)</span>
                    <div class="p-fluid">
                        <p-chips styleClass="p-chips" formControlName="reqattendiesList" [(ngModel)]="requiredEmails"
                            separator=","></p-chips>
                        <small
                            *ngIf="eventsForm.get('reqattendiesList').touched && eventsForm.get('reqattendiesList').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('reqattendiesList').errors['required']">Required</span>
                        </small>
                    </div>

                </div>
                <p-dialog [style]="{height: '500px'}" header="Add Imagility Attendes" [(visible)]="required"
                    [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                    <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filtered"
                        (completeMethod)="serachAttendes($event)" field="name" [multiple]="true"
                        formControlName="reqImagilityattendiesList" [(ngModel)]="requiredImgEmails">
                    </p-autoComplete>
                    <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                    <div class="mt-2">
                        <button class="btn btn-primary" (click)="addEmail()">Add</button>
                        <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                    </div>
                    <div [style]="{height: '200px'}">

                    </div>

                </p-dialog>
                <div class=" mt-3">
                    <label>Optional Attendes</label>
                    <span><i class="fa fa-plus ml-2" (click)="addImagilityEmail()"></i></span>
                    <span>(click on plus icon add Imagility emails)</span>
                    <div class="p-fluid">
                        <p-chips styleClass="p-chips" [(ngModel)]="optEmail" formControlName="optattendiesList"
                            separator=","></p-chips>
                    </div>
                </div>

                <p-dialog header="Add Imagility Attendes" [(visible)]="optional" [modal]="true"
                    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                    <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filtered"
                        (completeMethod)="serachAttendes($event)" field="name" [multiple]="true"
                        formControlName="optimagilityattendiesList" [(ngModel)]="optImgEmail">
                    </p-autoComplete>
                    <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                    <div class="mt-2">
                        <button class="btn btn-primary" (click)="addoptEmail()">Add</button>
                        <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                    </div>
                    <div [style]="{height: '200px'}">

                    </div>
                </p-dialog>
                <!-- <div class=" mt-3">
                    <label class="label-cls required">Share the Event with </label>
                        <span><i class="fa fa-plus ml-2" (click)="requiredImagilityEmail()"></i></span>
                        <span>(click on plus icon add Imagility emails)</span>
                        <p-chips [(ngModel)]="requiredEmails" formControlName="reqattendiesList"   separator=","></p-chips>
                        <small
                    *ngIf="eventsForm.get('reqattendiesList').touched && eventsForm.get('reqattendiesList').errors"
                    class="p-error">
                    <span *ngIf="eventsForm.get('reqattendiesList').errors['required']">Required</span>
                  </small>
               </div>
               <p-dialog   [style]="{height: '500px'}"header="Add Imagility Attendes" [(visible)]="required" [modal]="true"
               [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
               <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filteredeventAttendes"
                   (completeMethod)="serachAttendes($event)" field="emailId" [multiple]="true"
                   formControlName="reqImagilityattendiesList">
               </p-autoComplete>
               <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
               <div>
                   <button class="btn btn-primary" (click)="addEmail()">Add</button>
               </div>
               <div [style]="{height: '200px'}">
               </div>
           </p-dialog>
           <div class=" mt-3">
            <label>Optional Attendes</label>
            <span><i class="fa fa-plus ml-2" (click)="addImagilityEmail()"></i></span>
            <span>(click on plus icon add Imagility emails)</span>
            <p-chips formControlName="optattendiesList"  [(ngModel)]="optEmail" separator=","></p-chips>
            <small
                    *ngIf="eventsForm.get('optattendiesList').touched && eventsForm.get('optattendiesList').errors"
                    class="p-error">
                    <span *ngIf="eventsForm.get('optattendiesList').errors['required']">Required</span>
                  </small>
        </div>
        <p-dialog  header="Add Imagility Attendes" [(visible)]="optional" [modal]="true"
                    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                    <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filteredeventAttendes"
                        (completeMethod)="serachAttendes($event)" field="emailId" [multiple]="true"
                        formControlName="optimagilityattendiesList">
                    </p-autoComplete>
                    <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                    <div>
                        <button class="btn btn-primary" (click)="addImagilityEmail()">Add</button>
                    </div>
                    <div [style]="{height: '200px'}">

                    </div>
                </p-dialog> -->
                <div class="row mt-2">
                    <label class="label-cls required" for="location">Location</label>
                    <input id="location" type="text" formControlName="meetingLocation">
                    <small *ngIf="eventsForm.get('meetingLocation').touched && eventsForm.get('meetingLocation').errors"
                        class="p-error">
                        <span *ngIf="eventsForm.get('meetingLocation').errors['required']">Required</span>
                    </small>
                </div>

                <div class="row divider mt-5">
                </div>
                <div class="row mt-3">
                    <p class="label-cls"> Time and duration</p>
                </div>
                <div class="row mt-3">
                    <h6 style="font-weight:bolder;">Time Zone: EST ( Eastern time zone - USA )</h6>
                </div>
                <div class="row">
                    <div class="col-2 nopadding">
                        <label class="label-cls required"> Start Date</label>
                        <p-calendar #sd yearRange="2000:2099" formControlName="startDate" name="date"
                            styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                            [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [minDate]="minimumDate"
                            (onSelect)="checkEndDateTouched(sd)">
                        </p-calendar>
                        <small *ngIf="eventsForm.get('startDate').touched && eventsForm.get('startDate').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('startDate').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-2 ml-3">
                        <label class="label-cls required">Start Time</label>
                        <input type="time" formControlName="startTime">
                        <small *ngIf="eventsForm.get('startTime').touched && eventsForm.get('startTime').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('startTime').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-1 mt-2 text-center">
                        <label for=""></label>
                        <div>
                            <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
                        </div>
                    </div>
                    <div class="col-2 ">
                        <label class="label-cls required"> End Date</label>
                        <!-- <input type="date" formControlName="endDate" > -->
                        <p-calendar formControlName="endDate" name="date" styleClass="p-form-datepicker"
                            dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                            [minDate]="sd.value || minimumDate" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2099">
                        </p-calendar>
                        <small *ngIf="eventsForm.get('endDate').touched && eventsForm.get('endDate').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('endDate').errors['required']">Required</span>
                        </small>

                    </div>
                    <div class="col-2 ">
                        <label class="label-cls required">End Time</label>
                        <input type="time" formControlName="endTime">
                        <small *ngIf="eventsForm.get('endTime').touched && eventsForm.get('endTime').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('endTime').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div class="row divider mt-5">
                </div>



                <div class="row mt-4">
                    <h6 style="font-weight:bolder;">Details</h6>
                </div>
                <div class="row mt-2">
                    <div class="col-12 nopadding">
                        <label for="">Description/Agenda</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description"
                            rows="3"></textarea>
                    </div>
                    <small *ngIf="eventsForm.get('description').touched && eventsForm.get('description').errors"
                        class="p-error">
                        <span *ngIf="eventsForm.get('description').errors['required']">Required</span>
                    </small>
                </div>
                <div class="row mt-4 ">
                    <div class=" nopadding">
                        <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true"
                            customUpload="true" (uploadHandler)="Upload($event, createUploader)"
                            [accept]="acceptedTypes" invalidFileTypeMessageSummary=""
                            invalidFileTypeMessageDetail="Unaccepted format. PDF, JPG, JPEG, and PNG file types are allowed"
                            showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
                        </p-fileUpload>
                    </div>

                    <div style="padding: 10px;">
                        <label>Attatch details of event</label>
                    </div>
                </div>
            </div>
            <div class="mt-3" *ngIf="uploadedFile">
                <div>
                    <label for="upload"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <td>{{uploadedFile.fileName}}</td>
                        <td>{{uploadedFile.type}}</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="eventDoc.length>0" class="mt-3">
                <p-table [value]="eventDoc" styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-record>
                        <tr>
                            <td> {{record?.fileType }}</td>
                            <td>{{record?.fileName}}</td>
                            <td><i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(record)"></i></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-record>
                        <tr>
                            <td [attr.colspan]="3">
                                No records found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td> {{record?.fileType }}</td>
                    <td>{{record?.fileName}}</td>
                    <td>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-record>
                <tr>
                    <td [attr.colspan]="3">
                        No records found
                    </td>
                </tr>
            </ng-template>
        </div>
        <div *ngIf="!this.eventEditDetails" class="row mt-5 mb-5">
            <button type="button" [disabled]="!this.eventsForm.valid" class="btn btn-primary"
                (click)="save()">Save</button>
            <button type="button" class="btn btn-light ml-2" (click)="eventcancel()">Cancel</button>
        </div>
        <div *ngIf="this.eventEditDetails" class="row mt-5 mb-5">
            <button type="button" [disabled]="!this.eventsForm.valid" class="btn btn-primary"
                (click)="update()">Update</button>
            <button type="button" class="btn btn-primary ml-3" (click)="cancel()"> Cancel Event</button>
        </div>
    </form>
</div>