<div>
  <div class="p-3">
    <div>{{educationDetails.length > 0 ? educationDetails.length : 'No' }} Degrees Added by you</div>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="im-card-no-border-table">
      <ng-container matColumnDef="{{column.columnDef}}" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
        <td mat-cell *matCellDef="let element">
          {{column.columnDef == 'degreeType' ? element.degreeType.description : element[column.columnDef] }}
          <!-- <div class="row" *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <a>
              <mat-icon>expand_more</mat-icon>
            </a>
          </div> -->
          <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <mat-icon matTooltip="View more" class="mr-1 icn">expand_more</mat-icon>
          </span>
          <div *ngIf="updateBenProfile && showEditButton && !isLocked">
            <div class="row" style="width:100px;" *ngIf="column.columnDef == 'action'">
              <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Edit" class="im-table-verticle-icon"
                (click)="handleEdit(element)">edit</mat-icon>
              <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Delete" (click)="handleDelete(element)">
                delete_forever</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">

        <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

            <div class="w100 example-element-description py-1" *ngIf="element.courses?.length > 0">
              <h6>List of Courses</h6>
              <ng-container>
                <!-- <ul class="list-group" *ngFor="let course of element.courses; let i = index;">
                    <li class="list-group-item">{{course.courseName}}</li>

                  </ul> -->
                <table class="table table-striped">
                  <tr *ngFor="let item of element.courses;let i=index" class="border border-dark">
                    <td class="w40">
                      <div class="circleIndex rounded-circle">{{i+1}}
                      </div>
                    </td>
                    <td nowrap>
                      {{item.courseName}}
                    </td>
                  </tr>
                </table>
              </ng-container>
            </div>
            <div class="w100 example-element-description py-1" *ngIf="element.documents?.length > 0">
              <h6>List of Documents</h6>
              <ng-container>
                <table class="im-card-no-border-table">
                  <thead>
                    <tr>
                      <th class="w40"></th>
                      <th>Document</th>
                      <th>Document Type</th>
                      <th><i class="fa fa-download fa-lg icn" aria-hidden="true"></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of element.documents;let i=index">
                      <td class="w40">
                        <div class="circleIndex rounded-circle">{{i+1}}
                        </div>
                      </td>
                      <td>
                        <strong>{{ item.fileName }}</strong>
                      </td>
                      <td nowrap>
                        {{ item?.fileCategory?.code === 'OTHER' ? item?.fileCategoryOtherName : item?.fileCategory?.name }}
                      </td>
                      <td nowrap>
                        <app-file-download [fileName]="item.fileLocationName"
                          [Path]="envPath+item.fileLocation.substring(23)"></app-file-download>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>