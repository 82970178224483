import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PetitionerService } from './../../services/petitioner.service';
import { getSubTaskDetails } from 'visa-store';
import { UpdateEmploymentService } from './update-employment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VisaExtendedState } from './visa-extended-state';
import { ImagilityBaseResponse, SubTaskDetails } from 'app-models';

@Component({
  selector: 'app-update-employment',
  templateUrl: './update-employment.component.html',
  styleUrls: ['./update-employment.component.scss'],
})
export class UpdateEmploymentComponent implements OnInit, OnDestroy {
  isOnboarding = true;
  taskDetails: any;
  employmentDetails: any;
  taskDetailsSubscription$ = new Subject();
  index = 0;
  isRoutedFromBene: boolean;
  taskId = 0;
  beneficiaryEmployeeId = 0;
  companyId = 0;
  i9FormId = 0;
  constructor(
    private petitionerService: PetitionerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected pbStore: Store<VisaExtendedState>,
    private updateEmploymentService: UpdateEmploymentService,
  ) {
    // const url = this.activatedRoute.snapshot['_routerState']['url'] || '';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .pipe(takeUntil(this.taskDetailsSubscription$))
      .subscribe((data) => {
        this.index = data['urlAfterRedirects'].includes('on-board') ? 0 : 1;
        this.isRoutedFromBene = data['url'].includes('beneficiaries') ? true : false;
      });
    this.activatedRoute.queryParams.subscribe(params => {
      this.taskId = params.taskId || 0;
      this.beneficiaryEmployeeId = params.beneficiaryEmployeeId || 0;
      this.companyId = params.companyId || 0;
      this.i9FormId = params.i9FormId || 0;
    });

  }

  onFormSubmitted() {
    this.goBack();
  }
  ngOnInit() {
    this.getEmploymentDetails();
  }

  getEmploymentDetails() {
    if (!this.isRoutedFromBene) {
      this.petitionerService.taskViewTypeBSubject.next(4);
      this.pbStore.pipe(select(getSubTaskDetails))
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe((taskDetails: SubTaskDetails) => {
          if (taskDetails.id) {
            this.taskDetails = taskDetails;
            this.companyId = taskDetails.companyId;
            this.updateEmploymentService
              .getOnboardingEmployment(0, this.taskDetails.id, this.taskDetails.companyId)
              .pipe(takeUntil(this.taskDetailsSubscription$))
              .subscribe((employmentDetails) => {
                this.beneficiaryEmployeeId = employmentDetails.beneficiaryId;
                this.i9FormId = employmentDetails.i9FormId;
                // if (employmentDetails) {
                // this.taskUtilityService.setTaskDetails(this.taskDetails);
                this.employmentDetails = {
                  ...employmentDetails,
                  beneficiaryId: this.beneficiaryEmployeeId,
                  taskId: this.taskDetails.id,
                  companyId: this.taskDetails.companyId

                };

                // }
              });

          }
        });
    }
    else {
      this.updateEmploymentService.getOnboardingEmploymentFromBenList(this.beneficiaryEmployeeId, 0, this.companyId)
        .pipe(takeUntil(this.taskDetailsSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          console.log(response);
          if (response.status === 200) {
            this.beneficiaryEmployeeId = response.data.beneficiaryId;
            this.i9FormId = response.data.i9FormId;
          }
        });
    }
  }



  goBack() {
    if (!this.isRoutedFromBene) {
      this.router.navigate(['./../details'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../../beneficiaries'], { relativeTo: this.activatedRoute });
    }
  }

  ngOnDestroy(): void {
    this.taskDetailsSubscription$.next();
    this.taskDetailsSubscription$.complete();
    this.petitionerService.taskViewTypeBSubject.next(1);
  }
  handleChange(e) {
    this.index = e.index;
    const queryParamsObj = {
      beneficiaryEmployeeId: this.beneficiaryEmployeeId || 0,
      companyId: this.companyId || 0,
      taskId: this.taskId,
      i9FormId: this.i9FormId || 0
    };
    if (this.index === 0) {
      this.router.navigate(['./on-board'], { queryParams: queryParamsObj, relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['./I-9'], { queryParams: queryParamsObj, relativeTo: this.activatedRoute });
    }

  }
}
