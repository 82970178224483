import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'authentication';
// import { updateStepStatusToInitialState } from 'visa-store';
import { takeUntil } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';
import { DeleteDialogConfigurations } from '../../utility/dialog.configuration';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { TaskAllocationService } from '../../../services/task-allocation.service';
import * as RolesRouteMap from 'app-models';
import { Router } from '@angular/router';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-step-times-configurations',
  templateUrl: './step-times-configurations.component.html',
  styleUrls: ['./step-times-configurations.component.scss'],
  providers: [DialogService]
})
export class StepTimesConfigurationsComponent implements OnInit {

  stepConfigurList: any;
  stepsTypeCols: any[];
  totalElementCount = 0;
  pageSizeOptions = [25, 50, 100];
  pageIndex = 1; // default
  pageSize = 25; // default
  timeConfiguredRecordCount = 2;
  companyId: number = 0;
  userType = '';
  observableSubscription$ = new Subject();
  destroy$ = new Subject<void>();


  payload = {
    pageNumber: this.pageIndex,
    recordsPerPage: this.pageSize,
  }

  constructor(
    private toastr: ToastrService,
    public dialogService: DialogService,
    private store: Store<any>,
    private taskAllocationService: TaskAllocationService,
    private authenticationService: AuthenticationService,
    public router: Router) {
    //this.companyId = this.authenticationService.currentUserValue['companyId'];
  }

  ngOnInit(): void {
    this.companyId = parseInt(sessionStorage.getItem("companyId"));
    this.userType = sessionStorage.getItem('userTypeRole');
    if (this.userType === 'Petitioner' || this.userType === 'Attorney') {
      this.stepsTypeCols = [
        { field: 'companyName', header: 'Company Name' },
        { field: 'superTaskName', header: 'Task Type' },
        { field: 'visaName', header: 'Visa type' },
        { field: 'petitionName', header: 'Petition Type' },
        { field: 'taskTypeName', header: 'Sub-Task type' },
        { field: 'immigrationCategoryName', header: 'Immigration Category' },
        { field: 'immigrationClassificationName', header: 'Immigration Classification' },
        { field: 'visaName', header: 'Visa Template' }       
      ];
    }
    if (this.userType === 'Platform') {
      this.stepsTypeCols = [
        { field: 'superTaskName', header: 'Task Type' },
        { field: 'visaName', header: 'Visa type' },
        { field: 'petitionName', header: 'Petition Type' },
        { field: 'taskTypeName', header: 'Sub-Task type' },
        { field: 'immigrationCategoryName', header: 'Immigration Category' },
        { field: 'immigrationClassificationName', header: 'Immigration Classification' },
        { field: 'taskTypeSaveJsonVisaName', header: 'Visa Template' }
      ];
    }
    this.getStepsConfiguredList();
  }

  addNewConfiureTime() { }

  getStepsConfiguredList() {
    this.taskAllocationService.postListStepsConfigured("NA", this.companyId, this.payload).subscribe(data => {
      if (data.status === 200) {
        this.timeConfiguredRecordCount = data.data['totalCount'];
        this.stepConfigurList = data.data['taskType'];
        // this.stepConfigurList.forEach(element => {
        //   if(!element.immigrationCategoryName && !element.visaName) {
        //     element.immigrationCategoryName = "Employment Based"
        //   }
        //   if(!element.immigrationClassificationName && !element.petitionName) {
        //     element.immigrationClassificationName = "EB-1c"
        //   }
        // });
      }
      else {
        this.stepConfigurList = [];
      }
    });

    this.stepConfigurListDetails = this.stepConfigurList.data.taskType
    console.log(this.stepConfigurListDetails, 'this.stepConfigurList');

  }

  public stepConfigurListDetails = [];

  paginationEvent(event) {
    this.payload.pageNumber = event.page + 1;
    this.payload.recordsPerPage = event.rows;
    this.getStepsConfiguredList();
  }
  routeToCreateConfigure() {
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/task-allocation/configure-time`]);

  }

  editStepsTimeConfigured(item) {
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/task-allocation/configure-time`]
      , { state: { data: item } });
  }

  deleteStepsTimeConfigured(item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.taskAllocationService.deleteListStepsConfigured("NA", item.companyId, item.taskTypeId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          if (response.status == 200) {
            this.toastr.success(response.message, 'Success');
            this.getStepsConfiguredList();
          } {
            CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // this.store.dispatch(updateStepStatusToInitialState());
  }

}
