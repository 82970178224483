import { Component, OnInit,OnDestroy } from '@angular/core';
import { RecentlyVisitedFilterData, RecentlyVisitedFilterModel } from 'src/app/data/models/dashboard-widgets.model';
import { PetitionerService} from "../../../../services/petitioner.service";
import * as viewByOptions from '../../../../data/constants/viewby-option.json';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as RolesRouteMap from '../../../../data/constants/roles';
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';
import { Subscription} from "rxjs";
import { EncryptService } from 'src/app/modules/core/http/http-encrypt.service';
import { CustomErrorToastComponent } from 'custom-error-toast';
@Component({
  selector: 'app-recently-visited-task',
  templateUrl: './recently-visited-task.component.html',
  styleUrls: ['./recently-visited-task.component.scss'],
})

export class RecentlyVisitedTaskComponent implements OnInit,OnDestroy { 
  viewByparam: string; 
  viewBy: RecentlyVisitedFilterModel[];
  displayedColumns: string[] = ['time', 'id', 'name', 'company'];
  allvisitedTasks:RecentlyVisitedFilterData[] = [];
  userType:any ="";
  payload = {
    pageNumber: 0,
    recordsPerPage: 25,
    searchText: ""
  }
  private subscription: Subscription;
  
  constructor(public toastr: ToastrService,private petetionService:PetitionerService,private router: Router, private route:ActivatedRoute,
    private encryptService: EncryptService,
    protected store: Store<any>) { }

  ngOnInit(): void {
   this.viewBy = (viewByOptions as any).default;
   this.getViewBy(this.viewBy[0].value);
   this.userType = sessionStorage.getItem('userTypeRole');
  }
  getViewBy(viewOption: string = 'today'){
  let payload = this.payload;
  const encryptedText = this.encryptService.toEncrypt(viewOption);
  this.subscription = this.petetionService.getRecentVisitedTask(encryptedText,payload)
    .pipe()
    .subscribe((response) => { 
      this.allvisitedTasks = response;
    });
  }
  gotToTaskDetails(item){
    sessionStorage.removeItem('selectedSocCode');
    sessionStorage.removeItem('majorgroupValue');
    if(item?.taskSource === 'WIZARD'){
      sessionStorage.setItem('wizardTID', item?.subTaskId);
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
    }else{
      this.store.dispatch(new Task.SetTaskRouteId(item?.id));
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details`]);
    }
  }
  goToUserProfile(input){
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('empId');
     let url;
     url = `/${RolesRouteMap.userRoleRouteMapping[this.userType]}/`+this.userType.toLowerCase()+'/users/newuser';
    if(input.employeeDetail){
        sessionStorage.setItem('user', JSON.stringify(input.employeeDetail[0]));
        sessionStorage.setItem('empId', input.employeeDetail[0].id);
    }
    if (input.petitionerId == null) {
        this.router.navigate([url], { relativeTo: this.route, state: { editMode: true } });
      } 
      else {
        this.router.navigate([url], { relativeTo: this.route, state: { editMode: true, clientId: input.petitionerId } });
      }
  }
  goToCompanyProfile(petitionerId,modeOfCreation){
    if(petitionerId == null || modeOfCreation == null){
      CustomErrorToastComponent.showErrorToast(this.toastr, "Petitioner ID/Mode of Creation is null");
    }
    else{
      this.router.navigate(['../edit-client/'+petitionerId], {
        relativeTo: this.route,
        queryParams: { modeOfCreation: modeOfCreation },
      });
    }
  }
  ngOnDestroy(): void {
      this.subscription.unsubscribe(); 
  }
}
