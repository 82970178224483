import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TourModalComponent } from 'src/app/modules/shared/modal/tour-modal/tour-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as Roles from '../../../../app/data/constants/roles';
import { AttorneyWelcomeDialog } from '../../dashboard-attorney/dashboard-attorney.component';
import { BeneficiaryWelcomeDialog } from '../../dashboard-beneficiary/dashboard-beneficiary.component';
import { PetitionerWelcomeDialog } from '../../dashboard-petitioner/dashboard-petitioner.component';

@Component({
  selector: 'app-config-menu',
  templateUrl: './config-menu.component.html',
  styleUrls: ['./config-menu.component.scss']
})
export class ConfigMenuComponent implements OnInit {
  display;
  videos: any[] = [];
  resourceLink;
  isLoggedIn: boolean;
  welcomeDialog;
  displayMenu: boolean;
  isFBUser: boolean;
  constructor(private router: Router,public dialog: MatDialog,
    private authenticationService: AuthenticationService) {    
     }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((data) => {
      this.isLoggedIn = data ? true : false;
      this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
      this.setValues();
    });

  }

  setValues(){
    if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY) {
      this.welcomeDialog = AttorneyWelcomeDialog;
      this.resourceLink = '/attorney-landing/resources';
      this.displayMenu = true;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
      this.welcomeDialog = PetitionerWelcomeDialog;
      this.resourceLink = '/petitioner-landing/resources';
      this.displayMenu = true;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.BENEFICIARY) {
      this.welcomeDialog = BeneficiaryWelcomeDialog;
      this.resourceLink = '/beneficiary-landing/resources';
      this.displayMenu = true;
    }
    else{
      this.displayMenu = false;
    }
  }

  routeToResourcePage() {
    this.router.navigate([this.resourceLink]);
  }

  displayStartTourDialog(){
    window.scrollTo(0,0);
    this.dialog.open(TourModalComponent,
      {
        width: '650px',
        data: { update: { 
          userType: sessionStorage.getItem('userTypeRole')}
        }
      });
  }

  displayIntroVideo(){
    this.dialog.open(this.welcomeDialog,
      {
        height: '740px',
        width: '800px',
      });
  }

}
