<div class="container p-0 notify-beneficiary">
    <div class="form-row">
        <div class="form-group col-md-12 notifify-title">
            <h5 mat-dialog-title>View Message</h5>
            <button (click)="onClose()" mat-icon-button aria-label="Close" class="icon-button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="col-md-12">
            <hr>
            <div class="form-group">
                <p class="viewMessage">{{notificationData.text}}</p>
            </div>
            <hr>
        </div> 
    </div> 
    <div class="submit-portion">
        <button mat-button class="btn btn-primary" (click)="onClose()">CLOSE</button> 
    </div> 
</div>