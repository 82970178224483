import { Component, OnInit } from '@angular/core';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';

@Component({
  selector: 'app-platform-admin-landing',
  templateUrl: './platform-admin-landing.component.html',
  styleUrls: ['./platform-admin-landing.component.scss']
})
export class PlatformAdminLandingComponent implements OnInit {

  constructor(
    private cacheCountryService: CacheCountryService

  ) { }

  ngOnInit() {
    this.cacheCountryService.getCountry().subscribe();
  }

}
