import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { $ } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AttorneyProfileService {

  constructor(private httpClient: HttpClient) { }

  saveEmployeeDetails(companyId, employeeId, payload) {
    return this.httpClient.post(`/company/${companyId}/employee/${employeeId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    ); 
  }


  getEmployeeDetails(companyId, employeeId){
    return this.httpClient.get(`/company/${companyId}/employee/${employeeId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    )}

    updateProfilePic(companyId,employeeId,payload) {
      return this.httpClient.post(`/company/${companyId}/employee/${employeeId}/profileImage`, payload).pipe(
        map((response) => response)
      );
    }

    getAttorneyDetails(companyId, employeeId){
      return this.httpClient.get(`/company/${companyId}/attorney/${employeeId}/attorneyInfo`).pipe(
        pluck('data'), map((data: Array<any>) => {
          return data;
        })
      )}

      saveAttorneyDetails(companyId, employeeId, payload) {
        return this.httpClient.post(`/company/${companyId}/attorney/${employeeId}/attorneyInfo`, payload).pipe(
          map((response: ImagilityBaseResponse) => response)
        ); 
      }
}
