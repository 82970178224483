import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, NgForm, Validators, FormControl } from '@angular/forms';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() { }

  frmToValidation(frmDt, toDt) {
    return true;
  }
  MinDateValidate(inputDt: Date, MaxDt: Date) {
    return inputDt < MaxDt;
  }
  MaxDateValidate(inputDt, MaxDt) {
    return inputDt > MaxDt;
  }
  currentDateValidator(currentDate: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // console.log("$$$$ service",control.value, currentDate)
      if (control.value !== undefined && (control.value > currentDate)) {
        return { 'currentDateError': true };
      }
      // if(control.value !== undefined && !moment(control.value, 'MM-DD-YYYY').isValid()){
      // return { 'patternError': true };
      // }
      return null;
    };
  }
  dynamicRequiredValidator(formName: NgForm, visaType, fieldNames: {
    addRequiredValidator: any[],
    removeAllValidator: any[]
  }) {
    fieldNames.addRequiredValidator.forEach(field => {
      const fieldName: FormControl = field;
      if (fieldName) {
        fieldName.setValidators([Validators.required]);
        fieldName.updateValueAndValidity();
      }
    });
    fieldNames.removeAllValidator.forEach(field => {
      const fieldName: FormControl = field;
      if (fieldName) {
        fieldName.clearValidators();
        fieldName.updateValueAndValidity();
      }
    });
  }

  // maxDateValidator(minDate: any): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: boolean } | null => {
  //    // console.log("$$$$ service",control.value, currentDate)
  //       if (control.value !== undefined && ( control.value > minDate)) {
  //           return { 'maxDateError': true };
  //       }
  //       return null;
  //   };
  // }
}
