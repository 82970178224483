import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
 import * as es6printJS from "print-js";
import { stepStatus } from '../../../../data/constants/step-status';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';


@Component({
  selector: 'app-rfe-print-response',
  templateUrl: './rfe-print-response.component.html',
  styleUrls: ['./rfe-print-response.component.scss']
})
export class RfePrintResponseComponent implements OnInit {
  @Input() rfeTimelineModalInput;
  pdfSrc;
  pdfTimeStamp;
  SubTaskOverView;
  userType;  
  disableFields;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private store: Store<fromTask.State>,private petitionerService:PetitionerService,private rfeService:RfeService,private toastr: ToastrService) {
    this.userType = sessionStorage.getItem('userTypeRole');
   }

  ngOnInit() {
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      if(this.SubTaskOverView.taskStepDetails[6].stepStatusDetails.stepStatusCode == 'COMPLETE')
        this.disableFields = true;
      else
        this.disableFields = false;
    });
    //this.generateResponse();
  }

  generateResponse(){
    this.notify.emit(stepStatus.Inprogress);
    this.rfeService.generatePdf(this.SubTaskOverView['id'], 0).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
        this.rfeService.getGeneratedPdf(this.SubTaskOverView['id'], 0).subscribe((data: any) => {
          if (data) {
            let dataSlice = data["path"].split('/var/imagility/uploads/');
            this.pdfSrc = environment.docs + dataSlice[1];
            this.pdfTimeStamp = data["createdDate"];
          }
        });
      } 
    });
    
  }

  printResponse(){
    //this.changeStatusToComplete();
     es6printJS({printable:this.pdfSrc, type:'pdf', showModal:true});
  }

  changeStatusToComplete(){
    this.notify.emit(stepStatus.Complete);
  }

}
