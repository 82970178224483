import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://accounts.google.com',
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/index.html',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  useSilentRefresh: true,
  clientId: '86761000249-k15m3k54ocup0c1s6jps1qc4kr5epo8t.apps.googleusercontent.com',
  scope: 'openid profile email https://www.googleapis.com/auth/calendar',
  showDebugInformation: true,
  // customQueryParams:{access_type:'offline',prompt:'consent'},
  // dummyClientSecret:"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",
};

export interface UserInfo {
  info: {
    sub: string
    email: string,
    name: string,
    picture: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  gmail = 'https://gmail.googleapis.com'
  calendarList = 'https://www.googleapis.com/calendar/v3/users/me/calendarList'

  userProfileSubject = new Subject<UserInfo>();
  emailArrSubscription$ = new Subject();
  googleMessageType: string;
  selectedEmailId: any;
  selectedReplyType: any;
  selectedUsername: any;
  preservedMsgTypeForDraft: string;
  userInfo: object;

  constructor(private readonly oAuthService: OAuthService,
    private handler: HttpBackend,
    private readonly httpClient: HttpClient) {
    this.httpClient = new HttpClient(handler);
    oAuthService.configure(authCodeFlowConfig);
    oAuthService.setupAutomaticSilentRefresh();
    oAuthService.logoutUrl = "https://www.google.com/accounts/Logout";
    oAuthService.postLogoutRedirectUri = window.origin + '/attorney-landing/dashboard/attorney-dashboard/scheduling';

    this.oAuthService.setupAutomaticSilentRefresh();


    oAuthService.loadDiscoveryDocument().then(() => {
      if (oAuthService.hasValidAccessToken()) {
        oAuthService.loadUserProfile().then((userProfile) => {
          this.userInfo = userProfile;
          this.userProfileSubject.next(userProfile as UserInfo)
        }).catch(error => {
          if (error.error === "invalid_request") {
            this.login().then(() => { });
          }
        })
      }
    }).catch(error => {
      if (error.error.error.status === "UNAUTHENTICATED") {
        this.login().then(() => { });
      }
    })
    oAuthService.events.subscribe((event) => {
      if (event instanceof OAuthSuccessEvent) {
        // console.log(event);
      }
    })
  }

  getUserProfile(): Promise<any> {
    // this.oAuthService.refreshToken();
    return this.oAuthService.loadDiscoveryDocument().then(() => {
      if (this.oAuthService.hasValidAccessToken()) {
        return this.oAuthService.loadUserProfile().then((userProfile) => {
          this.userInfo = userProfile;
          this.userProfileSubject.next(userProfile as UserInfo);
          return userProfile;
        }).catch(error => {
          if (error.error === "invalid_request") {
            this.login().then(() => { });
          }
        })
      }
    })
  }

  async login(): Promise<any> {


    await this.oAuthService.initImplicitFlowInPopup().then(async () => {
      await this.oAuthService.loadUserProfile().then((userProfile) => {
        this.userInfo = userProfile;
        this.userProfileSubject.next(userProfile as UserInfo)
      })
    })
  }

  emails(userId: string): Observable<any> {
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/messages`, { headers: this.authHeader() })
  }
  getGoogleCalColors(): Observable<any> {
    return this.httpClient.get(`https://www.googleapis.com/calendar/v3/colors`, { headers: this.authHeader() })
  }

  getMail(userId: string, mailId: string): Observable<any> {
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/messages/${mailId}`, { headers: this.authHeader() })
  }
  getCals(): Observable<any> {
    return this.httpClient.get(`${this.calendarList}`, { headers: this.authHeader() })
  }
  getEvents(calId: string): Observable<any> {
    calId = encodeURIComponent(calId);
    return this.httpClient.get(`https://www.googleapis.com/calendar/v3/calendars/${calId}/events`, { headers: this.authHeader() })
  }
  getEventById(calId: string, eventId: string): Observable<any> {
    calId = encodeURIComponent(calId);
    eventId = encodeURIComponent(eventId);
    return this.httpClient.get(`https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${eventId}`, { headers: this.authHeader() })
  }
  deleteEventById(calId: string, eventId: string): Observable<any> {
    calId = encodeURIComponent(calId);
    eventId = encodeURIComponent(eventId);
    return this.httpClient.delete(`https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${eventId}`, { headers: this.authHeader() })
  }

  createEvent(calendarId, obj): Observable<any> {
    calendarId = encodeURIComponent(calendarId);
    return this.httpClient.post(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?sendUpdates=all`, obj, { headers: this.authHeader() })
  }
  patchEventById(calId: string, eventId: string, obj): Observable<any> {
    calId = encodeURIComponent(calId);
    eventId = encodeURIComponent(eventId);
    return this.httpClient.patch(`https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${eventId}?sendUpdates=all&sendNotifications=true`, obj, { headers: this.authHeader() })
  }

  isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken()
  }

  signOut() {
    this.oAuthService.logOut(true)

    // this.oAuthService.revokeTokenAndLogout({
    //   client_id: this.oAuthService.clientId,
    //   returnTo: this.oAuthService.redirectUri
    // }, true);
  }

  getGmailThreads(label: string, userId: string, subject: string, maxResult: number, nextToken: string) {
    let nextTokenStr = '';
    if (nextToken) {
      nextTokenStr = `pageToken=${nextToken}&`;
    }
    //maxResults=${maxResult} before &q
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/threads?${nextTokenStr}maxResults=${maxResult}&q=subject=${subject}&labelIds=${label}`, { headers: this.authHeader() })
  }

  // getMessageCount(label:string,userId:string,subject:string){
  //   return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/labels/${label}&q=subject=${subject}`, { headers: this.authHeader() })
  // }

  getGmailEmailsOnId(messageId: string, userId: string) {
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/messages/${messageId}`, { headers: this.authHeader() })
  }

  getGmailThreadOnId(threadId: string, userId: string, labelIds: string) {
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/threads/${threadId}?labelIds=${labelIds}`, { headers: this.authHeader() })
  }

  async getGmailAttachment(messageId: string, useId: string, attachmentId: string) {
    const result = await this.httpClient.get(`${this.gmail}/gmail/v1/users/${useId}/messages/${messageId}/attachments/${atob(attachmentId)}`, { headers: this.authHeader() }).toPromise()
    return result;
  }

  deleteEmailPermanently(useId: string, threadId: string) {
    return this.httpClient.delete(`${this.gmail}/gmail/v1/users/${useId}/threads/${threadId}`, { headers: this.authHeader(), observe: 'response' })
  }

  moveEmailToTrash(useId: string, threadId: string) {
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/threads/${threadId}/trash`, {}, { headers: this.authHeader(), observe: 'response' })
  }

  moveMessageToTrash(useId: string, messageId: string) {
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/messages/${messageId}/trash`, {}, { headers: this.authHeader(), observe: 'response' })
  }

  SendEmail(useId: string, content: any) {
    this.authHeader().append('Content-Type', 'text/html');
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/messages/send`, content, { headers: this.authHeader(), observe: 'response' })
  }

  SaveEmail(useId: string, content: any) {
    this.authHeader().append('Content-Type', 'text/html');
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/drafts`, content, { headers: this.authHeader(), observe: 'response' })
  }
  // SaveEmailWithAttachment(useId:string,content:any){
  //   this.authHeader().append('Content-Type', 'text/html');
  //   return this.httpClient.post(`${this.gmail}/upload/gmail/v1/users/${useId}/drafts`,content, { headers: this.authHeader(),observe: 'response' })
  // }

  markEmailRead(useId: string, id: string, message: any) {
    this.authHeader().append('Content-Type', 'text/html');
    this.authHeader().append('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/messages/${id}/modify`, message, { headers: this.authHeader(), observe: 'response' })
  }

  createLabel(useId: string, content: any) {
    this.authHeader().append('Content-Type', 'text/html');
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/labels`, content, { headers: this.authHeader(), observe: 'response' })
  }

  getFolders(userId: string) {
    return this.httpClient.get(`${this.gmail}/gmail/v1/users/${userId}/labels`, { headers: this.authHeader() })
  }

  moveThreads(requestBody: any, useId: string, threadId: string) {
    return this.httpClient.post(`${this.gmail}/gmail/v1/users/${useId}/threads/${threadId}/modify`, requestBody, { headers: this.authHeader(), observe: 'response' })
  }

  private authHeader(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.oAuthService.getAccessToken()}`
    })
  }

  // saveGoogleIntegrationData(reqJson){
  //   return this.httpClient.post(`/calendarintegration/createorupdate`, reqJson).pipe(
  //     map((response: any) => {
  //       return response;
  //     })
  //   );

  // }
}
