<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-10 step-header">
            <h4>Steps Time Configuration</h4>
            <div class="mat-subheading-1 titleColor">Step-up for each timeline steps and manage</div>
        </div>
        <div class="col-md-2 pl-0">
            <div class="task-btn" >
                <button pButton type="button" (click)="routeToCreateConfigure()"
                 class="im-btn button_whitebg im-btn-with-icon im-btn-outline-primary">
                    <div class="d-flex">
                        <span>CONFIGURE TIME</span>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <p-divider class="pb-15"></p-divider>
    <div class="row tab-container">
        <div class="col-2 tab-label tab-height activeTimeLine">
            <div class="lbl-text"> Time Configured({{ timeConfiguredRecordCount }})</div>
        </div>
    </div>

    <div class="mat-elevation-z8">
        <p-table [columns]="stepsTypeCols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
            styleClass="p-datatable-gridlines" [value]="stepConfigurList" dataKey="id"
            *ngIf="stepConfigurList?.length > 0">
            <ng-template pTemplate="header" let-columns>
                <tr class="text-uppercase">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                    <th>Configured On </th>
                    <th>Action </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-expanded="expanded" let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <span>
                            {{(row[col.field]) ? row[col.field] : '--'}}
                        </span>
                    </td>
                    <td><span>{{row.configuredOn | date:'MMM d, y h:mm a'}}</span></td>
                    <td>
                        <div class="row">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editStepsTimeConfigured(row)">
                                    Edit Steps Time Configuration</button>
                                <button mat-menu-item (click)="deleteStepsTimeConfigured(row)">
                                    Delete Steps Time Configuration</button>
                            </mat-menu>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="stepConfigurList?.length == 0" class="mt-4 text-center">No Data</div>
        <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event)"
            *ngIf="stepConfigurList?.length > 0" [totalRecords]="totalElementCount"
            [rowsPerPageOptions]="pageSizeOptions" length="totalElementCount">
        </p-paginator>
    </div>

</div>