import { Action } from '@ngrx/store';

export const SET_FBFORMTYPE = '[FB] SET_FBFORMTYPE';
export const SET_ISFORMSBUILDERLOGIN = '[FB] SET_ISFORMSBUILDERLOGIN';
export const SET_ISFORMSBUILDERPREVIEW = '[FB] SET_ISFORMSBUILDERPREVIEW';
export const SET_FORMSBUILDERMODULEID = '[FB] SET_FORMSBUILDERMODULEID';
export const SET_FORMSBUILDERID = '[FB] SET_FORMSBUILDERID';
export const SET_ISBENEFICIARYPROFILE = '[FB] SET_ISBENEFICIARYPROFILE';

export class SetFBFormType implements Action {
  readonly type = SET_FBFORMTYPE;

  constructor(public payload: number) { }
}

export class SetIsFBModuleLogin implements Action {
  readonly type = SET_ISFORMSBUILDERLOGIN;

  constructor(public payload: boolean) { }
}

export class SetIsFBModulePreview implements Action {
  readonly type = SET_ISFORMSBUILDERPREVIEW;

  constructor(public payload: boolean) { }
}

export class SetFormBuilderModuleId implements Action {
  readonly type = SET_FORMSBUILDERMODULEID;

  constructor(public payload: number) { }
}

export class SetFormBuilderId implements Action {
  readonly type = SET_FORMSBUILDERID;

  constructor(public payload: number) { }
}

export class SetIsBeneficiaryProfile implements Action {
  readonly type = SET_ISBENEFICIARYPROFILE;

  constructor(public payload: boolean) { }
}

export type fbActions = SetFBFormType | SetIsFBModuleLogin | SetIsFBModulePreview |
  SetFormBuilderModuleId | SetFormBuilderId | SetIsBeneficiaryProfile;
