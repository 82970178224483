import { Component, OnInit } from '@angular/core';
import { UserRoleService } from 'src/app/services/user-role.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  content;
  constructor(private userService: UserRoleService) {
    let userType;
    switch(sessionStorage.getItem('userTypeRole')){
      case 'Petitioner': userType = 'PETNR'; break;
      case 'Beneficiary': userType = 'BEN'; break;
      case 'Attorney': userType = 'ATRNY'; break;
      default: userType = 'BEN';
    }
    this.userService.getPolicyContent(userType,'PRVCYPLCY').subscribe((data) => {
      if (data) {
        this.content = data.content;
      }
    });
   }

  ngOnInit() {
    
  }

}
