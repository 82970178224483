import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserRoleService } from 'src/app/services/user-role.service';
import { VideoTourService } from 'src/app/services/video-tour.service';

@Component({
  selector: 'app-petitioner-menu',
  templateUrl: './petitioner-menu.component.html',
  styleUrls: ['./petitioner-menu.component.scss']
})
export class PetitionerMenuComponent implements OnInit, OnDestroy {
  isAdmin = false;
  // isJdsPds = false;
  isAdminSelected = false;
  videoTourContentObj: any[] = [];
  observableSubscription$ = new Subject();



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userRoleService: UserRoleService,
    private videoTourService: VideoTourService,
    private joyride: JoyrideService
  ) {
    this.videoTourService.videoTourStarted$
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(value => {
        if (value === 'Petitioner') {
          this.startVideoTour();
        }
      });
  }

  ngOnInit(): void {


    // this.router.navigate(['dashboard'], { relativeTo: this.route });
    this.userRoleService.isUserTypeAdmin.next(false);
    this.authenticationService.currentUser
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data) => {
        if (data) {
          data.permissions.forEach(el => {
            if (el === 'ISADMIN') {
              this.isAdmin = true;
              // IM-3554-Petitioner Admin - Menu - If the petitioner is Admin, show the Admin menu while logging in.
              this.isAdminSelected = true;
            }
          });
          if (!sessionStorage.getItem('selectedCompanyId')) { // IM4818 // fix:listing associated companies are affected
            sessionStorage.setItem('selectedCompanyId', data.companyId);
          }
        }
      });

    this.videoTourContentObj = [
      {
        'title': 'Start with Creating Beneficiaries ',
        'headerOne': "Petitions are filed on behalf of Beneficiaries,by Petitioners.Use the <b>Create New</b> button to create a new beneficiary,fill in details and <b>Save</b>.",
        'image': '/assets/images/video-tour/Petitioner_Start_with_Creating_Beneficiaries.svg',
        'videoLink': '',
        'nextButtonText': 'Learn how to create a <b>New Task/Petition </b>',
        'watchLabel': ''

      },
      {
        'title': 'Create a New Task',
        'headerOne': "To create a petition, you need to create a task. This can be done through the <strong>Dashboard</strong> or <strong>Tasks Menu.</strong> Use the <strong>Create New Task</strong> button to create a new task,fill in details and <strong>Save.</strong>",
        'image': '/assets/images/video-tour/Petitioner_Create_New_Task.svg',
        'videoLink': 'How to create Tasks',
        'nextButtonText': 'Learn how to <b>Create Lawfirm Connections</b>',
        'watchLabel': ''

      },
      {
        'title': 'Create Lawfirm Connections',
        'headerOne': "If you want to onboard your Lawfirm to create petitions, you need to add Lawfirms to your account. Use the <strong>Add Connection</strong> button to create a new Lawfirm Connection, fill in details and <strong>Save.</strong>",
        'image': '/assets/images/video-tour/Petitioner_Create_Attorney_Connections.svg',
        'videoLink': 'Next steps after Tasks creation',
        'nextButtonText': 'To continue with the tour',
        'watchLabel': ''

      },
      {
        'title': 'Back to Dashboard',
        'headerOne': "This contains a snapshot of all activites related to your account: <strong>Petitioner Information, Platform Statistics, Notifications, Tasks </strong>etc.",
        'image': '',
        'videoLink': 'Part 1: How to build a Petition',
        'nextButtonText': 'Our tour ends here!',
        'watchLabel': 'to start building a petition!'

      }
    ];

  }

  startVideoTour() {
    this.joyride.startTour(
      { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep'] }
    )

  }
  adminPetitioner(isadminselected: boolean) {
    if (this.isAdmin) {
      this.isAdminSelected = isadminselected;
      this.userRoleService.isUserTypeAdmin.next(this.isAdminSelected);
    }
  }
  // isOpen;
  routeToResourcePage() {
    this.joyride.closeTour();
    let resourceLink;
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType == 'Petitioner') {
      resourceLink = '/petitioner-landing/resources';
    } else if (userType == 'Beneficiary') {
      resourceLink = '/beneficiary-landing/resources';
    } else if (userType == 'Attorney') {
      resourceLink = '/attorney-landing/resources';
    }
    this.router.navigate([resourceLink]);
  }


  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.videoTourService.videoTourStarted$.next(false);
  }



}
