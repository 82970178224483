import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {
  @Input() invoiceId: number;
  @Input() title: string;

  viewInvoiceData: any;
  attorneysignsrc = "";
  businessLogosrc = "";
  //invoiceId: number;

  constructor(
    private router: Router,
    private billinginvoiceservice: BillingInvoiceService
  ) {
    // console.log(this.invoiceId, "$$$$$");
  }

  ngOnInit(): void {
    this.getInvoicePreviewDetails();
  }

  getInvoicePreviewDetails() {
    // if(history) {
    // this.invoiceId = history.state.invoiceId;
    // }
    // else{
    this.invoiceId = this.invoiceId;

    // console.log("id", this.invoiceId);
    this.billinginvoiceservice
      .getPreviewInvoiceDetails(this.invoiceId)
      .subscribe((res) => {
        // console.log("preview", res);
        this.viewInvoiceData = res;
        this.attorneysignsrc = this.viewInvoiceData.signImage
        ? "data:image/jpeg;base64," + this.viewInvoiceData.signImage
        : null;
        this.businessLogosrc = this.viewInvoiceData.billBycompanyLogo
        ? "data:image/jpeg;base64," + this.viewInvoiceData.billBycompanyLogo
        : null;
        // this.businessLogosrc = null;
        // this.attorneysignsrc = `data:image/jpeg;base64,${this.viewInvoiceData.signImage}`;
        // this.businessLogosrc = `data:image/jpeg;base64,${this.viewInvoiceData.billBycompanyLogo}`;
      });
  }
  backtoInvoice() {
    this.router.navigate([
      "attorney-landing/attorney-invoice/generate-invoice",
    ]);
  }
}
