<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-11 step-title p-0">
      <h5 class="mb-0">Update Decision</h5>
    </div>
    <div class="col-md-1 pr-0 d-flex justify-content-end">
      <i (click)="handleClose();" class="close-icon pi pi-times"></i>
    </div>
  </div>
  <p-divider></p-divider>
  <form name="updateDecisionForm" (submit)="postDecisionDetails()" #updateDecisionForm="ngForm">
    <div class="main-body">
      <div class="row col-md-12 pl-0 pt-2">
        <div class="col-md-12 row update-p d-flex align-items-center pb-2">
          <h6 class="col-dm-12 pr-2">The Receipt number for your filed petition is : </h6> <br>
          <p class="col-dm-12">{{receiptNumber}}</p>
        </div>
        <div class="row col-md-12 p-0 pt-2">
          <div class="col-md-4 processing-dropdown">
            <label class="label-cls required">Select the response you got from {{isDs160 ? 'consulate' : 'USCIS'}}</label>
            <p-dropdown appendTo="body" placeholder="Select Response" [options]="listResponses"
              (onChange)="handleUSCISChange()" [(ngModel)]="updateDecision.decissionCode" optionLabel="name"
              optionValue="code" name="decissionCode" required>
            </p-dropdown>
            <div
              *ngIf="updateDecisionForm.controls['decissionCode'].touched && updateDecisionForm.controls['decissionCode'].invalid">
              <span class="invalid-message" *ngIf="updateDecisionForm.controls['decissionCode'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>

          <!-- Approved -->
          <div class="row col-md-8 p-0" *ngIf="updateDecision.decissionCode === 'APPROVED'">
            <div class="col-md-4 calendar-input">
              <label class="label-cls required">Notice date</label>
              <p-calendar appendTo="body" type="text" class="mw-100" id="dob" [(ngModel)]="updateDecision.outcomeDate"
                [disabled]="disabledControls" inputId="icon1" [showIcon]="true" dateFormat="mm-dd-yy"
                [minDate]="getReceiptDate" [maxDate]="currentDate" placeholder="MM/DD/YYYY" placeholder="MM/DD/YYYY"
                name="outcomeDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050" required>
              </p-calendar>
              <div
                *ngIf="updateDecisionForm.controls['outcomeDate'].touched && updateDecisionForm.controls['outcomeDate'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeDate'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <ng-template [ngIf]="primaryData.caseType !== 'I130' && primaryData?.visatype !== 'ASYLUM'">
              <div class="col-md-4 calendar-input">
                <label class="label-cls required">Validity From</label>
                <p-calendar appendTo="body" type="text" class="mw-100" id="dob"
                  [(ngModel)]="updateDecision.validityStartDate" [disabled]="disabledControls" inputId="icon2"
                  [showIcon]="true" dateFormat="mm-dd-yy" [minDate]="getReceiptDate" placeholder="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY" name="validityStartDate" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="1910:2050" required>
                </p-calendar>
                <div
                  *ngIf="updateDecisionForm.controls['validityStartDate'].touched && updateDecisionForm.controls['validityStartDate'].invalid">
                  <span class="invalid-message"
                    *ngIf="updateDecisionForm.controls['validityStartDate'].errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-4 calendar-input">
                <label class="label-cls required">Validity To</label>
                <p-calendar appendTo="body" type="text" class="mw-100" id="dob"
                  [(ngModel)]="updateDecision.validityEndDate"
                  [disabled]="disabledControls || !updateDecisionForm.controls['validityStartDate'].value"
                  inputId="icon3" [showIcon]="true" dateFormat="mm-dd-yy" [minDate]="updateDecision.validityStartDate"
                  placeholder="MM/DD/YYYY" placeholder="MM/DD/YYYY" name="validityEndDate" [monthNavigator]="true"
                  [yearNavigator]="true" yearRange="1910:2050" required>
                </p-calendar>
                <div
                  *ngIf="updateDecisionForm.controls['validityEndDate'].touched && updateDecisionForm.controls['validityEndDate'].invalid">
                  <span class="invalid-message" *ngIf="updateDecisionForm.controls['validityEndDate'].errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
            </ng-template>

            <!-- <div class="col-md-6">
                          <label class="label-cls">Beneficiary Confirmation Number</label>
                          <input type="text" class="im-input mw-100" pInputText
                              [(ngModel)]="updateDecision.beneficiaryConfirmationNumber"
                              name="beneficiaryConfirmationNumber" [readonly]="disabledControls"
                              placeholder="Enter Beneficiary Confirmation Number" />
                      </div> -->
          </div>

          <!-- Denied -->
          <div class="row col-md-8 p-0" *ngIf="updateDecision.decissionCode === 'DENIED'">
            <div class="col-md-6 calendar-input">
              <label class="label-cls required">Notice date</label>
              <p-calendar appendTo="body" type="text" class="mw-100" id="dob" [(ngModel)]="updateDecision.outcomeDate"
                [maxDate]="noticeMaxDate" name="outcomeDate" [disabled]="disabledControls" inputId="icon4"
                [showIcon]="true" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="1910:2050" required>
              </p-calendar>
              <div
                *ngIf="updateDecisionForm.controls['outcomeDate'].touched && updateDecisionForm.controls['outcomeDate'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeDate'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <label class="label-cls required">Denial Reason</label>
              <input type="text" class="im-input mw-100" [(ngModel)]="updateDecision.outcomeReason" pInputText
                name="outcomeReason" [readonly]="disabledControls" placeholder="Enter Denial Reason" required />
              <div
                *ngIf="updateDecisionForm.controls['outcomeReason'].touched && updateDecisionForm.controls['outcomeReason'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeReason'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
          </div>

          <!-- Withdrawn -->
          <div class="row col-md-8 p-0" *ngIf="updateDecision.decissionCode === 'WITHDRAWN'">
            <div class="col-md-6 calendar-input">
              <label class="label-cls required">Notice date</label>
              <p-calendar appendTo="body" type="text" class="mw-100" id="dob" [(ngModel)]="updateDecision.outcomeDate"
                name="outcomeDate" [disabled]="disabledControls" inputId="icon5" [showIcon]="true"
                [maxDate]="noticeMaxDate" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="1910:2050" required>
              </p-calendar>
              <div
                *ngIf="updateDecisionForm.controls['outcomeDate'].touched && updateDecisionForm.controls['outcomeDate'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeDate'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <label class="label-cls required">Withdrawn Reason</label>
              <input type="text" class="im-input mw-100" [(ngModel)]="updateDecision.outcomeReason" pInputText
                name="outcomeReason" [readonly]="disabledControls" placeholder="Enter Withdrawn Reason" required />
              <div
                *ngIf="updateDecisionForm.controls['outcomeReason'].touched && updateDecisionForm.controls['outcomeReason'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeReason'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
          </div>

          <!-- RFE Issues -->
          <div class="row col-md-8 p-0" *ngIf="updateDecision.decissionCode === 'RFEISS'">
            <div class="col-md-6 calendar-input">
              <label class="label-cls required">Notice date</label>
              <p-calendar appendTo="body" type="text" class="mw-100" id="dob" [(ngModel)]="updateDecision.outcomeDate"
                [maxDate]="noticeMaxDate" name="outcomeDate" [disabled]="disabledControls"
                (onSelect)="setMinRFEResponseDueDate()" inputId="icon6" [showIcon]="true" dateFormat="mm/dd/yy"
                placeholder="MM/DD/YYYY" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050" required>
              </p-calendar>
              <div
                *ngIf="updateDecisionForm.controls['outcomeDate'].touched && updateDecisionForm.controls['outcomeDate'].invalid">
                <span class="invalid-message" *ngIf="updateDecisionForm.controls['outcomeDate'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-6 calendar-input">
              <label class="label-cls required">RFE Response Due Date</label>
              <p-calendar appendTo="body" type="text" class="mw-100" id="dob"
                [(ngModel)]="updateDecision.rfeResponseDueDate" [minDate]="minRFEResponseDueDate"
                name="rfeResponseDueDate" [disabled]="disabledControls" inputId="icon7" [showIcon]="true"
                dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="1910:2050" required>
              </p-calendar>
              <div
                *ngIf="updateDecisionForm.controls['rfeResponseDueDate'].touched && updateDecisionForm.controls['rfeResponseDueDate'].invalid">
                <span class="invalid-message"
                  *ngIf="updateDecisionForm.controls['rfeResponseDueDate'].errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- File upload -->
      <div class="row col-md-12 pl-0 pt-4">
        <!-- <i140-file-upload style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
        </i140-file-upload> -->
        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
        </file-upload-library>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row pl-0 pt-3 d-flex align-items-center">
      <div class="col-md-3 decision-check">
        <p-checkbox value="Notify Beneficiary" binary="true" [disabled]="updateDecision.decissionCode == null"
          [(ngModel)]="updateDecision.isNotifyBeneficiary" name="isNotifyBeneficiary" label="Notify Beneficiary">
        </p-checkbox>
      </div>
      <div class="col-md-3 decision-check">
        <p-checkbox value="Notify Petitioner" binary="true" [disabled]="updateDecision.decissionCode == null"
          [(ngModel)]="updateDecision.isNotifyPetitioner" name="isNotifyPetitioner" label="Notify Petitioner"
          *ngIf="userType === 'Attorney' && primaryData.caseType !== 'I130'"></p-checkbox>
      </div>
      <div class="col-md-6 pull-right">
        <button pButton type="submit" label="Save" title="Save" icon="pi pi-save" class="pull-right"
          [ngClass]="updateDecisionForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="updateDecisionForm.invalid  || disabledControls"></button>
      </div>
    </div>
  </form>
</div>