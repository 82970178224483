<div class=" mt-2">
    <div class="mt-3">
        <div>
            <form [formGroup]="courtDateForm">
                <div class="row">
                    <div class="col-4 nopadding">
                        <h5 class='bolder'>Set Court Date Reminder</h5>
                    </div>
                    <div class="col-lg-8">
                        <div class="pull-right">
                            <p-radioButton class='mr-1' name="groupname" value="1" label="public"
                                formControlName='isPublic'>
                            </p-radioButton>
                            <p-radioButton name="groupname" value="0" label='private' formControlName='isPublic'>
                            </p-radioButton>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label for="title" class="required">Court date for? </label>
                    <input id="title" type="text" formControlName="title" />
                    <small *ngIf="courtDateForm.get('title').touched && courtDateForm.get('title').errors"
                        class="p-error">
                        <span *ngIf="courtDateForm.get('title').errors['required']">Required</span>
                    </small>
                </div>
                <div class="row">
                    <div class="col-6 nopadding">
                        <label for="loc">Court Location</label>
                        <input id="loc" type="text" formControlName="location" />
                    </div>
                    <div class="col-6">
                        <label for="judgename">Judges name</label>
                        <input id="judgename" type="text" formControlName="judgeName" />
                    </div>
                </div>
                <div class="row mt-4 mb-3 divider"></div>
                <div class="row mt-3">
                    <p class="label-cls"> Time and duration</p>
                </div>
                <div class="row mt-3">
                    <h6 class='bolder'>Time Zone: EST ( Eastern time zone - USA )</h6>
                </div>
                <div class="row  mt-3"></div>
                <div class="row">
                    <div class="col-2 col-lg-2 nopadding">
                        <label for='startDate' class="required"> Start Date</label>
                        <p-calendar #sd formControlName="startDate" name="date" styleClass="p-form-datepicker"
                            dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true" [monthNavigator]="true"
                            [minDate]="minimumDate" [yearNavigator]="true" (onSelect)="checkEndDateTouched(ed,sd)"
                            yearRange="2000:2099">
                        </p-calendar>
                        <small *ngIf="courtDateForm.get('startDate').touched && courtDateForm.get('startDate').errors"
                            class="p-error">
                            <span *ngIf="courtDateForm.get('startDate').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-2 col-lg-2 ml-3 nopadding" style="padding-left:10px !important">
                        <label class="required">Start Time</label>
                        <input type="time" #st formControlName="startTime" (onSelect)="checkEndTimeTouched(et,st)"
                            (blur)="checkEndTimeTouched(et,st)" />
                        <small *ngIf="courtDateForm.get('startTime').touched && courtDateForm.get('startTime').errors"
                            class="p-error">
                            <span *ngIf="courtDateForm.get('startTime').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-1 col-lg-1 mt-3 text-center">
                        <label for=""></label>
                        <div>
                            <i class="pi pi-arrow-right arrow"></i>
                        </div>
                    </div>
                    <div class="col-2 col-lg-2 nopadding" style="padding-right:10px !important">
                        <label class="required"> End Date</label>
                        <p-calendar #ed [disabled]='!sd.value' formControlName="endDate" name="date"
                            styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                            [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2099"
                            [minDate]="sd.value || minimumDate" (onSelect)="setEndDate(ed.value)">
                        </p-calendar>
                        <small *ngIf="courtDateForm.get('endDate').touched && courtDateForm.get('endDate').errors"
                            class="p-error">
                            <span *ngIf="courtDateForm.get('endDate').errors['required']">Required</span>
                        </small>
                    </div>

                    <div class="col-2 col-lg-2 nopadding">
                        <label class="required">End Time</label>
                        <input type="time" #et formControlName="endTime" (blur)="checkStartTimeExists(et,st)" />
                        <small *ngIf="courtDateForm.get('endTime').touched && courtDateForm.get('endTime').errors"
                            class="p-error">
                            <span *ngIf="courtDateForm.get('endTime').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div class="row mt-4 mb-3 divider"></div>
                <div class="row mt-4">
                    <h6 class='bolder'>Details</h6>
                </div>
                <div class="row mt-2">
                    <div class="col-12 nopadding">
                        <label for="">Description/Agenda</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                            formControlName="description"></textarea>
                    </div>
                </div>

                <!-- {{ this.courtDateForm.value }} -->
                <div class="row mt-5 mb-5">
                    <button *ngIf='!courtDateObj' type="button" [disabled]="!courtDateForm.valid"
                        class="btn btn-primary" (click)="save()">
                        Save
                    </button>

                    <button *ngIf='courtDateObj' type="button" [disabled]="!courtDateForm.valid"
                        class="btn btn-primary ml-2" (click)="save()">
                        Update
                    </button>
                    <button *ngIf='courtDateObj' type="button" [disabled]="!courtDateForm.valid"
                        class="btn btn-primary ml-2" (click)="cancelCourtdate()">
                        Cancel CourtDate
                    </button>
                    <button *ngIf='!courtDateObj' type="button" class="btn btn-light ml-2"
                        (click)="cancel()">Cancel</button>
                </div>
            </form>
        </div>

        <!-- <div>
            {{ courtDateForm.value }}
            {{ courtDateForm.status }}
            {{ courtDateForm.valid }}
            <div>
                <p>Title :: {{ courtDateForm.get("title")?.value }}</p>
                <p>Location :: {{ courtDateForm.get("location")?.value }}</p>
                <p>Location :: {{ courtDateForm.get("location")?.value?.name }}</p>
                <p>judgeName :: {{ courtDateForm.get("judgeName")?.value }}</p>
                <p>startDate :: {{ courtDateForm.get("startDate")?.value }}</p>
                <p>startTime :: {{ courtDateForm.get("startTime")?.value }}</p>
                <p>endDate :: {{ courtDateForm.get("endDate")?.value }}</p>
                <p>endTime :: {{ courtDateForm.get("endTime")?.value }}</p>
                <p>description :: {{ courtDateForm.get("description")?.value }}</p>
            </div>
        </div> -->
    </div>
</div>