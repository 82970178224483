import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';

@Injectable()
export class CountryApiService {

    constructor(private http: HttpClient) {
    }

    getStates(countryCode: string) {
        return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status) {
                    return response.data;
                }
            })
        );
    }

}
