<table class="details-table" mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="petionerDetails">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell class="no-border" *matCellDef="let row">
      <div class="request-container">
        <div class="row">
          <div class="col-10 boldHead">
            <p>My Petitioner Details</p>
            <label class="companyname">{{ row.companyName }}</label>
            <p>Address</p>
            <label>{{ row.companyAddress }}</label>
            <p>Phone No</p>
            <div *ngIf="row.phone === null; else showPhone">
              <label >N.A</label>
            </div>
            <ng-template #showPhone>
              <label> {{ row.phone }} </label> 
            </ng-template>
            <p>Email ID</p>
            <label>{{ row.email }}</label>
          </div>
          <div class="d-flex justify-content-center align-items-center img-div">
            <img src="/assets/images/beneficiary-icon.png" />
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  class="align-paginator"
  [ngClass]="{ 'hide-paginator': petionerDetails?.length <= 0 }"
  [pageSizeOptions]="[1]"
></mat-paginator>
<mat-divider
  class="divider"
  [ngClass]="{ 'hide-paginator': petionerDetails?.length <= 0 }"
></mat-divider>
