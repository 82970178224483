import { AuthenticationService } from './authentication.service';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ReferenceLibraryService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  public requestDataFromMultipleSources(): Observable<any[]> {
    let response1 = this.http.get(`/referenceLibrary/ADDR`);
    let response2 = this.http.get(`/referenceLibrary/MARSTAT`);
    let response3 = this.http.get(`/referenceLibrary/PHON`);
    let response4 = this.http.get(`/referenceLibrary/EMLTYP`);
    let response5 = this.http.get(`/referenceLibrary/GENDER`);
    let response6 = this.http.get(`/referenceLibrary/TITL`);
    let response7 = this.http.get(`/referenceLibrary/MARSTAT`);
    let response8 = this.http.get(`/referenceLibrary/RACE`);
    let response9 = this.http.get(`/referenceLibrary/FAMRELTYP`);
    let response10 = this.http.get(`/referenceLibrary/CTZNACQRTYP`);
    let response11 = this.http.get(`/referenceLibrary/ETHNICITY`);
    return forkJoin([response1, response2, response3,response4, response5, response6,response7, response8, response9,response10, response11]);
  }

  getReferenceData(referenceCode: string): Observable<any> {
    if (this.authenticationService.currentUserValue) {
      return this.http.get(`/referenceLibrary/${referenceCode}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    } else {
      return EMPTY;
    }
  }

  getReferenceDataWithoutAuthentication(referenceCode: string): Observable<any> {
      return this.http.get(`/referenceLibrary/${referenceCode}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
  }

  getGroupReferenceData(groupReferenceCode: string): Observable<any> {
    return this.http.get(`/referenceLibrary/${groupReferenceCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  postReferenceData(payload): Observable<any> {
    if (this.authenticationService.currentUserValue) {
      return this.http.post(`/referenceLibrary/`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    } else {
      return EMPTY;
    }
  }

  getReferencePaymentModeData(referenceCode: string): Observable<any> {
    if (this.authenticationService.currentUserValue) {
      return this.http.get(`/referenceLibrary/${referenceCode}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    } else {
      return EMPTY;
    }
  }
}
