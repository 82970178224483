<p-card>
  <h5 class="p-text-bold">
    Enter details to set up time for each timeline step.
  </h5>
  <!-- <app-create-task [isConfigureTimeList]="true"></app-create-task> -->
  <form [formGroup]="congigureTimeForm">
    <div class="p-fluid p-formgrid p-grid px-3 pt-2">
      <div class="p-col-4">
        <label for="tasktype" class="create-task-label required">Task Type</label>
        <p-dropdown inputId="tasktype" styleClass="redBorder"
          name="superTaskTypeCode"
          [options]="taskTypeList"
          formControlName="superTaskTypeCode"
          placeholder="Select Task Type" 
          optionLabel="name"
          optionValue="code"
          (onChange)="getVisaType()"
          [disabled]="disabledStepTask"
        ></p-dropdown>
        <span
          class="invalid-message"
          *ngIf="
            congigureTimeForm.get('superTaskTypeCode').touched &&
            congigureTimeForm.get('superTaskTypeCode').errors?.required
          "
        >
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <div class="p-col-4">
        <div *ngIf="!isImmigrant">
          <label for="visatype" class="create-task-label required"
            >Visa Type</label
          >
          <p-dropdown
            inputId="visatype"
            [options]="visaTypes"
            formControlName="visaType"
            optionLabel="name"
            name="visatype"
            [disabled]="
              !congigureTimeForm.get('superTaskTypeCode').value ||
              disabledStepTask
            "
            optionValue="code"
            (onChange)="getPetitionType()"
            placeholder="Select Visa Type"
          >
          </p-dropdown>
          <span
            class="invalid-message"
            *ngIf="
              congigureTimeForm.get('visaType').touched &&
              congigureTimeForm.get('visaType').errors?.required
            "
          >
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
        </div>
        <div *ngIf="isImmigrant">
          <label for="immCategoryCode" class="create-task-label required"
            >Immigration Category</label
          >
          <p-dropdown
            inputId="immCategoryCode"
            [options]="visaTypes"
            formControlName="immCategoryCode"
            (onChange)="getImmigrationClassification()"
            [disabled]="
              !congigureTimeForm.get('superTaskTypeCode').value ||
              disabledStepTask
            "
            optionLabel="name"
            name="immCategoryCode"
            optionValue="code"
            placeholder="Select Immigration Category"
          >
          </p-dropdown>
          <span
            class="invalid-message"
            *ngIf="
              congigureTimeForm.get('immCategoryCode').touched &&
              congigureTimeForm.get('immCategoryCode').errors?.required
            "
          >
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
        </div>
      </div>
      <div class="p-col-4">
        <div *ngIf="!isImmigrant">
          <label for="petitiontype" class="create-task-label required"
            >Petition Type</label
          >
          <p-dropdown
            inputId="petitiontype"
            [options]="petitionTypes"
            formControlName="petitionType"
            name="pettype"
            [disabled]="
              !congigureTimeForm.get('visaType').value || disabledStepTask
            "
            optionLabel="name"
            optionValue="code"
            (onChange)="getSubTaskType()"
            placeholder="Select Petition Type"
          >
          </p-dropdown>
          <span
            class="invalid-message"
            *ngIf="
              congigureTimeForm.get('petitionType').touched &&
              congigureTimeForm.get('petitionType').errors?.required
            "
          >
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
        </div>
        <!-- <div
          *ngIf="
            isImmigrant &&
            (!congigureTimeForm.get('immCategoryCode').value ||
              (congigureTimeForm.get('immCategoryCode').value &&
                congigureTimeForm.get('immCategoryCode').value !== 'GCFLY'))
          "
        > -->
        <div
          *ngIf="
            isImmigrant &&
            (!congigureTimeForm.get('immCategoryCode').value ||
              (congigureTimeForm.get('immCategoryCode').value))
          "
        >
          <label for="immClassificationCode" class="create-task-label required"
            >Immigration Classification</label
          >
         <!-- <div class="">
          <label for="immClassificationValue" class="create-task-label hardCodedCls"
          >EB-1c/EB-2c/EB-3c</label>
         </div> -->
          <p-dropdown
            inputId="immClassificationCode"
            [options]="petitionTypes"
            formControlName="immClassificationCode"
            name="pettype"
            optionLabel="name"
            optionValue="code"
            [disabled]="
              !congigureTimeForm.get('immCategoryCode').value ||
              disabledStepTask
            "
            (onChange)="onChangeImmigrationClassification()"
            placeholder="Select Immigration Classification"
          >
          </p-dropdown>
          <span
            class="invalid-message"
            *ngIf="
              congigureTimeForm.get('immClassificationCode').touched &&
              congigureTimeForm.get('immClassificationCode').errors?.required
            "
          >
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
        </div>
      </div>
      <div class="p-col-4 mt-3" *ngIf="userType === 'Petitioner'">
        <label for="companyname" class="create-task-label required"
          >Company</label
        >
        <p-dropdown
          inputId="companyname"
          (onChange)="onCompanyChangeID()"
          [options]="petitionerCompanyList"
          formControlName="companyId"
          [disabled]="
            !(
              congigureTimeForm.get('petitionType').value ||
              congigureTimeForm.get('immClassificationCode').value
            ) || disabledStepTask
          "
          name="companyId"
          optionLabel="companyName"
          optionValue="id"
          placeholder="Select a Company"
        >
        </p-dropdown>
        <span
          class="invalid-message"
          *ngIf="
            congigureTimeForm.get('companyId').touched &&
            congigureTimeForm.get('companyId').errors?.companyIsRequired
          "
        >
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <div
        class="p-col-4 mt-3"
        *ngIf="
          userType === 'Attorney' && congigureTimeForm.get('immCategoryCode').value !== 'GCFLY' && congigureTimeForm.get('immCategoryCode').value !== 'ASYLUM'
        "
      >
        <label for="companyname" class="create-task-label required"
          >Client Name</label
        >
        <p-dropdown
          inputId="companyname"
          [options]="companyListAssociatedWithAttorney"
          formControlName="companyId"
          [disabled]="
            !(
              congigureTimeForm.get('petitionType').value ||
              congigureTimeForm.get('immClassificationCode').value
            ) || disabledStepTask
          "
          name="companyId"
          optionLabel="petitionerName"
          optionValue="petitionerId"
          placeholder="Select a Company"
        >
        </p-dropdown>
        <span
          class="invalid-message"
          *ngIf="
            congigureTimeForm.get('companyId').touched &&
            congigureTimeForm.get('companyId').errors?.companyIsRequired
          "
        >
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>
      <!-- <div *ngIf="!isImmigrant">
        <label for="visatype" class="create-task-label required">Visa Type</label>
        <p-dropdown inputId="visatype" [options]="visaTypes" formControlName="visaType" optionLabel="name"
          name="visatype" [disabled]="!congigureTimeForm.get('superTaskTypeCode').value || disabledStepTask" optionValue="code"
          (onChange)="getPetitionType()" placeholder="Select Visa Type">
        </p-dropdown>
        <span class="invalid-message"
          *ngIf="congigureTimeForm.get('visaType').touched && congigureTimeForm.get('visaType').errors?.required">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div> -->
      <div class="p-col-4 mt-3">
        <label for="subTasktype" class="create-task-label required"
          >Sub-Task Type</label
        >
        <p-dropdown
          inputId="subtasktype"
          [options]="subTaskType"
          formControlName="subTaskType"
          placeholder="Select Task Type"
          optionLabel="name"
          optionValue="code"
          (onChange)="onChangeSubTaskType()"
          [disabled]="
            !(
              congigureTimeForm.get('petitionType').value ||
              congigureTimeForm.get('immClassificationCode').value
            ) || disabledStepTask
          "
        >
        </p-dropdown>
        <span
          class="invalid-message"
          *ngIf="
            congigureTimeForm.get('subTaskType').touched &&
            congigureTimeForm.get('subTaskType').errors?.required
          "
        >
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>

      <!-- visa template selection - introduced as part of versioning of visa -->
      <div class="p-col-4 mt-3">
        <label for="subTasktype" class="create-task-label"
          >Visa Template</label
        >
        <p-dropdown
          inputId="tasktypeid"
          [options]="visaTemplates"          
          placeholder="Select a Template"
          optionLabel="visaName"
          optionValue="visaId"
          formControlName = "visaId"
          (onChange)="onChangeVisaTemplate($event)" 
          [disabled]="
            !(
              congigureTimeForm.get('subTaskType').value
            ) || disabledStepTask
          "
        >
        </p-dropdown>
        <span
          class="invalid-message"
          *ngIf="
            congigureTimeForm.get('taskTypeId').touched &&
            congigureTimeForm.get('taskTypeId').errors?.required
          "
        >
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
      </div>

      <div class="p-col-2 mt-5" *ngIf="isDiplay">
        <button
          pButton
          type="button"
          (click)="onDisplay()"
          [disabled]="congigureTimeForm.invalid"
          class="im-btn button_whitebg im-btn-with-icon im-btn-outline-primary"
        >
          <div class="d-flex">
            <span>Get Steps</span>
          </div>
        </button>
      </div>
    </div>
  </form>
</p-card>
<div *ngIf="isShowStepsLines">
  <app-step-task-allocation
    (stepTaskTimelineListDetails)="receiveMessage($event)"
    [isAssignTo]="true"
    [stepTimeLineDetails]="stepTimeLineDetails"
  ></app-step-task-allocation>
  <div class="p-col-2 mt-5">
    <button
      [disabled]="saveDisable"
      pButton
      type="button"
      (click)="saveTimeLine()"
      class="im-btn button_whitebg im-btn-with-icon im-btn-outline-primary"
    >
      <div class="d-flex">
        <span>Save</span>
      </div>
    </button>
    <button
      pButton
      type="button"
      (click)="cancel()"
      class="im-btn button_whitebg im-btn-with-icon im-btn-outline-primary ml-2"
    >
      <div class="d-flex">
        <span>Cancel</span>
      </div>
    </button>
  </div>
</div>
