import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { VisaExtendedState } from '../../../utility/interfaces/visa-extended-state';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { I140FileUploadConfiguration, ImagilityBaseResponse, PrimaryData, SubTaskDetails, TaskStep } from 'app-models';
import * as errorUtils from "error-message-utility";
import { ProfessionalPublicationsService } from './professional-publications.service';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { UploadDocumentService } from 'src/app/services/upload-document.service';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
import * as DirtyForm from '../../../../../app-state/actions/dirty-form.actions';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode, getSubTaskDetails } from 'visa-store';
import moment from 'moment';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};
@Component({
  selector: 'app-professional-publications',
  templateUrl: './professional-publications.component.html',
  styleUrls: ['./professional-publications.component.scss']
})
export class ProfessionalPublicationsComponent implements OnInit {
  @ViewChild("proffessionalimageupload") public proffessionalimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("proffessionalkendoeditor") public proffessionalkendoeditor: EditorComponent;
  subcriptions: Subscription[] = [];
  publicationsForm: FormGroup;
  disabledControls: boolean;
  stepDetails: TaskStep;
  enableEdit: boolean = false;
  addedPublication = [];
  hideAddAwardSection: boolean = false;
  fileUploadConfigurations: I140FileUploadConfiguration;
  primaryData;
  additionalInfo;
  formDirtyFromDifferentControls = false;
  currentDate = new Date();
  typeOfButton: string;
  addEditButtonText: string = 'Add';
  task: SubTaskDetails;
  inputData: PrimaryData;
  stepStatus;
  showMarkComplete: boolean = false;
  markStep: string;
  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //       //this.isFormValidCheck();
  //     }
  //   }
  // };


  observableSubscription$ = new Subject();

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadDocumentService: UploadDocumentService,
    public dialogService: DialogService,
    public dynamicDialogConfig: DynamicDialogConfig,
    private professionalPublicationsService: ProfessionalPublicationsService,
    private store: Store<VisaExtendedState>,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.disabledControls = false;
  }

  ngOnInit(): void {

    this.publicationsForm = this.fb.group({
      title: ['', Validators.required],
      author: ['', Validators.required],
      docEvidenceCode: ['PROFPUBLCTN', Validators.required],
      publicationId: [],
      publishedDate: ['', Validators.required]
    });

    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        this.stepDetails = response[1];
        if (this.task['extrAblDocEvdList'][this.task['extrAblDocEvdList'].length - 1]['code'] == 'PROFPUBLCTN') {
          this.showMarkComplete = true;
        }
        this.fileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PUBLCDOC/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PUBLCDOC/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'PUBLCDOC'
        });

        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
        this.toggleControlsStatus();
        this.getAddedPublications();
        this.getAdditionalInfo();
      });
    //this.primaryData = this.dynamicDialogConfig.data;/
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit '
    } else {
      this.markStep = 'MARK STEP COMPLETE';
    }
  }

  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }
  openproffessionalimageuploaddialog() {
    this.proffessionalimageupload.open();
  }
  ngAfterViewInit() {
    this.subcriptions[this.subcriptions.length] = this.publicationsForm.statusChanges.subscribe(() => {
      if (this.publicationsForm.dirty || this.formDirtyFromDifferentControls) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }
  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  updateControls(status: boolean) {
    this.enableEdit = status;
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  editPublication(document) {
    this.publicationsForm.patchValue({
      title: document.title,
      author: document.author,
      docEvidenceCode: document.docEvidenceCode,
      publicationId: document.publicationId,
      publishedDate: moment(document.publishedDate).utc().toDate()//new Date(document.publishedDate)
    });
    this.addEditButtonText = 'Update';
  }

  deletePublication(id) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.professionalPublicationsService.deletePublication(
          this.dynamicDialogConfig.data.petitionId, id,
        ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.addEditButtonText = 'Add';
          this.publicationsForm.reset();
          this.publicationsForm.controls['docEvidenceCode'].setValue('PROFPUBLCTN');
          this.publicationsForm.updateValueAndValidity();
          this.getAddedPublications();
        });
      }
    });
  }

  toggleAddSection() {
    this.publicationsForm.reset();
    this.publicationsForm.updateValueAndValidity();
    this.hideAddAwardSection = !this.hideAddAwardSection;
  }
  addPublication() {
    // let payload = {};
    // payload["title"]=this.publicationsForm.get('title').value;
    // payload["awardedYear"]=this.publicationsForm.get('awardedYear').value;
    // payload["awardingInstitution"]=this.publicationsForm.get('awardingInstitution').value;
    // payload["docEvidence"]="PROFPUBLCTN";
    // payload["id"] = this.publicationsForm.get('id').value ? this.publicationsForm.get('id').value : '0';
    if (this.publicationsForm.valid) {
      this.professionalPublicationsService.addPublication(this.dynamicDialogConfig.data.petitionId, this.publicationsForm.value).subscribe((response: ImagilityBaseResponse) => {
        if (response && response['data']) {
          this.toastr.success(response.message, 'Success');
          this.publicationsForm.reset();
          this.publicationsForm.controls['docEvidenceCode'].setValue('PROFPUBLCTN');
          this.publicationsForm.updateValueAndValidity();
          this.addEditButtonText = 'Add';
          this.getAddedPublications();
        }
      });
    }
  }

  getAddedPublications() {
    this.professionalPublicationsService.getPublicationsList(this.dynamicDialogConfig.data.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response['data']) {
        this.addedPublication = response['data'] || [];
      }
    });
  }

  saveAdditionalInfo() {
    let payload = {
      "additionalText": this.additionalInfo,
      "infoType": 'PROFPUBLCTN',
    };
    this.professionalPublicationsService.saveAdditionalInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response['status'] === 200) {
          this.toastr.success(response.message, 'Success');
          this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
          this.getAdditionalInfo();
          this.addEditButtonText = 'Add';
        }
      });

  }

  getAdditionalInfo() {
    this.professionalPublicationsService.getAdditionalInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, 'PROFPUBLCTN').subscribe((response: ImagilityBaseResponse) => {
        if (response && response['data']) {
          this.additionalInfo = response['data']['additionalText'];
        }
      });
  }
  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }
    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
    // this.toggleControlsStatus();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    //this.store.dispatch(updateStepStatusToInitialState());
  }
  public professionalpublicationseditorValueChange(value: string): void {
    this.additionalInfo = value;
  }
}
