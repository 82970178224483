import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomQuestionnaireService {
  masterData: any;

  constructor(private httpClient: HttpClient) {
  }
  policyInfo = new BehaviorSubject({});

  getSuperTaskList(payload) {
    return this.httpClient.post(`/superTask/list`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  getSuperTask(superTaskId) {
    return this.httpClient.get(`/superTask/${superTaskId}/overview`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  getSubTaskList(superTaskId) {
    return this.httpClient.post(`/superTask/${superTaskId}/subTask/list`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  updateNotificationSettings(taskTypeId) {
    return this.httpClient.post(`/addCaseAutomation/notification/${taskTypeId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getSectionQuestionList(companyId) {
    return this.httpClient.get(`/getCustomList/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  createNewSection(isCustomStep, data, status) {
    return this.httpClient.post(`/saveSection?isCustomStep=${isCustomStep}&isUpdate=${status}`, data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  saveSection(item, companyId, data, addQuestions) {
    return this.httpClient.post(`/saveSectionAndQuestions/${item.id}/${item.custom}/${companyId}?addQuestion=${addQuestions}`, data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }



  getBeneficiaryOrDependentDetails(data) {
    return this.httpClient.get(`/referenceLibrary/${data}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response;
        }
      })
    );
  }

  getSectionList(id, companyId, payload) {    
    return this.httpClient.post(`/getQuestionsByCompanyAndSectionOrStep/${id}/${companyId}?isStep=false`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getSectionMainList(companyId, isStep, payload) {
    return this.httpClient.post(`/getSectionList/${companyId}/${isStep}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteSection(companyId: number, id: number, isStep: boolean) {
    return this.httpClient.delete(`/deleteSection/${companyId}/${id}/${isStep}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteCaseRequestSection(id: number) {
    return this.httpClient.delete(`/deleteCase/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  createNewStep(isCustomStep, data, status) {
    return this.httpClient.post(`/saveSection?isCustomStep=${isCustomStep}&isUpdate=${status}`, data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  saveStep(item, data, companyId, addQuestions) {
    return this.httpClient.post(`/saveStepAndQuestions/${item.id}/${item.custom}/${companyId}?addQuestion=${addQuestions}`, data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getStepList(companyId, taskTypeId, isStep) {
    return this.httpClient.get(`/getCustomStepList/${taskTypeId}/${companyId}/${isStep}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }


  deleteQuestion(qId: number) {
    return this.httpClient.delete(`/deleteQuestion/${qId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSectionDetails(companyId, id, isStep) {
    return this.httpClient.get(`/getAllQuestions/${companyId}/${id}/${isStep}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response;
        }
      })
    );
  }

  getAnswerTypeList(ansType) {
    return this.httpClient.get(`/referenceLibrary/${ansType}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getCustomStepsDetails(companyId, taskTypeId) {
    return this.httpClient.get(`/getCustomQuestionnaryDetails/company/${companyId}?taskTypeId=${taskTypeId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getStepMainList(companyId, isStep, selectedTaskType, payload) {
    return this.httpClient.post(`/getSectionList/${companyId}/${isStep}?suptsktyp=${selectedTaskType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getCaseRequestStepMainList(companyId, selectedTaskType, payload) {
    return this.httpClient.post(`/getCaseRequestList?companyId=${companyId}&suptsktyp=${selectedTaskType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  } 
  getBeneficiaryLibraryMainList(companyId, selectedTaskType, payload) {
    return this.httpClient.post(`/getCaseRequestList?companyId=${companyId}&isUsedForBenQuestionnaryLibrary=true&suptsktyp=${selectedTaskType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  } 
  getSubStepList(id, companyId, payload) {    
    return this.httpClient.post(`/getQuestionsByCompanyAndSectionOrStep/${id}/${companyId}?isStep=true`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  // getStepListQuestionById(id, companyId, payload) {
  //   return this.httpClient.post(`/getQuestionsByCompanyAndSectionOrStep/${id}/${companyId}?isStep=true`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       if (response.status === 200) {
  //         return response;
  //       }
  //     })
  //   );
  // }
  getCustomJsonList(data) {
    return this.httpClient.get(`/getCustomJson/${data.companyId}/${data.beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }
  saveUpdateSubTask(payload: any, taskType: string) {
    return this.httpClient.post(`/${taskType}/task/${payload.id}/update`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
  }

}
