import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class PastJobDescriptionService {

  constructor(private http: HttpClient) { }

  getReferenceData(referenceCode: string) {
    return this.http.get(`/referenceLibrary/${referenceCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getStates(countryCode: string) {
    return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getJobDescription(visatype, caseType, caseId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/jobDescription?isThisPastJob=true`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  postJobDescription(payload, visatype, caseType, caseId) {
    return this.http.post(`/${visatype}/${caseType}/${caseId}/jobDescription`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // /api/v1/{type}/{caseType}/{caseId}/workLocation/{workLocationId} - To delete work location
  deleteWorkLocationItem(visatype, caseType, caseId, workLocationId) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/workLocation/${workLocationId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}