import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modalId: string;
  @Output() closed = new EventEmitter();
  @Input() size: 'sm' | 'lg';
  @ViewChild('content', { static: true }) content: any;
  private modalRefernce: NgbModalRef;
  data: any;
  constructor(private modalService: NgbModal) { }
  closeResult: string;

  ngOnInit() { }

  public open(data) {
    this.data = data;
    this.modalRefernce = this.modalService.open(this.content, { size: this.size,  backdrop: 'static'});
    this.modalRefernce.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closed.emit();
    });
  }

  public close() {
    if (this.modalRefernce) {
      this.modalRefernce.close();
      this.closed.emit();
    }
  }
}
