import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ImagilityWebStorage } from 'src/app/services/webStorage.service';
import { ModalComponent } from './modules/shared/modal/app-modal/modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IdleService } from './modules/core/services/idle.service';
import { Subject, Subscription } from 'rxjs';
import { AutoResume } from '@ng-idle/core';
import * as RolesRouteMap from 'src/app/data/constants/roles';
import { filter, pairwise, take, takeUntil } from 'rxjs/operators';
import { EnvironmentService } from 'environment-configurations';
import { SnoozeComponent } from './snooze/snooze.component';
import { SnoozeService } from './services/snooze.service';
import * as moment from 'moment';
import { MarketingService } from './modules/marketing/common/services/marketing.service';
import * as fromFB from './modules/form-designer/fb-state/reducers/fb.reducer';
import { Store } from '@ngrx/store';
import { AppState } from './data/models/app-state';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  isHeaderIncluded = false;
  routerStateUrl = '';
  sansHeaderRoutes = ['/login/login/signin',
    '/login/login/welcome',
    '/login/login/signup',
    '/login/login/register',
    '/login/login/resetpassword',
    '/login/login/verifyemail',
    '/page-not-found',
    '/session-expired',
    '/access-denied',
    '/something-went-wrong',
    '/landing',
    '/beneficiary-profile',
    '/beneficiary-profile/authentication',
    '/beneficiary-profile/profile/education',
    '/beneficiary-profile/document',
    '/lead/collected-lead',
    '/attorney-profile/authentication',
    '/company-request-details/authentication',
    'external-client/data-and-docs'
  ];
  @ViewChild('timeoutModal', { static: true }) timeoutModal: ModalComponent;
  @ViewChild('reloadModal', { static: true }) reloadModal: ModalComponent;
  @ViewChild('snoozeModal', { static: true }) snoozeModal: ModalComponent;
  private subscriptions: Subscription[] = [];
  public timeLeft = 0;
  observableSubscription$ = new Subject();
  snoozes: any;
  isFBModulePreview = false;
  isExternal;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private imagilityWebStorage: ImagilityWebStorage,
    private idleService: IdleService,
    private snoozeService: SnoozeService,
    private marketingService: MarketingService,
    private store: Store<AppState>,
    private environment: EnvironmentService // this is needed to initialize the environment variable in the environment Library. Please don't delete this.
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        if (events && events.length > 1 &&
          events[1].urlAfterRedirects &&
          events[1].urlAfterRedirects.indexOf('users/newuser') !== -1 &&
          events[0].urlAfterRedirects) {
          // do not use this session variable anywhere else
          sessionStorage.setItem('clientCompanyId', events[0].urlAfterRedirects);
        } else {
          sessionStorage.removeItem('clientCompanyId');
        }
      });
    this.imagilityWebStorage.startLocalstorageEventListening();/*IM3069*/
    // redirect to dashbaord if already logged in
    if (this.authenticationService.currentUserValue) {
      this.isLoggedIn = true;
      this.permissionsService.loadPermissions(this.authenticationService.currentUserValue['permissions']);
    }
    else {

    }
  }

  ngOnInit() {
    this.isExternal = localStorage.getItem("isExternal");
    this.store.select(fromFB.getIsFBModulePreview)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((isFBModulePreview: boolean) => {
        this.isFBModulePreview = isFBModulePreview;
      });
    this.triggerSnooze();
    this.snoozeService.getMessage().subscribe((message) => {
      if (message.text === "Saved snooze Successfully.") {
        this.triggerSnooze();
      }
    })
    localStorage.removeItem('enteredEmail');
    localStorage.removeItem('enteredPassword');
    localStorage.removeItem('linkRequestCode');
    localStorage.removeItem('beneficiaryId');
    this.router.events
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
          if (e.url.indexOf('login') < 0) {
            if (!this.idleService.get().isRunning()) {
              this.idleService.set(1800, 300);
              this.idleService.reset();
            }
          } else {
            this.reloadModal.close();
            this.timeoutModal.close();
            this.idleService.get().stop();
          }
          if ((e.url.indexOf('users') >= 0 && (e.url.indexOf('companies') < 0 &&
            e.url.indexOf('attorneys') < 0)) || (e.url.indexOf('roles') >= 0 &&
              (e.url.indexOf('companies') < 0 && e.url.indexOf('attorneys') < 0))) {
            sessionStorage.setItem('selectedCompanyId', sessionStorage.getItem('companyId'));
          }
        }
      });
    if (this.authenticationService.currentUserValue) {
      this.dashboardWidgetsService.setBellIconCountOnHeader();
    }
    this.subscriptions.push(
      this.idleService.get().onTimeout
        .subscribe(() => {
          this.idleService.get().stop();
          this.timeoutModal.close();
          if (this.reloadModal) {
            this.reloadModal.close();
          }
          this.idleService.get().stop();
          this.logout();
        })
    );

    this.subscriptions.push(
      this.idleService.get().onIdleStart
        .subscribe(() => {
          this.timeoutModal.open({});
          this.idleService.get().setAutoResume(AutoResume.notIdle);
        })
    );

    this.subscriptions.push(
      this.idleService.get().onTimeoutWarning
        .subscribe((countdown) => {
          this.timeLeft = countdown;
        })
    );

    this.subscriptions.push(
      this.imagilityWebStorage.reloadTrigger
        .subscribe(isReload => {
          if (isReload) {
            this.reloadModal.open({});
          }
        })
    );

    this.subscriptions.push(
      this.idleService.get().onInterrupt
        .subscribe(interrupt => {
          if (!this.idleService.get().isIdling()) {
            this.timeoutModal.close();
          }
        })
    );

    this.route.queryParams
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(params => {
        if (params.linkrequestId) {
          if (params.initiated && String(params.initiated).toLowerCase() === 'attorney') {
            if (params.beneficiaryType && params.beneficiaryType === 'employmentBased') {
              this.router.navigate(['/login/welcome/' + params.linkrequestId], {
                state: {
                  linkrequestId: params.linkrequestId,
                  initiated: params.initiated,
                  type: params.type ? params.type : null,
                  beneficiaryType: params.beneficiaryType ? params.beneficiaryType : null
                }
              });
            } else {
              this.router.navigate(['/login/registerImmigrantType'], {
                state: {
                  linkrequestId: params.linkrequestId,
                  initiated: params.initiated,
                  type: params.type ? params.type : null
                }
              });
            }
          } else {
            this.router.navigate(['/login/welcome/' + params.linkrequestId]);
          }
        }
      });

  }

  triggerSnooze() {
    let sortedDates = [];
    let userId = sessionStorage.getItem('userId')
    if (userId) {
      this.snoozeService.getSnoozes(parseInt(userId)).subscribe((response: any) => {
        if (response.data != null) {
          this.snoozeService.snoozes = response.data;
          let pendingSnoozes = response.data.filter((snooze) => {
            let splitDate = snooze.reminderDate.split("-");
            snooze.reminderDate = splitDate[1] + '-' + splitDate[2] + '-' + splitDate[0];
            let isPendingSnooze = new Date(snooze.reminderDate + ' ' + snooze.reminderTime) > new Date();
            if (isPendingSnooze) {
              return snooze;
            }
          });
          if (pendingSnoozes.length) {
            sortedDates = pendingSnoozes.sort(function (a, b) {
              return moment.utc(a.reminderDate + ' ' + a.reminderTime).diff(moment.utc(b.reminderDate + ' ' + b.reminderTime))
            });
            let SnoozeTime = new Date(sortedDates[0].reminderDate + ' ' + sortedDates[0].reminderTime).getTime();
            let now = new Date().getTime();
            sortedDates[0].timerInterval = (SnoozeTime - now);
            if (sortedDates[0].timerInterval > 0) {
              this.showSnooze(sortedDates[0])
            }
          }
        }
      });
    }
  }

  showSnooze(data) {
    setTimeout(() => {
      this.marketingService.getLeadById(data.id).subscribe((response: any) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = ['shake-any'];
        dialogConfig.data = { snoozeDetails: data, leadDetails: response.data };
        const dialogI = this.dialog.open(SnoozeComponent, dialogConfig);
        dialogI.afterClosed().subscribe((reason) => {
        });

        this.router.events.subscribe(() => {
          dialogI.close();
        });
      });
    }, data.timerInterval)
  }

  logout() {
    this.authenticationService.logout()
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(data => {
        if (data && data['status'] === 200) {
          this.authenticationService.clearUserData();
          this.isLoggedIn = false;
          this.router.navigate(['/login']);
        }
      });
  }

  onActivate() {
    this.routerStateUrl = this.route.snapshot['_routerState']['url'] || '';
    this.activateErrorTemplateFlag(this.routerStateUrl);
  }

  onDeactivate() {
    this.routerStateUrl = this.route.snapshot['_routerState']['url'] || '';
    this.activateErrorTemplateFlag(this.routerStateUrl);
  }

  activateErrorTemplateFlag(url) {
    this.isHeaderIncluded = this.sansHeaderRoutes.some((item) => {
      return url.includes(item);
    });
  }

  ngOnDestroy() {
    this.idleService.get().stop();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.imagilityWebStorage.stopLocalstorageEventListening(); /*IM3069*/
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  public closeTimeoutModal(input) {
    if (input == 'Yes') {
      this.timeoutModal.close();
    } else {
      this.timeoutModal.close();
      this.idleService.get().stop();
      this.logout();
    }

  }

  public timeoutModalClosed() {
    this.idleService.get().watch();
  }

  closeReloadModal() {
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[localStorage.getItem('userTypeRole')]}/dashboard`]);
    this.reloadModal.close();
  }
  closedReloadModal() {

  }
  closeSnoozeModal() {
    this.snoozeModal.close();
  }

  

}
