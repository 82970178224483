<div class="container-fluid">
  <form [formGroup]="otherInfoForm">
    <div class="row d-flex align-items-center">
      <div class="col-md-12 step-title">
        <h5 class="mb-0">Other Information</h5>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="row mb-3">
      <h5 class="col-12">Adjustment of Status</h5>
      <label class="label-cls pl-3 mb-2">At which USCIS office will the beneficiary apply for adjustment of status to
        lawful permanent resident?</label>
      <div class="col-4">
        <label class="label-cls">City or Town</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                        'im-input-error':
                          (otherInfoForm.get('officeCity').touched) &&
                          otherInfoForm.get('officeCity').errors?.pattern
                      }" pattern="^[a-zA-Z ]*$" formControlName="officeCity" />
        <span class="invalid-message" *ngIf="
                        (otherInfoForm.get('officeCity').touched) &&
                        otherInfoForm.get('officeCity').errors?.pattern
                      ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message" *ngIf="otherInfoForm.get('officeCity').touched && otherInfoForm.errors?.officeCity">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> State </label>
        <p-dropdown appendTo="body" [options]="statesList" *ngIf="statesList.length > 0" id="stateProvinceCode"
          formControlName="officeState" name="statesList" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('officeState').touched && otherInfoForm.errors?.officeState">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
    </div>

    <p-divider class="d-block mb-4"></p-divider>

    <div class="row mb-3">
      <h5 class="col-12">Pirior Petitions</h5>
      <label class="label-cls pl-3 mb-2">At which U.S. Embassy or Consulate location will the beneficiary apply for an
        immigrant visa?
      </label>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> Country </label>
        <p-dropdown appendTo="body" [options]="countryList" [filter]="false" id="countryCode"
          [disabled]="disabledControls" formControlName="usaEmbassyCountry" optionLabel="countryName"
          optionValue="countryCode" (onChange)="handleCountryChange($event.value, null, null)" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('usaEmbassyCountry').touched && otherInfoForm.errors?.usaEmbassyCountry">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-4">
        <label class="label-cls">City or Town</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                            'im-input-error':
                              (otherInfoForm.get('usaEmbassyCityOrTown').touched) &&
                              otherInfoForm.get('usaEmbassyCityOrTown').errors?.pattern
                          }" pattern="^[a-zA-Z ]*$" formControlName="usaEmbassyCityOrTown" />
        <span class="invalid-message" *ngIf="
                            (otherInfoForm.get('usaEmbassyCityOrTown').touched) &&
                            otherInfoForm.get('usaEmbassyCityOrTown').errors?.pattern
                          ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('usaEmbassyCityOrTown').touched && otherInfoForm.errors?.usaEmbassyCityOrTown">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> Province </label>
        <p-dropdown appendTo="body" [options]="listEmbassyStates"  id="stateProvinceCodeEmbassy"
          formControlName="usaEmbassyProvince" name="listEmbassyStates" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('usaEmbassyProvince').touched && otherInfoForm.errors?.usaEmbassyProvince">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
        <!-- <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listBirthStates.length === 0"
                id="clientName" formControlName="aosUsStateProvinceName" type="text" pInputText placeholder="Enter" /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="label-cls d-block">Have you EVER previously filed a petition for this beneficiary or any
          other alien?</label>
        <div class="row mt-2">
          <div class="col-4">
            <div class="p-field-checkbox">
              <p-radioButton class="float-left p-checkbox" name="haveYoufiledForBeneficiary"
                formControlName="haveYoufiledForBeneficiary" [value]="true" label="Yes">
              </p-radioButton>
            </div>
          </div>
          <div class="col-4">
            <div class="p-field-checkbox">
              <p-radioButton class="float-left p-checkbox" name="haveYoufiledForBeneficiary"
                formControlName="haveYoufiledForBeneficiary" [value]="false" label="No">
              </p-radioButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-3"> -->
    <!-- <h5>Adjustment of Status</h5> -->
     <div *ngIf="otherInfoForm.get('haveYoufiledForBeneficiary').value">
    <label class="row ml-0">What is the full legal name of the beneficiary or alien you filed on behalf of?</label>
    <div class="row mt-2">
      <div class="col-4">
        <label class="label-cls">Given name(first name)</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                'im-input-error':
                                  (otherInfoForm.get('legalNameFirstName').touched) &&
                                  otherInfoForm.get('legalNameFirstName').errors?.pattern
                              }" pattern="^[a-zA-Z ]*$" formControlName="legalNameFirstName" />
        <span class="invalid-message" *ngIf="
                                (otherInfoForm.get('legalNameFirstName').touched) &&
                                otherInfoForm.get('legalNameFirstName').errors?.pattern
                              ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message" *ngIf="otherInfoForm.get('legalNameFirstName').touched && otherInfoForm.errors?.legalNameFirstName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-4">
        <label class="label-cls">Middle name</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                  'im-input-error':
                                    (otherInfoForm.get('legalNameMiddleName').touched) &&
                                    otherInfoForm.get('legalNameMiddleName').errors?.pattern
                                }" pattern="^[a-zA-Z ]*$" formControlName="legalNameMiddleName" />
        <span class="invalid-message" *ngIf="
                                  (otherInfoForm.get('legalNameMiddleName').touched) &&
                                  otherInfoForm.get('legalNameMiddleName').errors?.pattern
                                ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('legalNameMiddleName').touched && otherInfoForm.errors?.legalNameMiddleName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-4">
        <label class="label-cls">Family name(last name)</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                  'im-input-error':
                                    (otherInfoForm.get('legalNameLastName').touched) &&
                                    otherInfoForm.get('legalNameLastName').errors?.pattern
                                }" pattern="^[a-zA-Z ]*$" formControlName="legalNameLastName" />
        <span class="invalid-message" *ngIf="
                                  (otherInfoForm.get('legalNameLastName').touched) &&
                                  otherInfoForm.get('legalNameLastName').errors?.pattern
                                ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message" *ngIf="otherInfoForm.get('legalNameLastName').touched && otherInfoForm.errors?.legalNameLastName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
    </div>

    <label class="row my-2 ml-0">Where was the petition filed?</label>
    <div class="row">
      <div class="col-4">
        <label class="label-cls">City or Town</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                      'im-input-error':
                                        (otherInfoForm.get('petitionFiledCityOrtown').touched) &&
                                        otherInfoForm.get('petitionFiledCityOrtown').errors?.pattern
                                    }" pattern="^[a-zA-Z ]*$" formControlName="petitionFiledCityOrtown" />
        <span class="invalid-message" *ngIf="
                                      (otherInfoForm.get('petitionFiledCityOrtown').touched) &&
                                      otherInfoForm.get('petitionFiledCityOrtown').errors?.pattern
                                    ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('petitionFiledCityOrtown').touched && otherInfoForm.errors?.petitionFiledCityOrtown">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-md-4 form-group processing-dropdown">
        <label class="label-cls"> State </label>
        <p-dropdown appendTo="body" [options]="statesList" *ngIf="statesList.length > 0" id="stateProvinceCode"
          formControlName="petitionFiledState" name="statesList" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="select">
        </p-dropdown>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('petitionFiledState').touched && otherInfoForm.errors?.petitionFiledState">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
        <!-- <input class="im-input mw-100" [readonly]="disabledControls" *ngIf="listBirthStates.length === 0"
                          id="clientName" formControlName="aosUsStateProvinceName" type="text" pInputText placeholder="Enter" /> -->
      </div>
    </div>
    <label class="row mt-2 ml-0">When was the petition filed?</label>
    <div class="row">
      <div class="col-4">
        <p-calendar class="im-input-calendar mw-100" styleClass="p-form-datepicker" appendTo="body"
          dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true" [disabled]="disabledControls"
          formControlName="petitionFiledDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050"
          [showButtonBar]="true">
        </p-calendar>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('petitionFiledDate').touched && otherInfoForm.errors?.petitionFiledDate">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
    </div>
    <label class="row mt-2 top-bottom-space ml-0">What was the result?</label>
    <div class="row mb-2">
      <div class="col-4">
        <!-- <input type="text" class="im-input" pInputText [ngClass]="{
          'im-input-error':
            (otherInfoForm.get('result').touched) &&
            otherInfoForm.get('result').errors?.pattern
        }" pattern="^[a-zA-Z ]*$" formControlName="result" />
        <span class="invalid-message" *ngIf="
          (otherInfoForm.get('result').touched) &&
          otherInfoForm.get('result').errors?.pattern
        ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="otherInfoForm.get('result').touched && otherInfoForm.errors?.result">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div> -->
        <p-dropdown appendTo="body" [options]="petitionResultStatus"  id="result"
        formControlName="result" name="petitionResultStatusName"  placeholder="select">
      </p-dropdown>
      </div>
    </div>
    </div>
  </form>
  <form [formGroup]="otherRealtivesPetitionForm">
    <div>
    <h5>Are you submitting separate petitions for other relatives?
    </h5>
    <label class="row ml-0 mt-2">What is your relative's full legal name?
    </label>
    <div class="row mt-2">
      <div class="col-4">
        <label class="label-cls required">Given name(first name)</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                'im-input-error':
                                  (otherRealtivesPetitionForm.get('firstName').touched) &&
                                  otherRealtivesPetitionForm.get('firstName').errors?.pattern
                              }" pattern="^[a-zA-Z ]*$" formControlName="firstName" />
        <span class="invalid-message" *ngIf="
                                (otherRealtivesPetitionForm.get('firstName').touched) &&
                                otherRealtivesPetitionForm.get('firstName').errors?.pattern
                              ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message" *ngIf="otherRealtivesPetitionForm.get('firstName').touched && otherRealtivesPetitionForm.errors?.firstName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-4">
        <label class="label-cls">Middle name</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                  'im-input-error':
                                    (otherRealtivesPetitionForm.get('middleName').touched) &&
                                    otherRealtivesPetitionForm.get('middleName').errors?.pattern
                                }" pattern="^[a-zA-Z ]*$" formControlName="middleName" />
        <span class="invalid-message" *ngIf="
                                  (otherRealtivesPetitionForm.get('middleName').touched) &&
                                  otherRealtivesPetitionForm.get('middleName').errors?.pattern
                                ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message"
          *ngIf="otherRealtivesPetitionForm.get('middleName').touched && otherRealtivesPetitionForm.errors?.middleName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
      <div class="col-4">
        <label class="label-cls required">Family name(last name)</label>
        <input type="text" class="im-input" pInputText [ngClass]="{
                                  'im-input-error':
                                    (otherRealtivesPetitionForm.get('lastName').touched) &&
                                    otherRealtivesPetitionForm.get('lastName').errors?.pattern
                                }" pattern="^[a-zA-Z ]*$" formControlName="lastName" />
        <span class="invalid-message" *ngIf="
                                  (otherRealtivesPetitionForm.get('lastName').touched) &&
                                  otherRealtivesPetitionForm.get('lastName').errors?.pattern
                                ">
          {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
        </span>
        <div class="invalid-message" *ngIf="otherRealtivesPetitionForm.get('lastName').touched && otherRealtivesPetitionForm.errors?.lastName">
          {{getGlobalErrorMessages('REQUIRED')}}
        </div>
      </div>
    </div>
    <label class="row ml-0 top-bottom-space">What is your relationship to this relative?
    </label>
    <div class="row">
    <div class="col-md-4 form-group processing-dropdown">
      <label class="label-cls required"> Relationship </label>
      <p-dropdown appendTo="body" [options]="relationshipList" *ngIf="relationshipList.length > 0" id="relationship"
        formControlName="relationship" name="relationshipList" optionLabel="name"
        optionValue="code" placeholder="select">
      </p-dropdown>
      <div class="invalid-message"
        *ngIf="otherRealtivesPetitionForm.get('relationship').touched && otherRealtivesPetitionForm.errors?.relationship">
        {{getGlobalErrorMessages('REQUIRED')}}
      </div>
    </div>
  </div>

  <div class="col-md-12 p-0 ">
    <div class="row mb-4 mt-3">
      <div class="col-12">
        <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-outline-primary mr-3"
          [ngClass]="{'disabled': disabledControls || !otherRealtivesPetitionForm.valid }"
          [disabled]="disabledControls || !otherRealtivesPetitionForm.valid" (click)="saveRelativeInfo()">ADD</button>
        <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-light"
          (click)="cancelForm()">CANCEL</button>
      </div>
    </div>
    <div class="row w-100 mt-4 mb-5">
      <div class="container-fluid">
        <h5 class="sub-title text-primary">Added Details</h5>
        <p-table tableStyleClass="i130-address-table" [value]="relationPetitionList" *ngIf="relationPetitionList.length > 0">
          <ng-template pTemplate="header">
            <tr>
              <th class="col-6 px-2" colspan="2">Separete petitions for other relatives</th>
              <th class="col-3 px-2">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc let-ri="rowIndex">
            <tr>
              <td class="col-3 px-2">{{doc.firstName}}</td>
              <td class="col-3 px-2">{{doc.relationship}}</td>
              <td class="col-3 px-2">
                <div class="row action-btn">
                  <div class="p-0">
                    <button pButton type="button" [disabled]="disabledControls" title="Edit" icon="pi pi-pencil"
                      (click)="editDocument(doc)"
                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                    </button>
                  </div>
                  <div class="p-0">
                    <button pButton type="button" [disabled]="disabledControls" title="Delete" icon="pi pi-trash"
                      (click)="deleteDocument(doc.id)"
                      class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="no-data-box" *ngIf="relationPetitionList.length == 0">
          <p>Currently No Details Added</p>
        </div>
      </div>
    </div>
  </div>

    </div>
  </form>
    <!-- </div> -->
    <div class="row pt-3">
        <div class="col-md-3"> 
          <button pButton type="button" label="Save" title="Save" class="save-btn" icon="pi pi-save"
            [ngClass]="disabledControls   ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
            [disabled]="disabledControls"  (click)="saveOtherInfo()"></button>
        </div>
        <div class="col-md-3">
          <button pButton type="button" label="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
              d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
          </button>
        </div>
      </div>
 
</div>