import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ParentInformationService {

  constructor(private http: HttpClient) { }
  getParentInformation(relationType, beneficiaryId: number) {
    return this.http.get(`/${relationType}/beneficiary/${beneficiaryId}/dependent/profileDetails`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }
}
