<div class="immigrationContainer" *ngIf="showLifeStoryTimeLineSection">
  <div class="mt-4 btn-group row">
    <div class="col-6"></div>
    <div class="col viewType" *ngIf="!modeVal">
      <button class="childBtn firstBtn" [class.activebtn]="isTimeLineView" (click)="toggleListView(false)">
        <mat-icon matTooltip="Immigration timeline view" class="btn-icn">timeline</mat-icon>
        Timeline View
      </button>
      <button class="childBtn lastBtn" [class.activebtn]="isListView" (click)="toggleListView(true)">
        <mat-icon matTooltip="Immigration list view" class="btn-icn">list</mat-icon>
        List View
      </button>
      <!-- <mat-hint class="hint-text">(Switch to list view to add education)</mat-hint> -->
    </div>
  </div>

  <!-- IM-2570 When â€œI never applied for any non-immigrant visaâ€ selected, time line view shall have the verbiage-->
  <!-- IM-4204 Immigration - Timeline View - Immigration data and Do not have any non-immigrant visa both are displaying-->
  <span class="aligncenter" *ngIf="isTimeLineView && neverAppliedFlag === 'No' && immigrationData?.length <= 0">
    <h5>Do not have any immigrant/non-immigrant visa</h5>
  </span>
  <div class="row" *ngIf="isTimeLineView && immigrationData?.length > 0">
    <app-immigration-timeline class="col-12 mt-4" [immigrationData]="immigrationData"></app-immigration-timeline>
  </div>
  <div class="add-placeholder" *ngIf="isTimeLineView && immigrationData?.length == 0">
    <button mat-fab color="primary" class="btn" (click)="toggleListView(true)">
      <mat-icon matTooltip="Add immigration details">add_circle_outline</mat-icon>
    </button>
  </div>

  <div class="row" *ngIf="isListView" [ngClass]="{disabledView: isViewMode}">
    <ng-container *ngIf="!isExternalEmployeeId">
    <div class="container p-2" *ngIf="!viewMode" style="border: 1px dashed lightgray">
      <form (click)="trackNonImmigrantVisaForm(nonImmigrantVisForm)" #nonImmigrantVisForm="ngForm">
        <h5>Immigrant/Non-Immigrant Visa Info</h5>
        <div *ngIf="showEditButton">
          <div class="row align-items-center">
            <div class="col-md-7">Have you ever applied for any US immigrant/non-immigrant visa?</div>
            <div class="col-md-5">
              <mat-radio-group (change)="handleToggle()" name="neverAppliedFlag"
                aria-labelledby="example-radio-group-label" class="example-radio-group has-immigration" [(ngModel)]="neverAppliedFlag">
                <mat-radio-button class="example-radio-button" value="Yes">
                  Yes
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" value="No">
                  No
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row col-md-12">
            <label *ngIf="neverAppliedFlag === 'No'">
              Thank you for your response. You do not have to enter any further
              details.
            </label>
          </div>
          <div *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : true">
            <div class="row align-items-center" *ngIf="expandPanel">
              <div class="form-group col-md-8">
                <label>Alien Registration Number</label>
                <input class="im-input mb-2" type="text" name="alienRegNumber" [(ngModel)]="visaMeta.alienRegNo"
                  placeholder="Enter" #alienRegNumber="ngModel" [disabled]="visaMeta.hasAlienRegnNo" />
              </div>
              <div class="form-group col-md-4" style="margin-bottom: 0">
                <mat-checkbox name="alienRegNumberNA" (change)="updateAlienNo()" [(ngModel)]="visaMeta.hasAlienRegnNo">
                  Not Applicable
                </mat-checkbox>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div *ngIf="beneficiaryType == 'self'">
              <div *ngFor="let ques of customQuestions?.sectionList">
                <div *ngIf="ques.name == 'Immigration Details'">
                  <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
                    <div *ngIf="(item?.key == 'QTPROFILEBEN') && item.selected == true"> 
                      <app-custom-questions 
                        [isNewPortion]="false" 
                        [viewQuestions]="false" 
                        [loadedIn]="'Immigration Details'" 
                        [beneficiaryId]="beneficiaryId" 
                        [isSection]="true"
                        [familyId]="familyId"
                        (refreshList)="onRefreshPage($event)"
                        ></app-custom-questions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="beneficiaryType !== 'self'">
              <div *ngFor="let ques of customQuestions?.sectionList">
                <div *ngIf="ques.name == 'Immigration Details'">
                  <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
                    <div *ngIf="(selectedUser?.dependentType?.code == item.key) && item.selected == true"> 
                      <app-custom-questions 
                        [isNewPortion]="false" 
                        [viewQuestions]="false" 
                        [loadedIn]="'Immigration Details'" 
                        [beneficiaryId]="beneficiaryId" 
                        [isSection]="true"
                        [familyId]="familyId"
                        (refreshList)="onRefreshPage($event)"
                        ></app-custom-questions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row col-md-12">
            <!-- IM-3956 Alien Registration Number* -->
            <button type="button" class="btn btn-primary" (click)="saveVisaMetaInfo(nonImmigrantVisForm)"> Save </button>
          </div>
        </div>
      </form>
    </div>
    <div class="row mt-4" *ngIf=" neverAppliedFlag === 'Yes' || (isListView && dataSource.data.length > 0) ">
      <button *ngIf="updateBenProfile && !viewMode && showEditButton" class="btn btn-primary mb-2" type="button"
        (click)="toggleExpansion(); closeForm(visaDetailsForm);  stepper?.reset();visaFormCheck(visaDetailsForm);">
        Add / Edit Immigration
      </button>
    </div>

    <mat-accordion style="width: 100%" *ngIf="showEditButton">
      <mat-expansion-panel hideToggle
        [expanded]=" (expandPanel && immiexpandPanel) || (immiexpandPanel && dataSource.data.length > 0)">
        <mat-horizontal-stepper [linear]="isLinear" (selectionChange)="markAsUntouched()" #stepper
          labelPosition="bottom" class="pb-mat-stepper-custom-horizontal">
          <mat-step [completed]="hasID">
            <ng-template matStepLabel>Visa Details </ng-template>
            <form (click)="visaFormCheck(visaDetailsForm)" class="mt-2"
              (ngSubmit)="saveVisaDetails(visaDetailsForm)" #visaDetailsForm="ngForm"
              (change)="visaFormCheck(visaDetailsForm)">
              <div class="row">
                <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                  <label class="label-cls">Which Visa did you apply for?</label>
                  <p-dropdown (onChange)="handleVisaTypeChange(visa.immigrationStatusCode,          visaDetailsForm);resetPetitionTypeDropDown()" [options]="visaTypeList" name="immigrationStatusCode" [(ngModel)]="visa.immigrationStatusCode" optionValue="code" optionLabel="name" placeholder="Select" #immigrationStatusCode="ngModel" required class="w-100" appendTo="body" [filter]="true" filterBy="name">
                  </p-dropdown>
                  <!-- <select class="im-input im-dropdown-toggle col-md-10 mw-100" name="immigrationStatusCode"
                    [(ngModel)]="visa.immigrationStatusCode" #immigrationStatusCode="ngModel" (change)="
                    handleVisaTypeChange(
                      visa.immigrationStatusCode,
                      visaDetailsForm
                    );
                    resetPetitionTypeDropDown()
                  " required>
                    <option *ngFor="let visa of visaTypeList" [value]="visa.code">
                      {{ visa.name }}
                    </option>
                  </select> -->                 
                </div>
                <div class="form-group col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <label class="label-cls">Which Visa did you apply for?</label>
                  <p-dropdown (onChange)="handleVisaTypeChange(visa.immigrationStatusCode,          visaDetailsForm);resetPetitionTypeDropDown()" [options]="visaTypeList" name="immigrationStatusCode" [(ngModel)]="visa.immigrationStatusCode" optionValue="code" optionLabel="name" placeholder="Select" #immigrationStatusCode="ngModel" class="w-100">
                  </p-dropdown>
                </div>
                <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                  <label class="label-cls">What is the petition type?</label>
                  <p-dropdown [options]="visaPetitionTypeList" name="petitionTypeCode" required [(ngModel)]="visa.petitionTypeCode" optionValue="code" optionLabel="name" placeholder="Select" #petitionTypeCode="ngModel" class="w-100">
                  </p-dropdown>                  
                </div>
                <div class="form-group col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <label class="label-cls">What is the petition type?</label>
                  <p-dropdown [options]="visaPetitionTypeList" name="petitionTypeCode" [(ngModel)]="visa.petitionTypeCode" optionValue="code" optionLabel="name" placeholder="Select" #petitionTypeCode="ngModel" class="w-100">
                  </p-dropdown>
                </div>
              </div>
              <!-- IM-3831 : For able to save visa details -->
              <div class="row" *ngIf="!iscaseRequest">
                <div *ngIf="!checkPetitionName()" class="form-group col-md-6">
                  <label class="label-cls">What is the Petitioner Name?</label>
                  <input class="im-input mw-100" type="text" name="companyName" [(ngModel)]="visa.companyName"
                    placeholder="Enter" #companyName="ngModel" />                  
                </div>
              </div>
              <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                <div *ngIf="!checkPetitionName()" class="form-group col-md-6">
                  <label class="label-cls">What is the Petitioner Name?</label>
                  <input class="im-input mw-100" type="text" name="companyName" [(ngModel)]="visa.companyName"
                    placeholder="Enter" #companyName="ngModel" />
                </div>
              </div>
              <!-- IM-3831 : For able to save visa details -->
              <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : true">
                <div *ngIf="checkPetitionName()" class="form-group col-md-6">
                  <label class="label-cls">What is the Petitioner Name?</label>
                  <input class="im-input mw-100" type="text" name="companyName" [(ngModel)]="visa.companyName"
                    placeholder="Enter" #companyName="ngModel" />
                </div>
              </div>
              <div class="row" *ngIf="!iscaseRequest">
                <div class="form-group col-md-12">
                  <label class="label-cls d-block">What is the visa outcome?</label>
                  <mat-radio-group aria-label="Select an option" name="outcome"
                    (change)="outcomeCodeIdChange()" [(ngModel)]="visa.outcomeIdCode" #outcomeIdCode="ngModel">
                    <mat-radio-button class="example-radio-button" *ngFor="let option of outcomeTypeList"
                      [value]="option.code">
                      {{ option.name }}
                    </mat-radio-button>
                  </mat-radio-group>                 
                </div>
              </div>
              <div class="row" *ngIf="iscaseRequest">
                <div class="form-group col-md-12">
                  <label class="label-cls d-block">What is the visa outcome?</label>
                  <mat-radio-group aria-label="Select an option" name="outcome"
                    (change)="outcomeCodeIdChange()" [(ngModel)]="visa.outcomeIdCode" #outcomeIdCode="ngModel">
                    <mat-radio-button class="example-radio-button" *ngFor="let option of outcomeTypeList"
                      [value]="option.code">
                      {{ option.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 pt-2">
                  <mat-divider></mat-divider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 pt-2">
                  <h5 class="labelText">Enter visa details</h5>
                </div>
              </div>
              <div class="row pt-2">
                <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                  <label class="label-cls">USCIS Receipt Number</label>
                  <input class="im-input mw-100" type="text" name="noticeNo" [(ngModel)]="visa.noticeNo"
                    placeholder="Enter" #noticeNo="ngModel" pattern="^[A-Za-z0-9]+$" />
                  <mat-hint class="hint-text">Alphanumeric Characters only *</mat-hint>
                  <mat-error>                    
                    <span class="invalid-message" *ngIf="
                      (noticeNo.touched || visaDetailsForm.submitted) &&
                      noticeNo.errors?.pattern">
                      {{ getGlobalErrorMessages("INVALID_FORMAT") }}
                    </span>
                  </mat-error>
                </div>
                <div class="form-group col-md-6" 
                *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false): false">
                  <label class="label-cls">USCIS Receipt Number</label>
                  <input class="im-input mw-100" type="text" name="noticeNo" [(ngModel)]="visa.noticeNo"
                    placeholder="Enter" #noticeNo="ngModel" pattern="^[A-Za-z0-9]+$" />
                  <mat-hint class="hint-text">Alphanumeric Characters only *</mat-hint>                  
                </div>
                <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                  <label class="label-cls">USCIS Receipt Date</label>
                  <mat-form-field appearance="outline" class="mt-1 date-field">
                    <input matInput [matDatepicker]="receiptDtPicker" name="receiptDate" [max]="today"
                      #receiptDate="ngModel" [(ngModel)]="visa.receiptDate" placeholder="MM/DD/YYYY" (click)="receiptDtPicker.open()" />
                    <mat-datepicker-toggle matSuffix [for]="receiptDtPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #receiptDtPicker></mat-datepicker>
                  </mat-form-field>                  
                </div>
                <div class="form-group col-md-6" 
                *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <label class="label-cls">USCIS Receipt Date</label>
                  <mat-form-field appearance="outline" class="mt-0 date-field">
                    <input matInput [matDatepicker]="receiptDtPicker" name="receiptDate" [max]="today"
                      #receiptDate="ngModel" [(ngModel)]="visa.receiptDate" placeholder="MM/DD/YYYY" (click)="receiptDtPicker.open()" />
                    <mat-datepicker-toggle matSuffix [for]="receiptDtPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #receiptDtPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <!-- Denial  -->
              <div class="container-fluid" *ngIf="visa.outcomeIdCode === 'VSADENY'"
                style="padding-left: 0px; padding-right: 0px">
                <div class="row">
                  <div class="form-group col-md-8" *ngIf="!iscaseRequest">
                    <label class="label-cls">Reason for denial</label>
                    <input class="im-input mb-2 mw-100" type="text" name="reasonForDenial"
                      [(ngModel)]="visa.outcomeReason" placeholder="Enter" #reasonForDenial="ngModel" />                    
                  </div>
                  <div class="form-group col-md-8"  *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Reason for denial</label>
                    <input class="im-input mb-2 mw-100" type="text" name="reasonForDenial"
                      [(ngModel)]="visa.outcomeReason" placeholder="Enter" #reasonForDenial="ngModel" />
                  </div>
                  <div class="form-group col-md-4" *ngIf="!iscaseRequest">
                    <label class="label-cls">Denial Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="denialDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" [(ngModel)]="visa.outcomeDate" placeholder="MM/DD/YYYY" #outcomeDate="ngModel"
                        (click)="denialDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="denialDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #denialDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Denial Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="denialDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" [(ngModel)]="visa.outcomeDate" placeholder="MM/DD/YYYY" #outcomeDate="ngModel" (click)="denialDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="denialDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #denialDtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- APPROVED -->
              <div class="container-fluid" *ngIf="visa.outcomeIdCode === 'VSAAPP'"
                style="padding-left: 0px; padding-right: 0px">
                <div class="row">
                  <div class="form-group col-md-4" *ngIf="!iscaseRequest">
                    <label class="label-cls">USCIS Approved Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="approvedDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" #outcomeDate="ngModel" [(ngModel)]="visa.outcomeDate" placeholder="MM/DD/YYYY"
                        (click)="approvedDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="approvedDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #approvedDtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">USCIS Approved Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="approvedDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" #outcomeDate="ngModel" [(ngModel)]="visa.outcomeDate" placeholder="MM/DD/YYYY" (click)="approvedDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="approvedDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #approvedDtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-4" *ngIf="!iscaseRequest">
                    <label class="label-cls">Visa Validity Start Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <!-- IM-3888 Visa validity start date can be future date. / Validity start date can be earlier than the approval date-->
                      <!-- IM-4040 Validity start date can be earlier than the approval date but not less than receipt date-->
                      <input matInput [matDatepicker]="valStartDtPicker" name="validityStartDate"
                        #validityStartDate="ngModel" [(ngModel)]="visa.validityStartDate" placeholder="MM/DD/YYYY"
                        (dateChange)="visaValidityStartChange()" required [min]="visa.receiptDate"
                        (click)="valStartDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valStartDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valStartDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Visa Validity Start Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <!-- IM-3888 Visa validity start date can be future date. / Validity start date can be earlier than the approval date-->
                      <!-- IM-4040 Validity start date can be earlier than the approval date but not less than receipt date-->
                      <input matInput [matDatepicker]="valStartDtPicker" name="validityStartDate"
                        #validityStartDate="ngModel" [(ngModel)]="visa.validityStartDate" placeholder="MM/DD/YYYY"
                        (dateChange)="visaValidityStartChange()" [min]="visa.receiptDate"
                        (click)="valStartDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valStartDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valStartDtPicker></mat-datepicker>
                    </mat-form-field>                   
                  </div>
                  <div class="form-group col-md-4" *ngIf="!iscaseRequest">
                    <label class="label-cls">Visa Validity End Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valEndDtPicker" name="validityEndDate"
                        [min]="minVisaValidityEndDate" #validityEndDate="ngModel" [(ngModel)]="visa.validityEndDate"
                        placeholder="MM/DD/YYYY" (click)="valEndDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valEndDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valEndDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Visa Validity End Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valEndDtPicker" name="validityEndDate"
                        [min]="minVisaValidityEndDate" #validityEndDate="ngModel" [(ngModel)]="visa.validityEndDate"
                        placeholder="MM/DD/YYYY" (click)="valEndDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valEndDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valEndDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <label class="label-cls"><strong>Did you go through Consular Process for Visa Stamping?</strong></label>
                  </div>
                  <div class="col-md-6">
                    <mat-radio-group name="visaStamping"
                      aria-labelledby="example-radio-group-label" class="example-radio-group"
                      [(ngModel)]="visa.visaStamping">
                      <mat-radio-button class="example-radio-button" [value]="true">
                        Yes
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="false">
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>


                <div class="row" *ngIf="visa.visaStamping">
                  <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                    <label class="label-cls">Visa Issue Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valVisaIssueDateDtPicker" name="visaIssueDate"
                        #visaIssueDate="ngModel" [(ngModel)]="visa.visaIssueDate" placeholder="MM/DD/YYYY"
                        (dateChange)="visaIssueDateChange()" [min]="visa.receiptDate"
                        (click)="valVisaIssueDateDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valVisaIssueDateDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valVisaIssueDateDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Visa Issue Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valVisaIssueDateDtPicker" name="visaIssueDate"
                        #visaIssueDate="ngModel" [(ngModel)]="visa.visaIssueDate" placeholder="MM/DD/YYYY"
                        (dateChange)="visaIssueDateChange()" [min]="visa.receiptDate"
                        (click)="valVisaIssueDateDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valVisaIssueDateDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valVisaIssueDateDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-6" *ngIf="!iscaseRequest">
                    <label class="label-cls">Visa Expiration Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valVisaExpirationDateDtPicker" name="visaExpirationDate"
                        [min]="minVisaExpirationDate" #visaExpirationDate="ngModel" [(ngModel)]="visa.visaExpirationDate"
                        placeholder="MM/DD/YYYY" (click)="valVisaExpirationDateDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valVisaExpirationDateDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valVisaExpirationDateDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                  <div class="form-group col-md-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                    <label class="label-cls">Visa Expiration Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="valVisaExpirationDateDtPicker" name="visaExpirationDate"
                        [min]="minVisaExpirationDate" #visaExpirationDate="ngModel" [(ngModel)]="visa.visaExpirationDate"
                        placeholder="MM/DD/YYYY" (click)="valVisaExpirationDateDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="valVisaExpirationDateDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #valVisaExpirationDateDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                </div>



                <div class="row align-items-center" *ngIf="!iscaseRequest">
                  <div class="form-group col-md-8">
                    <label class="label-cls">EAD Number</label>
                    <input [disabled]="visa.isEadNo" class="im-input mb-2 mw-100" type="text" name="eadNo"
                      [(ngModel)]="visa.eadNo" placeholder="Enter" #eadNo="ngModel" />                   
                  </div>
                  <div class="form-group col-md-4">
                    <mat-checkbox (change)="eadUpdate()" name="isEadNo" [(ngModel)]="visa.isEadNo">
                      Not Applicable
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row align-items-center" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <div class="form-group col-md-8">
                    <label class="label-cls">EAD Number</label>
                    <input [disabled]="visa.isEadNo" class="im-input mb-2 mw-100" type="text" name="eadNo"
                      [(ngModel)]="visa.eadNo" placeholder="Enter" #eadNo="ngModel" />
                  </div>
                  <div class="form-group col-md-4">
                    <mat-checkbox (change)="eadUpdate()" name="isEadNo" [(ngModel)]="visa.isEadNo">
                      Not Applicable
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row align-items-center" *ngIf="!iscaseRequest">
                  <div class="form-group col-md-8">
                    <label class="label-cls">SEVIS Number</label>
                    <input class="im-input mb-2 mw-100" type="text" name="sevisNo" [disabled]="visa.isSevisNo"
                      [(ngModel)]="visa.sevisNo" placeholder="Enter" #sevisNo="ngModel" />                    
                  </div>
                  <div class="form-group col-md-4">
                    <mat-checkbox (change)="sevisUpdate()" name="isSevisNo" [(ngModel)]="visa.isSevisNo"
                      [disabled]="isVisaTypeF1">
                      Not Applicable
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row align-items-center" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <div class="form-group col-md-8">
                    <label class="label-cls">SEVIS Number</label>
                    <input class="im-input mb-2 mw-100" type="text" name="sevisNo" [disabled]="visa.isSevisNo"
                      [(ngModel)]="visa.sevisNo" placeholder="Enter" #sevisNo="ngModel" />
                  </div>
                  <div class="form-group col-md-4">
                    <mat-checkbox (change)="sevisUpdate()" name="isSevisNo" [(ngModel)]="visa.isSevisNo"
                      [disabled]="isVisaTypeF1">
                      Not Applicable
                    </mat-checkbox>
                  </div>
                </div>
                <!-- <div class="row">
                                <div class="col-md-12 form-group">
                                     <mat-slide-toggle name="hasTravelToUs" [(ngModel)]="visa.hasTravelToUs"
                                        labelPosition="before">Have you used this visa?</mat-slide-toggle>
                                </div>
                            </div> -->
                <div class="row align-items-center" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : true">
                  <div class="col-md-12">
                    <label class="label-cls">Have you used this visa?</label>
                  </div>
                  <div class="col-md-6">
                    <mat-radio-group name="hasTravelToUs" (change)="handleToggleHasTravelToUs()"
                      aria-labelledby="example-radio-group-label" class="example-radio-group"
                      [(ngModel)]="visa.hasTravelToUs">
                      <mat-radio-button class="example-radio-button" [value]="true">
                        Yes
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="false">
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <!-- WITHDRAWN -->
              <div class="container-fluid" *ngIf="visa.outcomeIdCode === 'VSAWTDN'"
                style="padding-left: 0px; padding-right: 0px">
                <div class="row" *ngIf="!iscaseRequest">
                  <div class="form-group col-md-8">
                    <label class="label-cls">Reason for withdrawal</label>
                    <input class="im-input mb-2 mw-100" type="text" name="reasonForDenial"
                      [(ngModel)]="visa.outcomeReason" placeholder="Enter" #reasonForDenial="ngModel" />                    
                  </div>
                  <div class="form-group col-md-4">
                    <label class="label-cls">Withdrawal Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="denialDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" #outcomeDate="ngModel" [(ngModel)]="visa.outcomeDate" 
                        placeholder="MM/DD/YYYY" (click)="denialDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="denialDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #denialDtPicker></mat-datepicker>
                    </mat-form-field>                    
                  </div>
                </div>
                <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : false">
                  <div class="form-group col-md-8">
                    <label class="label-cls">Reason for withdrawal</label>
                    <input class="im-input mb-2 mw-100" type="text" name="reasonForDenial"
                      [(ngModel)]="visa.outcomeReason" placeholder="Enter" #reasonForDenial="ngModel" />
                  </div>
                  <div class="form-group col-md-4">
                    <label class="label-cls">Withdrawal Date</label>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input matInput [matDatepicker]="denialDtPicker" name="outcomeDate" [min]="visa.receiptDate"
                        [max]="today" #outcomeDate="ngModel" [(ngModel)]="visa.outcomeDate" 
                        placeholder="MM/DD/YYYY" (click)="denialDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="denialDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #denialDtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : true">
                <div class="col-md-12 form-group">
                  <mat-checkbox (change)="updateCurrentlyUS()" name="currentlyStayInUS"
                    [(ngModel)]="visa.currentlyStayInUS">
                    Currently in the U.S
                  </mat-checkbox>
                </div>
              </div>              
              <div class="next-btn">                
                <hr />              
                <div class="d-flex flex-row-reverse">
                  <div class="p-2">
                    <button type="button" class="btn btn-primary" matStepperNext [disabled]="!hasID">
                      Next
                    </button>
                  </div>
                  <!-- IM-3933 : Beneficiary Profile- Immigration section- Click on Add/Edit immigration button -->
                  <div class="p-2">
                    <button *ngIf="!modeVal" type="button" class="button_bg-gray" (click)="closeForm(visaDetailsForm)">
                      Close
                    </button>
                  </div>
                  <div class="p-2">
                    <button type="submit" class="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step *ngIf="visa.outcomeIdCode === 'VSAAPP' && (iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true)">
            <ng-template matStepLabel> U.S. Stay History</ng-template>
            <div class="col-12 mt-2 p-2" *ngIf="!visa.hasTravelToUs">
              <p>
                Since you never travelled to U.S. on this Visa, You do not need to
                enter U.S. Stay History
              </p>

              <div class="row pt-3">
                <div class="col-md-6">
                  <button type="button" class="back-button" matStepperPrevious>
                    Back
                  </button>
                </div>
                <!-- IM-4059 Close and back - next button added -->
                <div class="col-md-6 text-right">
                  <button *ngIf="!modeVal" type="button" class="back-button mr-3" (click)="closeForm(visaDetailsForm)">
                    Close
                  </button>
                  <button type="button" class="btn btn-primary float-right" matStepperNext>
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="visa.hasTravelToUs" (click)="trackTravelHisFormChange(travelHistoryForm)">
              <form class="mt-2" (ngSubmit)="saveTravelHistory(travelHistoryForm)"
                #travelHistoryForm="ngForm">
                <div class="row" *ngIf="!iscaseRequest">
                  <div class="form-group col-md-4">
                    <label class="label-cls"><span>I94 Number</span> <small> (max 11 Digit)</small></label>
                    <input class="im-input mb-2 mw-100" type="text" name="i94Number" placeholder="Enter"
                      #i94Number="ngModel" [(ngModel)]="travelHistory.i94Number" maxlength="11" />
                  </div>
                  <div class="form-group col-md-4">
                    <label class="label-cls">{{isVisaTypeF1 ? 'I94 Expiry' : 'I94 Expiry Date'}}</label>
                    <input *ngIf="isVisaTypeF1" class="im-input mb-2 mw-100" type="text" name="durationOfStatus" placeholder="Enter"
                      #durationOfStatus="ngModel" [(ngModel)]="travelHistory.durationOfStatus" />
                    <mat-form-field *ngIf="!isVisaTypeF1" appearance="outline" class="mt-1 date-field">
                      <input matInput [matDatepicker]="i94ExpiryDtPicker" name="i94ExpiryDate" #i94ExpiryDate="ngModel"
                        [(ngModel)]="travelHistory.i94ExpiryDate" placeholder="MM/DD/YYYY" (click)="i94ExpiryDtPicker.open()" />
                      <mat-datepicker-toggle matSuffix [for]="i94ExpiryDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #i94ExpiryDtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : false">
                  <div class="form-group col-md-4">
                    <label class="label-cls"><span>I94 Number</span> <small> (max 11 Digit)</small></label>
                    <input class="im-input mb-2 mw-100" type="text" name="i94Number" placeholder="Enter"
                      #i94Number="ngModel" [(ngModel)]="travelHistory.i94Number" maxlength="11" />
                  </div>
                  <div class="form-group col-md-4">
                    <label class="label-cls required">{{isVisaTypeF1 ? 'I94 Expiry' : 'I94 Expiry Date'}}</label>
                    <mat-form-field *ngIf="!isVisaTypeF1" appearance="outline" class="mt-1 date-field">
                      <input matInput [matDatepicker]="i94ExpiryDtPicker" name="i94ExpiryDate" #i94ExpiryDate="ngModel"
                        [(ngModel)]="travelHistory.i94ExpiryDate" placeholder="MM/DD/YYYY" (click)="i94ExpiryDtPicker.open()" required/>
                      <mat-datepicker-toggle matSuffix [for]="i94ExpiryDtPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #i94ExpiryDtPicker></mat-datepicker>
                    </mat-form-field>
                    <input *ngIf="isVisaTypeF1" class="im-input mb-2 mw-100" type="text" name="durationOfStatus" placeholder="Enter"
                      #durationOfStatus="ngModel" [(ngModel)]="travelHistory.durationOfStatus" />
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <mat-divider></mat-divider>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12 mt-2">
                    <h5 class="labelText">
                      Let us know your Stay(Arrival and Exit) Info
                      <mat-icon class="mr-1 icn iconPosition" (click)="openInfoModal()" matTooltip="info">
                        info
                      </mat-icon>
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 borderRight">
                    <div class="row no-gutters" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true">
                      <div class="col-12">
                        <mat-radio-group name="isCurrentlyUSChecked"
                          (change)="handleToggleArrivalDate(isCurrentlyUSChecked)"
                          aria-labelledby="example-radio-group-label" class="example-radio-group"
                          [(ngModel)]="isCurrentlyUSChecked">
                          <mat-radio-button class="example-radio-button" [checked]="isCurrentlyUSChecked"
                            [value]="true">
                            Start of stay on this visa
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" [checked]="!isCurrentlyUSChecked"
                            [value]="false">
                            Arrival Date
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row no-gutters" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true">
                      <div class="col-12" *ngIf="!isCurrentlyUSChecked">
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="arrivalDtPicker" name="arrivalDate" placeholder="MM/DD/YYYY"
                            [min]="min" #arrivalDate="ngModel" [(ngModel)]="travelHistory.arrivalDate"
                            [visaInput]="visa" arrivalDateValidator (click)="arrivalDtPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="arrivalDtPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #arrivalDtPicker></mat-datepicker>
                        </mat-form-field>                        
                      </div>
                      <div class="col-12" *ngIf="isCurrentlyUSChecked">
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="effectiveStartDtPicker" name="effectiveStartDate"
                            placeholder="MM/DD/YYYY" [min]="min" #effectiveStartDate="ngModel"
                            [(ngModel)]="travelHistory.effectiveStartDate" [visaInput]="visa" arrivalDateValidator
                           (click)="effectiveStartDtPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="effectiveStartDtPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #effectiveStartDtPicker></mat-datepicker>
                        </mat-form-field>                        
                      </div>
                    </div>
                    <div class="row no-gutters mt-3" *ngIf="!isCurrentlyUSChecked">
                      <div class="form-group col-md-6 pr-1" *ngIf="!iscaseRequest">
                        <label>Mode Of Travel</label>
                        <input class="im-input mb-2" type="text" name="meansOfTravel" placeholder="Enter"
                          #meansOfTravel="ngModel" [(ngModel)]="travelHistory.meansOfTravel" />                        
                      </div>
                      <div class="form-group col-md-6 pr-1" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : false">
                        <label>Mode Of Travel</label>
                        <input class="im-input mb-2" type="text" name="meansOfTravel" placeholder="Enter"
                          #meansOfTravel="ngModel" [(ngModel)]="travelHistory.meansOfTravel" />
                      </div>
                      <div class="form-group col-md-6 pl-1" *ngIf="!iscaseRequest">
                        <label>Port of entry into the US</label>
                        <input class="im-input mb-2" type="text" name="portOfEntry" placeholder="Enter"
                          #portOfEntry="ngModel" [(ngModel)]="travelHistory.portOfEntry" />                       
                      </div>
                      <div class="form-group col-md-6 pl-1" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : false">
                        <label>Port of entry into the US</label>
                        <input class="im-input mb-2" type="text" name="portOfEntry" placeholder="Enter"
                          #portOfEntry="ngModel" [(ngModel)]="travelHistory.portOfEntry" />
                      </div>

                      <!-- arrivalCountryCode?: string;
                    arrivalStateProvinceCode?:string;
                    arrivalCity?: string; -->
                    <div class="form-group col-md-6 pr-1" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true">
                        <label>Arrival Country </label>
                        <p-dropdown (onChange)="handleCountryChange($event.value)" [options]="listArrivalCountry" name="arrivalCountryCode" [(ngModel)]="travelHistory.arrivalCountryCode" styleClass="bene-we-dropdown" #arrivalCountryCode="ngModel" optionValue="countryCode" optionLabel="countryName" placeholder="Select" [filter]="true" filterBy="countryName" appendTo="body">
                        </p-dropdown>
                      </div>
                      <div class="form-group col-md-6 pl-1" *ngIf="(listStates.length >0) && (iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true)">
                        <label>Arrival State </label>
                        <p-dropdown (onChange)="handleStateChange($event.value)" [options]="listStates" name="arrivalStateProvinceCode" [(ngModel)]="travelHistory.arrivalStateProvinceCode" styleClass="bene-we-dropdown" #arrivalStateProvinceCode="ngModel" optionValue="stateProvinceCode" optionLabel="stateProvinceName" placeholder="Select" [filter]="true" filterBy="stateProvinceName">
                        </p-dropdown>
                      </div>

                      <div class="col-6 pl-1" *ngIf="(listStates.length <= 0) && (iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true)">
                        <label>Arrival State </label>
                        <input class="im-input col-md mt-0" type="text" name="arrivalStateProvincName" placeholder="State"
                          #arrivalStateProvincName="ngModel" [(ngModel)]="travelHistory.arrivalStateProvinceName">
                      </div>

                      <div class="form-group col-md-6 pl-1" *ngIf="!iscaseRequest">
                        <label>Arrival City</label>
                        <input class="im-input mb-2" type="text" name="arrivalCity" placeholder="Enter"
                          #arrivalCity="ngModel" [(ngModel)]="travelHistory.arrivalCity" />                        
                      </div>
                      <div class="form-group col-md-6 pl-1" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : false">
                        <label>Arrival City</label> 
                        <input class="im-input mb-2" type="text" name="arrivalCity" placeholder="Enter"
                          #arrivalCity="ngModel" [(ngModel)]="travelHistory.arrivalCity" />
                      </div>
                    </div>
                    <!-- <div class="row" *ngIf="!isCurrentlyUSChecked">



                    <div class="form-group col-md-4 pl-1">
                      <label>Arrival State</label>
                      <input
                        class="im-input mb-2"
                        type="text"
                        name="arrivalStateProvinceCode"
                        placeholder="Enter"
                        #arrivalStateProvinceCode="ngModel"
                        [(ngModel)]="travelHistory.arrivalStateProvinceCode"
                        required
                      />
                      <span
                        class="invalid-message"
                        *ngIf="
                          (arrivalStateProvinceCode.touched ||
                            travelHistoryForm.submitted) &&
                            arrivalStateProvinceCode.errors?.required
                        "
                      >
                        {{ getGlobalErrorMessages("REQUIRED") }}
                      </span>
                    </div>

                    <div class="form-group col-md-4 pl-1">
                      <label>Arrival City</label>
                      <input
                        class="im-input mb-2"
                        type="text"
                        name="arrivalCity"
                        placeholder="Enter"
                        #arrivalCity="ngModel"
                        [(ngModel)]="travelHistory.arrivalCity"
                        required
                      />
                      <span
                        class="invalid-message"
                        *ngIf="
                          (portOfEntry.touched ||
                            travelHistoryForm.submitted) &&
                          portOfEntry.errors?.required
                        "
                      >
                        {{ getGlobalErrorMessages("REQUIRED") }}
                      </span>
                    </div>
                  </div> -->


                  </div>
                  <div class="col-6">
                    <div class="row no-gutters" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true">
                      <div class="col-12">
                        <mat-radio-group name="isDepatureChecked"
                          (change)="handleToggleDepartureDate(isDepatureChecked)"
                          aria-labelledby="example-radio-group-label" class="example-radio-group"
                          [(ngModel)]="isDepatureChecked">
                          <mat-radio-button class="example-radio-button" [value]="false">
                            End of stay on this visa
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="true">
                            Depature Date
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row no-gutters" *ngIf="iscaseRequest ? (currentItemDisplay.includes('USSTAYHIST') ? true: false) : true">
                      <div class="col-8" *ngIf="isDepatureChecked">
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="ExitDtPicker" name="exitDate" #exitDate="ngModel"
                            [(ngModel)]="travelHistory.exitDate" placeholder="MM/DD/YYYY"
                            [min]="travelHistory.arrivalDate" (click)="ExitDtPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="ExitDtPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #ExitDtPicker></mat-datepicker>
                        </mat-form-field>
                        <!-- IM-3831 : For able to save visa details -->
                        <span class="invalid-message" *ngIf="exitDate?.touched && exitDate.errors">
                          Departure date cannot be greater than Arrival Date
                          {{ travelHistory.arrivalDate | ImgDateFormat }}
                        </span>
                      </div>
                    </div>
                    <div class="row no-gutters">
                      <div class="col-8" *ngIf="!isDepatureChecked">
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="effectiveEndDtPicker" name="effectiveEndDate"
                            #effectiveEndDate="ngModel" [(ngModel)]="travelHistory.effectiveEndDate"
                            placeholder="MM/DD/YYYY" [min]="travelHistory.effectiveStartDate"
                            (click)="effectiveEndDtPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="effectiveEndDtPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #effectiveEndDtPicker></mat-datepicker>
                        </mat-form-field>
                        <!-- IM-3831 : For able to save visa details -->
                        <span class="invalid-message" *ngIf="exitDate?.touched && exitDate.errors">
                          Departure date cannot be greater than Arrival Date
                          {{ travelHistory.arrivalDate | ImgDateFormat }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary">
                      Save & Add More
                    </button>
                  </div>
                  <div class="col-md-12">
                    <small>* You can add multiple travel history by clicking "Save &
                      Add More" button
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <mat-divider></mat-divider>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-md-6">
                    <button type="button" class="back-button" matStepperPrevious>
                      Back
                    </button>
                  </div>
                  <div class="col-md-6 text-right">
                    <button type="button" *ngIf="!modeVal" class="back-button mr-3" (click)="
                      stepper.reset();
                      visaDetailsForm.reset({});
                      travelHistoryForm.reset({});
                      toggleExpansion();
                      resetImmigration();
                    ">
                      Close
                    </button>
                    <button type="button" class="btn btn-primary float-right" matStepperNext>
                      Next
                    </button>
                  </div>
                </div>
              </form>
              <div class="pt-3">
                <h5>Travel History List</h5>
              </div>
              <div class="pt-2 mb-3" *ngIf="!isTravelHistoryValid">
                <small class="invalid-message">
                  For this visa, you mentioned that you were in US, so one of your
                  travel history shouldn't have the exit date. Please update it
                  properly to calculate your stay at US.
                </small>
              </div>
              <table class="im-card-no-border-table" mat-table [dataSource]="travelInfoDataSource">
                <ng-container *ngFor="let column of columnsTravelInfo" [matColumnDef]="column.columnDef">
                  <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                  <td mat-cell *matCellDef="let row">
                    <span class="column.customClass" *ngIf="column.columnType == 'icon'">
                      <mat-icon class="mr-1 icn" class="im-table-verticle-icon"
                        (click)="editTravelInfo(row);trackTravelHisFormChange(travelHistoryForm)" matTooltip="Edit"
                        *ngIf="!viewMode">
                        edit
                      </mat-icon>
                      <mat-icon class="mr-1 icn" (click)="deleteTravelInfo(row)" matTooltip="Delete" *ngIf="!viewMode">
                        delete_forever
                      </mat-icon>
                    </span>
                    <span *ngIf="column.columnType == 'text'">
                      {{ row[column.columnDef] }}
                    </span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForTravelInfo"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumnsForTravelInfo"
                  class="example-element-row"></tr>
              </table>
            </div>
          </mat-step>
          <mat-step 
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('VISADTLS') ? true: false) : true">
            <ng-template matStepLabel>Upload Documents</ng-template>  
            <div class="row formBox">
              <div class="col-md-12 mt-2">
                <app-file-upload style="width: 100%" [configurations]="configurations"
                  (fileUploadSuccess)="fileUploadSuccess()">
                </app-file-upload>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button type="button" class="back-button" matStepperPrevious>
                  Back
                </button>
              </div>
              <!-- IM-3886 : option to navigate to 3rd step or close. -->
              <div class="col-md-6 text-right">
                <button *ngIf="!modeVal" class="back-button mr-3" (click)="closeForm(visaDetailsForm)">
                  Close
                </button>
                <button type="button" class="btn btn-primary float-right" (click)="stepper.reset();
                          visaDetailsForm.reset();
                          resetTravalHistoryForm();
                          resetImmigration()" *ngIf="!viewMode">
                  Add New Immigration
                </button>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
  <div class="spacing im-card-no-border-table" [ngClass]="{disabledView: isViewMode}" *ngIf=" (isListView && neverAppliedFlag === 'Yes') ||
          (isListView && dataSource.data.length > 0) ">
    <div class="p-3">
      <div>
        {{
        dataSource && dataSource.data.length > 0 ? dataSource.data.length : "No"
        }}
        Immigration details Added by you
      </div>
    </div>
    <table class="im-card-no-border-table" multiTemplateDataRows mat-table [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <mat-icon matTooltip="View more" class="mr-1 icn">expand_more</mat-icon>
          </span>
          <div *ngIf="updateBenProfile && showEditButton">
            <ng-container *ngIf="!isExternalEmployeeId">
            <span class="column.customClass" *ngIf="column.columnType == 'icon'">
              <mat-icon class="mr-1 icn" matTooltip="Edit" *ngIf="!viewMode" class="im-table-verticle-icon"
                (click)="editImmigration(row);visaFormCheck(visaDetailsForm)">
                edit
              </mat-icon>
              <mat-icon class="mr-1 icn" matTooltip="Delete" (click)="deleteImmigration(row)" *ngIf="!viewMode">
                delete_forever
              </mat-icon>
            </span>
          </ng-container>
          </div>
          <span *ngIf="column.columnType == 'text'">
            {{ row[column.columnDef] }}
          </span>
        </td>
      </ng-container>
      <div class="example-element-description">
        <ng-container matColumnDef="expandedDetail">
          <td class="w40" mat-cell *matCellDef="let element; let i = index" [attr.colspan]="columns.length">
            <div class="example-element-detail" [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            ">
              <div class="w100 example-element-description py-1">
                <ng-container *ngIf="element.outcomeIdCode === 'VSAAPP'">
                  <table class="im-card-no-border-table">
                    <thead>
                      <tr>
                        <th>Approved Date</th>
                        <th>Validity Start Date</th>
                        <th>Validity End Date</th>
                        <th>EAD Number</th>
                        <th>SEVIS Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td nowrap>
                          {{ element.outcomeDate | ImgDateFormat }}
                        </td>
                        <td>
                          {{ element.validityStartDate | ImgDateFormat }}
                        </td>
                        <td nowrap>
                          {{ element.validityEndDate | ImgDateFormat }}
                        </td>
                        <td>
                          {{ element.eadNo }}
                        </td>
                        <td nowrap>
                          {{ element.sevisNo }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
                <ng-container *ngIf="element.outcomeIdCode === 'VSADENY'">
                  <table class="im-card-no-border-table">
                    <thead>
                      <tr>
                        <th style="width: 80%">Reason For Denial</th>
                        <th style="width: 80%">Denial Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td nowrap>
                          {{ element.outcomeReason }}
                        </td>
                        <td>
                          {{ element.outcomeDate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
                <ng-container *ngIf="element.outcomeIdCode === 'VSAWTDN'">
                  <table class="im-card-no-border-table">
                    <thead>
                      <tr>
                        <th style="width: 80%">Reason For Withdrawal</th>
                        <th style="width: 80%">Withdrawal Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td nowrap>
                          {{ element.outcomeReason }}
                        </td>
                        <td>
                          {{ element.outcomeDate | ImgDateFormat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
              <div class="w100 example-element-description py-1">
                <h6>Travel History</h6>
                <ng-container>
                  <table class="im-card-no-border-table">
                    <thead>
                      <tr>
                        <th class="w40"></th>
                        <th>Mode Of Travel</th>
                        <th>Port</th>
                        <th>Arrival Date</th>
                        <th>I94 Number</th>
                        <th>I94 Expiry Date</th>
                        <th>Exit Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let travel of element.travelInfo; let i = index">
                        <td class="w40">
                          <div class="circleIndex rounded-circle">
                            {{ i + 1 }}
                          </div>
                        </td>
                        <td>
                          {{ travel.meansOfTravel }}
                        </td>
                        <td nowrap>
                          {{ travel.portOfEntry }}
                        </td>
                        <td>
                          {{ travel.arrivalDate | ImgDateFormat }}
                        </td>
                        <td nowrap>
                          {{ travel.i94Number }}
                        </td>
                        <td>
                          {{ travel.i94ExpiryDate | ImgDateFormat }}
                        </td>
                        <td nowrap>
                          {{ travel.exitDate | ImgDateFormat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
              <div class="w100 example-element-description py-1">
                <h6>List of Documents</h6>
                <ng-container>
                  <table class="im-card-no-border-table">
                    <thead>
                      <tr>
                        <th class="w40"></th>
                        <th>Document</th>
                        <th>Document Type</th>
                        <th>
                          <i class="fa fa-download fa-lg icn" aria-hidden="true"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of element.documentList; let i = index">
                        <td class="w40">
                          <div class="circleIndex rounded-circle">
                            {{ i + 1 }}
                          </div>
                        </td>
                        <td>
                          <strong>{{ item.fileName }}</strong>
                        </td>
                        <td nowrap>
                          <!-- IM-8688 -->
                          {{ item?.fileCategory?.code === 'OTHER' ? item?.fileCategoryOtherName :
                          item?.fileCategory?.name }}
                        </td>
                        <td nowrap>
                          <app-file-download [fileName]="item.fileLocation.substring(23)"
                            [Path]="envPath + item.fileLocation.substring(23)"></app-file-download>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>