<form [formGroup]="dutiesForm" class="container p-0" (ngSubmit)="appendDutyList()">
    <div>
        <h5> Enter the list of duties required for this job</h5>

        <div class="row mt-2">
            <label for="col-md-12 pl-0" class="mb-0 control-lbl required"> Duty that the beneficiary will perform</label>
            <div class="col-md-12 pl-0">
                <textarea class="im-input text-area duty-txt" type="textarea" required formControlName="name"></textarea>
            </div>
            <div class="invalid-message"
                *ngIf="(dutiesForm.get('name').touched  && dutiesForm.get('name').errors?.required)">This Field is
                required
            </div>
        </div>

        <div class="row mt-2">
            <div>
                <label class="mb-0 control-lbl"> Sub Duties </label>
                <span class="ml-3"> <a href="JavaScript:Void(0)" (click)="addSubDuty()"> + New Sub Duty </a></span>
            </div>
            <div class="col-md-12 pl-0" style="max-height: 200px; overflow-y: auto; ">
                <div formArrayName="subJobDuty">
                    <div *ngFor="let duty of subDutiesList; let subDutyIndex= index">
                        <div class="col-md-12 row pl-0 pr-0 mt-2" [formGroupName]="subDutyIndex">
                            <textarea type="textarea" class="im-input text-area duty-txt"
                                formControlName="name"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    <div class="row col-md-12 dasheedTopBorder mt-4 pt-3 pl-0">
        <div class="pl-0">
            <button type="submit" class="btn btn-primary"
                [ngClass]="dutiesForm.invalid ? 'btn btn-primary btn-success_disabled ':'btn btn-primary mr-3 '"
                [disabled]="dutiesForm.invalid">Save
            </button>
        </div>
        <div class="col-md-3">
            <button type="button" (click)="handleClose('CANCEL')" class="btn btn-primary">Close </button>
        </div>
    </div>

</form>