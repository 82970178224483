<div class="container-fluid" *ngIf="primaryData && addressHistoryForm">
  <form [formGroup]="addressHistoryForm">
    <div>
      <div class="row d-flex align-items-center">
        <div class="col-md-12 step-title">
          <h5 class="mb-0">Address History<small>(Physical addresses for the last {{historyLength}} )</small></h5>
        </div>
      </div>
      <p-divider></p-divider>
      <div>
        <div class="col-10 pl-0">
          <div class="row">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls d-block"> <b>In care of </b><i><small>(optional)</small></i> </label>
              <input class="im-input mw-100" id="inCareOfName" [class.disabled]="disabledControls" formControlName="inCareOfName"
              type="text" pInputText placeholder="Enter" />
            </div>
          </div>
          <app-address (childAddressForm)="addAddressFormControlToParentForm($event)"
            [selectedCountryAndStateCode]="selectedCountryAndStateCode" [addressReadOnly]="disabledControls"
            [formDisabled]="addressFormDisabled">
          </app-address>
          <div class="row">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls d-block"> <b>County</b><i><small>(optional)</small></i> </label>
              <input class="im-input mw-100" id="inCareOfName" [class.disabled]="disabledControls" formControlName="county"
              type="text" pInputText placeholder="Enter" />
            </div>
          </div>
          <div class="row w-100">
            <h5 class="sub-title ml-3">Date of Residence</h5>
          </div>          
          <div class="row">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls required d-block"> From </label>
              <p-calendar class="im-input-calendar mw-100" styleClass="p-form-datepicker" appendTo="body"
                dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [maxDate]="addressHistoryForm.get('dateRangeTo').value || currentDate" [showIcon]="true"
                [disabled]="disabledControls" formControlName="dateRangeFrom"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}"
                [showButtonBar]="true">
              </p-calendar>
              <div class="invalid-message"
                *ngIf="addressHistoryForm.get('dateRangeFrom').touched && addressHistoryForm.get('dateRangeFrom').errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls d-block" [ngClass]="{'required': !currentDisable}"> To
              </label>
              <p-calendar class="im-input-calendar mw-100" appendTo="body" styleClass="p-form-datepicker"
                dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                [minDate]="addressHistoryForm.get('dateRangeFrom').value" [maxDate]="currentDate"
                [disabled]="disabledControls || !addressHistoryForm.get('dateRangeFrom').value || currentDisable"
                formControlName="dateRangeTo" [showButtonBar]="true" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{fromYear}}:{{toYear}}">
              </p-calendar>
              <div class="invalid-message"
                *ngIf="addressHistoryForm.get('dateRangeTo').touched && addressHistoryForm.get('dateRangeTo').errors?.dateRangeToFieldIsRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
          </div>
          <div class="row col-12 form-group"
            *ngIf="primaryData.familyId === userId && !(['K1', 'K3'].includes(this.primaryData.visatype))">
            <div class="p-field-checkbox">
              <p-checkbox id="livedWithSpouse" formControlName="hasLivedTogthr" [disabled]="disabledControls"
                binary="true">
              </p-checkbox>
              <label class="label-cls">Lived with a spouse?</label>
            </div>
          </div>
          <div class="row w-100"
            *ngIf="addressHistoryForm.get('hasLivedTogthr').value && primaryData.familyId === userId">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls required">Lived together - From </label>
              <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [maxDate]="addressHistoryForm.get('liveTogetherToDate').value || currentDate" [showIcon]="true"
                [disabled]="disabledControls" formControlName="liveTogetherFromDate"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}"
                [showButtonBar]="true">
              </p-calendar>
              <div class="invalid-message"
                *ngIf="addressHistoryForm.get('liveTogetherFromDate').touched && addressHistoryForm.errors?.liveTogetherFromDateFieldRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls required">Lived together - To </label>
              <p-calendar appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [showIcon]="true" [minDate]="addressHistoryForm.get('liveTogetherFromDate').value" [maxDate]="currentDate"
                [disabled]="disabledControls || !addressHistoryForm.get('liveTogetherFromDate').value"
                formControlName="liveTogetherToDate" [showButtonBar]="true" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}">
              </p-calendar>
              <div class="invalid-message"
                *ngIf="addressHistoryForm.get('liveTogetherToDate').touched && addressHistoryForm.errors?.liveTogetherToDateFieldRequired">
                {{getGlobalErrorMessages('REQUIRED')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 p-0">
      <div class="row mb-4">
        <div class="col-12">
          <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-outline-primary mr-3"
            [disabled]="disabledControls || !addressHistoryForm.valid"
            [ngClass]="{'disabled': disabledControls || !addressHistoryForm.valid }"
            (click)="saveAddress()">ADD</button>
          <button type="button" *ngIf="!disabledControls" class="p-btn btn btn-light"
            (click)="cancelForm()">CANCEL</button>
        </div>
      </div>
      <div class="row w-100 mt-4 mb-5">
        <div class="container-fluid">
          <h5 class="sub-title text-primary">Added Details</h5>
          <p-table tableStyleClass="i130-address-table" [value]="addressList" *ngIf="addressList.length > 0">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" class="col-6 px-0">Address 1</th>
                <th scope="col" class="col-6">Type</th>
                <th scope="col" class="col-4">Date of Residence </th>
                <th scope="col" class="col-2 px-0">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-doc let-ri="rowIndex">
              <tr>
                <td class="col-6 px-0">{{doc.addressLine1}}</td>
                <td class="col text-uppercase">{{(doc.type)? doc.type?.description: 'PHYSICAL ADDRESS'}}</td>
                <td class="col-4" *ngIf="doc.dateRangeTo">{{doc.dateRangeFrom | date:'MMM yyyy'}} - {{doc.dateRangeTo |
                  date:'MMM yyyy'}}</td>
                <!-- <td class="col-4" *ngIf="!doc.dateRangeTo">{{doc.dateRangeFrom | date:'MMM yyyy'}} - Present</td> -->
                
                <td class="col-4" *ngIf="!doc.dateRangeTo && !doc.dateRangeFrom">Present</td>
                <td class="col-4" *ngIf="!doc.dateRangeTo && doc.dateRangeFrom">{{doc.dateRangeFrom | date:'MMM yyyy'}}
                  - Present</td>
                <td class="col-2 px-0">
                  <div class="row action-btn" *ngIf="!doc.dateRangeTo">
                    <div class="p-0">
                      <button pButton type="button" title="Edit" icon="pi pi-pencil" (click)="editDocument(doc)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      </button>
                    </div>
                    <!-- <div class="p-0">
                      <button pButton type="button" [disabled]="disabledControls" title="Delete" icon="pi pi-trash"
                        (click)="deleteDocument(doc.addressHistoryId)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      </button>
                    </div> -->
                  </div>
                  <div class="row action-btn" *ngIf="doc.dateRangeTo">
                    <div class="p-0">
                      <button pButton type="button" [disabled]="disabledControls" title="Edit" icon="pi pi-pencil"
                        (click)="editDocument(doc)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      </button>
                    </div>
                    <div class="p-0">
                      <button pButton type="button" [disabled]="disabledControls" title="Delete" icon="pi pi-trash"
                        (click)="deleteDocument(doc.addressHistoryId)"
                        class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div class="no-data-box" *ngIf="addressList.length == 0">
            <p>Currently No Details Added</p>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
