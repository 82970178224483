import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { GCI140State, storeFeatureKey } from '..';

//Created subTaskDetails Feature Selector out of GCI140 state
export const getGCI140Feature = createFeatureSelector<GCI140State>(
    storeFeatureKey
);

//Creating subTaskDetails Selector using subTaskDetails Feature Selector
// return complete sub task details
export const getSubTaskDetails = createSelector(
    getGCI140Feature,
    (state: GCI140State) => {
        return state.subTaskDetails;
    }
);


//Get Step List out of Sub Task Details
// Returns the step list
export const getSubTaskStepList = createSelector(
    getSubTaskDetails,
    (state: SubTaskDetails): TaskStep[] => {
        return state.taskStepDetails;
    }
);

//Get specific step put of Step list
// Returns the step details based on step code
export const getStepDetails = createSelector(
    getSubTaskStepList,
    (stepList: TaskStep[], props: { id: number }): TaskStep => {
        return stepList && stepList.length > 0 ? stepList.find(x => x.id === props.id) : null;
    }
);


