import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import * as errorUtils from 'error-message-utility';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ApiServicesService } from '../../../beneficiary/services/api-services.service';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { getStepDetails, updateStepStatus, updateStepStatusToInitialState } from 'visa-store';


import { Subject } from 'rxjs/internal/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { first } from 'rxjs/internal/operators/first';
import { ImagilityBaseResponse, PrimaryData, ReferenceLibrary } from 'app-models';
import { PastJobDescriptionService } from './past-job-description.service';

import { I140FileUploadConfiguration } from 'app-models';
import { DirtyFormGuard, MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { combineLatest } from 'rxjs';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { JobDescriptionService } from '../job-description/job-description.service';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-past-job-description',
  templateUrl: './past-job-description.component.html',
  styleUrls: ['./past-job-description.component.scss']
})
export class PastJobDescriptionComponent implements OnInit, AfterViewInit, OnDestroy {

  inputData: PrimaryData;
  jobDescriptionForm: FormGroup;
  jobDescription: any;
  listDuties: [{
    id: number,
    name: string,
    petitionId: number,
    positionId: number,
    sequenceNo: number
  }];
  subJobDuty: {
    id: number,
    duty: string,
    name: string,
  }[];
  disabledControls: boolean;
  wageUnitType: ReferenceLibrary[];
  countryList: [];
  stateList: [];
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  titleSoc: string;

  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  guardSubscription: any;

  addressList = [];
  address: FormArray;
  fileUploadConfigurations: I140FileUploadConfiguration;
  statelistArrObj: {
    workAddress: {
      countryCode: string,
      stateList: any[]
    }[];
  } = {
      workAddress: []
    };
  deleteWorkLocReqArr: any[] = [];
  stockOwnershipFeinArr: FormArray;
  employmentInterruptionsArr: FormArray;

  constructor(
    private jobDescriptionBuilder: FormBuilder,
    private store: Store<VisaExtendedState>,
    private pastJobDescriptionService: PastJobDescriptionService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountyService: CacheCountryService,
    private guard: DirtyFormGuard,
    private apiServiceState: ApiServicesService,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    public dialog: MatDialog,
    private jobDescriptionService: JobDescriptionService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.countryList = [];
    this.stateList = [];
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
  }

  ngOnInit() {
    this.jobDescriptionForm = this.jobDescriptionBuilder.group({
      id: [0],
      jobTitle: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      socCode: [null],
      socTitle: [null],
      duties: this.jobDescriptionBuilder.array([]),
      address: this.jobDescriptionBuilder.array([]),
      wagesFrom: ['', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
      wagesTo: [null],
      wageTypeCode: ['', Validators.required],
      wageTypeName: [''],
      thisFulltime: [true],
      workingHoursPerWeek: [''],
      thisPermanentPos: [],
      thisNewPos: [],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      workLocationId: [null],
      caseId: [],
      socCode1: [null],
      socCode2: [null],
      thisPastJob: [true],
      selectedCategory: [null],
      jobRoleJD: [null],
      otherJobPosition: [null],
      selectedRaiobtn: [0],
      stockOwnershipFeinArr:this.jobDescriptionBuilder.array([]),
      employmentInterruptionsArr:this.jobDescriptionBuilder.array([]),
      isSameBeneficiaryEmploymentWithAbroad:[null],
      beneficiaryWorksSupervisedInfo:[null],
      specializedKnowledgeDuties:[null]
    });
    this.getCountries();
    this.getWageUnitType();
    this.updateFormControls();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.getJobDescription();

        this.fileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: false,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/FORGNOFFLTR/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/FORGNOFFLTR/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'FORGNOFFLTR'
        });

        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;
        this.toggleControlsStatus();
        // this.handleCountryChange('USA');
      });
  }

  getAddressFormArray() {
    return (this.jobDescriptionForm.get('address') as FormArray).controls;
  }
  getStockOwnerShipFeinFormArray() {
    return (this.jobDescriptionForm.get('stockOwnershipFeinArr') as FormArray).controls;
  }
  getEmploymentInterruptionsArrFormArray() {
    return (this.jobDescriptionForm.get('employmentInterruptionsArr') as FormArray).controls;
  }

  // getStockOwnerShipFeinFormArray() {

  //   return (this.jobDescriptionForm.get('stockOwnershipFeinArr') as FormArray).controls;

  // }

  // getEmploymentInterruptionsArrFormArray() {

  //   return (this.jobDescriptionForm.get('employmentInterruptionsArr') as FormArray).controls;

  // }

  ngAfterViewInit() {
    this.formChangesSubscription = this.jobDescriptionForm.valueChanges.subscribe(() => {
      if (this.jobDescriptionForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  buildDutyList(item) {
    const subJobDuty = [];
    if (item.subJobDuty && item.subJobDuty.length > 0) {
      item.subJobDuty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    } else if (item.subduty && item.subduty.length > 0) {
      item.subduty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    }
    return this.jobDescriptionBuilder.group({
      name: item.name || item.duty,
      subJobDuty: this.jobDescriptionBuilder.array(subJobDuty)
    });
  }

  buildSubDutyList(item) {
    return this.jobDescriptionBuilder.group({
      name: item.name || item.duty
    });
  }

  handleAddUpdateDuty({ type, data, dutyIndex }) {
    if (type === 'ADD') {
      // If type is ADD then append the job duties to exisiting one
      (this.jobDescriptionForm.controls['duties'] as FormArray).push(this.buildDutyList(data));
    }
    if (type === 'UPDATE') {
      // If type is UPDATE then update the job duties
      (this.jobDescriptionForm.controls['duties'] as FormArray).setControl(dutyIndex, this.buildDutyList(data));
    }
  }

  getJobDescription() {
    this.deleteWorkLocReqArr = [];
    this.statelistArrObj = {
      workAddress: []
    };
    this.pastJobDescriptionService.getJobDescription(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          this.jobDescription = response.data;
          this.loadFormValues(response.data);
        } else {
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
          //console.log("length",((this.jobDescriptionForm.controls['address'] as FormArray).length));
          if((this.jobDescriptionForm.controls['address'] as FormArray).length === 0){
            (this.jobDescriptionForm.controls['address'] as FormArray).push(this.createaddressItem());
          }
          if((this.jobDescriptionForm.controls['stockOwnershipFeinArr'] as FormArray).length === 0){
          (this.jobDescriptionForm.controls['stockOwnershipFeinArr'] as FormArray).push(this.createStockOwnershipItem());
          }
          if((this.jobDescriptionForm.controls['employmentInterruptionsArr'] as FormArray).length === 0){
          (this.jobDescriptionForm.controls['employmentInterruptionsArr'] as FormArray).push(this.createEmploymentInterruptionsArrItem());
          }
          this.statelistArrObj.workAddress.push({
            countryCode: null,
            stateList: []
          });
          // if (['L1A', 'L1B'].includes(this.inputData.visatype)) {
          // this.handleCountryChange('USA', 0, 'workAddress');
          // }
        }
      });
  }

  // Load job Desc form with values
  loadFormValues(response) {

    response = {
      ...response,
      startDate: response.startDate ? new Date(response.startDate) : null,
      endDate: response.endDate ? new Date(response.endDate) : null
    };
    try {
      this.jobDescriptionForm.reset({
        ...response,
        selectedCategory: null,
        jobRoleJD: null,
        otherJobPosition: null,
        selectedRaiobtn: 0
      });
    } catch (error) {
      console.log(error);
    }
    // this.jobDescriptionForm.patchValue({
    //   employmentInterruptionsArr: response.employmentInterruptionsArr
    // });
    this.loadDuties(response.duties);
    this.loadAddress(response.address);
    this.loadEmployemntInterruptions(response.employmentInterruptionsArr);
    this.loadStockOwnershipFeinArr(response.stockOwnershipFeinArr);
    

  }


  loadDuties(list) {
    (this.jobDescriptionForm.controls['duties'] as FormArray).clear();
    list.forEach(element => {
      (this.jobDescriptionForm.controls['duties'] as FormArray).push(this.buildDutyList(element));
    });
  }

  loadEmployemntInterruptions(list) {
    (this.jobDescriptionForm.controls['employmentInterruptionsArr'] as FormArray).clear();
    list.forEach(element => {
      (this.jobDescriptionForm.controls['employmentInterruptionsArr'] as FormArray).push(this.createEmploymentInterruptionsArrItem(element));
    });
  }

  loadStockOwnershipFeinArr(list) {
    (this.jobDescriptionForm.controls['stockOwnershipFeinArr'] as FormArray).clear();
    list.forEach(element => {
      (this.jobDescriptionForm.controls['stockOwnershipFeinArr'] as FormArray).push(this.createStockOwnershipItem(element));
    });
    // this.jobDescriptionForm.patchValue({
    //   employmentInterruptionsArr:list
    // });
  }

  getCountries() {
    this.cacheCountyService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountyService.getCountryWithoutUSA().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.length > 0) {
        this.countryList = data;
      }
    });
  }

  getWageUnitType() {
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('PAYTYP')
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.wageUnitType = response;
      });
  }

  handleDataChange(event: any) {
    if (!event.checked) {
      this.jobDescriptionForm.get('workingHoursPerWeek').reset();
    }
  }

  handleDutyDelete(i: number) {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.dutiesDelete(i);
      }
    });
  }

  handleDeleteAllDuties() {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.alldutiesDelete();
      }
    });
  }

  handleRemoveWorkClose(ind) {
    const workLocationId = (this.jobDescriptionForm.controls['address'] as FormArray).controls[ind].get('workLocationId').value;
    if (workLocationId !== 0) {

      this.pastJobDescriptionService.deleteWorkLocationItem(
        this.dynamicDialogConfig.data.visatype,
        this.dynamicDialogConfig.data.caseType,
        this.dynamicDialogConfig.data.caseId,
        workLocationId
      ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message, 'Success');
          this.getJobDescription();
        }
      });
    } else {
      ((this.jobDescriptionForm.controls['address'] as FormArray).removeAt(ind));
      this.statelistArrObj.workAddress.splice(ind, 1);
    }
  }
  

  handleSubDutyDelete({ dutyIndex, subDutyIndex }) {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.subdutiesDelete({ dutyIndex, subDutyIndex });
      }
    });
  }

  updateFormControls() {
    this.jobDescriptionForm.get('thisFulltime').valueChanges
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((value) => {
        if (!value) {
          this.jobDescriptionForm.get('workingHoursPerWeek').setValidators(Validators.required);
        } else {
          this.jobDescriptionForm.get('workingHoursPerWeek').clearValidators();
        }
        this.jobDescriptionForm.get('workingHoursPerWeek').reset();
        this.jobDescriptionForm.get('workingHoursPerWeek').updateValueAndValidity();
      });
  }

  prePostJobDescription() {
    if (this.deleteWorkLocReqArr.length > 0) {
      combineLatest(this.deleteWorkLocReqArr)
        .pipe(take(1))
        .subscribe((response: ImagilityBaseResponse[]) => {
          this.postJobDescription();
        });
    } else {
      this.postJobDescription();
    }
  }

  postJobDescription() {
    const { selectedRaiobtn, jobRoleJD, otherJobPosition, selectedCategory, ...payload } = {
      ...this.jobDescriptionForm.value,
      startDate: this.jobDescriptionForm.value.startDate ? new Date(this.jobDescriptionForm.value.startDate) : null,
      endDate: this.jobDescriptionForm.value.endDate ? new Date(this.jobDescriptionForm.value.endDate) : null,
      thisPastJob: true,
      selectedRaiobtn: this.jobDescriptionForm.value.selectedRaiobtn,
      jobRoleJD: this.jobDescriptionForm.value.jobRoleJD,
      otherJobPosition: this.jobDescriptionForm.value.otherJobPosition,
      selectedCategory: this.jobDescriptionForm.value.selectedCategory
    };
    const APIs = [
      this.pastJobDescriptionService.postJobDescription(payload,
        this.dynamicDialogConfig.data.visatype,
        this.dynamicDialogConfig.data.caseType,
        this.dynamicDialogConfig.data.caseId)
    ];
    if (selectedRaiobtn === 1) {
      const selectJobRole = JSON.parse(sessionStorage.getItem('selectedJobRole'));
      const jobDuty = this.jobDescriptionForm.value.duties ?
        this.jobDescriptionForm.value.duties.map(x => {
          return {
            dutyId: 0,
            duty: x.name,
            subduty: x.subJobDuty && x.subJobDuty.length > 0 ?
              x.subJobDuty.map(y => {
                return {
                  dutyId: 0,
                  duty: y.name,
                };
              }) : []
          };
        }) : [];
      const jobRoleConst = {
        id: selectJobRole.id,
        jobDuty,
        jobRoleName: selectJobRole.jobTitle === 'Other' ? this.jobDescriptionForm.value.otherJobPosition : selectJobRole.jobTitle,
        requestFlag: 'updatemasterdata'
      };
      APIs.push(this.jobDescriptionService.saveJobRole(this.inputData.companyId,
        this.jobDescriptionForm.value.selectedCategory.id, jobRoleConst));
    }
    combineLatest(APIs)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        if (response[0] && response[0].status === 200) {
          this.getJobDescription();
          this.toastr.success(response[0].message, 'Success');
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
        if (selectedRaiobtn === 1 && response[1] && response[1].status === 200) {
          this.toastr.success(response[1].message, 'Success');
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
  }


  handleCountryChange(countryCode: string, addressIndex: number, addressType: string) {
    if (addressType === 'workAddress') {
      (this.jobDescriptionForm.get('address') as FormArray).controls[addressIndex].patchValue({
        stateProvinceCode: null,
        stateProvinceName: null
      });
    }
    const workAddressCountryCodeArr = this.statelistArrObj.workAddress
      .filter(x => x.countryCode).map(x => x.countryCode);
    const countryCodeArr = Array.from(new Set([
      ...Array.from(new Set(workAddressCountryCodeArr))
    ]));
    if (countryCodeArr && countryCodeArr.length > 0 && countryCodeArr.includes(countryCode)) {
      let stateList: any[];
      if (workAddressCountryCodeArr.includes(countryCode)) {
        stateList = this.statelistArrObj.workAddress.find(x => x.countryCode === countryCode).stateList;
      }
      this.statelistArrObj[addressType][addressIndex] = {
        countryCode,
        stateList
      };
    } else {
      this.apiServiceState.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
        this.statelistArrObj[addressType][addressIndex] = {
          countryCode,
          stateList: states
        };
      });
    }
  }
  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';

    this.fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: false,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/FORGNOFFLTR/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/FORGNOFFLTR/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'FORGNOFFLTR'
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  handleCancel() {
    this.getJobDescription();
  }

  handleWorkLocationTypeChange(workLocTypeVAl) {
    const address = this.jobDescriptionForm.controls['address'] as FormArray;
    address.value.forEach((element, i) => {
      const workLocationId = element.workLocationId;
      if (workLocationId !== 0) {
        this.deleteWorkLocReqArr.push(this.pastJobDescriptionService.deleteWorkLocationItem(
          this.dynamicDialogConfig.data.visatype,
          this.dynamicDialogConfig.data.caseType,
          this.dynamicDialogConfig.data.caseId,
          workLocationId
        ));
      } else {
        ((this.jobDescriptionForm.controls['address'] as FormArray).removeAt(i));
      }
    });
    this.statelistArrObj = {
      workAddress: []
    };
    address.clear();
    address.push(this.createaddressItem(workLocTypeVAl));
    if (['L1A', 'L1B', 'TN1', 'TN2'].includes(this.inputData.visatype)) {
      // this.handleCountryChange('USA', 0, 'workAddress');
    }
  }

  createaddressItem(onClickWorkLocationType?: string): FormGroup {
    const visaType = this.dynamicDialogConfig.data.visatype;
    return this.jobDescriptionBuilder.group({
      id: [0],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine2NoType: [null],
      addressTypeCode: 'WORK',
      beneficiaryId: [null],
      countryCode: [null],
      countryName: [null],
      doorNo: [null],
      locality: [null],
      state: [null],
      stateProvinceCode: [null],
      stateProvinceName: [null],
      streetNo: [null],
      city: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      workLocationTypeCode: [onClickWorkLocationType ? onClickWorkLocationType :
        (visaType && ['L1A', 'E1', 'E2', 'E2C', 'TN1', 'TN2'].includes(visaType) ? 'INHSE' : null)],
      clientName: [''],
      clientEmail: ['', [Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      clientPhoneNo: ['', Validators.pattern(/^-?(0|[0-9]\d*)?$/)],
      type: [null],
      familyId: [null],
      companyId: [null],
      employeeId: [null],
      immigrationId: [null],
      workLocationId: [0],
      clientPhoneCode: [visaType && ['L1A', 'L1B'].includes(visaType) ? 'USA' : null],
      sequenceNo: ['0']
    });
  }

  handleAddLocationClick() {
    this.address = this.jobDescriptionForm.get('address') as FormArray;
    this.address.push(this.createaddressItem());
    this.statelistArrObj.workAddress.push({
      countryCode: null,
      stateList: []
    });
  }
  handleAddShareOwnershipFeinClick() {
    this.stockOwnershipFeinArr = this.jobDescriptionForm.get('stockOwnershipFeinArr') as FormArray;
    this.stockOwnershipFeinArr.push(this.createStockOwnershipItem());

  }
  handleAddEmploymentInterruptionsClick() {
    this.employmentInterruptionsArr = this.jobDescriptionForm.get('employmentInterruptionsArr') as FormArray;
    this.employmentInterruptionsArr.push(this.createEmploymentInterruptionsArrItem());

  }

  // createStockOwnershipItem(): FormGroup {
  //   return this.jobDescriptionBuilder.group({
  //     id: [0],
  //     companyStockOwnershipPercentage: [''],
  //     companyFeiNumber: [''],
  //   });
  // }

  // createEmploymentInterruptionsArrItem(): FormGroup {
  //   return this.jobDescriptionBuilder.group({
  //     id: [0],
  //     employmentInterruptionFromDate: [null],
  //     employmentInterruptionToDate: [null],
  //     employmentInterruptionExplanation:['']
  //   });
  // }


  loadAddress(list) {
    const address = this.jobDescriptionForm.controls['address'] as FormArray;
    address.clear();
    this.statelistArrObj.workAddress = [];
    if (!list || (list && list.length === 0)) {
      address.push(this.createaddressItem());
      this.statelistArrObj.workAddress.push({
        countryCode: null,
        stateList: []
      });
      if (['L1A', 'L1B', 'TN1', 'TN2'].includes(this.inputData.visatype)) {
        // this.handleCountryChange('USA', 0, 'workAddress');
      }
    } else {
      list.forEach((element, i) => {
        this.statelistArrObj.workAddress.push({
          countryCode: null,
          stateList: []
        });
        address.push(this.createaddressItem());
        this.handleCountryChange(element.countryCode, i, 'workAddress');
      });
      this.jobDescriptionForm.patchValue({
        address: list
      });
    }
  }

//   handleAddShareOwnershipFeinClick() {
//     this.stockOwnershipFeinArr = this.jobDescriptionForm.get('stockOwnershipFeinArr') as FormArray;
//     this.stockOwnershipFeinArr.push(this.createStockOwnershipItem());
//   }

//   handleAddEmploymentInterruptionsClick() {
//     this.employmentInterruptionsArr = this.jobDescriptionForm.get('employmentInterruptionsArr') as FormArray;
//     this.employmentInterruptionsArr.push(this.createEmploymentInterruptionsArrItem());
// }

createStockOwnershipItem(element?): FormGroup {
    return this.jobDescriptionBuilder.group({
      id: [element && element?.id ? element?.id : null],
      companyStockOwnershipPercentage: [element && element?.companyStockOwnershipPercentage ? element?.companyStockOwnershipPercentage : null],
      companyFeiNumber: [element && element?.companyFeiNumber ? element?.companyFeiNumber : null],
    });
  }

createEmploymentInterruptionsArrItem(element?): FormGroup {
    return this.jobDescriptionBuilder.group({
      id: [element && element?.id ? element?.id : null ],
      employmentInterruptionFromDate: [element && element?.employmentInterruptionFromDate ? new Date(element?.employmentInterruptionFromDate) : null],
      employmentInterruptionToDate: [element && element?.employmentInterruptionToDate ? new Date(element?.employmentInterruptionToDate) : null],
      employmentInterruptionExplanation: [element && element?.employmentInterruptionExplanation ? element?.employmentInterruptionExplanation : '']
    });
  }
  handleRemoveOwenerShipClose(index, controlArrayName?) {
   // ((this.jobDescriptionForm.controls['stockOwnershipFeinArr'] as FormArray).removeAt(index));
    ((this.jobDescriptionForm.controls[controlArrayName] as FormArray).removeAt(index));
  }
  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
  dutiesDelete(itemno) {
    (this.jobDescriptionForm.controls['duties'] as FormArray).removeAt(itemno);
  }

  alldutiesDelete() {
    (this.jobDescriptionForm.controls['duties'] as FormArray).clear();
  }

  subdutiesDelete({ dutyIndex, subDutyIndex }) {
    ((this.jobDescriptionForm.controls['duties'] as FormArray)
      .controls[dutyIndex] as FormArray)
      .controls['subJobDuty'].removeAt(subDutyIndex);
  }

  dialogueInitializer(title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['No', 'Yes']
      }
    });
    return dialogRef;
  }

}
