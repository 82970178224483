<div class="position-relative">
  <app-help-video-overlay #helpVideoComponent></app-help-video-overlay>
</div>
<p-card styleClass="tasklist-card">
  <div class="pt-2 p-d-flex">
    <div class="col-md-6 pl-0 d-flex">
      <div class="py-3 pl-2 col-4 pl-0 align-middle task-tab" [ngClass]="{'activeTasksTab': activeTasksTab === 'mine' }" (click)="onActiveTaskTab('mine')"><span class="pl-3">My Tasks</span></div>
      <div class="py-3 pl-2 col-4 pl-0 align-middle task-tab" [ngClass]="{'activeTasksTab': activeTasksTab === 'all' }" (click)="onActiveTaskTab('all')"><span class="pl-3">All Tasks</span></div>
    </div>
    <div class="py-2 col-md-{{ isOutsideImagility ? 4 : 6 }} d-flex justify-content-end" *ngIf="!roleName">
      <button type="button" (click)="addNewTask()"
          class="create-task-petition" [ngClass]="{'mr-4': !isOutsideImagility}">
          <i class="fa fa-plus-circle ml-0 p-0"></i> Create Task / Petition</button>
    </div>
    <div class="py-2 col-md-2" *ngIf="isOutsideImagility">
      <div class="imagilityFlagIcon pull-right">
        <span> <img src="/assets/flag-1.svg" alt=""> </span>
        <span class="flag-text">Petition Not Created Through Imagility </span>
      </div>
    </div>
  </div>
  <div class="content-holder pt-2 pb-0">
    <div class="mt-2 mb-1 row tab-container">
      
      <div class="col pb-3 px-0 mx-1 tab-label mb-3" (click)="toogleTaskTab('NEW',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'NEW' }">
        <div class="lbl-text text-center"><span class="tab-heading-lable">In Progress:</span> <p-dropdown class="filter-dropdown" appendTo="body" [options]="viewOptions" optionValue="value" optionLabel="field" (onChange)="toogleTaskTab('NEW', null, $event.value)" [(ngModel)]="viewOptionNew">
           </p-dropdown></div>
      </div>
      <!-- IM-3329 and IM-3342 updated code for the Change in Task Widget -->
      <div class="col pb-3 mx-1 px-0 tab-label mb-3" (click)="toogleTaskTab('FILED',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'FILED'}">
        <div class="lbl-text text-center"><span class="tab-heading-lable">Awaiting Decision:</span> <p-dropdown class="filter-dropdown" appendTo="body" [options]="viewOptions" optionValue="value" optionLabel="field" (onChange)="toogleTaskTab('FILED', null, $event.value)" [(ngModel)]="viewOptionPending">
        </p-dropdown></div>
      </div>
      <div class="col pb-3 px-0 mx-1 tab-label mb-3" (click)="toogleTaskTab('COMPLETE',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'COMPLETE' }">
        <div class="lbl-text text-center"><span class="tab-heading-lable">Completed:</span> <p-dropdown class="filter-dropdown" appendTo="body" [options]="viewOptions" optionValue="value" optionLabel="field" (onChange)="toogleTaskTab('COMPLETE', null, $event.value)" [(ngModel)]="viewOptionCompleted">
        </p-dropdown></div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="view-label">View By  </label>
        <p-dropdown class="filter-dropdown" [style]="{width: '180px'}" [options]="taskType" optionValue="code"
          [(ngModel)]="filter.superTaskType" (onChange)="getSubTaskType(this.filter.superTaskType);" optionLabel="name">
        </p-dropdown>
      </div>
      <div class="col-6">
          <div class="filter position-relative">
              <p-overlayPanel #filterPanel styleClass="overlayPanel" [style]="{width: '300px', padding: '0px 10px'}"
                [dismissable]="false">
                <ng-template pTemplate>
                  <div *ngIf="filter.superTaskType === 'NONIMMPET'" class="row col-md-12 pt-2 pb-2 pl-0 pr-0">
                    <div class="col-md-12 p-0 pb-2">
                      <label class="filter-label">Visa Type</label> <br>
                      <p-multiSelect [style]="{width: '250px'}" optionValue="code" [options]="visaTypes"
                        [(ngModel)]="filter.visaType" defaultLabel="Select Visa" optionLabel="name">
                      </p-multiSelect>
                    </div>
                    <div class="col-md-12 p-0">
                      <label class="filter-label">Petition Type</label> <br>
                      <p-multiSelect [style]="{width: '250px'}" optionValue="code" [options]="petitionTypes"
                        [(ngModel)]="filter.petitionType" defaultLabel="Select Petition" optionLabel="name">
                      </p-multiSelect>
                    </div>
                  </div>
                  <div *ngIf="filter.superTaskType === 'IMMPET'" class="row col-md-12 pt-2 pb-2 pl-0 pr-0">
                    <div class="col-md-12 p-0 pb-2">
                      <label class="filter-label">Immigration Category</label> <br>
                      <p-multiSelect [style]="{width: '250px'}" optionValue="code" [options]="immCategoryTypes"
                        [(ngModel)]="filter.immCategoryType" defaultLabel="Select Immigration Category" optionLabel="name">
                      </p-multiSelect>
                    </div>
                    <div class="col-md-12 p-0">
                      <label class="filter-label">Immigration Clssification</label> <br>
                      <p-multiSelect [style]="{width: '250px'}" optionValue="code" [options]="immClassificationTypes"
                        [(ngModel)]="filter.immClassificationType" defaultLabel="Select Immigration Classification"
                        optionLabel="name">
                      </p-multiSelect>
                    </div>
                  </div>
                  <div class="row col-md-12 pl-0 pr-0 pb-2">
                    <div class="col-md-6 p-0 pb-2">
                      <label class="filter-label">Sub-Task Type</label> <br>
                      <p-multiSelect [style]="{width: '250px'}" optionValue="code" [options]="subTaskType"
                        [(ngModel)]="filter.subTaskType" defaultLabel="Select SubTask" optionLabel="name">
                      </p-multiSelect>
                    </div>
                  </div>
                  <!-- <div class="row col-md-12 pl-0 pr-0 pb-2">
                    <div class="col-md-6 p-0 pb-2">
                      <label class="filter-label">Task Due Date</label> <br>
                    <div style="width: 100%;">
                      
                      <p-calendar #sd [style]="{'width':'100%'}"  placeholder="From Date"   styleClass="p-form-datepicker"
                dateFormat="mm/dd/yy"   [showIcon]="true"  [(ngModel)]="startDate">
            </p-calendar>
                    </div>
                    <div style="width: 100%;" class="mt-2">
                     
                      <p-calendar #sd [style]="{'width':'100%'}"  placeholder="To Date"   styleClass="p-form-datepicker"
                dateFormat="mm/dd/yy"   [showIcon]="true" [(ngModel)]="endDate">
            </p-calendar>
                    </div>
                    </div>
        
                  </div> -->
                  <mat-divider></mat-divider>
                  <div class="row col-md-12 pt-3">
                    <div class="col-md-7">
                      <button pButton type="button" label="Cancel" title="Cancel" (click)="filterPanel.hide();"
                        class="p-button-outlined filter-btns"></button>
                    </div>
                    <div class="col-md-5 p-0">
                      <button pButton type="button" label="Apply" title="Apply"
                        class="p-button-primary p-button apply-btn filter-btns"
                        (click)="handleFilterChange();filterPanel.hide()"></button>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
        
              <button type="text" class="taskFilter-btn pull-right" pButton label="Filter" (click)="filterPanel.toggle($event)"
                icon="pi pi-filter" iconPos="right"> </button>
            </div>
      </div>
    </div>
    <div class="row">
      <div class="col px-1 task-table">
        <p-table [columns]="cols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
          [scrollable]="true" scrollHeight="300px" styleClass="p-datatable-gridlines" [value]="taskList" dataKey="id"
          *ngIf="taskList?.length > 0" (onRowExpand)="onRowExpand($event)">
          <ng-template pTemplate="header" let-columns>
            <tr class="text-uppercase">
              <th style="width: 3rem" *ngIf="toggleStatus !== 'FILED'"> </th>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
            <tr>
              <td class="imagilityFlagIcon" *ngIf="toggleStatus !== 'FILED'">
                <span *ngIf="isOutsideImagility"> <img src="/assets/flag-1.svg" alt=""></span>
                <button type="button" pButton pRipple [pRowToggler]="task"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                </button>
              </td>
              <td *ngFor="let col of columns">
                <span *ngIf="col.field === 'companyName'">
                  {{ task['companyId'] ? task['companyName'] : '&mdash;' }}
                </span>
                <span *ngIf="col.field === 'beneficiaryName'">
                  {{ task['beneficiaryId'] ? task['beneficiaryFirstName'] + ' ' + task['beneficiaryLastName'] :
                  task['sponsorFirstName'] + ' ' + task['sponsorLastName'] }}
                </span>
                <span *ngIf="col.field === 'status'">
                  {{task['taskStatusId']['name']}}
                </span>
                <span class="taskId" *ngIf="col.field === 'taskId'" (click)="handleTask(task)">
                  {{task['id']}}
                </span>
                <span *ngIf="!['companyName', 'beneficiaryName', 'status', 'taskId'].includes(col.field)">
                  {{task[col.field]}}
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-task>
            <tr>
              <td colspan="8">
                <div class="p-p-3 sub-task-type">
                  <p-table class="sub-task-table" [columns]='nestedCols' [value]="task.subTaskList" dataKey="subTaskId">
                    <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-subtask let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <span *ngIf="col.field === 'preparerName'">
                  <span *ngIf="userType === 'Petitioner'">
                    {{subtask['immigrationSpecialistFirstName']}} {{subtask['immigrationSpecialistLastName']}}
                  </span>
                  <span *ngIf="userType === 'Attorney'">
                    {{subtask['preparerFirstName']}} {{subtask['preparerLastName']}}
                  </span>
                </span>
                <span>
                  {{subtask[col.field]}}
                </span>
                <div *ngIf="subtask['taskStatus']['code'] === 'COMPLETED'">
                  <span *ngIf="col.field === 'enddate'">
                    <span> {{subtask['endDate']}} </span>
                  </span>
                </div>
                <span *ngIf="col.field === 'enddate'">
                  <div *ngIf="subtask['subTaskType'] === 'PETITION' && subtask['taskStatus']['code'] === 'FILED'">
                    In Process
                    ({{subtask['lapseTimeDays']}} Days )</div>
                  <div *ngIf="subtask['subTaskType'] === 'RFE'">
                    {{subtask['taskStatus']['code'] === 'RESPONDED' ? subtask['lastModifiedDate'] :
                    'Due in' + ' ' + subtask['lapseTimeDays'] + ' ' + 'Days' }}
                  </div>
                </span>
                <span *ngIf="col.field === 'status'">
                  {{subtask['taskStatus']['name']}}
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">No Data</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>
      <div *ngIf="taskList?.length == 0" class="mt-4 text-center">No Data</div>
      <div class="show-more-box bg-white" *ngIf="taskList?.length > 0"><a class="show-more" (click)="showMore()">Show
          More</a>
      </div>

    </div>
  </div>
  </div>
</p-card>
