
<h5 mat-dialog-title>
  <div class="col-md-12 p-0 row delete-header">
      <div class="col-md-10 p-0 post-lca-title">
        <span *ngIf="data.action == 'archive'">Confirm Archiving LCA Posting</span>
        <span *ngIf="data.action == 'take down'">Take Down posted LCA</span>
        <span *ngIf="data.action == 'delete'">Delete Archived LCA Posting</span>
      </div>
      <div class="col-md-2 p-0 justify-content-end d-flex">
      <i (click)="onDismiss();" class="close-icon pi pi-times"></i>
      </div>
  </div>
</h5>
<div class="row post-lca-form" mat-dialog-content>
  <div class="col-md-1 p-0 text-center">
    <i *ngIf="data.action != 'delete'" class="fa fa-exclamation-triangle warning-icon" aria-hidden="true"></i>
    <i *ngIf="data.action == 'delete'" class="fa fa-exclamation-circle delete warning-icon" aria-hidden="true"></i>
  </div>
  <div class="col-md-11 p-0">
    <span *ngIf="data.action == 'archive'">Archiving will move the LCA Posting to a historical archive and make it read-only. <br> To make changes to an archived LCA, you will need to restore it first. <br> <br> Are you sure you want to archive the selected LCA?</span>

    <span *ngIf="data.action == 'take down'">
      Today is the {{dateDifference}}<sup>{{dateDifference | DateSuffixPipe}}</sup> day of the LCA being posted. {{data.lcaData.daysPosted - dateDifference}} days remaining.<br> <br> Are you sure you want to take down the posted LCA? <br> <br> If you take down the post, the Job posting will no longer be visible to anyone?</span>

    <span *ngIf="data.action == 'delete'">
       You are about to Delete the LCA Posting<br> <br> <b>Are you sure you want to delete the Labor Condition Application (LCA)?</b> <br> <br> This action cannot be undone. Please ensure that you have reviewed the information carefully before proceeding.</span>

    <br> <br>
  </div>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-button (click)="onDismiss()">{{buttons[0]}}</button>
    <button class="post-lca-button" [ngClass]="{'delete-button': data.action == 'delete'}" pButton (click)="onConfirm()">Yes, {{buttons[1]}}</button>
</div>