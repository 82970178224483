import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TaskAllocationRoutingModule } from './task-allocation-routing.module';
import { StepTimesConfigurationsComponent } from './step-times-configurations/step-times-configurations.component';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabViewModule } from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import { StepTaskAllocationComponent } from './step-task-allocation/step-task-allocation.component';
import { CardModule } from 'primeng/card';
import { CreateConfigureTimeComponent } from './create-configure-time/create-configure-time.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskAllocationContainerComponent } from './task-allocation-container/task-allocation-container.component';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  declarations: [
    StepTimesConfigurationsComponent, 
    StepTaskAllocationComponent, 
    CreateConfigureTimeComponent, TaskAllocationContainerComponent],
  imports: [
    CommonModule,
    TaskAllocationRoutingModule,
    DividerModule,
    FormsModule, 
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    CardModule,
    PaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    DynamicDialogModule,
    TabViewModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    NgxPermissionsModule.forChild(),
  ],
  exports : [StepTaskAllocationComponent, TaskAllocationContainerComponent]
})
export class TaskAllocationModule {

 }
