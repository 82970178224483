import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { VisaExtendedState } from '../../../utility/interfaces/visa-extended-state';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { I140FileUploadConfiguration, ImagilityBaseResponse, PrimaryData, SubTaskDetails, TaskStep } from 'app-models';
import * as errorUtils from "error-message-utility";
import { ParticipationAsJudgeService } from './participation-as-judge.service';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { UploadDocumentService } from 'src/app/services/upload-document.service';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
import * as DirtyForm from '../../../../../app-state/actions/dirty-form.actions';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode, getSubTaskDetails } from 'visa-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};
@Component({
  selector: 'app-participation-as-judge',
  templateUrl: './participation-as-judge.component.html',
  styleUrls: ['./participation-as-judge.component.scss']
})
export class ParticipationAsJudgeComponent implements OnInit {
  @ViewChild("participationimageupload") public participationimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("participationkendoeditor") public participationkendoeditor: EditorComponent;
  subcriptions: Subscription[] = [];
  disabledControls: boolean;
  stepDetails: TaskStep;
  hideAddAwardSection: boolean = false;
  fileUploadConfigurations: I140FileUploadConfiguration;
  primaryData;
  participationInfo: {
    detailsAndExplanationInfo: string,
    docEvidenceCode: string,
    participationId: number,
    petitionId: number
  } = {
      detailsAndExplanationInfo: '',
      docEvidenceCode: 'PANELJUDGE',
      participationId: null,
      petitionId: null
    };
  formDirtyFromDifferentControls = false;
  typeOfButton: string;
  task: SubTaskDetails;
  inputData: PrimaryData;
  stepStatus;
  showMarkComplete = false;
  markStep: string;
  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //     }
  //   }
  // };


  observableSubscription$ = new Subject();

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadDocumentService: UploadDocumentService,
    public dialogService: DialogService,
    public dynamicDialogConfig: DynamicDialogConfig,
    private participationAsJudgeService: ParticipationAsJudgeService,
    private store: Store<VisaExtendedState>,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.disabledControls = false;
  }
  openparticipationimageuploaddialog() {
    this.participationimageupload.open();
  }
  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        if (this.task['extrAblDocEvdList'][this.task['extrAblDocEvdList'].length - 1]['code'] == 'PANELJUDGE') {
          this.showMarkComplete = true;
        }
        this.stepDetails = response[1];
        this.fileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/JUDGPRTCPTDOC/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/JUDGPRTCPTDOC/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'JUDGPRTCPTDOC'
        });

        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
        this.getParticipationInfo();
      });
    //this.primaryData = this.dynamicDialogConfig.data;/
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit '
    } else {
      this.markStep = 'MARK Step COMPLETE';
    }
  }

  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }

  ngAfterViewInit() {

  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  toggleAddSection() {
    this.hideAddAwardSection = !this.hideAddAwardSection;
  }

  saveParticipationInfo() {
    this.participationInfo.petitionId = this.dynamicDialogConfig.data.petitionId;
    let payload = this.participationInfo;
    this.participationAsJudgeService.addParticipationInfo(this.dynamicDialogConfig.data.petitionId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response['data']) {
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }

  getParticipationInfo() {
    this.participationAsJudgeService.getParticipationInfo(this.dynamicDialogConfig.data.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response['data']) {
        this.participationInfo = response['data'];
        //froalaService.insertPageBreak();
      }
    });
  }

  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }
    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
    // this.toggleControlsStatus();
  }
  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    //this.store.dispatch(updateStepStatusToInitialState());
  }
  public participationInfodetailsAndExplanationInfoeditorValueChange(value: string): void {
    this.participationInfo.detailsAndExplanationInfo = value;
    this.setFormDirty();
  }
}
