<div mat-dialog-title class="position-relative text-center">
  <h5 class="mt-2 text-primary"><strong>Join Our Tour</strong></h5>
  <h6>We'd like to show the top tasks a {{userType}} would typically do on the Platform... </h6>
  <hr />  
</div>

<mat-dialog-content class="mat-typography text-left">
  <div *ngIf="userType == 'Attorney'" class="ml-2">
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating_Beneficiaries.svg"> Creating Tasks/Petitions</p>
    </div>
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating Tasks_Petitions.svg"> Creating Clients Connections</p>
    </div>
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating_Attorney_Connections.svg"> Managing Beneficiaries</p>
    </div>
  </div>
  <div *ngIf="userType == 'Petitioner'" class="ml-2">
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating_Beneficiaries.svg"> Creating Beneficiaries</p>
    </div>
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating Tasks_Petitions.svg"> Creating Tasks/Petitions</p>
    </div>
    <div>
      <p><img src="/assets/images/video-tour/Icon_Creating_Attorney_Connections.svg"> Creating Attorney Connections</p>
    </div>
   
  </div>
  <div *ngIf="userType == 'Beneficiary'" class="ml-2">
    <div>
      <p><img src="/assets/images/video-tour/Beneficairy_Building_Profile.svg"> Building your Profile</p>
    </div>
    <div>
      <p><img src="/assets/images/video-tour/Beneficairy_Working_with_Timelines.svg"> Working with Timeline</p>
    </div>
   
   
  </div>
  <div class="text-right">
  <h4 class="d-inline-block mr-2">Let's get started with these tasks...</h4>
  <button type="button" class="btn btn-primary" (click)="startVideoTour()">
    <span class="float-left">Let's Go</span>
  </button>
</div>
  <hr/>
  <div class="text-left">
    <div class="d-inline-block">
  <h4 class="d-inline-block mb-0"><strong>Assistance at any time:</strong></h4>
  <div class="text-secondary">Click on the floating help button on the top right of the page</div>
  </div>
  <button mat-dialog-close (click)="routeToResourcePage()" class="rounded-pill back-button button-width-icon-left btn-pos-top-right btn button_whitebgImp float-right">
      <i class="fa fa-question-circle"></i>
      Video Help
  </button>
  </div>
  <hr/>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close class="link-button btn">Dismiss</button>
</mat-dialog-actions>
