import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as DirtyFormSelectors from './app-state/selectors/dirty-form.selector';
// import { truncate } from 'fs';
import { ConfirmationModalComponent } from './modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable({ providedIn: 'root' })
export class DirtyFormGuard implements CanDeactivate<any> {
  constructor(private dialog: MatDialog, private store: Store<any>, private ngxService: NgxUiLoaderService) { }

  public canDeactivate(): Observable<boolean> {
    return this.store.pipe(
      select(DirtyFormSelectors.selectFormDirty),
      mergeMap(dirtyObj => {
        if (!dirtyObj.dirty) {
          return of(true);
        } else {
          // return of(false);
          this.ngxService.stopAll();
          return this.dialog.open(ConfirmationModalComponent, {
            width: '400px',
            data: {
              update: {
                title: 'Unsaved Changes!',
                message: (dirtyObj.message) ? dirtyObj.message :
                  'If you leave before saving, all changes made on the page will be lost. Do you wish to continue?'
              },
              // buttons: ['No, Stay on the Page', 'Yes, Leave the Page']
              buttons: ['No', 'Yes'],
              disableClose: true
            }
          }).afterClosed().pipe(
            map((confirmed: boolean) => {
              return confirmed;
            })
          );
        }
      })
    );
  }
}
