import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UserRoleService } from 'src/app/services/user-role.service';
import { DashboardWidgetsService } from '../../../../services/dashboard-widgets.service';
import { HelpVideoOverlayComponent } from '../../../../modules/shared/help-video-overlay/help-video-overlay.component';
@Component({
  selector: 'dashboard-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit, AfterViewInit {
  public userName = '';
  public email = '';
  public imgsrc = '../../../../../assets/cameraIcon.png';
  public editLink = '';
  public beneficiaryId: number;
  public profileCompletionPerecentage: string;

  public alertsList: Array<any>;
  public initialPage = 1;
  public pageSize = 1;
  public maxPages = 6;

  @ViewChild(HelpVideoOverlayComponent, { static: true }) helpVideoComponent: HelpVideoOverlayComponent;

  public alertWidgetPagination = { currentIndex: 0, maxIndex: 0 };
  constructor(private userService: UserRoleService,
    private dashboardWidgetsService: DashboardWidgetsService) {
    this.beneficiaryId = parseInt(sessionStorage.getItem('loggedInBeneficiaryId'));
    this.profileCompletionPerecentage = '0%';
    this.alertsList = [];
    this.alertWidgetPagination['currentIndex'] = 0;
    this.alertWidgetPagination['maxIndex'] = 0;
  }

  ngOnInit() {
    this.getAllAlerts();
    this.helpVideoComponent.setValues('right', 'Building Beneficiary Profile', 'Building Beneficiary Profile');
    //IM-13361 Back Button
    sessionStorage.removeItem('bene-update-profile');
  }

  ngAfterViewInit() {
    this.setprofileInfo();
  }

  setprofileInfo() {
    this.userService.getUserInfoByBeneficiaryId(this.beneficiaryId).subscribe((data) => {
      if (data) {
        this.userName = data['firstName'] + ' ' + (data['middleName'] && data['middleName'].length > 0 ? data['middleName'] : '') + ' ' + (data['lastName']&& data['lastName'].length > 0 ? data['lastName'] : '');
        this.email = data['userName'];
        this.imgsrc = (data['profileImage']) ? `data:image/jpeg;base64,${data['profileImage']}` : '../../../../../assets/cameraIcon.png';
        this.profileCompletionPerecentage = data['profileCompletion'];
        sessionStorage.setItem('profilePic', data['profileImage']);
      } else {
        this.userName = '--No Data --';
        this.email = '--No Data --';
        this.imgsrc = '../../../../../assets/cameraIcon.png';
      }
    });
  }

  getAllAlerts() {
    this.dashboardWidgetsService.getAlertsList().subscribe((response: Object) => {
      if (response) {
        this.alertsList = response['data'];
        if (this.alertsList.length > 0) {
          this.alertWidgetPagination.maxIndex = this.alertsList.length > 6 ? 5 : this.alertsList.length - 1;
        }
      }
      else {
        this.alertsList = [];
      }
    });
  }

  setContentIndex(action) {
    if (action == "inc") {
      this.alertWidgetPagination.currentIndex == this.alertWidgetPagination.maxIndex ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex + 1;
    }

    if (action == "dec") {
      this.alertWidgetPagination.currentIndex == 0 ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex - 1;
    }


  }

}
