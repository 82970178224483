export const FAMILYDATA = [
    {
      heading: 'A. FINAL ACTION DATES FOR FAMILY-SPONSORED PREFERENCE CASES',
      item: [
        {sponsored: 'F1', allChargeability: '01JAN15', china: '01JAN15', india: '01JAN15', mexico: '01MAY01', philippines: '01MAR12'},
        {sponsored: 'F2A', allChargeability: '08FEB20', china: '08FEB20', india: '08FEB20', mexico: '01FEB20', philippines: '08FEB20'},
        {sponsored: 'F2B', allChargeability: '01OCT15', china: '01OCT15', india: '01OCT15', mexico: '22OCT03', philippines: '22OCT11'},
        {sponsored: 'F3', allChargeability: '22APR09', china: '22APR09', india: '22APR09', mexico: '08SEP98', philippines: '08JUN02'},
        {sponsored: 'F4', allChargeability: '22MAY07', china: '22MAY07', india: '15NOV05', mexico: '15SEP00', philippines: '15OCT02'}
      ]
    }, 
    {
      heading: 'B. DATES FOR FILING FAMILY-SPONSORED VISA APPLICATIONS',
      item: [
        {sponsored: 'F1', allChargeability: '01SEP17', china: '01SEP17', india: '01SEP17', mexico: '01APR05', philippines: '22APR15'},
        {sponsored: 'F2A', allChargeability: '01SEP23', china: '01SEP23', india: '01SEP23', mexico: '01SEP23', philippines: '01SEP23'},
        {sponsored: 'F2B', allChargeability: '01JAN17', china: '01JAN17', india: '01JAN17', mexico: '01AUG04', philippines: '01OCT13'},
        {sponsored: 'F3', allChargeability: '01MAR10', china: '01MAR10', india: '01MAR10', mexico: '15JUN01', philippines: '08NOV03'},
        {sponsored: 'F4', allChargeability: '01MAR08', china: '01MAR08', india: '22FEB06', mexico: '15APR01', philippines: '22APR04'}
      ]
    }
];

export const EMPLOYMENTDATA = [
    {
      heading: 'A. FINAL ACTION DATES FOR EMPLOYMENT-BASED PREFERENCE CASES',
      item: [
        {sponsored: '1st', allChargeability: 'C', china: '01NOV22', india: '01FEB22', mexico: 'C', philippines: 'C'},
        {sponsored: '2nd', allChargeability: '15MAR23', china: '01MAR20', india: '15JUL12', mexico: '15MAR23', philippines: '15MAR23'},
        {sponsored: '3rd', allChargeability: '01DEC21', china: '01SEP20', india: '22OCT12', mexico: '01DEC21', philippines: '01DEC21'},
        {sponsored: 'Other Workers', allChargeability: '01JAN21', china: '01JAN17', india: '22OCT12', mexico: '01JAN21', philippines: '01MAY20'},
        {sponsored: '4th', allChargeability: '01JAN21', china: '01JAN21', india: '01JAN21', mexico: '01JAN21', philippines: '01JAN21'},
        {sponsored: 'Certain Religious Workers', allChargeability: '01JAN21', china: '01JAN21', india: '01JAN21', mexico: '01JAN21', philippines: '01JAN21'},
        {sponsored: '5th Unreserved (including C5, T5, I5, R5)', allChargeability: 'C', china: '15DEC15', india: '01DEC20', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Rural (20%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: High Unemployment (10%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Infrastructure (2%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
      ]
    }, 
    {
      heading: 'B. DATES FOR FILING OF EMPLOYMENT-BASED VISA APPLICATIONS',
      item: [
        {sponsored: '1st', allChargeability: 'C', china: '01JAN23', india: '01JAN21', mexico: 'C', philippines: 'C'},
        {sponsored: '2nd', allChargeability: '15FEB23', china: '01JUN20', india: '15MAY12 ', mexico: '15FEB23', philippines: '15FEB23'},
        {sponsored: '3rd', allChargeability: '01FEB23', china: '01JUL21', india: '01AUG12', mexico: '01FEB23', philippines: '01JAN23'},
        {sponsored: 'Other Workers', allChargeability: '15DEC20', china: '01JUN17', india: '01AUG12', mexico: '15DEC20', philippines: '15MAY20'},
        {sponsored: '4th', allChargeability: '01SEP19', china: '01SEP19', india: '01SEP19', mexico: '01SEP19', philippines: '01SEP19'},
        {sponsored: 'Certain Religious Workers', allChargeability: '01SEP19', china: '01SEP19', india: '01SEP19', mexico: '01SEP19', philippines: '01SEP19'},
        {sponsored: '5th Unreserved (including C5, T5, I5, R5)', allChargeability: 'C', china: '01JAN17', india: '01APR22', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Rural (20%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: High Unemployment (10%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Infrastructure (2%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
      ]
    }
];

export const UPCOMINGFAMILYDATA = [
    {
      heading: 'A. FINAL ACTION DATES FOR FAMILY-SPONSORED PREFERENCE CASES',
      item: [
        {sponsored: 'F1', allChargeability: '08FEB15', china: '08FEB15', india: '08FEB15', mexico: '01MAY01', philippines: '01MAR12'},
        {sponsored: 'F2A', allChargeability: '22JUN20', china: '22JUN20', india: '22JUN20', mexico: '15JUN20', philippines: '22JUN20'},
        {sponsored: 'F2B', allChargeability: '22NOV15', china: '22NOV15', india: '22NOV15', mexico: '22OCT03', philippines: '22OCT11'},
        {sponsored: 'F3', allChargeability: '01OCT09', china: '01OCT09', india: '01OCT09', mexico: '08SEP98', philippines: '08JUN02'},
        {sponsored: 'F4', allChargeability: '08JUN07', china: '08JUN07', india: '15DEC05', mexico: '15OCT00', philippines: '15JUN03'}
      ]
    }, 
    {
      heading: 'B. DATES FOR FILING FAMILY-SPONSORED VISA APPLICATIONS',
      item: [
        {sponsored: 'F1', allChargeability: '01SEP17', china: '01SEP17', india: '01SEP17', mexico: '01APR05', philippines: '22APR15'},
        {sponsored: 'F2A', allChargeability: '01SEP23', china: '01SEP23', india: '01SEP23', mexico: '01SEP23', philippines: '01SEP23'},
        {sponsored: 'F2B', allChargeability: '01JAN17', china: '01JAN17', india: '01JAN17', mexico: '01AUG04', philippines: '01OCT13'},
        {sponsored: 'F3', allChargeability: '01MAR10', china: '01MAR10', india: '01MAR10', mexico: '15JUN01', philippines: '08NOV03'},
        {sponsored: 'F4', allChargeability: '01MAR08', china: '01MAR08', india: '22FEB06', mexico: '15APR01', philippines: '22APR04'}
      ]
    }
];

export const UPCOMINGEMPLOYMENTDATA = [
    {
      heading: 'A. FINAL ACTION DATES FOR EMPLOYMENT-BASED PREFERENCE CASES',
      item: [
        {sponsored: '1st', allChargeability: 'C', china: '15JUL22', india: '01OCT20', mexico: 'C', philippines: 'C'},
        {sponsored: '2nd', allChargeability: '22NOV22', china: '01JAN20', india: '01MAR12', mexico: '22NOV22', philippines: '22NOV22'},
        {sponsored: '3rd', allChargeability: '08SEP22', china: '01SEP20', india: '01JUL12', mexico: '08SEP22', philippines: '08SEP22'},
        {sponsored: 'Other Workers', allChargeability: '08SEP20', china: '01JAN17', india: '01JUL12', mexico: '08SEP20', philippines: '01MAY20'},
        {sponsored: '4th', allChargeability: '01DEC19', china: '01DEC19', india: '01DEC19', mexico: '01DEC19', philippines: '01DEC19'},
        {sponsored: 'Certain Religious Workers', allChargeability: '01DEC19', china: '01DEC19', india: '01DEC19', mexico: '01DEC19', philippines: '01DEC19'},
        {sponsored: '5th Unreserved (including C5, T5, I5, R5)', allChargeability: 'C', china: '15DEC15', india: '01DEC20', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Rural (20%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: High Unemployment (10%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Infrastructure (2%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
      ]
    }, 
    {
      heading: 'B. DATES FOR FILING OF EMPLOYMENT-BASED VISA APPLICATIONS',
      item: [
        {sponsored: '1st', allChargeability: 'C', china: '01JAN23', india: '01JAN21', mexico: 'C', philippines: 'C'},
        {sponsored: '2nd', allChargeability: '15FEB23', china: '01JUN20', india: '15MAY12 ', mexico: '15FEB23', philippines: '15FEB23'},
        {sponsored: '3rd', allChargeability: '01FEB23', china: '01JUL21', india: '01AUG12', mexico: '01FEB23', philippines: '01JAN23'},
        {sponsored: 'Other Workers', allChargeability: '15DEC20', china: '01JUN17', india: '01AUG12', mexico: '15DEC20', philippines: '15MAY20'},
        {sponsored: '4th', allChargeability: '01JAN20', china: '01JAN20', india: '01JAN20', mexico: '01JAN20', philippines: '01JAN20'},
        {sponsored: 'Certain Religious Workers', allChargeability: '01JAN20', china: '01JAN20', india: '01JAN20', mexico: '01JAN20', philippines: '01JAN20'},
        {sponsored: '5th Unreserved (including C5, T5, I5, R5)', allChargeability: 'C', china: '01JAN17', india: '01APR22', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Rural (20%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: High Unemployment (10%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
        {sponsored: '5th Set Aside: Infrastructure (2%)', allChargeability: 'C', china: 'C', india: 'C', mexico: 'C', philippines: 'C'},
      ]
    }
];

export const DIVERSITYDATA = [
    {
      heading: 'B.  DIVERSITY IMMIGRANT (DV) CATEGORY FOR THE MONTH OF FEBRUARY',
      item: [
        { region: 'AFRICA', allChargeability: '26,500', china: 'Except:  Algeria 26,000 Egypt  25,000 Morocco   25,000 '},
        { region: 'ASIA', allChargeability: '5,500', china: 'Except:  Iran     5,450 Nepal  4,500'},
        { region: 'EUROPE', allChargeability: '13,500', china: 'Except:  Russia   13,250 Uzbekistan  5,500 '},
        { region: 'NORTH AMERICA (BAHAMAS) ', allChargeability: '5', china: ''},
        { region: 'OCEANIA', allChargeability: '875', china: ''},
        { region: 'SOUTH AMERICA, and the CARIBBEAN', allChargeability: '1,600', china: ''},
      ]
    },
    {
      heading: 'C.  THE DIVERSITY (DV) IMMIGRANT CATEGORY RANK CUT-OFFS WHICH WILL APPLY IN MARCH',
      item: [
        { region: 'AFRICA', allChargeability: '40,000', china: 'Except: Algeria  35,000  Egypt  25,000  Morocco  27,500 '},
        { region: 'ASIA', allChargeability: '7,000', china: 'Except:  Iran    6,750 Nepal  5,000'},
        { region: 'EUROPE', allChargeability: '16,000', china: 'Except:  Russia   15,500 Uzbekistan    5,750 '},
        { region: 'NORTH AMERICA (BAHAMAS) ', allChargeability: '13', china: ''},
        { region: 'OCEANIA', allChargeability: '1,200', china: ''},
        { region: 'SOUTH AMERICA, and the CARIBBEAN', allChargeability: '2,200', china: ''},
      ]
    }
];

export const UPCOMINGDIVERSITYDATA = [
    {
      heading: 'B.  DIVERSITY IMMIGRANT (DV) CATEGORY FOR THE MONTH OF MARCH',
      item: [
        { region: 'AFRICA', allChargeability: '40,000', china: 'Except:  Algeria 35,000 Egypt  25,000Morocco   27,500 '},
        { region: 'ASIA', allChargeability: ' 7,000', china: 'Except:  Iran     6,750 Nepal  5,000'},
        { region: 'EUROPE', allChargeability: '16,000', china: 'Except:  Russia   15,500 Uzbekistan    5,750 '},
        { region: 'NORTH AMERICA (BAHAMAS) ', allChargeability: '13', china: ''},
        { region: 'OCEANIA', allChargeability: '1,200', china: ''},
        { region: 'SOUTH AMERICA, and the CARIBBEAN', allChargeability: '2,200', china: ''},
      ]
    },
    {
      heading: 'C.  THE DIVERSITY (DV) IMMIGRANT CATEGORY RANK CUT-OFFS WHICH WILL APPLY IN APRIL',
      item: [
        { region: 'AFRICA', allChargeability: '45,000', china: 'Except: Algeria  42,000  Egypt  30,000  Morocco  35,000 '},
        { region: 'ASIA', allChargeability: '7,200', china: 'Except:  Iran    7,000 Nepal  6,000'},
        { region: 'EUROPE', allChargeability: '17,500', china: 'Except:  Russia   17,400 Uzbekistan    7,000 '},
        { region: 'NORTH AMERICA (BAHAMAS) ', allChargeability: '13', china: ''},
        { region: 'OCEANIA', allChargeability: '1,250', china: ''},
        { region: 'SOUTH AMERICA, and the CARIBBEAN', allChargeability: '2,400', china: ''},
      ]
    }
];