import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, pluck } from 'rxjs/operators';
import { Attorney } from '../data/models/attorney.model';
import { Observable } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { BehaviorSubject } from 'rxjs';
import { NonNullAssert } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class AttorneyService {
  selectedCompanyId = new BehaviorSubject(0);
  externalEmployeeId = new BehaviorSubject(0);
  clientListSubject = new BehaviorSubject<any>({});
  // externalClientRequestedDocsList = new BehaviorSubject(null);
  selectedClient: any;
  constructor(private httpClient: HttpClient) { }

  saveAttorney(payload) {
    return this.httpClient.post(`/company`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getAttorneysList(companyId: number, reqJson) {
    return this.httpClient.post(`/petitioner/dashboard/attorney/company/${companyId}`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getClientsList(companyId: number, reqJson) {
    return this.httpClient.post(`/attorney/dashboard/petitioner/company/${companyId}`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  getCompanyList(companyId: number) {
    return this.httpClient.get(`/company/${companyId}/addBeneficiaryByPetitioner`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          return response.status === 200 ? response.data : [];
        })
      );
  }

  getClientListPolicySettings(companyId: number) {
    return this.httpClient.get(`/policy/beneficiary/task/petitioner/company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  markAsComplete(accessCode: string) {
    return this.httpClient.post(`/attor/company/markcomplete/${accessCode}`, null).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response : [];
      })
    )
  }

  sendNotification(accessCode: string) {
    return this.httpClient.post(`/attor/petitioner/notification/${accessCode}`, null).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response : [];
      })
    )
  }

  getAttorneyBeneficiaries(companyId: number, reqJson) {
    return this.httpClient.post(`/attorney/${companyId}/beneficiaries/list`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  getAttorneyManagerUsersList(req) {
    return this.httpClient.post(`/externalAttorney/getUserList`, req).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }
  getSelfRegisteredBeneficiaries(payload) {
    return this.httpClient.post('/fetchUnLinkedBeneficiaries/list', payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  getSearchClientsList(companyId: number, reqJson) {
    return this.httpClient.post(`/company/search`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  requestedInfo(companyId) {
    return this.httpClient.get(`/attor/questionaire/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : {};
      })
    );
  }

  getContactPersonList(companyId: number) {
    return this.httpClient.get(`/company/${companyId}/contactperson/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  saveClientConnect(petitionerId: number, attorneyId, payload) {
    return this.httpClient.post(`/petitioner/dashboard/attorney/link/petitioner/${petitionerId}/attorney/${attorneyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getBenficiaryLists(payload) {
    return this.httpClient.post(`/beneficiaries/list`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  deleteAttorneyConnection(linkRequestId: number, comments: string) {
    return this.httpClient.delete(`/linkRequest/${linkRequestId}/delete/reason/${comments}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      }));
  }

  withdrawAttorneyConnection(linkRequestId: number, reqJson) {
    return this.httpClient.post(`/linkRequest/${linkRequestId}/withdraw`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getAttorneys(companyType: string) {
    return this.httpClient.get(`/company/companyType/${companyType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  attorneyConnect(petitionerId: number, attorneyId: number, reqJson) {
    return this.httpClient.post(`/petitioner/dashboard/attorney/link/petitioner/${petitionerId}/attorney/${attorneyId}`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getPetitionsByAttorneyId(attorneyId, companyId) {
    return this.httpClient.get(`/dashboard/attorney/${attorneyId}/company/${companyId}/getPetition`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getBeneficiariesForAttorney(attorneyId, companyId) {
    return this.httpClient.get(`/dashboard/attorney/${attorneyId}/company/${companyId}/getBeneficiary`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      }));
  }

  getBeneficiariesListForAttorney(companyId, payload) {
    return this.httpClient.post(`/attorney/${companyId}/company/${companyId}/beneficiary`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  getTaskList(payload) {
    return this.httpClient.post(`/task`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    )
  }

  getTaskListForAttorney(attorneyId, payload) {
    return this.httpClient.post(`/task/attorney/${attorneyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    )
  }

  delinkBeneficiary(beneficiaryId, attorneyId) {
    return this.httpClient.post(`/beneficiary/deLinkWithAttorney/${beneficiaryId}/${attorneyId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response : null;
      }));
  }

  resendInviteBeneficiary(linkRequestId) {
    return this.httpClient.get(`/linkRequest/${linkRequestId}/resend`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      }));
  }


  getAttorneyCompany() {
    return this.httpClient.get(`/attorney/companylist`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  postCompany(payload: any) {
    return this.httpClient.post(`/companyContact/add`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getCompanyInformation() {
    let reqJson = {
      "compGroupCode": "COMPINFO",
      "docGroupCode": "COMPPETDOCUS",
      "questionGroupCode": "QUECTGTYP"
    }
    return this.httpClient.post(`/attor/getCompanyInformation`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  
  saveRequestedDataAndDocs(payload) {
    return this.httpClient.post(`/attor/saveRequestedDataAndDocs`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  saveBeneficiaryNew(payload) {
    return this.httpClient.post(`/attorney/beneficiary/add`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getExtenalClientDetails(accessCode) {
    return this.httpClient.post(`/attor/company/authenticate/${accessCode}`, null).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

}
