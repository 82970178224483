import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class MaritalHistoryService {

  constructor(private http: HttpClient) { }

  getMaritalHistoryInformation(sponsorId: number, familyId: number) {
    return this.http.get(`/sponsor/${sponsorId}${familyId ? '?familyId=' + familyId : ''}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postMaritalHistoryInformation(sponsorId: number, familyId: number, payload) {
    return this.http.post(`/sponsor/${sponsorId}${familyId ? '?familyId=' + familyId : ''}`,
      payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && (response.status === 200 || response.status === 204)) {
            return response;
          }
        })
      );
  }

  deleteMaritalHistoryItem(maritalHistoryId) {
    return this.http.delete(`/maritalHistory/${maritalHistoryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
