import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaOverallComponent } from './pa-overall/pa-overall.component';
import { PaCategorizedComponent } from './pa-categorized/pa-categorized.component';
import { PetitionAnalysisRoutingModule } from './petition-analysis-routing.module';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { TreeModule } from 'primeng/tree';
import { SharedModule } from "../shared/shared.module";
import { PAUtility } from './utility.service';
import { PaLoadComponent } from './pa-load/pa-load.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    PaOverallComponent,
    PaCategorizedComponent,
    PaLoadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    TreeModule,
    DropdownModule,
    ButtonModule,
    AccordionModule,
    MultiSelectModule,
    CheckboxModule,
    SharedModule,
    PetitionAnalysisRoutingModule
  ],
  providers: [PAUtility],
  bootstrap: [
    PaOverallComponent,
    PaLoadComponent
  ]
})
export class PetitionAnalysisModule { }
