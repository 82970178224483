import { ImagilityBaseResponse } from 'app-models';
import { Injectable, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
@Injectable({
  providedIn: 'root'
})
export class NationalInternationalPrizesService {
  @ViewChild("nationalprizesupload") public nationalprizesupload: UploadImageDialogComponent;
@Output() @ViewChild("nationalprizeskendoeditor") public nationalprizeskendoeditor: EditorComponent;
  constructor(private http:HttpClient) { }

  opennationalprizesuploaddialog() {    
    this.nationalprizesupload.open();
  }

  addAward(awardType,petitionId,payload){
    return this.http.post(`/extraordinaryAbility/awards/${awardType}/petition/${petitionId}`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }

  getAwardList(awardType,petitionId){
    return this.http.get(`/extraordinaryAbility/awards/${awardType}/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  deleteAward(petitionId,awardId){
    return this.http.delete(`/extraordinaryAbility/petition/${petitionId}/award/${awardId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }else
        {
          return null;
        }
      })
    );
  }

  getAdditionalInfo(type,caseType,caseId,infoType){
    return this.http.get(`/${type}/${caseType}/${caseId}/additionalInfo/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else {
          return [];
        }
      })
    );
  }

  saveAdditionalInfo(type,caseType,caseId,payload){
    return this.http.post(`/${type}/${caseType}/${caseId}/additionalInfo`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return [];
        }
      })
    );
  }

}
