<div class="beneficiary-dashboard-container">
  <!-- <div class="header-ad text-center">
     <dashboard-large-banner-ad></dashboard-large-banner-ad>
  </div> -->
  <div class="row">
    <div class="col-md-12">
      <div class="welcome">
        <div class="welcome-flexbox">
          <div class="user-name">Welcome {{userName}}!</div>
          <div class="user-time">
            <span class="material-icons">calendar_today</span><span>{{userTimeNow}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex">


  </div>
  <div class="platform-stat">
  </div>
  <div class="row my-2">
  </div>



  <div class="empty"></div>


</div>


<div class="row">
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-12 px-0">

        <div class="task-table">
          <dashboard-all-time-lines></dashboard-all-time-lines>
        </div>
      </div>

      <div class="col-md-6 my-3 pl-0">
        <div class="contact-details">

          <mat-card class="dashboard-regular-card mat-card pb-30">


            <mat-card-content>
              <contact-details></contact-details>
              <contact-details-attorney></contact-details-attorney>
            </mat-card-content>

          </mat-card>
        </div>
      </div>
      <div class="col-md-6 my-3 px-0">
        <div class="process-time h-100">
          <dashboard-processing-time></dashboard-processing-time>

        </div>

        <!--teamlist-assorted-->
      </div>



    </div>
  </div>
  <div class="col-md-4 pl-0">
    <!----- start top left side box ----->
    <div class="information mb-2">
      <dashboard-profile-card></dashboard-profile-card>
    </div>
    <!-----end  top left side box ----->

    <!-----start top middle box ----->
    <div class="notification ben-notification my-2">
      <notification-list></notification-list>
    </div>

    <div>
      <app-individuals-date-tracker class="w-100"></app-individuals-date-tracker>
    </div>

    <div class="faq-details my-2">
      <mat-card class="dashboard-regular-card mat-card ">

        <mat-card-content>
          <app-faq-dashboard></app-faq-dashboard>
        </mat-card-content>
      </mat-card>

    </div>
    <div class="teamlist-assorted my-2 benf-platform">
      <!-- <div class="todo-micro_ad-team-flexbox">  -->
      <!-- <app-i9-form></app-i9-form> -->
      <platform-statistics></platform-statistics>
      <!-- <div class="ad-box">
        <dashboard-medium-size-box-ad ></dashboard-medium-size-box-ad>
      </div> -->

      <!-- <div class="card border-0 mb-3 shadow-sm mb-30">
        <div class="card-header">To Do List</div>
        <div class="card-body text-center overflow-auto">
          <!-- <dashboard-coming-soon-announce-ad></dashboard-coming-soon-announce-ad> -->
      <!--  <img width="157px" src="../../../assets/comingsoon.png">
        </div>
      </div> -->

      <!-- <div class="banner-xs">
         <dashboard-micro-banner-ad></dashboard-micro-banner-ad>
     </div>   -->
      <!-- </div> -->
    </div>
  </div>
  <!----- end  task box row, right box containing TODO ,micro banner add and  team list ----->
  <!-- <div class="footer-ad">
    <dashboard-large-banner-ad></dashboard-large-banner-ad>
  </div> -->
</div>
<!---container closes-->
