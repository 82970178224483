
<div *ngIf="userType == 'Attorney'">
    <p-card header="Prepare response using" class="bg-gray" [hidden]="true">
        <div class="radio-panel">
            <p-radioButton disabled="true" class="radio_green" (onClick)="changeResponseType()" name="responseType"
                label="Respond to each evidence" value="each" [(ngModel)]="responseType"></p-radioButton>
        </div>
        <label class="px-4 font-weight-bold">OR</label>
        <div class="radio-panel">
            <p-radioButton class="radio_green" (onClick)="changeResponseType()" name="responseType"
                label="Create response manually" value="manual" [(ngModel)]="responseType"></p-radioButton>
        </div>
    </p-card>
    <hr [hidden]="true"/>
    <div>
        <div *ngFor="let item of attoreyResponseSteps;let i=index" class="stepsParentPanel">

            <div class="stepsPanel"
                [ngClass]="{'complete': item?.stepStatusDetails?.stepStatusCode === 'COMPLETE' || item?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'new':item?.stepStatusDetails?.stepStatusCode === 'NEW' }">
                <i class="fa" aria-hidden="true" *ngIf="item?.stepStatusDetails?.stepStatusCode === 'NEW' "></i>
                <i class="fa fa-check" aria-hidden="true"
                    *ngIf="item?.stepStatusDetails?.stepStatusCode === 'COMPLETE'|| item?.stepStatusDetails?.stepStatusCode === 'INPROGRESS'"></i>
                <label>{{ item.stepName }} </label>
                <button [disabled]="disableFields" class="no-label" type="button" pButton (click)="proceedToResponseAction(item);">{{
                    item.buttonLabel }}</button> 
            </div>
        </div>
      </div>
      <div class="mt-2 d-inline-block" *ngIf="allStepsComplete" >
        <button type="button" [disabled]="disableFields"  pButton label="Organize Documents" (click)="displayOrganizeDocumentsDialog();" class="p-button-outlined"></button>
        <div class="d-inline pl-3 ml-3 border-left pt-3 align-bottom">
            <button [disabled]="disableFields" type="button" pButton (click)="generatePdf();" label="Generate Draft PDF" class="btn-success"></button>
        </div>
        <button *ngIf="!disableFields" type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" (click)="changeStatusToComplete();" label="Mark Complete" class="ml-2 button_whitebg p-button-outlined"></button>
        <div *ngIf="disableFields" class="d-inline-block ml-2">
          <img src="../../../../../assets/images/stepCompleteLabel.png" />
        </div>  
      </div>  
        <div class="mt-2 d-inline-block float-right" *ngIf="pdfSrc!=null && pdfSrc.length > 0">
            <button [disabled]="disableFields" type="button" pButton (click)="viewPdf();" label="View Draft PDF" class="btn-success"></button>
            <label class="text-secondary d-block ml-4">{{pdfTimeStamp | date:'dd-MMM-yyyy'}}</label>
        </div>
</div>

<div *ngIf="userType != 'Attorney'" class="p-3 border rounded bg-light">
  <div><strong>Last Updated By : </strong> {{ SubTaskOverView['preparerAttorneyName']}}</div>
  <hr/>
  <div><strong>Status : </strong> {{ SubTaskOverView['taskStepDetails'][2]['stepStatusDetails']['stepStatusName'] }}</div>
  <div><strong>Comment : </strong> {{ SubTaskOverView['taskStepDetails'][2]['stepNote'] }}</div>
</div>

<p-dialog [(visible)]="displaySectionDialog" [draggable]="false" 
[modal]="true" [responsive]="true" (onHide)="getRFEResponseValue()"
styleClass="parentDialog"
         [minY]="70" [maximizable]="false" [baseZIndex]="50000">
        <ng-template pTemplate="header" >
            <div >
            <div class="row">
              <div class="col-12 px-0">
            <h5 class="text-primary">{{ header }}</h5>
            </div>
            </div>
            <div class="row">
              <div class="col-12 px-0">
              <label>{{ subheader }}</label>
            </div>
            </div>
          </div>
          </ng-template>
        <div [ngSwitch]="displaySection">
            <div *ngSwitchCase="'Documents'">
              <app-rfe-prepare-response-step1 [currentStepId]="currentStepId" [rfeNoticeId]="rfeNoticeId" [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-prepare-response-step1>
            </div>
            <div *ngSwitchCase="'ResponseDetails'">
                <app-rfe-prepare-response-step2 [currentStepId]="currentStepId" [rfeNoticeId]="rfeNoticeId" [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-prepare-response-step2>
              </div>
              <div *ngSwitchCase="'PrepareResponse'">
                <app-rfe-prepare-response-step3 [currentStepId]="currentStepId" [rfeNoticeId]="rfeNoticeId" [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-prepare-response-step3>
              </div>  
        </div>

</p-dialog>  

<p-dialog [(visible)]="displayOrganizeDocuments" [draggable]="false" [modal]="true" 
[responsive]="true" [style]="{width: '1000px', minWidth: '1000px',minHeight:'600px',height:'600px'}" [minY]="70"
[maximizable]="false" [baseZIndex]="50000">
<ng-template pTemplate="header">
    <div >
    <div class="row">
      <div class="col-12 px-0">
    <h5 class="text-primary">Organize RFE Documents</h5>
    </div>
    </div>
    <div class="row">
      <div class="col-12 px-0">
      <label>Drag & drop Documents to respected Title sheets</label>
    </div>
    </div>
  </div>
  </ng-template>
  <hr class="mt-0"/>
  <app-rfe-organize-documents [supportingDocumentsTree]="supportingDocumentsTree" [rfeTimelineModalInput]="rfeTimelineModalInput"></app-rfe-organize-documents>
</p-dialog>
 
      
