import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImagilityBaseResponse } from '../data/models/response.model';
// import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService {
  public uploaded$ = new BehaviorSubject<any>({});
  constructor(private http: HttpClient) { }
  public deletedfile$ = new BehaviorSubject<any>({});

  // uploadDocument(beneficiaryId: number, categoryName: string, entityId: number, fileCategory: string, payload) {
  //   return this.http.post(`/document/beneficiary/${beneficiaryId}/category/${categoryName}/entity/${entityId}/fileCategory/${fileCategory}`, payload).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       console.log(response);
  //       return response;
  //     })
  //   );
  // }

  // getUploadDocument(beneficiaryId: number, categoryName: string) {
  //   return this.http.get(`/document/beneficiary/${beneficiaryId}/category/${categoryName}`).pipe(
  //     map((response) => response)
  //   );
  // }

  // deleteUploadDocument(beneficiaryId: number, categoryName: string, fileCategory: string, fileId: number) {
  //   return this.http.delete(`/document/benficiary/${beneficiaryId}/category/${categoryName}/fileCategory/${fileCategory}/${fileId}`).pipe(
  //     map((response) => response)
  //   )
  // }

  genericUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, entityId: any, fileCategory: string,
    otherName: string, payload, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? (appendUrl ? '&':'?' ) + `familyId=${familyId}` : '';
    return this.http.post(`/document/${useCase}/${useCaseId}/category/${categoryName}/entity/${entityId}/fileCategory/${fileCategory}${appendUrl}${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  generiBankUploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number, containerId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/upload/bankDetails/${empId}/category/${categoryName}/fileCategory/${fileCategory}/bId/${containerId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericW4UploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/w4formsupload/${empId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }


  genericForm16UploadDocument(
    categoryName: string, fileCategory: string,
    otherName: string, payload, empId: number, familyId?: number) {
    let appendUrl = '';
    if (otherName) {
      appendUrl = `?otherName=${otherName}`;
    }
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/formBuilder/employee/form16upload/${empId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        this.uploaded();
        return response;
      })
    );
  }

  genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericBankGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number, bId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/fbUploadedDocs/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}/bId/${bId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  genericW4GetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/fbW4UploadedDocs/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }
  genericDeleteUploadDocument(
    useCase: string, useCaseId: number, categoryName: string, fileCategory: string, fileId: number,
    entityId: any, useEntityId: boolean, isEntityBasedDelete: boolean) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const fileIdTobePassed = (isEntityBasedDelete) ? '' : fileId;
    return this.http.delete(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}/fileCategory/${fileCategory}/${fileIdTobePassed}`).pipe(
      map((response) => {
        this.uploaded();
        return response;
      })
    );
  }
  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  deleteBankFileFromDocumentList(Id: number) {
    return this.http.delete(`/formBuilder/delete/bankDetailsDocument/${Id}`).pipe(
      map((data) => {
        this.deletefile(Id);
        return data;
      })
    );
  }

  deleteW4FileFromDocumentList(empId: number, categoryName: string, fileCategory: string) {
    return this.http.delete(`/formBuilder/employee/w4Details/delete/${empId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
      map((data) => {
        this.deletefile(empId);
        return data;
      })
    );
  }

  getEntitySpecificUploadDocument(useCase: string, useCaseId: number, categoryName: string, entity) {
    // console.log(entity);
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}/entity/${entity}`).pipe(
      map((response) => response)
    );
  }

  uploaded() {
    this.uploaded$.next(true);
  }

  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

}
