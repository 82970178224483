import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck, map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { BehaviorSubject } from 'rxjs';
import { typeSourceSpan } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class TaskAllocationService {
  private editStepTimeLineDetails = new BehaviorSubject('');
  currentStepTimeLineDetails = this.editStepTimeLineDetails.asObservable();

  constructor(private httpClient: HttpClient) { }
  changeStepTimeLineDetails(data: any) {
    this.editStepTimeLineDetails.next(data)
  }
  postListStepsConfigured(type, companyId, payload) {
    return this.httpClient.post(`/${type}/company/${companyId}/taskTypes`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  saveTimeLineConfigured(task, taskTypeId, reqPayload) {
    return this.httpClient.post(`/${task}/taskType/${taskTypeId}/timelineSteps`, reqPayload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteListStepsConfigured(type, companyId, taskTypeId) {
    return this.httpClient.delete(`/${type}/company/${companyId}/taskType/${taskTypeId}/deleteSteps`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getStepTableData() {
    return this.httpClient.get("http://localhost:3000/stepTaskTimelineList").pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    )
  }
  
  postTaskTimelineDetails(type, req){
    return this.httpClient.post(`/${type}/timelineSteps`, req).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    )
  }
   
  saveTaskTimelineDetails(type, req, taskTypeId){
    return this.httpClient.post(`/${type}/taskType/${taskTypeId}/timelineSteps`, req).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    )
  }

}
