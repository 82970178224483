export const VIDEOS = {
  BENEFICIARY_VIDEOS: [
    {
      sectionName: 'Imagility for Beneficiaries',
      url: 'https://player.vimeo.com/video/518582941?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Building Beneficiary Profile',
      url: 'https://player.vimeo.com/video/518582858?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Compliance',
      url: '',
      links: [{
        sectionName: 'FDNS Details',
        url: 'https://player.vimeo.com/video/585297061?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

      },
      {
        sectionName: 'Compliance Public and Private Access File',
        url: 'https://player.vimeo.com/video/585296967?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

      }]
    },

    {
      sectionName: 'Using Timelines and Notifications for Beneficiary',
      url: 'https://player.vimeo.com/video/518552861?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },

    {
      sectionName: 'Designing A Job Description',
      url: 'https://player.vimeo.com/video/518507712?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'How to create a H1-B Petition',
      url: '',
      links: [{
        sectionName: 'Part 1: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948515?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]

    },
    // {
    //   sectionName : 'Part 2: How to build a Petition',
    //   url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    // },
    {
      sectionName: 'Importance of Speciality Occupation',
      url: 'https://player.vimeo.com/video/518554434?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    }],
  ATTORNEY_VIDEOS: [
    {
      sectionName: 'Imagility for Lawfirms',
      url: 'https://player.vimeo.com/video/518582501?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Imagility Navigation for Lawfirms',
      url: 'https://player.vimeo.com/video/530250636?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Building Beneficiary Profile',
      url: 'https://player.vimeo.com/video/518582858?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Designing A Job Description',
      url: 'https://player.vimeo.com/video/518507712?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Importance of Speciality Occupation',
      url: 'https://player.vimeo.com/video/518554434?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },

    {
      sectionName: 'How to create Tasks',
      url: 'https://player.vimeo.com/video/518550775?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Creating RFE Sub Task for petition created outside Imagility',
      url: 'https://player.vimeo.com/video/584794763?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Creating RFE Sub Task for petition created through Imagility',
      url: 'https://player.vimeo.com/video/584797978?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Next steps after Tasks creation',
      url: 'https://player.vimeo.com/video/518576512?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'How to create a H1-B Petition',
      url: '',
      links: [{
        sectionName: 'Part 1: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948515?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]
    },
    {
      sectionName: 'How to build a H-1B petition',
      url: '',
      links: [{
        sectionName: 'Part 1 How to Build a H-1B Petition',
        url: 'https://player.vimeo.com/video/665524738?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2 How to Build a H-1B Petition',
        url: 'https://player.vimeo.com/video/665526024?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]
    },
    {
      sectionName: 'Building an RFE Response',
      url: '',
      links: [{
        sectionName: 'Part 1: Building an RFE Response',
        url: 'https://player.vimeo.com/video/584799030?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2: Building an RFE Response',
        url: 'https://player.vimeo.com/video/584800721?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]
    },
    // {
    //   sectionName : 'Part 2: How to build a Petition',
    //   url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    // },
    {
      sectionName: 'How to use Notifications to collabrate?',
      url: 'https://player.vimeo.com/video/518583015?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Picking the right LCA',
      url: 'https://player.vimeo.com/video/518579514?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Lawfirm Creating Clients',
      url: 'https://player.vimeo.com/video/727663099?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    },
    {
      sectionName: 'Lawfirm Creating Beneficiary',
      url: 'https://player.vimeo.com/video/727660795?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    },
    {
      sectionName: 'Lawfirm Inviting Beneficiary',
      url: '',
      links: [{
        sectionName: 'Part 1: Lawfirm Inviting Beneficiary',
        url: 'https://player.vimeo.com/video/880507423?h=4e34a98ccf',

      },
      {
        sectionName: 'Part 2: Beneficiary accepting Invite from Lawfirm',
        url: 'https://player.vimeo.com/video/880523778?h=b457e302d9',

      }]
    },
    {
      sectionName: 'How to E-file LCA',
      url: '',
      links: [{
        sectionName: 'Part 1 LCA E-filing through Imagility',
        url: 'https://player.vimeo.com/video/793262715?h=7db2881a77',

      },
      {
        sectionName: 'Part 2 LCA E-filing through Imagility',
        url: 'https://player.vimeo.com/video/794795720?h=a841df8d2a',

      }]
    },
    {
      sectionName: 'How to Build Reports on Imagility',
      url: 'https://player.vimeo.com/video/665524422?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Document Checklist',
      url: 'https://player.vimeo.com/video/880526790?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    }
  ],
  PETITIONER_VIDEOS: [
    {
      sectionName: 'Imagility for Petitioners',
      url: 'https://player.vimeo.com/video/518583749?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    },
    {
      sectionName: 'Building Beneficiary Profile',
      url: 'https://player.vimeo.com/video/518582858?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'How to create Tasks',
      url: 'https://player.vimeo.com/video/518550775?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Creating RFE Sub Task for petition created outside Imagility',
      url: 'https://player.vimeo.com/video/584794763?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Creating RFE Sub Task for petition created through Imagility',
      url: 'https://player.vimeo.com/video/584797978?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Next steps after Tasks creation',
      url: 'https://player.vimeo.com/video/518576512?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'How to create a H1-B Petition',
      url: '',
      links: [{
        sectionName: 'Part 1: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948515?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2: How to build a Petition',
        url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]

    },
    {
      sectionName: 'How to build a H-1B petition',
      url: '',
      links: [{
        sectionName: 'Part 1 How to Build a H-1B Petition',
        url: 'https://player.vimeo.com/video/665524738?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2 How to Build a H-1B Petition',
        url: 'https://player.vimeo.com/video/665526024?h=73f0f03534&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]
    },
    {
      sectionName: 'Building an RFE Response',
      url: '',
      links: [{
        sectionName: 'Part 1: Building an RFE Response',
        url: 'https://player.vimeo.com/video/584799030?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      },
      {
        sectionName: 'Part 2: Building an RFE Response',
        url: 'https://player.vimeo.com/video/584800721?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

      }]
    },
    // {
    //   sectionName : 'Part 2: How to build a Petition',
    //   url: 'https://player.vimeo.com/video/518948679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    // },
    {
      sectionName: 'How to use Notifications to collabrate?',
      url: 'https://player.vimeo.com/video/518583015?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

    },
    {
      sectionName: 'Designing A Job Description',
      url: 'https://player.vimeo.com/video/518507712?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Importance of Speciality Occupation',
      url: 'https://player.vimeo.com/video/518554434?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Picking the right LCA',
      url: 'https://player.vimeo.com/video/518579514?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'How to Build Reports on Imagility',
      url: 'https://player.vimeo.com/video/665524422?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    },
    {
      sectionName: 'Compliance',
      url: '',
      links: [{
        sectionName: 'FDNS Details',
        url: 'https://player.vimeo.com/video/585297061?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

      },
      {
        sectionName: 'Compliance Public and Private Access File',
        url: 'https://player.vimeo.com/video/585296967?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

      }]
    },
    {
      sectionName: 'How to E-file LCA',
      url: '',
      links: [{
        sectionName: 'Part 1 LCA E-filing through Imagility',
        url: 'https://player.vimeo.com/video/793262715?h=7db2881a77',

      },
      {
        sectionName: 'Part 2 LCA E-filing through Imagility',
        url: 'https://player.vimeo.com/video/794795720?h=a841df8d2a',

      }]
    },
    {
      sectionName: 'Petitioner Inviting Beneficiary',
      url: '',
      links: [{
        sectionName: 'Part 1: Petitioner Inviting Beneficiary',
        url: 'https://player.vimeo.com/video/880507423?h=4e34a98ccf',

      },
      {
        sectionName: 'Part 2: Beneficiary accepting Invite from Petitioner',
        url: 'https://player.vimeo.com/video/880523778?h=b457e302d9',

      }]
    },
    {
      sectionName: 'Document Checklist',
      url: 'https://player.vimeo.com/video/880526790?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',

    }
  ]
};
