
  <p-dialog [draggable]="false" [(visible)]="displayDocumentPanelForResponse" [modal]="true" [responsive]="true"
      [style]="{width: '1000px', minWidth: '200px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
      <ng-template pTemplate="header">
          <div>
              <div class="row">
                  <div class="col-12 px-0">
                      <h5 class="text-primary mb-0">Request RFE Response Details</h5>
                  </div>
              </div>
          </div>
      </ng-template>
      <hr class="mt-0" />
      <div class="row form-group">
          <div class="row pl-0 outerRow w-100" *ngFor="let item of supportingDocumentsForNotification;let i=index">
            <div class="col-5 pl-0">
                  <div class="text-secondary pb-1"><small>Category</small></div>
                  <p-checkbox name="group2" value="item" [value]="item" [inputId]="item.categoryDtlId" (onChange)="checkedCheckbox($event)"
                      [(ngModel)]="selectedCategoryTypeForResponse"></p-checkbox>
                  <label [for]="item.categoryDtlId" class="d-inline-block mb-0 ml-2">{{item.categoryName}}</label>
              </div>
              <div class="col-6  border-left">
                  <div class="text-secondary pb-1"><small>Request for</small></div>
                  <div class="row">
                      <div class="col-6 pl-0">
                          <p-radioButton name="notifyTo" value="petitioner" class="mr-2" label="Petitioner"
                              [(ngModel)]="item.notifyTo"></p-radioButton>
                      </div>
                      <div class="col-6 pl-0">
                          <p-radioButton name="notifyTo" value="beneficiary" class="mr-2" label="Beneficiary"
                              [(ngModel)]="item.notifyTo"></p-radioButton>
                      </div>
                      <!-- <div class="col-4 pl-0"><p-radioButton name="notifyTo"  value="" class="mr-2" label="None" [(ngModel)]="documentType.notifyTo" ></p-radioButton></div> -->
                  </div>
              </div>
              <div class="col-12 my-2">
                  <h6 class="mt-1">Add Notes / Comments</h6>
                  <input type="text" pInputText [(ngModel)]="item.notes" style="width:100%;height:80px" />
              </div>     
              <div class="col-12 px-0">
                  <hr class="mb-0" />
              </div>                            
          </div>

              
          </div>
          <div class="mt-2 w-100">
              <!-- <button type="button" pButton (click)="saveData();" label="Save"></button> -->
              <!-- <button type="button" iconPos="right" pButton icon="fa fa-exclamation-circle" (click)="changeStatus();" label="Mark Complete" class="float-right button_whitebg p-button-outlined"></button> -->
            </div>    

      <p-footer>
          <button type="button" [disabled]="!requestEnabled" pButton label="Request" (click)="sendNotificationFromResponse()"></button>
      </p-footer>
  </p-dialog>
 
  <button pButton type="button" (click)="setDisplayDocumentPanelFromResponse()" label="Request RFE Response Details"
      class="d-block p-button-outlined"></button>
  <small
      [ngClass]="{'d-block mt-2 text-success': notificationStatusUpdateForResponse === 'Response received from Petitioner/Beneficiary',
                                      'd-none' : notificationStatusUpdateForResponse === 'Not Available',
                                   'mt-2  text-secondary d-block':notificationStatusUpdateForResponse === 'Request Sent - Awaiting for Response' }">
      {{ notificationStatusUpdateForResponse }}</small>
  <div *ngIf="!contentAvailable" class="border mt-2 p-2">
      <mat-icon class="mr-1 icn" class="text-warning material-icons">warning</mat-icon>
      <span class="text-secondary align-top">Currently no reponse added by Petitioner/ Beneficiary</span>
  </div>
  <div *ngIf="responseDetails != null" class="p-2">
      <div *ngFor="let item of responseDetails;let i=index" >
          <div *ngIf="item.responseText" class="border p-3 mt-2">
            <h6 class="d-inline-block">{{item.rfeNoticeCategoryName}}</h6>
            <label class="float-right">{{item.responseProvidedBy}} Response</label>
            <div class="border p-2" [innerHTML]="item.responseText | keepHtml"></div>
        </div>
      </div>

  </div>

  <div *ngIf="supportingDocumentsForResponse != null" class="p-2">
    <div *ngFor="let item of supportingDocumentsForResponse;let i=index">
        <div *ngIf="item.titleSheetDetailDocName" class="border p-3 mt-2">
            <h6 class="d-inline-block">{{item.categoryName}} </h6>
            <label class="float-right">{{item.notifyTo}} Response</label>
            <div class="border p-2">
                {{item.titleSheetDetailDocName}}
            </div>
        </div>
    </div>

</div>

  <hr />
  <div class="mt-2 w-100">
      <!-- <button type="button" pButton (click)="saveData();" label="Save"></button> -->
      <button type="button" pButton (click)="changeStatus();" label="Complete"
          class="ui-button-secondary float-right"></button>
  </div>