<div class="col-md-12 p-0">
    <div class="row border-bottom">
        <div class="col-md-10 py-3 px-0">
            <h5 class="text-primary m-0">Petition Building</h5>
        </div>
        <div class="col-md-2 p-0 align-items-center justify-content-end d-flex">
            <i (click)="handleNo()" class="close-icon pi pi-times"></i>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 py-3 px-0">
            <h6 class="m-0">How do you want to build the petition?</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pb-3 px-0">
            <div class="row">
                <div *ngFor="let process of processes; let i = 'index'" class="col px-2">
                    <div class="border p-2 h-100 py-3" style="cursor:pointer" [ngClass]="process.isChecked? 'border-primary' : ''" (click)="selectProcess(i)">
                        <p class="text-center" style="font-size: 35px;"><i class="fa text-primary {{process.icon}}"></i></p>
                        <h6 class="my-2 text-center font-weight-bold">{{process.title}}</h6>
                            
                        <p class="mb-0 mt-2 small text-gray position-relative text-center">{{process.description}}</p>     
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-center d-flex pr-0">
        <div class="col-md-12 px-2 text-center">
            <button pButton type="button" label="Continue" (click)="handleYes()"></button>
        </div>
    </div>
</div>