import { Injectable, NgZone, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationError } from '@angular/router';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  private message: string;

  constructor() {
    super();
  }

  handleError(error: any): void {
    //Backend returns unsuccessful response codes such as 404, 500 etc.
     console.log('An error occurred:', error);
    console.log('Stack trace:', error.stack);
    //let zone = this.injector.get(NgZone);
    //let router = this.injector.get(Router);
    //if(error.status == 403) {
    //this.message =  `Error occured`;
    // zone.run(() => router.navigate(['/something-went-wrong'],{ state: { data:{ url:'',message:this.message } }}));

    // }

  }
}

