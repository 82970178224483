import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class VisaBulletinService {

  constructor(private http: HttpClient) { }

  getIndividualTracker(uId, payload) {
    return this.http.post(`/visaBulletinTask/attorney/${uId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  notifyBeneficiary(data) {
    return this.http.post(`/sendEmailNotificationToIndividual`,data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } 
      })
    ); 
  }

  downloadDetails(fName: any, payload) {
    const fileName = fName === 'Excel' ? 'XLS' : fName;
    return this.http.post(`/reports/attorney/${sessionStorage.getItem('companyId')}/visaBulletin/generate/CSV`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else { 
          return {};
        }
      }));
  }

  downloadReport(Path, fileName, fileType) {
    this.http.get(Path, { responseType: 'arraybuffer' }).subscribe(data => {
      const blob = new Blob([data], { type: fileType });
      saveAs(blob, fileName);
    });
  }

}
