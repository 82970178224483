import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoTourService {
  public videoTourStarted$ = new BehaviorSubject<any>(false);
  constructor(private http: HttpClient) { }
  videoTourStart(userType) {
    this.videoTourStarted$.next(userType);
  }

}
