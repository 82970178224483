import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isLoggedIn: boolean;
  termsPath;
  privacyPath;
  contactPath;
  hideContent: boolean = false;
  userType;
  currentYear;
  domainName:any;
  footerNameString:any;
  isFBUser: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) { }

  ngOnInit() {

    this.domainName = this.userService.domainName;
    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;    
    this.footerNameString = regexExp.test(this.domainName);

    this.authenticationService.currentUser.subscribe((data) => {
      this.isLoggedIn = data ? true : false;
      let userPath;
      this.currentYear = (new Date()).getFullYear();
        this.userType = sessionStorage.getItem('userTypeRole');
        if (this.userType == 'Petitioner') {
          userPath = `${environment.appUrl}/petitioner-landing/`;
        } else if (this.userType == 'Beneficiary') {
          userPath = `${environment.appUrl}/beneficiary-landing/`;
        } else if (this.userType == 'Attorney') {
          userPath = `${environment.appUrl}/attorney-landing/`;
        } else if(this.userType == 'Platform') {
          this.hideContent = true;
        }
        this.termsPath = userPath + 'terms-and-conditions';
        this.privacyPath = userPath + 'privacy';
        this.contactPath = userPath + 'contact-us';
    });
    this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
  }

}
