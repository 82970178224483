<div class="container-fluid">
  <div class="app-container">
    <app-header [isHeaderIncluded]="isHeaderIncluded" *ngIf="!isFBModulePreview && !isExternal"></app-header>
    <router-outlet (activate)='onActivate()' (deactivate)='onDeactivate()'>
    </router-outlet>
  </div>
  <app-modal [modalId]="'timeoutModal'" (closed)="timeoutModalClosed()" #timeoutModal>
    <h5 class="modal-title">Time Out Warning</h5>
    <div class="modal-body pr-2 pl-2 pt-2">
      <p class="text-justify">Your session will time out in {{timeLeft|secondsToTime}} seconds. Do you wish to continue
        the session?</p>
    </div>
    <div class="modal-footer justify-content-md-center">
      <button type="button" class="btn btn-primary continue-button" (click)="closeTimeoutModal('Yes')">
        <span class="float-left">Yes</span>
        <i class="icon-next button-chevron"></i>
      </button>
      <button type="button" class="btn btn-secondary continue-button" (click)="closeTimeoutModal('No')">
        <span class="float-left">No</span>
        <i class="icon-next button-chevron"></i>
      </button>
    </div>
  </app-modal>
  <app-modal [modalId]="'reloadModal'" (closed)="closedReloadModal()" #reloadModal>
    <h5 class="modal-title">Reload Page</h5>
    <div class="modal-body pr-2 pl-2 pt-2">
      <p class="text-justify">Another user session is active now. Please reload this page to proceed.</p>
    </div>
    <div class="modal-footer justify-content-md-center">
      <button type="button" class="btn btn-primary continue-button" (click)="closeReloadModal()">
        <span class="float-left">Reload</span>
        <i class="icon-next button-chevron"></i>
      </button>
    </div>
  </app-modal>
  <app-footer id="footer"></app-footer>
  <app-config-menu *ngIf="!isHeaderIncluded"></app-config-menu>
</div>
<ngx-ui-loader></ngx-ui-loader>
