import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OtherInformationService {

  constructor(private http:HttpClient) { }

  getOtherInformation(type, caseId: number) {
    return this.http.get(`/${type}/PETITION/${caseId}/timeLine/otherInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postOtherInformation(visaType, caseType, caseId: number, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/timeLine/otherInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }
  postRelativeInformation(visaType, caseType, caseId: number, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/timeLine/otherRelative`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  getAllRelativesInfo(visaType, caseType, caseId: number) {
    return this.http.get(`/${visaType}/${caseType}/${caseId}/timeLine/allRelatives`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  deleteRelativeItem(visaType, caseType,caseId,id) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/timeLine/otherRelative/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
