import { Component, OnInit, Input } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { UploadImageInfo } from '../upload-image/upload-image.component';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent implements HttpInterceptor {
  @Input() public editor: EditorComponent;
  public opened = false;
  public src: string;
  public height: number;
  public width: number;

  public get canInsert(): boolean {
      return !this.src;
  }

  public uploadImage(): void {
      // Invoking the insertImage command of the Editor.
      this.editor.exec('insertImage', this.UploadImageInfo);

      // Closing the Dialog.
      this.close();
  }

  public get UploadImageInfo(): UploadImageInfo {
      return {
          src: this.src,
          height: this.height,
          width: this.width
      };
  }

  public setUploadImageInfo(value: UploadImageInfo) {

      if (value) {
          this.src = value.src;
          this.height = value.height;
          this.width = value.width;
      } else {
          this.resetData();
      }
  }

  public open(): void {
      this.opened = true;
  }

  public close(): void {
      this.opened = false;
      this.resetData();
  }

  public resetData(): void {
      this.src = null;
      this.width = null;
      this.height = null;
  }
  /*
      Mocked backend service.
      For further details, check
      https://angular.io/guide/http#writing-an-interceptor
 */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     //if(req.url.indexOf("api/v1saveUrl") != -1){
     if((req.url.indexOf("api/v1saveUrl") != -1)|| (req.url.indexOf("saveUrl") != -1)){   
          //return of(new HttpResponse({ status: 200 }));
          return of(new HttpResponse({ status: 200, body: ""}));
      }
      return next.handle(req);
  }
}