import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomKendoRoutingModule } from './custom-kendo-routing.module';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { UploadModule } from "@progress/kendo-angular-upload";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadImageDialogComponent } from './upload-image-dialog/upload-image-dialog.component';

@NgModule({
  declarations: [UploadImageComponent, UploadImageDialogComponent],
  imports: [
    CommonModule,
    CustomKendoRoutingModule,
    DialogsModule,
    InputsModule,
    UploadModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadImageDialogComponent,
      multi: true,
    },
  ],
  exports:[UploadImageDialogComponent]
})
export class CustomKendoModule { }
