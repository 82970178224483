<form [formGroup]="notifyBeneficiaryForm" (ngSubmit)="notifyBeneficiaryFormSub()">
    <div class="container p-0 notify-beneficiary">
        <div class="form-row">
        <div class="form-group col-md-12 notifify-title">
            <h6 mat-dialog-title>Send Notification</h6>
            <button mat-dialog-close mat-icon-button aria-label="Close" class="icon-button">
            <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="col-md-12">
            <hr>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>To</mat-label>
                    <mat-select name="beneficiary" formControlName="sendTo" (selectionChange)="onChangeBeneficiary($event.value)">
                        <mat-option *ngFor="let item of beneficiaryList" [value]="item.beneficiaryEmployeeId
                        "> 
                            {{item.beneficiaryEmployeeFName}}{{" "}}{{item.beneficiaryEmployeeLName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="invalid-message"
                    *ngIf="(notifyBeneficiaryForm.get('sendTo').touched && notifyBeneficiaryForm.get('sendTo').errors?.required)">
                    {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
            </div>
        </div> 
        <div class="col-md-12">
            <textarea formControlName="sendMessage" pInputTextarea [rows]="5"  name="question" placeholder="Enter Your Message..."></textarea>
            <span class="invalid-message"
                *ngIf="(notifyBeneficiaryForm.get('sendMessage').touched && notifyBeneficiaryForm.get('sendMessage').errors?.required)">
                {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
            <hr>
        </div> 
        </div> 
        <div class="submit-portion">
            <button mat-button cdkFocusInitial class="btn-primary" [mat-dialog-close]>CANCEL</button>
            <button mat-button [mat-dialog-close] cdkFocusInitial class="btn-primary" type="submit" [disabled]="notifyBeneficiaryForm.invalid">SEND</button> 
        </div> 
    </div>
</form>