<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-8 step-title">
      <h5 class="mb-0">Provide Petitioner Details & Documents <span *ngIf="fields.showUSCompanyTitle">(US
          Company)</span></h5>
      <p class="mb-0">Upload supporting petitioner documents </p>
    </div>
    
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <gc-status-update [stepDetails]="stepDetails" [isViewMode]="inputData['isViewMode']" [isCaseRequest]="inputData['isCaseRequest']"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" type="EDIT">
      </gc-status-update>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
      (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
    </lib-assigning-task-library>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="main-body footer-adjust">
    <lib-last-update-library [inputDetails]="stepDetails" [primaryData]="inputData" sectionCode="PETITIONER_DOCS"></lib-last-update-library>
    <lib-in-care-of-name [inputData]="inputData" [petitionId]="inputData.caseId" [disabledControls]="disabledControls"></lib-in-care-of-name>
    <div class="row col-md-12 pt-3 pb-3">     
      <h5 class="sub-title">Petitioner Details <span *ngIf="fields.showUSCompanyTitle">(US Company)</span></h5>
    </div>
    <div class="row pb-3">
      <div class="col">
        <label class="label-cls">Employer Name</label>
        <div class="readOnly-label">{{petitionerDetails?.companyName}}</div>
      </div>
    </div>
    <div class="row  pb-3">
      <div class="col-md-4">
        <label class="label-cls">Address Line 1</label>
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length
          -1]?.addressLine1}}</div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">Address Line 2</label>
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length
          -1]?.addressLine2}}</div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">Country</label>
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length
          -1]?.countryName}}</div>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-md-4">
        <label class="label-cls">State</label>
        <!-- <div class="readOnly-label" *ngIf="!petitionerDetails?.address[petitionerDetails.address.length -1]?.stateProvinceName === null">{{petitionerDetails?.address[petitionerDetails.address.length -1]?.stateProvinceName}}</div> -->
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length -1]?.state}}
        </div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">City</label>
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length -1]?.city}}
        </div>
      </div>
      <div class="col-md-4">
        <label class="label-cls">ZIP / Postal Code</label>
        <div class="readOnly-label">{{petitionerDetails?.address[petitionerDetails.address.length
          -1]?.postCode}}</div>
      </div>
    </div>
    <p-divider *ngIf="fields.showDividerBeforeEntitiesRelationshipdetails"></p-divider>
    <div class="row col-md-12 pl-0 pr-0 profile-title pt-3" *ngIf="fields.showEntitiesRelationshipdetails">
      <h6>US/Foreign Company Relationship Details</h6>
      <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
        <!-- <textarea [(ngModel)]="template.body" name="text" [froalaEditor]="options">
            </textarea> -->
            <kendo-editor [(value)]="template.body" (valueChange)="petitionerdetailseditorValueChange($event)" #petitionerdetailskendoeditor style="height: 400px;" formControlName="text" name="text">
              <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                  <kendo-toolbar-button
                  title="Upload’s Image at cursor point"
                  text="Upload Image"
                  (click)="openpetitionerdetailsimageuploaddialog()"
                  ></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>                                  
              </kendo-toolbar>
          </kendo-editor>
          <app-upload-image-dialog #petitionerdetailsimageupload [editor]="petitionerdetailskendoeditor"></app-upload-image-dialog>      
        <button type="button" pButton class="mt-3 p-button-outlined" (click)="handleSave()">SAVE</button>
      </div>
    </div>
    <div class="row col-md-12 pl-0 pr-0 profile-title pt-3" *ngIf="fields.showPetitionOrganizationDetails">
      <form class="p-form w-100" (ngSubmit)="postpetitionerDetails()" [formGroup]="petitionerDetailsForm">
        <div class="row col-md-12 mb-2">
          <div class="col-md-6 pl-0 bold-label" name="question">
            <label class="label-cls">Does an appropriate labor organization exist for the petition</label>
          </div>
          <div class="col-md-6">
            <p-inputSwitch id="doesLabourOrgExist" [disabled]="disabledControls" formControlName="doesLabourOrgExist">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row col-md-8 mb-4">
          <input type="text" [disabled]="disabledControls" pInputText
            [readonly]="petitionerDetailsForm.get('doesLabourOrgExist').value" class="p-form-control"
            placeholder="Enter" formControlName="noLabOrgExplanation" />
        </div>
        <div class="row col-md-12 mb-2">
          <div class="col-md-12 pl-0 bold-label" name="question">
            <label class="label-cls">Is the required consultation or written advisory opinion being submitted with this
              petition?</label>
          </div>
        </div>
        <div class="row col-md-12 mb-4">
          <div class="col-3 p-0">
            <p-radioButton class="float-left p-checkbox mr-2" formControlName="isAdvOpinionSubmt" [value]="1"
              label="Yes">
            </p-radioButton>
          </div>
          <div class="col-5">
            <p-radioButton class="float-left p-checkbox ml-2 mr-2" formControlName="isAdvOpinionSubmt" [value]="0"
              label="No - copy of request attached">
            </p-radioButton>
          </div>
          <div class="col-4">
            <p-radioButton class="float-left p-checkbox ml-2 mr-2" formControlName="isAdvOpinionSubmt" [value]="2"
              label="N/A">
            </p-radioButton>
          </div>
        </div>
        <div class="row w-100 mt-2" formGroupName="peerPetitionAdvOpinionOrgDtlResponseDTO">
          <div class="row w-100 mb-2">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls"> Name of Recognized Peer / Peer Group or Labor Organization </label>
              <input class="im-input mw-100" id="orgName" formControlName="orgName" type="text" pInputText
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgName').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgName').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgName').errors?.required && (petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0)">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
          </div>
          <div class="row w-100 mb-2" formGroupName="orgAddrId">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls required"> Address Line 1 </label>
              <input class="im-input mw-100" id="addressLine1" formControlName="addressLine1" type="text" pInputText
                [required]="petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').errors?.required && (petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0)">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-6 input-disabled">
              <label class="label-cls"> Address Line 2 </label>
              <input class="im-input mw-100" id="addressLine2" formControlName="addressLine2" pInputText type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
            </div>
          </div>
          <div class="row w-100 mb-3" formGroupName="orgAddrId">
            <div class="col-md-3 form-group processing-dropdown">
              <label class="label-cls  required"> Country </label>
              <p-dropdown [options]="countryList" id="countryCode" formControlName="countryCode" class="p-form-control"
                [required]="petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0"
                [disabled]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                name="countryCode" optionLabel="countryName" optionValue="countryCode" placeholder="select"
                (onChange)="handleCountryChange($event.value, 0, 'workAddress')"> </p-dropdown>
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 form-group processing-dropdown"
              *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[0] && statelistArrObj.workAddress[0].stateList && statelistArrObj.workAddress[0].stateList.length > 0">
              <label class="label-cls required"> State </label>
              <p-dropdown appendTo="body" [options]="statelistArrObj.workAddress[0].stateList"
                [required]="petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0"
                id="workAddressStateProvinceCode" formControlName="stateProvinceCode" [disabled]="disabledControls"
                name="state" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
              </p-dropdown>
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled"
              *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[0] && (!statelistArrObj.workAddress[0].stateList || (statelistArrObj.workAddress[0].stateList && statelistArrObj.workAddress[0].stateList.length === 0))">
              <label class="label-cls"> State </label>
              <input class="im-input mw-100" id="workAddressStateProvinceCodeText" formControlName="stateProvinceName"
                pattern="[a-zA-Z ]*" type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)" pInputText
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled">
              <label class="label-cls  required"> City </label>
              <input class="im-input mw-100" id="city" formControlName="city" pInputText type="text"
                [required]="petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0" pattern="[a-zA-Z ]*"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled">
              <label class="label-cls required"> Postal Code </label>
              <input class="im-input" id="postCode" formControlName="postCode" type="text" pInputText
                [required]="petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
          </div>
          <div class="row w-100 mb-3">
            <div class="col-md-3 input-disabled">
              <label class="label-cls"> Date Sent </label>
              <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [showIcon]="true"
                [disabled]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)" formControlName="sentDate" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="1910:2050">
              </p-calendar>
              <!-- <span class="invalid-message" *ngIf="petitionerDetailsForm.get('orgAddrId.sentDate').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
                <span class="invalid-message" *ngIf="petitionerDetailsForm.get('orgAddrId.sentDate').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>  -->
            </div>

            <div class="col-md-3 input-disabled">
              <label class="label-cls">Daytime Telephone Number </label>
              <input class="im-input mw-100" id="phoneNo" formControlName="phoneNo" pInputText type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                maxlength="15" placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo').touched && petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo').invalid">
                <!-- <span class="invalid-message" *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span> -->
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 mt-2">
          <div class="col-md-6 pl-0 bold-label" name="question">
            <label class="label-cls">Click here to add other category</label>
          </div>
          <div class="col-md-6">
            <p-inputSwitch id="isManagementPetitionAvailable"
              [disabled]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
              formControlName="isManagementPetitionAvailable">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row w-100 mt-2" *ngIf="petitionerDetailsForm.get('isManagementPetitionAvailable').value"
          formGroupName="managementPetitionAdvOpinionOrgDtlResponseDTO">
          <div class="row w-100 mb-2">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls"> Name of Management Organization </label>
              <input class="im-input mw-100" id="orgName" formControlName="orgName" type="text" pInputText
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgName').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgName').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgName').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
          </div>
          <div class="row w-100 mb-2" formGroupName="orgAddrId">
            <div class="col-md-6 form-group input-disabled">
              <label class="label-cls required"> Address Line 1 </label>
              <input class="im-input mw-100" id="addressLine1" required formControlName="addressLine1" type="text"
                pInputText [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1').errors?.required && (petitionerDetailsForm.get('isAdvOpinionSubmt').value === 0)">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-6 input-disabled">
              <label class="label-cls"> Address Line 2 </label>
              <input class="im-input mw-100" id="addressLine2" formControlName="addressLine2" pInputText type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
            </div>
          </div>
          <div class="row w-100 mb-3" formGroupName="orgAddrId">
            <div class="col-md-3 form-group processing-dropdown">
              <label class="label-cls  required"> Country </label>
              <p-dropdown [options]="countryList" id="countryCode" formControlName="countryCode"
                [disabled]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                name="countryCode" required optionLabel="countryName" optionValue="countryCode" placeholder="select"
                (onChange)="handleCountryChange($event.value, 1, 'workAddress')"> </p-dropdown>
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 form-group processing-dropdown"
              *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[1] && statelistArrObj.workAddress[1].stateList && statelistArrObj.workAddress[1].stateList.length > 0">
              <label class="label-cls required"> State </label>
              <p-dropdown appendTo="body" [options]="statelistArrObj.workAddress[1].stateList"
                id="workAddressStateProvinceCode" formControlName="stateProvinceCode" [disabled]="disabledControls"
                name="state" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
              </p-dropdown>
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled"
              *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[1] && (!statelistArrObj.workAddress[1].stateList || (statelistArrObj.workAddress[1].stateList && statelistArrObj.workAddress[1].stateList.length === 0))">
              <label class="label-cls"> State </label>
              <input class="im-input mw-100" id="workAddressStateProvinceCodeText" formControlName="stateProvinceName"
                pattern="[a-zA-Z ]*" type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)" pInputText
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled">
              <label class="label-cls  required"> City </label>
              <input class="im-input mw-100" id="city" formControlName="city" pInputText type="text" required
                pattern="[a-zA-Z ]*"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
            <div class="col-md-3 input-disabled">
              <label class="label-cls required"> Postal Code </label>
              <input class="im-input" id="postCode" formControlName="postCode" type="text" pInputText required
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                placeholder="Enter" />
              <div
                *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').invalid">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').touched && petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
          </div>
          <div class="row w-100 mb-3">
            <div class="col-md-3 input-disabled">
              <label class="label-cls"> Date Sent </label>
              <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
                [showIcon]="true"
                [disabled]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)" formControlName="sentDate" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="1910:2050">
              </p-calendar>
            </div>

            <div class="col-md-3 input-disabled">
              <label class="label-cls">Daytime Telephone Number </label>
              <input class="im-input mw-100" id="phoneNo" formControlName="phoneNo" pInputText type="text"
                [readonly]="disabledControls || (petitionerDetailsForm.get('isAdvOpinionSubmt').value !== 0)"
                maxlength="15" placeholder="Enter" />
              <div *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.phoneNo')">
                <span class="invalid-message"
                  *ngIf="petitionerDetailsForm.get('managementPetitionAdvOpinionOrgDtlResponseDTO.phoneNo').errors?.pattern">
                  {{getGlobalErrorMessages('INVALID_FORMAT')}}
                </span>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
    <p-divider></p-divider>
    <div class="row col-md-12 p-0 pt-2 d-flex align-items-center">
      <div class="col-md-6">
        <h5 class="sub-title">Petitioner Documents <span *ngIf="fields.showUSCompanyTitle">(US Company)</span></h5>
      </div>
      <div class="col-md-6 sync-btn">
        <button pButton type="button" label="" pTooltip="SYNC FROM PROFILE" class="p-button-outlined  pull-right"
          [disabled]="disabledControls" (click)="syncPetitionerDocuments()"><i
            class="pi pi-check"></i>&nbsp;&nbsp;&nbsp;</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 p-0 ">
        <div class="courseList col-md-12 p-0">
          <p-card class="courses-card">
            <div class="row document-header">
              <div class="col-md-5">
                <h6>Document Type</h6>
              </div>
              <div class="col-md-5">
                <h6>Document Name</h6>
              </div>
              <div class="col-md-2">
                <h6>Action</h6>
              </div>
            </div>
            <div class="row document  courses-title d-flex align-items-center"
              *ngFor="let item of petitionerDocumentList; let i = index">
              <div class="col-md-5">
                <span>
                  {{ item?.fileCategory.code === 'OTHER' && item.isPresent ? item.fileCtaegoryOtherName : item?.fileCategory.name }}
                </span>
              </div>
              <div class="col-md-5">
                <span *ngIf="item.isPresent">
                  {{ item.fileName }}
                </span>
                <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                  <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                </span>
              </div>
              <div class="col-md-2 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
                <div class="mr-2 padding0">
                  <button pButton type="button" icon="pi pi-eye" pTooltip="View" [disabled]="!item.fileLocation"
                    (click)="openDoc(item)" class="p-button-text p-button-rounded"></button>
                </div>
                <div class="mr-2 padding0">
                  <button *ngIf="userType === 'Attorney'" pButton pRipple type="button" style=" color: #75777A;"
                    icon="pi pi-directions-alt icons-arrow" class="p-button-rounded p-button-text" pTooltip="Send Back"
                    [disabled]="disabledControls" (click)="handleSendBack(item)"></button>
                </div>
                <div class="mr-2 padding0">
                  <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                    (click)="deleteImmigrationDocuments(item)" [disabled]="disabledControls"
                    class="p-button-text p-button-rounded"></button>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!item?.isPresent">
                <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Notify" type="button"
                  icon="pi pi-bell" [disabled]="disabledControls || userType === 'Petitioner'" style=" color: #75777A;"
                  (click)="handleNotification(item)" class="p-button-text p-button-rounded"></button>

                <!-- <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Not Applicable"
                              type="button" icon="pi pi-ban" [disabled]="disabledControls"
                              class="p-button-text p-button-rounded"></button> -->
              </div>
            </div>
          </p-card>
        </div>
      </div>
      <!-- <div class="col-md-12 p-0 ">
            <i140-file-upload style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
            </i140-file-upload>
        </div> -->
    </div>
    <p-divider></p-divider>
    <div class="row col-md-12 p-0 pt-2 d-flex align-items-center">
      <div class="col-md-6">
        <!-- <p class="mb-0">Upload any additional required documents</p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0 ">
        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="otherDocumentsFileUploadConfigurations">
        </file-upload-library>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
    <!-- pending UI verification -->
   <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId"
    [viewQuestions]="false" [showSaveBtn]="false" [loadedIn]="stepDetails.stepName"
    [isSection]="false"></lib-custom-questionnaire>
   </div>
   <p-divider></p-divider>
  <div class="row pt-3">
    <div class="col-md-2">
      <button pButton type="submit" *ngIf="fields.showPetitionOrganizationDetails" label="Save"
        (click)="postpetitionerDetails()"
        [ngClass]="petitionerDetailsForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
        [disabled]="petitionerDetailsForm.invalid  || disabledControls"></button>
    </div>
    <div class="col-md-2">
      <button pButton type="button" *ngIf="fields.showPetitionOrganizationDetails" label="Cancel" class="p-button-secondary cancel-btn button-width-icon-left
              d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
      </button>
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-6 completeStep">
      <gc-status-update [stepDetails]="stepDetails"
        [visaType]="inputData.caseType === 'I130' ? inputData.caseType : inputData.visatype" [type]="typeOfButton">
      </gc-status-update>
    </div>
  </div>
</div>
