import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse, User } from 'app-models';
import { BehaviorSubject, Subject, Subscription, Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/operators';
import { ImagilityWebStorage } from 'web-storage';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class EditCompleteTrainingDetailsService {

  public isExpired = false;
  formChangesSubscription: Subscription;
  public statusChangeSubScriptionSubjectStep = new BehaviorSubject<number>(1);
  public formIsDirtySubject = new BehaviorSubject<{ dirty: boolean, formName: string; formNumber: number }>({ dirty: false, formName: '', formNumber: 1 });
  public isExperienceChanged = new Subject<any>();
  public onLifeStorySubTabs = new Subject();
  public deletedfile$ = new BehaviorSubject<any>({});
  public showBeneficiary = new BehaviorSubject<any>(false);
  public countryDataSource = new BehaviorSubject<any>({ listCountries: [] });
  public uploaded$ = new BehaviorSubject<any>({});
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public dependentProfileSubject = new BehaviorSubject(<any>{});
  public selfBeneficiaryDetailChange = new Subject();
  

  constructor(public http: HttpClient,
    public webStorage: ImagilityWebStorage) {
    this.currentUserSubject = new BehaviorSubject<any>(this.webStorage.getWebStorageItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  checkValidity() {
    return this.isExpired;/////
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  changeDependentProfileID(dependentId: number, beneficiaryType: string) {
    this.dependentProfileSubject.next({ dependentId: dependentId, beneficiaryType: beneficiaryType });
  }
  checkBeneEditPermission() {
    const permission = JSON.parse(sessionStorage.getItem('currentUser'));
    return permission ? permission['permissions'].length > 0 && permission['permissions'].find(x => x === 'EDIT_BENEFICIARY') ? true : false : false;
    // return permission['permissions'].length>0 && permission['permissions'].find(x=> x === 'DELETE_BENEFICIARY')? true : false;
  }

  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  getTrainingsDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, isUsedForBenEdit: any) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=${isUsedForBenEdit}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  deleteTrainingsDetails(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, educationId: number) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/${synctype}/${educationId}?superTaskId=${superTaskId}&taskId=${subTaskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } 
      })
    );
  }

  saveTrainingDetails(petitionId: number, type: string, payload: any) {
    return this.http.post(`/petition/saveTrainingOrLicense/${petitionId}/${type}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  getTrainingInformationPerm(caseId, isUsedForBenEdit: boolean) {
    return this.http.get(`/gc/perm/${caseId}/educationDetails/education/training?isUsedForBenEdit=${isUsedForBenEdit}`);
  }
}
