<div #reminderpageDiv *ngIf="reminderpageLoaded" class="mt-4">
    <div class="row mt-2">
        <div class="col-2 nopadding mt-3">
            <h5 style="font-weight:bolder;">Reminder Details</h5>
        </div>
    </div>
    <form [formGroup]="reminderForm">
        <div class="row mt-2 ml-2">
            <label class='required' for="">Description/Agenda</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                formControlName="description"></textarea>
            <small *ngIf="reminderForm.get('description').touched && reminderForm.get('description').errors"
                class="p-error">
                <span *ngIf="reminderForm.get('description').errors['required']">Required</span>
            </small>
        </div>
        <div class="row divider mt-3">
        </div>
        <div class="row mt-2">
            <div class="col-3">
                <div>
                    <label for="first-name" class="label-cls required">Date</label>
                </div>
                <p-calendar formControlName="startDate" name="date" styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
                    placeholder="MM/DD/YYYY" [showIcon]="true" [minDate]="minimumDate" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2000:2099">
                </p-calendar>
                <small *ngIf="reminderForm.get('startDate').touched && reminderForm.get('startDate').errors"
                    class="p-error">
                    <span *ngIf="reminderForm.get('startDate').errors['required']">Required</span>
                </small>
            </div>
            <div class="col-3">
                <label for="first-name" class="label-cls required">Time</label>

                <input type="time" formControlName="startTime">
                <small *ngIf="reminderForm.get('startTime').touched && reminderForm.get('startTime').errors"
                    class="p-error">
                    <span *ngIf="reminderForm.get('startTime').errors['required']">Required</span>
                </small>

            </div>
            <div class="col-6">
            </div>
        </div>
        <div class="row divider mt-3">
        </div>
        <div *ngIf="!this.reminderEditDetails" class="row mt-4 mb-4">
            <button type="button" [disabled]="!reminderForm.valid" class="btn btn-primary"
                (click)="save()">Save</button>
            <button type="button" class="btn btn-light ml-2" (click)="cancelreminder()">Cancel</button>
        </div>
        <div *ngIf="this.reminderEditDetails" class="row mt-5 mb-5">
            <button type="button" [disabled]="!reminderForm.valid" class="btn btn-primary"
                (click)="update()">Update</button>
            <button class="btn btn-primary ml-3" (click)="cancel()"> Cancel Reminder</button>
        </div>
    </form>
</div>