<mat-card class="time-line-card mat-card">
  <mat-card-header class="border-bottom mat-card-header">
    <mat-card-title class="title">
      <span>Shape your Journey</span>
      <ng-template #beneficiarySecondStepContent>
        <app-guided-tour-steps [content]="beneficiaryGuidedTourContent[1]"></app-guided-tour-steps>
      </ng-template>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-----open button group---->
    <div class="mt-2 row tab-container" joyrideStep="beneficiarySecondStep"
      [stepContent]="beneficiarySecondStepContent">
      <div class="col mr-2 ml-1 tab-label" [ngClass]="{ 'activeTimeLine': selectedTimeLineType === 'PET'}"
        (click)="loadTimeLineType('PET')">
        <div class="lbl-text bene-timeline-tab-lbl"><img src="/assets/images/petition-timeline-icon.png" alt=""
            class="mr-2 w-10"> Petition
          Timeline</div>
      </div>
      <div class="col mr-2 ml-1 tab-label" [ngClass]="{ 'activeTimeLine': selectedTimeLineType === 'IMM'}"
        (click)="loadTimeLineType('IMM')">
        <!-- (click)="loadTimeLineType('IMM')"-->
        <div class="lbl-text bene-timeline-tab-lbl"><img src="/assets/images/immigration-timeline-icon.png" alt=""
            class="mr-2 mb-2 w-10">
          Immigration Timeline</div>
      </div>
      <div class="col mr-2 ml-1 tab-label" [ngClass]="{ 'activeTimeLine': selectedTimeLineType === 'PROJ'}"
        (click)="loadTimeLineType('PROJ')">
        <!--  (click)="loadTimeLineType('PROJ')"-->
        <div class="lbl-text bene-timeline-tab-lbl"><img src="/assets/images/project-timeline-icon.png" alt=""
            class="mr-2 w-10"> Project
          Timeline</div>
      </div>
    </div>
    <!-----end button group---->


    <div class="mt-2 row all-content-container">
      <!--- open all-content-container-->
      <div class="row petition-time-line" [ngClass]="{'hidden-time-line' : selectedTimeLineType !== 'PET' }">
        <!--- petition-time-line  -->
        <ng-container
          *ngIf="(PetitionDrowDownList && PetitionDrowDownList.length > 0 && stepsData.length>0) || (PetitionDrowDownList && PetitionDrowDownList.length > 0 && isBeneficiaryVisible) ; else noPetitionData">
          <!-- <div class="btn-group col-12">
            <ng-container *ngFor="let taskbtnitem of taskCapsuleDataSource;first as isFirst; last as isLast"> -->
          <!-- <button class="childBtn" [ngClass]="{ firstBtn: isFirst, lastBtn: isLast, oneRecord: isFirst && isLast,
                activebtn: selectedTaskId === taskbtnitem.taskid }" (click)="loadtaskTimeLine(taskbtnitem.taskid)">
                {{ taskbtnitem.name }} for {{ taskbtnitem.companyName }}
              </button> -->
          <!-- </ng-container>
          </div> -->
          <!--- timeline componenet---->
          <div *ngIf="isBeneficiaryVisible" class="row col-12 mt-2">
            <div class="col-md-6 mt-2 pl-0">
              Select Current Open Petition :
            </div>
            <div class="col-md-6 mt-2 pr-0 text-right">
              <!-- Use filters to choose current or completed petitions -->
            </div>
          </div>
          <div class="row col-12 mt-2">
            <div class="col-md-8 mt-2 pl-0">
              <!-- <p-dropdown class="filter-dropdown" [style]="{width: '250px'}" [options]="PetitionDrowDownList"
                  [(ngModel)]="selectedSubTaskType" optionLabel="getOptionValue(item)" (onChange)="toggleTimeline(selectedSubTaskType);">
                  <ng-template pTemplate="item" let-item>
                    {{ getOptionValue(item) }}
                    </ng-template>
                  <ng-template pTemplate="selectedItem" let-item>
                    {{ getOptionValue(item) }}
                    </ng-template>
                </p-dropdown>   -->
              <select class="visa_dropdown phoneCodeSelect" name="selectedSubTask" [(ngModel)]="selectedSubTaskType"
                #selectedSubTask="ngModel" (ngModelChange)="toggleTimeline($event);">
                <option *ngFor="let item of PetitionDrowDownList" [ngValue]='item'>
                  <span [ngSwitch]="item.taskTypeCode">
                    <span *ngSwitchCase="'I130'">
                      {{item.classificationName + ' : ' + item.taskTypeCode}}
                    </span>
                    <span *ngSwitchDefault>
                      {{(item.visaTypeName || item.classificationName) + ' : ' + item.taskTypeCode + ' : ' +
                      item.petitionTypeName + ' - '+
                      item.companyName}}
                    </span>
                  </span>
                </option>
              </select>
              <!-- <mat-select class="filter-dropdown" [style]="{width: '250px'}" (selectionChange)="toggleTimeline(selectedSubTaskType)" [(ngModel)]="selectedSubTaskType">
                  <mat-option *ngFor="let item of PetitionDrowDownList" value="item">
                      {{item.visaTypeName + ' ' + item.petitionTypeName + ' - ' + item.companyName}}
                  </mat-option>
                </mat-select> -->
            </div>
            <!-- <div class="col-md-2 mt-2 pr-1">
                  <div class="filter filtertab">
                    <p-overlayPanel #filterPanel [style]="{width: '600px', padding: '0px 10px'}" [dismissable]="false" class="filterOverlayPanel">
                        <ng-template pTemplate>
                               <div class="row"></div>
                              <div class="col-md-6 p-0 pb-2">
                                <label class="view-label">Task Type</label>
                                  <p-dropdown [style]="{width: '250px'}" [options]="taskType" optionValue="code"
                                      [(ngModel)]="filter.superTaskType"
                                      optionLabel="name" (ngModelChange)="getSubTaskType(filter.superTaskType)">
                                  </p-dropdown>
                              </div>
                            <div *ngIf="filter.superTaskType === 'NONIMMPET'" class="row col-md-12 pt-2 pb-2 pl-0 pr-0">
                                <div class="col-md-6 p-0 pb-2">
                                    <label class="filter-label">Visa Type</label> <br>
                                    <p-dropdown  [style]="{width: '250px'}" optionValue="code" [options]="visaTypes" (onChange)="getPetitionList()"
                                        [(ngModel)]="filter.visaType" defaultLabel="Select Visa" optionLabel="name">
                                    </p-dropdown >
                                </div>
                                <div class="col-md-6 p-0">
                                    <label class="filter-label">Petition Type</label> <br>
                                    <p-dropdown  [style]="{width: '250px'}" optionValue="code" [options]="petitionTypes" (ngModelChange)="getSubtaskForNonImmigrant()"
                                        [(ngModel)]="filter.petitionType" defaultLabel="Select Petition" optionLabel="name">
                                    </p-dropdown >
                                </div>
                            </div>
                            <div *ngIf="filter.superTaskType === 'IMMPET'" class="row col-md-12 pt-2 pb-2 pl-0 pr-0">
                                <div class="col-md-6 p-0 pb-2">
                                    <label class="filter-label">Immigration Category</label> <br>
                                    <p-dropdown  [style]="{width: '250px'}" optionValue="code" [options]="immCategoryTypes"
                                        [(ngModel)]="filter.immCategoryType" defaultLabel="Select Immigration Category"
                                        optionLabel="name">
                                    </p-dropdown >
                                </div>
                                <div class="col-md-6 p-0">
                                    <label class="filter-label">Immigration Clssification</label> <br>
                                    <p-dropdown  [style]="{width: '250px'}" optionValue="code"
                                        [options]="immClassificationTypes" [(ngModel)]="filter.immClassificationType"
                                        defaultLabel="Select Immigration Classification" optionLabel="name">
                                    </p-dropdown >
                                </div>
                            </div>
                            <div class="row col-md-12 pl-0 pr-0 pb-2">
                                <div class="col-md-6 p-0 pb-2">
                                    <label class="filter-label">Sub-Task Type</label> <br>
                                    <p-dropdown  [style]="{width: '250px'}" optionValue="code" [options]="subTaskType"
                                        [(ngModel)]="filter.subTaskType" defaultLabel="Select SubTask" optionLabel="name">
                                    </p-dropdown >
                                </div>
                                <div class="col-md-6 p-0 pb-2">
                                  <label class="filter-label">Company Name</label>
                                  <p-dropdown class="d-block w-100" [options]="companyList" [(ngModel)]="filter.companyId" optionLabel="companyName" name="companyId" #companyId="ngModel" optionValue="id">
                                  </p-dropdown>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="row col-md-12 pt-3">
                                <div class="col-md-7">
                                    <button pButton type="button" label="Cancel" (click)="filterCancel();filterPanel.hide();"
                                        class="p-button-outlined"></button>
                                </div>
                                <div class="col-md-5 p-0">
                                    <button pButton type="button" label="Apply" class="p-button-primary p-button apply-btn"
                                        (click)="handleFilterChange();filterPanel.hide()"></button>
                                </div>
                            </div>
                        </ng-template>
                    </p-overlayPanel>

                    <button type="text" class="taskFilter-btn" pButton label="Filter" (click)="filterPanel.toggle($event)"
                        icon="pi pi-filter" iconPos="right"> </button>
                </div>
              </div> -->
          </div>
          <div class="col-12 app-time-line-wrapper mt-2" *ngIf="isBeneficiaryVisible">
            <!-- <label class="mr-2">View By</label> -->


            <div *ngIf="selectedSubTaskType.taskTypeCode != 'RFE' && stepsData?.length > 0" class="petitionTimeline">
              <ng-container *ngIf="completedPetitions; else noCompProjectData">
                <app-timeline [loadingFromOnChanges]="true" [selectedTaskType]="selectedSubTaskType.taskTypeCode"
                  [taskObj]="stepsData" [receiptETADateRange]="receiptETADateRange"
                  [decisionETADateRange]="decisionETADateRange" [taskId]="taskId" [taskTypeDetails]="taskTypeDetails"
                  [visaTypes]="visaTypes" [immigrationTypes]="immiStatus" [petitionTypes]="petitionTypes"
                  [dashboardTimeline]="true" [petitionId]="petitionId" [userName]="userName"
                  [noticeNumber]="noticeNumber" [superTaskId]="superTaskId"></app-timeline>
              </ng-container>
              <!---IM-3396 -->
              <ng-template #noCompProjectData>
                <div class="no_data">No current or completed petitions at this time</div>
              </ng-template>
            </div>
            <div *ngIf="selectedSubTaskType.taskTypeCode == 'RFE' && stepsData?.length > 0" class="rfeTimeline">
              <app-timeline [loadingFromOnChanges]="true" [selectedTaskType]="selectedSubTaskType.taskTypeCode"
                [taskObj]="stepsData" [taskId]="taskId" [taskTypeDetails]="taskTypeDetails"
                [isOutsideImagility]="isOutsideImagility" [petitionId]="petitionId" [userName]="userName"
                [noticeNumber]="noticeNumber" [superTaskId]="superTaskId" [dashboardTimeline]="true"></app-timeline>
            </div>
            <!-- <app-timeline
            [taskObj]="stepsData"
            [taskId]="taskId"
            [taskTypeDetails]="taskTypeDetails"
            [visaTypes]="visaTypes"
            [immigrationTypes]="immiStatus"
            [petitionTypes]="petitionTypes"
            [petitionId]="petitionId">
            </app-timeline> -->
          </div>
          <div class="no_data" *ngIf=" (isBeneficiaryVisible && stepsData.length == 0) ||  (!isBeneficiaryVisible)"> 'You do not have view access permission from your company'
          </div>
        </ng-container>
        <ng-template #noPetitionData>
          <!---IM-3396 -->
          <div class="no_data"> No active petitions at this time</div>
        </ng-template>
      </div>
      <!--- closing petition-time-line  -->

      <div class="col-12 immigration-time-line" [ngClass]="{ 'hidden-time-line': selectedTimeLineType !== 'IMM' }">
        <!--- immigration-time-line -->
        <div class="row app-time-line-wrapper">
          <ng-container *ngIf="immigrationData.length>0; else noImmigrationData">
            <app-immigration-timeline class="w-100" [showEditBtn]="true" [immigrationData]="immigrationData">
            </app-immigration-timeline>
          </ng-container>
          <!---IM-3396 -->
          <ng-template #noImmigrationData>
            <div class="no_data">No Immigration records found</div>
          </ng-template>
        </div>
      </div>

      <div class="col-12 project-time-line hidden-time-line"
        [ngClass]="{'hidden-time-line' : selectedTimeLineType !== 'PROJ' }">
        <!--- project-time-line-->
        <div class="row app-time-line-wrapper">
          <ng-container *ngIf="experienceData?.length; else noProjectData">
            <app-project-timeline class="w-100" [showEditBtn]="true" [experienceData]="experienceData">
            </app-project-timeline>
          </ng-container>
          <!---IM-3396 -->
          <ng-template #noProjectData>
            <div class="no_data">No Project records found</div>
          </ng-template>
        </div>
      </div>

    </div>
    <!--- close all-content-container-->

  </mat-card-content>
</mat-card>
