<p-card styleClass="tasklist-card">
    <div class="card-header d-flex justify-content-between align-items-center pl-0 pr-0">
        Visa Bulletin
    </div>
    <div class="pt-2 p-d-flex">
        <div class="col-md-8 pl-0 d-flex">
            <div class="py-3 1 col-4 pl-0 align-middle task-tab"
                [ngClass]="{'activeTasksTab': activeTasksTab === 'familyBased' }"
                (click)="onActiveTaskTab('familyBased')"><span class="pl-3">Family-Sponsored </span></div>
            <div class="py-3 col-4 pl-0 align-middle task-tab"
                [ngClass]="{'activeTasksTab': activeTasksTab === 'employeeBased' }"
                (click)="onActiveTaskTab('employeeBased')"><span class="pl-3">Employment-Based</span></div>
        </div>
    </div>
    <div class="content-holder pt-2 pb-0">

        <div class="row" *ngIf="activeTasksTab === 'familyBased'">
            <div class="colDate">
                <label class="view-label">View By </label>
                <p-dropdown class="filter-dropdown" [style]="{width: '180px'}" [options]="dateTypesFam" optionValue="code"
                    [(ngModel)]="dateFilter" (onChange)="getDateTypeFamily(dateFilter);" optionLabel="name">
                </p-dropdown>
            </div>
            <div class="col px-1 task-table" *ngIf="activedatesFam === 'FCURRENT'">
                <p-table [columns]="familyBasedCurrentDateCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [scrollable]="true"
                    scrollHeight="290px"   styleClass="p-datatable-gridlines" [value]="familyCurrentList" dataKey="id"
                    *ngIf="familyCurrentList?.length > 0">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span>
                                    {{task[col.field]}}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="familyCurrentList?.length == 0" class="mt-4 text-center">No Data</div>
                <div class="show-more-box bg-white" *ngIf="familyCurrentList?.length > 0"><a class="show-more"
                        (click)="showMore()">View All</a>
                </div>

            </div>
            <div class="col px-1 task-table" *ngIf="activedatesFam === 'FFILLING'">
                <p-table [columns]="familyBasedFillingDateCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [scrollable]="true"
                    scrollHeight="290px"   styleClass="p-datatable-gridlines" [value]="familyFillingList" dataKey="id"
                    *ngIf="familyFillingList?.length > 0">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span>
                                    {{task[col.field]}}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="familyFillingList?.length == 0" class="mt-4 text-center">No Data</div>
                <div class="show-more-box bg-white" *ngIf="familyFillingList?.length > 0"><a class="show-more"
                        (click)="showMore()">View All</a>
                </div>

            </div>
        </div>
        <div class="row" *ngIf="activeTasksTab === 'employeeBased'">
            <div class="colDate">
                <label class="view-label">View By </label>
                <p-dropdown class="filter-dropdown" [style]="{width: '180px'}" [options]="dateTypesEmp" optionValue="code"
                    [(ngModel)]="dateFilter" (onChange)="getDateTypeEmployee(dateFilter);" optionLabel="name">
                </p-dropdown>
            </div>
            <div class="col px-1 task-table" *ngIf="activedatesEmp === 'ECURRENT'">
                <p-table [columns]="empBasedCurrentDateCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [scrollable]="true"
                    scrollHeight="290px"   styleClass="p-datatable-gridlines" [value]="empCurrentList" dataKey="id"
                    *ngIf="empCurrentList?.length > 0">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span>
                                    {{task[col.field]}}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="empCurrentList?.length == 0" class="mt-4 text-center">No Data</div>
                <div class="show-more-box bg-white" *ngIf="empCurrentList?.length > 0"><a class="show-more"
                        (click)="showMore()">View All</a>
                </div>

            </div>
            <div class="col px-1 task-table" *ngIf="activedatesEmp === 'EFILLING'">
                <p-table [columns]="empBasedFillingDateCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [scrollable]="true"
                    scrollHeight="290px"   styleClass="p-datatable-gridlines" [value]="empFillingList" dataKey="id"
                    *ngIf="empFillingList?.length > 0">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span>
                                    {{task[col.field]}}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="empFillingList?.length == 0" class="mt-4 text-center">No Data</div>
                <div class="show-more-box bg-white" *ngIf="empFillingList?.length > 0"><a class="show-more"
                        (click)="showMore()">View All</a>
                </div>

            </div>
        </div>
    </div>
</p-card>