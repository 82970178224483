export const DeleteDialogConfigurations = {
    width: '30%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001"}
};

export const NoteDialogConfigurations = {
    width: '40%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001"},
    data: {}
};

export const ViewInvoiceConfigurations = {
    width: '80%',
    height:'500px',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001"},
    data: {}
}
