import { Component, OnInit } from '@angular/core';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';


@Component({
  selector: 'app-beneficiary-landing',
  templateUrl: './beneficiary-landing.component.html',
  styleUrls: ['./beneficiary-landing.component.css'],
})
export class BeneficiaryLandingComponent implements OnInit {
  constructor( 
    private cacheCountryService: CacheCountryService

  ) {}

  ngOnInit(): void {
    this.cacheCountryService.getCountry().subscribe();

  }
}
