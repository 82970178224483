<p-dialog [(visible)]="displayResponse" [modal]="true" [responsive]="true"
    [style]="{width: '1000px', minWidth: '200px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
    <ng-template pTemplate="header">
        <div>
            <div class="row">
                <div class="col-12 px-0">
                    <h5 class="text-primary mb-0">{{rfeDisplayResponse["responseTitle"]}}</h5>
                    <label>{{rfeDisplayResponse["responseTypeName"]}}</label>
                </div>
            </div>
        </div>
    </ng-template>
    <hr class="mt-0" />
    <div
        *ngIf='rfeDisplayResponse["responseTitle"] != "Petition Details" && rfeDisplayResponse["responseTitle"] != "USCIS Office Address"'>
        <label class="text-primary">Response Content</label>
        <div class="row">
            <div [innerHTML]='rfeDisplayResponse["responseText"]'></div>
        </div>
        <!-- <label>{{rfeDisplayResponse["responseText"]}}</label> -->
    </div>
    <div *ngIf='rfeDisplayResponse["responseTitle"] === "USCIS Office Address" '>
        <label class="text-primary">Response Content</label>
        <div class="border-bottom pb-1 pt-2">
            <div class="row">
                <label class="col-8" style="font-size:14px;">Address</label>
                <label class="col" style="font-size:14px;">Shipping Type</label>

            </div>
        </div>
        <div *ngFor="let item of rfeDisplayResponse['responseText'];let i=index">
            <div class="border-bottom pb-1 pt-2">
                <div class="row">
                    <label class="col-8" style="font-size:14px;">{{item.address}}</label>
                    <label class="col-2" style="font-size:14px;">{{item.shippingTypeName}}</label>
                    <div class="col-2">

                        <button pButton class="p-button-outlined p-button-sm" type="button"
                            (click)="copyContent(rfeDisplayResponse,item);" label="COPY"></button>
                    </div>
                </div>
            </div>
            <!-- <label>{{item["address"]}}</label> -->

        </div>
    </div>
    <div *ngIf='rfeDisplayResponse["responseTitle"] === "Petition Details" '>
        <div>
            <label class="text-primary">Response Content</label>
            <div class="row">
                <div [innerHTML]='rfeDisplayResponse["responseText"]'></div>
            </div>
        </div>

    </div>
    <p-footer>
        <span *ngIf='rfeDisplayResponse["responseTitle"] != "USCIS Office Address" '>
            <button type="button" pButton label="Copy" (click)="copyContent(rfeDisplayResponse)"></button></span>
        <button type="button" pButton label="Cancel" (click)="closeDisplayResponse()"></button>
    </p-footer>
</p-dialog>
<div class="row">
    <div class="col-4">
        <h6>Defined Response List</h6>
        <small>Copy Paste the required Standard response</small>
        <p-tabView>
            <p-tabPanel header="Defined Responses">
                <div *ngFor="let item of standardRfeTemplateList;let i=index">
                    <div class="border-bottom py-2"><label class="d-block"
                            style="font-size:14px;">{{item.responseTitle}}</label>
                        <div>
                            <small clas="d-block text-secondary">Standard Response</small>
                            <div class="float-right">
                                <button class="p-button-outlined " type="button" pButton (click)="view(item);"
                                    label="VIEW"></button>
                                <button class="p-button-outlined ml-1" type="button" pButton (click)="copyContent(item)"
                                    label="COPY"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Legal Responses">
                <div *ngFor="let item of legalRfeTemplateList;let i=index">
                    <div class="border-bottom py-2"><label class="d-block"
                            style="font-size:14px;">{{item.responseTitle}}</label>
                        <div>
                            <small clas="d-block text-secondary">Legal Response</small>
                            <div class="float-right">
                                <button class="p-button-outlined " type="button" pButton (click)="view(item);"
                                    label="VIEW"></button>
                                <button class="p-button-outlined ml-1" type="button" pButton (click)="copyContent(item)"
                                    label="COPY"></button>
                            </div>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>

        <p-tabView>
            <p-tabPanel header="Petition Details" *ngIf='!(rfeTimelineModalInput["isPetitionOutsideImagility"])'>
                <div *ngFor="let item of petitionData;let i=index">
                    <div class="border-bottom py-2">
                        <label class="font-weight-bold d-block" style="font-size:14px;">{{item.name}}</label>
                        <div class="pt-1" *ngFor="let section of item.sections;let i=index">
                            <div class="row">
                                <div class="col-7 mx-0 px-0 float-left">
                                    <small clas="d-block text-secondary">{{section.description}}</small>
                                </div>
                                <div class="col mx-0 px-0 float-right">
                                    <button class="p-button-outlined " type="button" pButton
                                        (click)="viewPetiton(section);" label="VIEW"></button>
                                    <button class="p-button-outlined ml-1" type="button" pButton
                                        (click)="copyContentPetition(section);" label="COPY"></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="border-bottom pb-1 pt-2">
                              <div class="row">
                                  <div class="col-6 pl-0">
                                  <label style="font-size:12px;">{{item.name}}</label>
                                  </div>
                                  <div class="col d-flex">
                                      <button class="p-button-outlined" type="button" pButton (click)="viewPetiton(item);" label="VIEW"></button>
                                      <button class="p-button-outlined ml-1" type="button" pButton (click)="copyContentPetition(item);" label="COPY"></button>
                                      
                                  </div>
                              </div>
                          </div> -->
                </div>
            </p-tabPanel>
            <p-tabPanel header="Place Holder">
                <div *ngFor="let item of rfePlaceholderList;let i=index">
                    <div class="border-bottom pb-1 pt-2">
                        <div>
                            <label class="d-inline-block"
                                style="font-size:14px;">{{item.placeHolderDescription}}</label>
                            <div class="float-right">
                                <button class="p-button-outlined " type="button" pButton
                                    (click)="viewPlaceholder(item);" label="VIEW"></button>
                                <button class="p-button-outlined ml-1" type="button" pButton
                                    (click)="copyPlaceholderContent(item)" label="COPY"></button>
                            </div>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
    <div class="col-8">
        <div class="template-editor-maxwidth headerEditor">

            <div class="d-flex">
                <div class="p-1 flex-grow-1">
                    <h6>Header</h6>
                </div>
                <div class="p-1"><button class="p-button-outlined p-button-sm" type="button" pButton
                        (click)="copyDefaultContent('header');" label="Reload Header"></button></div>

            </div>


            <!-- <textarea spellcheck="true" [(ngModel)]="rfeResponseHeader" name="rfeResponseHeader"
                  [froalaEditor]="options"></textarea> -->
            <kendo-editor [(ngModel)]="rfeResponseHeader" name="rfeResponseHeader"></kendo-editor>
            <!-- <kendo-editor [(value)]="rfeResponseHeader" (valueChange)="rferesponseheadereditorValueChange($event)" #rfeResponseHeaderkendoeditor style="max-height: 600px; overflow: auto; height: 400px;" name="rfeResponseHeader">
                <kendo-toolbar>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                  <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                  <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                    <kendo-toolbar-button
                    title="Upload’s Image at cursor point"
                    text="Upload Image"
                    (click)="openrferesponseheaderimageuploaddialog()"
                    ></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>                                  
                </kendo-toolbar>
            </kendo-editor>
            <app-upload-image-dialog #rferesponseheaderupload [editor]="rfeResponseHeaderkendoeditor"></app-upload-image-dialog> -->
        </div>
        <div class="mt-4 template-editor-maxwidth contentEditor">
            <div class="d-flex">
                <div class="p-1 flex-grow-1">
                    <h6>Standard Content</h6>
                </div>
                <div class="p-1">
                    <button class="p-button-outlined p-button-sm" type="button" pButton
                        (click)="copyDefaultContent('body');" label="Reload Content"></button>
                </div>
            </div>

            <!-- <textarea spellcheck="true" [(ngModel)]="rfeResponseContent" name="rfeResponseContent"
                [froalaEditor]="options"></textarea> -->
            <kendo-editor [(ngModel)]="rfeResponseContent" name="rfeResponseContent"></kendo-editor>
            <!-- <kendo-editor [(value)]="rfeResponseContent" (valueChange)="rferesponsecontentValueChange($event)" #rfeResponseContentkendoeditor style="max-height: 600px; overflow: auto; height: 400px;" name="rfeResponseContent">
                <kendo-toolbar>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                  <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                  <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                    <kendo-toolbar-button
                    title="Upload’s Image at cursor point"
                    text="Upload Image"
                    (click)="openrferesponsecontentimageuploaddialog()"
                    ></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>                                  
                </kendo-toolbar>
            </kendo-editor>
            <app-upload-image-dialog #rferesponsecontentupload [editor]="rfeResponseContentkendoeditor"></app-upload-image-dialog> -->
        </div>
        <div class="mt-4 template-editor-maxwidth footerEditor">

            <div class="d-flex">
                <div class="p-1 flex-grow-1">
                    <h6>Footer</h6>
                </div>
                <div class="p-1">
                    <button class="p-button-outlined p-button-sm" type="button" pButton
                        (click)="copyDefaultContent('footer');" label="Reload Footer"></button>
                </div>

            </div>

            <!-- <textarea spellcheck="true" [(ngModel)]="rfeResponseFooter" name="rfeResponseFooter"
                [froalaEditor]="options"></textarea> -->
            <kendo-editor [(ngModel)]="rfeResponseFooter" name="rfeResponseFooter"></kendo-editor>
            <!-- <kendo-editor [(value)]="rfeResponseFooter" (valueChange)="rferesponsefooterValueChange($event)" #rfeResponseFooterkendoeditor style="max-height: 600px; overflow: auto; height: 400px;" name="rfeResponseFooter">
                <kendo-toolbar>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                  <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>                                
                  <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                    <kendo-toolbar-button
                    title="Upload’s Image at cursor point"
                    text="Upload Image"
                    (click)="openrferesponsefooterimageuploaddialog()"
                    ></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>                                  
                </kendo-toolbar>
            </kendo-editor>
            <app-upload-image-dialog #rferesponsefooterupload [editor]="rfeResponseFooterkendoeditor"></app-upload-image-dialog> -->
        </div>
    </div>
    <hr />
    <div class="mt-2 text-right">
        <button type="button" pButton (click)="saveRfeResponseTemplate();" label="Save"></button>
        <div class="d-inline pl-3 ml-3 border-left pt-3">
            <button [disabled]="disableComplete" type="button" pButton (click)="changeStatus();" label="Complete"
                class="ui-button-secondary"></button>
        </div>
    </div>

</div>