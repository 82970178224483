<div class="trainingContainer">
    <ng-container>
        <div class="row pt-3 pb-2">
            <button class="btn btn-primary mb-2" type="button" *ngIf="updateBenProfile && !viewMode && showEditButton"
                (click)="toggleExpansion();stepper.reset();formCheck(trainingForm)">Add Training</button>
        </div>
        <mat-accordion>
            <mat-expansion-panel hideToggle [expanded]="expandPanel">
                <mat-card>
                    <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
                        class="pb-mat-stepper-custom-horizontal">
                        <mat-step [completed]="hasID">
                            <ng-template matStepLabel>Training Details</ng-template>
                            <form (ngSubmit)="saveTrianing(trainingForm)" #trainingForm="ngForm">
                                <!-- Removed to work save training(click)="formCheck(trainingForm)"  -->
                                <div class="formBox">
                                    <div class="form-group col-md-12 mt-2">
                                        <label class="label-cls">Name</label>
                                        <input class="im-input" type="text" name="name" [(ngModel)]="training.name"
                                            #name="ngModel" placeholder="Enter" />
                                    </div>

                                    <div class="form-group col-md-12">
                                        <label class="label-cls">Institution</label>
                                        <input class="im-input" type="text" name="institution"
                                            [(ngModel)]="training.institution" #institution="ngModel"
                                            placeholder="Enter" />
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="label-cls">Start Date</label>
                                            <mat-form-field appearance="outline" class="mt-0 date-field">
                                                <input [(ngModel)]="training.startDate" #startDt="ngModel"
                                                    name="startDt" [max]="today" matInput [matDatepicker]="startDate"
                                                    (click)="startDate.open()" />
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="startDate"></mat-datepicker-toggle>
                                                <mat-datepicker #startDate></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label class="label-cls">End Date</label>
                                            <mat-form-field appearance="outline" class="mt-0 date-field">
                                                <input [(ngModel)]="training.endDate" name="endDt" matInput
                                                    [min]="training.startDate" [matDatepicker]="endDate"
                                                    #endDt="ngModel" (click)="endDate.open()" />
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="endDate"></mat-datepicker-toggle>
                                                <mat-datepicker #endDate></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div>
                                        <div class="row paddingTop10 dasheedTopBorder">
                                            <div class="form-group col-md-6" *ngIf="!viewMode">
                                                <lib-save-as-draft confirmModelKey="savedrafttraining"
                                                    [disabled]="isSaveAsDraftDisabled"
                                                    *ngIf="beneficiaryType === 'self'" class="mr-3"
                                                    (onClickSaveAsDraft)="saveAsDraft($event)"
                                                    [formToBeSaved]="trainingForm['form']"></lib-save-as-draft>
                                                <button class="btn btn-success" type="submit">Save</button>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="d-flex flex-row-reverse">
                                            <div class="p-2"><button type="button" class="btn btn-primary"
                                                    matStepperNext [disabled]="!hasID">Next</button></div>
                                            <div class="p-2"><button type="button" class="back-button mr-3"
                                                    (click)="closeForm(trainingForm)">Close</button></div>
                                            <div class="p-2 f10 mTop10">(Click on save button to save data)</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Topics Covered</ng-template>
                            <div class="row formBox">
                                <div class="form-group col-md-12 mt-2">
                                    <div class="row">
                                        <label class="row">Add topics or subjects covered in the Training</label>
                                        <small>Enter all relevant Topics or Subjects Covered as a part of your training
                                            using a
                                            comma to
                                            separate.</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row paddingTop10">
                                        <label class=" col-md-12">Topics Or Subjects Covered</label>
                                        <div class="col-md-12">
                                            <mat-form-field appearance="outline"
                                                class="example-chip-list chips-contianer">
                                                <mat-chip-list #chipList aria-label="Fruit selection">
                                                    <mat-chip *ngFor="let subject of listAddedSubjects"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="remove(subject)">
                                                        {{subject}}
                                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                    </mat-chip>
                                                    <input placeholder="New subject..." [matChipInputFor]="chipList"
                                                        (paste)="pasteChips($event)"
                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        [(ngModel)]="topic" name="topic"
                                                        (input)="onNewTopicEnter($event)"
                                                        (matChipInputTokenEnd)="add($event)">
                                                </mat-chip-list>
                                            </mat-form-field>
                                            <mat-hint class="hint-text">Please enter comma separated values</mat-hint>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-success" (click)="addSubjects()"
                                                [disabled]="listAddedSubjects.length === 0">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="spacing im-card-no-border-table">
                                    <table class="im-card-no-border-table" mat-table [dataSource]="listSubjects"
                                        matSort>
                                        <ng-container *ngFor="let column of subjectsColumns"
                                            [matColumnDef]="column.columnDef">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.header}}
                                            </th>
                                            <td mat-cell *matCellDef="let row; let i = index">
                                                <span class="column.customClass" *ngIf="column.columnType=='icon'">
                                                    <mat-icon class="mr-1 icn" (click)="deleteSubject(i)">delete_forever
                                                    </mat-icon>
                                                </span>
                                                <span *ngIf="column.columnType=='text'">
                                                    {{row[column.columnDef]}}
                                                </span>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="subDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: subDisplayedColumns;"></tr>
                                    </table>
                                </div>
                                <div style="width:100%;">
                                    <hr />
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="button" class="back-button" matStepperPrevious>Back</button>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <button class="back-button mr-3"
                                                (click)="closeForm(trainingForm);stepper.reset();">Close</button>
                                            <button type="button" class="btn btn-primary pull-right"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Upload Documents</ng-template>
                            <div class="row formBox">
                                <div class="col-md-12 mt-2">
                                    <immigration-file-upload class="w-100" [configurations]="configurations"
                                        [primaryData]="inputData" (fileUploadSuccess)="fileUploadSuccess()">
                                    </immigration-file-upload>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="button" class="back-button" matStepperPrevious>Back</button>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <button class="back-button mr-3"
                                                (click)="closeForm(trainingForm);stepper.reset();">Close</button>
                                            <button type="button" class="btn btn-primary pull-right"
                                                (click)="resetForm(trainingForm);stepper.reset();">Add
                                                Next Training</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                    </mat-horizontal-stepper>
                </mat-card>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <!-----------------------------------*****  view added training  items ***** ------------------------------------->

    <div class="spacing im-card-no-border-table">
        <div class="p-3">
            <div>{{dataSource && dataSource.data.length > 0 ? dataSource.data.length : 'No' }} Trainings Added
                by you</div>
        </div>
        <table class="im-card-no-border-table" multiTemplateDataRows mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let row">
                    <span class="row" *ngIf="column.columnDef == 'toggle'" class="toggle-col">
                        <a>
                            <mat-icon>expand_more</mat-icon>
                        </a>
                    </span>
                    <div *ngIf="updateBenProfile && showEditButton">
                        <span class="row" *ngIf="column.columnDef == 'action'">
                            <ng-container>
                                <mat-icon class="mr-1 icn" *ngIf="!viewMode" title="edit" class="im-table-verticle-icon"
                                    (click)="handleEdit(row);formCheck(trainingForm)">edit
                                </mat-icon>
                                <mat-icon class="mr-1 icn" *ngIf="!viewMode" title="delete"
                                    (click)="handleDelete(row, trainingForm)">delete_forever
                                </mat-icon>
                            </ng-container>
                        </span>
                    </div>
                    <span *ngIf="column.columnType=='text' && !['startDate','endDate'].includes(column.columnDef)">
                        {{row[column.columnDef]}}
                    </span>
                    <span *ngIf="['startDate','endDate'].includes(column.columnDef)">
                        {{row[column.columnDef] ? (row[column.columnDef] | VisaDateFormat) : ''}}
                    </span>
                </td>
            </ng-container>
            <div class="example-element-description">
                <ng-container matColumnDef="expandedDetail">
                    <label>
                        List of Topics
                    </label>
                    <td class="w40" mat-cell *matCellDef="let element;let i = index;" [attr.colspan]="columns.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="w100 example-element-description py-1" *ngIf="element?.subjects?.length > 0">
                                <h6>List of Topics</h6>
                                <ng-container>
                                    <table class="table table-striped">
                                        <tr *ngFor="let sub of element.subjects; let i = index;"
                                            class="border border-dark">
                                            <td class="w40">
                                                <div class="circleIndex rounded-circle">{{i + 1}}
                                                </div>
                                            </td>
                                            <td nowrap>
                                                {{sub}}
                                            </td>
                                        </tr>
                                    </table>
                                </ng-container>
                            </div>
                            <div class="w100 example-element-description py-1" *ngIf="element?.documents?.length > 0">
                                <h6>List of Documents</h6>
                                <ng-container>
                                    <table class="im-card-no-border-table">
                                        <thead>
                                            <tr>
                                                <th class="w40"></th>
                                                <th>Document</th>
                                                <th>Document Type</th>
                                                <th><i class="fa fa-download fa-lg icn" aria-hidden="true"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of element.documents;let i=index">
                                                <td class="w40">
                                                    <div class="circleIndex rounded-circle">{{i + 1}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <strong>{{ item.fileName }}</strong>
                                                </td>
                                                <td nowrap>
                                                    {{ item?.fileCategory?.code === 'OTHER' ?
                                                    item?.fileCategoryOtherName : item?.fileCategory?.name }}
                                                </td>
                                                <td nowrap>
                                                    <button pButton type="button" icon="pi pi-download"
                                                        pTooltip="Download" (click)="downloadDoc(item)"
                                                        class="p-button-text p-button-rounded p-3"></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </ng-container>
            </div>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
</div>