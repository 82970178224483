<div [formGroup]="parentForm">
  <div class="row pt-4 duties-header">
    <div class="col-md-12">
      <h5>List Of Duties</h5>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-6">
      <label class="label-cls">Select the Job Category</label>
      <p-dropdown placeholder="Select Category" [options]="categoryList" [style]="{'width':'100%'}" appendTo="body"
        formControlName="selectedCategory" optionLabel="jobCategory" (onChange)="onChange_Category($event)"
        [disabled]="disabledControls">
      </p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3 processing-dropdown">
      <label class="label-cls">Select the Job Position</label>
      <p-dropdown [options]="jobRoleOptions" [filter]="false" id="jobRolesId" appendTo="body"
        [disabled]="disabledControls" optionLabel="jobTitle" optionValue="jobTitle" formControlName="jobRoleJD"
        (onChange)="setPositionTitle($event)" placeholder="select">
      </p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <label for="pl-0" class="mb-0 mt-3 label-cls"> <b>Note</b> :
        <span> {{parentForm.get('jobRoleJD').value && parentForm.get('jobRoleJD').value === 'Other' ? 'Please specify
          the
          job position and enter it yourself' :
          'After
          selecting Job
          Position,
          Please click on the Populate Job Duties button to get the default List of Duties or enter it
          yourself'}}</span>
      </label>
    </div>
    <div class="col-md-12">
      <ng-template
        [ngIf]="!parentForm.get('jobRoleJD').value || (parentForm.get('jobRoleJD').value && parentForm.get('jobRoleJD').value !== 'Other')">
        <button type="button" pButton (click)="saveJobRoleOrPopulateDuties()" class="pull-left"
          [ngClass]="disabledControls || !parentForm.get('jobRoleJD').value  ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="disabledControls || !parentForm.get('jobRoleJD').value">
          POPULATE DUTIES & SUB-DUTIES
        </button>
        <div class="pull-left duties-header">
          <h6 class="mb-0 mt-0 mr-3 line-height-same-as-btn">OR</h6>
        </div>
      </ng-template>
      <div class="pull-left mr-3"
        *ngIf="parentForm.get('jobRoleJD').value && parentForm.get('jobRoleJD').value === 'Other'">
        <label class="label-cls">Specify</label>
        <input class="im-input mask-in mw-100" id="otherJobPosition" formControlName="otherJobPosition" type="text"
          [readonly]="disabledControls" pInputText placeholder="Enter" />
      </div>
      <div class="pull-left">
        <div [ngClass]="{'addBtn': parentForm.get('jobRoleJD').value && parentForm.get('jobRoleJD').value === 'Other'}">
          <button type="button" pButton [disabled]="disabledControls" class="btn p-button-primary addDutyBtn btn-success"
            [disabled]="disabledControls"
            [ngClass]="{'disabled btn-success_disabled': disabledControls}" label="ENTER IT YOURSELF"
            (click)="addDuty()">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 pt-2 d-flex align-items-center duties-header">
    <div class="col-6">
      <h6 class="heading p-text-bold"> Added Duties & Sub-duties</h6>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button type="button" pButton [disabled]="disabledControls" label="Delete all duties" class="btn btn-primary ml-3"
        (click)="deleteAllDuties()" iconPos="right">
      </button>
    </div>
  </div>
  <div class="row mt-2 mb-2" formArrayName="duties">
    <p-card class="my-1 col-md-12 duty-card" *ngFor="let duty of dutyList; let dutyIndex= index">
      <div class="row mt-2" [formGroupName]="dutyIndex">
        <div class="col-md-1 mt-3 text-center"><span class="duty-list">{{dutyIndex+1}}</span></div>
        <div class="col-md-11 pl-0">
          <div class="row duty-title d-flex align-items-center">
            <div class="col-md-10 pr-0 pl-0">
              Duty
            </div>
            <div class="col-md-2 pr-0 pl-0">
              <button type="button" pButton [disabled]="disabledControls" pTooltip="Delete"
                (click)="dutiesDelete(dutyIndex)" class="p-button-rounded p-button-text pull-right">
                <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
              </button>
              <button type="button" pButton [disabled]="disabledControls" pTooltip="Edit"
                (click)="handleEdit(duty, dutyIndex)" class="p-button-rounded p-button-text pull-right">
                <mat-icon class="mr-1 icn" matTooltip="Edit">edit</mat-icon>
              </button>
            </div>
          </div>
          <div class="row col-md-12  duty-txt" style="font-size: 14px;">
              {{duty.value.name}}
          </div>
          <div *ngIf="getSubDutiesList(dutyIndex).length > 0" class="row col-md-12 duty-title">
              Sub-Duty
          </div>
          <div class="row p-0" formArrayName="subJobDuty"
            *ngFor="let dutyDescription of getSubDutiesList(dutyIndex); let subJobDutyIndex=index">
            <div class="col-md-12  duty-txt" [formGroupName]="subJobDutyIndex">
              <div class="row">
                <div class="col-md-11 p-0">
                  {{dutyDescription.value.name}}
                </div>
                <div class="col-md-1 p-0">
                  <button pButton [disabled]="disabledControls" type="button" pTooltip="Delete"
                    (click)="subDutiesDelete(dutyIndex, subJobDutyIndex)"
                    class="p-button-rounded p-button-text pull-right">
                    <mat-icon class="mr-1 icn" matTooltip="Delete">delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="row pb-5 mt-3"
    *ngIf="parentForm.get('jobRoleJD').value && (parentForm.get('jobRoleJD').value !== 'Other' || (parentForm.get('jobRoleJD').value === 'Other' && parentForm.get('otherJobPosition').value))  && dutyList.length > 0">
    <div class="col-md-12 mb-1">
      <p-radioButton name="groupname" label="Update data only for this petition" [value]="0"
        formControlName="selectedRaiobtn"></p-radioButton>
    </div>
    <div class="col-md-12">
      <p-radioButton name="groupname" label="Add/Update data in the master data" [value]="1"
        formControlName="selectedRaiobtn"></p-radioButton>
    </div>
  </div>
</div>
