import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-license-timeline',
  templateUrl: './license-timeline.component.html',
  styleUrls: ['./license-timeline.component.scss']
})
export class LicenseTimelineComponent implements OnInit {
  @Input() licenseData;
  constructor() { }

  ngOnInit() {
  }

}
