import { Component, OnDestroy, OnInit } from '@angular/core';

import { PrimaryData } from 'app-models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-itinerary-of-services',
  templateUrl: './itinerary-of-services.component.html',
  styleUrls: ['./itinerary-of-services.component.scss']
})
export class ItineraryOfServicesComponent implements OnDestroy {
  primaryData: PrimaryData;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  onDialogClose() {
    this.dynamicDialogRef.close();
  }

  ngOnDestroy() {
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

}
