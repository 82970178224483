import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChildren, OnDestroy, Input, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CacheCountryService } from '../../../../../services/cacheCountries.service';
import { ApiServicesService } from '../../../services/api-services.service';
import { UploadDocumentService } from '../../../../../services/upload-document.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Education } from '../../../../../data/models/education.model';
import { ReferenceLibrary } from '../../../../../data/models/referenceLibrary.model';
import { ReferenceLibraryService } from '../../../../../services/referenceLibrary.service';
import { MatAccordion } from '@angular/material/expansion';
import { UtilityServiceService } from '../../../services/utility-service.service';
import * as Roles from '../../../../../data/constants/roles';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { DependentProfileDataService } from '../../../services/dependent-profile-data.service';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { ActivatedRoute } from '@angular/router';
import { CustomQuestionsComponent } from '../../../../shared/custom-questions/custom-questions.component';
import { acceptedFileTypes } from 'app-models';
import { ApiServicesService as benQuestionairreApiService } from "src/app/modules/beneficiary-questionnaire/services/api-services.service";
import { CustomErrorToastComponent } from 'projects/custom-error-toast/src/public-api';
@Component({
  selector: 'app-add-degree',
  templateUrl: './add-degree.component.html',
  styleUrls: ['./add-degree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddDegreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() mode :string;
  @Input() showFileUploadSection : boolean;
  @Input() isViewMode:boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;

  modeVal: boolean = false;
  [x: string]: any;
  showEditButton: boolean = true;
  addDegreeCourceForm: FormGroup;
  addRelatedCourceForm: FormGroup;
  submitted = false;
  updateBenProfile = true;
  listCountry: any[];
  listStates: [];
  listCourses: any[] = [];
  beneficiaryId: number;
  reqPayload: Education;
  educationDegreeDetails: any;
  getList: boolean;
  educationDetailsList: any = [];
  // auto chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  courses: any = [];
  degreeType: ReferenceLibrary[];
  documentTypes: any = [];
  setYearValue: any = '2010';
  showEndYearValidationError = false;
  showStartYearValidationError = false;
  showAwardedYearValidationError = false;
  formSubmittd = false;
  viewMode = false;
  endYearValue: any;
  degreeFileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENEDUDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENEDUDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    isDeleteEnabled: this.viewMode ? false : true,
    isUploadEnabled: this.viewMode ? false : true,
    familyId: null
  };
  expandPanel = false;
  isListView = false;
  isTimeLineView = true;
  hasID = false;
  isLinear = true;
  iscountryUSA = false;
  @ViewChildren(MatAccordion) accordion: MatAccordion;
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[];
  dependentIdSubscription: Subscription;
  familyId: any;
  beneficiaryType: any;
  observableSubscription$ = new Subject();
  isSaveAsDraftDisabled:boolean = true;
  selectedUser: any;
  customQuestions: any;
  // showFileUploadSection: boolean;
  // customTriggeredStatus: boolean = false;
  isExternalEmployeeId;
  isLocked=false;
  currentItemDisplay: any = [];
  iscaseRequest: boolean = false;
  isBenQuestShowDivs: boolean = false;

  onlyFormBuilder;

  @ViewChild(CustomQuestionsComponent) customQuestionsComponent;

  showDegreeAwardedDateRequired: boolean = true;
  isWizard: boolean = false;
  beneData: any = null;
  wizardData: any = {};
  constructor(
    private cacheCountryService: CacheCountryService,
    private apiService: ApiServicesService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private uploadDocumentService: UploadDocumentService,
    private authenticationService: AuthenticationService,
    private referenceLibraryService: ReferenceLibraryService,
    private utilityService: UtilityServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private benServ: BeneficiaryQuestionnaireService,
    private benQuestionairreApiService: benQuestionairreApiService) {
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if(this.isExternalEmployeeId){
      this.isLocked=true;
    }
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('DEGR').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.degreeType = response;
      // console.log("this.degreeType",this.degreeType);
    });

    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = [{countryCode:null,countryName:'Select',phoneCode:''},...data.listCountries];
      }
    });

    // this.referenceLibraryService.getReferenceData('BENEDUDOC').pipe(takeUntil(this.observableSubscription$))..subscribe((response: ReferenceLibrary[]) => {
    //   this.documentTypes = response;
    // });

    this.addDegreeCourceForm = this.fb.group({
      id: [],
      degree: [''],
      degreetype: ['', Validators.required],
      fieldOfStudy: ['', Validators.required],
      grade: [''],
      universityName: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      postCode: [''],
      countryCode: ['', Validators.required],
      stateProvinceCode: [null],
      stateProvinceName: [''],
      city: ['', Validators.required],
      startYear: ['', Validators.required],
      endYear: ['', Validators.required],
      degreeAwardedDate: ['']
    });

    this.addRelatedCourceForm = this.fb.group({

    });

    this.listCountry = [];
    this.listStates = [];
    this.formChangesSubscriptions = [];
    //check for Wizard flag
    this.beneData = !!(localStorage.getItem('BeneData')) ? JSON.parse(localStorage.getItem('BeneData')) : null;
    this.isWizard = this.beneData?.version === 'WWF';
    if(this.isWizard){
      this.wizardData = {
        visaType: this.beneData?.visaType,
        caseType: this.beneData?.taskTypeRef,
        caseId: this.beneData?.petitionId,
        superTaskId: this.beneData.superTaskId,
        subTaskId: this.beneData?.taskId,
        taskId: this.beneData?.taskId,
      };
    }
  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }

    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });
    // this.degreeFileUploadConfigurations.entityId = 90; // need to get
    // (sessionStorage.getItem('dependentId'))
    this.dependentIdSubscription = this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
      // console.log("$$$$ dependentId", selectedBeneObj);
      this.familyId = selectedBeneObj.dependentId;
      this.beneficiaryType = selectedBeneObj.beneficiaryType;
      // console.log('this.beneficiaryType', this.beneficiaryType);
      this.showFileUploadSection = (this.beneficiaryType === 'family' && this.familyId) ? true : (this.beneficiaryType === 'self') ? true : false;
      if(this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')){
        if(this.beneficiaryType === 'self'){
          this.iscaseRequest = true;
        } else {
          this.iscaseRequest = false;
        }
      }
      //this.toggleExpansion('close');
      this.expandPanel = false;
      this.isTimeLineView = true;
      this.isListView = false;
      this.resetForm();
      this.hasID = false;
      this.isLinear = true;
      this.afterLoadingNgInit();
      // if (message) {
      //   this.messages.push(message);
      // } else {
      //   // clear messages when empty message received
      //   this.messages = [];
      // }
    });
    this.showEditButton = !this.benServ.checkValidity();
    this.dependentProfileDataService.onLifeStorySubTabs.next();
    // this.beneficiaryId = ((sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId']);
    // this.afterLoadingNgInit();
    // this.formStatusCheck();
    this.addDegreeCourceForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(formValues => {
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });

    if(!this.listCountry?.length){
      this.cacheCountryService.getCountry().pipe(takeUntil(this.destroy$)).subscribe((data: any)=>{
        if(data?.length){
          this.listCountry = [{countryCode:null,countryName:'Select',phoneCode:''},...data];
        }
      });
    }
  }

  formStatusCheck() {

    this.formChangesSubscriptions[0] = this.addDegreeCourceForm.valueChanges.pipe(
      map(() => this.addDegreeCourceForm.dirty && this.addDegreeCourceForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: 'If you leave before saving, all changes made on the \'Education\' form will be lost. Do you wish to continue?'
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }

    });

    this.formChangesSubscriptions[1] = this.addRelatedCourceForm.valueChanges.pipe(
      map(() => this.addRelatedCourceForm.dirty && this.addRelatedCourceForm.enabled),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: 'If you leave before saving, all changes made on the \'Related Course\' form will be lost. Do you wish to continue?'
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      }

    });

  }


  afterLoadingNgInit() {
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    if (this.route.snapshot.paramMap.get('listview')) {
      this.toggleListView(true);
    }
    if (this.beneficiaryId) {
      this.degreeFileUploadConfigurations.useCase = this.isWizard ? 'petition' : 'beneficiary';
      this.degreeFileUploadConfigurations.useCaseId = this.isWizard ? this.wizardData?.caseId : this.beneficiaryId;
      this.degreeFileUploadConfigurations.familyId = this.familyId;
      this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      this.getUpolodedFiles();
    } //else {
    //this.getDegreeDetails();
    //}
    // this.getDegreeDetails();
    //  this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
    // this.cacheCountryService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountryService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = [{countryCode:null,countryName:'Select',phoneCode:''},...data.listCountries];
      }

    });
    // IM-3066 - remove deleted file.
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getDegreeDetails();
      }
    });

    this.uploadDocumentService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getDegreeDetails();
      }
    });

    this.addDegreeCourceForm.controls['endYear'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(change => {
      this.degreeAwardedDateValidator();
    });
    this.addDegreeCourceForm.controls['startYear'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(change => {
      this.degreeAwardedDateValidator();
    });
    // IM-3493 - System accepting Degree awarded date lesser than education end year
    this.addDegreeCourceForm.controls['degreeAwardedDate'].valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(val => {
      this.showAwardedYearValidationError = false;
      if (this.addDegreeCourceForm.get('endYear').value && moment(this.addDegreeCourceForm.get('endYear').value).format('YYYY') > moment(val).format('YYYY')) {
        this.showAwardedYearValidationError = true;
      }
    });

    if(localStorage.getItem('currentUserSelection')){
      this.selectedUser = JSON.parse(localStorage.getItem('currentUserSelection'));
    }

    if(localStorage.getItem('customQuestions')){
      this.customQuestions = JSON.parse(localStorage.getItem('customQuestions'));
    }

  }


  handleCountryChange() {
    const countryCode = this.addDegreeCourceForm.get('countryCode').value;
    if(null === countryCode){
      this.listStates = [];
      this.addDegreeCourceForm.patchValue({
        stateProvinceName: null,
        stateProvinceCode: null
      });
      return;
    }
    this.apiService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      this.changeDetectorRef.markForCheck();

      // IM-3410 bug fixing
      this.addDegreeCourceForm.get('stateProvinceName').clearValidators();
      this.addDegreeCourceForm.get('stateProvinceCode').clearValidators();
      if (states.length > 0) {
        this.addDegreeCourceForm.get('stateProvinceCode').setValidators([Validators.required]);
      } else {
        this.addDegreeCourceForm.get('stateProvinceName').setValidators([Validators.required]);
      }
      this.addDegreeCourceForm.get('stateProvinceName').updateValueAndValidity();
      this.addDegreeCourceForm.get('stateProvinceCode').updateValueAndValidity();

      if(this.iscaseRequest){
        this.addDegreeCourceForm.get('stateProvinceName').clearValidators();
        this.addDegreeCourceForm.get('stateProvinceCode').clearValidators();
        this.addDegreeCourceForm.get('stateProvinceName').updateValueAndValidity();
        this.addDegreeCourceForm.get('stateProvinceCode').updateValueAndValidity();
      }
    });

    if (countryCode === 'USA') {
      this.iscountryUSA = true;
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.required, Validators.pattern(/^[a-z0-9]+$/)]);
    } else {
      this.iscountryUSA = false;
      this.addDegreeCourceForm.get('postCode').clearValidators();
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.pattern(/^[a-z0-9]+$/)]);
    }
    this.addDegreeCourceForm.get('postCode').updateValueAndValidity();

    if(this.iscaseRequest){
      this.addDegreeCourceForm.get('postCode').clearValidators();
      this.addDegreeCourceForm.get('postCode').setValidators([Validators.pattern(/^[a-z0-9]+$/)]);
      this.addDegreeCourceForm.get('postCode').updateValueAndValidity();
    }
    this.degreeAwardedDateValidator();



  }

  getUpolodedFiles() {
    this.uploadDocumentService.genericGetUploadDocument(this.degreeFileUploadConfigurations.useCase, this.degreeFileUploadConfigurations.useCaseId, this.degreeFileUploadConfigurations.categoryName, this.degreeFileUploadConfigurations.entityId, this.degreeFileUploadConfigurations.useEntityId, this.degreeFileUploadConfigurations.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      if (response['status'] === 200) {
        this.degreeFileUploadConfigurations.listUploadedFiles = response['data'];
        this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      } else {
        this.degreeFileUploadConfigurations.listUploadedFiles = [];
        this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      }
      if (response['status'] === 200 || response['status'] === 204) {
        this.getDegreeDetails();
      }
    });

    // console.log("uplosg config",this.degreeFileUploadConfigurations);
  }

  addCourses() {
    this.listCourses = this.listCourses?.length ? this.listCourses.concat(this.courses) : this.courses;
    this.saveDegreeDetails();
  }

  removeAt(index) {
    const title = 'Confirm';
    const message = 'Do you wish to delete the Item?';
    this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
      if (result) {
        if (index !== -1) { this.listCourses.splice(index, 1); }
        this.saveDegreeDetails();
        // console.log("remove",courseId,index,this.listCourses)
      }
    });
  }

  getDegreeDetails() {
    const getEducationReq = this.isWizard ? this.benQuestionairreApiService.getPetitionEducationDetails(this.wizardData?.visaType,this.wizardData?.caseType,this.wizardData?.caseId,'education',this.wizardData?.superTaskId,this.wizardData?.taskId) : this.apiService.getEducationDetails(this.beneficiaryId, this.familyId); 
    getEducationReq.pipe(take(1))
      .subscribe(data => {
        // console.log("Beneficiary education details data", data['status']);

        if (data['status'] === 200) {
          this.educationDetailsList = this.isWizard ? data['data'] : data['data']['education'];
          this.benServ.educationDetailsChanged(this.educationDetailsList);
          //  console.log("Beneficiary education details", this.educationDetailsList);
          // this.dataSource = new MatTableDataSource(this.educationDetails);
        }
        if (data['status'] === 204) {
          this.educationDetailsList = [];
        }
      });
  }

  saveDegreeDetails(isDraft:boolean = false) {
    // moment(this.license.startDate).format('YYYY-MM-DD')
    // moment("25/04/2012","DD/MM/YYYY").year()
    //  console.log("Degree Info",this.addDegreeCourceForm.value);
    // console.log("courses Info",this.courses);
    this.educationDegreeDetails = this.addDegreeCourceForm.value;
    this.createPayload(this.addDegreeCourceForm.value, isDraft);
    this.addDegreeCourceForm.reset(this.addDegreeCourceForm.value);
  }
  createPayload(degreeInfo, isDraft:boolean = false) {
    const reqObj = {
      isSaveAsDraft: isDraft,
      id: degreeInfo.id || 0,
      country: {
        countryCode: degreeInfo.countryCode ? degreeInfo.countryCode : ''
      },
      university: degreeInfo.universityName ? degreeInfo.universityName : '',
      degree: degreeInfo.degree ? degreeInfo.degree : '',
      grade: degreeInfo.grade ? degreeInfo.grade : '',
      equivalance: degreeInfo.equivalance ? degreeInfo.equivalance : '',
      fieldOfStudy: degreeInfo.fieldOfStudy ? degreeInfo.fieldOfStudy : '',
      startYear: degreeInfo.startYear ? moment(degreeInfo.startYear).year().toString() : '',
      endYear: degreeInfo.endYear ? moment(degreeInfo.endYear).year().toString() : '',
      degreeAwardedDate: degreeInfo.degreeAwardedDate ? moment(degreeInfo.degreeAwardedDate).format('YYYY-MM-DD') : '',
      addressLine1: degreeInfo.addressLine1 ? degreeInfo.addressLine1 : '',
      addressLine2: degreeInfo.addressLine2 ? degreeInfo.addressLine2 : '',
      stateProvinceCode: degreeInfo.stateProvinceCode ? degreeInfo.stateProvinceCode : null,
      stateProvinceName: degreeInfo.stateProvinceName ? degreeInfo.stateProvinceName : '',
      postCode: degreeInfo.postCode ? degreeInfo.postCode : '',
      city: degreeInfo.city ? degreeInfo.city : '',
      degreeType: {
        id: degreeInfo.degreetype
      },
      courses: this.listCourses
    };

    const reqPayload = { education: [reqObj] };

    // console.log('req payload', reqPayload);

    const saveReq = this.isWizard ? this.benQuestionairreApiService.savePetitionEducatinDegreeDetails(this.wizardData?.caseId,reqPayload) : this.apiService.saveEducatinDegreeDetails(this.beneficiaryId, reqPayload, this.familyId);
    saveReq.pipe(takeUntil(this.observableSubscription$)).subscribe(response => {
      if (response.status === 200) {
        if (this.beneficiaryType === 'self') {
          if(this.customQuestionsComponent){
            this.customQuestionsComponent.onClickSave(null);
          }
        } else {
          if(this.customQuestionsComponent){
            this.customQuestionsComponent.onClickSave(this.familyId);
          }
        }
        //  console.log("response $$$$$$$$",response);
        this.toastr.success(response.message, 'Success');
        const educationListing = this.isWizard ? response.data : response.data['education'];
        if(!this.isWizard ){
          educationListing.forEach(element => {
            this.degreeFileUploadConfigurations.entityId = element.id;
            this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
            this.listCourses = element.courses;
            this.courses = [];
            // reqObj.id = element.id;
            this.addDegreeCourceForm.controls['id'].setValue(element.id);
            // this.addDegreeCourceForm.get('id').setValue=element.id;
          });
          this.degreeFileUploadConfigurations.useCase = 'beneficiary';
          this.degreeFileUploadConfigurations.useCaseId = this.beneficiaryId;
        }else{
          this.degreeFileUploadConfigurations.entityId = educationListing?.id;
            this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
            this.listCourses = educationListing?.courses;
            this.courses = [];
            this.addDegreeCourceForm.controls['id'].setValue(educationListing?.id);
            this.degreeFileUploadConfigurations.useCase = 'petition';
            this.degreeFileUploadConfigurations.useCaseId = this.wizardData?.caseId;
        }
        this.hasID = true;
        this.isLinear = false;
        // this.resetForm();
        this.getDegreeDetails();

        // this.getLicense();
      } else {
        CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
      }
    });
    // this.customTriggeredStatus == false ? this.customTriggeredStatus = true: this.customTriggeredStatus = false;
  }

  editDegreeHandler(eduDegree) {
    this.expandPanel = true;
    // console.log(eduDegree);
    this.addDegreeCourceForm.setValue({
      id: eduDegree.id ? eduDegree.id : 0,
      degree: eduDegree.degree ? eduDegree.degree : '',
      degreetype: eduDegree.degreeType.id,
      fieldOfStudy: eduDegree.fieldOfStudy,
      grade: eduDegree.grade ? eduDegree.grade : '',
      universityName: eduDegree.university,
      addressLine1: eduDegree.addressLine1,
      addressLine2: eduDegree.addressLine2,
      postCode: eduDegree.postCode ? eduDegree.postCode : '',
      countryCode: eduDegree.country ? eduDegree.country.countryCode : null,
      stateProvinceCode: eduDegree.stateProvinceCode ? eduDegree.stateProvinceCode : null,
      stateProvinceName: eduDegree.stateProvinceName,
      city: eduDegree.city,
      startYear: eduDegree.startYear ? moment([eduDegree.startYear, 0, 1]) : '',
      endYear: eduDegree.endYear ? moment([eduDegree.endYear, 0, 1]) : '',
      degreeAwardedDate: eduDegree.degreeAwardedDate ? moment(eduDegree.degreeAwardedDate).toISOString() : ''
    });
    this.handleCountryChange();
    this.listCourses = eduDegree.courses;
    if (eduDegree.id) {
      this.degreeFileUploadConfigurations.listUploadedFiles = eduDegree.documents;
      this.degreeFileUploadConfigurations.useCaseId = this.beneficiaryId;
      this.degreeFileUploadConfigurations.entityId = eduDegree.id;
      this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
      this.hasID = true;
      this.isLinear = false;
    }
  }

  deleteDegreeHandler(eduDegree) {
    //  console.log("delete degree in parent",eduDegree);
    const deleteReq = this.isWizard ? this.benQuestionairreApiService.deleteBenEducationDegreeDetails(this.wizardData?.visaType,this.wizardData?.caseType,this.wizardData?.caseId,'education',this.wizardData?.superTaskId,this.wizardData?.subTaskId,eduDegree.id) : this.apiService.deleteEducatinDegreeDetails(this.beneficiaryId, parseInt(eduDegree.id), this.familyId); 
    deleteReq.pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (this.addDegreeCourceForm.get('id').value === eduDegree.id) {
        this.resetForm();
      }
      this.toastr.success('Deleted successfully!', 'Success');
      this.getDegreeDetails();
    });


  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.courses.push(
        {
          courseName: value.trim(),
          courseId: ''
        }
      );
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); // Prevents the default action
    event.clipboardData
      .getData('Text') // Gets the text pasted
      .split(/,/) // Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.courses.push({
            courseName: value.trim(),
            courseId: ''
          }); // Push if valid
        }
      });
  }

  remove(fruit: any): void {
    const index = this.courses.indexOf(fruit);

    if (index >= 0) {
      this.courses.splice(index, 1);
    }
  }

  // dynamically adding validator for degreeAwardedDate

  degreeAwardedDateValidator() {
    const countryCode = this.addDegreeCourceForm.get('countryCode').value;
    const degreeAwardedDateControl = this.addDegreeCourceForm.get('degreeAwardedDate');
    // const endYearControl = this.addDegreeCourceForm.get('endYear');
    let endYear;
    let startYear;
    if (this.addDegreeCourceForm.get('startYear').value) {
      startYear = (this.addDegreeCourceForm.get('startYear').value).year();
    }
    if (this.addDegreeCourceForm.get('endYear').value) {
      this.endYearValue = moment(this.addDegreeCourceForm.get('endYear').value).format('YYYY-MM-DD');
      endYear = (this.addDegreeCourceForm.get('endYear').value).year();
    }


    const currentYear = moment().year();
    // console.log("years",endYear,startYear,currentYear);
    if (endYear && countryCode) {
      if (countryCode !== 'USA' && (endYear < currentYear)) {
        this.showDegreeAwardedDateRequired = true;
        degreeAwardedDateControl.setValidators(Validators.required);

      } else {
        this.showDegreeAwardedDateRequired = false;
        degreeAwardedDateControl.clearValidators();
      }

    }

    if (startYear && endYear) {
      // IM-3850 -Education -> End year same as start year to be allowed
      if (endYear >= startYear) {
        this.showEndYearValidationError = false;
      } else {
        this.showEndYearValidationError = true;
      }

    }

    if (startYear) {
      if (startYear > (new Date()).getFullYear()) {
        this.showStartYearValidationError = true;
      } else {
        this.showStartYearValidationError = false;
      }

    }
    degreeAwardedDateControl.updateValueAndValidity();
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    if (this.expandPanel) {
      this.resetForm();
      this.hasID = false;
      this.isLinear = true;
    }
  }

  addNewDegree() {
    this.resetForm();
    this.hasID = false;
    this.isLinear = true;
  }

  resetForm() {
    // this.addDegreeCourceForm.clearValidators();
    // this.addDegreeCourceForm.updateValueAndValidity();
    this.addDegreeCourceForm.reset();
    // validators required for both new and edit form
    this.formSubmittd = true;
    this.listCourses = [];
    this.degreeFileUploadConfigurations.listUploadedFiles = [];
    this.degreeFileUploadConfigurations.useCaseId = 0;
    this.degreeFileUploadConfigurations.entityId = 0;
    this.degreeFileUploadConfigurations = Object.assign({}, this.degreeFileUploadConfigurations);
    this.showEndYearValidationError = false;
    this.showStartYearValidationError = false;
  }

  closeForm() {
    this.toggleExpansion();
    this.addDegreeCourceForm.reset();
  }


  fileUploadSuccess() {
    // this.getDegreeDetails();
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }

  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onRefreshPage(event){
    if(event){
      this.ngOnInit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mode.currentValue) {
      if(changes.mode.currentValue === 'list') {
        this.modeVal = true;
      }
    }
    if((changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue)){
      this.formValidation();
      if(changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue){
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      if(changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue){
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
    } else {
      this.iscaseRequest = false;
    }
  }
  formValidation(){
    this.addDegreeCourceForm = this.fb.group({
      id: [],
      degree: [''],
      degreetype: [''],
      fieldOfStudy: [''],
      grade: [''],
      universityName: [''],
      addressLine1: [''],
      addressLine2: [''],
      postCode: [''],
      countryCode: [''],
      stateProvinceCode: [null],
      stateProvinceName: [''],
      city: [''],
      startYear: [''],
      endYear: [''],
      degreeAwardedDate: ['']
    });
  }

  ngAfterViewInit(): void {
    if(this.modeVal) {
      this.showFileUploadSection = true;
      this.toggleListView(true);
      this.toggleExpansion();
    }
  }

  ngOnDestroy() {
    this.addDegreeCourceForm.get('startYear').clearValidators();
    this.addDegreeCourceForm.get('endYear').clearValidators();
    this.addDegreeCourceForm.get('startYear').updateValueAndValidity();
    this.addDegreeCourceForm.get('endYear').updateValueAndValidity();

    if (this.formChangesSubscriptions.length > 0) {
      this.formChangesSubscriptions.forEach(element => {
        element.unsubscribe();
      });
    }
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  saveAsDraft(event){
    this.saveDegreeDetails(event);
  }

}
