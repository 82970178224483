import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ElementRef
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { UploadDocumentService } from "src/app/services/upload-document.service";
import * as Roles from "../../../data/constants/roles";
import { PetitionerService } from "src/app/services/petitioner.service";
import { LCA } from "src/app/data/models/lca.model";
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, map, filter } from 'rxjs/operators';
import { fromEvent,Subscription } from 'rxjs';
@Component({
  selector: "app-lca",
  templateUrl: "./lca.component.html",
  styleUrls: ["./lca.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class LcaComponent implements OnInit {
  lcaList: LCA[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  pageEvent: PageEvent;
  pageSizeOptions = [10,25,50,100];
  pageSize = 25; //default
  pageIndex = 0; //default
  searchText:string = '';
  searchSubscription: Subscription;
  totalRecordCount: number = 0;
  validityEndDate;
  validityStartDate;

  updateBenProfile: Boolean = true;
  educationDetails: any = [];
  beneficiaryId: any;
  expandedElement: any | null;
  envPath: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("searchAccessListTable", { static: true }) searchAccessListTable: ElementRef;
  companyId: string;

  columns = [
    { columnDef: "toggle", header: "", columnType: "icon", customClass: "" },
    {
      columnDef: "beneficiaryFullname",
      header: "Name",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "visaType",
      header: "Visa Type",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "petitionType",
      header: "Petition Type",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "companyName",
      header: "Company",
      columnType: "text",
      customClass: "",
    },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private petitionerService: PetitionerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.companyId = sessionStorage.getItem("companyId");
    this.searchSetUp();
    this.loadLcaCodes();
  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchAccessListTable.nativeElement, 'keyup').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2)
      , debounceTime(1000)).subscribe((text: string) => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.searchText = text.trim();
        this.loadLcaCodes();
      });
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map((c) => c.columnDef);
  }

  loadLcaCodes() {
    let payload = {
      caseNumber: "",
      socCode: "",
      pageNumber: this.pageIndex+1,
      pageSize: this.pageSize,
      searchText: this.searchText,
      status: ["LCAUNASSI", "LCAASSIGN", "LCAWTHDR"],
    };
    this.petitionerService
      .getPrivateAccessFiles(this.companyId, payload)
      .subscribe((data) => {
        this.lcaList = data["privateAccessFilesResponseDtoList"];
        this.totalRecordCount = data["totalCount"]||0;
        this.dataSource = new MatTableDataSource(this.lcaList);
        this.dataSource.sort = this.sort;
      });
  }

  getRecord(row, element) {
    if (element) {
      this.petitionerService
        .getLcaDetailsPrivateById(element.companyId, element.taskId)
        .subscribe((data) => {
          row.documents = data;
        });
    }
  }
  displayDocument(item) {
    // console.log(item);
  //  window.open(`${item.fileLocation}`, "_blank");
  const docPath = item.substring(23);
  window.open(`${environment.docs}${docPath}`, "_blank");
  }

  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadLcaCodes();
  }


  resetSearch(){
      this.searchText =''; //reset search
      this.pageIndex = 0;//reset search
      this.loadLcaCodes();
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }
}
