import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EncryptService } from './http-encrypt.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private encryptService: EncryptService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check for `linkTokenId_unique` in the URL
    if (request.url.includes('linkTokenId_unique')) {
      const urlParts = request.url.split('?');
      const baseUrl = urlParts[0]; // Get the base URL before '?'

      // Add environment.baseUrl if the base URL does not start with http or https
      if (!/^(http|https):/i.test(baseUrl)) {
        request = request.clone({ url: environment.baseUrl + baseUrl });
      }
    }
    // Encryption logic for other URLs
    else {
      const encryptUrl = this.encryptService.encryption(request.url);
      const newUrl = encryptUrl == null ? request.url : encryptUrl;

      // For other URLs that don't start with `http` or `https` and are not assets
      if (!/^(http|https):/i.test(newUrl) && !request.url.startsWith('assets/')) {
        request = request.clone({ url: environment.baseUrl + newUrl });
      }
    }

    return next.handle(request);
  }
}
