<p-card styleClass="tasklist-card">
    <div class="card-header d-flex justify-content-between align-items-center pl-0 pr-0">
        Individuals Priority Date Tracker
    </div>
    <div class="content-holder pt-2 pb-0">

        <div class="row">
            <div class="col-12">
                <label class="prioritySubHead m-0" *ngIf="isBeneficiary">Priority Dates for you</label>
            </div>
            <div class="col-12 px-1 task-table">
                <p-table [columns]="familyBasedCurrentDateCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table" [scrollable]="true"
                    scrollHeight="400px" styleClass="p-datatable-gridlines" [value]="familyCurrentList" dataKey="id"
                    *ngIf="familyCurrentList?.length > 0">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th *ngFor="let col of columns">
                                <span *ngIf="col && col.field == 'priorityDate'">
                                    <div class="priorityInfo">
                                        {{col.header}} <mat-icon matTooltip="An individual's priority dates are determined by the visa approval receipt date. If the receipt date is not entered in the task update decision field, Priority date will remain empty. Kindly include the receipt date if it has not been added yet.">info</mat-icon>
                                    </div>
                                </span>
                                <span *ngIf="col && col.field !== 'priorityDate'">
                                    {{col.header}}
                                </span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span *ngIf="col && col.field == 'priorityDate'">
                                    {{task[col.field] ? task[col.field] : '-'}}
                                </span>
                                <span *ngIf="col && col.field !== 'priorityDate'">
                                    {{task[col.field]}}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="familyCurrentList?.length == 0" class="mt-4 text-center">No Data</div>
                <div class="show-more-box bg-white" *ngIf="isShowMore && familyCurrentList?.length > 0"><a class="show-more"
                        (click)="showMore()">View All</a>
                </div> 
            </div>
        </div>

    </div>
</p-card>