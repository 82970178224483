<!-- <p-dialog [(visible)]="displaySupportingDocuments" [modal]="true" [responsive]="true"
[style]="{width: '1000px', minWidth: '200px'}" [minY]="70" [maximizable]="false" [baseZIndex]="50000">
<ng-template pTemplate="header">

</ng-template>-->

<div class="row">
  <div class="col-3">
    <h6>Attorney Comment</h6>
    <div class="border px-2 pt-2 pb-3">
      <div *ngFor="let item of notificationComments">
        <div class="text-break">{{item.notes}}</div>
      </div>
    </div>

  </div>
  <div class="col-9">
    <h6>Requested Documents</h6>
    <div class="border px-2 pt-2 pb-3">
      <p-accordion [multiple]="true" *ngFor="let item of missingDocs;let i=index">
        <p-accordionTab [selected]="true">

          <ng-template pTemplate="header">{{item.categoryName}}</ng-template>
          <ng-template pTemplate="content">
            <p-table [value]="item.missingDocArray" [scrollable]="true" scrollHeight="150px">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 12em">Document Type</th>
                  <th style="width: 17em">Document Name</th>
                  <th style="width: 7em">Action</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-doc>

                <tr *ngIf="doc.titleSheetDetailDocName">
                  <td colSpan="3">
                    <div class="row" *ngIf="doc.docList.length == 0">
                      <div class="col-4">{{doc.titleSheetDetailDocName}}</div>
                      <div class="col-5">
                        <span class="text-danger ml-3" *ngIf="doc.docList.length == 0">
                          <mat-icon class="mr-1 icn" class="delete-client-entry material-icons">
                            warning</mat-icon><span class="align-top ml-1">Missing
                            Document</span>
                        </span>

                      </div>
                      <div class="col-3">
                        <span *ngIf="doc.docList.length == 0" class="pl37per">
                          <button pButton type="button" (click)="uploadMissingDocument(doc,item.categoryDtlId)"
                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                            <i class="fa fa-upload" aria-hidden="true" matTooltip="Upload Document"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="doc.docList.length > 0">
                      <div class="row mt-2" *ngFor="let singleDoc of doc.docList">
                        <div class="col-4">{{doc.titleSheetDetailDocName}}</div>
                        <div class="col-5">
                          <span>{{singleDoc.fileName}}.{{singleDoc.fileType}}</span>
                        </div>
                        <div class="col-3"><span>

                            <button pButton type="button" (click)="uploadMissingDocument(doc,item.categoryDtlId)"
                              class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                              <mat-icon class="mr-1 icn" matTooltip="Upload Document" class="material-icons">file_upload
                              </mat-icon>
                            </button>
                          </span>
                          <span>

                            <button pButton type="button" (click)="viewDocument(singleDoc.fileLocation)"
                              icon="fa fa-eye" class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                              <mat-icon class="mr-1 icn" matTooltip="View Document" class="material-icons">visibility
                              </mat-icon>
                            </button>
                          </span>
                          <span>

                            <button pButton type="button" (click)="confirmDelete(singleDoc.documentId)"
                              class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                              <mat-icon class="mr-1 icn" matTooltip="Delete Document" class="material-icons">delete
                              </mat-icon>
                            </button>
                          </span>
                        </div>


                      </div>

                    </div>
                  </td>
                </tr>


              </ng-template>

            </p-table>
          </ng-template>

        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="mt-4 mb-2">
      <button pButton type="button" label="Upload Other Documents" class="p-button-outlined align-top"
        (click)="uploadOtherDocs()"></button>
      <!-- <p-overlayPanel [dismissable]="true" appendTo="contentPanel" #op> -->
      <p-dialog [(visible)]="showFileuploadSection" [modal]="true" [responsive]="true"
        [style]="{width: '1000px', minWidth: '200px'}" [minY]="70" [maximizable]="false" [baseZIndex]="60000">
        <ng-template pTemplate>
          <div class="row py-4" *ngIf="showForOtherDocs">
            <div class="col-4">
              <div class="mt-4">
                <label>Category</label>
                <p-dropdown class="w-100" [options]="categories" [(ngModel)]="selectedCategory"
                  (onChange)="updateDocType(selectedCategory)" optionLabel="categoryName">
                </p-dropdown>
              </div>
            </div>
            <div class="col-4 pl-2">
              <div class="mt-4">
                <label>Document Type</label>
                <p-dropdown class="w-100" [options]="documentType" placeholder="Select / Enter"
                  [(ngModel)]="selectedDocType" optionLabel="docName" (onChange)="setCustomEmpty()">
                </p-dropdown>
              </div>
            </div>
            <div class="col-4 pl-2">
              <label>Can't find your document type, Enter here</label>
              <input type="text" pInputText [(ngModel)]="customDocType" (keyup)="setDocTypeEmpty()" />
            </div>
          </div>
          <div class="ml-3 pb-4">
            <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Browse" [auto]="true"
              customUpload="true" (uploadHandler)="onUpload($event, createUploader)" [accept]="acceptedTypes"
              showUploadButton="false" showCancelButton="false" maxFileSize="10485760">
            </p-fileUpload>
          </div>

        </ng-template>
      </p-dialog>
      <!-- </p-overlayPanel> -->
      <!-- <div class="d-inline-block">
                <button pButton type="button" label="Request for Documents" (click)="setDisplayDocumentPanel()"
                    class="p-button-outlined ml-3"></button>
                <small
                    [ngClass]="{'mt-2 ml-3': notificationStatusUpdate === 'Response received from Petitioner/Beneficiary',
                                    'd-none' : notificationStatusUpdate === 'Not Available',
                                 'mt-2 ml-3 text-secondary d-block':notificationStatusUpdate === 'Request Sent - Awaiting for Response' }">

                    {{ notificationStatusUpdate }}</small>
            </div>  -->
    </div>


    <div class="border px-2 pt-2 pb-3" *ngIf="userType != 'Beneficiary'">
      <p-accordion>
        <p-accordionTab [selected]="false">
          <ng-template pTemplate="header">Available Document List</ng-template>
          <ng-template pTemplate="content">
            <p-accordion [multiple]="true" *ngFor="let item of supportingDocuments;let i=index">
              <p-accordionTab [selected]="true">

                <ng-template pTemplate="header">{{item.categoryName}}</ng-template>
                <ng-template pTemplate="content">
                  <p-table [value]="item.documentTypes" [scrollable]="true" scrollHeight="150px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="col-5">Document Type</th>
                        <th class="col-5">Document Name</th>
                        <th class="col-2">Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-doc>
                      <tr *ngIf="doc.documents.length <= 1">
                        <td colspan="3" style="padding: 0!important;" *ngIf="doc.present">
                          <div class="col-5 d-inline-block content">{{doc.docName}} </div>
                          <div class="col-5 d-inline-block content"><span style="margin-left:10px;">
                              {{doc.documents[0].fileName}}.{{doc.documents[0].fileType}}</span>

                          </div>
                          <div class="col-2 d-inline-block content">
                            <span *ngIf="doc.present" style="margin-left:10px;">
                              <button pButton type="button" (click)="viewDocument(doc.documents[0].fileLocation)"
                                icon="fa fa-eye" class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="View Document" class="material-icons">visibility
                                </mat-icon>
                              </button>
                            </span>
                            <span *ngIf="doc.present">
                              <button pButton type="button" (click)="confirmDelete(doc.documents[0].documentId)"
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Delete Document" class="material-icons">delete
                                </mat-icon>
                              </button>
                            </span>


                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="doc.documents.length > 1">
                        <td colspan="3" style="border:0;padding: 0!important;">
                          <p-table [value]="doc.documents">
                            <ng-template pTemplate="body" let-singleDoc>

                      <tr *ngIf='doc.present'>
                        <td colspan="3" style="border:0;padding: 0!important;">
                          <div class="col-5 d-inline-block content">{{doc.docName}} </div>
                          <div class="col-5 d-inline-block content">
                            <span *ngIf="doc.present" style="margin-left:10px;">
                              {{singleDoc.fileName}}.{{singleDoc.fileType}}</span>

                          </div>
                          <div class="col-2 d-inline-block content">
                            <span *ngIf="doc.present" style="margin-left:10px;">
                              <button pButton type="button" (click)="viewDocument(singleDoc.fileLocation)"
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="View Document" class="material-icons">visibility
                                </mat-icon>
                              </button>
                            </span>
                            <span *ngIf="doc.present">
                              <button pButton type="button" (click)="confirmDelete(singleDoc.documentId)"
                                class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                <mat-icon class="mr-1 icn" matTooltip="Delete Document" class="material-icons">delete
                                </mat-icon>
                              </button>
                            </span>


                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  </td>

                  </tr>
                </ng-template>
                </p-table>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
      </ng-template>
      </p-accordionTab>
      </p-accordion>
    </div>

  </div>

  <div class="mt-2 w-100">
    <!-- <button type="button" pButton (click)="saveData();" label="Save"></button>  -->
    <button type="button" pButton (click)="changeStatus('Complete');" label="Complete"
      class="ui-button-secondary float-right"></button>
  </div>
  <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
</div>
<!-- </p-dialog> -->
