import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BeneficiaryDocumentsEditLibraryService {

  public deletedfile$ = new BehaviorSubject<any>({});
  public showBeneficiary = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

   getStates(countryCode: string) {
    return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  saveVisaMetaInfo(beneficiaryId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  saveVisaDetailsInfo(beneficiaryId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  getVisaDetails(beneficiaryId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`).pipe(
      map((response) => response));
  }

  getVisaMetaInfo(beneficiaryId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`).pipe(
      map((response) => response));
  }

  saveTravelHistory(beneficiaryId: number, immigrationId: number, payload, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
    );
  }

  getTravelHistory(beneficiaryId: number, immigrationId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));

  }

  deleteImmigration(beneficiaryId: number, immigrationId: number, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}${profileFamilyString}`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));
  }

  deleteTravelInfo(beneficiaryId: number, immigrationId: number, travelInfoId: number) {
    return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo/${travelInfoId}`).pipe(
      map((response) => {
        // IM-3066 - remove deleted file.
        this.deletefile(beneficiaryId);
        return response;
      }));
  }

  // IM-3066 - remove deleted file.
  deletefile(beneficiaryId: number) {
    this.deletedfile$.next(true);
  }

  genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
    const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }
}
