import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'app-models';
import { MarkCleanFormAction } from 'dirty-check-store';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent implements OnInit {
  title: string;
  message: string;
  buttons = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    protected store: Store<AppState>
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.title = this.dialogData.update.title;
    this.message = this.dialogData.update.message;
    this.buttons = (this.dialogData.buttons) ? this.dialogData.buttons : ['No', 'Yes'];
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  handleNo(){
    this.dialogRef.close(false);
  }
}
