import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { AttorneyService } from '../../../services/attorney.service';
import { Company } from '../../../data/models/company.model';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';
import { debounceTime, map, filter, subscribeOn, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { ConfirmationService } from 'primeng/api';
import { CompanyService } from 'src/app/services/company.service';


@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss']
})
export class BeneficiaryListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'companyName', 'beneficiaryEmail', 'modeOfCreationName', 'action'];
  dataSource: MatTableDataSource<any>;
  pageIndex: number;
  pageSize: number;
  searchText: string;
  beneficiaryStatus: string = 'ACTIVE';
  totalRecordCount: number = 0;
  totalActiveRecords: number = 0;
  totalPastRecords: number = 0;
  totalPendingRecords: number = 0;
  pageSizeOptions: number[] = [25, 50, 100];
  selectAttorneyCompanyId: number = 0;
  selectPetitionerCompanyId: number = 0;
  listOfAttorneyCompanies: Company[];
  listOfPetitionerCompanies: Company[];
  searchSubscription: Subscription;
  companyIdSelected = 0;
  pendingStatus: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("searchBeneficiaryListTable", { static: true }) searchBeneficiaryListTable: ElementRef;
  observableSubscription$ = new Subject();
  comingFromClientPage = false;
  applyFilterDisabled : boolean;

  companyType = ['Individual', 'Attorney', 'Petitioner'];

  filter = {
    filterByCompany: 'Individual',
    selectAttorneyCompanyId: 0,
    selectPetitionerCompanyId: 0
  }
  appliedFilters: any = {
    filterByCompany: 'Individual',
    selectAttorneyCompanyId: 0,
    selectPetitionerCompanyId: 0
  }
  constructor(
    private apiService: AttorneyService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private petitionerApiService: ApiServicesService,
    private companyService: CompanyService,
  ) {
    this.pageIndex = 0; //default
    this.pageSize = 25; //default
    this.searchText = '';
    this.companyType = ['Individual', 'Attorney', 'Petitioner'];
  }

  ngOnInit() {
    //this.getCompanyType(this.filter.filterByCompany);
    this.searchSetUp();
    this.getSelfBeneficiary();
  }


  getCompanyType(filterByCompany) {
    if (filterByCompany === 'Attorney') {
      this.companyService.getCompaniesPlatformAdmin('ATRNY').subscribe(data => {
        this.listOfAttorneyCompanies = data;
      });
      this.applyFilterDisabled = this.filter.selectAttorneyCompanyId === 0 ? true : false;
    }
    else if (filterByCompany === 'Petitioner') {
      this.companyService.getCompaniesPlatformAdmin('PETNR').subscribe(data => {
        this.listOfPetitionerCompanies = data;
      });
      this.applyFilterDisabled = this.filter.selectPetitionerCompanyId === 0 ? true : false;
    }
  }

  onChangeAttorneyCompany(){
    this.applyFilterDisabled = this.filter.selectAttorneyCompanyId === 0 ? true : false;
  }
  onChangePeitionerCompany(){
    this.applyFilterDisabled = this.filter.selectPetitionerCompanyId === 0 ? true : false;
  }

 
  handleFilterChange() {
    this.appliedFilters = Object.assign({},this.filter);
    let filterByCompany = this.appliedFilters.filterByCompany;
    if (filterByCompany === 'Attorney') {
      this.getClientsList();
    }
    else if (filterByCompany === 'Petitioner') {
      this.loadBenficiaries();
    }else{
      this.appliedFilters.selectAttorneyCompanyId = 0;
      this.appliedFilters.selectPetitionerCompanyId = 0;
      this.getSelfBeneficiary();
    }

  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchBeneficiaryListTable.nativeElement, 'keyup').pipe(
      map((event: any) => { return event.target.value; }) 
      , debounceTime(1000)).subscribe((text: string) => {     
        this.searchText = text.trim();
        this.getData();
      });
  }

  getData(){
    if((this.appliedFilters.filterByCompany === 'Attorney') &&  (this.appliedFilters.selectAttorneyCompanyId)){
      this.getClientsList();
    }
    else if((this.appliedFilters.filterByCompany === 'Petitioner') && (this.appliedFilters.selectPetitionerCompanyId)){
      this.loadBenficiaries();
    }
    else{
      this.getSelfBeneficiary();
    }
  }

  getSelfBeneficiary() {
    this.apiService.getSelfRegisteredBeneficiaries({
      pageNumber: this.pageIndex + 1,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: this.searchText,
      status: [this.beneficiaryStatus],
      sortBy: 'beneficiaryName',
      sortOrder: 'asc'
    })
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((result) => {
        if (result) {
          this.dataSource = new MatTableDataSource(result['beneficiaryList']);
          this.totalRecordCount = result['totalRecords'];
          this.dataSource.sort = this.sort;
        } else {
          this.totalRecordCount = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }

  getClientsList() {
    this.apiService.getAttorneyBeneficiaries((this.appliedFilters.selectAttorneyCompanyId), {
      pageNumber: this.pageIndex + 1,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: this.searchText,
      status: [this.beneficiaryStatus],
      sortBy: 'beneficiaryName',
      sortOrder: 'asc'
    })
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((result: []) => {
        if (result) {
          this.dataSource = new MatTableDataSource(result['beneficiaryList']);
          this.totalRecordCount = this.beneficiaryStatus === 'ACTIVE' ? result['totalActiveRecords'] : (this.beneficiaryStatus === 'PEND' ? result['totalPendingRecords'] : result['totalPastRecords']);
          this.totalActiveRecords = result['totalActiveRecords'] ? result['totalActiveRecords'] : 0;
          this.totalPastRecords = result['totalPastRecords'] ? result['totalPastRecords'] : 0;
          this.totalPendingRecords = result['totalPendingRecords'] ? result['totalPendingRecords'] : 0;
          this.dataSource.sort = this.sort;
        } else {
          this.totalRecordCount = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }

  loadBenficiaries() {
    const payload = {
      pageNumber: this.pageIndex + 1,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: this.searchText,
      status: [this.beneficiaryStatus],
      sortBy: 'beneficiaryName',
      sortOrder: 'asc'
    }
    this.petitionerApiService.getBeneficiariesListForPetitioner(this.appliedFilters.selectPetitionerCompanyId, payload)

      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((result) => {
        if (result) {
          this.dataSource = new MatTableDataSource(result['beneficiaryList']);
          this.totalRecordCount = this.beneficiaryStatus === 'ACTIVE' ? result['totalActiveRecords'] : (this.beneficiaryStatus === 'PEND' ? result['totalPendingRecords'] : result['totalPastRecords']);
          this.totalActiveRecords = result['totalActiveRecords'] ? result['totalActiveRecords'] : 0;
          this.totalPastRecords = result['totalPastRecords'] ? result['totalPastRecords'] : 0;
          this.totalPendingRecords = result['totalPendingRecords'] ? result['totalPendingRecords'] : 0;
          this.dataSource.sort = this.sort;
        } else {
          this.totalRecordCount = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }


  viewBeneficiary(row) {
    sessionStorage.setItem('listBeneficiaryId', row.beneficiaryEmployeeId);
    if (!this.comingFromClientPage)
      this.router.navigate(['../beneficiaries/profile'], { state: { profileName: row.beneficiaryEmployeeFName + "'s profile" }, relativeTo: this.route });//IM-3898
    else
      this.router.navigate(['../../beneficiaries/profile'], { state: { profileName: row.beneficiaryEmployeeFName + "'s profile" }, relativeTo: this.route });//IM-3898

  }

  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
  }


  resetSearch() {
    this.searchText = ''; //reset search 
    this.pageIndex = 0;//reset search
    this.getData();
  }

  resetFilters(){
    this.filter = Object.assign({},this.appliedFilters);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

}
