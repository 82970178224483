<mat-form-field appearance="outline" class="mt-0 date-field w-100 step-date-wrong-invalid-shown">
  <input
    matInput
    [matDatepicker]="dp"
    [formControl]="yearFormControl"
    maxlength="4"
    type="number"
    (click)="dp.open()"
  />
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    startView="multi-year"
    (yearSelected)="_yearSelectedHandler($event, dp)"
    panelClass="year-picker"
    #dp
  ></mat-datepicker>
</mat-form-field>
