import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { ImagilityBaseResponse } from 'src/app/data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class CreateTaskService {
  onChangeOfSponsorSubject = new Subject();

  constructor(private http: HttpClient) { }

  getPetitionList(visaType: string) {
    return this.http.get(`/${visaType}/petitionTypes`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
    );
  }

  createPetition(visaType, payload) {
    return this.http.post(`/${visaType}/PETITION/create`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  getSponsorList(attorneyId: number) {
    return this.http.post(`/attorney/${attorneyId}/beneficiaries/list`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  getVisaTemplates(payload){
    return this.http.post(`/vd/visaTemplate`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }
  getVisaTemplateJSON(visaId){
    return this.http.get(`/vd/taskType/${visaId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  getDependentList(beneficiary_id) {
    return this.http.get(`/vd/beneficiary/${beneficiary_id}/dependentDetails`).pipe(
      map(response => {
        return response['data'];
      })
    )
  }
}
