import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, combineLatest } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { CreateTaskService } from 'src/app/modules/superTask/components/create-task/create-task.service';
import { AttorneyService } from 'src/app/services/attorney.service';
import { LookupService } from 'src/app/services/lookup-data.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { USER_PERMISSIONS } from 'permission-library';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AddBeneficiaryOrMemberService } from 'add-beneficiary-or-member';
import { AddClientModalComponent } from 'src/app/modules/clients/add-client-modal/add-client-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as errorUtils from "error-message-utility";
import { DirtyFormGuard, MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';

@Component({
  selector: 'app-add-individual-sponsor',
  templateUrl: './add-individual-sponsor.component.html',
  styleUrls: ['./add-individual-sponsor.component.scss'],
  providers: []
})
export class AddIndividualSponsorComponent implements OnInit, OnDestroy {
  beneType = 'IMMPET';
  autoCompletePlaceholder: string = 'Enter/Select';
  isExtension: any;
  filteredCompanies: any;
  dependentVisaArray: any;
  newBeneForm: FormGroup;
  userType = localStorage.getItem('userTypeRole');
  destroy$ = new Subject<void>();
  beneficiaryList: any[] = [];
  filteredBeneficiaryList: any[] = [];
  dependentList: any[] = [];
  filteredDependentList: any[] = [];
  primaryCompanyId: number;
  petitionTypes: any;
  duplicatePetitionerList: any;
  petitionerCompanyList: any;
  companyListAssociatedWithAttorney: any;
  fields: {
    disableIsOutsideImagility: boolean;
  } = {
      disableIsOutsideImagility: false
    };
  petitionerUserList: any[];
  sendInvite: any;
  search: any;
  filteredPetitionerUsers: any[];
  emailPattern: any = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  inputData: any;
  citizenType: any = 'GC';
  isSelfSponsor: boolean = true;
  relationShipTypes: any[] = [];
  emailRequired: boolean;
  primaryContact: string;
  guardSubscription: any;
  formChangesSubscriptions: any[] = [];

  constructor(
    private router: Router,
    private petitionerService: PetitionerService,
    private lookupService: LookupService,
    private attorneyService: AttorneyService,
    private addBeneficiaryOrMemberService: AddBeneficiaryOrMemberService,
    private fb: FormBuilder,
    protected store: Store<any>,
    private toast: ToastrService,
    private apiService: ApiServicesService,
    public dialog: MatDialog,
    private createTaskService: CreateTaskService,
    private guard: DirtyFormGuard,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
      this.inputData = this.dynamicDialogConfig.data;
      this.newBeneForm = this.fb.group({
        id: [null],
        beneficiaryId: [null],
        companyId: [null],
        company: ['', Validators.required],
        beneficiary: [''],
        petitionType: [null],
        fName: [null, Validators.required],
        lName: [null, Validators.required],
        mName: [null],

        applicantemail: [null],
        email: [null, Validators.required],
        primaryContact: [null],
        sendInvite: [false],
        emailContacts: [null],
        relationShipType: [null],
        authorizedSignatory: [null],
        visaType: [null],
        immCategoryCode: [null],
        fFname: [null],
        fLname: [null],
        fmName: [null],
        immClassificationCode: [null],
        taskTypeCode: [null],
        superTaskTypeCode: ['NONIMMPET'],
        isOutsideImagility: [false],
      });
  }

  ngOnInit(): void {
    this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
    this.userType = sessionStorage.getItem('userTypeRole');
    const reqJson = {
      searchText: this.search ? this.search : '',
      searchCriteria: '',
      sortBy: '',
      sortOrder: '',
      pageNumber: 0,
      recordsPerPage: 0
    };
    this.attorneyService.getClientsList(this.primaryCompanyId, reqJson).pipe(take(1))
      .subscribe((response: any) => {
        this.filteredCompanies = response.petetionerList;
        this.companyListAssociatedWithAttorney = response.petetionerList;
        this.getPetitionerUsers(this.primaryCompanyId, false, {});
        this.getBeneficiaryList(this.primaryCompanyId);
      });
    this.addBeneficiaryOrMemberService.getFamilyRelationshipTypes().subscribe((results) => {
      if (results) {
        this.relationShipTypes = results;
      }
    });

    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.formChangesSubscriptions[0] = this.newBeneForm.valueChanges
    .subscribe(() => {
      if (this.newBeneForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }
  get email() {
    return this.newBeneForm.get('email');
  }

  validate() {
    this.newBeneForm.get('email')?.updateValueAndValidity();
  }
  onChangeFilterDropDown() {

  }

  BeneTypeChanged(type) {
    this.newBeneForm.reset();
    if (type === 'IMMPET') {
      this.newBeneForm.controls['company'].setValidators([Validators.required]);
      this.newBeneForm.controls['email'].setValidators([Validators.required]);
      this.newBeneForm.controls['fFname'].clearValidators();
      this.newBeneForm.controls['fLname'].clearValidators();
      this.newBeneForm.controls['primaryContact'].clearValidators();
      this.newBeneForm.controls['applicantemail'].clearValidators();
    } else if (type === 'NONIMMPET') {
      this.newBeneForm.controls['company'].clearValidators();
      if(!this.isSelfSponsor){
        this.newBeneForm.controls['email'].clearValidators();
        this.newBeneForm.controls['applicantemail'].setValidators([Validators.required]);
      }
    }
    if (!this.isSelfSponsor) {
      this.newBeneForm.controls['company'].clearValidators();
    }
    this.newBeneForm.controls['company'].updateValueAndValidity();

  }

  navigate(navigationPath) {
    this.router.navigateByUrl(navigationPath)
  }

  isSponsorChanged(event) {
    if (event) {
      this.newBeneForm.controls['primaryContact'].clearValidators();
    } else {
      this.newBeneForm.controls['primaryContact'].setValidators([Validators.required]);
    }
    this.newBeneForm.controls['company'].updateValueAndValidity();
  }


  getCompanies(search: any = null, beneType, updateNew: boolean = false, newCompanyData: any = {}) {
    const reqJson = {
      searchText: search ? search : '',
      searchCriteria: '',
      sortBy: '',
      sortOrder: '',
      pageNumber: 0,
      recordsPerPage: 0
    };
    if (beneType) {
      let companyListReq;
      this.fields.disableIsOutsideImagility = false;
      let req = [];
      if (this.userType === 'Petitioner') {
        companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId, 'countryCode');
        req.push(companyListReq);
      } else if (this.userType === 'Attorney') {
        companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
        req.push(companyListReq);
      }
      combineLatest(req)
        .pipe(take(1))
        .subscribe((response: any[]) => {
          this.petitionTypes = response[0].map(x => {
            return {
              code: x.code,
              name: x.name,
              id: x.id,
              desc: x.description,
              group: x.group
            };
          });
          // this.duplicateDataPetitionTypes = this.petitionTypes;
          this.duplicatePetitionerList.petitionType = this.petitionTypes;
          if (this.userType === 'Petitioner') {
            this.petitionerCompanyList = response[1].map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
            // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
            if (updateNew && newCompanyData) {
              this.newBeneForm.patchValue({
                companyId: newCompanyData.companyId,
                company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
              });
              this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
              this.getBeneficiaryList(newCompanyData.companyId);
            }
            this.duplicatePetitionerList.company = this.petitionerCompanyList;
          } else if (this.userType === 'Attorney') {
            if (response[1] && response[1].petetionerList) {
              if (this.dependentVisaArray.includes(this.newBeneForm.get('visaType').value)) {
                this.companyListAssociatedWithAttorney = [{
                  petitionerId: 0,
                  petitionerName: 'Individual/Sponsor',
                  contactPersonFirstName: '--',
                  contactPersonLastName: '',
                  contactPersonMiddleName: '',
                }, ...response[1].petetionerList];
              } else {
                this.companyListAssociatedWithAttorney = response[1].petetionerList;
              }
              this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
              if (updateNew && newCompanyData) {
                this.newBeneForm.patchValue({
                  companyId: newCompanyData.companyId,
                  company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                });
                this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                this.getBeneficiaryList(newCompanyData.companyId);
              }
            }
          }
          this.onChangeFilterDropDown();
        });
    } else {
      let companyListReq;
      if (this.userType === 'Petitioner') {
        companyListReq = this.petitionerService.getPetitionerClientList(this.primaryCompanyId);
      } else if (this.userType === 'Attorney') {
        companyListReq = this.attorneyService.getClientsList(this.primaryCompanyId, reqJson);
      }
      companyListReq.pipe(take(1))
        .subscribe((response: any) => {
          const immCategoryCode = this.newBeneForm.get('immCategoryCode').value;
          this.duplicatePetitionerList.petitionType = this.petitionTypes;
          if (this.userType === 'Petitioner') {
            this.petitionerCompanyList = response.map((c) => { return { petitionerName: c.companyName, petitionerId: c.id, ...c }; });
            // this.duplicateDataPetitionerCompanyList = this.petitionerCompanyList;
            this.duplicatePetitionerList.company = this.petitionerCompanyList;
            if (updateNew && newCompanyData) {
              this.newBeneForm.patchValue({
                companyId: newCompanyData.companyId,
                company: this.petitionerCompanyList.find((c) => c.petitionerId === newCompanyData.companyId)
              });
              this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
              this.getBeneficiaryList(newCompanyData.companyId);
            }
          } else if (this.userType === 'Attorney') {
            if (response && response.petetionerList) {
              if (beneType == 'IMMPET') {
                this.companyListAssociatedWithAttorney = [{
                  petitionerId: 0,
                  petitionerName: 'Individual/Sponsor',
                  contactPersonFirstName: '--',
                  contactPersonLastName: '',
                  contactPersonMiddleName: '',
                }, ...response.petetionerList];
              } else {
                this.companyListAssociatedWithAttorney = response.petetionerList;
              }
              this.duplicatePetitionerList.company = this.companyListAssociatedWithAttorney;
              if (updateNew && newCompanyData) {
                this.newBeneForm.patchValue({
                  companyId: newCompanyData.companyId,
                  company: this.companyListAssociatedWithAttorney.find((c) => c.petitionerId === newCompanyData.companyId)
                });
                this.getPetitionerUsers(newCompanyData.companyId, true, newCompanyData);
                this.getBeneficiaryList(newCompanyData.companyId);
              }

            }
          }
        });
    }

  }

  onDependentChange(selectedItem: any) {
    console.log('Form:', this.newBeneForm);

  }

  getDependents(benificiaryId: number, updateNew: boolean = false, newApplicantData: any = {}) {
    this.createTaskService.getDependentList(benificiaryId).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.dependentList = response.map(x => {
        return {
          ...x,
          displayName: `${x.firstName} ${x.lastName} - ${x.relationshipName}`,
          isChecked: false,
          isPrimaryApplicant: false,
          email: x?.emailContacts && x.emailContacts[0].email
        };
      });
      if (this.newBeneForm.value?.visaType === 'K1') {
        this.dependentList = this.dependentList.filter(item => item.relationshipCode === 'FIANCE');
      } else if (this.newBeneForm.value?.visaType === 'K3') {
        this.dependentList = this.dependentList.filter(item => item.relationshipCode === 'SPOUSE');
      } else if (this.newBeneForm.value?.immCategoryCode && this.newBeneForm.value?.immCategoryCode === 'GCFLY') {
        if (this.newBeneForm.value?.immClassificationCode === 'REMVCONDTRES') {
          this.dependentList = this.dependentList.filter(item => ['SPOUSE', 'CHILD'].includes(item.relationshipCode));
        } else {
          this.dependentList = this.dependentList;
        }
      } else if (this.newBeneForm.value?.immClassificationCode &&
        ['WVRUNLAWPRSNCI601A', 'WVRINADMI601', 'AFFDVTSUPT864'].includes(this.newBeneForm.value?.immClassificationCode)) {
        this.dependentList = this.dependentList;
      } else {
        //this.dependentList = this.dependentList.filter(item => ['SPOUSE', 'CHILD'].includes(item.relationshipCode));
        this.dependentList = this.dependentList;
      }

      if (updateNew && newApplicantData) {
        const newApplicant = this.dependentList.find((dep) => dep.beneficiaryEmployeeId === newApplicantData.id);
        this.newBeneForm.patchValue({
          primaryApplicant: newApplicant
        });
        this.onDependentChange(newApplicant);
      }
    });
  }

  onBenChange(selectedItem: any) {
    if (!selectedItem) return false;
    this.newBeneForm.patchValue({
      beneficiaryId: selectedItem.id ? selectedItem.id : selectedItem.beneficiaryEmployeeId
    });
    //const creatingTaskFor = this.newBeneForm.get('creatingTaskFor').value;
    if (!!(this.newBeneForm.get('immCategoryCode').value && this.newBeneForm.get('immCategoryCode').value === 'GCFLY') || (this.newBeneForm.value.superTaskTypeCode === 'NONIMMPET' && ['K1', 'K3'].includes(this.newBeneForm.value.visaType))) {
      this.getDependents(this.newBeneForm.value.beneficiaryId);
    }
  }

  onPeitionerUserChange(selectedItem: any) {
    this.newBeneForm.patchValue({
      authorizedSignatoryId: selectedItem.id
    });
  }


  getPetitionerUsers(compayId: number, updateNew: boolean = false, newContactData: any = {}) {
    if (compayId) {
      this.lookupService.getReviewersUser(compayId, USER_PERMISSIONS.PETITION_REVIEWER).subscribe((data: Array<any>) => {
        this.petitionerUserList = data.map((emp) => { emp['fullName'] = `${emp.firstName}${emp.middleName ? ' ' + emp.middleName : ''} ${emp.lastName}`; return emp; });
        if (updateNew && newContactData) {
          const newContact = this.petitionerUserList.find((pu) => pu.id === newContactData?.id);
          this.newBeneForm.patchValue({
            authorizedSignatory: newContact
          });
          this.onPeitionerUserChange(newContact);
        }
      });
    }
  }
  filterPetitionerUsers(event: any) {
    let query = event.query;
    let filtered = [];

    for (let i = 0; i < this.petitionerUserList.length; i++) {
      let item = this.petitionerUserList[i];
      if (item.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }

    this.filteredPetitionerUsers = filtered;
  }

  getBeneficiaryList(companyId, searchText: string = '', updateNew: boolean = false, newBenData: any = {}) {
    const beneficiaryParamPayload = {
      pageNumber: 1,
      recordsPerPage: 100,
      searchCriteria: 'beneficiary',
      searchText: searchText,
      sortBy: 'companyName',
      sortOrder: 'asc',
      status: ['ACTIVE']
    };
    this.apiService.getBeneficiariesByCompanyId(companyId, beneficiaryParamPayload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Array<any>) => {
        if (data) {
          this.beneficiaryList = data.map((ben) => { ben['fullName'] = `${ben.beneficiaryEmployeeFName}${ben.beneficiaryEmployeeMName ? ' ' + ben.beneficiaryEmployeeMName : ''} ${ben.beneficiaryEmployeeLName}`; return ben; });
          if (updateNew && newBenData) {
            const newBen = this.beneficiaryList.find((ben) => ben.beneficiaryEmployeeId === newBenData?.id);
            this.newBeneForm.patchValue({
              beneficiary: newBen
            });
            this.onBenChange(newBen);
          }
        }
      });
  }


  filterBeneficiaries(event) {
    let query = event.query;
    let filtered = [];

    for (let i = 0; i < this.beneficiaryList.length; i++) {
      let item = this.beneficiaryList[i];
      if (item.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }

    this.filteredBeneficiaryList = filtered;
  }

  backToDashboard() { }
  cancel() {
    this.router.navigateByUrl('attorney-landing/attorney/beneficiaries');
  }
  onSubmit() {
    const attorneyId = parseInt(sessionStorage.getItem('companyId'));
    let reqpayload = {}
    if (this.beneType === 'IMMPET') {
      reqpayload = {
        "citizenship": "Indian",
        "companyId": parseInt(this.newBeneForm.value.company.petitionerId),
        "email": this.newBeneForm.value.email,
        "firstName": this.newBeneForm.value.fName,
        "sendInvite": this.newBeneForm.value.sendInvite,
        "lastName": this.newBeneForm.value.lName,
        "middleName": this.newBeneForm.value.mName,
        "individualType": "EmployeeBased",

      }
    } else {
      let attorneyCompanyId = parseInt(sessionStorage.getItem('companyId'));
      reqpayload = {
        "citizenship": this.citizenType,
        "companyId": attorneyCompanyId,
        "email": this.newBeneForm.value.email,
        "firstName": this.newBeneForm.value.fName,
        "sendInvite": this.newBeneForm.value.sendInvite,
        "lastName": this.newBeneForm.value.lName,
        "middleName": this.newBeneForm.value.mName,
        "individualType": "Individual",
        "familyEmail": this.newBeneForm.value.applicantemail,
        "familyFirstName": this.newBeneForm.value.fFname,
        "familyLastName": this.newBeneForm.value.fLname,
        "familyMiddleName": this.newBeneForm.value.fmName,
        "familyRelationShip": this.newBeneForm.value.relationShipType,
        "selfSponsor": this.isSelfSponsor,
        "primaryContact": this.newBeneForm.value.primaryContact
      }
    }
    this.attorneyService.saveBeneficiaryNew(reqpayload).subscribe((Response) => {
      if (Response) {
        this.toast.success(Response.message, 'Success');
        if(!this.inputData){
          this.router.navigateByUrl('attorney-landing/attorney/beneficiaries');
        }else{
          this.dynamicDialogRef.close();
        }
      }
    })
  }
  handleAddClick(type) {
    const dialogRef = this.dialog.open(AddClientModalComponent, {
      panelClass: 'add-client-dialog',
      width: '740px',
      data: { attorneyId: this.userType === 'Attorney' ? this.primaryCompanyId : null, sendInvite: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
        this.toast.success(result, 'Success');
      }
    });
  }
  filterDependents(ev) { }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  isDuplicatedtoggle(ev) { }
  onCompanyChange(ev) {
    console.log(ev);
  }


  filterCompanies(event: any) {
    let query = event.query;
    let filtered = [];
    let arrayTobeFiltered = (this.userType === 'Petitioner') ? this.petitionerCompanyList : this.companyListAssociatedWithAttorney;
    // if (this.beneType && this.userType === 'Attorney') {
    //   arrayTobeFiltered = [{
    //     petitionerId: 0,
    //     petitionerName: 'Individual/Sponsor',
    //     contactPersonFirstName: '--',
    //     contactPersonLastName: '',
    //     contactPersonMiddleName: '',
    //   }, ...arrayTobeFiltered];
    // }

    const keyToFilter = (this.userType === 'Petitioner') ? 'companyName' : 'petitionerName';
    for (let i = 0; i < arrayTobeFiltered.length; i++) {
      let item = arrayTobeFiltered[i];
      if (item[keyToFilter].toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(item);
      }
    }
    this.filteredCompanies = filtered;
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(item => { item.unsubscribe(); });
    }
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }
}

