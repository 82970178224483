import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-training-timeline',
  templateUrl: './training-timeline.component.html',
  styleUrls: ['./training-timeline.component.scss']
})
export class TrainingTimelineComponent implements OnInit {
  @Input() trainingData;
  constructor() { }

  ngOnInit() {
  }

}
