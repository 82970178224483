import { ImagilityBaseResponse } from 'app-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobDescriptionService {
  constructor(private http: HttpClient) { }
  getJobDescription(visatype, caseType, caseId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/jobDescription?isThisPastJob=false`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  getPositionTitleList(visatype, caseType, caseId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/positionTitleList`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getDegreeListByPosition(visatype, caseType, caseId, postionTitleId) {
    return this.http.get(`/${visatype}/${caseType}/${caseId}/postionTitle/${postionTitleId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }


  postJobDescription(payload, visatype, caseType, caseId) {
    return this.http.post(`/${visatype}/${caseType}/${caseId}/jobDescription`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // /api/v1/{type}/{caseType}/{caseId}/workLocation/{workLocationId} - To delete work location
  deleteWorkLocationItem(visatype, caseType, caseId, workLocationId) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/workLocation/${workLocationId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // /api/v1/{type}/{caseType}/{caseId}/itinenary/{itinenaryId} - To delete Itinenary info
  deleteItineraryItem(visatype, caseType, caseId, itinenaryId) {
    return this.http.delete(`/${visatype}/${caseType}/${caseId}/itinenary/${itinenaryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  saveJobRole(companyId, categoryId, paylod) {///
    return this.http.post(`/company/${companyId}/${categoryId}/masterData/jobRole`, paylod).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
