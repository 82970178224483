import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
const enum states {
  ACTIVE= 0,
  IDLE,
  TIMED_OUT
}
@Injectable({
  providedIn: 'root'
})
export class IdleService {
  state = states.ACTIVE;
  timedOut = false;
  public noTimeOut = new Set(['/error/time-out', '/login']);
  constructor(private idle: Idle) {}

  public set(idleTime: number, timeoutTime: number) {
    this.idle.setIdle(idleTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(timeoutTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }
  public get() {
    return this.idle;
  }
  public getState() {
    return this.state;
  }
  public reset() {
    this.idle.watch();
    this.state = states.ACTIVE;
    this.timedOut = false;
  }
}
