import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor(private httpClient: HttpClient) { }

  getAllEvents(payload, calItemType?) {
    // "calItemType": 1067,
    // if (calItemType) {
    //   payload.calItemTypes = calItemType
    // }
    // console.log('service payload', payload)
    const url = '/meeting/search/calendar';
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }


  // getAllEvents(payload, calItemType?) {
  saveCalendarItem(payload) {
    // console.log('saveCalendarItem', payload)
    const url = '/scheduleCalender/create';
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }  elser= re
      })
    );
  }


  updateCalendarItem(payload) {
    // console.log('updateCalendarItem', payload)
    const url = '/scheduleCalender/update';
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }



  saveMeeting(payload) {
    // console.log('meeting',payload)
    return this.httpClient.post(`/scheduleCalender/create`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  getAttendesList(userid, searchquery) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userid}`
    });
    return this.httpClient.get(`/company/272/employeeInfo/email/${searchquery}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getClientNameList(userid) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userid}`
    });
    return this.httpClient.get(`/company/272/hierarchy`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;

      })
    );
  }

  getEmployeeDetails(companyid, searchText) {
    const url = `/company/${companyid}/employeeInfo/name/${searchText}`;
    return this.httpClient.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  getAttendesList1(userid, searchquery, companyId) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userid}`
    });
    return this.httpClient.get(`/company/${companyId}/employeeInfo/email/${searchquery}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getClientNameList1(usertoken, companyid) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${usertoken}`
    });
    return this.httpClient.get(`/company/${companyid}/hierarchy`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;

      })
    );
  }

  getEmployeeDetails1(companyId, searchText) {
    const url = `/company/${companyId}/employeeInfo/name/${searchText}`;
    return this.httpClient.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  updateMeeting(payload) {
    // console.log('meeting')
    return this.httpClient.post(`/scheduleCalender/update`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );

  }


  getTeamembersList(token, comId) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.httpClient.get(`/company/${comId}/employeeInfo/ALL/NA`).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );

  }

  getCalendarItemDetails(calItemId, series: number) {
    const url = `/meeting/calendar/${calItemId}/${series}`;
    // let url = `/company/${companyId}/employeeInfo/name/${searchText}`
    return this.httpClient.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  cancelCalendarItem(payload) {
    return this.httpClient.put('/calendar/cancel', payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  eventUpload(payload, calendarSchedulId) {
    return this.httpClient
      .post(
        `/schedulCalender/event/${calendarSchedulId}/fileRefGroupCode/CALITEMDOC/fileRefCode/CALITEMDOC/document`,
        payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          // if (response.status === 200) {
          return response;
          // }
        })
      );
  }

  getEventDoc(calendarSchedulId) {
    // const headers = new Headers({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${userid}`
    // })
    return this.httpClient.get(`/schedulCalender/event/${calendarSchedulId}/fileRefGroupCode/CALITEMDOC/document`).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  getTaskComplete(payload) {

    return this.httpClient.put(`/calendar/task/complete`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }

  deleteEventDocument(docid) {
    return this.httpClient.delete(`/schedulCalender/event/${docid}/deleteDocument`).pipe(
      map((response) => {
        // if (response.status === 200) {
        return response;
        // }
      })
    );
  }
}



