import { constants } from 'perf_hooks';



export enum layout_code {
    FIND_SPONSOR = '',
    OFFER_LETTER = '',
    ACCEPT_OFFER = '',
    REGISTER_USCIS = '',
    AWAIT_LOTTERY = '',
    PAY_DETERMINATION = '',
    POSITION_DETAIL = 'builder/pb/job-description/position-details',
    BEN_POSITION_DETAIL = 'builder/pb/job-description/position-details',
    REVIEW_POSITION = 'builder/pb/job-description/position-details',
    FILE_LCA = 'builder/pb/lca/lca-soc',
    APPLY_LCA = 'builder/pb/lca/lca-soc',
    NOTIFY_US_WF = '',
    POST_LCA = '',
    SEND_DOCUMENTS = 'builder/pb/beneficiary-qualification/education-information',
    BEN_SEND_DOCUMENTS = 'builder/pb/beneficiary-qualification/education-information',
    WORK_LOCATION = 'builder/pb/job-description/work-location',
    BEN_WORK_LOCATION = 'builder/pb/job-description/work-location',
    SUPPORT_LETTER = 'builder/pb/letter/support',
    PRINT_PETITION = 'builder/pb/review-print/print',
    SUBMIT_FEES = '',
    GET_RECEIPT = '',
    WAIT = '',
    RFE = '',
    VISA = '',
    // WORK_LOCATION='builder/pb/emp/work-location',
    DUTY_DETAILS = 'builder/pb/job-description/job-duties',
    BEN_DUTY_DETAILS = 'builder/pb/job-description/job-duties',
    OCCUPATION_DETAIL = 'builder/pb/speciality-occupation/occupation-determination',
    BEN_OCCUPATION_DETAIL = 'builder/pb/speciality-occupation/occupation-determination',
    IOS_DETAIL = 'builder/pb/beneficiary-qualification/itinerary-services',
    BEN_IOS_DETAIL = 'builder/pb/beneficiary-qualification/itinerary-services',
    REVIEW_DOCS = 'builder/pb/beneficiary-qualification/immigration-documents',
    BEN_REVIEW_DOCS = 'builder/pb/beneficiary-qualification/immigration-documents',
    PROJECT_DOCS = 'builder/pb/emp-relationship/supervisor',
    BEN_PROJECT_DOCS = 'builder/pb/emp-relationship/supervisor',
    FILL_FORM = 'builder/pb/uscis/mailing-address',
    REVIEW_PETITION = 'builder/pb/review-print/print',
}

export enum pb_reviewer_code {
    POSITION_DETAIL = 'POSITION_DETAIL',
    DUTIES = 'DUTY_DETAILS',
    WORK_LOCATION = 'WORK_LOCATION',
    SELECT_LCA = "FILE_LCA",
    IOS_DETAIL = "IOS_DETAIL",
    BENE_QULIFICATION = "SEND_DOCUMENTS",
    OCCUPATION_DETAILS = "OCCUPATION_DETAIL",
    REVIEW_DOCUMENTS = "REVIEW_DOCS",
    PROJECT_DOCUMENTS = "PROJECT_DOCS",
    FILL_FORM = "FILL_FORM",
    SUPPORT_LETTER = "SUPPORT_LETTER",
    REVIEW_PETITION = 'REVIEW_PETITION',
}

export enum pb_step_staus_code {
    POSITION_DETAIL = 'POSITION_DETAIL',
    BEN_POSITION_DETAIL = 'BEN_POSITION_DETAIL',
    DUTIES = 'DUTY_DETAILS',
    WORK_LOCATION = 'WORK_LOCATION',
    APPLY_LCA = "APPLY_LCA",
    SELECT_LCA = "FILE_LCA",
    IOS_DETAIL = "IOS_DETAIL",
    BENE_QULIFICATION = "SEND_DOCUMENTS",
    OCCUPATION_DETAILS = "OCCUPATION_DETAIL",
    REVIEW_DOCUMENTS = "REVIEW_DOCS",
    PROJECT_DOCUMENTS = "PROJECT_DOCS",
    FILL_FORM = "FILL_FORM",
    SUPPORT_LETTER = "SUPPORT_LETTER",
    PRINT_PETITION = 'PRINT_PETITION',
    SUBMIT_FEES = 'SUBMIT_FEES',
    GET_RECEIPT = 'GET_RECEIPT'
}

export enum pb_reviewer_doc {
    PASSPORT = "BENPASSDOC"
}


export enum pb_step_name {
    POSITION_DETAIL = 'Enter Position Details',
    BEN_POSITION_DETAIL = 'Enter Position Details',
    DUTIES = 'Enter List of Duties',
    WORK_LOCATION = 'Enter Work Location',
    APPLY_LCA = "Select SOC Code & Assign LCA",
    // SELECT_LCA : "FILE_LCA",
    IOS_DETAIL = "Enter Itinerary of Services",
    BENE_QULIFICATION = "Enter Education & Work Experience",
    OCCUPATION_DETAILS = "Enter Occupation Details",
    REVIEW_DOCUMENTS = "Review Documents",
    PROJECT_DOCUMENTS = "Enter Project Details",
    FILL_FORM = "Generate Forms",
    SUPPORT_LETTER = "Create Support Letter",
    REVIEW_PETITION = 'Review Petition',
    PRINT_PETITION = 'Print Petition',
    SUBMIT_FEES = 'Submit Petition with Fees',
    GET_RECEIPT = 'Get Receipt',
    PROJECT_DOCS = "Enter Project Details",
    WAIT = 'Wait for USCIS Decision',
    BEN_REVIEW_DOCS = "Provide Other Documents"
}

export enum GC_I140_Layout {
    PROCESSING_INFORMATION = 'Enter Processing Information',
    JOB_DESCRIPTION = 'Enter Job Description',
    Past_JOB_DESCRIPTION = 'Enter Past Job Description (Foreign Company)',
    BENIFICARY_PROFILE_DETAILS = 'Enter Beneficiary Personal Details',
    EDU_DETAIL = 'Enter Beneficiary Education Details',
    BEN_EDU_DETAIL = 'Enter Beneficiary Education Details',
    BENIFICARY_EXPERIENCE_DETAILS = 'Enter Beneficiary Experience Details',
    BEN_EXP_DETAIL = 'Enter Beneficiary Experience Details',
    BEN_DEPENDENT_INFO = 'Enter Beneficiary Dependent Details',
    DEPENDENT_INFO = 'Enter Beneficiary Dependent Details',
    PET_DOC_AND_DETAILS = 'Enter Petitioner Details & Documents',
    USCIS_CONTENT = 'USCIS Content',
    LETTER_GEN = 'Letter Generation',
    REVIEW_I140_PET = 'Review I-140 Petition',
    PRINT_I140_PET = 'Print I-140 Petition',
    SUB_I140_PET = 'Submit I-140 Petition',
    GET_RECEIPT = 'Get Receipts',
    BENEFICIARY_DOCUMENTS = 'Provide Beneficiary Documents',
    UPDATE_DECISION = 'Update Decision',
    SEND_NOTIFICATION = 'Send Notification',
    PROVIDE_BEN_DOCS = 'Provide Beneficiary Documents',
    BEN_PROVIDE_BEN_DOCS = 'Provide Beneficiary Documents',
}

