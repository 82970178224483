import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PrimaryData, TaskSummaryOverview } from 'app-models';
import * as Task from './../../../superTask/task-state/actions/task.actions';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { VisaExtendedState } from 'src/app/modules/visa-base/utility/interfaces/visa-extended-state';

@Component({
  selector: 'app-enter-entities-relationship-details',
  templateUrl: './enter-entities-relationship-details.component.html',
  styleUrls: ['./enter-entities-relationship-details.component.scss']
})
export class EnterEntitiesRelationshipDetailsComponent implements OnInit {

  primaryData: PrimaryData;
  form: FormGroup;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    public petitionerService: PetitionerService,
    private store: Store<VisaExtendedState>
  ) {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  ngOnInit() {
  }

  onDialogClose() {
    this.dynamicDialogRef.close();
  }

  // onStepStatusUpdate() {
  //   this.petitionerService.getSuperTask(this.dynamicDialogConfig.data.superTaskId)
  //     .pipe(take(1))
  //     .subscribe((taskSummaryOverview: TaskSummaryOverview) => {
  //       this.store.dispatch(new Task.SetTaskSummaryOverview(taskSummaryOverview));
  //     });
  // }

}
