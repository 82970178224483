// export const CALITEMS  = [
//     {name: 'Court Date', code: 'COURTDATE', type:'court date'},
//     {name: 'Meeting', code: 'NY', type:'meeting'},
//     {name: 'Event', code: 'RM', type:'event'},
//     {name: 'Task', code: 'LDN', type: 'task'},
//     {name: 'Reminder', code: 'PRS', type:'reminder'}
// ]

export const CALITEMS  = [
  {displayName:'Meeting',  name: 'meeting', code: 1067, type: 'meeting', id:1067 },
  { displayName:'Event', name: 'event', code: 1068, type: 'event' , id:1068},
  { displayName:'Task', name: 'task', code: 1069, type: 'task' , id: 1069},
  {displayName:'Reminder',  name: 'reminder', code: 1071, type: 'reminder', id:1071 },
  {displayName:'Court Date',  name: 'courtdate', code: 1070, type:'court date', id:1070},
]
export enum calItemEnum {
    Meeting = 'MEETING',
    Task = 'TASK',
    Court = 'COURTDATE',
    Reminder = 'REMINDER',
    Event = 'EVENT'
  }