<div class="content-container pb-1">
  <div class="row mt-3">
    <div class="col-md-2">
      <div class="p-3" style="height:200px ;">
        <img [src]="businessLogosrc " alt="profile picture">
      </div>
    </div>
    <div class="col-1 mt-5 text">
      <span>Receipt</span>
    </div>
    <div class="col-7">

    </div>


    <div class="col-2 mt-2">
      <div class="mt-2">
        <strong> Payment Date</strong> : <span>{{this.viewInvoiceData.paymentRecvdDate}}</span>
      </div>
      <div class="mt-2">
        <strong>Receipt Number</strong> : <span>{{this.viewInvoiceData.receiptNumber}}</span>
      </div>
      <div class="mt-2">
        <strong>Receipt date</strong>: <span>{{this.viewInvoiceData.invoiceDueDate}}</span>
      </div>
    </div>
  </div>


  <div class="row mt-3 " style=" background-color: #EDF4FB;">


    <div class=" col-6 mt-2 p-2 ">
      <strong>Billed To</strong>

    </div>

    <div class=" col-6 mt-2 p-2">
      <strong></strong>
    </div>
  </div>
  <div class="divider">

  </div>
  <div class="row" style=" background-color: #EDF4FB;">
    <div class="col-6 mb-3 mt-2">
      <span class="companyname">{{viewInvoiceData.billedTocompany.companyName}}</span>
      <address>
        {{viewInvoiceData.billedTocompany.address.addressLine1}} {{viewInvoiceData.billedTocompany.address.city}} <br>
        {{viewInvoiceData.billedTocompany.address.countryCode}} {{viewInvoiceData.billedTocompany.address.postCode}}<br>

        Tel: {{viewInvoiceData.billedTocompany.phoneNumber}} <br>
        Email: {{viewInvoiceData.billedTocompany.email}} <br>
      </address>
    </div>
    <div class="col-6 " style="text-align:right">
      <div class="mt-2">
        <strong>Mode of Payment</strong> : <span>{{this.viewInvoiceData.paymentMode ? this.viewInvoiceData.paymentMode:
          null}}</span>
      </div>
      <div class="mt-2">
        <strong>Reference Number</strong> : <span>{{this.viewInvoiceData.receiptNumber ?
          this.viewInvoiceData.receiptNumber : null}}</span>
      </div>
      <div class="mt-2">
        <strong>Invoice No</strong>: <span>{{this.viewInvoiceData.invoiceNumber ? this.viewInvoiceData.invoiceNumber:
          null }}</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <p-table [value]="viewInvoiceData.billingItemDTOs" responsiveLayout="scroll" dataKey="id">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            Billing Item
          </th>
          <th>chargeableAmt</th>
          <th>Tax</th>
          <th>Discount</th>
          <th>Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          
          <td>{{rowData.billingItem}}</td>
          <td><i class='icon-style'>
            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                class="dollar img-style" style="width:12px" />
        </i>
            {{rowData.chargeableAmt}}</td>
          <td><i class='icon-style'>
            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                class="dollar img-style" style="width:12px" />
        </i>{{rowData.taxAmt}}</td>
          <td><i class='icon-style'>
            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                class="dollar img-style" style="width:12px" />
        </i>{{rowData.discountAmt}}</td>
          <td><i class='icon-style'>
            <img src="../../../../assets/images/invoice/dolar-sign.svg"
                class="dollar img-style" style="width:12px" />
        </i>{{rowData.totalAmt}}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <div class="row mt-3">
    <div class="col-10"></div>
    <div class="col-2">
      <strong>Total </strong> : <span>{{viewInvoiceData.grandTotal}}</span>
    </div>
  </div>


  <div class="row  mt-3">
    <div class="col-10"></div>
    <div class="col-2">
      <strong class="text-capitalize">{{viewInvoiceData.grandTotalInWords}}</strong>
    </div>

  </div>
</div>
<div class="row d-flex justify-content-center align-items-center">
  <div class="col-5"></div>
  <div class="col-1 nopadding ">
    Powered by
  </div>
  <div class="col-1">
    <img src="../../../../assets/images/logo-4_youtube.jpg">
  </div>
  <div class="col-5"></div>
</div>