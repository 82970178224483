<div class="container pt-4">
    <div class="row">
        <div class="col-md-12">
            <div class="main-timeline" *ngIf="trainingData.length>0">
                <div class="triangle-box position-relative text-center">
                    <div class='arrow-icon'></div>
                </div>
                <div class="timeline" *ngFor="let item of trainingData">
                    <!-- <a class="timeline-content">                        
                        <div class="timeline-duration"><span>{{item?.name}}</span></div>
                        <h3 class="title">{{item?.institution}}</h3>                        
                        <h3 class="title">{{item?.endDate | date:'yyyy'}}</h3>   
                    </a> -->
                    <a class="timeline-content">
                        <div class="row">
                         <!-- <div class="col-2"><h3><i class="timeline-custom-icon fa fa-newspaper-o"></i></h3></div> -->
                         <div class="col">                            
                            <h3 class="title">{{item?.name}}</h3>
                            <p class="description">
                                {{item?.institution}}
                            </p>
                         </div>
                        </div> 
                        <div class="timeline-duration ed-duration position-absolute"><span>{{item?.endDate | date:'yyyy'}}</span></div>                       
                    </a>
                </div>                
            </div>
            <div class="rounded-area">
                <div class="rounded-area-inner"></div>
            </div>
            <div *ngIf="trainingData.length==0" class="no-data">-- No Data --</div>
        </div>
    </div>
</div>