export const ATTORNEY = 'Attorney';
export const PETITIONER = 'Petitioner';
export const BENEFICIARY = 'Beneficiary';
export const PLATFORMADMIN = 'Platform';

export enum userRoleRouteMapping {
  Petitioner = 'petitioner-landing',
  Attorney = 'attorney-landing',
  Beneficiary = 'beneficiary-landing',
  Platform = 'platform-admin-landing'
}
