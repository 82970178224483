<!-- Edit Mode -->
<div class="container-fluid p-0"  *ngIf="beneSelfDetailsForm">
    <div class="edit-beneficiary-profile">
      <form [formGroup]="beneSelfDetailsForm" #f="ngForm" (ngSubmit)="savePersonalData()">
        <div class="row mt-2">
          <div class="col-3">
            <p class="label-cls">Title</p>
            <p-dropdown [options]="listTitle" id="title" formControlName="title" optionLabel="name" optionValue="code" 
              placeholder="Select Title" appendTo="body"></p-dropdown>
          </div>
          <div class="col-3">
            <p class="label-cls" [class.required]="beneficiaryType==='self'">First Name</p>
            <input type="text" class="im-input" [ngClass]="{
                'im-input-error':
                  (beneSelfDetailsForm.get('fName').touched || submitted) &&
                  beneSelfDetailsForm.get('fName').errors?.required
              }" pattern="^[\D]*$" formControlName="fName" placeholder="Enter your First Name" />
            <span class="invalid-message" *ngIf="
                (beneSelfDetailsForm.get('fName').touched || submitted) &&
                beneSelfDetailsForm.get('fName').errors?.required
              ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
            <span class="invalid-message" *ngIf="
                (beneSelfDetailsForm.get('fName').touched || submitted) &&
                beneSelfDetailsForm.get('fName').errors?.pattern
              ">
              {{ getGlobalErrorMessages("ALPHABETS_SPLCHARS") }}
            </span>
          </div>
          <div class="col-3">
            <p class="label-cls">Middle Name</p>
            <input type="text" class="im-input" formControlName="mName" placeholder="Enter your Middle Name" />
          </div>
          <div class="col-3">
            <p class="label-cls" [class.required]="beneficiaryType==='self'">Last Name</p>
            <input type="text" class="im-input" [ngClass]="{
                'im-input-error':
                  (beneSelfDetailsForm.get('lName').touched || submitted) &&
                  beneSelfDetailsForm.get('lName').errors?.required
              }" pattern="^[\D]*$" formControlName="lName" placeholder="Enter your Last Name" />
            <span class="invalid-message" *ngIf="
                  (beneSelfDetailsForm.get('lName').touched || submitted) &&
                  beneSelfDetailsForm.get('lName').errors?.required
                ">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
            <span class="invalid-message" *ngIf="
                  (beneSelfDetailsForm.get('lName').touched || submitted) &&
                  beneSelfDetailsForm.get('lName').errors?.pattern
                ">
              {{ getGlobalErrorMessages("ALPHABETS_SPLCHARS") }}
            </span>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-12" *ngIf="editDetails">
            <mat-checkbox (change)="toggleAlias($event)" [checked]="showAliasName">
              <span>Do you have any other name?</span>
            </mat-checkbox>
          </div>
          <div class="w-100" *ngIf="showAliasName" formArrayName="aliasName">
            <div formGroupName="0" class="row">
              <div class="col-3 showAliasName">
                <mat-form-field>
                  <p class="label-cls">Title</p>
                  <mat-select formControlName="title">
                    <mat-option *ngFor="let title of listTitle" [value]="title.name">
                      {{ title.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-9">
                <p class="label-cls">Alias Name</p>
                <input type="text" class="im-input mw-100" formControlName="fName" placeholder="Alias Name" />
              </div>
            </div>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-3" formGroupName="gender">
            <p class="label-cls">Gender</p>
            <p-dropdown [options]="listGender" formControlName="id" optionLabel="name" optionValue="id"
              placeholder="Select Gender" appendTo="body"></p-dropdown>
          </div>
          <div class="col-3">
            <p class="label-cls">Date of Birth</p>
            <mat-form-field appearance="outline" class="mt-0 date-field">
              <input formControlName="dob" matInput min="1920-12-31" [max]="currentDate" [matDatepicker]="dob"
                placeholder="MM/DD/YYYY" (click)="dob.open()" />
              <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
              <mat-datepicker #dob></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 p-0">
            <div formArrayName="phoneContacts">
              <ng-container *ngFor="
                  let x of beneSelfDetailsForm.get('phoneContacts')['controls'];
                  index as i
                ">
                <div [formGroupName]="i" class="row row-with-negative-margin">
                  <div class="col-6" formGroupName="countryCode">
                    <p class="label-cls">Country Code</p>
                    <p-dropdown [options]="listCountry" formControlName="countryCode" optionValue="countryCode"
                      optionLabel="countryName" placeholder="Select" appendTo="body"></p-dropdown>
                  </div>
                  <div class="col-6">
                    <p class="label-cls">Mobile No.<small> (10 digits)
                      </small></p>
                    <input type="text" class="im-input col-md" formControlName="phoneNo" minlength="10" maxlength="10"
                      placeholder="Enter" />
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-3" formArrayName="emailContacts">
            <p class="label-cls" [class.required]="beneficiaryType==='self'">Email Id</p>
            <ng-container *ngFor="
                  let x of beneSelfDetailsForm.get('emailContacts')['controls'];
                  index as i
                ">
              <div [formGroupName]="i">
                <input type="text" class="im-input" [ngClass]="{
                      'im-input-error':
                        ((x.get('email').touched || submitted) &&
                          x.get('email').hasError('pattern')) ||
                        ((x.get('email').touched || submitted) &&
                          x.get('email').hasError('required'))
                    }" formControlName="email" placeholder="Enter"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$" />
              </div>
              <span class="invalid-message" *ngIf="
                    (x.get('email').touched || submitted) &&
                    x.get('email').hasError('required')
                  ">
                {{ getGlobalErrorMessages("REQUIRED") }}
              </span>
              <span class="invalid-message" *ngIf="
                    (x.get('email').touched || submitted) &&
                    x.get('email').hasError('pattern')
                  ">
                {{ getGlobalErrorMessages("INVALID_EMAIL") }}
              </span>
            </ng-container>
          </div>
          <div class="col-3">
            <p class="label-cls">Social Security Number</p>
            <div class="ssn-field">
              <input type="text" class="im-input ssn-input" formControlName="ssn"
                [ngClass]="{ 'ssn-input-hide': !ssn_hide }" placeholder="SSN" minlength="9" maxlength="9" />
              <mat-icon matSuffix matTooltip="Show/Hide" (click)="ssn_hide = !ssn_hide">
                {{ ssn_hide ? "visibility" : "visibility_off" }}</mat-icon>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div>
              <p class="label-cls mb-2">Are you a Lawful Permanent Resident?</p>
              <p-radioButton labelStyleClass="profileRadio" formControlName="isLpr" name="isLpr" [value]="1" label="Yes"
                (onClick)="handleIsLprChanges()" inputId="YES">
              </p-radioButton>
              <p-radioButton labelStyleClass="profileRadio" class="ml-2" formControlName="isLpr" name="isLpr" [value]="0"
                (onClick)="handleIsLprChanges()" label="No" inputId="NO">
              </p-radioButton>
            </div>
          </div>
          <div class="col-4" *ngIf="(beneSelfDetailsForm.get('isLpr').value == 1)">
            <p class="label-cls mb-2">Green Card Number <small>(Ex.
                XXX0000000000)</small></p>
            <input type="text" class="im-input" formControlName="greenCardNo" maxlength="13"
              placeholder="Enter your Green Card No." />
          </div>
        </div>
        <div class="row" *ngIf="beneSelfDetailsForm.get('isLpr').value == 1">
          <div class="col-12">
            <div class="p-grid p-my-2">
              <div class="p-col-11" class="label-cls mt-2 pl-2" name="question">
                Did you gain lawful permanent resident status through marriage to the U.S Citizen or Lawful
                Permanent resident?
              </div>
              <div class="p-col-1">
                <p-inputSwitch formControlName="lprThruMarriage" (onChange)="handleLawfulSwitchChange()">
                </p-inputSwitch>
              </div>
              <div class="mt-2 p-col-12">
                <p class="lbl-text-2">Add photocopy of your Greencard</p>
                <file-upload-deafult-library class="w-100" [configurations]="fileUploadConfigurations"
                  [primaryData]="petitionData" (fileUploadSuccess)="fileUploadSuccess()">
                </file-upload-deafult-library>
              </div>
            </div>
          </div>
        </div>
        <!-- File upload -->
        <!-- <div class="row col-md-12 pl-0 pt-4">
          123
    
            <file-upload-library style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations"></file-upload-library>
        </div> -->
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="labelText">Place of Birth</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-4" formGroupName="countryCode">
            <p class="label-cls">Country</p>
            <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
              optionValue="countryCode" (onChange)="resetBirthStateOnCountryChange();
                handleCountryChange($event.value, 'Birth')" placeholder="Select" appendTo="body"></p-dropdown>
          </div>
          <div class="col-4" *ngIf="listStatesBirth.length > 0">
            <p class="label-cls">State</p>
            <p-dropdown [options]="listStatesBirth" formControlName="birthStateProvinceCode"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="Select State" appendTo="body"></p-dropdown>
          </div>
  
          <div class="col-4" *ngIf="listStatesBirth.length <= 0">
            <p class="label-cls">State</p>
            <input class="im-input" *ngIf="isBirthStateRequired" formControlName="birthStateProvinceName" type="text"
              placeholder="State" />
            <input class="im-input" *ngIf="!isBirthStateRequired" formControlName="birthStateProvinceName" type="text"
              placeholder="State" />
          </div>
          <div class="col-4">
            <p class="label-cls">City</p>
            <input class="im-input" formControlName="birthCity" type="text" placeholder="City" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <div formGroupName="ctznshipCountryCode">
              <p class="label-cls">Citizenship</p>
              <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
                optionValue="countryCode" (onChange)="updateCountry($event.value, 'issueCountryCode')"
                placeholder="Select" appendTo="body"></p-dropdown>
            </div>
          </div>
        </div>
        <div *ngIf="showCitizenShipAquiredInfo">
          <div class="row mt-2">
            <div class="col-12">
              <div class="selectionContainer" formGroupName="ctznAcqrTypeId">
                <p class="label-cls mb-2">My Citizenship was acquired through:</p>
                <div *ngFor="let item of citizenshipAcquiredArr" class="col-3 mt-2 p-field-checkbox">
                  <p-radioButton [inputId]="item.id" name="ctznAcqrId" [value]="item.id" formControlName="ctznAcqrId">
                  </p-radioButton>
                  <label [for]="item.id" class="label-cls">{{item.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="p-grid p-my-2 pl-2">
                <div class="p-col-9" class="label-cls mt-2" name="question">
                  Have you obtained certificate of Naturalization or Certificate of Citizenship?
                </div>
                <div class="p-col-1">
                  <p-inputSwitch formControlName="hasCtznCrtfct" (onChange)="handleCitizenshipSwitchChange()">
                  </p-inputSwitch>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="beneSelfDetailsForm.get('hasCtznCrtfct').value == 1">
            <div class="col-4">
              <p class="label-cls">Certificate Number</p>
              <input class="im-input" formControlName="ctznCrtfctNo" type="text" placeholder="Enter" maxlength="8" />
            </div>
            <div class="col-4">
              <p class="label-cls">Place of Issuance</p>
              <input class="im-input" formControlName="ctznCrtfctIssPlace" type="text" placeholder="Enter" />
            </div>
            <div class="col-4">
              <p class="label-cls">Date of Issuance</p>
              <mat-form-field appearance="outline" class="mt-0 date-field">
                <input formControlName="ctznCrtfctIssDate" matInput [max]="currentDate" min="1920-12-12"
                  [matDatepicker]="ctznCrtfctIssDate" placeholder="MM/DD/YYYY"
                  (click)="ctznCrtfctIssDate.open()" />
                <mat-datepicker-toggle matSuffix [for]="ctznCrtfctIssDate"></mat-datepicker-toggle>
                <mat-datepicker #ctznCrtfctIssDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="showMarriageSection">
          <div class="row mt-4">
            <div class="col-12">
              <h5 class="labelText">Marital Status</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 selectionContainer" formGroupName="maritalStatus">
              <h5 class="label-cls">What is your current marital status?</h5>
              <div *ngFor="let item of maritalStatusArr" class="col-3 mt-2 p-field-checkbox">
                <p-radioButton [inputId]="item.id" name="id" [value]="item.id" formControlName="id"></p-radioButton>
                <label [for]="item.id" class="label-cls">{{item.name}}</label>
              </div>
            </div>
          </div>
          <div
            *ngIf="beneSelfDetailsForm.get('maritalStatus').get('id').value && beneSelfDetailsForm.get('maritalStatus').get('id').value != 10">
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">Date of Marriage</p>
                <mat-form-field appearance="outline" class="mt-0 date-field">
                  <input formControlName="marriageDate" matInput [max]="currentDate" min="1920-12-12"
                    [matDatepicker]="marriageDate" placeholder="MM/DD/YYYY" (click)="marriageDate.open()" />
                  <mat-datepicker-toggle matSuffix [for]="marriageDate"></mat-datepicker-toggle>
                  <mat-datepicker #marriageDate></mat-datepicker>
                </mat-form-field>
                <span class="invalid-message" *ngIf="
            (submitted ||
              beneSelfDetailsForm
                .get('marriageDate')
                .touched) &&
            beneSelfDetailsForm
              .get('marriageDate')
             .errors?.required
          ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
  
              </div>
            </div>
  
            <div class="row mt-4">
              <div class="col-12">
                <h6 class="labelSubText">Place of Marriage</h6>
              </div>
            </div>
  
            <div class="row">
              <div class="col-4" formGroupName="countryOfMarriage">
                <p class="label-cls">Country of Marriage</p>
                <p-dropdown [options]="listCountryMarriage" formControlName="countryCode" optionLabel="countryName"
                  optionValue="countryCode" (onChange)="handleCountryChange($event.value, 'Marriage')"
                  placeholder="Select" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="(listStatesMarriage.length > 0)">
                <p class="label-cls">State of Marriage</p>
                <p-dropdown [options]="listStatesMarriage" formControlName="marriageStateProvinceCode"
                  optionLabel="stateProvinceName" optionValue="stateProvinceCode"
                  (onChange)="handleStateChange($event.value, 1)" placeholder="Select State" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="(listStatesMarriage.length <= 0)">
                <p class="label-cls">State of Marriage</p>
                <input class="im-input" formControlName="marriageStateProvinceName" type="text" placeholder="State" />
              </div>
              <div class="col-4">
                <p class="label-cls">City of Marriage</p>
                <input class="im-input" formControlName="cityOfMarriage" type="text" placeholder="City" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="labelText">Physical Address Details</h5>
          </div>
        </div>
        <!-- Current Address -->
        <div formArrayName="address">
          <div [formGroupName]="getAddressGroup('CURR')">
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 1</p>
                <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 2 <i
                    pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                    class="pi pi-info-circle"></i></p>
                <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">Area</p>
                <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
              </div>
              <div class="col-4" formGroupName="countryCode">
                <p class="label-cls">Country</p>
                <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
                  optionValue="countryCode" (onChange)="
                handleCountryChange($event.value, 'Current');
                resetStateOnCountryChange(0)
              " placeholder="Select" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesCurrent.length > 0">
                <p class="label-cls">State</p>
                <p-dropdown [options]="listStatesCurrent" formControlName="stateProvinceCode"
                  optionLabel="stateProvinceName" optionValue="stateProvinceCode"
                  (onChange)="handleStateChange($event.value, 0)" placeholder="Select State" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesCurrent.length <= 0">
                <p class="label-cls">State</p>
                <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">City</p>
                <input class="im-input" formControlName="city" type="text" placeholder="City" />
              </div>
              <div class="col-4">
                <p class="label-cls">Zip / Postal Code</p>
                <input class="im-input" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <h5 class="labelText">Mailing Address Details</h5>
          </div>
          <div class="col-4 pt-1">
            <p class="label-cls ddLabel">Copy From</p>
            <p-dropdown [options]="listCopyMailAddress" optionLabel="name"
                optionValue="code" (onChange)="copyAddress($event.value, 'Mailing')" placeholder="Select" appendTo="body"></p-dropdown>
          </div>
        </div>
        <!-- Mailing Address -->
        <div formArrayName="address">
          <div [formGroupName]="getAddressGroup('MAIL')">
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 1</p>
                <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 2 <i
                    pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                    class="pi pi-info-circle"></i></p>
                <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">Area</p>
                <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
              </div>
              <div class="col-4" formGroupName="countryCode">
                <p class="label-cls">Country</p>
                <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
                  optionValue="countryCode" (onChange)="
                handleCountryChange($event.value, 'Mailing');
                resetStateOnCountryChange(1)
              " placeholder="Select" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesMailing.length > 0">
                <p class="label-cls">State</p>
                <p-dropdown [options]="listStatesMailing" formControlName="stateProvinceCode"
                  optionLabel="stateProvinceName" optionValue="stateProvinceCode"
                  (onChange)="handleStateChange($event.value, 1)" placeholder="Select State" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesMailing.length <= 0">
                <p class="label-cls">State</p>
                <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">City</p>
                <input class="im-input" formControlName="city" type="text" placeholder="City" />
              </div>
              <div class="col-4">
                <p class="label-cls">Zip / Postal Code</p>
                <input class="im-input" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <h5 class="labelText">Foreign Address Details</h5>
          </div>
          <div class="col-4 pt-1">
            <p class="label-cls ddLabel">Copy From</p>
            <p-dropdown [options]="listCopyFornAddress" optionLabel="name"
              optionValue="code" (onChange)="copyAddress($event.value, 'Foreign')" placeholder="Select"  appendTo="body"></p-dropdown>
          </div>
          <div class="col-md-12 mb-2">
            <label class="mb-0 label-cls">
              <b>Note</b> : <span> For applicants whose current address is outside the US. <i
                  pTooltip='If you are applying from your country of origin (i.e., outside the US), enter your "Non-US Address" details under "Foreign Address" also.'
                  class="pi pi-info-circle" style="color: #01a0da;"></i></span>
            </label>
          </div>
        </div>
        <div formArrayName="address">
          <div [formGroupName]="getAddressGroup('FORN')">
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">
                  Address 1<span class="required" *ngIf="showForienAddressMandatory"></span>
                </p>
                <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 2 <i
                    pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                    class="pi pi-info-circle"></i></p>
                <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">Area</p>
                <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
              </div>
              <div class="col-4" formGroupName="countryCode">
                <p class="label-cls">Country</p>
                <p-dropdown [options]="listCountryForeignAdd" formControlName="countryCode" optionLabel="countryName"
                  optionValue="countryCode" (onChange)="
                handleCountryChange($event.value, 'Foreign');
                resetStateOnCountryChange(2)
              " placeholder="Select" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesForeign.length > 0">
                <p class="label-cls">
                  State
                </p>
                <p-dropdown [options]="listStatesForeign" formControlName="stateProvinceCode"
                  optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="Select State" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesForeign.length <= 0">
                <p class="label-cls">State</p>
                <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">
                  City<span class="required" *ngIf="showForienAddressMandatory"></span>
                </p>
                <input class="im-input" formControlName="city" type="text" placeholder="City" />
              </div>
              <div class="col-4">
                <p class="label-cls">Zip / Postal Code</p>
                <input class="im-input" formControlName="postCode" type="text" maxLength="10" placeholder="Zip Code" />
              </div>
            </div>
          </div>
        </div>
        
        <div class="row mt-4">
          <div class="col-4">
            <h5 class="labelText">Permanent Address Details</h5>
          </div>
          <div class="col-4 pt-1">
            <p class="label-cls ddLabel">Copy From</p>
            <p-dropdown [options]="listCopyPermAddress" optionLabel="name"
              optionValue="code" (onChange)="copyAddress($event.value, 'Permanent')" placeholder="Select" appendTo="body"></p-dropdown>
          </div>
        </div>
        <div formArrayName="address">
          <div [formGroupName]="getAddressGroup('PERM')">
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 1</p>
                <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-8">
                <p class="label-cls">Address 2 <i
                    pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                    class="pi pi-info-circle"></i></p>
                <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2"
                  maxLength="150" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">Area</p>
                <input class="im-input" formControlName="locality" type="text" placeholder="Locality" />
              </div>
              <div class="col-4" formGroupName="countryCode">
                <p class="label-cls">Country</p>
                <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
                  optionValue="countryCode" (onChange)="
                handleCountryChange($event.value, 'Permanent');
                resetStateOnCountryChange(3)
              " placeholder="Select" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesPermanent.length > 0">
                <p class="label-cls">State</p>
                <p-dropdown [options]="listStatesPermanent" formControlName="stateProvinceCode"
                  optionLabel="stateProvinceName" optionValue="stateProvinceCode"
                  (onChange)="handleStateChange($event.value, 3)" placeholder="Select State" appendTo="body"></p-dropdown>
              </div>
              <div class="col-4" *ngIf="listStatesPermanent.length <= 0">
                <p class="label-cls">State</p>
                <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <p class="label-cls">City</p>
                <input class="im-input" formControlName="city" type="text" placeholder="City" />
              </div>
              <div class="col-4">
                <p class="label-cls">Zip / Postal Code</p>
                <input class="im-input" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="labelText">Biographic Information</h5>
          </div>
        </div>
  
        <div class="row mt-2">
          <div class="col-12">
            <div class="selectionContainer" formGroupName="ethnicity">
              <p class="label-cls mb-2">Ethnicity</p>
              <div *ngFor="let ethinItem of ethinicityArr" class="col-3 mt-2 p-0 p-field-checkbox">
                <p-radioButton [inputId]="ethinItem.id" name="ethnicityId" [value]="ethinItem.id"
                  formControlName="ethnicityId"></p-radioButton>
                <label [for]="ethinItem.id" class="label-cls">{{ethinItem.name}}</label>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="race" >
          <p class="label-cls pl-3">Race</p>
          <ng-container *ngFor="let control of raceArray?.controls; let i = index;">
            <div>
              <div class="row mt-2" style="display: block;">
                <div class="col-4" style="display: inline-block;">
                  <p-checkbox [label]="raceArr[i]?.name" [formControl]="control" binary="true"></p-checkbox>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
  
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Hair Color</p>
            <p-dropdown [options]="hairColors" formControlName="hairColor" optionLabel="name" optionValue="code"
              placeholder="Select" appendTo="body"></p-dropdown>
          </div>
          <div class="col-4">
            <p class="label-cls">Eyes Color</p>
            <p-dropdown [options]="eyeColors" formControlName="eyeColor" optionLabel="name" optionValue="code"
              placeholder="Select" appendTo="body"></p-dropdown>
          </div>
          <div class="col-4">
            <p class="label-cls">Weight(in pounds)</p>
            <input type="text" class="im-input" formControlName="weight" placeholder="Enter" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Height(inches)</p>
            <input type="text" class="im-input" formControlName="height" placeholder="Enter" />
          </div>
          <div class="col-8">
            <p class="label-cls">Marks of identification</p>
            <input type="text" class="im-input mw-100" formControlName="identificationMark" placeholder="Enter" />
          </div>
        </div>
  
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="labelText">Passport Details</h5>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 has-passport-outer">
            <p-checkbox label="Ignore passport details" (onChange)="updatePassportFormControl($event)"
              formControlName="hasPassport" binary="true"></p-checkbox>
          </div>
        </div>
        <ng-container *ngIf="!beneSelfDetailsForm.get('hasPassport').value">
          <div formArrayName="passport">
            <ng-container *ngFor="
                let x of beneSelfDetailsForm.get('passport')['controls'];
                index as i
              ">
              <div [formGroupName]="i">
                <div class="row mt-2">
                  <div class="col-8">
                    <p class="label-cls">Passport No.</p>
                    <input type="text" class="im-input mw-100" formControlName="passportNo" placeholder="Passport #"
                      maxlength="9" />
                  </div>
                  <div class="col-4" formGroupName="issueCountryCode">
                    <p class="label-cls">Issuing Authority</p>
                    <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
                      optionValue="countryCode" placeholder="Select" appendTo="body"></p-dropdown>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-4">
                    <p class="label-cls">Date of Issue</p>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input formControlName="issueDate" matInput [max]="currentDate" min="1920-12-12"
                        [matDatepicker]="issueDate" placeholder="MM/DD/YYYY" (click)="issueDate.open()" />
                      <mat-datepicker-toggle matSuffix [for]="issueDate"></mat-datepicker-toggle>
                      <mat-datepicker #issueDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <p class="label-cls">Date of Expiry</p>
                    <mat-form-field appearance="outline" class="mt-0 date-field">
                      <input placeholder="MM/DD/YYYY" [matDatepicker]="expiryDate" matInput formControlName="expiryDate"
                        max="9999-12-31" [min]="
                          beneSelfDetailsForm.controls['passport']?.value[0]?.issueDate" (change)="validateExpiry(
                            beneSelfDetailsForm.controls['passport']?.value[0]?.issueDate,
                            beneSelfDetailsForm.controls['passport']?.value[0]?.expiryDate)"
                        (click)="expiryDate.open()" />
                      <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                      <mat-datepicker #expiryDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="row mt-4 mb-4">
          <div class="col-12">
            <div class="position-relative">
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
  
        <div class="row paddingTop10 mb-2" *ngIf="beneficiaryData?.id > 0">
          <div class="col-4 linehgt10">
            <label><b>Signature </b></label>
            <p><button type="button" (click)="uploadSignatureImage()" class="signaturePromptBtn btn btn-primary btn-lg"
                title=".png only">
                <span *ngIf='!signatureImage' class="paddingLeft10  paddingRight10">Upload Signature</span>
                <span *ngIf='signatureImage'>Change Signature</span>
              </button>
            </p>
          </div>
          <div class="col-8 linehgt10">
            <img *ngIf='!!signatureImage' alt="Signature" class="signatureImageBox" [src]="signatureImage">
          </div>
        </div>
  
        <div class="row">
          <div class="col-12 text-right">
            <button class="back-button d-inline-flex align-items-center pr-3 pl-3 mr-3" (click)="backToTask()">
              <i class="fa fa-chevron-circle-left pr-1" aria-hidden="true"></i>
              Back
            </button>
            <!-- <lib-save-as-draft *ngIf="detailsAbout.showDetailFor === 'self'" class="mr-3"
                (onClickSaveAsDraft)="saveAsDraft($event)" [showSaveIcon]="true"
                [formToBeSaved]="beneSelfDetailsForm"></lib-save-as-draft> -->
            <button type="submit" class="btn btn-success button-width-icon-left mr-3"
              [disabled]="beneSelfDetailsForm.invalid" [ngClass]="{'invalidBtn': beneSelfDetailsForm.invalid }">
              <i class="fa fa-floppy-o"></i>
              Save
            </button>
            <!-- <button  type="button" class="back-button button-width-icon-left"
                (click)="clearProfileData(f)">
                <i class="fa fa-times-circle"></i>
                Cancel
              </button> -->
          </div>
        </div>
      </form>
      <div class="row">
        <mat-card *ngIf="!beneSelfDetailsForm.get('hasPassport').value"> 
          <div class="mt-2 mb-2 mr-2 ml-2 row">
            <p class="lbl-text-2">Add passport photocopy</p>
            <file-upload-deafult-library class="w-100" [configurations]="fileUploadPassConfigurations" [primaryData]="petitionData" (fileUploadSuccess)="fileUploadSuccess()"></file-upload-deafult-library>
          </div> 
        </mat-card>
      </div>
    </div>
    <!-- <div class="add-placeholder" *ngIf="showAddIcon">
      <button mat-fab color="primary" class="btn" (click)="toggleEditView(true)" [disabled]="isDisabled">
        <mat-icon matTooltip="Create Profile">add_circle_outline</mat-icon>
      </button>
      <h5 class="label-dark mt-3">Create {{beneficiaryData?.relationShipType?.name}} Profile</h5>
    </div> -->
  </div>