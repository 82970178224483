
<div class="parent-container" *ngIf="isLoggedIn && displayMenu && !isFBUser">
<p-sidebar  [(visible)]="display" position="right" showCloseIcon="false" (mouseleave)="display = false">
  <p-button type="button" label="Guided Tour" iconPos="left" class="d-block" (click)="display = false;displayStartTourDialog()"></p-button>
  <p-button type="button" label="Play Intro Videos" iconPos="left" class="d-block" (click)="display = false;displayIntroVideo()">
    <i class="fa fa-play-circle" aria-hidden="true"></i>
  </p-button>
  <p-button type="button" label="Videos Help" iconPos="left" class="d-block" (click)="display = false;routeToResourcePage()">
    <i class="fa fa-question-circle" aria-hidden="true"></i>
  </p-button>
</p-sidebar>
<p-button class="help-button" type="text" (mouseover)="display = true">
  <i class="fa fa-question-circle" aria-hidden="true"></i>
</p-button>
</div>