export enum layout_code {
  POSITION_DETAIL = 'POSITION_DETAIL',
  BEN_POSITION_DETAIL = 'BEN_POSITION_DETAIL',
  PAST_POSITION_DETAIL = 'PAST_POSITION_DETAIL',
  BEN_PAST_POSITION_DETAIL = 'BEN_PAST_POSITION_DETAIL',
  PERSONAL_DETAIL = 'PERSONAL_DETAIL',
  BEN_PERSONAL_DETAIL = 'BEN_PERSONAL_DETAIL',
  EDUCATION_DETAIL = 'EDUCATION_DETAIL',
  BEN_EDUCATION_DETAIL = 'BEN_EDUCATION_DETAIL',
  EXPERIENCE_DETAIL = 'EXPERIENCE_DETAIL',
  BEN_EXPERIENCE_DETAIL = 'BEN_EXPERIENCE_DETAIL',
  IMM_OTH_DOCS = 'IMM_OTH_DOCS',
  BEN_IMM_OTH_DOCS = 'BEN_IMM_OTH_DOCS',
  BEN_DEPENDENT_INFO = 'BEN_DEPENDENT_INFO',
  DEPENDENT_INFO = 'DEPENDENT_INFO',
  PETITIONER_DOCS = 'PETITIONER_DOCS',
  USCIS_CONTENT = 'USCIS_CONTENT',
  FILL_FORM = 'FILL_FORM',
  REVIEW_PETITION = 'REVIEW_PETITION',
  PRINT_PETITION = 'PRINT_PETITION',
  SUBMIT_FEES = 'SUBMIT_FEES',
  GET_RECEIPT = 'GET_RECEIPT',
  BENEFICIARY_DOCUMENTS = 'BENEFICIARY_DOCUMENTS',
  UPDATE_DECISION = 'UPDATE_DECISION',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  PROVIDE_BEN_DOCS = 'PROVIDE_BEN_DOCS',
  BEN_PROVIDE_BEN_DOCS = 'BEN_PROVIDE_BEN_DOCS',
  PETITIONER_DOCS_FORGN = 'PETITIONER_DOCS_FORGN',
  ENT_REL_DETAIL = 'ENT_REL_DETAIL',
  BEN_ENT_REL_DETAIL = 'BEN_ENT_REL_DETAIL',
  REGISTER_USCIS = 'REGISTER_USCIS',
  AWAIT_LOTTERY = 'AWAIT_LOTTERY',
  OCCUPATION_DETAIL = 'OCCUPATION_DETAIL',
  BEN_OCCUPATION_DETAIL = 'BEN_OCCUPATION_DETAIL',
  H1_B_POSITION_DETAIL = 'POSITION_DETAIL',
  H1B_POSITION_DETAIL = 'H1_B_POSITION_DETAIL',
  PETITIONER_DOCS_H1B = 'PETITIONER_DOCS',
  IOS_DETAIL = 'IOS_DETAIL',
  BEN_IOS_DETAIL = 'BEN_IOS_DETAIL',
  LCA = 'APPLY_LCA',
  EMPR_EMP_REL_DETAIL = 'EMPR_EMP_REL_DETAIL',
  UPDATE_EMPLOYMENT = 'UPDATE_EMPLOYMENT',
  BEN_EMPR_EMP_REL_DETAIL = 'BEN_EMPR_EMP_REL_DETAIL',
  EXTR_ABL_DETAIL = 'EXTR_ABL_DETAIL',
  BEN_EXTR_ABL_DETAIL = 'BEN_EXTR_ABL_DETAIL',
  PETITIONER_DOCS_US = 'PETITIONER_DOCS_US',
  O_POSITION_DETAIL = 'O_POSITION_DETAIL',
  BEN_O_POSITION_DETAIL = 'BEN_O_POSITION_DETAIL',
  TN_POSITION_DETAIL = 'TN_POSITION_DETAIL',
  EB_POSITION_DETAIL = 'EB_POSITION_DETAIL',
  E_POSITION_DETAIL = 'E_POSITION_DETAIL',
  O_PETITIONER_DOCS = 'O_PETITIONER_DOCS',
  BEN_O_PETITIONER_DOCS = 'BEN_O_PETITIONER_DOCS',
  BEN_H1_B_POSITION_DETAIL = 'BEN_H1_B_POSITION_DETAIL',
  DEPENDENT_PERSONAL_DETAIL = 'PERSONAL_DETAIL',
  PROC_INFO = 'PROC_INFO',
  BEN_PROC_INFO = 'BEN_PROC_INFO',
  BENEFICIARY_DETAIL = 'BENEFICIARY_DETAIL',
  BEN_BENEFICIARY_DETAIL = 'BEN_BENEFICIARY_DETAIL',
  PRIMARY_APPLICANT_DETAILS = 'PRIMARY_APPLICANT_DETAILS',
  SECONDARY_APPLICANT_DETAILS = 'SECONDARY_APPLICANT_DETAILS',
  BEN_PRIMARY_APPLICANT_DETAILS = 'BEN_PRIMARY_APPLICANT_DETAILS',
  BEN_SECONDARY_APPLICANT_DETAILS = 'BEN_SECONDARY_APPLICANT_DETAILS',
  BEN_USCIS_CONTENT = 'BEN_USCIS_CONTENT',
  BEN_FILL_FORM = 'BEN_FILL_FORM',
  BEN_REVIEW_PETITION = 'BEN_REVIEW_PETITION',
  BEN_PRINT_PETITION = 'BEN_PRINT_PETITION',
  BEN_SUBMIT_FEES = 'BEN_SUBMIT_FEES',
  BEN_GET_RECEIPT = 'BEN_GET_RECEIPT',
  SPOUSE_DETAIL = 'SPOUSE_DETAIL',
  CHILD_DETAIL = 'CHILD_DETAIL',
  BEN_SPOUSE_DETAIL = 'BEN_SPOUSE_DETAIL',
  BEN_CHILD_DETAIL = 'BEN_CHILD_DETAIL',
  BEN_PETITIONER_DOCS = 'BEN_PETITIONER_DOCS',
  BEN_EDU_DETAIL = 'BEN_EDU_DETAIL',
  BEN_EXP_DETAIL = 'BEN_EXP_DETAIL',
  UPDATE_DETERMINATION = 'UPDATE_DETERMINATION',
  LCA_JOB_DETAIL = "LCA_JOB_DETAIL",
  BEN_LCA_JOB_DETAIL = "BEN_LCA_JOB_DETAIL",
  INDIVIDUAL_DETAIL = "INDIVIDUAL_DETAIL",
  BEN_INDIVIDUAL_DETAIL = "BEN_INDIVIDUAL_DETAIL",
  BEN_EFILING="BEN_EFILING",
  EFILING="EFILING"
}

export enum GC_I140_Layout {
  PROCESSING_INFORMATION = 'Enter Processing Information',
  JOB_DESCRIPTION = 'Enter Job Description',
  Past_JOB_DESCRIPTION = 'Enter Past Job Description (Foreign Company)',
  BENIFICARY_PROFILE_DETAILS = 'Enter Beneficiary Personal Details',
  EDU_DETAIL = 'Enter Beneficiary Education Details',
  BEN_EDU_DETAIL = 'Enter Beneficiary Education Details',
  BENIFICARY_EXPERIENCE_DETAILS = 'Enter Beneficiary Experience Details',
  BEN_EXP_DETAIL = 'Enter Beneficiary Experience Details',
  BEN_DEPENDENT_INFO = 'Enter Beneficiary Dependent Details',
  DEPENDENT_INFO = 'Enter Beneficiary Dependent Details',
  PET_DOC_AND_DETAILS = 'Enter Petitioner Details & Documents',
  USCIS_CONTENT = 'USCIS Content',
  LETTER_GEN = 'Letter Generation',
  REVIEW_I140_PET = 'Review I-140 Petition',
  PRINT_I140_PET = 'Print I-140 Petition',
  SUB_I140_PET = 'Submit I-140 Petition',
  GET_RECEIPT = 'Get Receipts',
  BENEFICIARY_DOCUMENTS = 'Provide Beneficiary Documents',
  UPDATE_DECISION = 'Update Decision',
  SEND_NOTIFICATION = 'Send Notification',
  PROVIDE_BEN_DOCS = 'Provide Beneficiary Documents',
  BEN_PROVIDE_BEN_DOCS = 'Provide Beneficiary Documents',
  LCA_JOB_DETAIL = "LCA_JOB_DETAIL"
}
