import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateStepStatus, updateStepStatusToInitialState } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { PrimaryData, TaskStep } from 'app-models';
// import { FroalaEditorOptions } from 'editor-library';
import { H1BEERPayload, ProvideEerService } from '../provide-eer/provide-eer.service';
import { mergeMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy, OnChanges {
  //@ViewChild("projectdetailskendoeditor", { static: false }) projectdetailskendoeditor: any;
  @ViewChild("projectdetailsimageupload") public projectdetailsimageupload: UploadImageDialogComponent;
@Output() @ViewChild("projectdetailskendoeditor") public projectdetailskendoeditor: EditorComponent;
  projectDetailsForm: FormGroup;
  // public options: any = FroalaEditorOptions.EditorOptions;
  disabledControls: boolean;
  disabledProjectName: boolean;
  typeOfButton: string; // This is required for status update button
  observableSubscription$ = new Subject();
  @Input() stepDetails: TaskStep;
  @Input() primaryData: PrimaryData;
  @Input() payload: H1BEERPayload;
  @Input() showProjectName = false;
  @Input() editorName: string;
  @Input() editorSubName: string;
  @Input() tabIndex: number;
  @Input() editorLabel: string;
  @Input() projectList: any[] = [];
  @Input()
  simplifyH1b;
  wizardTID:string='';

  isSimplifyH1b: boolean;
  constructor(
    private store: Store<any>,
    private stepStatusUpdatePayloadConstruction: StepStatusUpdatePayloadService,
    private provideEerService: ProvideEerService
  ) {
    this.disabledControls = false;
    this.disabledProjectName = false;
  }

  ngOnInit(): void {
    // combineLatest([
    //   this.store.pipe(select(getStepStatusUpdate)),
    //   this.store.pipe(select(getVisaTypeCode)),
    // ])
    //   .pipe(takeUntil(this.observableSubscription$)).subscribe((response: any[]) => {
    //     if (response[0] && response[0].status === 200 && response[1]) {
    //       this.toastr.success(response[0].message);
    //       this.store.dispatch(loadSubTaskDetails({
    //         taskId: this.stepDetails.taskId,
    //         visaTypeCode: response[1]
    //       }));
    //     }
    //   });
    // this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((data: TaskStep) => {
    //     this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
    //     this.stepDetails = data;
    //     this.getProjectDetails();
    //     this.toggleControlsStatus();
    //   });
    this.isSimplifyH1b = this.simplifyH1b; 
    this.projectDetailsForm.valueChanges.pipe(
      map(() => this.projectDetailsForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if(this.isSimplifyH1b) { return } 
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.wizardTID =sessionStorage.getItem('wizardTID');
    if(this.wizardTID){
      this.toggleControlsStatus();
    }
    else {
      if (changes?.stepDetails || changes?.tabIndex){
        this.typeOfButton =
        this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ?
          'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.toggleControlsStatus();
      }
    }
    
    if (changes.payload || changes.tabIndex) {
      this.setEditorDetails();
      this.disabledProjectName = this.projectDetailsForm.value.editorTitle === null ? false : true;
    }

    // if (this.projectDetailsForm) {
    //   // work around for primeng radio button
    //   this.projectDetailsForm.get('selectedRadioValue').valueChanges.subscribe(e => {
    //     this.projectDetailsForm.get('selectedRadioValue').setValue(e, { emitEvent: false });
    //   });
    // }
  }

  projectdetailsimageuploaddialog() {    
    this.projectdetailsimageupload.open();
  }

  setEditorDetails() {
    let editorValue: string;
    switch (this.tabIndex) {
      case 1:
        editorValue = this.payload.projectDetails;
        break;
      case 2:
        editorValue = this.payload.projectMilestones;
        break;
      case 3:
        editorValue = this.payload.projectStaffing;
        break;
      case 4:
        editorValue = this.payload.additionDetails;
        break;
    }
    if (this.showProjectName) {
      this.projectDetailsForm.reset({
        editorTitle: this.payload.projectName,
        editorValue, selectedRadioValue: 0,
      });
    } else {
      this.projectDetailsForm.reset({
        editorValue, selectedRadioValue: 0,
      });
    }
  }

  postProjectDetails() {
    let editorValue: string;
    let editorMasterLabel: string;
    let projectName = this.payload ? this.payload.projectName : null;
    const projectId = this.payload && this.payload.projectId ? this.payload.projectId : null;
    switch (this.tabIndex) {
      case 1:
        editorValue = 'projectDetails';
        editorMasterLabel = 'projectDescription';
        break;
      case 2:
        editorValue = 'projectMilestones';
        editorMasterLabel = 'projectMilestoneDescription';
        break;
      case 3:
        editorValue = 'projectStaffing';
        editorMasterLabel = 'projectStaffingDescription';
        break;
      case 4:
        editorValue = 'additionDetails';
        editorMasterLabel = 'additionalDetailsDescription';
        break;
    }
    if (this.showProjectName) {
      projectName = this.projectDetailsForm.value.editorTitle;
    }

    this.provideEerService.getSupervisorDetails(this.primaryData.visatype,
      this.primaryData.petitionType, this.primaryData.petitionId,
      this.primaryData.superTaskId, this.primaryData.subTaskId)
      .pipe(
        take(1),
        mergeMap(response => {
          if (response) {
            const { clientDetails, vendorDetails, worklocationName, worklocationType, ...payload } = {
              ...response,
              clientDetails: response.clientDetails,
              vendorDetails: response.vendorDetails,
              worklocationName: response.worklocationName,
              worklocationType: response.worklocationType,
              projectId,
              projectName,
              [editorValue]: this.projectdetailskendoeditor.value
              //[editorValue]: this.projectDetailsForm.value.editorValue
            };
            this.payload = Object.assign({}, payload);
            return this.provideEerService.postSupervisorDetails(this.primaryData.visatype,
              this.primaryData.petitionType, this.primaryData.petitionId,
              this.primaryData.superTaskId, this.primaryData.subTaskId, this.payload);
          } else {
            return EMPTY;
          }
        }))
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
          if(!this.wizardTID){
            if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
              this.updateStepStatus('INPROGRESS');
            }
          }
          if (this.projectDetailsForm.controls['selectedRadioValue'].value === 1) {
            this.saveMasterData(projectId, editorMasterLabel, this.projectDetailsForm.value.editorValue);
          } else {
            this.provideEerService.getSupervisorDetailsSubject.next();
          }
        }
      });
  }

  saveMasterData(projectId, editorLabel, editorValue) {
    let payload;
    if (projectId && this.projectList && this.projectList.length > 0) {
      const selectedProject = this.projectList.find(p => p.id === projectId);
      payload = {
        additionalDetailsDescription: selectedProject && selectedProject.additionalDetailsDescription ?
          selectedProject.additionalDetailsDescription : null,
        id: selectedProject && selectedProject.id ? selectedProject.id : 0,
        projectDescription: selectedProject && selectedProject.projectDescription ? selectedProject.projectDescription : null,
        projectMilestoneDescription: selectedProject && selectedProject.projectMilestoneDescription ?
          selectedProject.projectMilestoneDescription : null,
        projectName: selectedProject && selectedProject.projectName !== 'Other' ? selectedProject.projectName : null,
        projectStaffingDescription: selectedProject && selectedProject.projectStaffingDescription ?
          selectedProject.projectStaffingDescription : null,
        requestFlag: 'updatemasterdata',
        [editorLabel]: editorValue
      };
    } else {
      payload = {
        additionalDetailsDescription: this.payload.additionDetails ? this.payload.additionDetails : null,
        id: this.payload.projectId ? this.payload.projectId : 0,
        projectDescription: this.payload.projectDetails ? this.payload.projectDetails : null,
        projectMilestoneDescription: this.payload.projectMilestones ? this.payload.projectMilestones : null,
        projectName: this.payload.projectName,
        projectStaffingDescription: this.payload.projectStaffing ? this.payload.projectStaffing : null,
        requestFlag: 'updatemasterdata'
      };
    }
    // switch (this.tabIndex) {
    //   case 1:
    //     payload.projectDescription=this.payload.projectDetails;
    //     break;
    //   case 2:
    //     payload.projectMilestoneDescription=this.payload.projectMilestones;
    //     break;
    //   case 3:
    //     payload.projectStaffingDescription=this.payload.projectStaffing;
    //     break;
    //   case 4:
    //     payload.additionalDetailsDescription=this.payload.additionDetails;
    //     break;
    // }
    this.provideEerService.saveMasterData(this.primaryData.companyId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any) => {
        if (response) {
          this.provideEerService.onMasterDataSave.next(response.id);
        }
      });
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    if(!this.wizardTID){
      this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    }
    if (!this.projectDetailsForm) {
      this.projectDetailsForm = new FormGroup({
        editorValue: new FormControl(null, Validators.required),
        selectedRadioValue: new FormControl(0, Validators.required)
      });
    }
    if (this.showProjectName) {
      if (!this.projectDetailsForm.get('editorTitle')) {
        this.projectDetailsForm.addControl('editorTitle', new FormControl(null, Validators.required));
      }
    } else {
      if (this.projectDetailsForm.get('editorTitle')) {
        this.projectDetailsForm.removeControl('editorTitle');
      }
    }
    if (this.disabledControls) {
      this.projectDetailsForm.disable();
    } else {
      this.projectDetailsForm.enable();
    }
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadConstruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
