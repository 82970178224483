<div class="row customQuestionPortion" *ngIf="isViewQuestions">
    <div class="col-md-12 p-0" *ngIf="!isNewPortionVisible">
        <h5 class="labelText" *ngIf="questionStatus">{{isNewPortionVisible ? listOfQuestions[0]?.name : 'Additional Details'}}</h5>
    </div>
    <div class="col-md-12 customQuestions" *ngFor="let sdata of listOfQuestions; let s=index;">
        <div class="row">
            <div class="col-md-12 p-0" *ngFor="let item of sdata?.questionList; let i=index;">
                <div class="row">
                    <div class="col-md-3 p-0" *ngIf="item.answerTypeId === 'DATE'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <p class="label-light m-0">{{item.questionLabel}}</p>
                        <label class="label-dark wrap-word" *ngIf="item.answers">{{item.answers | date}}</label>
                        <label class="label-dark wrap-word" *ngIf="!item.answers">--</label>
                    </div> 
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'LONGANS'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <p class="label-light m-0">{{item.questionLabel}}</p>
                        <label class="label-dark wrap-word">{{item.answers ? item.answers : "--"}}</label>
                    </div>
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'SHORTANS'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <p class="label-light m-0">{{item.questionLabel}}</p>
                        <label class="label-dark wrap-word">{{item.answers ? item.answers : "--"}}</label>
                    </div> 
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'MULTISELECT'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <p class="label-light m-0">{{item.questionLabel}}</p>
                        <div *ngIf="item.multiSelectAnswers; else otherContent">
                            <label class="label-dark wrap-word" *ngFor="let opt of item.multiSelectAnswers;let j=index;">{{opt}}<span *ngIf="(item.multiSelectAnswers.length-1) !== j"> ,</span></label>
                        </div>
                        <ng-template #otherContent>
                            <label class="label-dark wrap-word">--</label>
                        </ng-template>
                    </div>
                </div>
            </div> 
        </div>
    </div> 
</div>

<div class="row customQuestionPortion" *ngIf="!isViewQuestions">
    <div class="col-md-12" *ngIf="!isNewPortionVisible">
        <h5 class="labelText" *ngIf="questionStatus">{{isNewPortionVisible ? listOfQuestions[0]?.name : 'Additional Details'}}</h5>
    </div>
    <div *ngFor="let sdata of listOfQuestions; let s=index;" 
    [ngClass]="(sdata?.isBenReadable || sdata?.isReadOnly) ? 'disableCls col-md-12 p-0 customQuestions' : 'col-md-12 p-0 customQuestions'">
        <div class="row">
            <div class="col-md-12" *ngFor="let item of sdata?.questionList; let i=index;">
                <div class="row">
                    <div class="col-md-12 mb-2 p-0" *ngIf="item.answerTypeId === 'DATE'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <label class="label-cls customWidth">{{item.questionLabel}}</label>
                        <mat-form-field appearance="outline" class="p-0 col-md-3 mt-0 date-field customWidth">
                            <input matInput  
                                [disabled]="item?.isBenReadable || item?.isReadOnly"
                                [matDatepicker]="employedFromPicker" 
                                name="startDate"
                                placeholder="Date"
                                [(ngModel)]="customQues[item?.questionId]"
                                (click)="employedFromPicker.open()" placeholder="MM/DD/YYYY" />
                            <mat-datepicker-toggle matSuffix [for]="employedFromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #employedFromPicker></mat-datepicker>
                        </mat-form-field> 
                    </div> 
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'LONGANS'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <label class="label-cls">{{item.questionLabel}}</label>
                        <textarea [style]="{width: '100%'}" 
                                pInputTextarea 
                                [rows]="3" 
                                [(ngModel)]="customQues[item?.questionId]"
                                name="question" 
                                placeholder="Enter answer" 
                                [disabled]="item?.isBenReadable || item?.isReadOnly">
                        </textarea>
                    </div>
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'SHORTANS'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <label class="label-cls">{{item.questionLabel}}</label>
                        <input type="text" class="im-input customInput" placeholder="Enter answer" [(ngModel)]="customQues[item?.questionId]" [disabled]="item?.isBenReadable || item?.isReadOnly">
                    </div> 
                    <div class="col-md-12 p-0" *ngIf="item.answerTypeId === 'MULTISELECT'" [hidden]="item?.isBenHidden == 1 ? true: false">
                        <label class="label-cls">{{item.questionLabel}}</label>
                        <div [ngClass]="item?.layOutJsonDTO?.layOutVertical == '1' ? 'verticalCls' : 'horizontalCls' " >
                            <div *ngFor="let opt of item.layOutJsonDTO.options; let j=index;">
                                <mat-checkbox  [(ngModel)]="customQues[item?.questionId][j].checked" name="opt{{i}}" [disabled]="item?.isBenReadable || item?.isReadOnly">
                                    {{opt}}
                                </mat-checkbox>                        
                            </div> 
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div> 
</div>

<div class="row" *ngIf="isNewPortionVisible">
    <div class="col-md-12 pt-3" *ngIf="!isSectionStatus">
            <button *ngFor="let sdata of listOfQuestions; let s=index;" [ngClass]="(sdata?.isBenReadable || sdata?.isReadOnly) ? 'disableCls btn btn-primary pull-right' : 'btn btn-primary pull-right'" type="button" (click)="onClickSave(null)">Save</button>
            <button class="btn btn-primary pull-right mr-2" type="button" (click)="closeModal()">close</button>
    </div>
    <div class="p-3" *ngIf="isSectionStatus">
        <button *ngFor="let sdata of listOfQuestions; let s=index;" [ngClass]="(sdata?.isBenReadable || sdata?.isReadOnly) ? 'disableCls btn btn-primary pull-right' : 'btn btn-primary pull-right'" type="button" (click)="onClickSave(isFamilyId)">Save</button>
    </div>
</div> 
