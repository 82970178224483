import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OccupationDetailsService {

    constructor(private httpClient: HttpClient) { }

    postOccupationDetails(visaType, caseType, caseId, payload) {
        return this.httpClient.post(`/${visaType}/${caseType}/${caseId}/occupationDetails`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getOccupationDetails(visaType, caseType, caseId) {
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/occupationDetails`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

}