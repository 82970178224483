<div class="content-container pb-1 viewinvoice">
  <!-- <div class="row">
    <button class="back-button back-btn-white-bg pl-0"><i class="fa fa-chevron-circle-left" aria-hidden="true"
        (click)="backtoInvoice()"></i>
      Back
    </button>
  </div> -->
  <div class="row mt-3 preview-header heading-text"  *ngIf="title == 'View Invoice'">
    <div class="col-12 ml-3 p-2">
      <span>Invoice Preview</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <div class="p-3" *ngIf="businessLogosrc">
        <img [src]="businessLogosrc " >
      </div>
    </div>
    <div class="col-5 mt-2 text" style="text-align:center ;">
      <span>Invoice</span>
    </div>
    <!-- <div class="col-7">
    </div> -->
    <div class="col-3 mt-2 nopadding">
      <div class="mt-2">
        <strong>Date</strong> : <span>{{this.viewInvoiceData.invoiceDate}}</span>
      </div>
      <div class="mt-2">
        <strong>Due Date</strong> : <span>{{this.viewInvoiceData.invoiceDueDate}}</span>
      </div>
      <div class="mt-2">
        <strong>Invoice No</strong>: <span>{{this.viewInvoiceData.invoiceNumber}}</span>
      </div>
    </div>
  </div>
  <div class="row mt-3 " style=" background-color: #EDF4FB;">
    <div class=" col-6 mt-2">
      <strong>Bill By</strong>
    </div>
    <div class="ml-2col-6 mt-2 ml-2 " style="padding-left: 267px ;">
      <strong>Bill To</strong>
    </div>
  </div>
  <div class="divider">
  </div>
  <div class="row" style=" background-color: #EDF4FB;">
    <div class="col-6">
      <strong>{{viewInvoiceData.billedBycompany.companyName}}</strong>
      <address class="mt-3">
        {{viewInvoiceData.billedBycompany.address.addressLine1}}  {{viewInvoiceData.billedBycompany.address.city}} <br>
      {{viewInvoiceData.billedBycompany.address.countryCode}}  {{viewInvoiceData.billedBycompany.address.postCode}}<br>
        Tel: {{viewInvoiceData.billedBycompany.phoneNumber}} <br>
        Email: {{viewInvoiceData.billedBycompany.email}} <br>
      </address>
    </div>
    <div class="col-6" style="padding-left: 276px ;">
      <strong>{{viewInvoiceData.billedTocompany.companyName}}</strong>
      <address class="mt-3">
        {{viewInvoiceData.billedTocompany.address.addressLine1}} {{viewInvoiceData.billedTocompany.address.city}}<br>
              {{viewInvoiceData.billedTocompany.address.countryCode}}  {{viewInvoiceData.billedTocompany.address.postCode}}<br>
        Tel: {{viewInvoiceData.billedTocompany.phoneNumber}} <br>
        Email: {{viewInvoiceData.billedTocompany.email}} <br>
      </address>
    </div>
  </div>
  <div class=" row mt-3">
    <p-table [value]="viewInvoiceData.billingItemDTOs" dataKey="id">
      <ng-template pTemplate="header" >
        <tr>
          <!-- <th>No</th> -->
          <th>Billing Item </th>
          <th>Chargeable amount</th>
          <th>Discount</th>
          <th>Tax </th>
          <th>Total amount </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
        <tr>
          <!-- <td>{{rowIndex + 1}}</td> -->
          <td>{{order.billingItem}}</td>
          <td><span class="pos-bottom">{{order.chargeableAmt | currency }}</span> </td>
          <td><span class="pos-bottom">{{order.discountAmt | currency  }}</span></td>
          <td><span class="pos-bottom"> {{order.taxAmt | currency }}</span></td>
          <td><span class="pos-bottom">  {{order.totalAmt | currency }}</span></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="row mt-3">
    <!-- <div class="col-10"></div> -->
    <!-- <div class="col-2">
      <strong>Total </strong> : <i class='icon-style'><img src="../../../../assets/images/invoice/dolar-sign.svg"
      class="img-style" />
</i><span class="pos-bottom">  {{viewInvoiceData.grandTotal}} </span>
    </div> -->
    <div class="col-8"></div>
    <div class="col-4"> 
      <div class="pull-right">
      <strong>Total  : </strong><strong class="pos-bottom">  {{viewInvoiceData.grandTotal | currency }} </strong>
<strong class="text-capitalize">({{viewInvoiceData.grandTotalInWords}} dollars only)</strong>
</div>
    </div>
  </div>


  <!-- <div class="row  mt-3">
    <div class="col-10"></div>
    <div class="col-2">
      <strong class="text-capitalize">{{viewInvoiceData.grandTotalInWords}} dollars only</strong>
    </div>

  </div> -->
 
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <!-- <div class="col-6 preview-text">
      Terms and conditions
      <div class="divider"></div>
      <p>{{viewInvoiceData.termsAndConditions}}</p>
    </div> -->
    <div class="col-6 ">
      <div class="terms">
      Terms and conditions
      <div class="divider"></div>
      <div class="terms-content">{{viewInvoiceData.termsAndConditions}}</div>
    </div>
    </div>
    <div class="col-2">
    </div>
    <div class="col-4 ">
      
      <!-- <div *ngIf="viewInvoiceData.signImage" class="p-3">
        <img [src]="attorneysignsrc " >
      </div> -->
      <div class="pull-right">
      <div *ngIf="this.attorneysignsrc" class="p-3">
        <img [src]="attorneysignsrc " >
      </div>
      <div *ngIf="viewInvoiceData.signatoryName">
        <div>
        <label>Invoice Preparer</label>
      </div>
      <strong>{{viewInvoiceData.signatoryName}}<span> (Immigration Attorney)</span></strong>
      </div>
    </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-5"></div>
    <div class="col-1 nopadding ">
      Powered by
    </div>
    <div class="col-1">
      <img src="../../../../assets/images/logo-4_youtube.jpg">
    </div>
    <div class="col-5"></div>
  </div>