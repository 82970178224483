import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirtyFormGuard } from 'dirty-check-store';
import { Store } from '@ngrx/store';
import * as errorUtils from '../../../../modules/utility/global-utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServicesService } from 'src/app/modules/beneficiary-questionnaire/services/api-services.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit, AfterViewInit {
  beneficiaryList = [];
  notifyBeneficiaryForm: FormGroup;
  currentUser: any;
  beneficiaryParamPayload = {
    pageNumber: 1,
    recordsPerPage: 100,
    searchCriteria: 'beneficiary',
    searchText: '',
    sortBy: 'companyName',
    sortOrder: 'asc',
    status: ['ACTIVE']
  };
  
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public guard: DirtyFormGuard,
    @Inject(MAT_DIALOG_DATA) private dialogData,
    private dialogRef: MatDialogRef<SendNotificationComponent>,
    private authenticationService: AuthenticationService,
    private apiService: ApiServicesService,
    private questionnaireService: QuestionnaireService,
    private toastr: ToastrService,
    public store: Store<any>) {
      this.createForm();
      this.authenticationService.currentUser.subscribe((data) => {
        if (data) {
          this.currentUser = data.firstname+" "+data.lastName;
        }
      });
      this.getBeneficiaryList(this.dialogData);
  }  

  private createForm() {
    this.notifyBeneficiaryForm = this.formBuilder.group({
      sendTo: ['', [Validators.required]],
      sendMessage: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit() {}

  getBeneficiaryList(companyId) {
    this.apiService.getBeneficiariesByCompanyId(companyId, this.beneficiaryParamPayload).subscribe((res)=> {
      if (res) {
        this.beneficiaryList = res;
      } 
    })
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onChangeBeneficiary(data){
    const currentBeneficiary = this.beneficiaryList.find(item => item.beneficiaryEmployeeId === data);
    this.notifyBeneficiaryForm.controls.sendMessage.setValue(`Dear ${currentBeneficiary.beneficiaryEmployeeFName}${" "}${currentBeneficiary.beneficiaryEmployeeLName},\n\n         please fill out the questionnaire form and submit within the stipulated timeline.\n\n With thanks ${this.currentUser}.`);
  }

  notifyBeneficiaryFormSub(){
    const payload = {
      beneficiaryId: this.notifyBeneficiaryForm.controls.sendTo.value,
      companyId: this.dialogData
    }
    const data = {
      messageBody: this.notifyBeneficiaryForm.controls.sendMessage.value
    };
    this.questionnaireService.notifyBeneficiary(payload, data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy() {
  }

}
