import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as RolesRouteMap from 'src/app/data/constants/roles';
@Component({
  selector: 'app-route-invalid',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  wrongUrl: string = '';
  redirectUrl: string = '';
  errorMessage: string = '';

  constructor(private router: Router) {
    //this.wrongUrl = this.router.getCurrentNavigation().extras.state.data.url;
    //this.errorMessage = this.router.getCurrentNavigation().extras.state.data.error;

  }

  ngOnInit() {
    if (('isFBUser' in sessionStorage) && sessionStorage.getItem('isFBUser') === 'true') {
      this.redirectUrl = '/modules-landing/fb/module';
    } else if (sessionStorage.getItem('userTypeRole')) {
      this.redirectUrl = `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard`;
    } else {
      this.redirectUrl = '../login/login/signin';
    }

  }

  redirectToTheUrl() {
      this.router.navigate([this.redirectUrl]);
  }

}

