<div class="mx-4 mt-4 rounded border shadow row">
    <div class="col-8 bg-white containerCol">
        <h4 class="pb-5rem">Get in Touch</h4>
        <div class="row">
        <div class="col-6 pl-0">
          <img src="../../../../assets/images/sales.svg" alt="" class="mb-2">
          <p><strong>Sales</strong></p>
          <p class="mb-0">Phone: 617-865-8444</p>
          <p>Email: <a href="mailto:sales@imagility.co">Sales@imagility.co</a></p>
        </div>
        <div class="col-6">
          <img src="../../../../assets/images/support.svg" alt=""  class="mb-2">
          <p><strong>Support</strong></p>
          <p class="mb-0">Phone: 617-865-8444</p>
          <p>Email: <a href="mailto:support@imagility.co">Support@imagility.co</a></p>
        </div>
      </div>

  </div>
    <div class="col-4 containerCol">
      <h4 class="pb-5rem">Our Location</h4>
        <img src="../../../../assets/images/address.svg" alt=""  class="mb-2">
        <p><strong>Address</strong></p>
          <p class="mb-0">125 Cambridge Park Drive</p>
          <p>Cambridge, MA 02140, USA</p>
    </div>
</div>