<button type="button" *ngIf="type === 'MARK_SUBMIT'"
    [disabled]="stepDetails?.stepStatusDetails?.stepStatusCode !== 'INPROGRESS' || stepDetails?.isReadOnly === 1"
    class="im-btn im-btn-with-icon im-btn-outline-primary  d-flex align-items-center button_whitebg" (click)="updateStepStatus('SUBMIT')">
    <span class="mr-2 d-flex align-items-center" [pTooltip]="tootlTipTextMarkSubmit" tooltipPosition="bottom">
        <i class="pi pi-exclamation-circle"></i>
    </span>Mark Submit
</button>

<button type="button" *ngIf="type === 'MARK_COMPLETE' && stepDetails?.stepStatusDetails?.stepStatusCode !== 'COMPLETE'"
    [disabled]="stepDetails?.stepStatusDetails?.stepStatusCode !== 'INPROGRESS'  || stepDetails?.isReadOnly === 1"
    class="im-btn im-btn-with-icon im-btn-outline-primary d-flex align-items-center button_whitebg" (click)="updateStepStatus('COMPLETE')">
    <span class="mr-2 d-flex align-items-center" [pTooltip]="tootlTipTextMarkComplete" tooltipPosition="bottom">
        <i class="pi pi-exclamation-circle"></i>
    </span>Mark Complete
</button>
<div>
    <img *ngIf="type === 'MARK_COMPLETE' && stepDetails?.stepStatusDetails?.stepStatusCode === 'COMPLETE'"
        src="../../../../../assets/images/stepCompleteLabel.png" />
</div>

<div class="d-flex">
    <button type="button" *ngIf="type === 'EDIT'"
    [disabled]="stepDetails?.stepStatusDetails?.stepStatusCode === 'NEW' || stepDetails?.stepStatusDetails?.stepStatusCode === 'INPROGRESS' || stepDetails?.isReadOnly === 1"
    class="im-btn im-btn-with-icon im-btn-outline-primary  d-flex align-items-center button_whitebg mr-5" (click)="updateStepStatus('INPROGRESS')">
    <span class="mr-2 d-flex align-items-center" [pTooltip]="tootlTipTextEdit" tooltipPosition="bottom">
        <i class="pi pi-pencil"></i>
    </span>Edit
</button>
<div *ngIf="type === 'EDIT'" [pTooltip]="stepDetails?.notes?.noteText" class="icon-background notes task-notes-btn" [class.yellow]="stepDetails?.notesAvailable===true" #btnNote (click)="notepanel.toggle($event, btnNote)">
    <!-- <i class="material-icons icn-note">assignment</i>     -->
    <span class="icn-note"></span>
</div>    
<p-overlayPanel #notepanel appendTo="btnNote" class="notepanel" [showCloseIcon]="true" [dismissable]="true">
    <ng-template pTemplate>              
      <task-notes-lib [step]="stepDetails" (noteAdded)="updateNoteAvailability($event)"></task-notes-lib>
    </ng-template>
</p-overlayPanel>
</div>