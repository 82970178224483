import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validator, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { SchedulingService } from 'src/app/services/scheduling.service';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import moment from "moment";
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EmailClientService } from '../../email-client/service/email-client.service';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})

export class MeetingComponent implements OnInit {
  @Input('obj') meetingEditObj;

  obs: Subscription;
  companyId: any;
  otherPeoples: any;
  calendarItemId: any;
  series: any;

  cities: any = [];
  userType: string;
  recurrencedropdown: any[];

  showRecurrnece: boolean = false;
  timedropdown: any = [];
  requiredEmails: any =[];
  hideDate: boolean;
  requiredImgEmails: any = [];
  optImgEmail: any = [];
  selectedWeeks: string[] = [];
  
  meetingForm: FormGroup;
  durationList: any[] = [{ 'type': 'WEEK', 'code': 'WEEK' },
  { 'type': 'BI-WEEK', 'code': 'BIWEEK' },
  { 'type': 'MONTH', 'code': 'MONTH' }, { 'type': 'YEAR', 'code': 'YEAR' }];

  daysList: any[] = [
  { 'value': 1, 'day': 'MONDAY' },
  { 'value': 2, 'day': 'TUESDAY' },
  { 'value': 3, 'day': 'WEDNESDAY' },
  { 'value': 4, 'day': 'THURSDAY' },
  { 'value': 5, 'day': 'FRIDAY' },
  { 'value': 6, 'day': 'SATURDAY' },
  { 'value': 7, 'day': 'SUNDAY' }];
  

  selectedDays: string[] = [];
  dateTimeToSend: Date;
  selectedTime: string;
  scheduleType: string = '';
  BIWeekly: string = 'FIRST WEEK';
  recurrences: any[] = [];
  weekNo;
  dayOfMonth;
  monthOfYear: any;
  custommonthOfYear:any;
 
  navUrl;
  privateMeeting: string;
  publicMeeting: string;
  UserToken: any;
  filteredCountries: any[];
  countries: any[];
  filteredEmails: any;
  petitionerCompanyList: any[];
   meetingEditDetails: any;
  required: boolean;
  optional: boolean;
  empNotfound: boolean;
  custompopup: boolean;
  timevalidationerror: boolean;
  updatepopUp: boolean;
  meetingUpdate: string;
  customRecurrence: any = [
    { "name": "days", "code": "DAILY" },
    { "name": "weeks", "code": "WEEKLY" },
    { "name": "months", "code": "MONTHLY" },
    { "name": "years", "code": "YEARLY" }
  ]
  weeKnumber: any = [
    { "name": "First", "code": 1 },
    { "name": "Second", "code": 2 },
    { "name": "Third", "code": 3 },
    { "name": "Fourth", "code": 4 },
    { "name": "Fifth", "code": 5 }
  ]
  optEmail: any = [];
  minimumDate;
  isPublic: any;
  optionalEmail: any = [];
  requiredEmail : any = [];
  subrecurrenceType: any;
  meetingpageLoaded: boolean = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private schedulingservice: SchedulingService,
    private reflibrarayservice: ReferenceLibraryService,
    public emailClientService: EmailClientService,
    private toaster: ToastrService) 
    {
    this.companyId = sessionStorage.getItem('companyId')
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    } 
    this.reflibrarayservice.getReferenceData('CALRECURTYP').subscribe(res => {
      console.log('company', res)
      this.recurrencedropdown = res;
      this.recurrencedropdown[2].name = 'Daily(EveryDay)'
    })
    //this.schedulingservice.getClientNameList1(this.UserToken).subscribe(res => {
    this.schedulingservice.getClientNameList1(this.UserToken, this.companyId).subscribe(res => {
      console.log('companylist', res)
      this.petitionerCompanyList = res['data'];
    })
    this.meetingForm = this.fb.group({
      subject: ['', Validators.required],
      attendiesList: [''],
      optimagilityattendiesList: [''],
      calItemTypeCode: ['MEETING'],
      calendarItemId: ['0'],
      calendarScheduleId: ['0'],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      startDate: ['', Validators.required],
      isAllday: 0,
      isPublic: [],
      isSeries: 0,
      meetingCompanyId: [''],
      meetingCompanyName: [''],
      meetingLocation: ['',Validators.required],
      timeZoneCode: [''],
      description: [''],
      selectedWeeks: [],
      dayOfMonth: [''],
      monthOfYear: [''],
      custommonthOfYear:[''],
      customdayOfMonth:[''],
      customWeekNo: [''],
      customrecuurenceType: [''],
      customselectedWeeks: [],
      customday: [''],
      // eventType:[''],
      recuurenceType: ['', Validators.required],
      optattendiesList:[''],
      reqattendiesList:['', Validators.required ],
      reqImagilityattendiesList:[''],
      customInterval: [''],
     recurrenceTypeDTO: this.fb.group({
        day: null,
        interval: 1,
        month: null,
        type: null,
        weekDays: null,
        subType:null,
        weekNo: null
      }),
    });
    this.meetingpageLoaded = true;

  }

  ngOnInit(): void {
    this.minimumDate = new Date();
    this.hideDate = true;
    this.calendarItemId = this.meetingEditObj.id;
    this.series = this.meetingEditObj.isSeries;
    this.UserToken = sessionStorage.getItem('UserToken');
    this.schedulingservice.getCalendarItemDetails(this.calendarItemId, this.series).subscribe(res => {
      this.meetingEditDetails = res['data'];
      this.optionalEmail = this.meetingEditDetails.optional.filter(val =>{
         return val.userId !== "";
      })
      this.requiredEmail = this.meetingEditDetails.required.filter(val =>{
        return val.userId !== "";
     })
      if (this.meetingEditDetails) {
        if(this.meetingEditDetails.recurrenceType.type ==='NOREPEAT'){
          this.hideDate = false;
        }
        this.updatepopUp = true;
        this.requiredEmails = this.meetingEditDetails.required.map(val =>{
          return val.emailId;
        });
        this.optEmail = this.meetingEditDetails.optional.map(val =>{
          return val.emailId;
        })
        this.requiredImgEmails = this.requiredEmail.map(val =>{
          return{
          "name": val.firstName + ' ' + val.lastName,
            "emailId": val.emailId,
            "userId": val.userId,
          }
        });
      
        this.optImgEmail = this.optionalEmail.map(val =>{
          return{
          "name": val.firstName + ' ' + val.lastName,
            "emailId": val.emailId,
            "userId": val.userId,
          }
        });
        this.meetingForm = this.fb.group({
          subject: [this.meetingEditDetails.title, Validators.required],
          description: [this.meetingEditDetails.description],
         
          calItemTypeCode: [this.meetingEditDetails.calendarItemType],
          calendarItemId: [this.meetingEditDetails.id],
          calendarScheduleId: ['0'],
          meetingLocation: [this.meetingEditDetails.location,Validators.required],
          startTime: [this.meetingEditDetails.startTime, Validators.required],
          endTime: [this.meetingEditDetails.endTime, Validators.required],
          startDate: [moment(this.meetingEditDetails.startDate).format('MM/DD/YYYY'), Validators.required],
          endDate: [moment(this.meetingEditDetails.endDate).format('MM/DD/YYYY')],
          isAllday: 0,
          isPublic: this.meetingEditDetails.isPublic,
          isSeries: 0,
          meetingCompanyId: [this.meetingEditDetails.clientId],
          meetingCompanyName: [this.meetingEditDetails.companyName],
          timeZoneCode: [''],
          selectedWeeks: [this.meetingEditDetails.recurrenceType.weekDays],
          customselectedWeeks: [this.meetingEditDetails.recurrenceType.weekDays],
          dayOfMonth: this.meetingEditDetails.recurrenceType.day,
          customdayOfMonth: this.meetingEditDetails.recurrenceType.day,
          monthOfYear: [''],
          custommonthOfYear: [''],
          customWeekNo: this.meetingEditDetails.recurrenceType.weekNo,
          customday: this.meetingEditDetails.recurrenceType.weekDays ? this.meetingEditDetails.recurrenceType.weekDays[0] :'',//saving in arrary format so or display 
          recuurenceType: [this.meetingEditDetails.recurrenceType.type],
          customInterval:[this.meetingEditDetails.recurrenceType.interval],
          customrecuurenceType:[this.meetingEditDetails.recurrenceType.subType],
          reqattendiesList:[this.meetingEditDetails.required.map((value )=> {
            return value.emailId
          }),Validators.required],
          optattendiesList:[this.meetingEditDetails.optional.map((value )=> {
            return value.emailId
          })],
          reqImagilityattendiesList:[this.meetingEditDetails.required ],
          optimagilityattendiesList:[this.meetingEditDetails.optional],
          recurrenceTypeDTO: this.fb.group({
            day: this.meetingEditDetails.recurrenceType.day,
            interval: 1,
            month: null,
            type: this.meetingEditDetails.recurrenceType.type,
            subtype: this.meetingEditDetails.recurrenceType.subType,
            weekDays: [null],
          }),
        });
        this.scheduleType = this.meetingEditDetails.recurrenceType.type;
        this.subrecurrenceType = this.meetingEditDetails.recurrenceType.subType;
        this.selectedWeeks = this.meetingEditDetails.recurrenceType.weekDays;
        console.log('this.meetingEditObj ::', this.meetingEditObj);
        console.log('this.meetingEditObj', this.meetingEditObj)
      } else{
        this.meetingForm = this.fb.group({
          subject: ['', Validators.required],
          attendiesList: [''],
          optimagilityattendiesList: [''],
          calItemTypeCode: ['MEETING'],
          calendarItemId: ['0'],
          calendarScheduleId: ['0'],
          startTime: ['', Validators.required],
          endDate: ['', Validators.required],
          endTime: ['', Validators.required],
          startDate: ['', Validators.required],
          isAllday: 0,
          isPublic: [''],
          isSeries: 0,
          meetingCompanyId: [''],
          meetingCompanyName: [''],
          meetingLocation: ['',Validators.required],
          timeZoneCode: [''],
          description: [''],
          selectedWeeks: [],
          dayOfMonth: [''],
          monthOfYear: [''],
          customdayOfMonth:[''],
          customWeekNo: [''],
          customrecuurenceType: [''],
          customselectedWeeks: [''],
          customday: [''],
          recuurenceType: [''],
          optattendiesList:[''],
          reqattendiesList:['', Validators.required ],
          reqImagilityattendiesList:['',Validators.required],
          customInterval: [''],
         recurrenceTypeDTO: this.fb.group({
            day: null,
            interval: 1,
            month: null,
            type: null,
            weekDays: [],
            subType:null,
            weekNo: null
          }),
        });
      }
     
    })
  }

  ngAfterViewInit() {
    let control = this.meetingForm.get('isPublic');
    this.obs =  control.valueChanges.subscribe(e => { control.setValue(e, {emitEvent: false}); });
  }

 
  filterAttendes(event) {
    let query = event.query;
    // this.schedulingservice.getAttendesList(this.UserToken, query, this.companyId).subscribe(res => {
      this.schedulingservice.getAttendesList1(this.UserToken, query, this.companyId).subscribe(res => {
      this.countries = res['data'];
      if(res.status == 204){
        this.empNotfound = true;
      }
      if (this.countries && this.countries.length) {
        this.empNotfound = false;
        this.filteredEmails = this.countries.map(element => {
          var attendesObj = {
            "name": element.firstName + ' ' + element.lastName,
            "emailId": element.email,
            "userId": element.userId,
            "isOptional": 0,

          }
          return attendesObj;
        })
        this.filteredCountries = this.filteredEmails;
      }
    },
      error => {
        console.log(error)
      }
    )
  }



  recurrenceTypechanged(ev) {
    this.hideDate = true;
    this.scheduleType = ev.value;
    this.onChangeScheduleType(ev.value);
    // if(this.scheduleType === 'CUSTOM'){
    //   this.custompopup = true;
    // }
    if(this.scheduleType ==='NOREPEAT'){
      this.hideDate = false;
    }
  }

  
  onChangecustomScheduleType(revurrencetype: any) {
    switch (revurrencetype) {
      case 'DAILY':
        this.resetCustomValue();
        break;
      case 'WEEKLY':
        this.resetCustomValue();
        break;
      case 'MONTHLY':
        this.resetCustomValue();
        break;
      case 'YEARLY':
        this.resetCustomValue();
        break;
    }
  }

    resetCustomValue(){
      this.meetingForm.value.customInterval.setValue(null);
      this.meetingForm.value.customrecuurenceType.setValue(null);
      this.meetingForm.value.customselectedWeeks.setValue(null);
      this.meetingForm.value.customdayOfMonth.setValue(null);
      this.meetingForm.value.customWeekNo.setValue(null);
    }

  onChangeScheduleType(revurrencetype: any) {
    switch (revurrencetype) {
      case 'DAILY':
        this.resetDurationValues();
        break;
      case 'WEEKLY':
        this.resetDurationValues();
        break;
      case 'MONTHLY':
        this.resetDurationValues();
        break;
      case 'YEARLY':
        this.resetDurationValues();
        break;
      case 'NOREPEAT':
        this.resetDurationValues();
        break;
      case 'BIWEEKLY':
        this.resetDurationValues();
        break;
      case 'CUSTOM':
        this.resetDurationValues();
    }
  }


  resetDurationValues() {
    this.meetingForm.get('dayOfMonth').setValue(null);
    this.meetingForm.get('selectedWeeks').setValue(null);
    this.meetingForm.get('monthOfYear').setValue(null);
  }
  onChangeWeekDays(day, event) {
    if (event.checked) {
      console.log('days', day)
    }
    else {
      var index = this.recurrences.findIndex(item => item.dayOfWeek === day.value);
      this.recurrences.splice(index, 1);

    }
  }



  save() {
    let startT = this.meetingForm.get("startTime")?.value;
      let endT = this.meetingForm.get("endTime")?.value;
      let hour = startT.substring(0,2);
      let minutes = startT.substring(3);
      let ehour = endT.substring(0,2);
      let eminutes = endT.substring(3);
      if( ehour < hour  ) {
        this.timevalidationerror = true;
        this.meetingForm.patchValue({
          endTime: ''
        })
      } else  if( ehour == hour && eminutes <  minutes) {
        this.timevalidationerror = true;
        this.meetingForm.patchValue({
          endTime: ''
        })
      }
    let meetingObj = this.meetingForm.value;
    this.meetingForm.value.isSeries = this.scheduleType === 'NOREPEAT' ? 0 : 1;
    this.addAttendes();
    this.meetingForm.value.isPublic = parseInt(this.isPublic);
    this.meetingForm.value.startDate = moment(this.meetingForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.meetingForm.value.endDate = moment(this.meetingForm.get("endDate")?.value).format('YYYY-MM-DD'),
    this.meetingForm.value.recurrenceTypeDTO.type = this.scheduleType;
    this.meetingForm.value.recurrenceTypeDTO.weekDays = this.meetingForm.value.selectedWeeks;
    this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.dayOfMonth;
    if (this.scheduleType === 'YEARLY') {
      this.meetingForm.value.recurrenceTypeDTO.month = this.meetingForm.value.monthOfYear.getMonth() + 1;
      this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.monthOfYear.getDate();
    }
    if (this.scheduleType === 'BIWEEKLY') {
      this.meetingForm.value.recurrenceTypeDTO.interval = 2;
    }
    if(this.scheduleType === 'CUSTOM'){
      if( this.meetingForm.value.recurrenceTypeDTO.subType === 'DAILY'){
        this.meetingForm.value.recurrenceTypeDTO.interval = this.meetingForm.value.customInterval;
      }
      if(this.subrecurrenceType === 'MONTHLY'){
        this.meetingForm.value.recurrenceTypeDTO.weekDays = 
        this.meetingForm.value.customselectedWeeks ===""  ? null : [this.meetingForm.value.customday];
        this.meetingForm.value.recurrenceTypeDTO.weekNo = this.meetingForm.value.recurrenceTypeDTO.customWeekNo ==="" ? null:
        this.meetingForm.value.customWeekNo;
        this.meetingForm.value.recurrenceTypeDTO.day=0;

      }
      if(this.subrecurrenceType === 'WEEKLY'){
        this.meetingForm.value.recurrenceTypeDTO.weekDays = this.meetingForm.value.customselectedWeeks;
      }

      if (this.subrecurrenceType === 'YEARLY') {
        this.meetingForm.value.recurrenceTypeDTO.month = this.meetingForm.value.custommonthOfYear.getMonth() + 1;
        this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.custommonthOfYear.getDate();
      }
      else {
        // this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.customdayOfMonth ==="" ? null:this.meetingForm.value.customdayOfMonth;
      }
      this.meetingForm.value.recurrenceTypeDTO.subType = this.meetingForm.value.customrecuurenceType;
      this.meetingForm.value.recurrenceTypeDTO.interval = this.meetingForm.value.customInterval ? this.meetingForm.value.customInterval : "1";
    }
    if(this.scheduleType=== 'WEEKLY' && !this.meetingForm.value.selectedWeeks.length){
      this.meetingForm.value.recurrenceTypeDTO.weekDays =['MONDAY','TUESDAY', 'WEDNESDAY' ,'THURSDAY' ,'FRIDAY' ,'SATURDAY','SUNDAY' ];
    }
    console.log('meet', this.meetingForm.value);

    this.schedulingservice.saveMeeting(meetingObj).subscribe(response => {
      this.toaster.success(response.message, 'Success')
      this.router.navigate([this.navUrl]);
    },
      error => {
        console.log('meeting error', error)
      }
    );
  }

  addAttendes(){
    let attendesArray =[];
    if(this.requiredImgEmails.length >0){
      const temp = this.requiredImgEmails.map(val=>{
        return{
               "emailId": val.emailId,
               "isOptional": 0,
             "userId": val.userId
        }
      });
      attendesArray = [...attendesArray,...temp];
      this.meetingForm.value.attendiesList = attendesArray;
    } 
    if(this.optImgEmail.length >0){
      const temp = this.optImgEmail.map(val=>{
        return{
          "emailId": val.emailId,
          "isOptional": 1,
        "userId": val.userId
        }
      });
      attendesArray = [...attendesArray,...temp];
      this.meetingForm.value.attendiesList = attendesArray;
    } 
    if(this.optEmail.length >0){
      const temp = this.optEmail.map(val=>{
        return{
          "emailId": val,
          "isOptional": 1,
        "userId": null
        }
      });
      attendesArray = [...attendesArray,...temp];
      this.meetingForm.value.attendiesList = attendesArray;
      const result = this.meetingForm.value.attendiesList.reduce((finalArray, current) => {
        let obj = finalArray.find((item) => item.emailId === current.emailId);
        if (obj) {
          return finalArray;
        }
        return finalArray.concat([current]);
      }, []);
      this.meetingForm.value.attendiesList = result;
      
    }
    if(this.requiredEmails.length >0){
      const temp = this.requiredEmails.map(val=>{
        return{
          "emailId": val,
          "isOptional": 0,
        "userId": null
        }
      });
      attendesArray = [...attendesArray,...temp];
      this.meetingForm.value.attendiesList = attendesArray;
      const result = this.meetingForm.value.attendiesList.reduce((finalArray, current) => {
        let obj = finalArray.find((item) => item.emailId === current.emailId);
        if (obj) {
          return finalArray;
        }
        return finalArray.concat([current]);
      }, []);
      this.meetingForm.value.attendiesList = result;
    }
    

    const finalattendesArray = this.meetingForm.value.attendiesList.reduce((finalArray, current) => {
      let obj = finalArray.find((item) => item.emailId === current.emailId);
      if (obj) {
        return finalArray;
      }
      return finalArray.concat([current]);
    }, []);
    this.meetingForm.value.attendiesList = finalattendesArray;
  }
  
  addImagilityEmail(){
    this.optional = true;
    // this.meetingForm.patchValue({
    //   optimagilityattendiesList: null
    // });
    // this.optImgEmail = [];
  }
  closeEmailpopup(){
    this.required = false;
    this.optional = false;
  }

  requiredImagilityEmail(){
    this.required = true;
  //   this.meetingForm.patchValue({
  //     reqImagilityattendiesList: null
  //   });
  //  this.requiredImgEmails = [];
  }

  update() {
    let updateobj = this.meetingForm.value;
    this.addAttendes();
    this.meetingForm.value.isPublic = parseInt(this.isPublic);
    this.meetingForm.value.recurrenceTypeDTO.type = this.scheduleType;
    this.meetingForm.value.isSeries = this.meetingUpdate;
    this.meetingForm.value.startDate = moment(this.meetingForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.meetingForm.value.endDate = moment(this.meetingForm.get("endDate")?.value).format('YYYY-MM-DD'),
    this.meetingForm.value.calItemTypeCode = 'MEETING';
    this.meetingForm.value.recurrenceTypeDTO.weekDays = this.meetingForm.value.selectedWeeks;
    this.meetingForm.value.recurrenceTypeDTO.month = this.meetingForm.value.dayOfMonth;
    this.meetingForm.value.recurrenceTypeDTO.type = this.scheduleType;
    if (this.scheduleType === 'YEARLY') {
      this.meetingForm.value.recurrenceTypeDTO.month = this.meetingForm.value.monthOfYear.getMonth() + 1;
      this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.monthOfYear.getDate();
    }
    if (this.scheduleType === 'BIWEEKLY') {
      this.meetingForm.value.recurrenceTypeDTO.interval = 2;
    }
    if(this.scheduleType === 'CUSTOM'){
      if( this.meetingForm.value.recurrenceTypeDTO.subType === 'DAILY'){
        this.meetingForm.value.recurrenceTypeDTO.interval = this.meetingForm.value.customInterval;
      }
      if(this.subrecurrenceType === 'MONTHLY'  ){ //|| this.subrecurrenceType === 'YEARLY' 
        // this.meetingForm.value.recurrenceTypeDTO.weekDays = 
        // this.meetingForm.value.customselectedWeeks ? null :  [this.meetingForm.value.customday];
        this.meetingForm.value.recurrenceTypeDTO.weekDays = [this.meetingForm.value.customday];
        this.meetingForm.value.recurrenceTypeDTO.weekNo = this.meetingForm.value.recurrenceTypeDTO.customWeekNo ==="" ? null:
        this.meetingForm.value.customWeekNo;
        this.meetingForm.value.recurrenceTypeDTO.day=0;

      }
      if(this.subrecurrenceType === 'WEEKLY'){
        this.meetingForm.value.recurrenceTypeDTO.weekDays = this.meetingForm.value.customselectedWeeks;
      }
      if (this.subrecurrenceType === 'YEARLY') {
        this.meetingForm.value.recurrenceTypeDTO.month = this.meetingForm.value.custommonthOfYear.getMonth() + 1;
        this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.custommonthOfYear.getDate();
      }

      this.meetingForm.value.recurrenceTypeDTO.subType = this.meetingForm.value.customrecuurenceType;
      // this.meetingForm.value.recurrenceTypeDTO.day = this.meetingForm.value.customdayOfMonth ==="" ? null:this.meetingForm.value.customdayOfMonth;
      this.meetingForm.value.recurrenceTypeDTO.interval = this.meetingForm.value.customInterval;
    }
    if(this.scheduleType=== 'WEEKLY' && !this.meetingForm.value.selectedWeeks.length){
      this.meetingForm.value.recurrenceTypeDTO.weekDays =['MONDAY','TUESDAY', 'WEDNESDAY' ,'THURSDAY' ,'FRIDAY' ,'SATURDAY','SUNDAY' ];
    }
    this.schedulingservice.updateMeeting(updateobj).subscribe(response => {
      console.log('meeting response', response);
      this.toaster.success(response.message, 'Success');
      this.router.navigate([this.navUrl]);
    },
      error => {
        console.log('meeting error', error)
      }
    );
  }

  cancel() {
    let cancelpayload = {
      "calendarId": this.meetingEditObj.parentId,
      "calendarItemId": this.meetingEditObj.id,
      "calendarItemType": this.meetingEditObj.calenderItemType,
      "isCancelAllItems": true
    }
    this.schedulingservice.cancelCalendarItem(cancelpayload).subscribe(res => {
      if (res) {
        this.toaster.success(res.message, 'Success')
        this.router.navigate([this.navUrl]);
      }
    })

  }

  updatesave(){
   this.updatepopUp = false;
  }

  updatecancel(){
    this.router.navigate([this.navUrl]);
  }

  reset(){
    this.meetingForm.reset();
    if(window.location.href.indexOf("schedulemeetingid") != -1){
      this.navUrl = "/attorney-landing/leads/marketing";
    }else{
      if (this.userType == "Petitioner") {
        this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
      } else if (this.userType == "Attorney") {
        this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
      }
    }     
    this.router.navigate([this.navUrl]);
  }

  addEmail(){
   let reuiredemails = this.meetingForm.value.reqImagilityattendiesList.map(val =>val.emailId)
   this.requiredEmails = [...this.meetingForm.value.reqattendiesList, ...reuiredemails];
   this.requiredEmails = [...new Set(this.requiredEmails)]
   //this.meetingForm.value.reqImagilityattendiesList.reset();
}
addoptEmail() {
  let optionalemails = this.meetingForm.value.optimagilityattendiesList.map(val => val.emailId);
  this.optEmail = [...this.meetingForm.value.optattendiesList, ...optionalemails];
  this.optEmail = [...new Set(this.optEmail)]
}
    
setEndDate(ed){
  console.log('setEndDate', ed);
 
} 

checkEndDateTouched(sd){
  if(sd.value){
    this.meetingForm.patchValue({
      endDate: sd.value
    })
  }

  // if(ed.value) {
  //   alert ('ed has value'  +ed.value);
  //   alert('startDate'+moment(sd.value));
  //   alert('startDate'+moment(sd.value).format('MM/DD/YYYY'));
  //   alert('endDate'+moment(ed.value));
  //   alert('endDate'+moment(ed.value).format('MM/DD/YYYY'));
  // } else {
  //   alert('startDate'+moment(sd.value).format('MM/DD/YYYY'));
  // }
}

checkendTime(){
  console.log('time');
}

setEndTime(et){
  // console.log('setEnd Time', et);
  // alert('setEnd Time' + et.value);
}

checkEndTimeTouched(et,st){
  // if(et.value) {
  //   alert ('ed has value'  +et.value);
  //   alert('startDate'+moment(et.value));
  //   alert('startDate'+moment(st.value).format('MM/DD/YYYY'));
  //   alert('endDate'+moment(et.value));
  //   alert('endDate'+moment(et.value).format('MM/DD/YYYY'));
  // } else {
  //   alert('startTime :: '+ st.value);
  // }
}

checkStartTimeExists(et,st){
  // if(st.value) {
  //   let startTime = st.value;
  //   let hour = startTime.substring(0,2);
  //   let minutes = startTime.substring(2);
  //   let endTime = et.value;
  //   let ehour = endTime.substring(0,2);
  //   let eminutes = endTime.substring(2);
  //   alert ('time ::' + startTime +'hour :: ' + hour + 'minutes' + minutes + 'endTime ::' + endTime +'ehour :: ' + ehour + 'eminutes' + eminutes);
  // }
}

customrecurrenceTypechanged(ev){
  console.log(ev.value);
  this.subrecurrenceType = ev.value;
  this.onChangecustomScheduleType(ev.value);
}

onweekNochanged(ev){
  console.log('weekno',ev.value)
   
}

    

}

 


